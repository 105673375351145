/* eslint-disable */
import React, {useState, memo, useEffect, useRef} from 'react';
import moment from 'moment';
import {diff} from 'deep-object-diff';
import T from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, FieldArray} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {RadioGroup} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {
  getLocationLoading,
  locationData,
  locations
} from '../../../../store/locationSettings/selectors';
import {
  DashboardDisableStore,
  DashboardField,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  DashboardFormWrapper,
  DashboardHolder,
  DashboardInnerDescription,
  DashboardStoreToggleSection,
  DashboardStoreToggleWrapper,
  DashboardText,
  DashboardTimeDelete,
  DashboardTimeInner,
  DashboardTitle,
  DialogLinkButton,
  FieldLabel
} from '../../dashboard/styles';
import Loading from '../../../../components/shared/Utils/Loading';
import {
  UPDATE_LOCATION_SETTINGS_DATA,
  POST_LOCATION_SETTINGS_DATA,
  OPEN_CONFIRMATION_MODAL,
  OPEN_DISABLE_MODAL,
  PUBLISH_LOCATION,
  OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL,
  SET_STATUS_BUTTON
} from '../../../../store/locationSettings/action';
import FieldInput from '../../../../components/FieldInput';
import CheckboxS2E from '../../../../components/Checkbox';
import {ButtonInfo, ButtonPrimary} from '../../../../components/buttons';
import RadioS2E from '../../../../components/Radiobox';
import AutocompletedMapField from '../LocationMap/AutocompletedMapField';
import LocationMap from '../LocationMap/LocationMap';
import SwitchS2E from '../../../../components/Switch';
import LocationConfirmationModal from '../Modals/LocationConfirmationModal';
import LocationDisableModal from '../Modals/LocationDisableModal';
import {mapProps} from '../constants';
import {
  isLocationNameUnique,
  openingDaysServerParser,
  isOpeningHoursChange,
  refreshClosingTime,
  changeTimeToFitWorkingHours
} from '../helpers';
import DaysPickerContainer from '../DaysPickerContainer';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import StoreSettingsSchema from '../../../../helpers/services/formValidationSchemas/StoreSettingsSchema';
import cn from 'classnames';
import {maxLenFormatter} from '../../../../helpers/functionUtils/helpFunctions';
import FileUploader from '../../../../components/FileUploader';
import {
  countryCodeFormatter,
  etaFormatter,
  phoneNumberFormatter,
  taxFormatter
} from '../../../../helpers/functionUtils/helpFunctions';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import {toast} from 'react-toastify';
import FieldMaskInput from '../../../../components/FieldMaskInput';
import {LocationFormActions, StoreDisabledBanner} from '../styles';
import SaveButton from '../../../../assets/SaveButton';
import Textarea from '../../../../components/FieldTextarea/style';
import validateEtaDuration from '../helpers/ValidateEtaDuration';
import ButtonClear from '../../../../components/buttons/ButtonClear/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import ButtonSaveScroll from '../../../../components/buttons/ButtonSave';
import RouteLeavingGuardNew from '../../../../helpers/RouteLeavingGuardNew';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';
import IconSaveDisk from '../../../../assets/IconSaveDisk';
import ButtonBold from '../../../../components/buttons/ButtonBold/styles';
import {UNPUBLISH_BANNER_SETTINGS_TYPE} from '../../../../store/global/globalNotifications/constants';
import ChangeAddressConfirmationModal from '../Modals/ChangeAddressConfirmationModal';
import {getSessionStorageItem} from '../../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../../helpers/sessionStorage/constants';

const getServiceDisabledHint = (timestamp, msg, data) => {
  timestamp =
    typeof timestamp === 'object'
      ? Number(Date.parse(timestamp)) / 1000
      : timestamp;

  let text = msg || 'Disabled';

  if (timestamp) {
    text += ' until ' + moment.unix(timestamp).utc().format('MM/DD/YYYY HH:mm');
  }

  return text;
};

const LocationForm = ({isCreating, setIsCreating}) => {
  const formikRef = useRef();
  const openingDays = useDaysPickerContext();
  const merchantLocations = locations();
  const showUnPublishBannerType = useSelector(
    (state) => state.globalNotificationsReducer.unPublishBanner.type
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [mapMarker, setMapMarker] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const loading = getLocationLoading();

  const [messageUnPublish, setMessageUnPublish] = useState(false);
  const computedAddTaxButtonClasses = (taxPercents) => {
    const haveEmptyTax = Boolean(taxPercents.find((elem) => elem.Value === ''));
    if (haveEmptyTax || taxPercents.length >= 5) {
      return 'disabled';
    }

    return '';
  };

  const [showCancelHolidaysModal, setShowCancelHolidaysModal] = useState(false);
  const [hideBannerHolidays, setHideBannerHolidays] = useState(false);
  const [valuesHolidays, setValuesHolidays] = useState();

  const activeOrders = useSelector(
    (state) => state.activeOrdersReducer.activeOrders
  );

  const isDisableModalFutureOrders = useSelector(
    (state) => state.locationSettingsReducer.isDisableModalFutureOrders
  );
  const {isChangeAddressModalOpen} = useSelector(
    (state) => state.locationSettingsReducer
  );

  const {data, disableButton} = useSelector(
    (state) => state.locationSettingsReducer
  );

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closePublishMessage = () => {
    setMessageUnPublish(false);
    setConfirmedNavigation(true);
  };

  const checkIfSauceEmployee = () => {
    return getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
  };

  const publishLocation = () => {
    dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallBack: () => {},
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  useEffect(() => {
    if (data) {
      dispatch(SET_STATUS_BUTTON(false));
    }
  }, [data]);

  useEffect(() => {
    if (data?._id) setValuesHolidays(false);
  }, [data?._id]);

  useEffect(() => {
    if (data?.StoreHolidays && !Object?.keys(data?.StoreHolidays)?.length) {
      setValuesHolidays(false);
    }
  }, [data?.StoreHolidays]);

  useEffect(() => {
    if (formikRef.current && !isCreating && !messageUnPublish) {
      const {
        values,
        resetForm,
        setFieldValue,
        isSubmitting
      } = formikRef.current;

      // if (!isSubmitting) {
      //   resetForm({...values});
      // }
      setFieldValue('OpeningHoursChanged', values.OpeningHoursChanged);
    }
  }, [formikRef.current]);

  const {
    FullName,
    Phone,
    Email,
    CustomErrorMessage,
    PickupEtaMinutes,
    EtaMinutes,
    Say2eatDispatch,
    InhouseDelivery,
    Latitude,
    Longitude,
    TaxPercents,
    AlcoholTax,
    DeliveryOptions,
    DeliveryEnableIn,
    PickupEnableIn,
    PickupDisableReason,
    PickupDisableOption,
    DeliveryDisableReason,
    DeliveryDisableOption,
    OpeningHours,
    DeliveryAreaType,
    Timezone,
    Address,
    WorkingDays,
    ClosedManually,
    ClosedManuallyUntilTime,
    ClosedManuallyTime,
    notSyncedData,
    WelcomeLogoURL,
    ClosedManuallyReason,
    StoreDisableOption,
    // MailForHumanResponseMessage = '',
    MailForWeeklyReport = '',
    StructuredAddress
  } = locationData();
  const initValues = () =>
    isCreating
      ? {
          FullName: '',
          Phone: '',
          CountryCode: '+1',
          Email: '',
          ClosedManually: false,
          CustomErrorMessage: '',
          PickupEtaMinutes: 15,
          DeliveryOptions: {
            Pickup: false,
            Delivery: false,
            IsCatering: false,
            Curbside: false,
            CurbsideInstructions: 'Please send us your car model and color',
            CurbsideAdditionalInformation: 'Car: '
          },
          OpeningDays: 'allDays',
          EtaMinutes: 60,
          Say2eatDispatch: true,
          InhouseDelivery: false,
          Address: '',
          City: '',
          Country: '',
          State: '',
          ZipCode: '',
          Location: '',
          GoogleMapURL: '',
          WelcomeLogoURL: '',
          FullAddress: '',
          PlaceId: '',
          Latitude: 0,
          Longitude: 0,
          TaxPercents: [],
          AlcoholTax: 0,
          DeliveryAreaType: '',
          MailForWeeklyReport: '',
          StreetNumber: '',
          StructuredAddress: {}
          // MailForHumanResponseMessage: ''
        }
      : {
          FullName,
          Phone: phoneNumberFormatter(
            Phone.includes('+1') ? Phone.split('+1')[1] : Phone
          ),
          CountryCode: '+1',
          Email,
          ClosedManually,
          WelcomeLogoURL,
          CustomErrorMessage,
          ClosedManuallyTime,
          PickupEtaMinutes: String(PickupEtaMinutes),
          DeliveryOptions: {
            ...DeliveryOptions,
            CurbsideInstructions:
              DeliveryOptions?.CurbsideInstructions ||
              'Please send us your car model and color',
            CurbsideAdditionalInformation:
              DeliveryOptions?.CurbsideAdditionalInformation || 'Car: '
          },
          EtaMinutes,
          Say2eatDispatch,
          InhouseDelivery,
          Latitude,
          Longitude,
          TaxPercents,
          DeliveryAreaType,
          Timezone,
          Address,
          notSyncedData,
          OpeningHoursChanged: [],
          ClosedManuallyReason,
          PickupDisableReason,
          PickupDisableOption,
          DeliveryDisableReason,
          DeliveryEnableIn,
          PickupEnableIn,
          ClosedManuallyUntilTime,
          StoreDisableOption,
          DeliveryDisableOption,
          // TODO: Dirty hack duplicate to avoid address validation error on form load
          FullAddress: Address,
          PlaceId: '',
          City: Address,
          StreetNumber: Address,
          AlcoholTax: parseFloat(AlcoholTax),
          MailForWeeklyReport: MailForWeeklyReport
            ? MailForWeeklyReport.replace(/;/g, ', ')
            : '',
          StructuredAddress
          // MailForHumanResponseMessage
        };

  const getOppositeServiceValue = (currentType) => {
    return currentType === 'Pickup' ? 'Delivery' : 'Pickup';
  };

  const onServiceChange = (type, values) => {
    if (
      !values.DeliveryOptions[getOppositeServiceValue(type)] &&
      values.DeliveryOptions[type]
    ) {
      toast.error(
        'You cannot disable both options: Pickup & Sauce Delivery. One of the options should be enabled!'
      );
      return;
    }

    if (!values.DeliveryOptions[type]) {
      dispatch(
        OPEN_CONFIRMATION_MODAL({
          open: true,
          entityName: type
        })
      );
    } else {
      dispatch(
        OPEN_DISABLE_MODAL({
          open: true,
          entityName: type
        })
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  const initialValues = initValues();

  const shouldAllowFormReinitialize = diff(
    initialValues,
    formikRef?.current?.values
  );

  const closeChangeAddressConfirmationModal = () => {
    dispatch(OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL({open: false}));
  };

  return (
    <Formik
      enableReinitialize={
        !Object.keys(shouldAllowFormReinitialize || {}).length
      }
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={StoreSettingsSchema}
      onSubmit={(fields, {setFieldError, setFieldValue, resetForm}) => {
        setFormSubmitting(true);

        if (
          isLocationNameUnique(fields.FullName, merchantLocations, FullName)
        ) {
          setFormSubmitting(false);
          setFieldError(
            'FullName',
            'Location name must be unique across merchant!'
          );
          return;
        }

        const {
          City,
          Country,
          FullAddress,
          Location,
          State,
          ZipCode,
          OpeningHoursChanged,
          StreetNumber,
          ...usefulFormData
        } = fields;

        const hours = openingDaysServerParser(openingDays);

        usefulFormData.Phone = Phone.includes('+1')
          ? `${usefulFormData.CountryCode || '+1'}${fields.Phone}`
          : fields?.Phone;

        const formData = {
          ...usefulFormData,
          //[Rounding
          Latitude: parseFloat(Number(usefulFormData.Latitude).toFixed(7)) || 0,
          Longitude:
            parseFloat(Number(usefulFormData.Longitude).toFixed(7)) || 0,
          //]
          PickupEtaMinutes: parseFloat(usefulFormData.PickupEtaMinutes),
          EtaMinutes: parseFloat(usefulFormData.EtaMinutes),
          AlcoholTax: parseFloat(usefulFormData.AlcoholTax) || 0,
          OpeningHours: hours,
          MailForWeeklyReport:
            usefulFormData?.MailForWeeklyReport?.trim()
              .replace(/,/g, ';')
              .split(' ')
              .join('') || null
        };

        // if (usefulFormData?.ClosedManuallyUntilTime) {
        //   formData.ClosedManuallyUntilTime = changeTimeToFitWorkingHours(
        //     usefulFormData?.ClosedManuallyUntilTime,
        //     hours
        //   );
        //
        //   // The next string is for prevent reset time in UI to fields.PickupEnableIn after submitting,
        //   // so user can see correct time when feature will be enabled
        //   fields.ClosedManuallyUntilTime = formData.ClosedManuallyUntilTime;
        // }

        if (usefulFormData?.PickupEnableIn) {
          formData.PickupEnableIn =
            PickupDisableOption === 'Until Next Opening Time'
              ? refreshClosingTime(
                  'PickupEnableIn',
                  fields?.Timezone,
                  hours,
                  setFieldValue
                )()
              : usefulFormData.PickupEnableIn;

          // The next string is for prevent reset time in UI to fields.PickupEnableIn after submitting,
          // so user can see correct time when feature will be enabled
          fields.PickupEnableIn = formData.PickupEnableIn;
        }

        if (usefulFormData?.DeliveryEnableIn) {
          formData.DeliveryEnableIn =
            DeliveryDisableOption === 'Until Next Opening Time'
              ? refreshClosingTime(
                  'DeliveryEnableIn',
                  fields?.Timezone,
                  hours,
                  setFieldValue
                )()
              : usefulFormData.DeliveryEnableIn;

          // The next string is for prevent reset time in UI to fields.DeliveryEnableIn after submitting,
          // so user can see correct time when feature will be enabled
          fields.DeliveryEnableIn = formData.DeliveryEnableIn;
        }

        if (isOpeningHoursChange(OpeningHours, hours)) {
          formData.DeliveryHoursSuchAsOpeningHours = true;
        }

        if (hours.length) {
          if (validateEtaDuration(hours, usefulFormData?.EtaMinutes)) {
            setFormSubmitting(false);
            setFieldError(
              'EtaMinutes',
              'ETA cannot be greater than (or equal) duration of working hours! Please change ETA or restaurant working hours.'
            );

            return;
          }

          if (isCreating) {
            dispatch(
              POST_LOCATION_SETTINGS_DATA({
                payload: formData,
                successCallback: () => setFormSubmitting(false)
              })
            );
            setIsCreating(false);
          } else {
            let dataToPost = diff(initialValues, formData);
            if (dataToPost.StructuredAddress) {
              dataToPost.StructuredAddress = {
                ...formData.StructuredAddress,
                Latitude:
                  parseFloat(
                    Number(formData.StructuredAddress.Latitude).toFixed(7)
                  ) || 0,
                Longitude:
                  parseFloat(
                    Number(formData.StructuredAddress.Longitude).toFixed(7)
                  ) || 0
              };
            }
            // Reset Arrays by full values (don't need a diff format for now)
            Object.keys(formData).forEach((key) => {
              if (Array.isArray(formData[key]) && !!dataToPost[key]) {
                dataToPost[key] = formData[key];
              }
            });

            dispatch(
              UPDATE_LOCATION_SETTINGS_DATA({
                payload: dataToPost,
                successCallback: () => {
                  setFormSubmitting(false);
                  resetForm({values: fields});
                  publishLocation();
                  if (fields.hasOwnProperty('storeHolidays')) {
                    setFieldValue('storeHolidays', undefined);
                  }
                },
                errorCallback: () => {
                  setFormSubmitting(false);
                  resetForm({values: fields});
                  dispatch(STOP_GET_PUBLISH_STATUS_JOB());
                  dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
                }
              })
            );
          }
        }
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        dirty,
        errors,
        touched,
        handleBlur,
        setErrors,
        submitForm,
        resetForm
      }) => {
        return (
          <DashboardFormWrapper>
            <Form id='store-settings-form'>
              <ConnectedFocusError />
              <DashboardStoreToggleSection>
                <DashboardStoreToggleWrapper>
                  <div>
                    <SwitchS2E
                      name='ClosedManually'
                      checked={!ClosedManually}
                      label={`Store ${ClosedManually ? 'Disabled' : 'Enabled'}`}
                      labelPlacement='start'
                      onChange={() => {
                        if (ClosedManually) {
                          dispatch(
                            OPEN_CONFIRMATION_MODAL({
                              open: true,
                              entityName: 'Store'
                            })
                          );
                        } else {
                          dispatch(
                            OPEN_DISABLE_MODAL({
                              open: true,
                              entityName: 'Store'
                            })
                          );
                        }
                      }}
                    />
                    {/*<ErrorSign>*/}
                    {/*  {values?.ClosedManually === true &&*/}
                    {/*    getServiceDisabledHint(*/}
                    {/*      values?.ClosedManuallyUntilTime,*/}
                    {/*      'Closed',*/}
                    {/*      data*/}
                    {/*    )}*/}
                    {/*</ErrorSign>*/}
                  </div>
                  <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                    <div className='DropInfo DropInfoCenter disabled'>
                      Pay attention that disabling the store will fully disable
                      the store and prevent the users from making orders.
                    </div>
                  </ButtonInfo>
                </DashboardStoreToggleWrapper>
                <DashboardDisableStore className={!ClosedManually && 'hide'}>
                  <DashboardFields>
                    <DashboardField className='w100 dFlex'>
                      <DashboardFieldLabel>
                        <FieldLabel className='FieldLabelLg'>
                          Closed Message
                        </FieldLabel>
                      </DashboardFieldLabel>
                      <DashboardFieldInner className='w60'>
                        <Textarea
                          style={{resize: 'none'}}
                          className='ClosedMessage'
                          value={values.CustomErrorMessage}
                          onChange={(event) =>
                            setFieldValue(
                              'CustomErrorMessage',
                              maxLenFormatter(
                                event.target.value,
                                values.CustomErrorMessage,
                                150
                              )
                            )
                          }
                          name='CustomErrorMessage'
                          placeholder='Closed Message'
                        />
                      </DashboardFieldInner>
                    </DashboardField>
                  </DashboardFields>
                </DashboardDisableStore>
              </DashboardStoreToggleSection>
              <DashboardDisableStore className={ClosedManually && 'disabled'}>
                <DashboardHolder className='LocationForm'>
                  <DashboardFields>
                    <DashboardField className='w100 dFlex'>
                      <DashboardFieldLabel>
                        <FieldLabel className='FieldLabelLg'>
                          Location Name
                        </FieldLabel>
                      </DashboardFieldLabel>

                      {/* <RouteLeavingGuard */}
                      {/* when={dirty || lastUpdatedForm > lastBotSync} */}
                      {/* titleText={ */}
                      {/*   dirty */}
                      {/*     ? 'Close without saving?' */}
                      {/*     : 'Close without publishing?' */}
                      {/* }*/}
                      {/* contextText={ */}
                      {/*   dirty */}
                      {/*     ? 'You have unsaved changes. Are you sure you want to leave this page without saving?' */}
                      {/*     : 'You have unpublished changes. Are you sure you want to leave now?' */}
                      {/* }*/}
                      {/* navigate={(path) => { */}
                      {/*   history.push(path); */}
                      {/*   if (dirty) { */}
                      {/*     sendGtmEvent('storeSettingsUnsavedChanges', { */}
                      {/*       unsaved: true */}
                      {/*     });*/}
                      {/*   } else {*/}
                      {/*     sendGtmEvent('storeSettingsUnpublishedChanges', { */}
                      {/*       unPublished: true */}
                      {/*     }); */}
                      {/*   } */}
                      {/* }} */}
                      {/* /> */}

                      <LocationConfirmationModal
                        setFieldValue={setFieldValue}
                        values={values}
                        closedManually={ClosedManually}
                        dispatch={dispatch}
                        resetForm={resetForm}
                        setFormSubmitting={setFormSubmitting}
                        disableButton={disableButton}
                      />
                      <LocationDisableModal
                        setGlobalFieldValue={setFieldValue}
                        openingHours={OpeningHours}
                        showCancelHolidaysModal={showCancelHolidaysModal}
                        setShowCancelHolidaysModal={setShowCancelHolidaysModal}
                        setHideBannerHolidays={setHideBannerHolidays}
                        data={data}
                        setValuesHolidays={setValuesHolidays}
                        setFormSubmitting={setFormSubmitting}
                        locationValues={values}
                        dispatch={dispatch}
                        disableButton={disableButton}
                      />
                      <ChangeAddressConfirmationModal
                        openModal={isChangeAddressModalOpen}
                        isCloseConfirmModal={
                          closeChangeAddressConfirmationModal
                        }
                        fullAddress={values.FullAddress}
                        initValuesAddress={initialValues.FullAddress}
                        checkActiveOrders={!!activeOrders?.length}
                      />
                      <DashboardFieldInner className='w60'>
                        <FieldInput
                          type='text'
                          value={values.FullName}
                          onChange={(e) => {
                            const formattedString = e.target.value.replace(
                              /[!@#$%^&+*]/g,
                              ''
                            );
                            setFieldValue('FullName', formattedString);
                          }}
                          name='FullName'
                          placeholder='Location Name'
                          className={errors.FullName && 'error'}
                        />
                        <ErrorSignWrapper leftPosition='211px'>
                          <ErrorSign>
                            {errors.FullName && errors.FullName}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardFieldInner>
                    </DashboardField>
                  </DashboardFields>
                </DashboardHolder>
                {!hideBannerHolidays &&
                  data?.StoreHolidays?.disableStoreTime &&
                  data?.StoreHolidays?.enableStoreTime && (
                    <StoreDisabledBanner>
                      <div className='StoreDisabledBannerRside'>
                        <div className='StoreDisabledBannerText'>
                          The store will not receive new orders between{' '}
                          {moment(data?.StoreHolidays?.disableStoreTime)
                            .utc()
                            .format('MMMM Do YYYY [at] h:mm A')}{' '}
                          until{' '}
                          {moment(data?.StoreHolidays?.enableStoreTime)
                            .utc()
                            .format('MMMM Do YYYY [at] h:mm A')}
                        </div>
                        {data?.StoreHolidays?.disableStoreTime &&
                          data?.StoreHolidays?.enableStoreTime && (
                            <DashboardText className='bottomText'>
                              <DialogLinkButton
                                onClick={() => setShowCancelHolidaysModal(true)}
                                className='Sm LinkButton'
                              >
                                Click here to cancel
                              </DialogLinkButton>{' '}
                              the future dates limitation and{' '}
                              <strong>receive new orders now.</strong>
                            </DashboardText>
                          )}
                      </div>
                    </StoreDisabledBanner>
                  )}
                <DashboardHolder>
                  <DashboardTitle className='WithLine mBottom titleMd'>
                    Services Enablement
                  </DashboardTitle>
                  <DashboardFields>
                    <DashboardField className='w100 dFlex AlignStart'>
                      <DashboardFieldLabel className='marginTopMd'>
                        <div>
                          <SwitchS2E
                            onChange={() => {
                              onServiceChange('Pickup', values);
                              setFieldValue('DeliveryOptions.Curbside', false);
                            }}
                            name='DeliveryOptions.Pickup'
                            checked={values.DeliveryOptions.Pickup}
                            label='Pickup'
                            labelPlacement='start'
                            isDisabled={
                              !checkIfSauceEmployee() && !InhouseDelivery
                            }
                          />
                          <ErrorSign>
                            {values?.DeliveryOptions?.Pickup === false &&
                              getServiceDisabledHint(values?.PickupEnableIn)}
                          </ErrorSign>
                        </div>
                      </DashboardFieldLabel>
                      <DashboardFieldInner
                        className={
                          !values.DeliveryOptions.Pickup
                            ? 'disabled dFlex flexColumn'
                            : 'dFlex flexAuto'
                        }
                      >
                        <DashboardFields>
                          <DashboardField className='w100 dFlex'>
                            <DashboardText className='bold mRight20 mobMinRight mobFixWidth'>
                              Food Preparation Time
                            </DashboardText>
                            <FieldInput
                              disabled={!values.DeliveryOptions.Pickup}
                              className={cn('FieldNumber', {
                                error: errors?.PickupEtaMinutes
                              })}
                              type='text'
                              value={values.PickupEtaMinutes}
                              onChange={(event) =>
                                setFieldValue(
                                  'PickupEtaMinutes',
                                  etaFormatter(event.target.value)
                                )
                              }
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              // onBlur={(event) => {
                              //   !Number(event.target.value) &&
                              //     setFieldValue('PickupEtaMinutes', 1);
                              // }}
                              name='PickupEtaMinutes'
                              placeholder='Food Preparation Time'
                            />
                            <ErrorSignWrapper>
                              <ErrorSign>
                                {errors.PickupEtaMinutes &&
                                  errors.PickupEtaMinutes}
                              </ErrorSign>
                            </ErrorSignWrapper>
                            <DashboardText className='mLeft20 mobMinLeft'>
                              minutes
                            </DashboardText>
                            <ButtonInfo className='Outline ButtonInfoDrop'>
                              <div className='DropInfo DropInfoCenter disabled DropInfoRightMob'>
                                The average time required to prepare the food.
                              </div>
                            </ButtonInfo>
                          </DashboardField>

                          <DashboardField className='w100'>
                            <CheckboxS2E
                              onChange={(e) => {
                                const {checked} = e.target;
                                setFieldValue(
                                  'DeliveryOptions.Curbside',
                                  checked
                                );
                              }}
                              name={'DeliveryOptions.Curbside'}
                              checked={
                                values.DeliveryOptions.Curbside &&
                                values.DeliveryOptions.Pickup
                              }
                              label={'Curbside Pickup'}
                            />
                          </DashboardField>
                          {values.DeliveryOptions.Curbside &&
                            values.DeliveryOptions.Pickup && (
                              <>
                                <DashboardField className='flexColumn AlignStart w30Plus'>
                                  <FieldLabel>Curbside Instructions</FieldLabel>
                                  <FieldInput
                                    type='text'
                                    value={
                                      values.DeliveryOptions
                                        ?.CurbsideInstructions
                                    }
                                    onChange={handleChange}
                                    name='DeliveryOptions.CurbsideInstructions'
                                    placeholder='Please send us your car model and color'
                                    maxLength='40'
                                  />
                                </DashboardField>

                                <DashboardField className='flexColumn AlignStart w30Plus'>
                                  <FieldLabel>
                                    Curbside Additional Information
                                  </FieldLabel>
                                  <FieldInput
                                    type='text'
                                    value={
                                      values.DeliveryOptions
                                        ?.CurbsideAdditionalInformation
                                    }
                                    onChange={handleChange}
                                    name='DeliveryOptions.CurbsideAdditionalInformation'
                                    placeholder='Car: '
                                    maxLength='40'
                                  />
                                </DashboardField>
                              </>
                            )}
                        </DashboardFields>
                      </DashboardFieldInner>
                    </DashboardField>

                    <DashboardField className='w100 dFlex mTop'>
                      <DashboardFieldLabel>
                        <div>
                          <SwitchS2E
                            onChange={() => onServiceChange('Delivery', values)}
                            name='DeliveryOptions.Delivery'
                            checked={values.DeliveryOptions.Delivery}
                            label='Delivery'
                            labelPlacement='start'
                            isDisabled={
                              !checkIfSauceEmployee() && !InhouseDelivery
                            }
                          />
                          <ErrorSign>
                            {values?.DeliveryOptions?.Delivery === false &&
                              getServiceDisabledHint(values?.DeliveryEnableIn)}
                          </ErrorSign>
                        </div>
                      </DashboardFieldLabel>
                      <DashboardFieldInner
                        className={
                          !values.DeliveryOptions.Delivery
                            ? 'disabled w60 dFlex'
                            : 'w60 dFlex'
                        }
                      >
                        <RadioGroup
                          aria-label='Delivery'
                          name='checkedDiscount'
                        >
                          <DashboardFields>
                            <FlexContainer>
                              <FlexItem>
                                <DashboardField className='w100 dFlex opacity04'>
                                  <RadioS2E
                                    type='radio'
                                    label='Sauce Dispatch'
                                    name='Say2eatDispatch'
                                    checked={
                                      values.Say2eatDispatch &&
                                      !values.InhouseDelivery &&
                                      values.DeliveryOptions.Delivery
                                    }
                                    value={values.Say2eatDispatch}
                                    onChange={() => {
                                      setFieldValue('Say2eatDispatch', true);
                                      setFieldValue('InhouseDelivery', false);
                                      setFieldValue('EtaMinutes', 60);
                                    }}
                                  />
                                  <DashboardText className='mLeft20'>
                                    Dynamic ETA
                                  </DashboardText>
                                  <ButtonInfo className='Outline ButtonInfoDrop'>
                                    <div className='DropInfo DropInfoCenter DropInfoRightMob'>
                                      With Sauce dispatch delivery method, the
                                      ETA will be dynamically calculated based
                                      on distance and driver availability.
                                    </div>
                                  </ButtonInfo>
                                </DashboardField>
                                <DashboardField className='w100 dFlex'>
                                  <RadioS2E
                                    type='radio'
                                    value={values.InhouseDelivery}
                                    label='Inhouse Delivery'
                                    name='InhouseDelivery'
                                    className='opacity04'
                                    checked={
                                      values.InhouseDelivery &&
                                      !values.Say2eatDispatch &&
                                      values.DeliveryOptions.Delivery
                                    }
                                    onChange={() => {
                                      setFieldValue('InhouseDelivery', true);
                                      setFieldValue('Say2eatDispatch', false);
                                    }}
                                  />
                                  <DashboardField
                                    className={cn('dFlex', {
                                      opacity04: !values.InhouseDelivery,
                                      opacity1: values.InhouseDelivery
                                    })}
                                  >
                                    <DashboardText className='bold mRight20 '>
                                      ETA
                                    </DashboardText>

                                    <FieldInput
                                      className={cn('FieldNumber', {
                                        error:
                                          errors.EtaMinutes &&
                                          values.InhouseDelivery,
                                        isDisabled: !values.InhouseDelivery
                                      })}
                                      disabled={!values.InhouseDelivery}
                                      type='text'
                                      value={values.EtaMinutes}
                                      onChange={(event) =>
                                        setFieldValue(
                                          'EtaMinutes',
                                          etaFormatter(
                                            event.target.value,
                                            values.PickupEtaMinutes
                                          )
                                        )
                                      }
                                      onPaste={(e) => {
                                        e.preventDefault();
                                        return false;
                                      }}
                                      onBlur={(event) => {
                                        !Number(event.target.value) &&
                                          setFieldValue('EtaMinutes', 1);
                                      }}
                                      name='EtaMinutes'
                                      placeholder='ETA'
                                    />
                                    <ErrorSignWrapper leftPosition='54px'>
                                      <ErrorSign>
                                        {errors.EtaMinutes &&
                                          values.InhouseDelivery &&
                                          errors.EtaMinutes}
                                      </ErrorSign>
                                    </ErrorSignWrapper>
                                    <DashboardText className='mLeft20'>
                                      minutes
                                    </DashboardText>
                                    <ButtonInfo className='Outline ButtonInfoDrop'>
                                      <div className='DropInfo DropInfoCenter DropInfoRightMob'>
                                        The average time required to deliver the
                                        food is based on your delivery service.
                                      </div>
                                    </ButtonInfo>
                                  </DashboardField>
                                </DashboardField>
                              </FlexItem>
                            </FlexContainer>
                          </DashboardFields>
                        </RadioGroup>
                      </DashboardFieldInner>
                    </DashboardField>
                  </DashboardFields>
                </DashboardHolder>
                <DashboardHolder>
                  <DashboardTitle className='WithLine mBottom titleMd'>
                    General Settings
                  </DashboardTitle>
                  <DashboardFields>
                    <DashboardField className='w100 dFlex'>
                      <DashboardFieldLabel className='DashboardFieldLabelCenter'>
                        <FieldLabel className='FieldLabelLg'>
                          Location phone number
                        </FieldLabel>
                      </DashboardFieldLabel>
                      <DashboardFieldInner className='w100Auto'>
                        <DashboardFields className='noWrap'>
                          <DashboardField className='FieldNumber'>
                            <FieldLabel>
                              Country Code <span>*</span>
                            </FieldLabel>
                            <FieldInput
                              onBlur={handleBlur}
                              disabled
                              value={
                                values.CountryCode === '+'
                                  ? '+1'
                                  : values.CountryCode
                              }
                              onChange={(e) => {
                                const code = countryCodeFormatter(
                                  e.target.value
                                );
                                setFieldValue('CountryCode', code);
                              }}
                              name='CountryCode'
                              placeholder='+1'
                              className={cn({
                                error:
                                  errors?.CountryCode && touched?.CountryCode
                              })}
                            />
                            <ErrorSignWrapper>
                              {errors?.CountryCode && touched?.CountryCode ? (
                                <ErrorSign>{errors?.CountryCode}</ErrorSign>
                              ) : null}
                            </ErrorSignWrapper>
                          </DashboardField>
                          <DashboardField className='wAutoSpace'>
                            <FieldLabel>
                              Your phone number <span>*</span>
                            </FieldLabel>
                            <FieldMaskInput
                              mask='(999) 999-9999'
                              value={values.Phone}
                              onChange={(e) => {
                                const code = phoneNumberFormatter(
                                  e.target.value
                                );
                                setFieldValue('Phone', code);
                              }}
                              name='Phone'
                              placeholder='Your phone number'
                              className={cn({
                                error: errors?.Phone && touched?.Phone
                              })}
                              onBlur={(e) => {
                                const regExp = /^\([\d]{3}\) [\d]{3}-[\d]{1,}$/g;
                                if (!regExp.test(e.target.value)) {
                                  setFieldValue('Phone', '');
                                  setErrors({
                                    ...errors,
                                    Phone: 'Incorrect phone format'
                                  });
                                }
                                handleBlur(e);
                              }}
                            />
                            <ErrorSignWrapper leftPosition='12px'>
                              {errors?.Phone && touched?.Phone ? (
                                <ErrorSign>{errors?.Phone}</ErrorSign>
                              ) : null}
                            </ErrorSignWrapper>
                          </DashboardField>
                        </DashboardFields>
                        <DashboardInnerDescription className='DashboardInnerDescription'>
                          Will be presented to your customers
                        </DashboardInnerDescription>
                      </DashboardFieldInner>
                    </DashboardField>

                    <DashboardField className='w100 dFlex'>
                      <DashboardFieldLabel>
                        <FieldLabel className='FieldLabelLg'>
                          Mail For Weekly Report
                        </FieldLabel>
                      </DashboardFieldLabel>
                      <DashboardFieldInner className='w60FLex'>
                        <FieldInput
                          value={values.MailForWeeklyReport}
                          onChange={(e) => {
                            const {
                              target: {value}
                            } = e;
                            if (value === '') {
                              setFieldValue('MailForWeeklyReport', null);
                              return;
                            }
                            handleChange(e);
                          }}
                          name='MailForWeeklyReport'
                          onBlur={handleBlur}
                          placeholder='Mail For Weekly Report'
                          className={cn({
                            error:
                              errors?.MailForWeeklyReport &&
                              touched?.MailForWeeklyReport
                          })}
                        />
                        <ErrorSignWrapper>
                          {errors?.MailForWeeklyReport &&
                          touched?.MailForWeeklyReport ? (
                            <ErrorSign>
                              {errors?.MailForWeeklyReport || 'Empty'}
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DashboardFieldInner>
                      <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                        <div className='DropInfo DropInfoRight DropInfoRightMob'>
                          You can enter more than one email separated by commas.
                          For example: sauce1@test.com, sauce2@test.com
                        </div>
                      </ButtonInfo>
                    </DashboardField>
                    {/* <DashboardField className='w100 dFlex'>
                      <DashboardFieldLabel>
                        <FieldLabel className='FieldLabelLg'>
                          Mail For Human Response Message
                        </FieldLabel>
                      </DashboardFieldLabel>
                      <DashboardFieldInner className='w60FLex'>
                        <FieldInput
                          value={values.MailForHumanResponseMessage}
                          onChange={(e) => {
                            const {
                              target: {value}
                            } = e;
                            if (value === '') {
                              setFieldValue(
                                'MailForHumanResponseMessage',
                                null
                              );
                              return;
                            }
                            handleChange(e);
                          }}
                          name='MailForHumanResponseMessage'
                          onBlur={handleBlur}
                          placeholder='Mail For Human Response Message'
                          className={cn({
                            error:
                              errors?.MailForHumanResponseMessage &&
                              touched?.MailForHumanResponseMessage
                          })}
                        />
                        <ErrorSignWrapper leftPosition='210px'>
                          {errors?.MailForHumanResponseMessage &&
                          touched?.MailForHumanResponseMessage ? (
                            <ErrorSign>
                              {errors?.MailForHumanResponseMessage || 'Empty'}
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DashboardFieldInner>
                    </DashboardField> */}
                  </DashboardFields>
                </DashboardHolder>

                <div style={{position: 'relative'}}>
                  <ErrorSignWrapper
                    topPosition='22px'
                    leftPosition='1px'
                    zIndex='2'
                  >
                    <ErrorSign>
                      {errors.EtaMinutes &&
                        !values.InhouseDelivery &&
                        errors.EtaMinutes}
                    </ErrorSign>
                  </ErrorSignWrapper>
                </div>

                <DaysPickerContainer WorkingDays={WorkingDays} />

                <DashboardHolder>
                  <DashboardTitle className='WithLine mBottom titleMd'>
                    Tax
                  </DashboardTitle>
                  <DashboardFields>
                    <DashboardField className='w100'>
                      <FieldArray name='TaxPercents'>
                        {({remove, push}) => (
                          <>
                            {values.TaxPercents.length > 0 &&
                              values.TaxPercents.map((tax, index) => (
                                <FlexItem>
                                  <DashboardFieldInner className='w10'>
                                    <DashboardFields>
                                      <DashboardField
                                        key={`taxName-${index + 1}`}
                                      >
                                        <FieldLabel className='FW400 FieldLabelLg'>
                                          Tax Name
                                        </FieldLabel>
                                        <FieldInput
                                          className={cn('FieldTaxName', {
                                            error:
                                              errors.TaxPercents &&
                                              errors?.TaxPercents[index]?.Name
                                          })}
                                          type='text'
                                          value={tax.Name}
                                          name={`TaxPercents[${index}].Name`}
                                          onChange={handleChange}
                                          placeholder='Enter tax name'
                                        />
                                        <ErrorSignWrapper>
                                          <ErrorSign>
                                            {errors.TaxPercents &&
                                              errors?.TaxPercents[index]
                                                ?.Name &&
                                              errors?.TaxPercents[index]?.Name}
                                          </ErrorSign>
                                        </ErrorSignWrapper>
                                      </DashboardField>
                                      <DashboardField
                                        key={`taxValue-${index + 1}`}
                                      >
                                        <FieldLabel className='FW400 FieldLabelLg'>
                                          Tax Rate %
                                        </FieldLabel>
                                        <FieldInput
                                          className={cn('FieldTaxName', {
                                            error:
                                              errors.TaxPercents &&
                                              errors?.TaxPercents[index]?.Value
                                          })}
                                          type='text'
                                          value={tax.Value}
                                          name={`TaxPercents[${index}].Value`}
                                          onChange={(event) => {
                                            setFieldValue(
                                              `TaxPercents.${index}.Value`,
                                              taxFormatter(
                                                event.target.value,
                                                tax.Value
                                              )
                                            );
                                          }}
                                          placeholder='Tax Rate %'
                                        />
                                        <ErrorSignWrapper>
                                          <ErrorSign>
                                            {errors.TaxPercents &&
                                              errors?.TaxPercents[index]
                                                ?.Value &&
                                              errors?.TaxPercents[index]?.Value}
                                          </ErrorSign>
                                        </ErrorSignWrapper>
                                        {values.TaxPercents.length > 1 && (
                                          <DashboardTimeInner className='DashboardTimeDelete pRight'>
                                            <DashboardTimeDelete
                                              type='button'
                                              onClick={() => remove(index)}
                                            />
                                          </DashboardTimeInner>
                                        )}
                                      </DashboardField>
                                    </DashboardFields>
                                  </DashboardFieldInner>
                                </FlexItem>
                              ))}
                            {values.TaxPercents.length < 5 && (
                              <DashboardField className='dFlex justifyCenter'>
                                <DialogLinkButton
                                  className={computedAddTaxButtonClasses(
                                    values.TaxPercents
                                  )}
                                  onClick={() => push({Name: '', Value: ''})}
                                  type='button'
                                >
                                  Add Another Tax
                                </DialogLinkButton>
                              </DashboardField>
                            )}
                          </>
                        )}
                      </FieldArray>
                    </DashboardField>
                    <DashboardField className='w100 dFlex'>
                      <FieldLabel className='FieldLabelLg marginRightSm'>
                        Alcohol Tax
                      </FieldLabel>
                      <FieldInput
                        className='FieldNumber'
                        type='text'
                        name='AlcoholTax'
                        value={values.AlcoholTax}
                        onChange={(event) => {
                          setFieldValue(
                            'AlcoholTax',
                            taxFormatter(event.target.value, values.AlcoholTax)
                          );
                        }}
                        placeholder='Tax %'
                      />
                      <FieldLabel className='FieldLabelLg FW400 marginLeftXs'>
                        %
                      </FieldLabel>
                    </DashboardField>
                  </DashboardFields>
                </DashboardHolder>
                <DashboardHolder>
                  <DashboardTitle className='WithLine mBottom titleMd'>
                    Address
                  </DashboardTitle>
                  <DashboardFields>
                    <DashboardField className='w60Plus withClear'>
                      <AutocompletedMapField
                        type='text'
                        value={values.Address}
                        onChange={handleChange}
                        name='Address'
                        fieldToClear='FullAddress'
                        placeholder='Address'
                        setFieldValue={setFieldValue}
                        map={map}
                        mapMarker={mapMarker}
                        className={cn('AutoComplete', {
                          error: errors.Address || errors.StreetNumber
                        })}
                        structuredAddress={values.StructuredAddress}
                      />
                      {!!values.Address && (
                        <ButtonClear
                          onClick={() => {
                            setFieldValue('Location', {});
                            setFieldValue('FullAddress', '');
                            setFieldValue('Address', '');
                            setFieldValue('Country', '');
                            setFieldValue('State', '');
                            setFieldValue('PlaceId', '');
                            setFieldValue('City', '');
                            setFieldValue('Street', '');
                            setFieldValue('ZipCode', '');
                            setFieldValue('StreetNumber', '');
                          }}
                        />
                      )}
                      <ErrorSignWrapper>
                        <ErrorSign>
                          {errors.Address
                            ? errors.Address
                            : errors.StreetNumber && errors.StreetNumber}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    </DashboardField>

                    <DashboardField className='w60Plus'>
                      <LocationMap
                        center={mapProps.center}
                        zoom={mapProps.zoom}
                        values={values}
                        setFieldValue={setFieldValue}
                        setMap={setMap}
                        map={map}
                        mapMarker={mapMarker}
                        setMapMarker={setMapMarker}
                        isCreating={isCreating}
                      />
                    </DashboardField>
                  </DashboardFields>
                </DashboardHolder>
                <DashboardHolder>
                  <DashboardTitle className='WithLine mBottom titleMd'>
                    Store logo
                  </DashboardTitle>
                  <DashboardFields>
                    <DashboardField className='w60Plus'>
                      <FileUploader
                        name='WelcomeLogoURL'
                        className={cn('width100 UploadPhotoFile', {
                          error: errors.WelcomeLogoURL
                        })}
                        url={values.WelcomeLogoURL}
                        onSetFile={(url) =>
                          setFieldValue('WelcomeLogoURL', url)
                        }
                        allowedFileFormats={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg'
                        ]}
                      />
                    </DashboardField>
                    <ErrorSignWrapper className='left0'>
                      <ErrorSign>{errors.WelcomeLogoURL}</ErrorSign>
                    </ErrorSignWrapper>
                  </DashboardFields>
                </DashboardHolder>
              </DashboardDisableStore>
              <LocationFormActions
                className={cn('LocationFormActions', {
                  PublishOverlay: messageUnPublish
                })}
              >
                <div className='LocationFormSave'>
                  <ButtonPrimary
                    type='submit'
                    className={cn('saveChanges ButtonSave ButtonMedium', {
                      disabled:
                        disableButton ||
                        (formSubmitting
                          ? true
                          : showUnPublishBannerType ===
                            UNPUBLISH_BANNER_SETTINGS_TYPE
                          ? false
                          : !dirty)
                    })}
                  >
                    <SaveButton />
                    <span>Save</span>
                  </ButtonPrimary>
                  <RouteLeavingGuardNew
                    iconMessage={<IconSaveDisk />}
                    messageUnPublish={messageUnPublish}
                    setMessageUnPublish={setMessageUnPublish}
                    confirmedNavigation={confirmedNavigation}
                    when={dirty}
                    titleText={
                      'Are you sure you want to leave this page without saving?'
                    }
                    buttonCancel={
                      <ButtonBold
                        className='Grey'
                        onClick={closePublishMessage}
                      >
                        Leave
                      </ButtonBold>
                    }
                    buttonConfirm={
                      <ButtonBold
                        onClick={async () => {
                          setMessageUnPublish(false);
                          await submitForm();
                        }}
                      >
                        Save
                      </ButtonBold>
                    }
                    navigate={(path) => {
                      if (dirty) {
                        sendGtmEvent('storeSettingsUnsavedChanges', {
                          unsaved: true
                        });
                      }
                      history.push(path);
                    }}
                  />
                </div>
                {/*<LocationPublishButton isSubmitting={formSubmitting} />*/}
              </LocationFormActions>
              <ButtonSaveScroll
                type='submit'
                className={cn({
                  disabled:
                    disableButton ||
                    (formSubmitting
                      ? true
                      : showUnPublishBannerType ===
                        UNPUBLISH_BANNER_SETTINGS_TYPE
                      ? false
                      : !dirty)
                })}
              />
            </Form>
          </DashboardFormWrapper>
        );
      }}
    </Formik>
  );
};

LocationForm.propTypes = {
  isCreating: T.bool.isRequired,
  setIsCreating: T.func.isRequired,
  setConfirmedNavigation: T.func.isRequired
};

LocationForm.defaultProps = {};

export default memo(LocationForm);
