import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {Caption2} from '../../../../../../../components/typography';
import RadioInputsWrapper from './styles';

const RoundingRadio = ({values, setFieldValue}) => {
  let roundingValueDisplayed;
  let roundingValueField;

  if (values?.data?.MenuShowUrlInput) {
    roundingValueDisplayed = values.data.roundingValueURL;
    roundingValueField = 'data.roundingValueURL';
  } else if (values?.data?.MenuEmail) {
    roundingValueDisplayed = values.data.roundingValueEmail;
    roundingValueField = 'data.roundingValueEmail';
  } else {
    roundingValueDisplayed = values.data.roundingValue;
    roundingValueField = 'data.roundingValue';
  }

  return (
    <RadioInputsWrapper>
      <Caption2 className='captionRadioPrices'>
        My Sauce menu prices should be rounded to (ie 5.00 or 4.99):
      </Caption2>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        value={roundingValueDisplayed}
        onChange={(event) => {
          setFieldValue(roundingValueField, event.target.value, false);
        }}
      >
        <FormControlLabel
          className='Say2EatRadio'
          value='To00'
          control={
            <Radio
              labelStyle={{color: '#172751'}}
              iconStyle={{fill: '#FF2D38'}}
              inputStyle={{color: '#FF2D38'}}
              style={{color: '#FF2D38'}}
            />
          }
          label='.00'
        />
        <FormControlLabel
          className='Say2EatRadio'
          value='To99'
          control={
            <Radio
              labelStyle={{color: '#172751'}}
              iconStyle={{fill: '#FF2D38'}}
              inputStyle={{color: '#FF2D38'}}
              style={{color: '#FF2D38'}}
            />
          }
          label='.99'
        />
      </RadioGroup>
    </RadioInputsWrapper>
  );
};

RoundingRadio.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default RoundingRadio;
