import {put, select, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_ERROR,
  CONFIRM_EMAIL_SUCCESS,
  CONNECT_GOOGLE_MY_BUSINESS,
  CONNECT_GOOGLE_MY_BUSINESS_REDIRECT,
  CONNECT_STRIPE_ACCOUNT,
  CONNECT_STRIPE_ACCOUNT_SUCCESS,
  GET_ACCOUNT_STEPS_STATUS,
  GET_ACCOUNT_STEPS_STATUS_SUCCESS,
  RESEND_CONFIRMATION,
  SET_WEBSITE_TEMPLATE,
  BOOK_A_CALL,
  BOOK_A_CALL_SUCCESS,
  BOOK_A_CALL_FAILED,
  SET_WEBSITE_TEMPLATE_SUCCESS,
  PUT_LOCATION_SETUP_DATA
} from './action';
import {
  confirmUserEmailApi,
  connectStripeApi,
  connectStripeSignUp2Api,
  getAccountStatusApi,
  getAccountStatusSignUp2Api,
  getGoogleAuthLink,
  postGoogleAuthCode,
  setWebsiteTemplateAPi,
  bookCallApi
} from '../../../helpers/services/api/account';
// eslint-disable-next-line import/no-cycle
import {getUserData} from '../user/saga';
import history from '../../../history';
import {request} from '../../../helpers/services/utils/request';
import {GET, PUT} from '../../../helpers/services/constants/API_CONSTANTS';
import {LOCATION_SETUP_DATA} from '../../../helpers/services/constants/API_ENDPOINTS';
import {SET_ACTIVE_LOCATION} from '../user/action';

const id = ({user}) => user.userData.id;
const userData = ({user}) => user.userData;
const merchantid = ({user}) => user.userData.merchant_id;
const getToken = ({authReducer}) => authReducer.token;
const getLocationId = ({user}) => user.locationId;
const getActiveLocation = ({user}) => user.activeLocation;

const handleGoogleAuthWindow = (url, dispatch) => {
  const w = 520;
  const h = 570;
  const left = window.screen.width / 2 - w / 2;
  const top = window.screen.height / 2 - h / 2;
  const strWindowFeatures = `location=yes,height=570,width=520,scrollbars=yes,status=yes,left=${left},top=${top}`;
  const googleWindow = window.open(url, '_blank', strWindowFeatures);
  const intervalId = setInterval(() => {
    if (googleWindow.closed) {
      dispatch(GET_ACCOUNT_STEPS_STATUS());
      clearInterval(intervalId);
    }
  }, 1000);
};

// eslint-disable-next-line consistent-return
export function* setWebsiteTemplate({payload}) {
  try {
    const token = yield select(getToken);
    const userID = yield select(id);
    const {status} = yield setWebsiteTemplateAPi(token, userID, payload);

    if (status === 201 || status === 204) {
      yield getUserData();
      yield history.push('/my-account');
      yield put(SET_WEBSITE_TEMPLATE_SUCCESS());
      toast.success('Template has been selected successfully!');
    }
    return true;
  } catch (error) {
    if (error.response.status === 405) {
      toast.error('You have already set website template!');
    }
    toast.error(`Error occurred - please try again [${error}]`);
    return false;
  }
}

export function* confirmUserEmail({payload: {email, callBack}}) {
  try {
    const userID = yield select(id);
    const {status} = yield confirmUserEmailApi(userID, email);
    if (status === 201 || status === 204 || status === 200) {
      yield put(CONFIRM_EMAIL_SUCCESS());
      if (callBack) {
        callBack();
      }
    }
    if (status === 405) {
      return;
      // yield put(CONFIRM_EMAIL_SUCCESS());
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(CONFIRM_EMAIL_ERROR());
  }
}

export function* getAccountStepsStatus() {
  try {
    const user = yield select(userData);
    let result;
    if (user.isSignUp2) {
      result = yield getAccountStatusSignUp2Api(user.id);
    } else {
      result = yield getAccountStatusApi(user.id, user.merchant_id);
    }
    if (result.data) {
      yield put(GET_ACCOUNT_STEPS_STATUS_SUCCESS(result.data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* connectGoogleMyBusiness({payload: dispatch}) {
  try {
    const {data} = yield getGoogleAuthLink();
    handleGoogleAuthWindow(data, dispatch);
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* connectGoogleMyBusinessRedirect({payload: {code}}) {
  try {
    const merchant_id = yield select(merchantid);
    yield postGoogleAuthCode(code, merchant_id);
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* connectStripeAccount() {
  try {
    const user = yield select(userData);
    if (user.isSignUp2) {
      yield connectStripeSignUp2Api(user.id);
    } else {
      yield connectStripeApi(user.id);
    }
    yield getUserData();
    yield put(CONNECT_STRIPE_ACCOUNT_SUCCESS());
    yield toast.success('Your Stripe account has been connected successfully!');
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* bookCall() {
  try {
    const user = yield select(userData);

    const {data} = yield bookCallApi(user.id);
    if (data) {
      yield put(BOOK_A_CALL_SUCCESS(data));
      toast.success('Call was booked!');
    }
  } catch (error) {
    yield put(BOOK_A_CALL_FAILED());
    console.log('SAGA ERROR', error);
  }
}

function* putLocationSetupData({payload}) {
  const locationId = yield select(getLocationId);
  const activeLocation = yield select(getActiveLocation);

  try {
    const data = yield request(PUT, LOCATION_SETUP_DATA(locationId), {
      data: {
        ...payload,
        ItTeamEmail:
          activeLocation.setupData?.ItTeamEmail === payload.ItTeamEmail
            ? undefined
            : payload.ItTeamEmail
      }
    });

    if (data) {
      toast.success('Data was saved successfully! Thank you! ');
      yield put(
        SET_ACTIVE_LOCATION({
          ...activeLocation,
          setupData: payload
        })
      );
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* accountSaga() {
  yield takeEvery(CONNECT_STRIPE_ACCOUNT, connectStripeAccount);
  yield takeEvery(SET_WEBSITE_TEMPLATE, setWebsiteTemplate);
  yield takeEvery(CONFIRM_EMAIL, confirmUserEmail);
  yield takeEvery(GET_ACCOUNT_STEPS_STATUS, getAccountStepsStatus);
  yield takeEvery(RESEND_CONFIRMATION, confirmUserEmail);
  yield takeEvery(CONNECT_GOOGLE_MY_BUSINESS, connectGoogleMyBusiness);
  yield takeEvery(
    CONNECT_GOOGLE_MY_BUSINESS_REDIRECT,
    connectGoogleMyBusinessRedirect
  );
  yield takeEvery(BOOK_A_CALL, bookCall);
  yield takeEvery(PUT_LOCATION_SETUP_DATA, putLocationSetupData);
}

export default accountSaga;
