import React, {useState} from 'react';
import T from 'prop-types';
import {toast} from 'react-toastify';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogActions from '../../../../../components/DialogActions/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import {Body0} from '../../../../../components/typography';
import {
  SET_FEEDBACK_STATUS,
  SET_FEEDBACK_STATUS_SUCCESS
} from '../../../../../store/global/user/action';

const DialogDisableSurvey = ({
  setIsOpenDisableModal,
  isOpenDisableModal,
  Title,
  Description
}) => {
  const dispatch = useDispatch();
  const [disableButton, setDisableButton] = useState(false);
  return (
    <Dialog
      open={isOpenDisableModal}
      scroll='body'
      onClose={() => setIsOpenDisableModal(false)}
    >
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => setIsOpenDisableModal(false)} />
        <DialogTitle>{Title}</DialogTitle>
        <DialogBody>
          <Body0 className='text-center'>{Description}</Body0>
          <DialogActions>
            <DialogAction>
              <ButtonPrimary
                className={cn('', {
                  disabled: disableButton
                })}
                onClick={() => {
                  setDisableButton(true);
                  dispatch(
                    SET_FEEDBACK_STATUS({
                      isSurveyActivated: false,
                      onSuccess: () => {
                        dispatch(SET_FEEDBACK_STATUS_SUCCESS(false));
                        setIsOpenDisableModal(false);
                        setDisableButton(false);
                        toast.success('Survey has been disabled successfully!');
                      },
                      onError: () => {
                        setDisableButton(false);
                        toast.error(
                          'Something went wrong! Please try again later!'
                        );
                      }
                    })
                  );
                }}
              >
                <span>Disable survey</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DialogDisableSurvey.propTypes = {
  setIsOpenDisableModal: T.func.isRequired,
  isOpenDisableModal: T.bool.isRequired,
  Title: T.string.isRequired,
  Description: T.string.isRequired
};

export default DialogDisableSurvey;
