import styled from 'styled-components';

const Subtitle4 = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #232d3a;
  span {
    color: #ff2d38;
  }
`;

export default Subtitle4;
