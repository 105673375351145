import React from 'react';

export const IconOrderVolume = () => (
  <svg
    width='23'
    height='26'
    viewBox='0 0 23 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.8978 7.17539L22.8771 7.13984C22.8513 7.08398 22.8204 7.0332 22.7843 6.98242L19.1066 0.73125C18.8435 0.279297 18.3535 0 17.8222 0H5.17968C4.6484 0 4.15838 0.279297 3.89016 0.73125L0.15569 7.10938C0.119583 7.16523 0.0989506 7.22617 0.0783182 7.28711C0.0267372 7.4293 -0.00421143 7.57656 -0.00421143 7.73398V24.2074C0.000946678 25.1977 0.815927 26 1.82176 26H21.1801C22.1859 26 23.0009 25.1977 23.0009 24.2074V7.67812C23.0009 7.65781 23.0009 7.63242 23.0009 7.61211C23.0061 7.46484 22.9751 7.3125 22.8978 7.17539ZM12.303 1.52344H17.7912L20.6798 6.44414H12.303V1.52344ZM5.21579 1.52344H10.7556V6.44414H2.33241L5.21579 1.52344ZM21.4535 24.2074C21.4535 24.3547 21.3297 24.4766 21.1801 24.4766H1.82176C1.67217 24.4766 1.54838 24.3547 1.54838 24.2074V7.96758H21.4535V24.2074Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconOrderVolume;
