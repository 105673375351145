import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  DialogAction,
  DialogBody,
  DialogCheckbox,
  DialogText
} from '../../dashboard/styles';
import {
  EDIT_CATEGORY_MODAL,
  EDIT_ITEM_MODAL,
  EDIT_MODIFIER_GROUP_MODAL,
  EDIT_MODIFIER_MODAL,
  EDIT_NOTE_MODAL
} from '../../../../store/menuEditorModals/action';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {setWarningNote} from '../../../../helpers/localStorage/MenuEditorNote';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';

const EditModalNote = () => {
  const dispatch = useDispatch();
  const {
    entityDuplicateInfo,
    modalNoteEntityName,
    entityId,
    oldEntityData,
    isEditNoteModalOpen
  } = useSelector((state) => state.menuEditorModalsReducer);
  const [checked, setChecked] = useState(false);
  const handleChangeCheckbox = () => {
    setChecked((prev) => !prev);
    setWarningNote('showEditNote', checked);
  };

  const submitAction = () => {
    if (modalNoteEntityName === 'category') {
      dispatch(
        EDIT_CATEGORY_MODAL({
          open: true,
          entityDuplicateInfo
        })
      );
    }

    if (modalNoteEntityName === 'item') {
      dispatch(
        EDIT_ITEM_MODAL({
          open: true,
          entityDuplicateInfo
        })
      );
    }

    if (modalNoteEntityName === 'modifier') {
      dispatch(
        EDIT_MODIFIER_MODAL({
          open: true,
          entityDuplicateInfo
        })
      );
    }

    if (modalNoteEntityName === 'modifierGroup') {
      dispatch(
        EDIT_MODIFIER_GROUP_MODAL({
          open: true
        })
      );
    }

    dispatch(
      EDIT_NOTE_MODAL({
        open: false,
        entityDuplicateInfo,
        entityId,
        oldEntityData
      })
    );
  };

  return (
    <Dialog
      onClose={() =>
        dispatch(
          EDIT_NOTE_MODAL({
            open: false,
            entityId
          })
        )
      }
      open={isEditNoteModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => dispatch(EDIT_NOTE_MODAL({open: false}))} />
        <DialogTitle className='DialogTitleOrange'>Please note:</DialogTitle>
        <DialogBody>
          <DialogText className='DialogTextSm'>
            This {modalNoteEntityName} will be edited across the entire menu.
          </DialogText>
          <DialogCheckbox>
            <CheckboxContainer>
              <FormControlLabel
                className='Say2EatCheckbox'
                control={
                  <Checkbox
                    labelStyle={{color: '#172751'}}
                    iconStyle={{fill: '#FF2D38'}}
                    inputStyle={{color: '#FF2D38'}}
                    style={{color: '#FF2D38'}}
                    onChange={handleChangeCheckbox}
                    name='checkedNote'
                    checked={checked}
                  />
                }
                label='Don’t show this message again'
              />
            </CheckboxContainer>
          </DialogCheckbox>
          <DialogAction>
            <ButtonPrimary onClick={() => submitAction()}>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default EditModalNote;
