import styled from 'styled-components';
import {FieldInput} from '../dashboard/styles';
import {ErrorSignWrapper} from '../../../components/errorSign/errorSign';

const AlignCenter = styled.div`
  text-align: center;
`;

const Input = styled(FieldInput)`
  height: 36px;
  padding: 8px;
`;

const Relative = styled.div`
  position: relative;
`;

const Error = styled(ErrorSignWrapper)`
  top: 100%;
  left: 0;
`;

const DashboardContent = styled.div`
  display: block;
  padding: 110px 100px 200px 372px;
  overflow-x: hidden;

  @media (max-width: 1650px) {
    padding: 110px 48px 200px 320px;
  }
  @media (max-width: 1440px) {
    padding: 110px 38px 200px 310px;
  }
  @media (max-width: 1250px) {
    padding: 110px 80px 170px 80px;
  }

  .HeadlineDesktop {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    padding: 110px 60px 170px 60px;
  }
  @media (max-width: 1100px) {
    padding: 110px 50px 170px 50px;
  }
  @media (max-width: 1000px) {
    padding: 110px 40px 70px 40px;
  }

  &.TabletPTop {
    @media (max-width: 1200px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1100px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1000px) {
      padding: 110px 40px 70px 40px;
    }
  }
`;

export {AlignCenter, Input, Relative, Error, DashboardContent};
