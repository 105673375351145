import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {DialogAction, DialogBody} from '../../../../dashboard/styles';
import ButtonPrimary from '../../../../../../components/buttons/ButtonPrimary/styles';
import MemberPictureImg from '../../../../../../assets/team-red.gif';
import {MemberPicture} from '../../../styles';
import DialogContainer from '../../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../../components/DialogTitle/styles';

export default function MemberConfirmation({open, onClose}) {
  const closeDialog = () => {
    onClose();
  };

  return (
    <Dialog open={open} scroll='body' onClose={closeDialog}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <MemberPicture src={MemberPictureImg} />
        <DialogTitle className='memberConfirmation'>
          New team member added
        </DialogTitle>
        <DialogBody>
          <DialogAction className='mTopLarge'>
            <ButtonPrimary onClick={closeDialog}>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

MemberConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

MemberConfirmation.defaultProps = {
  open: false,
  onClose: () => {}
};
