import React from 'react';
import T from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Input,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import SelectFieldWrapper from './style';

const LocationSelectField = ({
  value,
  onChange,
  menuItems,
  input,
  className,
  optionLabelName,
  emptyLabel,
  ...props
}) => {
  const menuItemsMap = menuItems.reduce((map, item) => {
    // eslint-disable-next-line no-param-reassign
    map[item._id] = item;

    return map;
  }, {});

  return (
    <SelectFieldWrapper>
      <Select
        className={className}
        value={value}
        onChange={onChange}
        input={input || <Input />}
        renderValue={(selected) =>
          selected
            .map((i) => (menuItemsMap[i] ? menuItemsMap[i].FullName : ''))
            .join(', ')
        }
        {...props}
      >
        {!menuItems.length && (
          <MenuItem disabled>{emptyLabel || 'No locations here!'} </MenuItem>
        )}
        {menuItems.map((item) => (
          <MenuItem key={item._id} value={item._id}>
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={value.includes(item._id)}
                />
              }
            />

            {item[optionLabelName] || ''}
          </MenuItem>
        ))}
      </Select>
    </SelectFieldWrapper>
  );
};

LocationSelectField.propTypes = {
  value: T.array,
  name: T.string,
  onChange: T.func.isRequired,
  menuItems: T.array.isRequired,
  className: T.string,
  optionLabelName: T.string,
  input: T.oneOfType([T.elementType, T.element]),
  emptyLabel: T.string
};

LocationSelectField.defaultProps = {
  value: [],
  name: '',
  className: '',
  input: null,
  optionLabelName: '',
  emptyLabel: ''
};

export default LocationSelectField;
