import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {ButtonPrimary} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {DialogSubTitle} from '../../../dashboard/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {
  HANDLE_NOT_STORE_MODAL,
  HANDLE_CONNECT_STORE_MODAL
} from '../../../../../store/uberEats/actions';
import history from '../../../../../history';
import {removeSessionStorageItem} from '../../../../../helpers/sessionStorage';

const BYOC_STEP_STORAGE_KEY = 'BYOC_STEP_STORAGE_KEY';

const NotStoresModal = ({open}) => {
  const dispatch = useDispatch();
  const stores = useSelector(
    (state) => state.uberEatsReducer.uberEatsActiveStores
  );

  const onClose = () => {
    dispatch(HANDLE_CONNECT_STORE_MODAL(false));
    dispatch(HANDLE_NOT_STORE_MODAL(false));
    removeSessionStorageItem(BYOC_STEP_STORAGE_KEY);
    history.push('/uber-eats');
  };

  return (
    <Dialog open={open}>
      <DialogContainer className='DialogSm'>
        <DialogTitle className='DialogTitle marginBottomLg'>
          No UberEats account available
        </DialogTitle>
        <DialogSubTitle>
          All of your UberEats locations are already connected to other
          accounts. Go to your UberEats Manager account, disconnect the relevant
          account and then repeat this process
        </DialogSubTitle>
        <DialogBody>
          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

NotStoresModal.propTypes = {
  open: PropTypes.bool.isRequired
};

export default NotStoresModal;
