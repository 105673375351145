/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import './index.css';
import {Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import {LocalizationProvider} from '@material-ui/pickers';
import history from './history';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'fontsource-roboto';
import Toaster from './components/shared/Utils/Toaster';
import {ThemeProvider} from './components/Datepicker/DatePickerContext';
import store from './store';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './helpers/ErrorCatchPage';
import {newLogger} from './services/logger/new-logger';

// const history = createHistory();

ReactDOM.render(
  <React.StrictMode>
    <Helmet
      script={[
        {
          async: true,
          defer: true,
          src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places&language=en&sensor=false‌​`,
          type: 'text/javascript'
        }
      ]}
    />
    <Provider store={store}>
      <ThemeProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => history.push('/')}
        >
          <Router history={history}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <Toaster />
              <App />
            </LocalizationProvider>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
