import {createAction} from 'redux-act';

export const FETCH_HIDDEN_SECTIONS = createAction(
  'menuEditor/FETCH_HIDDEN_SECTIONS'
);

export const SET_HIDDEN_SECTIONS = 'menuEditor/SET_HIDDEN_SECTIONS';
export const CLEAR_HIDDEN_SECTIONS = 'menuEditor/CLEAR_HIDDEN_SECTIONS';

export const FETCH_HIDDEN_ITEMS = createAction('menuEditor/FETCH_HIDDEN_ITEMS');
export const SET_HIDDEN_ITEMS = 'menuEditor/SET_HIDDEN_ITEMS';
export const CLEAR_HIDDEN_ITEMS = 'menuEditor/CLEAR_HIDDEN_ITEMS';

export const FETCH_HIDDEN_MODIFIERS = createAction(
  'menuEditor/FETCH_HIDDEN_MODIFIERS'
);
export const SET_HIDDEN_MODIFIERS = 'menuEditor/SET_HIDDEN_MODIFIERS';
export const CLEAR_HIDDEN_MODIFIERS = 'menuEditor/CLEAR_HIDDEN_MODIFIERS';

export const CLEAR_HIDDEN_STORE = createAction('menuEditor/CLEAR_HIDDEN_STORE');

export const GET_HIDDEN_TAB = createAction('menuEditorHidden/GET_HIDDEN_TAB');

export const GET_HIDDEN_TAB_SUCCESS = createAction(
  'menuEditorHidden/GET_HIDDEN_TAB_SUCCESS'
);

export const FETCH_SINGLE_CATEGORY_TREE = createAction(
  'menuEditorHidden/FETCH_SINGLE_CATEGORY_TREE'
);
export const SET_SINGLE_CATEGORY_TREE = createAction(
  'menuEditorHidden/SET_SINGLE_CATEGORY_TREE'
);
export const FETCH_SINGLE_ITEM_TREE = createAction(
  'menuEditorHidden/FETCH_SINGLE_ITEM_TREE'
);
export const SET_SINGLE_ITEM_TREE = createAction(
  'menuEditorHidden/SET_SINGLE_ITEM_TREE'
);
export const FETCH_SINGLE_MODIFIER_TREE = createAction(
  'menuEditorHidden/FETCH_SINGLE_MODIFIER_TREE'
);
export const SET_SINGLE_MODIFIER_TREE = createAction(
  'menuEditorHidden/SET_SINGLE_MODIFIER_TREE'
);
export const FETCH_HIDDEN_COUNTERS = createAction(
  'menuEditorHidden/FETCH_HIDDEN_COUNTERS'
);
export const LOAD_HIDDEN_COUNTERS = createAction(
  'menuEditorHidden/LOAD_HIDDEN_COUNTERS'
);
export const SET_HIDDEN_COUNTERS = createAction(
  'menuEditorHidden/SET_HIDDEN_COUNTERS'
);
