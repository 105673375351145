import React, {memo, useEffect, useMemo, useState} from 'react';
import T from 'prop-types';
import {useFormikContext} from 'formik';
import {nanoid} from 'nanoid';
import {RadioGroup} from '@material-ui/core';
import {
  DashboardField,
  DashboardFields,
  DashboardHolder,
  DashboardTitle
} from '../../dashboard/styles';
import DaysPicker from '../DaysPicker';
import {ButtonOutline} from '../../../../components/buttons';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {generateTimeRanges, openingDaysServerParser} from '../helpers';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';

const DaysPickerContainer = ({WorkingDays}) => {
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();
  const [openingDaysRadio, setOpeningDaysRadio] = useState('allDays');
  const isDayError = useMemo(
    () => !openingDaysServerParser(openingDays).length,
    [openingDays]
  );

  useEffect(() => {
    if (WorkingDays.length) {
      setOpeningDays(WorkingDays);
    } else {
      setOpeningDays([
        {
          id: nanoid(),
          days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          startOfWorkingDay: '',
          endOfWorkingDay: '',
          duration: '',
          timePeriods: [
            {
              id: nanoid(),
              startOfWorkingDay: '10:00 AM',
              endOfWorkingDay: '10:00 PM',
              duration: '12:00',
              hoursIntervals: [
                Object.keys(generateTimeRanges(15, 'en', 10, 22))
              ]
            }
          ]
        }
      ]);
    }
  }, [WorkingDays]);

  useEffect(() => {
    const checkOpeningDaysRadio = () => {
      if (openingDays.length === 1) {
        return (
          openingDays[0].days.length === 7 &&
          openingDays[0].timePeriods[0]?.startOfWorkingDay === '12:00 AM' &&
          openingDays[0].timePeriods[0]?.endOfWorkingDay === '12:00 AM'
        );
      }
      return false;
    };

    if (checkOpeningDaysRadio()) {
      setOpeningDaysRadio('allDays');
    } else {
      setOpeningDaysRadio('certainDays');
    }
  }, [openingDays]);

  const allDaysRadioChange = () => {
    setOpeningDays([
      {
        id: nanoid(),
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        startOfWorkingDay: '',
        endOfWorkingDay: '',
        duration: '',
        timePeriods: [
          {
            id: nanoid(),
            startOfWorkingDay: '12:00 AM',
            endOfWorkingDay: '11:45 PM',
            duration: '11:45',
            hoursIntervals: [Object.keys(generateTimeRanges(15, 'en'))]
          }
        ]
      }
    ]);
  };

  const createOpeningDay = () => {
    const newId = nanoid();
    setOpeningDays((prevState) => [
      ...prevState,
      {
        id: newId,
        days: [],
        startOfWorkingDay: '',
        endOfWorkingDay: '',
        duration: '',
        timePeriods: [
          {
            id: nanoid(),
            startOfWorkingDay: '10:00 AM',
            endOfWorkingDay: '10:00 PM',
            duration: '12:00',
            hoursIntervals: [Object.keys(generateTimeRanges(15, 'en', 10, 22))]
          }
        ]
      }
    ]);

    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, newId)
    );
  };

  return (
    <>
      <DashboardHolder>
        <DashboardTitle className='WithLine mBottom titleMd'>
          Opening Days and Hours
        </DashboardTitle>
        <div style={{color: 'red', height: '1em'}}>
          {isDayError && 'Please, select time of work'}
        </div>
        <RadioGroup
          aria-label='Opening Days and Hours'
          name='openingDaysRadio'
          onChange={(e) => setOpeningDaysRadio(e.target.value)}
          value={openingDaysRadio}
        >
          <DashboardFields>
            {/* <DashboardField className='w100 dFlex '>
              <RadioS2E
                type='radio'
                value='allDays'
                onClick={() => allDaysRadioChange()}
                label='
                24/7'
                checked={openingDaysRadio === 'allDays'}
              />
              <RadioS2E
                type='radio'
                value='certainDays'
                checked={openingDaysRadio === 'certainDays'}
                label='On certain days of the week'
              />
            </DashboardField> */}
            <DashboardField className='w100 flexColumn'>
              <DaysPicker />
            </DashboardField>
          </DashboardFields>
        </RadioGroup>
      </DashboardHolder>
      {openingDaysRadio === 'certainDays' && (
        <DashboardHolder>
          <DashboardFields>
            <DashboardField className='w100 justifyCenter'>
              {openingDays.reduce((acc, elem) => acc + elem.days.length, 0) <
                7 && (
                <ButtonOutline
                  className='DashboardFieldButton'
                  type='button'
                  onClick={() => createOpeningDay()}
                >
                  Add Another Opening Day
                </ButtonOutline>
              )}
            </DashboardField>
          </DashboardFields>
        </DashboardHolder>
      )}
    </>
  );
};

DaysPickerContainer.propTypes = {
  WorkingDays: T.array.isRequired
};
export default DaysPickerContainer;
