import React, {useEffect} from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {RadioGroup} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {
  DialogAction,
  DialogBody,
  DialogField,
  DialogSubTitle,
  FieldInput,
  FieldLabel
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import RadioS2E from '../../../../components/Radiobox';
import {SUBMIT_USER_EDIT_ADD_WRAPPER} from '../../../../store/usersManagement/action';
import DialogFields from '../../../../components/DialogFields/styles';
import UserManagementSchema from '../../../../helpers/services/formValidationSchemas/UserManagementSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {HintText} from '../../../../components/HintText/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import LocationSelect from '../../../../components/SelectField/LocationSelect';
import {GET_USER_LOCATIONS} from '../../../../store/global/user/action';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';

export default function AddUser({open, onClose, modalModel, currentUser}) {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.user.locations);

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      role: '',
      ...modalModel,
      locations_whitelist: modalModel?.locations_whitelist
        ? modalModel?.locations_whitelist
        : []
    },
    enableReinitialize: true,
    validationSchema: UserManagementSchema,
    onSubmit: (values, {resetForm}) => {
      sendGtmEvent('addNewUser', {
        role: values.role
      });

      dispatch(
        SUBMIT_USER_EDIT_ADD_WRAPPER({
          values: {...values, email: values.email.toLowerCase()},
          isEdit: !!modalModel._id,
          actions: () => {
            resetForm();
            onClose();
          }
        })
      );
    }
  });

  useEffect(() => {
    Object.keys(modalModel).forEach((field) => {
      if (field === 'locations_whitelist') {
        return;
      }
      formik.setFieldValue(field, modalModel[field], false);
    });
  }, [JSON.stringify(modalModel)]);

  useEffect(() => {
    const isNotValidLocations = locations.some(
      (location) => !location.FullName
    );
    if (isNotValidLocations) dispatch(GET_USER_LOCATIONS());
  }, []);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} scroll='body' onClose={closeDialog}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomLg'>
          {modalModel._id ? 'Edit' : 'Add New'} <span>User</span>
        </DialogTitle>
        <DialogSubTitle className='textMedium'>
          {modalModel._id
            ? 'Edit user of your team and set permissions'
            : 'Add a new user to your team and set their permissions'}
        </DialogSubTitle>
        <DialogBody>
          <DialogFields>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>Email</FieldLabel>
              <FieldInput
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                className={cn({
                  error: formik.errors.email && formik.touched.email
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.email && formik.errors.email}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>First Name</FieldLabel>
              <FieldInput
                name='first_name'
                onChange={formik.handleChange}
                value={formik.values.first_name}
                className={cn({
                  error: formik.errors.first_name && formik.touched.first_name
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.first_name && formik.errors.first_name}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='DialogField w100 marginBottomXs'>
              <FieldLabel className='inline-label'>Last Name</FieldLabel>
              <FieldInput
                name='last_name'
                onChange={formik.handleChange}
                value={formik.values.last_name}
                className={cn({
                  error: formik.errors.last_name && formik.touched.last_name
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.last_name && formik.errors.last_name}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            {modalModel._id !== currentUser.id && (
              <DialogField>
                <FieldLabel className='FieldLabelLg w100'>
                  Select role
                </FieldLabel>
                <ErrorSignWrapper>
                  <ErrorSign>
                    {formik.touched.role && formik.errors.role}
                  </ErrorSign>
                </ErrorSignWrapper>
                <DialogFields>
                  <RadioGroup
                    aria-label='role'
                    name='role'
                    value={formik.values.role}
                    onChange={formik.handleChange}
                  >
                    <DialogField className='w100 SelectRole'>
                      <RadioS2E
                        name='role'
                        value='admin'
                        label='Admin'
                        onClick={formik.handleChange}
                        checked={formik.values.role === 'admin'}
                      />
                      <HintText className='withoutIcon'>
                        <span>
                          Add and edit users, and do anything a Manager can do
                        </span>
                      </HintText>
                    </DialogField>
                    <DialogField className='w100 SelectRole'>
                      <RadioS2E
                        name='role'
                        value='manager'
                        label='Manager'
                        onClick={formik.handleChange}
                        checked={formik.values.role === 'manager'}
                      />
                      <HintText className='withoutIcon'>
                        <span>
                          Access Reports, Orders, Financials, Analytics, and
                          Passwords
                        </span>
                      </HintText>
                    </DialogField>
                    <DialogField className='w100 SelectRole'>
                      <RadioS2E
                        name='role'
                        value='accounting'
                        label='Accountant'
                        onClick={formik.handleChange}
                        checked={formik.values.role === 'accounting'}
                      />
                      <HintText className='withoutIcon'>
                        <span>Access Orders, Financials, and Analytics</span>
                      </HintText>
                    </DialogField>
                    <DialogField className='w100 SelectRole'>
                      <RadioS2E
                        name='role'
                        value='employee'
                        label='Employee'
                        onClick={formik.handleChange}
                        checked={formik.values.role === 'employee'}
                      />
                      <HintText className='withoutIcon'>
                        <span>
                          Access Orders and get notified of new orders
                        </span>
                      </HintText>
                    </DialogField>
                  </RadioGroup>
                </DialogFields>
              </DialogField>
            )}
            <DialogField className='w100 Say2EatSelect'>
              <FieldLabel className='inline-label'>
                Location whitelist
              </FieldLabel>
              <LocationSelect
                multiple
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                name='locations_whitelist'
                emptyLabel='Select location'
                value={formik.values.locations_whitelist}
                onChange={formik.handleChange}
                optionLabelName='FullName'
                menuItems={locations}
              />
            </DialogField>
          </DialogFields>
          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>{modalModel._id ? 'Edit User' : 'Add New User'}</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

AddUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object,
  currentUser: PropTypes.object
};

AddUser.defaultProps = {
  currentUser: {},
  open: false,
  onClose: () => {},
  modalModel: {}
};
