import {createReducer} from 'redux-act';
import {setLevelNamesByModifierGroupTree} from '../../helpers/menuEditor';
import {
  CLEAR_MODIFIERS_GROUPS_DATA,
  DELETE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP_SUCCESS,
  GET_MODIFIER_GROUP_BY_ID,
  GET_MODIFIER_GROUP_BY_ID_SUCCESS,
  GET_MODIFIER_GROUP_MODAL,
  GET_MODIFIER_GROUP_MODAL_SUCCESS,
  GET_MODIFIERS,
  GET_MODIFIERS_SUCCESS,
  POST_MODIFIER_GROUP,
  POST_MODIFIER_GROUP_SUCCESS,
  UPDATE_MODIFIER_GROUP,
  UPDATE_MODIFIER_GROUP_SUCCESS,
  FETCH_SINGLE_MODIFIER_GROUP_TREE,
  FETCH_SINGLE_MODIFIER_GROUP_SUCCESS
} from './action';

const initialState = () => ({
  modifiersGroupModalData: [],
  modifiersGroupModalLoading: false,
  modifiersGroupModalPagination: {
    pages: 1,
    page: 1,
    limit: 500,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  modifierGroupByIdData: {},
  modifierGroupByIdDataLoading: false,
  modifiersData: [],
  modifiersLoading: false,
  rerenderModifierGroups: false,
  modifiersPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name',
    hasNextPage: true,
    hasPrevPage: false,
    prevPage: 0
  }
});

const menuEditorModifierGroupsReducer = createReducer(
  {
    [GET_MODIFIERS]: (state) => ({
      ...state,
      modifiersLoading: true
    }),
    [GET_MODIFIERS_SUCCESS]: (state, payload) => {
      let data = [];
      if (payload.page === 1) {
        data = [...payload.docs];
      } else {
        data = [...state.modifiersData, ...payload.docs];
      }

      setLevelNamesByModifierGroupTree(data, FETCH_SINGLE_MODIFIER_GROUP_TREE);

      return {
        ...state,
        modifiersData: data,
        modifiersLoading: false,
        modifiersPagination: {
          pages: payload.totalPages,
          page: payload.page,
          limit: payload.limit,
          total: payload.totalDocs,
          sort: 'desc',
          sortBy: 'Name',
          hasNextPage: payload.hasNextPage,
          hasPrevPage: payload.hasPrevPage,
          prevPage: payload.prevPage
        }
      };
    },

    [FETCH_SINGLE_MODIFIER_GROUP_SUCCESS]: (state, payload) => {
      const modifierGroup = payload;
      setLevelNamesByModifierGroupTree(
        [modifierGroup],
        FETCH_SINGLE_MODIFIER_GROUP_TREE
      );
      const modifiersData = state.modifiersData.map((item) =>
        item.PosId !== modifierGroup.PosId ? item : modifierGroup
      );

      return {
        ...state,
        modifiersData
      };
    },

    [GET_MODIFIER_GROUP_BY_ID]: (state) => ({
      ...state,
      modifierGroupByIdDataLoading: true
    }),

    [GET_MODIFIER_GROUP_BY_ID_SUCCESS]: (state, payload) => ({
      ...state,
      modifierGroupByIdData: payload,
      modifierGroupByIdDataLoading: false
    }),

    [GET_MODIFIER_GROUP_MODAL]: (state) => ({
      ...state,
      modifiersGroupModalLoading: true
    }),
    [GET_MODIFIER_GROUP_MODAL_SUCCESS]: (
      state,
      {docs, totalPages, page, limit, totalDocs}
    ) => ({
      ...state,
      modifiersGroupModalData: docs,
      modifiersGroupModalLoading: false,
      modifiersGroupModalPagination: {
        pages: totalPages,
        page,
        limit,
        totalDocs,
        sort: 'desc',
        sortBy: 'Name'
      }
    }),

    [POST_MODIFIER_GROUP]: (state) => ({
      ...state
      // rerenderOverview: false
    }),
    [POST_MODIFIER_GROUP_SUCCESS]: (state) => ({
      ...state,
      rerenderModifierGroups: !state.rerenderModifierGroups
    }),
    [UPDATE_MODIFIER_GROUP]: (state) => ({
      ...state
      // rerenderOverview: false
    }),
    [UPDATE_MODIFIER_GROUP_SUCCESS]: (state) => ({
      ...state,
      rerenderModifierGroups: !state.rerenderModifierGroups
    }),
    [DELETE_MODIFIER_GROUP]: (state) => ({
      ...state
      // rerenderOverview: false
    }),
    [DELETE_MODIFIER_GROUP_SUCCESS]: (state) => ({
      ...state,
      rerenderModifierGroups: !state.rerenderModifierGroups
    }),
    [CLEAR_MODIFIERS_GROUPS_DATA]: () => ({
      ...initialState(),
      modifiersData: []
      // rerenderOverview: false
    })
  },
  initialState()
);

export default menuEditorModifierGroupsReducer;
