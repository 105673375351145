import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import {useDatePickerContext} from '../../Datepicker/DatePickerContext';
import Loading from '../../shared/Utils/Loading';
import {
  DashboardBox,
  DashboardBoxes,
  DashboardBoxNumber,
  DashboardBoxText,
  NoResultText
} from '../../../routes/App/dashboard/styles';
import {GET_CUSTOMERS_COUNT} from '../../../store/analyticsCustomersCount/action';

const TotalCustomers = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const {customersCount, customersCountLoading} = useSelector(
    (state) => state.analyticsCustomersCount
  );

  const date = useDatePickerContext();

  useEffect(() => {
    if (timezone) {
      dispatch(
        GET_CUSTOMERS_COUNT({
          startDate: date[0]
            ? moment(date[0]).utcOffset(timezone, false).utc().format()
            : null,
          endDate: date[1]
            ? moment(date[1]).utcOffset(timezone, false).utc().format()
            : null
        })
      );
    }
  }, [date, locationId, dispatch, timezone]);
  return (
    <>
      {customersCountLoading && <Loading />}
      {Object.keys(customersCount).length !== 0 && !customersCountLoading && (
        <DashboardBoxes>
          <DashboardBox>
            <DashboardBoxNumber>
              {' '}
              {customersCount.totalCustomers}
            </DashboardBoxNumber>
            {/* <DashboardBoxPercent className="PercentDecrease">2.5%</DashboardBoxPercent> */}
          </DashboardBox>
          <DashboardBox>
            <DashboardBoxText>
              <strong>{customersCount.ordersPerCustomer.toFixed(2)}</strong>{' '}
              Orders per Customer (avg.)
            </DashboardBoxText>
            <DashboardBoxText>
              <strong>{customersCount.avgItemsPerOrder.toFixed(2)}</strong>{' '}
              Items per Order (avg.)
            </DashboardBoxText>
          </DashboardBox>
        </DashboardBoxes>
      )}
      {!customersCountLoading && Object.keys(customersCount).length === 0 && (
        <Box display='flex' alignItems='center' justifyContent='center' m={1}>
          <NoResultText>No results found for this date range!</NoResultText>
        </Box>
      )}
    </>
  );
};

export default TotalCustomers;
