import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {Form, Formik} from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import {toast} from 'react-toastify';
import {nanoid} from 'nanoid';
import cn from 'classnames';
import Select from '@material-ui/core/Select';
import {ButtonPrimary} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {
  DialogField,
  DialogSubTitle,
  FieldLabel
} from '../../../dashboard/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import SelectFieldWrapper from '../../../../../components/SelectField/style';
import {
  CONNECT_UBEREATS_STORE,
  HANDLE_CONNECT_STORE_MODAL,
  SET_UBEREATS_STORE_ID
} from '../../../../../store/uberEats/actions';
import history from '../../../../../history';
import {removeSessionStorageItem} from '../../../../../helpers/sessionStorage';
import ConfirmStoreSelectModal from './confirmStoreSelectModal';

const BYOC_STEP_STORAGE_KEY = 'BYOC_STEP_STORAGE_KEY';

const StoreSelectModal = ({open}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const locationId = useSelector((state) => state.user?.activeLocation?._id);
  const locationName = useSelector(
    (state) => state.user?.activeLocation?.FullName
  );
  const stores = useSelector(
    (state) => state.uberEatsReducer.uberEatsActiveStores
  );

  const [openConfirm, setOpenConfirm] = useState(false);

  const initValues = {
    store:
      stores?.filter((item) => item?.status === 'ONLINE')[0]?.id ||
      stores[0]?.id,
    storeName:
      stores?.filter((item) => item?.status === 'ONLINE')[0]?.name ||
      stores[0]?.name
  };

  const onClose = () => {
    removeSessionStorageItem(BYOC_STEP_STORAGE_KEY);
    history.push('/uber-eats');
    dispatch(HANDLE_CONNECT_STORE_MODAL(false));
  };

  const onCloseConfirm = () => {
    setOpenConfirm(false);
  };
  useEffect(() => {
    if (stores?.length === 1 && open) {
      dispatch(SET_UBEREATS_STORE_ID(stores[0].id));
      dispatch(
        CONNECT_UBEREATS_STORE({
          postCallback: () => {
            toast.success(
              'Your UberEats account has been connected successfully!'
            );
          },
          requestBody: {locationId, isByocEnabled: false, metadata: []},
          uberEatsStoreId: stores[0].id
        })
      );
      dispatch(HANDLE_CONNECT_STORE_MODAL(false));
    }
  }, [open]);

  const confirm = () => {
    dispatch(SET_UBEREATS_STORE_ID(formikRef?.current?.values.store));
    dispatch(
      CONNECT_UBEREATS_STORE({
        postCallback: () => {
          toast.success(
            'Your UberEats account has been connected successfully!'
          );
        },
        requestBody: {locationId, isByocEnabled: false, metadata: []},
        uberEatsStoreId: formikRef?.current?.values.store
      })
    );
    dispatch(HANDLE_CONNECT_STORE_MODAL(false));
    history.push('/uber-eats');
  };

  return (
    <>
      {stores.length > 1 ? (
        <Formik
          initialValues={initValues}
          enableReinitialize
          innerRef={formikRef}
          onSubmit={(values) => {
            if (values.store) {
              setOpenConfirm(true);
            } else {
              formikRef?.current?.setErrors({
                store: 'Please enter UberEats store'
              });
            }
          }}
        >
          {({values, errors, touched, setFieldValue, submitForm}) => {
            return (
              <Form>
                <Dialog open={open} scroll='body'>
                  <DialogContainer className='DialogSm'>
                    <DialogClose onClick={onClose} />
                    <DialogTitle className='DialogTitle marginBottomLg'>
                      Select UberEats account
                    </DialogTitle>
                    <DialogSubTitle>
                      Make sure you connect the right UberEats account that
                      matches Sauce&apos;s ({locationName}) account
                    </DialogSubTitle>
                    <DialogBody>
                      <DialogField className='w100 Say2EatSelect'>
                        <FieldLabel className='inline-label'>
                          Select A Store
                        </FieldLabel>
                        <SelectFieldWrapper>
                          <Select
                            value={values.store}
                            name='store'
                            onChange={(e) => {
                              setFieldValue('store', e.target.value);
                              setFieldValue(
                                'storeName',
                                stores.find(
                                  (item) => e.target.value === item.id
                                )?.name
                              );
                            }}
                            className={cn({
                              error: errors?.store && touched?.store
                            })}
                            displayEmpty
                            MenuProps={{
                              disableScrollLock: true,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {stores.map((item) => (
                              <MenuItem key={nanoid()} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </SelectFieldWrapper>
                        <ErrorSignWrapper>
                          {errors?.store && touched?.store ? (
                            <ErrorSign>{errors?.store}</ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DialogField>
                      <DialogAction>
                        <ButtonPrimary onClick={submitForm} type='submit'>
                          <span>Connect</span>
                        </ButtonPrimary>
                      </DialogAction>
                    </DialogBody>
                    <ConfirmStoreSelectModal
                      open={openConfirm}
                      locationName={locationName}
                      uberEatsStoreName={formikRef?.current?.values.storeName}
                      onClose={onCloseConfirm}
                      confirm={confirm}
                    />
                  </DialogContainer>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

StoreSelectModal.propTypes = {
  open: PropTypes.bool.isRequired
};

export default StoreSelectModal;
