import {createReducer} from 'redux-act';
import {
  ADD_NEW_MODAL,
  CREATE_CATEGORY_MODAL,
  CREATE_ITEM_MODAL,
  CREATE_MODIFIER_GROUP_MODAL,
  CREATE_MODIFIER_MODAL,
  DELETE_MODAL,
  HIDDEN_MODAL,
  EDIT_CATEGORY_MODAL,
  EDIT_ITEM_MODAL,
  EDIT_MODIFIER_GROUP_MODAL,
  EDIT_MODIFIER_MODAL,
  EDIT_NOTE_MODAL,
  SELECT_ITEM_MODAL_OPEN,
  SOLD_OUT_MODAL
} from './action';

const initialState = () => ({
  isCreateCategoryModalOpen: false,
  isEditCategoryModalOpen: false,
  isSubmodal: false,
  entityId: '',
  oldEntityData: {},
  entityDuplicateInfo: {},
  isAddNewModalOpen: false,
  isDeleteModalOpen: false,
  isEditNoteModalOpen: false,
  modalNoteEntityName: '',
  isCreateItemModalOpen: false,
  getNewItemFormData: undefined,
  isEditItemModalOpen: false,
  isSoldOutModalOpen: false,
  isCreateModifierModalOpen: false,
  isCreateModifierGroupModalOpen: false,
  getNewModifierGroupFormData: undefined,
  getNewModifierFormData: undefined,
  isEditModifierGroupModalOpen: false,
  isEditModifierModalOpen: false,
  isSelectItemModalOpen: false,
  selectItemModalParams: {
    itemType: '',
    queryParams: {},
    selectedItems: [],
    itemList: [],
    loading: false,
    callBack: () => {},
    closeModal: () => {}
  }
});

const menuEditorModalsReducer = createReducer(
  {
    [SELECT_ITEM_MODAL_OPEN]: (
      state,
      {
        open,
        itemType,
        queryParams,
        itemList,
        selectedItems,
        callBack,
        closeModal,
        loading
      }
    ) => ({
      ...state,
      isSelectItemModalOpen: open,
      selectItemModalParams: {
        itemType,
        queryParams,
        selectedItems,
        itemList,
        loading,
        callBack,
        closeModal
      }
    }),
    [CREATE_CATEGORY_MODAL]: (state, {open, getNewItemFormData}) => ({
      ...state,
      isCreateCategoryModalOpen: open,
      getNewItemFormData
    }),
    [EDIT_CATEGORY_MODAL]: (state, payload) => ({
      ...state,
      isEditCategoryModalOpen: payload.open,
      entityId: payload.entityId,
      oldEntityData: payload.oldEntityData
    }),
    [ADD_NEW_MODAL]: (state, payload) => ({
      ...state,
      isAddNewModalOpen: payload.open
    }),
    [SOLD_OUT_MODAL]: (
      state,
      {open, name, entityDuplicateInfo, entityId, oldEntityData}
    ) => ({
      ...state,
      isSoldOutModalOpen: open,
      modalNoteEntityName: name,
      entityDuplicateInfo,
      entityId,
      oldEntityData
    }),
    [DELETE_MODAL]: (state, payload) => ({
      ...state,
      isDeleteModalOpen: payload.open,
      modalNoteEntityName: payload.name,
      entityId: payload.entityId
    }),
    [HIDDEN_MODAL]: (state, payload) => {
      return {
        ...state,
        isHiddenModalOpen: payload.open,
        modalNoteEntityName: payload.name,
        entityId: payload.entityId,
        oldEntityData: payload.oldEntityData,
        hiddenModalStatus: payload.hiddenModalStatus
      };
    },
    [EDIT_NOTE_MODAL]: (
      state,
      {open, name, entityDuplicateInfo, entityId, oldEntityData}
    ) => ({
      ...state,
      isEditNoteModalOpen: open,
      modalNoteEntityName: name,
      entityDuplicateInfo,
      entityId,
      oldEntityData
    }),
    [CREATE_ITEM_MODAL]: (
      state,
      {
        open,
        getNewItemFormData,
        isSubmodal,
        entityDuplicateInfo,
        getNewItemFormDataCategory,
        entityId
      }
    ) => ({
      ...state,
      isCreateItemModalOpen: open,
      entityDuplicateInfo,
      isSubmodal,
      getNewItemFormData,
      getNewItemFormDataCategory,
      entityId
    }),
    [EDIT_ITEM_MODAL]: (
      state,
      {open, entityId, entityDuplicateInfo, oldEntityData}
    ) => ({
      ...state,
      isEditItemModalOpen: open,
      entityDuplicateInfo,
      entityId,
      oldEntityData
    }),
    [CREATE_MODIFIER_MODAL]: (
      state,
      {open, isSubmodal, getNewModifierFormData}
    ) => ({
      ...state,
      isCreateModifierModalOpen: open,
      isSubmodal,
      getNewModifierFormData
    }),
    [CREATE_MODIFIER_GROUP_MODAL]: (
      state,
      {open, getNewModifierGroupFormData}
    ) => ({
      ...state,
      isCreateModifierGroupModalOpen: open,
      getNewModifierGroupFormData
    }),
    [EDIT_MODIFIER_GROUP_MODAL]: (state, {open, entityId, oldEntityData}) => ({
      ...state,
      isEditModifierGroupModalOpen: open,
      entityId,
      oldEntityData
    }),
    [EDIT_MODIFIER_MODAL]: (
      state,
      {open, entityId, entityDuplicateInfo, oldEntityData}
    ) => ({
      ...state,
      isEditModifierModalOpen: open,
      entityDuplicateInfo,
      entityId,
      oldEntityData
    })
  },
  initialState()
);

export default menuEditorModalsReducer;
