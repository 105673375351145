import * as Yup from 'yup';
import {STRING_REGEXP, URL_REGEXP} from '../constants/RegExpConstants';

const SalesSetupUberEatsMenuPullingSchema = Yup.object().shape({
  data: Yup.object().shape({
    platformSelect: Yup.number(),
    MenuUberEats: Yup.bool(),
    MenuShowUrlInput: Yup.bool(),
    lowerAllPrices: Yup.bool(),
    inflationType: Yup.string(),
    UEDeliveryCommission: Yup.number()
      .nullable()
      .when(
        ['MenuUberEats', 'platformSelect'],
        (MenuUberEats, platformSelect) => {
          if (
            (platformSelect === 0 ||
              platformSelect === 2 ||
              platformSelect === 5) &&
            MenuUberEats
          ) {
            return Yup.number()
              .typeError('Enter the commission you are paying UberEats')
              .required('Enter the commission you are paying UberEats')
              .min(1, 'Value must be positive');
          }
          return Yup.number().nullable();
        }
      ),
    UEPickupCommission: Yup.number()
      .nullable()
      .when(
        ['MenuUberEats', 'platformSelect'],
        (MenuUberEats, platformSelect) => {
          if (
            (platformSelect === 0 ||
              platformSelect === 2 ||
              platformSelect === 5) &&
            MenuUberEats
          ) {
            return Yup.number()
              .typeError('Enter the commission you are paying UberEats')
              .required('Enter the commission you are paying UberEats')
              .min(1, 'Value must be positive');
          }
          return Yup.number().nullable();
        }
      ),
    inflationInputAmount: Yup.number().when(
      ['lowerAllPrices', 'inflationType', 'MenuUberEats', 'platformSelect'],
      (lowerAllPrices, inflationType, MenuUberEats, platformSelect) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuUberEats &&
          lowerAllPrices &&
          inflationType === '$'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    inflationInputPercentage: Yup.number().when(
      ['lowerAllPrices', 'inflationType', 'MenuUberEats', 'platformSelect'],
      (lowerAllPrices, inflationType, MenuUberEats, platformSelect) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuUberEats &&
          lowerAllPrices &&
          inflationType === '%'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    lowerAllPricesURL: Yup.bool(),
    inflationTypeURL: Yup.string(),
    inflationInputAmountURL: Yup.number().when(
      [
        'lowerAllPricesURL',
        'inflationTypeURL',
        'MenuShowUrlInput',
        'platformSelect'
      ],
      (
        lowerAllPricesURL,
        inflationTypeURL,
        MenuShowUrlInput,
        platformSelect
      ) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuShowUrlInput &&
          lowerAllPricesURL &&
          inflationTypeURL === '$'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    inflationInputPercentageURL: Yup.number().when(
      [
        'lowerAllPricesURL',
        'inflationTypeURL',
        'MenuShowUrlInput',
        'platformSelect'
      ],
      (
        lowerAllPricesURL,
        inflationTypeURL,
        MenuShowUrlInput,
        platformSelect
      ) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuShowUrlInput &&
          lowerAllPricesURL &&
          inflationTypeURL === '%'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    inflationInputAmountEmail: Yup.number().when(
      [
        'lowerAllPricesEmail',
        'inflationTypeEmail',
        'MenuEmail',
        'platformSelect'
      ],
      (lowerAllPricesEmail, inflationTypeEmail, MenuEmail, platformSelect) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuEmail &&
          lowerAllPricesEmail &&
          inflationTypeEmail === '$'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    inflationInputPercentageEmail: Yup.number().when(
      [
        'lowerAllPricesEmail',
        'inflationTypeEmail',
        'MenuEmail',
        'platformSelect'
      ],
      (lowerAllPricesEmail, inflationTypeEmail, MenuEmail, platformSelect) => {
        if (
          (platformSelect === 0 ||
            platformSelect === 2 ||
            platformSelect === 5) &&
          MenuEmail &&
          lowerAllPricesEmail &&
          inflationTypeEmail === '%'
        ) {
          return Yup.number()
            .required('Enter a value')
            .min(0, 'Value must be positive')
            .max(100, 'Value must be less then 100');
        }
        return Yup.number();
      }
    ),
    MenuUseUrl: Yup.string()
      // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
      .when(
        ['MenuShowUrlInput', 'platformSelect'],
        (MenuShowUrlInput, platformSelect) => {
          if (
            (platformSelect === 0 ||
              platformSelect === 2 ||
              platformSelect === 5) &&
            MenuShowUrlInput
          ) {
            return (
              Yup.string()
                // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
                // .url('URL is not valid')
                .required('URL is not valid')
            );
          }
          return Yup.string();
          //   .matches(
          //   new RegExp(URL_REGEXP),
          //   'URL is not valid'
          // );
        }
      ),
    OtterUsername: Yup.string().when(['platformSelect'], (platformSelect) => {
      if (platformSelect === 3) {
        return Yup.string()
          .required('Mandatory field')
          .matches(new RegExp(STRING_REGEXP), 'English characters only');
      }
      return Yup.string();
    }),
    OtterPassword: Yup.string().when(['platformSelect'], (platformSelect) => {
      if (platformSelect === 3) {
        return Yup.string().required('Mandatory field');
      }
      return Yup.string();
    }),
    OtterPriceSourceLink: Yup.string()
      // .matches(new RegExp(URL_REGEXP), 'Not a valid link')
      .when(['platformSelect'], (platformSelect) => {
        if (platformSelect === 3) {
          return (
            Yup.string()
              // .url('Not a valid link')
              // .matches(new RegExp(URL_REGEXP), 'Not a valid link')
              .required('Mandatory field')
          );
        }
        return Yup.string();
        // .matches(new RegExp(URL_REGEXP), 'Not a valid link');
      }),
    OrdermarkUsername: Yup.string().when(
      ['platformSelect', 'OrdermarkType'],
      (platformSelect, OrdermarkType) => {
        if (platformSelect === 4 && OrdermarkType === 'Credentials') {
          return Yup.string()
            .required('Mandatory field')
            .matches(new RegExp(STRING_REGEXP), 'English characters only');
        }
        return Yup.string();
      }
    ),
    OrdermarkPassword: Yup.string().when(
      ['platformSelect', 'OrdermarkType'],
      (platformSelect, OrdermarkType) => {
        if (platformSelect === 4 && OrdermarkType === 'Credentials') {
          return Yup.string().required('Mandatory field');
        }
        return Yup.string();
      }
    ),
    OrdermarkPriceSourceLink: Yup.string()
      // .url('Not a valid link')
      // .matches(new RegExp(URL_REGEXP), 'Not a valid link')
      .when(
        ['platformSelect', 'OrdermarkType'],
        (platformSelect, OrdermarkType) => {
          if (platformSelect === 4 && OrdermarkType === 'Credentials') {
            return (
              Yup.string()
                // .url('Not a valid link')
                // .matches(new RegExp(URL_REGEXP), 'Not a valid link')
                .required('Mandatory field')
            );
          }
          return Yup.string();
          //   .matches(
          //   new RegExp(URL_REGEXP),
          //   'Not a valid link'
          // );
        }
      ),
    OrdermarkLocationId: Yup.string().when(
      ['platformSelect', 'OrdermarkType'],
      (platformSelect, OrdermarkType) => {
        if (platformSelect === 4 && OrdermarkType === 'LocationId') {
          return Yup.string()
            .required('Mandatory field')
            .matches(new RegExp(STRING_REGEXP), 'English characters only');
        }
        return Yup.string();
      }
    ),
    urlMenuImagesURLValue: Yup.string()
      // .url('URL is not valid')
      // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
      .when(
        [
          'MenuShowUrlInput',
          'platformSelect',
          'menuImagesCheckedURL',
          'urlMenuImagesURL'
        ],
        (
          MenuShowUrlInput,
          platformSelect,
          menuImagesCheckedURL,
          urlMenuImagesURL
        ) => {
          if (
            (platformSelect === 0 ||
              platformSelect === 2 ||
              platformSelect === 5) &&
            MenuShowUrlInput &&
            !menuImagesCheckedURL &&
            urlMenuImagesURL
          ) {
            return (
              Yup.string()
                // .url('URL is not valid')
                // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
                .required('URL is not valid')
            );
          }
          return Yup.string();
          //   .matches(
          //   new RegExp(URL_REGEXP),
          //   'URL is not valid'
          // );
        }
      ),
    emailMenuImagesURLValue: Yup.string()
      // .url('URL is not valid')
      // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
      .when(
        [
          'MenuEmail',
          'platformSelect',
          'menuImagesCheckedEmail',
          'emailMenuImagesURL'
        ],
        (
          MenuEmail,
          platformSelect,
          menuImagesCheckedEmail,
          emailMenuImagesURL
        ) => {
          if (
            (platformSelect === 0 ||
              platformSelect === 2 ||
              platformSelect === 5) &&
            MenuEmail &&
            !menuImagesCheckedEmail &&
            emailMenuImagesURL
          ) {
            return (
              Yup.string()
                // .url('URL is not valid')
                // .matches(new RegExp(URL_REGEXP), 'URL is not valid')
                .required('URL is not valid')
            );
          }
          return Yup.string();
          //   .matches(
          //   new RegExp(URL_REGEXP),
          //   'URL is not valid'
          // );
        }
      ),
    SquareMenuInstruction: Yup.string().when(
      ['platformSelect'],
      (platformSelect) => {
        if (platformSelect === 6) {
          return Yup.string()
            .required('Mandatory field')
            .matches(new RegExp(STRING_REGEXP), 'English characters only');
        }
        return Yup.string();
      }
    )
  })
});

export default SalesSetupUberEatsMenuPullingSchema;
