import styled from 'styled-components';

const DatepickerContainer = styled.div`
  display: inline-block;
  .MuiPickersStaticWrapper-root {
    .MuiToolbar-root {
      display: none;
    }
  }
  .MuiPickersBasePicker-root {
    .MuiPickersCalendarHeader-root {
      h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #2e384d;
      }
    }
  }

  .MuiPickersStaticWrapper-root {
    .MuiPickersPopper-paper {
      padding: 30px;
      background: #fff;
      box-shadow: 0px 10px 40px rgba(114, 135, 150, 0.4);
      border-radius: 3px;
    }
    .MuiPickersBasePicker-pickerView {
      min-width: 272px;
      max-width: 272px;
      min-height: 252px;

      .MuiPickersYearSelection-root {
        .MuiPickersYear-root {
          .MuiPickersYear-yearButton {
            &.Mui-disabled {
              opacity: 0.4;
            }
          }
        }
      }
    }

    .MuiPickersDesktopDateCalendar-arrowSwitcher {
      padding: 0px 12px 8px 12px;
    }

    .MuiPickersCalendarHeader-root {
      padding-left: 12px;
      .MuiPickersCalendarHeader-monthTitleContainer {
        align-items: center;
        pointer-events: none;
        .MuiPickersCalendarHeader-yearSelectionSwitcher {
          display: none !important;
        }
      }
      .MuiButtonBase-root {
        width: 32px;
        height: 32px;
        border-radius: 2px;
      }
      .MuiSvgIcon-root {
        fill: #142941;
      }

      h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: #2e384d;
      }
      .MuiPickersArrowSwitcher-root {
        .Mui-disabled {
          opacity: 0.4;
        }
        .MuiButtonBase-root {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .MuiPickersCalendar-daysHeader {
      .MuiPickersCalendar-weekDayLabel {
        color: #142941;
        font-size: 14px;
        width: 32px;
        height: 32px;
        font-weight: 700;
        font-family: 'Poppins';
      }
    }

    .MuiPickersCalendar-week {
      margin: 5px 0;

      .MuiButtonBase-root {
        border-radius: 2px;
        color: #142941;
        font-size: 14px;
        font-weight: 400;
        width: 32px;
        height: 32px;
        margin: 2px;
        font-family: 'Poppins';

        &.MuiPickersDay-today {
          border: 1px solid rgba(255, 132, 18, 1);
        }

        &.MuiPickersDay-today.Mui-selected {
          background-color: #ff8412;
        }

        &.MuiPickersDateRangeDay-dayOutsideRangeInterval:hover {
          border: 1px solid rgba(54, 153, 139, 1);
          background-color: #fff;
        }

        &.Mui-disabled {
          opacity: 0.4;
        }
        &.Mui-selected {
          color: #fff;
          background-color: #ff8412;

          &:hover {
            background-color: #ff8412;
          }
        }
      }
    }
  }
`;

export default DatepickerContainer;
