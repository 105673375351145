import {createAction} from 'redux-act';

export const INSIGHTS_PERFORMANCE_GET_INFO = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_GET_INFO'
);
export const INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS'
);
export const INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO'
);
export const INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS'
);
export const INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS'
);
export const INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO'
);
export const INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING'
);
export const INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL'
);
export const INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL'
);

export const INSIGHTS_PERFORMANCE_SET_LOADING_PAGE = createAction(
  'insightsPerformance/INSIGHTS_PERFORMANCE_SET_LOADING_PAGE'
);
