import React from 'react';
import T from 'prop-types';
import Textarea from './style';

const FieldTextarea = ({
  value,
  name,
  onChange,
  type,
  className,
  placeholder,
  disabled
}) => {
  return (
    <Textarea
      disabled={disabled}
      className={className}
      type={type}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

FieldTextarea.propTypes = {
  value: T.oneOfType([T.string, T.number]).isRequired,
  name: T.string,
  onChange: T.func.isRequired,
  type: T.string,
  className: T.string,
  placeholder: T.string,
  disabled: T.bool
};

FieldTextarea.defaultProps = {
  name: '',
  type: 'text',
  className: '',
  placeholder: '',
  disabled: false
};

export default FieldTextarea;
