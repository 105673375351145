import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardContent} from '../../../../../components/DashboardContent/styles';
import Headline from '../../../../../components/Headline/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import RestrictedArea from '../../../../../components/layout/RestrictedArea';
import {
  GET_COUNTER,
  GET_USERS,
  OPEN_CONFIRMATION_MODAL
} from '../../../../../store/usersManagement/action';
import IconAdd from '../../../../../assets/IconAdd';
import {DashboardHeader} from '../../../../../components/DashboardHeader/styles';
import LinkBack from '../../../../../components/LinkBack/styles';
import history from '../../../../../history';
import {TeamInner} from './styles';
import Team from './UsersTable/Team';
import AddUser from './Modals/AddUser';
import ResetPassword from './Modals/ResetPassword';
import MemberConfirmation from './Modals/MemberConfirmation';
import {GET_USER_LOCATIONS} from '../../../../../store/global/user/action';
import DeleteUserModalConfirm from '../../../usersManagement/Modals/DeleteTeamMember';

// eslint-disable-next-line react/prop-types
const AddButton = ({setOpenModal}) => (
  <ButtonPrimary
    className='ButtonAdd ButtonMedium'
    onClick={() => setOpenModal(true)}
  >
    <IconAdd />
    <span>Add new</span>
  </ButtonPrimary>
);

const TeamMembers = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const [modalModel, setModalModel] = useState({});
  const [openDeleteTeamMemberModal, setOpenDeleteTeamMemberModal] = useState(
    false
  );

  const {userData} = useSelector((state) => state.user);

  const {
    loading,
    pagination: {totalDocs},
    isConfirmationModalOpen
  } = useSelector((state) => state.usersManagementReducer);

  const locations = useSelector((state) => state.user.locations);

  useEffect(() => {
    if (userData) dispatch(GET_USERS());
    if (userData) dispatch(GET_COUNTER());
  }, [userData]);

  useEffect(() => {
    const isNotValidLocations = locations.some(
      (location) => !location.FullName
    );
    if (isNotValidLocations) dispatch(GET_USER_LOCATIONS());
  }, []);

  return (
    <DashboardContent className='TabletPTop DashboardContent'>
      <DashboardHeader className='whiteBgTablet'>
        <LinkBack onClick={() => history.push('/my-account')}>
          Back to My account
        </LinkBack>
      </DashboardHeader>
      <TeamInner>
        <div className='flex-center-between'>
          <Headline className='Sm'>Add your team member</Headline>
          <RestrictedArea restrictions={['userAdd']}>
            <AddButton setOpenModal={setOpenModal} />
          </RestrictedArea>
        </div>
        {totalDocs > 0 && (
          <Team
            onEdit={(user) => {
              setModalModel(user);
              setOpenModal(true);
            }}
            onResetPassword={(user) => {
              setModalModel(user);
              setOpenResetPassModal(true);
            }}
            onDelete={(user) => {
              setModalModel(user);
              setOpenDeleteTeamMemberModal(true);
            }}
          />
        )}
        {!loading && totalDocs === 0 && <div>Users were not found</div>}
      </TeamInner>
      <AddUser
        open={openModal}
        currentUser={userData}
        modalModel={modalModel}
        onClose={() => {
          setOpenModal(false);
          setModalModel({});
        }}
      />

      <MemberConfirmation
        open={isConfirmationModalOpen}
        onClose={() => dispatch(OPEN_CONFIRMATION_MODAL({open: false}))}
      />

      <ResetPassword
        open={openResetPassModal}
        modalModel={modalModel}
        onClose={() => {
          setModalModel({});
          setOpenResetPassModal(false);
        }}
      />

      <DeleteUserModalConfirm
        open={openDeleteTeamMemberModal}
        modalModel={modalModel}
        onClose={() => {
          setModalModel({});
          setOpenDeleteTeamMemberModal(false);
        }}
      />
    </DashboardContent>
  );
};

export default TeamMembers;
