import React from 'react';

export const IconCirclePlus = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='10'
      cy='10'
      r='9'
      fill='none'
      stroke='#FF2D38'
      strokeWidth='2'
    />
    <rect x='4' y='9' width='12' height='2' rx='1' fill='#FF2D38' />
    <rect
      x='11'
      y='4'
      width='12'
      height='2'
      rx='1'
      transform='rotate(90 11 4)'
      fill='#FF2D38'
    />
  </svg>
);

export default IconCirclePlus;
