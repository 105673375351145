import React from 'react';
import T from 'prop-types';
import {useSelector} from 'react-redux';
import EmptyContentBox from '../../../components/EmptyContentBox';
import {DashboardInner} from '../../../components/DashboardInner/styles';

const ElementsNotFoundMessage = (props) => {
  const searchValue = useSelector(
    (store) => store.menuEditorReducer.searchValue
  );
  const message = !searchValue
    ? props.message
    : `Sorry, we couldn’t find anything matching '${searchValue}'.`;

  return (
    <DashboardInner className='withoutShadow paddingSm'>
      <EmptyContentBox message={message} />
    </DashboardInner>
  );
};

ElementsNotFoundMessage.propTypes = {
  message: T.string
};

ElementsNotFoundMessage.defaultProps = {
  message: 'No content'
};

export default ElementsNotFoundMessage;
