import {createReducer} from 'redux-act';
import {ORDER_DIRECTION} from '../../helpers/constants/Table';
import USER from '../../helpers/constants/adminUsers';
import {
  CLEAR_FILTERS,
  CLEAR_LOCATIONS,
  CLEAR_SET_RESET_PASSWORD_USER_ID,
  CLEAR_USER,
  GET_LOCATIONS_SUCCESS,
  GET_MERCHANTS_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  PAGE_CHANGE,
  SEARCH_CHANGE,
  SET_RESET_PASSWORD_USER_ID,
  SORT_CHANGE
} from './actions';
import {
  CLEAR_OWNER_CREATION_MERCHANT_ID,
  SET_OWNER_CREATION_MERCHANT_ID
} from '../merchants/action';

const initialPaging = () => ({
  total: 0,
  limit: 10,
  pages: 0,
  page: 0
});

const initialSort = () => ({
  sortBy: USER.MERCHANT_NAME,
  sortDirection: ORDER_DIRECTION.ASC
});

const initialState = () => ({
  loading: false,
  paging: initialPaging(),
  search: '',
  sort: initialSort(),
  selectedRecord: null,
  resetPasswordUserId: null,
  data: [],
  merchants: [],
  locations: []
});

const adminUsersReducer = createReducer(
  {
    [GET_USERS]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [GET_USERS_SUCCESS]: (state, {docs, totalDocs, totalPages}) => {
      return {
        ...state,
        data: docs,
        paging: {
          ...state.paging,
          total: totalDocs,
          pages: totalPages
        },
        loading: false
      };
    },

    [GET_USER]: (state, payload) => {
      return {
        ...state,
        selectedRecord: {id: payload},
        loading: true
      };
    },
    [GET_USER_SUCCESS]: (state, payload) => {
      return {
        ...state,
        selectedRecord: payload,
        loading: false
      };
    },
    [CLEAR_USER]: (state) => {
      return {
        ...state,
        selectedRecord: null,
        locations: [],
        merchants: []
      };
    },
    [PAGE_CHANGE]: (state, payload) => {
      return {
        ...state,
        paging: {
          ...state.paging,
          page: payload
        }
      };
    },
    [SEARCH_CHANGE]: (state, payload) => {
      return {
        ...state,
        search: payload,
        paging: initialPaging()
      };
    },
    [SORT_CHANGE]: (state, payload) => {
      return {
        ...state,
        sort: {
          sortBy: payload.sortBy,
          sortDirection: payload.sortDirection
        }
      };
    },
    [CLEAR_FILTERS]: (state) => {
      return {
        ...state,
        search: '',
        sort: initialSort(),
        paging: initialPaging()
      };
    },
    [CLEAR_LOCATIONS]: (state) => {
      return {
        ...state,
        locations: []
      };
    },

    [GET_MERCHANTS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        merchants: payload
      };
    },
    [GET_LOCATIONS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        locations: payload
      };
    },
    [SET_RESET_PASSWORD_USER_ID]: (state, payload) => {
      return {
        ...state,
        resetPasswordUserId: payload
      };
    },
    [CLEAR_SET_RESET_PASSWORD_USER_ID]: (state) => {
      return {
        ...state,
        resetPasswordUserId: null
      };
    }
  },
  initialState()
);

export default adminUsersReducer;
