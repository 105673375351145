/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  DialogField,
  DialogFieldText,
  DialogFieldTitle,
  DialogHint,
  FieldInput
} from '../../routes/App/dashboard/styles';
import {ErrorSign, ErrorSignWrapper} from '../errorSign/errorSign';
import CheckboxContainer from '../Checkbox/styles';

const RulesFormField = ({formik}) => {
  return (
    <>
      <DialogField className='w100'>
        <DialogFieldTitle>Rules</DialogFieldTitle>
        <DialogHint className='mTop10'>
          <span>
            Set rules to control how customers select modifiers in this modifier
            group
          </span>
        </DialogHint>
      </DialogField>
      <DialogField className='w50 dFlex'>
        <CheckboxContainer>
          <FormControlLabel
            className='Say2EatCheckbox'
            control={
              <Checkbox
                labelStyle={{color: '#172751'}}
                iconStyle={{fill: '#FF2D38'}}
                inputStyle={{color: '#FF2D38'}}
                style={{color: '#FF2D38'}}
                onChange={formik.handleChange}
                name='isRequiredItems'
                checked={formik.values.isRequiredItems}
              />
            }
            label='Min number of modifiers customers to select an item'
          />
        </CheckboxContainer>
        {/* <ButtonInfo className='Outline ButtonInfoDrop'>
          <div className='DropInfo DropInfoCenter'>
            The rank and performance of your items based on Number of Sales per
            Item (Orders) and by Generated Revenue per Item(Sales)
          </div>
        </ButtonInfo> */}
      </DialogField>
      <DialogField className='w50 dFlex'>
        <CheckboxContainer>
          <FormControlLabel
            className='Say2EatCheckbox'
            control={
              <Checkbox
                labelStyle={{color: '#172751'}}
                iconStyle={{fill: '#FF2D38'}}
                inputStyle={{color: '#FF2D38'}}
                style={{color: '#FF2D38'}}
                onChange={formik.handleChange}
                name='isRequiredUnlimited'
                checked={formik.values.isRequiredUnlimited}
              />
            }
            label='Unlimited modifiers selection'
          />
        </CheckboxContainer>
        {/* <ButtonInfo className='Outline ButtonInfoDrop'>
          <div className='DropInfo DropInfoCenter'>
            The rank and performance of your items based on Number of Sales per
            Item (Orders) and by Generated Revenue per Item(Sales)
          </div>
        </ButtonInfo> */}
      </DialogField>
      <DialogField
        className={`w50 dFlexNoWrap ${
          !formik.values.isRequiredItems && 'isDisabled'
        } ${formik.errors && formik.errors.MinSelection && 'errors'}`}
      >
        <DialogFieldText className='mRight'>
          Min number of modifiers customers can select?
        </DialogFieldText>
        {/* <ButtonInfo className='Outline ButtonInfoDrop'>
          <div className='DropInfo DropInfoCenter'>
            The rank and performance of your items based on Number of Sales per
            Item (Orders) and by Generated Revenue per Item(Sales)
          </div>
        </ButtonInfo> */}
        <ErrorSignWrapper>
          {formik.errors && formik.errors.MinSelection && (
            <ErrorSign>{formik.errors.MinSelection}</ErrorSign>
          )}
        </ErrorSignWrapper>

        <FieldInput
          type='number'
          name='MinSelection'
          value={
            formik.values.MinSelection < 1 ? '' : formik.values.MinSelection
          }
          onChange={formik.handleChange}
          className={`${
            formik.errors && formik.errors.MinSelection && 'error'
          } Sm mLeft`}
          placeholder={
            formik.values.MinSelection < 1 ? '' : formik.values.MinSelection
          }
        />
      </DialogField>

      <DialogField
        className={`w50 dFlexNoWrap ${
          formik.values.isRequiredUnlimited && 'isDisabled'
        } `}
      >
        <DialogFieldText className='mRight'>
          Max number of modifiers customers can select?
        </DialogFieldText>
        {/* <ButtonInfo className='Outline  ButtonInfoDrop'>
          <div className='DropInfo  '>
            The rank and performance of your items based on Number of Sales per
            Item (Orders) and by Generated Revenue per Item(Sales)
          </div>
        </ButtonInfo> */}
        <ErrorSignWrapper>
          {formik.errors && formik.errors.MaxSelection && (
            <ErrorSign>{formik.errors.MaxSelection}</ErrorSign>
          )}
        </ErrorSignWrapper>

        <FieldInput
          type='number'
          name='MaxSelection'
          value={
            formik.values.MaxSelection < 1 ? '' : formik.values.MaxSelection
          }
          onChange={formik.handleChange}
          className={`${
            formik.errors && formik.errors.MaxSelection && 'error'
          } Sm mLeft`}
          placeholder={
            formik.values.MaxSelection < 1 ? '' : formik.values.MaxSelection
          }
        />
      </DialogField>
    </>
  );
};

export default RulesFormField;
