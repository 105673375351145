import React from 'react';
import PropTypes from 'prop-types';
import CheckboxS2E from '../../../../../../../components/Checkbox';
import UberPricesChangeControl from './uberPricesChangeControl';
import RoundingRadio from './roundingRadio';
import {Caption2} from '../../../../../../../components/typography';
import {DashboardField} from '../../../../../dashboard/styles';

const ObPriceReductionModule = ({values, setFieldValue, errors, touched}) => {
  let lowerAllPricesValue;
  let inflationTypeValue;
  let lowerAllPricesField;

  if (values?.data?.MenuShowUrlInput) {
    lowerAllPricesValue = values.data.lowerAllPricesURL;
    inflationTypeValue = values.data.inflationTypeURL;
    lowerAllPricesField = 'data.lowerAllPricesURL';
  } else if (values?.data?.MenuEmail) {
    lowerAllPricesValue = values.data.lowerAllPricesEmail;
    inflationTypeValue = values.data.inflationTypeEmail;
    lowerAllPricesField = 'data.lowerAllPricesEmail';
  } else {
    lowerAllPricesValue = values.data.lowerAllPrices;
    inflationTypeValue = values.data.inflationType;
    lowerAllPricesField = 'data.lowerAllPrices';
  }

  return (
    <DashboardField className='w100 paddingTBZero mTop30'>
      <CheckboxS2E
        onChange={() =>
          setFieldValue(lowerAllPricesField, !lowerAllPricesValue)
        }
        checked={lowerAllPricesValue}
        name='LowerPricesCheckbox'
        label='Lower all my menu prices automatically '
      />
      {lowerAllPricesValue && (
        <>
          <UberPricesChangeControl
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
          {inflationTypeValue === '%' && (
            <RoundingRadio values={values} setFieldValue={setFieldValue} />
          )}

          <Caption2>
            <b>Note:</b> You are about to reduce your prices in bulk. We advise
            you to check that you are happy with your new menu prices at the end
            of the onboarding process.
          </Caption2>
        </>
      )}
    </DashboardField>
  );
};

ObPriceReductionModule.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired
};

export default ObPriceReductionModule;
