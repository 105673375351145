import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import Subtitle0 from '../../../../../components/typography/Subtitle0';
import {Body0} from '../../../../../components/typography';
import {FeedbackInnerButton, ImgSurvey} from '../../styles';
import ImgSurveyActivate from '../../../../../assets/img-survey.svg';
import {
  SET_FEEDBACK_STATUS,
  SET_FEEDBACK_STATUS_SUCCESS
} from '../../../../../store/global/user/action';

const ActivateContainer = () => {
  const [isActivating, setIsActivating] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Subtitle0 className='text-center marginBottom2Xs'>
        <span>Customer’s Feedback and Reviews</span>
        <span className='breakLine'>with Post Order Survey</span>
      </Subtitle0>
      <Body0 className='text-center Medium'>
        Get customers’ feedback, know where you perform great and where you
        should improve.
        <span className='breakLine'>
          Grow your 5 stars reviews on Google My Business (GMB)
        </span>
      </Body0>
      <ImgSurvey src={ImgSurveyActivate} />
      <FeedbackInnerButton
        className={isActivating && 'disabled'}
        onClick={() =>
          dispatch(
            SET_FEEDBACK_STATUS({
              isSurveyActivated: true,
              preCallback: () => {
                setIsActivating(true);
              },
              onSuccess: () => {
                dispatch(SET_FEEDBACK_STATUS_SUCCESS(true));
                setIsActivating(false);
                toast.success('Survey has been activated successfully!');
              },
              onError: () => {
                setIsActivating(false);
                toast.error('Something went wrong! Please try again later!');
              }
            })
          )
        }
      >
        <span>Activate Survey</span>
      </FeedbackInnerButton>
    </>
  );
};

export default ActivateContainer;
