/* eslint-disable */
import {PUBLISH_STATUS_ENABLE} from '../../constants';
import {arrayFlip} from '../../../../helpers/arrayHelper.js';
import {countOutOffStock, createSingleBranch} from '../MenuBranch.js';
import {getOuterJoin} from '../../../../helpers/objectHelper.js';
import {setActiveDirection} from '../MenuList.js';

function updateCategory(store, id, data) {
  const {activeTab} = store;

  if (!store.sectionsMap.hasOwnProperty(id)) {
    return store;
  }

  // клонирование основных объектов секции
  const prevData = store.sectionsMap[id] || {};
  const section = {...prevData, ...data};
  const branch = createSingleBranch(section, 'category');
  const activeBranch = {...store.activeSectionBranchMap[id]};

  branch.$children = [...section.Items];
  activeBranch.$children = [...section.Items];

  //обновление связей для дочерних Items
  const ItemsDiff = getOuterJoin(
    arrayFlip(prevData.Items, false),
    arrayFlip(section.Items, true),
  )

  if (Object.keys(ItemsDiff).length) {
    let isChangeMap = false;

    for (const PosId in ItemsDiff) {
      if (!store.itemsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const item = {...store.itemsMap[PosId]};
      item.Sections = ItemsDiff[PosId] ? [...item.Sections, id] : item.Sections.filter(sid => sid !== id);
      store.itemsMap[PosId] = item;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.itemsMap = {...store.itemsMap};
    }
  }

  // перезапись основных объектов секции
  store.sectionsMap[id] = section;
  store.sectionsBranchMap[id] = branch;
  store.activeSectionBranchMap[id] = activeBranch;

  //обновление счетчиков для секции
  countOutOffStock(id, store);

  //чистка секции из списка скрытых, если статус поменялся
  if (activeTab === 'HIDDEN') {
      store.activeSectionsDirection = setActiveDirection(
      store.sectionsDirection.filter((id) => store.sectionsMap[id].Visible === false),
      store.activeSectionBranchMap
    );
  }

  return store;
}

export function updateOneCategory(prevStore, payload) {
  let store = {...prevStore};
  const {id, data} = payload;

  store = updateCategory(store, id, data);

  return getNextStore(store);
}

export function updateManyCategories(prevStore, payload) {
  let store = {...prevStore};
  const {ids, data} = payload;

  ids.forEach(id => (store = updateCategory(store, id, data)));

  return getNextStore(store);
}

function clearCategory(store, sectionId) {
  const oldSection = store.sectionsMap[sectionId];

  // разрываем связи с дочерними Items
  if (oldSection.Items.length) {
    let isChangeMap = false;

    for (const PosId of oldSection.Items) {
      if (!store.itemsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const item = {...store.itemsMap[PosId]};
      item.Sections = item.Sections.filter(sid => sid !== sectionId);
      store.itemsMap[PosId] = item;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.itemsMap = {...store.itemsMap};
    }
  }

  delete store.sectionsMap[sectionId];
  delete store.sectionsBranchMap[sectionId];
  delete store.activeSectionBranchMap[sectionId];

  return store;
}

export function clearOneCategory(prevStore, sectionId) {
  let store = {...prevStore};
  const filterDirection = (id) => id !== sectionId;

  store = clearCategory(store, sectionId);
  store.sectionsDirection = store.sectionsDirection.filter(filterDirection);
  store.activeSectionsDirection = store.activeSectionsDirection.filter(filterDirection);

  return getNextStore(store);
}

export function clearManyCategories(prevStore, ids=[]) {
  let store = {...prevStore};
  const idsMap = arrayFlip(ids);
  const filterDirection = (id) => !idsMap.hasOwnProperty(id);

  ids.forEach(sectionId => (store = clearCategory(store, sectionId)));
  store.sectionsDirection = store.sectionsDirection.filter(filterDirection);
  store.activeSectionsDirection = store.activeSectionsDirection.filter(filterDirection);

  return getNextStore(store);
}

function getNextStore(store) {
  return {
    ...store,
    sectionsMap: {...store.sectionsMap},
    sectionsBranchMap: {...store.sectionsBranchMap},
    activeSectionBranchMap: {...store.activeSectionBranchMap},
    publishStatus: PUBLISH_STATUS_ENABLE
  }
}