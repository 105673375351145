import styled from 'styled-components';

export const TeamTableWrapper = styled.div`
  margin-top: 30px;
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #232d3a;
    padding-right: 20px;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;
  }
  .MuiTable-root {
    tr {
      &:first-child {
        th {
          padding-top: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 15%;
          }
        }
      }
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .MuiTableRow-root {
    vertical-align: top;
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;

export default TeamTableWrapper;
