import React from 'react';

export const IconUnSelectAll = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 19C14 19.5523 13.5523 20 13 20H2C0.89543 20 0 19.1046 0 18V7C0 6.44771 0.447715 6 1 6C1.55228 6 2 6.44771 2 7V18H13C13.5523 18 14 18.4477 14 19ZM18 16H6C4.89543 16 4 15.1046 4 14V2C4 0.89543 4.89543 0 6 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM6 2V14H18V2H6Z'
      fill='#2F3D48'
    />
  </svg>
);

export default IconUnSelectAll;
