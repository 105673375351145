import {toast} from 'react-toastify';
import {put, takeEvery} from 'redux-saga/effects';
import {
  DELETE_MENU_QUEUE_ITEM,
  DELETE_MENU_QUEUE_ITEM_SUCCESS,
  GET_MENU_QUEUE,
  GET_MENU_QUEUE_SUCCESS,
  SAVE_MENU_QUEUE_ITEM,
  SAVE_MENU_QUEUE_ITEM_SUCCESS,
  UPDATE_MENU_QUEUE_ITEM,
  UPDATE_MENU_QUEUE_ITEM_SUCCESS
} from './action';
import {
  getMenuQueueApi,
  deleteMenuQueueApi,
  saveMenuQueueApi,
  updateMenuQueueApi
} from '../../helpers/services/api/menuQueue';

function* getMenuQueue() {
  try {
    const {data} = yield getMenuQueueApi();
    if (data) {
      yield put(GET_MENU_QUEUE_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* saveMenuQueue({payload}) {
  try {
    const {data} = yield saveMenuQueueApi(payload);
    if (data) {
      yield put(SAVE_MENU_QUEUE_ITEM_SUCCESS(data));
      toast.success('Item create success');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* updateMenuQueue({payload}) {
  try {
    const {data} = yield updateMenuQueueApi(payload);
    if (data) {
      yield put(UPDATE_MENU_QUEUE_ITEM_SUCCESS(payload));
      toast.success('Item update success');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* deleteMenuQueue({payload}) {
  try {
    const {data} = yield deleteMenuQueueApi(payload);

    if (data) {
      yield put(DELETE_MENU_QUEUE_ITEM_SUCCESS(payload));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* menuQueueSaga() {
  yield takeEvery(GET_MENU_QUEUE, getMenuQueue);
  yield takeEvery(SAVE_MENU_QUEUE_ITEM, saveMenuQueue);
  yield takeEvery(UPDATE_MENU_QUEUE_ITEM, updateMenuQueue);
  yield takeEvery(DELETE_MENU_QUEUE_ITEM, deleteMenuQueue);
}

export default menuQueueSaga;
