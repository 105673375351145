import styled from 'styled-components';

const PhoneOrderingContainer = styled.div`
  .RCSearch {
    .DashboardFields {
      @media (max-width: 720px) {
        display: block;
        .wAuto {
          padding-top: 0;
        }
      }
    }
  }
`;

export default PhoneOrderingContainer;
