import {put, select, takeEvery} from 'redux-saga/effects';
import {
  cancelOrderDelivery,
  getDeliveryStatusApi,
  getDispatchInfoApi,
  getOrderApi
} from '../../../helpers/services/api/orders';
import {
  CANCEL_DELIVERY_ORDER,
  CHANGE_STATUS,
  CHANGE_STATUS_SUCCESS,
  GET_DELIVERY_STATUS,
  GET_DELIVERY_STATUS_SUCCESS,
  GET_DISPATCH_INFO,
  GET_DISPATCH_INFO_SUCCESS,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  OPEN_CANCEL_DELIVERY_ORDER_MODAL,
  OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL
} from './action';
import {changeStatusActiveOrder} from '../../global/orders/saga';
import {changeStatusHistory} from '../orderList/saga';
import {SET_LOADING_CANCELLATION} from '../../global/modals/action';

export function* getOrder({payload}) {
  try {
    const {Timezone} = yield select((state) => state.user.activeLocation);
    const {data} = yield getOrderApi(payload);

    if (data) {
      yield put(GET_ORDER_SUCCESS({Timezone, ...data}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getDispatchInfo({payload}) {
  try {
    const {data} = yield getDispatchInfoApi(payload);

    if (data) {
      yield put(GET_DISPATCH_INFO_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* changeStatus({payload: {location, ...values}}) {
  try {
    const order = yield select((state) => state.orderDetailsReducer.order);

    yield put(SET_LOADING_CANCELLATION(true));
    let success;

    if (location === 'history') {
      success = yield changeStatusHistory({
        payload: {...values},
        orderId: order?.id
      });
    } else {
      success = yield changeStatusActiveOrder({
        payload: {...values},
        orderId: order?.id
      });
    }

    if (success) {
      yield put(CHANGE_STATUS_SUCCESS(success));
    }
    yield put(SET_LOADING_CANCELLATION(false));
  } catch (error) {
    yield put(SET_LOADING_CANCELLATION(false));
    console.log('SAGA ERROR', error);
  }
}

function* getDeliveryStatus({payload}) {
  try {
    const {data} = yield getDeliveryStatusApi(payload);

    if (data) {
      yield put(GET_DELIVERY_STATUS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* cancelDeliveryOrder({payload}) {
  try {
    const {status} = yield cancelOrderDelivery(payload.deliveryId, {
      reasonComment: payload.reasonComment
    });

    if (status === 200) {
      yield put(GET_ORDER(payload.orderId));
      yield put(GET_DELIVERY_STATUS(payload.deliveryId));
      yield put(OPEN_CANCEL_DELIVERY_ORDER_MODAL(false));
      yield put(
        OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL({
          isOpenModal: true,
          message: 'Driver has been successfully canceled'
        })
      );
    }
  } catch (error) {
    yield put(OPEN_CANCEL_DELIVERY_ORDER_MODAL(false));
    yield put(GET_DELIVERY_STATUS(payload.deliveryId));
    yield put(
      OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL({
        isOpenModal: true,
        message: 'Sorry, driver cannot be canceled'
      })
    );
    console.log('SAGA ERROR', error);
  }
}

function* orderDetailsSaga() {
  yield takeEvery(GET_ORDER, getOrder);
  yield takeEvery(CHANGE_STATUS, changeStatus);
  yield takeEvery(GET_DISPATCH_INFO, getDispatchInfo);
  yield takeEvery(GET_DELIVERY_STATUS, getDeliveryStatus);
  yield takeEvery(CANCEL_DELIVERY_ORDER, cancelDeliveryOrder);
}

export default orderDetailsSaga;
