import React from 'react';

const IconSidebarHelp = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 1C4.13906 1 1 4.13906 1 8C1 11.8609 4.13906 15 8 15C11.8609 15 15 11.8609 15 8C15 4.13906 11.8609 1 8 1ZM7.70742 12.6676C7.22617 12.6676 6.83242 12.2738 6.83242 11.7926C6.83242 11.3113 7.22617 10.9176 7.70742 10.9176C8.18867 10.9176 8.58242 11.3113 8.58242 11.7926C8.58242 12.2738 8.19141 12.6676 7.70742 12.6676ZM8.58242 9.10742V10.0398C8.58242 10.2012 8.45117 10.3324 8.28984 10.3324H7.125C6.96367 10.3324 6.83242 10.2012 6.83242 10.0398V8.58242C6.83242 7.93984 7.35469 7.41484 8 7.41484C8.64531 7.41484 9.16758 6.89258 9.16758 6.24727C9.16758 5.60195 8.64531 5.07969 8 5.07969C7.35469 5.07969 6.83242 5.60195 6.83242 6.24727V6.53984C6.83242 6.70117 6.70117 6.83242 6.53984 6.83242H5.375C5.21367 6.83242 5.08242 6.70117 5.08242 6.53984V6.25C5.08242 4.64219 6.39219 3.33242 8 3.33242C9.60781 3.33242 10.9176 4.64219 10.9176 6.25C10.9176 7.65 9.93047 8.83672 8.58242 9.10742Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarHelp;
