export const FIND_DELIVERY = {
  ID: 'id',
  STATUS: 'status',
  CUSTOMER: 'customer',
  CUSTOMER_NAME: 'customerName',
  CUSTOMER_PHONE: 'customerPhone',
  RESTAURANT_NAME: 'restaurantName',
  TIMEZONE: 'restaurantTimezone',
  IS_CATERING: 'isCatering',
  ORDER_AMOUNT: 'amount',
  TIP: 'tips',
  FIRST_TEN_ORDERS: 'restaurantFirst10Orders',
  LOCATION_TIER: 'restaurantTier',
  IS_FUTURE_ORDER: 'isFutureOrder',
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  DROPOFF: 'dropoff',
  LOCATION: 'location',
  MERCHANT: 'merchant',
  RISK_LEVEL: 'riskLevel',
  COURIER: 'courier',
  ACTIONS: 'actions',
  CORE_ORD_NOTE: 'specialNote',
  CORE_ID: 'coreOrderId',
  ORDER_ITEMS_COUNT: 'itemsCount',
  SOURCE: 'source',
  USER_TRACKING_LINK: 'userTrackingLink',
  RESTAURANT_TRACKING_LINK: 'restaurantTrackingLink',
  OLD_DISPATCH_PROVIDER_TRACKING_LINK: 'oldDispatchProviderTrackingLink',
  NEW_DISPATCH_PROVIDER_TRACKING_LINK: 'newDispatchProviderTrackingLink',
  DISPATCH_VERSION: 'dispatchVersion',
  CREATED_AT: 'createdAt'
};

export const FIND_DELIVERY_STATUS = {
  SCHEDULED: 'Scheduled',
  PICKED_UP: 'PickedUp',
  ASSIGNED: 'Assigned',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  PICKUP_ENROUTE: 'EnRouteToPickup',
  PICKUP_ARRIVED: 'ArrivedToPickup',
  DROPOFF_ENROUTE: 'EnRouteToDropOff',
  DROPOFF_ARRIVED: 'ArrivedToDropOff',
  FUTURE_ORDER: 'Future',
  FAILED: 'Failed',
  RETURN_ENROUTE: 'EnRouteToReturn',
  RETURNED: 'Returned',
  SEARCHING: 'Searching',
  CANCELLATION_FAILED: 'CancellationFailed'
};

export const FIND_DELIVERY_STATUS_NAME = {
  Unknown: 'Unknown',
  Scheduled: 'Scheduled',
  Searching: 'Searching',
  Assigned: 'Assigned',
  EnRouteToPickup: 'Pickup enroute',
  ArrivedToPickup: 'At the restaurant',
  PickedUp: 'Picked up',
  EnRouteToDropOff: 'Dropoff enroute',
  ArrivedToDropOff: 'At drop off',
  EnRouteToReturn: 'Return enroute',
  Delivered: 'Dropped off',
  Cancelled: 'Cancelled',
  CancellationFailed: 'Cancellation failed',
  Failed: 'Delivery failed',
  Returned: 'Returned',
  Future: 'Future order'
};

export const FIND_DELIVERY_CANCEL_SOURCE = {
  SAUCE: 'Sauce',
  CUSTOMER: 'Customer',
  PROVIDER: 'Provider'
};

export const FIND_DELIVERY_TRACKING_LINKS_TYPES = {
  USER: 'customer_tracking_link',
  RESTAURANT: 'location_tracking_link',
  OLD_PROVIDER: 'old_dispatch_tracking_link',
  NEW_PROVIDER: 'provider_tracking_link'
};
export const FIND_DELIVERY_DISPATCH_VERSION_VALUE = {
  v1: 'Old Dispatch',
  v2: 'New Dispatch'
};

export const FIND_DELIVERY_DISPATCH_VERSION = {
  DISPATCH_V1: 'v1',
  DISPATCH_V2: 'v2'
};
