import styled from 'styled-components';
import IconBgDecor from '../../../../assets/icon-arrow-hint.svg';

const PublishDrop = styled.div`
  position: absolute;
  top: calc(100% + 20px);
  right: -70px;
  width: 400px;
  background-color: #fff;
  border: 2px solid #ff2d38;
  border-radius: 10px;
  padding: 22px 30px;
  z-index: 9;
  transition-property: transform, opacity, visibility;
  transition-duration: 0.3s;

  @media (max-width: 1650px) {
    width: 390px;
    padding: 20px 26px;
    right: -20px;
  }
  @media (max-width: 767px) {
    width: 360px;
    padding: 18px 22px;
    right: -15px;
  }
  @media (max-width: 440px) {
    width: 320px;
  }
  @media (max-width: 350px) {
    width: 310px;
    padding: 16px;
  }

  .PublishDropInfo {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 auto;
      width: 64px;
      height: 64px;
      display: inline-block;
      @media (max-width: 1650px) {
        width: 58px;
        height: 58px;
      }
      @media (max-width: 767px) {
        width: 48px;
        height: 48px;
      }
      @media (max-width: 440px) {
        width: 44px;
        height: 44px;
      }
      @media (max-width: 350px) {
        width: 40px;
        height: 40px;
      }
    }

    .PublishDropText {
      flex: 1 1 auto;
      text-align: left;
      padding-left: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: #172751;
      @media (max-width: 767px) {
        font-size: 15px;
      }
      @media (max-width: 440px) {
        padding-left: 12px;
        font-size: 14px;
      }
    }
  }

  .PublishDropActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    & > * {
      margin-left: 20px;
      flex: 0 0 auto;
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &::after {
    position: absolute;
    bottom: 100%;
    right: 128px;
    content: '';
    width: 35px;
    height: 10px;
    background-image: url('${IconBgDecor}');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1650px) {
      right: 80px;
    }
    @media (max-width: 599px) {
      right: 17px;
    }
  }

  .PublishDropOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2f3d48;
    opacity: 0.7;
  }
`;

export default PublishDrop;
