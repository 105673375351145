/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import sha256 from 'crypto-js/sha256';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {
  ElevatedPaper,
  FlexContainer,
  FlexItem,
  TopLineNav
} from 'components/layout';
import {ButtonPrimary} from 'components/buttons';
import {Body1, Caption1, Subtitle2} from 'components/typography';
import {
  BillSection,
  EtaClock,
  OrderDetailsHeader,
  OrderRBottom,
  OrderRSide,
  OrderRTop
} from 'routes/App/orders/OrderDetails/styles';
import {FieldTextarea} from 'routes/App/dashboard/styles';
import PrinterIcon from 'assets/IconPrinter';
import {
  CHANGE_STATUS,
  GET_DELIVERY_STATUS,
  GET_DISPATCH_INFO,
  GET_ORDER,
  OPEN_CANCEL_DELIVERY_ORDER_MODAL
} from 'store/orders/orderDetails/action';
import Loading from 'components/shared/Utils/Loading';
import {CHANGE_ORDER_INFO} from 'store/orders/orderList/action';
import Bill from 'routes/App/orders/components/Bill';
import LinkBack from 'components/LinkBack/styles';
import AdjustOrRefundPayment from 'routes/App/orders/modals/AdjustOrRefund';
import ButtonOutline from 'components/buttons/ButtonOutline/styles';
import useNetwork from 'hooks/useNetwork';
import PulsatingDot from 'components/PulsatingDot';
import {
  AVAILABLE_ORDER_STATUSES,
  ORDER,
  ORDER_STATUS
} from 'helpers/constants/orders';
import StatusButton from 'routes/App/orders/OrdersList/components/StatusButton';
import {
  checkIsFullNotTier1RefundOrder,
  checkIsFullRefundOrder,
  checkIsFutureOrder,
  checkIsPossibleToCancelDriver,
  checkIsRefundOrder,
  filterTier1Refunds
} from 'helpers/functionUtils/ordersUtils';
import DashboardContent from 'components/DashboardContent/styles';
import DashboardInner from 'components/DashboardInner/styles';
import {phoneFormatForView} from 'helpers/functionUtils/helpFunctions';
import {usePrevious} from 'hooks/usePrevious';
import IconCar from 'assets/IconCar';
import {SET_BAD_ORDER_DETAIL} from 'store/bookADriver/bookADriverCreate/action';
import {
  FEATURE_RESTRICTION_IDS,
  STRIPE_ACCOUNT_RESTRICTION_NAMES
} from 'helpers/constants/restrictions';
import RestrictedArea from 'components/layout/RestrictedArea';
import IconDollarRefund from 'assets/IconDollarRefund';
import {CLEAR_REFUND_DATA} from 'store/orders/orderRefund/action';
import IconRefundOrderDetails from 'assets/IconRefundOrderDetails';
import {getSessionStorageItem} from 'helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from 'helpers/sessionStorage/constants';
import IconOrderAdjustment from 'assets/icon-order-adjustment.svg';
import IconOrderAdjust from 'assets/IconOrderAdjust';
import {OPEN_ADJUST_MODAL} from 'store/orders/orderAdjust/action';
import AdjustOrderModal from 'routes/App/orders/OrderDetails/Adjust';
import AdjustSuccessModal from 'routes/App/orders/OrderDetails/Adjust/Modals/AdjustSuccessModal';
import AdjustErrorModal from 'routes/App/orders/OrderDetails/Adjust/Modals/AdjustErrorModal';
import {OPEN_CANCELLATION_CONFIRMATION_MODAL} from 'store/global/modals/action';
import CancelDeliveryOrderModal from '../modals/CancelDeliveryOrderModal';
import CancelDeliveryOrderResultModal from '../modals/CancelDelivaryOrderResultModal';

export default function OrderDetails() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const [isOnline] = useNetwork();

  const {
    order,
    loading,
    dispatchInfo,
    changeStatusLoading,
    deliveryStatus,
    isOpenCancelDeliveryOrderModal,
    loadingCancellation,
    deliveryCancellationResult
  } = useSelector(({orderDetailsReducer}) => ({
    loading: orderDetailsReducer.loading,
    order: orderDetailsReducer.order,
    dispatchInfo: orderDetailsReducer.dispatchInfo,
    changeStatusLoading: orderDetailsReducer.changeStatusLoading,
    deliveryStatus: orderDetailsReducer.deliveryStatus,
    isOpenCancelDeliveryOrderModal:
      orderDetailsReducer.isOpenCancelDeliveryOrderModal,
    loadingCancellation: orderDetailsReducer.loadingCancellation,
    deliveryCancellationResult: orderDetailsReducer.deliveryCancellationResult
  }));
  const {locationName, activeLocation, locationId} = useSelector(
    (state) => state.user
  );

  const {isNetworkOnline} = useSelector(
    (state) => state.globalNotificationsReducer
  );

  const {
    isOpenAdjustModal,
    isOpenSuccessModal,
    isOpenErrorModal,
    errorMessage
  } = useSelector((state) => state.orderAdjustReducer);

  const prevLocation = usePrevious(locationId);
  const [notesValue, setNotesValue] = useState(order.InternalNotes || '');

  const redirectToPreviousRoute = (state = {}) => {
    history.push(
      params.back === 'history' ? '/orders/history' : '/orders/active',
      state
    );
  };
  const redirectToRefundRoute = (orderId, tier1Redirect = false) => {
    dispatch(CLEAR_REFUND_DATA());
    history.push({
      pathname:
        params.back === 'history'
          ? `/orders/history/${orderId}/details/refund?tier1=${tier1Redirect}`
          : `/orders/active/${orderId}/details/refund?tier1=${tier1Redirect}`
    });
  };

  useEffect(() => {
    if (prevLocation) {
      redirectToPreviousRoute();
    }
  }, [locationId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (order.id !== params.id) {
      dispatch(GET_ORDER(params.id));
    }

    //TODO: Very dirty hack to avoid details clearing after back from refunds
    // needed to be refactored
    // return () => {
    //   dispatch(ORDER_UNMOUNT());
    // };
  }, []);

  useEffect(() => {
    if (order.InternalNotes) {
      setNotesValue(order.InternalNotes);
    }
  }, [order.InternalNotes]);

  useEffect(() => {
    if (order?.orderInfo?.deliveryId && isOnline) {
      dispatch(GET_DELIVERY_STATUS(order?.orderInfo?.deliveryId));
      dispatch(GET_DISPATCH_INFO(order?.orderInfo?.deliveryId));
    }
  }, [order?.orderInfo?.deliveryId, isOnline]);

  const [locationState] = useState(location?.state?.windowPosition);

  const ref = useRef();

  const [showAdjustRefundSelector, setShowAdjustRefundSelector] = useState(
    false
  );

  const onStatusChange = (id, status, previousStatus, deliveryProvider) => {
    if (status !== previousStatus && !changeStatusLoading && isNetworkOnline) {
      if (status === ORDER_STATUS.CANCELLED) {
        dispatch(
          OPEN_CANCELLATION_CONFIRMATION_MODAL({
            isOpen: true,
            info: {id, status, previousStatus, location: params.back},
            source: 'details',
            deliveryProvider
          })
        );
      } else {
        dispatch(
          CHANGE_STATUS({id, status, previousStatus, location: params.back})
        );
      }
    }
  };

  const checkIfSauceEmployee = () => {
    return getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
  };

  const getPricePerItem = (orderItem) => {
    let pricePerItem = Number(orderItem.pricePerUnit || 0);
    if (orderItem.itemOptions && orderItem.itemOptions.length) {
      orderItem.itemOptions.forEach(
        (option) =>
          option.items &&
          option.items.length &&
          option.items.forEach((optionItem) => {
            pricePerItem += getPricePerItem(optionItem);
          })
      );
    }
    return pricePerItem;
  };

  const getMerchantTax = (refund) => {
    let tax = 0;
    refund.items.forEach((item) => {
      tax +=
        (Number(getPricePerItem(order.orderInfo.items[item.itemIndex])) *
          Number(item.quantity) *
          Number(order.orderInfo.taxPercent)) /
        100;
    });
    return tax;
  };

  return (
    <DashboardContent className='DashboardContent'>
      <TopLineNav>
        <LinkBack
          onClick={() => {
            redirectToPreviousRoute({windowPosition: locationState});
          }}
        >
          Back To All Orders
        </LinkBack>
      </TopLineNav>
      <DashboardInner className='orderDetailsPaddingBottom'>
        <OrderDetailsHeader className='marginBottomMd'>
          <div className='OrderDetailsActions'>
            <RestrictedArea
              restrictions={[]}
              features={[FEATURE_RESTRICTION_IDS.REFUNDS]}
              extraAccessLogic={() =>
                activeLocation.StripeAccountType !==
                STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD
              }
            >
              {!checkIfSauceEmployee() && (
                <ButtonOutline
                  className={cn('ButtonSmall marginRightMd buttonRefund', {
                    disabledRefundButton: checkIsFullRefundOrder(order)
                  })}
                  onClick={() => {
                    redirectToRefundRoute(order?.id, false);
                  }}
                  withIcon
                >
                  <IconDollarRefund />
                  Refund
                </ButtonOutline>
              )}
            </RestrictedArea>
            <RestrictedArea
              restrictions={[]}
              features={[FEATURE_RESTRICTION_IDS.PAYMENT_ADJUSTMENT]}
            >
              <ButtonOutline
                className='ButtonSmall marginRightMd buttonRefund'
                onClick={() => {
                  dispatch(OPEN_ADJUST_MODAL(true));
                }}
                withIcon
              >
                <IconOrderAdjust />
                <span>Adjust</span>
              </ButtonOutline>
            </RestrictedArea>
            <ButtonOutline
              data-gtm={'trackDeliveryButton'}
              className={cn('ButtonSmall', {
                disabled: !order?.orderInfo?.mapUrl
              })}
              onClick={() => {
                if (order?.orderInfo?.mapUrl) {
                  window.open(order?.orderInfo?.mapUrl, '_blank');
                }
              }}
            >
              Track Delivery
            </ButtonOutline>

            <ReactToPrint
              pageStyle={`
                  @media print {
                    html, body {
                      width: 80mm;
                      background-color: white !important;
                       }
                      }  
                    `}
              trigger={() => (
                <ButtonOutline
                  className='ButtonSmall secondary'
                  data-gtm='printButton'
                  withIcon
                >
                  <PrinterIcon data-gtm='printButton' />
                  Print 80mm
                </ButtonOutline>
              )}
              content={() => ref.current}
            />
          </div>
          <RestrictedArea
            restrictions={[]}
            features={[FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER]}
          >
            <ButtonPrimary
              data-gtm='bookADriverButton'
              onClick={() => {
                dispatch(SET_BAD_ORDER_DETAIL(order));
                history.push(`/book-a-driver/create-order`, {
                  windowPosition: window.scrollY
                });
              }}
              className='ButtonMedium ButtonDriver'
            >
              <IconCar data-gtm='bookADriverButton' />
              <span data-gtm='bookADriverButton'>Book a Driver</span>
            </ButtonPrimary>
          </RestrictedArea>
        </OrderDetailsHeader>

        {loading ? (
          <Loading />
        ) : (
          <>
            {checkIsFutureOrder(order) && (
              <div className='marginBottomSm'>
                <PulsatingDot
                  classNameDot='orderDetails'
                  classNamePulsating='orderDetails'
                />
                <Subtitle2 className='marginLeft2l'>
                  <b>Note</b>: this is a future order. ETA is{' '}
                  <b>
                    {moment(order.date).utcOffset(0).format('MMMM D, h:mm A')}
                  </b>
                </Subtitle2>
              </div>
            )}
            {!!order.adjustments?.length && (
              <div className='marginBottomXl orderDetailsRefund'>
                <img src={IconOrderAdjustment} alt='Adj' />
                <Subtitle2>
                  Order total was <b>adjusted </b>
                  on{' '}
                  {moment(
                    order.adjustments[order.adjustments.length - 1].CreatedAt
                  )
                    .utcOffset(activeLocation?.Timezone || 0, false)
                    .utc()
                    .format('DD MMMM YYYY')}
                </Subtitle2>
              </div>
            )}
            {checkIsRefundOrder(order) &&
              filterTier1Refunds(order).map((item) => (
                <div className='marginBottomXl orderDetailsRefund'>
                  <IconRefundOrderDetails />
                  <Subtitle2>
                    This order was{' '}
                    <b>
                      {item?.type === 'Full' ? 'fully' : 'partially'} refunded{' '}
                    </b>
                    on{' '}
                    {moment(item?.createdAt)
                      .utcOffset(0)
                      .format('DD MMMM YYYY')}
                  </Subtitle2>
                </div>
              ))}
            {deliveryStatus?.status === 'Canceled' && (
              <div className='marginBottomXl'>
                <Subtitle2>
                  Order delivery has been <b>canceled</b>
                  {deliveryStatus?.lastUpdateDate && (
                    <>
                      {' '}
                      on{' '}
                      {moment(deliveryStatus?.lastUpdateDate)
                        .utcOffset(activeLocation?.Timezone || 0, false)
                        .utc()
                        .format('DD MMMM YYYY')}
                    </>
                  )}
                </Subtitle2>
              </div>
            )}

            <FlexContainer className='OrderDetails'>
              <FlexItem className='OrderDetailsLside'>
                <BillSection>
                  <ElevatedPaper>
                    <Bill
                      order={order}
                      locationName={locationName}
                      timezone={activeLocation.Timezone}
                    />
                  </ElevatedPaper>
                </BillSection>
                <div style={{display: 'none'}}>
                  <Bill
                    ref={ref}
                    order={order}
                    locationName={locationName}
                    useSalesPackage={activeLocation.UseSalesPackage}
                    timezone={activeLocation.Timezone}
                  />
                </div>
              </FlexItem>
              <FlexItem>
                <OrderRSide>
                  <OrderRTop>
                    <Body1 className='block marginBottomLg'>
                      <div
                        style={
                          !isNetworkOnline ||
                          changeStatusLoading ||
                          order[ORDER.ORDER_STATUS] === ORDER_STATUS.CANCELLED
                            ? {opacity: '0.5', pointerEvents: 'none'}
                            : null
                        }
                      >
                        <StatusButton
                          status={order[ORDER.ORDER_STATUS]}
                          order={order}
                          onStatusChange={(status) => {
                            onStatusChange(
                              order[ORDER.ID],
                              status,
                              order[ORDER.ORDER_STATUS],
                              order[ORDER.DELIVERY_PROVIDER]
                            );
                          }}
                        />
                      </div>
                    </Body1>

                    {deliveryStatus?.status &&
                      checkIsPossibleToCancelDriver(order) &&
                      AVAILABLE_ORDER_STATUSES.includes(
                        deliveryStatus?.status
                      ) && (
                        <Body1 className='block marginBottomLg'>
                          <ButtonOutline
                            onClick={() =>
                              dispatch(OPEN_CANCEL_DELIVERY_ORDER_MODAL(true))
                            }
                            className='ButtonSmall'
                          >
                            Cancel driver
                          </ButtonOutline>
                        </Body1>
                      )}

                    <Body1 className='block marginBottomLg'>
                      <EtaClock className='inlineBlock marginRightXs'>
                        ETA:
                      </EtaClock>
                      <strong>
                        {order.date &&
                          moment(order.date)
                            .utcOffset(0)
                            .format('MMMM D, h:mm A')}
                      </strong>
                    </Body1>

                    <Body1 className='block marginBottomLg'>
                      <span className='inlineBlock marginRightXs'>
                        Delivery address:
                      </span>
                      <strong>
                        {order.orderInfo.deliveryAddress || 'N/A'}
                      </strong>
                    </Body1>

                    <Body1 className='block marginBottomLg'>
                      <span className='inlineBlock marginRightXs'>
                        Vehicle Description:
                      </span>
                      <strong>
                        {order.orderInfo.curbsideInstructions || 'N/A'}
                      </strong>
                    </Body1>

                    {order?.orderInfo?.deliveryProvider !==
                      'Say2eat Dispatch V2' && (
                      <Body1 className='block marginBottomLg'>
                        <span className='inlineBlock marginRightXs'>
                          Delivery Reference Number:
                        </span>
                        <strong>
                          {dispatchInfo?.DeliveryReferenceNumber || 'N/A'}
                        </strong>
                      </Body1>
                    )}
                  </OrderRTop>
                  <OrderRBottom>
                    <Caption1 className='block marginBottomXs'>
                      Internal Notes
                    </Caption1>

                    <FieldTextarea
                      name=''
                      value={notesValue}
                      onChange={(e) => setNotesValue(e.target.value)}
                      className='FieldTextareaLg'
                      placeholder='Write your notes here'
                    />

                    <ButtonPrimary
                      className='ButtonOrderDetailsMargin'
                      data-gtm={'saveNotes'}
                      onClick={() =>
                        dispatch(
                          CHANGE_ORDER_INFO({
                            id: params.id,
                            note: notesValue
                          })
                        )
                      }
                    >
                      <span>Save Notes</span>
                    </ButtonPrimary>
                  </OrderRBottom>
                </OrderRSide>
              </FlexItem>
            </FlexContainer>
          </>
        )}
      </DashboardInner>

      {showAdjustRefundSelector && (
        <AdjustOrRefundPayment
          onClose={() => setShowAdjustRefundSelector(false)}
          openAdjustPayment={() => {
            history.push(`${history.location.pathname}/adjust`);
            setShowAdjustRefundSelector(false);
          }}
          openRefundPayment={() => {
            history.push(`${history.location.pathname}/refund`);
            setShowAdjustRefundSelector(false);
          }}
        />
      )}
      {isOpenAdjustModal && (
        <AdjustOrderModal
          isOpen={isOpenAdjustModal}
          invoiceId={order?.invoiceID}
          orderId={order?.id}
        />
      )}
      {isOpenSuccessModal && <AdjustSuccessModal isOpen={isOpenSuccessModal} />}
      {isOpenErrorModal && (
        <AdjustErrorModal
          isOpen={isOpenErrorModal}
          errorMessage={errorMessage}
        />
      )}
      <CancelDeliveryOrderModal
        open={isOpenCancelDeliveryOrderModal}
        deliveryId={order?.orderInfo?.deliveryId}
        orderId={order?.id}
        loadingCancellation={loadingCancellation}
      />
      <CancelDeliveryOrderResultModal
        open={deliveryCancellationResult.isOpenModal}
        message={deliveryCancellationResult.message}
      />
    </DashboardContent>
  );
}
