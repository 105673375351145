import {createAction} from 'redux-act';

export const AUTH_USER_SUCCESS = createAction('auth/AUTH_USER_SUCCESS');
export const AUTH_USER = createAction('auth/AUTH_USER');

export const AUTH_CHAT_USER = createAction('auth/AUTH_CHAT_USER');
export const AUTHENTICATION_FETCHING_SUCCESS = createAction(
  'auth/AUTHENTICATION_FETCHING_SUCCESS'
);
export const LOGOUT_USER = createAction('auth/LOGOUT_USER');
export const LOGOUT_USER_SUCCESS = createAction(
  'auth/LOGOUT_USER_SUCCESS',
  () => {
    const appVersion = localStorage.getItem('appVersion');
    const currentLocationId = localStorage.getItem('locationId');
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('appVersion', appVersion);
    localStorage.setItem('locationId', currentLocationId);
  }
);
export const FORGOT_PASSWORD = createAction('auth/FORGOT_PASSWORD');
export const FORGOT_PASSWORD_STATE_CHANGE = createAction(
  'auth/FORGOT_PASSWORD_STATE_CHANGE'
);
export const UPDATE_PASSWORD = createAction('auth/UPDATE_PASSWORD');
export const UPDATE_MEMBER_PASSWORD = createAction(
  'auth/UPDATE_MEMBER_PASSWORD'
);
export const REFRESH_TOKEN = createAction('auth/REFRESH_TOKEN');
export const LOGIN_ACTION = createAction('auth/LOGIN_ACTION');
export const SAVE_USER_EMAIL = createAction('auth/SAVE_USER_EMAIL');
export const SAVE_USER_EMAIL_SUCCESS = createAction(
  'auth/SAVE_USER_EMAIL_SUCCESS'
);
export const SIGNUP_USER = createAction('auth/SIGNUP_USER');
export const SIGNUP_USER_SUCCESS = createAction('auth/SIGNUP_USER_SUCCESS');
export const SIGNUP_USER_FAILED = createAction('auth/SIGNUP_USER_FAILED');

export const SELF_SIGNUP_USER = createAction('auth/SELF_SIGNUP_USER');
export const SELF_SIGNUP_USER_SUCCESS = createAction(
  'auth/SELF_SIGNUP_USER_SUCCESS'
);
export const SELF_SIGNUP_USER_FAILED = createAction(
  'auth/SELF_SIGNUP_USER_FAILED'
);

export const RETURN_TO_OLD_DASHBOARD = createAction(
  'auth/RETURN_TO_OLD_DASHBOARD'
);
export const SET_ENTERED_EMAIL = createAction('auth/SET_ENTERED_EMAIL');
export const SET_FETCHING = createAction('auth/SET_FETCHING');
