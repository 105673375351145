import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReducerManager from './reducers';
import rootSaga from './sagas';
import {LOGOUT_USER_SUCCESS} from './global/auth/action';
import {
  ADD_DYNAMIC_REDUCER,
  ADD_DYNAMIC_SAGA,
  REMOVE_DYNAMIC_REDUCER
} from './global/user/action';

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const a = composeEnhancers(applyMiddleware(sagaMiddleware));
const reducerManager = createReducerManager();
const injectedSagas = new Map();
const createSagaInjector = () => {
  // Create a dictionary to keep track of injected sagas

  const isInjected = (key) => injectedSagas.has(key);

  const add = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = sagaMiddleware.run(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a staticlly loaded file,
  add('root', rootSaga);

  return add;
};
const reducerWrap = (state, action) => {
  if (action.type === LOGOUT_USER_SUCCESS.getType()) {
    return reducerManager.reduce(undefined, action);
  }
  if (action.type === ADD_DYNAMIC_REDUCER.getType()) {
    const {key, reducer} = action.payload;
    reducerManager.add(key, reducer);
  }
  if (action.type === REMOVE_DYNAMIC_REDUCER.getType()) {
    reducerManager.remove(action.payload);
  }
  if (action.type === ADD_DYNAMIC_SAGA.getType()) {
    const {key, saga} = action.payload;
    createSagaInjector()(key, saga);
  }
  return reducerManager.reduce(state, action);
};
const store = createStore(reducerWrap, a);

createSagaInjector();

export default store;
