export const API_VERSION_1 = 'v1';
export const baseURL = `/api/${API_VERSION_1}`;
export const SERVER_URL = `${process.env.REACT_APP_API_URL}`;
export const SERVER_UBEREATS_URL = `${process.env.REACT_APP_UBEREATS_API_URL}`;
export const SERVER_URL_BOOK_A_DRIVER = `${process.env.REACT_APP_API_URL_BOOK_A_DRIVER}`;
export const SERVER_DISCOUNTS_URL = `${process.env.REACT_APP_DISCOUNTS_API_URL}`;
export const SERVER_FEEDBACK_URL = `${process.env.REACT_APP_FEEDBACK_API_URL}`;
export const SERVER_AUTH_URL = `${process.env.REACT_APP_AUTH_URL}`;
export const SERVER_MENU_SYNC_URL = `${process.env.REACT_APP_MENU_SYNC_API_URL}`;
export const SERVER_TEST_AUTO_URL = `${process.env.REACT_APP_TEST_AUTO_API_URL}`;
export const SERVER_DISPATCH_URL = `${process.env.REACT_APP_DISPATCH_URL}`;
export const SERVER_MARKETING_SERVICE_URL = `${process.env.REACT_APP_MARKETING_SERVICE_API_URL}`;
export const DISPATCH_TOKEN = `${process.env.REACT_APP_DISPATCH_AUTHORIZATION_TOKEN}`;
export const REPORTS_SERVICE_URL = `${process.env.REACT_APP_REPORTS_SERVICE_BASE_URL}/api/reports/v1/dashboard`;
export const RDB_SERVICE_URL = `${process.env.REACT_APP_RDB_SERVICE_URL}`;
export const RDB_SERVICE_V2_URL = `${process.env.REACT_APP_NEW_RDB_API_URL}`;
export const CORE_ORDERS_SERVICE_URL = `${process.env.REACT_APP_CORE_ORDERS_SERVICE_BASE_URL}`;
export const REFUND_CALCULATOR_URL = `${process.env.REACT_APP_REFUND_CALCULATOR_API_URL}`;
export const HUBSPOT_URL = `${process.env.REACT_APP_HUBSPOT_URL}`;
export const INSIGHTS_SERVICE_URL = `${process.env.REACT_APP_INSIGHTS_SERVICE_API_URL}`;
export const PAYMENT_ADJUSTMENTS_SERVICE_URL = `${process.env.REACT_APP_PAYMENT_ADJUSTMENTS_API_URL}`;
export const CONFIGURATION_SERVICE_URL = `${process.env.REACT_APP_CONFIGURATION_SERVICE}`;
export const GET = 'get';
export const POST = 'post';
export const PUT = 'put';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const PATCH = 'patch';

export const STATUS_SUCCESS = 200;
export const STATUS_BAD_REQUEST = 400;
export const STATUS_UNAUTHORIZED = 401;
export const STATUS_NOT_FOUNT = 404;
