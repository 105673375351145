import {SERVER_MENU_SYNC_URL, SERVER_URL} from './API_CONSTANTS';

// Location settings API endpoints
export const GET_LOCATION_SETTINGS_DATA = () =>
  `${SERVER_URL}/locations-management`;

export const GET_LOCATION_SETTINGS_DATA_BY_ID = (id) =>
  `${SERVER_URL}/locations-management/${id}`;

export const UPDATE_LOCATION_SETTINGS_DATA = (id) =>
  `${SERVER_URL}/locations-management/${id}`;

export const POST_LOCATION_SETTINGS_DATA = () =>
  `${SERVER_URL}/locations-management`;

export const GET_CALLING_PHONE = (countryCode) =>
  `${SERVER_URL}/geographi/${countryCode}/callingCode`;

export const PUBLISH_LOCATION = (menuConfigId) =>
  `${SERVER_MENU_SYNC_URL}/sync-bot/${menuConfigId}`;

export const UPDATE_LOCATION_ACCOUNT_TEMPLATE = (id) =>
  `${SERVER_URL}/locations-management/${id}`;

export const UPDATE_FEEDBACK_SETTINGS = (id) =>
  `${SERVER_URL}/locations-management/settings/${id}/feedback`;

export const UPDATE_FEEDBACK_BANNER_STATUS = (id) =>
  `${SERVER_URL}/locations-management/settings/${id}/feedback/banner`;

export const CREATE_STORE_HOLIDAYS = (id) =>
  `${SERVER_URL}/locations-management/settings/${id}/holidays-request`;
