import styled from 'styled-components';

const MainRLPaddings = styled.div`
  display: block;
  padding-right: 100px;
  padding-left: 96px;

  @media (max-width: 1600px) {
    padding-right: 48px;
    padding-left: 48px;
  }

  @media (max-width: 1250px) {
    padding-right: 28px;
    padding-left: 28px;
  }

  @media (max-width: 1000px) {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export default MainRLPaddings;
