import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from '../../../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../../../components/DialogTitle/styles';
import {DialogSubTitle} from '../../../../../../components/DialogSubTitle/styles';
import DialogBody from '../../../../../../components/DialogBody/styles';
import {ButtonPrimary} from '../../../../../../components/buttons';
import {DialogAction} from '../../../../dashboard/styles';
import ImgRefund from '../../../../../../assets/img-refund.svg';
import {RefundConfirmationImg} from './styles';
import {ORDER_UNMOUNT} from '../../../../../../store/orders/orderDetails/action';
import {CLEAR_REFUND_DATA} from '../../../../../../store/orders/orderRefund/action';

const ConfirmationModal = (props) => {
  const history = useHistory();
  const {params} = useRouteMatch();
  const dispatch = useDispatch();

  const redirectToOrdersDetails = (state = {}) => {
    dispatch(ORDER_UNMOUNT());
    dispatch(CLEAR_REFUND_DATA());
    history.push(
      params.back === 'history'
        ? `/orders/history/${params.id}/details`
        : `/orders/active/${params.id}/details`,
      state
    );
  };

  const {open, onClose, values} = props;
  return (
    <Dialog open={open}>
      <DialogContainer className='DialogSm Overflow'>
        <DialogTitle className='DialogTitleMarginBottom'>
          Refund confirmed
        </DialogTitle>
        <DialogSubTitle className='textCenter mBottomLarge'>
          The customer was refunded and a confirmation email was sent
        </DialogSubTitle>
        {/* {values.refundType === 'Full' || values.refundType === 'Partial' ? (
          <DialogSubTitle className='textCenter mBottomLarge'>
            Our Refund Team is currently handling your repayment claim from the
            delivery company
          </DialogSubTitle>
        ) : null} */}
        <DialogBody>
          <RefundConfirmationImg src={ImgRefund} />
          <DialogAction>
            <ButtonPrimary
              onClick={() => {
                onClose();
                redirectToOrdersDetails();
              }}
            >
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired
};

export default ConfirmationModal;
