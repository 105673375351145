import {createReducer} from 'redux-act';
import {UPLOAD, UPLOAD_SUCCESS} from './action';

const initialState = () => ({
  uploadFileLoading: false
});

const uploadFileReducer = createReducer(
  {
    [UPLOAD]: (state) => ({
      ...state,
      uploadFileLoading: true
    }),

    [UPLOAD_SUCCESS]: (state) => ({
      ...state,
      uploadFileLoading: false
    })
  },
  initialState()
);

export default uploadFileReducer;
