import React, {memo, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {MobileTimePicker} from '@material-ui/pickers';
import T from 'prop-types';
import moment from 'moment';
import {FormControl, TextField} from '@material-ui/core';
import {FieldLabel} from '../../../../components/FieldLabel/styles';
import {
  DashboardTimeDelete,
  DashboardTimeInnerMenu
} from '../../dashboard/styles';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {intervals} from '../helpers';
import {isValueObjChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';

const initLocalTimeRanges = (selectedRanges) => {
  const next = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const interval in selectedRanges) {
    next[interval] = {
      disabled: selectedRanges[interval],
      active: false
    };
  }
  return next;
};

const SelectRow = ({timeRanges, period, day}) => {
  const [, setLocalTimeRanges] = useState(initLocalTimeRanges(timeRanges));
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();
  useEffect(() => {
    const nextLocalSelectedRanges = initLocalTimeRanges(timeRanges);

    period?.hoursIntervals?.forEach((interval) => {
      if (
        Object.prototype.hasOwnProperty.call(nextLocalSelectedRanges, interval)
      ) {
        nextLocalSelectedRanges[interval] = {
          disabled: false,
          active: true
        };
      }
    });

    setLocalTimeRanges(nextLocalSelectedRanges);
  }, [timeRanges]);

  const removeTimePeriods = (periodId) => {
    const arr = [...openingDays];
    const elementId = arr.findIndex((d) => d.id === day.id);
    arr[elementId].timePeriods = arr[elementId].timePeriods.filter(
      (d) => d.id !== periodId
    );
    setOpeningDays(arr);

    setFieldValue(
      'OpeningHoursChanged',
      isValueObjChanged(OpeningHoursChanged, periodId)
    );
  };

  const handleSelectChange = (event, periodId, name) => {
    const arr = [...openingDays];
    const elementId = arr.findIndex((d) => d.id === day.id);
    const element = arr.find((d) => d.id === day.id);

    const timePeriodId = element.timePeriods.findIndex(
      (el) => el.id === periodId
    );

    arr[elementId].timePeriods[timePeriodId][name] = event;
    if (arr[elementId].error) {
      delete arr[elementId].error;
    }
    if (
      arr[elementId].timePeriods[timePeriodId].startOfWorkingDay &&
      arr[elementId].timePeriods[timePeriodId].endOfWorkingDay
    ) {
      const start = moment(
        arr[elementId].timePeriods[timePeriodId].startOfWorkingDay,
        'h:mm A'
      );
      const end = moment(
        arr[elementId].timePeriods[timePeriodId].endOfWorkingDay,
        'h:mm A'
      );

      arr[elementId].timePeriods[timePeriodId].hoursIntervals = intervals(
        start,
        end
      );

      arr[elementId].timePeriods[timePeriodId].duration = moment(
        moment.duration(end.diff(start)).asHours()
      ).format('h:mm');
    }

    setOpeningDays(arr);

    setFieldValue(
      'OpeningHoursChanged',
      isValueObjChanged(
        OpeningHoursChanged,
        periodId,
        arr[elementId].timePeriods[timePeriodId]
      )
    );
  };

  return (
    <>
      <DashboardTimeInnerMenu className='Say2EatSelect Say2EatSelectLight Say2EatSelectTime'>
        <FieldLabel className={day.error && 'error'}>From</FieldLabel>
        <FormControl className={day.error && 'error'}>
          <MobileTimePicker
            ampmInClock
            mask='__:__:__'
            value={new Date(`01/01/1970 ${period.startOfWorkingDay}`)}
            onChange={(newValue) =>
              handleSelectChange(
                moment(newValue).format('h:mm A'),
                period.id,
                'startOfWorkingDay'
              )
            }
            renderInput={(props) => {
              return (
                <TextField {...props}>{period.startOfWorkingDay}</TextField>
              );
            }}
          />
        </FormControl>
      </DashboardTimeInnerMenu>
      <DashboardTimeInnerMenu className='Say2EatSelect Say2EatSelectLight Say2EatSelectTime'>
        <FieldLabel className={day.error && 'error'}>To</FieldLabel>
        <FormControl className={day.error && 'error'}>
          <MobileTimePicker
            ampmInClock
            value={new Date(`01/01/1970 ${period.endOfWorkingDay}`)}
            onChange={(newValue) =>
              handleSelectChange(
                moment(newValue).format('h:mm A'),
                period.id,
                'endOfWorkingDay'
              )
            }
            renderInput={(props) => {
              return (
                <TextField {...props}>{period.startOfWorkingDay}</TextField>
              );
            }}
          />
        </FormControl>
      </DashboardTimeInnerMenu>
      <ErrorSignWrapper className='positionBottom3'>
        {day.error && (
          <ErrorSign>
            You cannot choose time outside the opening hours
          </ErrorSign>
        )}
      </ErrorSignWrapper>
      {day.timePeriods.length > 1 && (
        <DashboardTimeInnerMenu className='DashboardTimeDelete'>
          <DashboardTimeDelete
            type='button'
            onClick={() => removeTimePeriods(period.id)}
          />
        </DashboardTimeInnerMenu>
      )}
    </>
  );
};
SelectRow.propTypes = {
  timeRanges: T.oneOfType([T.array, T.object]).isRequired,
  period: T.object.isRequired,
  day: T.object.isRequired
};

SelectRow.defaultProps = {};

export default memo(SelectRow);
