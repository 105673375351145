import React, {memo, useEffect, useMemo, useState} from 'react';
import T from 'prop-types';
import {useFormikContext} from 'formik';
import {nanoid} from 'nanoid';
import {RadioGroup} from '@material-ui/core';
import {
  DashboardField,
  DashboardFields,
  DashboardHolder,
  DialogLinkButton
} from '../../dashboard/styles';
import DaysPicker from '../DaysPicker';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {generateTimeRanges, openingDaysServerParser} from '../helpers';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';

const DaysPickerContainer = ({WorkingDays}) => {
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();
  const [openingDaysRadio, setOpeningDaysRadio] = useState(true);
  const isDayError = useMemo(
    () => !openingDaysServerParser(openingDays).length,
    [openingDays]
  );

  useEffect(() => {
    if (WorkingDays?.length) {
      setOpeningDays(WorkingDays);
    } else {
      setOpeningDays([
        {
          id: nanoid(),
          days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          startOfWorkingDay: '',
          endOfWorkingDay: '',
          duration: '',
          timePeriods: [
            {
              id: nanoid(),
              startOfWorkingDay: '10:00 AM',
              endOfWorkingDay: '10:00 PM',
              duration: '12:00',
              hoursIntervals: [
                Object.keys(generateTimeRanges(15, 'en', 10, 22))
              ]
            }
          ]
        }
      ]);
    }
  }, []);

  useEffect(() => {
    const checkOpeningDaysRadio = () => {
      if (openingDays?.length === 1) {
        return (
          openingDays[0]?.days.length === 7 &&
          openingDays[0]?.timePeriods[0]?.startOfWorkingDay === '10:00 AM' &&
          openingDays[0]?.timePeriods[0]?.endOfWorkingDay === '10:00 PM'
        );
      }
      return false;
    };

    if (checkOpeningDaysRadio()) {
      setOpeningDaysRadio(true);
    } else {
      setOpeningDaysRadio(false);
    }
  }, [openingDays]);

  const createOpeningDay = () => {
    const newId = nanoid();
    setOpeningDays((prevState) => [
      ...prevState,
      {
        id: newId,
        days: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        startOfWorkingDay: '',
        endOfWorkingDay: '',
        duration: '',
        timePeriods: [
          {
            id: nanoid(),
            startOfWorkingDay: '10:00 AM',
            endOfWorkingDay: '10:00 PM',
            duration: '12:00',
            hoursIntervals: [Object.keys(generateTimeRanges(15, 'en', 10, 22))]
          }
        ]
      }
    ]);
    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, newId)
    );
  };

  return (
    <>
      <DashboardHolder className='w100 mBottom0'>
        <RadioGroup
          aria-label='Opening Days and Hours'
          name='openingDaysRadio'
          onChange={(e) => setOpeningDaysRadio(e.target.checked)}
          value={openingDaysRadio}
        >
          <DashboardFields>
            <DashboardField className='w100 flexColumn'>
              <DaysPicker />
            </DashboardField>
          </DashboardFields>
        </RadioGroup>
      </DashboardHolder>
      <DashboardFields>
        <DashboardField className='w100 justifyCenter'>
          <DialogLinkButton type='button' onClick={() => createOpeningDay()}>
            Add more
          </DialogLinkButton>
        </DashboardField>
      </DashboardFields>
    </>
  );
};

DaysPickerContainer.propTypes = {
  WorkingDays: T.array.isRequired
};
export default memo(DaysPickerContainer);
