import styled from 'styled-components';
import IconSearch from '../../../assets/icon-search.svg';
import IconClose from '../../../assets/icon-close.svg';

const Wrapper = styled.div`
  position: relative;
`;

const Remove = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url(${IconClose});
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

const SearchField = styled.input`
  display: block;
  position: relative;
  width: 100%;
  padding: 0 16px;
  outline: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: rgba(23, 39, 81, 1);
  padding-left: 48px;
  background-image: url('${IconSearch}');
  background-repeat: no-repeat;
  background-position: center left 17px;
  background-color: #fff;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #dfe2ea;

  &::placeholder {
    color: rgba(23, 39, 81, 0.6);
  }
`;

export {SearchField, Wrapper, Remove};
