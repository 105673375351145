/* eslint-disable */
export const CATEGORY_SCHEME = 'category';
export const ITEM_SCHEME = 'item';
export const GROUP_SCHEME = 'modifierGroup';
export const MODIFIER_SCHEME = 'modifier';

class BranchScheme {
  constructor(
    type,
    fieldId,
    storeMap,
    branchStoreMap,
    activeBranchStoreMap,
    childrenScheme,
    childrenStore,
    childrenBranchStore,
    childrenActiveBranchStore,
    childrenField,
    childrenType,
    childrenTypePlural,
    searchFields
  ) {
    this.type = type;
    this.fieldId = fieldId;
    this.storeMap = storeMap;
    this.branchStoreMap = branchStoreMap;
    this.activeBranchStoreMap = activeBranchStoreMap;
    this.childrenScheme = childrenScheme;
    this.childrenStore = childrenStore;
    this.childrenBranchStore = childrenBranchStore;
    this.childrenActiveBranchStore = childrenActiveBranchStore;
    this.childrenField = childrenField;
    this.childrenType = childrenType;
    this.childrenTypePlural = childrenTypePlural;
    this.searchFields = searchFields;

    Object.freeze(this);
  }
}

export const treeBranchesScheme = {
  category: new BranchScheme(
    'category',
    'MerchantSectionId',
    'sectionsMap',
    'sectionsBranchMap',
    'activeSectionBranchMap',
    'item',
    'itemsMap',
    'itemsBranchMap',
    'activeItemsBranchMap',
    'Items',
    'item',
    'items',
    ['Name' /*, 'Notes'*/]
  ),
  item: new BranchScheme(
    'item',
    'PosId',
    'itemsMap',
    'itemsBranchMap',
    'activeItemsBranchMap',
    'modifierGroup',
    'groupsMap',
    'groupsBranchMap',
    'activeGroupsBranchMap',
    'OptionsGroups',
    'modifier group',
    'modifier groups',
    ['Name' /*, 'Description', 'Notes'*/]
  ),
  modifierGroup: new BranchScheme(
    'modifierGroup',
    'PosId',
    'groupsMap',
    'groupsBranchMap',
    'activeGroupsBranchMap',
    'modifier',
    'modifiersMap',
    'modifiersBranchMap',
    'activeModifiersBranchMap',
    'Items',
    'modifier',
    'modifiers',
    ['OptionName' /*, 'Notes', 'OptionDescription'*/]
  ),
  modifier: new BranchScheme(
    'modifier',
    'PosId',
    'modifiersMap',
    'modifiersBranchMap',
    'activeModifiersBranchMap',
    'modifierGroup',
    'groupsMap',
    'groupsBranchMap',
    'activeGroupsBranchMap',
    'ModifierGroups',
    'modifier group',
    'modifier groups',
    ['Name' /*, 'Description'*/]
  )
};
