/* eslint-disable */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import T from 'prop-types';
import {ReactSVG} from 'react-svg';
import {
  AccordionDescriptionText,
  AccordionHeader,
  AccordionHeaderDelivery,
  AccordionHeaderDescription,
  AccordionHeaderImage,
  AccordionHeaderInner,
  AccordionHeaderItems,
  AccordionHeaderName,
  AccordionHeaderPickup,
  AccordionHeaderPrice,
  AccordionHeaderText
} from '../../dashboard/styles';
import DndList from './dgdList';
import PopperСomponent from '../../../../components/Popper';
import CategoryImage from '../../../../assets/category-img.svg';
import ItemImage from '../../../../assets/item-img.svg';
import ModifierImage from '../../../../assets/modifier-img.svg';
import ModifierGroupImage from '../../../../assets/modifierGroup-img.svg';
import DgdIcon from '../../../../assets/dgd-icon.svg';
import UnvisibleIcon from '../../../../assets/icon-unvisible.svg';
import BulkCheckbox from '../components/BulkCheckbox';

class DgDItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: !!props.activeTreeBranchData.$search
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.activeTreeBranchData !== nextProps.activeTreeBranchData) {
      const currentSearch = this.props.activeTreeBranchData.$search;
      const nextSearch = nextProps.activeTreeBranchData.$search;

      if (nextSearch && !this.state.expanded) {
        this.setState({expanded: true});

        return false;
      }

      if (currentSearch && !nextSearch && this.state.expanded) {
        this.setState({expanded: false});

        return false;
      }
    }

    return true;
  }

  parseData = (
    {
      PosId,
      MerchantSectionId,
      Name,
      OptionName,
      PictureURL,
      Price,
      InStock,
      Invisible,
      NonDeliverable,
      PickUp,
      Notes,
      Sections,
      Items,
      MenuItems,
      OptionsGroups,
      Modifiers,
      ModifierGroups
    },
    {$branchScheme: {type, childrenScheme, childrenType, childrenTypePlural}},
    {$id, $search, $children, OutOfStock, Hidden}
  ) => ({
    id: $id,
    isSearched: !!$search,
    name: Name || OptionName,
    itemsAffecting: MenuItems,
    entityName: type,
    ChildrenType: childrenType,
    ChildrenTypePlural: childrenTypePlural,
    childrenScheme: childrenScheme,
    children: $children,
    picture: PictureURL,
    defaultPicture: () => ({
      category: CategoryImage,
      item: ItemImage,
      modifierGroup: ModifierGroupImage,
      modifier: ModifierImage
    }),
    price: Price,
    inStock: InStock,
    delivery: NonDeliverable,
    PickUp,
    Notes,
    Sections,
    OutOfStock,
    Hidden,
    Invisible
  });

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({expanded: !this.state.expanded});
  };

  // eslint-disable-next-line consistent-return
  renderOutOfStockSign = (OutOfStock) => {
    if (OutOfStock.Items.size > 0 && OutOfStock.Modifiers.size > 0) {
      return (
        <AccordionDescriptionText className='OutOfStock'>
          <span>86</span>{' '}
          {OutOfStock.Items.size === 1
            ? `${OutOfStock.Items.size} item `
            : `${OutOfStock.Items.size} items `}
          &nbsp;&&nbsp;
          {OutOfStock.Modifiers.size === 1
            ? `${OutOfStock.Modifiers.size} modifier `
            : `${OutOfStock.Modifiers.size} modifiers `}
          are out of Stock
        </AccordionDescriptionText>
      );
    }

    if (OutOfStock.Items.size > 0 && OutOfStock.Modifiers.size === 0) {
      return (
        <AccordionDescriptionText className='OutOfStock'>
          <span>86</span>{' '}
          {OutOfStock.Items.size === 1
            ? `${OutOfStock.Items.size} item `
            : `${OutOfStock.Items.size} items `}
          {OutOfStock.Items.size === 1 ? 'is ' : 'are '} out of Stock
        </AccordionDescriptionText>
      );
    }

    if (OutOfStock.Items.size === 0 && OutOfStock.Modifiers.size > 0) {
      return (
        <AccordionDescriptionText className='OutOfStock'>
          <span>86</span>
          {OutOfStock.Modifiers.size === 1
            ? `${OutOfStock.Modifiers.size} modifier `
            : `${OutOfStock.Modifiers.size} modifiers `}
          {OutOfStock.Modifiers.size === 1 ? ' is ' : ' are '} out of Stock
        </AccordionDescriptionText>
      );
    }
    return false;
  };

  render() {
    const {expanded} = this.state;
    const {
      itemData,
      treeBranchData,
      activeTreeBranchData,
      withoutDrag,
      withBulkCheckbox,
      bulkSelectedMap,
      dragHandleProps
    } = this.props;

    const {
      id,
      name,
      isSearched,
      picture,
      price,
      inStock,
      delivery,
      itemsAffecting,
      entityName,
      defaultPicture,
      childrenScheme,
      children,
      ChildrenType,
      ChildrenTypePlural,
      PickUp,
      Notes,
      Sections,
      OutOfStock
    } = this.parseData(itemData, treeBranchData, activeTreeBranchData);
    const isDragAndDropDisable = isSearched || withoutDrag;

    const isHidden =
      (entityName === 'category' && !itemData.Visible) ||
      ((entityName === 'item' || entityName === 'modifier') &&
        itemData.Invisible);

    return (
      <Accordion
        className='Accordion wordWrap'
        expanded={expanded}
        onChange={this.handleChange(id)}
        disabled={!children.length}
      >
        <AccordionHeader className={cn({searched: false})}>
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            {withBulkCheckbox && (
              <BulkCheckbox
                id={id}
                type={entityName}
                checked={bulkSelectedMap && bulkSelectedMap.hasOwnProperty(id)}
              />
            )}
            <AccordionHeaderImage className='AccordionHeaderImage'>
              <img
                src={picture || defaultPicture()[entityName]}
                alt={entityName}
              />
            </AccordionHeaderImage>
            <AccordionHeaderInner>
              <AccordionHeaderName
                className={
                  entityName === 'modifier'
                    ? 'AccordionHeaderName'
                    : 'AccordionHeaderName noBorderRight'
                }
              >
                {name}
              </AccordionHeaderName>
              {/*{delivery === false && entityName !== 'modifier' && (*/}
              {/*  <AccordionHeaderDelivery>Delivery</AccordionHeaderDelivery>*/}
              {/*)}*/}
              {/*{PickUp && <AccordionHeaderPickup>Pickup</AccordionHeaderPickup>}*/}
              {price > 0 && (
                <AccordionHeaderPrice>$ {price}</AccordionHeaderPrice>
              )}
              <AccordionHeaderItems className='AccordionHeaderItems'>
                {children.length > 1 || children.length === 0
                  ? ` ${children.length} ${ChildrenTypePlural}`
                  : `${children.length} ${ChildrenType}`}
              </AccordionHeaderItems>
              {inStock === false ? (
                <AccordionHeaderText className='OutOfStock'>
                  <span>86</span> Out of Stock
                </AccordionHeaderText>
              ) : null}
              {isHidden && (
                <AccordionHeaderText className='OutOfStock'>
                  <ReactSVG className='VisibleIcon' src={UnvisibleIcon} />
                  Hidden
                </AccordionHeaderText>
              )}
              {itemsAffecting && (
                <AccordionHeaderDescription>
                  <AccordionDescriptionText>
                    <strong>Items affecting: </strong>
                    {itemsAffecting.length > 1 || itemsAffecting.length === 0
                      ? ` ${itemsAffecting.length} items`
                      : `${itemsAffecting.length} item`}
                  </AccordionDescriptionText>
                  {Notes && (
                    <AccordionDescriptionText>
                      <strong>Notes:</strong> {Notes}
                    </AccordionDescriptionText>
                  )}
                </AccordionHeaderDescription>
              )}

              {entityName === 'category' && (
                <>
                  <AccordionHeaderDescription
                    className={
                      !Notes &&
                      this.renderOutOfStockSign(OutOfStock) === false &&
                      'noMarginTop'
                    }
                  >
                    {this.renderOutOfStockSign(OutOfStock)}
                    {Notes && (
                      <AccordionDescriptionText>
                        <strong>Notes:</strong> {Notes}
                      </AccordionDescriptionText>
                    )}
                  </AccordionHeaderDescription>
                </>
              )}

              {entityName === 'item' &&
                Sections.length > 0 &&
                typeof Sections[0] !== 'string' && (
                  <AccordionHeaderDescription>
                    <AccordionDescriptionText>
                      <strong>Categories:</strong>{' '}
                      {Sections.map((section) => section.Name).join(', ')}
                    </AccordionDescriptionText>
                  </AccordionHeaderDescription>
                )}
            </AccordionHeaderInner>
          </AccordionSummary>

          {!isDragAndDropDisable && (
            <div className='DragAndDropIcon' {...dragHandleProps}>
              <ReactSVG src={DgdIcon} />
            </div>
          )}
          <PopperСomponent entityId={id} entity={itemData} name={entityName} />
        </AccordionHeader>
        <AccordionDetails>
          {Boolean(expanded && children && children.length) && (
            <DndList
              parentId={id}
              parentType={entityName}
              items={children}
              itemScheme={childrenScheme}
              withoutDrag={children.length < 2}
            />
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
}

DgDItem.propTypes = {
  item: T.shape({
    id: T.number,
    name: T.string,
    children: T.array,
    withoutDrag: T.bool,
    withBulkCheckbox: T.bool,
    bulkSelectedMap: T.object
  })
};

DgDItem.defaultProps = {
  item: {
    id: 0,
    name: '',
    children: [],
    withoutDrag: false,
    withBulkCheckbox: false,
    bulkSelectedMap: null
  }
};

export default DgDItem;
