import styled from 'styled-components';

const ButtonBold = styled.a`
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #ff2d38;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }

  &.Grey {
    color: #929cb9;
  }
`;

export default ButtonBold;
