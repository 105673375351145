import styled from 'styled-components';

export const SearchPanel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 22px 24px;
  margin-top: 22px;
  background-color: #fff;
  border-radius: 10px;
  @media (max-width: 1360px) {
    padding: 18px 16px;
  }
  @media (max-width: 1276px) {
    padding-bottom: 13px;
  }

  .SearchPanelInput {
    max-width: 500px;
    width: 100%;
    flex: 0 0 auto;
    margin-right: 20px;

    @media (max-width: 1750px) {
      max-width: 420px;
    }
    @media (max-width: 1600px) {
      max-width: 400px;
      margin-right: 10px;
    }
    @media (max-width: 1440px) {
      max-width: 340px;
    }
    @media (max-width: 1360px) {
      max-width: 328px;
      margin-right: 8px;
    }
    @media (max-width: 1276px) {
      max-width: 100%;
      margin: 0 0 10px 0;
    }
  }
`;

export default SearchPanel;
