import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {CardsButtonGrid} from '../../../../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../../../../components/CardsButton/styles';
import {ReactComponent as PlanetLogo} from '../../../../../../../assets/icon-palnet.svg';
import {ReactComponent as EmailLogo} from '../../../../../../../assets/icon-email.svg';
import {DashboardField} from '../../../../../dashboard/styles';
import FieldInput from '../../../../../../../components/FieldInput';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../../components/errorSign/errorSign';
import Caption1 from '../../../../../../../components/typography/Caption1';
import Caption4 from '../../../../../../../components/typography/Caption4';

const ImagesConnect = ({
  values,
  setFieldValue,
  setErrors,
  setTouched,
  errors,
  touched,
  setFieldTouched
}) => {
  let menuImagesValue;
  let menuImagesTypeURL;
  let menuImagesTypeEmail;
  let menuImagesURLValue;

  if (values?.data?.MenuShowUrlInput && !values?.data?.menuImagesCheckedURL) {
    menuImagesValue = values?.data?.menuImagesCheckedURL;
    menuImagesTypeURL = values?.data?.urlMenuImagesURL;
    menuImagesTypeEmail = values?.data?.urlMenuImagesEmail;
    menuImagesURLValue = values?.data?.urlMenuImagesURLValue || '';
  } else if (values?.data?.MenuEmail && !values?.data?.menuImagesCheckedEmail) {
    menuImagesValue = values?.data?.menuImagesCheckedEmail;
    menuImagesTypeURL = values?.data?.emailMenuImagesURL;
    menuImagesTypeEmail = values?.data?.emailMenuImagesEmail;
    menuImagesURLValue = values?.data?.emailMenuImagesURLValue || '';
  }
  return (
    <>
      {!menuImagesValue && (
        <DashboardField className='paddingTBZero mTop50 w100'>
          <Caption4 className='positionRelative'>
            Where should we take your menu images from?
            <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
              {errors?.noImagesSource ? (
                <ErrorSign>{errors?.noImagesSource}</ErrorSign>
              ) : null}
            </ErrorSignWrapper>
          </Caption4>
          <CardsButtonGrid className='CardsButtonGridOrange CardsButtonGridRed flexStart'>
            <CardsButton
              className={cn('increasedMargin', {
                isActive: menuImagesTypeURL
              })}
              onClick={() => {
                setErrors({});
                setTouched({});
                let changeData;
                if (values?.data?.MenuShowUrlInput) {
                  changeData = {
                    ...values.data,
                    urlMenuImagesURL: true,
                    urlMenuImagesEmail: false
                  };
                } else if (values?.data?.MenuEmail) {
                  changeData = {
                    ...values.data,
                    emailMenuImagesURL: true,
                    emailMenuImagesEmail: false
                  };
                }
                setFieldValue('data', changeData);
              }}
            >
              <PlanetLogo />
              <span>URL</span>
            </CardsButton>
            <CardsButton
              className={cn('increasedMargin', {
                isActive: menuImagesTypeEmail
              })}
              onClick={() => {
                setErrors({});
                setTouched({});
                let changeData;
                if (values?.data?.MenuShowUrlInput) {
                  changeData = {
                    ...values.data,
                    urlMenuImagesURL: false,
                    urlMenuImagesEmail: true
                  };
                } else if (values?.data?.MenuEmail) {
                  changeData = {
                    ...values.data,
                    emailMenuImagesURL: false,
                    emailMenuImagesEmail: true
                  };
                }

                setFieldValue('data', changeData);
              }}
            >
              <EmailLogo />
              <span>Email</span>
            </CardsButton>
          </CardsButtonGrid>
          {menuImagesTypeURL && (
            <DashboardField>
              <FieldLabel className='FieldLabelLg'>URL</FieldLabel>
              <FieldInput
                placeholder='Paste your link here'
                type='text'
                value={menuImagesURLValue}
                onChange={(e) => {
                  if (
                    values?.data.MenuShowUrlInput &&
                    values?.data?.urlMenuImagesURL
                  ) {
                    setFieldValue('data.urlMenuImagesURLValue', e.target.value);
                  } else if (
                    values?.data?.MenuEmail &&
                    values?.data?.emailMenuImagesURL
                  ) {
                    setFieldValue(
                      'data.emailMenuImagesURLValue',
                      e.target.value
                    );
                  }
                }}
                // name='data.MenuUseUrl'
                className={cn('blackPlaceholder', {
                  error:
                    (errors?.data?.urlMenuImagesURLValue &&
                      touched?.data?.urlMenuImagesURLValue) ||
                    (errors?.data?.emailMenuImagesURLValue &&
                      touched?.data?.emailMenuImagesURLValue)
                })}
                onBlur={() => {
                  if (values?.data?.urlMenuImagesURL) {
                    setFieldTouched('data.urlMenuImagesURLValue');
                  } else if (values?.data?.emailMenuImagesURL) {
                    setFieldTouched('data.emailMenuImagesURLValue');
                  }
                }}
              />
              <ErrorSignWrapper>
                {errors?.data?.urlMenuImagesURLValue &&
                touched?.data?.urlMenuImagesURLValue ? (
                  <ErrorSign>{errors?.data?.urlMenuImagesURLValue}</ErrorSign>
                ) : null}
                {errors?.data?.emailMenuImagesURLValue &&
                touched?.data?.emailMenuImagesURLValue ? (
                  <ErrorSign>{errors?.data?.emailMenuImagesURLValue}</ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DashboardField>
          )}
          {menuImagesTypeEmail && (
            <DashboardField className='w100 dFlex justifyCenter mTop50'>
              <Caption1 style={{fontWeight: 400, marginBottom: '10px'}}>
                Please send your menu to{' '}
                <span style={{fontWeight: 700}}>onboarding@getsauce.com</span>
              </Caption1>
            </DashboardField>
          )}
        </DashboardField>
      )}
    </>
  );
};

ImagesConnect.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.object.isRequired,
  setTouched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired
};

export default ImagesConnect;
