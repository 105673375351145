import React, {useEffect, useState} from 'react';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import Headline from 'components/Headline/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import DatepickerModule from 'components/DatepickerModule';
import {DashboardContent} from 'components/DashboardContent/styles';
import {
  InsightsPerformanceCardsContainer,
  InsightsPerformanceDatePicker,
  InsightsPerformanceSelectWrapper
} from 'routes/App/Insights/Performance/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
  INSIGHTS_PERFORMANCE_CARDS_INFO,
  INSIGHTS_PERFORMANCE_CATEGORY
} from 'helpers/constants/Insights/performance';
import {ButtonPrimary} from 'components/buttons';
import {DashboardHeaderActions} from 'routes/App/BookADriver/List/styles';
import {
  INSIGHTS_PERFORMANCE_GET_INFO,
  INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL,
  INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL
} from 'store/insights/performance/action';
import SettingsModal from 'routes/App/Insights/Performance/Modals/SettingsModal';
import ContactUsModal from 'routes/App/Insights/Growth/Modals/ContactUsModal';
import FullScreenLoading from 'components/shared/Utils/FullScreenLoading/FullScreenLoading';
import InsightsPerformanceCardEl from 'routes/App/Insights/Performance/components/InsightsPerformanceCardEl';
import {DashboardTitle} from 'routes/App/dashboard/styles';

const InsightsPerformance = () => {
  const dispatch = useDispatch();
  const {
    performanceInfo,
    loading,
    isSettingsModalOpen,
    modalInfo
  } = useSelector((state) => state.insightsPerformanceReducer);
  const {locationId} = useSelector((state) => state.user);

  const [date, setDate] = useState([null, null]);

  useEffect(() => {
    dispatch(INSIGHTS_PERFORMANCE_GET_INFO(date));
  }, [locationId, date]);

  return (
    <>
      <DashboardContent>
        <DashboardHeader>
          <Headline>Insights (Performance)</Headline>
          <DashboardHeaderActions>
            <ButtonPrimary
              className='ButtonInsightsPerformanceSettings'
              onClick={() =>
                dispatch(INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL(true))
              }
            >
              <span>Settings</span>
            </ButtonPrimary>
          </DashboardHeaderActions>
        </DashboardHeader>
        <DashboardInner className='DashboardInnerW50 PaddingInsightsPerformanceDatePicker withoutShadow'>
          <InsightsPerformanceSelectWrapper className='InsightsPerformanceWrapper'>
            <span className='DatePickerTitle'>Time period</span>
            <InsightsPerformanceDatePicker>
              <DatepickerModule
                date={date}
                updateDate={setDate}
                defaultSelectIndex={0}
                updatedAllPeriods='true'
              />
            </InsightsPerformanceDatePicker>
          </InsightsPerformanceSelectWrapper>
        </DashboardInner>
        {loading ? (
          <FullScreenLoading />
        ) : (
          <>
            <DashboardTitle className='WithLine title24 titleBold InsightsPerformanceTitle'>
              Revenue
            </DashboardTitle>
            <InsightsPerformanceCardsContainer>
              {performanceInfo
                ?.filter(
                  (el) => el.category === INSIGHTS_PERFORMANCE_CATEGORY.REVENUE
                )
                ?.map((card) => (
                  <InsightsPerformanceCardEl card={card} />
                ))}
            </InsightsPerformanceCardsContainer>
            <DashboardTitle className='WithLine title24 titleBold InsightsPerformanceTitle'>
              Retention
            </DashboardTitle>
            <InsightsPerformanceCardsContainer>
              {performanceInfo
                ?.filter(
                  (el) =>
                    el.category === INSIGHTS_PERFORMANCE_CATEGORY.RETENTION
                )
                ?.map((card) => (
                  <InsightsPerformanceCardEl card={card} />
                ))}
            </InsightsPerformanceCardsContainer>
            <DashboardTitle className='WithLine title24 titleBold InsightsPerformanceTitle'>
              Customer Experience
            </DashboardTitle>
            <InsightsPerformanceCardsContainer>
              {performanceInfo
                ?.filter(
                  (el) =>
                    el.category ===
                    INSIGHTS_PERFORMANCE_CATEGORY.CUSTOMER_EXPERIENCE
                )
                ?.map((card) => (
                  <InsightsPerformanceCardEl card={card} />
                ))}
            </InsightsPerformanceCardsContainer>
          </>
        )}
      </DashboardContent>
      <SettingsModal openModal={isSettingsModalOpen} date={date} />
      {modalInfo?.isOpen && (
        <ContactUsModal
          openModal={modalInfo?.isOpen}
          feature={modalInfo?.feature}
          featuresConst={INSIGHTS_PERFORMANCE_CARDS_INFO}
          closeModalAction={INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL}
          insightsType='Performance'
        />
      )}
    </>
  );
};

export default InsightsPerformance;
