import {useSelector} from 'react-redux';

export const stepData = () =>
  useSelector((state) => {
    return {
      step0: state.selfCreateAccountReducer.PendingData[0] || {},
      step1: state.selfCreateAccountReducer.PendingData[1] || {},
      step2:
        state.selfCreateAccountReducer.PendingData[2]?.step === 2
          ? state.selfCreateAccountReducer.PendingData[2]
          : state.selfCreateAccountReducer.PendingData[1] || {},
      UserId: state.user.userData?.id,
      dataReceived: state.selfCreateAccountReducer.dataReceived,
      pendingDataSetuped: state.user?.userData?.pendingDataSetuped,
      currentStep: state.selfCreateAccountReducer.step,
      isModalOpen: state.selfCreateAccountReducer.isModalOpen,
      loading: state.selfCreateAccountReducer.loading,
      restaurantName:
        state.selfCreateAccountReducer.PendingData[0]?.Merchant?.MerchantName,
      countries: state.selfCreateAccountReducer.countries,
      routeStep: state.selfCreateAccountReducer.routeStep,
      showStepperAfterCalendly: state.accountReducer.showStepperAfterCalendly,
      finalSetup: state.selfCreateAccountReducer.finalSetup
    };
  });
export const generalInfoSelf = (state) =>
  state.selfCreateAccountReducer.PendingData[0] || {};
export const SignUpData = () => useSelector((state) => state.user.userData);
export const ConfirmedModalData = () =>
  useSelector((state) => state.selfCreateAccountReducer.isConfirmedModalOpen);
export const WelcomeModalData = () =>
  useSelector((state) => state.selfCreateAccountReducer.isWelcomeModalOpen);
export const PendingData = () =>
  useSelector((state) => state.selfCreateAccountReducer.PendingData);
export const SidebarProgress = () =>
  useSelector((state) => state.selfCreateAccountReducer.sidebarProgress);
