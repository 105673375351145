/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Team from './UsersTable/Team';
import Headline from '../../../components/headline2/HeadlineComponent';
import IconAdd from '../../../assets/IconAdd';
import ButtonPrimary from '../../../components/buttons/ButtonPrimary/styles';
import AddUser from './Modals/AddUser';
import {
  GET_COUNTER,
  GET_USERS,
  OPEN_CONFIRMATION_MODAL
} from '../../../store/usersManagement/action';
import ResetPassword from './Modals/ResetPassword';
import DeleteUserModalConfirm from './Modals/DeleteTeamMember';
import RestrictedArea from '../../../components/layout/RestrictedArea';
import MemberConfirmation from '../account/MyAccount/teamMembers/Modals/MemberConfirmation';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import DashboardHeader from '../../../components/DashboardHeader/styles';

// eslint-disable-next-line react/prop-types
const AddButton = ({setOpenModal}) => (
  <ButtonPrimary
    data-gtm='addNewUserButton'
    className='ButtonMedium ButtonAdd'
    onClick={() => setOpenModal(true)}
  >
    <IconAdd />
    <span data-gtm='addNewUserButton'>Add new</span>
  </ButtonPrimary>
);

const TeamContainer = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openResetPassModal, setOpenResetPassModal] = useState(false);
  const [modalModel, setModalModel] = useState({});
  const [openDeleteTeamMemberModal, setOpenDeleteTeamMemberModal] = useState(
    false
  );

  const {userData} = useSelector((state) => state.user);

  const {isConfirmationModalOpen} = useSelector(
    (state) => state.usersManagementReducer
  );

  useEffect(() => {
    if (userData) dispatch(GET_USERS());
    if (userData) dispatch(GET_COUNTER());
  }, []);

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline
          label='User Management'
          buttonInfo={
            <RestrictedArea restrictions={['userAdd']}>
              <AddButton setOpenModal={setOpenModal} />
            </RestrictedArea>
          }
        />
      </DashboardHeader>
      <DashboardInner>
        <Team
          onEdit={(user) => {
            setModalModel(user);
            setOpenModal(true);
          }}
          onResetPassword={(user) => {
            setModalModel(user);
            setOpenResetPassModal(true);
          }}
          onDelete={(user) => {
            setModalModel(user);
            setOpenDeleteTeamMemberModal(true);
          }}
        />
      </DashboardInner>

      <AddUser
        open={openModal}
        currentUser={userData}
        modalModel={modalModel}
        onClose={() => {
          setOpenModal(false);
          setModalModel({});
        }}
      />

      <MemberConfirmation
        open={isConfirmationModalOpen}
        onClose={() => dispatch(OPEN_CONFIRMATION_MODAL({open: false}))}
      />

      <ResetPassword
        open={openResetPassModal}
        modalModel={modalModel}
        onClose={() => {
          setModalModel({});
          setOpenResetPassModal(false);
        }}
      />

      <DeleteUserModalConfirm
        open={openDeleteTeamMemberModal}
        modalModel={modalModel}
        onClose={() => {
          setModalModel({});
          setOpenDeleteTeamMemberModal(false);
        }}
      />
    </DashboardContent>
  );
};

export default TeamContainer;
