import {GET, PUT, POST, DELETE} from '../constants/API_CONSTANTS';
import {
  API_CRUD_MENU_QUEUE,
  API_DELETE_MENU_QUEUE
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getMenuQueueApi = async () => {
  return request(GET, API_CRUD_MENU_QUEUE());
};

export const updateMenuQueueApi = async (data) => {
  return request(PUT, API_CRUD_MENU_QUEUE(), {data});
};

export const saveMenuQueueApi = async (data) => {
  return request(POST, API_CRUD_MENU_QUEUE(), {data});
};

export const deleteMenuQueueApi = async (id) => {
  return request(DELETE, API_DELETE_MENU_QUEUE(id));
};
