/* eslint-disable */
import React, {memo, useEffect, useState} from 'react';
import {selectedDaysArray} from '../constants';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import DaysRow from './DaysRow';

const DaysPickerDays = ({day}) => {
  const [selectedDays, setSelectedDays] = useState(selectedDaysArray());
  const openingDays = useDaysPickerContext();

  useEffect(() => {
    const nextDays = selectedDaysArray();

    openingDays.forEach((day) => {
      day.days.forEach((dayName) => {
        if (Object.prototype.hasOwnProperty.call(nextDays, dayName)) {
          nextDays[dayName] = true;
        }
      });
    });

    setSelectedDays(nextDays);
  }, [openingDays]);
  return (
    <>
      <DaysRow selectedDays={selectedDays} day={day} />
    </>
  );
};

export default memo(DaysPickerDays);
