import React from 'react';
import PropTypes from 'prop-types';
import {Subtitle1} from '../../../../../components/typography';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {DashboardField, DashboardFields} from '../../../dashboard/styles';
import FieldPassword from '../../../../../components/FieldPassword';

const LetUsDo = ({formik}) => {
  return (
    <>
      <DashboardFields>
        <DashboardField className='w100 dFlex dFlexNoWrap justifySB'>
          <Subtitle1>Provide us with the following details.</Subtitle1>
        </DashboardField>
        <DashboardField className='w100'>
          <FieldLabel>Login link to where you website is hosted</FieldLabel>
          <FieldInput
            name='HostLoginLink'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Link'
            value={formik.values.HostLoginLink}
            className={
              formik.errors.HostLoginLink &&
              formik.touched.HostLoginLink &&
              'error'
            }
          />
          <ErrorSignWrapper>
            <ErrorSign>
              {formik.touched.HostLoginLink && formik.errors.HostLoginLink}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='w100'>
          <FieldLabel>User name</FieldLabel>
          <FieldInput
            name='HostUserName'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Name'
            value={formik.values.HostUserName}
            className={
              formik.errors.HostUserName &&
              formik.touched.HostUserName &&
              'error'
            }
          />
          <ErrorSignWrapper>
            <ErrorSign>
              {formik.touched.HostUserName && formik.errors.HostUserName}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='w100'>
          <FieldLabel>Password</FieldLabel>
          <FieldPassword
            name='HostPassword'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Password'
            value={formik.values.HostPassword}
            className={
              formik.errors.HostPassword &&
              formik.touched.HostPassword &&
              'error'
            }
          />
          <ErrorSignWrapper>
            <ErrorSign>
              {formik.touched.HostPassword && formik.errors.HostPassword}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
      </DashboardFields>
    </>
  );
};

LetUsDo.propTypes = {
  formik: PropTypes.object.isRequired
};

export default LetUsDo;
