import React from 'react';

export const IconItem = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M16.6869 16.6869H0V0H16.6869V16.6869ZM2.04966 14.6317H14.6317V2.04966H2.04966V14.6317Z'
        fill='#929CB9'
      />
      <path
        d='M24.0002 23.9997H7.31885V7.31836H24.0058V23.9997H24.0002ZM9.36851 21.9501H21.9506V9.36802H9.36851V21.9501Z'
        fill='#929CB9'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconItem;
