import React from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {format} from 'date-fns';
import IconSortActiveUp from '../../../../assets/icon-sort-active-up.svg';
import IconSortActiveDown from '../../../../assets/icon-sort-active-down.svg';
import {TableSort, TableText} from './TeamTableStyle';
import Loading from '../../../../components/shared/Utils/Loading';
import {TeamTableWrapper} from './styles';
import PopperActions from './PoperActions';
import withRestrictions from '../../../../hocs/withRestrictions';

const getPopperActions = (config, restrictions = [], row, currentUserId) => {
  return config.filter((action) =>
    !restrictions.includes(action.restriction) && row._id === currentUserId
      ? action.title !== 'Remove'
      : true
  );
};

const TeamTable = (props) => {
  const {
    headCells,
    searchModifiers: {sortBy, sort},
    onSort,
    items,
    onEdit,
    onDelete,
    onResetPassword,
    loading,
    restrictions,
    currentUserId
  } = props;

  const popperActionsConfig = [
    {
      title: 'Edit',
      action: onEdit,
      restriction: 'userEdit'
    },
    {
      title: 'Reset Password',
      action: onResetPassword,
      restriction: 'passwordReset'
    },
    {
      title: 'Remove',
      action: onDelete,
      restriction: 'userEdit'
    }
  ];

  const popperActions = (row) =>
    getPopperActions(popperActionsConfig, restrictions, row, currentUserId);

  if (!items.length) return null;
  if (loading) return <Loading />;

  return (
    <TeamTableWrapper>
      <TableContainer>
        <Table>
          <colgroup>
            <col style={{width: '25%'}} />
            <col style={{width: '25%'}} />
            <col style={{width: '25%'}} />
            <col style={{width: '25%'}} />
            <col style={{width: '25%'}} />
            <col style={{width: '10%'}} />
          </colgroup>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={nanoid()}
                  sortDirection={sortBy === headCell.id ? sort : false}
                  align={headCell.alignLeft ? 'left' : 'inherit'}
                >
                  {!headCell.sort ? (
                    headCell.label
                  ) : (
                    <TableSort
                      icon={
                        // eslint-disable-next-line no-nested-ternary
                        sortBy === headCell.id
                          ? sort === 'desc'
                            ? IconSortActiveDown
                            : IconSortActiveUp
                          : null
                      }
                      active={sortBy === headCell.id}
                      direction={sortBy === headCell.id ? sort : 'desc'}
                      onClick={() =>
                        onSort({
                          sortBy: headCell.id,
                          sort: sort === 'asc' ? 'desc' : 'asc'
                        })
                      }
                    >
                      {headCell.label}
                    </TableSort>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => {
              return (
                <TableRow key={nanoid()} tabIndex={-1}>
                  <TableCell align='left'>
                    <TableText>
                      {row.first_name} {row.last_name}
                    </TableText>
                  </TableCell>
                  <TableCell align='left'>
                    <TableText>{row.email}</TableText>
                  </TableCell>
                  <TableCell align='left'>
                    <TableText>{row.role}</TableText>
                  </TableCell>
                  <TableCell align='left'>
                    <TableText>
                      {row.last_sign_in &&
                        format(new Date(row.last_sign_in), 'dd MMM yyy')}
                    </TableText>
                  </TableCell>
                  <TableCell align='right'>
                    <PopperActions
                      row={row}
                      popperActions={() => popperActions(row)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </TeamTableWrapper>
  );
};

TeamTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  sort: PropTypes.string,
  searchModifiers: PropTypes.object,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  onDelete: PropTypes.func,
  onResetPassword: PropTypes.func,
  onEdit: PropTypes.func,
  currentUserId: PropTypes.string,
  restrictions: PropTypes.array.isRequired
};

TeamTable.defaultProps = {
  sortBy: '',
  sort: '',
  currentUserId: '',
  searchModifiers: {},
  loading: false,
  onSort: () => {},
  onDelete: () => {},
  onResetPassword: () => {},
  onEdit: () => {}
};

export default withRestrictions(TeamTable);
