import {createAction} from 'redux-act';

export const GET_BAD_ORDER = createAction('orderDetails/GET_BAD_ORDER');
export const GET_BAD_ORDER_SUCCESS = createAction(
  'orderDetails/GET_BAD_ORDER_SUCCESS'
);
export const RUN_BAD_ORDER_DETAIL_JOB = createAction(
  'orderDetails/RUN_BAD_ORDER_DETAIL_JOB'
);
export const STOP_BAD_ORDER_DETAIL_JOB = createAction(
  'orderDetails/STOP_BAD_ORDER_DETAIL_JOB'
);
export const SET_BAD_ORDER_DETAIL_NOTES = createAction(
  'orderDetails/SET_BAD_ORDER_DETAIL_NOTES'
);
export const CLEAR_BAD_ORDER = createAction('orderDetails/CLEAR_BAD_ORDER');
