import styled from 'styled-components';

const Headline = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::after {
    display: inline-block;
    width: 100%;
    height: 1px;
    flex: 1 1 auto;
    margin-left: 12px;
    background-color: #190848;
    content: '';
  }

  &.light {
    &::after {
      opacity: 0.1;
    }
  }
`;

export default Headline;
