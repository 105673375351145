import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import CustomProgressBar from '../../../../../../components/CustomProgressBar';
import {SidebarProgress} from '../../../../../../store/selfCreateAccount/selectors';
import {SidebarProgressSales} from '../../../../../../store/setupRestaurant/selectors';
import {userData} from '../../../../../../store/global/account/selectors';

const MobileProgressBar = () => {
  const {isSignUp2} = useSelector(userData);
  const {pathname} = useLocation();
  const progress = () => {
    if (isSignUp2) {
      return SidebarProgress();
    }
    return SidebarProgressSales();
  };

  return (
    <CustomProgressBar
      className='ProgressBar ProgressBarMob marginBottomXl'
      progress={progress()}
      secondStepTitle={
        (pathname.includes('self-setup/book-call') && 'Book A Call') ||
        (pathname.includes('self-setup/generalInfo') &&
          'Start free trial/Book a call') ||
        (pathname.includes('sales-setup/general-info') && 'Add menu') ||
        (pathname.includes('sales-setup/add-menu') && 'Add menu')
      }
    />
  );
};

export default memo(MobileProgressBar);
