import React from 'react';
import ButtonOutline from '../../components/buttons/ButtonOutline/styles';

function DeveloperPage() {
  return (
    <div>
      <ButtonOutline
        className='ButtonMedium'
        onClick={() => {
          const errorConstant = 1;
          errorConstant.forEach((el) => console.log(el));
        }}
      >
        <span data-gtm='bookADriverButton'>Create error</span>
      </ButtonOutline>
    </div>
  );
}

export default DeveloperPage;
