import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import SauceIcon from '../../../../assets/sauce-delivery.png';
import Body5 from '../../../../components/typography/Body5';
import {ButtonOutline} from '../../../../components/buttons';
import IconLoading from '../../../../assets/IconLoading';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import {StatusText} from '../styles';

const UberActions = ({uberEatsEnabled, BYOCconnected}) => {
  const history = useHistory();

  return (
    <>
      <DashboardInner className='mTop15'>
        <FlexContainer className='align-center UberEatsBox'>
          <FlexItem className='wAutoSpace'>
            <FlexContainer className='align-center'>
              <img src={SauceIcon} alt='sauce-logo' className='marginRightLg' />
              <Body5>
                {!uberEatsEnabled
                  ? 'UberEats account is disconnected'
                  : 'UberEats account is connected'}
              </Body5>
            </FlexContainer>
          </FlexItem>
          <FlexItem className='wAuto marginLeftXsPlus'>
            <FlexContainer className='align-center UberConnect'>
              {uberEatsEnabled && !BYOCconnected && (
                <ButtonOutline
                  type='button'
                  className='ButtonMd2 marginRightMd ButtonOutline'
                  onClick={() => history.push('/uber-eats/byoc-wizard')}
                  data-gtm='activateBYOCBigButton'
                >
                  Activate now
                </ButtonOutline>
              )}
              <IconLoading color={BYOCconnected ? '#27ae60' : '#929CB9'} />
              <StatusText
                color={BYOCconnected && '#27ae60'}
                className='marginLeftXs'
              >
                {!BYOCconnected ? 'Not active' : 'Active'}
              </StatusText>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </DashboardInner>
    </>
  );
};

UberActions.propTypes = {
  uberEatsEnabled: PropTypes.bool.isRequired,
  BYOCconnected: PropTypes.bool.isRequired
};

export default UberActions;
