import React from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {QueueTableWrapper, TableText} from './QueueTableStyle';
import Loading from '../../../../../components/shared/Utils/Loading';
import PopperActions from './PoperActions';

const QueueTable = (props) => {
  const {headCells, items = [], loading, onDelete, onEdit} = props;
  if (!items.length && !loading) return <div>Menu queue were not found</div>;

  return (
    <QueueTableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={nanoid()}>{headCell.label}</TableCell>
              ))}
              <TableCell key={nanoid()} style={{width: '5%'}} />
            </TableRow>
          </TableHead>
          {props.loading ? (
            <Loading />
          ) : (
            <TableBody>
              {items.map((row) => (
                <TableRow key={nanoid()} tabIndex={-1}>
                  {headCells.map(({id}) => (
                    <TableCell align='left'>
                      <TableText>{(row[id] || '').toString()}</TableText>
                    </TableCell>
                  ))}
                  <TableCell align='right'>
                    <PopperActions
                      onDelete={() => onDelete(row.id)}
                      onEdit={() => onEdit(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </QueueTableWrapper>
  );
};

QueueTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

QueueTable.defaultProps = {
  loading: false
};

export default QueueTable;
