import React from 'react';

const BannerCarIcon = () => (
  <svg
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M27.1892 21.8823C24.687 21.8823 22.6514 23.9179 22.6514 26.4202C22.6514 28.9224 24.687 30.958 27.1892 30.958C29.6918 30.958 31.727 28.9224 31.727 26.4202C31.727 23.9179 29.6914 21.8823 27.1892 21.8823ZM27.1892 28.6891C25.9379 28.6891 24.9203 27.6714 24.9203 26.4202C24.9203 25.1689 25.9379 24.1512 27.1892 24.1512C28.4405 24.1512 29.4581 25.1689 29.4581 26.4202C29.4581 27.6715 28.4405 28.6891 27.1892 28.6891Z'
      fill='white'
    />
    <path
      d='M11.6843 21.8823C9.1821 21.8823 7.14648 23.9179 7.14648 26.4202C7.14648 28.9224 9.1821 30.958 11.6843 30.958C14.1865 30.958 16.2221 28.9224 16.2221 26.4202C16.2221 23.9179 14.1865 21.8823 11.6843 21.8823ZM11.6843 28.6891C10.433 28.6891 9.4154 27.6714 9.4154 26.4202C9.4154 25.1689 10.433 24.1512 11.6843 24.1512C12.9352 24.1512 13.9532 25.1689 13.9532 26.4202C13.9532 27.6715 12.9356 28.6891 11.6843 28.6891Z'
      fill='white'
    />
    <path
      d='M30.2455 8.89377C30.0526 8.51071 29.6605 8.26904 29.2316 8.26904H23.2568V10.538H28.532L31.6211 16.6821L33.6488 15.6626L30.2455 8.89377Z'
      fill='white'
    />
    <path d='M23.7852 25.3237H15.2012V27.5926H23.7852V25.3237Z' fill='white' />
    <path
      d='M8.28199 25.3237H4.34927C3.72264 25.3237 3.21484 25.8316 3.21484 26.4582C3.21484 27.0848 3.72271 27.5926 4.34927 27.5926H8.28206C8.90868 27.5926 9.41648 27.0847 9.41648 26.4582C9.41648 25.8315 8.90861 25.3237 8.28199 25.3237Z'
      fill='white'
    />
    <path
      d='M35.7623 18.3882L33.5309 15.5143C33.3165 15.2374 32.9856 15.0756 32.635 15.0756H24.3913V7.13442C24.3913 6.5078 23.8834 6 23.2569 6H4.34927C3.72264 6 3.21484 6.50787 3.21484 7.13442C3.21484 7.76098 3.72271 8.26884 4.34927 8.26884H22.1224V16.21C22.1224 16.8366 22.6302 17.3444 23.2568 17.3444H32.0795L33.7316 19.4727V25.3234H30.5929C29.9663 25.3234 29.4585 25.8313 29.4585 26.4578C29.4585 27.0845 29.9664 27.5923 30.5929 27.5923H34.866C35.4926 27.5923 36.0004 27.0844 36.0005 26.4578V19.084C36.0005 18.8322 35.9166 18.5871 35.7623 18.3882Z'
      fill='white'
    />
    <path
      d='M8.20646 19.5757H2.98794C2.36131 19.5757 1.85352 20.0836 1.85352 20.7101C1.85352 21.3367 2.36138 21.8445 2.98794 21.8445H8.20639C8.83302 21.8445 9.34082 21.3367 9.34082 20.7101C9.34089 20.0836 8.83302 19.5757 8.20646 19.5757Z'
      fill='white'
    />
    <path
      d='M10.8151 15.1133H1.13442C0.507867 15.1133 0 15.6211 0 16.2478C0 16.8744 0.507867 17.3822 1.13442 17.3822H10.8151C11.4417 17.3822 11.9495 16.8743 11.9495 16.2478C11.9495 15.6212 11.4417 15.1133 10.8151 15.1133Z'
      fill='white'
    />
    <path
      d='M12.6686 10.6514H2.98794C2.36131 10.6514 1.85352 11.1592 1.85352 11.7858C1.85352 12.4124 2.36138 12.9202 2.98794 12.9202H12.6686C13.2953 12.9202 13.8031 12.4123 13.8031 11.7858C13.8031 11.1592 13.2953 10.6514 12.6686 10.6514Z'
      fill='white'
    />
  </svg>
);

export default BannerCarIcon;
