import {createAction} from 'redux-act';

export const GET_LOCATION_DATA = createAction('user/GET_LOCATION_DATA');
export const GET_LOCATION_DATA_SUCCESS = createAction(
  'user/GET_LOCATION_DATA_SUCCESS'
);
export const GET_LOCATION_ID = createAction('user/GET_LOCATION_ID');
export const GET_TIMEZONE = createAction('user/GET_TIMEZONE');
export const GET_USER_DATA = createAction('user/GET_USER_DATA');
export const GET_USER_DATA_SUCCESS = createAction('user/GET_USER_DATA_SUCCESS');
export const GET_REPLIED_REVIEWS = createAction('user/GET_REPLIED_REVIEWS');
export const GET_REPLIED_REVIEWS_SUCCESS = createAction(
  'user/GET_REPLIED_REVIEWS_SUCCESS'
);
export const UPDATE_USER_DATA = createAction('user/UPDATE_USER_DATA');
export const UPDATE_USER_DATA_SUCCESS = createAction(
  'user/UPDATE_USER_DATA_SUCCESS'
);

export const PATCH_USER_DATA = createAction('user/PATCH_USER_DATA');
export const PATCH_USER_DATA_SUCCESS = createAction(
  'user/PATCH_USER_DATA_SUCCESS'
);
export const SET_ACTIVE_LOCATION = createAction('user/SET_ACTIVE_LOCATION');

export const ADD_DYNAMIC_REDUCER = createAction('user/ADD_DYNAMIC_REDUCER');
export const ADD_DYNAMIC_SAGA = createAction('user/ADD_DYNAMIC_SAGA');
export const REMOVE_DYNAMIC_REDUCER = createAction(
  'user/REMOVE_DYNAMIC_REDUCER'
);

export const GET_LOCATION = createAction('user/GET_LOCATION');
export const GET_USER_LOCATIONS = createAction('user/GET_USER_LOCATIONS');

export const SET_BYOC_CONFIGURATION = createAction(
  'user/SET_BYOC_CONFIGURATION'
);
export const SET_BYOC_CONFIGURATION_SUCCESS = createAction(
  'user/SET_BYOC_CONFIGURATION_SUCCESS'
);

export const SET_UBER_CONFIGURATION = createAction(
  'user/SET_UBER_CONFIGURATION'
);
export const SET_FEEDBACK_STATUS_SUCCESS = createAction(
  'user/SET_FEEDBACK_STATUS_SUCCESS'
);

export const SET_FEEDBACK_STATUS = createAction('user/SET_FEEDBACK_STATUS');

export const SET_FEEDBACK_BANNER_STATUS = createAction(
  'user/SET_FEEDBACK_BANNER_STATUS'
);
export const SET_LOCATION_ID = createAction('user/SET_LOCATION_ID');
export const SET_LOCATION_ID_SUCCESS = createAction(
  'user/SET_LOCATION_ID_SUCCESS'
);
