import styled from 'styled-components';
import IconInfo from '../../../assets/icon-question-green.svg';
import IconInfoOutline from '../../../assets/icon-question-outline-red.svg';
import IconInfoOrange from '../../../assets/icon-info-orange.svg';
import IconBgDecor from '../../../assets/icon-arrow-hint.svg';
import IconBgDecorOrange from '../../../assets/icon-arrow-hint-orange.svg';

const ButtonInfo = styled.div`
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background-image: url('${IconInfo}');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-color: transparent;
  display: inline-block;
  position: relative;

  &.InsightsGrowsMetric {
    background-image: url('${IconInfoOutline}');
    background-size: 22px;
    width: 28px;
    height: 28px;
    margin-left: 20px;
  }

  &.OutlineBig {
    background-image: url('${IconInfoOutline}');
    background-size: 22px;
    width: 28px;
    height: 28px;
  }

  &.Outline {
    background-image: url('${IconInfoOutline}');
    background-size: 18px;
  }

  &.OutlineLg {
    background-image: url('${IconInfoOutline}');
    background-size: 20px;
  }

  &.OrangeOutline {
    background-image: url('${IconInfoOrange}');
    background-size: 18px;
  }

  &.WithoutPointer {
    cursor: auto;
  }

  &.MarginBottomMob {
    @media (max-width: 599px) {
      margin-bottom: 10px;
    }
  }
  &.ButtonInfoInsightsPerformance {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  &.LeftMargin {
    margin-left: 11px;
  }

  .DropInfo {
    position: absolute;
    top: calc(100% + 8px);
    right: -22px;
    width: 475px;
    border: 2px solid #ff2d38;
    background-color: #fff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #232d3a;
    text-align: left;
    transform: translateY(2px);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;

    @media (max-width: 1000px) {
      width: 400px;
      padding: 16px;
      font-size: 13px;
    }
    @media (max-width: 767px) {
      width: 300px;
      padding: 12px;
      font-size: 12px;
    }
    @media (max-width: 700px) {
      width: 280px;
      font-size: 11px;
      padding: 10px;
    }
    @media (max-width: 660px) {
      width: 220px;
      font-size: 11px;
    }
    @media (max-width: 599px) {
      right: -18px;
    }

    .mainItems {
      display: block;
      padding-left: 18px;

      li {
        list-style-type: disc;

        strong {
          font-weight: 500;
        }
      }
    }

    .childItems {
      display: block;
      padding-left: 38px;

      li {
        list-style-type: circle;

        i {
          font-style: italic;
        }
      }
    }

    &::after {
      position: absolute;
      bottom: 100%;
      right: 14px;
      content: '';
      width: 35px;
      height: 10px;
      background-image: url('${IconBgDecor}');
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 599px) {
        right: 10px;
      }
    }

    &.DropInfoCenter {
      right: 50%;
      transform: translateX(50%);

      &::after {
        right: 50%;
        transform: translateX(50%);
      }
    }

    &.DropInfoRightLaptop {
      @media (max-width: 1650px) {
        right: -18px;
        transform: none;
        &::after {
          right: 10px;
          transform: none;
        }
      }
    }

    &.DropInfoLeftTablet {
      @media (max-width: 1000px) {
        left: -18px;
        transform: none;
        right: inherit;
        &::after {
          left: 10px;
          right: inherit;
          transform: none;
        }
      }
    }
    &.DropInfoCenterMob {
      @media (max-width: 599px) {
        right: 50%;
        left: inherit;
        transform: translateX(50%);

        &::after {
          right: 50%;
          left: inherit;
          transform: translateX(50%);
        }
      }
    }

    &.DropInfoRightMob {
      @media (max-width: 599px) {
        right: -18px;
        transform: none;
        &::after {
          right: 10px;
          transform: none;
        }
      }
    }

    &.DropInfoLeft {
      right: inherit;
      left: -22px;

      &::after {
        right: inherit;
        left: 14px;
        @media (max-width: 599px) {
          left: 10px;
        }
      }

      @media (max-width: 599px) {
        left: -18px;
      }
    }

    &.DropInfoOrange {
      background-color: #ff8412;

      &::after {
        background-image: url('${IconBgDecorOrange}');
      }
    }

    &.DropInfoUp {
      top: inherit;
      bottom: calc(100% + 8px);

      &::after {
        bottom: inherit;
        top: 100%;
        transform: rotate(180deg) translateX(-50%);
      }
    }

    &.DropInfoUpInsightsPerformance {
      top: auto;
      bottom: calc(100% + 8px);
      z-index: 12;
      cursor: default;

      &::after {
        bottom: inherit;
        top: 100%;
        transform: rotate(180deg) translateX(-50%);
        right: 33px;
      }
      .clickHereLink {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
      }
    }

    &.Small {
      width: 280px;
      @media (max-width: 1000px) {
        width: 280px;
        padding: 16px;
        font-size: 13px;
      }
      @media (max-width: 767px) {
        width: 280px;
        padding: 12px;
        font-size: 12px;
      }
      @media (max-width: 700px) {
        width: 280px;
        font-size: 11px;
        padding: 10px;
      }
      @media (max-width: 660px) {
        width: 220px;
        font-size: 11px;
      }
    }
  }

  &.ButtonInfoDrop {
    &:hover {
      .DropInfo {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;

        &.DropInfoCenter {
          transform: translate(50%, 0);
        }

        &.DropInfoRightLaptop {
          @media (max-width: 1650px) {
            transform: none;
          }
        }

        &.DropInfoLeftTablet {
          @media (max-width: 1000px) {
            transform: none;
          }
        }
        &.DropInfoCenterMob {
          @media (max-width: 599px) {
            transform: translate(50%, 0);
          }
        }

        &.DropInfoRightMob {
          @media (max-width: 599px) {
            transform: none;
          }
        }

        &.DropInfoBADCatering {
          a {
            color: #ff2d38;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }
  }

  &.ButtonClick {
    transition-property: opacity;
    transition-duration: 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export default ButtonInfo;
