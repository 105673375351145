import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DialogAction, DialogBody} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import AnimateLocationDelivery from '../../../../assets/animate-delivery-red.gif';
import {OrderSuccessImage} from './styles';
import {ButtonPrimary} from '../../../../components/buttons';
import {
  CLEAR_BAD_NEW_ACTIVE_ORDERS,
  CLEAR_BAD_NEW_DELIVERED_ORDERS,
  RUN_BAD_DELIVERED_ORDERS_JOB,
  STOP_BAD_DELIVERED_ORDERS_JOB
} from '../../../../store/global/bookADriverDelivered/action';

const BADCourierModal = () => {
  const dispatch = useDispatch();
  const {newActiveOrders} = useSelector(
    (state) => state.bookADriverDeliveredOrdersReducer
  );
  const {length} = newActiveOrders || [];

  const onClose = () => {
    dispatch(CLEAR_BAD_NEW_ACTIVE_ORDERS());
    dispatch(STOP_BAD_DELIVERED_ORDERS_JOB());
    dispatch(RUN_BAD_DELIVERED_ORDERS_JOB());
  };
  return (
    <Dialog open={length > 0} onClose={onClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle>
          The driver you have booked <span>will arrive in 5 min</span>
        </DialogTitle>

        <DialogBody>
          <OrderSuccessImage src={AnimateLocationDelivery} />
          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>Ok</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

BADCourierModal.propTypes = {};

export default BADCourierModal;
