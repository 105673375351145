/* eslint-disable */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import TextField from '@material-ui/core/TextField';
import {
  DateRangeDelimiter,
  LocalizationProvider,
  StaticDateRangePicker
} from '@material-ui/pickers';
import endOfDay from 'date-fns/endOfDay';
import {useDatePickerContext} from '../Datepicker/DatePickerContext';
import moment from 'moment';

const DatePicker = ({
  setSelectedDate,
  minDate,
  disablePast,
  dateValue,
  datepickerOnChange,
  disableFuture,
  dateHolidays
}) => {
  DatePicker.propTypes = {
    setSelectedDate: PropTypes.func.isRequired,
    dateValue: PropTypes.instanceOf(Array).isRequired,
    datepickerOnChange: PropTypes.func.isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
    disablePast: PropTypes.bool.isRequired,
    dateHolidays: PropTypes.bool.isRequired
  };

  const localTimeZone = moment.parseZone(new Date()).utcOffset() / 60;
  const getLocalTimeZone = (date) => {
    return moment.parseZone(moment(date).toDate()).utcOffset() / 60;
  };

  const transformDateToDatepicker = (dateToTransform) => {
    if (dateToTransform) {
      return moment(
        moment(dateToTransform).add(-getLocalTimeZone(dateToTransform), 'hours')
      ).toDate();
    }
  };

  DatePicker.defaultProps = {};
  const date = useDatePickerContext();
  useEffect(() => {
    setSelectedDate(dateValue);
  }, [dateValue, setSelectedDate]);
  return (
    <>
      {date && (
        <>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <StaticDateRangePicker
              calendars={2}
              displayStaticWrapperAs='desktop'
              // autoOk
              // label="Clearable"
              // clearable
              disablePast={disablePast}
              minDate={minDate}
              disableFuture={disableFuture}
              startText='Check-in'
              endText='Check-out'
              value={[
                transformDateToDatepicker(dateValue[0]),
                transformDateToDatepicker(dateValue[1])
              ]}
              onChange={(data) => {
                // if (data[0] && data[1]) {
                //   datepickerOnChange([moment(data[0])
                //       .utcOffset(0, true)
                //       .format(),
                //     moment(data[1]).endOf('day')
                //       .utcOffset(0, true)
                //       .format(),
                //   ]);
                // }
                if (data[0] && data[1]) {
                  if (dateHolidays) {
                    let startDate = moment(data[0]).format('MMMM Do YYYY');
                    let endDate = moment(data[1]).format('MMMM Do YYYY');
                    const setEndDate =
                      startDate === endDate
                        ? moment(data[1]).add(1, 'days')
                        : data[1];
                    datepickerOnChange([
                      moment(data[0]).utcOffset(0, true).format(),
                      moment(data[1]).utcOffset(0, true).format()
                    ]);
                  } else {
                    datepickerOnChange([
                      moment(data[0]).utcOffset(0, true).format(),
                      moment(data[1]).endOf('day').utcOffset(0, true).format()
                    ]);
                  }
                } else {
                  if (dateHolidays) {
                    datepickerOnChange([
                      moment(data[0]).utcOffset(0, true).format(),
                      moment(data[0]).utcOffset(0, true).format()
                    ]);
                  }
                }
              }}
              renderInput={(startProps, endProps) => {
                return (
                  <>
                    <TextField {...startProps} />
                    <DateRangeDelimiter> to </DateRangeDelimiter>
                    <TextField {...endProps} />
                  </>
                );
              }}
            />
          </LocalizationProvider>
        </>
      )}
    </>
  );
};

export default DatePicker;
