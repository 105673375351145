import React, {useState} from 'react';
import moment from 'moment';
import T from 'prop-types';
import {TextField} from '@material-ui/core';
import {DateRangePicker} from '@material-ui/pickers';
import DatepickerContainer from './styles';

let selectedValue = null;

const DatepickerS2E = ({
  onChange,
  onClose,
  value,
  hideTextFrom,
  disabled,
  calendars,
  disablePast
}) => {
  const [open, setOpen] = useState(false);

  const handlePickerOpen = () => {
    setOpen((prevState) => !prevState);
  };

  const preventInputTyping = (e) => {
    e.preventDefault();
    return false;
  };

  return (
    <DatepickerContainer className={disabled && 'disabled'}>
      <DateRangePicker
        open={open}
        disablePast={disablePast}
        calendars={calendars || 1}
        value={value}
        onChange={(newValue) => {
          selectedValue = newValue;
          onChange(newValue);
        }}
        onClose={() => {
          onClose(selectedValue);
          setOpen(false);
        }}
        renderInput={(startProps, endProps) => {
          if (selectedValue && selectedValue[0]) {
            // eslint-disable-next-line no-param-reassign
            startProps.inputProps.value = moment(selectedValue[0]).format(
              'MM/DD/YYYY'
            );
          }
          if (selectedValue && selectedValue[1]) {
            // eslint-disable-next-line no-param-reassign
            endProps.inputProps.value = moment(selectedValue[1]).format(
              'MM/DD/YYYY'
            );
          }

          return (
            <>
              {!hideTextFrom && <body1 className='Delimiter'>From</body1>}
              <TextField
                placeholder='Start date'
                {...startProps}
                onClick={() => handlePickerOpen()}
                onKeyDown={(e) => preventInputTyping(e)}
              />
              <body1 className='Delimiter DelimiterMiddle'>to</body1>
              <TextField
                {...endProps}
                onClick={() => handlePickerOpen()}
                onKeyDown={(e) => preventInputTyping(e)}
              />
            </>
          );
        }}
      />
    </DatepickerContainer>
  );
};

DatepickerS2E.propTypes = {
  onChange: T.func,
  value: T.string.isRequired,
  onClose: T.func,
  hideTextFrom: T.bool,
  disabled: T.bool,
  calendars: T.number,
  disablePast: T.bool
};

DatepickerS2E.defaultProps = {
  onChange: () => {},
  onClose: () => {},
  hideTextFrom: false,
  disabled: false,
  calendars: 1,
  disablePast: false
};

export default DatepickerS2E;
