import React from 'react';
import PropTypes from 'prop-types';
import {Remove, SearchField as SearchFieldStyles, Wrapper} from './style';

export default function SearchField({onClear, isClearIconVisible, ...props}) {
  return (
    <Wrapper>
      <SearchFieldStyles {...props} />
      {isClearIconVisible && <Remove onClick={onClear} />}
    </Wrapper>
  );
}

SearchField.propTypes = {
  onClear: PropTypes.func.isRequired,
  isClearIconVisible: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};
