import React from 'react';
import SvgButtonIcon from '../components/misc/SvgButtonIcon';

export const IconOrderAdjust = () => (
  <SvgButtonIcon
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.4999 0H10.2655C9.85303 0 9.2766 0.238929 8.9852 0.530108L0.437725 9.07754C-0.145908 9.66051 -0.145908 10.6163 0.437725 11.1986L6.80201 17.5627C7.38433 18.1456 8.33921 18.1456 8.9224 17.562L17.4699 9.01589C17.761 8.72475 18 8.1474 18 7.73586V1.50006C18 0.675215 17.3247 0 16.4999 0ZM13.4998 6.00003C12.6713 6.00003 11.9997 5.32771 11.9997 4.49997C11.9997 3.67086 12.6713 2.9999 13.4998 2.9999C14.3282 2.9999 15 3.67086 15 4.49997C15.0001 5.32771 14.3282 6.00003 13.4998 6.00003Z'
      fill='#FF2D38'
    />
  </SvgButtonIcon>
);

export default IconOrderAdjust;
