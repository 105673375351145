import styled from 'styled-components';
import body1 from 'components/layout/body1/styles';

export const TableText = styled(body1)`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const PhoneOrderingTableWrapper = styled.div`
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #232d3a;
    padding-right: 20px;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;
    @media (max-width: 1700px) {
      font-size: 14px;
    }
  }
  .MuiTable-root {
    @media (max-width: 1200px) {
      min-width: 1100px;
    }
    tr {
      &:first-child {
        th {
          padding-top: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 15%;
          }
        }
      }
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;
