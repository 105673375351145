import {useSelector} from 'react-redux';

export const stepData = () =>
  useSelector((state) => {
    return {
      step0: state.setupRestaurantReducer.PendingData[0] || {},
      step1: state.setupRestaurantReducer.PendingData[1] || {},
      UserId: state.user.userData?.id,
      dataReceived: state.setupRestaurantReducer.dataReceived,
      currentStep: state.setupRestaurantReducer.step,
      isModalOpen: state.setupRestaurantReducer.isModalOpen,
      loading: state.setupRestaurantReducer.loading,
      restaurantName:
        state.setupRestaurantReducer.PendingData[0]?.Merchant?.MerchantName,
      shortUrl: state.setupRestaurantReducer?.shortLink?.mask
        ? state.setupRestaurantReducer?.shortLink?.mask
        : state.setupRestaurantReducer?.shortLink?.short,
      countries: state.setupRestaurantReducer?.countries,
      pendingDataSetuped: state.user?.userData?.pendingDataSetuped,
      uberEatsStoresOnboarding:
        state.setupRestaurantReducer.uberEatsStoresOnboarding,
      isUberEatsConnected: state.setupRestaurantReducer.isUberEatsConnected,
      uberEatsStoreSelectModalOpen:
        state.setupRestaurantReducer.uberEatsStoreSelectModalOpen,
      uberEatsStoreMenusOnboarding:
        state.setupRestaurantReducer.uberEatsStoreMenusOnboarding,
      uberEatsMenusLoading: state.setupRestaurantReducer.uberEatsMenusLoading
    };
  });

export const generalInfo = (state) =>
  state.setupRestaurantReducer.PendingData[0] || {};
export const selectRestaurantUrl = () =>
  useSelector(
    (state) =>
      state.user.userData?.RestaurantURL ||
      state.setupRestaurantReducer?.RestaurantURL ||
      state.setupRestaurantReducer?.PendingData[0]?.Merchant?.RestaurantURL
  );
export const SignUpData = (state) => state.user.userData;
export const PendingData = () =>
  useSelector((state) => state.setupRestaurantReducer.PendingData);
export const WelcomeModalData = () =>
  useSelector((state) => state.setupRestaurantReducer.isWelcomeModalOpen);
export const SidebarProgressSales = () =>
  useSelector((state) => state.setupRestaurantReducer.sidebarProgress);
