import {createReducer} from 'redux-act';
import {GET_SALES_PER_HOUR, GET_SALES_PER_HOUR_SUCCESS} from './action';

const initialState = () => ({
  salesPerHour: [],
  salesPerHourLoading: false
});

const analyticsSalesPerHourReducer = createReducer(
  {
    [GET_SALES_PER_HOUR]: (state) => ({
      ...state,
      salesPerHourLoading: true
    }),
    [GET_SALES_PER_HOUR_SUCCESS]: (state, payload) => ({
      ...state,
      salesPerHour: payload,
      salesPerHourLoading: false
    })
  },
  initialState()
);

export default analyticsSalesPerHourReducer;
