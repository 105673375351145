import React from 'react';
import T from 'prop-types';
import EmptyContentDiv from './style';
import Body1 from '../typography/Body1';

const EmptyContentBox = (props) => {
  const {className, message} = props;

  return (
    <EmptyContentDiv className={className}>
      <Body1 className='sm'>{message}</Body1>
    </EmptyContentDiv>
  );
};

EmptyContentBox.propTypes = {
  className: T.string,
  message: T.string
};

EmptyContentBox.defaultProps = {
  className: '',
  message: 'No content'
};

export default EmptyContentBox;
