import {useSelector} from 'react-redux';

const virtualNumberData = () =>
  useSelector((state) => {
    return {
      info: state?.virtualNumberReducer?.info || {},
      loading: state?.virtualNumberReducer?.loading || false,
      isShowSuccessModal:
        state?.virtualNumberReducer?.isShowSuccessModal || false,
      isEdit: state?.virtualNumberReducer?.isEdit || false,
      phoneNumberUpdated:
        state?.virtualNumberReducer?.phoneNumberUpdated || false
    };
  });

export default virtualNumberData;
