import {createAction} from 'redux-act';

export const RC_GET_ORDER = createAction('refundsCalculator/RC_GET_ORDER');
export const RC_GET_ORDER_SUCCESS = createAction(
  'refundsCalculator/RC_GET_ORDER_SUCCESS'
);
export const RC_ORDER_INVOICE_ERROR = createAction(
  'refundsCalculator/RC_ORDER_INVOICE_ERROR'
);
export const RC_HANDLE_CHECK = createAction(
  'refundsCalculator/RC_HANDLE_CHECK'
);
export const RC_QUANTITY_CHANGE = createAction(
  'refundsCalculator/RC_QUANTITY_CHANGE'
);
export const RC_FEES_AND_TAXES_CHECK = createAction(
  'refundsCalculator/RC_FEES_AND_TAXES_CHECK'
);
export const RC_LOCATION_FAULT_SELECT = createAction(
  'refundsCalculator/RC_LOCATION_FAULT_SELECT'
);
export const RC_SET_SUBTOTAL_PERCENT = createAction(
  'refundsCalculator/RC_SET_SUBTOTAL_PERCENT'
);

export const RC_GENERATE_ORDER_REFUND = createAction(
  'refundsCalculator/RC_GENERATE_ORDER_REFUND'
);
export const RC_GENERATE_ORDER_REFUND_SUCCESS = createAction(
  'refundsCalculator/RC_GENERATE_ORDER_REFUND_SUCCESS'
);

export const RC_CLEAR_CALCULATOR_DATA = createAction(
  'refundsCalculator/RC_CLEAR_CALCULATOR_DATA'
);
export const RC_CLEAR_ALL_CALCULATOR_DATA = createAction(
  'refundsCalculator/RC_CLEAR_ALL_CALCULATOR_DATA'
);

export const RC_OPEN_CONFIRMATION_MODAL = createAction(
  'refundsCalculator/RC_OPEN_CONFIRMATION_MODAL'
);

export const RC_MAKE_ORDER_REFUND = createAction(
  'refundsCalculator/RC_MAKE_ORDER_REFUND'
);

export const RC_OPEN_RESULT_MODAL = createAction(
  'refundsCalculator/RC_OPEN_RESULT_MODAL'
);
export const RC_SET_LOADING = createAction('refundsCalculator/RC_SET_LOADING');

export const RC_SET_RESULT_LOADING = createAction(
  'refundsCalculator/RC_SET_RESULT_LOADING'
);

export const RC_ADJUSTMENTS_CHECK = createAction(
  'refundsCalculator/RC_ADJUSTMENTS_CHECK'
);
