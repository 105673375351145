import {SERVER_URL} from './API_CONSTANTS';

export const SET_WEBSITE_TEMPLATE = (userId) =>
  `${SERVER_URL}/account/${userId}`;
export const CONFIRM_USER_EMAIL = (userId, email) =>
  `${SERVER_URL}/account/${userId}/confirmation/${email}`;
export const GET_GOOGLE_AUTH_LINK = () => `${SERVER_URL}/google-auth/link`;
export const POST_GOOGLE_AUTH_CODE = () =>
  `${SERVER_URL}/google-auth/save-token`;
export const GET_ACCOUNT_STATUS_STEP = (userId, merchantId) =>
  `${SERVER_URL}/account/${userId}/status/${merchantId}`;
export const POST_TEAM_MEMBER = (merchantId, userId) =>
  `${SERVER_URL}/account/${merchantId}/${userId}/members`;
export const POST_OWNER = (merchantId) =>
  `${SERVER_URL}/account/${merchantId}/add-owner`;
export const POST_STRIPE_ACCOUNT = (userId) =>
  `${SERVER_URL}/account/${userId}/stripe`;

export const POST_STRIPE_SIGN_UP_2_ACCOUNT = (userId) =>
  `${SERVER_URL}/signUp2/account/${userId}/stripe`;
export const GET_ACCOUNT_STATUS_SIGN_UP_2_STEP = (userId) =>
  `${SERVER_URL}/signUp2/account/${userId}/statuses`;
export const BOOK_A_CALL = (userId) =>
  `${SERVER_URL}/signUp2/account/${userId}/bookCall`;
