import styled from 'styled-components';
import IconSearch from '../../../../assets/icon-search.svg';

const SearchField = styled.input`
  display: block;
  position: relative;
  width: 100%;
  outline: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: rgba(23, 39, 81, 1);
  padding: 0 16px 0 70px;
  background-image: url('${IconSearch}');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center left 28px;
  background-color: #fff;
  height: 56px;
  border-radius: 40px;
  border: 1px solid #dfe2ea;

  &::placeholder {
    color: rgba(23, 39, 81, 0.6);
  }
`;

export const TeamTableWrapper = styled.div`
  padding: 15px 20px 20px 20px;
  @media (max-width: 1440px) {
    padding: 0;
  }
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #232d3a;
    padding-right: 20px;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;
  }
  .MuiTable-root {
    tr {
      &:first-child {
        th {
          padding-top: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 15%;
          }
        }
      }
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .MuiTableRow-root {
    vertical-align: top;
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;

export const TeamCounter = styled.div`
  padding: 30px 0;
  display: flex;
  font-size: 14px;
`;

export const CounterBadge = styled.span`
  display: flex;
  border-right: 2px solid #ff2d38;
  margin-left: 15px;
  padding-right: 15px;

  &:last-child {
    border-right: none;
  }
  &:first-child {
    margin-left: 0;
  }
`;

export const CounterCount = styled.b`
  color: #ff2d38;
  padding-left: 10px;
`;

export default SearchField;
