import React from 'react';
import PropTypes from 'prop-types';
import Headline from './HeadlineComponentStyle';

const HeadlineComponent = (props) => {
  HeadlineComponent.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    buttonInfo: PropTypes.objectOf(PropTypes.any)
  };

  HeadlineComponent.defaultProps = {
    label: '',
    className: '',
    buttonInfo: ''
  };

  const {label = 'Label', buttonInfo, className} = props;
  return (
    <Headline className={className}>
      {label} {buttonInfo}
    </Headline>
  );
};

export default HeadlineComponent;
