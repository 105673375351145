import styled from 'styled-components';

const RadioInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0 45px;

  .captionRadioPrices {
    margin-right: 22px;
  }

  .inputsWrapper {
    display: flex;
  }

  .inputAmount {
    width: 85px;
    margin-right: 35px;
  }

  label {
    margin-right: 30px;
    .MuiTypography-body1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #232d3a;
    }
  }
`;

export default RadioInputsWrapper;
