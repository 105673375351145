import React from 'react';
import T from 'prop-types';
import {Dialog} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogFields from '../../../../../components/DialogFields/styles';
import {DialogBody} from '../../../dashboard/styles';
import DialogField from '../../../../../components/DialogField/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogActions from '../../../../../components/DialogActions/styles';
import {OPEN_INFORMATION_MODAL} from '../../../../../store/emailMarketing/action';

const InformationModal = ({openModal}) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(OPEN_INFORMATION_MODAL(false));
  };

  return (
    <Dialog open={openModal} onClose={() => onClose()} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom'>
          How attributed sales are calculated
        </DialogTitle>
        <DialogBody>
          <DialogFields>
            <DialogField className='w100 TextJustify'>
              Attributed Sales is the total dollar amount attributed to orders
              placed as a result of marketing activities.
            </DialogField>
            <DialogField className='w100 TextJustify'>
              Sauce attributes a guest’s order to your marketing activity if the
              guest places an order within 30 days of receiving your email, and
              provides their email address with the order.
            </DialogField>
            <DialogField className='w100 TextJustify'>
              Note that not all orders count towards the attributed sales
              amount. Sales that are made with a different email address will
              not count towards the total, so your sales amounts are likely to
              be higher than the attributed.
            </DialogField>
            <DialogField className='w100 TextJustify'>
              Further, note that 30-day attribution may include results of
              campaigns that started more than 30 days ago and will therefore
              not be included in the last 30-day emails sent and delivered
              count.
            </DialogField>
          </DialogFields>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w40'>
              <ButtonPrimary onClick={() => onClose()} type='button'>
                <span>Okay</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

InformationModal.propTypes = {
  openModal: T.bool.isRequired
};

export default InformationModal;
