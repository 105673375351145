import styled from 'styled-components';

export const ButtonSave = styled.button`
  position: fixed;
  bottom: 84px;
  right: 19px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32);
  border-radius: 30px;
  padding: 10px;
  width: 56px;
  height: 56px;
  border: none;
  outline: none;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #f0868c 96.81%
    );
    z-index: 2;
    transition-property: opacity;
    transition-duration: 0.3s;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #ff2d38 96.81%
    );
    z-index: 1;
  }
  &:hover {
    &::before {
      opacity: 0;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 3;
    fill: #fff;
  }

  &.disabled {
    background-image: none;
    background-color: #ededed;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    &::before,
    &::after {
      display: none;
    }
  }
`;

export default ButtonSave;
