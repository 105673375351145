import React, {useEffect, useRef} from 'react';
import {Dialog} from '@material-ui/core';
import T from 'prop-types';
import {Form, Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO,
  INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL,
  INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO
} from 'store/insights/performance/action';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {DialogSubTitle} from 'components/DialogSubTitle/styles';
import {
  DialogField,
  DialogFieldText,
  FieldInput,
  FieldInputPercent
} from 'routes/App/dashboard/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {INSIGHTS_PERFORMANCE_SETTINGS_CATERING_VALUES} from 'helpers/constants/Insights/performance';
import InsightsPerformanceSettingsSchema from 'helpers/services/formValidationSchemas/InsightsPerformanceSettingsSchema';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import {twoDecimalsFormatter} from 'helpers/functionUtils/helpFunctions';
import Loading from 'components/shared/Utils/Loading';

const SettingsModal = ({openModal, date}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {settingsLoading, settingsInfo} = useSelector(
    (state) => state.insightsPerformanceReducer
  );
  const {locationId} = useSelector((state) => state.user);

  useEffect(() => {
    if (openModal) {
      formikRef?.current?.resetForm();
      dispatch(INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO());
    }
  }, [openModal]);
  const closeModal = () => {
    dispatch(INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL(false));
  };

  const initialValues = {
    averageDeliveryCommission: settingsInfo?.averageDeliveryCommission,
    averagePickupCommission: settingsInfo?.averagePickupCommission,
    averageMonthlyCateringOrders: settingsInfo?.averageMonthlyCateringOrders
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={InsightsPerformanceSettingsSchema}
        onSubmit={(values, {resetForm}) => {
          closeModal();
          dispatch(
            INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO({
              locationId,
              body: values,
              date
            })
          );
        }}
      >
        {({values, handleChange, setFieldValue, errors, touched}) => {
          return (
            <Dialog
              open={openModal}
              onClose={() => {
                closeModal();
              }}
              scroll='body'
            >
              <DialogContainer className='InsightsPerformanceSettingsModal'>
                {settingsLoading ? (
                  <Loading />
                ) : (
                  <>
                    <DialogTitle className='mB17'>Settings</DialogTitle>
                    <DialogBody>
                      <Form>
                        <DialogSubTitle className='mB0'>
                          3rd party order commissions
                        </DialogSubTitle>
                        <DialogField className='w50 dFlexNoWrap dFlexSpaceBetween noPadding mB8'>
                          <DialogFieldText className='mRight fontWeight400'>
                            Avg. delivery commission per order
                          </DialogFieldText>

                          <ErrorSignWrapper
                            topPosition='-14px'
                            leftPosition='0px'
                          >
                            {errors &&
                              errors.averageDeliveryCommission &&
                              touched.averageDeliveryCommission && (
                                <ErrorSign>
                                  {errors.averageDeliveryCommission}
                                </ErrorSign>
                              )}
                          </ErrorSignWrapper>

                          <FieldInputPercent className='FieldInputSm'>
                            <FieldInput
                              type='text'
                              name='averageDeliveryCommission'
                              value={values.averageDeliveryCommission}
                              onChange={(e) => {
                                const value = twoDecimalsFormatter(
                                  e.target.value,
                                  values.averageDeliveryCommission,
                                  100,
                                  true
                                );
                                setFieldValue(
                                  'averageDeliveryCommission',
                                  value
                                );
                              }}
                              className={`${
                                errors &&
                                errors.averageDeliveryCommission &&
                                touched.averageDeliveryCommission &&
                                'error'
                              }`}
                            />
                          </FieldInputPercent>
                        </DialogField>
                        <DialogField className='w50 dFlexNoWrap dFlexSpaceBetween noPadding mB37'>
                          <DialogFieldText className='mRight fontWeight400'>
                            Avg. pickup commission per order
                          </DialogFieldText>

                          <ErrorSignWrapper
                            topPosition='-14px'
                            leftPosition='0px'
                          >
                            {errors &&
                              errors.averagePickupCommission &&
                              touched.averagePickupCommission && (
                                <ErrorSign>
                                  {errors.averagePickupCommission}
                                </ErrorSign>
                              )}
                          </ErrorSignWrapper>

                          <FieldInputPercent className='FieldInputSm'>
                            <FieldInput
                              type='text'
                              name='averagePickupCommission'
                              value={values.averagePickupCommission}
                              onChange={(e) => {
                                const value = twoDecimalsFormatter(
                                  e.target.value,
                                  values.averagePickupCommission,
                                  100,
                                  true
                                );
                                setFieldValue('averagePickupCommission', value);
                              }}
                              className={`${
                                errors &&
                                errors.averagePickupCommission &&
                                touched.averagePickupCommission &&
                                'error'
                              }`}
                            />
                          </FieldInputPercent>
                        </DialogField>
                        <DialogSubTitle className='mB0'>
                          Catering
                        </DialogSubTitle>
                        <DialogField className='w50 dFlexNoWrap dFlexSpaceBetween noPadding'>
                          <DialogFieldText className='mRight fontWeight400'>
                            Avg. monthly catering orders
                          </DialogFieldText>

                          <ErrorSignWrapper
                            topPosition='-14px'
                            leftPosition='0px'
                          >
                            {errors &&
                              errors.averageMonthlyCateringOrders &&
                              touched.averageMonthlyCateringOrders && (
                                <ErrorSign>
                                  {errors.averageMonthlyCateringOrders}
                                </ErrorSign>
                              )}
                          </ErrorSignWrapper>

                          <DialogField className='w97px p0 Say2EatSelect'>
                            <FormControl>
                              <Select
                                inputProps={{'aria-label': 'value'}}
                                onChange={(e) => {
                                  setFieldValue(
                                    'averageMonthlyCateringOrders',
                                    e.target.value
                                  );
                                }}
                                value={values.averageMonthlyCateringOrders}
                                name='averageMonthlyCateringOrders'
                                className={`${
                                  errors &&
                                  errors.averageMonthlyCateringOrders &&
                                  touched.averageMonthlyCateringOrders &&
                                  'error'
                                }`}
                              >
                                {INSIGHTS_PERFORMANCE_SETTINGS_CATERING_VALUES.map(
                                  (value) => (
                                    <MenuItem value={value.value}>
                                      {value.title}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </DialogField>
                        </DialogField>
                        <DialogActions className='justifySpaceBetween'>
                          <DialogAction className='w50'>
                            <ButtonOutline
                              type='button'
                              onClick={() => {
                                closeModal();
                              }}
                            >
                              <span>Cancel</span>
                            </ButtonOutline>
                          </DialogAction>
                          <DialogAction className='w50'>
                            <ButtonPrimary type='submit'>
                              <span>Save</span>
                            </ButtonPrimary>
                          </DialogAction>
                        </DialogActions>
                      </Form>
                    </DialogBody>
                  </>
                )}
              </DialogContainer>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

SettingsModal.propTypes = {
  openModal: T.bool.isRequired,
  date: T.array.isRequired
};

export default SettingsModal;
