import * as Yup from 'yup';

const ItemSchema = Yup.object().shape({
  Name: Yup.string()
    .max(750, 'Item name is too long')
    .required('Item name field is required!')
    .trim(),
  Price: Yup.number().moreThan(0).required('Item price field is required!'),
  Description: Yup.string().max(
    4000,
    'Description field cannot be more than 4000 characters'
  ),
  Categories: Yup.array().min(1, 'Pick at least 1 category').of(Yup.object({}))
});

export default ItemSchema;
