import styled from 'styled-components';

// TODO: an experimental component as well as FlexItem. May not be used in future.

const BoxMenuItems = styled.div`
  display: flex;
  align-items: flex-start;
  .RecursionRelation_Connect {
    flex: 1 1 auto;
    margin: 24px 10px 0 10px;
    fill: #ff2d38;
  }
`;

export default BoxMenuItems;
