/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import memoize from 'fast-memoize';
import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import CustomToast from '../../components/shared/Utils/CustomToast';
import {
  deleteModifierApi,
  getAllModifiersApi,
  getModifierByIdApi,
  patchModifierApi,
  postModifierApi,
  updateModifierApi
} from '../../helpers/services/api/menuEditor';
import {
  DELETE_MODIFIER,
  DELETE_MODIFIER_SUCCESS,
  GET_ALL_MODIFIER,
  GET_ALL_MODIFIER_SUCCESS,
  GET_MODIFIER_BY_ID,
  GET_MODIFIER_BY_ID_SUCCESS,
  PATCH_MODIFIER,
  PATCH_MODIFIER_SUCCESS,
  POST_MODIFIER,
  POST_MODIFIER_SUCCESS,
  UPDATE_MODIFIER,
  UPDATE_MODIFIER_SUCCESS
} from './action';
import {
  ADD_NEW_MODIFIER,
  SET_UPDATED_MODIFIER,
  CLEAR_MODIFIER
} from '../menuEditor/action';

const toJsState = (state) => {
  return state.toJS();
};

const getAllModifiersState = ({menuEditorModifierReducer}) =>
  menuEditorModifierReducer.allModifiersPagination;
const entityId = ({menuEditorModalsReducer}) =>
  menuEditorModalsReducer.entityId;

export const getState = memoize(toJsState);

export function* getAllModifiers({payload}) {
  try {
    const itemsParams = yield select(getAllModifiersState);
    const pagination = {
      ...itemsParams,
      // outStock: payload.outStock,
      // fields: payload.fields,
      // search: 'EGV',
      ...payload
    };

    const {data} = yield getAllModifiersApi(pagination);

    if (data) {
      yield put(GET_ALL_MODIFIER_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* modifierById({payload}) {
  try {
    const {data} = yield getModifierByIdApi(payload);
    if (data) {
      yield put(GET_MODIFIER_BY_ID_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* postNewModifier({payload}) {
  try {
    const {data} = yield postModifierApi(payload);

    if (data || data === '') {
      yield put(ADD_NEW_MODIFIER({id: data.PosId, data}));
      toast.success('Modifier has been created');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* updateModifier({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield updateModifierApi(id, form);

    if (data || data === '') {
      yield put(SET_UPDATED_MODIFIER({id, data: form}));
      toast.success('Modifier has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* patchModifier({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield patchModifierApi(id || form?.id, form);

    if (data || data === '') {
      yield put(SET_UPDATED_MODIFIER({id: id || form?.id, data: form}));
      toast.success('Modifier has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* deleteModifier({payload}) {
  try {
    const id = yield select(entityId);
    const {data} = yield deleteModifierApi(id || payload);

    if (data || data === '') {
      yield put(CLEAR_MODIFIER(id || payload));
      toast.success('Modifier has been delete');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* menuEditorModifierSaga() {
  yield takeEvery(GET_ALL_MODIFIER, getAllModifiers);
  yield takeEvery(POST_MODIFIER, postNewModifier);
  yield takeEvery(PATCH_MODIFIER, patchModifier);
  yield takeEvery(UPDATE_MODIFIER, updateModifier);
  yield takeEvery(DELETE_MODIFIER, deleteModifier);
  yield takeEvery(GET_MODIFIER_BY_ID, modifierById);
}

export default menuEditorModifierSaga;
