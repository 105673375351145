import React, {useState} from 'react';
import AbstractAdjustRefund from '../components/AbstractAdjustRefund';
import {ButtonPrimary} from '../../../../components/buttons';
import AbstractConfirm from '../modals/AbstractConfirm';
import Headline from '../../../../components/Headline/styles';
import {Body1} from '../../../../components/typography';

export default function AdjustPayment() {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <AbstractAdjustRefund
        content={
          <>
            <Headline className='Sm marginBottomLg'>
              <span>Adjust </span>a Payment
            </Headline>
            <Body1 className='marginBottomLg'>
              A customer may be charged more if you undercharged them for a
              product or service.
            </Body1>
          </>
        }
        actionButton={
          <ButtonPrimary
            onClick={() => setShowConfirm(true)}
            className='ButtonPlus'
          >
            <span>Adjust Payment</span>
          </ButtonPrimary>
        }
      />

      {showConfirm && (
        <AbstractConfirm
          operation={{
            nameTitle: <span>payment ajdustment?</span>,
            completedName: 'adjusted'
          }}
          actionButton={
            <ButtonPrimary className='ButtonPlus'>
              <span>Yes, Adjust Payment</span>
            </ButtonPrimary>
          }
          onClose={() => setShowConfirm(false)}
        />
      )}
    </>
  );
}
