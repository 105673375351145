import styled from 'styled-components';

const SubTitle = styled.h3`
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export default SubTitle;
