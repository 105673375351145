import {createReducer} from 'redux-act';
import {isEqual} from 'lodash';
import {
  RC_CLEAR_ALL_CALCULATOR_DATA,
  RC_CLEAR_CALCULATOR_DATA,
  RC_FEES_AND_TAXES_CHECK,
  RC_GENERATE_ORDER_REFUND,
  RC_GENERATE_ORDER_REFUND_SUCCESS,
  RC_GET_ORDER,
  RC_GET_ORDER_SUCCESS,
  RC_HANDLE_CHECK,
  RC_LOCATION_FAULT_SELECT,
  RC_OPEN_CONFIRMATION_MODAL,
  RC_OPEN_RESULT_MODAL,
  RC_ORDER_INVOICE_ERROR,
  RC_QUANTITY_CHANGE,
  RC_SET_SUBTOTAL_PERCENT,
  RC_SET_LOADING,
  RC_SET_RESULT_LOADING,
  RC_ADJUSTMENTS_CHECK
} from 'store/refundsCalculator/action';

const getPreparedItems = (arr = [], parentPath = []) => {
  let resultArr = arr ? [...arr] : [];
  const previousPath = [...parentPath];
  if (resultArr.length > 0) {
    resultArr = resultArr.map((item) => {
      const path = [...previousPath, item.index];
      return {
        ...item,
        path,
        checked: false,
        selected: item.quantity,
        modifiers: getPreparedItems(item.modifiers, path)
      };
    });
  }
  return resultArr;
};

const unpackItems = (itemsArr = [], result = []) => {
  const resultArr = [];

  const unpack = (arr) => {
    arr.forEach((item) => {
      resultArr.push(item);
      if (item.modifiers.length > 0) {
        unpack(item.modifiers);
      }
    });
  };

  unpack(itemsArr);

  return resultArr;
};

const checkedAllElements = (currentItem, items, keyName, keyValue) => {
  // flat map modifiers in one dimension array
  const getModList = (mods) =>
    mods.flatMap((mod) => [mod, ...getModList(mod.modifiers)]);

  // base case
  return items.map((item) => ({
    ...item,
    [keyName]:
      getModList(currentItem.modifiers)
        .concat(currentItem)
        .findIndex((el) => isEqual(el.path, item.path)) !== -1
        ? keyValue
        : item[keyName]
  }));
};

const formatRestaurantName = (name) => {
  let result;
  if (name) {
    result = name?.toLowerCase().split(' ').join('-');
  }
  return result;
};

const initialState = () => ({
  orderData: {},
  partialRefCheckboxes: {
    tip: false,
    deliveryFee: false
  },
  invoiceIdError: null,
  endCustomerRefund: 0,
  restaurantReverse: 0,
  locationFault: 0,
  percent: 0,
  refundResponsibility: -1,
  openConfirmationModal: false,
  refunds: [],
  resultModal: false,
  resultModalInfo: {},
  loading: false,
  resultLoading: false,
  priceAdjustmentIds: []
});

const refundsCalculatorReducer = createReducer(
  {
    [RC_GET_ORDER]: (state) => {
      return {
        ...state,
        orderData: {}
      };
    },
    [RC_GET_ORDER_SUCCESS]: (state, payload) => {
      const orderData = {
        id: payload.id,
        invoiceId: payload.invoiceId,
        orderType: payload.type || 'N/A',
        orderDate: payload.placedAt || 'N/A',
        orderNotes: payload.notes || 'N/A',
        orderStatus: payload.status || 'N/A',
        pickupDate: payload.pickedUpAt || 'N/A',
        locationName: payload.location?.name || 'N/A',
        customerName: payload.customer?.name || 'N/A',
        customerEmail: payload.customer?.email || 'N/A',
        deliveryId: payload.delivery?.id || 'N/A',
        deliveryCompanyId: payload.delivery?.externalId || 'N/A',
        deliveryCompany: payload.delivery?.service || 'N/A',
        uberId: payload.delivery?.uberId ? payload.delivery?.uberId : 'N/A',
        deliveryStatus: payload.delivery?.status || 'N/A',
        statusReason: payload.delivery?.statusReason || 'N/A',
        deliveryAddress: payload.delivery?.address || 'N/A',
        deliveryInstructions: payload.delivery?.instructions || 'N/A',
        dropOffDelay: payload.delivery?.dropOffDelay || 'N/A',
        trackingLink: payload.delivery?.trackingUrl,
        proofOfDelivery: payload.delivery?.deliveryProof || 'N/A',
        subtotal: payload.payment?.subtotalAfterDiscount || 0,
        finalMarkup: payload.payment?.finalMarkup || 0,
        discount: payload.payment?.discount || 0,
        tax: payload.payment?.tax || 0,
        serviceFee: payload.payment?.serviceFee || 0,
        deliveryFee: payload.payment?.deliveryFee || 0,
        tip: payload.payment?.tip || 0,
        finalPayment: payload.payment?.total || 0,
        items: unpackItems(getPreparedItems(payload.orderItems)),
        paymentInfo: payload.payment?.paymentIntentId || 'N/A',
        priceAdjustments: payload.priceAdjustments || []
      };

      return {
        ...state,
        orderData,
        refunds: payload.refunds || [],
        invoiceIdError: null,
        loading: false
      };
    },
    [RC_ORDER_INVOICE_ERROR]: (state, payload) => {
      return {
        ...state,
        invoiceIdError: payload,
        loading: false
      };
    },
    [RC_HANDLE_CHECK]: (state, {index, checked}) => {
      const items = [...state.orderData.items];
      items[index] = {
        ...items[index],
        checked
      };

      let updatedItems = checkedAllElements(
        items[index],
        items,
        'checked',
        checked
      );

      if (!checked) {
        updatedItems = checkedAllElements(
          updatedItems[index],
          updatedItems,
          'selected',
          updatedItems[index].quantity
        );
      }

      return {
        ...state,
        orderData: {...state.orderData, items: updatedItems}
      };
    },
    [RC_QUANTITY_CHANGE]: (state, {index, value}) => {
      const items = [...state.orderData.items];
      items[index] = {
        ...items[index],
        selected: value
      };
      const updatedItems = checkedAllElements(
        items[index],
        items,
        'selected',
        value
      );

      return {
        ...state,
        orderData: {...state.orderData, items: updatedItems}
      };
    },
    [RC_FEES_AND_TAXES_CHECK]: (state, payload) => {
      const changedCheckboxes = {...state.partialRefCheckboxes};
      changedCheckboxes[payload] = !changedCheckboxes[payload];

      return {
        ...state,
        partialRefCheckboxes: changedCheckboxes
      };
    },
    [RC_LOCATION_FAULT_SELECT]: (state, payload) => {
      return {
        ...state,
        refundResponsibility: payload,
        locationFault: payload === 0 ? 'yes' : 'no'
      };
    },
    [RC_SET_SUBTOTAL_PERCENT]: (state, payload) => {
      return {
        ...state,
        percent: payload
      };
    },
    [RC_GENERATE_ORDER_REFUND]: (state) => {
      return {
        ...state,
        resultLoading: true
      };
    },
    [RC_GENERATE_ORDER_REFUND_SUCCESS]: (state, payload) => {
      return {
        ...state,
        endCustomerRefund: payload?.totalCustomerRefundAmount,
        restaurantReverse: payload?.totalLocationReverseAmount,
        resultLoading: false
      };
    },
    [RC_CLEAR_CALCULATOR_DATA]: (state) => {
      const clearedItems = [...state.orderData?.items].map((item) => {
        return {
          ...item,
          checked: false,
          selected: item.quantity
        };
      });

      return {
        ...state,
        endCustomerRefund: 0,
        restaurantReverse: 0,
        percent: 0,
        orderData: {...state.orderData, items: clearedItems},
        partialRefCheckboxes: {
          tip: false,
          deliveryFee: false
        },
        priceAdjustmentIds: []
      };
    },
    [RC_CLEAR_ALL_CALCULATOR_DATA]: () => {
      return {
        ...initialState()
      };
    },
    [RC_OPEN_CONFIRMATION_MODAL]: (state, payload) => {
      return {
        ...state,
        openConfirmationModal: payload
      };
    },
    [RC_OPEN_RESULT_MODAL]: (state, payload) => {
      return {
        ...state,
        resultModal: payload.isOpen,
        resultModalInfo: payload.info
      };
    },
    [RC_SET_LOADING]: (state, payload) => {
      return {
        ...state,
        loading: payload
      };
    },
    [RC_SET_RESULT_LOADING]: (state, payload) => {
      return {
        ...state,
        resultLoading: payload
      };
    },
    [RC_ADJUSTMENTS_CHECK]: (state, payload) => {
      let result = state.priceAdjustmentIds;
      if (result.includes(payload)) {
        result = result.filter((el) => el !== payload);
      } else {
        result.push(payload);
      }
      return {
        ...state,
        priceAdjustmentIds: result
      };
    }
  },
  initialState()
);

export default refundsCalculatorReducer;
