import {createAction} from 'redux-act';

export const SET_WEBSITE_TEMPLATE = createAction(
  'account/SET_WEBSITE_TEMPLATE'
);
export const SET_WEBSITE_TEMPLATE_SUCCESS = createAction(
  'account/SET_WEBSITE_TEMPLATE_SUCCESS'
);
export const TOGGLE_CALENDLY = createAction('account/TOGGLE_CALENDLY');
export const CONFIRM_EMAIL = createAction('account/CONFIFRM_EMAIL');
export const RESEND_CONFIRMATION = createAction('account/RESEND_CONFIRMATION');
export const CONFIRM_EMAIL_SUCCESS = createAction(
  'account/CONFIRM_EMAIL_SUCCESS'
);
export const CONFIRM_EMAIL_ERROR = createAction('account/CONFIRM_EMAIL_ERROR');
export const CONFIRM_EMAIL_MODAL = createAction('account/CONFIRM_EMAIL_MODAL');
export const CONNECT_GOOGLE_MY_BUSINESS = createAction(
  'account/CONNECT_GOOGLE_MY_BUSINESS'
);
export const CONNECT_GOOGLE_MY_BUSINESS_REDIRECT = createAction(
  'account/CONNECT_GOOGLE_MY_BUSINESS_REDIRECT'
);
export const GET_ACCOUNT_STEPS_STATUS = createAction(
  'account/GET_ACCOUNT_STEPS_STATUS'
);
export const GET_ACCOUNT_STEPS_STATUS_SUCCESS = createAction(
  'account/GET_ACCOUNT_STEPS_STATUS_SUCCESS'
);

export const CONNECT_STRIPE_ACCOUNT = createAction(
  'account/CONNECT_STRIPE_ACCOUNT'
);

export const CONNECT_STRIPE_ACCOUNT_SUCCESS = createAction(
  'account/CONNECT_STRIPE_ACCOUNT_SUCCESS'
);

export const BOOK_A_CALL = createAction('account/BOOK_A_CALL');

export const BOOK_A_CALL_SUCCESS = createAction('account/BOOK_A_CALL_SUCCESS');

export const BOOK_A_CALL_FAILED = createAction('account/BOOK_A_CALL_FAILED');

export const SHOW_STEPPER_AFTER_CALENDLY = createAction(
  'account/SHOW_STEPPER_AFTER_CALENDLY'
);

export const PUT_LOCATION_SETUP_DATA = createAction(
  'account/PUT_LOCATION_SETUP_DATA'
);
