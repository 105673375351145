/* eslint-disable */
import styled from 'styled-components';

export const DialogBody = styled.div`
  margin-top: 15px;

  @media (max-width: 1440px) {
    margin-top: 5px;
  }
`;
