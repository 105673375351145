import React, {memo} from 'react';
import ReactPaginate from 'react-paginate';
import {useDispatch, useSelector} from 'react-redux';
import {TablePagination, TablePaginationText} from '../../dashboard/styles';
import {PAGE_CHANGE} from '../../../../store/adminUsers/actions';
import {adminUsersData} from '../../../../store/adminUsers/selectors';
import {SEARCH_CHANGE} from '../../../../store/merchants/action';

const UsersTablePagination = () => {
  const dispatch = useDispatch();
  const {
    paging: {total, limit, pages, search, page}
  } = useSelector(adminUsersData);

  const onPageChange = (newPage) => {
    dispatch(PAGE_CHANGE(newPage.selected));
    dispatch(SEARCH_CHANGE(search));
  };

  return (
    <TablePagination>
      <TablePaginationText>
        Showing {limit > total ? total : limit} out of {total}
      </TablePaginationText>
      <ReactPaginate
        previousLabel='previous'
        nextLabel='next'
        breakLabel='...'
        breakClassName='break-me'
        pageCount={pages}
        initialPage={page}
        forcePage={page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName='pagination'
        subContainerClassName='pages pagination'
        activeClassName='active'
      />
    </TablePagination>
  );
};

export default memo(UsersTablePagination);
