import styled from 'styled-components';
import IconSort from '../../../../../assets/icon-sort.svg';
import body1 from '../../../../../components/layout/body1/styles';

export const TableSort = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: #232d3a;
  display: inline-block;
  padding-right: 20px;
  position: relative;
  font-family: 'Poppins';
  @media (max-width: 1700px) {
    font-size: 14px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 11px;
    height: 15px;
    background-image: url('${(props) => props.icon || IconSort}');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      width: 10px;
      height: 14px;
    }
  }
`;

export const TableText = styled(body1)`
  display: inline-block;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const UserName = styled(body1)`
  display: block;
  padding-top: 5px;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const TableIcon = styled.div`
  min-height: 32px;
  margin-bottom: 9px;
`;

export const TableImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
`;

export const QueueTableWrapper = styled.div`
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #232d3a;
    padding-right: 20px;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;
    @media (max-width: 1700px) {
      font-size: 14px;
    }
  }
  .MuiTable-root {
    @media (max-width: 1200px) {
      min-width: 1100px;
    }
    tr {
      &:first-child {
        th {
          padding-top: 0;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 15%;
          }
          &:nth-child(5) {
            width: 15%;
          }
          &:nth-child(6) {
            width: 15%;
          }
        }
      }
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;
