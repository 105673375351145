import {createAction} from 'redux-act';

export const GET_ORDERS_RTB = createAction('realTimeBoard/GET_ORDERS_RTB');

export const CLEAR_ORDERS_RTB = createAction('realTimeBoard/CLEAR_ORDERS_RTB');

export const GET_ORDERS_RTB_SUCCESS = createAction(
  'realTimeBoard/GET_ORDERS_RTB_SUCCESS'
);

export const RUN_ORDER_RTB_JOB = createAction(
  'realTimeBoard/RUN_ORDER_RTB_JOB'
);
export const STOP_ORDER_RTB_JOB = createAction(
  'realTimeBoard/STOP_ORDER_RTB_JOB'
);

export const SET_SORTING = createAction('realTimeBoard/SET_SORTING');

export const GET_ORDER_ACTIONS = createAction(
  'realTimeBoard/GET_ORDER_ACTIONS'
);

export const GET_ORDER_ACTIONS_SUCCESS = createAction(
  'realTimeBoard/GET_ORDER_ACTIONS_SUCCESS'
);

export const SEND_ORDER_ACTIONS = createAction(
  'realTimeBoard/SEND_ORDER_ACTIONS'
);

export const CLEAR_ORDER_ACTIONS = createAction(
  'realTimeBoard/CLEAR_ORDER_ACTIONS'
);

export const OPEN_ACTIONS_MADE_MODAL = createAction(
  'realTimeBoard/OPEN_ACTIONS_MADE_MODAL'
);

export const OPEN_ADDITION_INFO_MODAL = createAction(
  'realTimeBoard/OPEN_ADDITION_INFO_MODAL'
);

export const CLEAR_ORDER_ADDITION_INFO = createAction(
  'realTimeBoard/CLEAR_ORDER_ADDITION_INFO'
);

export const PIN_ORDER = createAction('realTimeBoard/PIN_ORDER');

export const UNPIN_ORDER = createAction('realTimeBoard/UNPIN_ORDER');

export const OPEN_OWNED_MODAL = createAction('realTimeBoard/OPEN_OWNED_MODAL');

export const RDB_REMOVE_ORDER_MODAL = createAction(
  'realTimeBoard/RDB_REMOVE_ORDER_MODAL'
);
