/*eslint-disable*/
import React, {useRef, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../../components/FieldInput/style';
import {initLocationAfterMapPick} from './utils';
import {GET_CALLING_PHONE} from '../../../../store/locationSettings/action';
import {useDebouncedCallback} from 'use-debounce';

const optionTypes = {
  Address: 'address',
  FullAddress: 'address',
  'merchant.Locations[0].Address': 'address'
};

const AutocompletedMapField = (props) => {
  AutocompletedMapField.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    formReference: PropTypes.string,
    structuredAddress: PropTypes.object,
    isAdminPage: PropTypes.string
  };

  const input = useRef(null);
  // const callingCode = useSelector(
  //   (state) => state.locationSettingsReducer.callingCode
  // );
  const dispatch = useDispatch();

  const getCallingCode = (code) => {
    dispatch(GET_CALLING_PHONE({countryCode: code}));
  };

  // useEffect(() => { useless while countyCode only for America
  //   if (callingCode) {
  //     setFieldValue('CountryCode', `+${callingCode}`);
  //   }
  // }, [callingCode]);

  const {
    value,
    onChange,
    setFieldValue,
    name,
    map,
    mapMarker,
    className,
    setCountryState,
    countryList,
    placeholder,
    formReference,
    fieldToClear,
    onBlur,
    structuredAddress,
    isAdminPage
  } = props;

  useEffect(() => {
    const options = {
      types: [`${optionTypes[name]}`]
    };
    let searchBox;
    let script;

    if (google) {
      searchBox = new google.maps.places.Autocomplete(input.current, options);
      google.maps.event.addListener(searchBox, 'place_changed', async () => {
        await initLocationAfterMapPick(
          searchBox.getPlace(),
          setFieldValue,
          map,
          mapMarker,
          getCallingCode,
          setCountryState,
          countryList,
          input.current.value,
          structuredAddress,
          isAdminPage
        );
      });
    } else {
      script = document.createElement('script');
      script.src =
        'https://maps.google.com/maps/api/js?libraries=places&language=en'; // Call google maps api script if it has not been called when app loads
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (script) document.body.removeChild(script);
      google && google.maps.event.clearInstanceListeners(searchBox);
    };
  }, [map]);

  const clearFieldOnTyping = useDebouncedCallback((field, value) => {
    setFieldValue(field, value);
  }, 500);

  return (
    <Input
      ref={input}
      disabled={false}
      className={[className, 'fff'].map((classname) => classname)}
      type='text'
      value={value}
      name={name || 'Address'}
      onChange={onChange}
      onBlur={onBlur}
      onKeyUp={(e) => {
        if (e.keyCode === 13 && onBlur) {
          onBlur();
        } else {
          clearFieldOnTyping.callback(fieldToClear, '');
        }
      }}
      {...(formReference && {form: formReference})}
      placeholder={placeholder || 'Address'}
      autoCorrect='nope'
      autoComplete='off'
      spellCheck='false'
    />
  );
};

export default AutocompletedMapField;
