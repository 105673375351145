import React, {useEffect, useState} from 'react';
import {checkNetworkConnection} from '../helpers/functionUtils/checkNetworkConnection';

const useNetwork = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    checkNetworkConnection(
      () => setNetwork(true),
      () => setNetwork(false)
    );
  };

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    const interval = setInterval(() => {
      updateNetwork();
    }, 10000);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
      clearInterval(interval);
    };
  });
  return [isOnline, updateNetwork];
};

export default useNetwork;
