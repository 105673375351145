import styled from 'styled-components';

const DashboardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.w60Plus {
    width: calc(60% + 190px);
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    display: block;

    & > * {
      &:nth-child(2) {
        margin-top: 5px;
      }
    }
  }
`;

export default DashboardTitle;
