import {put, select, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  //  changeUberStatusApi,
  connectUberEatsStoreApi,
  getUberEatsMerchantStatusApi,
  getUberEatsStoreApi,
  getUberEatsStoresApi,
  sendSlackNotificationBYOCApi,
  sendSlackNotificationUEDisconnect
} from '../../helpers/services/api/uberEats';
import {
  CHANGE_UBER_STATUS_SUCCESS,
  DISCONNECT_UBER_EATS,
  HANDLE_DISCONNECT_MODAL,
  CONNECT_UBEREATS_STORE,
  GET_UBEREATS_DATA,
  GET_UBEREATS_DATA_SUCCESS,
  GET_UBEREATS_STORES_SUCCESS,
  HANDLE_CONNECT_STORE_MODAL,
  HANDLE_NOT_STORE_MODAL,
  SEND_SLACK_NOTIF,
  SET_UBEREATS_ACCOUNT,
  SET_UBEREATS_STORE_ID
} from './actions';
import history from '../../history';

function* setupActiveStoresToState(stores) {
  const storesSelect = [];
  if (stores.data && stores.data.length) {
    stores.data.forEach((store) => {
      if (!store.activationSettings) {
        const item = {
          id: store?.id,
          name: store?.details?.name,
          status: store?.onlineStatus?.status
        };
        storesSelect.push(item);
      }
    });
  }
  yield put(GET_UBEREATS_STORES_SUCCESS(storesSelect));
  return storesSelect;
}

function* getUberEatsData({payload}) {
  try {
    const {merchantId, locationId} = payload;
    const isMerchantConnected = yield getUberEatsMerchantStatusApi(merchantId);

    if (isMerchantConnected.status === 200) {
      yield put(SET_UBEREATS_ACCOUNT(true));
      yield put(SET_UBEREATS_STORE_ID(''));
      const stores = yield getUberEatsStoresApi(merchantId);
      const storesSelect = yield setupActiveStoresToState(stores);

      const locationStoreId = stores?.data?.find(
        (store) => store?.activationSettings?.locationId === locationId
      )?.id;

      if (locationStoreId) {
        const store = yield getUberEatsStoreApi(locationStoreId);
        if (store?.data) {
          yield put(GET_UBEREATS_DATA_SUCCESS(store.data));
          yield put(SET_UBEREATS_STORE_ID(locationStoreId));
        }
      } else {
        yield;
        yield put(GET_UBEREATS_DATA_SUCCESS(null));
      }
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* connectUberStore({payload}) {
  try {
    const {postCallback = () => {}, requestBody, uberEatsStoreId} = payload;

    const merchantId = yield select(
      (state) => state.user.userData?.merchant_id
    );

    const stores = yield getUberEatsStoresApi(merchantId);
    if (stores) {
      yield setupActiveStoresToState(stores);
    }

    // TODO: CURRENTLY WE ARE CHOOSING THE FIRST STORE THAT MATCHES, WE WILL HAVE MULTIPLE STORES AND LOCATIONS IN THE FUTURE
    const stateUberEatsStoreId = yield select(
      (state) => state.uberEatsReducer.uberEatsStoreId
    );

    const res = yield connectUberEatsStoreApi(
      uberEatsStoreId || stateUberEatsStoreId,
      requestBody
    );

    if (res.status === 200) {
      yield put(CHANGE_UBER_STATUS_SUCCESS(res.data));
      yield put(HANDLE_CONNECT_STORE_MODAL(false));
      history.push('/uber-eats');
      postCallback();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}
function* disconnectUberEats({payload}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const res = yield sendSlackNotificationUEDisconnect({
      ...payload,
      locationId
    });
    if (res.status === 200) {
      yield put(HANDLE_DISCONNECT_MODAL(3));
    }
  } catch (error) {
    toast.error('Something went wrong! Please try again later!');
    yield put(HANDLE_DISCONNECT_MODAL(0));
    console.log('SAGA ERROR', error);
  }
}

// function* changeUberStatus({payload}) {
//  try {
//    const locationId = yield select((state) => state.user.locationId);
//
//    const res = yield changeUberStatusApi(locationId, payload);
//
//    if (res.status === 200) {
//      yield put(CHANGE_UBER_STATUS_SUCCESS(payload?.IsActive));
//      toast.success(
//        // res?.data?.response ||
//        'Your UberEats account has been updated successfully!'
//      );
//    }
//  } catch (error) {
//    toast.error('Something went wrong! Please try again later!');
//    console.log('SAGA ERROR', error);
//  }
// }

function* sendSlackNotification({payload}) {
  try {
    const user = yield select((state) => state.user);
    const slackMessageBody = {
      availabilityBridges: payload,
      restaurant: user.activeLocation.FullName,
      first_name: user.userData.first_name,
      last_name: user.userData.last_name,
      phone: user.activeLocation.Phone
    };
    yield sendSlackNotificationBYOCApi(slackMessageBody);
  } catch (error) {
    toast.error('Something went wrong! Please try again later!');
    console.log('SAGA ERROR', error);
  }
}

function* uberEatsSaga() {
  yield takeEvery(DISCONNECT_UBER_EATS, disconnectUberEats);
  yield takeEvery(GET_UBEREATS_DATA, getUberEatsData);
  //  yield takeEvery(CHANGE_UBER_STATUS, changeUberStatus);
  yield takeEvery(SEND_SLACK_NOTIF, sendSlackNotification);
  yield takeEvery(CONNECT_UBEREATS_STORE, connectUberStore);
}

export default uberEatsSaga;
