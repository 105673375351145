import {createAction} from 'redux-act';

export const GET_ORDER = createAction('orderDetails/GET_ORDER');
export const ORDER_UNMOUNT = createAction('orderDetails/ORDER_UNMOUNT');
export const GET_ORDER_SUCCESS = createAction('orderDetails/GET_ORDER_SUCCESS');
export const GET_DISPATCH_INFO = createAction('orderDetails/GET_DISPATCH_INFO');
export const GET_DISPATCH_INFO_SUCCESS = createAction(
  'orderDetails/GET_DISPATCH_INFO_SUCCESS'
);
export const CHANGE_STATUS = createAction('orderDetails/CHANGE_STATUS');
export const CHANGE_STATUS_SUCCESS = createAction(
  'orderDetails/CHANGE_STATUS_SUCCESS'
);
export const GET_DELIVERY_STATUS = createAction(
  'orderDetails/GET_DELIVERY_STATUS'
);
export const GET_DELIVERY_STATUS_SUCCESS = createAction(
  'orderDetails/GET_DELIVERY_STATUS_SUCCESS'
);
export const CANCEL_DELIVERY_ORDER = createAction(
  'orderDetails/CANCEL_DELIVERY_ORDER'
);
export const OPEN_CANCEL_DELIVERY_ORDER_MODAL = createAction(
  'orderDetails/OPEN_CANCEL_DELIVERY_ORDER_MODAL'
);
export const SET_LOADING_CANCELLATION = createAction(
  'orderDetails/SET_LOADING_CANCELLATION'
);
export const OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL = createAction(
  'orderDetails/OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL'
);
