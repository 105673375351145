import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

const DaysPickerContext = createContext();
const DaysPickerContextUpdate = createContext();

export const useDaysPickerContext = () => {
  return useContext(DaysPickerContext);
};

export const useDaysPickerContextUpdate = () => {
  return useContext(DaysPickerContextUpdate);
};
export const DaysPickerContextProvider = ({children}) => {
  DaysPickerContextProvider.propTypes = {
    children: PropTypes.objectOf(PropTypes.any)
  };

  DaysPickerContextProvider.defaultProps = {
    children: {}
  };

  const [openingDays, setOpeningDays] = useState([]);

  const updateOpeningDays = (arr) => {
    setOpeningDays(arr);
  };

  return (
    <DaysPickerContext.Provider value={openingDays}>
      <DaysPickerContextUpdate.Provider value={updateOpeningDays}>
        {children}
      </DaysPickerContextUpdate.Provider>
    </DaysPickerContext.Provider>
  );
};
