import {POST, PATCH} from '../constants/API_CONSTANTS';
import {
  LOCATION_VN_STATUS,
  API_CREATE_VN,
  API_UPDATE_VN,
  API_ACTIVATE_VN,
  API_CREATE_TWILIO,
  API_DISABLE_VN
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getLocationVNStatus = async (locationId) => {
  return request(POST, LOCATION_VN_STATUS(locationId));
};

export const createVirtualNumber = async (locationId, data) => {
  return request(POST, API_CREATE_VN(locationId), {data});
};

export const patchVirtualNumber = async (locationId, data) => {
  return request(PATCH, API_UPDATE_VN(locationId), {data});
};

export const createTwilio = async (locationId, data) => {
  return request(POST, API_CREATE_TWILIO(locationId), {data});
};

export const activateVirtualNumber = async (locationId) => {
  return request(POST, API_ACTIVATE_VN(locationId));
};

export const disableVirtualNumber = async (locationId) => {
  return request(POST, API_DISABLE_VN(locationId));
};
