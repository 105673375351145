import React from 'react';

const UploadButton = () => {
  return (
    <svg
      width='37'
      height='34'
      viewBox='0 0 37 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.5 32C6.95304 32 0 25.047 0 16.5C0 7.95304 6.95304 1 15.5 1C24.047 1 31 7.95304 31 16.5C31 25.047 24.047 32 15.5 32ZM15.5 2.29167C7.66604 2.29167 1.29167 8.66604 1.29167 16.5C1.29167 24.334 7.66604 30.7083 15.5 30.7083C23.334 30.7083 29.7083 24.334 29.7083 16.5C29.7083 8.66604 23.334 2.29167 15.5 2.29167Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        d='M22.6042 24.25H8.39583C8.03933 24.25 7.75 23.9607 7.75 23.6042C7.75 23.2477 8.03933 22.9583 8.39583 22.9583H22.6042C22.9607 22.9583 23.25 23.2477 23.25 23.6042C23.25 23.9607 22.9607 24.25 22.6042 24.25Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        d='M15.4998 21.6667C15.1433 21.6667 14.854 21.3773 14.854 21.0208V8.42708C14.854 8.07058 15.1433 7.78125 15.4998 7.78125C15.8563 7.78125 16.1457 8.07058 16.1457 8.42708V21.0208C16.1457 21.3773 15.8563 21.6667 15.4998 21.6667Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        d='M20.021 13.2708C19.8557 13.2708 19.6903 13.2075 19.5638 13.0822L15.5002 9.01736L11.4366 13.0822C11.1847 13.3341 10.7753 13.3341 10.5234 13.0822C10.2715 12.8304 10.2715 12.4209 10.5234 12.169L15.0442 7.6482C15.2961 7.39632 15.7055 7.39632 15.9574 7.6482L20.4783 12.169C20.7301 12.4209 20.7301 12.8304 20.4783 13.0822C20.3517 13.2075 20.1863 13.2708 20.021 13.2708Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
    </svg>
  );
};

export default UploadButton;
