import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog} from '@material-ui/core';
import {Form, Formik, useFormik} from 'formik';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import {
  DashboardField,
  DialogAction,
  DialogBody
} from '../../../dashboard/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import DialogField from '../../../../../components/DialogField/styles';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import DialogFields from '../../../../../components/DialogFields/styles';
import {
  CHANGE_NEW_MERCHANT,
  GET_UNASSIGNED_MENU_QUEUE
} from '../../../../../store/merchants/action';
import {DialogSubTitle} from '../../../../../components/DialogSubTitle/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import {Body1} from '../../../../../components/typography';
import AutocompletedMapField from '../../../settings/LocationMap/AutocompletedMapField';
import ButtonClear from '../../../../../components/buttons/ButtonClear/styles';
import EditNewMerchantSchema from '../../../../../helpers/services/formValidationSchemas/EditNewMerchantSchema';

const getMenuLinkFieldPath = (values) => {
  const {setupData} = values.merchant || {};

  if (setupData?.MenuShowUrlInput) {
    return 'merchant.setupData.MenuUseUrl';
  }

  return '';
};

const getImagesLinkFieldPath = (values) => {
  const {setupData} = values.merchant || {};

  if (setupData?.ImagesShowUrlInput) {
    return 'merchant.setupData.ImagesUseUrl';
  }
  return '';
};

const getMenuLinkValue = (values) => {
  return values.merchant?.setupData?.MenuShowUrlInput
    ? values.merchant?.setupData?.MenuUseUrl
    : '';
};

const getImagesLinkData = (values) => {
  return values.merchant?.setupData?.ImagesShowUrlInput
    ? values.merchant?.setupData?.ImagesUseUrl
    : '';
};

const getS2ELink = (values, touched, selectedRecord) => {
  const newLink = values.merchant?.Locations[0]?.GeneratedLink?.url;
  const oldRestaurantURL = selectedRecord.merchant.RestaurantURL;
  const oldLink = selectedRecord.merchant.Locations[0].GeneratedLink?.url;

  if (!touched && oldRestaurantURL === oldLink) {
    return '';
  }
  return newLink;
};

const BaseField = ({formik, name, placeholder, dialogFieldClass, type}) => (
  <DialogField className={dialogFieldClass}>
    <FieldLabel>{placeholder}</FieldLabel>
    <FieldInput
      type={type}
      value={_.get(formik.values, name)}
      onChange={formik.handleChange}
      name={name}
      placeholder={placeholder}
      className={cn({
        error: _.get(formik.errors, name) && _.get(formik.touched, name)
      })}
    />
    <ErrorSignWrapper>
      {_.get(formik.errors, name) && _.get(formik.touched, name) ? (
        <ErrorSign>{_.get(formik.errors, name)}</ErrorSign>
      ) : null}
    </ErrorSignWrapper>
  </DialogField>
);

BaseField.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dialogFieldClass: PropTypes.string,
  type: PropTypes.string
};

BaseField.defaultProps = {dialogFieldClass: 'w100', type: 'text'};

export default function EditMerchant({onClose}) {
  const {selectedRecord, webConfig, menuConfig} = useSelector(
    (state) => state.merchantsReducer
  );

  const formikRef = useRef();

  const [S2ELinkTouched, setS2ELinkTouched] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    onSubmit: () => {
      dispatch(
        CHANGE_NEW_MERCHANT({
          id: selectedRecord.id,
          formValues: formik.values,
          successCb: onClose
        })
      );
    },
    initialValues: selectedRecord,
    enableReinitialize: true,
    innerRef: formikRef
  });

  useEffect(() => {
    dispatch(GET_UNASSIGNED_MENU_QUEUE());
  }, []);

  const unionWebConfigList = !selectedRecord.merchant?.Locations[0]
    ?.WixWebOrderingConfigId
    ? webConfig
    : [
        {wix_id: selectedRecord.merchant?.Locations[0]?.WixWebOrderingConfigId},
        ...webConfig
      ];
  const unionMenuConfigList = !selectedRecord.merchant?.Locations[0]
    ?.WixMenuConfigId
    ? menuConfig
    : [
        {wix_id: selectedRecord.merchant?.Locations[0]?.WixMenuConfigId},
        ...menuConfig
      ];
  const menuConfigId = selectedRecord.merchant?.Locations[0]?.MenuConfigId;
  const isNotWixMenuConfig =
    menuConfigId &&
    menuConfigId.toString() !==
      selectedRecord.merchant?.Locations[0]?.WixMenuName;

  if (isNotWixMenuConfig) {
    unionMenuConfigList.push({
      wix_id: menuConfigId
    });
  }

  return (
    <Dialog open scroll='body' onClose={onClose}>
      <Formik
        initialValues={selectedRecord}
        onSubmit={(values) => {
          dispatch(
            CHANGE_NEW_MERCHANT({
              id: selectedRecord.id,
              formValues: values,
              successCb: onClose
            })
          );
        }}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={EditNewMerchantSchema}
      >
        {({
          values,
          setFieldValue,
          errors,
          handleChange,
          handleSubmit,
          touched
        }) => (
          <Form>
            <DialogContainer className='DialogMd'>
              <DialogClose onClick={onClose} />
              <DialogTitle className='marginBottomXl'>
                Editing {values.merchant?.MerchantName}
              </DialogTitle>
              <DialogBody>
                <DialogFields className='mBottom40'>
                  <BaseField
                    formik={formik}
                    name='merchant.MerchantName'
                    placeholder='Merchant Name'
                  />
                </DialogFields>
                <DialogSubTitle>Owner Info</DialogSubTitle>
                <DialogFields className='mBottom40'>
                  <BaseField
                    formik={formik}
                    name='first_name'
                    placeholder='First Name'
                    dialogFieldClass='w50'
                  />
                  <BaseField
                    formik={formik}
                    name='last_name'
                    placeholder='Last Name'
                    dialogFieldClass='w50'
                  />
                  <BaseField
                    formik={formik}
                    name='email'
                    placeholder='Email'
                    dialogFieldClass='w50'
                  />
                </DialogFields>
                {values.merchant?.Locations?.[0] && (
                  <>
                    <DialogSubTitle>Location Info</DialogSubTitle>
                    <DialogFields>
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].FullName'
                        placeholder='Full Name'
                      />
                      {/* <BaseField
                  formik={formik}
                  name='merchant.Locations[0].Address'
                  placeholder='Address'
                /> */}
                      <DashboardField className='w100'>
                        <FieldLabel>Address</FieldLabel>
                        <AutocompletedMapField
                          type='text'
                          value={values?.merchant?.Locations[0]?.Address}
                          onChange={handleChange}
                          name='merchant.Locations[0].Address'
                          fieldToClear='FullAddress'
                          placeholder='Address'
                          setFieldValue={setFieldValue}
                          className={cn('AutoComplete', {
                            error:
                              errors?.merchant?.Locations[0]?.Address ||
                              errors?.merchant?.Locations[0]?.StreetNumber
                          })}
                          structuredAddress={
                            values?.merchant?.Locations[0]?.StructuredAddress
                          }
                          isAdminPage='true'
                        />
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {errors?.merchant?.Locations[0]?.Address
                              ? errors?.merchant?.Locations[0]?.Address
                              : errors?.merchant?.Locations[0]?.StreetNumber &&
                                errors?.merchant?.Locations[0]?.StreetNumber}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </DashboardField>

                      <DialogField className='w50 Say2EatSelect'>
                        <FieldLabel>Web Config</FieldLabel>
                        <FormControl>
                          <Select
                            inputProps={{'aria-label': 'value'}}
                            onChange={handleChange}
                            value={
                              values.merchant?.Locations[0]
                                ?.WixWebOrderingConfigId
                            }
                            name='merchant.Locations[0].WixWebOrderingConfigId'
                          >
                            {unionWebConfigList.map((item) => (
                              <MenuItem value={item.wix_id}>
                                {item.wix_id}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </DialogField>
                      <DialogField className='w50 Say2EatSelect'>
                        <FieldLabel>Menu Config</FieldLabel>
                        <FormControl>
                          <Select
                            inputProps={{'aria-label': 'value'}}
                            onChange={handleChange}
                            disabled={isNotWixMenuConfig}
                            value={
                              isNotWixMenuConfig
                                ? menuConfigId
                                : values.merchant?.Locations[0]?.WixMenuConfigId
                            }
                            name='merchant.Locations[0].WixMenuConfigId'
                          >
                            {unionMenuConfigList.map((item) => (
                              <MenuItem value={item.wix_id}>
                                {item.wix_id}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </DialogField>
                      <BaseField
                        formik={formik}
                        name='merchant.RestaurantURL'
                        placeholder='Origin Restaurant Link'
                      />
                      <BaseField
                        formik={formik}
                        name={
                          values.merchant.Locations[0].GeneratedLink.mask
                            ? 'merchant.Locations[0].GeneratedLink.mask'
                            : 'merchant.Locations[0].GeneratedLink.short'
                        }
                        placeholder='Short Link'
                      />
                      <DialogField className='w100'>
                        <FieldLabel>S2E Link</FieldLabel>
                        <FieldInput
                          type='text'
                          value={getS2ELink(
                            values,
                            S2ELinkTouched,
                            selectedRecord
                          )}
                          onChange={(e) => {
                            setS2ELinkTouched(true);
                            handleChange(e);
                          }}
                          name='merchant.Locations[0].GeneratedLink.url'
                          placeholder='Full Link'
                          className={cn({
                            error:
                              errors.merchant?.Locations[0]?.GeneratedLink
                                ?.url &&
                              touched.merchant?.Locations[0]?.GeneratedLink?.url
                          })}
                        />
                        <ErrorSignWrapper>
                          {errors.merchant?.Locations[0]?.GeneratedLink?.url &&
                          touched.merchant?.Locations[0]?.GeneratedLink?.url ? (
                            <ErrorSign>
                              {
                                errors.merchant?.Locations[0]?.GeneratedLink
                                  ?.url
                              }
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DialogField>
                    </DialogFields>
                    <DialogSubTitle>Stripe SetUp</DialogSubTitle>
                    <DialogFields>
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeAccountKeyPostfix'
                        placeholder='Account Key Postfix'
                        dialogFieldClass='w50'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeAccountType'
                        placeholder='Account Type'
                        dialogFieldClass='w50'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripePublishKeyTest'
                        placeholder='Publish Key Test'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripePublishKeyLive'
                        placeholder='Publish Key Live'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeTransactionFeePercent'
                        placeholder='Transaction Fee Percent'
                        dialogFieldClass='w50'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeTransactionFeeAmount'
                        placeholder='Transaction Fee Amount'
                        dialogFieldClass='w50'
                      />
                      <DialogField className='w50 Say2EatSelect'>
                        <FieldLabel>Stripe Test Mode</FieldLabel>
                        <FormControl>
                          <Select
                            inputProps={{'aria-label': 'value'}}
                            onChange={handleChange}
                            value={
                              values.merchant?.Locations[0]?.StripeTestMode
                            }
                            name='merchant.Locations[0].StripeTestMode'
                          >
                            <MenuItem value>Enabled</MenuItem>
                            <MenuItem value={false}>Disabled</MenuItem>
                          </Select>
                        </FormControl>
                      </DialogField>
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeIcon'
                        placeholder='Icon'
                        dialogFieldClass='w50'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeManagedAccountLive'
                        placeholder='Managed Account Live'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeManagedAccountTest'
                        placeholder='Managed Account Test'
                      />
                      <BaseField
                        formik={formik}
                        name='merchant.Locations[0].StripeRecipeDescription'
                        placeholder='Recipe Description'
                      />
                    </DialogFields>
                  </>
                )}
                <DialogSubTitle>Ops Info</DialogSubTitle>
                <DialogFields>
                  <DialogField className='w50'>
                    <FieldLabel>Menu Location</FieldLabel>
                    <FieldInput
                      disabled={!values.merchant?.setupData?.MenuShowUrlInput}
                      type='text'
                      value={getMenuLinkValue(values)}
                      onChange={handleChange}
                      name={getMenuLinkFieldPath(values)}
                      placeholder='Menu Location'
                      className={cn({
                        error:
                          getMenuLinkValue(errors) && getMenuLinkValue(touched)
                      })}
                    />
                    <ErrorSignWrapper>
                      {getMenuLinkValue(errors) && getMenuLinkValue(touched) ? (
                        <ErrorSign>{getMenuLinkValue(errors)}</ErrorSign>
                      ) : null}
                    </ErrorSignWrapper>
                  </DialogField>
                  <DialogField className='w50'>
                    <FieldLabel>Images Location</FieldLabel>
                    <FieldInput
                      disabled={!values.merchant?.setupData?.ImagesShowUrlInput}
                      type='text'
                      value={getImagesLinkData(values)}
                      onChange={handleChange}
                      name={getImagesLinkFieldPath(values)}
                      placeholder='Merchant Name'
                      className={cn({
                        error:
                          getImagesLinkData(errors) &&
                          getImagesLinkData(touched)
                      })}
                    />
                    <ErrorSignWrapper>
                      {getImagesLinkData(errors) &&
                      getImagesLinkData(touched) ? (
                        <ErrorSign>{getImagesLinkData(errors)}</ErrorSign>
                      ) : null}
                    </ErrorSignWrapper>
                  </DialogField>
                  <BaseField
                    formik={formik}
                    name='merchant.Locations[0].setupData.ItTeamName'
                    placeholder='IT Contact'
                    dialogFieldClass='w50'
                  />
                  <BaseField
                    formik={formik}
                    name='merchant.Locations[0].setupData.ItTeamEmail'
                    placeholder='IT Email'
                    dialogFieldClass='w50'
                  />
                  <BaseField
                    formik={formik}
                    name='merchant.Locations[0].setupData.HostLoginLink'
                    placeholder='Host Location'
                  />
                  <BaseField
                    formik={formik}
                    name='merchant.Locations[0].setupData.HostUserName'
                    placeholder='Host User'
                    dialogFieldClass='w50'
                  />
                  <BaseField
                    formik={formik}
                    name='merchant.Locations[0].setupData.HostPassword'
                    placeholder='Host Password'
                    dialogFieldClass='w50'
                  />
                </DialogFields>
                {values?.merchant?.Locations[0]?.CopyMenuUrl && (
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                    className='marginTopXs marginBottomXs'
                  >
                    <FlexItem>
                      <Body1>Click to download user&apos;s uploaded menu</Body1>
                    </FlexItem>
                    <FlexItem>
                      <ButtonPrimary
                        className='ButtonMd'
                        onClick={() =>
                          window.open(
                            values?.merchant?.Locations[0]?.CopyMenuUrl
                          )
                        }
                      >
                        <span>Download</span>
                      </ButtonPrimary>
                    </FlexItem>
                  </FlexContainer>
                )}
                {values?.merchant?.Locations[0]?.CopyImagesUrl && (
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                    className='marginTopXs marginBottomXs'
                  >
                    <FlexItem>
                      <Body1>
                        Click to download user&apos;s uploaded menu images
                      </Body1>
                    </FlexItem>
                    <FlexItem>
                      <ButtonPrimary
                        className='ButtonMd'
                        onClick={() =>
                          window.open(
                            values?.merchant?.Locations[0]?.CopyImagesUrl
                          )
                        }
                      >
                        <span>Download</span>
                      </ButtonPrimary>
                    </FlexItem>
                  </FlexContainer>
                )}
              </DialogBody>
              <br />
              <DialogAction>
                <ButtonPrimary onClick={handleSubmit}>
                  <span>Apply</span>
                </ButtonPrimary>
              </DialogAction>
            </DialogContainer>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

EditMerchant.propTypes = {
  onClose: PropTypes.func.isRequired
};
