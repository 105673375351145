/* eslint-disable */
import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Route, useHistory, useLocation} from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppVersion from './components/AppSettings/AppVersion';
import './App.css';
import {Box} from '@material-ui/core';
import Navbar from './components/layout/nav/Navbar';
import {useDatePickerContext} from './components/Datepicker/DatePickerContext';
import GlobalStyle from './global-styles';
import Sidebar from './components/layout/sidebar/Sidebar';
import Index from './routes/App';
import WarningModal from './components/Modals/WarningModal';
import Auth from './routes/Auth';
import {
  AUTH_CHAT_USER,
  AUTH_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS
} from './store/global/auth/action';
import {getStorageToken} from './helpers/localStorage/authStorage';
import Managers from './routes/App/Managers';
import AppVersionNotification from './components/Modals/AppVersionNotification';
import useNetwork from './hooks/useNetwork';
import {SET_NETWORK_CONNECTION} from './store/global/globalNotifications/action';
import {
  CLEAR_ACTIVE_ORDERS,
  RUN_GET_ACTIVE_ORDERS_JOB,
  STOP_GET_ACTIVE_ORDERS_JOB
} from './store/global/orders/action';
import {usePrevious} from './hooks/usePrevious';
import {
  CLEAR_BAD_NEW_ACTIVE_ORDERS,
  CLEAR_BAD_NEW_DELIVERED_ORDERS,
  RUN_BAD_DELIVERED_ORDERS_JOB,
  STOP_BAD_DELIVERED_ORDERS_JOB
} from './store/global/bookADriverDelivered/action';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './helpers/ErrorCatchPage';
import {hubspotComment} from './helpers/services/api/hubspot';
import HubspotChat from './routes/App/HubspotChat';
import {SET_LOCATION_ID} from './store/global/user/action';
import hubspotChatReducer from './store/global/hubspotChat/reducer';
import {toast} from 'react-toastify';
import {
  GET_USER_INFO_FOR_CHAT,
  GET_USER_INFO_FOR_CHAT_SUCCESS,
  LOAD_CHAT
} from './store/global/hubspotChat/action';
import DeveloperPage from './routes/Developer';

function App() {
  const date = useDatePickerContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [onlineConnection] = useNetwork();
  const PERMISSIONS = {
    SUPERADMIN: 'sudo',
    USER: 'user',
    AUTH: 'auth',
    CHAT: 'chat',
    DEVELOPER: 'developer'
  };
  const {token, fetchingUserData, isAuthenticated} = useSelector(
    (state) => state.authReducer
  );
  const {userData, locationId, locations, activeLocation} = useSelector(
    (state) => state.user
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const UberBannerShow = useSelector(
    (state) => state.uberEatsReducer.uberBannerShow
  );
  const showPublishProgressBar = useSelector(
    (state) => state.globalNotificationsReducer.showPublishProgress
  );
  const showUnPublishBanner = useSelector(
    (state) => state.globalNotificationsReducer.unPublishBanner.showBanner
  );

  const hubspotInfo = useSelector((state) => state.hubspotChatReducer);

  const formattedDateForShowBanner = (dateObj) => {
    return moment()
      .utc()
      .set({
        year: dateObj?.Year,
        month: dateObj?.Month - 1,
        date: dateObj?.Day,
        hour: dateObj?.Time?.Hour,
        minute: dateObj?.Time?.Minute
      });
  };

  // const startDateHolidays = useSelector((state) => state?.user?.activeLocation?.OpeningHoursExceptions[0]?.Start)
  // const disableTimeFormat = formattedDateForShowBanner(activeLocation?.OpeningHoursExceptions[0]?.Start)

  const diffDateForShowBanner = (value) => {
    const duration = moment.duration(
      moment(value).diff(moment().utc().format())
    );
    const day = duration.asDays();
    return Math.floor(day) <= 1;
  };

  const prevLocation = usePrevious(locationId);

  const isLoggedIn =
    date && token && (userData.role === 'sudo' || locationId) && userData.id;
  const getUserAccess = () => {
    if (location.pathname.includes('/hubspot-chat')) {
      return PERMISSIONS.CHAT;
    }
    if (location.pathname.includes('/developer')) {
      return PERMISSIONS.DEVELOPER;
    }
    if (!isLoggedIn) {
      return PERMISSIONS.AUTH;
    }

    return userData.role === 'sudo' ? PERMISSIONS.SUPERADMIN : PERMISSIONS.USER;
  };

  const runGetNewOrdersJob = () => {
    dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
    dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
  };
  const runGetNewDeliveredJob = () => {
    dispatch(STOP_BAD_DELIVERED_ORDERS_JOB());
    dispatch(RUN_BAD_DELIVERED_ORDERS_JOB());
  };

  useEffect(() => {
    if (
      (prevLocation && history.location.pathname.includes('/orders/active')) ||
      (!history.location.pathname.includes('/orders/active') &&
        !history.location.pathname.includes('/hubspot-chat'))
    ) {
      dispatch(CLEAR_ACTIVE_ORDERS());
      runGetNewOrdersJob();
      dispatch(CLEAR_BAD_NEW_DELIVERED_ORDERS());
      dispatch(CLEAR_BAD_NEW_ACTIVE_ORDERS());
      runGetNewDeliveredJob();
    }
  }, [locationId]);

  useEffect(() => {
    dispatch(SET_NETWORK_CONNECTION(onlineConnection));
    if (!onlineConnection) {
      dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
      dispatch(STOP_BAD_DELIVERED_ORDERS_JOB());
    } else if (isLoggedIn) {
      runGetNewOrdersJob();
      runGetNewDeliveredJob();
    }
  }, [onlineConnection]);

  useEffect(() => {
    // TODO: Remove when the old dashboard does not need to be used.
    // For connection with old dashboard, remove all except 'else', revert AUTH_USER action
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authToken = urlParams.get('token');
    const queryLocationId = urlParams.get('locationId');
    const queryLocationName = urlParams.get('locationName');
    const queryUserEmail = urlParams.get('userEmail');
    if (queryLocationId) {
      localStorage.setItem('locationId', queryLocationId);
    }
    if (
      history.location.pathname.includes('/hubspot-chat') &&
      authToken &&
      queryLocationId
    ) {
      dispatch(
        GET_USER_INFO_FOR_CHAT_SUCCESS({
          ...(queryUserEmail && {
            userEmail: decodeURIComponent(queryUserEmail)
          }),
          ...(queryLocationName && {
            FullName: decodeURIComponent(queryLocationName)
          })
        })
      );
      dispatch(
        AUTH_CHAT_USER({
          token: authToken,
          fromOldDashboard: true,
          locationId: queryLocationId
        })
      );
      localStorage.setItem('access_token', JSON.stringify(authToken));

      dispatch(LOAD_CHAT(true));
    } else if (
      history.location.pathname.includes('/hubspot-chat') &&
      !authToken
    ) {
      toast.error('Missing token!');
    } else if (
      history.location.pathname.includes('/hubspot-chat') &&
      !queryLocationId
    ) {
      toast.error('Missing location ID');
    }
    // Validation of the jwt token will be possible after deploy and receiving the url
    else if (authToken) {
      const hasLoginToken = history.location.pathname.includes('login_token');
      dispatch(
        AUTH_USER({token: authToken, fromOldDashboard: true, hasLoginToken})
      );
      localStorage.setItem('access_token', JSON.stringify(authToken));
      urlParams.delete('token');
      history.push(window.location.pathname);
    } else {
      const storedTokens = getStorageToken();
      if (storedTokens.refreshToken) {
        if (token || storedTokens.token) {
          dispatch(AUTH_USER({token: token || storedTokens.token}));
        } else {
          dispatch(LOGOUT_USER());
        }
      } else {
        dispatch(LOGOUT_USER_SUCCESS());
        const whiteList = [
          '/reset-password/',
          '/sign-up-sales-new',
          '/sign-up',
          '/forgot-password',
          '/activate-member/',
          '/setup'
        ];
        if (
          !whiteList.some((item) => history.location.pathname.includes(item))
        ) {
          history.push('/');
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.HubSpotConversations && locationId) {
      const {FullName, Phone} = locations?.length
        ? locations.filter((el) => el._id === locationId)[0]
        : hubspotInfo;
      const messageText = `Location Name: ${FullName}\nLocation Phone: ${Phone}\nUser Email: ${
        userData?.email || hubspotInfo?.userEmail
      }`;
      window.HubSpotConversations.clear({resetWidget: true});
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.on('conversationStarted', (payload) =>
        hubspotComment(payload, messageText)
      );
      window.HubSpotConversations.on('widgetLoaded', (payload) => {
        if (history.location.pathname.includes('/hubspot-chat')) {
          window.HubSpotConversations.widget.open();
          dispatch(LOAD_CHAT(false));
        }
      });
    }
  }, [locationId]);

  useEffect(() => {
    if (userData?.id) {
      window.pendo.initialize({
        visitor: {
          id: userData.id // Required if user is logged in
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional
        }
      });
    }
  }, [userData]);

  if (fetchingUserData)
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <CircularProgress className='CircularProgress' />
      </Box>
    );

  return (
    <AppVersion>
      <GlobalStyle />
      <WarningModal />
      <AppVersionNotification />
      {(() => {
        switch (getUserAccess()) {
          case PERMISSIONS.USER:
            return (
              <div
                className={cn('main', {
                  withoutNavigation:
                    !userData.pendingDataSetuped ||
                    [
                      '/my-account/self-setup',
                      '/my-account/sales-setup',
                      '/my-account/sales-setup-new'
                    ].some((el) => history.location.pathname.includes(el)),
                  UberConnect: UberBannerShow,
                  ProcessPublish:
                    showPublishProgressBar && !showUnPublishBanner,
                  BannerUnPublish:
                    showUnPublishBanner && !showPublishProgressBar,
                  BannerDisableStore:
                    activeLocation.ClosedManually ||
                    (activeLocation &&
                      activeLocation.OpeningHoursExceptions?.length &&
                      diffDateForShowBanner(
                        formattedDateForShowBanner(
                          activeLocation?.OpeningHoursExceptions[0]?.Start
                        )
                      ))
                })}
              >
                <Navbar
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
                <Sidebar
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {}}
                >
                  <Index />
                </ErrorBoundary>
              </div>
            );
          case PERMISSIONS.SUPERADMIN:
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {}}
              >
                <Managers />
              </ErrorBoundary>
            );
          case PERMISSIONS.CHAT:
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {}}
              >
                <HubspotChat />
              </ErrorBoundary>
            );
          case PERMISSIONS.DEVELOPER:
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => {}}
              >
                <DeveloperPage />
              </ErrorBoundary>
            );
          default:
            return (
              <div className='auth-main'>
                <Auth />
              </div>
            );
        }
      })()}
    </AppVersion>
  );
}

export default App;
