import {createAction} from 'redux-act';

export const GET_USER_INFO_FOR_CHAT = createAction(
  'hubspotChat/GET_USER_INFO_FOR_CHAT'
);

export const LOAD_CHAT = createAction('hubspotChat/LOAD_CHAT');
export const GET_USER_INFO_FOR_CHAT_SUCCESS = createAction(
  'hubspotChat/GET_USER_INFO_FOR_CHAT_SUCCESS'
);
