import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TableCell} from '@material-ui/core';
import Table from '../../../../components/Table';
import ButtonMore from '../../merchants/components/ButtonMore';
import {
  CLEAR_FILTERS,
  GET_USER_SUCCESS,
  GET_USERS,
  SET_RESET_PASSWORD_USER_ID,
  SORT_CHANGE
} from '../../../../store/adminUsers/actions';
import {adminUsersData} from '../../../../store/adminUsers/selectors';
import {CHANGE_USER} from '../../../../store/merchants/action';

const getTableModel = (dispatch) => [
  {
    title: 'Email',
    field: 'email',
    width: '20%',
    sortable: true,
    renderer: (item) => <TableCell>{item.email}</TableCell>
  },
  {
    title: 'Merchant',
    field: 'merchant_name',
    width: '20%',
    sortable: true,
    renderer: (item) => <TableCell>{item.merchant_name}</TableCell>
  },
  {
    title: 'User Full Name',
    width: '20%',
    renderer: (item) => (
      <TableCell>
        {`${item.first_name || ''} ${item.last_name || ''}`}
      </TableCell>
    )
  },
  {
    title: 'Role',
    width: '15%',
    field: 'role',
    sortable: true,
    renderer: (item) => <TableCell>{item.role}</TableCell>
  },

  {
    title: 'Locations Whitelist',
    width: '20%',
    renderer: (item) => (
      <TableCell>
        {item &&
          !!item.locations_whitelist &&
          item.locations_whitelist.map((i) => i.FullName).join(', ')}
      </TableCell>
    )
  },
  {
    width: '10%',
    renderer: (item) => {
      if (item.role !== 'sudo') {
        return item?.Merchant[0]?.IsDeleted ? (
          <ButtonMore itemsData={[]} />
        ) : (
          <ButtonMore
            itemsData={[
              {
                title: 'Edit',
                action: () => dispatch(GET_USER_SUCCESS(item))
              },
              {
                title: 'Reset Password',
                action: () => dispatch(SET_RESET_PASSWORD_USER_ID(item.id))
              },
              {
                title: 'Login',
                action: () => dispatch(CHANGE_USER(item.id))
              }
            ]}
          />
        );
      }
      return true;
    }
  }
];

const UsersTable = () => {
  const dispatch = useDispatch();
  const {
    data,
    paging: {total, page},
    search,
    sort
  } = useSelector(adminUsersData);

  useEffect(() => {
    dispatch(GET_USERS({page, search, sort}));
  }, [page, sort]);

  useEffect(() => {
    return () => dispatch(CLEAR_FILTERS());
  }, []);

  const onTableSort = ({sortBy, sortDirection}) => {
    dispatch(SORT_CHANGE({sortBy, sortDirection}));
  };
  return (
    <>
      {total ? (
        <Table
          tableModel={getTableModel(dispatch)}
          data={data}
          rowKey='userId'
          sortBy={sort.sortBy}
          sortDirection={sort.sortDirection}
          onSort={onTableSort}
        />
      ) : (
        <div>There&apos;s no users here</div>
      )}
    </>
  );
};

export default UsersTable;
