import React from 'react';
import T from 'prop-types';
import moment from 'moment';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import {ButtonInfo} from '../../../../../components/buttons';
import Body5 from '../../../../../components/typography/Body5';
import Body0 from '../../../../../components/typography/Body0';
import {RCHeaderItems, RCHeaderItem} from '../styles';

const getRefundStatusString = (refunds) => {
  let string;
  const lastRefund = refunds[refunds.length - 1];
  if (lastRefund.status === 'Failed') {
    string = 'Failed';
  } else if (refunds[0].type === 'Partial') {
    string = 'Partially refunded';
  } else if (lastRefund.type === 'Subtotal') {
    string = 'Subtotal % refunded';
  } else if (lastRefund.type === 'Full') {
    string = 'Fully refunded';
  } else {
    string = 'Unknown refunded';
  }
  return string;
};

const RCHeader = ({setOpenAdditionInfoModal, orderData, refunds}) => {
  const orderDate = moment(orderData.orderDate).format('MM.DD.YY h:mm a');
  const lastRefund = refunds[refunds.length - 1] || null;
  return (
    <DashboardInner className='RefundsCalculator withoutShadow'>
      <RCHeaderItems>
        <RCHeaderItem>
          <Body5 className='RCHeaderTitle marginBottomXs2'>
            Location Name:
          </Body5>
          <Body0 className='RCHeaderValue wordWrap'>
            {orderData.locationName}
          </Body0>
        </RCHeaderItem>
        <RCHeaderItem>
          <Body5 className='RCHeaderTitle marginBottomXs2'>
            Customer Name:
          </Body5>
          <Body0 className='RCHeaderValue wordWrap'>
            {orderData.customerName}
          </Body0>
        </RCHeaderItem>
        <RCHeaderItem>
          <Body5 className='RCHeaderTitle marginBottomXs2'>Order Date:</Body5>
          <Body0 className='RCHeaderValue'>{orderDate}</Body0>
        </RCHeaderItem>
        {!!refunds?.length && (
          <RCHeaderItem>
            <Body5 className='RCHeaderTitle marginBottomXs2'>Status:</Body5>
            <Body0
              className={cn('RCHeaderValue wordWrap', {
                Green: refunds.length && lastRefund.status === 'Refunded',
                Red: refunds.length && lastRefund.status === 'Failed'
              })}
            >
              {getRefundStatusString(refunds)}
            </Body0>
          </RCHeaderItem>
        )}
        <RCHeaderItem>
          <Body5 className='RCHeaderTitle marginBottomXs2'>
            Final Payment:
          </Body5>
          <Body0 className='RCHeaderValue'>
            {`$${orderData.finalPayment}`}
          </Body0>
        </RCHeaderItem>
        <RCHeaderItem className='wAuto'>
          <ButtonInfo
            className='Outline ButtonInfoDrop ButtonClick'
            onClick={() => setOpenAdditionInfoModal(true)}
          />
        </RCHeaderItem>
      </RCHeaderItems>
    </DashboardInner>
  );
};

export default RCHeader;

RCHeader.propTypes = {
  setOpenAdditionInfoModal: T.func.isRequired,
  orderData: T.object.isRequired,
  refunds: T.array.isRequired
};
