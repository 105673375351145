import {createAction} from 'redux-act';

export const OPEN_CONTACT_FORM_MODAL = createAction(
  'emailMarketing/OPEN_CONTACT_FORM_MODAL'
);

export const OPEN_INFORMATION_MODAL = createAction(
  'emailMarketing/OPEN_INFORMATION_MODAL'
);

export const POST_CONTACT_FORM = createAction(
  'emailMarketing/POST_CONTACT_FORM'
);
export const GET_MARKETING_STATISTIC = createAction(
  'emailMarketing/GET_MARKETING_STATISTIC'
);
export const GET_MARKETING_STATISTIC_SUCCESS = createAction(
  'emailMarketing/GET_MARKETING_STATISTIC_SUCCESS'
);
export const SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER = createAction(
  'emailMarketing/SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER'
);
