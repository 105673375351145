import styled from 'styled-components';
import IconBackDark from 'assets/icon-back-dark.svg';

const LinkBack = styled.div`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: #ff2d38;
  padding-left: 30px;
  position: relative;
  cursor: pointer;

  &.link {
    padding-left: 0px;
    font-size: 18px;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      display: none;
    }
  }
  a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #ff2d38;
    padding-left: 8px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    margin-top: -6px;
    width: 24px;
    height: 12px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyNCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wLjI3NDk2OSA1LjE0ODg4QzAuMjc1MjQ5IDUuMTQ4NiAwLjI3NTQ4NCA1LjE0ODI3IDAuMjc1ODEyIDUuMTQ3OTlMNS4xNzQ0NCAwLjI3Mjk5N0M1LjU0MTQyIC0wLjA5MjIwNjEgNi4xMzUgLTAuMDkwODQ3IDYuNTAwMyAwLjI3NjE4NEM2Ljg2NTU1IDAuNjQzMTY4IDYuODY0MTQgMS4yMzY3NSA2LjQ5NzE2IDEuNjAxOTlMMy4yMDgyMiA0Ljg3NDk5SDIzLjA2MjVDMjMuNTgwMyA0Ljg3NDk5IDI0IDUuMjk0NzEgMjQgNS44MTI0OUMyNCA2LjMzMDI3IDIzLjU4MDMgNi43NDk5OSAyMy4wNjI1IDYuNzQ5OTlIMy4yMDgyN0w2LjQ5NzExIDEwLjAyM0M2Ljg2NDA5IDEwLjM4ODIgNi44NjU1IDEwLjk4MTggNi41MDAyNSAxMS4zNDg4QzYuMTM0OTUgMTEuNzE1OSA1LjU0MTMzIDExLjcxNzEgNS4xNzQzOSAxMS4zNTJMMC4yNzU3NjQgNi40NzY5OUMwLjI3NTQ4NCA2LjQ3NjcxIDAuMjc1MjQ5IDYuNDc2MzggMC4yNzQ5MjEgNi40NzYxQy0wLjA5MjI1MDggNi4xMDk2MyAtMC4wOTEwNzc4IDUuNTE0MTMgMC4yNzQ5NjkgNS4xNDg4OFoiIGZpbGw9IiNGRjJEMzgiLz4KPC9zdmc+');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition-property: transform;
    transition-duration: 0.3s;
  }
  &:hover {
    &::before {
      transform: translateX(-5px);
    }
  }

  &.NavLink {
    padding-left: 0;
    a {
      padding-left: 30px;
    }
  }
  &.DarkColor {
    color: #172751;
    &::before {
      background-image: url('${IconBackDark}');
    }
  }
`;

export default LinkBack;
