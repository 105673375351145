import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import ReactPaginate from 'react-paginate';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TableContainer} from '@material-ui/core';
import moment from 'moment';
import {
  DashboardTable,
  NoResultText,
  TableImage,
  TableInline,
  TablePagination,
  TablePaginationText,
  TableSort,
  TableText
} from '../../../routes/App/dashboard/styles';
import TablePhoto from '../../../assets/icon-dish.svg';
import IconSortActiveUp from '../../../assets/icon-sort-active-up.svg';
import IconSortActiveDown from '../../../assets/icon-sort-active-down.svg';
import Loading from '../../shared/Utils/Loading';
import {HeadCells, ITEMS_PER_PAGE} from '../../../helpers/constants/Table';
import {useDatePickerContext} from '../../Datepicker/DatePickerContext';
import {GET_SALES_PER_ITEM} from '../../../store/analyticsSalesPerItem/action';
import roundTheNumber from '../../../helpers/functionUtils/roundTheNumber';

const SalesByItems = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const {salesPerItem, salesPerItemLoading} = useSelector(
    (state) => state.analyticsSalesPerItemReducer
  );

  const date = useDatePickerContext();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('totalOrders');
  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * ITEMS_PER_PAGE;
  const pageCount = Math.ceil(salesPerItem.length / ITEMS_PER_PAGE);
  const handlePageClick = ({selected: selectedPage}) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (timezone) {
      dispatch(
        GET_SALES_PER_ITEM({
          startDate: date[0]
            ? moment(date[0]).utcOffset(timezone, false).utc().format()
            : null,
          endDate: date[1]
            ? moment(date[1]).utcOffset(timezone, false).utc().format()
            : null
        })
      );
    }
  }, [date, locationId, dispatch, timezone]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  function descendingComparator(a, b, descendingOrderBy) {
    if (b[descendingOrderBy] < a[descendingOrderBy]) {
      return -1;
    }
    if (b[descendingOrderBy] > a[descendingOrderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(comparatorOrder, comparatorOrderBy) {
    return comparatorOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, comparatorOrderBy)
      : (a, b) => -descendingComparator(a, b, comparatorOrderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sortOrder = comparator(a[0], b[0]);
      if (sortOrder !== 0) return sortOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(property, event);
  };
  return (
    <>
      {salesPerItemLoading && <Loading />}
      {!salesPerItemLoading && (
        <>
          <DashboardTable>
            <TableContainer>
              <Table aria-label='enhanced table'>
                <TableHead>
                  <TableRow>
                    {HeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        align={headCell.alignLeft ? 'left' : 'inherit'}
                        className={
                          headCell.id === 'name' ? 'TableCellName' : ''
                        }
                      >
                        <TableSort
                          icon={
                            // eslint-disable-next-line no-nested-ternary
                            orderBy === headCell.id
                              ? order === 'desc'
                                ? IconSortActiveDown
                                : IconSortActiveUp
                              : null
                          }
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'desc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                        </TableSort>
                      </TableCell>
                    ))}
                    {/* <TableCell>
                      <TableSort>Rank</TableSort>
                    </TableCell>
                    <TableCell className="TableCellName" align="left">
                      <TableSort>Item</TableSort>
                    </TableCell>
                    <TableCell align="left">
                      <TableSort>Orders (Total)</TableSort>
                    </TableCell>
                    <TableCell align="left">
                      <TableSort>Sales (Total)</TableSort>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(salesPerItem, getComparator(order, orderBy))
                    .slice(offset, offset + ITEMS_PER_PAGE)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={row.name} tabIndex={-1}>
                          <TableCell
                            component='td'
                            scope='row'
                            inputprops={{'aria-labelledby': labelId}}
                          >
                            <TableText>{row.rank}</TableText>
                          </TableCell>
                          <TableCell style={{width: 350}} align='left'>
                            <TableInline>
                              <TableImage src={row.img || TablePhoto} />
                              <TableText>{row.name}</TableText>
                            </TableInline>
                          </TableCell>
                          <TableCell align='left'>
                            <TableText>
                              {roundTheNumber(row.totalOrders)}
                            </TableText>
                          </TableCell>
                          <TableCell align='left'>
                            <TableText>
                              {roundTheNumber(row.totalSales)}
                            </TableText>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination>
              <TablePaginationText>
                Showing{' '}
                {ITEMS_PER_PAGE > salesPerItem.length
                  ? salesPerItem.length
                  : ITEMS_PER_PAGE}{' '}
                from {salesPerItem.length}
              </TablePaginationText>
              <ReactPaginate
                previousLabel='previous'
                nextLabel='next'
                breakLabel='...'
                breakClassName='break-me'
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={cn('pagination', {
                  remove: salesPerItem.length === 0
                })}
                subContainerClassName='pages pagination'
                activeClassName='active'
              />
            </TablePagination>
          </DashboardTable>
        </>
      )}
      {!salesPerItemLoading && salesPerItem.length === 0 && (
        <Box display='flex' alignItems='center' justifyContent='center' m={1}>
          <NoResultText>No results found for this date range!</NoResultText>
        </Box>
      )}
    </>
  );
};

export default SalesByItems;
