/*eslint-disable*/
import {createReducer} from 'redux-act';
import {
  FETCH_MENU_DATA,
  SET_LOADING_MENU_DATA,
  SET_READY_MENU_DATA,
  SET_MENU_DATA,
  CLEAR_MENU_DATA,
  SET_ACTIVE_TREE_LOADING,
  SET_ACTIVE_TAB,
  SET_PUBLISH_BUTTON_STATE,
  SET_PUBLISH_BUTTON_STATE_MENU_UPDATE,
  SET_SEARCH_VALUE,
  SEARCH_IN_TREE,
  TOGGLE_CHECKBOX_MENU_ITEM,
  SET_SORTING_ORDER,
  ADD_NEW_SECTION,
  ADD_NEW_ITEM,
  ADD_NEW_MODIFIER,
  ADD_NEW_GROUP,
  SET_UPDATED_SECTION,
  SET_UPDATED_ITEM,
  SET_UPDATED_MODIFIER,
  SET_UPDATED_GROUP,
  CLEAR_SECTION,
  CLEAR_ITEM,
  CLEAR_GROUP,
  CLEAR_MODIFIER,
  CLEAR_RECURSION_RELATIONS_SUCCESS,
  BULK_UPDATE_SUCCESSFUL,
  BULK_DELETE_SUCCESSFUL,
  SELECT_ALL_BUTTON
} from './action';
import {PUBLISH_STATUS_DISABLE, PUBLISH_STATUS_ENABLE} from './constants';
import {
  CATEGORY_SCHEME,
  ITEM_SCHEME,
  GROUP_SCHEME,
  MODIFIER_SCHEME
} from './helper/MenuTree';
import {initMenuStore, setActiveTree} from './helper/MenuStore';
import {setActiveDirection, sortByString} from './helper/MenuList';
import {countOutOffStock, createSingleBranch} from './helper/MenuBranch';
import {arrayFlip} from '../../helpers/arrayHelper';
import {setPublishStatus} from './helper/MenuPublish';
import {
  updateOneCategory,
  updateManyCategories,
  clearOneCategory,
  clearManyCategories
} from './helper/MenuOperations/Category';
import {
  updateOneItem,
  updateManyItems,
  clearOneItem,
  clearManyItems
} from './helper/MenuOperations/Item';
import {
  updateOneGroup,
  updateManyGroups,
  clearOneGroup,
  clearManyGroups
} from './helper/MenuOperations/Group';
import {
  updateOneModifier,
  updateManyModifiers,
  clearOneModifier,
  clearManyModifiers
} from './helper/MenuOperations/Modifier';

const initialState = () => ({
  isMenuReady: false,
  isMenuLoading: false,
  menuId: null,
  isToast: null,

  sectionsMap: {},
  itemsMap: {},
  groupsMap: {},
  modifiersMap: {},

  sectionsBranchMap: {},
  itemsBranchMap: {},
  groupsBranchMap: {},
  modifiersBranchMap: {},

  activeSectionBranchMap: {},
  activeItemsBranchMap: {},
  activeGroupsBranchMap: {},
  activeModifiersBranchMap: {},

  sectionsDirection: [],
  itemsDirection: [],
  groupsDirection: [],
  modifiersDirection: [],

  activeSectionsDirection: [],
  activeItemsDirection: [],
  activeGroupsDirection: [],
  activeModifiersDirection: [],

  activeTab: null,
  searchValue: '',
  publishStatus: PUBLISH_STATUS_DISABLE,
  isPublishButtonEnabled: true,
  isActiveTreeProcessing: false,

  selectedCategories: null,
  selectedItems: null,
  selectedGroups: null,
  selectedModifiers: null,
  selectAllButton: false
});

const menuEditorReducer = createReducer(
  {
    [FETCH_MENU_DATA]: (store, data) => ({
      ...store,
      isMenuReady: false,
      isMenuLoading: false
    }),
    [SET_LOADING_MENU_DATA]: (store, data) => ({
      ...store,
      isMenuLoading: Boolean(data)
    }),
    [SET_READY_MENU_DATA]: (store, status) => ({
      ...store,
      isMenuReady: Boolean(status)
    }),

    [SET_MENU_DATA]: (store, payload) => initMenuStore(store, payload),
    [SET_ACTIVE_TAB]: (store, activeTab) => {
      const nextStore = setActiveTree(store, activeTab);

      return {
        ...nextStore,
        selectedCategories: null,
        selectedItems: null,
        selectedGroups: null,
        selectedModifiers: null,
        selectAllButton: false
      };
    },
    [SEARCH_IN_TREE]: (store, search) =>
      setActiveTree(store, store.activeTab, search),

    [SET_SEARCH_VALUE]: (store, searchValue) => ({...store, searchValue}),

    [SET_PUBLISH_BUTTON_STATE]: (store, status) =>
      setPublishStatus(store, status, false),
    [SET_PUBLISH_BUTTON_STATE_MENU_UPDATE]: (store, status) => ({
      ...store,
      publishStatus: status
    }),
    [SET_ACTIVE_TREE_LOADING]: (store, isProcess) => ({
      ...store,
      isActiveTreeProcessing: Boolean(isProcess)
    }),

    [ADD_NEW_SECTION]: (store, {id, data}) => {
      const {activeTab} = store;
      const branch = createSingleBranch(data, 'category');

      store.sectionsMap[id] = data;
      store.sectionsBranchMap[id] = branch;
      store.activeSectionBranchMap[id] = {
        $id: id,
        $children: branch.$children,
        $branchStore: branch.$branchScheme.branchStoreMap,
        $search: null
      };

      countOutOffStock(id, store);

      if (data.Items.length) {
        for (const PosId of data.Items) {
          const item = {...store.itemsMap[PosId]};
          item.Sections = [...item.Sections, id];
          store.itemsMap[PosId] = item;
        }

        store.itemsMap = {...store.itemsMap};
      }

      store.sectionsDirection.push(id);

      //TODO: доработать отображение во время поиска и на странице фильтров
      if (activeTab === 'CATEGORY') {
        store.activeSectionsDirection = [id, ...store.activeSectionsDirection];
      }

      return setPublishStatus(
        {
          ...store,
          sectionsMap: {...store.sectionsMap},
          sectionsBranchMap: {...store.sectionsBranchMap},
          activeSectionBranchMap: {...store.activeSectionBranchMap},
          sectionsDirection: [...store.sectionsDirection]
        },
        PUBLISH_STATUS_ENABLE
      );
    },
    [ADD_NEW_ITEM]: (store, {id, data}) => {
      const {activeTab} = store;
      const branch = createSingleBranch(data, 'item');

      store.itemsMap[id] = data;
      store.itemsBranchMap[id] = branch;
      store.activeItemsBranchMap[id] = {
        $id: id,
        $children: branch.$children,
        $branchStore: branch.$branchScheme.branchStoreMap,
        $search: null
      };

      if (data.Sections.length) {
        let isChangeBranchMap = false;
        let isChangeActiveBranchMap = false;

        for (const MerchantSectionId of data.Sections) {
          const section = {...store.sectionsMap[MerchantSectionId]};
          section.Items = [...section.Items, id];
          store.sectionsMap[MerchantSectionId] = section;

          if (store.sectionsBranchMap.hasOwnProperty(MerchantSectionId)) {
            store.sectionsBranchMap[MerchantSectionId].$children = [
              ...section.Items
            ];
            store.sectionsBranchMap[MerchantSectionId] = {
              ...store.sectionsBranchMap[MerchantSectionId]
            };
            isChangeBranchMap = true;

            if (
              store.activeSectionBranchMap.hasOwnProperty(MerchantSectionId)
            ) {
              store.activeSectionBranchMap[MerchantSectionId].$children = [
                ...section.Items
              ];
              store.activeSectionBranchMap[MerchantSectionId] = {
                ...store.activeSectionBranchMap[MerchantSectionId]
              };
              isChangeActiveBranchMap = true;
            }
          }
        }

        store.sectionsMap = {...store.sectionsMap};

        if (isChangeBranchMap) {
          store.sectionsBranchMap = {...store.sectionsBranchMap};
        }

        if (isChangeActiveBranchMap) {
          store.activeSectionBranchMap = {...store.activeSectionBranchMap};
        }
      }

      if (data.OptionsGroups.length) {
        for (const PosId of data.OptionsGroups) {
          const group = {...store.groupsMap[PosId]};
          group.MenuItems = [...group.MenuItems, id];
          store.groupsMap[PosId] = group;
        }

        store.groupsMap = {...store.groupsMap};
      }

      store.itemsDirection = sortByString(
        Object.values(store.itemsMap),
        'Name'
      ).map((item) => item.PosId);

      //TODO: доработать отображение во время поиска и на странице фильтров
      if (activeTab === 'ITEM') {
        store.activeItemsDirection = [id, ...store.activeItemsDirection];
        // store.activeItemsDirection = setActiveDirection(store.itemsDirection, store.activeItemsBranchMap);
      }

      return setPublishStatus(
        {
          ...store,
          itemsMap: {...store.itemsMap},
          itemsBranchMap: {...store.itemsBranchMap},
          activeItemsBranchMap: {...store.activeItemsBranchMap}
        },
        PUBLISH_STATUS_ENABLE
      );
    },
    [ADD_NEW_GROUP]: (store, {id, data}) => {
      const {activeTab} = store;
      const branch = createSingleBranch(data, 'modifierGroup');

      store.groupsMap[id] = data;
      store.groupsBranchMap[id] = branch;
      store.activeGroupsBranchMap[id] = {
        $id: id,
        $children: branch.$children,
        $branchStore: branch.$branchScheme.branchStoreMap,
        $search: null
      };

      if (data.MenuItems.length) {
        let isChangeBranchMap = false;
        let isChangeActiveBranchMap = false;

        for (const PosId of data.MenuItems) {
          const item = {...store.itemsMap[PosId]};
          item.OptionsGroups = [...item.OptionsGroups, id];
          store.itemsMap[PosId] = item;

          if (store.itemsBranchMap.hasOwnProperty(PosId)) {
            store.itemsBranchMap[PosId].$children = [...item.OptionsGroups];
            store.itemsBranchMap[PosId] = {...store.itemsBranchMap[PosId]};
            isChangeBranchMap = true;

            if (store.activeItemsBranchMap.hasOwnProperty(PosId)) {
              store.activeItemsBranchMap[PosId].$children = [
                ...item.OptionsGroups
              ];
              store.activeItemsBranchMap[PosId] = {
                ...store.activeItemsBranchMap[PosId]
              };
              isChangeActiveBranchMap = true;
            }
          }

          store.itemsMap = {...store.itemsMap};

          if (isChangeBranchMap) {
            store.itemsBranchMap = {...store.itemsBranchMap};
          }

          if (isChangeActiveBranchMap) {
            store.activeItemsBranchMap = {...store.activeItemsBranchMap};
          }
        }
      }

      if (data.Modifiers.length) {
        let isChangeBranchMap = false;
        let isChangeActiveBranchMap = false;

        for (const PosId of data.Modifiers) {
          const modifier = {...store.modifiersMap[PosId]};
          modifier.ModifierGroups = [...modifier.ModifierGroups, id];
          store.modifiersMap[PosId] = modifier;

          if (store.modifiersBranchMap.hasOwnProperty(PosId)) {
            store.modifiersBranchMap[PosId].$children = [
              ...modifier.ModifierGroups
            ];
            store.modifiersBranchMap[PosId] = {
              ...store.modifiersBranchMap[PosId]
            };
            isChangeBranchMap = true;

            if (store.activeModifiersBranchMap.hasOwnProperty(PosId)) {
              store.activeModifiersBranchMap[PosId].$children = [
                ...modifier.ModifierGroups
              ];
              store.activeModifiersBranchMap[PosId] = {
                ...store.activeModifiersBranchMap[PosId]
              };
              isChangeActiveBranchMap = true;
            }
          }
        }

        store.modifiersMap = {...store.modifiersMap};

        if (isChangeBranchMap) {
          store.modifiersBranchMap = {...store.modifiersBranchMap};
        }

        if (isChangeActiveBranchMap) {
          store.activeModifiersBranchMap = {...store.activeModifiersBranchMap};
        }
      }

      if (data.Items.length) {
        for (const PosId of data.Items) {
          const modifier = {...store.modifiersMap[PosId]};
          modifier.ParentModifierGroups = [
            ...modifier.ParentModifierGroups,
            id
          ];
          store.modifiersMap[PosId] = modifier;
        }

        store.modifiersMap = {...store.modifiersMap};
      }

      store.groupsDirection = sortByString(
        Object.values(store.groupsMap),
        'OptionName'
      ).map((item) => item.PosId);

      //TODO: доработать отображение во время поиска и на странице фильтров
      if (activeTab === 'GROUP') {
        store.activeGroupsDirection = [id, ...store.activeGroupsDirection];
        // store.activeGroupsDirection = setActiveDirection(store.groupsDirection, store.activeGroupsBranchMap);
      }

      return setPublishStatus(
        {
          ...store,
          groupsMap: {...store.groupsMap},
          groupsBranchMap: {...store.groupsBranchMap},
          activeGroupsBranchMap: {...store.activeGroupsBranchMap}
        },
        PUBLISH_STATUS_ENABLE
      );
    },
    [ADD_NEW_MODIFIER]: (store, {id, data}) => {
      const {activeTab} = store;
      const branch = createSingleBranch(data, 'modifier');

      store.modifiersMap[id] = data;
      store.modifiersBranchMap[id] = branch;
      store.activeModifiersBranchMap[id] = {
        $id: id,
        $children: branch.$children,
        $branchStore: branch.$branchScheme.branchStoreMap,
        $search: null
      };

      if (data.ParentModifierGroups.length) {
        let isChangeBranchMap = false;
        let isChangeActiveBranchMap = false;

        for (const PosId of data.ParentModifierGroups) {
          const group = {...store.groupsMap[PosId]};
          group.Items = [...group.Items, id];
          store.groupsMap[PosId] = group;

          if (store.groupsBranchMap.hasOwnProperty(PosId)) {
            store.groupsBranchMap[PosId].$children = [...group.Items];
            store.groupsBranchMap[PosId] = {...store.groupsBranchMap[PosId]};
            isChangeBranchMap = true;

            if (store.activeGroupsBranchMap.hasOwnProperty(PosId)) {
              store.activeGroupsBranchMap[PosId].$children = [...group.Items];
              store.activeGroupsBranchMap[PosId] = {
                ...store.activeGroupsBranchMap[PosId]
              };
              isChangeActiveBranchMap = true;
            }
          }
        }

        store.groupsMap = {...store.groupsMap};

        if (isChangeBranchMap) {
          store.groupsBranchMap = {...store.groupsBranchMap};
        }

        if (isChangeActiveBranchMap) {
          store.activeGroupsBranchMap = {...store.activeGroupsBranchMap};
        }
      }

      if (data.ModifierGroups.length) {
        for (const PosId of data.ModifierGroups) {
          const group = {...store.groupsMap[PosId]};
          group.Modifiers = [...group.Modifiers, id];
          store.groupsMap[PosId] = group;
        }

        store.groupsMap = {...store.groupsMap};
      }

      store.modifiersDirection = sortByString(
        Object.values(store.modifiersMap),
        'Name'
      ).map((item) => item.PosId);

      //TODO: доработать отображение во время поиска и на странице фильтров
      if (activeTab === 'MODIFIER') {
        store.activeModifiersDirection = [
          id,
          ...store.activeModifiersDirection
        ];
        // store.activeModifiersDirection = setActiveDirection(store.modifiersDirection, store.activeModifiersBranchMap);
      }

      return setPublishStatus(
        {
          ...store,
          modifiersMap: {...store.modifiersMap},
          modifiersBranchMap: {...store.modifiersBranchMap},
          activeModifiersBranchMap: {...store.activeModifiersBranchMap}
        },
        PUBLISH_STATUS_ENABLE
      );
    },

    [SET_UPDATED_SECTION]: updateOneCategory,
    [SET_UPDATED_ITEM]: updateOneItem,
    [SET_UPDATED_GROUP]: updateOneGroup,
    [SET_UPDATED_MODIFIER]: updateOneModifier,

    [CLEAR_SECTION]: clearOneCategory,
    [CLEAR_ITEM]: clearOneItem,
    [CLEAR_GROUP]: clearOneGroup,
    [CLEAR_MODIFIER]: clearOneModifier,

    [TOGGLE_CHECKBOX_MENU_ITEM]: (store, item) => {
      const {
        activeTab,
        activeSectionsDirection,
        activeItemsDirection,
        activeGroupsDirection,
        activeModifiersDirection
      } = store;

      const getSelectedStore = (st) => {
        return st && Object.keys(st).length ? st : null;
      };

      const toggleProcess = (
        _selectedStore,
        storeDirection,
        type,
        isAllSelected
      ) => {
        let selectedStore = _selectedStore ? {..._selectedStore} : {};

        if (item === 'all') {
          if (
            Object.keys(selectedStore).length === storeDirection.length &&
            (isAllSelected === undefined || isAllSelected)
          ) {
            selectedStore = null;
            store.selectAllButton = true;
          } else {
            selectedStore = arrayFlip(storeDirection, true);
            store.selectAllButton = false;
          }
          return getSelectedStore(selectedStore);
        }

        if (item.id === 'all') {
          if (item.type === type) {
            if (Object.keys(selectedStore).length === storeDirection.length) {
              selectedStore = null;
            } else {
              selectedStore = arrayFlip(storeDirection, true);
            }
          }
          return getSelectedStore(selectedStore);
        }

        if (type === item.type) {
          if (selectedStore.hasOwnProperty(item.id)) {
            delete selectedStore[item.id];
          } else {
            selectedStore[item.id] = true;
          }
          if (!Object.keys(selectedStore).length) {
            selectedStore = null;
          }
          store.selectAllButton =
            selectedStore &&
            Object.keys(selectedStore).length === storeDirection.length;
          return getSelectedStore(selectedStore);
        }
        return _selectedStore;
      };

      if (activeTab === 'CATEGORY') {
        store.selectedCategories = toggleProcess(
          store.selectedCategories,
          activeSectionsDirection,
          CATEGORY_SCHEME
        );
      }

      if (activeTab === 'ITEM') {
        store.selectedItems = toggleProcess(
          store.selectedItems,
          activeItemsDirection,
          ITEM_SCHEME
        );
      }

      if (activeTab === 'GROUP') {
        store.selectedGroups = toggleProcess(
          store.selectedGroups,
          activeGroupsDirection,
          GROUP_SCHEME
        );
      }

      if (activeTab === 'MODIFIER') {
        store.selectedModifiers = toggleProcess(
          store.selectedModifiers,
          activeModifiersDirection,
          MODIFIER_SCHEME
        );
      }

      if (activeTab === 'HIDDEN') {
        const selectedCategories = store.selectedCategories
          ? Object.keys(store.selectedCategories).length
          : 0;
        const selectedItems = store.selectedItems
          ? Object.keys(store.selectedItems).length
          : 0;
        const selectedModifiers = store.selectedModifiers
          ? Object.keys(store.selectedModifiers).length
          : 0;

        const isCurrentAllSelected = Boolean(
          selectedCategories === activeSectionsDirection.length &&
            selectedItems === activeItemsDirection.length &&
            selectedModifiers === activeModifiersDirection.length
        );

        store.selectedCategories = toggleProcess(
          store.selectedCategories,
          activeSectionsDirection,
          CATEGORY_SCHEME,
          isCurrentAllSelected
        );
        store.selectedItems = toggleProcess(
          store.selectedItems,
          activeItemsDirection,
          ITEM_SCHEME,
          isCurrentAllSelected
        );
        store.selectedModifiers = toggleProcess(
          store.selectedModifiers,
          activeModifiersDirection,
          MODIFIER_SCHEME,
          isCurrentAllSelected
        );
      }

      if (activeTab === 'OUT_OFF_STOCK') {
        const selectedItems = store.selectedItems
          ? Object.keys(store.selectedItems).length
          : 0;
        const selectedModifiers = store.selectedModifiers
          ? Object.keys(store.selectedModifiers).length
          : 0;

        const isCurrentAllSelected = Boolean(
          selectedItems === activeItemsDirection.length &&
            selectedModifiers === activeModifiersDirection.length
        );

        store.selectedItems = toggleProcess(
          store.selectedItems,
          activeItemsDirection,
          ITEM_SCHEME,
          isCurrentAllSelected
        );
        store.selectedModifiers = toggleProcess(
          store.selectedModifiers,
          activeModifiersDirection,
          MODIFIER_SCHEME,
          isCurrentAllSelected
        );
      }

      return {...store};
    },
    [BULK_UPDATE_SUCCESSFUL]: (store, payload) => {
      let nextStore = store;
      const {categories, items, groups, modifiers} = payload;

      if (categories) {
        nextStore = updateManyCategories(nextStore, categories);
        nextStore.selectedCategories = null;
      }

      if (items) {
        nextStore = updateManyItems(nextStore, items);
        nextStore.selectedItems = null;
      }

      if (groups) {
        nextStore = updateManyGroups(nextStore, groups);
        nextStore.selectedGroups = null;
      }

      if (modifiers) {
        nextStore = updateManyModifiers(nextStore, modifiers);
        nextStore.selectedModifiers = null;
      }

      return nextStore;
    },
    [BULK_DELETE_SUCCESSFUL]: (store, payload) => {
      let nextStore = store;
      const {categories, items, groups, modifiers} = payload;

      if (categories) {
        nextStore = clearManyCategories(nextStore, categories.ids);
        nextStore.selectedCategories = null;
      }

      if (items) {
        nextStore = clearManyItems(nextStore, items.ids);
        nextStore.selectedItems = null;
      }

      if (groups) {
        nextStore = clearManyGroups(nextStore, groups.ids);
        nextStore.selectedGroups = null;
      }

      if (modifiers) {
        nextStore = clearManyModifiers(nextStore, modifiers.ids);
        nextStore.selectedModifiers = null;
      }

      return nextStore;
    },

    [CLEAR_RECURSION_RELATIONS_SUCCESS]: (store, relations) => {
      if (!relations.length || !Array.isArray(relations)) {
        return store;
      }

      for (const [parent, child] of relations) {
        const group = parent.type === 'group' ? parent.data : child.data;
        const modifier = parent.type === 'group' ? child.data : parent.data;

        const groupBranch = store.groupsBranchMap[group.PosId];
        const modifierBranch = store.modifiersBranchMap[modifier.PosId];

        const activeGroupBranch = store.activeGroupsBranchMap[group.PosId];
        const activeModifierBranch =
          store.activeModifiersBranchMap[modifier.PosId];

        if (parent.type === 'group') {
          group.Items = group.Items.filter((PosId) => PosId !== modifier.PosId);
          store.groupsMap[group.PosId] = {...group};

          groupBranch.$children = group.Items;
          store.groupsBranchMap[group.PosId] = {...groupBranch};

          if (activeGroupBranch) {
            activeGroupBranch.$children = group.Items;
            store.activeGroupsBranchMap[group.PosId] = {...activeGroupBranch};
          }

          modifier.ParentModifierGroups = modifier.ParentModifierGroups.filter(
            (PosId) => PosId !== group.PosId
          );
          store.modifiersMap[modifier.PosId] = {...modifier};
        } else {
          modifier.ModifierGroups = modifier.ModifierGroups.filter(
            (PosId) => PosId !== group.PosId
          );
          store.modifiersMap[modifier.PosId] = {...modifier};

          modifierBranch.$children = modifier.ModifierGroups;
          store.modifiersBranchMap[modifier.PosId] = {...modifierBranch};

          if (activeModifierBranch) {
            activeModifierBranch.$children = modifier.ModifierGroups;
            store.activeModifiersBranchMap[modifier.PosId] = {
              ...activeModifierBranch
            };
          }

          group.Modifiers = group.Modifiers.filter(
            (PosId) => PosId !== modifier.PosId
          );
          store.groupsMap[group.PosId] = {...group};
        }
      }

      return {
        ...store,
        groupsMap: {...store.groupsMap},
        groupsBranchMap: {...store.groupsBranchMap},
        activeGroupsBranchMap: {...store.activeGroupsBranchMap},
        modifiersMap: {...store.modifiersMap},
        modifiersBranchMap: {...store.modifiersBranchMap},
        activeModifiersBranchMap: {...store.activeModifiersBranchMap}
      };
    },

    [SET_SORTING_ORDER]: (store, payload) => {
      const nextStore = {
        ...store,
        publishStatus: PUBLISH_STATUS_ENABLE
      };
      const {ParentId, ParentType, Elements} = payload;

      if (ParentType === 'menu') {
        nextStore.sectionsDirection = Elements;
        nextStore.activeSectionsDirection = setActiveDirection(
          Elements,
          nextStore.activeSectionBranchMap
        );
      }

      if (ParentType === 'category') {
        const {
          sectionsMap,
          sectionsBranchMap,
          activeSectionBranchMap
        } = nextStore;

        const category = {...sectionsMap[ParentId]};
        const categoryBranch = {...sectionsBranchMap[ParentId]};
        const activeCategoryBranch = {...activeSectionBranchMap[ParentId]};

        category.Items = Elements;
        categoryBranch.$children = Elements;
        activeCategoryBranch.$children = Elements;

        nextStore.sectionsMap = {...sectionsMap, [ParentId]: category};
        nextStore.sectionsBranchMap = {
          ...sectionsBranchMap,
          [ParentId]: categoryBranch
        };
        nextStore.activeSectionBranchMap = {
          ...activeSectionBranchMap,
          [ParentId]: activeCategoryBranch
        };

        return nextStore;
      }

      if (ParentType === 'item') {
        const {itemsMap, itemsBranchMap, activeItemsBranchMap} = nextStore;

        const item = {...itemsMap[ParentId]};
        const itemBranch = {...itemsBranchMap[ParentId]};
        const activeItemBranch = {...activeItemsBranchMap[ParentId]};

        item.OptionsGroups = Elements;
        itemBranch.$children = Elements;
        activeItemBranch.$children = Elements;

        nextStore.itemsMap = {...itemsMap, [ParentId]: item};
        nextStore.itemsBranchMap = {...itemsBranchMap, [ParentId]: itemBranch};
        nextStore.activeItemsBranchMap = {
          ...activeItemsBranchMap,
          [ParentId]: activeItemBranch
        };

        return nextStore;
      }

      if (ParentType === 'modifierGroup') {
        const {groupsMap, groupsBranchMap, activeGroupsBranchMap} = nextStore;

        const group = {...groupsMap[ParentId]};
        const groupBranch = {...groupsBranchMap[ParentId]};
        const activeGroupBranch = {...activeGroupsBranchMap[ParentId]};

        group.Items = Elements;
        groupBranch.$children = Elements;
        activeGroupBranch.$children = Elements;

        nextStore.groupsMap = {...groupsMap, [ParentId]: group};
        nextStore.groupsBranchMap = {
          ...groupsBranchMap,
          [ParentId]: groupBranch
        };
        nextStore.activeGroupsBranchMap = {
          ...activeGroupsBranchMap,
          [ParentId]: activeGroupBranch
        };

        return nextStore;
      }

      if (ParentType === 'modifier') {
        const {
          modifiersMap,
          modifiersBranchMap,
          activeModifiersBranchMap
        } = nextStore;

        const modifier = {...modifiersMap[ParentId]};
        const modifierBranch = {...modifiersBranchMap[ParentId]};
        const activeModifierBranch = {...activeModifiersBranchMap[ParentId]};

        modifier.ModifierGroups = Elements;
        modifierBranch.$children = Elements;
        activeModifierBranch.$children = Elements;

        nextStore.modifiersMap = {...modifiersMap, [ParentId]: modifier};
        nextStore.modifiersBranchMap = {
          ...modifiersBranchMap,
          [ParentId]: modifierBranch
        };
        nextStore.activeModifiersBranchMap = {
          ...activeModifiersBranchMap,
          [ParentId]: activeModifierBranch
        };
      }

      return nextStore;
    },

    [CLEAR_MENU_DATA]: (store) => ({
      ...initialState(),
      publishStatus: store.publishStatus
    }),

    [SELECT_ALL_BUTTON]: (store) => {
      return {
        ...store,
        selectAllButton: !store.selectAllButton
      };
    }
  },
  initialState()
);

export default menuEditorReducer;
