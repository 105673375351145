import * as Yup from 'yup';

const GOOGLE_REGEX = /^UA-\d{4,9}-\d{1,4}$|^G-\w{10}$/;
const FACEBOOK_REGEX = /^\d{15,16}$/;

const MarketingIntegrationsSchema = Yup.object().shape({
  googleTagId: Yup.string().when('showKey', {
    is: 'google-analytics',
    then: Yup.string()
      .matches(GOOGLE_REGEX, 'Invalid Google tag ID')
      .required('Google tag ID is required')
      .nullable(),
    otherwise: Yup.string().nullable().notRequired()
  }),
  metaPixelId: Yup.string().when('showKey', {
    is: 'meta-pixel',
    then: Yup.string()
      .matches(FACEBOOK_REGEX, 'Invalid Facebook Pixel ID')
      .required('Facebook Pixel ID is required')
      .nullable(),
    otherwise: Yup.string().nullable().notRequired()
  })
});

export default MarketingIntegrationsSchema;
