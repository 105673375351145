import {put, takeEvery, select, takeLatest} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  getCountriesForStepsApi,
  getSetupUserAccountApi,
  setupUserAccountApi
} from '../../helpers/services/api/user';
import {
  GET_SETUP_USER_ACCOUNT,
  GET_SETUP_USER_ACCOUNT_SUCCESS,
  SETUP_USER_ACCOUNT,
  SETUP_USER_ACCOUNT_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES,
  GET_SHORT_LINK_SUCCESS,
  GET_SHORT_LINK,
  GET_UBER_EATS_STORES_ONBOARDING,
  SAVE_UBER_EATS_STORES_ONBOARDING,
  GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
  CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
  UBER_EATS_SELECT_STORE_ONBOARDING,
  UBER_EATS_ONBOARDING_SAVE_MENUS,
  UBER_EATS_ONBOARDING_MENUS_LOADING,
  INITIATE_ONBOARDING_MENU_PROCESS
} from './action';
import {getAccountStepsStatus} from '../global/account/saga';
import {getUserData} from '../global/user/saga';
import {
  getUberEatsMerchantStatusApi,
  getUberEatsStoreMenus,
  getUberEatsStoresApi,
  postUberEatsOnboardingProcess
} from '../../helpers/services/api/uberEats';

const userId = ({user}) => user.userData?.id;
const currentState = ({setupRestaurantReducer}) => setupRestaurantReducer;

function* setupAccount({payload: {payload, successCallback}}) {
  try {
    const {
      PendingData,
      PendingDataForRequest,
      finalSetup,
      isOldSalesSetup
    } = yield select(currentState);
    // TODO change Pending data to save only current page step and previous
    const preparedPendingData =
      payload?.step === 1 && !isOldSalesSetup
        ? PendingDataForRequest.filter((item) => item.step <= payload.step)
        : PendingData.filter((item) => item.step <= payload.step);

    const {data, ...rest} = yield setupUserAccountApi(
      {...payload, PendingData: preparedPendingData, isOldSalesSetup},
      finalSetup
    );
    if (payload.RestaurantURL) {
      yield getUserData();
    }
    if (data || rest?.status === 201) {
      yield put(SETUP_USER_ACCOUNT_SUCCESS(data));
      if (successCallback) successCallback();
    }

    if (finalSetup) {
      yield getAccountStepsStatus();
      yield getUserData();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getCountries({payload}) {
  try {
    const {data} = yield getCountriesForStepsApi(payload);
    if (data) {
      yield put(GET_COUNTRIES_SUCCESS(data.docs));
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* getSetupAccount() {
  try {
    const id = yield select(userId);
    const {step} = yield select(currentState);
    if (step === 0) {
      yield getCountries({});
    }
    const {data} = yield getSetupUserAccountApi(id);
    if (data) {
      yield put(GET_SETUP_USER_ACCOUNT_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getShortUrl() {
  try {
    const id = yield select(userId);
    const {data} = yield getSetupUserAccountApi(id);

    if (data) {
      yield put(GET_SHORT_LINK_SUCCESS(data));
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* getUberEatsStoresOnboarding() {
  try {
    const merchantId = yield select((state) => state.user.userData.merchant_id);

    const stores = yield getUberEatsStoresApi(merchantId);

    if (stores) {
      const storesArr = [];
      if (stores.data && stores.data.length) {
        stores.data.forEach((store) => {
          const item = {
            id: store?.id,
            name: store?.details?.name,
            status: store?.onlineStatus?.status
          };
          storesArr.push(item);
        });
      }
      yield put(SAVE_UBER_EATS_STORES_ONBOARDING(storesArr));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getUberEatsMenus({payload}) {
  try {
    yield put(UBER_EATS_ONBOARDING_MENUS_LOADING(true));

    const menus = yield getUberEatsStoreMenus(payload);

    if (menus) {
      const menusArr = [];
      const menuNamesVariants = {
        MENU_TYPE_FULFILLMENT_DELIVERY: 'Delivery',
        MENU_TYPE_FULFILLMENT_PICK_UP: 'PickUp',
        MENU_TYPE_FULFILLMENT_DINE_IN: 'DineIn'
      };
      if (menus.data && menus.data.length) {
        menus.data.forEach((menu) => {
          const item = {
            storeId: menu?.storeId,
            menuType: menu?.menuType,
            menuName: menuNamesVariants[menu?.menuType]
          };
          menusArr.push(item);
        });
      }
      yield put(UBER_EATS_ONBOARDING_SAVE_MENUS(menusArr));
      yield put(UBER_EATS_ONBOARDING_MENUS_LOADING(false));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getUberEatsStatusOnboarding() {
  try {
    const merchantId = yield select((state) => state.user.userData.merchant_id);

    const response = yield getUberEatsMerchantStatusApi(merchantId);

    if (response?.status === 200) {
      yield put(CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING(true));
    } else {
      yield put(CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING(false));
    }
  } catch (error) {
    yield put(CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING(false));
    console.log('SAGA ERROR', error);
  }
}

function* initiateOnboarding({payload: {payload, successCallback}}) {
  try {
    const {locationId} = yield select((state) => state.user);
    const {data, ...info} = payload;

    let respMenuOnboarding = {};

    if (
      (payload.data.platformSelect === 0 ||
        payload.data.platformSelect === 2 ||
        payload.data.platformSelect === 5) &&
      payload.data.MenuUberEats
    ) {
      const requestBody = {
        uberEatsStoreId: payload.data.uberEatsSelectedStoreId,
        sauceLocationId: locationId,
        menuType: payload.data.uberEatsSelectedMenu,
        reducePriceConfig: payload.data.lowerAllPrices
          ? {
              percent:
                payload.data.inflationType === '%'
                  ? payload.data.inflationInputPercentage
                  : null,
              amount:
                payload.data.inflationType === '$'
                  ? payload.data.inflationInputAmount
                  : null,
              roundingType:
                payload.data.inflationType === '%'
                  ? payload.data.roundingValue
                  : null
            }
          : null
      };

      respMenuOnboarding = yield postUberEatsOnboardingProcess(requestBody);
    }

    if (
      respMenuOnboarding.status === 200 ||
      ((payload.data.platformSelect === 0 ||
        payload.data.platformSelect === 2 ||
        payload.data.platformSelect === 5) &&
        !payload.data.MenuUberEats) ||
      (payload.data.platformSelect !== 0 &&
        payload.data.platformSelect !== 2 &&
        payload.data.platformSelect !== 5)
    ) {
      yield put(
        SETUP_USER_ACCOUNT({
          payload: info,
          successCallback
        })
      );
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* setupRestaurantSaga() {
  yield takeLatest(SETUP_USER_ACCOUNT, setupAccount);
  yield takeEvery(GET_SETUP_USER_ACCOUNT, getSetupAccount);
  yield takeEvery(GET_COUNTRIES, getCountries);
  yield takeEvery(GET_SHORT_LINK, getShortUrl);
  yield takeEvery(GET_UBER_EATS_STORES_ONBOARDING, getUberEatsStoresOnboarding);
  yield takeEvery(
    GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
    getUberEatsStatusOnboarding
  );
  // yield takeEvery(UBER_EATS_SELECT_STORE_ONBOARDING, getUberEatsMenus);
  yield takeEvery(INITIATE_ONBOARDING_MENU_PROCESS, initiateOnboarding);
}

export default setupRestaurantSaga;
