import styled from 'styled-components';

const SubtitleWithLine = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: #929cb9;
  opacity: 0.6;

  &::after {
    flex: 1 1 auto;
    content: '';
    height: 1px;
    background-color: #929cb9;
    margin-left: 10px;
  }
`;

export default SubtitleWithLine;
