import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {TableCell} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import {DashboardContent} from 'components/DashboardContent/styles';
import Headline from 'components/headline2/HeadlineComponentStyle';
import {FlexContainer, FlexItem, MainPaper} from 'components/layout';
import SearchField from 'components/fields/SearchField';
import {ButtonOutline} from 'components/buttons';
import ButtonMore from 'routes/App/merchants/components/ButtonMore';

import {
  GET_SUDO_USER,
  PAGE_CHANGE,
  CLEAR_SUDO_USER,
  GET_SUDO_USERS,
  GET_ALL_SUDO_USER_FEATURE_FLAGS,
  UPDATE_SUDO_USER,
  CLEAR_FILTERS,
  SEARCH_CHANGE
} from 'store/adminSudoUsers/action';
import Table from 'components/Table';
import {
  TablePagination,
  TablePaginationText
} from 'routes/App/dashboard/styles';
import Loading from 'components/shared/Utils/Loading';
import EditSudoUserModal from 'routes/App/Managers/SudoUsers/Modals/EditSudoUserModal';

const getTableModel = (dispatch, userId) => [
  {
    title: 'First name',
    renderer: (item) => <TableCell>{item?.first_name}</TableCell>,
    width: '25%'
  },
  {
    title: 'Last name',
    width: '25%',
    renderer: (item) => <TableCell>{item?.last_name}</TableCell>
  },
  {
    title: 'Email',
    width: '25%',
    renderer: (item) => <TableCell>{item?.email}</TableCell>
  },
  {
    width: '5%',
    renderer: (item) =>
      userId !== item.id && (
        <ButtonMore
          itemsData={[
            {
              title: 'Edit',
              action: () => {
                dispatch(GET_ALL_SUDO_USER_FEATURE_FLAGS());
                dispatch(GET_SUDO_USER(item?._id));
              }
            }
          ]}
        />
      )
  }
];

const SudoUsers = () => {
  const dispatch = useDispatch();
  const {
    loading,
    sudoUsers,
    paging: {total, limit, pages, page},
    sort,
    currentSudoUser,
    allSudoUserFeatureFlags
  } = useSelector((state) => state.adminSudoUsersReducer);
  const [searchField, setSearchField] = useState('');

  const userData = useSelector((state) => state.user.userData);

  const onPageChange = (newPage) => {
    dispatch(PAGE_CHANGE(newPage.selected));
  };

  const closeDialog = () => {
    dispatch(CLEAR_SUDO_USER());
  };

  useEffect(() => {
    dispatch(CLEAR_FILTERS());
  }, []);

  useEffect(() => {
    dispatch(GET_SUDO_USERS({page, search: searchField, sort}));
  }, [page]);

  const onSearchClick = () => {
    dispatch(SEARCH_CHANGE(searchField));
    dispatch(GET_SUDO_USERS({page, search: searchField, sort}));
  };

  const updateSudoUser = (id, data) => {
    dispatch(UPDATE_SUDO_USER({id, data}));
    setSearchField('');
  };

  return (
    <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
      <Headline className='marginBottomXs'>Sudo Users</Headline>
      <FlexContainer>
        <FlexItem flex='1 1 auto' className='marginRightXs'>
          <SearchField
            className='marginBottomXs'
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && onSearchClick()}
          />
        </FlexItem>
        <FlexItem>
          <ButtonOutline
            className={cn('ButtonMedium', {disabled: !searchField})}
            onClick={onSearchClick}
          >
            Search
          </ButtonOutline>
        </FlexItem>
      </FlexContainer>
      {loading ? (
        <Loading />
      ) : (
        <MainPaper>
          {total ? (
            <Table
              tableModel={getTableModel(dispatch, userData?.id)}
              data={sudoUsers}
              rowKey='_id'
              sortBy={sort.sortBy}
              sortDirection={sort.sortDirection}
            />
          ) : (
            <div>There&apos;s no merchants here</div>
          )}

          <TablePagination>
            <TablePaginationText>
              Showing {limit > total ? total : limit} out of {total} data
            </TablePaginationText>
            <ReactPaginate
              previousLabel='previous'
              nextLabel='next'
              breakLabel='...'
              breakClassName='break-me'
              pageCount={pages}
              initialPage={page}
              forcePage={page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={onPageChange}
              containerClassName='pagination'
              subContainerClassName='pages pagination'
              activeClassName='active'
            />
          </TablePagination>
        </MainPaper>
      )}
      {currentSudoUser && (
        <EditSudoUserModal
          onClose={closeDialog}
          currentSudoUser={currentSudoUser}
          allSudoUserFeatureFlags={allSudoUserFeatureFlags}
          allowedFeatures={userData?.AllowedFeatures}
          updateUser={updateSudoUser}
        />
      )}
    </DashboardContent>
  );
};

export default SudoUsers;
