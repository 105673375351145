import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import IconExit from '../../../../../assets/iconExit.svg';
import IconCloseWhite from '../../../../../assets/icon-close-white.svg';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 272px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 10px rgba(108, 49, 151, 0.08),
    0px 10px 20px rgba(108, 49, 151, 0.06),
    0px 30px 30px rgba(108, 49, 151, 0.04);
  z-index: 12;
  transition-property: transform;
  transition-duration: 0.3s;

  @media (max-width: 1250px) {
    transform: translateX(-100%);

    &.isOpen {
      transform: translateX(0);
      .SidebarOverlay {
        transition-delay: 0.3s;
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const SidebarContainer = styled.div`
  display: block;
  position: relative;
  flex: 1 1 auto;
  z-index: 2;
  padding: 50px 19px 0 19px;
  overflow-y: auto;
  background-color: #fff;
  @media (max-width: 1440px) {
    padding-top: 35px;
  }
  @media (max-width: 767px) {
    padding: 35px 15px 0 15px;
  }
`;

export const SidebarOverlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('${IconCloseWhite}');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: left 300px top 32px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
`;

export const SidebarTitle = styled.div`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.24em;
  color: #8b92a5;
  opacity: 0.5;
  padding-left: 21px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

export const Logo = styled.a`
  display: block;
  margin: 0 0 35px 25px;
  flex: 0 0 auto;
  @media (max-width: 1440px) {
    margin-bottom: 28px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  img {
    width: 100%;
    max-width: 152px;
  }
`;

export const SidebarHolder = styled.div`
  display: block;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SidebarMenu = styled.ul`
  display: block;
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const SidebarMenuItem = styled.li`
  display: block;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SidebarMenuButton = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #929cb9;
  background-color: transparent;
  padding: 12px 12px 12px 26px;
  border-radius: 12px;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  i {
    display: inline-block;
    margin-right: 11px;
    transition-property: fill, stroke;
    transition-duration: 0.3s;
  }
  svg {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    top: -1px;
    width: 16px;
    path {
      transition-property: fill, stroke;
      transition-duration: 0.3s;
    }
  }
  &:hover {
    color: #232d3a;
    background-color: #f2f1f6;
    svg {
      path {
        fill: #ff2d38;
      }
    }
    i {
      color: #ff2d38;
    }
  }
  &.isActive {
    color: #232d3a;
    font-weight: 700;
    background-color: #f2f1f6;
    i {
      color: #ff2d38;
    }
    svg {
      path {
        fill: #ff2d38;
      }
    }
  }
`;
export const SidebarMenuButtonRedirect = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #929cb9;
  background-color: transparent;
  padding: 12px 12px 12px 26px;
  border-radius: 12px;
  transition-property: color, background-color;
  transition-duration: 0.3s;
  @media (max-width: 767px) {
    font-size: 14px;
  }
  i {
    display: inline-block;
    margin-right: 11px;
    transition-property: fill, stroke;
    transition-duration: 0.3s;
  }
  svg {
    display: inline-block;
    margin-right: 15px;
    width: 16px;
    path {
      transition-property: fill, stroke;
      transition-duration: 0.3s;
    }
  }
  &:hover {
    color: #232d3a;
    background-color: rgba(23, 201, 129, 0.1);
    svg {
      path {
        fill: #17c981;
      }
    }
    i {
      color: #17c981;
    }
  }
  &.isActive {
    color: #232d3a;
    font-weight: 700;
    background-color: rgba(23, 201, 129, 0.1);
    i {
      color: #17c981;
    }
    svg {
      path {
        fill: #17c981;
      }
    }
  }
`;

export const SidebarProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 26px 18px 26px 20px;
  position: relative;
  z-index: 2;
  background-color: #fff;
  @media (max-width: 767px) {
    padding: 20px 15px;
  }
`;
export const SidebarProfilePhoto = styled.img`
  flex: 0 0 auto;
  width: 54px;
  height: 54px;
  background-color: #ccc;
  border-radius: 4px;
  object-fit: cover;
  object-position: center top;
`;

export const SidebarProfileInfo = styled.div`
  flex: 1 1 auto;
  padding-left: 14px;
  position: relative;
  max-width: 200px;
`;

export const SidebarProfileName = styled.div`
  display: block;
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  color: #2f3d48;
  padding-right: 30px;

  span {
    display: inline-block;
  }
`;

export const SidebarProfilePicture = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background-color: #fff;
  color: #2f3d48;
  object-fit: cover;
  object-position: center top;
  font-weight: 500;
  font-size: 24px;
  border: 2px solid #929cb9;
`;

export const SidebarProfileLogoutButton = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-15px);
  right: 0;
  width: 30px;
  height: 30px;
  background-image: url('${IconExit}');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  cursor: pointer;
  transition-property: opacity;
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

export const SidebarProfilePosition = styled.div`
  display: block;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 1;
  color: #2f3d48;
  word-wrap: break-word;
  padding-right: 30px;
  &:first-letter {
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    font-size: 11px;
  }
`;
