import styled from 'styled-components';
import IconSelect from '../../assets/icon-dropdown-dark.svg';

const SelectWrapper = styled.div`
  &.w50 {
    width: calc(50% - 20px);
    max-width: 100%;
    @media (max-width: 767px) {
      width: 100%;
    }
    .MuiInput-root {
      width: 100%;
    }
  }

  &.SelectWrapperPTop {
    padding: 10px 0 0 0;
  }

  .MuiFormControl-root {
    background: #f7f7fa;
    border: 0.5px solid #d5d8de;
    border-radius: 6px;
    padding: 12px;
    width: 380px;
    @media (max-width: 1000px) {
      width: 360px;
      padding: 4px 12px;
    }
    @media (max-width: 479px) {
      width: 100%;
    }
  }

  @media (max-width: 1250px) {
    width: 100%;
  }
  .MuiInputBase-input {
    padding: 0;
    cursor: pointer;
    input {
      cursor: pointer;
    }
  }
  .MuiInputBase-root {
    &::before {
      display: none;
    }
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #232d3a;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #172751 !important;
  }
  .MuiSelect-nativeInput {
    border: 0;
  }
  .MuiSelect-select:focus {
    background: transparent;
  }
  .MuiSelect-icon {
    background-image: url('${IconSelect}');
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    right: 12px;
    path {
      display: none;
    }
  }

  .MuiInput-underline {
    &::after {
      display: none;
    }
  }

  .MuiNativeSelect-selectMenu {
    color: white;
  }

  &.SelectMd {
    .MuiFormControl-root {
      padding: 0;
      .MuiSelect-select {
        padding: 16px 19px;
      }
      .error {
        border: 1px solid #ba0302;
        border-radius: 6px;
      }
    }
  }

  &.w100 {
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .full-with-select {
    width: 100%;
    padding: 0;
    margin-top: 30px;
    .MuiInputBase-input {
      padding: 15px;
    }
  }
  &.AdjustReasonSelect {
    .MuiInput-root {
      width: 100%;
    }
  }
`;

export default SelectWrapper;
