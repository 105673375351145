import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {useFormikContext} from 'formik';
import SelectRow from './SelectRow';
import {DashboardTime} from '../../dashboard/styles';
import {generateTimeRanges} from '../helpers';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {isValueObjChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';

const DaysPickerTimeSelect = ({timePeriods, day}) => {
  const [timeRanges, setTimeRanges] = useState(generateTimeRanges(15, 'en'));
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();

  const removeTimePeriods = (periodId) => {
    const arr = [...openingDays];
    const elementId = arr.findIndex((d) => d.id === day.id);
    arr[elementId].timePeriods = arr[elementId].timePeriods.filter(
      (d) => d.id !== periodId
    );
    setOpeningDays(arr);

    setFieldValue(
      'OpeningHoursChanged',
      isValueObjChanged(OpeningHoursChanged, periodId)
    );
  };

  const entryCheck = (arr1, arr2) => {
    return !arr1.find((elem) => {
      return arr2.indexOf(elem) === -1;
    });
  };

  useEffect(() => {
    const nextIntervals = generateTimeRanges(15, 'en');

    timePeriods.forEach((period) => {
      timePeriods.forEach((mapPeriod) => {
        if (
          mapPeriod.id !== period.id &&
          mapPeriod.hoursIntervals.length &&
          entryCheck(mapPeriod.hoursIntervals, period.hoursIntervals)
        ) {
          removeTimePeriods(mapPeriod.id);
        }
      });
      period.hoursIntervals.forEach((interval) => {
        if (Object.prototype.hasOwnProperty.call(nextIntervals, interval)) {
          nextIntervals[interval] = true;
        }
      });
    });

    setTimeRanges(nextIntervals);
  }, [day, openingDays, timePeriods]);

  return (
    <>
      {day.timePeriods.map((period) => (
        <DashboardTime className='mLeft marginB15' key={`period-${period.id}`}>
          <SelectRow period={period} timeRanges={timeRanges} day={day} />
        </DashboardTime>
      ))}
    </>
  );
};

DaysPickerTimeSelect.propTypes = {
  timePeriods: T.array.isRequired,
  day: T.object.isRequired
};

export default DaysPickerTimeSelect;
