import React from 'react';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {DashboardContent} from '../../../../../components/DashboardContent/styles';
import LinkBack from '../../../../../components/LinkBack/styles';
import {DashboardHeader} from '../../../../../components/DashboardHeader/styles';
import {
  DashboardField,
  DashboardFields,
  FieldInput,
  FieldLabel
} from '../../../dashboard/styles';
import Body3 from '../../../../../components/typography/Body3';
import Headline from '../../../../../components/Headline/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import {BankAccountForm} from '../../styles';
import history from '../../../../../history';
import BankAccountSchema from '../../../../../helpers/services/formValidationSchemas/BankAccountSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {PATCH_USER_BANK_INFO} from '../../../../../store/accountVerifyBankAccount/action';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';

const BankAccount = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      BusinessLegalName: '',
      BankAccountNumber: '',
      VerifyBankAccount: '',
      RoutingNumber: ''
    },
    validationSchema: BankAccountSchema,
    onSubmit: (values, {resetForm}) => {
      dispatch(PATCH_USER_BANK_INFO({bankInfo: values}));
      resetForm();
    }
  });

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader className='whiteBgTablet'>
        <LinkBack onClick={() => history.push('/my-account')}>
          Back to My account
        </LinkBack>
      </DashboardHeader>
      <DashboardInner className='paddingLg'>
        <Headline className='Sm marginBottomSm'>
          Verify your bank account
        </Headline>
        <Body3>It is a long established fact that a reader</Body3>
        <BankAccountForm>
          <DashboardFields className='AlignCenter DashboardFields'>
            <DashboardField className='w100'>
              <FieldLabel>Business legal name</FieldLabel>
              <FieldInput
                className={
                  formik.errors.BusinessLegalName &&
                  formik.touched.BusinessLegalName &&
                  'error'
                }
                name='BusinessLegalName'
                value={formik.values.BusinessLegalName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Business legal name'
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.BusinessLegalName &&
                    formik.errors.BusinessLegalName}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100'>
              <FieldLabel>Bank account number</FieldLabel>
              <FieldInput
                className={
                  formik.errors.BankAccountNumber &&
                  formik.touched.BankAccountNumber &&
                  'error'
                }
                name='BankAccountNumber'
                value={formik.values.BankAccountNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Bank account number'
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.BankAccountNumber &&
                    formik.errors.BankAccountNumber}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100'>
              <FieldLabel>Verify bank account</FieldLabel>
              <FieldInput
                className={
                  formik.errors.VerifyBankAccount &&
                  formik.touched.VerifyBankAccount &&
                  'error'
                }
                name='VerifyBankAccount'
                value={formik.values.VerifyBankAccount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Verify bank account'
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.VerifyBankAccount &&
                    formik.errors.VerifyBankAccount}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100 marginBottomXl'>
              <FieldLabel>Routing number</FieldLabel>
              <FieldInput
                className={
                  formik.errors.RoutingNumber &&
                  formik.touched.RoutingNumber &&
                  'error'
                }
                name='RoutingNumber'
                value={formik.values.RoutingNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Routing number'
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.RoutingNumber && formik.errors.RoutingNumber}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100 justifyCenter'>
              <ButtonPrimary
                onClick={formik.handleSubmit}
                className='DashboardFieldButton'
              >
                <span>Apply</span>
              </ButtonPrimary>
            </DashboardField>
          </DashboardFields>
        </BankAccountForm>
      </DashboardInner>
    </DashboardContent>
  );
};

export default BankAccount;
