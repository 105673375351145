import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import FeedbackContainer from './FeedbackContainer';

const FeedbackRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/`} component={FeedbackContainer} />
    </Switch>
  );
};

export default FeedbackRoutes;
