import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TeamTable from './TeamTable';
import {MembersHeadCells} from '../../../../../../helpers/constants/Table';
import {
  DELETE_USER,
  GET_USERS
  // RESET_PASSWORD
} from '../../../../../../store/usersManagement/action';
import Pagination from './Pagination';

// eslint-disable-next-line react/prop-types
const Team = ({onEdit, onResetPassword, onDelete}) => {
  const dispatch = useDispatch();
  const [searchModifiers, setSearchModifiers] = useState({limit: 8});
  const mergeModifiers = (value) =>
    setSearchModifiers({...searchModifiers, ...value});
  const {userData} = useSelector((state) => state.user);

  const {loading, data} = useSelector((state) => state.usersManagementReducer);

  useEffect(() => {
    if (userData) dispatch(GET_USERS({limit: 8}));
  }, [JSON.stringify(searchModifiers)]);

  return (
    <>
      <TeamTable
        currentUserId={userData.id}
        loading={loading}
        onSort={mergeModifiers}
        searchModifiers={searchModifiers}
        items={data}
        headCells={MembersHeadCells}
        onEdit={onEdit}
        onDelete={onDelete}
        // onResetPassword={(user) => dispatch(RESET_PASSWORD(user))}
        onResetPassword={onResetPassword}
      />

      <Pagination />
    </>
  );
};

export default Team;
