export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const mapProps = {
  center: {
    lat: 32.06,
    lng: 34.77
  },
  zoom: 10
};

export const selectedDaysArray = () => ({
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
  Sun: false
});
