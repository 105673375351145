import styled from 'styled-components';

const DiscountsTableWrapper = styled.div`
  .MuiTableCell-head {
    &.headTableCell {
      padding-top: 23px;
      padding-bottom: 23px;
    }
  }

  .MuiTable-root {
    border-collapse: separate;

    .MuiTableRow-root td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .MuiTableRow-root td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr {
      &:first-child {
        th {
          white-space: pre;
          &:nth-child(1) {
            width: 17%;
          }

          &:nth-child(2) {
            width: 17%;
          }

          &:nth-child(3) {
            width: 17%;
          }

          &:nth-child(4) {
            width: 17%;
          }

          &:nth-child(5) {
            width: 17%;
          }

          &:nth-child(6) {
            width: 17%;
          }

          &:nth-child(7) {
            text-align: right;
          }
        }
      }
    }
  }
  .IconPercentType {
    height: 19px;
    svg {
      height: 100%;
    }
  }
  .IconDollarType {
    height: 22px;
    svg {
      height: 100%;
    }
  }
  .PopperAction {
    height: 24px;
  }
`;

export default DiscountsTableWrapper;
