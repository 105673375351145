import {createAction} from 'redux-act';

export const GET_APP_VERSION = createAction('appVersion/GET_LOCATION_DATA');

export const GET_APP_VERSION_SUCCESS = createAction(
  'appVersion/GET_APP_VERSION_SUCCESS'
);

export const CHANGED_APP_VERSION_MODAL = createAction(
  'appVersion/CHANGED_APP_VERSION_MODAL'
);
