import React from 'react';

const IconPin = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.7111 6.87347L17.019 0.181369C16.7775 -0.0604563 16.3852 -0.0604563 16.1436 0.181369L16.1153 0.209724C15.7049 0.619948 15.4788 1.16563 15.4788 1.74586C15.4788 2.11968 15.5738 2.4784 15.7502 2.7965L8.74807 8.80597C8.21217 8.32702 7.52818 8.06489 6.80369 8.06489C6.02299 8.06489 5.28909 8.36888 4.73722 8.92088L4.69524 8.96285C4.45342 9.20455 4.45342 9.59658 4.69524 9.83828L8.70437 13.8474L4.79975 17.7519C4.72162 17.8323 2.87344 19.7368 1.65838 21.2523C0.501255 22.6953 0.272431 22.9596 0.260544 22.9732C0.0457124 23.2179 0.0575994 23.5869 0.287538 23.8179C0.408017 23.9389 0.566881 24.0001 0.72624 24.0001C0.871484 24.0001 1.0171 23.9493 1.13424 23.8469C1.14451 23.838 1.40281 23.6135 2.85512 22.4491C4.37046 21.234 6.27497 19.3857 6.36152 19.3014L10.2598 15.4031L14.0542 19.1975C14.1751 19.3185 14.3336 19.3789 14.4919 19.3789C14.6503 19.3789 14.8089 19.3185 14.9297 19.1975L14.9716 19.1555C15.5236 18.6037 15.8276 17.8696 15.8276 17.0891C15.8276 16.3646 15.5654 15.6806 15.0865 15.1447L21.096 8.14253C21.4141 8.31898 21.7728 8.41395 22.1466 8.41395C22.727 8.41395 23.2726 8.18797 23.6828 7.7775L23.7111 7.74915C23.953 7.5072 23.953 7.11518 23.7111 6.87347Z'
        fill='#929CB9'
      />
    </svg>
  );
};

export default IconPin;
