import styled from 'styled-components';

const Caption5 = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #2f3d48;
  &.CaptionMobSmall {
    @media (max-width: 599px) {
      font-size: 18px;
    }
  }
`;

export default Caption5;
