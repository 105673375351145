import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog} from '@material-ui/core';
import DialogBody from '../../../../components/DialogBody/styles';
import OnlineModalGif from '../../../../assets/modal-online.gif';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {DialogAction} from '../../dashboard/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import {SuccessImage} from '../../account/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import Body4 from '../../../../components/typography/Body4';
import {OPEN_ONLINE_MODAL} from '../../../../store/global/globalNotifications/action';

const OnlineModal = () => {
  const [onlineGif, setOnlineGif] = useState(null);
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.globalNotificationsReducer.isOnlineModalOpen
  );
  const onClose = () => {
    dispatch(OPEN_ONLINE_MODAL(false));
  };

  useEffect(() => {
    setOnlineGif(OnlineModalGif);
    if (!isOpen) setOnlineGif(null);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} scroll='body' onClose={onClose}>
      <DialogContainer className='DialogM'>
        <DialogClose onClick={onClose} />
        <DialogBody>
          <DialogTitle className='marginBottomXs'>
            You‘re back online now!
          </DialogTitle>
          <SuccessImage src={onlineGif} />
          <Body4 className='text-center'>
            If any new orders were placed while You&#039;re offline,
            <br />
            <b>they are now reflected in your Orders.</b>
          </Body4>
          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>Yay!</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default OnlineModal;
