import {put, select, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  getLocationNotificationsApi,
  patchLocationApi,
  postTestOrder,
  putOrderApi,
  getOrderApi,
  getCloudPrintConfigurationsApi,
  updateCloudPrintConfigurationsApi
} from '../../../helpers/services/api/orders';
import {
  CHANGE_ORDER_INFO,
  GET_ORDER,
  GET_ORDERS,
  SET_NEXT_PAGE,
  CHANGE_STATUS_HISTORY,
  CHANGE_STATUS_HISTORY_SUCCESS,
  GET_LOCATION_ID_DATA,
  GET_LOCATION_ID_DATA_SUCCESS,
  PATCH_LOCATION_ACTION,
  SEND_TEST_ORDER,
  SEND_TEST_ORDER_SUCCESS,
  REFRESH_ORDERS,
  SET_ORDERS,
  GET_ORDER_SUCCESS,
  SET_ORDERS_EMPTY,
  TURN_OFF_COMPLETED_NOTIFICATION,
  OPEN_STATUS_CHANGE_MODAL,
  GET_CLOUD_PRINT_CONFIGURATIONS_SUCCESS,
  GET_CLOUD_PRINT_CONFIGURATIONS,
  UPDATE_CLOUD_PRINT_CONFIGURATIONS
} from './action';
import {
  ORDER,
  ORDER_CANCELLATION_STATUS,
  ORDER_STATUS,
  ORDER_TYPE,
  ORDER_TYPES
} from '../../../helpers/constants/orders';
import {
  API_ORDER_INFO,
  API_ORDERS
} from '../../../helpers/services/constants/API_ENDPOINTS';
import {request} from '../../../helpers/services/utils/request';
import {GET, PUT} from '../../../helpers/services/constants/API_CONSTANTS';
import {GET_USER_DATA} from '../../global/user/action';
import {
  RUN_GET_ACTIVE_ORDERS_JOB,
  STOP_GET_ACTIVE_ORDERS_JOB
} from '../../global/orders/action';
import userId from '../../global/user/selectors';
import {patchUsersApi} from '../../../helpers/services/api/user';
import camelize from '../../../helpers/functionUtils/camelize';
import {OPEN_CANCELLATION_REFUND_MODAL} from '../../global/orders/CancellationRefund/action';
import {
  OPEN_CANCELLATION_CONFIRMATION_MODAL,
  SET_LOADING_CANCELLATION
} from '../../global/modals/action';

export function* getOrders() {
  try {
    const {
      orders,
      filter: {
        startDate,
        endDate,
        orderChannels,
        orderTypes,
        orderStatus,
        orderMiscMany,
        orderPaymentsMethods
      },
      page,
      limit,
      sorting: {sort, sortBy}
    } = yield select((state) => state.ordersHistoryReducer);
    const {Timezone} = yield select((state) => state.user.activeLocation);

    // const {newOrders} = yield select(
    //   (state) => state.globalNotificationsReducer
    // );

    if (Object.values(orderStatus).length === 0) {
      yield put(SET_ORDERS_EMPTY());
      return false;
    }

    const {data} = yield request(
      GET,
      API_ORDERS({
        ...(startDate && {startDate}),
        ...(endDate && {endDate}),
        page,
        orderChannels: orderChannels?.join(',') || '',
        orderTypes:
          orderTypes
            ?.map((type) =>
              type === ORDER_TYPES[ORDER_TYPE.SAUCE_DISPATCH]
                ? camelize(ORDER_TYPE.SAUCE_DISPATCH)
                : camelize(type)
            )
            .join(',') || '',
        orderStatuses: orderStatus?.join(',') || 'All',
        orderMisc: orderMiscMany?.join(',') || '',
        orderPaymentsMethods: orderPaymentsMethods?.join(',') || '',
        sort,
        sortBy,
        limit
      })
    );

    if (data?.docs) {
      yield put(SET_ORDERS({data: [...orders, ...data.docs], Timezone}));
      yield put(SET_NEXT_PAGE(data));
    }

    return data;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return null;
  }
}

export function* changeStatus({payload: {id, status, previousStatus}}) {
  try {
    const completedOrderNotification = yield select(
      (state) => state.user?.userData?.ShowCompletedOrderNotification
    );

    const {data} = yield putOrderApi(id, {
      [ORDER.ORDER_STATUS]: status,
      disableOverdue: previousStatus === 'Overdue'
    });
    if (data[ORDER.ID]) {
      toast.success('Order status was changed!');
      if (
        previousStatus !== 'Completed' &&
        status === 'Completed' &&
        completedOrderNotification
      ) {
        yield put(OPEN_STATUS_CHANGE_MODAL(true));
      }
    }
    return data;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

export function* changeStatusHistory({
  payload: {id, status, previousStatus},
  orderId = null
}) {
  try {
    yield put(SET_LOADING_CANCELLATION(true));
    const data = yield changeStatus({payload: {id, status, previousStatus}});

    if (data && data[ORDER.ID]) {
      if (
        data[ORDER.ORDER_STATUS] === 'New' ||
        data[ORDER.ORDER_STATUS] === 'Accepted'
      ) {
        yield put(CHANGE_STATUS_HISTORY_SUCCESS({[ORDER.ID]: data[ORDER.ID]}));
      } else if (data[ORDER.ORDER_STATUS] === ORDER_STATUS.CANCELLED) {
        yield put(
          OPEN_CANCELLATION_CONFIRMATION_MODAL({
            isOpen: false,
            info: null,
            source: null,
            deliveryId: null
          })
        );
        yield put(
          OPEN_CANCELLATION_REFUND_MODAL({
            isOpenModal: true,
            invoiceId:
              !data[ORDER.REFUND] ||
              !data[ORDER.REFUND]?.find((el) => el.type === 'Full')
                ? data[ORDER.INVOICE_ID]
                : null,
            showCannotDeliveryText:
              data[ORDER.ORDER_CANCELLATION_STATUS] &&
              data[ORDER.ORDER_CANCELLATION_STATUS] !==
                ORDER_CANCELLATION_STATUS.CANCELLED_SUCCESSFULLY,
            orderId
          })
        );
        yield put(
          CHANGE_STATUS_HISTORY_SUCCESS({
            [ORDER.ORDER_STATUS]: data[ORDER.ORDER_STATUS],
            [ORDER.ID]: data[ORDER.ID]
          })
        );
      } else {
        yield put(
          CHANGE_STATUS_HISTORY_SUCCESS({
            [ORDER.ORDER_STATUS]: data[ORDER.ORDER_STATUS],
            [ORDER.ID]: data[ORDER.ID]
          })
        );
      }
    }
    yield put(SET_LOADING_CANCELLATION(false));
    return data[ORDER.ORDER_STATUS];
  } catch (error) {
    yield put(SET_LOADING_CANCELLATION(false));
    console.log('SAGA ERROR', error);
    return false;
  }
}

export function* refreshOrders(newOrders) {
  try {
    const {
      filter: {
        startDate,
        endDate,
        orderChannels,
        orderTypes,
        orderStatus,
        orderMiscMany
      },
      total,
      limit,
      sorting: {sort, sortBy}
    } = yield select((state) => state.ordersHistoryReducer);

    const {Timezone} = yield select((state) => state.user.activeLocation);

    const {data} = yield request(
      GET,
      API_ORDERS({
        startDate,
        endDate,
        page: 0,
        orderChannels,
        orderTypes,
        orderMisc: orderMiscMany,
        orderStatuses: orderStatus?.join(',') || 'All',
        sort,
        sortBy,
        limit: (total || limit) + newOrders.length
      })
    );

    if (data?.docs?.length > 0) {
      yield put(SET_ORDERS({data: data.docs, Timezone}));
    }

    return data;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return null;
  }
}

export function* getLocationNotificationsById() {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const id = yield select(userId);

    const {data} = yield getLocationNotificationsApi(locationId, id);

    if (data) {
      yield put(GET_LOCATION_ID_DATA_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getCloudPrintConfigurationsById() {
  try {
    const locationId = yield select((state) => state.user.locationId);

    const {data} = yield getCloudPrintConfigurationsApi(locationId);

    if (data) {
      yield put(GET_CLOUD_PRINT_CONFIGURATIONS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* updateCloudPrintConfigurationsById({
  payload: {values, onSuccess}
}) {
  try {
    const locationId = yield select((state) => state.user.locationId);

    const response = yield updateCloudPrintConfigurationsApi(
      locationId,
      values
    );

    if (response.status === 204) {
      toast.success('Cloud Print Configurations has been updated successfully');
      if (onSuccess) onSuccess();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* patchLocation({payload: {data, actions}}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const id = yield select(userId);

    yield patchLocationApi({locationId, id, data});
    actions();
    toast.success('The update was successful!');
    yield put(GET_LOCATION_ID_DATA());
    yield put(GET_USER_DATA());
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* sendTestOrder() {
  try {
    yield postTestOrder();
    yield put(SEND_TEST_ORDER_SUCCESS());
    yield put(STOP_GET_ACTIVE_ORDERS_JOB());
    yield put(RUN_GET_ACTIVE_ORDERS_JOB());
    toast.success('The test order has been created!');
  } catch (error) {
    yield put(SEND_TEST_ORDER_SUCCESS());
    console.log('SAGA ERROR', error);
  }
}

export function* changeInfo({payload: {id, note}}) {
  try {
    const res = yield request(PUT, API_ORDER_INFO(id), {data: {note}});

    if (res.status === 200) {
      toast.success('Order notes were changed!');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getOrder({payload: {id, successCb}}) {
  try {
    const {data} = yield getOrderApi(id);

    if (data) {
      yield put(GET_ORDER_SUCCESS(data));
      successCb();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* turnOffCompletedStatusNotification({payload}) {
  try {
    const id = yield select((state) => state.user.userData.id);

    const {status} = yield patchUsersApi(id, {
      ShowCompletedOrderNotification: payload
    });

    if (status === 204) {
      yield put(GET_USER_DATA());
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

// const candidateForOverdue = (order) => {
//   return [ORDER_STATUS.NEW, ORDER_STATUS.ACCEPTED, 'Test'].includes(
//     order.orderStatus
//   );
// };
//
// export function* checkOverdueJob() {
//   while (true) {
//     const {orders} = yield select((state) => state.ordersHistoryReducer);
//     const {candidatesForOverdue} = yield select(
//       (state) => state.globalNotificationsReducer
//     );
//     const ordersName = [];
//     for (let i = 0; i < orders.length; i += 1) {
//       const order = orders[i];
//       if (
//         !candidatesForOverdue.includes(order.id) &&
//         candidateForOverdue(order) &&
//         isAfter(new Date(), new Date(order.expiredTime))
//       ) {
//         yield put(ADD_CANDIDATE_FOR_OVERDUE(order.id));
//         ordersName.push(order.userName);
//       }
//     }
//
//     if (ordersName.length) {
//       if (ordersName.length > 1) {
//         toast.warn(
//           `Orders for clients: ${ordersName.join(', ')} will soon overdue`
//         );
//       } else toast.warn(`Order for ${ordersName[0]} will soon overdue`);
//     }
//
//     const overdueOrders = orders.filter((order) => {
//       if (
//         candidateForOverdue(order) &&
//         isAfter(new Date(), new Date(order.date))
//       ) {
//         return true;
//       }
//       return false;
//     });
//
//     if (overdueOrders.length > 0) {
//       yield put(SET_ORDERS(orders));
//     }
//
//     yield delay(CHECK_OVERDUE_INTERVAL);
//   }
// }
//
// export function* runCheckOverdueJob() {
//   const {stop} = yield race({
//     job: call(checkOverdueJob),
//     stop: take(STOP_CHECK_OVERDUE_JOB)
//   });
//
//   if (stop) {
//     yield cancel();
//   }
// }

function* orderListSaga() {
  yield takeEvery(GET_ORDERS, getOrders);
  yield takeEvery(CHANGE_STATUS_HISTORY, changeStatusHistory);
  yield takeEvery(REFRESH_ORDERS, refreshOrders);
  yield takeEvery(GET_LOCATION_ID_DATA, getLocationNotificationsById);
  yield takeEvery(PATCH_LOCATION_ACTION, patchLocation);
  yield takeEvery(SEND_TEST_ORDER, sendTestOrder);
  yield takeEvery(CHANGE_ORDER_INFO, changeInfo);
  yield takeEvery(GET_ORDER, getOrder);
  yield takeEvery(
    TURN_OFF_COMPLETED_NOTIFICATION,
    turnOffCompletedStatusNotification
  );
  yield takeEvery(
    GET_CLOUD_PRINT_CONFIGURATIONS,
    getCloudPrintConfigurationsById
  );
  yield takeEvery(
    UPDATE_CLOUD_PRINT_CONFIGURATIONS,
    updateCloudPrintConfigurationsById
  );
}

export default orderListSaga;
