/* eslint-disable */
import React, {Component} from 'react';
import {connect, useSelector} from 'react-redux';
import {
  SET_SEARCH_VALUE,
  SEARCH_IN_TREE,
  SET_ACTIVE_TREE_LOADING
} from '../../../../store/menuEditor/action';
import Field from '../../../../components/Field/styles';
import SearchPanel from '../../../../components/SeachPanel/styles';
import ButtonsPanel from '../ButtonPanel';
import ButtonClear from '../../../../components/buttons/ButtonClear/styles';

class SearchBlock extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      value: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      this.state.value !== nextState.value &&
      this.props.activeTab === nextProps.activeTab
    ) {
      this.searchInTree(nextState.value);
    }

    if (
      this.props.searchValue !== nextProps.searchValue &&
      this.props.activeTab === nextProps.activeTab
    ) {
      this.props.SEARCH_IN_TREE(nextProps.searchValue);
    }

    if (this.props.activeTab !== nextProps.activeTab) {
      this.setState({value: ''});
    }

    return true;
  }

  searchInTree = (value) => {
    clearTimeout(this.timeout);
    const searchString = value.trim();

    if (searchString && searchString.length < 3) {
      return;
    }

    this.timeout = setTimeout(() => {
      this.props.SET_SEARCH_VALUE(searchString);
    }, 500);
  };

  render() {
    return (
      <SearchPanel>
        <Field className='SearchPanelInput withClear'>
          <input
            className='FieldSearch Outline BgGrey'
            type='search'
            placeholder='Search by Categories, Items, Modifiers'
            onChange={(event) => {
              this.setState({value: event.target.value});
            }}
            value={this.state.value}
          />
          {this.state.value && (
            <ButtonClear
              className='ButtonClear'
              onClick={() => {
                this.setState({value: ''});
              }}
            />
          )}
        </Field>
        <ButtonsPanel />
      </SearchPanel>
    );
  }
}

export default connect(
  (store) => ({
    searchValue: store.menuEditorReducer.searchValue,
    activeTab: store.menuEditorReducer.activeTab
  }),
  {
    SET_SEARCH_VALUE,
    SEARCH_IN_TREE,
    SET_ACTIVE_TREE_LOADING
  }
)(SearchBlock);
