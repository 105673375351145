import React from 'react';
import {useFormikContext} from 'formik';
import {nanoid} from 'nanoid';
import cn from 'classnames';
import {Wrapper} from '../../settings/styles';
import DayItem from './DayItem';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {DialogFieldCopy, DialogFieldRemove} from '../../dashboard/styles';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';

const DaysPicker = () => {
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();

  const removeDay = (id) => {
    setOpeningDays((prevState) => prevState.filter((d) => d.id !== id));

    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, id)
    );
  };

  const duplicateDay = (day) => {
    const newDay = {
      ...day,
      id: nanoid(),
      days: [...day.days],
      timePeriods: [{...day.timePeriods[0], id: nanoid()}]
    };
    setOpeningDays((prevState) => [...prevState, newDay]);
  };
  return (
    <>
      {openingDays?.length > 0 &&
        openingDays?.map((day) => (
          <FlexContainer className='w100 align-center marginBottomSm'>
            <Wrapper className='w100 mBottom0 smPaddings' key={`day-${day.id}`}>
              <DayItem day={day} />
            </Wrapper>
            <FlexItem className='wAuto marginLeftXs dFlex'>
              <DialogFieldCopy
                className={cn('relative', {
                  disabled: day.error
                })}
                onClick={() => duplicateDay(day)}
              />
              {openingDays?.length > 1 && (
                <DialogFieldRemove
                  className='relative'
                  onClick={() => removeDay(day.id)}
                />
              )}
            </FlexItem>
          </FlexContainer>
        ))}
    </>
  );
};

DaysPicker.propTypes = {};

export default DaysPicker;
