import {createReducer} from 'redux-act';
import {
  GET_LOCATIONS_PHONE_ORDERING,
  GET_LOCATIONS_PHONE_ORDERING_SUCCESS,
  GET_LOCATIONS_PHONE_ORDERING_FAIL,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS,
  TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL,
  UPDATE_PHONE_ORDERING,
  UPDATE_PHONE_ORDERING_SUCCESS,
  UPDATE_PHONE_ORDERING_FAIL,
  TOGGLE_PHONE_ORDERING,
  TOGGLE_PHONE_ORDERING_SUCCESS,
  TOGGLE_PHONE_ORDERING_FAIL,
  OPEN_SETTINGS_MODAL,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS
} from './action';

const initialState = () => ({
  locations: [],
  isLoading: false,
  isOpenSettingsModal: false,
  settings: null
});

/**
 * @param state {*}
 * @param isLoading {boolean}
 * @return {*&{isLoading}}
 */
const loadingState = (state, isLoading) => ({
  ...state,
  isLoading
});

const phoneOrderingReducer = createReducer(
  {
    [GET_LOCATIONS_PHONE_ORDERING]: (state) => loadingState(state, true),
    [GET_LOCATIONS_PHONE_ORDERING_SUCCESS]: (state, payload) => ({
      ...state,
      locations: payload,
      isLoading: false
    }),
    [GET_LOCATIONS_PHONE_ORDERING_FAIL]: (state) => loadingState(state, false),

    [TOGGLE_ALL_LOCATIONS_PHONE_ORDERING]: (state) => loadingState(state, true),
    [TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_SUCCESS]: (state) =>
      loadingState(state, false),
    [TOGGLE_ALL_LOCATIONS_PHONE_ORDERING_FAIL]: (state) =>
      loadingState(state, false),

    [UPDATE_PHONE_ORDERING]: (state) => loadingState(state, true),
    [UPDATE_PHONE_ORDERING_SUCCESS]: (state) => loadingState(state, false),
    [UPDATE_PHONE_ORDERING_FAIL]: (state) => loadingState(state, false),

    [TOGGLE_PHONE_ORDERING]: (state) => loadingState(state, true),
    [TOGGLE_PHONE_ORDERING_SUCCESS]: (state) => loadingState(state, false),
    [TOGGLE_PHONE_ORDERING_FAIL]: (state) => loadingState(state, false),
    [OPEN_SETTINGS_MODAL]: (state, payload) => ({
      ...state,
      isOpenSettingsModal: payload
    }),
    [GET_SETTINGS]: (state) => loadingState(state, true),
    [GET_SETTINGS_SUCCESS]: (state, payload) => ({
      ...state,
      settings: payload,
      isLoading: false
    })
  },
  initialState()
);

export default phoneOrderingReducer;
