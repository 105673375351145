export const clarityIdentify = (merchantName, locationName, locationId) => {
  if (typeof window.clarity === 'function') {
    try {
      if (merchantName) {
        window.clarity('set', 'merchantName', merchantName);
      }
      if (locationName) {
        window.clarity('set', 'locationName', locationName);
      }
      if (locationId) {
        window.clarity('set', 'locationId', locationId);
      }
    } catch (error) {
      console.error('ClarityIdentityError', error);
    }
  }
};

export default clarityIdentify;
