import {createAction} from 'redux-act';

export const GET_ALL_MODIFIER = createAction(
  'menuEditorModifier/GET_ALL_MODIFIER'
);
export const GET_ALL_MODIFIER_SUCCESS = createAction(
  'menuEditorModifier/GET_ALL_MODIFIER_SUCCESS'
);

export const GET_MODIFIER_BY_ID = createAction(
  'menuEditorModifier/GET_MODIFIER_BY_ID'
);
export const GET_MODIFIER_BY_ID_SUCCESS = createAction(
  'menuEditorModifier/GET_MODIFIER_BY_ID_SUCCESS'
);

export const POST_MODIFIER = createAction('menuEditorModifier/POST_MODIFIER');
export const POST_MODIFIER_SUCCESS = createAction(
  'menuEditorModifier/POST_MODIFIER_SUCCESS'
);

export const PATCH_MODIFIER = createAction('menuEditorItem/PATCH_MODIFIER');
export const PATCH_MODIFIER_SUCCESS = createAction(
  'menuEditorItem/PATCH_MODIFIER_SUCCESS'
);

export const UPDATE_MODIFIER = createAction(
  'menuEditorModifier/UPDATE_MODIFIER'
);
export const UPDATE_MODIFIER_SUCCESS = createAction(
  'modifier/UPDATE_MODIFIER_SUCCESS'
);

export const DELETE_MODIFIER = createAction(
  'menuEditorModifier/DELETE_MODIFIER'
);
export const DELETE_MODIFIER_SUCCESS = createAction(
  'modifier/DELETE_MODIFIER_SUCCESS'
);
