import {createAction} from 'redux-act';

export const GET_MENU_QUEUE = createAction('menuQueue/GET_MENU_QUEUE');
export const GET_MENU_QUEUE_SUCCESS = createAction(
  'menuQueue/GET_MENU_QUEUE_SUCCESS'
);
export const SAVE_MENU_QUEUE_ITEM = createAction(
  'menuQueue/SAVE_MENU_QUEUE_ITEM'
);
export const SAVE_MENU_QUEUE_ITEM_SUCCESS = createAction(
  'menuQueue/SAVE_MENU_QUEUE_ITEM_SUCCESS'
);
export const UPDATE_MENU_QUEUE_ITEM = createAction(
  'menuQueue/UPDATE_MENU_QUEUE_ITEM'
);
export const UPDATE_MENU_QUEUE_ITEM_SUCCESS = createAction(
  'menuQueue/UPDATE_MENU_QUEUE_ITEM_SUCCESS'
);
export const DELETE_MENU_QUEUE_ITEM = createAction(
  'menuQueue/DELETE_MENU_QUEUE_ITEM'
);
export const DELETE_MENU_QUEUE_ITEM_SUCCESS = createAction(
  'menuQueue/DELETE_MENU_QUEUE_ITEM_SUCCESS'
);
