import {request} from '../utils/request';
import {GET, POST} from '../constants/API_CONSTANTS';
import {
  GET_MARKETING_STATISTIC,
  POST_CONTACT_FORM_EMAIL_MARKETING
} from '../constants/API_ENDPOINTS';

export const postContactFormApi = async (data) => {
  return request(POST, POST_CONTACT_FORM_EMAIL_MARKETING(), {data});
};

export const getStatisticApi = async (locationId) => {
  return request(GET, GET_MARKETING_STATISTIC(locationId), null, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_MARKETING_API_TOKEN}`
    }
  });
};
