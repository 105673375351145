import {put, takeEvery, select} from 'redux-saga/effects';
import {
  MARKETING_INTEGRATIONS_DISABLE,
  MARKETING_INTEGRATIONS_DISABLE_SUCCESS,
  MARKETING_INTEGRATIONS_ENABLE,
  MARKETING_INTEGRATIONS_GET_INFO,
  MARKETING_INTEGRATIONS_GET_INFO_SUCCESS,
  MARKETING_INTEGRATIONS_SET_LOADING
} from 'store/marketingIntegrations/action';
import {toast} from 'react-toastify';
import {
  disableMarketingIntegration,
  enableMarketingIntegration,
  getMarketingIntegrationsInfo
} from 'helpers/services/api/marketingItegrations';

const convertObjectToString = (obj) => {
  const entries = Object.entries(obj).map(([key, value]) => `${key}=${value}`);
  return entries.join(' ');
};

function* getMarketingIntegrations() {
  try {
    const {locationId} = yield select((state) => state.user);
    const {data} = yield getMarketingIntegrationsInfo(locationId);
    if (data) {
      yield put(MARKETING_INTEGRATIONS_GET_INFO_SUCCESS(data));
    }
  } catch (error) {
    yield put(MARKETING_INTEGRATIONS_SET_LOADING(false));
    console.log('SAGA ERROR', error);
  }
}

function* enableIntegration({
  payload: {
    body: {integration, data},
    postAction
  }
}) {
  try {
    const {locationId} = yield select((state) => state.user);
    const query = convertObjectToString(data);
    const request = yield enableMarketingIntegration(
      locationId,
      integration,
      query
    );
    if (request.status === 200) {
      toast.success(
        `${
          integration === 'google-analytics' ? 'Google Analytics' : 'Meta Pixel'
        } was successfully connected`
      );
      postAction();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* disableIntegration({payload: {integration, postAction}}) {
  try {
    const {locationId} = yield select((state) => state.user);
    const request = yield disableMarketingIntegration(locationId, integration);
    if (request.status === 200) {
      toast.success(
        `${
          integration === 'google-analytics' ? 'Google Analytics' : 'Meta Pixel'
        } was successfully disconnected`
      );
      yield put(
        MARKETING_INTEGRATIONS_DISABLE_SUCCESS(
          integration === 'google-analytics'
            ? {googleTagId: null}
            : {metaPixelId: null}
        )
      );
      postAction();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* marketingIntegrationsSaga() {
  yield takeEvery(MARKETING_INTEGRATIONS_GET_INFO, getMarketingIntegrations);
  yield takeEvery(MARKETING_INTEGRATIONS_ENABLE, enableIntegration);
  yield takeEvery(MARKETING_INTEGRATIONS_DISABLE, disableIntegration);
}

export default marketingIntegrationsSaga;
