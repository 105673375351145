import React from 'react';
import Type from 'prop-types';
import cn from 'classnames';
import StyledInputMask from './style';

function FieldMaskInput({className, ...props}) {
  return <StyledInputMask className={cn('', className)} {...props} />;
}

FieldMaskInput.propTypes = {
  className: Type.string
};

FieldMaskInput.defaultProps = {
  className: ''
};

export default FieldMaskInput;
