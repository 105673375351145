import styled from 'styled-components';

const Headline = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 1.2;
  color: #2f3d48;
  width: 100%;

  @media (max-width: 1250px) {
    font-size: 24px;
  }
  @media (max-width: 599px) {
    display: block;

    button {
      margin-top: 10px;
    }
  }
  &.HeadlineMobileRow {
    @media (max-width: 599px) {
      display: flex;
    }
  }
  &.FlexStart {
    justify-content: flex-start;
  }
`;

export default Headline;
