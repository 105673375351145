import React from 'react';

export const IconItem = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.64609 3.95179L5.29375 1.84632L4.47344 0.418976L0 3.00023V8.16272H1.64609V3.95179Z'
      fill='#929CB9'
    />
    <path
      d='M12.1242 22.1025L8.47656 19.9971L7.65625 21.4244L12.1242 24.0057L16.5922 21.4244L15.7719 19.9971L12.1242 22.1025Z'
      fill='#929CB9'
    />
    <path
      d='M19.7805 0.418976L18.9602 1.84632L22.6079 3.95179V8.16272H24.254V3.00023L19.7805 0.418976Z'
      fill='#929CB9'
    />
    <path
      d='M18.1242 16H6.20943V4.08517H18.1242V16ZM8.22507 13.9777H16.102V6.10082H8.22507V13.9777Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconItem;
