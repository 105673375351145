import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import Modal from 'components/Modals/Modal';
import {DialogAction} from 'routes/App/dashboard/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {
  DELETE_DISCOUNT,
  PUBLISH_DISCOUNTS,
  UPDATE_DISCOUNT
} from 'store/discounts/action';
import {DISCOUNT_STATUS} from 'constants/Discounts';
import {ButtonOutline} from 'components/buttons';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from 'store/global/globalNotifications/action';
import EmailMarketingDeleteDiscountModal from 'routes/App/discounts/DiscountsList/Modals/EmailMarketingDeleteDiscountModal';

const ModalConfirm = ({onCloseAction, actionType, discount}) => {
  const dispatch = useDispatch();

  const [
    isDeleteDisableEmailMarketingDiscountModal,
    setIsDeleteDisableEmailMarketingDiscountModal
  ] = useState(false);

  const publishDiscounts = () => {
    dispatch(
      PUBLISH_DISCOUNTS({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  const deleteDiscount = () => {
    dispatch(
      DELETE_DISCOUNT({
        payload: discount._id,
        postCallback: () => publishDiscounts(),
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
    onCloseAction();
  };

  const disableDiscount = () => {
    // eslint-disable-next-line no-param-reassign
    discount.Status = DISCOUNT_STATUS.history;
    dispatch(
      UPDATE_DISCOUNT({
        payload: {
          id: discount._id,
          discount
        },
        postCallback: () => publishDiscounts(),
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
    onCloseAction();
  };

  const handleSubmit = () => {
    if (discount.EmailMarketing) {
      setIsDeleteDisableEmailMarketingDiscountModal(true);
    } else if (actionType === 'Delete') {
      deleteDiscount();
    } else {
      disableDiscount();
    }
  };

  return (
    <>
      {isDeleteDisableEmailMarketingDiscountModal ? (
        <EmailMarketingDeleteDiscountModal
          isOpen={isDeleteDisableEmailMarketingDiscountModal}
          onCloseAction={onCloseAction}
          discountAction={
            actionType === 'Delete' ? deleteDiscount : disableDiscount
          }
          actionType={actionType}
        />
      ) : (
        <Modal
          onCloseAction={onCloseAction}
          width='500px'
          title={
            <Box
              lineHeight={1.2}
              fontWeight='bold'
              color='#172751'
              textAlign='center'
              fontSize={24}
            >
              Are you sure you want <br /> to{' '}
              {actionType === 'Delete' ? 'delete' : 'disable'} {discount?.Name}{' '}
              discount?
            </Box>
          }
          body={
            <DialogAction className='marginTop50'>
              <ButtonOutline onClick={onCloseAction} className='marginRightSm'>
                <span>Cancel</span>
              </ButtonOutline>
              <ButtonPrimary onClick={handleSubmit}>
                {actionType === 'Delete' ? (
                  <span>Delete</span>
                ) : (
                  <span>Disable</span>
                )}
              </ButtonPrimary>
            </DialogAction>
          }
        />
      )}
    </>
  );
};

ModalConfirm.propTypes = {
  onCloseAction: PropTypes.array.isRequired,
  discount: PropTypes.object.isRequired,
  actionType: PropTypes.string.isRequired
};

export default ModalConfirm;
