import styled from 'styled-components';

const body1 = styled.p`
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #232d3a;

  &.Sm {
    font-size: 14px;
  }

  @media (max-width: 1600px) {
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    font-size: 14px;
  }
`;

export default body1;
