/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Loading from '../shared/Utils/Loading';
import {ButtonPrimary} from '../buttons';
import DialogContainer from '../DialogContainer/styles.js';
import DialogClose from '../DialogClose/styles.js';
import {DialogBody} from './style.js';
import Body1 from '../typography/Body1';

function InfoModal(props) {
  const {isLoading, isOpen, message, btnOkTitle, onOk} = props;

  const onOkClick = () => {
    onOk();
  };

  return (
    <Dialog open={isOpen} scroll='body' onClose={onOkClick}>
      <DialogContainer className='DialogXs ConfirmModal'>
        <DialogClose onClick={onOkClick} />
        <Body1 className='ConfirmModal-Title'>{message}</Body1>
        <DialogBody className='ConfirmModal-BtnPanel'>
          {isLoading && <Loading />}
          {!isLoading && (
            <ButtonPrimary
              className='ButtonSmall ButtonMin'
              onClick={onOkClick}
            >
              <span>{btnOkTitle}</span>
            </ButtonPrimary>
          )}
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

InfoModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  btnOkTitle: PropTypes.string,
  onOk: PropTypes.func
};

InfoModal.defaultProps = {
  isLoading: false,
  isOpen: false,
  message: '',
  btnOkTitle: 'Ok',
  onOk: () => {}
};

export default InfoModal;
