import React from 'react';
import SvgButtonIcn from '../components/misc/SvgButtonIcon';

export const IconRefundOrderDetails = () => (
  <svg
    width='21'
    height='21'
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_14337_108259)'>
      <path
        d='M10.4996 5.27344C7.61684 5.27344 5.27148 7.61879 5.27148 10.5016C5.27148 13.3844 7.61684 15.7297 10.4996 15.7297C13.3824 15.7297 15.7278 13.3844 15.7278 10.5016C15.7278 7.61879 13.3824 5.27344 10.4996 5.27344ZM11.1149 13.0678V13.1375C11.1149 13.4773 10.8394 13.7527 10.4996 13.7527C10.1599 13.7527 9.88439 13.4773 9.88439 13.1375V13.0938H9.84067C9.50089 13.0938 9.22543 12.8183 9.22543 12.4785C9.22543 12.1388 9.50089 11.8633 9.84067 11.8633H10.8291C11.035 11.8633 11.2024 11.6958 11.2024 11.4901C11.2024 11.2843 11.035 11.1168 10.8291 11.1168H10.1834C9.2918 11.1168 8.56643 10.3909 8.56643 9.49858C8.56643 8.71395 9.13709 8.06111 9.88443 7.93269V7.86563C9.88443 7.52585 10.1599 7.25039 10.4997 7.25039C10.8394 7.25039 11.1149 7.52585 11.1149 7.86563V7.90935H11.1586C11.4984 7.90935 11.7739 8.18481 11.7739 8.52458C11.7739 8.86436 11.4984 9.13982 11.1586 9.13982H10.1537C9.95695 9.13982 9.7969 9.30048 9.7969 9.49793C9.7969 9.7124 9.97028 9.8863 10.1834 9.8863H10.8291C11.7135 9.8863 12.4329 10.6057 12.4329 11.49C12.4329 12.2768 11.8632 12.9325 11.1149 13.0678Z'
        fill='#FF2D38'
      />
      <path
        d='M10.6648 1.32005L10.9346 1.05025C11.1749 0.810022 11.1749 0.420456 10.9346 0.180187C10.6944 -0.0600827 10.3049 -0.0600417 10.0646 0.180187L8.74663 1.49818C8.63125 1.61356 8.56641 1.77003 8.56641 1.93319C8.56641 2.09635 8.63121 2.25283 8.74663 2.36821L10.0646 3.6862C10.1848 3.80634 10.3422 3.86638 10.4996 3.86638C10.6571 3.86638 10.8146 3.80634 10.9346 3.68616C11.1749 3.44589 11.1749 3.05637 10.9346 2.8161L10.6691 2.55056C14.9756 2.64112 18.4512 6.17216 18.4512 10.5C18.4512 10.8398 18.7266 11.1152 19.0664 11.1152C19.4062 11.1152 19.6816 10.8398 19.6816 10.5C19.6816 5.49224 15.6519 1.4086 10.6648 1.32005Z'
        fill='#FF2D38'
      />
      <path
        d='M10.9354 17.3118C10.6951 17.0716 10.3056 17.0715 10.0653 17.3118C9.82504 17.5521 9.82504 17.9416 10.0653 18.1819L10.3309 18.4474C6.02433 18.3569 2.54883 14.8259 2.54883 10.498C2.54883 10.1583 2.27337 9.88281 1.93359 9.88281C1.59382 9.88281 1.31836 10.1583 1.31836 10.498C1.31836 15.5058 5.34815 19.5894 10.3352 19.678L10.0654 19.9478C9.82508 20.188 9.82508 20.5776 10.0654 20.8179C10.1854 20.938 10.3429 20.998 10.5004 20.998C10.6578 20.998 10.8153 20.938 10.9354 20.8179L12.2534 19.4999C12.3687 19.3845 12.4336 19.228 12.4336 19.0649C12.4336 18.9017 12.3688 18.7452 12.2534 18.6298L10.9354 17.3118Z'
        fill='#FF2D38'
      />
    </g>
    <defs>
      <clipPath id='clip0_14337_108259'>
        <rect width='21' height='21' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconRefundOrderDetails;
