import React from 'react';

export const IconSidebarBookADriver = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 6.56248C5.8864 6.56248 4.84315 6.07675 4.12891 5.24402V5.93357C4.12891 7.51669 5.41688 8.80467 7 8.80467C8.58312 8.80467 9.87109 7.51669 9.87109 5.93357V5.24402C9.15682 6.07675 8.11357 6.56248 7 6.56248Z'
      fill='#929CB9'
    />
    <path
      d='M5.56445 9.33447V9.98052C5.56445 10.0765 5.59809 10.1694 5.65953 10.2431L6.68492 11.4736C6.76285 11.5671 6.87827 11.6211 7 11.6211C7.12173 11.6211 7.23718 11.5671 7.31508 11.4736L8.34047 10.2431C8.40189 10.1694 8.43555 10.0765 8.43555 9.98052V9.33447C7.99405 9.52153 7.50889 9.62506 7 9.62506C6.49111 9.62506 6.00595 9.52153 5.56445 9.33447Z'
      fill='#929CB9'
    />
    <path
      d='M7.41016 14H9.73438V9.48142C9.57849 9.45397 9.41869 9.43795 9.25586 9.43445V9.98045C9.25586 10.2677 9.15458 10.5475 8.97066 10.7682L7.94527 11.9986C7.80153 12.1711 7.6163 12.2983 7.41016 12.3711V14Z'
      fill='#929CB9'
    />
    <path
      d='M4.26562 9.4814V14H6.58984V12.371C6.3837 12.2983 6.19845 12.1711 6.0547 11.9986L5.02934 10.7682C4.84542 10.5475 4.74414 10.2677 4.74414 9.98045V9.43445C4.58131 9.43789 4.42151 9.45394 4.26562 9.4814Z'
      fill='#929CB9'
    />
    <path
      d='M10.5547 9.74695V13.9999H11.9219C12.1484 13.9999 12.332 13.8163 12.332 13.5898V12.5781C12.332 11.3339 11.6056 10.2564 10.5547 9.74695Z'
      fill='#929CB9'
    />
    <path
      d='M3.44531 9.74695C2.39441 10.2564 1.66797 11.3339 1.66797 12.5781V13.5898C1.66797 13.8163 1.85161 13.9999 2.07812 13.9999H3.44531V9.74695Z'
      fill='#929CB9'
    />
    <path
      d='M4.31958 4.10156L4.53319 4.42198C5.0843 5.24866 6.0065 5.74219 7.00003 5.74219C7.99357 5.74219 8.91576 5.24866 9.46688 4.42198L9.68046 4.10156H4.31958Z'
      fill='#929CB9'
    />
    <path
      d='M10.3367 3.28125L10.6498 2.90555C10.8638 2.64873 10.9653 2.31112 10.9284 1.97928C10.8912 1.645 10.7241 1.34843 10.4578 1.14412C9.77751 0.62218 8.62536 0 7.00005 0C5.37473 0 4.22258 0.62218 3.54227 1.14412C3.27599 1.34843 3.10884 1.645 3.07165 1.97928C3.03474 2.31112 3.13629 2.64873 3.35029 2.90555L3.66337 3.28125H10.3367Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarBookADriver;
