import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const AccountSteps = styled.div`
  display: block;
  margin-top: 30px;
`;
export const ButtonsPanelRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media (max-width: 1600px) {
    margin: 0 -5px;
  }
  @media (max-width: 1360px) {
    margin: 0 -4px 0;
  }

  & > * {
    margin: 5px 10px;
    @media (max-width: 1600px) {
      margin: 5px;
    }
    @media (max-width: 1360px) {
      margin: 5px 4px;
    }
    &.Dark {
      min-width: 154px;
    }
    &.OutOfStock {
      min-width: 154px;
    }
    &.Hidden,
    &.Delete {
      min-width: 126px;
      @media (max-width: 1600px) {
        min-width: 110px;
      }
      @media (max-width: 1360px) {
        min-width: 100px;
      }
    }
  }
`;

export const MenuEditorPublish = styled.div`
  position: relative;

  &.PublishOverlay {
    z-index: 13;
  }
  .PublishDrop {
    &::after {
      right: 145px;
      @media (max-width: 1650px) {
        right: 98px;
      }
    }
  }
  @media (max-width: 767px) {
    .PublishDrop {
      right: inherit;
      left: -15px;

      &::after {
        right: inherit;
        left: 80px;
      }
    }
  }
  @media (max-width: 599px) {
    .PublishDrop {
      right: inherit;
      left: 0;

      &::after {
        right: inherit;
        left: 70px;
      }
    }
  }
  @media (max-width: 470px) {
    .PublishDrop {
      left: 50%;
      transform: translateX(-50%);
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export const DgDCheckbox = styled.div`
  position: absolute;
  top: 19px;
  left: -10px;
  pointer-events: auto;
  @media (max-width: 1000px) {
    left: 0;
    top: 15px;
  }
`;
