import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Body4 = styled(AbstractText)`
  font-size: 14px;
  font-weight: 500;
  color: #232d3a;

  &.Green {
    color: #17c981;
  }
  &.Red {
    color: #ff2d38;
  }
  span.grey {
    color: #929cb9;
  }
`;

export default Body4;
