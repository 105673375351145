/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import OrdersTable from '../components/OrdersTable';
import {OrdersContainer} from './styles';
import {
  CLEAR_BAD_HISTORY_ORDERS,
  GET_BAD_HISTORY_ORDERS,
  SET_BAD_HISTORY_TABLE_CONFIG
} from '../../../../store/bookADriver/bookADriverList/action';

import ButtonScrollTop from '../../../../components/buttons/ButtonScrollTop';

const HistoryOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isNetworkOnline} = useSelector(
    (state) => state.globalNotificationsReducer
  );
  const {historyOrders, config, hasNextPage, loading} = useSelector(
    (state) => state.bookADriverListReducer
  );
  const {timezone, locationId} = useSelector((state) => state.user);

  const getOrders = () => {
    dispatch(GET_BAD_HISTORY_ORDERS({config}));
  };

  useEffect(() => {
    getOrders();

    return () => {
      dispatch(CLEAR_BAD_HISTORY_ORDERS());
    };
  }, [locationId]);
  const openDetails = (data) => {
    /*dispatch(STOP_BAD_ORDER_DETAIL_JOB());
    dispatch(RUN_BAD_ORDER_DETAIL_JOB(data._id));*/
    history.push(`/book-a-driver/history/${data._id}/details`, {
      windowPosition: window.scrollY
    });
  };

  const handleRequestSort = (property) => {
    const isAsc =
      config.sortField === property.sortBy && config.sortDir === 'asc';
    dispatch(
      SET_BAD_HISTORY_TABLE_CONFIG({
        sortDir: isAsc ? 'desc' : 'asc',
        sortField: property.sortBy
      })
    );
    dispatch(CLEAR_BAD_HISTORY_ORDERS());
    getOrders();
  };

  const createSortHandler = (property) => {
    handleRequestSort(property);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={historyOrders.length}
        next={getOrders}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'inherit'
        }}
        hasMore={hasNextPage}
      >
        <OrdersContainer>
          <OrdersTable
            openDetails={openDetails}
            orders={historyOrders}
            onSort={createSortHandler}
            isNetworkOnline={isNetworkOnline}
            orderBy={config.sortField}
            orderDirection={config.sortDir}
            timezone={timezone}
            loading={loading}
          />
        </OrdersContainer>
        <ButtonScrollTop />
      </InfiniteScroll>
    </>
  );
};

HistoryOrders.propTypes = {};

export default HistoryOrders;
