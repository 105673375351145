import * as Yup from 'yup';

const RealDeliveryBoardActionsMadeSchema = Yup.object().shape({
  bookedADriver: Yup.bool(),
  deliveryId: Yup.string().when(['bookedADriver'], (bookedADriver) => {
    if (bookedADriver) {
      return Yup.string().required(
        'BaD Sauce Delivery ID is mandatory for BaD'
      );
    }
    return Yup.string();
  })
});

export default RealDeliveryBoardActionsMadeSchema;
