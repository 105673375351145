import {createAction} from 'redux-act';

export const GET_USERS = createAction('adminUsers/GET_USERS');
export const GET_USERS_SUCCESS = createAction('adminUsers/GET_USERS_SUCCESS');
export const GET_USER = createAction('adminUsers/GET_USER');
export const GET_USER_SUCCESS = createAction('adminUsers/GET_USER_SUCCESS');
export const PAGE_CHANGE = createAction('adminUsers/PAGE_CHANGE');
export const SEARCH_CHANGE = createAction('adminUsers/SEARCH_CHANGE');
export const SORT_CHANGE = createAction('adminUsers/SORT_CHANGE');
export const CLEAR_FILTERS = createAction('adminUsers/CLEAR_FILTERS');
export const CLEAR_LOCATIONS = createAction('adminUsers/CLEAR_LOCATIONS');
export const CLEAR_USER = createAction('adminUsers/CLEAR_USER');

export const GET_MERCHANTS = createAction('adminUsers/GET_MERCHANTS');
export const GET_MERCHANTS_SUCCESS = createAction(
  'adminUsers/GET_MERCHANTS_SUCCESS'
);

export const GET_LOCATIONS = createAction('adminUsers/GET_LOCATIONS');
export const GET_LOCATIONS_SUCCESS = createAction(
  'adminUsers/GET_LOCATIONS_SUCCESS'
);

export const SET_RESET_PASSWORD_USER_ID = createAction(
  'adminUsers/SET_RESET_PASSWORD_USER_ID'
);
export const CLEAR_SET_RESET_PASSWORD_USER_ID = createAction(
  'adminUsers/CLEAR_SET_RESET_PASSWORD_USER_ID'
);

export const RESET_PASSWORD_ADMIN = createAction(
  'adminUsers/RESET_PASSWORD_ADMIN'
);
export const RESET_PASSWORD_ADMIN_SUCCESS = createAction(
  'adminUsers/RESET_PASSWORD_ADMIN_SUCCESS'
);
