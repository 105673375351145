import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import ImgEmailMarketingBig from '../../../../assets/img-email-marketing-big.png';
import {
  EmailMarketingBodyTitle,
  EmailMarketingButton,
  EmailMarketingFooter,
  EmailMarketingFooterBlock,
  EmailMarketingImage,
  EmailMarketingNote
} from './styles';
import {ButtonPrimary} from '../../../../components/buttons';
import IconMarketingAutomated from '../../../../assets/IconMarketingAutomated';
import IconMarketingRepeat from '../../../../assets/IconMarketingRepeat';
import IconMarketingEngagement from '../../../../assets/IconMarketingEngagement';
import {OPEN_CONTACT_FORM_MODAL} from '../../../../store/emailMarketing/action';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';

const UpsellPage = () => {
  const dispatch = useDispatch();
  const {activeLocation} = useSelector((state) => state.user);

  return (
    <DashboardInner className='paddingLg mTop30'>
      <>
        <EmailMarketingImage src={ImgEmailMarketingBig} alt='' />
        <EmailMarketingBodyTitle>
          Boost revenues by as much 40%<span>*</span> and build a long lasting
          relationship with your customers using Sauce’s automated customer
          marketing engine.
        </EmailMarketingBodyTitle>
        <EmailMarketingButton>
          <ButtonPrimary
            onClick={() => {
              dispatch(OPEN_CONTACT_FORM_MODAL(true));
              sendGtmEvent('click-marketing-start', {
                location_name: activeLocation?.FullName,
                location_id: activeLocation?._id
              });
            }}
            className='w200'
          >
            <span>Start</span>
          </ButtonPrimary>
        </EmailMarketingButton>
        <EmailMarketingFooter>
          <EmailMarketingFooterBlock>
            <IconMarketingAutomated />
            <span className='FooterBlockTitle'>Fully Automated</span>
            <span className='FooterBlockText'>
              You cook. We market. Sit back and enjoy the benefits. Let Sauce
              engage with your customers on your behalf.
            </span>
          </EmailMarketingFooterBlock>
          <EmailMarketingFooterBlock className='WithBorders'>
            <IconMarketingRepeat />
            <span className='FooterBlockTitle'>More Repeat Customers</span>
            <span className='FooterBlockText'>
              Boost your GMV with highly engaged returning customers. Get them
              coming back to order regularly and frequently.
            </span>
          </EmailMarketingFooterBlock>
          <EmailMarketingFooterBlock>
            <IconMarketingEngagement />
            <span className='FooterBlockTitle'>End-to-End Engagement!</span>
            <span className='FooterBlockText'>
              Unlike traditional marketing tools, our data-driven service
              analyzes guest activity toward building long term relationships
              with your customer base.
            </span>
          </EmailMarketingFooterBlock>
        </EmailMarketingFooter>
        <EmailMarketingNote>
          <span>*</span> Based on existing customer data.
        </EmailMarketingNote>
      </>
    </DashboardInner>
  );
};

export default UpsellPage;
