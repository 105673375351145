import React from 'react';

const IconFullRefund = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_14404_108270)'>
        <path
          d='M24.813 16.8924C24.0809 16.5005 23.1968 16.5432 22.5058 17.0038L18.879 19.4217C18.763 18.2696 17.7877 17.3672 16.6055 17.3672H12.7969L12.3 16.9946C11.3106 16.2524 10.0845 15.8438 8.84762 15.8438C7.80498 15.8438 6.78128 16.1273 5.88717 16.6637L5.68745 16.7836V16.0977C5.68745 15.677 5.3464 15.3359 4.92573 15.3359H0.761719C0.341047 15.3359 0 15.677 0 16.0977V25.2383C0 25.659 0.341047 26 0.761719 26H4.92578C5.34645 26 5.6875 25.659 5.6875 25.2383V24.9844H17.685C18.4633 24.9844 19.213 24.7502 19.8528 24.3072L25.0317 20.7217C25.638 20.302 26 19.6113 26 18.8739C26 18.0435 25.5452 17.2842 24.813 16.8924ZM4.16406 24.4766H1.52344V16.8594H4.16406V24.4766ZM24.1646 19.4692L18.9856 23.0546C18.6017 23.3204 18.152 23.4609 17.685 23.4609H5.6875V18.5602L6.67103 17.9701C7.32839 17.5757 8.08102 17.3672 8.84762 17.3672C9.75696 17.3672 10.6584 17.6677 11.3859 18.2133L12.0859 18.7383C12.2178 18.8372 12.3782 18.8906 12.543 18.8906H16.6055C17.0255 18.8906 17.3672 19.2323 17.3672 19.6523C17.3672 20.0724 17.0255 20.4141 16.6055 20.4141H11.0195C10.5989 20.4141 10.2578 20.7551 10.2578 21.1758C10.2578 21.5965 10.5989 21.9375 11.0195 21.9375H17.0061C17.5592 21.9375 18.0949 21.7754 18.5554 21.4684L23.3509 18.2714C23.6765 18.0543 23.9792 18.1739 24.0942 18.2355C24.2092 18.297 24.4766 18.4824 24.4766 18.8739C24.4766 19.1114 24.36 19.334 24.1646 19.4692Z'
          fill='#929CB9'
        />
      </g>
      <path
        d='M4.5 7C2.57011 7 1 8.57011 1 10.5C1 12.4299 2.57011 14 4.5 14C6.42989 14 8 12.4299 8 10.5C8 8.57011 6.42989 7 4.5 7ZM4.5 13.1763C3.02432 13.1763 1.82374 11.9757 1.82374 10.5C1.82374 9.02432 3.02432 7.82374 4.5 7.82374C5.97568 7.82374 7.17626 9.02432 7.17626 10.5C7.17626 11.9757 5.97568 13.1763 4.5 13.1763Z'
        fill='#929CB9'
      />
      <path
        d='M4.51562 10.9121C4.65563 10.9121 4.76953 11.026 4.76953 11.166C4.76953 11.306 4.65563 11.4199 4.51562 11.4199C4.39537 11.4199 4.29077 11.3347 4.2669 11.2172C4.22505 11.0111 4.02404 10.8779 3.81784 10.9197C3.61169 10.9616 3.47854 11.1626 3.52039 11.3688C3.58948 11.7089 3.82698 11.9816 4.13474 12.107V12.1816C4.13474 12.392 4.30526 12.5625 4.5156 12.5625C4.72594 12.5625 4.89646 12.392 4.89646 12.1816V12.1073C5.26825 11.9563 5.53122 11.5914 5.53122 11.166C5.53122 10.606 5.07561 10.1504 4.5156 10.1504C4.3756 10.1504 4.26169 10.0365 4.26169 9.89648C4.26169 9.75648 4.3756 9.64258 4.5156 9.64258C4.63585 9.64258 4.74046 9.72784 4.76433 9.84532C4.80617 10.0514 5.00734 10.1846 5.21338 10.1428C5.41951 10.1009 5.55268 9.89986 5.51084 9.69371C5.44177 9.35358 5.20427 9.08089 4.89648 8.95556V8.88086C4.89648 8.67052 4.72596 8.5 4.51562 8.5C4.30529 8.5 4.13477 8.67052 4.13477 8.88086V8.95523C3.76297 9.10623 3.5 9.47112 3.5 9.89648C3.5 10.4565 3.95561 10.9121 4.51562 10.9121Z'
        fill='#929CB9'
      />
      <path
        d='M12.9004 7C10.9705 7 9.40039 8.57011 9.40039 10.5C9.40039 12.4299 10.9705 14 12.9004 14C14.8303 14 16.4004 12.4299 16.4004 10.5C16.4004 8.57011 14.8303 7 12.9004 7ZM12.9004 13.1763C11.4247 13.1763 10.2241 11.9757 10.2241 10.5C10.2241 9.02432 11.4247 7.82374 12.9004 7.82374C14.3761 7.82374 15.5766 9.02432 15.5766 10.5C15.5766 11.9757 14.3761 13.1763 12.9004 13.1763Z'
        fill='#929CB9'
      />
      <path
        d='M12.916 10.9121C13.056 10.9121 13.1699 11.026 13.1699 11.166C13.1699 11.306 13.056 11.4199 12.916 11.4199C12.7958 11.4199 12.6912 11.3347 12.6673 11.2172C12.6254 11.0111 12.4244 10.8779 12.2182 10.9197C12.0121 10.9616 11.8789 11.1626 11.9208 11.3688C11.9899 11.7089 12.2274 11.9816 12.5351 12.107V12.1816C12.5351 12.392 12.7057 12.5625 12.916 12.5625C13.1263 12.5625 13.2968 12.392 13.2968 12.1816V12.1073C13.6686 11.9563 13.9316 11.5914 13.9316 11.166C13.9316 10.606 13.476 10.1504 12.916 10.1504C12.776 10.1504 12.6621 10.0365 12.6621 9.89648C12.6621 9.75648 12.776 9.64258 12.916 9.64258C13.0362 9.64258 13.1408 9.72784 13.1647 9.84532C13.2066 10.0514 13.4077 10.1846 13.6138 10.1428C13.8199 10.1009 13.9531 9.89986 13.9112 9.69371C13.8422 9.35358 13.6047 9.08089 13.2969 8.95556V8.88086C13.2969 8.67052 13.1264 8.5 12.916 8.5C12.7057 8.5 12.5352 8.67052 12.5352 8.88086V8.95523C12.1634 9.10623 11.9004 9.47112 11.9004 9.89648C11.9004 10.4565 12.356 10.9121 12.916 10.9121Z'
        fill='#929CB9'
      />
      <path
        d='M21.3003 7C19.3704 7 17.8003 8.57011 17.8003 10.5C17.8003 12.4299 19.3704 14 21.3003 14C23.2302 14 24.8003 12.4299 24.8003 10.5C24.8003 8.57011 23.2302 7 21.3003 7ZM21.3003 13.1763C19.8246 13.1763 18.624 11.9757 18.624 10.5C18.624 9.02432 19.8246 7.82374 21.3003 7.82374C22.776 7.82374 23.9766 9.02432 23.9766 10.5C23.9766 11.9757 22.776 13.1763 21.3003 13.1763Z'
        fill='#929CB9'
      />
      <path
        d='M21.3159 10.9121C21.4559 10.9121 21.5698 11.026 21.5698 11.166C21.5698 11.306 21.4559 11.4199 21.3159 11.4199C21.1957 11.4199 21.0911 11.3347 21.0672 11.2172C21.0253 11.0111 20.8243 10.8779 20.6181 10.9197C20.412 10.9616 20.2788 11.1626 20.3207 11.3688C20.3898 11.7089 20.6273 11.9816 20.935 12.107V12.1816C20.935 12.392 21.1056 12.5625 21.3159 12.5625C21.5262 12.5625 21.6968 12.392 21.6968 12.1816V12.1073C22.0685 11.9563 22.3315 11.5914 22.3315 11.166C22.3315 10.606 21.8759 10.1504 21.3159 10.1504C21.1759 10.1504 21.062 10.0365 21.062 9.89648C21.062 9.75648 21.1759 9.64258 21.3159 9.64258C21.4361 9.64258 21.5408 9.72784 21.5646 9.84532C21.6065 10.0514 21.8076 10.1846 22.0137 10.1428C22.2198 10.1009 22.353 9.89986 22.3111 9.69371C22.2421 9.35358 22.0046 9.08089 21.6968 8.95556V8.88086C21.6968 8.67052 21.5263 8.5 21.3159 8.5C21.1056 8.5 20.9351 8.67052 20.9351 8.88086V8.95523C20.5633 9.10623 20.3003 9.47112 20.3003 9.89648C20.3003 10.4565 20.7559 10.9121 21.3159 10.9121Z'
        fill='#929CB9'
      />
      <path
        d='M8.7002 0C6.7703 0 5.2002 1.57011 5.2002 3.5C5.2002 5.42989 6.7703 7 8.7002 7C10.6301 7 12.2002 5.42989 12.2002 3.5C12.2002 1.57011 10.6301 0 8.7002 0ZM8.7002 6.17626C7.22452 6.17626 6.02394 4.97568 6.02394 3.5C6.02394 2.02432 7.22452 0.823743 8.7002 0.823743C10.1759 0.823743 11.3765 2.02432 11.3765 3.5C11.3765 4.97568 10.1759 6.17626 8.7002 6.17626Z'
        fill='#929CB9'
      />
      <path
        d='M8.71582 3.91211C8.85582 3.91211 8.96973 4.02601 8.96973 4.16602C8.96973 4.30602 8.85582 4.41992 8.71582 4.41992C8.59557 4.41992 8.49096 4.33466 8.46709 4.21718C8.42525 4.01106 8.22423 3.87793 8.01804 3.91973C7.81189 3.9616 7.67874 4.16264 7.72058 4.36878C7.78967 4.70892 8.02718 4.98164 8.33494 5.10697V5.18164C8.33494 5.39198 8.50546 5.5625 8.71579 5.5625C8.92613 5.5625 9.09665 5.39198 9.09665 5.18164V5.10727C9.46845 4.95627 9.73142 4.59138 9.73142 4.16602C9.73142 3.606 9.27581 3.15039 8.71579 3.15039C8.57579 3.15039 8.46189 3.03649 8.46189 2.89648C8.46189 2.75648 8.57579 2.64258 8.71579 2.64258C8.83604 2.64258 8.94065 2.72784 8.96452 2.84532C9.00637 3.05144 9.20754 3.18462 9.41358 3.14277C9.6197 3.1009 9.75288 2.89986 9.71103 2.69371C9.64197 2.35358 9.40446 2.08089 9.09668 1.95556V1.88086C9.09668 1.67052 8.92616 1.5 8.71582 1.5C8.50548 1.5 8.33496 1.67052 8.33496 1.88086V1.95523C7.96317 2.10623 7.7002 2.47112 7.7002 2.89648C7.7002 3.4565 8.1558 3.91211 8.71582 3.91211Z'
        fill='#929CB9'
      />
      <path
        d='M17.1006 0C15.1707 0 13.6006 1.57011 13.6006 3.5C13.6006 5.42989 15.1707 7 17.1006 7C19.0305 7 20.6006 5.42989 20.6006 3.5C20.6006 1.57011 19.0305 0 17.1006 0ZM17.1006 6.17626C15.6249 6.17626 14.4243 4.97568 14.4243 3.5C14.4243 2.02432 15.6249 0.823743 17.1006 0.823743C18.5763 0.823743 19.7768 2.02432 19.7768 3.5C19.7768 4.97568 18.5763 6.17626 17.1006 6.17626Z'
        fill='#929CB9'
      />
      <path
        d='M17.1162 3.91211C17.2562 3.91211 17.3701 4.02601 17.3701 4.16602C17.3701 4.30602 17.2562 4.41992 17.1162 4.41992C16.996 4.41992 16.8914 4.33466 16.8675 4.21718C16.8256 4.01106 16.6246 3.87793 16.4184 3.91973C16.2123 3.9616 16.0791 4.16264 16.121 4.36878C16.1901 4.70892 16.4276 4.98164 16.7353 5.10697V5.18164C16.7353 5.39198 16.9058 5.5625 17.1162 5.5625C17.3265 5.5625 17.497 5.39198 17.497 5.18164V5.10727C17.8688 4.95627 18.1318 4.59138 18.1318 4.16602C18.1318 3.606 17.6762 3.15039 17.1162 3.15039C16.9762 3.15039 16.8623 3.03649 16.8623 2.89648C16.8623 2.75648 16.9762 2.64258 17.1162 2.64258C17.2364 2.64258 17.341 2.72784 17.3649 2.84532C17.4068 3.05144 17.6079 3.18462 17.814 3.14277C18.0201 3.1009 18.1533 2.89986 18.1114 2.69371C18.0424 2.35358 17.8049 2.08089 17.4971 1.95556V1.88086C17.4971 1.67052 17.3265 1.5 17.1162 1.5C16.9059 1.5 16.7354 1.67052 16.7354 1.88086V1.95523C16.3636 2.10623 16.1006 2.47112 16.1006 2.89648C16.1006 3.4565 16.5562 3.91211 17.1162 3.91211Z'
        fill='#929CB9'
      />
      <defs>
        <clipPath id='clip0_14404_108270'>
          <rect width='26' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFullRefund;
