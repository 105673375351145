/* eslint-disable */
import {PUBLISH_STATUS_WAITING} from '../constants';

export function setPublishStatus(store, status, isMenuUpdate = true) {
  const {publishStatus} = store;
  let nextPublishStatus = publishStatus;

  if (isMenuUpdate && publishStatus !== PUBLISH_STATUS_WAITING) {
    nextPublishStatus = status;
  }

  return {
    ...store,
    publishStatus: nextPublishStatus
  };
}
