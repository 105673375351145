import {createReducer} from 'redux-act';
import {
  OPEN_ADJUST_MODAL,
  CALCULATE_ADJUST_SUCCESS,
  OPEN_ADJUST_SUCCESS_MODAL,
  OPEN_ADJUST_ERROR_MODAL,
  CREATE_ADJUST
} from 'store/orders/orderAdjust/action';

const initialState = () => ({
  isOpenAdjustModal: false,
  loading: false,
  calculationResults: {totalAmount: 0, taxes: 0},
  isOpenSuccessModal: false,
  isOpenErrorModal: false,
  errorMessage: null
});

const orderAdjustReducer = createReducer(
  {
    [OPEN_ADJUST_MODAL]: (state, payload) => {
      return {
        isOpenAdjustModal: payload
      };
    },
    [CREATE_ADJUST]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [CALCULATE_ADJUST_SUCCESS]: (state, payload) => {
      const taxes =
        payload.salesTaxAmount +
        (payload.alcoholTaxAmount ? payload.alcoholTaxAmount : 0);
      return {
        ...state,
        calculationResults: {
          totalAmount: payload.totalAmount,
          taxes
        }
      };
    },
    [OPEN_ADJUST_SUCCESS_MODAL]: (state, payload) => {
      return {
        ...state,
        isOpenSuccessModal: payload,
        isOpenAdjustModal: false
      };
    },
    [OPEN_ADJUST_ERROR_MODAL]: (state, payload) => {
      return {
        ...state,
        isOpenErrorModal: payload.isOpen,
        errorMessage: payload.message,
        isOpenAdjustModal: false,
        loading: false
      };
    }
  },
  initialState()
);

export default orderAdjustReducer;
