import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_COUNTER,
  GET_COUNTER_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  SUBMIT_USER_EDIT_ADD_WRAPPER,
  OPEN_CONFIRMATION_MODAL
} from './action';
import {
  deleteUserApi,
  getCounterApi,
  getUsersApi,
  resetPasswordApi,
  updateUsersApi
} from '../../helpers/services/api/user';
import {addTeamMemberApi} from '../../helpers/services/api/account';

const getMerchant = ({user}) => user.userData.merchant_name;
const merchantid = ({user}) => user.userData.merchant_id;
const userId = ({user}) => user.userData.id;
const pagination = ({usersManagementReducer}) =>
  usersManagementReducer.pagination;

function* getUsers({payload: {selected, sort, search, limit = 8} = {}}) {
  try {
    const merchant_name = yield select(getMerchant);
    const {data} = yield getUsersApi(
      merchant_name,
      selected,
      sort,
      search,
      limit
    );
    if (data) {
      yield put(GET_USERS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getCounter() {
  try {
    const merchant_name = yield select(getMerchant);
    const {data} = yield getCounterApi(merchant_name);
    if (data) {
      yield put(GET_COUNTER_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* deleteUser({payload: {_id}}) {
  try {
    const {status} = yield deleteUserApi(_id);
    const {page, sort, search} = yield select(pagination);

    if (status === 204) {
      yield put(DELETE_USER_SUCCESS(_id));
      yield put(GET_USERS({selected: page, sort, search}));
      yield put(GET_COUNTER());
      toast.success('User has been deleted');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* addTeamMember({payload}) {
  try {
    const merchant_id = yield select(merchantid);
    const user_id = yield select(userId);
    const {data} = yield addTeamMemberApi(merchant_id, user_id, payload);
    const {page} = yield select(pagination);
    if (data) {
      yield put(CREATE_USER_SUCCESS(data));
      yield put(GET_USERS({selected: page}));
      yield put(GET_COUNTER());
      yield put(OPEN_CONFIRMATION_MODAL({open: true}));
      toast.success('User has been created');
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(CREATE_USER_FAILED());
    return false;
  }
}

function* updateUser({payload}) {
  try {
    const {_id, ...body} = payload;
    const {status} = yield updateUsersApi(_id, body);
    if (status === 204) {
      yield put(UPDATE_USER_SUCCESS(payload));
      yield put(GET_COUNTER());
      if (payload.callBack) {
        payload.callBack();
      }
      toast.success('User has been updated');
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* resetPassword({payload: {_id, Password}}) {
  try {
    const {status} = yield resetPasswordApi(_id, {password: Password});
    if (status === 204) {
      yield put(RESET_PASSWORD_SUCCESS());
      toast.success('Password has been reset');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* wrapperUserAddOrEdit({payload}) {
  try {
    let success;
    if (payload.isEdit) {
      success = yield updateUser({payload: payload.values});
    } else {
      success = yield addTeamMember({
        payload: {...payload.values}
      });
    }
    if (success) {
      yield payload.actions();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* usersManagementSaga() {
  yield takeEvery(GET_USERS, getUsers);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(CREATE_USER, addTeamMember);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(GET_COUNTER, getCounter);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(SUBMIT_USER_EDIT_ADD_WRAPPER, wrapperUserAddOrEdit);
}

export default usersManagementSaga;
