import {takeEvery, put, select} from 'redux-saga/effects';
import {getHubspotInfo} from '../../../helpers/services/api/hubspot';
import {
  GET_USER_INFO_FOR_CHAT,
  GET_USER_INFO_FOR_CHAT_SUCCESS,
  LOAD_CHAT
} from './action';
import {SET_LOCATION_ID} from '../user/action';

const getToken = ({authReducer}) => authReducer.token;

export function* getUserInfoForChat(locationId) {
  try {
    const token = yield select(getToken);
    // const {data} = yield getHubspotInfo(token, locationId);
    //
    // if (data) {
    //   yield put(GET_USER_INFO_FOR_CHAT_SUCCESS(data));
    // }
    yield put(SET_LOCATION_ID(locationId));
    // yield put(LOAD_CHAT(false));
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}
function* hubspotChatSaga() {
  // yield takeEvery(GET_USER_INFO_FOR_CHAT, getUserInfoForChat);
}

export default hubspotChatSaga;
