import React, {useEffect, useState} from 'react';
// import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {SuccessImage, SuccessImageOrder} from '../../../account/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {DialogAction, DialogCheckbox} from '../../../dashboard/styles';
import Body4 from '../../../../../components/typography/Body4';
import DialogActions from '../../../../../components/DialogActions/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import StatusGif1 from '../../../../../assets/completed-order-1.gif';
import StatusGif2 from '../../../../../assets/completed-order-2.gif';
import StatusGif3 from '../../../../../assets/completed-order-3.gif';
import {
  OPEN_STATUS_CHANGE_MODAL,
  TURN_OFF_COMPLETED_NOTIFICATION
} from '../../../../../store/orders/orderList/action';
import DialogClose from '../../../../../components/DialogClose/styles';

const displayRandomGif = (min, max) => {
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const gifs = [StatusGif1, StatusGif2, StatusGif3];
  return gifs[randomNumber];
};

const OrderStatusChangeModal = () => {
  const [displayCheckbox, setDisplayCheckbox] = useState(false);
  const [gif, setGif] = useState(null);
  // const history = useHistory();
  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.ordersHistoryReducer.isStatusChangeModalOpen
  );

  useEffect(() => {
    setGif(displayRandomGif(0, 2));
    return () => {
      setGif(null);
      setDisplayCheckbox(false);
    };
  }, [isOpen || null]);

  const handleCheckbox = () => {
    setDisplayCheckbox((prevState) => !prevState);
  };

  const onSubmit = () => {
    if (displayCheckbox) {
      dispatch(TURN_OFF_COMPLETED_NOTIFICATION(false));
    }
    dispatch(OPEN_STATUS_CHANGE_MODAL(false));
  };
  return (
    <Dialog open={isOpen} scroll='body' onClose={onSubmit}>
      <DialogContainer className='DialogM'>
        <DialogClose onClick={onSubmit} />
        <DialogBody>
          <DialogTitle className='marginBottomSm'>
            It is now a completed order!
          </DialogTitle>
          <Body4 className='text-center marginBottomXl'>
            Your order has now been marked as Completed
            <br />
            and can be viewed under Orders History
          </Body4>
          <SuccessImage src={gif} />
          <DialogCheckbox>
            <FormControlLabel
              className='Say2EatCheckbox noMarginRight'
              control={
                <Checkbox
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  onChange={handleCheckbox}
                  name='checkedNote'
                  checked={displayCheckbox}
                />
              }
              label='Do not display this message again when an order status changes to Completed'
            />
          </DialogCheckbox>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50 noMarginTop'>
              <ButtonPrimary onClick={onSubmit}>
                <span>Back to Orders</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default OrderStatusChangeModal;
