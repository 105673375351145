import React from 'react';
import {Route} from 'react-router-dom';
import SelfCreateAccount from './account/SetupAccount/SeflSetup';
import CreateAccount from './account/SetupAccount/SalesSetup';

const SetupRoutes = () => {
  return (
    <>
      <Route path='/my-account/self-setup' component={SelfCreateAccount} />
      <Route path='/my-account/sales-setup' component={CreateAccount} />
      <Route path='/my-account/sales-setup-new' component={CreateAccount} />
    </>
  );
};

export default SetupRoutes;
