import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {AccordionItemName} from '../../dashboard/styles';
import BulkCheckbox from './BulkCheckbox';
import {ITEM_SCHEME} from '../../../../store/menuEditor/helper/MenuTree';

function ItemAccordionTitle(props) {
  const {itemsData} = props;
  const selectedItems = useSelector(
    (store) => store.menuEditorReducer.selectedItems
  );
  const isChecked =
    selectedItems && Object.keys(selectedItems).length === itemsData.length;

  return (
    <AccordionItemName className='AccordionItemName'>
      {!!itemsData.length && (
        <div className='BulkCheckbox'>
          <BulkCheckbox id='all' type={ITEM_SCHEME} checked={isChecked} />
        </div>
      )}
      Items ({itemsData.length})
    </AccordionItemName>
  );
}

ItemAccordionTitle.propTypes = {
  itemsData: PropTypes.array.isRequired
};

export default ItemAccordionTitle;
