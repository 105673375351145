import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FeedbackBannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div:first-child {
    text-align: center;
    flex: 1;
  }
  div:last-child {
    margin-right: 15px;
  }
`;
