/* eslint-disable */
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {PUBLISH_MENU} from '../../../../store/menuEditor/action';
import RecursionRelationModal from './RecursionRelationModal';
import {
  PUBLISH_STATUS_DISABLE,
  PUBLISH_STATUS_ENABLE,
  PUBLISH_STATUS_WAITING
} from '../../../../store/menuEditor/constants';
import {
  FETCH_MENU_RECURSION_RELATIONS,
  CLEAR_RECURSION_RELATIONS
} from '../../../../store/menuEditor/action';
import history from '../../../../history';
import RouteLeavingGuardNew from '../../../../helpers/RouteLeavingGuardNew';
import {MenuEditorPublish} from '../styles';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';
import IconPublishMessage from '../../../../assets/IconPublishMessage';
import ButtonBold from '../../../../components/buttons/ButtonBold/styles';
import IconPublishNew from '../../../../assets/IconPublishNew';

function PublishButton() {
  const dispatch = useDispatch();
  const publishStatus = useSelector(
    (store) => store.menuEditorReducer.publishStatus
  );
  const menuConfigId = useSelector((store) => store.menuEditorReducer.menuId);
  const isPublishRunning = useSelector(
    (store) => store.globalNotificationsReducer.publishRunning
  );
  const [confirmBox, setConfirmBox] = useState(null);
  const [, setInfoBox] = useState(null);
  const [recursionBox, setRecursionBox] = useState(null);
  const [isRecursionChecking, setIsRecursionChecking] = useState(false);
  const [messageUnPublish, setMessageUnPublish] = useState(false);

  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closePublishMessage = () => {
    const TASK_TYPE_LAST_CUSTOM_DATA_UPDATE = 'LAST_CUSTOM_DATA_UPDATE';
    setMessageUnPublish(false);
    setConfirmedNavigation(true);
    dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
    dispatch(
      SHOW_UNPUBLISH_BANNER({
        showBanner: true,
        types: [TASK_TYPE_LAST_CUSTOM_DATA_UPDATE]
      })
    );
  };

  const labels = {
    [PUBLISH_STATUS_DISABLE]: {
      title: 'Publish',
      tooltip: 'Nothing has been changed in the menu'
    },
    [PUBLISH_STATUS_ENABLE]: {
      title: 'Publish',
      tooltip: ''
    },
    [PUBLISH_STATUS_WAITING]: {
      title: 'Waiting...',
      tooltip: ''
    }
  };

  const label = labels[publishStatus] || {title: '', tooltip: ''};

  const onMenuPublishResult = (data) => {
    setConfirmBox(null);

    if (!data) {
      return;
    }

    const {message} = data;

    setInfoBox({
      message,
      btnOkTitle: 'OK',
      onOk: () => setInfoBox(null)
    });
  };

  const onPublishMenu = () => {
    dispatch(
      PUBLISH_MENU({
        menuConfigId,
        preCallback: () => {
          setConfirmBox({...confirmBox, isLoading: true});
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallback: onMenuPublishResult,
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  const onCheckRecursionInMenu = () => {
    dispatch(
      FETCH_MENU_RECURSION_RELATIONS({
        preCallback: () => {
          setMessageUnPublish(false);
          setIsRecursionChecking(true);
        },
        postCallback: (relations) => {
          if (relations) {
            setRecursionBox({
              message:
                'You have an invalid relations between the items below. Please solve them or clear the relations to be able to continue.',
              relations: relations,
              onOk: () => {
                dispatch(
                  CLEAR_RECURSION_RELATIONS({
                    relations,
                    preCallback: () => {
                      setRecursionBox({...recursionBox, isLoading: true});
                    },
                    postCallback: (relations) => {
                      setRecursionBox({
                        message:
                          'The invalid relations has been removed successfully. Please click on Publish button to continue.',
                        relations: relations,
                        onOk: () => {
                          setRecursionBox(null);
                          onPublishMenu();
                        },
                        onCancel: () => setRecursionBox(null)
                      });
                    }
                  })
                );
              },
              onCancel: () => setRecursionBox(null)
            });
          } else {
            onPublishMenu();
          }

          setIsRecursionChecking(false);
        }
      })
    );
  };

  return (
    <MenuEditorPublish
      className={cn('MenuEditorPublish', {
        PublishOverlay: messageUnPublish
      })}
    >
      <ButtonPrimary
        onClick={
          publishStatus === PUBLISH_STATUS_ENABLE
            ? onCheckRecursionInMenu
            : undefined
        }
        className={cn('ButtonMedium ButtonPublish', {
          disabled:
            isRecursionChecking || publishStatus !== PUBLISH_STATUS_ENABLE
        })}
      >
        <IconPublishNew />
        <span className='titleBtn'>
          {isRecursionChecking ? 'Checking...' : label.title}
        </span>
      </ButtonPrimary>

      <RouteLeavingGuardNew
        iconMessage={<IconPublishMessage />}
        messageUnPublish={messageUnPublish}
        setMessageUnPublish={setMessageUnPublish}
        confirmedNavigation={confirmedNavigation}
        when={publishStatus === PUBLISH_STATUS_ENABLE && !isPublishRunning}
        titleText='Please note: Your live menu has not been updated. To update your menu click Publish'
        buttonCancel={
          <ButtonBold className='Grey' onClick={closePublishMessage}>
            Dismiss
          </ButtonBold>
        }
        navigate={(path) => {
          history.push(path);
        }}
        forbiddenNextLocations={[
          '/menu-editor/categories',
          '/menu-editor/items',
          '/menu-editor/groups',
          '/menu-editor/modifiers',
          '/menu-editor/out-of-stock',
          '/menu-editor/hidden'
        ]}
      />

      {!!recursionBox && (
        <RecursionRelationModal
          isOpen
          isLoading={!!recursionBox.isLoading}
          relations={recursionBox.relations}
          message={recursionBox.message}
          btnOkTitle={recursionBox.btnOkTitle}
          btnCancelTitle={recursionBox.btnCancelTitle}
          onOk={recursionBox.onOk}
          onCancel={recursionBox.onCancel}
        />
      )}

      {/* { */}
      {/*  !!confirmBox && */}
      {/*  <ConfirmModal */}
      {/*    isOpen */}
      {/*    isLoading={!!confirmBox.isLoading} */}
      {/*    message={confirmBox.message} */}
      {/*    btnOkTitle={confirmBox.btnOkTitle} */}
      {/*    btnCancelTitle={confirmBox.btnCancelTitle} */}
      {/*    onOk={confirmBox.onOk} */}
      {/*    onCancel={confirmBox.onCancel} */}
      {/*  /> */}
      {/* } */}

      {/* { */}
      {/*  !!infoBox && */}
      {/*  <InfoModal */}
      {/*    isOpen */}
      {/*    message={infoBox.message} */}
      {/*    btnOkTitle={infoBox.btnOkTitle} */}
      {/*    onOk={infoBox.onOk} */}
      {/*  /> */}
      {/* } */}
    </MenuEditorPublish>
  );
}

export default PublishButton;
