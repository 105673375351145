/* eslint-disable */
import React from 'react';
import {useDispatch} from 'react-redux';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import IconAdd from '../../../../assets/IconAdd';
import {ADD_NEW_MODAL} from '../../../../store/menuEditorModals/action';

function CreateButton() {
  const dispatch = useDispatch();

  const onCreateModalOpen = () => {
    dispatch(ADD_NEW_MODAL({open: true}));
  };

  return (
    <ButtonPrimary
      onClick={onCreateModalOpen}
      className='ButtonMedium ButtonAdd'
    >
      <IconAdd />
      <span>Add New</span>
    </ButtonPrimary>
  );
}

export default CreateButton;
