import React, {useEffect} from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import MarketingIntegrationsMainPage from 'routes/App/MarketingIntegrations/MainPage';
import MarketingIntegrationsDetailsPage from 'routes/App/MarketingIntegrations/DetailsPage';
import sendGA from 'helpers/services/utils/ga';

const MarketingIntegrations = () => {
  const match = useRouteMatch();

  useEffect(() => {
    sendGA('Marketing Integrations', 'Marketing Integrations', null, null);
  }, []);

  return (
    <Switch>
      <Route
        path={`${match.path}/details`}
        component={MarketingIntegrationsDetailsPage}
      />
      <Route
        path={`${match.path}/`}
        component={MarketingIntegrationsMainPage}
      />
    </Switch>
  );
};

export default MarketingIntegrations;
