import {createReducer} from 'redux-act';
import {GET_CATEGORY_BY_ID, CLEAR_CATEGORY_DATA} from './action';

const initialState = () => ({
  overviewData: [],
  overviewLoading: false,
  rerenderOverview: false
});

const menuEditorCategoryReducer = createReducer(
  {
    [CLEAR_CATEGORY_DATA]: (store) => ({
      ...store,
      overviewData: []
    }),
    [GET_CATEGORY_BY_ID]: (store) => ({
      ...store,
      overviewLoading: true
    })
  },
  initialState()
);

export default menuEditorCategoryReducer;
