import React, {memo, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect, Route, useHistory} from 'react-router-dom';
import Headline from '../../../../components/headline2/HeadlineComponent';
import IconAdd from '../../../../assets/IconAdd';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {getTotalDocsCount} from '../../../../store/discounts/selectors';
import {
  PUSH_MODALS_STACK,
  SET_WARNING_MODAL
} from '../../../../store/discounts/action';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {
  DashboardHeaderActions,
  OrderTab,
  OrderTabs
} from '../../orders/OrdersList/styles';
import {Caption1} from '../../../../components/typography';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Modals from './Modals';
import {DISCOUNT_MODALS} from '../../../../helpers/constants/discounts';
import DiscountsView from './DiscountsView';
import history from '../../../../history';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';

const DiscountsContainer = () => {
  const {
    location: {pathname}
  } = useHistory();
  const headerRef = useRef();
  const dispatch = useDispatch();
  const docsCount = getTotalDocsCount();

  useEffect(() => {
    if (pathname === '/discounts') {
      history.push('/discounts/active');
    }
  }, [pathname]);

  useEffect(() => {
    return () => dispatch(SET_WARNING_MODAL(true));
  }, []);

  const addNewDiscount = () => {
    dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS.TURN_ON}));
  };

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline label='Discounts' />
        <DashboardHeaderActions className='DiscountActions'>
          <ButtonPrimary
            onClick={(e) => {
              e.stopPropagation();
              addNewDiscount();
            }}
            className='ButtonAdd ButtonMedium'
            data-gtm='addNewDiscountButton'
          >
            <IconAdd customAttribute='data-gtm' value='addNewDiscountButton' />
            <span data-gtm='addNewDiscountButton'>Add New</span>
          </ButtonPrimary>
        </DashboardHeaderActions>
      </DashboardHeader>
      <DashboardInner className='withoutShadow paddingXl' ref={headerRef}>
        <OrderTabs>
          <OrderTab activeClassName='isActive' to='/discounts/active'>
            <Caption1 className='Caption marginBottom0'>
              <span style={{color: 'red'}}>
                {pathname === '/discounts/active' ? docsCount : ''}{' '}
              </span>
              Active Discounts
            </Caption1>
          </OrderTab>
          <OrderTab activeClassName='isActive' to='/discounts/history'>
            <Caption1 className='Caption marginBottom0'>
              Discounts History
            </Caption1>
          </OrderTab>
        </OrderTabs>
      </DashboardInner>

      <>
        <Route path='/discounts/active' component={DiscountsView} />
        <Route path='/discounts/history' component={DiscountsView} />
        <Redirect from='/discounts' to='/discounts/active' />
      </>

      <Modals />
    </DashboardContent>
  );
};

export default memo(DiscountsContainer);
