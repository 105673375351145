import styled from 'styled-components';

const Headline = styled.h2`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  color: #2f3d48;

  &.HeadLineMenu {
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: -7px;
    @media (max-width: 1250px) {
      margin-top: 12px;
    }
    @media (max-width: 1200px) {
      margin-top: 36px;
    }
    @media (max-width: 767px) {
      display: block;
    }
    @media (max-width: 599px) {
      margin-top: 25px;
      display: block;
    }
    .buttons {
      display: flex;
      margin: 0 -8px;
      @media (max-width: 767px) {
        margin-top: 12px;
      }
      @media (max-width: 470px) {
        display: block;
      }

      &.isFixed {
        @media (max-width: 767px) {
          position: fixed;
          top: 70px;
          right: 19px;
          z-index: 1;
          transition-property: top;
          transition-duration: 0.4s;
        }
        @media (max-width: 599px) {
          position: relative;
          top: 0;
          right: 0;
        }
      }

      .ButtonAdd {
        display: flex;
        justify-content: center;
        min-width: 180px;
        margin: 8px;
        padding: 10px 0;

        @media (max-width: 767px) {
          margin: 0 8px 0 0;
        }
        @media (max-width: 470px) {
          max-width: 100%;
          margin: 8px 0 0 0;
          width: 100%;
        }

        span {
          color: white;
        }

        &.disabled {
          svg {
            path {
              fill: #2f3d48;
            }
          }

          span.titleBtn {
            color: #7f888e;
          }
        }
      }
      .ButtonPublish {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 180px;
        margin: 8px;
        padding: 8px 0;

        @media (max-width: 767px) {
          margin: 0 8px 0 0;
        }
        @media (max-width: 470px) {
          max-width: 100%;
          margin: 8px 0 0 0;
          width: 100%;
        }

        span {
          color: white;
        }
        svg {
          width: 30px;
          height: 30px;
          flex: 0 0 auto;
          margin-right: 6px;
          display: inline-block;
          position: relative;
          z-index: 3;
        }
        &.disabled {
          svg {
            path {
              fill: #fff;
            }
          }

          span.titleBtn {
            color: #fff;
          }
        }
        .TooltipIcon {
          cursor: pointer;
          pointer-events: all;

          path {
            fill: #7f888e;
          }
        }
      }
    }
  }

  &.Center {
    text-align: center;
  }

  @media (max-width: 1250px) {
    font-size: 24px;
  }
  &.Sm {
    font-size: 24px;
  }
  &.mBottom {
    margin-bottom: 7px;
  }

  & > span {
    color: #17c981;
    padding: 0 6px;
    &.orange {
      color: #ff8412;
    }
    &.red {
      color: #ff2d38;
    }
  }
  &.displayBlock {
    display: block;
  }

  &.HeadlineDecor {
    white-space: nowrap;
    &::after {
      display: inline-block;
      width: 100%;
      height: 1px;
      flex: 1 1 auto;
      margin-left: 12px;
      background-color: #190848;
      content: '';
    }
  }
  &.MarginTop35 {
    margin-top: 35px;
  }
  &.InsightsGrowsTitle {
    font-size: 24px;

    @media (max-width: 1250px) {
      font-size: 18px;
    }
  }
  &.mTop5 {
    margin-top: 5px;
  }
  &.FindDeliverySub {
    font-size: 21px;
    margin-top: 20px;
  }
`;

export default Headline;
