import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import {SHOW_EMAIL_CHECK_MODAL, UPDATE_EMAIL} from './action';
import {getAccountStepsStatus} from '../account/saga';
import {UPDATE_USER_DATA_SUCCESS} from '../user/action';
import {getUserData} from '../user/saga';
import {getAppIp, getAppType, getAppVersion} from '../../../helpers/app.helper';
import {request} from '../../../helpers/services/utils/request';
import {PUT} from '../../../helpers/services/constants/API_CONSTANTS';

const getToken = ({authReducer}) => authReducer.token;
const UserId = ({user}) => user.userData.id;

const updateUsersApi = async (token, body) => {
  return request(
    PUT,
    `${process.env.REACT_APP_API_URL}/my-profile`,
    {body},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};

export function* updateUserEmail({payload}) {
  try {
    const body = {
      ...payload
    };
    const token = yield select(getToken);
    const {status} = yield updateUsersApi(token, body);

    if (status === 204) {
      yield put(SHOW_EMAIL_CHECK_MODAL(false));
      toast.success(
        'Email has been updated successfully and can be used to log in'
      );
      yield getUserData();
      yield getAccountStepsStatus();
      yield put(UPDATE_USER_DATA_SUCCESS(payload));
    }
    return true;
  } catch (error) {
    if (error.response.status === 405) {
      toast.error('User with this email is already registered!');
      return false;
    }
    toast.error(`Error occurred - please try again [${error}]`);
    return false;
  }
}

function* modalsSaga() {
  yield takeEvery(UPDATE_EMAIL, updateUserEmail);
}

export default modalsSaga;
