import * as Yup from 'yup';

const phoneRegex = /^([+]1)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const BookADriverSchema = Yup.object()
  .shape({
    FirstName: Yup.string()
      .required('Please enter first name')
      .trim('The field cannot contain only spaces.')
      .max(50, 'First name cannot be more than 50 characters'),
    LastName: Yup.string()
      .required('Please enter last name')
      .trim('The field cannot contain only spaces.')
      .max(50, 'Last name cannot be more than 50 characters'),
    Phone: Yup.string()
      .required('Please enter phone')
      .matches(phoneRegex, 'Please enter valid phone'),
    Address: Yup.string()
      .required('Please enter dropoff address')
      .trim('Please enter dropoff address'),
    StreetNumber: Yup.string()
      .required(
        'Your address does not have any street number specified. Please select another address or choose address with street number specified!'
      )
      .trim(
        'Your address does not have any street number specified. Please select another address or choose address with street number specified!'
      ),
    Street: Yup.string()
      .required(
        'Your address does not have any street specified. Please select another address or choose address with street specified!'
      )
      .trim(
        'Your address does not have any street specified. Please select another address or choose address with street specified!'
      ),
    City: Yup.string()
      .required(
        'Your address does not have any city specified. Please select another address or choose address with city specified!'
      )
      .trim(
        'Your address does not have any city specified. Please select another address or choose address with city specified!'
      ),
    State: Yup.string()
      .required(
        'Your address does not have any state specified. Please select another address or choose address with state specified!'
      )
      .trim(
        'Your address does not have any state specified. Please select another address or choose address with state specified!'
      ),
    ZipCode: Yup.string()
      .required(
        'Your address does not have any zip code specified. Please select another address or choose address with zip code specified!'
      )
      .trim(
        'Your address does not have any zip code specified. Please select another address or choose address with zip code specified!'
      ),
    Email: Yup.string()
      .required('Email field is required!')
      .matches(emailRegex, 'Please enter valid email!'),
    Subtotal: Yup.number().required('Please enter subtotal').min(1),
    Amount: Yup.number()
      .min(2, 'Minimum tip is $2')
      .max(150, 'Please enter amount less than 150'),
    ItemCount: Yup.number()
      .required('Must have at least one item')
      .min(1, 'Must have at least one item'),
    Notes: Yup.object({
      apt: Yup.string().trim('The field cannot contain only spaces.'),
      floor: Yup.string().trim('The field cannot contain only spaces.'),
      entrance: Yup.string().trim('The field cannot contain only spaces.'),
      notes: Yup.string().trim('The field cannot contain only spaces.')
    })
    // Notes: Yup.string()
    //   .trim('The field cannot contain only spaces.')
    //   .max(
    //     250,
    //     'Delivery special instructions cannot be more than 50 characters'
    //   )
  })
  .test(function (value) {
    if (value.Address && !value.FullAddress) {
      return this.createError({
        path: 'Address',
        message: 'Please choose the suggested address from the dropdown!'
      });
    }
    if (!value.Amount && value.CourierTip === 'Custom') {
      return this.createError({
        path: 'Amount',
        message: 'Minimum tip is $2'
      });
    }
    return true;
  });

export default BookADriverSchema;
