import {put, select, takeEvery} from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import {
  GET_REPLIED_REVIEWS,
  GET_REPLIED_REVIEWS_SUCCESS
} from '../global/user/action';
import {
  createReplyApi,
  getAllReviewsApi,
  getRepliedReviewsByUserApi,
  getRepliesApi,
  getReviewByIdApi
} from '../../helpers/services/api/reviews';
import {
  CREATE_REPLY,
  CREATE_REPLY_SUCCESS,
  GET_REPLIES_SUCCESS,
  GET_REVIEW,
  GET_REVIEW_SUCCESS,
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS
} from './action';
import {getStorageToken} from '../../helpers/localStorage/authStorage';

const getReviewsState = ({reviewsReducer}) => reviewsReducer.pagination;
const getToken = ({authReducer}) => authReducer.token;

function* createReplySaga({payload}) {
  try {
    const {data} = yield createReplyApi(payload);
    if (data) {
      yield put(CREATE_REPLY_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getReviews({payload}) {
  try {
    const reviewsParams = yield select(getReviewsState);
    const pagination = {
      ...reviewsParams,
      ...payload
    };
    const {data} = yield getAllReviewsApi(pagination);
    if (data) {
      yield put(GET_REVIEWS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getRepliedReviewsByUserSaga({payload}) {
  try {
    const userId = jwt_decode(getStorageToken().token).id;
    const {data} = yield getRepliedReviewsByUserApi(payload, userId);
    if (data) {
      yield put(GET_REPLIED_REVIEWS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getReviewById({payload}) {
  try {
    const token = yield select(getToken);
    const {data} = yield getReviewByIdApi(payload, token);
    const {data: d2} = yield getRepliesApi(payload, token);
    if (data && d2) {
      yield put(GET_REVIEW_SUCCESS(data));
      yield put(GET_REPLIES_SUCCESS(d2));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* reviewsSaga() {
  yield takeEvery(GET_REVIEW, getReviewById);
  yield takeEvery(GET_REVIEWS, getReviews);
  yield takeEvery(CREATE_REPLY, createReplySaga);
  yield takeEvery(GET_REPLIED_REVIEWS, getRepliedReviewsByUserSaga);
}

export default reviewsSaga;
