import React from 'react';

const IconSidebarNew = () => (
  <svg
    width='33'
    height='36'
    viewBox='0 0 33 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.023 3.59668H11.428C10.6107 3.59668 9.94531 4.26166 9.94531 5.07939V6.66781C9.94531 7.48555 10.6107 8.15053 11.428 8.15053H13.023C13.8403 8.15053 14.5057 7.48555 14.5057 6.66781V5.07939C14.5057 4.26166 13.8403 3.59668 13.023 3.59668ZM13.023 6.66781H11.428V5.07939L13.023 5.07867V6.66781Z'
      fill='white'
    />
    <path
      d='M22.6835 6.66797H16.8576C16.4478 6.66797 16.1162 6.99991 16.1162 7.40933C16.1162 7.81874 16.4478 8.15068 16.8576 8.15068H22.6835C23.0932 8.15068 23.4248 7.81874 23.4248 7.40933C23.4248 6.99991 23.0932 6.66797 22.6835 6.66797Z'
      fill='white'
    />
    <path
      d='M18.7112 5.50681C18.8502 5.62518 19.021 5.68418 19.1926 5.68418C19.3657 5.68418 19.538 5.62409 19.677 5.50391L21.0757 4.29559C21.3856 4.02771 21.4203 3.55966 21.1524 3.2498C20.8831 2.93921 20.4154 2.90663 20.107 3.17342L19.1897 3.96581L18.966 3.77468C18.6554 3.51007 18.187 3.54554 17.9206 3.85758C17.6549 4.16889 17.6918 4.63694 18.0031 4.90265L18.7112 5.50681Z'
      fill='white'
    />
    <path
      d='M13.023 11.2896H11.428C10.6107 11.2896 9.94531 11.9545 9.94531 12.7723V14.3607C9.94531 15.1784 10.6107 15.8434 11.428 15.8434H13.023C13.8403 15.8434 14.5057 15.1784 14.5057 14.3607V12.7723C14.5057 11.9545 13.8403 11.2896 13.023 11.2896ZM13.023 14.3607H11.428V12.7723L13.023 12.7715V14.3607Z'
      fill='white'
    />
    <path
      d='M22.6835 14.3604H16.8576C16.4478 14.3604 16.1162 14.6923 16.1162 15.1017C16.1162 15.5111 16.4478 15.8431 16.8576 15.8431H22.6835C23.0932 15.8431 23.4248 15.5111 23.4248 15.1017C23.4248 14.6923 23.0932 14.3604 22.6835 14.3604Z'
      fill='white'
    />
    <path
      d='M19.1926 13.3766C19.3657 13.3766 19.538 13.3165 19.677 13.1963L21.0757 11.988C21.3856 11.7201 21.4203 11.252 21.1524 10.9422C20.8831 10.6316 20.4154 10.599 20.107 10.8658L19.1897 11.6582L18.966 11.4671C18.6554 11.2017 18.187 11.2379 17.9206 11.55C17.6549 11.8613 17.6918 12.3293 18.0031 12.595L18.7112 13.1992C18.8502 13.3176 19.021 13.3766 19.1926 13.3766Z'
      fill='white'
    />
    <path
      d='M13.023 18.9819H11.428C10.6107 18.9819 9.94531 19.6469 9.94531 20.4646V22.0531C9.94531 22.8708 10.6107 23.5358 11.428 23.5358H13.023C13.8403 23.5358 14.5057 22.8708 14.5057 22.0531V20.4646C14.5057 19.6469 13.8403 18.9819 13.023 18.9819ZM13.023 22.0531H11.428V20.4646L13.023 20.4639V22.0531Z'
      fill='white'
    />
    <path
      d='M22.6835 22.0532H16.8576C16.4478 22.0532 16.1162 22.3852 16.1162 22.7946C16.1162 23.204 16.4478 23.5359 16.8576 23.5359H22.6835C23.0932 23.5359 23.4248 23.204 23.4248 22.7946C23.4248 22.3852 23.0932 22.0532 22.6835 22.0532Z'
      fill='white'
    />
    <path
      d='M19.1926 21.0694C19.3657 21.0694 19.538 21.0093 19.677 20.8892L21.0757 19.6808C21.3856 19.413 21.4203 18.9449 21.1524 18.635C20.8831 18.3245 20.4154 18.2919 20.107 18.5587L19.1897 19.3511L18.966 19.1599C18.6554 18.8946 18.187 18.9308 17.9206 19.2428C17.6549 19.5541 17.6918 20.0222 18.0031 20.2879L18.7112 20.8921C18.8502 21.0104 19.021 21.0694 19.1926 21.0694Z'
      fill='white'
    />
    <path
      d='M32.6089 29.1598C32.6089 22.0804 32.6089 16.7529 32.6089 9.32525C32.6089 8.01919 31.5461 6.95674 30.24 6.95674H26.8525V1.85339C26.8525 0.831493 26.0214 0 24.9991 0H8.37068C7.34842 0 6.51728 0.831493 6.51728 1.85339V6.95674H2.36814C1.06208 6.95674 0 8.01919 0 9.32525V29.1598C0 30.4659 1.06208 31.5287 2.36814 31.5287H11.9857L10.2494 34.5173H8.89919C8.48941 34.5173 8.15783 34.8492 8.15783 35.2586C8.15783 35.6681 8.48941 36 8.89919 36H23.7097C24.1195 36 24.451 35.6681 24.451 35.2586C24.451 34.8492 24.1195 34.5173 23.7097 34.5173H22.3588L20.623 31.5287H30.24C31.5461 31.5287 32.6089 30.4659 32.6089 29.1598ZM26.8525 8.43946H30.24C30.7287 8.43946 31.1262 8.83692 31.1262 9.32525V25.2083H26.8525V8.43946ZM8 1.85339C8 1.64887 8.16652 1.48271 8.37068 1.48271H24.9991C25.2033 1.48271 25.3698 1.64887 25.3698 1.85339V25.0878H8V1.85339ZM1.48271 9.32525C1.48271 8.83692 1.88018 8.43946 2.36814 8.43946H6.51728V25.2083H1.48271V9.32525ZM20.6443 34.5173H11.9638L13.6999 31.5287H18.909L20.6443 34.5173ZM2.36814 30.046C1.88018 30.046 1.48271 29.6485 1.48271 29.1598V26.691H31.1262V29.1598C31.1262 29.6485 30.7287 30.046 30.24 30.046C25.5519 30.0425 2.45921 30.046 2.36814 30.046Z'
      fill='white'
    />
    <path
      d='M16.304 27.6206C15.8942 27.6206 15.5293 27.9862 15.5293 28.3956C15.5293 28.805 15.8942 29.1703 16.304 29.1703C16.7137 29.1703 17.0786 28.805 17.0786 28.3956C17.0786 27.9862 16.7137 27.6206 16.304 27.6206Z'
      fill='white'
    />
  </svg>
);

export default IconSidebarNew;
