import styled from 'styled-components';
import IconLocation from '../../assets/icon-location.svg';

const Input = styled.input`
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;
  padding: 0 17px;
  height: 56px;
  outline: none;
  width: 100%;
  -webkit-appearance: none;

  &:read-only {
    cursor: default;
  }

  &.error {
    border: 1px solid #ba0302;
  }

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.FieldNumber {
    width: 110px;

    @media (max-width: 1600px) {
      width: 75px;
    }
  }

  &.TypeNumberArrows {
    -webkit-appearance: button;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: button;
    }
  }

  &.FieldTaxName {
    width: 170px;
  }

  &.mLeft {
    margin-left: 16px;
  }

  &.Sm {
    width: 70px;
  }

  &.widthAuto {
    flex: 1 1 auto;
  }

  &::placeholder {
    color: #929cb9;
  }

  &.AutoComplete {
    padding-left: 35px;
    background-image: url(${IconLocation});
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center left 13px;
  }

  &.blackPlaceholder::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #2f3d48;
  }
`;

export default Input;
