import React from 'react';
import {useHistory} from 'react-router-dom';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import {ButtonPrimary} from '../../../../components/buttons';
import Body3 from '../../../../components/typography/Body3';
import TipSign from '../../../../components/SauceChip';
import IconBulb from '../../../../assets/uberEats/icon.bulb.svg';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';

const BYOCConfiguredPage = () => {
  const history = useHistory();

  return (
    <DashboardInner className='paddingXl mTop30'>
      <FlexContainer className='flexColumn align-center justify-center gap30'>
        <FlexItem>
          <Subtitle5>
            Sauce is currently <span className='red'>NOT delivering</span> your
            UberEats orders
          </Subtitle5>
        </FlexItem>
        <FlexItem className='centerText'>
          <Body3>
            You haven’t finished the activation process successfully yet.
            <br />
            OR
            <br />
            You turned this feature off in your UberEats manager app. <br />
            OR <br />
            Your first UberEats order with Sauce hasn’t arrived yet.
          </Body3>
        </FlexItem>

        <FlexItem className='w100 centerText marginBottomXl'>
          <ButtonPrimary
            className='ButtonMd2 w280'
            onClick={() => {
              history.push('/uber-eats/byoc-wizard');
            }}
          >
            <span>Show me activation wizard</span>
          </ButtonPrimary>
        </FlexItem>
        <FlexItem className='w100'>
          <TipSign
            icon={IconBulb}
            text={
              <p>
                Click the <span>chat button</span> so our team can help you
                maximize your UberEats revenues with Sauce delivery.
              </p>
            }
          />
        </FlexItem>
      </FlexContainer>
    </DashboardInner>
  );
};

export default BYOCConfiguredPage;
