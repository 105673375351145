import React from 'react';

const IconSidebarAnalytics = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.9737 9.55948C12.1718 9.55948 11.3863 9.43427 10.6419 9.18863C10.2786 9.06342 9.866 9.15989 9.62722 9.40348L8.15137 10.5181C6.45795 9.61423 5.37416 8.53111 4.48265 6.85L5.56711 5.40906C5.84011 5.13606 5.93796 4.73647 5.82096 4.36221C5.57327 3.613 5.44738 2.82684 5.44738 2.02632C5.44738 1.46046 4.98692 1 4.42107 1H2.02632C1.46046 1 1 1.46046 1 2.02632C1 8.62826 6.37174 14 12.9737 14C13.5395 14 14 13.5395 14 12.9737V10.5858C14 10.0199 13.5395 9.55948 12.9737 9.55948Z'
      fill='#929CB9'
    />
    <path
      d='M13.2539 1.42638C13.4168 1.49403 13.5462 1.62348 13.6139 1.78638C13.648 1.86653 13.6661 1.9526 13.6672 2.03971V4.70638C13.6672 4.88319 13.597 5.05276 13.4719 5.17778C13.3469 5.30281 13.1773 5.37305 13.0005 5.37305C12.8237 5.37305 12.6541 5.30281 12.5291 5.17778C12.4041 5.05276 12.3339 4.88319 12.3339 4.70638V3.65305L10.1339 5.84638C10.009 5.97055 9.83999 6.04024 9.66386 6.04024C9.48774 6.04024 9.31877 5.97055 9.19386 5.84638C9.06969 5.72147 9 5.5525 9 5.37638C9 5.20026 9.06969 5.03129 9.19386 4.90638L11.3872 2.70638H10.3339C10.1571 2.70638 9.98748 2.63614 9.86246 2.51112C9.73743 2.38609 9.66719 2.21652 9.66719 2.03971C9.66719 1.8629 9.73743 1.69333 9.86246 1.56831C9.98748 1.44328 10.1571 1.37305 10.3339 1.37305H13.0005C13.0876 1.3741 13.1737 1.39222 13.2539 1.42638Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarAnalytics;
