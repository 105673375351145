import {put, takeEvery} from 'redux-saga/effects';
import {GET_SALES_COUNT, GET_SALES_COUNT_SUCCESS} from './action';
import {getSalesCountApi} from '../../helpers/services/api/analytics';

function* getSalesCount({payload}) {
  try {
    const timestamp = new Date().getTime();
    const {data} = yield getSalesCountApi(payload.startDate, payload.endDate);
    if (data) {
      yield put(GET_SALES_COUNT_SUCCESS({data: data.data, timestamp}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* analyticsSalesCountSaga() {
  yield takeEvery(GET_SALES_COUNT, getSalesCount);
}

export default analyticsSalesCountSaga;
