import styled from 'styled-components';

export const DashboardContent = styled.div`
  display: block;
  padding: 110px 100px 200px 372px;
  overflow-x: hidden;

  @media (max-width: 1650px) {
    padding: 110px 48px 200px 320px;
  }
  @media (max-width: 1440px) {
    padding: 110px 38px 200px 310px;
  }
  @media (max-width: 1250px) {
    padding: 110px 80px 170px 80px;
  }

  .HeadlineDesktop {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    padding: 110px 60px 170px 60px;
  }
  @media (max-width: 1100px) {
    padding: 110px 50px 170px 50px;
  }
  @media (max-width: 1000px) {
    padding: 110px 40px 70px 40px;
  }

  &.TabletPTop {
    @media (max-width: 1200px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1100px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1000px) {
      padding: 110px 40px 70px 40px;
    }
  }
`;

export const ReviewsInner = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 24px 20px;
  margin-top: 16px;

  &.DashboardInnerW50 {
    flex: 0 0 calc(50% - 20px);
    margin: 20px 10px 0 10px;

    @media (max-width: 1600px) {
      margin: 20px 0 0 0;
    }
  }
`;
