/*eslint-disable*/
import React, {Component} from 'react';

import GoogleMap from 'google-map-react';
import PropTypes from 'prop-types';

import {initLocationAfterMapPick} from './utils';
import MarkerImg from '../../../../assets/location-marker.svg';

class LocationMap extends Component {
  constructor(props) {
    super(props);
  }

  marker = null;

  static defaultProps = {
    center: {
      lat: 32.06,
      lng: 34.77
    },
    zoom: 10
  };

  getGeocoderInfo(maps, param, locationAddress, cb) {
    const geocoder = new maps.Geocoder();
    geocoder.geocode(param, (results, status) => {
      if (status === 'OK') {
        cb(results.find((res) => res?.formatted_address === locationAddress));
      }
    });
  }

  getGeocoderAddress(
    maps,
    latLngParam,
    addressParam,
    locationAddress,
    successCallback
  ) {
    this.getGeocoderInfo(maps, latLngParam, locationAddress, (found) => {
      if (found) {
        successCallback(found);
      } else {
        this.getGeocoderInfo(maps, addressParam, locationAddress, (found) => {
          successCallback(found);
        });
      }
    });
  }

  renderMarkers(map) {
    const {
      values,
      setMap,
      isCreating,
      setFieldValue,
      setMapMarker
    } = this.props;
    if (!isCreating) {
      const latlng = new google.maps.LatLng(values.Latitude, values.Longitude);
      if (map) {
        // map.setZoom(15);
        map.setCenter(latlng);
      }

      // this.marker = new google.maps.Marker({
      //   position: latlng,
      //   map: map,
      //   icon: MarkerImg
      // });

      setMapMarker(
        new google.maps.Marker({
          position: latlng,
          map: map,
          icon: MarkerImg
        })
      );

      setFieldValue('FullAddress', values.Address);
      setFieldValue('City', values.Address); // TODO: Dirty hack for now!!!, was done to mute validator if nothing changed, should bo refactored
      setMap(map);
    }
  }

  onMapClick = async (e) => {
    const {setFieldValue, setMapMarker, mapMarker, setMap} = this.props;

    const geocoder = new google.maps.Geocoder();
    const {lat, lng} = e;

    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng)
    };

    geocoder.geocode({location: latlng}, async (results, status) => {
      if (status === 'OK') {
        // Select the exact place that was clicked on the map
        if (results[0]) {
          //  this.marker.setMap(null);

          await initLocationAfterMapPick(
            results[0],
            setFieldValue,
            this.props.map,
            mapMarker
          );
        }
      }
    });
  };

  render() {
    const {center, zoom} = this.props;

    return (
      <div id='map' style={{height: '400px', width: '100%'}}>
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={center}
          defaultZoom={zoom}
          onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
          onClick={this.onMapClick}
        />
      </div>
    );
  }
}

LocationMap.propTypes = {
  values: PropTypes.any,
  center: PropTypes.any,
  zoom: PropTypes.number,
  setFieldValue: PropTypes.func
};

export default LocationMap;
