import styled from 'styled-components';

const TextButton = styled.button`
  display: flex;
  border: none;
  background: none;
  outline: none;
  color: #17c981;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > span {
    padding-left: 10px;
  }
`;

export default TextButton;
