import {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
  SERVER_URL,
  SERVER_MENU_SYNC_URL
} from '../constants/API_CONSTANTS';
import {
  DELETE_FILE,
  DELETE_MENU_EDITOR_ITEM,
  DELETE_MENU_EDITOR_MODIFIER,
  DELETE_MENU_EDITOR_MODIFIER_GROUPS,
  DELETE_MENU_EDITOR_OVERVIEW,
  GET_MENU_EDITOR_ALL_MODIFIERS,
  GET_MENU_EDITOR_ITEM_BY_ID,
  GET_MENU_EDITOR_ITEMS,
  GET_MENU_EDITOR_MODIFIER_BY_ID,
  GET_MENU_EDITOR_MODIFIER_GROUP_BY_ID,
  GET_MENU_EDITOR_MODIFIERS,
  GET_MENU_EDITOR_OUT_STOCK,
  GET_MENU_EDITOR_OVERVIEW_BY_ID,
  MENU_EDITOR_OVERVIEW,
  PATCH_MENU_EDITOR_ITEM,
  PATCH_MENU_EDITOR_MODIFIER,
  POST_FILES,
  POST_MENU_EDITOR_ITEMS,
  POST_MENU_EDITOR_MODIFIER,
  POST_MENU_EDITOR_MODIFIER_GROUPS,
  POST_MENU_EDITOR_OVERVIEW,
  UPDATE_MENU_EDITOR_ITEM,
  UPDATE_MENU_EDITOR_MODIFIER,
  UPDATE_MENU_EDITOR_MODIFIER_GROUPS,
  UPDATE_MENU_EDITOR_OVERVIEW,
  UPDATE_SORTING_ORDER,
  PATCH_MENU_EDITOR_SECTION,
  MENU_EDITOR_OVERVIEW_UNAVAILABLE,
  GET_MENU_EDITOR_ITEMS_UNAVAILABLE,
  GET_MENU_EDITOR_MODIFIERS_UNAVAILABLE,
  GET_MENU_EDITOR_SECTION_TREE,
  GET_MENU_EDITOR_ITEM_TREE,
  GET_MENU_EDITOR_MODIFIER_GROUP_TREE,
  GET_MENU_EDITOR_MODIFIER_TREE,
  GET_MENU_EDITOR_HIDDEN_COUNTERS,
  GET_MENU_EDITOR_OUT_OFF_STOCK_COUNTERS
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';
import {TASK_TYPE_LAST_CUSTOM_DATA_UPDATE} from '../../../store/global/globalNotifications/constants';

export const updateSortingOrderApi = async (data) => {
  return request(POST, UPDATE_SORTING_ORDER(), {data});
};

export const bulkUpdateApi = async (uri, data) => {
  return request(PUT, `${SERVER_URL}${uri}`, {data});
};

export const bulkDeleteApi = async (uri, data) => {
  return request(DELETE, `${SERVER_URL}${uri}`, {data});
};

export const menuDataApi = async () => {
  return request(GET, `${SERVER_URL}/menu/data`);
};

export const menuRecursionRelationsApi = async () => {
  return request(GET, `${SERVER_URL}/menu/check-for-recursion`);
};

export const clearMenuRecursionRelationsApi = async (relations) => {
  return request(POST, `${SERVER_URL}/menu/clear-recursion-relations`, {
    data: {relations}
  });
};

export const publishMenuApi = async (menuConfigId) => {
  return request(
    POST,
    `${SERVER_MENU_SYNC_URL}/menu-publish/${menuConfigId}`,
    {},
    {headers: {'Sync-Type': TASK_TYPE_LAST_CUSTOM_DATA_UPDATE}}
  );
};

export const checkIsAllowMenuPublishApi = async (menuConfigId, SyncType) => {
  return request(
    GET,
    `${SERVER_MENU_SYNC_URL}/menu-publish-check/${menuConfigId}`,
    null,
    {
      silent: true,
      headers: {
        'Sync-Type': SyncType
      }
    }
  );
};

export const getOverwiewApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime',
  deepLevel
}) => {
  const params = {page, limit, sort, sortBy, deepLevel};
  return request(GET, MENU_EDITOR_OVERVIEW(params));
};

// MENU_EDITOR_OVERVIEW
export const getOverviewByIdApi = async (id) => {
  return request(GET, GET_MENU_EDITOR_OVERVIEW_BY_ID(id));
};

export const getUnavailableOverviewApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime',
  deepLevel
}) => {
  const params = {page, limit, sort, sortBy, deepLevel};
  return request(GET, MENU_EDITOR_OVERVIEW_UNAVAILABLE(params));
};

export const updateOverviewApi = async (id, data) => {
  return request(PUT, UPDATE_MENU_EDITOR_OVERVIEW(id), {data});
};

export const patchSectionApi = async (id, data) => {
  return request(PATCH, PATCH_MENU_EDITOR_SECTION(id), {data});
};

export const deleteOverviewApi = async (id) => {
  return request(DELETE, DELETE_MENU_EDITOR_OVERVIEW(id));
};

export const postOverviewApi = async (data) => {
  return request(POST, POST_MENU_EDITOR_OVERVIEW(), {data});
};
// MENU_EDITOR_ITEMS
export const getItemByIdApi = async (id) => {
  return request(GET, GET_MENU_EDITOR_ITEM_BY_ID(id));
};

export const getItemsApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime',
  outStock,
  deepLevel
}) => {
  const params = {page, limit, sort, sortBy, outStock, deepLevel};
  return request(GET, GET_MENU_EDITOR_ITEMS(params));
};

export const getItemsUnavailableApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime',
  outStock,
  deepLevel
}) => {
  const params = {page, limit, sort, sortBy, outStock, deepLevel};
  return request(GET, GET_MENU_EDITOR_ITEMS_UNAVAILABLE(params));
};

export const postItemsApi = async (data) => {
  return request(POST, POST_MENU_EDITOR_ITEMS(), {data});
};

export const updateItemApi = async (id, data) => {
  return request(PUT, UPDATE_MENU_EDITOR_ITEM(id), {data});
};

export const patchItemApi = async (id, data) => {
  return request(PATCH, PATCH_MENU_EDITOR_ITEM(id), {data});
};

export const deleteItemApi = async (id) => {
  return request(DELETE, DELETE_MENU_EDITOR_ITEM(id));
};
// MENU_EDITOR_MODIFIERS
export const getModifiersApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime'
}) => {
  const params = {page, limit, sort, sortBy};
  return request(GET, GET_MENU_EDITOR_MODIFIERS(params));
};

export const getOutOfStockApi = async () => {
  return request(GET, GET_MENU_EDITOR_OUT_STOCK());
};

export const getAllModifiersApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime',
  outStock,
  deepLevel
}) => {
  const params = {page, limit, sort, sortBy, outStock, deepLevel};
  return request(GET, GET_MENU_EDITOR_ALL_MODIFIERS(params));
};

export const getModifiersUnavailableApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime'
}) => {
  const params = {page, limit, sort, sortBy};
  return request(GET, GET_MENU_EDITOR_MODIFIERS_UNAVAILABLE(params));
};

export const postModifierApi = async (data) => {
  return request(POST, POST_MENU_EDITOR_MODIFIER(), {data});
};

export const getModifierByIdApi = async (id) => {
  return request(GET, GET_MENU_EDITOR_MODIFIER_BY_ID(id));
};

export const updateModifierApi = async (id, data) => {
  return request(PUT, UPDATE_MENU_EDITOR_MODIFIER(id), {data});
};

export const patchModifierApi = async (id, data) => {
  return request(PATCH, PATCH_MENU_EDITOR_MODIFIER(id), {data});
};

export const deleteModifierApi = async (id) => {
  return request(DELETE, DELETE_MENU_EDITOR_MODIFIER(id));
};

export const postModifierGroupApi = async (data) => {
  return request(POST, POST_MENU_EDITOR_MODIFIER_GROUPS(), {data});
};

export const updateModifierGroupApi = async (id, data) => {
  return request(PUT, UPDATE_MENU_EDITOR_MODIFIER_GROUPS(id), {data});
};

export const deleteModifierGroupApi = async (id) => {
  return request(DELETE, DELETE_MENU_EDITOR_MODIFIER_GROUPS(id));
};

export const getModifierGroupByIdApi = async (id) => {
  return request(GET, GET_MENU_EDITOR_MODIFIER_GROUP_BY_ID(id));
};

export const uploadFileApi = async (params) => {
  return request(POST, POST_FILES(), params);
};

export const deleteFileApi = async (file) => {
  return request(DELETE, DELETE_FILE(file), {});
};

export const getSectionTree = async (id) => {
  return request(GET, GET_MENU_EDITOR_SECTION_TREE(id));
};

export const getItemTree = async (id) => {
  return request(GET, GET_MENU_EDITOR_ITEM_TREE(id));
};

export const getModifierGroupTree = async (id) => {
  return request(GET, GET_MENU_EDITOR_MODIFIER_GROUP_TREE(id));
};

export const getModifierTree = async (id) => {
  return request(GET, GET_MENU_EDITOR_MODIFIER_TREE(id));
};

export const getHiddenCounters = async () => {
  return request(GET, GET_MENU_EDITOR_HIDDEN_COUNTERS());
};

export const getOutOffStockCounters = async () => {
  return request(GET, GET_MENU_EDITOR_OUT_OFF_STOCK_COUNTERS());
};
