import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import cn from 'classnames';

// import {DELETE_USER} from '../../../../store/usersManagement/action';
import {DialogAction, DialogBody} from '../../../../../dashboard/styles';
import DialogTitle from '../../../../../../../components/DialogTitle/styles';
import DialogClose from '../../../../../../../components/DialogClose/styles';
import DialogContainer from '../../../../../../../components/DialogContainer/styles';
import ButtonPrimary from '../../../../../../../components/buttons/ButtonPrimary/styles';
import {ButtonOutline} from '../../../../../../../components/buttons';
import {Caption2} from '../../../../../../../components/typography';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../../../components/FieldInput';

const EmailModal = ({open, onClose, defaultEmail}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(defaultEmail);

  const ACTION = (mail) => {
    console.log(mail);
  };

  const handleSubmit = () => {
    // dispatch(ACTION(modalModel));
    ACTION(email);
    onClose();
  };

  return (
    <Dialog
      style={{background: 'rgba(47,61,72,0.7)'}}
      open={open}
      onClose={onClose}
    >
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={onClose} />
        <DialogTitle>Email us your menu</DialogTitle>
        <Caption2
          style={{margin: '25px 0 50px', textAlign: 'center', fontSize: '16px'}}
        >
          Please confirm your email address
        </Caption2>
        <FieldLabel className='FieldLabelLg'>Your email</FieldLabel>
        <FieldInput
          placeholder={`${defaultEmail}`}
          type='text'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          name='inputEmail'
          // className={cn('blackPlaceholder', {
          //   error: errors?.ImagesUseUrl && touched?.data?.ImagesUseUrl
          // })}
        />
        <DialogBody>
          <DialogAction className='marginTop60'>
            <ButtonPrimary onClick={handleSubmit}>
              <span>Send</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EmailModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultEmail: PropTypes.string
};

EmailModal.defaultProps = {
  open: false,
  onClose: () => {},
  defaultEmail: 'example@mail.com'
};

export default EmailModal;
