import {createReducer} from 'redux-act';
import {
  GET_DISCOUNTS_SUCCESS,
  SET_LOADING,
  UPDATE_DISCOUNT_SUCCESS,
  CLEAR_STATE,
  DELETE_DISCOUNT_SUCCESS,
  PUSH_MODALS_STACK,
  SHIFT_MODALS_STACK,
  SET_DISCOUNT,
  CLEAR_DISCOUNTS,
  SET_PUBLISH_BUTTON_STATE,
  PUBLISH_DISCOUNTS_SUCCESS,
  SET_WARNING_MODAL,
  SET_SORTING,
  SET_BUTTON_STATUS,
  SET_EMAIL_MARKETING_TOGGLE_ERROR,
  OPEN_EMAIL_MARKETING_TURN_OFF_MODAL
} from './action';
import {PUBLISH_STATUS_ENABLE} from './constants';

const initialSorting = () => ({
  sort: 'asc',
  sortBy: 'Name'
});

const initialState = () => ({
  discounts: [],
  LastBotSync: null,
  updatedAt: null,
  publishStatus: PUBLISH_STATUS_ENABLE,
  shouldShowWarningModal: true,
  page: 1,
  totalDocsCount: 0,
  total: 0,
  hasNextPage: false,
  loading: false,
  buttonStatus: false,
  sorting: initialSorting(),
  discount: {
    reason: '',
    Discount: 12,
    DiscountType: 1,
    Name: '',
    MinOrder: '',
    CouponCode: null,
    OneTimeMode: {
      IsEnabled: false
    },
    StartTime: null,
    EndTime: null,
    ShowOnStorefront: false,
    EmailMarketing: false
  },
  modalsStack: [],
  isEmailMarketingToggleError: false,
  isEmailMarketingTurnOffModalOpen: false
});

const discountsReducer = createReducer(
  {
    [GET_DISCOUNTS_SUCCESS]: (state, payload) => {
      const {docs} = payload;
      return {
        ...state,
        discounts: [...state.discounts, ...docs],
        totalDocsCount: payload.totalDocs,
        total: state.total + docs.length,
        hasNextPage: payload.hasNextPage,
        page: payload.page,
        LastBotSync: payload?.LastBotSync,
        updatedAt: payload?.updatedAt
      };
    },
    [SET_LOADING]: (state, payload) => ({
      ...state,
      loading: payload
    }),
    [SET_BUTTON_STATUS]: (state, payload) => ({
      ...state,
      buttonStatus: payload
    }),
    [SET_DISCOUNT]: (state, payload) => ({
      ...state,
      discount: payload
    }),
    [UPDATE_DISCOUNT_SUCCESS]: (state, payload) => ({
      ...state,
      discounts: state.discounts.map((discount) =>
        discount.id === payload.id ? payload : discount
      )
    }),
    [DELETE_DISCOUNT_SUCCESS]: (state, payload) => ({
      ...state,
      discounts: state.discounts.filter((discount) => discount._id !== payload),
      totalDocsCount: state.totalDocsCount - 1
    }),
    [PUSH_MODALS_STACK]: (state, payload) => {
      const stack = [...state.modalsStack];
      stack.push(payload);
      return {
        ...state,
        modalsStack: stack
      };
    },
    [SHIFT_MODALS_STACK]: (state) => {
      const stack = [...state.modalsStack];
      stack.shift();
      return {
        ...state,
        modalsStack: stack
      };
    },
    [CLEAR_STATE]: () => initialState(),
    [CLEAR_DISCOUNTS]: (state) => ({
      ...state,
      discounts: [],
      total: 0,
      hasNextPage: false,
      page: 1,
      totalDocsCount: 0
    }),
    [SET_SORTING]: (state, payload) => {
      return {
        ...state,
        sorting: {
          ...payload
        }
      };
    },
    [SET_PUBLISH_BUTTON_STATE]: (store, status) => ({
      ...store,
      publishStatus: status
    }),
    [SET_WARNING_MODAL]: (store, payload) => ({
      ...store,
      shouldShowWarningModal: payload
    }),
    [PUBLISH_DISCOUNTS_SUCCESS]: (store, payload) => {
      return {
        ...store,
        updatedAt: payload?.updatedAt,
        LastBotSync: payload?.LastBotSync
      };
    },
    [SET_EMAIL_MARKETING_TOGGLE_ERROR]: (state, payload) => {
      return {
        ...state,
        isEmailMarketingToggleError: payload
      };
    },
    [OPEN_EMAIL_MARKETING_TURN_OFF_MODAL]: (state, payload) => {
      return {
        ...state,
        isEmailMarketingTurnOffModalOpen: payload
      };
    }
  },
  initialState()
);

export default discountsReducer;
