import React from 'react';
import PropTypes from 'prop-types';
import {ChipText} from './style';

const SauceChip = ({text, textColor, emphasizedTextColor, icon}) => {
  return (
    <ChipText color={textColor} emphasizedTextColor={emphasizedTextColor}>
      {icon && <img src={icon} alt='tip-icon' />} {text}
    </ChipText>
  );
};

SauceChip.propTypes = {
  text: PropTypes.element.isRequired, // ex: <p>Sample text <span>emphasized Text</span> </p>
  textColor: PropTypes.string,
  emphasizedTextColor: PropTypes.string,
  icon: PropTypes.element
};

SauceChip.defaultProps = {
  textColor: '#969BA0',
  emphasizedTextColor: '#ff2d38',
  icon: null
};

export default SauceChip;
