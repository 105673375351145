import * as Yup from 'yup';
import {INSIGHTS_PERFORMANCE_SETTINGS_VALUES} from 'helpers/constants/Insights/performance';

const InsightsPerformanceSettingsSchema = Yup.object().shape({
  averageDeliveryCommission: Yup.number()
    .min(
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageDeliveryCommission.minValue,
      `Field cannot be less than ${INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageDeliveryCommission.minValue}`
    )
    .max(
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageDeliveryCommission.maxValue,
      `Field cannot be greater than ${INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageDeliveryCommission.maxValue}`
    )
    .required('Mandatory filed'),
  averagePickupCommission: Yup.number()
    .min(
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averagePickupCommission.minValue,
      `Field cannot be less than ${INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averagePickupCommission.minValue}`
    )
    .max(
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averagePickupCommission.maxValue,
      `Field cannot be greater than ${INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averagePickupCommission.maxValue}`
    )
    .required('Mandatory filed'),
  averageMonthlyCateringOrders: Yup.string().required('Mandatory filed')
});

export default InsightsPerformanceSettingsSchema;
