import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import BulkCheckbox from './BulkCheckbox';
import {AccordionItemName} from '../../dashboard/styles';
import {CATEGORY_SCHEME} from '../../../../store/menuEditor/helper/MenuTree';

function CategoryAccordionTitle(props) {
  const {sectionsData} = props;
  const selectedCategories = useSelector(
    (store) => store.menuEditorReducer.selectedCategories
  );
  const isChecked =
    selectedCategories &&
    Object.keys(selectedCategories).length === sectionsData.length;

  return (
    <AccordionItemName className='AccordionItemName'>
      {sectionsData.length && (
        <div className='BulkCheckbox'>
          <BulkCheckbox id='all' type={CATEGORY_SCHEME} checked={isChecked} />
        </div>
      )}
      Categories ({sectionsData.length})
    </AccordionItemName>
  );
}

CategoryAccordionTitle.propTypes = {
  sectionsData: PropTypes.array.isRequired
};

export default CategoryAccordionTitle;
