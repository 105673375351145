import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  .MuiIconButton-root {
    padding: 0 8px;
    &:hover {
      background-color: transparent !important;
    }
  }

  &.justifyCenter {
    justify-content: center;
  }

  .MuiFormControlLabel-label {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color: #232d3a;
    font-family: 'Poppins';
  }
  &.Say2EatCheckboxSoldOut {
    .MuiFormControlLabel-label {
      color: #fca600;
    }
  }
`;

export default CheckboxContainer;
