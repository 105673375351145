import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import {SortWrapper, Wrapper} from './styles';
import {ORDER_DIRECTION} from '../../helpers/constants/Table';

const reverseSort = (order) => {
  return order === ORDER_DIRECTION.ASC
    ? ORDER_DIRECTION.DESC
    : ORDER_DIRECTION.ASC;
};

export default function Table({
  tableModel,
  data,
  rowKey,
  onSort,
  sortBy,
  sortDirection
}) {
  return (
    <Wrapper>
      <TableContainer>
        <MuiTable>
          {tableModel.map((col) => (
            <colgroup style={{width: col.width}} />
          ))}
          <TableHead>
            {tableModel.map((col) => (
              <TableCell>
                {col.sortable ? (
                  <SortWrapper
                    onClick={() =>
                      onSort({
                        sortBy: col.field,
                        sortDirection:
                          col.field === sortBy
                            ? reverseSort(sortDirection)
                            : ORDER_DIRECTION.ASC
                      })
                    }
                    order={sortBy === col.field ? sortDirection : null}
                  >
                    {col.title}
                  </SortWrapper>
                ) : (
                  col.title
                )}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row[rowKey]}>
                {tableModel.map((col) => (
                  <TableCell>
                    {col.renderer ? col.renderer(row) : row[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Wrapper>
  );
}

Table.propTypes = {
  tableModel: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  onSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string
};

Table.defaultProps = {
  onSort: () => {},
  sortBy: '',
  sortDirection: ''
};
