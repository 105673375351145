/* eslint-disable */
import {PUBLISH_STATUS_ENABLE} from '../../constants';
import {arrayFlip} from '../../../../helpers/arrayHelper.js';
import {createSingleBranch} from '../MenuBranch.js';
import {getOuterJoin} from '../../../../helpers/objectHelper.js';
import {setActiveDirection} from '../MenuList.js';

function updateItem(store, id, data) {
  const {activeTab} = store;

  if (!store.itemsMap.hasOwnProperty(id)) {
    return store;
  }

  // клонирование основных объектов Item
  const prevData = store.itemsMap[id] || {};
  const item = {...prevData, ...data};
  const branch = createSingleBranch(item, 'item');
  const activeBranch = {...store.activeItemsBranchMap[id]};
  activeBranch.$children = branch.$children;

  //обновление связей для родительских Sections
  const SectionsDiff = getOuterJoin(
    arrayFlip(prevData.Sections, false),
    arrayFlip(item.Sections, true),
  )

  if (Object.keys(SectionsDiff).length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const MerchantSectionId in SectionsDiff) {
      if (!store.sectionsMap.hasOwnProperty(MerchantSectionId)) {
        continue;
      }

      const section = {...store.sectionsMap[MerchantSectionId]};

      section.Items = SectionsDiff[MerchantSectionId]
        ? [...section.Items, id]
        : section.Items.filter(PosId => PosId !== id);
      store.sectionsMap[MerchantSectionId]= section;
      isChangeMap = true;

      if (store.sectionsBranchMap.hasOwnProperty(MerchantSectionId)) {
        store.sectionsBranchMap[MerchantSectionId].$children = [...section.Items];
        store.sectionsBranchMap[MerchantSectionId] = {...store.sectionsBranchMap[MerchantSectionId]};
        isChangeBranchMap = true;

        if (store.activeSectionBranchMap.hasOwnProperty(MerchantSectionId)) {
          store.activeSectionBranchMap[MerchantSectionId].$children = [...section.Items];
          store.activeSectionBranchMap[MerchantSectionId] = {...store.activeSectionBranchMap[MerchantSectionId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.sectionsMap = {...store.sectionsMap};
    }

    if(isChangeBranchMap) {
      store.sectionsBranchMap = {...store.sectionsBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeSectionBranchMap = {...store.activeSectionBranchMap};
    }
  }

  //обновление связей для дочерних OptionsGroups
  const GroupsDiff = getOuterJoin(
    arrayFlip(prevData.OptionsGroups, false),
    arrayFlip(item.OptionsGroups, true),
  )

  if (Object.keys(GroupsDiff).length) {
    let isChangeMap = false;

    for (const PosId in GroupsDiff) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};

      group.MenuItems = GroupsDiff[PosId] ? [...group.MenuItems, id] : group.MenuItems.filter(sid => sid !== id);
      store.groupsMap[PosId] = group;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }
  }

  // перезапись основных объектов секции
  store.itemsMap[id] = item;
  store.itemsBranchMap[id] = branch;
  store.activeItemsBranchMap[id] = activeBranch;

  //чистка item из списка скрытых, если статус поменялся
  if (activeTab === 'HIDDEN') {
    store.activeItemsDirection = setActiveDirection(
      store.itemsDirection.filter((id) => store.itemsMap[id].Invisible === true),
      store.activeItemsBranchMap
    );
  }

  //чистка item из списка недоступных, если статус поменялся
  if (activeTab === 'OUT_OFF_STOCK') {
    store.activeItemsDirection = setActiveDirection(
      store.itemsDirection.filter((id) => store.itemsMap[id].InStock === false),
      store.activeItemsBranchMap
    );
  }

  return store;
}

export function updateOneItem(prevStore, payload) {
  let store = {...prevStore};
  const {id, data} = payload;

  store = updateItem(store, id, data);

  return getNextStore(store);
}

export function updateManyItems(prevStore, payload) {
  let store = {...prevStore};
  const {ids, data} = payload;

  ids.forEach(id => (store = updateItem(store, id, data)));

  return getNextStore(store);
}

function clearItem (store, itemId) {
  const oldItem = store.itemsMap[itemId];

  //разрываем связи с родительскими Sections
  if (oldItem.Sections.length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const MerchantSectionId of oldItem.Sections) {
      if (!store.sectionsMap.hasOwnProperty(MerchantSectionId)) {
        continue;
      }

      const section = {...store.sectionsMap[MerchantSectionId]};
      section.Items = section.Items.filter(sid => sid !== itemId);
      store.sectionsMap[MerchantSectionId] = section;
      isChangeMap = true;

      if (store.sectionsBranchMap.hasOwnProperty(MerchantSectionId)) {
        store.sectionsBranchMap[MerchantSectionId].$children = [...section.Items];
        store.sectionsBranchMap[MerchantSectionId] = {...store.sectionsBranchMap[MerchantSectionId]};
        isChangeBranchMap = true;

        if (store.activeSectionBranchMap.hasOwnProperty(MerchantSectionId)) {
          store.activeSectionBranchMap[MerchantSectionId].$children = [...section.Items];
          store.activeSectionBranchMap[MerchantSectionId] = {...store.activeSectionBranchMap[MerchantSectionId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.sectionsMap = {...store.sectionsMap};
    }

    if(isChangeBranchMap) {
      store.sectionsBranchMap = {...store.sectionsBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeSectionBranchMap = {...store.activeSectionBranchMap};
    }
  }

  //разрываем связи с дочерними OptionsGroups
  if (oldItem.OptionsGroups.length) {
    let isChangeMap = false;

    for (const PosId of oldItem.OptionsGroups) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};
      group.MenuItems = group.MenuItems.filter(sid => sid !== itemId);
      store.groupsMap[PosId] = group;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }
  }

  delete store.itemsMap[itemId];
  delete store.itemsBranchMap[itemId];
  delete store.activeItemsBranchMap[itemId];

  return store;
}

export function clearOneItem (prevStore, itemId) {
  let store = {...prevStore};
  const filterDirection = (id) => id !== itemId;

  store = clearItem(store, itemId);
  store.itemsDirection = store.itemsDirection.filter(filterDirection);
  store.activeItemsDirection = store.activeItemsDirection.filter(filterDirection);

  return getNextStore(store);
}

export function clearManyItems (prevStore, ids=[]) {
  let store = {...prevStore};
  const idsMap = arrayFlip(ids);
  const filterDirection = (id) => !idsMap.hasOwnProperty(id);

  ids.forEach(itemId => (store = clearItem(store, itemId)));
  store.itemsDirection = store.itemsDirection.filter(filterDirection);
  store.activeItemsDirection = store.activeItemsDirection.filter(filterDirection);

  return getNextStore(store);
}

function getNextStore(store) {
  return {
    ...store,
    itemsMap: {...store.itemsMap},
    itemsBranchMap: {...store.itemsBranchMap},
    activeItemsBranchMap: {...store.activeItemsBranchMap},
    publishStatus: PUBLISH_STATUS_ENABLE
  }
}
