import {createReducer} from 'redux-act';
import {
  SET_BAD_NEW_DELIVERED_ORDERS,
  CLEAR_BAD_NEW_DELIVERED_ORDERS,
  SET_BAD_DELIVERED_ORDERS,
  CLEAR_BAD_DELIVERED_ORDERS,
  SET_BAD_DELIVERED_ORDERS_COUNT,
  SET_BAD_TABLE_CONFIG,
  SET_BAD_ACTIVE_ORDERS,
  SET_BAD_NEW_ACTIVE_ORDERS,
  CLEAR_BAD_NEW_ACTIVE_ORDERS,
  SET_BAD_PREV_ACTIVE_ORDERS
} from './action';

const initialConfig = () => ({
  status: 'delivered',
  page: '0',
  perPage: '50',
  sortField: 's2e_meta.pickup_eta',
  sortDir: 'desc',
  to: encodeURIComponent(new Date().toISOString()),
  from: '2022-01-10T00%3A00%3A00.000Z'
});

const initialState = () => ({
  historyOrders: [],
  orderCount: 0,
  deliveredOrders: undefined,
  newDeliveredOrders: [],
  activeOrders: undefined,
  prevActiveOrders: [],
  newActiveOrders: [],
  config: initialConfig()
});

const bookADriverDeliveredOrdersReducer = createReducer(
  {
    [SET_BAD_NEW_DELIVERED_ORDERS]: (state, payload) => ({
      ...state,
      newDeliveredOrders: payload
    }),
    [SET_BAD_DELIVERED_ORDERS]: (state, payload) => ({
      ...state,
      deliveredOrders: payload.data
    }),
    [SET_BAD_ACTIVE_ORDERS]: (state, payload) => ({
      ...state,
      activeOrders: payload.data
    }),
    [SET_BAD_NEW_ACTIVE_ORDERS]: (state, payload) => ({
      ...state,
      newActiveOrders: payload
    }),
    [SET_BAD_PREV_ACTIVE_ORDERS]: (state, payload) => ({
      ...state,
      prevActiveOrders: payload
    }),
    [CLEAR_BAD_NEW_ACTIVE_ORDERS]: (state) => ({
      ...state,
      newActiveOrders: []
    }),
    [CLEAR_BAD_DELIVERED_ORDERS]: (state) => ({
      ...state,
      deliveredOrders: undefined
    }),
    [CLEAR_BAD_NEW_DELIVERED_ORDERS]: (state) => ({
      ...state,
      newDeliveredOrders: []
    }),
    [SET_BAD_DELIVERED_ORDERS_COUNT]: (state, payload) => ({
      ...state,
      orderCount: payload
    }),
    [SET_BAD_TABLE_CONFIG]: (state, data) => {
      return {
        ...state,
        config: {
          ...state.config,
          ...data
        }
      };
    }
  },
  initialState()
);

export default bookADriverDeliveredOrdersReducer;
