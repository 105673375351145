import * as Yup from 'yup';

const CancelDeliveryOrderSchema = Yup.object().shape({
  reasonComment: Yup.string()
    .required('Comment is required')
    .trim()
    .max(250, 'Reason cannot be more than 250 characters')
    .nullable()
});

export default CancelDeliveryOrderSchema;
