import {createAction} from 'redux-act';

export const SAVE_STEP_DATA = createAction('selfCreateAccount/SAVE_STEP_DATA');
export const POST_STEP = createAction('selfCreateAccount/POST_STEP');
export const POST_STEP_SUCCESS = createAction(
  'selfCreateAccount/POST_STEP_SUCCESS'
);
export const GET_STEPS = createAction('selfCreateAccount/GET_STEPS');
export const GET_STEPS_SUCCESS = createAction(
  'selfCreateAccount/GET_STEPS_SUCCESS'
);

export const SHOW_STEP_MODAL = createAction(
  'selfCreateAccount/SHOW_STEP_MODAL'
);

export const SET_FINAL_SETUP = createAction(
  'selfCreateAccount/SET_FINAL_SETUP'
);

export const GET_COUNTRIES = createAction('selfCreateAccount/GET_COUNTRIES');
export const GET_COUNTRIES_SUCCESS = createAction(
  'selfCreateAccount/GET_COUNTRIES_SUCCESS'
);

export const SET_ROUTE_STEP = createAction('selfCreateAccount/SET_ROUTE_STEP');

export const CONTROL_MODALS = createAction('selfCreateAccount/CONTROL_MODALS');
export const CONTROL_SIDEBAR_PROGRESS = createAction(
  'selfCreateAccount/CONTROL_SIDEBAR_PROGRESS'
);
