import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import {Select} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';

export default function RefundFaultSelect({
  values,
  errors,
  touched,
  selectFaultHandler
}) {
  const options = [
    {name: 'Restaurant', type: 1},
    {name: 'Customer', type: 2}
  ];

  const fault = values.refundType === 'Full' ? 'faultFull' : 'faultPartial';

  return (
    <Box sx={{minWidth: 120}} className='Say2EatDrop'>
      <FormControl
        fullWidth
        variant='outlined'
        className={cn({
          error: errors[fault] && touched[fault] && 'error'
        })}
      >
        <Select
          displayEmpty
          id='refund-fault-select'
          value={values[fault]}
          name='fault'
          onChange={(e) => selectFaultHandler(e)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem disableRipple disabled className='reportTypeItem' value=''>
            Select responsible party
          </MenuItem>
          {options.map((item) => (
            <MenuItem
              className='reportTypeItem'
              key={nanoid()}
              value={item.name}
              disableRipple
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <ErrorSignWrapper className='positionBottom'>
          <ErrorSign>{touched[fault] && errors[fault]}</ErrorSign>
        </ErrorSignWrapper>
      </FormControl>
    </Box>
  );
}

RefundFaultSelect.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  selectFaultHandler: PropTypes.func.isRequired
};
