import React from 'react';
import {useDispatch} from 'react-redux';
import T from 'prop-types';
import moment from 'moment';
import {
  InsightsPerformanceCard,
  InsightsPerformanceCardButton,
  InsightsPerformanceCardInfo,
  InsightsPerformanceCardName,
  InsightsPerformanceCardNameContainer,
  InsightsPerformanceCardValue
} from 'routes/App/Insights/Performance/styles';
import {INSIGHTS_PERFORMANCE_CARDS_INFO} from 'helpers/constants/Insights/performance';
import {ButtonInfo} from 'components/buttons';
import {
  INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL,
  INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL
} from 'store/insights/performance/action';

const InsightsPerformanceCardEl = ({card}) => {
  const dispatch = useDispatch();

  const openContactUsModal = (feature) => {
    dispatch(
      INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL({isOpen: true, feature})
    );
  };

  return (
    <InsightsPerformanceCard>
      <InsightsPerformanceCardInfo>
        <div>
          <InsightsPerformanceCardNameContainer>
            <img
              className='cardIcon'
              src={INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.icon}
              alt=''
            />
            <InsightsPerformanceCardName>
              {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.name}
            </InsightsPerformanceCardName>
          </InsightsPerformanceCardNameContainer>
        </div>
        <div>
          <InsightsPerformanceCardValue>
            {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.withDollarSymbol &&
              card?.value !== 'N/A' &&
              '$'}
            {card?.value !== 'N/A'
              ? new Intl.NumberFormat('en-GB', {
                  maximumFractionDigits: 0
                }).format(card?.value)
              : card?.value}
            {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.withPercentSymbol &&
              card?.value !== 'N/A' &&
              '%'}
          </InsightsPerformanceCardValue>
          {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.contactUsButton && (
            <InsightsPerformanceCardButton>
              <div>
                <span
                  onClick={() => {
                    openContactUsModal(card?.title);
                  }}
                >
                  Contact us
                </span>
              </div>
            </InsightsPerformanceCardButton>
          )}
        </div>
      </InsightsPerformanceCardInfo>

      <ButtonInfo className='OutlineBig ButtonInfoDrop ButtonInfoInsightsPerformance'>
        <div className='DropInfo DropInfoUpInsightsPerformance'>
          <b>What:</b> {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.whatText}
          {(INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
            ?.isWhatClickHereSettings ||
            INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
              ?.isWhatClickHereContactUs) && (
            <>
              {' '}
              <span
                className='clickHereLink'
                onClick={() =>
                  INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
                    ?.isWhatClickHereSettings
                    ? dispatch(INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL(true))
                    : openContactUsModal(card?.title)
                }
              >
                {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.clickHereText}
              </span>{' '}
              {
                INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
                  ?.clickHereAdditionalText
              }
            </>
          )}
          <br />
          <br />
          <b>Why:</b> {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.whyText}
          {(INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
            ?.isWhyClickHereSettings ||
            INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
              ?.isWhyClickHereContactUs) && (
            <>
              {' '}
              <span
                className='clickHereLink'
                onClick={() =>
                  INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
                    ?.isWhyClickHereSettings
                    ? dispatch(INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL(true))
                    : openContactUsModal(card?.title)
                }
              >
                {INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]?.clickHereText}
              </span>{' '}
              {
                INSIGHTS_PERFORMANCE_CARDS_INFO[card?.title]
                  ?.clickHereAdditionalText
              }
            </>
          )}
          {card?.startCollectingDate && (
            <>
              <br />
              <br />* Data is from{' '}
              {moment(card.startCollectingDate)
                .utc(true)
                .format('MMMM yyyy')}{' '}
              onwards
            </>
          )}
        </div>
      </ButtonInfo>
    </InsightsPerformanceCard>
  );
};

InsightsPerformanceCardEl.propTypes = {
  card: T.object.isRequired
};

export default InsightsPerformanceCardEl;
