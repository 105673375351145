import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SET_ACTIVE_TAB} from '../../../../store/menuEditor/action';
import MenuEditingOnMobileModal from '../Modals/MenuEditingOnMobile';
import {DashboardAccordions, DashboardSection} from '../../dashboard/styles';
import Dgd from '../DgD';
import {CATEGORY_SCHEME} from '../../../../store/menuEditor/helper/MenuTree';
import ButtonScrollTop from '../../../../components/buttons/ButtonScrollTop';
import ElementsNotFoundMessage from '../ElementsNotFoundMessage';

const CategoriesContainer = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.menuEditorReducer.activeTab);
  const activeTree = useSelector(
    (state) => state.menuEditorReducer.activeSectionsDirection
  );
  const selectedCategories = useSelector(
    (store) => store.menuEditorReducer.selectedCategories
  );

  useEffect(() => dispatch(SET_ACTIVE_TAB('CATEGORY')), []);

  if (!activeTree || activeTab !== 'CATEGORY') {
    return null;
  }

  if (!activeTree.length) {
    return (
      <ElementsNotFoundMessage message='You don’t have any created categories yet.' />
    );
  }

  return (
    <>
      <MenuEditingOnMobileModal />
      <DashboardSection className='Mt20'>
        <DashboardAccordions>
          {Boolean(activeTree.length) && (
            <Dgd
              items={activeTree}
              itemScheme={CATEGORY_SCHEME}
              withBulkCheckbox
              bulkSelectedMap={selectedCategories}
            />
          )}
        </DashboardAccordions>
      </DashboardSection>
      <ButtonScrollTop />
    </>
  );
};

export default CategoriesContainer;
