import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {
  BOARD,
  BOARD_STATUS
} from '../../../../../helpers/constants/adminRealTImeBoard';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import Status from './status';
import TableComponent from '../../../../../components/TableComponent';
import {OrderAlertsContainer, OrdersTableWrapper, Pin} from './styles';
import {ButtonOutline} from '../../../../../components/buttons';
import {
  ActualLateComponent,
  CanceledByDeliveryComponent,
  CanceledByRestComponent,
  CanceledBySauceComponent,
  CateringComponent,
  IconBookADriverComponent,
  FutureOrderAlertComponent,
  LargeOrderComponent,
  LongDeliveryDistanceComponent,
  NewRestComponent,
  PotentialLateComponent,
  RestAboutToCloseComponent,
  RestClosedComponent,
  TierRestaurantComponent,
  TipsComponent,
  UnassignedFewTimesComponent
} from './helpersAlerts';
import ActionsMadeModal from '../Modals/ActionsMadeModal';
import {
  PIN_ORDER,
  UNPIN_ORDER,
  OPEN_ADDITION_INFO_MODAL,
  OPEN_ACTIONS_MADE_MODAL,
  GET_ORDER_ACTIONS,
  SEND_ORDER_ACTIONS,
  RDB_REMOVE_ORDER_MODAL
} from '../../../../../store/realTimeBoard/action';
import IconPin from '../../../../../assets/IconPin';
import ButtonMore from '../../../merchants/components/ButtonMore';
import {findLastOfColorAndUpdate} from '../helpers';

const formatTime = (date, timezone = 0) => {
  const resultDate =
    date?.includes('Z') || date?.includes('+00:00') ? date : `${date}Z`;
  return moment(resultDate)
    .utc()
    .utcOffset(Number(timezone) || 0)
    .format('hh:mm A');
};

const formatRestaurantName = (name) => {
  let result;
  if (name) {
    result = name?.toLowerCase().split(' ').join('-');
  }
  return result;
};

const getRestName = (order) => {
  if (order[BOARD.MERCHANT_NAME]) {
    if (order[BOARD.LOCATIONS_COUNT] > 1 && order[BOARD.LOCATION_NAME]) {
      return (
        <>
          <p>{order[BOARD.MERCHANT_NAME]?.trim()}</p>
          <p>{`(${order[BOARD.LOCATION_NAME]?.trim()})`}</p>
        </>
      );
    }
    return order[BOARD.MERCHANT_NAME];
  }
  return order[BOARD.RESTAURANT_NAME];
};

const diffTime = (promisedTime, actualTime) => {
  let diff = moment
    .duration(moment(promisedTime).diff(moment(actualTime)))
    .asMinutes()
    .toFixed(0);
  if (Number(diff) === 0) {
    diff = '0 min';
  } else if (diff.includes('-')) {
    diff = `${diff} min`;
  } else {
    diff = `+${diff} min`;
  }
  return (
    <>
      {diff && (
        <div
          className={cn('greenText', {
            redText: diff.includes('+')
          })}
        >
          {diff}
        </div>
      )}
    </>
  );
};

const checkAtThePickupStatus = (order) => {
  return (
    order[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE &&
    order[BOARD.COURIER_DISTANCE] <
      process.env.REACT_APP_RDB_COURIER_DISTANCE_TO_RESTAURANT
  );
};

const checkUserIsOwner = (orderData, userData) => {
  return (
    !!orderData[BOARD.ORDER_OWNER] &&
    orderData[BOARD.ORDER_OWNER].user_id === userData.id
  );
};

const getFieldsConfig = (dispatch, isPin, userData) => [
  {
    title: 'Order alerts',
    renderer: (data) => (
      <OrderAlertsContainer>
        {TierRestaurantComponent(data)}
        {PotentialLateComponent(data)}
        {ActualLateComponent(data)}
        {LargeOrderComponent(data)}
        {TipsComponent(data)}
        {RestAboutToCloseComponent(data)}
        {RestClosedComponent(data)}
        {CanceledBySauceComponent(data)}
        {CanceledByRestComponent(data)}
        {CanceledByDeliveryComponent(data)}
        {CateringComponent(data)}
        {NewRestComponent(data)}
        {LongDeliveryDistanceComponent(data)}
        {UnassignedFewTimesComponent(data)}
        {IconBookADriverComponent(data)}
        {FutureOrderAlertComponent(data)}
      </OrderAlertsContainer>
    )
  },
  {
    title: 'Restaurant time',
    renderer: (data) => (
      <div className='TableCenter textUppercase'>
        {data[BOARD.IS_FUTURE_ORDER]
          ? ''
          : moment()
              .utc()
              .utcOffset(Number(data[BOARD.TIMEZONE]) || 0)
              .format('h:mm a')}
      </div>
    )
  },
  {
    title: 'Pickup time',
    fieldName: 'pickup_deadline',
    sortable: false,
    renderer: (data) => (
      <div className='TableHorizontalCenter'>
        {data[BOARD.IS_FUTURE_ORDER] ? (
          ''
        ) : (
          <>
            <div className='wordWrap'>
              {formatTime(
                data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
                  data[BOARD.STATUS] === BOARD_STATUS.ASSIGNED ||
                  data[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE
                  ? data[BOARD.PICKUP_DEADLINE_TIME]
                  : data[BOARD.PICKUP_TIME],
                data[BOARD.TIMEZONE]
              )}
            </div>
            {data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
            data[BOARD.STATUS] === BOARD_STATUS.ASSIGNED ||
            data[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE ? (
              <div className='wordWrap'>
                {diffTime(
                  data[BOARD.PICKUP_TIME],
                  data[BOARD.PICKUP_DEADLINE_TIME]
                )}
              </div>
            ) : (
              <div className='wordWrap'>Actual</div>
            )}
          </>
        )}
      </div>
    )
  },
  {
    title: 'Dropoff time',
    fieldName: 'dropoff_deadline',
    sortable: false,
    renderer: (data) => (
      <div className='TableHorizontalCenter'>
        {data[BOARD.IS_FUTURE_ORDER] ? (
          ''
        ) : (
          <>
            <div className='wordWrap'>
              {formatTime(
                data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
                  data[BOARD.STATUS] === BOARD_STATUS.ASSIGNED ||
                  data[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE ||
                  data[BOARD.STATUS] === BOARD_STATUS.PICKED_UP ||
                  data[BOARD.STATUS] === BOARD_STATUS.DROPOFF_ENROUTE
                  ? data[BOARD.DROPOFF_DEADLINE_TIME]
                  : data[BOARD.DROPOFF_TIME],
                data[BOARD.TIMEZONE]
              )}
            </div>
            {data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
            data[BOARD.STATUS] === BOARD_STATUS.ASSIGNED ||
            data[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE ||
            data[BOARD.STATUS] === BOARD_STATUS.PICKED_UP ||
            data[BOARD.STATUS] === BOARD_STATUS.DROPOFF_ENROUTE ? (
              <div className='wordWrap'>
                {diffTime(
                  data[BOARD.DROPOFF_TIME],
                  data[BOARD.DROPOFF_DEADLINE_TIME]
                )}
              </div>
            ) : (
              <div className='wordWrap'>Actual</div>
            )}
          </>
        )}
      </div>
    )
  },
  {
    title: 'Status',
    sortable: false,
    fieldName: 'status',
    renderer: (data) => {
      return (
        <FlexContainer className='TableHorizontalCenter'>
          {checkAtThePickupStatus(data) ? (
            <>At the restaurant</>
          ) : (
            <>
              {(data[BOARD.STATUS] === BOARD_STATUS.CANCELLED ||
                data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_SAUCE ||
                data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_CUSTOMER ||
                data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_PROVIDER ||
                data[BOARD.STATUS] === BOARD_STATUS.DELIVERY_FAILED) && (
                <>Cancelled</>
              )}
              {data[BOARD.STATUS] === BOARD_STATUS.DELIVERED && (
                <>Dropped off</>
              )}
              {data[BOARD.STATUS] === BOARD_STATUS.PICKUP_ENROUTE && (
                <>Pickup enroute</>
              )}
              {data[BOARD.STATUS] === BOARD_STATUS.DROPOFF_ENROUTE && (
                <>Dropoff enroute</>
              )}
              {data[BOARD.STATUS] === BOARD_STATUS.PICKED_UP && <>Picked up</>}
              {data[BOARD.STATUS] === BOARD_STATUS.ASSIGNED && <>Assigned</>}
              {data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED && <>Scheduled</>}
              {data[BOARD.STATUS] === BOARD_STATUS.FUTURE_ORDER && (
                <>Future order</>
              )}
            </>
          )}
        </FlexContainer>
      );
    }
  },
  {
    title: 'Delivery company',
    fieldName: 'service_type',
    sortable: false,
    renderer: (data) => {
      return (
        <FlexContainer className='TableHorizontalCenter'>
          <div className='wordWrap'>
            {data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
            data[BOARD.STATUS] === BOARD_STATUS.FUTURE_ORDER ||
            ((data[BOARD.STATUS] === BOARD_STATUS.CANCELLED ||
              data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_SAUCE ||
              data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_PROVIDER ||
              data[BOARD.STATUS] === BOARD_STATUS.CANCELLED_BY_CUSTOMER) &&
              data[BOARD.IS_FUTURE_ORDER])
              ? 'N/A'
              : data[BOARD.DELIVERY_COMPANY] || 'N/A'}
          </div>
        </FlexContainer>
      );
    }
  },
  {
    title: 'Restaurant name',
    fieldName: 'restaurant_name',
    sortable: false,
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <div className='wordWrap restaurantName'>{getRestName(data)}</div>
      </FlexContainer>
    )
  },
  {
    title: 'Customer name',
    fieldName: 'customer_name',
    sortable: false,
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <div className='wordWrap customerName'>{data[BOARD.CUSTOMER_NAME]}</div>
      </FlexContainer>
    )
  },
  {
    title: 'Tracking link',
    renderer: (data) => (
      <FlexContainer className='ButtonsCenter'>
        <ButtonOutline
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_DISPATCH_URL}/r/${formatRestaurantName(
                data[BOARD.RESTAURANT_NAME]
              )}/${data[BOARD.DELIVERY_ID]}`,
              '_blank'
            );
          }}
          className='ButtonSmallXs'
        >
          <b>R</b>
        </ButtonOutline>
        <ButtonOutline
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_DISPATCH_URL}/u/${formatRestaurantName(
                data[BOARD.RESTAURANT_NAME]
              )}/${data[BOARD.DELIVERY_ID]}`,
              '_blank'
            );
          }}
          className='ButtonSmallXs'
        >
          <b>U</b>
        </ButtonOutline>
      </FlexContainer>
    )
  },
  {
    title: 'Actions made',
    renderer: (data) => (
      <FlexContainer className='TableHorizontalCenter'>
        <ButtonOutline
          onClick={() => {
            dispatch(
              OPEN_ACTIONS_MADE_MODAL({open: true, id: data[BOARD.ORDER_ID]})
            );
          }}
          className={cn('ButtonSmallXs RadiusMin paddingLeftRightMin', {
            disabled: !checkUserIsOwner(data, userData)
          })}
        >
          <b>Report Action</b>
        </ButtonOutline>
      </FlexContainer>
    )
  },
  {
    title: '',
    renderer: (data) => (
      <FlexContainer className='justify-end align-center heightReset'>
        {isPin && (
          <Pin
            className={cn('IconPin', {
              active: isPin
            })}
          >
            <IconPin />
          </Pin>
        )}
        <ButtonMore
          itemsData={[
            {
              title: isPin ? 'Unpin' : 'Pin to top',
              action: () =>
                dispatch(isPin ? UNPIN_ORDER(data) : PIN_ORDER(data))
            },
            {
              title: 'Additional info',
              action: () => {
                dispatch(OPEN_ADDITION_INFO_MODAL({open: true, data}));
                dispatch(GET_ORDER_ACTIONS({id: data[BOARD.ORDER_ID]}));
              }
            },
            {
              title: checkUserIsOwner(data, userData)
                ? 'Un-assign order'
                : 'Assign order to me',
              action: () => {
                const actionBody = checkUserIsOwner(data, userData)
                  ? {
                      created: moment().utc().format(),
                      userId: userData.id,
                      name: 'Release order'
                    }
                  : {
                      created: moment().utc().format(),
                      userId: userData.id,
                      name: 'Own order',
                      userName: `${userData.first_name || 'N/A'} ${
                        userData.last_name || 'N/A'
                      }`
                    };

                dispatch(
                  SEND_ORDER_ACTIONS({
                    data: {
                      actions: [actionBody]
                    },
                    id: data[BOARD.ORDER_ID],
                    core_order_id: data[BOARD.CORE_ID]
                  })
                );
              }
            },
            {
              title: 'Remove order',
              disabled:
                (data[BOARD.STATUS] !== BOARD_STATUS.DELIVERED &&
                  data[BOARD.STATUS] !== BOARD_STATUS.CANCELLED) ||
                data[BOARD.ORDER_OWNER]?.user_id !== userData?.id,
              action: () => {
                dispatch(
                  RDB_REMOVE_ORDER_MODAL({
                    open: true,
                    internal_delivery_id: data[BOARD.ORDER_ID],
                    core_invoice_id: data[BOARD.CORE_ID]
                  })
                );
              }
            }
          ]}
        />
      </FlexContainer>
    )
  }
  // },
  // {
  //   title: '',
  //   renderer: (data) => (
  //     <PoperAction popperActions={popperActionsConfig} row={data} />
  //   )
  // }
];

const BoardTable = ({
  orders,
  pinOrders,
  loading,
  onSort,
  orderBy,
  orderDirection
}) => {
  const dispatch = useDispatch();
  const {userData} = useSelector((state) => state.user);

  return (
    <OrdersTableWrapper
      className={cn('OrdersTableWrapper', {
        stickyPinTable: !!pinOrders.length
      })}
    >
      {!!pinOrders.length && (
        <div className='pinOrdersTable'>
          <TableComponent
            fields={getFieldsConfig(dispatch, true, userData)}
            data={pinOrders}
            rowKeyPath={BOARD.ID}
            onSort={onSort}
            orderBy={orderBy}
            orderDirection={orderDirection}
          />
        </div>
      )}
      {!orders.length && !!pinOrders.length ? (
        <></>
      ) : (
        <TableComponent
          fields={getFieldsConfig(dispatch, false, userData)}
          data={findLastOfColorAndUpdate(orders)}
          loading={loading}
          rowKeyPath={BOARD.ID}
          onSort={onSort}
          orderBy={orderBy}
          orderDirection={orderDirection}
          hideHeader={!!pinOrders.length}
        />
      )}
    </OrdersTableWrapper>
  );
};

BoardTable.propTypes = {
  orders: PropTypes.array.isRequired,
  pinOrders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string
};

BoardTable.defaultProps = {
  onSort: () => {},
  orderBy: '',
  orderDirection: ''
};

export default BoardTable;
