import {put, select, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {configInterseptors} from '../../../helpers/services/utils/interseptors';
import {
  GET_LOCATION,
  GET_LOCATION_DATA_SUCCESS,
  GET_USER_DATA_SUCCESS,
  PATCH_USER_DATA,
  SET_ACTIVE_LOCATION,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  GET_USER_LOCATIONS,
  SET_BYOC_CONFIGURATION,
  SET_BYOC_CONFIGURATION_SUCCESS,
  SET_FEEDBACK_STATUS,
  SET_FEEDBACK_BANNER_STATUS
} from './action';
// eslint-disable-next-line import/no-cycle
import {getAccountStepsStatus} from '../account/saga';
import {
  changeBYOCConfigurationStatusApi,
  patchUsersApi,
  updateUsersApi
} from '../../../helpers/services/api/user';
import {request} from '../../../helpers/services/utils/request';
import {GET} from '../../../helpers/services/constants/API_CONSTANTS';
import {LOCATION_DATA} from '../../../helpers/services/constants/API_ENDPOINTS';
import {getAppIp, getAppType, getAppVersion} from '../../../helpers/app.helper';
import {
  updateFeedbackBannerStatusApi,
  updateFeedbackSettingsApi
} from '../../../helpers/services/api/locationSettings';
import {clarityIdentify} from '../../../helpers/services/utils/clarityIdentify';
import {setSessionIdToStorage} from '../../../helpers/localStorage/UserSessionIdStorage';

const UserId = ({user}) => user.userData.id;
const locId = ({user}) => user.locationId;
const getToken = ({authReducer}) => authReducer.token;

const getLocationData = async (token) => {
  return request(
    GET,
    `${process.env.REACT_APP_API_URL}/locations`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};
// This function is used for tracking login time
const getUserDataApi = async (token, config) => {
  return request(
    GET,
    `${process.env.REACT_APP_API_URL}/users/current?trackLoginTime=${
      config?.saveTime || false
    }`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};

// This function is used for tracking login time
export function* getUserData(config) {
  try {
    const token = yield select(getToken);
    const {data} = yield getUserDataApi(token, config);
    if (data) {
      clarityIdentify(data.data.MerchantOriginName);
      setSessionIdToStorage(data.data?.id);
      yield put(GET_USER_DATA_SUCCESS(data.data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getUsersLocations() {
  try {
    const token = yield select(getToken);
    const {data} = yield getLocationData(token);

    if (data) {
      yield put(GET_LOCATION_DATA_SUCCESS(data));
      yield configInterseptors();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* updateUserData({payload}) {
  try {
    const userId = yield select(UserId);
    const {status} = yield updateUsersApi(userId, payload);

    if (status === 204) {
      yield getUserData();
      yield getAccountStepsStatus();
      yield put(UPDATE_USER_DATA_SUCCESS(payload));
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

export function* patchUserData({payload}) {
  try {
    const userId = yield select(UserId);
    const {status} = yield patchUsersApi(userId, payload);

    if (status === 204) {
      yield getUserData();
      // yield getAccountStepsStatus();
      yield put(UPDATE_USER_DATA_SUCCESS(payload));
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

// Dirty hack to set needed fields with minimal code impact
export function* getLocation({payload}) {
  if (payload?._id) {
    const {data} = yield request(GET, LOCATION_DATA(payload?._id));

    yield put(
      SET_ACTIVE_LOCATION({
        ...payload,
        GeneratedLink: data?.GeneratedLink || payload.GeneratedLink,
        setupData: data?.setupData
      })
    );
  }
}

function* changeBYOCConfigurationStatus({payload}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {status, onSuccess} = payload;
    const res = yield changeBYOCConfigurationStatusApi(locationId, status);

    if (res.status === 200) {
      yield put(SET_BYOC_CONFIGURATION_SUCCESS(status));
      onSuccess();
    }
  } catch (error) {
    toast.error('Something went wrong! Please try again later!');
    console.log('SAGA ERROR', error);
  }
}

export function* updateFeedbackSettings({payload}) {
  const {
    isSurveyActivated,
    preCallback = () => {},
    onSuccess = () => {},
    onError = () => {}
  } = payload;
  try {
    preCallback();
    const id = yield select(locId);
    const res = yield updateFeedbackSettingsApi(id, {isSurveyActivated});

    if (res.status === 204) {
      onSuccess();
    }
  } catch (error) {
    onError();
    console.log('SAGA ERROR', error);
  }
}

export function* updateFeedbackBannerStatus({payload}) {
  const {
    hasClosedSurveyBanner,
    preCallback = () => {},
    onSuccess = () => {},
    onError = () => {}
  } = payload;
  try {
    preCallback();
    const id = yield select(locId);
    const res = yield updateFeedbackBannerStatusApi(id, {
      hasClosedSurveyBanner
    });

    if (res.status === 204) {
      onSuccess();
    }
  } catch (error) {
    onError();
    console.log('SAGA ERROR', error);
  }
}

function* userSaga() {
  yield takeEvery(UPDATE_USER_DATA, updateUserData);
  yield takeEvery(PATCH_USER_DATA, patchUserData);
  yield takeEvery(GET_LOCATION, getLocation);
  yield takeEvery(GET_USER_LOCATIONS, getUsersLocations);
  yield takeEvery(SET_BYOC_CONFIGURATION, changeBYOCConfigurationStatus);
  yield takeEvery(SET_FEEDBACK_STATUS, updateFeedbackSettings);
  yield takeEvery(SET_FEEDBACK_BANNER_STATUS, updateFeedbackBannerStatus);
}

export default userSaga;
