import {request} from '../utils/request';
import {GET, PATCH} from '../constants/API_CONSTANTS';
import {
  LOCATIONS_NOTIFICATIONS_DATA,
  PUBLISH_BOT_STATUS
} from '../constants/GLOBAL_NOTIFICATIONS_API';
import {getAppIp, getAppType, getAppVersion} from '../../app.helper';

export const getLocationsNotificationsApi = async (id) => {
  return request(GET, LOCATIONS_NOTIFICATIONS_DATA(id));
};

export const getBotStatusApi = async (menuConfigId, SyncType) => {
  return request(
    GET,
    PUBLISH_BOT_STATUS(menuConfigId),
    {},
    {
      headers: {
        'Sync-Type': SyncType
      }
    }
  );
};

export const patchDeliveryOptionsApi = async (token, {id, ...body}) => {
  return request(
    PATCH,
    `${process.env.REACT_APP_API_URL}/locations-management/configurations/${id}`,
    {body},
    {
      headers: {
        Accept: 'application/merge-patch+json',
        Authorization: `Bearer ${token}`,
        'X-Merchant-Location': id,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};

export const toggleStoreApi = async (token, {id, ...body}) => {
  return request(
    PATCH,
    `${process.env.REACT_APP_API_URL}/locations-management/toggle-store/${id}`,
    {body},
    {
      headers: {
        Accept: 'application/merge-patch+json',
        Authorization: `Bearer ${token}`,
        'X-Merchant-Location': id,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};
