import {DELETE, GET, POST, PUT} from '../constants/API_CONSTANTS';
import {
  DELETE_REFRESH_TOKEN,
  LOGIN_USER,
  RETURN_TO_DASHBOARD,
  SEND_MAIL,
  UPDATE_MEMBER_PASSWORD,
  UPDATE_PASSWORD,
  UPDATE_TOKENS
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const userLoginApi = async (data) => {
  return request(POST, LOGIN_USER(), {data});
};

export const refreshTokensApi = async (data) => {
  return request(PUT, UPDATE_TOKENS(), {data});
};

export const deleteRefreshTokenApi = async (userId) => {
  return request(DELETE, DELETE_REFRESH_TOKEN(userId));
};

export const forgotPasswordApi = async (data) => {
  return request(GET, SEND_MAIL(data));
};

export const updateUserPasswordApi = async (token, data) => {
  return request(PUT, UPDATE_PASSWORD(token), {data});
};

export const updateMemberPasswordApi = async (token, data) => {
  return request(PUT, UPDATE_MEMBER_PASSWORD(token), {data});
};

export const returnToOldDashboardApi = async () => {
  return request(GET, RETURN_TO_DASHBOARD());
};
