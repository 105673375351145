import React from 'react';
import T from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import cn from 'classnames';
import Modal from '../../../../components/Modals/Modal';
import AnimateLocationDelivery from '../../../../assets/animate-delivery-red.gif';
import AnimateLocationPickup from '../../../../assets/animate-pizza-red.gif';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {
  OPEN_CONFIRMATION_MODAL,
  PUBLISH_LOCATION,
  SET_STATUS_BUTTON,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../../../store/locationSettings/action';
import {UberEatsImage} from '../../uberEats/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';

class LocationConfirmationModal extends React.Component {
  onClose = () => {
    this.props.OPEN_CONFIRMATION_MODAL({
      open: false,
      entityName: ''
    });
  };

  publishLocation = () => {
    this.props.dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          this.props.dispatch(
            SHOW_UNPUBLISH_BANNER({showBanner: false, types: []})
          );
          this.props.dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
          this.onClose();
        },
        postCallBack: () => {},
        errorCallback: () => {
          this.props.dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          this.props.dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
          this.onClose();
        }
      })
    );
  };

  onEnable = () => {
    const {
      entityName,
      setFieldValue,
      values,
      closedManually,
      dispatch,
      resetForm,
      setFormSubmitting,
      disableButton
    } = this.props;

    if (entityName === 'Store') {
      dispatch(SET_STATUS_BUTTON(true));

      const storeObj = {
        ClosedManually: false,
        ClosedManuallyTime: null,
        'notSyncedData.ClosedManually': false,
        ClosedManuallyReason: null,
        StoreDisableOption: null,
        ClosedManuallyUntilTime: null
      };

      if (values.ClosedManually && closedManually) {
        resetForm({...values, storeObj});
        Object.entries(storeObj).forEach(([key, value]) => {
          setFieldValue(key, value);
        });
        setFormSubmitting(false);

        dispatch(
          UPDATE_LOCATION_SETTINGS_DATA({
            payload: storeObj,
            successCallback: () => {
              this.publishLocation();
            },
            errorCallback: () => {
              dispatch(STOP_GET_PUBLISH_STATUS_JOB());
              dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
            }
          })
        );
      } else {
        Object.entries(storeObj).forEach(([key, value]) => {
          setFieldValue(key, value);
        });
        setFormSubmitting(false);
        this.onClose();
      }
    } else {
      const entityObj = {
        [`DeliveryOptions.${entityName}`]: true,
        [`notSyncedData.DeliveryOptions.${entityName}`]: true,
        [`${entityName}EnableIn`]: null,
        [`${entityName}DisableReason`]: null,
        [`${entityName}DisableOption`]: null
      };
      Object.entries(entityObj).forEach(([key, value]) => {
        setFieldValue(key, value);
      });
      this.onClose();
    }
  };

  render() {
    const {isOpen, entityName, disableButton} = this.props;

    return (
      <>
        {isOpen && (
          <Modal
            onCloseAction={() => this.onClose()}
            title={
              <DialogTitle className='marginBottom2l'>
                Enable <span>{entityName}?</span>
              </DialogTitle>
            }
            width='715px'
            body={
              <>
                <UberEatsImage
                  src={
                    entityName === 'Delivery'
                      ? AnimateLocationDelivery
                      : AnimateLocationPickup
                  }
                />
                <DialogActions>
                  <DialogAction className='w50'>
                    <ButtonOutline onClick={() => this.onClose()}>
                      <span>Keep it Disabled</span>
                    </ButtonOutline>
                  </DialogAction>
                  <DialogAction className='w50'>
                    <ButtonPrimary
                      className={cn('', {
                        disabled: disableButton
                      })}
                      onClick={() => {
                        this.onEnable();
                      }}
                    >
                      <span>Yes, Enable {entityName}</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogActions>
              </>
            }
          />
        )}
      </>
    );
  }
}

LocationConfirmationModal.propTypes = {
  isOpen: T.bool.isRequired,
  entityName: T.string,
  setFieldValue: T.func.isRequired,
  OPEN_CONFIRMATION_MODAL: T.func.isRequired,
  values: T.object.isRequired,
  closedManually: T.bool.isRequired,
  dispatch: T.func.isRequired,
  resetForm: T.func.isRequired,
  setFormSubmitting: T.func.isRequired,
  disableButton: T.bool.isRequired
};

LocationConfirmationModal.defaultProps = {
  entityName: ''
};

export default connect(
  (store) => ({
    isOpen: store.locationSettingsReducer.isConfirmationModalOpen,
    entityName: store.locationSettingsReducer.confirmationEntityName
  }),
  {
    OPEN_CONFIRMATION_MODAL
  }
)(LocationConfirmationModal);
