import React, {useEffect, useState} from 'react';
import {RadioGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch} from 'react-redux';
import {
  SetupActions,
  SetupCalculator,
  SetupCommission,
  SetupContainer,
  SetupFieldset,
  SetupHeader,
  SetupHolder,
  SetupLogo,
  SetupSection,
  SetupSidebar
} from './style';
import Body3 from '../../../../components/typography/Body3';
import SauceLogoRed from '../../../../assets/logo-sauce-red.svg';
import Subtitle0 from '../../../../components/typography/Subtitle0';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Caption4 from '../../../../components/typography/Caption4';
import {DashboardField, DashboardFields} from '../../../App/dashboard/styles';
import {FieldLabel} from '../../../../components/FieldLabel/styles';
import FieldInput from '../../../../components/FieldInput';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import RadioS2E from '../../../../components/Radiobox';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import {Body1} from '../../../../components/typography';
import DialogFields from '../../../../components/DialogFields/styles';
import DialogField from '../../../../components/DialogField/styles';
import {
  RangeSlider,
  RangeSliderBottom,
  RangeSliderTop
} from '../../../../components/RangeSlider/styles';
import RangeSliderS2E from '../../../../components/RangeSlider';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {
  Commission,
  CommissionInner,
  SaveMoney
} from '../../../App/account/styles';
import {stepData} from '../../../../store/selfCreateAccount/selectors';
import {
  POST_STEP,
  SAVE_STEP_DATA,
  SET_FINAL_SETUP,
  SHOW_STEP_MODAL
} from '../../../../store/selfCreateAccount/action';
import CustomProgressBar from '../../../../components/CustomProgressBar';
import {CardsButtonGrid} from '../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../components/CardsButton/styles';
import FileUploader from '../../../../components/FileUploader';

const SetupRestaurant2 = () => {
  return (
    <SetupSection>
      <SetupHolder>
        <SetupContainer>
          <SetupHeader>
            <SetupLogo src={SauceLogoRed} />
            <Subtitle5>
              <span>Setup</span> your restaurant
            </Subtitle5>
          </SetupHeader>
          <CustomProgressBar className='ProgressBar ProgressBarMob' />
          <Subtitle0>General Info</Subtitle0>
          <SetupFieldset>
            <Caption4>Your Info</Caption4>
            <DashboardFields>
              <DashboardField className='w25'>
                <FieldLabel>First Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w25'>
                <FieldLabel>Last Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='FieldNumber'>
                <FieldLabel>Country Code</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>Your phone number</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupFieldset>
            <Caption4>Restaurant Info</Caption4>
            <DashboardFields>
              <DashboardField className='w100'>
                <FieldLabel>Restaurant Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Restaurant URL</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Address</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Delivery special instructions</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='FieldNumber'>
                <FieldLabel>Country Code</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>The Restaurant&apos;s Phone Number</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>Sales tax rate</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupActions>
            <ButtonPrimary className='ButtonPrimary'>
              <span>Create My Account!</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
        <SetupContainer>
          <Subtitle0>Add your menu</Subtitle0>
          <SetupFieldset>
            <Caption4>Where should we copy your menu from?</Caption4>
            <CardsButtonGrid className='CardsButtonGridOrange'>
              <CardsButton>
                <span>Use URL</span>
              </CardsButton>
              <CardsButton>
                <span>Upload file</span>
              </CardsButton>
              <CardsButton>
                <span>Build from scratch</span>
              </CardsButton>
            </CardsButtonGrid>
            <DashboardFields>
              <DashboardField className='w100'>
                <FieldLabel>URL</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupFieldset>
            <Caption4>Where should we copy your menu from?</Caption4>
            <CardsButtonGrid className='CardsButtonGridOrange'>
              <CardsButton>
                <span>Use URL</span>
              </CardsButton>
              <CardsButton>
                <span>Upload file</span>
              </CardsButton>
              <CardsButton>
                <span>Without images</span>
              </CardsButton>
            </CardsButtonGrid>
            <DashboardFields>
              <DashboardField className='w100'>
                <FieldLabel>Upload file</FieldLabel>
                <FileUploader />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupActions className='SecondStep'>
            <ButtonOutline type='submit' className='ButtonOutline'>
              Back
            </ButtonOutline>
            <ButtonPrimary className='ButtonPrimary'>
              <span>Finish</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
      </SetupHolder>
      <SetupSidebar>
        <Body3>
          Just <strong className='Red'>2 small steps</strong> in order to extend
          your reach and start deliver to your customers!
        </Body3>
        <CustomProgressBar className='ProgressBar ProgressBarDesktop' />
      </SetupSidebar>
    </SetupSection>
  );
};

export default SetupRestaurant2;
