import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 75px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  margin: ${(props) => (props.margin ? props.margin : '10px 0')};

  &.LoadingWrapperCenter {
    width: 100%;
  }

  .CircularProgress {
    svg {
      circle {
        color: #ff2d38;
      }
    }
  }
  &.LoadingExportButton {
    min-height: 0;
    margin: 0;
  }
  &.RefundCalculator {
    min-height: 0;
    max-height: 20px;
    margin: 0;
  }
  &.InsightsControlModal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0;
    z-index: 4;
  }
  &.AdjustModal {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0;
    z-index: 4;
    top: 0;
  }
`;

export default LoadingWrapper;
