/* eslint-disable */
import {PUBLISH_STATUS_ENABLE} from '../../constants';
import {arrayFlip} from '../../../../helpers/arrayHelper.js';
import {createSingleBranch} from '../MenuBranch.js';
import {getOuterJoin} from '../../../../helpers/objectHelper.js';
import {setActiveDirection} from '../MenuList.js';

function updateModifier(store, id, data)  {
  const {activeTab} = store;

  if (!store.modifiersMap.hasOwnProperty(id)) {
    return store;
  }

  //клонирование основных объектов modifier
  const prevData = store.modifiersMap[id] || {};
  const modifier = {...prevData, ...data};
  const branch = createSingleBranch(modifier, 'modifier');
  const activeBranch = {...store.activeModifiersBranchMap[id]};

  branch.$children = [...modifier.ModifierGroups];
  activeBranch.$children = [...modifier.ModifierGroups];

  //обновление связей для родительских ModifierGroups
  const ParentGroupsDiff = getOuterJoin(
    arrayFlip(prevData.ParentModifierGroups, false),
    arrayFlip(modifier.ParentModifierGroups, true),
  )

  if (Object.keys(ParentGroupsDiff).length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId in ParentGroupsDiff) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};
      group.Items = ParentGroupsDiff[PosId] ? [...group.Items, id] : group.Items.filter(sid => sid !== id);
      store.groupsMap[PosId] = group;
      isChangeMap = true;

      if (store.groupsBranchMap.hasOwnProperty(PosId)) {
        store.groupsBranchMap[PosId].$children = [...group.Items];
        store.groupsBranchMap[PosId] = {...store.groupsBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeGroupsBranchMap.hasOwnProperty(PosId)) {
          store.activeGroupsBranchMap[PosId].$children = [...group.Items];
          store.activeGroupsBranchMap[PosId] = {...store.activeGroupsBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }

    if(isChangeBranchMap) {
      store.groupsBranchMap = {...store.groupsBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeGroupsBranchMap = {...store.activeGroupsBranchMap};
    }
  }

  //обновление связей для дочерних ModifierGroups
  const GroupsDiff = getOuterJoin(
    arrayFlip(prevData.ModifierGroups, false),
    arrayFlip(modifier.ModifierGroups, true),
  )

  if (Object.keys(GroupsDiff).length) {
    let isChangeMap = false;

    for (const PosId in GroupsDiff) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};

      group.Modifiers = GroupsDiff[PosId] ? [...group.Modifiers, id] : group.Modifiers.filter(sid => sid !== id);
      store.groupsMap[PosId] = group;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }
  }

  //перезапись основных объектов modifier
  store.modifiersMap[id] = modifier;
  store.modifiersBranchMap[id] = branch;
  store.activeModifiersBranchMap[id] = activeBranch;

  //чистка modifier из списка скрытых, если статус поменялся
  if (activeTab === 'HIDDEN') {
    store.activeModifiersDirection = setActiveDirection(
      store.modifiersDirection.filter((id) => store.modifiersMap[id].Invisible === true),
      store.activeModifiersBranchMap
    );
  }

  //чистка modifier из списка недоступных, если статус поменялся
  if (activeTab === 'OUT_OFF_STOCK') {
    store.activeModifiersDirection = setActiveDirection(
      store.modifiersDirection.filter((id) => store.modifiersMap[id].InStock === false),
      store.activeModifiersBranchMap
    );
  }

  return store;
}

export function updateOneModifier(prevStore, payload) {
  let store = {...prevStore};
  const {id, data} = payload;

  store = updateModifier(store, id, data);

  return getNextStore(store);
}

export function updateManyModifiers(prevStore, payload) {
  let store = {...prevStore};
  const {ids, data} = payload;

  ids.forEach(id => (store = updateModifier(store, id, data)));

  return getNextStore(store);
}

function clearModifier(store, modifierId) {
  const oldModifier = store.modifiersMap[modifierId];

  //разрываем связи с родительскими OptionsGroups
  if (oldModifier.ParentModifierGroups.length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId of oldModifier.ParentModifierGroups) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};
      group.Items = group.Items.filter(sid => sid !== modifierId);
      store.groupsMap[PosId] = group;
      isChangeMap = true;

      if (store.groupsBranchMap.hasOwnProperty(PosId)) {
        store.groupsBranchMap[PosId].$children = [...group.Items];
        store.groupsBranchMap[PosId] = {...store.groupsBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeGroupsBranchMap.hasOwnProperty(PosId)) {
          store.activeGroupsBranchMap[PosId].$children = [...group.Items];
          store.activeGroupsBranchMap[PosId] = {...store.activeGroupsBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }

    if(isChangeBranchMap) {
      store.groupsBranchMap = {...store.groupsBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeGroupsBranchMap = {...store.activeGroupsBranchMap};
    }
  }

  //разрываем связи с дочерними OptionsGroups
  if (oldModifier.ModifierGroups.length) {
    let isChangeMap = false;

    for (const PosId of oldModifier.ModifierGroups) {
      if (!store.groupsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const group = {...store.groupsMap[PosId]};
      group.Modifiers = group.Modifiers.filter(sid => sid !== modifierId);
      store.groupsMap[PosId] = group;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.groupsMap = {...store.groupsMap};
    }
  }

  delete store.modifiersMap[modifierId];
  delete store.modifiersBranchMap[modifierId];
  delete store.activeModifiersBranchMap[modifierId];

  return store;
}

export function clearOneModifier (prevStore, modifierId) {
  let store = {...prevStore};
  const filterDirection = (id) => id !== modifierId;

  store = clearModifier(store, modifierId);
  store.modifiersDirection = store.modifiersDirection.filter(filterDirection);
  store.activeModifiersDirection = store.activeModifiersDirection.filter(filterDirection);

  return getNextStore(store);
}

export function clearManyModifiers (prevStore, ids=[]) {
  let store = {...prevStore};
  const idsMap = arrayFlip(ids);
  const filterDirection = (id) => !idsMap.hasOwnProperty(id);

  ids.forEach(modifierId => (store = clearModifier(store, modifierId)));

  store.modifiersDirection = store.modifiersDirection.filter(filterDirection);
  store.activeModifiersDirection = store.activeModifiersDirection.filter(filterDirection);

  return getNextStore(store);
}

function getNextStore(store) {
  return {
    ...store,
    modifiersMap: {...store.modifiersMap},
    modifiersBranchMap: {...store.modifiersBranchMap},
    activeModifiersBranchMap: {...store.activeModifiersBranchMap},
    publishStatus: PUBLISH_STATUS_ENABLE
  }
}
