import styled from 'styled-components';
import {IconButton} from '@material-ui/core';
import {ButtonInfo} from '../../../../../components/buttons';
import SavingsIcon from '../../../../../assets/icon-savings-pig.svg';
import IconBgDecor from '../../../../../assets/icon-arrow-hint.svg';

export const OrdersTableWrapper = styled.div`
  .pinOrdersTable {
    margin-bottom: -45px;
  }
  .MuiTable-root {
    .hideHeader {
      visibility: hidden;
    }
  }

  tbody {
    div.TableBottomIndent {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .restaurantName,
  .customerName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  thead {
    background-color: #f7f7fa;
    position: sticky;
    top: 0;
    z-index: 1;
    @media (max-width: 1250px) {
      top: 86px;
    }
    @media (max-width: 767px) {
      position: relative;
      top: 0;
    }
    th {
      text-align: center;
    }
    .MuiTableCell-root {
      padding-top: 18px !important;
    }
  }
  &.stickyPinTable {
    .pinOrdersTable {
      position: sticky;
      top: 0;
      background-color: #f7f7fa;
      z-index: 3;
      border-radius: 0 0 10px 10px;
      @media (max-width: 1250px) {
        top: 86px;
      }
      @media (max-width: 767px) {
        position: relative;
        top: 0;
      }
      thead {
        position: inherit;
        top: 0;
      }
    }
  }
  .TableBottomIndent {
    margin-bottom: 5px !important;
  }
`;

export const BoardStatus = styled.div`
  div {
    display: flex;
    align-items: baseline;
    width: max-content;
    border-radius: 30px;
    padding: 4px 18px;
    outline: none;
    box-shadow: 0 8px 16px rgb(21 206 117 / 0%);

    svg circle {
      fill: #fff;
    }

    svg {
      @media (max-width: 1000px) {
        width: 8px;
        height: 8px;
      }
    }

    span {
      margin-left: 5px;
    }

    &.Orange {
      border: 2px solid #fca600;

      svg circle {
        fill: #fca600;
        stroke: #fca600;
      }
    }
    &.Red {
      background: #ff2d38;
      border: 2px solid #ff2d38;
      color: #fff;
      svg circle {
        fill: #fff;
      }
    }
    &.FullGreen {
      background: #17c981;
      border: 2px solid #17c981;
      color: #fff;

      svg circle {
        fill: #fff;
      }
    }
    &.Green {
      background: #fff;
      border: 2px solid #17c981;

      svg circle {
        fill: #17c981;
        stroke: #17c981;
      }
    }
    &.Future {
      font-weight: 500;
      padding: 0;
    }
  }
`;

export const Pin = styled.div`
  svg {
    path {
      fill: #929cb9;
    }
  }
  &.active {
    svg {
      path {
        fill: #ff2d38;
      }
    }
  }
`;

export const OrderAlertsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 112px;
`;

export const OrderAlert = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 28px;
  width: 28px;

  .OrderAlertInfo {
    position: absolute;
    bottom: calc(100% + 8px);
    left: -22px;
    width: 120px;
    border: 2px solid #ff2d38;
    background-color: #fff;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px 16px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4;
    color: #232d3a;
    text-align: left;
    transform: translateY(-4px);
    opacity: 0;
    visibility: hidden;
    z-index: 12;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;

    &::after {
      position: absolute;
      top: 100%;
      left: 16px;
      content: '';
      width: 35px;
      height: 10px;
      background-image: url('${IconBgDecor}');
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    &.OrderAlertInfoMd {
      width: 180px;
    }
    &.OrderAlertInfoLg {
      width: 230px;
    }
  }

  &:hover {
    .OrderAlertInfo {
      transform: translateY(-1px);
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const TierRestaurant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff2d38;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  width: 24px;
  height: 24px;
  line-height: 1;
`;

export const NewDispatchAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5070d4;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  width: 24px;
  height: 24px;
  line-height: 1;
`;
