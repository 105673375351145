import * as Yup from 'yup';

const CategorySchema = Yup.object().shape({
  Name: Yup.string()
    .max(750, 'Category name is too long')
    .required('Category name field is required!')
    .trim()
});

export default CategorySchema;
