import React from 'react';
import {Route} from 'react-router-dom';
import Login from './login';
import ResetPassword from './resetPassword';
import ForgotPassword from './forgotPassword';
import SignUp from './registration/signUp';
import SignUp2 from './registration/signUp2';
import ActivateMember from './activateMember';
import SetupRestaurant from './registration/signUpNew';
import SetupRestaurant2 from './registration/signUpNew/index2';

const Auth = () => {
  return (
    <>
      <Route exact path='/sign-up-sales-new' component={SignUp} />
      {/* <Route exact path='/sign-up' component={SignUp2} /> */}
      <Route exact path='/' component={Login} />
      <Route exact path='/setup' component={SetupRestaurant} />
      <Route exact path='/setup2' component={SetupRestaurant2} />
      <Route exact path='/reset-password/:token' component={ResetPassword} />
      <Route
        exact
        path='/activate-member/:token/:email'
        component={ActivateMember}
      />
      <Route exact path='/forgot-password' component={ForgotPassword} />
    </>
  );
};

export default Auth;
