import * as Yup from 'yup';

const UserManagementSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
    .required('Email field is required!'),
  first_name: Yup.string().required('First name field is required!')
});

export default UserManagementSchema;
