import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import cn from 'classnames';
import {
  DashboardDay,
  DashboardDays,
  DashboardField,
  DialogAction,
  DialogBody
} from 'routes/App/dashboard/styles';

import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';

import {SET_SETTINGS} from 'store/phoneOrdering/action';
import DialogActions from 'components/DialogActions/styles';

export default function SettingsPhoneOrderingModal({
  open,
  onClose,
  modalModel
}) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      ...modalModel
    },
    onSubmit: (values, {resetForm}) => {
      dispatch(SET_SETTINGS(values.workingDays));
      resetForm();
      onClose();
    }
  });

  useEffect(() => {
    formik.setFieldValue('workingDays', modalModel, false);
  }, [modalModel]);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='marginBottomXl'>
          Settings Phone Ordering
        </DialogTitle>
        <DialogBody>
          <DashboardField className='w100 DashboardFieldActions'>
            <DashboardDays>
              <DashboardDay
                key='day-name-mon'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.monday',
                    !formik.values?.workingDays?.monday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.monday
                })}
              >
                Mon
              </DashboardDay>
              <DashboardDay
                key='day-name-tue'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.tuesday',
                    !formik.values?.workingDays?.tuesday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.tuesday
                })}
              >
                Tue
              </DashboardDay>
              <DashboardDay
                key='day-name-wed'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.wednesday',
                    !formik.values?.workingDays?.wednesday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.wednesday
                })}
              >
                Wed
              </DashboardDay>
              <DashboardDay
                key='day-name-thu'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.thursday',
                    !formik.values?.workingDays?.thursday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.thursday
                })}
              >
                Thu
              </DashboardDay>
              <DashboardDay
                key='day-name-fri'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.friday',
                    !formik.values?.workingDays?.friday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.friday
                })}
              >
                Fri
              </DashboardDay>
              <DashboardDay
                key='day-name-sat'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.saturday',
                    !formik.values?.workingDays?.saturday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.saturday
                })}
              >
                Sat
              </DashboardDay>
              <DashboardDay
                key='day-name-sun'
                onClick={() =>
                  formik.setFieldValue(
                    'workingDays.sunday',
                    !formik.values?.workingDays?.sunday
                  )
                }
                className={cn({
                  isActive: formik.values?.workingDays?.sunday
                })}
              >
                Sun
              </DashboardDay>
            </DashboardDays>
          </DashboardField>
          <DialogActions className='justifyCenter marginTopNull'>
            <DialogAction className='w50'>
              <ButtonPrimary onClick={formik.submitForm}>
                <span>Save</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

SettingsPhoneOrderingModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object
};

SettingsPhoneOrderingModal.defaultProps = {
  open: false,
  onClose: () => {},
  modalModel: {}
};
