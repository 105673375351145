import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import BookADriverDetails from './Details';
import BookADriver from './List';
import CreateOrder from './CreateOrder';

const BookADriverRoutes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/create-order`} component={CreateOrder} />
      <Route
        path={`${match.path}/:back/:id/details`}
        component={BookADriverDetails}
      />
      <Route path={`${match.path}/`} component={BookADriver} />
    </Switch>
  );
};

export default BookADriverRoutes;
