import {createReducer} from 'redux-act';
import {
  GET_USER_INFO_FOR_CHAT,
  GET_USER_INFO_FOR_CHAT_SUCCESS,
  LOAD_CHAT
} from './action';

const initialState = () => ({
  userEmail: null,
  FullName: null,
  Phone: null,
  isLoading: false
});

const hubspotChatReducer = createReducer(
  {
    [GET_USER_INFO_FOR_CHAT_SUCCESS]: (store, payload) => ({
      ...store,
      userEmail: payload.userEmail,
      FullName: payload.FullName,
      Phone: payload.locationPhone
    }),
    [LOAD_CHAT]: (store, payload) => ({
      ...store,
      isLoading: payload
    })
  },
  initialState()
);

export default hubspotChatReducer;
