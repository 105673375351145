/* eslint-disable */
import moment from 'moment';
import {nanoid} from 'nanoid';
import arraysEqual from '../../../../helpers/functionUtils/compareArraysOfObjects';
import objectsEqual from '../../../../helpers/functionUtils/compareObjects';
import {generateEnableTime} from './GenerateEnableTime';
import {TIMESTAMP, TIMESTAMP_TYPE} from '../constants/DisableModalConstants';

// Forms time intervals for time periods
export function intervals(startString = '', endString = '', interval = 15) {
  const result = [];
  const start = moment(startString, 'h:mm A');
  const end = moment(endString, 'h:mm A');

  start.minutes(Math.ceil(start.minutes() / interval) * interval);

  const current = moment(start);

  while (current <= end) {
    result.push(current.format('h:mm A'));
    current.add(interval, 'minutes');
  }

  return result;
}

// Parse initial array from the server
export const daysMomentPickerParser = (data) => {
  const dayMinutes = 60;

  const modifiedWorkingDays = [];

  data &&
    data.map((d, index) => {
      const day = moment(new Date(2021, 4, 16, 0, 0, 0))
        .add(d.Start / dayMinutes, 'h')
        .format('ddd');
      const startOfWorkingDay = moment(new Date(2021, 4, 16, 0, 0, 0))
        .add(d.Start / dayMinutes, 'h')
        .format('h:mm A');
      const endOfWorkingDay = moment(new Date(2021, 4, 16, 0, 0, 0))
        .add(d.Start / dayMinutes, 'h')
        .add(
          moment(new Date(2021, 4, 16, 0, 0, 0))
            .add(d.Duration / dayMinutes, 'h')
            .format('H:mm'),
          'h'
        )
        .format('h:mm A');
      modifiedWorkingDays.push({
        id: index,
        day,
        duration: moment(new Date(2021, 4, 16, 0, 0, 0))
          .add(d.Duration / dayMinutes, 'h')
          .format('H:mm'),

        startOfWorkingDay,
        endOfWorkingDay
      });
    });

  const reduced = modifiedWorkingDays.reduce((acc, day_item) => {
    acc.forEach((a, idx) => {
      if (a.day === day_item.day) {
        acc[idx].timePeriods.push({
          id: nanoid(),
          endOfWorkingDay: day_item.endOfWorkingDay,
          hoursIntervals: intervals(
            day_item.startOfWorkingDay,
            day_item.endOfWorkingDay
          ),
          duration: day_item.duration,
          startOfWorkingDay: day_item.startOfWorkingDay
        });
      }
    });

    acc.push({
      ...day_item,
      timePeriods: [
        {
          id: nanoid(),
          endOfWorkingDay: day_item.endOfWorkingDay,
          duration: day_item.duration,
          hoursIntervals: intervals(
            day_item.startOfWorkingDay,
            day_item.endOfWorkingDay
          ),
          startOfWorkingDay: day_item.startOfWorkingDay
        }
      ]
    });

    return acc;
  }, []);
  return reduced.reduce((acc, current) => {
    const x = acc.find((item) => item.day === current.day);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

// Parse into array that will be rendered on the page
export const daysPickerArrayParser = (arr) => {
  const result = [];

  const compareSlots = (item1, item2) => {
    const item1TimePeriods = item1.timePeriods.map((el) => {
      const {id, hoursIntervals, ...rest} = el;
      return rest;
    });

    const item2TimePeriods = item2.timePeriods.map((el) => {
      const {id, hoursIntervals, ...rest} = el;
      return rest;
    });

    return (
      item1.endOfWorkingDay === item2.endOfWorkingDay &&
      arraysEqual(item1TimePeriods, item2TimePeriods, objectsEqual) &&
      item1.duration === item2.duration &&
      item1.endOfWorkingDay === item2.endOfWorkingDay
    );
  };

  arr.forEach((day_item) => {
    if (result.length) {
      if (result.some((item_res) => compareSlots(item_res, day_item))) {
        result.map((item_result) => {
          if (compareSlots(item_result, day_item)) {
            item_result.days.push(day_item.day);
          }
        });
      } else {
        result.push({
          id: nanoid(),
          days: [day_item.day],
          timePeriods: [...day_item.timePeriods],
          duration: day_item.duration,
          startOfWorkingDay: day_item.startOfWorkingDay,
          endOfWorkingDay: day_item.endOfWorkingDay
        });
      }
    } else {
      result.push({
        id: nanoid(),
        days: [day_item.day],
        duration: day_item.duration,
        timePeriods: [...day_item.timePeriods],
        startOfWorkingDay: day_item.startOfWorkingDay,
        endOfWorkingDay: day_item.endOfWorkingDay
      });
    }
  });
  return result;
};

export function sortPeriods(first, next) {
  if (first.Start < next.Start) {
    return -1;
  }

  if (first.Start > next.Start) {
    return 1;
  }

  return 0;
}

// crop server data objects
export function cropOpeningHours(list) {
  const nextList = [];

  const sortList = list.sort(sortPeriods);

  for (const period of sortList) {
    // const start = period.Start % minutesInDay;
    // const end = start + period.Duration;

    // if (end > minutesInDay) {
    //   nextList.push({
    //     Start: period.Start,
    //     Duration: minutesInDay - start
    //   });
    //
    //   nextList.push({
    //     Start: period.Start + minutesInDay - start,
    //     Duration: end - minutesInDay
    //   });
    // } else {
    //   nextList.push(period);
    // }

    nextList.push(period);
  }

  return nextList;
}

// Parser that forms data objects to send to the server
const getMinutesFromWeekStart = (d, time) =>
  moment(new Date(0)).day(d).weekday() * 24 * 60 +
  time.get('hour') * 60 +
  time.get('minute');

export const openingDaysServerParser = (days) => {
  const result = [];

  days.forEach((day) => {
    day.days.map((d) => {
      const resultObjects = day.timePeriods
        .filter(
          (period) =>
            period.endOfWorkingDay.trim() !== 'AM' &&
            period.startOfWorkingDay.trim() !== 'AM' &&
            period.endOfWorkingDay.trim() !== 'PM' &&
            period.startOfWorkingDay.trim() !== 'PM'
        )
        .map((period) => {
          const start = moment(period.startOfWorkingDay, 'h:mm A');
          let end = moment(period.endOfWorkingDay, 'h:mm A');
          const startPeriod = getMinutesFromWeekStart(d, start);

          if (end.isSameOrBefore(start)) {
            end.add(1, 'day');
          }

          const durationPeriod =
            moment.duration(moment(end).diff(start)).asHours() * 60;

          return {Start: startPeriod, Duration: Math.round(durationPeriod)};
        });

      const nextArray = resultObjects.sort((a, b) => {
        if (a.Start > b.Start) {
          return 1;
        }

        if (a.Start < b.Start) {
          return -1;
        }

        return 0;
      });

      let candidate = resultObjects[0];
      const mergedResultObjects = [candidate];

      for (let i = 1; i < resultObjects.length; i++) {
        const value = resultObjects[i];

        const valueEnd = value.Start + value.Duration;
        const candidateEnd = candidate.Start + candidate.Duration;

        if (value.Start <= candidateEnd && valueEnd >= candidateEnd) {
          candidate.Duration = valueEnd - candidate.Start;
        }

        if (value.Start > candidateEnd) {
          mergedResultObjects.push(value);
          candidate = mergedResultObjects[mergedResultObjects.length - 1];
        }
      }

      result.push(resultObjects);
    });
  });

  return mergeOpeningHours(result.flat().sort(sortPeriods));
};

//Merges data objects before sending to the server
export function mergeOpeningHours(list) {
  const nextList = [];
  const sortList = list.sort(sortPeriods);
  const MAX_END_SLOT_TIME = 10080;

  for (const period of sortList) {
    const prevTime = nextList.length ? nextList[nextList.length - 1] : null;
    const prevTimeEnd = prevTime && prevTime.Start + prevTime.Duration;

    // Check if previous period start later than current period or intersects with current period
    if (
      prevTime &&
      (prevTime.Start > period.Start || prevTimeEnd > period.Start) &&
      prevTime.Start + prevTime.Duration !== period.Start
    ) {
      prevTime.Duration = period.Start - prevTime.Start;
    }

    nextList.push(period);
  }

  const lastSlot = nextList.length > 1 && nextList[nextList.length - 1];
  const firstSlot = nextList.length > 1 && nextList[0];

  // Same logic as in the for loop, but it checks the intersection around the week, e.g. Saturday intersects with Sunday
  if (
    lastSlot &&
    firstSlot &&
    Math.abs(lastSlot.Start + lastSlot.Duration) > MAX_END_SLOT_TIME &&
    Math.abs(lastSlot.Start + lastSlot.Duration - MAX_END_SLOT_TIME) >
      Math.abs(firstSlot.Start)
  ) {
    nextList[nextList.length - 1].Duration =
      Math.abs(MAX_END_SLOT_TIME + firstSlot.Start) -
      nextList[nextList.length - 1].Start;
  }

  return nextList;
}

// generates time ranges for time selectors
export function generateTimeRanges(
  interval,
  language = window.navigator.language,
  from = 0,
  to = 24
) {
  const selectedRanges = {};
  const date = new Date();
  const format = {
    hour: 'numeric',
    minute: 'numeric'
  };

  for (
    let minutes = from * 60;
    minutes < (to === 24 ? 24 : to + 0.2) * 60;
    minutes += interval
  ) {
    date.setHours(0);
    date.setMinutes(minutes);
    selectedRanges[date.toLocaleTimeString(language, format)] = false;
  }
  return selectedRanges;
}

export const isLocationNameUnique = (
  locationName,
  locations,
  currentLocationName
) => {
  if (locationName === currentLocationName) return false;
  return locations.find(
    (location) =>
      location?.FullName.trim().toLowerCase() ===
      locationName.trim().toLowerCase()
  );
};

export function isOpeningHoursChange(prev, next) {
  if (!Array.isArray(prev) && !Array.isArray(next)) {
    return false;
  }

  if (
    (!Array.isArray(prev) && Array.isArray(next)) ||
    (Array.isArray(prev) && !Array.isArray(next))
  ) {
    return true;
  }

  if (prev.length !== next.length) {
    return true;
  }

  for (let index = 0; index < next.length; index++) {
    const {Start: PrevStart, Duration: PrevDuration} = prev[index];
    const {Start, Duration} = next[index];

    if (PrevStart !== Start || PrevDuration !== Duration) {
      return true;
    }
  }

  return false;
}

export const refreshClosingTime = (field, timezone, hours, setFieldValue) => {
  const enableTime = generateEnableTime(
    TIMESTAMP_TYPE[TIMESTAMP.OPENING_TIME],
    timezone,
    {
      dateValue: new Date(),
      entityName: '',
      periodValue: 'AM',
      reasonValue: 'Too busy',
      selectValue: '',
      radioValue: 'opening-time',
      reasonDescription: ''
    },
    hours
  );

  const value = {
    ClosedManuallyUntilTime: () => {
      const newDate = new Date(
        new Date(Number(enableTime) * 1000).toISOString()
      );
      setFieldValue(field, newDate);
      return newDate;
    },
    PickupEnableIn: () => {
      setFieldValue(field, enableTime);
      return enableTime;
    },
    DeliveryEnableIn: () => {
      setFieldValue(field, enableTime);
      return enableTime;
    }
  };

  return value[field];
};

export const changeTimeToFitWorkingHours = (timeValue, openingHours) => {
  const startWeek = moment().utc().startOf('week');
  let timeValueFromWeekStart = moment(timeValue).diff(startWeek, 'minutes');
  let addWeeks = 0;

  // check if the selected time is within the current week
  if (timeValueFromWeekStart > 10080) {
    addWeeks = Math.floor(timeValueFromWeekStart / 10080);
    timeValueFromWeekStart = timeValueFromWeekStart - 10080;
  }

  // checking if the open time falls within the restaurant's working hours...
  const inRange = openingHours.some((range) => {
    return (
      range.Start <= timeValueFromWeekStart &&
      timeValueFromWeekStart < range.Start + range.Duration
    );
  });

  // ...if not, then shift the selected 'closed until time' to the nearest next opening time
  if (!inRange) {
    let newDateUnix;

    const nextOpening = openingHours.filter(
      (hour) => hour.Start > timeValueFromWeekStart
    )[0];

    if (!nextOpening) {
      newDateUnix = moment()
        .utc()
        .add(1 + addWeeks, 'weeks')
        .startOf('week')
        .add(openingHours[0]?.Start, 'minutes')
        .unix();
    } else {
      newDateUnix = moment()
        .utc()
        .add(addWeeks, 'weeks')
        .startOf('week')
        .add(nextOpening.Start, 'minutes')
        .unix();
    }

    return new Date(new Date(Number(newDateUnix) * 1000).toISOString());
  }
  return timeValue;
};
