import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener,
  Fade,
  MenuItem,
  Paper,
  Popper as MuiPopper
} from '@material-ui/core';

export default function Popper({anchorEl, onClose, itemsData}) {
  return (
    <MuiPopper
      open
      anchorEl={anchorEl}
      placement='bottom-end'
      disablePortal
      className='MainDropDown'
      transition
    >
      {({TransitionProps}) => (
        <ClickAwayListener onClickAway={onClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper className='popper'>
              {itemsData.map(
                (item) =>
                  item && (
                    <MenuItem
                      disabled={item.disabled}
                      key={item.title}
                      onClick={() => {
                        item.action();
                        onClose();
                      }}
                    >
                      {item.title}
                    </MenuItem>
                  )
              )}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </MuiPopper>
  );
}

Popper.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  itemsData: PropTypes.array.isRequired
};
