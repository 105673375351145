import React from 'react';
import {Dialog} from '@material-ui/core';
import PropTypes from 'prop-types';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import Caption3 from '../../../../../components/typography/Caption3';
import {Body1} from '../../../../../components/typography';
import DialogActions from '../../../../../components/DialogActions/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline} from '../../../../../components/buttons';
import {DialogList, DialogListItem} from '../AdjustOrRefund/styles';

export default function AbstractConfirm({
  operation: {nameTitle, completedName},
  actionButton,
  onClose
}) {
  return (
    <Dialog open scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle className='marginBottomXl'>
          Are you sure you want to proceed with this
          {nameTitle}
        </DialogTitle>
        <Caption3>
          {`Your customers payment will be ${completedName} by xx.00 USD`}
        </Caption3>
        <DialogList className='DialogListMin marginBottomXl'>
          <DialogListItem>
            <Body1>Because of:</Body1>
            <Caption3>reason</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>For order number:</Body1>
            <Caption3>025899</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>Customer’s name:</Body1>
            <Caption3>Catherine Zapata</Caption3>
          </DialogListItem>
        </DialogList>
        <DialogActions>
          <DialogAction className='w40'>
            <ButtonOutline onClick={onClose}>Cancel</ButtonOutline>
          </DialogAction>
          <DialogAction className='w60'>{actionButton}</DialogAction>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
}

AbstractConfirm.propTypes = {
  operation: PropTypes.object.isRequired,
  actionButton: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
