import {createAction} from 'redux-act';

export const POST_BAD_ORDER = createAction('bookADriverDetails/POST_BAD_ORDER');
export const POST_BAD_ORDER_SUCCESS = createAction(
  'bookADriverDetails/POST_BAD_ORDER_SUCCESS'
);
export const GET_BAD_ORDER_DETAIL = createAction(
  'bookADriverDetails/GET_BAD_ORDER_DETAIL'
);
export const GET_BAD_ORDER_DETAIL_SUCCESS = createAction(
  'bookADriverDetails/GET_BAD_ORDER_DETAIL_SUCCESS'
);
export const SET_BAD_ORDER_DETAIL = createAction(
  'bookADriverDetails/SET_BAD_ORDER_DETAIL'
);
export const CLEAR_BAD_ORDER_DETAIL = createAction(
  'bookADriverDetails/CLEAR_BAD_ORDER_DETAIL'
);
export const SET_BAD_ORDER_DETAIL_SUCCESS = createAction(
  'bookADriverDetails/SET_BAD_ORDER_DETAIL_SUCCESS'
);
export const HANDLE_SUCCESS_MODAL = createAction(
  'bookADriverDetails/HANDLE_SUCCESS_MODAL'
);

export const HANDLE_FAIL_MODAL = createAction(
  'bookADriverDetails/HANDLE_FAIL_MODAL'
);
export const GET_ADDRESSES = createAction('bookADriverDetails/GET_ADDRESSES');
export const GET_ADDRESSES_SUCCESS = createAction(
  'bookADriverDetails/GET_ADDRESSES_SUCCESS'
);
export const CHECK_ADDRESS = createAction('bookADriverDetails/CHECK_ADDRESS');
export const CHECK_ADDRESS_SUCCESS = createAction(
  'bookADriverDetails/CHECK_ADDRESS_SUCCESS'
);
