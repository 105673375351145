import React from 'react';

export const IconSelectAll = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14 19C14 19.5523 13.5523 20 13 20H2C0.89543 20 0 19.1046 0 18V7C0 6.44771 0.447715 6 1 6C1.55228 6 2 6.44771 2 7V18H13C13.5523 18 14 18.4477 14 19ZM18 16H6C4.89543 16 4 15.1046 4 14V2C4 0.89543 4.89543 0 6 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16ZM6 2V14H18V2H6ZM7.99992 8.55992C7.6095 8.1695 7.6095 7.5365 7.99992 7.14608C8.39041 6.75559 9.02352 6.75566 9.41392 7.14623L10.8726 8.60555C10.943 8.67594 11.0571 8.67595 11.1274 8.60558L14.586 5.14697C14.9765 4.75652 15.6095 4.75652 16 5.14697C16.3904 5.53744 16.3904 6.17053 15.9999 6.56097L12.3084 10.2518C11.5858 10.9743 10.4143 10.9743 9.69172 10.2517L7.99992 8.55992Z'
      fill='#2F3D48'
    />
  </svg>
);

export default IconSelectAll;
