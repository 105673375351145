/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
import {takeEvery, select, put} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  deleteItemApi,
  getItemByIdApi,
  getItemsApi,
  getItemTree,
  patchItemApi,
  postItemsApi,
  updateItemApi
} from '../../helpers/services/api/menuEditor';
import {
  DELETE_ITEM,
  GET_ITEM_BY_ID,
  GET_ITEM_BY_ID_SUCCESS,
  GET_ITEM_MODAL,
  GET_ITEM_MODAL_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  PATCH_ITEM,
  PATCH_ITEM_SUCCESS,
  POST_ITEM,
  POST_ITEM_SUCCESS,
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  FETCH_SINGLE_ITEM_TREE,
  FETCH_SINGLE_ITEM_SUCCESS
} from './action';
import CustomToast from '../../components/shared/Utils/CustomToast';
import {ADD_NEW_ITEM, SET_UPDATED_ITEM, CLEAR_ITEM} from '../menuEditor/action';

const getItemsState = ({menuEditorItemReducer}) =>
  menuEditorItemReducer.itemsPagination;
const entityId = ({menuEditorModalsReducer}) =>
  menuEditorModalsReducer.entityId;
const getItemsModalState = ({menuEditorItemReducer}) =>
  menuEditorItemReducer.itemsModalPagination;

export function* getItemById({payload}) {
  try {
    const {data} = yield getItemByIdApi(payload);

    if (data) {
      yield put(GET_ITEM_BY_ID_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getItems({payload}) {
  try {
    const itemsParams = yield select(getItemsState);
    const pagination = {
      ...itemsParams,
      outStock: payload.outStock,
      ...payload
    };

    const {data} = yield getItemsApi(pagination);

    if (data) {
      yield put(GET_ITEMS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* fetchSingleItemTree({payload}) {
  try {
    const {data} = yield getItemTree(payload);

    if (data) {
      yield put(FETCH_SINGLE_ITEM_SUCCESS(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

export function* getItemsModal({payload}) {
  try {
    // const {successAction, ...payload} = payload;
    const itemsParams = yield select(getItemsModalState);
    const pagination = {
      ...itemsParams,
      // outStock: payload.outStock
      ...payload
    };

    const {data} = yield getItemsApi(pagination);

    if (data) {
      // if (successAction) {
      //   yield put(successAction(data));
      // } else {
      // }
      yield put(GET_ITEM_MODAL_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* postNewItem({payload}) {
  try {
    const {data} = yield postItemsApi(payload);

    if (data) {
      yield put(ADD_NEW_ITEM({id: data.PosId, data}));
      toast.success('Item has been created');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* updateItem({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield updateItemApi(id, form);

    if (data) {
      yield put(SET_UPDATED_ITEM({id, data: form}));
      toast.success('Item has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* patchItem({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield patchItemApi(id || form?.id, form);

    if (data) {
      yield put(SET_UPDATED_ITEM({id: id || form?.id, data: form}));
      toast.success('Item has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* deleteItem({payload}) {
  try {
    const id = yield select(entityId);
    const {data} = yield deleteItemApi(id || payload);

    if (data) {
      yield put(CLEAR_ITEM(id || payload));
      toast.success('Item has been delete');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* menuEditorItemSaga() {
  yield takeEvery(GET_ITEMS, getItems);
  yield takeEvery(POST_ITEM, postNewItem);
  yield takeEvery(UPDATE_ITEM, updateItem);
  yield takeEvery(PATCH_ITEM, patchItem);
  yield takeEvery(DELETE_ITEM, deleteItem);
  yield takeEvery(GET_ITEM_BY_ID, getItemById);
  yield takeEvery(GET_ITEM_MODAL, getItemsModal);
  yield takeEvery(FETCH_SINGLE_ITEM_TREE, fetchSingleItemTree);
}

export default menuEditorItemSaga;
