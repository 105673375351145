import styled from 'styled-components';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import {ButtonPrimary} from '../../../components/buttons';
import {DashboardActionCenter} from '../dashboard/styles';
import IconStarDark from '../../../assets/iconStarDark.svg';

export const ImgSurvey = styled.img`
  display: block;
  max-width: 293px;
  width: 100%;
  margin: 60px auto 0 auto;

  &.ImgSurveySoon {
    min-height: 282px;
    max-width: 340px;
  }
`;

export const FeedbackInner = styled(DashboardInner)`
  padding: 80px 34px 50px 34px;
  @media (max-width: 1023px) {
    padding: 50px 34px;
  }
  @media (max-width: 767px) {
    padding: 30px 15px 44px 15px;
  }
`;
export const FeedbackInnerButton = styled(ButtonPrimary)`
  margin: 60px auto 0 auto;
  max-width: 300px;
  width: 100%;
  display: block;
`;
export const FeedbackActionCenter = styled(DashboardActionCenter)`
  margin-top: 25px;
`;
export const FeedbackItems = styled.div`
  display: flex;
  align-items: flex-start;
  margin: -10px 10px -10px -10px;
  @media (max-width: 1750px) {
    margin: -10px;
    width: calc(100% + 20px);
    justify-content: space-between;
  }
  @media (max-width: 1000px) {
    margin: -7px;
  }
  @media (max-width: 767px) {
    margin: -4px;
    width: calc(100% + 8px);
  }
  @media (max-width: 599px) {
    flex-wrap: wrap;
    margin: -3px;
  }
`;
export const FeedbackItem = styled.div`
  flex: 0 0 auto;
  padding: 10px;
  text-align: center;
  &:nth-last-child(1),
  &:nth-last-child(2) {
    flex: 1 1 auto;
    @media (max-width: 1900px) {
      flex: 0 0 auto;
    }
    @media (max-width: 1439px) {
      flex: 1 1 auto;
    }
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    @media (max-width: 1439px) {
      flex: 0 0 auto;
    }
    @media (max-width: 1000px) {
      flex: 1 1 auto;
    }
  }
  @media (max-width: 1439px) {
    flex: 1 1 auto;
  }
  @media (max-width: 1000px) {
    padding: 7px;
  }
  @media (max-width: 767px) {
    padding: 4px;
  }
  @media (max-width: 599px) {
    flex: 0 0 50% !important;
    padding: 3px;
  }

  .Subtitle {
    @media (max-width: 1000px) {
      font-size: 13px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .Body0 {
    @media (max-width: 1000px) {
      font-size: 13px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  & > div {
    position: relative;
    padding-right: 26px;
    @media (max-width: 767px) {
      padding-right: 22px;
    }
    @media (max-width: 599px) {
      padding-right: 21px;
    }

    .ButtonInfoDrop {
      position: absolute;
      top: 1px;
      right: 0;
      @media (max-width: 1000px) {
        top: 0;
      }
      @media (max-width: 767px) {
        top: 0;
        width: 20px;
        height: 20px;
      }
      @media (max-width: 599px) {
        width: 18px;
        height: 18px;
        top: 1px;
      }
    }

    &:first-child {
      @media (max-width: 599px) {
        display: inline-block;
      }
    }
  }
`;

export const FeedbackPanelContainer = styled(DashboardInner)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1900px) {
    display: block;
  }

  .DashboardFieldLabel.widthAuto {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .DashboardFields.marginMinus {
    flex: 0 0 auto;
    @media (max-width: 1750px) {
      margin-top: 10px;
    }
    @media (max-width: 1000px) {
      margin: 15px 0 0 0;
      width: 100%;
      .wAutoSpace {
        padding: 0;
      }
    }
    &.DashboardFieldsCustomerFeedback {
      .DashboardFieldCustomerFeedback {
        & > * {
          flex: 0 0 auto;
        }
      }
    }
  }
`;

export const FeedbackRating = styled.div`
  display: inline-block;
  padding-left: 25px;
  position: relative;
  @media (max-width: 767px) {
    padding-left: 20px;
  }

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    background-image: url(${IconStarDark});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const FeedbackGrades = styled.div`
  display: flex;
  align-items: stretch;
  margin: 15px -10px -10px -10px;
  @media (max-width: 767px) {
    margin: 15px -7px -7px -7px;
    flex-wrap: wrap;
  }
`;

export const FeedbackGrade = styled.div`
  flex: 0 0 calc(20% - 20px);
  margin: 10px;
  text-align: center;
  @media (max-width: 767px) {
    margin: 7px;
    flex: 0 0 calc(33.3333333% - 14px);
  }
  @media (max-width: 599px) {
    margin: 7px;
    flex: 0 0 calc(50% - 14px);
  }

  .responses {
    margin-top: 2px;
    min-height: 40px;
    line-height: 1.1;
  }
`;
export const FeedbackGradeBox = styled.div`
  background-color: rgba(146, 156, 185, 0.2);
  border-radius: 8px;
  height: 235px;
  overflow: hidden;
  position: relative;
  margin: 8px 0 10px 0;
  @media (max-width: 1000px) {
    height: 160px;
  }
  @media (max-width: 767px) {
    height: 180px;
    margin: 8px 0 5px 0;
  }
  @media (max-width: 440px) {
    height: 140px;
    margin-top: 7px;
  }
`;
export const FeedbackGradeThumb = styled.div`
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${(props) => props.height};
  background-color: #17c981;
  transform: translateY(100%);
  animation: AnimateNumber 1s linear forwards;

  @keyframes AnimateNumber {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
export const SurveyItems = styled.div`
  margin-top: 34px;

  &.MostPopularAnswers {
    & > div {
      padding-left: 50px;
      position: relative;
      @media (max-width: 599px) {
        padding-left: 34px;
      }

      &::before {
        position: absolute;
        bottom: -7px;
        left: 0;
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @media (max-width: 599px) {
          width: 22px;
          height: 22px;
          bottom: -3px;
        }
      }
    }
  }
`;
export const SurveyItem = styled.div`
  display: block;
  margin-bottom: 32px;
  @media (max-width: 1000px) {
    margin-bottom: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 18px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.SurveyItemGreat {
    &::before {
      background-color: #17c981;
    }
  }

  &.SurveyItemBad {
    &::before {
      background: linear-gradient(0deg, #ff2d38, #ff2d38), #ff0505;
    }
  }
`;

export const SurveyItemsDescription = styled.div`
  display: flex;
  align-items: center;
  margin: 30px -20px -10px -20px;
  flex-wrap: wrap;
  @media (max-width: 599px) {
    margin-top: 16px;
  }
`;
export const SurveyItemDescription = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 20px;
  flex: 0 0 auto;
  @media (max-width: 599px) {
    flex: 1 1 auto;
  }

  &::before {
    display: inline-block;
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 20px;
    flex: 0 0 auto;
    @media (max-width: 599px) {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
  }

  &.SurveyItemDescriptionGreat {
    max-width: 125px;
    @media (max-width: 599px) {
      max-width: 100%;
    }

    &::before {
      background-color: #17c981;
    }
  }

  &.SurveyItemDescriptionBad {
    max-width: 310px;
    @media (max-width: 599px) {
      max-width: 100%;
    }

    &::before {
      background: linear-gradient(0deg, #ff2d38, #ff2d38), #ff0505;
    }
  }
`;
export const SurveyItemProgress = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 15px;
  background-color: rgba(146, 156, 185, 0.2);
  border-radius: 8px;
  margin-top: 2px;
  overflow: hidden;
`;
export const SurveyItemProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #4671db;
  width: ${(props) => props.width};
  animation: AnimateProgressBar 1s linear forwards;
  transform: translateX(-100%);
  transition-property: width;
  transition-duration: 0.3s;
  @keyframes AnimateProgressBar {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const DisableContainer = styled.div`
  left: 272px;
  top: 74px;
  height: 60px;
  width: calc(100% - 272px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #ff2d38;
  z-index: 6;
  padding: 8px 20px;
  @media (max-width: 1250px) {
    top: 96px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 70px;
    height: 40px;
    padding: 5px 20px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }
  & > div {
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1300px) {
      font-size: 18px;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }
  svg {
    margin-right: 14px;
    flex: 0 0 auto;
    @media (max-width: 1000px) {
      width: 30px;
      height: 35px;
    }
    @media (max-width: 767px) {
      width: 24px;
      height: 29px;
      margin-right: 10px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }

  a.Lg {
    margin-left: 5px;
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1300px) {
      font-size: 18px;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }
`;

export const UnderlineButton = styled.button`
  display: inline-block;
  font-style: normal;
  border: none;
  border-bottom: 1px solid #fff;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  font-family: 'Poppins';
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
  line-height: 1;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
  &:hover {
    border-bottom-color: transparent;
  }
  &.noClickEvent {
    pointer-events: none;
    border: none;
  }
`;
