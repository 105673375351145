/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {ButtonOutline} from '../../../../components/buttons';
import DialogContainer from '../../../../components/DialogContainer/styles.js';
import DialogClose from '../../../../components/DialogClose/styles.js';
import {DialogBody} from '../../dashboard/styles.js';
import Loading from '../../../../components/shared/Utils/Loading';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';

function ConfirmModal({isLoading, isOpen, message, onCancel, onOk}) {
  const onOkClick = () => {
    onOk();
  };

  const onCancelClick = () => {
    onCancel();
  };

  return (
    <Dialog open={isOpen} scroll='body' onClose={onCancelClick}>
      <DialogContainer className='DialogSm ConfirmModal'>
        <DialogClose onClick={onCancelClick} />
        <DialogTitle>{message}</DialogTitle>
        <DialogBody className='ConfirmModal-BtnPanel'>
          {isLoading && <Loading />}
          {!isLoading && (
            <DialogActions className='marginTopNull DialogActionsMin DialogActionsNoWrap'>
              <DialogAction className='w50'>
                <ButtonOutline className='ButtonSm' onClick={onOkClick}>
                  Confirm
                </ButtonOutline>
              </DialogAction>
              <DialogAction className='w50'>
                <ButtonOutline
                  className='ButtonSmall Dark'
                  onClick={onCancelClick}
                >
                  Cancel
                </ButtonOutline>
              </DialogAction>
            </DialogActions>
          )}
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

ConfirmModal.defaultProps = {
  isLoading: false,
  isOpen: false,
  message: '',
  onCancel: () => {},
  onOk: () => {}
};

export default ConfirmModal;
