/* eslint-disable */
import {treeBranchesScheme} from './MenuTree';

function getSearchString(item, type) {
  const branchScheme = treeBranchesScheme[type];
  const searchString = branchScheme.searchFields.reduce((str, field) => {
    str += ` ${item[field] || ''}`;

    return str;
  }, '');

  return searchString.toLowerCase();
}

export function createSingleBranch(item, type) {
  const branchScheme = treeBranchesScheme[type];
  const {fieldId, childrenField} = branchScheme;
  const children = item[childrenField];

  return {
    $id: item[fieldId],
    $branchScheme: branchScheme,
    $searchString: getSearchString(item, type),
    $children: Array.isArray(children) ? children : []
  };
}

export function countOutOffStock(sectionId, store) {
  const {sectionsBranchMap, activeSectionBranchMap} = store;
  const sectionBranch = sectionsBranchMap[sectionId];
  const activeSectionBranch = activeSectionBranchMap[sectionId];

  const OutOfStock = {
    Items: new Set(),
    Modifiers: new Set()
  };
  const Hidden = {
    Items: new Set(),
    Modifiers: new Set()
  };

  const processElement = (childId, parentBranch, OutOfStock, Hidden, level) => {
    const {
      childrenStore,
      childrenBranchStore,
      childrenActiveBranchStore
    } = parentBranch.$branchScheme;
    const data = store[childrenStore][childId];
    const branch = store[childrenBranchStore][childId];
    const activeBranch = store[childrenActiveBranchStore][childId];

    if (!activeBranch) {
      return;
    }

    if (branch.$branchScheme.type === 'item') {
      if (data.InStock === false) {
        OutOfStock.Items.add(childId);
      }

      if (data.Invisible === true) {
        Hidden.Items.add(childId);
      }
    }

    if (branch.$branchScheme.type === 'modifier') {
      if (data.InStock === false) {
        OutOfStock.Modifiers.add(childId);
      }

      if (data.Invisible === true) {
        Hidden.Modifiers.add(childId);
      }
    }

    if (activeBranch.$children) {
      processChildren(
        activeBranch.$children,
        branch,
        OutOfStock,
        Hidden,
        level + 1
      );
    }
  };

  const processChildren = (children, branch, OutOfStock, Hidden, level) => {
    if (level > 8) {
      return;
    }

    children.forEach((childId) =>
      processElement(childId, branch, OutOfStock, Hidden, level)
    );
  };

  if (activeSectionBranch.$children) {
    processChildren(
      activeSectionBranch.$children,
      sectionBranch,
      OutOfStock,
      Hidden,
      1
    );
  }

  store.activeSectionBranchMap[sectionId] = {
    ...activeSectionBranch,
    OutOfStock,
    Hidden
  };
}
