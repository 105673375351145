import React, {createRef, useState} from 'react';
import cn from 'classnames';
import {Form, Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import T from 'prop-types';
// import ReCAPTCHA from 'react-google-recaptcha';
import {
  Logos,
  LogosItem,
  SignUpBottom,
  SignUpBottomText,
  SignUpFeedback,
  SignUpFeedbackBottom,
  SignUpFeedbackButton,
  SignUpFeedbackCompany,
  SignUpFeedbackDescription,
  SignUpFeedbackInfo,
  SignUpFeedbackLogo,
  SignUpFeedbackPhoto,
  SignUpFeedbackTop,
  SignUpLink,
  SignUpSection
} from 'routes/Auth/registration/style';
import {ErrorSignWrapper, ErrorSign} from 'components/errorSign/errorSign';
import PhotoFeedback from 'assets/signUp1.jpg';
import IconFeedback from 'assets/logo-slice-factory.png';
import SauceLogo from 'assets/logo-sauce.svg';
import DialogField from 'components/DialogField/styles';
import {FieldLabel} from 'components/FieldLabel/styles';
import FieldInput from 'components/FieldInput';
import {ButtonPrimary} from 'components/buttons';
import DialogFields from 'components/DialogFields/styles';
import LinkButton from 'components/LinkButton/styles';
import FieldPassword from 'components/FieldPassword';
import SignUpSchema from 'helpers/services/formValidationSchemas/SignUpSchema';
import EmailSchema from 'helpers/services/formValidationSchemas/EmailSchema';
import authData from 'store/global/auth/selectors';
import LogoBagkok from 'assets/logo-bangkok-happy-bowl.png';
import LogoCarlos from 'assets/logo-carlos-okellys.png';
import LogoCarabao from 'assets/logo-carabao-express.png';
import LogoHolbox from 'assets/logo-holbox.png';
import LogoPizza from 'assets/logo-uncle-maddios-pizza.jpg';
import {Body0} from 'components/typography';
import {
  AuthForm,
  AuthLink,
  AuthLogo,
  AuthSection,
  AuthTitle
} from 'routes/Auth/styles';

const SignUpTemplate = ({
  showBottomLinks,
  showLogos,
  actionSignUp,
  actionSaveEmail
}) => {
  // const recaptchaRef = createRef();
  const {Email, isEmailEntered} = authData();
  const initValues = isEmailEntered
    ? {
        Email,
        Password: '',
        ConfirmPassword: ''
      }
    : {
        Email: ''
      };
  const [upFeedback, setUpFeedback] = useState('false');

  const handleToggle = () => {
    setUpFeedback(!upFeedback);
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      validationSchema={isEmailEntered ? SignUpSchema : EmailSchema}
      onSubmit={(values, {setSubmitting}) => {
        if (isEmailEntered) {
          actionSignUp({
            ...values,
            Email: values.Email.toLowerCase()
            // captchaRef: recaptchaRef.current
          });
        } else {
          actionSaveEmail({...values, Email: values.Email.toLowerCase()});
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        handleChange,
        errors,
        setFieldError,
        setFieldValue,
        touched
      }) => (
        <Form>
          <ConnectedFocusError />
          <SignUpSection className={upFeedback ? 'isActive' : null}>
            <SignUpFeedback className='SignUpFeedback'>
              <SignUpFeedbackTop>
                <SignUpFeedbackButton
                  className='SignUpFeedbackButton'
                  onClick={handleToggle}
                />
              </SignUpFeedbackTop>
              <SignUpFeedbackPhoto
                style={{backgroundImage: `url(${PhotoFeedback})`}}
              />

              <SignUpFeedbackBottom>
                <SignUpFeedbackDescription>
                  &quot;Within a few weeks we had seen a clear increase in
                  overall online sales and doubled our mobile app sales.&quot;
                </SignUpFeedbackDescription>
                <SignUpFeedbackCompany>
                  <SignUpFeedbackLogo src={IconFeedback} />
                  <SignUpFeedbackInfo>
                    <p>Dom DiDiana.</p>
                    <p>
                      President <br /> The Slice Factory
                    </p>
                  </SignUpFeedbackInfo>
                </SignUpFeedbackCompany>
              </SignUpFeedbackBottom>
            </SignUpFeedback>
            <AuthSection className='Referral'>
              <div>
                <AuthLogo src={SauceLogo} />
                <AuthForm>
                  {isEmailEntered ? (
                    <AuthTitle>
                      <span>Sauce up</span> your Online Sales
                    </AuthTitle>
                  ) : (
                    <AuthTitle>
                      <span>Sign up</span> to start getting orders
                    </AuthTitle>
                  )}

                  <DialogFields>
                    <DialogField className='w100 mTopMd'>
                      <FieldLabel className='FieldLabelLg mBottomMd'>
                        Work email address <span>*</span>
                      </FieldLabel>
                      <FieldInput
                        type='email'
                        value={values.Email}
                        onChange={handleChange}
                        name='Email'
                        placeholder='Work email address'
                        className={cn({error: errors.Email && touched.Email})}
                      />

                      <ErrorSignWrapper>
                        <ErrorSign>
                          {errors.Email && touched.Email && errors.Email}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    </DialogField>
                    {isEmailEntered && (
                      <>
                        <DialogField className='w100 '>
                          <FieldLabel className='FieldLabelLg mBottomMd'>
                            Password <span>*</span>
                          </FieldLabel>
                          <FieldPassword
                            value={values.Password}
                            onChange={handleChange}
                            name='Password'
                            className={cn({
                              error: errors.Password && touched.Password
                            })}
                            placeholder='Password'
                          />

                          <ErrorSignWrapper>
                            <ErrorSign>
                              {errors.Password &&
                                touched.Password &&
                                errors.Password}
                            </ErrorSign>
                          </ErrorSignWrapper>
                        </DialogField>

                        <DialogField className='w100 '>
                          <FieldLabel className='FieldLabelLg mBottomMd'>
                            Confirm password <span>*</span>
                          </FieldLabel>
                          <FieldPassword
                            value={values.ConfirmPassword}
                            onChange={handleChange}
                            name='ConfirmPassword'
                            className={cn({
                              error:
                                errors.ConfirmPassword &&
                                touched.ConfirmPassword
                            })}
                            placeholder='Confirm password'
                          />

                          <ErrorSignWrapper>
                            <ErrorSign>
                              {errors.ConfirmPassword &&
                                touched.ConfirmPassword &&
                                errors.ConfirmPassword}
                            </ErrorSign>
                          </ErrorSignWrapper>
                        </DialogField>

                        {/* <ReCAPTCHA */}
                        {/*  ref={recaptchaRef} */}
                        {/*  size='invisible' */}
                        {/*  sitekey='6LcmxFMbAAAAACvi_HL_6P7rBFIqJyDXCBcX0cVz' */}
                        {/* /> */}
                      </>
                    )}
                    <DialogField className='w100 Center mTopMd'>
                      <ButtonPrimary className='w400' type='submit'>
                        <span>
                          {isEmailEntered ? "Let's Do This!" : 'Sign Me Up'}
                        </span>
                      </ButtonPrimary>
                    </DialogField>
                    {!isEmailEntered && (
                      <DialogField className='w100 Center mTopMd'>
                        <Body0>
                          Already have an account?{' '}
                          <LinkButton className='Md' to='/'>
                            Sign in!
                          </LinkButton>
                        </Body0>
                      </DialogField>
                    )}
                  </DialogFields>
                  {!isEmailEntered && !showBottomLinks && (
                    <SignUpBottom>
                      By signing up you agree to Sauce
                      <SignUpLink
                        href={process.env.REACT_APP_TERMS_SERVICE_URL}
                        target='_blank'
                      >
                        Terms
                      </SignUpLink>{' '}
                      and
                      <SignUpLink
                        href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                        target='_blank'
                      >
                        Privacy Policy
                      </SignUpLink>
                    </SignUpBottom>
                  )}
                </AuthForm>
              </div>
              <div>
                {!isEmailEntered && showBottomLinks && (
                  <SignUpBottom>
                    By signing up you agree to Sauce
                    <AuthLink
                      href={process.env.REACT_APP_TERMS_SERVICE_URL}
                      target='_blank'
                    >
                      Terms
                    </AuthLink>{' '}
                    and
                    <AuthLink
                      href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                      target='_blank'
                    >
                      Privacy Policy
                    </AuthLink>
                  </SignUpBottom>
                )}
                {showLogos && (
                  <>
                    <SignUpBottomText className='text-center'>
                      Trusted by local restaurants in over 30 states.
                      Restaurants like yours.{' '}
                    </SignUpBottomText>
                    <Logos>
                      <LogosItem src={LogoBagkok} />
                      <LogosItem src={LogoCarlos} />
                      <LogosItem src={LogoCarabao} />
                      <LogosItem src={LogoHolbox} />
                      <LogosItem src={LogoPizza} />
                    </Logos>
                  </>
                )}
              </div>
            </AuthSection>
          </SignUpSection>
        </Form>
      )}
    </Formik>
  );
};

SignUpTemplate.propTypes = {
  showLogos: T.bool,
  showBottomLinks: T.bool.isRequired,
  actionSignUp: T.func.isRequired,
  actionSaveEmail: T.func.isRequired
};

SignUpTemplate.defaultProps = {
  showLogos: false
};

export default SignUpTemplate;
