import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DashboardField, DashboardFields} from '../../../../dashboard/styles';
import Subtitle from '../../../../../../components/typography/Subtitle1';
import SelectWrapper from '../../../../../../components/select/SelectComponentStyles';
import Field from '../../../../../../components/Field/styles';
import {
  RC_GENERATE_ORDER_REFUND,
  RC_SET_SUBTOTAL_PERCENT
} from '../../../../../../store/refundsCalculator/action';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';
import {SubtotalRefundContainer} from '../../styles';

const SubtotalRefund = ({typeValue, setFaultError}) => {
  const dispatch = useDispatch();
  const {locationFault, percent} = useSelector(
    (state) => state.refundsCalculator
  );
  const [valueSelect, setValueSelect] = useState(0);
  const [valueField, setValueField] = useState('');
  const [validateError, setValidateError] = useState('');

  const submitChanges = () => {
    if (locationFault) {
      dispatch(RC_GENERATE_ORDER_REFUND(typeValue));
    } else {
      setFaultError('Mandatory field');
    }
  };

  const handleChange = (event) => {
    setValueSelect(event.target.value);
    if (event.target.value !== 'other') {
      dispatch(RC_SET_SUBTOTAL_PERCENT(event.target.value));
    }
  };

  useEffect(() => {
    if (percent) {
      submitChanges();
    }
  }, [percent]);

  return (
    <SubtotalRefundContainer>
      <DashboardFields className='noWrapDesktop AlignCenter'>
        <DashboardField className='wAuto labelFieldFix'>
          <Subtitle>Subtotal refund %</Subtitle>
        </DashboardField>
        <DashboardField className='wAutoSpace width260'>
          <SelectWrapper className='SelectMd w100'>
            <FormControl className='select-formcontrol'>
              <Select
                value={valueSelect}
                name='subtotal-refund'
                onChange={handleChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                <MenuItem value={0}>Select</MenuItem>
                <MenuItem value={15}>15%</MenuItem>
                <MenuItem value={25}>25%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
                <MenuItem value={75}>75%</MenuItem>
                <MenuItem value='other'>Other</MenuItem>
              </Select>
            </FormControl>
          </SelectWrapper>
        </DashboardField>
      </DashboardFields>
      {valueSelect === 'other' && (
        <DashboardFields className='noWrapDesktop AlignCenter'>
          <DashboardField className='wAuto labelFieldFix'>
            <Subtitle>
              Other % <span>*</span>
            </Subtitle>
          </DashboardField>
          <DashboardField className='wAutoSpace width140'>
            <Field>
              <input
                className={cn('', {
                  error: validateError
                })}
                type='number'
                placeholder='0'
                onChange={(e) => {
                  setValueField(e.target.value);
                  if (e.target.value < 1 || e.target.value > 100) {
                    setValidateError('Enter a number between 1-100');
                  } else {
                    setValidateError('');
                    dispatch(RC_SET_SUBTOTAL_PERCENT(e.target.value));
                  }
                }}
                value={valueField}
              />
            </Field>
            <ErrorSignWrapper>
              <ErrorSign className='colorRed'>
                {!!validateError && validateError}
              </ErrorSign>
            </ErrorSignWrapper>
          </DashboardField>
        </DashboardFields>
      )}
    </SubtotalRefundContainer>
  );
};

SubtotalRefund.propTypes = {
  typeValue: PropTypes.number.isRequired,
  setFaultError: PropTypes.func
};

SubtotalRefund.defaultProps = {
  setFaultError: () => {}
};

export default SubtotalRefund;
