import styled from 'styled-components';

const BoxMenuItem = styled.div`
  flex: 0 0 calc(50% - 22px);

  .BoxMenuItemContainer {
    background-color: #f2f1f6;
    padding: 10px;
    border-radius: 5px;
  }

  .RecursionRelation_ItemType {
    flex: 0 0 auto;
    color: silver;
    font-size: 12px;
  }

  .RecursionRelation_ItemTitle {
    flex: 1 1 auto;
    word-break: break-word;
  }
`;

export default BoxMenuItem;
