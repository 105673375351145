import {request} from 'helpers/services/utils/request';
import {GET, POST} from 'helpers/services/constants/API_CONSTANTS';
import {
  GET_INSIGHTS_GROWS_INFO,
  POST_INSIGHTS_GROWTH_CONTACT_US,
  GET_INSIGHTS_PERFORMANCE_SETTINGS_INFO,
  POST_INSIGHTS_PERFORMANCE_SETTINGS_INFO,
  GET_INSIGHTS_PERFORMANCE_INFO,
  GET_LOCATIONS_WITH_INSIGHTS,
  POST_INSIGHTS_GROWTH_MODULES,
  GET_INSIGHTS_PERFORMANCE_SETTINGS_DATES
} from 'helpers/services/constants/API_ENDPOINTS';

export const getInsightsGrowthInfo = async (locationId) => {
  return request(GET, GET_INSIGHTS_GROWS_INFO(locationId));
};

export const postContactUsForm = async ({locationId, body, feature}) => {
  return request(POST, POST_INSIGHTS_GROWTH_CONTACT_US(locationId, feature), {
    data: body
  });
};

export const getInsightsPerformanceSettings = async (locationId) => {
  return request(GET, GET_INSIGHTS_PERFORMANCE_SETTINGS_INFO(locationId));
};

export const postInsightsPerformanceSettings = async ({locationId, body}) => {
  return request(POST, POST_INSIGHTS_PERFORMANCE_SETTINGS_INFO(locationId), {
    data: body
  });
};

export const getInsightsPerformanceInfo = async (locationId, from, to) => {
  return request(GET, GET_INSIGHTS_PERFORMANCE_INFO(locationId, from, to));
};

export const getInsightsPerformanceSettingsDates = async (locationId) => {
  return request(GET, GET_INSIGHTS_PERFORMANCE_SETTINGS_DATES(locationId));
};

export const getLocationsWithInsights = async (search) => {
  return request(GET, GET_LOCATIONS_WITH_INSIGHTS(search));
};

export const postInsightGrowthModules = async (data, id) => {
  return request(POST, POST_INSIGHTS_GROWTH_MODULES(id), {data});
};
