/* eslint-disable */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '../../../../components/Modals/Modal';
import {DialogAction, DialogBody, DialogText} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {userInfo} from '../../../../store/global/account/selectors';
import {
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_MODAL,
  RESEND_CONFIRMATION
} from '../../../../store/global/account/action';
import Loading from '../../../../components/shared/Utils/Loading';
import LinkBack from '../../../../components/LinkBack/styles';

const ConfirmEmailModal = ({onCloseAction, emailSent, isEmailSending}) => {
  const dispatch = useDispatch();
  const {
    userData: {email, email_confirmed}
  } = useSelector(userInfo);

  const onSubmit = () => {
    if (!email_confirmed && !emailSent) {
      dispatch(CONFIRM_EMAIL({email}));
      return;
    }
    dispatch(CONFIRM_EMAIL_MODAL({open: false}));
  };

  const renderBodyText = (condition) => {
    if (!email_confirmed) {
      if (condition) {
        return `An activation link was sent to ${email} containing your activation link`;
      }
      return `An activation link will be sent to ${email} containing your activation link.`;
    }
    return 'Your email has been verified!';
  };

  return (
    <Modal
      onCloseAction={onCloseAction}
      title={
        <Box
          lineHeight={1.2}
          fontWeight='bold'
          color='#172751'
          textAlign='center'
          fontSize={24}
        >
          Verify your email
        </Box>
      }
      body={
        <DialogBody className='positionRelative'>
          {isEmailSending && (
            <Backdrop
              style={{
                zIndex: 100,
                background: 'rgba(255, 255, 255, 0.5)',
                position: 'absolute'
              }}
              open
            >
              <Loading />
            </Backdrop>
          )}
          <DialogText>
            {renderBodyText(emailSent)}
            {emailSent && (
              <Box mt={4}>
                Didn't receive an email?
                <br />
                <LinkBack
                  className='link'
                  onClick={() => {
                    dispatch(
                      RESEND_CONFIRMATION({
                        email,
                        callBack: () =>
                          dispatch(CONFIRM_EMAIL_MODAL({open: false}))
                      })
                    );
                  }}
                >
                  Resend the link!
                </LinkBack>
              </Box>
            )}
          </DialogText>
          <DialogAction>
            <ButtonPrimary onClick={() => onSubmit()} type='submit'>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      }
    />
  );
};

ConfirmEmailModal.propTypes = {
  onCloseAction: PropTypes.func,
  emailSent: PropTypes.bool,
  isEmailSending: PropTypes.bool
};

ConfirmEmailModal.defaultProps = {
  onCloseAction: () => {},
  emailSent: false,
  isEmailSending: false
};

export default ConfirmEmailModal;
