import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {RadioGroup} from '@material-ui/core';
import T from 'prop-types';
import useSound from 'use-sound';
import FieldMaskInput from '../../../components/FieldMaskInput';
import {
  DashboardActionCenter,
  DashboardField,
  DashboardFields,
  FieldInput
} from '../dashboard/styles';
import RadioS2E from '../../../components/Radiobox';
import {FieldLabel} from '../../../components/FieldLabel/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../components/errorSign/errorSign';
import {ButtonPrimary} from '../../../components/buttons';
import exampleSound from '../../../assets/virtual-number-example.mp3';
import {phoneFormatForView} from '../../../helpers/functionUtils/helpFunctions';
import {Body1} from '../../../components/typography';
import {VirtualNumberFields, VirtualNumberPlay} from './styles';
import ButtonOutline from '../../../components/buttons/ButtonOutline/styles';
import DgDItem from '../menuEditor/DgD/dgdItem';

const AddVirtualNumber = ({
  formik,
  phone,
  isEdit,
  isActive,
  backFromEditActiveVN
}) => {
  const [toggleAudio, setToggleAudio] = useState(true);
  const [play, {stop, pause}] = useSound(exampleSound, {
    onend: () => {
      setToggleAudio(true);
    }
  });
  useEffect(() => stop, [stop]);

  const handlePlayAudio = () => {
    if (toggleAudio) {
      play();
    } else pause();
    setToggleAudio(!toggleAudio);
  };

  return (
    <>
      <Body1 className='marginBottomXl sm'>
        Activate Virtual Answering to receive online orders from diners calling
        your restaurant
      </Body1>
      <VirtualNumberPlay onClick={handlePlayAudio} className='marginBottomXl'>
        Listen to an example message
      </VirtualNumberPlay>
      <Body1 className='marginBottomXl'>
        The registered phone number for this restaurant is:{' '}
        {phoneFormatForView(phone)}
      </Body1>
      <VirtualNumberFields>
        <DashboardField className='w100'>
          <RadioGroup aria-label='valueRadio' name='valueRadio' role='group'>
            <DashboardFields>
              <DashboardField className='w100 mTop'>
                <RadioS2E
                  name='correctNumber'
                  checked={formik.values.correctNumber}
                  onClick={() => {
                    if (!formik.values.correctNumber) {
                      formik.setFieldValue('correctNumber', true);
                    }
                  }}
                  label='This is the correct number'
                  disabled={!phone}
                />
                {!phone && formik.values.correctNumber && (
                  <ErrorSignWrapper>
                    <ErrorSign>
                      Your phone number does not exist. Please provide a valid
                      phone number with the option below.
                    </ErrorSign>
                  </ErrorSignWrapper>
                )}
              </DashboardField>
              <DashboardField className='w100'>
                <RadioS2E
                  name='correctNumber'
                  checked={!formik.values.correctNumber}
                  onClick={() => {
                    if (formik.values.correctNumber) {
                      formik.setFieldValue('CountryCode', '+1');
                      formik.setFieldValue('Phone', '');
                      formik.setFieldValue('correctNumber', false);
                    }
                  }}
                  label='This is not the correct number. Please change it to:'
                />
              </DashboardField>
            </DashboardFields>
          </RadioGroup>
        </DashboardField>
      </VirtualNumberFields>
      {!formik.values.correctNumber && (
        <VirtualNumberFields className='noWrap'>
          <DashboardField className='FieldNumber'>
            <FieldLabel>Country code</FieldLabel>
            <FieldInput
              disabled
              name='CountryCode'
              placeholder='+1'
              value={formik.values.CountryCode}
              className={
                formik.errors.CountryCode &&
                formik.touched.CountryCode &&
                'error'
              }
            />
            <ErrorSignWrapper>
              <ErrorSign>
                {formik.touched.CountryCode && formik.errors.CountryCode}
              </ErrorSign>
            </ErrorSignWrapper>
          </DashboardField>
          <DashboardField className='wAutoSpace'>
            <FieldLabel>Phone number</FieldLabel>
            <FieldMaskInput
              mask='(999) 999-9999'
              name='Phone'
              placeholder='Type in the number your users should call'
              value={formik.values.Phone}
              className={formik.errors.Phone && formik.touched.Phone && 'error'}
              onChange={formik.handleChange}
              onBlur={(e) => {
                const regExp = /^\([\d]{3}\) [\d]{3}-[\d]{1,}$/g;

                if (!regExp.test(e.target.value)) {
                  formik.setFieldValue('Phone', '');
                  formik.setErrors({
                    ...formik.errors,
                    Phone: 'Incorrect phone format'
                  });
                }
                formik.handleBlur(e);
              }}
            />
            <ErrorSignWrapper>
              <ErrorSign>
                {formik.touched.Phone && formik.errors.Phone}
              </ErrorSign>
            </ErrorSignWrapper>
          </DashboardField>
        </VirtualNumberFields>
      )}
      <DashboardActionCenter>
        {isEdit && isActive && (
          <ButtonOutline onClick={backFromEditActiveVN}>
            <span>Back</span>
          </ButtonOutline>
        )}
        <ButtonPrimary
          data-gtm='saveVirtualAnswering'
          className={cn('ButtonPrimary', {
            disabled: !phone && formik.values.correctNumber
          })}
          type='submit'
          onClick={formik.handleSubmit}
        >
          <span data-gtm='saveVirtualAnswering'>Next</span>
        </ButtonPrimary>
      </DashboardActionCenter>
    </>
  );
};

AddVirtualNumber.propTypes = {
  formik: T.object.isRequired,
  phone: T.string.isRequired,
  isEdit: T.bool,
  isActive: T.bool,
  backFromEditActiveVN: T.func
};

AddVirtualNumber.defaultProps = {
  isEdit: false,
  isActive: false,
  backFromEditActiveVN: null
};

export default AddVirtualNumber;
