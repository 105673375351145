import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const IconViewReport = () => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.62101 18.2082H5.77692C6.11556 18.2082 6.39026 17.9531 6.39026 17.6144C6.39026 17.2758 6.11575 17.0207 5.77692 17.0207H3.62101C2.71337 17.0207 1.97949 16.2152 1.97949 15.2729V6.53109H16.8232V8.76398C16.8232 9.10262 17.1773 9.37733 17.516 9.37733C17.8546 9.37733 18.2087 9.10282 18.2087 8.76398V3.73927C18.2087 2.14149 16.7071 0.791504 15.1263 0.791504H3.62101C2.03709 0.791504 0.791992 2.11379 0.791992 3.73927V15.2729C0.791992 16.8914 2.03709 18.2082 3.62101 18.2082ZM1.97949 3.73927C1.97949 2.8063 2.72881 1.979 3.62101 1.979H15.1263C16.0421 1.979 16.8232 2.8063 16.8232 3.73927V5.34359H1.97949V3.73927Z'
      fill='white'
    />
    <path
      d='M14.7306 3.1665H14.4854C14.1468 3.1665 13.8721 3.52058 13.8721 3.85921C13.8721 4.19785 14.1466 4.55192 14.4854 4.55192H14.7306C15.0693 4.55192 15.344 4.19785 15.344 3.85921C15.344 3.52058 15.0693 3.1665 14.7306 3.1665Z'
      fill='white'
    />
    <path
      d='M12.6457 3.1665H12.4005C12.0618 3.1665 11.7871 3.52058 11.7871 3.85921C11.7871 4.19785 12.0616 4.55192 12.4005 4.55192H12.6457C12.9843 4.55192 13.259 4.19785 13.259 3.85921C13.259 3.52058 12.9843 3.1665 12.6457 3.1665Z'
      fill='white'
    />
    <path
      d='M10.4377 3.1665H10.1924C9.85381 3.1665 9.5791 3.52058 9.5791 3.85921C9.5791 4.19785 9.85361 4.55192 10.1924 4.55192H10.4377C10.7763 4.55192 11.051 4.19785 11.051 3.85921C11.051 3.52058 10.7763 3.1665 10.4377 3.1665Z'
      fill='white'
    />
    <path
      d='M12.0723 9.32861C9.38303 9.32861 6.92708 11.0174 5.9375 13.5312C5.9375 13.5312 7.22495 18.2721 12.0723 18.1688C17.194 18.0597 18.2083 13.5312 18.2083 13.5312C17.2188 11.0174 14.7624 9.32861 12.0723 9.32861ZM12.1161 16.9423C10.0114 16.9423 8.08925 15.6683 7.25998 13.7487C8.08905 11.8291 10.0114 10.5551 12.1161 10.5551C14.2213 10.5551 16.1439 11.8291 16.9731 13.7487C16.1441 15.6683 14.2213 16.9423 12.1161 16.9423Z'
      fill='white'
    />
    <path
      d='M12.1171 11.3691C10.8049 11.3691 9.7373 12.4365 9.7373 13.7487C9.7373 15.0609 10.8049 16.1282 12.1171 16.1282C13.4292 16.1282 14.4966 15.0609 14.4966 13.7487C14.4966 12.4365 13.4292 11.3691 12.1171 11.3691ZM12.1171 14.9018C11.4811 14.9018 10.9638 14.3844 10.9638 13.7487C10.9638 13.113 11.4811 12.5956 12.1171 12.5956C12.753 12.5956 13.2701 13.113 13.2701 13.7487C13.2701 14.3844 12.753 14.9018 12.1171 14.9018Z'
      fill='white'
    />
  </svg>
);
