import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import DialogTitle from 'components/DialogTitle/styles';
import DialogContainer from 'components/DialogContainer/styles';
import {ButtonOutline} from 'components/buttons';
import DialogBody from 'components/DialogBody/styles';
import {OPEN_ADJUST_ERROR_MODAL} from 'store/orders/orderAdjust/action';
import DialogAction from 'components/DialogAction/styles';
import DialogActions from 'components/DialogActions/styles';
import {DialogText} from 'routes/App/dashboard/styles';

const AdjustErrorModal = ({isOpen, errorMessage}) => {
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen}>
      <DialogContainer className='DialogSm Overflow'>
        <DialogTitle className='DialogTitleMarginBottom'>
          Could not process order adjustment
        </DialogTitle>
        <DialogBody>
          {!!errorMessage && <DialogText>{errorMessage}</DialogText>}
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonOutline
                onClick={() =>
                  dispatch(
                    OPEN_ADJUST_ERROR_MODAL({isOpen: false, errorMessage: null})
                  )
                }
              >
                <span>Ok</span>
              </ButtonOutline>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

AdjustErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};
export default AdjustErrorModal;
