import moment from 'moment';
import {HUBSPOT_TICKET_STATUS, ORDER, ORDER_STATUS} from '../constants/orders';

const getCurrentLocationTime = (timezone) =>
  moment()
    .utc()
    .utcOffset(Number(timezone) || 0)
    .format();

export const checkIsFutureOrder = (order) => {
  return (
    order[ORDER.AHEAD_TIME] &&
    moment(order[ORDER.EXPIRED_TIME]).utc().utcOffset(0).format() >
      getCurrentLocationTime(order?.Timezone)
  );
};

export const checkIsPossibleToCancelDriver = (order) => {
  return (
    moment(order[ORDER.DATE]).utc().utcOffset(0).format() >
    getCurrentLocationTime(order?.Timezone)
  );
};

const hsTicketCondition = (item) => {
  if (
    (item?.requestedBy === 'Sauce' || item?.requestedBy === 'End Customer') &&
    item?.requestTo === 'End Customer'
  ) {
    return (
      item?.fault !== 'Courier' &&
      (item?.hsTicketStatus === HUBSPOT_TICKET_STATUS.CLOSED ||
        item?.hsTicketStatus === HUBSPOT_TICKET_STATUS.CLOSED_STAGE)
    );
  }
  return true;
};

export const checkIsRefundOrder = (_order) => {
  const order = {..._order};
  if (Array.isArray(order[ORDER.REFUND])) {
    const filteredRefunds = order[ORDER.REFUND]?.filter(
      // (el) => !el?.tier1Refund
      (el) => el?.requestTo !== 'Sauce' && hsTicketCondition(el)
    );

    order[ORDER.REFUND] = filteredRefunds.length >= 1 ? filteredRefunds : null;
  }

  return !!order[ORDER.REFUND];
};

export const filterTier1Refunds = (order) => {
  // return order[ORDER.REFUND]?.filter((el) => !el?.tier1Refund);
  return order[ORDER.REFUND]?.filter(
    (el) => el?.requestTo !== 'Sauce' && hsTicketCondition(el)
  );
};

export const checkIsTier1Refunded = (order) => {
  return (
    !!order[ORDER.REFUND] &&
    // !!order[ORDER.REFUND]?.find((item) => item.tier1Refund)
    !!order[ORDER.REFUND]?.find((item) => item.requestTo !== 'Sauce')
  );
};

export const checkIsFullRefundOrder = (order) => {
  return (
    !!order[ORDER.REFUND] &&
    !!order[ORDER.REFUND]?.find((item) => item.type === 'Full')
  );
};

export const checkIsFullNotTier1RefundOrder = (order) => {
  return (
    !!order[ORDER.REFUND] &&
    !!order[ORDER.REFUND]?.find(
      (item) => item.type === 'Full' && !item.tier1Refund
    )
  );
};

export const checkIsFullNotToSauceRefundOrder = (order) => {
  return (
    !!order[ORDER.REFUND] &&
    !!order[ORDER.REFUND]?.find(
      (item) =>
        item.type === 'Full' &&
        item.requestTo !== 'Sauce' &&
        hsTicketCondition(item)
    )
  );
};

export const calculateOverdueAndFuture = (order) => {
  // eslint-disable-next-line prefer-const
  let {orderStatus, overdueDisabled, Timezone} = order;

  if (
    [ORDER_STATUS.NEW, ORDER_STATUS.ACCEPTED].includes(orderStatus) ||
    !orderStatus
  ) {
    const currentOrderTime = moment(order.date).utc().utcOffset(0).format();

    if (
      getCurrentLocationTime(Timezone) > currentOrderTime &&
      !overdueDisabled
    ) {
      orderStatus = ORDER_STATUS.OVERDUE;
    }
    if (
      checkIsFutureOrder(order) &&
      order[ORDER.ORDER_STATUS] !== ORDER_STATUS.ACCEPTED
    ) {
      orderStatus = ORDER_STATUS.FUTURE;
    }
  }

  return {...order, orderStatus};
};

export default calculateOverdueAndFuture;
