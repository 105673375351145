/* eslint-disable */
import React, {Component} from 'react';
import {
  DialogSearch,
  DialogSearchField,
  DialogSearchRemove
} from '../../../dashboard/styles';

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;
    this.state = {
      value: ''
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.value !== prevState.value) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(
        () => this.props.filterList(this.state.value),
        300
      );
    }
  }

  onChange = (e) => {
    this.setState({value: e.target.value});
  };

  onClear = (e) => {
    this.setState({value: ''});
  };

  render() {
    return (
      <DialogSearch>
        <DialogSearchField>
          <input
            type='text'
            className='FieldSearch'
            value={this.state.value}
            onChange={this.onChange}
          />
        </DialogSearchField>
        <DialogSearchRemove onClick={this.onClear} />
      </DialogSearch>
    );
  }
}

export default SearchInput;
