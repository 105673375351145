import React from 'react';

const SaveButton = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.7008 1H3.8125C2.26169 1 1 2.26169 1 3.8125V22.1875C1 23.7383 2.26169 25 3.8125 25H22.1875C23.7383 25 25 23.7383 25 22.1875V5.29919L20.7008 1ZM8.35938 2.875H12.9375V6.4375H14.8125V2.875H17.6875V7.375C17.6875 7.89194 17.2669 8.3125 16.75 8.3125H9.29688C8.77994 8.3125 8.35938 7.89194 8.35938 7.375V2.875ZM19.5625 23.125H6.48438V15.875C6.48438 15.3581 6.90494 14.9375 7.42188 14.9375H18.625C19.1419 14.9375 19.5625 15.3581 19.5625 15.875V23.125ZM23.125 22.1875C23.125 22.7044 22.7044 23.125 22.1875 23.125H21.4375V15.875C21.4375 14.3242 20.1758 13.0625 18.625 13.0625H7.42188C5.87106 13.0625 4.60938 14.3242 4.60938 15.875V23.125H3.8125C3.29556 23.125 2.875 22.7044 2.875 22.1875V3.8125C2.875 3.29556 3.29556 2.875 3.8125 2.875H6.48438V7.375C6.48438 8.92581 7.74606 10.1875 9.29688 10.1875H16.75C18.3008 10.1875 19.5625 8.92581 19.5625 7.375V2.875H19.9242L23.125 6.07581V22.1875Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
    </svg>
  );
};

export default SaveButton;
