import React, {memo} from 'react';
import PropTypes from 'prop-types';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Loading from 'components/shared/Utils/Loading';
import PopperActions from 'components/PopperActions';

import {PhoneOrderingTableWrapper, TableText} from './style';

/**
 *
 * @param headCells {Array<{id: string, label: string}>}
 * @param items {Array<{id: string | number, [key: string]: any}>}
 * @param loading {boolean}
 * @param togglePhoneOrdering {function}
 * @param editPhoneOrdering {function}
 * @return {JSX.Element}
 * @constructor
 */
const PhoneOrderingTable = ({
  headCells,
  items = [],
  loading,
  togglePhoneOrdering,
  editPhoneOrdering
}) => {
  if (!items.length && !loading)
    return <div>Phone Ordering is not enabled for any location</div>;

  return (
    <PhoneOrderingTableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
              <TableCell style={{width: '5%'}} />
            </TableRow>
          </TableHead>
          {loading ? (
            <Loading />
          ) : (
            <TableBody>
              {items &&
                items.map((row) => (
                  <TableRow key={row.id} tabIndex={-1}>
                    {headCells.map(({id}) => (
                      <TableCell align='left' key={id}>
                        <TableText>
                          <span title={String(row[id])}>{String(row[id])}</span>
                        </TableText>
                      </TableCell>
                    ))}
                    <TableCell align='right'>
                      <PopperActions
                        popperActions={() => [
                          {
                            title: 'Edit',
                            action: () => editPhoneOrdering(row)
                          },
                          {
                            title: `Switch ${
                              row.isPhoneOrderingEnabled ? 'off' : 'on'
                            }`,
                            action: () =>
                              togglePhoneOrdering(
                                row,
                                !row.isPhoneOrderingEnabled
                              )
                          }
                        ]}
                        row={row}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </PhoneOrderingTableWrapper>
  );
};

PhoneOrderingTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  togglePhoneOrdering: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  editPhoneOrdering: PropTypes.func.isRequired
};

PhoneOrderingTable.defaultProps = {
  loading: false
};

export default memo(PhoneOrderingTable);
