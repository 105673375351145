import React from 'react';
import T from 'prop-types';
import {NumberedListWrapper} from './style';
import Subtitle4 from '../typography/Subtitle4';

const NumberedList = ({list}) => {
  return (
    <NumberedListWrapper>
      <ol>
        {list.length &&
          list.map((el) => (
            <li key={el.key}>
              <Subtitle4>{el.description}</Subtitle4>
            </li>
          ))}
      </ol>
    </NumberedListWrapper>
  );
};

NumberedList.propTypes = {
  list: T.arrayOf(
    T.shape({
      description: T.string,
      key: T.string
    })
  ).isRequired
};

NumberedList.defaultProps = {};

export default NumberedList;
