import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FETCH_MENU_DATA} from '../../../store/menuEditor/action';

export default function MenuLoader() {
  const dispatch = useDispatch();
  const activeLocation = useSelector((state) => state.user.activeLocation);
  const [location, setLocation] = useState(activeLocation._id);

  useEffect(() => {
    dispatch(FETCH_MENU_DATA());
  }, [location]);

  useEffect(() => {
    setLocation(activeLocation._id);
  }, [activeLocation]);

  return null;
}
