import React from 'react';

export const IconWizard = () => (
  <svg
    width='23'
    height='22'
    viewBox='0 0 23 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.8111 9.7093L18.3391 6.38018L20.215 1.93082C20.3486 1.60803 20.1959 1.23462 19.8716 1.1017C19.6936 1.02575 19.4901 1.03841 19.3184 1.13335L15.1152 3.42449L11.5351 0.228287C11.2744 -0.00589013 10.8738 0.019426 10.6385 0.27892C10.5113 0.424489 10.4541 0.620692 10.4859 0.810565L11.3316 5.57006L7.21102 8.02575C6.91215 8.20297 6.81041 8.59537 6.99482 8.89284C7.09656 9.0574 7.26189 9.17132 7.45266 9.19664L9.95172 9.53841L0.53415 18.9055C-0.17805 19.6144 -0.17805 20.7599 0.53415 21.4688C1.24635 22.1776 2.39732 22.1776 3.10951 21.4751C3.10951 21.4751 3.10951 21.4751 3.11587 21.4688L12.6797 11.9435L13.252 14.5637C13.3092 14.8295 13.5318 15.0258 13.7989 15.0574C13.8243 15.0574 13.8497 15.0637 13.8752 15.0637C14.1168 15.0637 14.3457 14.9245 14.4475 14.703L16.5396 10.3549L21.3088 10.7916C21.6585 10.8232 21.9701 10.5701 22.0019 10.222C22.021 10.0321 21.951 9.84221 21.8111 9.7093ZM2.20655 20.5764C1.99034 20.7916 1.64696 20.7916 1.43076 20.5764C1.21455 20.3612 1.21455 20.0194 1.43076 19.8042L4.75012 16.5004L5.52591 17.2726L2.20655 20.5764ZM6.42887 16.3738L5.65309 15.6017L11.5287 9.7536L12.2155 9.84854L12.3554 10.4751L6.42887 16.3738ZM16.2153 9.0574C15.9482 9.03208 15.7002 9.17765 15.5857 9.41183L14.1041 12.4941L13.3728 9.146C13.3156 8.88651 13.0994 8.69031 12.8387 8.65233L9.41757 8.18398L12.3618 6.43082C12.5907 6.29791 12.7051 6.03841 12.6606 5.77892L12.0629 2.39284L14.6128 4.66499C14.8163 4.84221 15.1088 4.88018 15.3441 4.74727L18.3455 3.10803L17.0038 6.28525C16.902 6.52575 16.9593 6.80423 17.15 6.98778L19.63 9.36752L16.2153 9.0574Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconWizard;
