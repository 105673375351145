import React, {useEffect} from 'react';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Box} from '@material-ui/core';
import {CalendlyEventListener} from 'react-calendly';
import GeneralInfo from './GeneralInfo';
import {
  CONTROL_MODALS,
  GET_STEPS
} from '../../../../../store/selfCreateAccount/action';
import {
  PendingData,
  stepData
} from '../../../../../store/selfCreateAccount/selectors';
import BookCallWithUs from './callSteps/BookCallWithUs';
import FreeTrial from './freeTrialSteps/FreeTrial';
import {
  TOGGLE_CALENDLY,
  BOOK_A_CALL
} from '../../../../../store/global/account/action';
import {
  SetupHolder,
  SetupSection
} from '../../../../Auth/registration/signUpNew/style';
import DeliveryCalculator from './freeTrialSteps/Calculator';
import Loading from '../../../../../components/shared/Utils/Loading';
import {findLastFilledStep} from '../../helplers/findLastFilledStep';
import SidebarStepper from '../Components/SidebarStepper';
import {SetupLogout} from '../../styles';
import {Body1} from '../../../../../components/typography';
import {SidebarProfileLogoutButton} from '../../../../../components/layout/sidebar/styles';
import {LOGOUT_USER} from '../../../../../store/global/auth/action';

const SelfCreateAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const pendingData = PendingData();
  const {
    dataReceived,
    showStepperAfterCalendly,
    pendingDataSetuped
  } = stepData();

  useEffect(() => {
    dispatch(GET_STEPS());
  }, []);

  useEffect(() => {
    const lastFilledPagePath = findLastFilledStep(true, pendingData);

    if (dataReceived) {
      if (!pendingDataSetuped) {
        history.push(`${path}/${lastFilledPagePath}`);
      } else {
        history.push('/my-account');
      }
    }
  }, [dataReceived, history.location.pathname === '/my-account/self-setup']);

  if (!dataReceived) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <SetupSection>
        <SetupHolder>
          <Route exact path={`${path}/general-info`} component={GeneralInfo} />
          <Route exact path={`${path}/free-trial`} component={FreeTrial} />
          <Route exact path={`${path}/book-call`} component={BookCallWithUs} />
          <Route
            exact
            path={`${path}/calculator`}
            component={DeliveryCalculator}
          />
          <SetupLogout>
            <Body1>Logout</Body1>
            <SidebarProfileLogoutButton
              onClick={() => {
                dispatch(LOGOUT_USER());
              }}
              href={`${process.env.REACT_APP_OLD_DASHBOARD_URL}/logout`}
            />
          </SetupLogout>
        </SetupHolder>
        {history.location.pathname !== '/my-account/self-setup/calculator' && (
          <SidebarStepper isMobile={false} isSignUp2 />
        )}
      </SetupSection>

      <CalendlyEventListener
        onEventScheduled={() => {
          if (showStepperAfterCalendly) {
            const intervalId = setInterval(() => {
              if (!document.getElementsByClassName('calendly-overlay').length) {
                clearInterval(intervalId);
                dispatch(
                  CONTROL_MODALS({open: true, field: 'isConfirmedModalOpen'})
                );
                document.body.style.overflow = 'auto visible';
              }
            }, 500);
          }
          dispatch(BOOK_A_CALL());
          dispatch(TOGGLE_CALENDLY(false));
        }}
        onEventTypeViewed={() => {
          dispatch(TOGGLE_CALENDLY(true));
          const intervalId = setInterval(() => {
            if (!document.getElementsByClassName('calendly-overlay').length) {
              clearInterval(intervalId);
              dispatch(TOGGLE_CALENDLY(false));
              document.body.style.overflow = 'auto visible';
            }
          }, 500);
        }}
      />
    </>
  );
};

export default SelfCreateAccount;
