import React from 'react';
import NoReportsIcon from '../../../../assets/NoReportsIcon';
import {NoReportsContainer} from './styles';

const NoReportsScreen = () => {
  return (
    <NoReportsContainer className='reportsIcon'>
      <NoReportsIcon style={{}} />
    </NoReportsContainer>
  );
};

export default NoReportsScreen;
