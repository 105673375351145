/* eslint-disable */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import OrdersTable from '../components/OrdersTable';
import {OrdersContainer} from './styles';
import {
  RUN_BAD_ACTIVE_ORDERS_JOB,
  STOP_BAD_ACTIVE_ORDERS_JOB,
  SET_BAD_TABLE_CONFIG
} from '../../../../store/global/bookADriver/action';
import {
  RUN_BAD_ORDER_DETAIL_JOB,
  STOP_BAD_ORDER_DETAIL_JOB
} from '../../../../store/bookADriver/bookADriverDetails/action';
import OrderSuccess from '../Modals/OrderSuccess';
import {HANDLE_SUCCESS_MODAL} from '../../../../store/bookADriver/bookADriverCreate/action';

const ActiveOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isNetworkOnline} = useSelector(
    (state) => state.globalNotificationsReducer
  );
  const isSuccessOrder = useSelector(
    (state) => state.bookADriverCreateReducer.openSuccessModal
  );
  const successData = useSelector(
    (state) => state.bookADriverCreateReducer.successData
  );
  const {activeOrders, config, loading} = useSelector(
    (state) => state.activeBookADriverOrdersReducer
  );

  const {timezone, locationId} = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(
      SET_BAD_TABLE_CONFIG({to: encodeURIComponent(new Date().toISOString())})
    );
    dispatch(STOP_BAD_ACTIVE_ORDERS_JOB());
    dispatch(RUN_BAD_ACTIVE_ORDERS_JOB());

    return () => {
      dispatch(STOP_BAD_ACTIVE_ORDERS_JOB());
    };
  }, [locationId]);

  const openDetails = (data) => {
    /*dispatch(STOP_BAD_ORDER_DETAIL_JOB());
    dispatch(RUN_BAD_ORDER_DETAIL_JOB(data._id));*/
    history.push(`/book-a-driver/active/${data._id}/details`, {
      windowPosition: window.scrollY
    });
  };

  const handleRequestSort = (property) => {
    const isAsc =
      config.sortField === property.sortBy && config.sortDir === 'asc';
    dispatch(
      SET_BAD_TABLE_CONFIG({
        sortDir: isAsc ? 'desc' : 'asc',
        sortField: property.sortBy
      })
    );
    dispatch(STOP_BAD_ACTIVE_ORDERS_JOB());
    dispatch(RUN_BAD_ACTIVE_ORDERS_JOB());
  };

  const createSortHandler = (property) => {
    handleRequestSort(property);
  };

  return (
    <OrdersContainer className='tableBookADriver'>
      <OrdersTable
        openDetails={openDetails}
        orders={activeOrders}
        onSort={createSortHandler}
        isNetworkOnline={isNetworkOnline}
        orderBy={config.sortField}
        orderDirection={config.sortDir}
        timezone={timezone}
        loading={loading}
      />
      <OrderSuccess
        onClose={() => {
          dispatch(
            HANDLE_SUCCESS_MODAL({openSuccessModal: false, successData: {}})
          );
        }}
        open={isSuccessOrder}
        data={successData}
        timezone={timezone}
      />
    </OrdersContainer>
  );
};

export default ActiveOrders;
