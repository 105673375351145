import loggerClient from './ClientLogger';
import {decodeToken} from '../../helpers/localStorage/authStorage';
import {getLocationIdFromStorage} from '../../helpers/localStorage/locationIdStorage';

const ddTags = {
  tags: {
    ServiceVersion: process.env.REACT_APP_HEROKU_SLUG_COMMIT,
    RepositoryURL: process.env.REACT_APP_REPOSITORY_URL,

    // to enable the following tags, you need to enable the Heroku Dyno Metadata Labs feature
    // https://devcenter.heroku.com/articles/dyno-metadata
    // heroku labs:enable runtime-dyno-metadata -a <app-name>
    HerokuAppName: process.env.REACT_APP_HEROKU_APP_NAME,
    HerokuReleaseVersion: process.env.REACT_APP_HEROKU_RELEASE_VERSION,
    HerokuSlugCommit: process.env.REACT_APP_HEROKU_SLUG_COMMIT,
    HerokuSlugDescription: process.env.REACT_APP_HEROKU_SLUG_DESCRIPTION
  }
};

const parseReqData = (data) => {
  let result = data;
  try {
    if (result) {
      result = JSON.parse(result);
    }
  } catch (error) {
    console.error('Logger parse req data error: ', error);
  }
  return result;
};

const getDataForManualLogs = (userState) => {
  return {
    LocationId: userState?.locationId || getLocationIdFromStorage(),
    LocationName: userState?.locationName || null,
    UserId: decodeToken()?.email || 'unauth',
    ...(userState?.userData && {
      UserName: `${userState?.userData?.first_name} ${userState?.userData?.last_name}`
    }),
    ...ddTags
  };
};

export const newLogger = (options, message, method) => {
  loggerClient.logger[method](message, {
    ...options,
    logger: {
      name: 'dd-browser-logs'
    },
    ...ddTags
  });
};
export const getMethodByCode = (statusCode) => {
  let status = 'info';
  if (statusCode >= 400 && statusCode < 500) {
    status = 'warn';
  } else if (statusCode >= 500) {
    status = 'error';
  }

  return status;
};

const getCustomsRequestHeaders = (headers) => {
  const resultHeaders = {...headers};
  if ('Authorization' in resultHeaders) {
    delete resultHeaders.Authorization;
  }

  return resultHeaders;
};

export const getOptions = (res, isError, dataForLogs) => {
  return {
    type: 'http-outgoing',
    http: {
      url: res.config.url,
      status_code: res.status,
      method: res.config.method?.toUpperCase()
    },
    req: {
      headers: getCustomsRequestHeaders(res.config.headers),
      body: parseReqData(res.config.data)
    },
    res: {
      headers: res.headers,
      body: res.data
    },

    Level: getMethodByCode(res.status),
    LocationId: dataForLogs.locationId || getLocationIdFromStorage(),
    LocationName: dataForLogs.locationName || null,
    UserId: dataForLogs.email || 'unauth',
    ...(dataForLogs.userData && {
      UserName: `${dataForLogs.userData?.first_name} ${dataForLogs.userData?.last_name}`
    }),
    ClassName: 'helpers/services/utils/axios.js',
    MethodName: 'axios.interceptors.response.use',
    duration:
      (Date.now() - Number(res.config.headers['x-timestart'])) * 1000 * 1000
  };
};

export const infoLog = (message, data, userState) => {
  loggerClient.logger.info(message, {
    ...data,
    ...getDataForManualLogs(userState)
  });
};

export const errorLog = (message, data, error, userState) => {
  loggerClient.logger.error(
    message,
    {
      ...data,
      ...getDataForManualLogs(userState)
    },
    error
  );
};
