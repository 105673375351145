import React from 'react';
import {nanoid} from 'nanoid';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import Body3 from '../../../../components/typography/Body3';
import TipSign from '../../../../components/SauceChip';
import IconBulb from '../../../../assets/uberEats/icon.bulb.svg';
import NumberedList from '../../../../components/NumberedList';
import {UberOrderedList} from '../styles';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';

const BYOCConnectedPage = () => {
  const listData = [
    {description: 'Create/edit delivery zones.', key: nanoid()},
    {description: 'Edit minimum order.', key: nanoid()},
    {description: 'Edit delivery time.', key: nanoid()},
    {description: 'Edit customer delivery fee.', key: nanoid()},
    {
      description: 'Disable/enable Sauce couriers for UberEats orders.',
      key: nanoid()
    }
  ];

  return (
    <DashboardInner className='paddingXl mTop30'>
      <FlexContainer className='flexColumn gap30'>
        <FlexItem className='centerText'>
          <Subtitle5 className='text-center'>
            Sauce is currently delivering your UberEats orders
          </Subtitle5>
        </FlexItem>

        <FlexItem>
          <Body3>
            Manage the following in your{' '}
            <span className='Red fwBold'>UberEats manager</span> app:
          </Body3>
        </FlexItem>
        <FlexItem>
          <UberOrderedList>
            <NumberedList list={listData} />
          </UberOrderedList>
        </FlexItem>

        <FlexItem className='w100'>
          <TipSign
            icon={IconBulb}
            text={
              <p>
                Click the <span>chat button</span> so our team can help you
                maximize your UberEats revenues with Sauce delivery.
              </p>
            }
          />
        </FlexItem>
      </FlexContainer>
    </DashboardInner>
  );
};

export default BYOCConnectedPage;
