import React, {useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  DialogAction,
  DialogBody,
  DialogItem,
  DialogItems
} from '../../dashboard/styles';
import {SOLD_OUT_MODAL} from '../../../../store/menuEditorModals/action';
import {PATCH_ITEM} from '../../../../store/menuEditorItems/action';
import {PATCH_MODIFIER} from '../../../../store/menuEditorModifier/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';

const SoldOutModal = () => {
  const dispatch = useDispatch();
  const {
    entityDuplicateInfo,
    modalNoteEntityName,
    isSoldOutModalOpen,
    oldEntityData
  } = useSelector((state) => state.menuEditorModalsReducer);

  let initOutStateValue = 0;
  const {InStock = true, OutOfStockToNext = false} = entityDuplicateInfo || {};

  if (OutOfStockToNext) {
    initOutStateValue = 4;
  } else if (!OutOfStockToNext && !InStock) {
    initOutStateValue = 3;
  }

  const [outState, setOutState] = useState(initOutStateValue);

  const submitAction = () => {
    if (modalNoteEntityName === 'item') {
      if ((outState === 0 && InStock) || (outState === 1 && InStock)) {
        dispatch(SOLD_OUT_MODAL({open: false, entityDuplicateInfo}));
        return;
      }

      dispatch(
        PATCH_ITEM({
          oldEntityData,
          form: {
            InStock: outState !== 3 && outState !== 4,
            OutOfStockToNext: outState === 4
          }
        })
      );
      sendGtmEvent('menuEditorEntityOutOfStock', {
        entity: 'item',
        until: outState === 3 ? 'indefinitely' : 'until next opening hours'
      });
    }

    if (modalNoteEntityName === 'modifier') {
      if ((outState === 0 && InStock) || (outState === 1 && InStock)) {
        dispatch(SOLD_OUT_MODAL({open: false, entityDuplicateInfo}));
        return;
      }

      dispatch(
        PATCH_MODIFIER({
          oldEntityData,
          form: {
            InStock: outState !== 3 && outState !== 4,
            OutOfStockToNext: outState === 4
          }
        })
      );
      sendGtmEvent('menuEditorEntityOutOfStock', {
        entity: 'modifier',
        until: outState === 3 ? 'indefinitely' : 'until next opening hours'
      });
    }

    dispatch(SOLD_OUT_MODAL({open: false, entityDuplicateInfo}));
  };

  const handleSetOutOfStock = (isActive, value) => {
    if (isActive && outState !== value) {
      setOutState(value);
    }
  };

  return (
    <Dialog
      onClose={() => dispatch(SOLD_OUT_MODAL({open: false}))}
      open={isSoldOutModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => dispatch(SOLD_OUT_MODAL({open: false}))} />
        <DialogTitle className='DialogTitleOrange'>
          Mark Item as Sold Out (86)
        </DialogTitle>
        <DialogBody>
          <CheckboxContainer>
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut Center'
              control={
                <Checkbox
                  checked={outState >= 1}
                  onChange={() => setOutState(outState ? 0 : 1)}
                  name='ItemSoldOut'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                />
              }
              label='Mark Item as Sold Out (86)'
            />
          </CheckboxContainer>
          <DialogItems
            className={cn('DialogItemsSoldOut mTop5', {
              isDisabled: !outState >= 1
            })}
          >
            <DialogItem
              className={cn({isActive: outState === 3})}
              onClick={() => handleSetOutOfStock(!!outState, 3)}
            >
              <span>Mark as Unavailable</span>
            </DialogItem>
            <DialogItem
              className={cn({isActive: outState === 4})}
              onClick={() => handleSetOutOfStock(!!outState, 4)}
            >
              <span>Mark as Unavailable until next opening hours</span>
            </DialogItem>
          </DialogItems>
          <DialogAction>
            <ButtonPrimary onClick={() => submitAction()}>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default SoldOutModal;
