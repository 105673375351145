import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  SAVE_USER_EMAIL,
  SET_ENTERED_EMAIL,
  SIGNUP_USER
} from '../../../../store/global/auth/action';
import SignUpTemplate from '../components/SignUpTemplate';

const SignUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    return () => dispatch(SET_ENTERED_EMAIL(false));
  }, []);
  return (
    <SignUpTemplate
      actionSignUp={(val) =>
        dispatch(
          SIGNUP_USER({
            ...val,
            isOldSalesSetup: false
          })
        )
      }
      showBottomLinks
      actionSaveEmail={(val) => dispatch(SAVE_USER_EMAIL(val))}
    />
  );
};

export default SignUp;
