import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import {GET_ORDER, ORDER_UNMOUNT} from 'store/orders/orderDetails/action';
import {usePrevious} from 'hooks/usePrevious';
import RefundTopBar from 'routes/App/orders/OrderDetails/Refund/RefundTopBar';
import RefundTable from 'routes/App/orders/OrderDetails/Refund/RefundTable';
import {
  CHANGE_AMOUNT_ITEMS_REFUND_ORDER,
  CHANGE_STATUS_CONFIRMATION_MODAL,
  CHANGE_STATUS_CREATE_MODAL,
  SET_DATA_FOR_REFUND,
  CLEAR_REFUND_DATA,
  GET_DATA_FOR_REFUND_SUCCESS,
  GET_DATA_FOR_REFUND
} from 'store/orders/orderRefund/action';
import CreateRefundModal from 'routes/App/orders/OrderDetails/Refund/Modals/CreateRefundModal';
import ConfirmationModal from 'routes/App/orders/OrderDetails/Refund/Modals/ConfirmationModal';

import RefundBottomBar from 'routes/App/orders/OrderDetails/Refund/RefundBottomBar';
import RefundSchema from 'helpers/services/formValidationSchemas/RefundSchema';
import {
  checkIsFullNotTier1RefundOrder,
  checkIsFullRefundOrder
} from 'helpers/functionUtils/ordersUtils';
import useQuery from 'hooks/useQuery';
import {getSessionStorageItem} from 'helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from 'helpers/sessionStorage/constants';
import {parseForRestaurantRefund} from '../../../../../helpers/functionUtils/refundHelper';

function OrderRefund() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const formikRef = useRef();

  const order = useSelector((state) => state.orderDetailsReducer.order);
  const {
    isCreateModalOpen,
    isConfirmationModalOpen,
    orderRefundData,
    orderPriceAdjustments,
    priceAdjustmentIds,
    orderTips,
    tipForRefund
  } = useSelector((state) => state.refundReducer);
  const refundLoading = useSelector(
    (state) => state.refundReducer.refundsLoading
  );

  const [amountError, setAmountError] = useState(false);

  const getOrderRefundArray = () => {
    return parseForRestaurantRefund(orderRefundData);
  };

  const getAdjustsTotalAmount = () => {
    let totalAmount = 0;
    orderPriceAdjustments?.forEach((adjust) => {
      totalAmount += priceAdjustmentIds?.includes(adjust.priceAdjustmentId)
        ? adjust.totalAmount
        : 0;
    });
    return totalAmount;
  };

  const getTotalAmount = (array) => {
    let totalAmount = 0;
    array?.forEach((item) => {
      totalAmount += item.checked
        ? Number(item.amount) * Number(item.selected)
        : 0;
    });
    totalAmount += getAdjustsTotalAmount() + (tipForRefund ? orderTips : 0);
    return totalAmount;
  };

  const getTotalQuantityToRefund = (array) => {
    let totalQuantity = 0;
    array?.forEach((item) => {
      totalQuantity += item.checked ? Number(item.selected) : 0;
    });
    totalQuantity += priceAdjustmentIds.length + (tipForRefund ? 1 : 0);
    return totalQuantity;
  };

  const getItemToSubmit = (array) => {
    const newArray = parseForRestaurantRefund(array).filter(
      (el) => el.checked && el.selected > 0
    );

    return newArray.map((el) => {
      return {
        path: el.path,
        quantity: el.selected
      };
    });
  };

  const initValues = useMemo(
    () => ({
      refundType: 'Full',
      faultFull: 'Restaurant',
      faultPartial: 'Restaurant',
      reasonFull: '',
      reasonPartial: '',
      otherReasonTextFull: '',
      otherReasonTextPartial: '',
      requestedByFull: '',
      requestedByPartial: '',
      requestToFull: '',
      requestToPartial: '',
      totalAmount: 0,
      didReceiveOrderFull: '',
      didReceiveOrderPartial: '',
      didRemakeOrderFull: '',
      didRemakeOrderPartial: '',
      freeTextCCFull: '',
      freeTextCCPartial: '',
      tier1Refund:
        query.get('tier1') === 'true' ||
        !!getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME)
    }),
    []
  );

  const {locationId} = useSelector((state) => state.user);

  const redirectToOrdersTable = (state = {}) => {
    dispatch(ORDER_UNMOUNT());
    dispatch(CLEAR_REFUND_DATA());
    history.push(
      params.back === 'history' ? '/orders/history' : '/orders/active',
      state
    );
  };
  const prevLocation = usePrevious(locationId);

  useEffect(() => {
    if (prevLocation) {
      redirectToOrdersTable();
    }
  }, [locationId]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (order.id !== params.id) {
      dispatch(GET_ORDER(params.id));
    }
  }, []);

  const redirectToOrderDetails = (state = {}) => {
    dispatch(CLEAR_REFUND_DATA());
    history.push(
      params.back === 'history'
        ? `/orders/history/${params.id}/details`
        : `/orders/active/${params.id}/details`,
      state
    );
  };

  useEffect(() => {
    if (
      order?.orderInfo?.items?.length &&
      !getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME) &&
      checkIsFullNotTier1RefundOrder(order)
    ) {
      redirectToOrderDetails();
    } else {
      dispatch(GET_DATA_FOR_REFUND({invoiceId: order?.invoiceID}));
    }
  }, [order?.orderInfo?.items?.length]);

  const incrementCount = (id, increment) => {
    dispatch(CHANGE_AMOUNT_ITEMS_REFUND_ORDER({id, increment}));
  };

  return (
    <DashboardContent className='DashboardContent'>
      <Formik
        initialValues={initValues}
        innerRef={formikRef}
        validationSchema={RefundSchema}
        onSubmit={(values) => {
          if (
            values.refundType !== 'Full' &&
            getTotalQuantityToRefund(getOrderRefundArray()) === 0
          ) {
            return;
          }
          const fullRefundFields =
            values.refundType === 'Full'
              ? {
                  reason: {
                    reason: values.reasonFull,
                    fault: values.faultFull,
                    otherReasonText: values.otherReasonTextFull || ''
                  }
                }
              : {
                  totalAmount: getTotalAmount(getOrderRefundArray()),
                  totalAmountWithTax: getTotalAmount(getOrderRefundArray()),
                  items: getItemToSubmit(getOrderRefundArray()),
                  reason: {
                    reason: values.reasonPartial,
                    fault: values.faultPartial,
                    otherReasonText: values.otherReasonTextPartial || ''
                  },
                  tips: tipForRefund,
                  priceAdjustmentIds
                };
          dispatch(CHANGE_STATUS_CREATE_MODAL(true));
          dispatch(
            SET_DATA_FOR_REFUND({
              ...fullRefundFields,
              userName: order?.userName,
              userEmail: order?.userEmail,
              invoiceId: order?.invoiceID.replace(/say2eat_-|say2eat_/, ''),
              paymentInformation: order?.orderInfo?.paymentInformation,
              itemsCount: getTotalQuantityToRefund(getOrderRefundArray()),
              refundType: values.refundType
              // tier1Refund: values.tier1Refund
            })
          );
        }}
      >
        {({values, errors, touched, setFieldValue, submitForm, setTouched}) => (
          <Form>
            <ConnectedFocusError />
            <DashboardInner className='orderDetailsPaddingBottom RefundContainer'>
              <RefundTopBar
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                setTouched={setTouched}
                setAmountError={setAmountError}
                refundItems={getOrderRefundArray()}
                orderTips={orderTips}
                orderPriceAdjustments={orderPriceAdjustments}
              />
              <RefundTable
                setAmountError={setAmountError}
                values={values}
                orderRefundData={orderRefundData}
                orderPriceAdjustments={orderPriceAdjustments}
                priceAdjustmentIds={priceAdjustmentIds}
                orderTips={orderTips}
                tipForRefund={tipForRefund}
              />
            </DashboardInner>
            <RefundBottomBar
              totalAmount={getTotalAmount(getOrderRefundArray())}
              totalQuantity={getTotalQuantityToRefund(getOrderRefundArray())}
              submitForm={submitForm}
              amountError={amountError}
              setAmountError={setAmountError}
              values={values}
              refundItems={getOrderRefundArray()}
              orderTips={orderTips}
              orderPriceAdjustments={orderPriceAdjustments}
            />
            <CreateRefundModal
              open={isCreateModalOpen}
              onClose={() => dispatch(CHANGE_STATUS_CREATE_MODAL(false))}
              orderId={params.id}
              values={values}
              refundLoading={refundLoading}
              redirectToOrderDetails={redirectToOrderDetails}
            />
            <ConfirmationModal
              onClose={() => dispatch(CHANGE_STATUS_CONFIRMATION_MODAL(false))}
              open={isConfirmationModalOpen}
              values={values}
            />
          </Form>
        )}
      </Formik>
    </DashboardContent>
  );
}

export default memo(OrderRefund);
