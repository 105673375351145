import {toast} from 'react-toastify';
import {put, takeEvery, select} from 'redux-saga/effects';
import {
  getAllSudoUserFeatureFlagsApi,
  getSudoUserApi,
  getSudoUsersApi,
  updateSudoUserApi
} from 'helpers/services/api/adminSudoUsers';
import {
  CLEAR_SUDO_USER,
  GET_ALL_SUDO_USER_FEATURE_FLAGS,
  GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS,
  GET_SUDO_USER,
  GET_SUDO_USER_SUCCESS,
  GET_SUDO_USERS,
  GET_SUDO_USERS_SUCCESS,
  UPDATE_SUDO_USER
} from 'store/adminSudoUsers/action';

const getAdminSudoUsersState = ({adminSudoUsersReducer}) =>
  adminSudoUsersReducer;

function* getSudoUsers({payload}) {
  try {
    const {data} = yield getSudoUsersApi(payload);
    if (data) {
      yield put(GET_SUDO_USERS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getSudoUser({payload}) {
  try {
    const {data} = yield getSudoUserApi(payload);
    if (data) {
      yield put(GET_SUDO_USER_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* updateSudoUser({payload}) {
  try {
    const {
      paging: {page},
      search,
      sort
    } = yield select(getAdminSudoUsersState);
    const {data} = yield updateSudoUserApi(payload.id, payload.data);
    if (data) {
      toast.success('User updated successfully');
      yield put(CLEAR_SUDO_USER());
      yield put(GET_SUDO_USERS({page, search, sort}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getAllSudoUserFeatureFlags() {
  try {
    const {data} = yield getAllSudoUserFeatureFlagsApi();
    if (data) {
      yield put(GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* adminSudoUsersSaga() {
  yield takeEvery(GET_SUDO_USERS, getSudoUsers);
  yield takeEvery(GET_SUDO_USER, getSudoUser);
  yield takeEvery(UPDATE_SUDO_USER, updateSudoUser);
  yield takeEvery(GET_ALL_SUDO_USER_FEATURE_FLAGS, getAllSudoUserFeatureFlags);
}

export default adminSudoUsersSaga;
