/* eslint-disable */
import React, {Component} from 'react';
import DndList from './dgdList';
import {DashboardAccordions} from '../../dashboard/styles';

class DnD extends Component {
  render() {
    const {
      items,
      itemScheme,
      withoutMainDrag,
      withBulkCheckbox,
      bulkSelectedMap
    } = this.props;

    return (
      <DashboardAccordions className='withDgD'>
        {items && (
          <DndList
            items={items}
            itemScheme={itemScheme}
            withoutDrag={withoutMainDrag}
            withBulkCheckbox={withBulkCheckbox}
            bulkSelectedMap={bulkSelectedMap}
          />
        )}
      </DashboardAccordions>
    );
  }
}

export default DnD;
