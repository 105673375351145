/* eslint-disable  */
import React, {memo, useEffect, useState} from 'react';
import T from 'prop-types';
import {useFormikContext} from 'formik';
import {nanoid} from 'nanoid';
import {RadioGroup} from '@material-ui/core';
import {
  DashboardDays,
  DashboardField,
  DashboardFields,
  DialogLinkButton
} from '../../dashboard/styles';
import RadioS2E from '../../../../components/Radiobox';
import DaysPickerDays from '../DaysPickerDays';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import DaysPickerTimeSelect from '../DaysPickerTimeSelect';
import DialogClose from '../../../../components/DialogClose/styles';
import {generateTimeRanges} from '../helpers';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';

const allDaySelected = (day) =>
  day?.timePeriods?.[0]?.duration === '0:00' &&
  day?.timePeriods?.[0]?.startOfWorkingDay === '12:00 AM' &&
  day?.timePeriods?.[0]?.endOfWorkingDay === '12:00 AM';

const DayItem = ({day, withoutClose}) => {
  const [displayHoursSelection, setDisplayHoursSelection] = useState(
    allDaySelected(day) ? 'allDay' : 'certainHours'
  );

  const [bufferTimeSlots, setBufferTimeSlots] = useState(null);

  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();

  useEffect(() => {
    const arr = [...openingDays];
    const elementId = arr.findIndex((d) => d.id === day.id);
    if (!arr[elementId].timePeriods.length) {
      setDisplayHoursSelection('allDay');
    }
  }, [openingDays]);

  useEffect(() => {
    if (displayHoursSelection === 'allDay') {
      const arr = [...openingDays];
      const element = arr.findIndex((d) => d.id === day.id);

      setBufferTimeSlots(
        openingDays[element].timePeriods.length
          ? openingDays[element].timePeriods
          : null
      );
      arr[element].timePeriods = [
        {
          id: nanoid(),
          startOfWorkingDay: '12:00 AM',
          endOfWorkingDay: '12:00 AM',
          duration: '24:00',
          hoursIntervals: Object.keys(generateTimeRanges(15, 'en'))
        }
      ];
      setOpeningDays(arr);
    } else {
      if (bufferTimeSlots) {
        const arr = [...openingDays];
        const element = arr.findIndex((d) => d.id === day.id);
        arr[element].timePeriods = bufferTimeSlots;
        setBufferTimeSlots(null);
      }
    }

    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, 'displayHoursSelection')
    );
  }, [displayHoursSelection]);

  const createTimePeriod = () => {
    const arr = [...openingDays];
    const elementId = arr.findIndex((d) => d.id === day.id);
    arr[elementId].timePeriods.push({
      id: nanoid(),
      startOfWorkingDay: 'AM',
      endOfWorkingDay: 'PM',
      duration: '',
      hoursIntervals: []
    });

    setOpeningDays(() => arr);

    // When created new time period, we push it into OpeningHoursChanged array
    // to track changes in form and make or not to make the Save button active:
    setFieldValue('OpeningHoursChanged', [
      ...OpeningHoursChanged,
      arr[elementId].timePeriods[arr[elementId].timePeriods.length - 1]
    ]);
  };

  const removeDay = (id) => {
    setOpeningDays((prevState) => prevState.filter((d) => d.id !== id));

    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, id)
    );
  };

  const disableAddHours = (day) => {
    return (
      day &&
      day?.timePeriods.find(
        (period) =>
          period?.startOfWorkingDay === 'AM' || period?.endOfWorkingDay === 'PM'
      )
    );
  };

  return (
    <>
      <DashboardField style={{position: 'initial'}} className='w100'>
        {!withoutClose && (
          <DialogClose onClick={() => removeDay(day.id)} type='button' />
        )}
      </DashboardField>
      <DashboardField className='w100 DashboardFieldActions'>
        <DashboardDays>
          <DaysPickerDays day={day} />
        </DashboardDays>
      </DashboardField>
      <DashboardField className='w100 DashboardFieldActions'>
        <RadioGroup
          aria-label='Opening Days and Hours'
          name='checkedDiscount'
          onChange={(e) => setDisplayHoursSelection(e.target.value)}
          value={displayHoursSelection}
        >
          <DashboardFields>
            <DashboardField className='w100 dFlex '>
              <RadioS2E
                value='certainHours'
                label='On certain hours of the day'
                type='radio'
                checked={displayHoursSelection === 'certainHours'}
              />
              <RadioS2E
                value='allDay'
                label='All day (24 hours)'
                type='radio'
                checked={displayHoursSelection === 'allDay'}
              />
            </DashboardField>
          </DashboardFields>
        </RadioGroup>
      </DashboardField>
      {displayHoursSelection === 'certainHours' && (
        <DashboardField className='w100'>
          <DaysPickerTimeSelect timePeriods={day.timePeriods} day={day} />
        </DashboardField>
      )}
      {displayHoursSelection === 'certainHours' && !allDaySelected(day) && (
        <DashboardField className='w100 DashboardFieldActions'>
          <DialogLinkButton
            type='button'
            className={disableAddHours(day) && 'disabled'}
            onClick={() => createTimePeriod()}
          >
            Add hours
          </DialogLinkButton>
        </DashboardField>
      )}
    </>
  );
};

DayItem.propTypes = {
  day: T.object.isRequired
};

DayItem.defaultProps = {};

export default memo(DayItem);
