import {createReducer} from 'redux-act';
import {
  GET_LOCATION_SETTINGS_DATA,
  GET_LOCATION_SETTINGS_DATA_BY_ID,
  GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS,
  GET_LOCATION_SETTINGS_DATA_SUCCESS,
  OPEN_CONFIRMATION_MODAL,
  OPEN_DISABLE_MODAL,
  // OPEN_LEAVE_MODAL,
  POST_LOCATION_SETTINGS_DATA,
  POST_LOCATION_SETTINGS_DATA_SUCCESS,
  UPDATE_LOCATION_SETTINGS_DATA,
  UPDATE_LOCATION_SETTINGS_DATA_SUCCESS,
  GET_CALLING_PHONE,
  GET_CALLING_PHONE_SUCCESS,
  SET_PUBLISH_BUTTON_STATE,
  PUBLISH_LOCATION_SUCCESS,
  UPDATE_LOCATION_ACCOUNT_TEMPLATE,
  OPEN_LEAVE_MESSAGE,
  OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL,
  CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS,
  CREATE_STORE_HOLIDAYS_REQUEST,
  SET_STORE_HOLIDAYS,
  SET_STATUS_BUTTON
} from './action';
import {
  PATCH_DELIVERY_OPTIONS_SUCCESS,
  TOGGLE_STORE_SUCCESS
} from '../global/globalNotifications/action';
import {PUBLISH_STATUS_DISABLE, PUBLISH_STATUS_ENABLE} from './constants';

const initialState = () => ({
  loading: true,
  dataReceived: null,
  data: {},
  callingCode: null,
  // isLeaveModalOpen: false,
  isLeaveMessageOpen: false,
  isConfirmationModalOpen: false,
  isDisableModalOpen: false,
  confirmationEntityName: '',
  publishStatus: PUBLISH_STATUS_ENABLE,
  isChangeAddressModalOpen: false,
  storeHolidays: {},
  disableButton: false
});

const locationSettingsReducer = createReducer(
  {
    [GET_LOCATION_SETTINGS_DATA_BY_ID]: (state, payload) => ({
      ...state,
      dataReceived: null,
      loading: !payload?.noLoader
    }),
    [GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      dataReceived: true,
      loading: false
    }),
    [GET_LOCATION_SETTINGS_DATA]: (state) => ({
      ...state,
      dataReceived: null,
      loading: true
    }),
    [GET_LOCATION_SETTINGS_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      dataReceived: true,
      loading: false
    }),
    [GET_CALLING_PHONE_SUCCESS]: (state, payload) => ({
      ...state,
      callingCode: payload.callingCode
    }),
    // [OPEN_LEAVE_MODAL]: (state, {open}) => ({
    //   ...state,
    //   isLeaveModalOpen: open
    // }),
    [OPEN_LEAVE_MESSAGE]: (state) => ({
      ...state,
      isLeaveMessageOpen: true
    }),
    [OPEN_CONFIRMATION_MODAL]: (state, {open, entityName}) => ({
      ...state,
      isConfirmationModalOpen: open,
      confirmationEntityName: entityName
    }),
    [OPEN_DISABLE_MODAL]: (state, {open, entityName}) => ({
      ...state,
      isDisableModalOpen: open,
      confirmationEntityName: entityName
    }),
    [PATCH_DELIVERY_OPTIONS_SUCCESS]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload
      }
    }),
    [TOGGLE_STORE_SUCCESS]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload
      }
    }),
    [SET_PUBLISH_BUTTON_STATE]: (store, status) => ({
      ...store,
      publishStatus: status
    }),
    [PUBLISH_LOCATION_SUCCESS]: (store, payload) => ({
      ...store,
      data: {
        ...store.data,
        updatedAt: payload?.updatedAt,
        LastBotSync: payload?.LastBotSync
      }
    }),
    [UPDATE_LOCATION_ACCOUNT_TEMPLATE]: (store) => ({
      ...store
    }),
    [OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL]: (store, {open}) => ({
      ...store,
      isChangeAddressModalOpen: open
    }),
    [CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      dataReceived: true,
      loading: false
    }),
    [SET_STORE_HOLIDAYS]: (state, payload) => ({
      ...state,
      storeHolidays: payload
    }),
    [SET_STATUS_BUTTON]: (state, payload) => ({
      ...state,
      disableButton: payload
    })
  },
  initialState()
);

export default locationSettingsReducer;
