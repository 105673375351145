import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog} from '@material-ui/core';
import DialogBody from '../../../../components/DialogBody/styles';
import {WelcomeImage} from '../../../../components/WelcomeImage/styles';
import WelcomeImg from '../../../../assets/welcome_red.gif';
import {DialogAction} from '../../dashboard/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {CardsButtonGrid} from '../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../components/CardsButton/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import {stepData} from '../../../../store/selfCreateAccount/selectors';
import {userData} from '../../../../store/global/account/selectors';
import {PATCH_USER_DATA} from '../../../../store/global/user/action';

const Welcome = ({openModalAction}) => {
  const [gif, setGif] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {restaurantName} = stepData();
  const {isSignUp2} = useSelector(userData);

  const onSubmit = () => {
    dispatch(openModalAction({open: false, field: 'isWelcomeModalOpen'}));
    if (!isSignUp2) {
      dispatch(PATCH_USER_DATA({pendingDataSetuped: true}));
    }
    if (isSignUp2) {
      history.push('/my-account/self-setup/calculator');
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    setGif(WelcomeImg);
    return () => {
      setGif(null);
    };
  }, []);

  return (
    <Dialog open onClose={() => onSubmit()} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => onSubmit()} />
        <WelcomeImage src={gif} className='mBottom40' />
        <DialogTitle className='marginBottomXs'>
          <span>{restaurantName || ''}</span>
          {restaurantName && ','} Welcome onboard!
        </DialogTitle>
        <DialogBody className='mTop40'>
          <CardsButtonGrid className='CardsButtonGridWelcome'>
            <CardsButton className='isActive'>
              <span>
                Faster & better <b> commission-free delivery</b>, with premium
                support
              </span>
            </CardsButton>
            <CardsButton className='isActive'>
              <span>
                Increase sales using Sauce`s{' '}
                <b>multiple online revenue centers</b>
              </span>
            </CardsButton>
            <CardsButton className='isActive'>
              <span>
                Provide <b>superior guest experience</b> that drives repeat
                business
              </span>
            </CardsButton>
          </CardsButtonGrid>
          <DialogAction>
            <ButtonPrimary onClick={onSubmit}>
              <span>I’m Ready. Let’s Go!</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

Welcome.propTypes = {
  openModalAction: T.func.isRequired
};

export default Welcome;
