import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTHENTICATION_FETCHING_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_STATE_CHANGE,
  LOGIN_ACTION,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REFRESH_TOKEN,
  RETURN_TO_OLD_DASHBOARD,
  SAVE_USER_EMAIL,
  SAVE_USER_EMAIL_SUCCESS,
  SELF_SIGNUP_USER,
  SELF_SIGNUP_USER_SUCCESS,
  SELF_SIGNUP_USER_FAILED,
  SIGNUP_USER_FAILED,
  SET_ENTERED_EMAIL,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_MEMBER_PASSWORD,
  SET_FETCHING,
  AUTH_CHAT_USER
} from './action';
import {
  deleteRefreshTokenApi,
  forgotPasswordApi,
  refreshTokensApi,
  returnToOldDashboardApi,
  updateMemberPasswordApi,
  updateUserPasswordApi,
  userLoginApi
} from '../../../helpers/services/api/auth';
import history from '../../../history';
import {SESSION_EXPIRED_MODAL_OPEN} from '../sessionExpiredModal/action';
import {getUserData, getUsersLocations} from '../user/saga';
import {getUserInfoForChat} from '../hubspotChat/saga';
import {configInterseptors} from '../../../helpers/services/utils/interseptors';
import {
  getStorageToken,
  setInAvailableStorage
} from '../../../helpers/localStorage/authStorage';
import {
  saveUserEmailApi,
  selfSignUpUserApi,
  signUpUser
} from '../../../helpers/services/api/user';

import {SETUP_USER_ACCOUNT_MODAL} from '../../setupRestaurant/action';
import {SHOW_STEP_MODAL} from '../../selfCreateAccount/action';
import {setSessionStorageItem} from '../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../helpers/sessionStorage/constants';
import {GET_USER_INFO_FOR_CHAT} from '../hubspotChat/action';

const userEmail = ({authReducer}) => authReducer.userEmail;
const isOldSalesSetup = ({authReducer}) => authReducer.isOldSalesSetup;

function* saveUserEmail({payload}) {
  try {
    const {status} = yield saveUserEmailApi(payload);
    if (status === 201) {
      yield put(SAVE_USER_EMAIL_SUCCESS());
      yield put(SET_ENTERED_EMAIL(true));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* signupUser({payload}) {
  try {
    const email = yield select(userEmail);
    const isOldSalesSetupFlag = yield select(isOldSalesSetup);
    // const recaptchaKey = yield payload.captchaRef.executeAsync();
    // payload.captchaRef.reset();
    yield put(SET_FETCHING(true));
    const {data, status} = yield signUpUser(email, {
      Password: payload.Password,
      RestaurantURL: payload.RestaurantURL,
      isOldSalesSetup: isOldSalesSetupFlag
      // recaptchaKey
    });

    if (status === 200) {
      localStorage.setItem(
        process.env.REACT_APP_REFRESH_TOKEN_KEY,
        data.refreshToken
      );
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      yield put(AUTH_USER_SUCCESS(data.token));
      yield getUserData();
      yield getUsersLocations();
      yield put(SIGNUP_USER_SUCCESS(data));
      history.push('/my-account/sales-setup-new');
      yield put(SETUP_USER_ACCOUNT_MODAL({open: true}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    if (error.response.status === 405) {
      yield put(SIGNUP_USER_FAILED());
      yield put(SET_ENTERED_EMAIL(true));
      return;
    }
    yield put(SET_FETCHING(false));
  }
}

function* selfSignupUser({payload}) {
  try {
    const email = yield select(userEmail);
    // const recaptchaKey = yield payload.captchaRef.executeAsync();
    // payload.captchaRef.reset();
    yield put(SET_FETCHING(true));
    const {data, status} = yield selfSignUpUserApi(email, {
      Password: payload.Password,
      RestaurantURL: payload.RestaurantURL
      // recaptchaKey
    });
    if (status === 201) {
      localStorage.setItem(
        process.env.REACT_APP_REFRESH_TOKEN_KEY,
        data.refreshToken
      );
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      yield put(AUTH_USER_SUCCESS(data.token));
      yield getUserData();
      yield getUsersLocations();
      yield put(SELF_SIGNUP_USER_SUCCESS(data));
      history.push('/my-account/self-setup');
      yield put(SHOW_STEP_MODAL({open: true}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);

    if (error.response.status === 405) {
      yield put(SELF_SIGNUP_USER_FAILED());
      yield put(SET_ENTERED_EMAIL(true));
      return;
    }
    yield put(SET_FETCHING(false));
  }
}

function* refreshToken() {
  try {
    const rf = getStorageToken().refreshToken;
    const {data} = yield refreshTokensApi({refreshToken: rf});

    // TODO: delete after bugfix 1183
    console.log('Refresh token Attempt was run', data);

    if (data) {
      yield put(AUTH_USER_SUCCESS(data.token));

      setInAvailableStorage(data.token, data.refreshToken);

      yield configInterseptors();
    }
  } catch (error) {
    if (error.response.status === 403) {
      yield put(SESSION_EXPIRED_MODAL_OPEN());
    } else {
      console.log('SAGA ERROR', error);
    }

    yield put(LOGOUT_USER());
  }
}

function* userAuthentication({payload}) {
  try {
    // TODO: Remove when the old dashboard does not need to be used.
    // For connection with old dashboard, remove - if (payload.fromOldDashboard), revert AUTH_USER action

    if (payload.fromOldDashboard) {
      let response;

      if (payload.hasLoginToken) {
        const {data} = yield userLoginApi({
          token: payload.token,
          hasLoginToken: payload.hasLoginToken
        });
        sessionStorage.removeItem('secretKey');
        response = data;
      } else {
        const {data} = yield userLoginApi({
          token: payload.token,
          authWithOldToken: true
        });
        response = data;

        if (data?.token) {
          setSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME, true);
        }
      }

      if (response) {
        sessionStorage.setItem(process.env.REACT_APP_TOKEN_KEY, response.token);
        sessionStorage.setItem(
          process.env.REACT_APP_REFRESH_TOKEN_KEY,
          response.refreshToken
        );

        // history.push('/my-account')
        // TODO: Remove when the old dashboard does not need to be used.
        // For connection with old dashboard, revert AUTH_USER action
        yield put(AUTH_USER({token: response.token}));
      }
    } else {
      if (jwt_decode(payload.token).exp < Date.now() / 1000 + 360) {
        yield refreshToken();
      } else {
        yield put(AUTH_USER_SUCCESS(payload.token));
      }
      // This function is used for tracking login time
      yield getUserData({saveTime: true});
      yield getUsersLocations();
      yield put(AUTHENTICATION_FETCHING_SUCCESS());
    }
  } catch (error) {
    yield put(LOGOUT_USER_SUCCESS());
    history.push('/');
    console.log('SAGA ERROR', error);
  }
}

export function* chatUserAuthentication({payload}) {
  try {
    const {data} = yield userLoginApi({
      token: payload.token,
      authWithOldToken: true
    });

    if (data) {
      if (data?.token) {
        setSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME, true);
      }
      sessionStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
      sessionStorage.setItem(
        process.env.REACT_APP_REFRESH_TOKEN_KEY,
        data.refreshToken
      );
      if (jwt_decode(payload.token).exp < Date.now() / 1000 + 360) {
        yield refreshToken();
      } else {
        yield configInterseptors();
        yield put(AUTH_USER_SUCCESS(data.token));
      }
    }
    yield getUserInfoForChat(payload.locationId);
  } catch (error) {
    yield put(LOGOUT_USER_SUCCESS());
    history.push('/');
    console.log('SAGA ERROR', error);
  }
}

export function* removeRefreshTokenAfterLogout() {
  try {
    const tokens = getStorageToken();
    yield deleteRefreshTokenApi(
      jwt_decode(tokens.token || tokens.refreshToken).id
    );
    yield put(LOGOUT_USER_SUCCESS());
    history.push('/');
  } catch (error) {
    yield put(LOGOUT_USER_SUCCESS());
    history.push('/');
    console.log('SAGA ERROR', error);
  }
}

function* forgotPassword({payload}) {
  try {
    yield forgotPasswordApi(payload);
    yield put(FORGOT_PASSWORD_STATE_CHANGE({loading: false, success: true}));
  } catch (error) {
    yield put(FORGOT_PASSWORD_STATE_CHANGE({loading: false, success: false}));
    console.log('SAGA ERROR', error);
  }
}

function* updatePassword({payload}) {
  try {
    yield updateUserPasswordApi(payload.token, {
      password: payload.password
    });
    yield toast.success('Success password update');
    yield history.push('/');
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* loginSaga({payload}) {
  try {
    // TODO: Remove when the old dashboard does not need to be used.
    // For connection with old dashboard, remove newLogin field
    const {data} = yield userLoginApi({
      email: payload.Email,
      password: payload.Password,
      newLogin: true
    });
    if (data) {
      if (payload.RememberMe) {
        localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
        localStorage.setItem(
          process.env.REACT_APP_REFRESH_TOKEN_KEY,
          data.refreshToken
        );
      } else {
        sessionStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token);
        sessionStorage.setItem(
          process.env.REACT_APP_REFRESH_TOKEN_KEY,
          data.refreshToken
        );
      }
      // TODO: Remove when the old dashboard does not need to be used.
      // For connection with old dashboard, revert AUTH_USER action
      yield put(AUTH_USER({token: data.token}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* updateMemberPassword({payload}) {
  try {
    yield updateMemberPasswordApi(payload.token, {
      password: payload.password
    });
    payload.action();
    yield toast.success('Success activated');
    yield loginSaga({
      payload: {Email: payload.email, Password: payload.password}
    });
    yield history.push('/');
  } catch (error) {
    toast.error(
      `Error occurred - please try again [${
        (error.response && error.response.data.message) || error
      }]`
    );
  }
}

function* returnToOldDashboard({payload}) {
  try {
    const {data} = yield returnToOldDashboardApi();
    window.location.assign(data);
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* authSaga() {
  yield takeEvery(AUTH_USER, userAuthentication);
  yield takeEvery(AUTH_CHAT_USER, chatUserAuthentication);
  yield takeEvery(REFRESH_TOKEN, refreshToken);
  yield takeEvery(LOGOUT_USER, removeRefreshTokenAfterLogout);
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
  yield takeEvery(UPDATE_MEMBER_PASSWORD, updateMemberPassword);
  yield takeEvery(LOGIN_ACTION, loginSaga);
  yield takeEvery(SAVE_USER_EMAIL, saveUserEmail);
  yield takeEvery(SIGNUP_USER, signupUser);
  yield takeEvery(SELF_SIGNUP_USER, selfSignupUser);
  yield takeEvery(RETURN_TO_OLD_DASHBOARD, returnToOldDashboard);
}

export default authSaga;
