/* eslint-disable */
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import DndItem from './dgdItem';
import {treeBranchesScheme} from '../../../../store/menuEditor/helper/MenuTree';

export default function TreeItem(props) {
  const {
    itemId,
    itemScheme,
    dragHandleProps,
    withoutDrag,
    withBulkCheckbox,
    bulkSelectedMap
  } = props;

  const {storeMap, branchStoreMap, activeBranchStoreMap} = treeBranchesScheme[
    itemScheme
  ];

  const itemData = useSelector(
    (state) => state.menuEditorReducer[storeMap][itemId]
  );
  const treeBranchData = useSelector(
    (state) => state.menuEditorReducer[branchStoreMap][itemId]
  );
  const activeTreeBranchData = useSelector(
    (state) => state.menuEditorReducer[activeBranchStoreMap][itemId]
  );

  if (!itemData || !treeBranchData || !activeTreeBranchData) {
    return null;
  }

  return (
    <DndItem
      withoutDrag={withoutDrag}
      withBulkCheckbox={withBulkCheckbox}
      bulkSelectedMap={bulkSelectedMap}
      dragHandleProps={dragHandleProps}
      itemData={itemData}
      treeBranchData={treeBranchData}
      activeTreeBranchData={activeTreeBranchData}
    />
  );
}
