import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import {TOGGLE_CHECKBOX_MENU_ITEM} from '../../../../store/menuEditor/action';
import {DgDCheckbox} from '../styles';

function BulkCheckbox({id, type, checked}) {
  const dispatch = useDispatch();

  return (
    <DgDCheckbox onClick={(e) => e.stopPropagation()}>
      <Checkbox
        name='ContainsAlcohol'
        checked={checked}
        onChange={() => dispatch(TOGGLE_CHECKBOX_MENU_ITEM({id, type}))}
        labelStyle={{color: '#172751'}}
        iconStyle={{fill: '#FF2D38'}}
        inputStyle={{color: '#FF2D38'}}
        style={{
          color: '#FF2D38',
          height: '40px',
          flex: '0 1 40px'
        }}
      />
    </DgDCheckbox>
  );
}

BulkCheckbox.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  checked: PropTypes.bool
};

BulkCheckbox.defaultProps = {
  id: null,
  type: null,
  checked: false
};

export default BulkCheckbox;
