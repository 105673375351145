import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {DialogAction, DialogBody} from '../../dashboard/styles';
import {DialogSubTitle} from '../../../../components/DialogSubTitle/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import AnimateLocationDelivery from '../../../../assets/animate-delivery-red.gif';
import {OrderSuccessImage} from './styles';
import {ButtonPrimary} from '../../../../components/buttons';

const formattedDate = (date, timezone) => {
  let newDate = date;
  if (newDate) {
    newDate = moment(newDate)
      .utc()
      .utcOffset(Number(timezone))
      .format('MMMM D YY, h:mm A');
  }
  return newDate;
};
const OrderSuccess = (props) => {
  const {onClose, open, data, timezone} = props;
  return (
    <Dialog open={open} onClose={onClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle>
          Your order was created <span>successfully</span>
        </DialogTitle>
        {data?.deliveryType === 'ASAP' && (
          <DialogSubTitle className='badSuccess'>
            Please have the food ready for pickup at{' '}
            <span>
              {moment(formattedDate(data?.pickup_time, timezone)).format(
                'hh:mm a'
              )}
            </span>
          </DialogSubTitle>
        )}
        {data?.deliveryType === 'FutureDelivery' && (
          <DialogSubTitle className='badSuccess'>
            Please have the food ready for pickup at{' '}
            <span>
              {moment(formattedDate(data?.pickup_time, timezone)).format(
                'hh:mm a'
              )}
            </span>{' '}
            on{' '}
            <span>
              {moment(formattedDate(data?.pickup_time, timezone)).format(
                'MM/DD/YY'
              )}
            </span>
          </DialogSubTitle>
        )}
        <DialogBody>
          <OrderSuccessImage src={AnimateLocationDelivery} />
          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>Close</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

OrderSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired
};

export default OrderSuccess;
