import styled from 'styled-components';

export const OrderSuccessImage = styled.img`
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 30px auto 0 auto;
`;

export const OrderFailImage = styled.img`
  display: block;
  max-width: 200px;
  width: 100%;
  margin: 30px auto 0 auto;
`;
