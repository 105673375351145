import {createReducer} from 'redux-act';
import {GET_SALES_PER_ITEM, GET_SALES_PER_ITEM_SUCCESS} from './action';

const initialState = () => ({
  salesPerItem: [],
  salesPerItemLoading: false
});

const analyticsSalesPerItemReducer = createReducer(
  {
    [GET_SALES_PER_ITEM]: (state) => ({
      ...state,
      salesPerItemLoading: true
    }),
    [GET_SALES_PER_ITEM_SUCCESS]: (state, payload) => ({
      ...state,
      salesPerItem: payload,
      salesPerItemLoading: false
    })
  },
  initialState()
);

export default analyticsSalesPerItemReducer;
