import styled from 'styled-components';

export const BADCreateTotal = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #172751;
  text-align: center;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    margin-left: 13px;
    font-size: 30px;
    line-height: 1;
    min-height: 40px;
    .LoadingSpinner {
      margin: 0 0 0 12px;
      min-height: auto;
    }
  }
`;

export default BADCreateTotal;
