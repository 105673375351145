import React from 'react';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import {ButtonPrimary} from '../../../../../components/buttons';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import {DialogField, DialogFields, FieldInput} from '../../../dashboard/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {
  DISCONNECT_UBER_EATS,
  HANDLE_DISCONNECT_MODAL
} from '../../../../../store/uberEats/actions';
import DialogActions from '../../../../../components/DialogActions/styles';
import Body4 from '../../../../../components/typography/Body4';
import BYOCDisconnectSchema from '../../../../../helpers/services/formValidationSchemas/BYOCDisconnectSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import FieldMaskInput from '../../../../../components/FieldMaskInput';
import FieldTextarea from '../../../../../components/FieldTextarea';
import sendGtmEvent from '../../../../../helpers/services/utils/gtm';

const DisconnectFormModal = () => {
  const dispatch = useDispatch();
  const disconnectModalOpen = useSelector(
    (state) => state.uberEatsReducer.disconnectModalOpen
  );
  const {userData, activeLocation} = useSelector((state) => state.user);
  const onClose = () => {
    dispatch(HANDLE_DISCONNECT_MODAL(0));
  };

  const formik = useFormik({
    initialValues: {
      FullName: activeLocation?.FullName || '',
      Phone: activeLocation?.Phone || '',
      Email: userData?.email || '',
      Reason: ''
    },
    validationSchema: BYOCDisconnectSchema,
    onSubmit: (values) => {
      dispatch(DISCONNECT_UBER_EATS(values));
      sendGtmEvent('UberEatsDisconnectFormSent', {
        formSent: true
      });
    }
  });

  return (
    <Dialog scroll='body' open={disconnectModalOpen === 2}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => onClose()} />
        <form onSubmit={formik.handleSubmit}>
          <DialogBody>
            <DialogTitle>Disconnecting UberEats from Sauce</DialogTitle>
            <DialogBody>
              <Body4 className='marginTopXl' textAlign='center'>
                Fill out this form so our team can check if it is safe to
                disconnect you without losing important data.
              </Body4>
            </DialogBody>
            <DialogFields>
              <DialogField className='w100'>
                <FieldLabel>
                  <span>*</span> Full Name
                </FieldLabel>

                <FieldInput
                  className={formik.errors.FullName && 'error'}
                  name='FullName'
                  onChange={formik.handleChange}
                  value={formik.values.FullName}
                  placeholder='Write a full name here'
                />
                <ErrorSignWrapper>
                  {formik.errors.FullName && (
                    <ErrorSign>{formik.errors.FullName}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
              <DialogField className='w50'>
                <FieldLabel>
                  <span>*</span> Email
                </FieldLabel>

                <FieldInput
                  className={formik.errors.Email && 'error'}
                  name='Email'
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                  placeholder='Write a email here'
                />
                <ErrorSignWrapper>
                  {formik.errors.Email && (
                    <ErrorSign>{formik.errors.Email}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
              <DialogField className='w50'>
                <FieldLabel>
                  <span>*</span> Phone number
                </FieldLabel>

                <FieldMaskInput
                  className={formik.errors.Phone && 'error'}
                  name='Phone'
                  mask='(999) 999-9999'
                  onChange={formik.handleChange}
                  value={formik.values.Phone}
                  placeholder='Write a phone number here'
                />
                <ErrorSignWrapper>
                  {formik.errors.Phone && (
                    <ErrorSign>{formik.errors.Phone}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
              <DialogField className='w100'>
                <FieldLabel>
                  <span>*</span> Disconnect reason
                </FieldLabel>

                <FieldTextarea
                  className={cn('FieldTextareaLg WithoutResize', {
                    error: formik.errors.Reason
                  })}
                  name='Reason'
                  onChange={formik.handleChange}
                  value={formik.values.Reason}
                  placeholder='Type your reason'
                />
                <ErrorSignWrapper>
                  {formik.errors.Reason && (
                    <ErrorSign>{formik.errors.Reason}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
            </DialogFields>
            <DialogActions className='justifyCenter'>
              <ButtonPrimary type='submit'>
                <span>Send</span>
              </ButtonPrimary>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogContainer>
    </Dialog>
  );
};

export default DisconnectFormModal;
