import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modals/Modal';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {DialogText} from '../../../dashboard/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from '../../../../../components/buttons';
import DialogActions from '../../../../../components/DialogActions/styles';

const WarningModal = ({onClose, dialogText, submitButtonText, onSubmit}) => {
  const isLoading = useSelector(
    (state) => state.featureManagementReducer.modalLoading
  );

  return (
    <Modal
      title={<DialogTitle className='marginBottomSm'>Please note:</DialogTitle>}
      body={
        <>
          <DialogText className='marginBottomSm'>{dialogText}</DialogText>

          <DialogActions className='marginTopNull'>
            <DialogAction className='w50'>
              <ButtonOutline
                onClick={onClose}
                type='button'
                className={isLoading ? 'disabled ButtonLg' : 'ButtonLg'}
              >
                <span>Cancel</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                onClick={onSubmit}
                type='button'
                className={isLoading && 'disabled'}
              >
                <span>{submitButtonText}</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </>
      }
      onCloseAction={onClose}
    />
  );
};

WarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialogText: PropTypes.element.isRequired,
  submitButtonText: PropTypes.string.isRequired
};

export default WarningModal;
