import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {Checkbox, Dialog, FormControlLabel} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogHint,
  DialogItem,
  DialogItems,
  DialogLinkButton,
  FieldInput,
  FieldLabel
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  CREATE_MODIFIER_GROUP_MODAL,
  EDIT_MODIFIER_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import FileUploader from '../../../../components/FileUploader';
import {GET_MODIFIER_GROUP_MODAL} from '../../../../store/menuEditorModifierGroups/action';
import {
  GET_MODIFIER_BY_ID,
  UPDATE_MODIFIER
} from '../../../../store/menuEditorModifier/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import {priceFormatter} from '../constants';

const EditModifierModal = () => {
  const dispatch = useDispatch();

  const isEditModifierModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditModifierModalOpen
  );
  const getNewModifierFormData = useSelector(
    (state) => state.menuEditorModalsReducer.getNewModifierFormData
  );
  const entityId = useSelector(
    (state) => state.menuEditorModalsReducer.entityId
  );
  const oldEntityData = useSelector(
    (state) => state.menuEditorModalsReducer.oldEntityData
  );
  const entityDuplicateInfo = useSelector(
    (state) => state.menuEditorModalsReducer.entityDuplicateInfo
  );

  const modifierData = useSelector(
    (state) => state.menuEditorReducer.modifiersMap[entityId]
  );

  const groupsMap = useSelector((state) => state.menuEditorReducer.groupsMap);
  const groupsDirection = useSelector(
    (state) => state.menuEditorReducer.groupsDirection
  );
  const groupsList = groupsDirection.map((PosId) => groupsMap[PosId]);

  let initOutStateValue = 0;
  const {InStock = true, OutOfStockToNext = false} = entityDuplicateInfo || {};

  if (OutOfStockToNext) {
    initOutStateValue = 4;
  } else if (!OutOfStockToNext && !InStock) {
    initOutStateValue = 3;
  }

  const [outState, setOutState] = useState(initOutStateValue);
  const [fileUrl, setFileUrl] = useState('');
  const [childrenExpanded, setChildrenExpanded] = useState([]);
  const [parentExpanded, setParentExpanded] = useState([]);
  const [selectedModifiersGroups, setSelectedModifierGroups] = useState([]);
  const [
    selectedParentModifiersGroups,
    setSelectedParentModifierGroups
  ] = useState([]);

  const removeModifierGroup = (id) => {
    setSelectedModifierGroups(
      selectedModifiersGroups.filter((group) => group.PosId !== id)
    );
  };

  const removeParentModifierGroup = (id) => {
    setSelectedParentModifierGroups(
      selectedParentModifiersGroups.filter((group) => group.PosId !== id)
    );
  };

  useEffect(() => {
    if (entityDuplicateInfo?.PictureURL !== '') {
      setFileUrl(modifierData.PictureURL);
    }

    setSelectedModifierGroups(
      modifierData.ModifierGroups.map((PosId) => groupsMap[PosId])
    );
    setSelectedParentModifierGroups(
      modifierData.ParentModifierGroups.map((PosId) => groupsMap[PosId])
    );

    return () => {
      setParentExpanded(false);
      setChildrenExpanded(false);
      setSelectedModifierGroups([]);
      setSelectedParentModifierGroups([]);
    };
  }, [modifierData]);

  useEffect(() => {
    const fetchModifierData = () => {
      dispatch(GET_MODIFIER_BY_ID(entityId));
    };

    const fetchModifiersGroupsData = () => {
      dispatch(GET_MODIFIER_GROUP_MODAL({limit: 1000, page: 1}));
    };
    fetchModifiersGroupsData();
    fetchModifierData();
  }, []);

  const formik = useFormik({
    initialValues: {
      Name: modifierData?.Name || '',
      Price: modifierData?.Price || '',
      PictureURL: modifierData?.PictureURL || fileUrl,
      Description: modifierData?.OptionDescription || '',
      ModifierGroups: [],
      Invisible: modifierData?.Invisible || false,
      isRequiredModifiers: modifierData?.isRequiredModifiers || false,
      ExternalId: modifierData?.ExternalId || '',
      ExternalData: modifierData?.ExternalData || '',
      MaxAmount: modifierData?.MaxAmount || '1',
      ContainsAlcohol: modifierData?.ContainsAlcohol || false
    },
    enableReinitialize: true,
    onSubmit: (values, {resetForm, setErrors}) => {
      if (values.isRequiredModifiers && selectedModifiersGroups.length === 0) {
        setErrors({ModifierGroups: 'You must select a modifier group!'});
        return;
      }
      if (typeof getNewModifierFormData === 'function') {
        getNewModifierFormData({...values});
        dispatch(
          CREATE_MODIFIER_GROUP_MODAL({
            open: true
          })
        );
      } else {
        dispatch(
          UPDATE_MODIFIER({
            oldEntityData,
            form: {
              ...values,
              Name: String(values.Name).trim(),
              PictureURL: fileUrl,
              InStock: outState !== 3 && outState !== 4,
              OutOfStockToNext: outState === 4,
              ModifierGroups: [
                ...selectedModifiersGroups.map((group) => group.PosId)
              ],
              ParentModifierGroups: [
                ...selectedParentModifiersGroups.map((group) => group.PosId)
              ]
            }
          })
        );
      }

      dispatch(EDIT_MODIFIER_MODAL({open: false}));
      resetForm();
    }
  });

  const handleSetOutOfStock = (isActive, value) => {
    if (isActive && outState !== value) {
      setOutState(value);
    }
  };

  return (
    <Dialog
      onClose={() => dispatch(EDIT_MODIFIER_MODAL({open: false}))}
      open={isEditModifierModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogLg'>
        <DialogClose
          onClick={() => dispatch(EDIT_MODIFIER_MODAL({open: false}))}
        />
        <DialogTitle>
          Edit a <span>Modifier</span>
        </DialogTitle>
        <DialogBody>
          <form onSubmit={formik.handleSubmit}>
            <DialogFields>
              <DialogField className='w60'>
                <FieldLabel>Modifier name</FieldLabel>
                <FieldInput
                  name='Name'
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  placeholder='Write item name here'
                />
              </DialogField>
              <DialogField className='wSm'>
                <FieldLabel>Price</FieldLabel>
                <FieldInput
                  name='Price'
                  placeholder='$'
                  value={formik.values.Price}
                  onChange={(e) => {
                    const price = priceFormatter(
                      e.target.value,
                      formik?.values?.Price
                    );
                    formik?.setFieldValue('Price', price);
                  }}
                  onBlur={(e) => {
                    const lastElement = e.target.value.slice(-1)[0];
                    if (lastElement === '.') {
                      formik?.setFieldValue(
                        'Price',
                        e.target.value.slice(0, -1)
                      );
                    }
                  }}
                />
              </DialogField>
              <DialogField className='w100 dFlex'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox Say2EatCheckboxSoldOut'
                    control={
                      <Checkbox
                        name='Invisible'
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                        checked={formik.values.Invisible}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Hide and remove from menu'
                  />
                </CheckboxContainer>
                <DialogItems
                  className={cn(
                    'DialogItemsSoldOut DialogItemsSoldOutModifier mTop0 w70 AlignLeft',
                    {
                      isDisabled: !outState >= 1
                    }
                  )}
                />

                <FormControlLabel
                  className='Say2EatCheckbox Say2EatCheckboxSoldOut'
                  control={
                    <Checkbox
                      name='ItemSoldOut'
                      labelStyle={{color: '#172751'}}
                      iconStyle={{fill: '#FF2D38'}}
                      inputStyle={{color: '#FF2D38'}}
                      style={{color: '#FF2D38'}}
                      checked={outState >= 1}
                      onChange={() => setOutState(outState ? 0 : 1)}
                    />
                  }
                  label='Mark Item as Sold Out (86)'
                />
                <DialogItems
                  className={cn(
                    'DialogItemsSoldOut DialogItemsSoldOutModifier mTop0 w70 AlignLeft',
                    {
                      isDisabled: !outState >= 1
                    }
                  )}
                >
                  <DialogItem
                    className={cn({isActive: outState === 3})}
                    onClick={() => handleSetOutOfStock(!!outState, 3)}
                  >
                    <span>Mark as Unavailable</span>
                  </DialogItem>
                  <DialogItem
                    className={cn({isActive: outState === 4})}
                    onClick={() => handleSetOutOfStock(!!outState, 4)}
                  >
                    <span>Mark as Unavailable until tomorrow</span>
                  </DialogItem>
                </DialogItems>
              </DialogField>
              <DialogField className='w100 dFlex'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                        onChange={formik.handleChange}
                        name='ContainsAlcohol'
                        checked={formik.values.ContainsAlcohol}
                      />
                    }
                    label='Contains Alcohol'
                  />
                </CheckboxContainer>
              </DialogField>
              <DialogField className='w60'>
                <FieldLabel>Photo</FieldLabel>
                <FileUploader
                  url={fileUrl}
                  onSetFile={setFileUrl}
                  allowedFileFormats={['image/png', 'image/jpeg', 'image/jpg']}
                />
              </DialogField>
              <DialogField className='w100 dFlex noWrapTablet mTop20'>
                <DialogHint className='DialogHintTablet'>
                  <span>
                    Add the unique identifier you can use at your restaurant
                    (ex. SKU)
                  </span>
                </DialogHint>
                <DialogFieldInners className='DialogFieldInnersExternal'>
                  <DialogFieldInner className='w50'>
                    <FieldLabel>External ID</FieldLabel>
                    <FieldInput
                      name='ExternalId'
                      onChange={formik.handleChange}
                      value={formik.values.ExternalId}
                      placeholder='External ID'
                    />
                  </DialogFieldInner>
                  <DialogFieldInner className='w50'>
                    <FieldLabel>Exernal Data</FieldLabel>
                    <FieldInput
                      name='ExternalData'
                      onChange={formik.handleChange}
                      value={formik.values.ExternalData}
                      placeholder='Exernal Data'
                    />
                  </DialogFieldInner>
                </DialogFieldInners>
              </DialogField>

              <DialogField className='w50 Say2EatSelect'>
                {!!selectedModifiersGroups.length && (
                  <AccordionItem className='AccordionItemModal'>
                    <Accordion
                      onChange={() => setChildrenExpanded(!childrenExpanded)}
                      expanded={childrenExpanded}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <AccordionItemName className='AccordionItemName'>
                          {`Modifier Groups (${selectedModifiersGroups.length})`}
                        </AccordionItemName>
                      </AccordionSummary>
                      <AccordionItemBody>
                        <DialogFields>
                          {selectedModifiersGroups.map((group) => (
                            <DialogField
                              key={group.PosId}
                              className='w100 withRemove'
                            >
                              <FieldInput
                                name=''
                                readOnly
                                value={group.OptionName}
                                placeholder={group.OptionName}
                              />
                              <DialogFieldRemove
                                onClick={() => removeModifierGroup(group.PosId)}
                              />
                            </DialogField>
                          ))}
                        </DialogFields>
                      </AccordionItemBody>
                    </Accordion>
                  </AccordionItem>
                )}
                <DialogField className='Center w100'>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          itemType: 'modifierGroup',
                          itemList: groupsList,
                          selectedItems: selectedModifiersGroups,
                          loading: false,
                          callBack: setSelectedModifierGroups,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add Modifier Groups
                  </DialogLinkButton>
                </DialogField>
              </DialogField>

              <DialogField className='w50 Say2EatSelect'>
                {!!selectedParentModifiersGroups.length && (
                  <AccordionItem className='AccordionItemModal'>
                    <Accordion
                      onChange={() => setParentExpanded(!parentExpanded)}
                      expanded={parentExpanded}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <AccordionItemName className='AccordionItemName'>
                          {`Parent Modifier Groups (${selectedParentModifiersGroups.length})`}
                        </AccordionItemName>
                      </AccordionSummary>
                      <AccordionItemBody>
                        <DialogFields>
                          {selectedParentModifiersGroups.map((group) => (
                            <DialogField
                              key={group.PosId}
                              className='w100 withRemove'
                            >
                              <FieldInput
                                name=''
                                readOnly
                                value={group.OptionName}
                                placeholder={group.OptionName}
                              />
                              <DialogFieldRemove
                                onClick={() =>
                                  removeParentModifierGroup(group.PosId)
                                }
                              />
                            </DialogField>
                          ))}
                        </DialogFields>
                      </AccordionItemBody>
                    </Accordion>
                  </AccordionItem>
                )}
                <DialogField className='Center w100'>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          itemType: 'modifierGroupParent',
                          itemList: groupsList,
                          selectedItems: selectedParentModifiersGroups,
                          loading: false,
                          callBack: setSelectedParentModifierGroups,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add Parent Modifier Groups
                  </DialogLinkButton>
                </DialogField>
              </DialogField>
            </DialogFields>

            <DialogAction>
              <ButtonPrimary type='submit'>
                <span>Save Changes</span>
              </ButtonPrimary>
            </DialogAction>
          </form>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default EditModifierModal;
