import {put, takeEvery} from 'redux-saga/effects';
import {GET_SALES_PER_ITEM, GET_SALES_PER_ITEM_SUCCESS} from './action';
import {getSalesPerItemApi} from '../../helpers/services/api/analytics';

function* getSalesPerItem({payload}) {
  try {
    const {data} = yield getSalesPerItemApi(payload.startDate, payload.endDate);
    if (data) {
      yield put(GET_SALES_PER_ITEM_SUCCESS(data.data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* analyticsSalesPerItemSaga() {
  yield takeEvery(GET_SALES_PER_ITEM, getSalesPerItem);
}

export default analyticsSalesPerItemSaga;
