import React, {memo, useEffect, useState} from 'react';
import T from 'prop-types';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {FlexContainer, FlexItem} from '../layout';
import {
  BannerClose,
  BannerContent,
  BannerIcon,
  BannerUnderlineText,
  BannerWrapper
} from './styles';

const Banner = ({linkText, mainText = '', onClick, icon, onClose}) => {
  const [isHidden, setIsHidden] = useState(false);
  const hideBanner = () => {
    setIsHidden((prevState) => !prevState);
    onClose();
  };
  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );
  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );

  useEffect(() => {
    return () => setIsHidden(false);
  }, []);

  return (
    <BannerWrapper
      className={cn({
        hide: isHidden
      })}
    >
      <FlexContainer>
        <FlexItem flex={1}>
          <BannerContent
            onClick={() => {
              onClick();
            }}
            className={cn('', {
              NotLink: UEConnected && BYOCconfigured && BYOCconnected
            })}
          >
            <BannerIcon>{icon && icon}</BannerIcon>
            <BannerUnderlineText>
              <span>{linkText}</span> {mainText}
            </BannerUnderlineText>
          </BannerContent>
        </FlexItem>
        <FlexItem className='alignSelfCenter'>
          <BannerClose onClick={hideBanner} />
        </FlexItem>
      </FlexContainer>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  linkText: T.string,
  mainText: T.string.isRequired,
  onClick: T.func,
  icon: T.elementType,
  onClose: T.func
};

Banner.defaultProps = {
  linkText: '',
  onClick: () => {},
  icon: null,
  onClose: () => {}
};

export default memo(Banner);
