import React from 'react';
import PropTypes from 'prop-types';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import {Body1, Caption1} from '../../../../../components/typography';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import Subtitle6 from '../../../../../components/typography/Subtitle6';
import {
  SurveyItem,
  SurveyItemProgress,
  SurveyItemProgressBar,
  SurveyItems
} from '../../styles';
import normalizePercentageByDecimal from '../../../../../helpers/functionUtils/normalizePercentageByDecimal';

const StatisticsSurvey = (props) => {
  const {
    title,
    description,
    total,
    sumFirstQuestion,
    firstQuestionText,
    sumSecondQuestion,
    secondQuestionText,
    sumThirdQuestion,
    thirdQuestionText,
    sumFourthQuestion,
    fourthQuestionText
  } = props;

  const percentFirstQuestion = 100 / (total / sumFirstQuestion) || 0;
  const percentSecondQuestion = 100 / (total / sumSecondQuestion) || 0;
  const percentThirdQuestion = 100 / (total / sumThirdQuestion) || 0;
  const percentFourthQuestion = 100 / (total / sumFourthQuestion) || 0;

  const [
    firstQuestion,
    secondQuestion,
    thirdQuestion,
    fourthQuestion
  ] = normalizePercentageByDecimal([
    percentFirstQuestion,
    percentSecondQuestion,
    percentThirdQuestion,
    percentFourthQuestion
  ]);

  return (
    <>
      <DashboardInner className='w50'>
        <FlexContainer justifyContent='space-between'>
          <FlexItem>
            <Subtitle6 className='SubtitleMobSmall'>{title}</Subtitle6>
            <Body1 className='mTop2'>{description}</Body1>
          </FlexItem>
          <FlexItem className='wAuto'>
            <Subtitle6 className='SubtitleMobSmall'>Total: {total}</Subtitle6>
          </FlexItem>
        </FlexContainer>
        <SurveyItems>
          <SurveyItem>
            <FlexContainer justifyContent='space-between'>
              <Caption1>{firstQuestionText}</Caption1>
              <Body1 className='grey2 wAuto'>
                {sumFirstQuestion}/{total} ({firstQuestion}%)
              </Body1>
            </FlexContainer>
            <SurveyItemProgress>
              <SurveyItemProgressBar width={`${firstQuestion}%`} />
            </SurveyItemProgress>
          </SurveyItem>
          <SurveyItem>
            <FlexContainer justifyContent='space-between'>
              <Caption1>{secondQuestionText}</Caption1>
              <Body1 className='grey2 wAuto'>
                {sumSecondQuestion}/{total} ({secondQuestion}%)
              </Body1>
            </FlexContainer>
            <SurveyItemProgress>
              <SurveyItemProgressBar width={`${secondQuestion}%`} />
            </SurveyItemProgress>
          </SurveyItem>
          <SurveyItem>
            <FlexContainer justifyContent='space-between'>
              <Caption1>{thirdQuestionText}</Caption1>
              <Body1 className='grey2 wAuto'>
                {sumThirdQuestion}/{total} ({thirdQuestion}%)
              </Body1>
            </FlexContainer>
            <SurveyItemProgress>
              <SurveyItemProgressBar width={`${thirdQuestion}%`} />
            </SurveyItemProgress>
          </SurveyItem>
          {fourthQuestionText && (
            <SurveyItem>
              <FlexContainer justifyContent='space-between'>
                <Caption1>{fourthQuestionText}</Caption1>
                <Body1 className='grey2 wAuto'>
                  {sumFourthQuestion}/{total} ({fourthQuestion}%)
                </Body1>
              </FlexContainer>
              <SurveyItemProgress>
                <SurveyItemProgressBar width={`${fourthQuestion}%`} />
              </SurveyItemProgress>
            </SurveyItem>
          )}
        </SurveyItems>
      </DashboardInner>
    </>
  );
};

export default StatisticsSurvey;

StatisticsSurvey.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  sumFirstQuestion: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  firstQuestionText: PropTypes.string.isRequired,
  sumSecondQuestion: PropTypes.number.isRequired,
  secondQuestionText: PropTypes.string.isRequired,
  sumThirdQuestion: PropTypes.number.isRequired,
  thirdQuestionText: PropTypes.string.isRequired,
  sumFourthQuestion: PropTypes.number.isRequired,
  fourthQuestionText: PropTypes.string.isRequired
};
