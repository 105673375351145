import React from 'react';
import T from 'prop-types';
import {PulsatingDotContainer, Dot, Pulsating} from './styles';

const PulsatingDot = ({color, classNameDot, classNamePulsating}) => {
  return (
    <PulsatingDotContainer>
      <Dot color={color} className={classNameDot} />
      <Pulsating color={color} className={classNamePulsating} />
    </PulsatingDotContainer>
  );
};

PulsatingDot.propTypes = {
  color: T.bool,
  classNameDot: T.string,
  classNamePulsating: T.string
};

PulsatingDot.defaultProps = {
  color: '#17C981',
  classNameDot: '',
  classNamePulsating: ''
};

export default PulsatingDot;
