import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import Field from '../../../../../components/FieldInput';

export default function FieldInput({
  label,
  name,
  value,
  onChange,
  errorText,
  ...other
}) {
  return (
    <>
      <FieldLabel>{label}</FieldLabel>
      <Field
        {...other}
        type='text'
        value={value}
        onChange={onChange}
        name={name}
        placeholder={label}
        className={cn({
          error: !!errorText
        })}
      />
      <ErrorSignWrapper>
        {errorText ? <ErrorSign>{errorText}</ErrorSign> : null}
      </ErrorSignWrapper>
    </>
  );
}

FieldInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string
};

FieldInput.defaultProps = {
  value: '',
  errorText: '',
  onChange: () => {},
  name: ''
};
