import styled from 'styled-components';

const Caption1 = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #232d3a;

  span {
    color: #ff2d38;
    &.grey {
      color: #929cb9;
    }
    a {
      color: #ff2d38;
      text-decoration: none;
    }
  }
  &.grey2 {
    color: #929cb9;
  }

  &.textAlignCenter {
    text-align: center;
  }

  &.wordWrap {
    @media (max-width: 700px) {
      max-width: 230px;
      word-wrap: break-word;
    }
    @media (max-width: 599px) {
      max-width: inherit;
    }
    @media (max-width: 370px) {
      max-width: 230px;
    }
  }
  .big-steps {
    padding-left: 20px;
    li:last-child {
      margin-top: 22px;
    }
    .small-steps {
      padding-left: 20px;
      margin-top: 5px;
      list-style-type: disc;
      li:last-child {
        margin-top: 0;
      }
    }
  }
`;

export default Caption1;
