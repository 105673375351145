import {createAction} from 'redux-act';

export const FETCH_MENU_DATA = createAction('menuEditor/FETCH_MENU_DATA');
export const FETCH_MENU_RECURSION_RELATIONS = createAction(
  'menuEditor/FETCH_MENU_RECURSION_RELATIONS'
);
export const SET_LOADING_MENU_DATA = createAction(
  'menuEditor/LOADING_MENU_DATA'
);
export const SET_READY_MENU_DATA = createAction(
  'menuEditor/SET_READY_MENU_DATA'
);
export const SET_MENU_DATA = createAction('menuEditor/SET_MENU_DATA');
export const CLEAR_MENU_DATA = createAction('menuEditor/CLEAR_MENU_DATA');

export const SET_ACTIVE_TAB = createAction('menuEditor/SET_ACTIVE_TAB');
export const SELECT_ALL_BUTTON = createAction('menuEditor/SELECT_ALL_BUTTON');
export const SET_ACTIVE_TREE_LOADING = createAction(
  'menuEditor/SET_ACTIVE_TREE_LOADING'
);
export const SET_SEARCH_VALUE = createAction('menuEditor/SET_SEARCH_VALUE');
export const SEARCH_IN_TREE = createAction('menuEditor/SEARCH_IN_TREE');

export const UPDATE_SORTING_ORDER = createAction(
  'menuEditor/UPDATE_SORTING_ORDER'
);
export const SET_SORTING_ORDER = createAction('menuEditor/SET_SORTING_ORDER');

export const SET_PUBLISH_BUTTON_STATE = createAction(
  'menuEditor/SET_PUBLISH_BUTTON_STATE'
);
export const SET_PUBLISH_BUTTON_STATE_MENU_UPDATE = createAction(
  'menuEditor/SET_PUBLISH_BUTTON_STATE_MENU_UPDATE'
);
export const TOGGLE_CHECKBOX_MENU_ITEM = createAction(
  'menuEditor/TOGGLE_CHECKBOX_MENU_ITEM'
);

export const ADD_NEW_SECTION = createAction('menuSections/ADD_NEW_SECTION');
export const ADD_NEW_ITEM = createAction('menuSections/ADD_NEW_ITEM');
export const ADD_NEW_GROUP = createAction('menuSections/ADD_NEW_GROUP');
export const ADD_NEW_MODIFIER = createAction('menuSections/ADD_NEW_MODIFIER');

export const SET_UPDATED_SECTION = createAction(
  'menuSections/SET_UPDATED_SECTION'
);
export const SET_UPDATED_ITEM = createAction('menuSections/SET_UPDATED_ITEM');
export const SET_UPDATED_GROUP = createAction('menuSections/SET_UPDATED_GROUP');
export const SET_UPDATED_MODIFIER = createAction(
  'menuSections/SET_UPDATED_MODIFIER'
);

export const CLEAR_SECTION = createAction('menuSections/CLEAR_SECTION');
export const CLEAR_ITEM = createAction('menuSections/CLEAR_ITEM');
export const CLEAR_GROUP = createAction('menuSections/CLEAR_GROUP');
export const CLEAR_MODIFIER = createAction('menuSections/CLEAR_MODIFIER');

export const SET_RECURSION_RELATIONS = createAction(
  'menuSections/SET_RECURSION_RELATIONS'
);
export const CLEAR_RECURSION_RELATIONS = createAction(
  'menuSections/CLEAR_RECURSION_RELATIONS'
);
export const CLEAR_RECURSION_RELATIONS_SUCCESS = createAction(
  'menuSections/CLEAR_RECURSION_RELATIONS_SUCCESS'
);

export const PUBLISH_MENU = createAction('menuEditor/PUBLISH_MENU');
export const CHECK_IS_ALLOW_MENU_PUBLISHING = createAction(
  'menuEditor/CHECK_IS_ALLOW_MENU_PUBLISHING'
);

export const BULK_UPDATE = createAction('menuEditor/BULK_UPDATE');
export const BULK_UPDATE_SUCCESSFUL = createAction(
  'menuEditor/BULK_UPDATE_SUCCESSFUL'
);
export const BULK_DELETE = createAction('menuEditor/BULK_DELETE');
export const BULK_DELETE_SUCCESSFUL = createAction(
  'menuEditor/BULK_DELETE_SUCCESSFUL'
);
