import {createReducer} from 'redux-act';
import {GET_APP_VERSION_SUCCESS, CHANGED_APP_VERSION_MODAL} from './action';

const initialState = () => ({
  version: null,
  isNotificationModalOpen: false
});

const appVersionReducer = createReducer(
  {
    [GET_APP_VERSION_SUCCESS]: (state, payload) => ({
      ...state,
      version: payload
    }),
    [CHANGED_APP_VERSION_MODAL]: (state, {open}) => ({
      ...state,
      isNotificationModalOpen: open
    })
  },
  initialState()
);
export default appVersionReducer;
