import React from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {DashboardField, DashboardFields} from '../../../dashboard/styles';
import {Subtitle1} from '../../../../../components/typography';
import {HintText} from '../../../../../components/HintText/styles';
import FieldTextarea from '../../../../../components/FieldTextarea';
import {ActivateVideoGif, FieldCopy} from '../../styles';
import VideoGif from '../../../../../assets/output-onlinegiftools2.gif';
import {ButtonOutline} from '../../../../../components/buttons';

const Install = () => {
  const location = useSelector((state) => state.user?.activeLocation);

  return (
    <DashboardFields>
      <DashboardField className='w100 dFlex dFlexNoWrap justifySB'>
        <Subtitle1>Please add a link to your website. </Subtitle1>
        <HintText>
          <span>Sauce won’t be able to work proprely without it </span>
        </HintText>
      </DashboardField>
      <DashboardField className='w100'>
        <FieldCopy>
          <FieldTextarea
            disabled
            placeholder='Your link here'
            className='FieldTextareaLg'
            value={location.GeneratedLink?.short || ''}
          />
          <br />
          <CopyToClipboard
            text={location.GeneratedLink?.short || ''}
            onCopy={() =>
              toast.success('Copied successfully to your clipboard.')
            }
          >
            <ButtonOutline className='ButtonOutline ButtonSmall'>
              Copy URL
            </ButtonOutline>
          </CopyToClipboard>
        </FieldCopy>
      </DashboardField>
      <DashboardField className='w100'>
        <ActivateVideoGif src={VideoGif} />
      </DashboardField>
    </DashboardFields>
  );
};

export default Install;
