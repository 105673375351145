import ReactPaginate from 'react-paginate';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import T from 'prop-types';
import {TablePagination, TablePaginationText} from '../../dashboard/styles';
import {GET_USERS} from '../../../../store/usersManagement/action';

const Pagination = ({searchModifiers, mergeModifiers}) => {
  const dispatch = useDispatch();
  const {
    data,
    pagination: {totalDocs, totalPages}
  } = useSelector((state) => state.usersManagementReducer);

  const onPageChange = ({selected}) => {
    dispatch(GET_USERS({selected: selected + 1, ...searchModifiers}));
    mergeModifiers({selected: selected + 1});
  };

  return (
    <TablePagination>
      <TablePaginationText>
        Showing {data.length} from {totalDocs}
      </TablePaginationText>
      <ReactPaginate
        previousLabel='previous'
        nextLabel='next'
        breakLabel='...'
        breakClassName='break-me'
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName='pagination'
        subContainerClassName='pages pagination'
        activeClassName='active'
      />
    </TablePagination>
  );
};

Pagination.propTypes = {
  searchModifiers: T.object,
  mergeModifiers: T.func
};

Pagination.defaultProps = {
  searchModifiers: {},
  mergeModifiers: () => {}
};

export default Pagination;
