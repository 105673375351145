/* eslint-disable */
/**
 * @param {Array} list - Array for convert
 * @param {*} [value=null] - Custom value
 * @return {Object}
 */
export function arrayFlip(list, value) {
  return list.reduce((obj, item, index) => {
    obj[item] = value !== undefined ? value : index;

    return obj;
  }, {});
}

/**
 * @param {Array} arr - Array for convert.
 * @param {string} [type=index] - Type values for array item.
 * "index" - values is index number,
 * "value" - values is item values,
 * "custom" - user value
 * @param {*} [value=null] - Custom value. Used when 'type = custom'.
 * @returns {Object}
 */
export function convertToObject(arr, type = 'index', value = null) {
  return arr.reduce((obj, item, index) => {
    if (type === 'index') {
      obj[item] = index;

      return obj;
    }

    if (type === 'value') {
      obj[item] = item;

      return obj;
    }

    if (type === 'custom') {
      obj[item] = value;

      return obj;
    }
  }, {});
}
