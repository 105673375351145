import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import Headline from '../../../components/Headline/styles';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import {DashboardHeader} from '../../../components/DashboardHeader/styles';
import UpsellPage from './components/UpsellPage';
import MarketingContactUsModal from './components/Modals/MarketingContactUsModal';
import ResultPage from './components/ResultPage';
import {EmailMarketingSubTitle} from './components/styles';
import {ButtonInfo} from '../../../components/buttons';
import InformationModal from './components/Modals/InformationModal';
import {OPEN_INFORMATION_MODAL} from '../../../store/emailMarketing/action';
import sendGtmEvent from '../../../helpers/services/utils/gtm';

const EmailMarketing = () => {
  const dispatch = useDispatch();
  const {isOpenModal, isOpenInformationModal, spinner} = useSelector(
    (state) => state.emailMarketingReducer
  );
  const {activeLocation} = useSelector((state) => state.user);

  useEffect(() => {
    if (activeLocation?._id) {
      sendGtmEvent('view-marketing-tab', {
        type: activeLocation.AllowedServiceFeatures.includes(
          'show_marketing_results'
        )
          ? 'paying'
          : 'non-paying',
        location_name: activeLocation.FullName,
        location_id: activeLocation._id
      });
    }
  }, [activeLocation._id]);

  return (
    <DashboardContent
      className={cn('DashboardContent', {
        centerSpinner: spinner
      })}
    >
      <DashboardHeader
        className={cn('AlwaysFlex', {
          flex0: spinner
        })}
      >
        <Headline className='mTop5'>Email Marketing</Headline>
        {activeLocation.AllowedServiceFeatures.includes(
          'show_marketing_results'
        ) && (
          <ButtonInfo
            onClick={() => dispatch(OPEN_INFORMATION_MODAL(true))}
            className='Outline ButtonInfoDrop'
          />
        )}
      </DashboardHeader>
      {activeLocation.AllowedServiceFeatures.includes(
        'show_marketing_results'
      ) ? (
        <ResultPage />
      ) : (
        <UpsellPage />
      )}
      <MarketingContactUsModal openModal={isOpenModal} />
      <InformationModal openModal={isOpenInformationModal} />
    </DashboardContent>
  );
};

export default EmailMarketing;
