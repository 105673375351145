import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import {ButtonSave} from './styles';
import SaveButtonIcon from '../../../assets/SaveButton';

const ButtonSaveScroll = ({className}) => {
  const [buttonShow, setButtonShow] = useState(false);
  const buttonScrollTop = 160;
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setButtonShow(e.target.documentElement.scrollTop > buttonScrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <>
      {buttonShow && (
        <ButtonSave className={className}>
          <SaveButtonIcon />
        </ButtonSave>
      )}
    </>
  );
};

ButtonSaveScroll.propTypes = {
  className: T.bool.isRequired
};

ButtonSaveScroll.defaultProps = {};

export default ButtonSaveScroll;
