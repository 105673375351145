import {createReducer} from 'redux-act';
import {
  FETCH_LOCATIONS_NOTIFICATIONS,
  FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS,
  SHOW_NOTIFICATION_MODAL,
  OPEN_OFFLINE_MODAL,
  OPEN_ONLINE_MODAL,
  SET_NETWORK_CONNECTION,
  SET_OFFLINE_TOAST_ID,
  SET_ONLINE_STATUS,
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  SET_PUBLISH_STATUS,
  STOP_GET_PUBLISH_STATUS_JOB,
  SET_NEW_TASK_ADDED,
  SET_FINISH_SPEED
} from './action';
import {
  TASK_TYPE_LAST_CUSTOM_DATA_UPDATE,
  TASK_TYPE_SETTINGS_DATA_UPDATE,
  UNPUBLISH_BANNER_MENU_TYPE,
  UNPUBLISH_BANNER_SETTINGS_TYPE
} from './constants';

const initialState = () => ({
  isOnlineModalOpen: false,
  isOfflineModalOpen: false,
  offlineToastId: null,
  beenOffline: false,
  isNetworkOnline: true,
  notificationModalData: {},
  consecutiveModalsStack: [],
  isNotificationModalOpen: false,
  showPublishProgress: false,
  unPublishBanner: {showBanner: false, type: UNPUBLISH_BANNER_MENU_TYPE},
  publishStatus: null,
  publishRunning: false,
  lastTaskTime: null,
  botIsBusy: false,
  finishSpeed: false,
  newTaskAdded: false
});

const findMax = (array) => {
  let max = array[0] || 0;

  array.forEach((el) => {
    max = max > el ? max : el;
  });

  return max;
};

const globalNotificationsReducer = createReducer(
  {
    [FETCH_LOCATIONS_NOTIFICATIONS]: (state) => ({
      ...state
    }),
    [FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS]: (state, payload) => {
      const stack = [];

      if (payload.DeliveryEnableIn && !payload.DeliveryOptions.Delivery) {
        stack.push({
          DeliveryEnableIn: payload.DeliveryEnableIn,
          Delivery: payload.DeliveryOptions.Delivery,
          EntityName: 'Delivery'
        });
      }
      if (payload.PickupEnableIn && !payload.DeliveryOptions.Pickup) {
        stack.push({
          PickupEnableIn: payload.PickupEnableIn,
          Pickup: payload.DeliveryOptions.Pickup,
          EntityName: 'Pickup'
        });
      }

      if (payload.ClosedManuallyUntilTime && payload.ClosedManually) {
        stack.push({
          ClosedManuallyUntilTime: payload.ClosedManuallyUntilTime,
          ClosedManually: payload.ClosedManually,
          EntityName: 'Store'
        });
      }
      stack.sort((a, b) => {
        return Number(a.DeliveryEnableIn) > Number(b.PickupEnableIn) ? 1 : -1;
      });

      return {
        ...state,
        notificationModalData: payload,
        consecutiveModalsStack: stack
      };
    },
    [SHOW_NOTIFICATION_MODAL]: (state, payload) => ({
      ...state,
      isNotificationModalOpen: Boolean(payload)
    }),
    [SET_NETWORK_CONNECTION]: (state, payload) => ({
      ...state,
      isNetworkOnline: payload
    }),
    [OPEN_ONLINE_MODAL]: (state, payload) => ({
      ...state,
      isOnlineModalOpen: payload,
      isOfflineModalOpen: false
    }),
    [SET_OFFLINE_TOAST_ID]: (state, payload) => ({
      ...state,
      offlineToastId: payload
    }),
    [SET_ONLINE_STATUS]: (state, payload) => ({
      ...state,
      beenOffline: payload
    }),
    [OPEN_OFFLINE_MODAL]: (state, payload) => ({
      ...state,
      isOfflineModalOpen: payload,
      isOnlineModalOpen: false
    }),
    [SHOW_PUBLISH_PROGRESSBAR]: (state, payload) => ({
      ...state,
      showPublishProgress: payload,
      publishRunning: payload
    }),
    [SHOW_UNPUBLISH_BANNER]: (state, payload) => {
      const hasSettingsType = payload.types.find(
        (type) => type === TASK_TYPE_SETTINGS_DATA_UPDATE
      );
      const hasMenuType = payload.types.find(
        (type) => type === TASK_TYPE_LAST_CUSTOM_DATA_UPDATE
      );

      return {
        ...state,
        unPublishBanner: {
          showBanner: payload.showBanner,
          type:
            hasSettingsType && !hasMenuType
              ? UNPUBLISH_BANNER_SETTINGS_TYPE
              : UNPUBLISH_BANNER_MENU_TYPE
        }
      };
    },
    [SET_PUBLISH_STATUS]: (state, payload) => {
      return {
        ...state,
        botIsBusy:
          payload.find((el) => !el.WithError) &&
          !payload.find((el) => el.InProcess),
        lastTaskTime: findMax(payload.map((el) => el.CreatedAt)),
        newTaskAdded:
          state.lastTaskTime === null
            ? false
            : findMax(payload.map((el) => el.CreatedAt)) > state.lastTaskTime,
        publishStatus: payload,
        publishRunning: true
      };
    },
    [STOP_GET_PUBLISH_STATUS_JOB]: (state) => {
      return {
        ...state,
        publishStatus: [],
        publishRunning: false,
        finishSpeed: false,
        lastTaskTime: null,
        newTaskAdded: false,
        botIsBusy: false
      };
    },
    [SET_NEW_TASK_ADDED]: (state, payload) => ({
      ...state,
      newTaskAdded: payload
    }),
    [SET_FINISH_SPEED]: (state, payload) => ({
      ...state,
      finishSpeed: payload
    })
  },
  initialState()
);

export default globalNotificationsReducer;
