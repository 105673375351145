import {createReducer} from 'redux-act';
import {
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_COUNTER_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  OPEN_CONFIRMATION_MODAL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS
} from './action';
import {updateObjectInArrayByKey} from '../../helpers/functionUtils/arrayUtils';

const initialState = () => ({
  data: [],
  counter: {},
  pagination: {},
  loading: false,
  isConfirmationModalOpen: false
});

const usersManagementReducer = createReducer(
  {
    [GET_USERS]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_USERS_SUCCESS]: (state, {docs, ...pagination}) => ({
      ...state,
      data: docs,
      pagination,
      loading: false
    }),
    [UPDATE_USER]: (state) => ({
      ...state,
      loading: true
    }),
    [UPDATE_USER_SUCCESS]: (state, payload) => ({
      ...state,
      data: updateObjectInArrayByKey(state.data, payload, '_id'),
      loading: false
    }),
    [CREATE_USER]: (state) => ({
      ...state,
      loading: true
    }),
    [CREATE_USER_SUCCESS]: (state, payload) => ({
      ...state,
      data: [...state.data, payload],
      pagination: {
        ...state.pagination
      },
      loading: false
    }),
    [CREATE_USER_FAILED]: (state) => ({
      ...state,
      data: [...state.data],
      loading: false
    }),
    [DELETE_USER]: (state) => ({
      ...state,
      loading: true
    }),
    [DELETE_USER_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(({_id}) => _id !== payload),
      pagination: {
        ...state.pagination
      },
      loading: false
    }),
    [GET_COUNTER_SUCCESS]: (state, payload) => ({
      ...state,
      counter: payload
    }),
    [OPEN_CONFIRMATION_MODAL]: (state, {open}) => ({
      ...state,
      isConfirmationModalOpen: open
    })
  },
  initialState()
);

export default usersManagementReducer;
