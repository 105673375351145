import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {DialogSubTitle} from 'components/DialogSubTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {DialogAction} from 'routes/App/dashboard/styles';
import {
  SET_REFUND_LOADING,
  UPDATE_REFUND_DATA
} from 'store/orders/orderRefund/action';
import Loading from 'components/shared/Utils/Loading';
import {
  RESTAURANT_REFUND_INITIATOR,
  RESTAURANT_REFUND_TYPE
} from 'helpers/constants/refundCalculator';

const CreateRefundModal = (props) => {
  const {
    open,
    onClose,
    orderId,
    values,
    refundLoading,
    redirectToOrderDetails
  } = props;
  const dispatch = useDispatch();
  const {params} = useRouteMatch();
  const dataForRefund = useSelector(
    (state) => state.refundReducer.dataForRefund
  );

  const submitData = () => {
    dispatch(
      UPDATE_REFUND_DATA({
        orderType: params.back,
        id: orderId,
        data: {
          items: dataForRefund.items,
          initiator: RESTAURANT_REFUND_INITIATOR[dataForRefund.reason.fault],
          reason:
            dataForRefund.reason.reason === 'Other'
              ? dataForRefund.reason.otherReasonText
              : dataForRefund.reason.reason,
          type: RESTAURANT_REFUND_TYPE[dataForRefund.refundType],
          ...(Array.isArray(dataForRefund?.priceAdjustmentIds) &&
            dataForRefund?.priceAdjustmentIds?.length > 0 && {
              priceAdjustmentIds: dataForRefund?.priceAdjustmentIds
            }),
          ...(dataForRefund?.tips && {
            tips: dataForRefund?.tips
          })
        }
      })
    );
  };
  return (
    <Dialog open={open}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom DialogTitleLeft'>
          Do you want to proceed with this <span>refund?</span>
        </DialogTitle>
        <DialogSubTitle className='Sm MarginBottom22'>
          {values.refundType === 'Full'
            ? 'Your customer will be refunded in full'
            : `Your customer will be refunded with $${parseFloat(
                dataForRefund?.totalAmountWithTax
              ).toFixed(2)} for ${dataForRefund?.itemsCount} items`}
        </DialogSubTitle>
        <DialogBody>
          <div className='RefundBlock'>
            <span>Reason:</span>
            {dataForRefund?.reason?.reason === 'Other' ? (
              <span>{dataForRefund?.reason?.otherReasonText}</span>
            ) : (
              <span>{dataForRefund?.reason?.reason}</span>
            )}
          </div>
          <div className='RefundBlock'>
            <span>Order number:</span>
            <span>{dataForRefund?.invoiceId}</span>
          </div>
          <div className='RefundBlock'>
            <span>Customer name:</span>
            <span>{dataForRefund?.userName}</span>
          </div>
          <DialogAction className='DialogActions'>
            {refundLoading ? (
              <Loading className='LoadingExportButton' />
            ) : (
              <>
                <ButtonOutline onClick={() => onClose()}>
                  <span>Cancel</span>
                </ButtonOutline>
                <ButtonPrimary
                  onClick={() => {
                    submitData();
                    dispatch(SET_REFUND_LOADING(true));
                  }}
                >
                  <span>Make Refund</span>
                </ButtonPrimary>
              </>
            )}
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

CreateRefundModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  refundLoading: PropTypes.bool.isRequired,
  redirectToOrderDetails: PropTypes.func.isRequired
};

export default CreateRefundModal;
