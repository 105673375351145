import * as Yup from 'yup';

const ModifierSchema = Yup.object().shape({
  Name: Yup.string()
    .max(750, 'Modifier name is too long')
    .required('Modifier name field is required!')
    .trim(),
  Price: Yup.number().required('Modifier price field is required!')
});

export default ModifierSchema;
