import styled from 'styled-components';
import ButtonNotification from '../../buttons/ButtonNotification/styles';
import ButtonInfo from '../../buttons/ButtonInfo/styles';
import SelectWrapper from '../../select/SelectComponentStyles';
import IconLocation from '../../../assets/icon-location.svg';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding: 14px 48px;
  min-height: 72px;
  background-color: #2f3d48;
  z-index: 11;
  overflow: hidden;

  @media (max-width: 1250px) {
    padding: 0 80px;
    min-height: 96px;
  }
  @media (max-width: 1000px) {
    padding: 0 40px;
  }
  @media (max-width: 767px) {
    padding: 12px 32px;
    min-height: auto;
  }
  @media (max-width: 599px) {
    padding: 15px 20px;
    overflow: inherit;
  }

  &.DesktopTransparent {
    background-color: transparent;
    @media (max-width: 1250px) {
      background-color: #2f3d48;
    }
  }
`;

export const ManagersHeader = styled(Header)`
  min-height: unset;
  background: unset;

  @media (max-width: 1250px) {
    min-height: 42px;
  }
`;

export const LocationSelectWrapper = styled(SelectWrapper)`
  width: 390px;
  margin-top: 0;
  padding-left: 0;
  padding-top: 0;
  .MuiSelect-select.MuiSelect-select {
    font-weight: 500;
    color: #929cb9;
    padding: 12px 0 11px 60px;
    @media (max-width: 767px) {
      padding-left: 40px;
    }
    @media (max-width: 599px) {
      padding-left: 55px;
    }
  }
  .MuiFormControl-root {
    position: relative;
    border-radius: 11px;
    border: 1px solid transparent;
    padding: 0;
    @media (max-width: 999px) {
      padding: 0 !important;
    }
    @media (max-width: 599px) {
      border-bottom: 1px solid #d5d8de;
    }

    &:hover {
      border: 1px solid #d5d8de;
      @media (max-width: 599px) {
        border: 1px solid transparent;
        border-bottom: 1px solid #d5d8de;
      }
    }
    &::before {
      position: absolute;
      top: 50%;
      margin-top: -11px;
      width: 20px;
      height: 20px;
      left: 22px;
      content: '';
      background-image: url(${IconLocation});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
      @media (max-width: 767px) {
        left: 14px;
      }
      @media (max-width: 599px) {
        left: 20px;
      }
    }
    @media (max-width: 999px) {
      padding: 7px 20px;
    }
    .MuiSelect-icon {
      @media (max-width: 767px) {
        right: 14px;
      }
    }
  }
`;

export const HeaderSearchInput = styled.div`
  width: 390px;
  margin-top: 0;
  padding-left: 0;
  padding-top: 0;
`;
export const HeaderLside = styled.div`
  display: none;
  align-items: center;
  position: relative;
  z-index: 2;

  @media (max-width: 1250px) {
    display: flex;
  }
  @media (max-width: 767px) {
    flex: 0 0 auto;
  }
  @media (max-width: 599px) {
    flex: 0 0 100%;
    justify-content: center;
    position: relative;
  }
`;

export const HeaderBurger = styled.div`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 24px;
  cursor: pointer;
  @media (max-width: 599px) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 4px;
    height: 4px;
    background-color: #fff;
    content: '';
    transition-property: transform;
    transition-duration: 0.3s;
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    border-radius: 4px;
    height: 4px;
    background-color: #fff;
    transition-property: opacity;
    transition-duration: 0.3s;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 4px;
    height: 4px;
    background-color: #fff;
    content: '';
    transition-property: transform;
    transition-duration: 0.3s;
  }

  &.isOpen {
    &::before {
      transform: rotate(45deg) translate(7px, 7px);
    }
    &::after {
      transform: rotate(-45deg) translate(7px, -7px);
    }
    span {
      opacity: 0;
    }
  }
`;

export const ManagersHeaderBurger = styled(HeaderBurger)`
  &::before {
    background-color: rgba(0, 0, 0, 0.87);
  }
  &::after {
    background-color: rgba(0, 0, 0, 0.87);
  }
  span {
    background-color: rgba(0, 0, 0, 0.87);
  }
`;

export const HeaderLogo = styled.a`
  display: inline-block;
  margin-left: 30px;
  font-size: 0;
  @media (max-width: 599px) {
    margin-left: 0;
  }

  img {
    width: 100%;
    max-width: 152px;
  }
`;

export const HeaderRside = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  flex: 1;
  justify-content: flex-end;
  @media (max-width: 767px) {
    flex: 1 1 auto;
    padding-left: 20px;
    * {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .MuiFormControl-root {
      width: 100%;
      max-width: 280px;
      @media (max-width: 599px) {
        max-width: 100%;
        background-color: #fff;
        border-radius: 0;
      }
    }
  }
  @media (max-width: 599px) {
    position: absolute;
    top: 56px;
    left: 0;
    width: 100%;
    padding: 0;
  }
`;

export const HeaderLocation = styled.div`
  display: block;
`;

export const HeaderActions = styled.div`
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const HeaderNotification = styled(ButtonNotification)`
  display: inline-block;
  margin-right: 7px;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    right: -7px;
    top: 50%;
    background-color: rgba(146, 156, 185, 0.2);
    width: 1px;
    height: 12px;
    margin-top: -6px;
  }
`;

export const HeaderInfo = styled(ButtonInfo)`
  display: inline-block;
  margin-left: 7px;
`;
