import React from 'react';

export const IconDelete = () => (
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 3V2.5C6 1.837 6.264 1.201 6.733 0.732C7.201 0.263 7.837 0 8.5 0H9.5C10.163 0 10.799 0.263 11.268 0.732C11.737 1.201 12 1.837 12 2.5V3H17C17.552 3 18 3.448 18 4C18 4.552 17.552 5 17 5H16V16C16 18.209 14.21 20 12 20C10.168 20 7.833 20 6 20C3.791 20 2 18.209 2 16V5H1C0.448 5 0 4.552 0 4C0 3.448 0.448 3 1 3H6ZM14 5H4V16C4 17.105 4.896 18 6 18H12C13.105 18 14 17.105 14 16V5ZM10 8V15C10 15.552 10.448 16 11 16C11.552 16 12 15.552 12 15V8C12 7.448 11.552 7 11 7C10.448 7 10 7.448 10 8ZM6 8V15C6 15.552 6.448 16 7 16C7.552 16 8 15.552 8 15V8C8 7.448 7.552 7 7 7C6.448 7 6 7.448 6 8ZM10 3V2.5C10 2.367 9.948 2.24 9.854 2.146C9.76 2.053 9.633 2 9.5 2C9.173 2 8.827 2 8.5 2C8.368 2 8.241 2.053 8.147 2.146C8.053 2.24 8 2.367 8 2.5V3H10Z'
      fill='#FF2D38'
    />
  </svg>
);

export default IconDelete;
