import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import {Tab} from '@material-ui/core';
import {DashboardTabs} from '../../../dashboard/styles';

const FeedbackTabs = ({value, handleChange}) => {
  const {
    AllСompletedSurveysCount,
    DeliverySurveysCount,
    PickupSurveysCount
  } = useSelector((state) => state.feedbackReducer.data);

  return (
    <DashboardTabs className='FeedbackTabs'>
      <Tabs onChange={handleChange} value={value}>
        <Tab
          label={
            <>
              All<span>({AllСompletedSurveysCount})</span>
            </>
          }
        />
        <Tab
          label={
            <>
              Delivery<span>({DeliverySurveysCount})</span>
            </>
          }
        />
        <Tab
          label={
            <>
              Pickup<span>({PickupSurveysCount})</span>
            </>
          }
        />
      </Tabs>
    </DashboardTabs>
  );
};

export default FeedbackTabs;

FeedbackTabs.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};
