import React from 'react';
import T from 'prop-types';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';
import {AutoCompleteFieldWrapper} from './AutocompleteStyles';

const AutocompleteField = ({
  options,
  value,
  onChange,
  name,
  label,
  optionLabelName,
  ...props
}) => {
  return (
    <AutoCompleteFieldWrapper>
      <Autocomplete
        name={name}
        onChange={onChange}
        value={value}
        options={options}
        getOptionLabel={(option) => {
          return option[optionLabelName] || option || '';
        }}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputLabelProps={{shrink: false}}
            variant='outlined'
          />
        )}
      />
    </AutoCompleteFieldWrapper>
  );
};

AutocompleteField.propTypes = {
  options: T.array.isRequired,
  value: T.any,
  onChange: T.func.isRequired,
  name: T.string,
  optionLabelName: T.string,
  label: T.string,
  multiple: T.bool
};

AutocompleteField.defaultProps = {
  value: '',
  name: '',
  label: '',
  optionLabelName: '',
  multiple: false
};

export default AutocompleteField;
