import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import SelectAllButton from './SelectAllButton';
import OutOffStockButton from './OutOffStockButton';
import InStockButton from './InStockButton';
import HideButton from './HideButton';
import ShowButton from './ShowButton';
import DeleteButton from './DeleteButton';
import {ButtonsPanelRow} from '../styles';

export default function ButtonsPanel() {
  const activeTab = useSelector((store) => store.menuEditorReducer.activeTab);
  const selectedCategories = useSelector(
    (store) => store.menuEditorReducer.selectedCategories
  );
  const selectedItems = useSelector(
    (store) => store.menuEditorReducer.selectedItems
  );
  const selectedGroups = useSelector(
    (store) => store.menuEditorReducer.selectedGroups
  );
  const selectedModifiers = useSelector(
    (store) => store.menuEditorReducer.selectedModifiers
  );

  const activeTreeCategories = useSelector(
    (state) => state.menuEditorReducer.activeSectionsDirection
  );
  const activeTreeItems = useSelector(
    (state) => state.menuEditorReducer.activeItemsDirection
  );
  const activeTreeModifiers = useSelector(
    (state) => state.menuEditorReducer.activeModifiersDirection
  );
  const activeTreeGroup = useSelector(
    (state) => state.menuEditorReducer.activeGroupsDirection
  );

  const getDisableStatus = (_activeTab) => {
    if (_activeTab === 'CATEGORY' && !selectedCategories) {
      return true;
    }

    if (_activeTab === 'ITEM' && !selectedItems) {
      return true;
    }

    if (_activeTab === 'GROUP' && !selectedGroups) {
      return true;
    }

    if (_activeTab === 'MODIFIER' && !selectedModifiers) {
      return true;
    }

    if (
      _activeTab === 'OUT_OFF_STOCK' &&
      !selectedItems &&
      !selectedModifiers
    ) {
      return true;
    }

    if (
      _activeTab === 'HIDDEN' &&
      !selectedCategories &&
      !selectedItems &&
      !selectedModifiers
    ) {
      return true;
    }

    return false;
  };

  const isDisabledControlButtons = getDisableStatus(activeTab);

  return (
    <ButtonsPanelRow>
      <SelectAllButton activeTab={activeTab} />
      {['ITEM', 'MODIFIER'].includes(activeTab) &&
        !isDisabledControlButtons && (
          <OutOffStockButton
            activeTab={activeTab}
            selectedItems={selectedItems}
            selectedModifiers={selectedModifiers}
          />
        )}
      {['CATEGORY', 'ITEM', 'MODIFIER'].includes(activeTab) &&
        !isDisabledControlButtons && (
          <HideButton
            activeTab={activeTab}
            selectedCategories={selectedCategories}
            selectedItems={selectedItems}
            selectedModifiers={selectedModifiers}
          />
        )}
      {activeTab === 'OUT_OFF_STOCK' && !isDisabledControlButtons && (
        <InStockButton
          activeTab={activeTab}
          selectedItems={selectedItems}
          selectedModifiers={selectedModifiers}
        />
      )}
      {activeTab === 'HIDDEN' && !isDisabledControlButtons && (
        <ShowButton
          activeTab={activeTab}
          selectedCategories={selectedCategories}
          selectedItems={selectedItems}
          selectedModifiers={selectedModifiers}
        />
      )}
      {!isDisabledControlButtons && (
        <DeleteButton
          activeTab={activeTab}
          selectedCategories={selectedCategories}
          selectedItems={selectedItems}
          selectedGroups={selectedGroups}
          selectedModifiers={selectedModifiers}
        />
      )}
    </ButtonsPanelRow>
  );
}
