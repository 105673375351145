import {createAction} from 'redux-act';

export const MARKETING_INTEGRATIONS_GET_INFO = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_GET_INFO'
);
export const MARKETING_INTEGRATIONS_GET_INFO_SUCCESS = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_GET_INFO_SUCCESS'
);
export const MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE'
);
export const MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE'
);
export const MARKETING_INTEGRATIONS_ENABLE = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_ENABLE'
);
export const MARKETING_INTEGRATIONS_DISABLE = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_DISABLE'
);
export const MARKETING_INTEGRATIONS_DISABLE_SUCCESS = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_DISABLE_SUCCESS'
);

export const MARKETING_INTEGRATIONS_SET_LOADING = createAction(
  'marketingIntegrations/MARKETING_INTEGRATIONS_SET_LOADING'
);
