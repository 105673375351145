import {GET} from '../constants/API_CONSTANTS';
import {
  API_GET_TOTAL_CUSTOMERS,
  API_GET_SALES_PER_HOUR,
  API_GET_TOTAL_SALES,
  API_GET_SALES_PER_ITEM
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getCustomersCountApi = async (startDate, endDate) => {
  return request(GET, API_GET_TOTAL_CUSTOMERS(startDate, endDate));
};

export const getSalesCountApi = async (startDate, endDate) => {
  return request(GET, API_GET_TOTAL_SALES(startDate, endDate));
};

export const getSalesPerHourApi = async (timezone, startDate, endDate) => {
  return request(GET, API_GET_SALES_PER_HOUR(timezone, startDate, endDate));
};

export const getSalesPerItemApi = async (startDate, endDate) => {
  return request(GET, API_GET_SALES_PER_ITEM(startDate, endDate));
};
