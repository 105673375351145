import {createReducer} from 'redux-act';
import {
  GET_ORDERS_RTB_SUCCESS,
  CLEAR_ORDERS_RTB,
  RUN_ORDER_RTB_JOB,
  OPEN_ACTIONS_MADE_MODAL,
  RDB_REMOVE_ORDER_MODAL,
  OPEN_ADDITION_INFO_MODAL_SUCCESS
} from 'store/realTimeBoardV2/action';
import {dateToSortAsc} from 'routes/App/Managers/realTimeBoard/helpers';
import {
  BOARD_COLOR_PRIORITY,
  BOARD_STATUS,
  BOARD_V2_TRACKING_LINKS_TYPES
} from 'helpers/constants/adminRealTImeBoard';
import moment from 'moment/moment';

const initialState = () => ({
  orders: [],
  loading: false,
  lastUpdateTime: null,
  openActionsModal: false,
  currentOrderId: null,
  openAdditionInfoModal: false,
  additionInfo: {},
  orderActions: [],
  removeOrderModal: {
    open: false,
    id: null
  }
});

const checkIsFutureOrder = (pickup_time) => {
  const currentDateUTC = moment().utc();
  return moment(pickup_time).diff(currentDateUTC, 'minutes') > 60;
};

const lastArrayIndex = (arr = [], order) => {
  const filterArr = arr?.filter(
    (item) =>
      order?.customerEmail === item?.customerEmail &&
      order?.location?.name === item?.location?.name &&
      order?.customerName === item?.customerName
  );
  let index = null;
  if (filterArr.length) {
    index = arr.findIndex(
      (el) => el.id === filterArr[filterArr.length - 1]?.id
    );
  }
  return index;
};

const sortByColors = (a, b) => {
  if (Number(a.sortArray.join('')) > Number(b.sortArray.join(''))) {
    return -1;
  }
  if (Number(a.sortArray.join('')) < Number(b.sortArray.join(''))) {
    return 1;
  }
  return 0;
};

const checkLastSameOrder = (currentOrder, orders = []) => {
  const filterArr = orders.filter(
    (item) => item.parentId === currentOrder.parentId
  );
  // because unshift child order in getOrdersWithParents
  return filterArr[0] === currentOrder;
};

const getOrdersWithParents = (orders) => {
  const ordersWithParents = [];

  orders.forEach((order) => {
    if (
      order &&
      order.parentId &&
      !ordersWithParents.find((el) => el.id === order.id)
    ) {
      const parentOrder = orders.find((el) => el.id === order.parentId);
      if (parentOrder) {
        ordersWithParents.unshift({
          ...order,
          isSameOrder: true,
          colorPriority:
            parentOrder && !checkIsFutureOrder(parentOrder?.pickup?.eta)
              ? parentOrder?.colorPriority
              : order?.colorPriority
        });
      }
    }
  });
  ordersWithParents.forEach((order, index) => {
    if (checkLastSameOrder(order, ordersWithParents)) {
      ordersWithParents[index] = {
        ...ordersWithParents[index],
        isSameOrder: true,
        isLastOrder: true
      };
    }
  });
  return ordersWithParents;
};

const cutChildOrderFromAList = (orders, orderToMove) => {
  const indexFrom = orders.indexOf(
    orders.find((order) => order.id === orderToMove?.id)
  );

  orders.splice(indexFrom, 1);

  return orders;
};

const pasteAnOrderUnderItsParent = (orders, orderToMove) => {
  const indexTo =
    orders.indexOf(orders.find((order) => order.id === orderToMove.parentId)) +
    1;
  orders.splice(indexTo, 0, orderToMove);
  // update parent order order
  orders.splice(indexTo - 1, 1, {
    ...orders[indexTo - 1],
    isFirstOrder: true,
    isSameOrder: true,
    colorPriority: !checkIsFutureOrder(orderToMove?.pickup?.eta)
      ? orderToMove.colorPriority
      : orders[indexTo - 1].colorPriority
  });
  return orders;
};

const sortByDeliveryId = (orders) => {
  const ordersWithParents = getOrdersWithParents(orders);

  ordersWithParents.forEach((orderToMove) => {
    cutChildOrderFromAList(orders, orderToMove);
    pasteAnOrderUnderItsParent(orders, orderToMove);
  });
  return orders;
};

const sortOrders = (orders) => {
  const resultOrders = [];
  orders.forEach((order) => {
    const lastIndex = lastArrayIndex(resultOrders, order);
    if (lastIndex !== null) {
      resultOrders.splice(lastIndex + 1, 0, order);
    } else {
      resultOrders.push(order);
    }
  });

  const sortedByColors = resultOrders.sort((a, b) => sortByColors(a, b));

  return sortByDeliveryId(sortedByColors);
};

const realTimeBoardV2Reducer = createReducer(
  {
    [RUN_ORDER_RTB_JOB]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_ORDERS_RTB_SUCCESS]: (state, payload) => {
      const filteredOrders = [];

      payload?.forEach((order) => {
        let priority = order.riskLevel?.value || BOARD_COLOR_PRIORITY.GREEN;

        if (checkIsFutureOrder(order.pickup?.eta)) {
          priority = BOARD_COLOR_PRIORITY.WHITE;
        }

        filteredOrders.push({
          ...order,
          id: order.id,
          status: checkIsFutureOrder(order.pickup?.eta)
            ? {type: 'Future'}
            : order.status,
          restaurantName: order.location?.name,
          restaurantTier: order.location?.tier,
          restaurantTimezone: order.location?.timezone?.offset,
          restaurantFirst10Orders: order?.location?.servedFirst10Orders,
          customerName: order?.customer?.name,
          customerPhone: order?.customer?.phone,
          courierDistanceToRestaurant: order.delivery?.distanceMeters,
          colorPriority: priority,
          sortArray: [priority, dateToSortAsc(order.pickup?.deadline)],
          ...(order.trackingLinks && {
            userTrackingLink:
              order.trackingLinks.find(
                (el) => el.type === BOARD_V2_TRACKING_LINKS_TYPES.USER
              )?.url || null
          }),
          ...(order.trackingLinks && {
            restaurantTrackingLink:
              order.trackingLinks.find(
                (el) => el.type === BOARD_V2_TRACKING_LINKS_TYPES.RESTAURANT
              )?.url || null
          }),
          ...(order.trackingLinks && {
            oldDispatchProviderTrackingLink:
              order.trackingLinks.find(
                (el) => el.type === BOARD_V2_TRACKING_LINKS_TYPES.OLD_PROVIDER
              )?.url || null
          }),
          ...(order.trackingLinks && {
            newDispatchProviderTrackingLink:
              order.trackingLinks.find(
                (el) => el.type === BOARD_V2_TRACKING_LINKS_TYPES.NEW_PROVIDER
              )?.url || null
          })
        });
      });
      return {
        ...state,
        orders: sortOrders(filteredOrders),
        loading: false,
        lastUpdateTime: new Date()
      };
    },
    [CLEAR_ORDERS_RTB]: (state) => ({
      ...state,
      orders: [],
      lastUpdateTime: null
    }),
    [OPEN_ACTIONS_MADE_MODAL]: (state, payload) => ({
      ...state,
      openActionsModal: payload.open,
      currentOrderId: payload.id
    }),
    [OPEN_ADDITION_INFO_MODAL_SUCCESS]: (state, payload) => {
      return {
        ...state,
        openAdditionInfoModal: payload.open,
        additionInfo: payload.data
          ? {
              ...payload.data,
              id: payload.data.id,
              status: checkIsFutureOrder(payload.data.pickup?.eta)
                ? {type: 'Future Order'}
                : payload.data.status,
              restaurantName: payload.data.location?.name,
              restaurantTier: payload.data.location?.tier,
              restaurantTimezone: payload.data.location?.timezone?.offset,
              restaurantFirst10Orders:
                payload.data?.location?.servedFirst10Orders,
              customerName: payload.data.customer?.name,
              customerPhone: payload.data.customer?.phone,
              courierDistanceToRestaurant:
                payload.data.delivery?.distanceMeters,
              ...(payload.data.trackingLinks && {
                oldDispatchProviderTrackingLink:
                  payload.data.trackingLinks.find(
                    (el) =>
                      el.type === BOARD_V2_TRACKING_LINKS_TYPES.OLD_PROVIDER
                  )?.url || null
              }),
              ...(payload.data.trackingLinks && {
                newDispatchProviderTrackingLink:
                  payload.data.trackingLinks.find(
                    (el) =>
                      el.type === BOARD_V2_TRACKING_LINKS_TYPES.NEW_PROVIDER
                  )?.url || null
              })
            }
          : null
      };
    },

    [RDB_REMOVE_ORDER_MODAL]: (state, {open, id}) => ({
      ...state,
      removeOrderModal: {
        open,
        id
      }
    })
  },
  initialState()
);

export default realTimeBoardV2Reducer;
