import {GET, POST} from '../constants/API_CONSTANTS';
import {
  GET_ORDERS_RTB_V2,
  GET_ORDER_RTB_V2,
  POST_ORDER_RTB_V2_ACTION,
  REMOVE_ORDER_RTB_V2
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getOrdersRTBV2Api = async () => {
  return request(POST, GET_ORDERS_RTB_V2());
};
export const getOrderRTBV2Api = async (id) => {
  return request(GET, GET_ORDER_RTB_V2(id));
};

export const sendOrderActionsV2Api = async (data, id) => {
  return request(POST, POST_ORDER_RTB_V2_ACTION(id), {data});
};

export const removeOrderV2Api = async (id) => {
  return request(POST, REMOVE_ORDER_RTB_V2(id));
};
