import styled from 'styled-components';
import {DashboardFields} from '../../../dashboard/styles';

export const OrderForm = styled(DashboardFields)`
  max-width: 600px;
  width: 100%;
`;

export const OrderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  button {
    max-width: 268px;
    width: 100%;
    margin: 0 10px;
  }
`;
