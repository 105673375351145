import {createAction} from 'redux-act';

export const SET_ACTIVATE_SURVEY = createAction('feedback/SET_ACTIVATE_SURVEY');
export const GET_FEEDBACK_DATA = createAction('feedback/GET_FEEDBACK_DATA');
export const GET_FEEDBACK_DATA_SUCCESS = createAction(
  'feedback/GET_FEEDBACK_DATA_SUCCESS'
);
export const SET_LOADING = createAction('feedback/SET_LOADING');
export const CLEAR_FEEDBACK_DATA = createAction('feedback/CLEAR_FEEDBACK_DATA');
export const SET_FILTER = createAction('feedback/SET_FILTER');
