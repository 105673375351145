import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {
  ADD_NEW_MODAL,
  CREATE_CATEGORY_MODAL,
  CREATE_ITEM_MODAL,
  CREATE_MODIFIER_GROUP_MODAL,
  CREATE_MODIFIER_MODAL
} from '../../../../store/menuEditorModals/action';
import {
  DialogAction,
  DialogBody,
  DialogItem,
  DialogItemIcon,
  DialogItems
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import IconTypeCategory from '../../../../assets/IconCategory';
import IconTypeItem from '../../../../assets/IconItem';
import IconTypeModifierGroup from '../../../../assets/IconModifierGroup';
import IconTypeModifier from '../../../../assets/IconModifier';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';

const AddNewModal = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [isActive, setActive] = useState('');

  //   const formik = useFormik({
  //     initialValues: {
  //       name: '',
  //       notes: ''
  //     },
  //     onSubmit: (values) => {
  //       alert(JSON.stringify(values, null, 2));
  //     }
  //   });

  const toggleClass = (buttonName) => {
    setActive(buttonName);
  };

  const openCreateModal = () => {
    if (isActive === 'Category') {
      dispatch(CREATE_CATEGORY_MODAL({open: true, isSubmodal: false}));
    }
    if (isActive === 'Item') {
      dispatch(
        CREATE_ITEM_MODAL({open: true, isSubmodal: false, entityId: ''})
      );
    }
    if (isActive === 'ModifierGroup') {
      dispatch(CREATE_MODIFIER_GROUP_MODAL({open: true, isSubmodal: false}));
    }
    if (isActive === 'Modifier') {
      dispatch(CREATE_MODIFIER_MODAL({open: true, isSubmodal: false}));
    }
    setActive('');
    dispatch(ADD_NEW_MODAL({open: false}));
  };

  return (
    <Dialog
      onClose={() => dispatch(ADD_NEW_MODAL({open: false}))}
      open={store.menuEditorModalsReducer.isAddNewModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => dispatch(ADD_NEW_MODAL({open: false}))} />
        <DialogTitle>What would you like to add?</DialogTitle>
        <DialogBody>
          {/* <DialogHint className='DialogHintCenter'>
            <span>
              It is a long established fact that a reader will be distracted
            </span>
          </DialogHint> */}
          <DialogItems className='DialogItemsNewModal'>
            <DialogItem
              className={isActive === 'Category' ? 'isActive' : null}
              onClick={() => toggleClass('Category')}
            >
              <DialogItemIcon>
                <IconTypeCategory />
              </DialogItemIcon>
              <span>A Category</span>
            </DialogItem>
            <DialogItem
              className={isActive === 'Item' ? 'isActive' : null}
              onClick={() => toggleClass('Item')}
            >
              <DialogItemIcon>
                <IconTypeItem />
              </DialogItemIcon>
              <span>An Item</span>
            </DialogItem>
            <DialogItem
              className={isActive === 'ModifierGroup' ? 'isActive' : null}
              onClick={() => toggleClass('ModifierGroup')}
            >
              <DialogItemIcon>
                <IconTypeModifierGroup />
              </DialogItemIcon>
              <span>A Modifier Group</span>
            </DialogItem>
            <DialogItem
              className={isActive === 'Modifier' ? 'isActive' : null}
              onClick={() => toggleClass('Modifier')}
            >
              <DialogItemIcon>
                <IconTypeModifier />
              </DialogItemIcon>
              <span>A Modifier</span>
            </DialogItem>
          </DialogItems>
          <DialogAction>
            <ButtonPrimary onClick={() => openCreateModal()}>
              <span>Apply</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default AddNewModal;
