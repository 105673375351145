import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {DashboardHeader} from '../dashboard/styles';
import Headline from '../../../components/Headline/styles';
import {ButtonOutline} from '../../../components/buttons';
import {
  CONNECT_UBEREATS_STORE,
  FINISH_BYOC_CONFIG,
  HANDLE_CONNECT_STORE_MODAL,
  SEND_SLACK_NOTIF,
  HANDLE_NOT_STORE_MODAL
} from '../../../store/uberEats/actions';
import ByocFirst from './steps/ByocFirst';
import ByocSecond from './steps/ByocSecond';
import ByocThird from './steps/ByocThird';
import ByocFourth from './steps/ByocFourth';
import {
  ByocContainer,
  ByocContent,
  ByocSidebar,
  RedSpan,
  StepIndicator,
  StepIndicators,
  WizardActions,
  WizardPaper
} from './styles';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import Body3 from '../../../components/typography/Body3';
import history from '../../../history';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem
} from '../../../helpers/sessionStorage';
import StoreSelectModal from '../uberEats/modals/StoreSelectModal';
import NotStoresModal from '../uberEats/modals/NotStoresModal';

export const BYOC_STEP_STORAGE_KEY = 'BYOC_STEP_STORAGE_KEY';

const BYOCWizard = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const actionsContainerRef = useRef();

  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );

  const locationId = useSelector((state) => state.user?.activeLocation?._id);

  useEffect(() => {
    const currentStep = getSessionStorageItem(BYOC_STEP_STORAGE_KEY);

    if (BYOCconnected) {
      history.push('/uber-eats');
    } else if (!currentStep) {
      setSessionStorageItem(BYOC_STEP_STORAGE_KEY, currentPage);
    } else {
      setCurrentPage(getSessionStorageItem(BYOC_STEP_STORAGE_KEY));
    }
  }, []);

  const pageBack = () => {
    const page = currentPage - 1;

    if (currentPage !== 0) {
      setSessionStorageItem(BYOC_STEP_STORAGE_KEY, page);
      setCurrentPage(page);
    } else {
      removeSessionStorageItem(BYOC_STEP_STORAGE_KEY);
      history.push('/uber-eats');
    }
  };

  const pageNext = () => {
    const page = currentPage + 1;
    setSessionStorageItem(BYOC_STEP_STORAGE_KEY, page);
    setCurrentPage(page);
  };

  const sendSlackNotif = (availabilityBridges) => {
    dispatch(SEND_SLACK_NOTIF(availabilityBridges));
  };

  const pages = [
    <ByocFirst
      actionsRef={actionsContainerRef.current}
      goNextAction={pageNext}
    />,
    <ByocSecond
      actionsRef={actionsContainerRef.current}
      goNextAction={pageNext}
    />,
    <ByocThird
      actionsRef={actionsContainerRef.current}
      sendSlackNotif={sendSlackNotif}
      goNextAction={pageNext}
    />,
    <ByocFourth
      actionsRef={actionsContainerRef.current}
      goNextAction={() => {
        if (BYOCconfigured) {
          history.push('/uber-eats');
          return;
        }
        dispatch(
          CONNECT_UBEREATS_STORE({
            postCallback: () => {
              removeSessionStorageItem(BYOC_STEP_STORAGE_KEY);
              history.push('/uber-eats');
            },
            requestBody: {locationId, isByocEnabled: true, metadata: []}
          })
        );
      }}
    />
  ];

  const finishBYOCConfig = () => {
    dispatch(FINISH_BYOC_CONFIG());
  };

  const calcStepStyle = (step) => {
    return cn({
      passed: step < currentPage,
      current: step === currentPage
    });
  };

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline>UberEats</Headline>
        <ByocContainer>
          <ByocContent>
            <WizardPaper>
              {pages[currentPage]}
              <WizardActions ref={actionsContainerRef}>
                <ButtonOutline
                  className='ButtonLg ButtonNav'
                  onClick={pageBack}
                >
                  Back
                </ButtonOutline>
              </WizardActions>
            </WizardPaper>
          </ByocContent>
          <ByocSidebar>
            <Body3>
              Just{' '}
              <RedSpan>
                <b>4 small steps</b>
              </RedSpan>{' '}
              to start saving
            </Body3>
            <StepIndicators>
              <StepIndicator className={calcStepStyle(0)}>
                Process Overview
              </StepIndicator>
              <StepIndicator className={calcStepStyle(1)}>
                Call UberEats
              </StepIndicator>
              <StepIndicator className={calcStepStyle(2)}>
                Answer Email
              </StepIndicator>
              <StepIndicator className={calcStepStyle(3)}>
                Sign Contract
              </StepIndicator>
            </StepIndicators>
          </ByocSidebar>
        </ByocContainer>
      </DashboardHeader>
    </DashboardContent>
  );
};

export default BYOCWizard;
