import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {UnderlineButton, UnPublishContainer} from '../styles';
import Body3 from '../../typography/Body3';
import {IconUnPublish} from '../../../assets/IconUnPublish';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../store/global/globalNotifications/action';
import {
  CLEAR_RECURSION_RELATIONS,
  FETCH_MENU_RECURSION_RELATIONS,
  PUBLISH_MENU
} from '../../../store/menuEditor/action';
import RecursionRelationModal from '../../../routes/App/menuEditor/HeadLine/RecursionRelationModal';
import {
  UNPUBLISH_BANNER_MENU_TYPE,
  UNPUBLISH_BANNER_SETTINGS_TYPE
} from '../../../store/global/globalNotifications/constants';

const UnPublish = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const menuConfigId = useSelector(
    (state) => state.user.activeLocation?.MenuConfigId
  );
  const unPublishBannerType = useSelector(
    (state) => state.globalNotificationsReducer.unPublishBanner.type
  );

  const [confirmBox, setConfirmBox] = useState(null);
  const [, setInfoBox] = useState(null);
  const [recursionBox, setRecursionBox] = useState(null);
  const [isRecursionChecking, setIsRecursionChecking] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    return () => {
      setIsSaving(false);
    };
  }, []);

  const onMenuPublishResult = (data) => {
    setConfirmBox(null);

    if (!data) {
      return;
    }

    const {message} = data;

    setInfoBox({
      message,
      btnOkTitle: 'OK',
      onOk: () => setInfoBox(null)
    });
  };

  const onPublishMenu = () => {
    dispatch(
      PUBLISH_MENU({
        menuConfigId,
        preCallback: () => {
          setConfirmBox({...confirmBox, isLoading: true});
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallback: onMenuPublishResult,
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  const onCheckRecursionInMenu = () => {
    dispatch(
      FETCH_MENU_RECURSION_RELATIONS({
        preCallback: () => {
          setIsRecursionChecking(true);
        },
        postCallback: (relations) => {
          if (relations) {
            setRecursionBox({
              message:
                'You have an invalid relations between the items below. Please solve them or clear the relations to be able to continue.',
              relations,
              onOk: () => {
                dispatch(
                  CLEAR_RECURSION_RELATIONS({
                    relations,
                    preCallback: () => {
                      setRecursionBox({...recursionBox, isLoading: true});
                    },
                    // eslint-disable-next-line no-shadow
                    postCallback: (relations) => {
                      setRecursionBox({
                        message:
                          'The invalid relations has been removed successfully. Please click on Publish button to continue.',
                        relations,
                        onOk: () => {
                          setRecursionBox(null);
                          onPublishMenu();
                        },
                        onCancel: () => setRecursionBox(null)
                      });
                    }
                  })
                );
              },
              onCancel: () => setRecursionBox(null)
            });
          } else {
            onPublishMenu();
          }

          setIsRecursionChecking(false);
        }
      })
    );
  };

  const onMenuPublishClick = () =>
    isRecursionChecking ? undefined : onCheckRecursionInMenu();

  const onSettingsPublishClick = () => {
    if (location.pathname !== '/store-settings') {
      history.push('/store-settings');
    } else {
      setIsSaving(true);
    }
  };

  return (
    <UnPublishContainer>
      <IconUnPublish />
      <Body3 className='White'>
        {unPublishBannerType === UNPUBLISH_BANNER_MENU_TYPE
          ? 'You have unpublished changes, your menu was not updated.'
          : 'You have unsaved store settings changes, your store settings were not updated.'}
        <UnderlineButton
          type={
            location.pathname === '/store-settings' &&
            unPublishBannerType === UNPUBLISH_BANNER_SETTINGS_TYPE
              ? 'submit'
              : 'button'
          }
          form='store-settings-form'
          className={
            isRecursionChecking || isSaving
              ? 'White Lg noClickEvent'
              : 'White Lg'
          }
          onClick={() =>
            unPublishBannerType === UNPUBLISH_BANNER_MENU_TYPE
              ? onMenuPublishClick()
              : onSettingsPublishClick()
          }
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {unPublishBannerType === UNPUBLISH_BANNER_MENU_TYPE
            ? isRecursionChecking
              ? 'Checking...'
              : 'Click to publish'
            : isSaving
            ? 'Saving...'
            : 'Click to save'}
        </UnderlineButton>
      </Body3>

      {!!recursionBox && unPublishBannerType === UNPUBLISH_BANNER_MENU_TYPE && (
        <RecursionRelationModal
          isOpen
          isLoading={!!recursionBox.isLoading}
          relations={recursionBox.relations}
          message={recursionBox.message}
          btnOkTitle={recursionBox.btnOkTitle}
          btnCancelTitle={recursionBox.btnCancelTitle}
          onOk={recursionBox.onOk}
          onCancel={recursionBox.onCancel}
        />
      )}
    </UnPublishContainer>
  );
};

UnPublish.propTypes = {};

UnPublish.defaultProps = {};

export default UnPublish;
