import React from 'react';
import T from 'prop-types';
import {TextField} from '@material-ui/core';
import {DesktopDatePicker, MobileDatePicker} from '@material-ui/pickers';
import DatepickerContainer from './styles';

/* eslint-disable */

const SingleDatepickerDrop = ({onChange, onAccept, value, ...props}) => {
  return (
    <DatepickerContainer>
      <MobileDatePicker
        value={value}
        disablePast
        minDate={new Date()}
        maxDate={new Date().setFullYear(new Date().getFullYear() + 10)}
        onChange={onChange}
        onAccept={onAccept}
        {...props}
        renderInput={(props) => <TextField {...props} />}
      />
    </DatepickerContainer>
  );
};

SingleDatepickerDrop.propTypes = {
  onChange: T.func,
  onAccept: T.func,
  value: T.array
};

SingleDatepickerDrop.defaultProps = {
  onChange: () => {},
  onAccept: () => {}
};

export default SingleDatepickerDrop;
