import React from 'react';

const IconSidebarAnalytics = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.5714 14.4048C14.5385 12.2979 13.882 10.3821 12.7147 8.995C11.5542 7.61639 9.98144 6.85718 8.28564 6.85718C6.58985 6.85718 5.01708 7.61639 3.85658 8.995C2.6904 10.3806 2.03429 12.2937 2 14.398C2.65943 14.7283 5.38823 16 8.28564 16C11.4187 16 13.9599 14.7357 14.5714 14.4048Z'
      fill='#929CB9'
    />
    <path
      d='M8.34208 6.28571C10.0778 6.28571 11.4849 4.87861 11.4849 3.14286C11.4849 1.4071 10.0778 0 8.34208 0C6.60632 0 5.19922 1.4071 5.19922 3.14286C5.19922 4.87861 6.60632 6.28571 8.34208 6.28571Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarAnalytics;
