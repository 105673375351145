import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toast} from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DialogAction, DialogBody} from '../../../dashboard/styles';
import {ButtonOutline} from '../../../../../components/buttons';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogField from '../../../../../components/DialogField/styles';
import FieldInput from '../../../../../components/FieldInput';
import DialogFields from '../../../../../components/DialogFields/styles';
import {CLOUD_PRINT_URL} from '../../../../../helpers/constants/orders';
import CloudPrintSchema from '../../../../../helpers/services/formValidationSchemas/CloudPrintSchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {
  CLEAR_CLOUD_PRINT_CONFIGURATIONS,
  GET_CLOUD_PRINT_CONFIGURATIONS,
  UPDATE_CLOUD_PRINT_CONFIGURATIONS
} from '../../../../../store/orders/orderList/action';
import Loading from '../../../../../components/shared/Utils/Loading';
import ButtonInfo from '../../../../../components/buttons/ButtonInfo/styles';
import {AuthLink} from '../../../../Auth/styles';
import FieldPassword from '../../../../../components/FieldPassword';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';

export default function CloudPrint({onClose}) {
  const dispatch = useDispatch();
  const cloudPrintConfigurations = useSelector(
    (state) => state.ordersHistoryReducer.cloudPrintConfigurations
  );
  const isLoading = useSelector(
    (state) => state.ordersHistoryReducer.cloudPrintLoading
  );

  useEffect(() => {
    dispatch(GET_CLOUD_PRINT_CONFIGURATIONS());
    return () => dispatch(CLEAR_CLOUD_PRINT_CONFIGURATIONS());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StarPrintUser: cloudPrintConfigurations?.StarPrintUser || '',
      StarPrintPassword: cloudPrintConfigurations?.StarPrintPassword || '',
      StarPrintUserCopies: cloudPrintConfigurations?.StarPrintUserCopies || 1
    },
    validationSchema: CloudPrintSchema,
    onSubmit: (values) => {
      dispatch(
        UPDATE_CLOUD_PRINT_CONFIGURATIONS({
          values,
          onSuccess: () => onClose()
        })
      );
    }
  });

  return (
    <Dialog open onClose={onClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        {!isLoading ? (
          <>
            <DialogClose onClick={onClose} />
            <DialogTitle className='marginBottomXl verticalCenter'>
              Cloud Print Configurations
              <ButtonInfo className='Outline ButtonInfoDrop'>
                <div className='DropInfo'>
                  Enable your CloudPrint username and password. To get more
                  details on connecting your CloudPrinter, please visit this
                  <AuthLink
                    href='https://support.getsauce.com/cloud-print-prints-your-tablet-orders'
                    target='_blank'
                  >
                    page
                  </AuthLink>
                </div>
              </ButtonInfo>
            </DialogTitle>
            <DialogBody>
              <form onSubmit={formik.handleSubmit}>
                <DialogFields className='wAuto mBottom0'>
                  <DialogField className='w100'>
                    <FieldInput
                      name='StarPrintUser'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder='Username'
                      value={formik.values.StarPrintUser}
                      className={
                        formik.errors.StarPrintUser &&
                        formik.touched.StarPrintUser &&
                        'error'
                      }
                    />
                    <ErrorSignWrapper>
                      {formik.touched.StarPrintUser &&
                        formik.errors.StarPrintUser && (
                          <ErrorSign>{formik.errors.StarPrintUser}</ErrorSign>
                        )}
                    </ErrorSignWrapper>
                  </DialogField>
                  <DialogField className='w100 FieldPassword'>
                    <FieldPassword
                      className={
                        formik.errors.StarPrintPassword &&
                        formik.touched.StarPrintPassword &&
                        'error'
                      }
                      name='StarPrintPassword'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.StarPrintPassword}
                      placeholder='Password'
                    />
                    {formik.errors.StarPrintPassword &&
                      formik.touched.StarPrintPassword && (
                        <ErrorSignWrapper>
                          <ErrorSign>
                            {formik.errors.StarPrintPassword}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      )}
                  </DialogField>
                </DialogFields>

                <DialogFields className='wAuto verticalCenter'>
                  <DialogField className='wAuto'>
                    <FieldInput value={CLOUD_PRINT_URL} readonly />
                  </DialogField>
                  <DialogField>
                    <CopyToClipboard
                      text={CLOUD_PRINT_URL}
                      onCopy={() =>
                        toast.success('Copied successfully to your clipboard.')
                      }
                    >
                      <ButtonOutline type='button' className='ButtonMd'>
                        Copy URL
                      </ButtonOutline>
                    </CopyToClipboard>
                  </DialogField>
                </DialogFields>

                <DialogFields className='wAuto align-center verticalCenter'>
                  {/* <DialogField> */}
                  {/*  <span>Number of copies</span> */}
                  {/* </DialogField> */}
                  {/* <DialogField> */}
                  {/*  <FieldInput */}
                  {/*    style={{ */}
                  {/*      maxWidth: '50px', */}
                  {/*      padding: '0', */}
                  {/*      textAlign: 'center' */}
                  {/*    }} */}
                  {/*    type='number' */}
                  {/*    name='StarPrintUserCopies' */}
                  {/*    value={formik.values.StarPrintUserCopies} */}
                  {/*    onChange={formik.handleChange} */}
                  {/*    onBlur={formik.handleBlur} */}
                  {/*  /> */}
                  {/* </DialogField> */}
                  <DialogField className='w100 Say2EatSelect'>
                    <FieldLabel className='FieldLabelLg'>Copies</FieldLabel>
                    <FormControl>
                      <Select
                        inputProps={{'aria-label': 'value'}}
                        onChange={formik.handleChange}
                        value={formik.values.StarPrintUserCopies}
                        name='StarPrintUserCopies'
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogField>
                </DialogFields>

                <DialogAction>
                  <ButtonPrimary
                    type='submit'
                    className={formik.isSubmitting && 'disabled'}
                  >
                    <span>Apply</span>
                  </ButtonPrimary>
                </DialogAction>
              </form>
            </DialogBody>
          </>
        ) : (
          <Loading />
        )}
      </DialogContainer>
    </Dialog>
  );
}

CloudPrint.propTypes = {
  onClose: PropTypes.func
};

CloudPrint.defaultProps = {
  onClose: () => {}
};
