const allowedFileFormats = [
  'application/msword',
  'application/.xl*',
  'application/csv',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/doc',
  'application/zip',
  'application/rar',
  'application/7zip',
  'application/docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet (.XLSX)',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export default allowedFileFormats;
