import jwt_decode from 'jwt-decode';
import {REFRESH_TOKEN} from '../../store/global/auth/action';
import {getStorageToken} from '../../helpers/localStorage/authStorage';

const refreshToken = (dispatch) => {
  const interval = setInterval(() => {
    const {token} = getStorageToken();

    // TODO: delete after bugfix 1183
    console.log(
      'Refresh token attempt will be run',
      token,
      token ? jwt_decode(token) : 'Token is null',
      Date.now() / 1000 + 660
    );

    if (token && jwt_decode(token).exp < Date.now() / 1000 + 660) {
      // TODO: delete after bugfix 1183
      console.log('Refresh token attempt ready to run');

      dispatch(REFRESH_TOKEN());
    }
  }, 300000);
  return () => clearInterval(interval);
};

export default refreshToken;
