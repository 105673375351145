import {createAction} from 'redux-act';

export const SAVE_STEP_DATA = createAction('setupRestaurant/SAVE_STEP_DATA');
export const SETUP_USER_ACCOUNT = createAction(
  'setupRestaurant/SETUP_USER_ACCOUNT'
);
export const SETUP_USER_ACCOUNT_SUCCESS = createAction(
  'setupRestaurant/SETUP_USER_ACCOUNT_SUCCESS'
);
export const GET_SHORT_LINK = createAction('setupRestaurant/GET_SHORT_LINK');
export const GET_SHORT_LINK_SUCCESS = createAction(
  'setupRestaurant/GET_SHORT_LINK_SUCCESS'
);
export const GET_SETUP_USER_ACCOUNT = createAction(
  'setupRestaurant/GET_SETUP_USER_ACCOUNT'
);
export const GET_SETUP_USER_ACCOUNT_SUCCESS = createAction(
  'setupRestaurant/GET_SETUP_USER_ACCOUNT_SUCCESS'
);

export const SETUP_USER_ACCOUNT_MODAL = createAction(
  'setupRestaurant/SETUP_USER_ACCOUNT_MODAL'
);

export const SET_FINAL_SETUP = createAction('setupRestaurant/SET_FINAL_SETUP');

export const GET_COUNTRIES = createAction('setupRestaurant/GET_COUNTRIES');
export const GET_COUNTRIES_SUCCESS = createAction(
  'setupRestaurant/GET_COUNTRIES_SUCCESS'
);
export const SET_RESTAURANT_URL = createAction(
  'setupRestaurant/SET_RESTAURANT_URL'
);

export const CONTROL_SIDEBAR_PROGRESS = createAction(
  'setupRestaurant/CONTROL_SIDEBAR_PROGRESS'
);
export const CONTROL_MODALS = createAction('setupRestaurant/CONTROL_MODALS');

export const GET_UBER_EATS_STORES_ONBOARDING = createAction(
  'setupRestaurant/GET_UBER_EATS_STORES_ONBOARDING'
);

export const SAVE_UBER_EATS_STORES_ONBOARDING = createAction(
  'setupRestaurant/SAVE_UBER_EATS_STORES_ONBOARDING'
);

export const GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING = createAction(
  'setupRestaurant/GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING'
);

export const CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING = createAction(
  'setupRestaurant/GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING'
);

export const UBER_EATS_SELECT_STORE_ONBOARDING = createAction(
  'setupRestaurant/UBER_EATS_STORE_SELECT_ONBOARDING'
);

export const UBER_EATS_ONBOARDING_SAVE_MENUS = createAction(
  'setupRestaurant/UBER_EATS_ONBOARDING_SAVE_MENUS'
);

export const UBER_EATS_ONBOARDING_MENUS_LOADING = createAction(
  'setupRestaurant/UBER_EATS_ONBOARDING_MENUS_LOADING'
);

export const UBER_EATS_ONBOARDING_SAVE_MENU = createAction(
  'setupRestaurant/UBER_EATS_ONBOARDING_SAVE_MENU'
);

export const INITIATE_ONBOARDING_MENU_PROCESS = createAction(
  'setupRestaurant/INITIATE_ONBOARDING_MENU_PROCESS'
);

export const SAVE_STEP_DATA_FOR_REQUEST = createAction(
  'setupRestaurant/SAVE_STEP_DATA_FOR_REQUEST'
);
