import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ChipText = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 30px;
  border-top: 1px solid #dfe2ea;

  img {
    margin-right: 8px;
  }
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    color: ${(props) => props.color};
  }

  span {
    font-weight: 700;
    color: ${(props) => props.emphasizedTextColor};
  }
`;
