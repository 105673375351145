import React, {useState} from 'react';
// import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Dialog} from '@material-ui/core';
import {DashboardContent, DialogAction} from '../dashboard/styles';
import {SuccessImage} from '../account/styles';
import DialogContainer from '../../../components/DialogContainer/styles';
import DialogBody from '../../../components/DialogBody/styles';
import DialogTitle from '../../../components/DialogTitle/styles';
import Body4 from '../../../components/typography/Body4';
import {ButtonPrimary} from '../../../components/buttons';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import {gif} from './no-match-gif';

const NoMatch = () => {
  const history = useHistory();
  const location = useLocation();
  // const isOnline = useSelector(
  //   (state) => state.globalNotificationsReducer.isNetworkOnline
  // );
  const [isOpen, setIsOpen] = useState(false);

  useDidMountEffect(() => {
    if (!location.pathname.includes('/orders')) setIsOpen(true);

    return () => {
      setIsOpen(false);
    };
  }, [location]);

  return (
    <DashboardContent className='WithoutHeader'>
      <Dialog open={isOpen} scroll='body'>
        <DialogContainer className='DialogM'>
          <DialogBody>
            <DialogTitle className='marginBottomXs'>
              This Page Can not Be Found
            </DialogTitle>
            <SuccessImage src={gif} />
            <Body4 className='text-center'>
              We can’t find the page You&#039;re looking for. <br />
              How about returning to Orders?
            </Body4>
            <DialogAction>
              <ButtonPrimary
                onClick={() => {
                  history.push('/orders');
                  setIsOpen(false);
                }}
              >
                <span>Take Me Back to Orders</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogBody>
        </DialogContainer>
      </Dialog>
    </DashboardContent>
  );
};

export default NoMatch;
