import {createReducer} from 'redux-act';
import {
  GENERATE_REPORT,
  EXPORT_REPORTS,
  STOP_EXPORT_REPORTS_LOADING,
  STOP_REPORTS_LOADING,
  SET_REPORT_TYPE,
  SET_REPORTS_DATE_INTERVAL,
  GENERATE_REPORT_SUCCESS,
  SET_DATEPICKER_INDEX,
  SET_REPORT_VISITED_STATISTIC
} from './action';

const initialState = () => ({
  data: {dataRows: [], reportTypeInner: 2, reportPeriod: []},
  generationLoading: false,
  dataLoading: false,
  exportLoading: false,
  reportType: 2,
  datePickerTabIndex: 3,
  dateRange: ['', ''],
  totalRow: {},
  reportId: '',
  quantityData: null,
  stripeIntersected: 'none'
  // skip: 0
  // dataCount: 0
});

const reportsReducer = createReducer(
  {
    [SET_REPORT_VISITED_STATISTIC]: (state, payload) => ({
      ...state,
      generationLoading: false,
      quantityData: payload
    }),
    [GENERATE_REPORT]: (state) => ({
      ...state,
      generationLoading: true,
      // dataLoading: true,
      // data: [],
      skip: 0
    }),
    [GENERATE_REPORT_SUCCESS]: (state, payload) => {
      const dataRows =
        payload.reportData && payload.reportData.dataRows
          ? payload.reportData.dataRows
          : [];

      // metadata property 'stripe.account.intersection' can has one of three values:
      // 'none' - the location’s Stripe account is used only by the location itself
      // 'several' - the location’s Stripe account is used within multiple locations of a merchant
      // 'merchant-wise' - the location’s Stripe account is used within all the locations of a merchant

      let isStripeIntersected = 'none';

      // Create object for Total row in table:
      let totalObject = {};

      if (state.reportType === 0) {
        totalObject = payload.totalSummary;
        // let failedTransactions = 0;
        // dataRows.forEach((report) => {
        //   report.payouts.forEach((payout) => {
        //     if (payout.status && payout.status === 'failed') {
        //       failedTransactions += 1;
        //     }
        //   });
        // });
        // totalObject.payoutStatus = !!failedTransactions;
      }

      if (state.reportType === 2) {
        totalObject.status = !!dataRows.find((el) => el.status === 'failed');

        totalObject.amount = dataRows.reduce((accumulator, current) => {
          return (
            accumulator +
            (current.status !== 'failed' && current.status !== 'canceled'
              ? current.amount
              : 0)
          );
        }, 0);
      }

      if (state.reportType === 5) {
        totalObject = payload.totalSummary;
      }

      if (payload.metadata && state.reportType === 2) {
        const stripeFlag = payload.metadata.filter(
          (obj) => obj.key === 'stripe.account.intersection'
        );
        if (stripeFlag.length > 0) {
          isStripeIntersected = stripeFlag[0].value;
        }
      }

      return {
        ...state,
        totalRow: totalObject,
        reportId: payload.reportId,
        data: payload.reportData,
        stripeIntersected: isStripeIntersected
      };
    },
    // [GET_REPORTS]: (state) => ({
    //   ...state,
    //   data: []
    //   // dataLoading: true
    // }),
    // [GET_REPORTS_SUCCESS]: (state, payload) => {
    //   return {
    //     ...state,
    //     // data: payload.data.value,
    //     data: payload.data,
    //     generationLoading: false,
    //     dataLoading: false
    //     // dataCount: payload.data['@odata.count']
    //   };
    // },
    [EXPORT_REPORTS]: (state) => ({
      ...state,
      exportLoading: true
    }),
    [STOP_EXPORT_REPORTS_LOADING]: (state) => ({
      ...state,
      exportLoading: false
    }),
    [STOP_REPORTS_LOADING]: (state) => ({
      ...state,
      data: {dataRows: [], reportTypeInner: 2, reportPeriod: []},
      generationLoading: false,
      dataLoading: false
    }),
    [SET_REPORTS_DATE_INTERVAL]: (state, payload) => {
      return {
        ...state,
        dateRange: payload
      };
    },
    [SET_REPORT_TYPE]: (state, payload) => {
      return {
        ...state,
        reportType: payload
      };
    },
    [SET_DATEPICKER_INDEX]: (state, payload) => {
      return {
        ...state,
        datePickerTabIndex: payload
      };
    }
    // [REPORTS_PAGINATE]: (state, payload) => {
    //   return {
    //     ...state,
    //     skip: payload
    //   };
    // }
  },
  initialState()
);

export default reportsReducer;
