import React from 'react';
import {useSelector} from 'react-redux';
import {Route} from 'react-router-dom';
import NoMatch from './noMatch';
import Orders from './orders';
import {FEATURE_RESTRICTION_IDS} from '../../helpers/constants/restrictions';
import RestrictedArea from '../../components/layout/RestrictedArea';

const OfflineRoutes = () => {
  const isOnline = useSelector(
    (state) => state.globalNotificationsReducer.isNetworkOnline
  );

  return (
    <>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.ORDERS]}
      >
        <Route path='/orders' component={Orders} />
      </RestrictedArea>
      {!isOnline && (
        <Route path='*'>
          <NoMatch />
        </Route>
      )}
    </>
  );
};

export default OfflineRoutes;
