import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, useFormik} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, FormControlLabel} from '@material-ui/core';
import {diff} from 'deep-object-diff';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import {
  CREATE_CATEGORY_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DashboardField,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogHint,
  DialogLinkButton,
  FieldInput,
  FieldLabel,
  FieldTextarea
} from '../../dashboard/styles';
import FileUploader from '../../../../components/FileUploader';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import CategorySchema from '../../../../helpers/services/formValidationSchemas/CategorySchema';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {POST_CATEGORY} from '../../../../store/menuEditorCategory/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import {getAvailabilityHours, getAvailabilityHoursForServer} from '../helpers';
import {selectOptions, startActiveHours, startCustomHours} from '../constants';
import SelectWrapper from '../../../../components/select/SelectComponentStyles';
import DaysPickerContainer from '../DaysPickerContainer';

const CreateCategoryModal = () => {
  const dispatch = useDispatch();
  const isCreateCategoryModalOpen = useSelector(
    (store) => store.menuEditorModalsReducer.isCreateCategoryModalOpen
  );

  const itemsMap = useSelector((state) => state.menuEditorReducer.itemsMap);
  const itemsDirection = useSelector(
    (state) => state.menuEditorReducer.itemsDirection
  );
  const itemsList = itemsDirection.map((PosId) => itemsMap[PosId]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [expanded, setExpanded] = useState(false);

  const formikRef = useRef();
  const openingDays = useDaysPickerContext();

  const initialValues = {
    Name: '',
    Notes: '',
    ExternalId: '',
    ExternalData: '',
    ContainsAlcohol: false,
    AvailabilityHours: getAvailabilityHours(startActiveHours),
    OpeningHoursChanged: [],
    Availability: 'During opening hours'
  };

  const shouldAllowFormReinitialize = diff(
    initialValues,
    formikRef?.current?.values
  );

  useEffect(() => {
    if (formikRef.current) {
      const {
        values,
        resetForm,
        setFieldValue,
        isSubmitting
      } = formikRef.current;

      // if (!isSubmitting) {
      //   resetForm({...values});
      // }
      setFieldValue('OpeningHoursChanged', values.OpeningHoursChanged);
    }
  }, [formikRef.current]);

  useEffect(() => {
    return () => {
      setExpanded(false);
      setSelectedItems([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      Name: '',
      Notes: '',
      ExternalId: '',
      ExternalData: '',
      ContainsAlcohol: false
    },
    validationSchema: CategorySchema,
    onSubmit: (values) => {
      dispatch(
        POST_CATEGORY({
          ...values,
          Name: String(values.Name).trim(),
          Visible: true,
          IsOriginal: false,
          ParentId: null,
          ParentMenu: null,
          MakeItemsVisible: false,
          PictureURL: fileUrl,
          Items: [...selectedItems.map((item) => item.PosId)]
        })
      );
      sendGtmEvent('menuEditorNewEntityAdded', {
        entity: 'category'
      });
      dispatch(CREATE_CATEGORY_MODAL({open: false}));
    }
  });

  // const addNewItems = (group) => {
  //   setNewItems((prev) => [...prev, group]);
  // };
  useEffect(() => {
    if (selectedItems.length > 0) {
      setExpanded(true);
    }
  }, [selectedItems]);

  const removeItem = (id) => {
    const filter = selectedItems.filter((group) => group.PosId !== id);
    setSelectedItems(filter);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      enableReinitialize={
        !Object.keys(shouldAllowFormReinitialize || {}).length
      }
      validationSchema={CategorySchema}
      onSubmit={(values, {resetForm}) => {
        const body = {
          ...values,
          Name: String(values.Name).trim(),
          Visible: true,
          IsOriginal: false,
          ParentId: null,
          ParentMenu: null,
          MakeItemsVisible: false,
          PictureURL: fileUrl,
          Items: [...selectedItems.map((item) => item.PosId)],
          AvailabilityHours:
            values.Availability === 'Custom hours'
              ? getAvailabilityHoursForServer(openingDays)
              : startActiveHours
        };
        delete body.Availability;
        delete body.OpeningHoursChanged;
        dispatch(POST_CATEGORY(body));
        sendGtmEvent('menuEditorNewEntityAdded', {
          entity: 'category'
        });
        dispatch(CREATE_CATEGORY_MODAL({open: false}));
      }}
    >
      {({values, handleChange, submitForm, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            onClose={() => dispatch(CREATE_CATEGORY_MODAL({open: false}))}
            open={isCreateCategoryModalOpen}
            scroll='body'
          >
            <DialogContainer className='DialogXl'>
              <DialogClose
                onClick={() => dispatch(CREATE_CATEGORY_MODAL({open: false}))}
              />
              <DialogTitle>
                Create a <span>Category</span>
              </DialogTitle>
              <Form>
                <DialogBody>
                  <DialogFields>
                    <DialogField className='w50'>
                      <FieldLabel className='FieldLabelLg'>
                        Category name
                      </FieldLabel>

                      <FieldInput
                        className={errors.Name && touched.Name && 'error'}
                        name='Name'
                        onChange={handleChange}
                        value={values.Name}
                        placeholder='Write a category name here'
                      />
                      <ErrorSignWrapper>
                        {errors.Name && touched.Name && (
                          <ErrorSign>{errors.Name}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                    <DialogField className='w100 dFlex'>
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              onChange={handleChange}
                              name='ContainsAlcohol'
                              checked={values.ContainsAlcohol}
                            />
                          }
                          label='Contains Alcohol'
                        />
                      </CheckboxContainer>
                    </DialogField>

                    <DialogField className='w50'>
                      <FieldLabel>Photo</FieldLabel>
                      <FileUploader
                        url={fileUrl}
                        onSetFile={setFileUrl}
                        allowedFileFormats={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg'
                        ]}
                      />
                    </DialogField>

                    <DialogField className='w50'>
                      <FieldLabel>Notes (for internal use)</FieldLabel>
                      <FieldTextarea
                        className='FieldTextareaLg'
                        name='Notes'
                        value={values.Notes}
                        onChange={handleChange}
                      />
                    </DialogField>

                    <DialogField className='w30'>
                      <DashboardField className='wAutoSpace paddingL0'>
                        <FieldLabel>Availability</FieldLabel>
                        <SelectWrapper className='SelectMd w100'>
                          <FormControl className='select-formcontrol'>
                            <Select
                              value={values.Availability}
                              name='Availability'
                              onChange={(e) => {
                                setFieldValue('Availability', e.target.value);
                                if (e.target.value === 'Custom hours') {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startCustomHours)
                                  );
                                } else {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startActiveHours)
                                  );
                                }
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                getContentAnchorEl: null
                              }}
                            >
                              {selectOptions.map((item) => (
                                <MenuItem
                                  className='reportTypeItem'
                                  key={nanoid()}
                                  value={item.name}
                                  disableRipple
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </SelectWrapper>
                      </DashboardField>
                    </DialogField>
                    {values.Availability === 'Custom hours' && (
                      <DialogField className='w100'>
                        <DaysPickerContainer
                          WorkingDays={values?.AvailabilityHours}
                        />
                      </DialogField>
                    )}

                    <DialogField className='w100 dFlex noWrapTablet mTop20'>
                      <DialogHint className='DialogHintTablet'>
                        <span>
                          Add the unique identifier you can use at your
                          restaurant (ex. SKU)
                        </span>
                      </DialogHint>
                      <DialogFieldInners className='DialogFieldInnersExternal'>
                        <DialogFieldInner className='w50'>
                          <FieldLabel>External ID</FieldLabel>
                          <FieldInput
                            name='ExternalId'
                            onChange={handleChange}
                            value={values.ExternalId}
                            placeholder='External ID'
                          />
                        </DialogFieldInner>
                        <DialogFieldInner className='w50'>
                          <FieldLabel>External Data</FieldLabel>
                          <FieldInput
                            name='ExternalData'
                            onChange={handleChange}
                            value={values.ExternalData}
                            placeholder='External Data'
                          />
                        </DialogFieldInner>
                      </DialogFieldInners>
                    </DialogField>

                    <DialogField className='w100 Say2EatSelect'>
                      <AccordionItem className='AccordionItemModal'>
                        <Accordion
                          onChange={handleAccordionChange('items')}
                          expanded={expanded === 'items' || expanded === true}
                        >
                          <AccordionSummary
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <AccordionItemName className='AccordionItemName'>
                              Items including{' '}
                              {selectedItems.length > 0
                                ? ` (${selectedItems.length})`
                                : ''}
                            </AccordionItemName>
                          </AccordionSummary>
                          <AccordionItemBody>
                            <DialogFields>
                              {selectedItems.map((group) => (
                                <DialogField className='w100 withRemove'>
                                  <FieldInput
                                    name=''
                                    value={group.Name}
                                    readOnly
                                    placeholder={group.Name}
                                  />
                                  <DialogFieldRemove
                                    onClick={() => removeItem(group.PosId)}
                                  />
                                </DialogField>
                              ))}
                            </DialogFields>
                          </AccordionItemBody>
                        </Accordion>
                      </AccordionItem>
                    </DialogField>

                    <DialogField className='Center w100'>
                      <DialogLinkButton
                        onClick={() => {
                          dispatch(
                            SELECT_ITEM_MODAL_OPEN({
                              open: true,
                              selectedItems,
                              itemType: 'item',
                              itemList: itemsList,
                              loading: false,
                              callBack: setSelectedItems,
                              closeModal: () => {
                                dispatch(
                                  SELECT_ITEM_MODAL_OPEN({
                                    open: false,
                                    itemType: '',
                                    queryParams: {},
                                    itemList: [],
                                    loading: false,
                                    selectedItems: [],
                                    callBack: () => {},
                                    closeModal: () => {}
                                  })
                                );
                              }
                            })
                          );
                        }}
                      >
                        Add an Item
                      </DialogLinkButton>
                    </DialogField>
                  </DialogFields>
                  <DialogAction>
                    <ButtonPrimary type='submit'>
                      <span>Create a Category</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogBody>
              </Form>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreateCategoryModal;
