import React from 'react';
import PropTypes from 'prop-types';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import {Body1, Caption1} from '../../../../../components/typography';
import Caption5 from '../../../../../components/typography/Caption5';
import {
  FeedbackGrade,
  FeedbackGradeBox,
  FeedbackGrades,
  FeedbackGradeThumb,
  FeedbackRating
} from '../../styles';
import Subtitle6 from '../../../../../components/typography/Subtitle6';

const SatisfactionContainer = (props) => {
  const {
    title,
    description,
    ratingSatisfaction,
    percentOneStar,
    responseOneStar,
    percentTwoStar,
    responseTwoStar,
    percentThreeStar,
    responseThreeStar,
    percentFourStar,
    responseFourStar,
    percentFiveStar,
    responseFiveStar
  } = props;

  return (
    <>
      <FlexContainer justifyContent='space-between'>
        <FlexItem>
          <Subtitle6 className='SubtitleMobSmall'>{title}</Subtitle6>
          <Body1 className='mTop2'>{description}</Body1>
        </FlexItem>
        <FlexItem>
          <FeedbackRating>
            <Caption5 className='CaptionMobSmall'>
              {ratingSatisfaction}
            </Caption5>
          </FeedbackRating>
        </FlexItem>
      </FlexContainer>
      <FeedbackGrades>
        <FeedbackGrade>
          <Caption1>{percentOneStar}%</Caption1>
          <Body1 className='grey responses'>
            {responseOneStar} {responseOneStar > 1 ? 'responses' : 'response'}
          </Body1>
          <FeedbackGradeBox>
            <FeedbackGradeThumb height={`${percentOneStar}%`} />
          </FeedbackGradeBox>
          <Caption1 className='grey2'>1</Caption1>
        </FeedbackGrade>
        <FeedbackGrade>
          <Caption1>{percentTwoStar}%</Caption1>
          <Body1 className='grey responses'>
            {responseTwoStar} {responseTwoStar > 1 ? 'responses' : 'response'}
          </Body1>
          <FeedbackGradeBox>
            <FeedbackGradeThumb height={`${percentTwoStar}%`} />
          </FeedbackGradeBox>
          <Caption1 className='grey2'>2</Caption1>
        </FeedbackGrade>
        <FeedbackGrade>
          <Caption1>{percentThreeStar}%</Caption1>
          <Body1 className='grey responses'>
            {responseThreeStar}{' '}
            {responseThreeStar > 1 ? 'responses' : 'response'}
          </Body1>
          <FeedbackGradeBox>
            <FeedbackGradeThumb height={`${percentThreeStar}%`} />
          </FeedbackGradeBox>
          <Caption1 className='grey2'>3</Caption1>
        </FeedbackGrade>
        <FeedbackGrade>
          <Caption1>{percentFourStar}%</Caption1>
          <Body1 className='grey responses'>
            {responseFourStar} {responseFourStar > 1 ? 'responses' : 'response'}
          </Body1>
          <FeedbackGradeBox>
            <FeedbackGradeThumb height={`${percentFourStar}%`} />
          </FeedbackGradeBox>
          <Caption1 className='grey2'>4</Caption1>
        </FeedbackGrade>
        <FeedbackGrade>
          <Caption1>{percentFiveStar}%</Caption1>
          <Body1 className='grey responses'>
            {responseFiveStar} {responseFiveStar > 1 ? 'responses' : 'response'}
          </Body1>
          <FeedbackGradeBox>
            <FeedbackGradeThumb height={`${percentFiveStar}%`} />
          </FeedbackGradeBox>
          <Caption1 className='grey2'>5</Caption1>
        </FeedbackGrade>
      </FeedbackGrades>
    </>
  );
};

export default SatisfactionContainer;

SatisfactionContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  percentOneStar: PropTypes.number.isRequired,
  ratingSatisfaction: PropTypes.number.isRequired,
  responseOneStar: PropTypes.number.isRequired,
  percentTwoStar: PropTypes.number.isRequired,
  responseTwoStar: PropTypes.number.isRequired,
  percentThreeStar: PropTypes.number.isRequired,
  responseThreeStar: PropTypes.number.isRequired,
  percentFourStar: PropTypes.number.isRequired,
  responseFourStar: PropTypes.number.isRequired,
  percentFiveStar: PropTypes.number.isRequired,
  responseFiveStar: PropTypes.number.isRequired
};
