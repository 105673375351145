import React from 'react';

const iconGenerateReportButton = ({className}) => (
  <svg
    className='rot'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.4683 4.67856C11.7603 4.67856 12.8076 3.63123 12.8076 2.33928C12.8076 1.04733 11.7603 0 10.4683 0C9.17636 0 8.12903 1.04733 8.12903 2.33928C8.12903 3.63123 9.17636 4.67856 10.4683 4.67856Z'
      fill='#FF2D38'
    />
    <path
      d='M10.4683 19.9999C11.2434 19.9999 11.8717 19.3716 11.8717 18.5965C11.8717 17.8214 11.2434 17.1931 10.4683 17.1931C9.69321 17.1931 9.06488 17.8214 9.06488 18.5965C9.06488 19.3716 9.69321 19.9999 10.4683 19.9999Z'
      fill='#FF2D38'
    />
    <path
      d='M4.72031 6.82502C5.88315 6.82502 6.82581 5.88235 6.82581 4.71952C6.82581 3.55668 5.88315 2.61401 4.72031 2.61401C3.55747 2.61401 2.61481 3.55668 2.61481 4.71952C2.61481 5.88235 3.55747 6.82502 4.72031 6.82502Z'
      fill='#FF2D38'
    />
    <path
      d='M16.2163 17.3844C16.8623 17.3844 17.386 16.8608 17.386 16.2148C17.386 15.5688 16.8623 15.0452 16.2163 15.0452C15.5704 15.0452 15.0467 15.5688 15.0467 16.2148C15.0467 16.8608 15.5704 17.3844 16.2163 17.3844Z'
      fill='#FF2D38'
    />
    <path
      d='M2.33929 12.3392C3.3726 12.3392 4.21026 11.5016 4.21026 10.4683C4.21026 9.43495 3.3726 8.59729 2.33929 8.59729C1.30598 8.59729 0.468323 9.43495 0.468323 10.4683C0.468323 11.5016 1.30598 12.3392 2.33929 12.3392Z'
      fill='#FF2D38'
    />
    <path
      d='M18.5966 11.4027C19.113 11.4027 19.5317 10.984 19.5317 10.4676C19.5317 9.95113 19.113 9.53247 18.5966 9.53247C18.0802 9.53247 17.6615 9.95113 17.6615 10.4676C17.6615 10.984 18.0802 11.4027 18.5966 11.4027Z'
      fill='#FF2D38'
    />
    <path
      d='M3.56283 15.0588C2.92298 15.6986 2.92298 16.7339 3.56283 17.3738C4.20192 18.0136 5.23873 18.0136 5.87782 17.3738C6.51767 16.7339 6.51767 15.6986 5.87782 15.0588C5.23873 14.4182 4.20268 14.4121 3.56283 15.0588Z'
      fill='#FF2D38'
    />
    <path
      d='M16.2155 5.42084C16.6029 5.42084 16.9169 5.10685 16.9169 4.71952C16.9169 4.33218 16.6029 4.01819 16.2155 4.01819C15.8282 4.01819 15.5142 4.33218 15.5142 4.71952C15.5142 5.10685 15.8282 5.42084 16.2155 5.42084Z'
      fill='#FF2D38'
    />
  </svg>
);

export default iconGenerateReportButton;
