import styled from 'styled-components';
import IconPlus from '../../../assets/icon-plus-button.svg';

const PrimaryLinkDefault = styled.a`
  display: inline-block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 1;
  color: #fff;
  box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32);
  border-radius: 30px;
  padding: 19px 20px;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  &.disabled {
    background-image: none;
    background-color: #fff;
    box-shadow: none;
    cursor: default;
    border-color: #ededed;
    pointer-events: none;
    opacity: 1;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 3;
    @media (max-width: 599px) {
      line-height: 1.2;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #f0868c 96.81%
    );
    z-index: 2;
    transition-property: opacity;
    transition-duration: 0.3s;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #ff2d38 96.81%
    );
    z-index: 1;
  }

  a {
    display: block;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    width: 100%;
    position: relative;
    z-index: 3;
  }
  &:hover {
    &::before {
      opacity: 0;
    }
  }
  &.loadMore {
    display: block;
    width: 300px;
    margin: 40px auto;
  }
  &.saveChanges {
    max-width: 400px;
    width: 100%;
  }
  &.disabled {
    background-image: none;
    background-color: #ededed;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
    &::before,
    &::after {
      display: none;
    }
  }
  &.widthXlCenter {
    width: calc(100% - 30px);
    @media (max-width: 440px) {
      width: 100%;
    }
  }
  &.w400 {
    max-width: 400px;
    width: 100%;
  }
  &.ButtonXs {
    font-weight: 500;
    font-size: 13px;
    padding: 10px 15px;
  }
  &.ButtonSm {
    font-weight: 500;
    font-size: 14px;
    padding: 11px 19px;
  }
  &.ButtonMd {
    font-weight: 500;
    font-size: 16px;
    padding: 12px 26px;
  }
  &.ButtonLg {
    font-size: 18px;
    padding: 16px 26px;
  }
  &.forgot-password {
    width: 45%;
    margin: auto;
  }
  &.ButtonAdd {
    font-size: 16px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 45px;

    @media (max-width: 1000px) {
      font-size: 14px;
      padding: 10px 34px;
    }

    svg {
      width: 28px;
      height: 26px;
      flex: 0 0 auto;
      display: inline-block;
      margin-right: 10px;
      position: relative;
      z-index: 3;
    }
  }

  &.ButtonAddFixed {
    @media (max-width: 767px) {
      position: fixed;
      top: 94px;
      right: 20px;
      z-index: 1;
      transition-property: top;
      transition-duration: 0.4s;
    }
    @media (max-width: 599px) {
      position: relative;
      top: 0;
      right: 0;
    }
  }

  &.isFixed {
    @media (max-width: 780px) {
      position: fixed;
      top: 94px;
      right: 20px;
      z-index: 1;
      transition-property: top;
      transition-duration: 0.4s;
    }
    @media (max-width: 599px) {
      position: relative;
      top: 0;
      right: 0;
    }
  }
  &.ButtonPlus {
    padding: 16px 20px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url(${IconPlus});
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  &.ButtonBookCall {
    max-width: 240px;
    width: 100%;
  }
  &.ButtonMenuEditing {
    min-width: 260px;
    max-width: 260px;
  }
  &.ButtonMedium {
    font-size: 14px;
    padding: 16px 26px;
  }
  &.ButtonSmall {
    font-size: 16px;
    font-weight: 500;
    padding: 11px 26px;

    &.ButtonDriver {
      padding: 4px 26px;
    }
  }
  &.ButtonDriver {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 26px;

    @media (max-width: 1000px) {
      font-size: 14px;
      padding: 9px 26px;
    }

    svg {
      width: 29px;
      height: 28px;
      flex: 0 0 auto;
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: -1px;
      z-index: 3;
    }
  }
  &.ButtonSurvey {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13.5px 26px;
    min-width: 186px;

    @media (max-width: 1000px) {
      font-size: 14px;
      padding: 9px 26px;
      min-width: auto;
    }

    svg {
      width: 19px;
      height: 19px;
      flex: 0 0 auto;
      display: inline-block;
      margin-right: 5px;
      position: relative;
      z-index: 3;
    }
  }
`;

export default PrimaryLinkDefault;
