import {put, select, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  getAllModifiersApi,
  getOutOffStockCounters,
  getItemsApi,
  getItemTree,
  getModifierTree,
  getOutOfStockApi
} from '../../helpers/services/api/menuEditor';
import {
  FETCH_OUTSTOCK_ITEMS,
  FETCH_OUTSTOCK_MODIFIERS,
  GET_OUT_STOCK,
  GET_OUT_STOCK_SUCCESS,
  GET_OUT_STOCK_TAB,
  GET_OUT_STOCK_TAB_SUCCESS,
  SET_OUTSTOCK_ITEMS,
  CLEAR_OUTSTOCK_ITEMS,
  SET_OUTSTOCK_MODIFIERS,
  CLEAR_OUTSTOCK_MODIFIERS,
  FETCH_SINGLE_ITEM_TREE,
  SET_SINGLE_ITEM_TREE,
  FETCH_SINGLE_MODIFIER_TREE,
  SET_SINGLE_MODIFIER_TREE,
  FETCH_OUTSTOCK_COUNTERS,
  LOAD_OUTSTOCK_COUNTERS,
  SET_OUTSTOCK_COUNTERS
  // GET_OUT_STOCK_TAB_SUCCESS
} from './action';

const getItemsState = ({menuEditorItemReducer}) =>
  menuEditorItemReducer.itemsPagination;
const getAllModifiersState = ({menuEditorModifierReducer}) =>
  menuEditorModifierReducer.allModifiersPagination;

function* getOutOfStock() {
  try {
    const {data} = yield getOutOfStockApi();

    if (data) {
      yield put(GET_OUT_STOCK_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getAllOutOfStockTab({payload}) {
  try {
    const modifiersParams = yield select(getAllModifiersState);
    const itemsParams = yield select(getItemsState);

    const paginationModifiers = {
      ...modifiersParams,
      // fields: payload.fields,
      // EVG: payload.EVG,
      outStock: payload.outStock,
      page: 1,
      limit: 1000,
      fields: payload.fields,
      ...payload
    };
    const paginationItems = {
      ...itemsParams,
      outStock: payload.outStock,
      page: 1,
      limit: 1000,
      ...payload
    };

    const {data} = yield getAllModifiersApi(paginationModifiers);
    const {data: d2} = yield getItemsApi(paginationItems);

    if (d2 && data) {
      yield put(GET_OUT_STOCK_TAB_SUCCESS({data, d2}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* fetchOutStockItems() {
  try {
    const itemsParams = yield select(
      ({menuEditorHiddenReducer}) => menuEditorHiddenReducer.itemsPagination
    );
    const {data} = yield getItemsApi({
      ...itemsParams,
      outStock: true,
      page: 1,
      limit: 1000
    });

    if (data) {
      yield put({
        type: SET_OUTSTOCK_ITEMS,
        payload: data.docs
      });
    } else {
      yield put({type: CLEAR_OUTSTOCK_ITEMS});
    }
  } catch (error) {
    console.log('Error [fetchOutstockItems] saga: ', error);
  }
}

function* fetchOutStockModifiers() {
  try {
    const modifiersParams = yield select(
      ({menuEditorHiddenReducer}) =>
        menuEditorHiddenReducer.allModifiersPagination
    );
    const {data} = yield getAllModifiersApi({
      ...modifiersParams,
      outStock: true,
      page: 1,
      limit: 1000
    });

    if (data) {
      yield put({
        type: SET_OUTSTOCK_MODIFIERS,
        payload: data.docs
      });
    } else {
      yield put({type: CLEAR_OUTSTOCK_MODIFIERS});
    }
  } catch (error) {
    console.log('Error [fetchOutstockModifiers] saga: ', error);
  }
}

function* fetchOutStockCounters() {
  try {
    yield put(LOAD_OUTSTOCK_COUNTERS(true));

    const {data} = yield getOutOffStockCounters();

    if (data) {
      yield put(SET_OUTSTOCK_COUNTERS(data));
    }

    yield put(LOAD_OUTSTOCK_COUNTERS(false));
  } catch (error) {
    console.log('Error [fetchOutStockCounters]: ', error);
  }
}

function* fetchSingleItemTree({payload}) {
  try {
    const {data} = yield getItemTree(payload);

    if (data) {
      yield put(SET_SINGLE_ITEM_TREE(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

function* fetchSingleModifierTree({payload}) {
  try {
    const {data} = yield getModifierTree(payload);

    if (data) {
      yield put(SET_SINGLE_MODIFIER_TREE(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleModifierTree]: ', error);
  }
}

function* menuEditorOutStockSaga() {
  yield takeEvery(GET_OUT_STOCK_TAB, getAllOutOfStockTab);
  yield takeEvery(GET_OUT_STOCK, getOutOfStock);
  yield takeEvery(FETCH_OUTSTOCK_ITEMS, fetchOutStockItems);
  yield takeEvery(FETCH_OUTSTOCK_MODIFIERS, fetchOutStockModifiers);
  yield takeEvery(FETCH_SINGLE_ITEM_TREE, fetchSingleItemTree);
  yield takeEvery(FETCH_SINGLE_MODIFIER_TREE, fetchSingleModifierTree);
  yield takeEvery(FETCH_OUTSTOCK_COUNTERS, fetchOutStockCounters);
}

export default menuEditorOutStockSaga;
