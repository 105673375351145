export const BOARD = {
  ID: 'id',
  PICKUP_TIME: 'pickup_eta',
  PICKUP_DEADLINE_TIME: 'pickup_deadline',
  DROPOFF_TIME: 'dropoff_eta',
  DROPOFF_DEADLINE_TIME: 'dropoff_deadline',
  STATUS: 'status',
  DELIVERY_COMPANY: 'service_type',
  // DELIVERY_COMPANY_PHONE: 'service_phone',
  ORDER_ID: 'internal_delivery_id',
  DELIVERY_ID: 'delivery_id',
  UBER_ID: 'uber_id',
  CORE_ID: 'core_order_id',
  PICKUP_ADDRESS: 'pickup_address',
  CUSTOMER_NAME: 'customer_name',
  RESTAURANT_NAME: 'restaurant_name',
  DROPOFF_ADDRESS: 'dropoff_address',
  TIMEZONE: 'locTimezone',
  IS_CATERING: 'is_catering',
  ORDER_AMOUNT: 'value',
  TIP: 'tip',
  CANCEL_SOURCE: 'cancel_source',
  OPENING_HOURS: 'locOpeningHours',
  ORDERS_COUNT: 'locOrdersCount',
  FIRST_TEN_ORDERS: 'locFirst10Orders',
  LOCATION_TIER: 'locTier',
  DRIVER_CHANGE_COUNT: 'driver_change_count',
  STATUS_REASON: 'status_reason',
  DRIVING_DISTANCE: 'driving_distance_m',
  REQUEST_SOURCE: 'request_source',
  IS_FUTURE_ORDER: 'isFutureOrder',
  ORDER_ITEMS_COUNT: 'order_items',
  COURIER: 'courier',
  ACTIONS_MADE: 'actions_made',
  ACTIONS_TIME: 'actions_time',
  MERCHANT_NAME: 'merchantName',
  COURIER_DISTANCE: 'courierDistanceToRestaurant',
  LOCATIONS_COUNT: 'merchantLocCount',
  LOCATION_NAME: 'locName',
  RISK_LEVEL: 'riskLevel',
  CLOSED_MANUALLY_UNTIL_TIME: 'locClosedManuallyUntilTime',
  ORDER_OWNER: 'support_owner_info',
  CORE_ORD_NOTE: 'coreOrderSpecialNote'
};
export const BOARD_V2 = {
  ID: 'id',
  STATUS: 'status',
  CUSTOMER_NAME: 'customerName',
  CUSTOMER_PHONE: 'customerPhone',
  RESTAURANT_NAME: 'restaurantName',
  TIMEZONE: 'restaurantTimezone',
  IS_CATERING: 'isCatering',
  ORDER_AMOUNT: 'amount',
  TIP: 'tips',
  FIRST_TEN_ORDERS: 'restaurantFirst10Orders',
  LOCATION_TIER: 'restaurantTier',
  IS_FUTURE_ORDER: 'isFutureOrder',
  PICKUP: 'pickup',
  DELIVERY: 'delivery',
  DROPOFF: 'dropoff',
  LOCATION: 'location',
  MERCHANT: 'merchant',
  RISK_LEVEL: 'riskLevel',
  COURIER: 'courier',
  ACTIONS: 'actions',
  CORE_ORD_NOTE: 'specialNote',
  CORE_ID: 'coreOrderId',
  ORDER_ITEMS_COUNT: 'itemsCount',
  SOURCE: 'source',
  USER_TRACKING_LINK: 'userTrackingLink',
  RESTAURANT_TRACKING_LINK: 'restaurantTrackingLink',
  OLD_DISPATCH_PROVIDER_TRACKING_LINK: 'oldDispatchProviderTrackingLink',
  NEW_DISPATCH_PROVIDER_TRACKING_LINK: 'newDispatchProviderTrackingLink',
  DISPATCH_VERSION: 'dispatchVersion',
  CREATED_AT: 'createdAt'
};

export const BOARD_STATUS = {
  SCHEDULED: 'Scheduled',
  PICKED_UP: 'Picked-up',
  ASSIGNED: 'Assigned',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  DELIVERY_FAILED: 'Delivery Failed',
  PICKUP_ENROUTE: 'Pickup enroute',
  DROPOFF_ENROUTE: 'Dropoff enroute',
  FUTURE_ORDER: 'Future Order',
  CANCELLED_BY_SAUCE: 'Canceled by sauce',
  CANCELLED_BY_CUSTOMER: 'Canceled by customer',
  CANCELLED_BY_PROVIDER: 'Canceled by provider'
};

export const BOARD_V2_STATUS = {
  SCHEDULED: 'Scheduled',
  PICKED_UP: 'PickedUp',
  ASSIGNED: 'Assigned',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  PICKUP_ENROUTE: 'EnRouteToPickup',
  PICKUP_ARRIVED: 'ArrivedToPickup',
  DROPOFF_ENROUTE: 'EnRouteToDropOff',
  DROPOFF_ARRIVED: 'ArrivedToDropOff',
  FUTURE_ORDER: 'Future',
  FAILED: 'Failed',
  RETURN_ENROUTE: 'EnRouteToReturn',
  RETURNED: 'Returned',
  SEARCHING: 'Searching',
  CANCELLATION_FAILED: 'CancellationFailed'
};

export const BOARD_V2_STATUS_NAME = {
  Unknown: 'Unknown',
  Scheduled: 'Scheduled',
  Searching: 'Searching',
  Assigned: 'Assigned',
  EnRouteToPickup: 'Pickup enroute',
  ArrivedToPickup: 'At the restaurant',
  PickedUp: 'Picked up',
  EnRouteToDropOff: 'Dropoff enroute',
  ArrivedToDropOff: 'At drop off',
  EnRouteToReturn: 'Return enroute',
  Delivered: 'Dropped off',
  Cancelled: 'Cancelled',
  CancellationFailed: 'Cancellation failed',
  Failed: 'Delivery failed',
  Returned: 'Returned',
  Future: 'Future order'
};

export const BOARD_CANCEL_SOURCE = {
  D_DASHBOARD: 'D_DASHBOARD',
  TRACKING_PAGE: 'TRACKING_PAGE',
  DELIVERY_SERVICE: 'DELIVERY_SERVICE'
};
export const BOARD_V2_CANCEL_SOURCE = {
  SAUCE: 'Sauce',
  CUSTOMER: 'Customer',
  PROVIDER: 'Provider'
};

export const BOARD_V2_TRACKING_LINKS_TYPES = {
  USER: 'customer_tracking_link',
  RESTAURANT: 'location_tracking_link',
  OLD_PROVIDER: 'old_dispatch_tracking_link',
  NEW_PROVIDER: 'provider_tracking_link'
};
export const BOARD_V2_DISPATCH_VERSION_VALUE = {
  DISPATCH_V1: 'v1',
  DISPATCH_V2: 'v2'
};

export const BOARD_REQUEST_SOURCE = {
  BOOKADRIVER: 'BOOKADRIVER'
};

export const BOARD_COLOR_PRIORITY = {WHITE: 0, GREEN: 1, ORANGE: 2, RED: 3};

export const COURIER_PROXIMITY_DISTANCE = 500;

export const BOARD_ROW_BLINK = {ONCE: 1, THREE_TIMES: 3};
