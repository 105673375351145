import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useSound from 'use-sound';
import LocationNotification from '../../modals/LocationNotification';
import NewOrderModal from '../../modals/NewOrder';
import RestrictedArea from '../../../../components/layout/RestrictedArea';
import TastySound from '../../../../assets/Tasty.mp3';
import SaucySound from '../../../../assets/Saucy.mp3';
import FlavorySound from '../../../../assets/Flavory.mp3';
import {GET_LOCATION_ID_DATA} from '../../../../store/orders/orderList/action';
import BookADriverDeliverySuccessModal from '../../BookADriver/Modals/DeliverySuccess';
import BADCourierModal from '../../BookADriver/Modals/BADCourierModal';
import {FEATURE_RESTRICTION_IDS} from '../../../../helpers/constants/restrictions';

export default function Notifications() {
  const dispatch = useDispatch();
  const {
    locationData: {OrderNotifications}
  } = useSelector((state) => state.ordersHistoryReducer);

  useEffect(() => {
    if (!Object.keys(OrderNotifications).length) {
      dispatch(GET_LOCATION_ID_DATA());
    }
  }, []);

  const [playTastySound, exposedTastySound] = useSound(TastySound, {
    loop: true
  });
  const [playSaucySound, exposedSaucySound] = useSound(SaucySound, {
    loop: true
  });
  const [playFlavorySound, exposedFlavorySound] = useSound(FlavorySound, {
    loop: true
  });

  const soundsActions = {
    Tasty: {play: playTastySound, stop: exposedTastySound.stop},
    Saucy: {play: playSaucySound, stop: exposedSaucySound.stop},
    Flavory: {play: playFlavorySound, stop: exposedFlavorySound.stop}
  };

  const onSoundStop = () => {
    soundsActions[OrderNotifications.RingSoundName || 'Saucy'].stop();
  };

  useEffect(() => {
    return () => {
      onSoundStop();
    };
  }, []);

  const onHandlePlay = () => {
    soundsActions[OrderNotifications.RingSoundName || 'Saucy'].play();
  };
  return (
    <>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.STORE_SETTINGS]}
      >
        <LocationNotification />
      </RestrictedArea>
      <RestrictedArea
        restrictions={['ordersAlert']}
        features={[FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER]}
      >
        <BookADriverDeliverySuccessModal />
        <BADCourierModal />
      </RestrictedArea>
      <RestrictedArea
        restrictions={['ordersAlert']}
        features={[FEATURE_RESTRICTION_IDS.ORDERS]}
      >
        <NewOrderModal play={onHandlePlay} onSoundStop={onSoundStop} />
      </RestrictedArea>
    </>
  );
}
