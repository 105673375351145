import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import {useDatePickerContext} from '../../Datepicker/DatePickerContext';
import {SauceChart} from '../../../routes/App/dashboard/styles';
import Loading from '../../shared/Utils/Loading';
import {GET_SALES_PER_HOUR} from '../../../store/analyticsSalesPerHour/action';
import roundTheNumber from '../../../helpers/functionUtils/roundTheNumber';

const SalesByHours = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const {salesPerHour, salesPerHourLoading} = useSelector(
    (state) => state.analyticsSalesPerHourReducer
  );

  const date = useDatePickerContext();

  useEffect(() => {
    if (timezone) {
      dispatch(
        GET_SALES_PER_HOUR({
          startDate: date[0]
            ? moment(date[0]).utcOffset(timezone, false).utc().format()
            : null,
          endDate: date[1]
            ? moment(date[1]).utcOffset(timezone, false).utc().format()
            : null
        })
      );
    }
  }, [date, locationId, timezone, dispatch]);
  const generateGraphData = () => {
    const graph = new Array(24).fill().map((_, i) => {
      return {
        name: i,
        sales: 0,
        info: `${i % 12 > 12 ? (i % 12) - 12 : i % 12} - ${
          (i % 12) + 1 > 12 ? (i % 12) + 1 - 12 : (i % 12) + 1
        }`
      };
    });
    if (salesPerHour) {
      salesPerHour.forEach((data) => {
        graph.forEach((arr) => {
          const arrSales = arr;
          if (data.name === arr.name) {
            arrSales.sales = Number(data.sales);
          }
        });
      });
      graph[0].name = 12;
      graph[0].info = '12 - 1';
    }

    graph.forEach((a) => {
      const aName = a;
      if (a.name > 12) {
        aName.name -= 12;
      }
    });
    return graph;
  };
  const graphResolution = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1700) {
      return -23;
    }
    if (windowWidth >= 1600) {
      return -21;
    }
    if (windowWidth >= 1500) {
      return -20;
    }
    if (windowWidth >= 1400) {
      return -19;
    }
    if (windowWidth >= 1300) {
      return -17;
    }
    if (windowWidth >= 1250) {
      return -20;
    }
    if (windowWidth >= 1150) {
      return -18;
    }
    if (windowWidth >= 1050) {
      return -16;
    }
    if (windowWidth >= 950) {
      return -15;
    }
    if (windowWidth >= 850) {
      return -14;
    }
    if (windowWidth >= 200) {
      return -13;
    }
    return -26;
  };

  const CustomTooltip = ({active, payload}) => {
    CustomTooltip.propTypes = {
      active: PropTypes.bool.isRequired,
      payload: PropTypes.instanceOf(Object).isRequired
    };
    if (active && payload && payload.length) {
      return (
        <Box className='ChartTooltip'>
          <p>{` ${payload[0].payload.info}`}</p>
          <p>{` ${payload[0].name}: $${roundTheNumber(
            payload[0].payload.sales
          )}`}</p>
        </Box>
      );
    }

    return null;
  };
  return (
    <>
      {salesPerHourLoading && <Loading />}

      {salesPerHour && !salesPerHourLoading && (
        <SauceChart>
          <div>
            <ResponsiveContainer>
              <BarChart data={generateGraphData()}>
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor='#17C981' />
                    <stop offset='95%' stopColor='#3A928C' />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  strokeDasharray='0'
                  strokeWidth='1'
                  stroke='#E7E7E7'
                  vertical={false}
                />
                <XAxis
                  dataKey='name'
                  axisLine={false}
                  tickLine={false}
                  dx={graphResolution()}
                  tick={{dy: 15}}
                />
                <YAxis axisLine={false} tickLine={false} tick={{dx: 0}} />
                <Tooltip
                  cursor={{fill: 'rgba(23,201,129, .1)'}}
                  content={<CustomTooltip />}
                />
                <Bar
                  dataKey='sales'
                  fill='url(#colorUv)'
                  radius={[6, 6, 0, 0]}
                  barSize={20}
                />
              </BarChart>
            </ResponsiveContainer>
            <p className='SauceChartLabel'>12</p>
          </div>
        </SauceChart>
      )}
    </>
  );
};

export default SalesByHours;
