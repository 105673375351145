import {createReducer} from 'redux-act';
import {
  INSIGHTS_GROWTH_CLEAR_INFO,
  INSIGHTS_GROWTH_GET_INFO,
  INSIGHTS_GROWTH_GET_INFO_SUCCESS,
  INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL,
  INSIGHTS_GROWTH_SET_LOADING
} from 'store/insights/growth/action';
import {
  INSIGHTS_GROWTH_FEATURES_INFO,
  MODULE_WEIGHT_WITH_CATERING,
  MODULE_WEIGHT_WITHOUT_CATERING
} from 'helpers/constants/Insights';

const initialState = () => ({
  features: [
    {
      title: 'googleMarketing',
      status: 'Disabled'
    },
    {
      title: 'freeDelivery',
      status: 'Disabled'
    },
    {
      title: 'menuPhotos',
      status: 'Disabled'
    },
    {
      title: 'managePrices',
      status: 'Disabled'
    },
    {
      title: 'discounts',
      status: 'Disabled'
    },
    {
      title: 'socialMedia',
      status: 'Disabled'
    },
    {
      title: 'virtualAnswering',
      status: 'Disabled'
    },
    {
      title: 'phoneOrdering',
      status: 'Disabled'
    },
    {
      title: 'emailMarketing',
      status: 'Disabled'
    },
    {
      title: 'websiteOptimization',
      status: 'Disabled'
    }
  ],
  modalInfo: {
    isOpen: false,
    feature: null
  },
  loading: false
});

const insightsGrowthReducer = createReducer(
  {
    [INSIGHTS_GROWTH_GET_INFO]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [INSIGHTS_GROWTH_GET_INFO_SUCCESS]: (state, payload) => {
      const payloadKeys = Object.keys(payload);
      const featuresKeys = Object.keys(INSIGHTS_GROWTH_FEATURES_INFO);
      const filteredPayloadKeys = featuresKeys.filter(
        (el) => payloadKeys.includes(el) && payload[el]
      );

      const hasCatering = filteredPayloadKeys.some(
        (item) =>
          item === 'catering' && payload[item]?.status !== 'NotAvailable'
      );

      const newFeatures = filteredPayloadKeys
        .filter(
          (item) =>
            !(item === 'catering' && payload[item]?.status === 'NotAvailable')
        )
        .map((item) => {
          return {
            title: item,
            status: payload[item]?.status,
            weight: hasCatering
              ? MODULE_WEIGHT_WITH_CATERING[item]
              : MODULE_WEIGHT_WITHOUT_CATERING[item]
          };
        });

      newFeatures.sort((a, b) => {
        if (a.status === b.status) {
          return 0;
        }
        return a.status === 'Enabled' ? 1 : -1;
      });

      return {
        ...state,
        features: newFeatures,
        loading: false
      };
    },
    [INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL]: (state, payload) => {
      return {
        ...state,
        modalInfo: payload
      };
    },
    [INSIGHTS_GROWTH_SET_LOADING]: (state, payload) => {
      return {
        ...state,
        loading: payload
      };
    },
    [INSIGHTS_GROWTH_CLEAR_INFO]: () => {
      return {
        ...initialState()
      };
    }
  },

  initialState()
);

export default insightsGrowthReducer;
