import styled from 'styled-components';

export const AutocompleteWrapper = styled.div`
  .MuiInputLabel-formControl {
    display: none;
  }
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
  }
  .MuiFilledInput-root {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  .MuiFilledInput-underline::before {
    display: none;
  }
  .MuiInputBase-input:focus {
    outline: none;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #232d3a;
  }
  .MuiAutocomplete-input {
    display: none;
  }
  .MuiAutocomplete-fullWidth {
    min-width: inherit;
  }
  .MuiAutocomplete-paper {
    margin: 40px 0;
  }
`;

export const AutoCompleteFieldWrapper = styled.div`
  .MuiAutocomplete-root {
    background-color: #f7f7fa;
    border: 1px solid #d5d8de;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #2f3d48;
    padding: 0 17px;
    outline: none;
    width: 100%;
    opacity: 1;
  }

  .MuiAutocomplete-hasClearIcon {
    padding: 0;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: none;
  }
  .MuiInputLabel-root {
    transform-origin: center;
    font-size: 14px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: inherit;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiOutlinedInput-root {
    &:hover {
      border: 0;
    }
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .PrivateNotchedOutline-root-7 {
    border-width: 0px;
    &:hover {
      border: 0;
      cursor: pointer;
    }
  }
  .PrivateNotchedOutline-root-3 {
    border: 0;
  }
  .PrivateNotchedOutline-root-1 {
    border: 0;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    &:hover {
      border: 0;
      cursor: pointer;
    }
  }
`;
