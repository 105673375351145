import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ReportsDataPickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 599px) {
    display: block;
  }

  .dataPickersContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 10px 40px 15px 0;

    @media (max-width: 1800px) {
      margin-right: 20px;
    }
    @media (max-width: 1700px) {
      margin-right: 0;
    }

    @media (max-width: 1330px) {
      flex-wrap: wrap;
    }
    @media (max-width: 1240px) {
      flex-wrap: nowrap;
    }
    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
    @media (max-width: 599px) {
      display: block;
    }
  }
  .infoReportsContainer {
    margin: 5px 0;
  }
`;

export const ReportTypePickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media (max-width: 1330px) {
    margin-bottom: 16px;
  }
  @media (max-width: 1250px) {
    margin-bottom: 0;
  }
  @media (max-width: 1080px) {
    margin-bottom: 16px;
  }
  @media (max-width: 599px) {
    display: block;
    margin-right: 0;
  }

  span {
    white-space: nowrap;
    margin-right: 17px;
    @media (max-width: 599px) {
      margin: 0 0 10px 0;
      display: block;
    }
  }

  .Say2EatDrop {
    min-width: 192px;
    max-width: 240px;
    @media (max-width: 599px) {
      max-width: 100% !important;
      width: 100%;
    }
  }
`;

export const ReportsDatepicker = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media (max-width: 599px) {
    display: block;
  }
`;
export const GenerateButton = styled.button`
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 2;
  color: #ff2d38;
  border-radius: 30px;
  padding: 9px 37px;
  border: 2px solid #ff2d38;
  outline: none;
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    margin: 10px 0;
  }
  @media (max-width: 599px) {
    margin: 15px 0 0 0;
  }

  span {
    margin-left: 13px;
    margin-right: 14px;
  }

  svg {
    position: absolute;
    display: none;
  }
  &.animate {
    span {
      margin-left: 27px;
      margin-right: 0;
    }
    svg.rot {
      display: block;
      left: 0;
      animation: 1s linear 0s normal none infinite running rot;
      -webkit-animation: 1s linear 0s normal none infinite running rot;
      width: 100px;
    }
    @keyframes rot {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes rot {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const ReportsInfo = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  color: #929cb9;
  padding-left: 5px;
  border-left: 2px solid #ff2d38;
`;
