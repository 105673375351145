export const isValueChanged = (where, what) => {
  if (where.find((item) => item === what)) {
    return where.filter((item) => item !== what);
  }
  return [...where, what];
};

export const isValueObjChanged = (where, whatKey, replacement) => {
  let objectItem;
  let objectIndex;

  where.forEach((item, index) => {
    if (item.id && item.id === whatKey) {
      objectItem = item;
      objectIndex = index;
    }
  });

  if (replacement) {
    if (objectItem) {
      where.splice(objectIndex, 1, replacement);
      return where;
    }
    return [...where, 'TimePeriodChanged'];
  }

  if (objectItem) {
    where.splice(objectIndex, 1);
    return where;
  }

  return [...where, 'TimePeriodRemoved'];
};
