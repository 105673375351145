/* eslint-disable react/prop-types */
import React from 'react';
import PublishDrop from './PublishDrop/styles';
import Body0 from '../../typography/Body0';
import PublishOverlay from './PublishOverlay/styles';

const PublishMessage = ({
  titleText,
  iconMessage,
  buttonCancel,
  buttonConfirm
}) => {
  return (
    <>
      <PublishDrop className='PublishDrop'>
        <div className='PublishDropInfo'>
          {iconMessage}
          <Body0 className='PublishDropText'>{titleText}</Body0>
        </div>
        <div className='PublishDropActions'>
          {buttonCancel}
          {buttonConfirm}
        </div>
      </PublishDrop>
      <PublishOverlay />
    </>
  );
};

export default PublishMessage;
