import styled from 'styled-components';

export const SetupSection = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;
export const SetupHolder = styled.div`
  flex: 1 1 auto;
  padding: 60px 20px;
`;
export const SetupContainer = styled.div`
  max-width: 740px;
  width: 100%;
  margin: 0 auto 40px auto;

  &.calculatorPosition {
    margin: 0 45% 40px auto;
  }
  @media (max-width: 1500px) {
    max-width: 660px;
    margin: 0 auto 40px auto;
  }

  &.SecondStep {
    max-width: 600px;
    position: relative;
    left: -70px;
    @media (max-width: 1600px) {
      max-width: 550px;
    }
    @media (max-width: 1500px) {
      left: -30px;
    }
    @media (max-width: 1300px) {
      left: 0;
      max-width: 660px;
    }
  }
`;
export const SetupHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  @media (max-width: 599px) {
    display: block;
  }
`;
export const SetupLogo = styled.img`
  display: inline-block;
  max-width: 173px;
  width: 100%;
  flex: 0 0 auto;
  margin-right: 10px;
  position: relative;
  top: -7px;
  @media (max-width: 599px) {
    top: 0;
  }
`;
export const SetupFieldset = styled.div`
  margin-top: 20px;

  &.mTop40 {
    margin-top: 40px;
  }
`;
export const SetupCalculator = styled.div`
  position: relative;
`;
export const SetupCommission = styled.div`
  position: absolute;
  top: 50%;
  left: calc(100% + 40px);
  transform: translateY(-50%);
  max-width: 570px;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  border-radius: 10px;
  padding: 65px 70px 100px 70px;

  @media (max-width: 1400px) {
    left: calc(100% + 15px);
    max-width: 520px;
    padding: 50px 40px 80px 40px;
  }
  @media (max-width: 1250px) {
    position: relative;
    top: inherit;
    left: inherit;
    transform: none;
    max-width: 100%;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .SaveMoney {
    margin: 70px -70px 0 -70px;
    @media (max-width: 1400px) {
      margin: 50px -40px 0 -40px;
    }
    @media (max-width: 1250px) {
      margin: 50px -20px 0 -20px;
    }
  }
`;
export const SetupActions = styled.div`
  display: flex;
  align-items: center;
  margin: 50px -10px 0 -10px;
  @media (max-width: 1250px) {
    justify-content: center;
  }
  @media (max-width: 599px) {
    margin: 40px 0 0 0;
    display: block;
  }
  & > * {
    margin: 0 10px;
    &.ButtonPrimary {
      max-width: 390px;
      width: 100%;
      @media (max-width: 599px) {
        max-width: 100%;
      }
      &.ButtonPrimaryCenter {
        @media (max-width: 1250px) {
          display: block;
          margin: 0 auto;
        }
      }
    }
    @media (max-width: 599px) {
      margin: 10px 0 0 0;
      width: 100%;
      display: block;
    }
  }
  &.SecondStep {
    margin-top: 60px;
    max-width: 640px;
    .ButtonOutline,
    .ButtonPrimary {
      width: 50%;
      @media (max-width: 599px) {
        width: 100%;
      }
    }
  }

  &.CenteredBtn {
    margin: 60px auto 0;
  }
`;
export const SetupSidebar = styled.div`
  flex: 0 0 auto;
  width: 550px;
  padding: 140px 136px;
  background: radial-gradient(
    109.22% 109.22% at 91.81% 109.22%,
    #e8ecf1 0%,
    #ffffff 54.27%,
    #f4f7fc 100%
  );
  @media (max-width: 1440px) {
    width: 500px;
    padding: 140px 90px;
  }
  @media (max-width: 1250px) {
    display: none;
  }

  .ProgressBarContainer {
    margin-top: 15px;
  }
`;
