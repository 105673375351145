import React, {Component} from 'react';
import T from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogAction,
  DialogBody,
  DialogHolder
} from '../../../dashboard/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import {
  CREATE_CATEGORY_MODAL,
  CREATE_ITEM_MODAL,
  CREATE_MODIFIER_GROUP_MODAL,
  CREATE_MODIFIER_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../../store/menuEditorModals/action';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import Body3 from '../../../../../components/typography/Body3';

class SelectItemModal extends Component {
  constructor(props) {
    super(props);

    const {selectedItems, itemList} = this.props;

    this.state = {
      activeItem: itemList[0],
      selectedItems: [...(selectedItems || [])],
      modalName: ''
    };
  }

  componentDidMount() {
    const {itemType} = this.props;
    // const action = this.getActionByType(itemType);
    this.getModalNameByType(itemType);

    // action(queryParams);
  }

  getModalNameByType = (type) => {
    const name = {
      modifierGroupParent: 'Parent Modifier Groups',
      modifierGroup: 'Modifier Groups',
      category: 'Categories',
      item: 'Menu Items',
      modifier: 'Modifiers'
    };
    this.setState({modalName: name[type]});
    return name[type];
  };

  onItemChange = (item) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let selectedItems = [...this.state.selectedItems];

    const found = selectedItems.find((selectedItem) => {
      if (selectedItem.PosId) {
        return selectedItem.PosId === item.PosId;
      }
      return selectedItem.MerchantSectionId === item.MerchantSectionId;
    });
    if (found) {
      if (item.PosId) {
        selectedItems = selectedItems.filter((el) => el.PosId !== item.PosId);
      } else {
        selectedItems = selectedItems.filter(
          (el) => el.MerchantSectionId !== item.MerchantSectionId
        );
      }
    } else {
      selectedItems.push(item);
    }
    // TODO: возможно тут надо отсортировать массив, если массив будет состоять не только из айдишников а например айди и имя

    this.setState({selectedItems});
  };

  onItemSelect = (item) => {
    this.setState({activeItem: item});
  };

  onDone = () => {
    this.props.callBack(this.state.selectedItems);
    this.props.closeModal();
  };

  onCancel = () => {
    this.props.closeModal();
  };

  onOpenCreateModal = () => {
    const {itemType} = this.props;
    if (itemType === 'item') {
      this.props.openCreateItemModal({open: true});
      this.props.closeSelectMenuItemModal({open: false});
    } else if (itemType === 'modifier') {
      this.props.openCreateModifierModal({open: true});
      this.props.closeSelectMenuItemModal({open: false});
    } else if (itemType === 'category') {
      this.props.openCreateCategoryModal({open: true});
      this.props.closeSelectMenuItemModal({open: false});
    } else if (itemType === 'modifierGroup') {
      this.props.openCreateModifierGroupModal({open: true});
      this.props.closeSelectMenuItemModal({open: false});
    }
  };

  renderCreateModalButtonLabel = () => {
    return {
      modifier: 'Create a Modifier',
      item: 'Create an Item',
      modifierGroup: 'Create a Modifier Group',
      category: 'Create a Category'
    };
  };

  renderDescriptionLabel = () => {
    return {
      modifier: 'modifiers',
      item: 'items',
      modifierGroup: 'modifier groups',
      category: 'categories'
    };
  };

  render() {
    const {activeItem, selectedItems, modalName} = this.state;
    const {itemList, itemType} = this.props;
    const infoType =
      itemType === 'modifierGroupParent' ? 'modifierGroup' : itemType;

    return (
      <Dialog
        open={this.props.open}
        scroll='body'
        onClose={() => this.onCancel()}
      >
        <DialogContainer className='DialogLg'>
          <DialogClose onClick={() => this.onCancel()} />
          <DialogTitle>
            Select <span>{modalName && modalName}</span>
          </DialogTitle>
          <DialogBody>
            <DialogHolder>
              {itemList.length ? (
                <>
                  <LeftSideBar
                    itemList={itemList}
                    selectedItems={selectedItems}
                    onItemSelect={this.onItemSelect}
                    onItemChange={this.onItemChange}
                    activeItem={activeItem}
                    itemType={itemType}
                  />
                  <RightSideBar activeItem={activeItem} itemType={infoType} />
                </>
              ) : (
                <Body3 className='text-center'>
                  No {this.renderDescriptionLabel()[itemType]} yet created.
                  Please, create a new one.
                </Body3>
              )}
            </DialogHolder>
            <DialogAction className='DialogActionFlexCenterAndMargin'>
              {itemList.length ? (
                <ButtonPrimary
                  // className='ButtonMin'
                  onClick={() => this.onDone()}
                  type='submit'
                >
                  <span>Apply</span>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  type='submit'
                  className='MaxContentWidth'
                  onClick={this.onOpenCreateModal}
                >
                  <span>{this.renderCreateModalButtonLabel()[itemType]}</span>
                </ButtonPrimary>
              )}
            </DialogAction>
          </DialogBody>
        </DialogContainer>
      </Dialog>
    );
  }
}

SelectItemModal.defaultProps = {selectedItems: []};
SelectItemModal.propTypes = {
  itemType: T.string.isRequired,
  itemList: T.array.isRequired,
  open: T.bool.isRequired,
  callBack: T.func.isRequired,
  closeModal: T.func.isRequired,
  selectedItems: T.array,
  openCreateItemModal: T.func.isRequired,
  closeSelectMenuItemModal: T.func.isRequired,
  openCreateModifierModal: T.func.isRequired,
  openCreateCategoryModal: T.func.isRequired,
  openCreateModifierGroupModal: T.func.isRequired
};

const mapStateToProps = ({
  menuEditorModalsReducer: {
    isSelectItemModalOpen,
    selectItemModalParams: {
      itemType,
      queryParams,
      selectedItems,
      callBack,
      itemList,
      loading,
      closeModal
    }
  }
}) => {
  return {
    open: isSelectItemModalOpen,
    itemType,
    queryParams,
    selectedItems,
    callBack,
    loading,
    closeModal,
    itemList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openCreateItemModal: bindActionCreators(CREATE_ITEM_MODAL, dispatch),
    closeSelectMenuItemModal: bindActionCreators(
      SELECT_ITEM_MODAL_OPEN,
      dispatch
    ),
    openCreateModifierModal: bindActionCreators(
      CREATE_MODIFIER_MODAL,
      dispatch
    ),
    openCreateCategoryModal: bindActionCreators(
      CREATE_CATEGORY_MODAL,
      dispatch
    ),
    openCreateModifierGroupModal: bindActionCreators(
      CREATE_MODIFIER_GROUP_MODAL,
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectItemModal);
