import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {useDispatch} from 'react-redux';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {PlayerWrapper} from '../../../../../components/PlayerWrapper/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';

export default function ModalReportVideo() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [enableCloseButton, setEnableCloseButton] = React.useState(false);
  const [showCloseButton, setShowCloseButton] = React.useState(false);
  return (
    <Dialog open={open} scroll='body'>
      <DialogContainer className='DialogVideo'>
        <DialogClose
          className={cn('DialogClose xlRed', {
            disable: !enableCloseButton
          })}
          onClick={() => {
            setOpen(false);
          }}
        />
        <div
          className={cn('DialogVideoContainer', {
            show: showCloseButton
          })}
        >
          <ButtonPrimary
            className='ButtonCloseVideo'
            onClick={() => {
              setOpen(false);
            }}
          >
            <span>Close video</span>
          </ButtonPrimary>
          <PlayerWrapper className='player-wrapper'>
            <ReactPlayer
              className='react-player'
              url='https://say-2-eat.wistia.com/medias/y5podflfso'
              width='100%'
              height='100%'
              onEnded={() => {
                setEnableCloseButton(true);
                setShowCloseButton(true);
              }}
              onPlay={() => {
                setShowCloseButton(false);
              }}
              onSeek={() => {
                setShowCloseButton(false);
              }}
              controls
              muted={false}
              volume={1}
              config={{
                options: {
                  autoPlay: false,
                  volume: 1,
                  muted: false,
                  playbar: true,
                  settingsControl: false
                }
              }}
            />
          </PlayerWrapper>
        </div>
      </DialogContainer>
    </Dialog>
  );
}

ModalReportVideo.propTypes = {};
