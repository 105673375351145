import React from 'react';

export const IconPercentBig = () => (
  <svg
    width='22'
    height='24'
    viewBox='0 0 22 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.41884 21.5337C1.15239 21.2667 1.00342 20.9023 1.00342 20.5216C1.00342 20.1339 1.15122 19.7777 1.42757 19.5008L18.4194 2.47465C18.6858 2.20768 19.0493 2.05859 19.4287 2.05859C19.8082 2.05859 20.1716 2.20768 20.4381 2.47465C20.7045 2.74164 20.8535 3.10607 20.8535 3.48674C20.8535 3.8674 20.7045 4.23183 20.4381 4.49882L3.43751 21.5337C3.17108 21.8007 2.80762 21.9498 2.42818 21.9498C2.04873 21.9498 1.68528 21.8007 1.41884 21.5337Z'
      fill='#929CB9'
      stroke='white'
      strokeWidth='0.5'
    />
    <path
      d='M10.6486 5.12183C10.6486 7.80736 8.46599 9.99365 5.7871 9.99365C3.10822 9.99365 0.925598 7.80736 0.925598 5.12183C0.925598 2.4363 3.10822 0.25 5.7871 0.25C8.46599 0.25 10.6486 2.4363 10.6486 5.12183ZM5.7871 3.11502C4.68033 3.11502 3.78384 4.01406 3.78384 5.12183C3.78384 6.2296 4.68033 7.12863 5.7871 7.12863C6.89387 7.12863 7.79036 6.2296 7.79036 5.12183C7.79036 4.01406 6.89387 3.11502 5.7871 3.11502Z'
      fill='#929CB9'
      stroke='white'
      strokeWidth='0.5'
    />
    <path
      d='M11.2268 18.8777C11.2268 16.1922 13.4095 14.0059 16.0883 14.0059C18.7672 14.0059 20.9499 16.1922 20.9499 18.8777C20.9499 21.5632 18.7672 23.7495 16.0883 23.7495C13.4095 23.7495 11.2268 21.5632 11.2268 18.8777ZM16.0883 20.8932C17.1951 20.8932 18.0916 19.9942 18.0916 18.8864C18.0916 17.7787 17.1951 16.8796 16.0883 16.8796C14.9816 16.8796 14.0851 17.7787 14.0851 18.8864C14.0851 19.9942 14.9816 20.8932 16.0883 20.8932Z'
      fill='#929CB9'
      stroke='white'
      strokeWidth='0.5'
    />
  </svg>
);

export default IconPercentBig;
