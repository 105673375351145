import React from 'react';

export const IconModifierGroup = () => (
  <svg
    width='24'
    height='28'
    viewBox='0 0 24 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9973 1.89766L15.6067 4.00312L16.4184 2.58125L11.9973 0L7.57608 2.58125L8.3878 4.00312L11.9973 1.89766Z'
      fill='#929CB9'
    />
    <path
      d='M1.62886 7.95107L5.23832 5.84603L4.42659 4.41898L0 6.9997V12.1612H1.62886V7.95107Z'
      fill='#929CB9'
    />
    <path
      d='M1.62886 15.8347H0V20.9961L4.42118 23.5769L5.23291 22.1553L1.62345 20.0502V15.8347H1.62886Z'
      fill='#929CB9'
    />
    <path
      d='M11.9974 26.0977L8.38789 23.9922L7.57617 25.4196L11.9974 28.0008L16.4185 25.4196L15.6068 23.9922L11.9974 26.0977Z'
      fill='#929CB9'
    />
    <path
      d='M22.3656 20.0444L18.7561 22.1494L19.5678 23.571L23.989 20.9903V15.8288H22.3602L22.3656 20.0444Z'
      fill='#929CB9'
    />
    <path
      d='M19.5734 4.41895L18.7617 5.846L22.3711 7.95104V12.1611H24V6.99967L19.5734 4.41895Z'
      fill='#929CB9'
    />
    <path
      d='M15.0008 16.8713H5.16797V7.04398H14.9953V16.8713H15.0008ZM6.83594 15.2088H13.3328V8.71194H6.83594V15.2088Z'
      fill='#929CB9'
    />
    <path
      d='M19.0805 20.9563H9.25317V11.1289H19.0805V20.9563ZM10.9157 19.2883H17.4125V12.7914H10.9157V19.2883Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconModifierGroup;
