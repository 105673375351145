import BaseInputMask from 'react-input-mask';
import styled from 'styled-components';

const StyledInputMask = styled(BaseInputMask)`
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #172751;
  padding: 0 17px;
  height: 56px;
  outline: none;
  width: 100%;
  -webkit-appearance: none;

  &.error {
    border: 1px solid #ba0302;
  }

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.FieldNumber {
    width: 110px;
  }

  &.mLeft {
    margin-left: 16px;
  }

  &.Sm {
    width: 70px;
  }

  &.widthAuto {
    flex: 1 1 auto;
  }

  &::placeholder {
    color: #929cb9;
  }
`;

export default StyledInputMask;
