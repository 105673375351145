import styled from 'styled-components';

const DialogAction = styled.div`
  flex: 0 0 100%;
  padding: 10px;
  &.dFlexCenter {
    display: flex;
    justify-content: center;
  }
  &.w50 {
    flex: 0 0 50%;
    @media (max-width: 520px) {
      flex: 0 0 100%;
    }
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.w60 {
    flex: 0 0 60%;
  }
  &.w40 {
    flex: 0 0 40%;
  }
  &.w30 {
    flex: 0 0 30%;
  }
  * {
    width: 100%;
  }
  &.wAuto {
    flex: 1 0 auto;
  }
  &.pL0 {
    padding-left: 0;
  }
  &.pR0 {
    padding-right: 0;
  }
  &.w262 {
    width: 262px;
  }
  &.wAuto0 {
    flex: 0 0 auto;
  }
`;

export default DialogAction;
