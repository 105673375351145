import {put, takeEvery, select, takeLatest} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  getCountriesForStepsApi,
  getStepsSelfCreateApi,
  postStepSelfCreateApi
} from '../../helpers/services/api/user';
import {
  GET_STEPS,
  GET_STEPS_SUCCESS,
  POST_STEP_SUCCESS,
  POST_STEP,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS
} from './action';
import {getAccountStepsStatus} from '../global/account/saga';
import {getUserData} from '../global/user/saga';

const userId = ({user}) => user.userData?.id;
const currentState = ({selfCreateAccountReducer}) => selfCreateAccountReducer;

function* setupAccount({payload: {payload, successCallback}}) {
  try {
    const {PendingData, finalSetup} = yield select(currentState);
    const {data} = yield postStepSelfCreateApi(
      {...payload, PendingData},
      finalSetup
    );
    if (payload.RestaurantURL) {
      yield getUserData();
    }
    if (data) {
      yield put(POST_STEP_SUCCESS(data));
      if (successCallback) successCallback();
    }
    if (finalSetup) {
      yield getAccountStepsStatus();
      yield getUserData();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getCountries({payload}) {
  try {
    const {data} = yield getCountriesForStepsApi(payload);
    if (data) {
      yield put(GET_COUNTRIES_SUCCESS(data.docs));
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* getSetupAccount() {
  try {
    const id = yield select(userId);
    const {step} = yield select(currentState);
    if (step === 0) {
      yield getCountries({});
    }
    const {data} = yield getStepsSelfCreateApi(id);
    if (data) {
      yield put(GET_STEPS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* selfCreateAccountSaga() {
  yield takeLatest(POST_STEP, setupAccount);
  yield takeEvery(GET_STEPS, getSetupAccount);
  yield takeEvery(GET_COUNTRIES, getCountries);
}

export default selfCreateAccountSaga;
