import styled from 'styled-components';

const BorderedButton = styled.button`
  display: inline-block;
  font-family: 'Poppins';
  position: relative;
  font-style: normal;
  font-weight: bold;
  background-color: transparent;
  font-size: 18px;
  line-height: 1;
  color: #ff2d38;
  border-radius: 30px;
  padding: 14px 18px;
  outline: none;
  cursor: pointer;
  border: 2px solid #ff2d38;
  box-shadow: 0px 8px 16px rgba(21, 206, 117, 0);
  transition-property: background-color;
  transition-duration: 0.3s;

  &:hover {
    background-color: rgba(255, 45, 56, 0.06);
  }
  &.disabled {
    background-image: none;
    background-color: #fff;
    box-shadow: none;
    cursor: default;
    border-color: #ededed;
    pointer-events: none;
    opacity: 0.5;
  }

  &.active {
    background-color: #ff2d38;
    color: #fff;
  }
  &.ButtonSm {
    font-weight: 500;
    font-size: 14px;
    padding: ${(props) =>
      props.withIcon ? '10px 19px 10px 48px' : '10px 19px'};
  }
  &.ButtonLarge {
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    padding: 8px 45px;
  }
  &.ToastButton {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border: 2px solid #fff;
    padding: 10px 15px;
  }
  &.ButtonMd {
    font-weight: 500;
    font-size: 16px;
    padding: ${(props) =>
      props.withIcon ? '10px 24px 10px 50px' : '10px 24px'};
    @media (max-width: 1000px) {
      font-size: 14px;
    }
  }
  &.forgot-password {
    width: 45%;
    margin: auto;
  }
  &.npm,
  &.ButtonLg {
    padding: 17px 24px;
  }
  &.mR20 {
    margin-right: 20px;
  }
  &.eventOn {
    pointer-events: auto;
  }
`;

export default BorderedButton;
