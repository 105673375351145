import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {
  DialogAction,
  DialogBody,
  DialogField,
  FieldInput,
  FieldLabel
} from '../../../dashboard/styles';
import DialogFields from '../../../../../components/DialogFields/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import NewOwnerSchema from '../../../../../helpers/services/formValidationSchemas/NewOwnerSchema';
import {
  ADD_OWNER,
  CLEAR_OWNER_CREATION_MERCHANT_ID
} from '../../../../../store/merchants/action';
import CheckboxS2E from '../../../../../components/Checkbox';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';

export default function AddOwner() {
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(CLEAR_OWNER_CREATION_MERCHANT_ID());
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      flag: false
    },
    enableReinitialize: true,
    validationSchema: NewOwnerSchema,
    onSubmit: (values) => {
      dispatch(ADD_OWNER({form: values, onSuccess: closeDialog}));
    }
  });

  return (
    <Dialog open onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomLg'>
          <span>Owner Setup</span>
        </DialogTitle>
        <DialogBody>
          <DialogFields>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>Email</FieldLabel>
              <FieldInput
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                className={cn({
                  error: formik.errors.email && formik.touched.email
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.email && formik.errors.email}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>First Name</FieldLabel>
              <FieldInput
                name='first_name'
                onChange={formik.handleChange}
                value={formik.values.first_name}
                className={cn({
                  error: formik.errors.first_name && formik.touched.first_name
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.first_name && formik.errors.first_name}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='DialogField w100 marginBottomXs'>
              <FieldLabel className='inline-label'>Last Name</FieldLabel>
              <FieldInput
                name='last_name'
                onChange={formik.handleChange}
                value={formik.values.last_name}
                className={cn({
                  error: formik.errors.last_name && formik.touched.last_name
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.last_name && formik.errors.last_name}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='w100'>
              <CheckboxS2E
                name='flag'
                label='Is No_menu user'
                variant='green'
                checked={formik.values.flag}
                onChange={formik.handleChange}
              />
            </DialogField>
          </DialogFields>
          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>Add Owner</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}
