import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from '@material-ui/core';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import Headline from '../../../components/Headline/styles';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import {DashboardHeader} from '../../../components/DashboardHeader/styles';
import {
  restaurantSloganSchema,
  virtualNumberSchema
} from '../../../helpers/services/formValidationSchemas/VirtualNumberSchema';
import {
  ACTIVATE_VN,
  CREATE_TWILIO,
  CREATE_VN,
  GET_INFO_VN,
  SET_SHOW_MODAL,
  SET_STATUS_FOR_EDIT,
  UPDATE_VN,
  DISABLED_VN,
  SET_IS_EDIT_FLAG
} from '../../../store/virtualNumber/action';
import AddVirtualNumber from './AddVirtualNumber';
import AddSlogan from './AddSlogan';
import ActivateVirtualNumber from './ActivateVirtualNumber';
import virtualNumberData from '../../../store/virtualNumber/selectors';
import SuccessActivateVN from './Modals/SuccessActivateVN';
import {userInfo} from '../../../store/global/account/selectors';

const VirtualNumber = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector(userInfo);
  useEffect(() => {
    dispatch(GET_INFO_VN());
    dispatch(SET_IS_EDIT_FLAG());
  }, [locationId]);

  const {
    info,
    loading,
    isShowSuccessModal,
    isEdit,
    phoneNumberUpdated
  } = virtualNumberData();
  const locationName = useSelector(
    (state) => state.user?.activeLocation?.FullName
  );

  const getInitialValues = (status, phone) => {
    switch (status) {
      case 'CREATED':
        return {
          locationName: info.locationName || '',
          slogan: info.slogan || '',
          languages: info.languages || []
        };
      case 'ACTIVATED':
      case 'PENDING':
        return {};
      default:
        return {
          CountryCode: '+1',
          Phone: '',
          correctNumber: !!phone
        };
    }
  };

  const getValidations = (status) => {
    switch (status) {
      case 'CREATED':
        return restaurantSloganSchema;
      case 'ACTIVATED':
      case 'PENDING':
        return null;
      default:
        return virtualNumberSchema;
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(info.status, info.phone),
    validate: getValidations(info.status),
    onSubmit: (values, {resetForm}) => {
      if (info.status === 'CREATED') {
        if (isEdit) {
          dispatch(
            UPDATE_VN({
              slogan: values.slogan,
              locationName: values.locationName,
              languages: values.languages
            })
          );
        } else {
          dispatch(
            CREATE_TWILIO({
              slogan: values.slogan,
              locationName: values.locationName,
              languages: values.languages
            })
          );
        }
      }

      if (info.status === 'PENDING') {
        dispatch(ACTIVATE_VN());
      }

      if (!info.status) {
        const phone = values.correctNumber
          ? info.phone
          : values.CountryCode + values.Phone;

        if (isEdit) {
          if (values.correctNumber) {
            if (info.isActive) {
              dispatch(
                SET_STATUS_FOR_EDIT({isEdit: false, status: 'ACTIVATED'})
              );
            } else
              dispatch(SET_STATUS_FOR_EDIT({isEdit: true, status: 'CREATED'}));
          } else dispatch(UPDATE_VN({phone}));
        } else {
          dispatch(CREATE_VN({phone}));
        }
      }
      resetForm();
    }
  });

  const getContent = (data) => {
    switch (data.status) {
      case 'CREATED':
        return (
          <AddSlogan
            isActive={data.isActive}
            formik={formik}
            isEdit={isEdit}
            backAction={() =>
              dispatch(SET_STATUS_FOR_EDIT({status: '', isEdit: true}))
            }
            backFromEditActiveVNSlogan={() =>
              dispatch(
                SET_STATUS_FOR_EDIT({status: 'ACTIVATED', isEdit: false})
              )
            }
          />
        );
      case 'ACTIVATED':
      case 'PENDING':
        return (
          <ActivateVirtualNumber
            isEdit={isEdit}
            virtualNumber={data.twilioPhone}
            status={data.status}
            formik={formik}
            twilioAudioUrl={data.twilioAudioUrl}
            editAction={() =>
              dispatch(SET_STATUS_FOR_EDIT({status: '', isEdit: true}))
            }
            editSlogan={() =>
              dispatch(SET_STATUS_FOR_EDIT({status: 'CREATED', isEdit: true}))
            }
            editPhoneNumber={() =>
              dispatch(SET_STATUS_FOR_EDIT({status: '', isEdit: true}))
            }
            disabledVN={() => dispatch(DISABLED_VN())}
            phoneNumberUpdated={phoneNumberUpdated}
          />
        );
      default:
        return (
          <AddVirtualNumber
            isActive={data.isActive}
            formik={formik}
            phone={data.phone}
            isEdit={isEdit}
            backFromEditActiveVN={() =>
              dispatch(
                SET_STATUS_FOR_EDIT({status: 'ACTIVATED', isEdit: false})
              )
            }
          />
        );
    }
  };

  const Loader = () => (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <CircularProgress className='CircularProgress' />
    </Box>
  );

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline className='mTop5'>Virtual Answering</Headline>
      </DashboardHeader>
      {isShowSuccessModal && (
        <SuccessActivateVN onClose={() => dispatch(SET_SHOW_MODAL(false))} />
      )}
      {loading ? (
        <Loader />
      ) : (
        <DashboardInner className='paddingLg'>
          {getContent(info)}
        </DashboardInner>
      )}
    </DashboardContent>
  );
};

export default VirtualNumber;
