import {createReducer} from 'redux-act';
import {
  MAKE_REFUND,
  OPEN_CANCELLATION_REFUND_MODAL
} from 'store/global/orders/CancellationRefund/action';

const initialState = () => ({
  isOpenModal: false,
  invoiceId: null,
  showCannotDeliveryText: false,
  loadingRefund: false,
  orderId: null
});

const cancellationRefundReducer = createReducer(
  {
    [MAKE_REFUND]: (state) => {
      return {
        ...state,
        loadingRefund: true
      };
    },
    [OPEN_CANCELLATION_REFUND_MODAL]: (state, payload) => {
      return {
        ...state,
        isOpenModal: payload.isOpenModal,
        invoiceId: payload.invoiceId,
        showCannotDeliveryText: payload.showCannotDeliveryText,
        loadingRefund: payload.loadingRefund || false,
        orderId: payload.orderId || null
      };
    }
  },
  initialState()
);

export default cancellationRefundReducer;
