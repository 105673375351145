import {useSelector} from 'react-redux';

const authData = () =>
  useSelector((state) => {
    return {
      Email: state.authReducer.userEmail,
      isEmailEntered: state.authReducer.isEmailEntered
    };
  });

export default authData;
