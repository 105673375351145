import styled from 'styled-components';
import IconCalendar from '../../assets/icon-calendar.svg';
import IconSelect from '../../assets/icon-dropdown-dark.svg';

const DatepickerContainer = styled.div`
  display: inline-block;
  .MuiPickersStaticWrapper-root {
    .MuiToolbar-root {
      display: none;
    }
  }
  .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-root {
    height: 56px;
    border: 1px solid #d5d8de;
    background: #f7f7fa;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      left: inherit;
      border: 0;
      top: 50%;
      margin-top: -11px;
      width: 24px;
      height: 24px;
      right: 12px;
      content: '';
      background-image: url('${IconSelect}');
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: center;
      z-index: 1;
      transform: scale(1);
    }
    &:hover {
      border-color: #d5d8de;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  .MuiInputBase-input {
    padding: 0 44px 0 56px;
    cursor: pointer;
    height: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #232d3a;
    width: 130px;
    display: inline-block;
    background-image: url('${IconCalendar}');
    background-repeat: no-repeat;
    background-position: center left 21px;

    &::placeholder {
      color: #232d3a;
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(65px, 16px) scale(1);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #232d3a;
    transition: 0s;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
    transform: translate(65px, 16px) scale(1);
  }

  .MuiFormHelperText-root {
    display: none;
  }

  .MuiInputAdornment-root {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 2;
    margin: 0;
    .MuiButtonBase-root {
      width: 100%;
      height: 100%;
    }
  }
`;

export default DatepickerContainer;
