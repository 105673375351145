import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import {Select} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';

export default function CCRefundSelect({
  values,
  errors,
  touched,
  setFieldValue,
  options,
  selectValue
}) {
  return (
    <Box sx={{minWidth: 120}} className='Say2EatDrop'>
      <FormControl
        fullWidth
        variant='outlined'
        className={cn({
          error: errors[selectValue] && touched[selectValue] && 'error'
        })}
      >
        <Select
          displayEmpty
          id='refund-didReceiveOrderValue-select'
          value={values[selectValue]}
          name={selectValue}
          onChange={(e) => setFieldValue(selectValue, e.target.value)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {options.map((item) => (
            <MenuItem
              className='reportTypeItem'
              key={nanoid()}
              value={item.name}
              disableRipple
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <ErrorSignWrapper className='positionBottom'>
          <ErrorSign>{touched[selectValue] && errors[selectValue]}</ErrorSign>
        </ErrorSignWrapper>
      </FormControl>
    </Box>
  );
}

CCRefundSelect.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectValue: PropTypes.string.isRequired
};
