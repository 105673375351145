import {createAction} from 'redux-act';

export const GET_MERCHANTS_URLS = createAction('merchants/GET_MERCHANTS_URLS');
export const ACTIVATE_MERCHANT = createAction('merchants/ACTIVATE_MERCHANT');
export const VERIFY_MERCHANT = createAction('merchants/VERIFY_MERCHANT');
export const DEPLOY_OB_AUTOMATION = createAction(
  'merchants/DEPLOY_OB_AUTOMATION'
);
export const FACEBOOK_INFO_MODAL = createAction(
  'merchants/FACEBOOK_INFO_MODAL'
);
export const GET_MERCHANTS_URLS_SUCCESS = createAction(
  'merchants/GET_MERCHANTS_URLS_SUCCESS'
);
export const GET_MERCHANTS_URLS_ERROR = createAction(
  'merchants/GET_MERCHANTS_URLS_ERROR'
);
export const GET_ACTIVE_MERCHANTS = createAction(
  'merchants/GET_ACTIVE_MERCHANTS'
);

export const GET_FEATURE_FLAGS = createAction('merchants/GET_FEATURE_FLAGS');
export const GET_FEATURE_FLAGS_SUCCESS = createAction(
  'merchants/GET_FEATURE_FLAGS_SUCCESS'
);
export const PAGE_CHANGE = createAction('merchants/PAGE_CHANGE');
export const GET_MERCHANT = createAction('merchants/GET_MERCHANT');
export const GET_UNASSIGNED_MENU_QUEUE = createAction(
  'merchants/GET_UNASSIGNED_MENU_QUEUE'
);
export const GET_UNASSIGNED_MENU_QUEUE_SUCCESS = createAction(
  'merchants/GET_UNASSIGNED_MENU_QUEUE_SUCCESS'
);
export const CHANGE_NEW_MERCHANT = createAction(
  'merchants/CHANGE_NEW_MERCHANT'
);
export const UPDATE_ACTIVE_MERCHANT = createAction(
  'merchants/UPDATE_ACTIVE_MERCHANT'
);
export const UPDATE_ACTIVE_MERCHANT_SUCCESS = createAction(
  'merchants/UPDATE_ACTIVE_MERCHANT_SUCCESS'
);
export const CHANGE_LOCATION = createAction('merchants/CHANGE_LOCATION');
export const CHANGE_LOCATION_SUCCESS = createAction(
  'merchants/CHANGE_LOCATION_SUCCESS'
);
export const ACTIVATE_LOCATION = createAction('merchants/ACTIVATE_LOCATION');
export const SEARCH_CHANGE = createAction('merchants/SEARCH_CHANGE');
export const SORT_CHANGE = createAction('merchants/SORT_CHANGE');
export const GET_MERCHANT_SUCCESS = createAction(
  'merchants/GET_MERCHANT_SUCCESS'
);
export const CLEAR_MERCHANT = createAction('merchants/CLEAR_MERCHANT');
export const CLEAR_FILTERS = createAction('merchants/CLEAR_FILTERS');

export const CHANGE_USER = createAction('merchants/CHANGE_USER');
export const CHANGE_USER_SUCCESS = createAction(
  'merchants/CHANGE_USER_SUCCESS'
);
export const ADD_OWNER = createAction('merchants/ADD_OWNER');
export const CLEAR_NEW_OWNER = createAction('merchants/CLEAR_NEW_OWNER');
export const SET_OWNER_CREATION_MERCHANT_ID = createAction(
  'merchants/SET_OWNER_CREATION_MERCHANT_ID'
);
export const CLEAR_OWNER_CREATION_MERCHANT_ID = createAction(
  'merchants/CLEAR_OWNER_CREATION_MERCHANT_ID'
);

export const START_TEST_AUTOMATION = createAction(
  'merchants/START_TEST_AUTOMATION'
);

export const RUN_CHECK_AUTOMATION_TEST_RESULTS_JOB = createAction(
  'merchants/RUN_CHECK_AUTOMATION_TEST_ßRESULTS_JOB'
);

export const STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB = createAction(
  'merchants/STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB'
);

export const GET_TEST_AUTOMATION_RESULT = createAction(
  'merchants/GET_TEST_AUTOMATION_RESULT'
);

export const SET_TEST_AUTOMATION_RESULT = createAction(
  'merchants/SET_TEST_AUTOMATION_RESULT'
);

export const SET_TEST_AUTOMATION_JOB_ID = createAction(
  'merchants/SET_TEST_AUTOMATION_JOB_ID'
);
export const SET_SALES_PACKAGE = createAction('merchants/SET_SALES_PACKAGE');
export const SET_SALES_PACKAGE_SUCCESS = createAction(
  'merchants/SET_SALES_PACKAGE_SUCCESS'
);
export const SET_GMB_REVIEW_LINK = createAction(
  'merchants/SET_GMB_REVIEW_LINK'
);
export const SET_GMB_REVIEW_LINK_SUCCESS = createAction(
  'merchants/SET_GMB_REVIEW_LINK_SUCCESS'
);
