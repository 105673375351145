import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import {
  NewDispatchAlert,
  OrderAlert,
  TierRestaurant
} from 'routes/App/Managers/realTimeBoardV2/BoardTable/styles';
import {
  IconActualLate,
  IconBookADriver,
  IconCanceledByDelivery,
  IconCanceledByRest,
  IconCanceledBySauce,
  IconCatering,
  IconFutureOrderAlert,
  IconLargeOrder,
  IconLongDeliveryDistance,
  IconNewDispatchAlert,
  IconNewRest,
  IconPotentialLate,
  IconRestClosed,
  IconTips,
  IconUnassignedFewTimes,
  IconXLOrder
} from 'assets/orderAlertsIcons';
import roundTheNumber from 'helpers/functionUtils/roundTheNumber';
import {
  BOARD_REQUEST_SOURCE,
  BOARD_V2_STATUS,
  BOARD_V2,
  BOARD_V2_CANCEL_SOURCE,
  BOARD_V2_DISPATCH_VERSION_VALUE
} from 'helpers/constants/adminRealTImeBoard';

const getMinutesDiffUTC = (date) =>
  moment(date).utc().diff(moment().utc(), 'minutes');

const tiersAvgOrdersText = {
  0: '0',
  1: '10 or more',
  2: '3 - 10',
  3: '1 - 3',
  4: '0 - 1'
};

const findMatchingAvailability = (availabilities, pickup_time, timezone) => {
  const eta = moment(pickup_time)
    .utcOffset(Number(timezone) || 0)
    .utc(true);

  return availabilities.find((availability) => {
    const to = moment.utc(availability.to);
    const from = moment.utc(availability.from);
    return eta.isAfter(from) && eta.isBefore(to) && availability.isClosed;
  });
};

export const TierRestaurantComponent = (data) => {
  const tierValue = Number(data[BOARD_V2.LOCATION_TIER]);
  if (tierValue >= 0) {
    return (
      <OrderAlert>
        <TierRestaurant>T{tierValue}</TierRestaurant>
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Tier {tierValue} restaurant with {tiersAvgOrdersText[tierValue]} daily
          orders on avg.
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const PotentialLateComponent = (data) => {
  if (
    getMinutesDiffUTC(data[BOARD_V2.PICKUP]?.deadline) > 0 &&
    getMinutesDiffUTC(data[BOARD_V2.PICKUP]?.deadline) < 15 &&
    data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED
  ) {
    return (
      <OrderAlert>
        <IconPotentialLate />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Potential late - unassigned order with less than 15 min for pickup
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const ActualLateComponent = (data) => {
  if (
    getMinutesDiffUTC(data[BOARD_V2.PICKUP]?.deadline) <= 0 &&
    data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.SCHEDULED
  ) {
    return (
      <OrderAlert>
        <IconActualLate />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Actual late - late for pickup
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const LargeOrderComponent = (data) => {
  if (!data[BOARD_V2.IS_CATERING] && data[BOARD_V2.ORDER_AMOUNT] / 100 >= 100) {
    return (
      <OrderAlert>
        {data[BOARD_V2.ORDER_AMOUNT] / 100 >= 200 ? (
          <IconXLOrder />
        ) : (
          <IconLargeOrder />
        )}
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Large order - ${data[BOARD_V2.ORDER_AMOUNT] / 100}
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const TipsComponent = (data) => {
  if (data[BOARD_V2.TIP]) {
    return (
      <OrderAlert>
        <IconTips amount={Math.trunc(data[BOARD_V2.TIP] / 100)} />
        <div className='OrderAlertInfo'>
          ${roundTheNumber(data[BOARD_V2.TIP] / 100)} tip
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const RestClosedComponent = (data) => {
  if (
    data[BOARD_V2.LOCATION]?.availabilities?.length &&
    data[BOARD_V2.STATUS]?.type !== BOARD_V2_STATUS.DELIVERED &&
    data[BOARD_V2.STATUS]?.type !== BOARD_V2_STATUS.CANCELLED
  ) {
    if (
      findMatchingAvailability(
        data[BOARD_V2.LOCATION]?.availabilities,
        data[BOARD_V2.PICKUP]?.deadline,
        data[BOARD_V2.TIMEZONE]
      )
    ) {
      return (
        <OrderAlert>
          <IconRestClosed />
          <div className='OrderAlertInfo OrderAlertInfoLg'>
            An order for a closed restaurant
          </div>
        </OrderAlert>
      );
    }
  }
  return null;
};

export const CanceledBySauceComponent = (data) => {
  if (
    data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.CANCELLED &&
    data[BOARD_V2.STATUS]?.source &&
    data[BOARD_V2.STATUS]?.source === BOARD_V2_CANCEL_SOURCE.SAUCE
  ) {
    return (
      <OrderAlert>
        <IconCanceledBySauce />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Order was canceled by Sauce
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CanceledByRestComponent = (data) => {
  if (
    data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.CANCELLED &&
    data[BOARD_V2.STATUS]?.source &&
    data[BOARD_V2.STATUS]?.source === BOARD_V2_CANCEL_SOURCE.CUSTOMER
  ) {
    return (
      <OrderAlert>
        <IconCanceledByRest />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Order was canceled by the restaurant
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CanceledByDeliveryComponent = (data) => {
  if (
    data[BOARD_V2.STATUS]?.type === BOARD_V2_STATUS.CANCELLED &&
    data[BOARD_V2.STATUS]?.source &&
    data[BOARD_V2.STATUS]?.source === BOARD_V2_CANCEL_SOURCE.PROVIDER
  ) {
    return (
      <OrderAlert>
        <IconCanceledByDelivery />
        <div className='OrderAlertInfo OrderAlertInfoLg wordWrap'>
          Order was canceled by the delivery company{' '}
          {data[BOARD_V2.STATUS]?.reason
            ? `- ${data[BOARD_V2.STATUS]?.reason}`
            : ''}
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CateringComponent = (data) => {
  if (data[BOARD_V2.IS_CATERING]) {
    return (
      <OrderAlert>
        <IconCatering />
        <div className='OrderAlertInfo'>Catering order</div>
      </OrderAlert>
    );
  }
  return null;
};

export const NewDispatchComponent = (data) => {
  if (
    data[BOARD_V2.DISPATCH_VERSION] ===
    BOARD_V2_DISPATCH_VERSION_VALUE.DISPATCH_V2
  ) {
    return (
      <OrderAlert>
        <NewDispatchAlert>ND</NewDispatchAlert>
        <div className='OrderAlertInfo'>New dispatch order</div>
      </OrderAlert>
    );
  }
  return null;
};

export const NewRestComponent = (data) => {
  if (!data[BOARD_V2.FIRST_TEN_ORDERS]) {
    return (
      <OrderAlert>
        <IconNewRest />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          A new restaurant with less than 10 orders
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const LongDeliveryDistanceComponent = (data) => {
  if (data[BOARD_V2.DELIVERY]?.drivingDistanceMeters) {
    const distanceInMiles =
      Number(data[BOARD_V2.DELIVERY]?.drivingDistanceMeters) / 1000 / 1.609;

    if (distanceInMiles >= 10) {
      return (
        <OrderAlert>
          <IconLongDeliveryDistance />
          <div className='OrderAlertInfo OrderAlertInfoMd'>
            A long delivery of {roundTheNumber(distanceInMiles)} miles
          </div>
        </OrderAlert>
      );
    }
  }

  return null;
};

export const UnassignedFewTimesComponent = (data) => {
  if (
    data[BOARD_V2.DELIVERY]?.driverChangeCount &&
    data[BOARD_V2.DELIVERY]?.driverChangeCount >= 5
  ) {
    return (
      <OrderAlert>
        <IconUnassignedFewTimes />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Unassigned a few times - order was unassigned{' '}
          {data[BOARD_V2.DELIVERY]?.driverChangeCount} times
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const IconBookADriverComponent = (data) => {
  if (
    data[BOARD_V2.SOURCE] &&
    data[BOARD_V2.SOURCE] === BOARD_REQUEST_SOURCE.BOOKADRIVER
  ) {
    return (
      <OrderAlert>
        <IconBookADriver />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Book a Driver order
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const FutureOrderAlertComponent = (data) => {
  if (data[BOARD_V2.IS_FUTURE_ORDER]) {
    return (
      <OrderAlert>
        <IconFutureOrderAlert />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Future order{' '}
          {moment(data[BOARD_V2.PICKUP]?.eta)
            .utcOffset(Number(data[BOARD_V2.TIMEZONE]))
            .format('MMMM D, h:mm A')}
        </div>
      </OrderAlert>
    );
  }
  return null;
};
