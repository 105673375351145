import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import IconSort from '../../assets/icon-sort.svg';
import IconSortActiveUp from '../../assets/icon-sort-active-up.svg';
import IconSortActiveDown from '../../assets/icon-sort-active-down.svg';
import {ORDER_DIRECTION} from '../../helpers/constants/Table';

const getIcon = (order) => {
  if (!order) {
    return IconSort;
  }

  return order === ORDER_DIRECTION.ASC ? IconSortActiveUp : IconSortActiveDown;
};

export const Wrapper = styled.div`
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #232d3a;
    padding-right: 20px;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;
    @media (max-width: 1700px) {
      font-size: 14px;
    }
  }
  .MuiTableCell-root {
    &:first-child {
      padding-left: 0;
    }
  }
  .MuiTable-root {
    @media (max-width: 1200px) {
      min-width: 1100px;
    }
    tr {
      td {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .MuiTableRow-root {
    vertical-align: top;
  }
  .MuiTableCell-root {
    border-bottom: 0;
  }
`;

export const SortWrapper = styled(TableCell)`
  && {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 11px;
      height: 15px;
      background-image: url('${(props) => getIcon(props.order)}');
      background-size: contain;
      background-repeat: no-repeat;
      @media (max-width: 1440px) {
        width: 10px;
        height: 14px;
      }
    }
  }
`;
