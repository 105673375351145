import {createAction} from 'redux-act';

export const GET_SUDO_USERS = createAction('adminSudoUsers/GET_SUDO_USERS');
export const GET_SUDO_USERS_SUCCESS = createAction(
  'adminSudoUsers/GET_SUDO_USERS_SUCCESS'
);
export const GET_SUDO_USER = createAction('adminSudoUsers/GET_SUDO_USER');
export const GET_SUDO_USER_SUCCESS = createAction(
  'adminSudoUsers/GET_SUDO_USER_SUCCESS'
);
export const UPDATE_SUDO_USER = createAction('adminSudoUsers/UPDATE_SUDO_USER');

export const CLEAR_SUDO_USER = createAction('adminSudoUsers/CLEAR_USER');
export const PAGE_CHANGE = createAction('adminSudoUsers/PAGE_CHANGE');
export const SEARCH_CHANGE = createAction('adminSudoUsers/SEARCH_CHANGE');
export const GET_ALL_SUDO_USER_FEATURE_FLAGS = createAction(
  'adminSudoUsers/GET_ALL_SUDO_USER_FEATURE_FLAGS'
);
export const GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS = createAction(
  'adminSudoUsers/GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS'
);
export const CLEAR_FILTERS = createAction('adminSudoUsers/CLEAR_FILTERS');
