import styled from 'styled-components';

export const DialogSubTitle = styled.div`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.2;
  color: #2f3d48;
  margin-bottom: 9px;

  &.MarginBottom22 {
    margin-bottom: 22px;
  }
  &.textCenter {
    text-align: center;
  }

  &.Sm {
    font-size: 14px;
  }
  &.mBottomLarge {
    margin-bottom: 35px;
  }
  &.textMedium {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }

  &.textCenterBoldMarginBottomLarge {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-bottom: 43px;
  }

  &.textMd {
    font-size: 16px;
    max-width: 320px;
    margin: 12px auto;
    font-weight: 500;
    text-align: center;
    span {
      color: #5988dc;
    }
  }

  &.badSuccess {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 20px 0 40px 0;

    span {
      font-weight: bold;
      color: #ff2d38;
    }
  }
  &.mB0 {
    margin-bottom: 0;
  }
`;

export default DialogSubTitle;
