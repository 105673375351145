import * as Yup from 'yup';
import {URL_REGEXP} from '../constants/RegExpConstants';

const hostFields = ['HostLoginLink', 'HostUserName', 'HostPassword'];

const teamFields = ['ItTeamName', 'ItTeamEmail'];

const ifAnyFieldIsFilled = (fields) =>
  Yup.string().test(
    'block-is-filled',
    'This field is required',
    (value, context) => {
      if (
        Object.keys(context.parent).find(
          (key) => fields.includes(key) && !!context.parent[key]
        ) &&
        !value
      ) {
        return false;
      }

      return true;
    }
  );

const hostSchema = {
  HostLoginLink: ifAnyFieldIsFilled(hostFields).matches(
    new RegExp(URL_REGEXP),
    'Please enter a valid URL!'
  ),
  HostUserName: ifAnyFieldIsFilled(hostFields),
  HostPassword: ifAnyFieldIsFilled(hostFields)
};

const teamSchema = {
  ItTeamName: ifAnyFieldIsFilled(teamFields),
  ItTeamEmail: ifAnyFieldIsFilled(teamFields).email('Invalid email!')
};

export const addLinkSchema = Yup.object().shape({
  ...hostSchema,
  ...teamSchema
});

export default addLinkSchema;
