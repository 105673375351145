import styled from 'styled-components';
import {IconButton} from '@material-ui/core';
import {ButtonInfo} from '../../../../../components/buttons';
import SavingsIcon from '../../../../../assets/icon-savings-pig.svg';

export const IconButtonPrinter = styled(IconButton)`
  padding: 6px !important;
`;

export const SavingsIconButton = styled(ButtonInfo)`
  z-index: 10;
  background-image: url('${SavingsIcon}');
  background-size: contain;
  width: 28px;
  height: 28px;
  display: block;

  &:hover {
    .DropInfo {
      transform: translate(50%, -3px);
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const OrdersTableWrapper = styled.div`
  .MuiTable-root {
    tr {
      &:first-child {
        th {
          &:nth-child(1) {
            width: 20%;
            @media (max-width: 1000px) {
              width: 15%;
            }
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 18%;
            @media (max-width: 1000px) {
              width: 18%;
            }
          }

          //&:nth-child(4) {
          //  width: 14%;
          //  @media (max-width: 1000px) {
          //    width: 13%;
          //  }
          //}

          &:nth-child(4) {
            width: fit-content;
          }

          &:nth-child(5) {
            width: 13%;
          }

          &:nth-child(6) {
            width: 3%;
          }
        }
      }
    }
  }
  &.OrdersTableWrapper {
    thead {
      th {
        &:nth-child(4) {
          padding: 0 !important;
          width: 0 !important;
        }
      }
    }
    tbody {
      td {
        &:nth-child(4) {
          padding: 0 !important;
          width: 0 !important;
        }
      }
    }
  }
`;
