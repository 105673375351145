import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import T from 'prop-types';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {DialogText} from 'routes/App/dashboard/styles';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline} from 'components/buttons';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';

const EmailMarketingDeleteDiscountModal = ({
  isOpen,
  onCloseAction,
  discountAction,
  actionType
}) => {
  return (
    <Dialog open={isOpen}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom verticalCenter'>
          Are you sure you want to{' '}
          {actionType === 'Delete' ? 'delete' : 'disable'} an active marketing
          email discount?
        </DialogTitle>

        <DialogBody>
          <DialogText className='smText'>
            Email campaigns with discounts are known to be over 50% more
            effective
          </DialogText>
          <DialogActions>
            <DialogAction className='DialogActions w50'>
              <ButtonOutline onClick={onCloseAction}>
                <span>Cancel</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='DialogActions w50'>
              <ButtonPrimary onClick={discountAction}>
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EmailMarketingDeleteDiscountModal.propTypes = {
  isOpen: T.bool.isRequired,
  onCloseAction: T.func.isRequired,
  discountAction: T.func.isRequired,
  actionType: T.string.isRequired
};

export default EmailMarketingDeleteDiscountModal;
