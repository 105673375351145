import {request} from '../utils/request';
import {GET, POST, PATCH} from '../constants/API_CONSTANTS';
import {
  CONFIRM_USER_EMAIL,
  GET_ACCOUNT_STATUS_STEP,
  POST_TEAM_MEMBER,
  GET_GOOGLE_AUTH_LINK,
  POST_GOOGLE_AUTH_CODE,
  SET_WEBSITE_TEMPLATE,
  POST_STRIPE_ACCOUNT,
  POST_STRIPE_SIGN_UP_2_ACCOUNT,
  GET_ACCOUNT_STATUS_SIGN_UP_2_STEP,
  BOOK_A_CALL,
  POST_OWNER
} from '../constants/ACCOUNT_API';
import {getAppIp, getAppType, getAppVersion} from '../../app.helper';

export const setWebsiteTemplateAPi = async (token, id, data) => {
  return request(
    PATCH,
    SET_WEBSITE_TEMPLATE(id),
    {data},
    {
      headers: {
        Accept: 'application/merge-patch+json',
        Authorization: `Bearer ${token}`,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};
export const confirmUserEmailApi = async (id, email, data) => {
  return request(POST, CONFIRM_USER_EMAIL(id, email), {data});
};

export const getAccountStatusApi = async (userId, merchantId) => {
  return request(GET, GET_ACCOUNT_STATUS_STEP(userId, merchantId));
};

export const getAccountStatusSignUp2Api = async (userId) => {
  return request(GET, GET_ACCOUNT_STATUS_SIGN_UP_2_STEP(userId));
};

export const addTeamMemberApi = async (merchantId, userId, data) => {
  return request(POST, POST_TEAM_MEMBER(merchantId, userId), {data});
};

export const createOwnerApi = async (merchantId, data) => {
  return request(POST, POST_OWNER(merchantId), {data});
};

export const connectStripeApi = async (id) => {
  return request(POST, POST_STRIPE_ACCOUNT(id));
};

export const connectStripeSignUp2Api = async (id) => {
  return request(POST, POST_STRIPE_SIGN_UP_2_ACCOUNT(id));
};

export const getGoogleAuthLink = async () => {
  return request(GET, GET_GOOGLE_AUTH_LINK());
};

export const postGoogleAuthCode = async (code, merchant_id) => {
  return request(POST, POST_GOOGLE_AUTH_CODE(), {data: {code, merchant_id}});
};

export const bookCallApi = async (userId) => {
  return request(POST, BOOK_A_CALL(userId), {data: {callBooked: true}});
};
