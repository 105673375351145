/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import T from 'prop-types';
import {Dialog} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {DialogBody, DialogText} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import {OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL} from '../../../../store/locationSettings/action';

const ChangeAddressConfirmationModal = ({
  openModal,
  isCloseConfirmModal,
  fullAddress,
  initValuesAddress,
  checkActiveOrders
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      fullAddress &&
      initValuesAddress &&
      fullAddress !== initValuesAddress &&
      checkActiveOrders
    ) {
      dispatch(OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL({open: true}));
    }
  }, [fullAddress]);
  return (
    <Dialog open={openModal} onClose={isCloseConfirmModal} scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogClose onClick={() => isCloseConfirmModal()} />
        <DialogBody>
          <DialogText>
            You have active orders, please notify Sauce support about the
            change.
          </DialogText>
          <DialogActions className='marginTop25'>
            <DialogAction>
              <ButtonPrimary
                type='button'
                onClick={() => {
                  isCloseConfirmModal();
                }}
              >
                <span>Ok</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ChangeAddressConfirmationModal.propTypes = {
  openModal: T.bool.isRequired,
  isCloseConfirmModal: T.func.isRequired,
  fullAddress: T.string.isRequired,
  initValuesAddress: T.string.isRequired,
  checkActiveOrders: T.bool.isRequired
};
export default ChangeAddressConfirmationModal;
