/* eslint-disable */
import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom';
import Headline from '../../../../components/Headline/styles';
import {Caption1} from '../../../../components/typography';
import ActiveOrders from './ActiveOrders';
import OrdersHistory from './OrdersHistory';
import history from '../../../../history';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import IconCar from '../../../../assets/IconCar';
import {DashboardHeaderActions, OrderTab, OrderTabs} from './styles';
import ButtonPrimaryLink from '../../../../components/buttons/ButtonPrimaryLink/styles';

const BookADriver = () => {
  const headerRef = useRef();

  const {
    location: {pathname}
  } = useHistory();
  const location = useLocation();

  const {isNetworkOnline} = useSelector(
    (state) => state.globalNotificationsReducer
  );

  const {orderCount} = useSelector(
    (state) => state.activeBookADriverOrdersReducer
  );
  useEffect(() => {
    if (pathname === '/book-a-driver') {
      history.push('/book-a-driver/active');
    }
    window.scrollTo(0, location?.state?.windowPosition || 0);
  }, [pathname]);

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline>Book a Driver</Headline>
        <DashboardHeaderActions>
          <ButtonPrimaryLink
            to='/book-a-driver/create-order'
            className='ButtonMedium ButtonDriver'
            data-gtm='bookADriverButton'
          >
            <IconCar data-gtm='bookADriverButton' />
            <span data-gtm='bookADriverButton'>Book a Driver</span>
          </ButtonPrimaryLink>
        </DashboardHeaderActions>
      </DashboardHeader>
      <DashboardInner className='withoutShadow paddingXl' ref={headerRef}>
        <OrderTabs>
          <OrderTab activeClassName='isActive' to='/book-a-driver/active'>
            <Caption1 className='Caption'>
              <span style={{color: 'red'}}>{orderCount} </span>Active Orders
            </Caption1>
          </OrderTab>
          <OrderTab
            className={isNetworkOnline ? null : 'isDisabled'}
            activeClassName='isActive'
            to='/book-a-driver/history'
          >
            <Caption1 className='Caption'>Orders History</Caption1>
          </OrderTab>
        </OrderTabs>
      </DashboardInner>

      <>
        <Route path='/book-a-driver/active' render={() => <ActiveOrders />} />
        {isNetworkOnline && (
          <Route
            path='/book-a-driver/history'
            render={() => <OrdersHistory parentRef={headerRef.current} />}
          />
        )}

        <Redirect from='/book-a-driver' to='/book-a-driver/active' />
      </>
    </DashboardContent>
  );
};

export default BookADriver;
