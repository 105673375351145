import styled from 'styled-components';

export const RefundConfirmationImg = styled.img`
  display: block;
  max-width: 137px;
  width: 100%;
  margin: 30px auto 0 auto;
`;

export default RefundConfirmationImg;
