import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {DialogSubTitle} from '../../../dashboard/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from '../../../../../components/buttons';
import DialogActions from '../../../../../components/DialogActions/styles';

const ConfirmStoreSelectModal = ({
  open,
  uberEatsStoreName,
  locationName,
  onClose,
  confirm
}) => {
  return (
    <Dialog open={open}>
      <DialogContainer className='DialogSm'>
        <DialogSubTitle className='marginBottomLg'>
          Connect UberEats&apos;s ({uberEatsStoreName}) account to Sauce&apos;s
          ({locationName}) account?
        </DialogSubTitle>
        <DialogBody>
          <DialogActions>
            <DialogAction className='w50'>
              <ButtonOutline className='ButtonLg' onClick={onClose}>
                No
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary onClick={confirm}>
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ConfirmStoreSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  uberEatsStoreName: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired
};

export default ConfirmStoreSelectModal;
