import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import LinkBack from '../../../../components/LinkBack/styles';
import history from '../../../../history';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {CardsButtonGrid} from '../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../components/CardsButton/styles';
import LetUsDo from './LetUsDo';
import EMail from './EMail';
import Install from './Install';
import {DashboardAction} from '../../dashboard/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import {PUT_LOCATION_SETUP_DATA} from '../../../../store/global/account/action';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import {DashboardHold} from '../styles';
import Body3 from '../../../../components/typography/Body3';
import {addLinkSchema} from '../../../../helpers/services/formValidationSchemas/AddLinkShema';
import {GET_LOCATION} from '../../../../store/global/user/action';

const getTab = (tab, formik) => {
  switch (tab) {
    case 0:
      return <LetUsDo formik={formik} />;
    case 1:
      return <EMail formik={formik} />;
    case 2:
      return <Install />;
    default:
      return () => {};
  }
};

const AddLink = () => {
  const [showTab, setShowTab] = useState(0);

  const dispatch = useDispatch();

  const activeLocation = useSelector((state) => state.user?.activeLocation);
  const locationName = useSelector(
    (state) => state.user?.userData?.MerchantOriginName
  );

  useEffect(() => {
    if (activeLocation) {
      dispatch(GET_LOCATION(activeLocation));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      HostLoginLink: '',
      HostUserName: '',
      HostPassword: '',
      ItTeamName: '',
      ItTeamEmail: '',
      ...(activeLocation?.setupData || {})
    },
    validationSchema: addLinkSchema,
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      dispatch(PUT_LOCATION_SETUP_DATA(values));
    }
  });

  return (
    <DashboardContent className='TabletPTop DashboardContent'>
      <DashboardHeader className='whiteBgTablet'>
        <LinkBack onClick={() => history.push('/my-account')}>
          Back to My account
        </LinkBack>
      </DashboardHeader>
      <DashboardInner>
        <DashboardHold>
          <Subtitle5 className='marginBottomXs'>
            Add Link to your website
          </Subtitle5>
          <Body3>Add Sauce to {locationName}.</Body3>
          <CardsButtonGrid className='CardsButtonGridOrange'>
            <CardsButton
              className={showTab === 0 ? 'isActive' : null}
              onClick={() => setShowTab(0)}
            >
              <span>Let us do it for you</span>
            </CardsButton>
            <CardsButton
              className={showTab === 1 ? 'isActive' : null}
              onClick={() => setShowTab(1)}
            >
              <span>Email your IT team</span>
            </CardsButton>
            <CardsButton
              className={showTab === 2 ? 'isActive' : null}
              onClick={() => setShowTab(2)}
            >
              <span>Install it yourself</span>
            </CardsButton>
          </CardsButtonGrid>
          <div className='marginTopLg'>{getTab(showTab, formik)}</div>
          <DashboardAction>
            <ButtonPrimary
              onClick={() => {
                if (formik.dirty && formik.isValid) {
                  formik.handleSubmit();
                }
                if (showTab === 2) {
                  history.push('/my-account/');
                }
              }}
              className={
                (!formik.dirty || !formik.isValid) && showTab !== 2
                  ? 'disabled'
                  : null
              }
            >
              <span>{showTab !== 2 ? 'Apply' : 'Ok'}</span>
            </ButtonPrimary>
          </DashboardAction>
        </DashboardHold>
      </DashboardInner>
    </DashboardContent>
  );
};

export default AddLink;
