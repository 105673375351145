import {put, select, takeEvery} from 'redux-saga/effects';
import {GET_SALES_PER_HOUR, GET_SALES_PER_HOUR_SUCCESS} from './action';
import {getSalesPerHourApi} from '../../helpers/services/api/analytics';

const getTimezone = ({user}) => user.timezone;

function* getSalesPerHour({payload}) {
  try {
    const timezone = yield select(getTimezone);
    const {data} = yield getSalesPerHourApi(
      timezone,
      payload.startDate,
      payload.endDate
    );
    if (data) {
      yield put(GET_SALES_PER_HOUR_SUCCESS(data.data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* analyticsSalesPerHourSaga() {
  yield takeEvery(GET_SALES_PER_HOUR, getSalesPerHour);
}

export default analyticsSalesPerHourSaga;
