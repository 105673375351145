import {createAction} from 'redux-act';

export const SHOW_NOTIFICATION_MODAL = createAction(
  'globalNotifications/SHOW_NOTIFICATION_MODAL'
);
export const POLL_STOP = createAction('globalNotifications/POLL_STOP');
export const FETCH_LOCATIONS_NOTIFICATIONS = createAction(
  'globalNotifications/FETCH_LOCATIONS_NOTIFICATIONS'
);
export const FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS = createAction(
  'globalNotifications/FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS'
);
export const PATCH_DELIVERY_OPTIONS = createAction(
  'globalNotifications/PATCH_DELIVERY_OPTIONS'
);
export const PATCH_DELIVERY_OPTIONS_SUCCESS = createAction(
  'globalNotifications/PATCH_DELIVERY_OPTIONS_SUCCESS'
);
export const TOGGLE_STORE = createAction('globalNotifications/TOGGLE_STORE');
export const TOGGLE_STORE_SUCCESS = createAction(
  'globalNotifications/TOGGLE_STORE_SUCCESS'
);
export const SET_NETWORK_CONNECTION = createAction(
  'globalNotifications/SET_NETWORK_CONNECTION'
);
export const OPEN_ONLINE_MODAL = createAction(
  'globalNotifications/OPEN_ONLINE_MODAL'
);
export const OPEN_OFFLINE_MODAL = createAction(
  'globalNotifications/OPEN_OFFLINE_MODAL'
);

export const SET_OFFLINE_TOAST_ID = createAction(
  'globalNotifications/SET_OFFLINE_TOAST_ID'
);
export const SET_ONLINE_STATUS = createAction(
  'globalNotifications/SET_ONLINE_STATUS'
);

export const SHOW_PUBLISH_PROGRESSBAR = createAction(
  'globalNotifications/SHOW_PUBLISH_PROGRESSBAR'
);

export const SHOW_UNPUBLISH_BANNER = createAction(
  'globalNotifications/SHOW_UNPUBLISH_BANNER'
);

export const RUN_GET_PUBLISH_STATUS_JOB = createAction(
  'globalNotifications/RUN_GET_PUBLISH_STATUS_JOB'
);

export const STOP_GET_PUBLISH_STATUS_JOB = createAction(
  'globalNotifications/STOP_GET_PUBLISH_STATUS_JOB'
);

export const SET_PUBLISH_STATUS = createAction(
  'globalNotifications/SET_PUBLISH_STATUS'
);

export const SET_NEW_TASK_ADDED = createAction(
  'globalNotifications/SET_NEW_TASK_ADDED'
);

export const SET_FINISH_SPEED = createAction(
  'globalNotifications/SET_FINISH_SPEED'
);
