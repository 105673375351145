import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';

import {
  DialogAction,
  DialogBody,
  DialogField,
  FieldInput,
  FieldLabel
} from 'routes/App/dashboard/styles';

import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import DialogContainer from 'components/DialogContainer/styles';
import DialogClose from 'components/DialogClose/styles';
import DialogTitle from 'components/DialogTitle/styles';

import {UPDATE_PHONE_ORDERING} from 'store/phoneOrdering/action';

export default function EditPhoneOrderingLocation({open, onClose, modalModel}) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      phoneOrderingWebsiteUrl: '',
      ...modalModel
    },
    onSubmit: (values, {resetForm}) => {
      dispatch(
        UPDATE_PHONE_ORDERING({
          id: modalModel.locationId,
          data: {
            phoneOrderingWebsiteUrl: values.phoneOrderingWebsiteUrl
          }
        })
      );
      resetForm();
      onClose();
    }
  });

  useEffect(() => {
    ['phoneOrderingWebsiteUrl'].forEach((field) =>
      formik.setFieldValue(field, modalModel[field], false)
    );
  }, [modalModel]);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomXl'>
          Edit Phone Ordering Location
        </DialogTitle>
        <DialogBody>
          <DialogField className='DialogField'>
            <FieldLabel className='inline-label'>
              Phone Ordering Website URL
            </FieldLabel>
            <FieldInput
              placeholder='Phone Ordering Website URL'
              name='phoneOrderingWebsiteUrl'
              onChange={formik.handleChange}
              value={formik.values.phoneOrderingWebsiteUrl}
            />
          </DialogField>
          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>Apply</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

EditPhoneOrderingLocation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object
};

EditPhoneOrderingLocation.defaultProps = {
  open: false,
  onClose: () => {},
  modalModel: {}
};
