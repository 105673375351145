/* eslint-disable no-param-reassign */
import {createReducer} from 'redux-act';
import {setLevelNamesByItemGroupTree} from '../../helpers/menuEditor';
import {
  CLEAR_DATA,
  CLEAR_ITEM_BY_ID,
  DELETE_ITEM,
  DELETE_ITEM_SUCCESS,
  GET_ITEM_BY_ID,
  GET_ITEM_BY_ID_SUCCESS,
  GET_ITEM_MODAL,
  GET_ITEM_MODAL_SUCCESS,
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  PATCH_ITEM,
  PATCH_ITEM_SUCCESS,
  POST_ITEM,
  POST_ITEM_SUCCESS,
  UPDATE_ITEM,
  UPDATE_ITEM_SUCCESS,
  FETCH_SINGLE_ITEM_TREE,
  FETCH_SINGLE_ITEM_SUCCESS
} from './action';

const initialState = () => ({
  itemsData: [],
  itemByIdData: {},
  itemByIdLoading: false,
  itemsLoading: false,
  rerenderItems: false,
  itemsPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name',
    hasNextPage: true,
    hasPrevPage: false,
    prevPage: 0
  },
  itemsModalData: [],
  itemsModalLoading: false,
  itemsModalPagination: {
    pages: 1,
    page: 1,
    limit: 500,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  init: false
});

const menuEditorItemReducer = createReducer(
  {
    [GET_ITEMS]: (state) => ({
      ...state,
      itemsLoading: true
    }),

    [GET_ITEM_BY_ID]: (state) => ({
      ...state,
      itemByIdLoading: true
    }),
    [GET_ITEM_BY_ID_SUCCESS]: (state, payload) => ({
      ...state,
      itemByIdData: payload,
      itemByIdLoading: false
    }),
    [GET_ITEM_MODAL]: (state) => ({
      ...state,
      itemsModalLoading: true
    }),
    [GET_ITEM_MODAL_SUCCESS]: (state, payload) => ({
      ...state,
      itemsModalData: payload.docs,
      itemsModalLoading: false,
      itemsModalPagination: {
        pages: payload.totalPages,
        page: payload.page,
        limit: payload.limit,
        total: payload.totalDocs,
        sort: 'desc',
        sortBy: 'Name'
      }
    }),
    [POST_ITEM]: (state) => ({
      ...state
    }),
    [POST_ITEM_SUCCESS]: (state) => ({
      ...state,
      rerenderItems: !state.rerenderItems
    }),
    [UPDATE_ITEM]: (state) => ({
      ...state
    }),
    [UPDATE_ITEM_SUCCESS]: (state) => ({
      ...state,
      rerenderItems: !state.rerenderItems
    }),
    [PATCH_ITEM]: (state) => ({
      ...state
    }),
    [PATCH_ITEM_SUCCESS]: (state) => ({
      ...state,
      rerenderItems: !state.rerenderItems
    }),
    [DELETE_ITEM]: (state) => ({
      ...state
    }),
    [DELETE_ITEM_SUCCESS]: (state) => ({
      ...state,
      rerenderItems: !state.rerenderItems
    }),
    [GET_ITEMS_SUCCESS]: (state, payload) => {
      let data = [];
      if (payload.page === 1) {
        data = [...payload.docs];
      } else {
        data = [...state.itemsData, ...payload.docs];
      }

      setLevelNamesByItemGroupTree(data, FETCH_SINGLE_ITEM_TREE);

      return {
        ...state,
        itemsData: data,
        itemsLoading: false,
        init: true,
        // eslint-disable-next-line no-plusplus
        itemsPagination: {
          pages: payload.totalPages,
          page: payload.page,
          limit: 8,
          total: payload.totalDocs,
          sort: 'desc',
          sortBy: 'Name',
          hasNextPage: payload.hasNextPage,
          hasPrevPage: payload.hasPrevPage,
          prevPage: payload.prevPage
        }
      };
    },

    [FETCH_SINGLE_ITEM_SUCCESS]: (state, payload) => {
      const menuItem = payload;
      setLevelNamesByItemGroupTree([menuItem], FETCH_SINGLE_ITEM_TREE);
      const itemsData = state.itemsData.map((item) =>
        item.PosId !== menuItem.PosId ? item : menuItem
      );

      return {
        ...state,
        itemsData
      };
    },

    [CLEAR_DATA]: () => ({
      ...initialState(),
      itemsData: []
    }),

    [CLEAR_ITEM_BY_ID]: (state) => ({
      ...state,
      itemByIdData: []
    })
  },
  initialState()
);

export default menuEditorItemReducer;
