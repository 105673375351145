import {put, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  updateSortingOrderApi,
  menuDataApi,
  menuRecursionRelationsApi,
  clearMenuRecursionRelationsApi,
  publishMenuApi,
  bulkUpdateApi,
  bulkDeleteApi,
  checkIsAllowMenuPublishApi
} from '../../helpers/services/api/menuEditor';
import {
  UPDATE_SORTING_ORDER,
  SET_SORTING_ORDER,
  FETCH_MENU_DATA,
  FETCH_MENU_RECURSION_RELATIONS,
  CLEAR_RECURSION_RELATIONS,
  CLEAR_RECURSION_RELATIONS_SUCCESS,
  CHECK_IS_ALLOW_MENU_PUBLISHING,
  PUBLISH_MENU,
  SET_LOADING_MENU_DATA,
  SET_READY_MENU_DATA,
  SET_MENU_DATA,
  BULK_UPDATE,
  BULK_UPDATE_SUCCESSFUL,
  BULK_DELETE,
  BULK_DELETE_SUCCESSFUL,
  SET_PUBLISH_BUTTON_STATE_MENU_UPDATE
} from './action';
import {
  PUBLISH_STATUS_DISABLE,
  PUBLISH_STATUS_ENABLE,
  PUBLISH_STATUS_WAITING
} from './constants';
import {
  RUN_GET_PUBLISH_STATUS_JOB,
  SHOW_UNPUBLISH_BANNER
} from '../global/globalNotifications/action';
import {TASK_TYPE_LAST_CUSTOM_DATA_UPDATE} from '../global/globalNotifications/constants';

function* updateSortingOrder({payload}) {
  try {
    yield put(SET_SORTING_ORDER(payload));
    yield updateSortingOrderApi(payload);

    toast.success('Sorting Order has been updated');
  } catch (error) {
    console.log('SAGA [menuEditorSaga] ERROR', error);
  }
}

function* fetchMenuData() {
  try {
    yield put(SET_LOADING_MENU_DATA(true));

    const response = yield menuDataApi();
    if (response.data) {
      yield put(SET_MENU_DATA(response.data));
    }
  } catch (error) {
    console.log('SAGA [menuEditorSaga] ERROR', error);
  } finally {
    yield put(SET_LOADING_MENU_DATA(false));
    yield put(SET_READY_MENU_DATA(true));
  }
}

function* fetchMenuRecursionRelations({payload}) {
  const {preCallback = () => {}, postCallback = () => {}} = payload;

  try {
    preCallback();

    const response = yield menuRecursionRelationsApi();

    console.log('response', response);

    postCallback(response.data);
  } catch (error) {
    console.log('SAGA [menuEditorSaga] ERROR', error);

    postCallback();
  }
}

function* clearRecursionRelations({payload}) {
  const {relations, preCallback = () => {}, postCallback = () => {}} = payload;

  try {
    preCallback();

    yield clearMenuRecursionRelationsApi(relations);
    yield put(CLEAR_RECURSION_RELATIONS_SUCCESS(relations));

    postCallback();
  } catch (error) {
    console.log('SAGA [menuEditorSaga] ERROR', error);

    postCallback();
  }
}

function* checkIsAllowMenuPublishing({payload}) {
  const {menuConfigId, SyncType, callback = () => {}} = payload;

  try {
    if (!menuConfigId) {
      yield put(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
      return;
    }
    yield put(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));

    const response = yield checkIsAllowMenuPublishApi(menuConfigId, SyncType);

    if (response.data) {
      yield put(
        SET_PUBLISH_BUTTON_STATE_MENU_UPDATE(response.data.publishStatus)
      );
    }
    if (response?.data?.publishStatus === PUBLISH_STATUS_ENABLE) {
      yield put(
        SHOW_UNPUBLISH_BANNER({showBanner: true, types: response.data.types})
      );
    }
    //      callback(response.data.publishDelay);
    //    } else {
    //      callback(600);
    //    }
  } catch (error) {
    //    callback(600);

    console.log('SAGA [menuEditorSaga] ERROR', {...error});
  }
}

function* publishMenu({payload}) {
  const {
    menuConfigId,
    preCallback = () => {},
    postCallback = () => {},
    errorCallback = () => {}
  } = payload;

  try {
    preCallback();
    yield put(
      RUN_GET_PUBLISH_STATUS_JOB({SyncType: TASK_TYPE_LAST_CUSTOM_DATA_UPDATE})
    );

    yield put(SET_PUBLISH_BUTTON_STATE_MENU_UPDATE(PUBLISH_STATUS_WAITING));

    const response = yield publishMenuApi(menuConfigId);

    if (response.data) {
      yield put(SET_PUBLISH_BUTTON_STATE_MENU_UPDATE(PUBLISH_STATUS_DISABLE));
      postCallback(response.data);
    }
  } catch (error) {
    errorCallback();
    postCallback(null);
    console.log('SAGA [menuEditorSaga] ERROR', error);
  }
}

function* bulkUpdate({payload}) {
  try {
    const updated = {};
    const {
      categories,
      items,
      groups,
      modifiers,
      preCallback = () => {},
      postCallback = () => {}
    } = payload;

    preCallback();

    if (categories) {
      yield bulkUpdateApi('/sections', categories);
      updated.categories = categories;
    }

    if (items) {
      yield bulkUpdateApi('/menu-items', items);
      updated.items = items;
    }

    if (groups) {
      yield bulkUpdateApi('/m-groups', groups);
      updated.groups = groups;
    }

    if (modifiers) {
      yield bulkUpdateApi('/modifiers', modifiers);
      updated.modifiers = modifiers;
    }

    if (Object.keys(updated)) {
      yield put(BULK_UPDATE_SUCCESSFUL(updated));
    }

    postCallback();
    toast.success('Elements data has been updated');
  } catch (error) {
    console.log('SAGA [bulkUpdateSections] ERROR', error);
  }
}

function* bulkDelete({payload}) {
  try {
    const deleted = {};
    const {
      categories,
      items,
      groups,
      modifiers,
      preCallback = () => {},
      postCallback = () => {}
    } = payload;

    preCallback();

    if (categories) {
      yield bulkDeleteApi('/sections', categories);
      deleted.categories = categories;
    }

    if (items) {
      yield bulkDeleteApi('/menu-items', items);
      deleted.items = items;
    }

    if (groups) {
      yield bulkDeleteApi('/m-groups', groups);
      deleted.groups = groups;
    }

    if (modifiers) {
      yield bulkDeleteApi('/modifiers', modifiers);
      deleted.modifiers = modifiers;
    }

    if (Object.keys(deleted)) {
      yield put(BULK_DELETE_SUCCESSFUL(deleted));
    }

    postCallback();
    toast.success('Elements data has been deleted');
  } catch (error) {
    console.log('SAGA [bulkUpdateSections] ERROR', error);
  }
}

function* menuEditorSaga() {
  yield takeEvery(UPDATE_SORTING_ORDER, updateSortingOrder);
  yield takeEvery(FETCH_MENU_DATA, fetchMenuData);
  yield takeEvery(FETCH_MENU_RECURSION_RELATIONS, fetchMenuRecursionRelations);
  yield takeEvery(CLEAR_RECURSION_RELATIONS, clearRecursionRelations);
  yield takeEvery(PUBLISH_MENU, publishMenu);
  yield takeEvery(CHECK_IS_ALLOW_MENU_PUBLISHING, checkIsAllowMenuPublishing);

  yield takeEvery(BULK_UPDATE, bulkUpdate);
  yield takeEvery(BULK_DELETE, bulkDelete);
}

export default menuEditorSaga;
