/* eslint-disable */
import TableContainer from '@material-ui/core/TableContainer';
import T from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  DialogItemBox,
  DialogItemDescription,
  DialogItemName,
  DialogItemString,
  DialogItemStrings,
  DialogItemTable,
  DialogRightSidebar
} from '../../../dashboard/styles';

class RightSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      activeItem,
      itemType,
      sectionsMap,
      itemsMap,
      groupsMap,
      modifiersMap
    } = this.props;

    if (!activeItem) {
      return null;
    }

    return (
      <DialogRightSidebar>
        <DialogItemBox>
          <DialogItemName>
            {activeItem.Name || activeItem.OptionName}
          </DialogItemName>

          {!!activeItem.Description && (
            <DialogItemDescription className='wordWrap'>
              {activeItem.Description}
            </DialogItemDescription>
          )}

          <DialogItemStrings>
            {(itemType === 'modifier' || itemType === 'item') && (
              <>
                <DialogItemString>
                  <strong>Price:</strong>
                  <p>${activeItem.Price}</p>
                </DialogItemString>

                <DialogItemString>
                  <strong>Contains Alcohol:</strong>
                  <p>{activeItem.ContainsAlcohol ? 'Yes' : 'No'}</p>
                </DialogItemString>
              </>
            )}

            {activeItem.ExternalId && (
              <DialogItemString>
                <strong>External ID</strong>
                <p>{activeItem.ExternalId}</p>
              </DialogItemString>
            )}

            {activeItem.ExternalData && (
              <DialogItemString>
                <strong>External Data</strong>
                <p>{activeItem.ExternalData}</p>
              </DialogItemString>
            )}
          </DialogItemStrings>
        </DialogItemBox>

        {itemType === 'category' && (
          <DialogItemBox>
            <DialogItemDescription>Items</DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(activeItem.Items && !activeItem.Items.length) && (
                      <DialogItemString>
                        <p>This Section has no Items</p>
                      </DialogItemString>
                    )}
                    {Boolean(activeItem.Items && activeItem.Items.length) &&
                      activeItem.Items.map((posId) => {
                        const item = itemsMap[posId];

                        if (!item) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {item.Name}
                            </TableCell>
                            <TableCell>{item.Price} $</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'item' && (
          <DialogItemBox>
            <DialogItemDescription>
              Used in these Sections
            </DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.Sections && !activeItem.Sections.length
                    ) && (
                      <DialogItemString>
                        <p>This Item does not belong to any Section</p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.Sections && activeItem.Sections.length
                    ) &&
                      activeItem.Sections.map((merchantSectionId) => {
                        const section = sectionsMap[merchantSectionId];

                        if (!section) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {section.Name}
                            </TableCell>
                            <TableCell component='td' scope='row' />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'item' && (
          <DialogItemBox>
            <DialogItemDescription>Modifier Groups</DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.OptionsGroups &&
                        !activeItem.OptionsGroups.length
                    ) && (
                      <DialogItemString>
                        <p>This Item has no Modifier Groups</p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.OptionsGroups &&
                        activeItem.OptionsGroups.length
                    ) &&
                      activeItem.OptionsGroups.map((posId) => {
                        const group = groupsMap[posId];

                        if (!group) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {group.OptionName}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'modifierGroup' && (
          <DialogItemBox>
            <DialogItemDescription>Used in these Items</DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.MenuItems && !activeItem.MenuItems.length
                    ) && (
                      <DialogItemString>
                        <p>This Modifier Group does not belong to any Items</p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.MenuItems && activeItem.MenuItems.length
                    ) &&
                      activeItem.MenuItems.map((posId) => {
                        const item = itemsMap[posId];

                        if (!item) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {item.Name}
                            </TableCell>
                            <TableCell>{item.Price} $</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'modifierGroup' && (
          <DialogItemBox>
            <DialogItemDescription>
              Used in these Modifiers
            </DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.Modifiers && !activeItem.Modifiers.length
                    ) && (
                      <DialogItemString>
                        <p>
                          This Modifier Group does not belong to any Modifiers
                        </p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.Modifiers && activeItem.Modifiers.length
                    ) &&
                      activeItem.Modifiers.map((posId) => {
                        const modifier = modifiersMap[posId];

                        if (!modifier) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {modifier.Name}
                            </TableCell>
                            <TableCell>{modifier.Price} $</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'modifierGroup' && (
          <DialogItemBox>
            <DialogItemDescription>Modifiers</DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(activeItem.Items && !activeItem.Items.length) && (
                      <DialogItemString>
                        <p>This Modifier Group has no Modifiers</p>
                      </DialogItemString>
                    )}
                    {Boolean(activeItem.Items && activeItem.Items.length) &&
                      activeItem.Items.map((posId) => {
                        const modifier = modifiersMap[posId];

                        if (!modifier) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {modifier.Name}
                            </TableCell>
                            <TableCell>{modifier.Price} $</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'modifier' && (
          <DialogItemBox>
            <DialogItemDescription>
              Used in these Modifier Groups
            </DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.ParentModifierGroups &&
                        !activeItem.ParentModifierGroups.length
                    ) && (
                      <DialogItemString>
                        <p>
                          This Modifier does not belong to any Modifier Groups
                        </p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.ParentModifierGroups &&
                        activeItem.ParentModifierGroups.length
                    ) &&
                      activeItem.ParentModifierGroups.map((posId) => {
                        const group = groupsMap[posId];

                        if (!group) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {group.OptionName}
                            </TableCell>
                            <TableCell component='td' scope='row' />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}

        {itemType === 'modifier' && (
          <DialogItemBox>
            <DialogItemDescription>Modifier Groups</DialogItemDescription>
            <DialogItemTable>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(
                      activeItem.ModifierGroups &&
                        !activeItem.ModifierGroups.length
                    ) && (
                      <DialogItemString>
                        <p>This Modifier Groups has no Modifier Groups</p>
                      </DialogItemString>
                    )}
                    {Boolean(
                      activeItem.ModifierGroups &&
                        activeItem.ModifierGroups.length
                    ) &&
                      activeItem.ModifierGroups.map((posId) => {
                        const group = groupsMap[posId];

                        if (!group) {
                          return null;
                        }

                        return (
                          <TableRow>
                            <TableCell component='td' scope='row'>
                              {group.OptionName}
                            </TableCell>
                            <TableCell component='td' scope='row' />
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogItemTable>
          </DialogItemBox>
        )}
      </DialogRightSidebar>
    );
  }
}

RightSidebar.propTypes = {
  activeItem: T.object.isRequired,
  itemType: T.string.isRequired
};

export default connect((state) => ({
  sectionsMap: state.menuEditorReducer.sectionsMap,
  itemsMap: state.menuEditorReducer.itemsMap,
  groupsMap: state.menuEditorReducer.groupsMap,
  modifiersMap: state.menuEditorReducer.modifiersMap
}))(RightSidebar);
