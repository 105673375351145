/* eslint-disable */
import {createSingleBranch, countOutOffStock} from './MenuBranch';

export function sortByNumber(list, field) {
  list.forEach((item) => {
    item[field] = item[field] !== null ? +item[field] : 999999;
  });

  list.sort((prev, next) => {
    const prevField = prev[field];
    const nextField = next[field];

    return prevField - nextField;
  });

  return list;
}

export function sortByString(list, field) {
  list.sort((prev, next) => {
    const prevField = prev[field];
    const nextField = next[field];

    if (prevField > nextField) {
      return 1;
    }

    if (prevField < nextField) {
      return -1;
    }

    return prevField - nextField;
  });

  return list;
}

export function createMap(list, keyField) {
  return list.reduce((map, item) => {
    map[item[keyField]] = item;

    return map;
  }, {});
}

export function createBranchMap(list, type) {
  return list.reduce((map, item) => {
    const branch = createSingleBranch(item, type);
    map[branch.$id] = branch;

    return map;
  }, {});
}

export function setActiveDirection(direction, branchMap) {
  return direction.reduce((list, id) => {
    if (branchMap.hasOwnProperty(id)) {
      list.push(id);
    }

    return list;
  }, []);
}

export function setActiveBranchMapsForTreeSearch(store, activeTab, search) {
  const {
    sectionsBranchMap,
    itemsBranchMap,
    groupsBranchMap,
    modifiersBranchMap,

    sectionsDirection,
    itemsDirection,
    groupsDirection,
    modifiersDirection
  } = store;

  store.activeSectionBranchMap = {};
  store.activeItemsBranchMap = {};
  store.activeGroupsBranchMap = {};
  store.activeModifiersBranchMap = {};

  store.activeSectionsDirection = [];
  store.activeItemsDirection = [];
  store.activeGroupsDirection = [];
  store.activeModifiersDirection = [];

  const reduceSearchMap = (direction, branchMap) => {
    return direction.reduce((map, id) => {
      const branch = branchMap[id];
      const {$branchScheme, $searchString, $children} = branch;
      const isSearched = $searchString.indexOf(search) !== -1;

      map.set(id, {
        $id: id,
        $children,
        $branchStore: $branchScheme.branchStoreMap,
        $search: {
          isShow: isSearched,
          isEq: isSearched
        }
      });

      return map;
    }, new Map());
  };
  const searchedSections = reduceSearchMap(
    sectionsDirection,
    sectionsBranchMap
  );
  const searchedItems = reduceSearchMap(itemsDirection, itemsBranchMap);
  const searchedGroups = reduceSearchMap(groupsDirection, groupsBranchMap);
  const searchedModifiers = reduceSearchMap(
    modifiersDirection,
    modifiersBranchMap
  );

  const setParentShow = (parent) => {
    if (parent) {
      parent.$search.isShow = true;
    }
  };

  const filterModifiers = (children, level, parent) => {
    const nextChildren = [];

    if (level < 5) {
      for (const id of children) {
        if (store.activeModifiersBranchMap.hasOwnProperty(id)) {
          setParentShow(parent);
          nextChildren.push(id);
          continue;
        }

        const activeBranch = searchedModifiers.get(id);
        activeBranch.$children = filterGroups(
          activeBranch.$children,
          level + 1,
          activeBranch
        );

        if (activeBranch.$search.isShow) {
          setParentShow(parent);
          store.activeModifiersBranchMap[id] = activeBranch;
          nextChildren.push(id);
        }
      }
    }

    return nextChildren;
  };

  const filterGroups = (children, level, parent) => {
    const nextChildren = [];

    if (level < 5) {
      for (const id of children) {
        if (store.activeGroupsBranchMap.hasOwnProperty(id)) {
          setParentShow(parent);
          nextChildren.push(id);
          continue;
        }

        const activeBranch = searchedGroups.get(id);
        activeBranch.$children = filterModifiers(
          activeBranch.$children,
          level + 1,
          activeBranch
        );

        if (activeBranch.$search.isShow) {
          setParentShow(parent);
          store.activeGroupsBranchMap[id] = activeBranch;
          nextChildren.push(id);
        }
      }
    }

    return nextChildren;
  };

  const filterItems = (children, level, parent) => {
    const nextChildren = [];

    for (const id of children) {
      if (store.activeItemsBranchMap.hasOwnProperty(id)) {
        setParentShow(parent);
        nextChildren.push(id);
        continue;
      }

      const activeBranch = searchedItems.get(id);
      activeBranch.$children = filterGroups(
        activeBranch.$children,
        level + 1,
        activeBranch
      );

      if (activeBranch.$search.isShow) {
        setParentShow(parent);
        store.activeItemsBranchMap[id] = activeBranch;
        nextChildren.push(id);
      }
    }

    return nextChildren;
  };

  const filterSections = (children, level) => {
    const nextChildren = [];

    for (const id of children) {
      if (store.activeSectionBranchMap.hasOwnProperty(id)) {
        nextChildren.push(id);
        continue;
      }

      const activeBranch = searchedSections.get(id);
      activeBranch.$children = filterItems(
        activeBranch.$children,
        level + 1,
        activeBranch
      );

      if (activeBranch.$search.isShow) {
        store.activeSectionBranchMap[id] = activeBranch;
        nextChildren.push(id);
      }
    }

    return nextChildren;
  };

  if (activeTab === 'CATEGORY') {
    store.activeSectionsDirection = filterSections(sectionsDirection, 1);
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
  }

  if (activeTab === 'ITEM') {
    store.activeItemsDirection = filterItems(itemsDirection, 2);
  }

  if (activeTab === 'GROUP') {
    store.activeGroupsDirection = filterGroups(groupsDirection, 3);
  }

  if (activeTab === 'MODIFIER') {
    store.activeModifiersDirection = filterModifiers(modifiersDirection, 4);
  }

  if (activeTab === 'OUT_OFF_STOCK') {
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].InStock === false
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].InStock === false
    );

    store.activeItemsDirection = filterItems(filterItemsDirection, 2);
    store.activeModifiersDirection = filterModifiers(
      filterModifiersDirection,
      4
    );
  }

  if (activeTab === 'HIDDEN') {
    const filterSectionDirection = sectionsDirection.filter(
      (id) => store.sectionsMap[id].Visible === false
    );
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].Invisible === true
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].Invisible === true
    );

    store.activeSectionsDirection = filterSections(filterSectionDirection, 1);
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
    store.activeItemsDirection = filterItems(filterItemsDirection, 2);
    store.activeModifiersDirection = filterModifiers(
      filterModifiersDirection,
      4
    );
  }

  return store;
}

export function setActiveBranchMapsForSearch(store, activeTab, search) {
  const {
    sectionsBranchMap,
    itemsBranchMap,
    groupsBranchMap,
    modifiersBranchMap,

    sectionsDirection,
    itemsDirection,
    groupsDirection,
    modifiersDirection
  } = store;

  store.activeSectionsDirection = [];
  store.activeItemsDirection = [];
  store.activeGroupsDirection = [];
  store.activeModifiersDirection = [];

  const reduceSearchMap = (direction, branchMap) => {
    return direction.reduce((map, id) => {
      const branch = branchMap[id];
      const {$branchScheme, $searchString, $children} = branch;
      const isSearched = $searchString.indexOf(search) !== -1;

      if (isSearched) {
        map.set(id, {
          $id: id,
          $children,
          $branchStore: $branchScheme.branchStoreMap,
          $search: null
        });
      }

      return map;
    }, new Map());
  };

  const filterForSearch = (direction, branchMap) => {
    const nextChildren = [];
    const searchedSections = reduceSearchMap(direction, branchMap);

    for (const id of direction) {
      const activeBranch = searchedSections.get(id);

      if (activeBranch) {
        nextChildren.push(id);
      }
    }

    return nextChildren;
  };

  if (activeTab === 'CATEGORY') {
    store.activeSectionsDirection = filterForSearch(
      sectionsDirection,
      sectionsBranchMap
    );
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
  }

  if (activeTab === 'ITEM') {
    store.activeItemsDirection = filterForSearch(
      itemsDirection,
      itemsBranchMap
    );
  }

  if (activeTab === 'GROUP') {
    store.activeGroupsDirection = filterForSearch(
      groupsDirection,
      groupsBranchMap
    );
  }

  if (activeTab === 'MODIFIER') {
    store.activeModifiersDirection = filterForSearch(
      modifiersDirection,
      modifiersBranchMap
    );
  }

  if (activeTab === 'OUT_OFF_STOCK') {
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].InStock === false
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].InStock === false
    );

    store.activeItemsDirection = filterForSearch(
      filterItemsDirection,
      itemsBranchMap
    );
    store.activeModifiersDirection = filterForSearch(
      filterModifiersDirection,
      modifiersBranchMap
    );
  }

  if (activeTab === 'HIDDEN') {
    const filterSectionDirection = sectionsDirection.filter(
      (id) => store.sectionsMap[id].Visible === false
    );
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].Invisible === true
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].Invisible === true
    );

    store.activeSectionsDirection = filterForSearch(
      filterSectionDirection,
      sectionsBranchMap
    );
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
    store.activeItemsDirection = filterForSearch(
      filterItemsDirection,
      itemsBranchMap
    );
    store.activeModifiersDirection = filterForSearch(
      filterModifiersDirection,
      modifiersBranchMap
    );
  }

  return store;
}

export function setActiveBranchMaps(store, activeTab) {
  const {
    sectionsBranchMap,
    itemsBranchMap,
    groupsBranchMap,
    modifiersBranchMap,

    sectionsDirection,
    itemsDirection,
    groupsDirection,
    modifiersDirection
  } = store;

  const reduceActiveMap = (direction, branchMap) => {
    return direction.reduce((map, id) => {
      const branch = branchMap[id];
      const {$branchScheme, $children} = branch;

      map[id] = {
        $id: id,
        $children,
        $branchStore: $branchScheme.branchStoreMap,
        $search: null
      };

      return map;
    }, {});
  };

  store.activeSectionBranchMap = reduceActiveMap(
    sectionsDirection,
    sectionsBranchMap
  );
  store.activeItemsBranchMap = reduceActiveMap(itemsDirection, itemsBranchMap);
  store.activeGroupsBranchMap = reduceActiveMap(
    groupsDirection,
    groupsBranchMap
  );
  store.activeModifiersBranchMap = reduceActiveMap(
    modifiersDirection,
    modifiersBranchMap
  );
  store.activeSectionsDirection = [];
  store.activeItemsDirection = [];
  store.activeGroupsDirection = [];
  store.activeModifiersDirection = [];

  if (activeTab === 'CATEGORY') {
    const {sectionsDirection, activeSectionBranchMap} = store;
    store.activeSectionsDirection = setActiveDirection(
      sectionsDirection,
      activeSectionBranchMap
    );
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
  }

  if (activeTab === 'ITEM') {
    const {itemsDirection, activeItemsBranchMap} = store;
    store.activeItemsDirection = setActiveDirection(
      itemsDirection,
      activeItemsBranchMap
    );
  }

  if (activeTab === 'GROUP') {
    const {groupsDirection, activeGroupsBranchMap} = store;
    store.activeGroupsDirection = setActiveDirection(
      groupsDirection,
      activeGroupsBranchMap
    );
  }

  if (activeTab === 'MODIFIER') {
    const {modifiersDirection, activeModifiersBranchMap} = store;
    store.activeModifiersDirection = setActiveDirection(
      modifiersDirection,
      activeModifiersBranchMap
    );
  }

  if (activeTab === 'OUT_OFF_STOCK') {
    const {
      itemsDirection,
      activeItemsBranchMap,
      modifiersDirection,
      activeModifiersBranchMap
    } = store;
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].InStock === false
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].InStock === false
    );

    store.activeItemsDirection = setActiveDirection(
      filterItemsDirection,
      activeItemsBranchMap
    );
    store.activeModifiersDirection = setActiveDirection(
      filterModifiersDirection,
      activeModifiersBranchMap
    );
  }

  if (activeTab === 'HIDDEN') {
    const {
      sectionsDirection,
      activeSectionBranchMap,
      itemsDirection,
      activeItemsBranchMap,
      modifiersDirection,
      activeModifiersBranchMap
    } = store;
    const filterSectionDirection = sectionsDirection.filter(
      (id) => store.sectionsMap[id].Visible === false
    );
    const filterItemsDirection = itemsDirection.filter(
      (id) => store.itemsMap[id].Invisible === true
    );
    const filterModifiersDirection = modifiersDirection.filter(
      (id) => store.modifiersMap[id].Invisible === true
    );

    store.activeSectionsDirection = setActiveDirection(
      filterSectionDirection,
      activeSectionBranchMap
    );
    store.activeSectionBranchMap = reduceActiveMap(
      store.activeSectionsDirection,
      sectionsBranchMap
    );
    store.activeSectionsDirection.forEach((id) => countOutOffStock(id, store));
    store.activeItemsDirection = setActiveDirection(
      filterItemsDirection,
      activeItemsBranchMap
    );
    store.activeModifiersDirection = setActiveDirection(
      filterModifiersDirection,
      activeModifiersBranchMap
    );
  }

  return store;
}
