import styled from 'styled-components';
import IconNotification from '../../../assets/icon-bell.svg';

const ButtonNotification = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background-image: url('${IconNotification}');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-color: transparent;
  display: inline-block;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export default ButtonNotification;
