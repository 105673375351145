import React from 'react';
import {Slider} from '@material-ui/core';
import T from 'prop-types';
import {RangeSliderContainer} from './styles';

const RangeSliderS2E = ({defaultValue, min, max, step, onChange}) => {
  return (
    <RangeSliderContainer>
      <Slider
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        aria-label='custom thumb label'
        defaultValue={defaultValue}
      />
    </RangeSliderContainer>
  );
};

RangeSliderS2E.propTypes = {
  min: T.number.isRequired,
  max: T.number.isRequired,
  step: T.number.isRequired,
  defaultValue: T.string.isRequired,
  onChange: T.func
};

RangeSliderS2E.defaultProps = {
  onChange: () => {}
};

export default RangeSliderS2E;
