import React from 'react';
import T from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch} from 'react-redux';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {DialogText} from 'routes/App/dashboard/styles';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {OPEN_EMAIL_MARKETING_TURN_OFF_MODAL} from 'store/discounts/action';

const EmailMarketingTurnOffModal = ({isOpen, setFieldValue}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(OPEN_EMAIL_MARKETING_TURN_OFF_MODAL(false));
  };
  return (
    <Dialog open={isOpen}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom verticalCenter'>
          Are you sure you&apos;d like to turn off this discount for email
          marketing?
        </DialogTitle>

        <DialogBody>
          <DialogText className='smText'>
            Studies show that email campaigns with coupons can boost conversion
            rates by more than 50%!
          </DialogText>
          <DialogActions>
            <DialogAction className='DialogActions w50'>
              <ButtonOutline onClick={onClose}>
                <span>Cancel</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='DialogActions w50'>
              <ButtonPrimary
                onClick={() => {
                  setFieldValue('EmailMarketing', false);
                  dispatch(OPEN_EMAIL_MARKETING_TURN_OFF_MODAL(false));
                }}
              >
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EmailMarketingTurnOffModal.propTypes = {
  isOpen: T.bool.isRequired,
  setFieldValue: T.func.isRequired
};

export default EmailMarketingTurnOffModal;
