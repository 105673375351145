import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import T from 'prop-types';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default class DragnDropList extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.props.changeOrderAction(
      reorder(
        [...this.props.dragDataList],
        result.source.index,
        result.destination.index
      )
    );
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='DraGnDropList'
            >
              {this.props.items.map(
                (
                  {
                    id,
                    ChildComponent,
                    componentProps,
                    withoutDrag,
                    withBulkCheckbox,
                    bulkSelectedMap
                  },
                  index
                ) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(innerProvided) => (
                      <div
                        className='DraGnDropItem'
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                      >
                        <ChildComponent
                          {...componentProps}
                          withoutDrag={withoutDrag}
                          withBulkCheckbox={withBulkCheckbox}
                          bulkSelectedMap={bulkSelectedMap}
                          dragHandleProps={innerProvided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

DragnDropList.propTypes = {
  items: T.array.isRequired,
  changeOrderAction: T.func.isRequired,
  dragDataList: T.array.isRequired
};
