import * as Yup from 'yup';
import {MIN_ADJUST_AMOUNT, MAX_ADJUST_AMOUNT} from 'helpers/constants/orders';

const OrderAdjustSchema = Yup.object().shape({
  amount: Yup.number()
    .min(MIN_ADJUST_AMOUNT, `Min allowed adjustment is $${MIN_ADJUST_AMOUNT}`)
    .max(MAX_ADJUST_AMOUNT, `Max allowed adjustment is $${MAX_ADJUST_AMOUNT}`)
    .required('Amount is required')
    .nullable(),
  reason: Yup.string().required('Reason is required'),
  otherReason: Yup.string().when('reason', {
    is: 'Other',
    then: Yup.string()
      .max(150, 'Reason cannot be more than 150 characters')
      .nullable(),
    otherwise: Yup.string().nullable().notRequired()
  })
});

export default OrderAdjustSchema;
