import styled from 'styled-components';

// TODO: an experimental component as well as FlexItem. May not be used in future.

const FlexContainer = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || null};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => (props.alignItems ? 'center' : '')};
  flex-wrap: ${(props) => (props.flexWrap ? 'wrap' : 'nowrap')};
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.flexColumn {
    flex-direction: column;
  }
  &.flexRow {
    flex-direction: row;
  }
  &.gap10 {
    gap: 10px;
  }
  &.gap50 {
    gap: 50px;
  }
  &.gap30 {
    gap: 30px;
  }
  &.align-center {
    align-items: center;
  }
  &.w100 {
    width: 100%;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.TableCenter {
    align-items: center;
    flex-wrap: wrap;
    * {
      margin: 2px 5px;
    }
  }
  &.TableHorizontalCenter {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  &.ButtonsCenter {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -3px;
    @media (max-width: 1420px) {
      margin: 0 -2px;
    }

    & > * {
      margin: 2px 3px;
      @media (max-width: 1420px) {
        margin: 2px;
      }
    }
  }
  &.ColumnCenter {
    display: flex;
    justify-content: center;
    .plusButton {
      margin-left: 20px;
    }
    .minusButton {
      margin-right: 20px;
    }
    .activeCounter {
      cursor: pointer;
    }
    .disabledCounter {
      pointer-events: none;
      svg {
        circle {
          stroke: #929cb9;
        }
        rect {
          fill: #929cb9;
        }
      }
    }
    .quantityCounter {
      margin: 0;
      width: 20px;
      text-align: center;
    }
  }
  &.ColumnEnd {
    display: flex;
    justify-content: center;
  }

  &.orderProvider {
    @media (max-width: 1000px) {
      display: block;
    }
  }
  &.FlexContainerNotification {
    @media (max-width: 599px) {
      display: block;
    }
  }
  &.OrderDetails {
    @media (max-width: 599px) {
      display: block;
    }
  }
  &.UberEatsBox {
    @media (max-width: 599px) {
      display: block;

      & > * {
        &:nth-child(2) {
          text-align: center;
          justify-content: center;
          margin: 15px 0 0 0;
          & > * {
            justify-content: center;
          }
        }
      }
    }
  }

  &.RecursionRelation_Row {
    align-items: center;
    .RecursionRelation_ClearBtn {
      flex: 1 1 auto;
      height: 1px;
      background-color: #929cb9;
      margin: 0 10px;
    }
  }
  &.UberConnect {
    .ButtonOutline {
      position: relative;
      margin-right: 58px;
      min-width: 194px;

      @media (max-width: 1023px) {
        min-width: auto;
      }
      @media (max-width: 767px) {
        margin-right: 40px;
      }
      @media (max-width: 599px) {
        margin-right: 30px;
      }
      @media (max-width: 400px) {
        margin-right: 20px;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 54px;
        background-color: #dfe2ea;
        @media (max-width: 767px) {
          right: -25px;
          height: 40px;
        }
        @media (max-width: 599px) {
          right: -18px;
        }
        @media (max-width: 400px) {
          right: -13px;
        }
      }
    }
  }
  .wAutoSpace {
    flex: 1 1 auto;
  }
  .wAuto {
    flex: 0 0 auto;
  }
  &.RefundItemName {
    padding-left: ${(props) => {
      if (props.modifierLevel) {
        return `${30 * (props.modifierLevel - 1)}px`;
      }
      return '0';
    }};
  }
  &.realTimeBoardV2Search {
    width: 100%;
    max-width: 390px;
  }
`;

export default FlexContainer;
