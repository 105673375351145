/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useEffect, useState, memo} from 'react';
import {Prompt} from 'react-router-dom';
import PublishMessage from '../components/Publish/PublishMessage';

const RouteLeavingGuardNew = ({
  when,
  navigate,
  titleText,
  confirmedNavigation,
  messageUnPublish,
  setMessageUnPublish,
  iconMessage,
  buttonConfirm,
  buttonCancel,
  forbiddenNextLocations = []
}) => {
  const [lastLocation, setLastLocation] = useState(null);

  const handleBlockedNavigation = (nextLocation) => {
    const isNextLocationAllowed = forbiddenNextLocations.find((nextLoc) =>
      nextLocation.pathname.includes(nextLoc)
    );
    if (!confirmedNavigation && !isNextLocationAllowed) {
      setMessageUnPublish(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (messageUnPublish) {
      window.scrollTo(0, 0);
    }
  }, [messageUnPublish]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {messageUnPublish && (
        <PublishMessage
          iconMessage={iconMessage}
          titleText={titleText}
          buttonCancel={buttonCancel}
          buttonConfirm={buttonConfirm}
        />
      )}
    </>
  );
};
export default memo(RouteLeavingGuardNew);
