import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonOutline} from '../../../../components/buttons';
import {
  SELECT_ALL_BUTTON,
  TOGGLE_CHECKBOX_MENU_ITEM
} from '../../../../store/menuEditor/action';
import {IconSelectAll} from '../../../../assets/IconSelectAll';
import {IconUnSelectAll} from '../../../../assets/IconUnSelectAll';

function SelectAllButton({disabled}) {
  const dispatch = useDispatch();
  const selectAllButton = useSelector(
    (store) => store.menuEditorReducer.selectAllButton
  );

  return (
    <ButtonOutline
      className='ButtonSmall Dark ButtonSelectAll'
      onClick={() => {
        dispatch(TOGGLE_CHECKBOX_MENU_ITEM('all'));
        dispatch(SELECT_ALL_BUTTON());
      }}
    >
      {!selectAllButton ? <IconSelectAll /> : <IconUnSelectAll />}
      {!selectAllButton ? 'Select all' : 'Unselect all'}
    </ButtonOutline>
  );
}

SelectAllButton.propTypes = {
  disabled: PropTypes.bool
};

SelectAllButton.defaultProps = {
  disabled: false
};

export default SelectAllButton;
