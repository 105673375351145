import styled from 'styled-components';
import IconPlus from '../../../assets/icon-plus-button-green.svg';
import IconMinus from '../../../assets/icon-minus-button-orange.svg';

const ButtonOutline = styled.button`
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  position: relative;
  background-color: transparent;
  font-size: 18px;
  line-height: 1;
  color: #ff2d38;
  border-radius: 30px;
  padding: 17px 24px;
  outline: none;
  cursor: pointer;
  border: 2px solid #ff2d38;
  box-shadow: 0px 8px 16px rgba(21, 206, 117, 0);
  transition-property: background-color;
  transition-duration: 0.3s;

  &:hover {
    background-color: rgba(255, 45, 56, 0.06);
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }

  &.ButtonMedium {
    font-size: 14px;
    padding: 14px 24px;
  }

  &.ButtonSmall {
    font-size: 16px;
    font-weight: 500;
    padding: 9px 24px;
    padding-left: ${(props) => (props.withIcon ? '50px' : '24px')};
  }

  &.ButtonSmallXs {
    font-size: 14px;
    font-weight: 700;
    padding: 9px 24px;

    @media (max-width: 1420px) {
      padding: 7px 20px;
      font-size: 13px;
    }
  }

  &.RadiusMin {
    border-radius: 8px;
  }

  &.paddingLeftRightMin {
    padding-left: 12px;
    padding-right: 12px;
    @media (max-width: 1420px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &.EditVAP {
    max-width: 420px;
  }

  &.buttonRefund {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    background-image: none;
    background-color: #fff;
    box-shadow: none;
    cursor: default;
    border-color: #ededed;
    pointer-events: none;
    opacity: 0.5;
  }

  &.disabledRefundButton {
    background-image: none;
    background-color: #fff;
    box-shadow: none;
    cursor: default;
    border-color: #ededed;
    pointer-events: none;
    color: #ededed;

    svg {
      path {
        fill: #ededed;
      }
    }
  }

  &.secondary {
    color: #232d3a;
    border: 2px solid #172751;

    &:hover {
      background-color: rgba(23, 39, 81, 0.1);
    }
  }

  &.ButtonSelectAll {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 24px;

    @media (max-width: 1360px) {
      padding: 7px 16px;
    }

    svg {
      width: 20px;
      flex: 0 0 auto;
      margin-right: 6px;
    }
  }

  &.active {
    background-color: #ff2d38;
    color: #fff;
  }

  &.ButtonXs {
    font-weight: 500;
    font-size: 12px;
    padding: 5px 8px;
  }

  &.ButtonSm {
    font-weight: 500;
    font-size: 14px;
    padding: 10px 19px;

    &.Delivery {
      min-width: 150px;
      max-width: 150px;
      margin-right: 20px;
    }
  }

  &.ButtonLarge {
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    padding: 8px 45px;
    @media (max-width: 599px) {
      padding: 8px 30px;
    }
  }

  &.ToastButton {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    border: 2px solid #fff;
    padding: 10px 15px;
  }

  &.ButtonMd {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 24px;
    @media (max-width: 1000px) {
      font-size: 14px;
    }
  }

  &.ButtonMd2 {
    font-size: 14px;
    padding: 14px 24px;
    @media (max-width: 1000px) {
      font-size: 14px;
    }
  }

  &.forgot-password {
    width: 45%;
    margin: auto;
  }

  &.npm,
  &.ButtonLg {
    padding: 17px 24px;
  }

  &.mR20 {
    margin-right: 20px;
  }

  &.ButtonPlus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url(${IconPlus});
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 10px;
    }
  }

  &.ButtonMinusOrange {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 20px;
    border-color: #ff8412;
    color: #ff8412;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url(${IconMinus});
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 10px;
    }

    &:hover {
      background-color: rgba(255, 132, 18, 0.15);
    }
  }

  &.eventOn {
    pointer-events: auto;
  }

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.Dark {
    border-color: #2f3d48;
    box-shadow: none;
    color: #2f3d48;

    &:hover {
      background-color: rgba(47, 61, 72, 0.06);
    }
  }

  &.Orange {
    border-color: #fca600;
    box-shadow: none;
    color: #fca600;

    &:hover {
      background-color: rgba(252, 166, 0, 0.06);
    }
  }

  &.Hidden {
    padding: 7px 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1360px) {
      padding: 7px 14px;
    }

    svg {
      margin-right: 6px;
      height: 20px;
    }
  }

  &.Delete {
    padding: 7px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1360px) {
      padding: 7px 14px;
    }

    svg {
      margin-right: 6px;
      height: 20px;
    }
  }

  &.OutOfStock {
    padding: 8px 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1360px) {
      padding: 8px 14px;
    }

    .OutOfStockNumber {
      flex: 0 0 auto;
      background-color: #fca600;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 18px;
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      color: #fff;
      margin: 0 6px 0 0;
    }
  }

  &.ButtonMin {
    min-width: 120px;
  }

  &.fw700 {
    font-weight: 700;
  }

  &.ButtonDelivery {
    max-width: 115px;
    min-width: 115px;
    font-size: 12px;
    font-weight: 700;
    padding: 8px 5px;
    margin-right: 10px;
    @media (max-width: 1440px) {
      max-width: 76px;
      min-width: 76px;
      padding: 6px 5px;
    }
    @media (max-width: 1250px) {
      margin-right: 2px;
    }
  }
  &.EditIntegrations {
    font-size: 14px;
    padding: 16px 19px;
  }
`;

export default ButtonOutline;
