import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import body1 from '../../../../components/layout/body1/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';

export const ReportExportButton = styled.div`
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;

  color: #fff;
  box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32);
  border-radius: 30px;

  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;

  max-width: 188px;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding: 9px 53px;
  line-height: 28px;
  display: flex;
  align-items: center;

  div {
    margin-right: 11px;
    z-index: 3;
    display: flex;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &:hover {
    &::before {
      opacity: 0;
    }
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 3;
    @media (max-width: 599px) {
      line-height: 1.2;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #f0868c 96.81%
    );
    z-index: 2;
    transition-property: opacity;
    transition-duration: 0.3s;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #ff2d38 96.81%
    );
    z-index: 1;
  }

  a {
    display: block;
    text-decoration: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    width: 100%;
    position: relative;
    z-index: 3;
  }
`;

export const ReportButtonPrimary = styled(ButtonPrimary)`
  max-width: 188px;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding: 9px 53px;
  line-height: 28px;
  display: flex;
  align-items: center;
  div {
    margin-right: 11px;
    z-index: 3;
    display: flex;
  }
`;

export const ReportsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1250px) {
    display: block;
  }
`;
export const ReportsHeaderInnerDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ReportsTitle = styled(body1)`
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  position: relative;
`;

export const ReportsSubTitle = styled.div`
  display: inline-block;
  font-weight: 500;
  color: #2f3d48;
  font-size: 24px;
  line-height: 1;
  @media (max-width: 1250px) {
    margin-bottom: 15px;
    font-size: 20px;
  }
`;

export const ReportsDatepicker = styled.div`
  display: block;

  width: calc(50% - 10px);
  @media (min-width: 1600px) {
    width: 75%;
  }
  @media (max-width: 1600px) {
    width: 75%;
  }
  @media (max-width: 1250px) {
    width: 100%;
  }
`;

export const ReportsTable = styled.div`
  margin-top: 32px;
  .MuiTableCell-root {
    border-bottom: 0;
  }
  .MuiTable-root {
    min-width: 767px;
  }
`;

export const TableHeadText = styled(body1)`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
`;
// export const TableText = styled(TableHeadText)`
//   font-weight: normal;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   width: 100%;
//   padding-right: 20px;
// `;

export const ReportsTableCell = styled(TableCell)`
  max-width: 120px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 1250px) {
    max-width: 165px;
    width: 165px;
  }
  @media (min-width: 1600px) {
    max-width: 200px;
    width: 200px;
  }
  &.MuiTableCell-sizeSmall {
    padding: 28px 0 0 0;
  }
  &.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox:last-child {
    padding: 0;
  }
  &.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox {
    padding: 0;
  }
  &:last-child {
    max-width: 135px;
    width: 135px;
  }
`;
export const ReportsTableCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  &.MuiCheckbox-root {
    color: #ff2d38;
  }
  &.MuiCheckbox-colorSecondary.Mui-checked {
    color: #ff2d38;
  }
`;

export const NoReportsContainer = styled.div`
  min-height: 350px;

  svg {
    position: absolute;
    top: 50%;
    left: calc((50% - 170px) + 136px);

    @media (max-width: 1250px) {
      left: calc(50% - 170px);
    }
  }
`;

export const TableContainer = styled.div`
  @media (max-width: 1000px) {
    overflow-x: scroll;
  }

  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #232d3a;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;

    &.headTableCell {
      padding-top: 30px;
      padding-bottom: 15px;
      @media (max-width: 1024px) {
        padding-top: 25px;
      }
      @media (max-width: 767px) {
        padding-top: 20px;
      }
    }

    &.cellVerticalAlignStart {
      vertical-align: top;
    }

    @media (max-width: 1700px) {
      font-size: 14px;
    }
  }

  .MuiTable-root {
    border-collapse: separate;

    .MuiTableRow-root td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .MuiTableRow-root td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &.refundsReportTable {
    .MuiTable-root {
      tr {
        &:first-child {
          th {
            width: 14%;
          }
        }
      }
    }
  }

  &.customersReportTable {
    .MuiTable-root {
      tr {
        &:first-child {
          th {
            width: 10%;
          }
        }
      }
    }
  }

  .tableRow {
    vertical-align: middle;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.2),
      10px 10px 20px rgba(108, 49, 151, 0.06),
      30px 30px 30px rgba(108, 49, 151, 0.04);

    .reportsPayoutStatusContainer {
      margin: auto;
      padding: 0 !important;

      div {
        padding: 0 !important;
      }

      .reportsPayoutStatus {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 12px;
        text-transform: uppercase;
        color: #ffffff;
        background: #ff2d38;
        border-radius: 17px;
        padding: 1px 7px;
        white-space: nowrap;
      }
    }

    .cellNoWrap {
      white-space: nowrap;
    }

    .cellWordBreak {
      word-break: break-word;
    }
    .cellVerticalAlignStart {
      vertical-align: top !important;
    }

    .minWithColumn {
      min-width: 130px;

      @media (max-width: 1600px) {
        min-width: 100px;
      }
      @media (max-width: 900px) {
        min-width: auto;
      }
    }
  }

  .MuiTableCell-root {
    border-bottom: 0;
    position: relative;
    padding: 15px 6px;
    @media (max-width: 1500px) {
      padding: 15px 4px;
    }
    @media (max-width: 1024px) {
      padding: 5px;
    }

    &:first-child {
      padding-left: 28px;
    }

    &:last-child {
      padding-right: 12px;
      @media (max-width: 1000px) {
        padding-right: 15px;
      }
    }
  }
`;

export const TableText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #232d3a;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
  &.reportsTablePadding {
    div:first-child {
      padding: 10px 0;
    }
  }
  &.reportsTotal {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 21px;
    color: #172751;

    div:first-child {
      padding: 10px 0;
    }
  }
  .centeredText {
    text-align: center;
  }
  .reportsGrayText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 115%;
    color: #929cb9;
  }
`;
