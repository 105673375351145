import {request} from 'helpers/services/utils/request';
import {GET, PUT} from 'helpers/services/constants/API_CONSTANTS';
import {buildUrl} from 'helpers/functionUtils/AdminBuildUrl';
import {
  GET_ALL_SUDO_USER_FEATURE_FLAGS_API,
  GET_SUDO_USERS_API,
  SUDO_USER_API
} from 'helpers/services/constants/API_ENDPOINTS';

export const getSudoUsersApi = async (params) => {
  return request(GET, buildUrl(GET_SUDO_USERS_API(), params));
};
export const updateSudoUserApi = async (id, data) => {
  return request(PUT, SUDO_USER_API(id), {data});
};
export const getSudoUserApi = async (id) => {
  return request(GET, SUDO_USER_API(id));
};
export const getAllSudoUserFeatureFlagsApi = async () => {
  return request(GET, GET_ALL_SUDO_USER_FEATURE_FLAGS_API());
};
