import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Headline from '../../../../components/Headline/styles';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import RCHeader from './Components/RCHeader';
import RCSearch from './Components/RCSearch';
import RCOptions from './Components/RCOptions';
import AdditionInformationModal from './Components/Modals/AdditionInformationModal';
import {RefundsCalculatorContainer} from './styles';
import RCResult from './Components/RCResult';
import RequestResultModal from './Components/Modals/RequestResultModal';
import {RC_CLEAR_ALL_CALCULATOR_DATA} from '../../../../store/refundsCalculator/action';

const RefundsCalculator = () => {
  const [openAdditionInfoModal, setOpenAdditionInfoModal] = useState(false);
  const {orderData, refunds, resultModal, resultModalInfo} = useSelector(
    (state) => state.refundsCalculator
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(RC_CLEAR_ALL_CALCULATOR_DATA());
  }, []);

  return (
    <RefundsCalculatorContainer>
      <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
        <DashboardHeader>
          <Headline>Refunds Calculator</Headline>
        </DashboardHeader>
        <RCSearch />
        {!!orderData?.id && (
          <>
            <RCHeader
              orderData={orderData}
              setOpenAdditionInfoModal={setOpenAdditionInfoModal}
              refunds={refunds}
            />
            {refunds.length ? <RCResult /> : <RCOptions />}
          </>
        )}
      </DashboardContent>
      <AdditionInformationModal
        orderData={orderData}
        openAdditionInfoModal={openAdditionInfoModal}
        setOpenAdditionInfoModal={setOpenAdditionInfoModal}
      />
      <RequestResultModal openModal={resultModal} info={resultModalInfo} />
    </RefundsCalculatorContainer>
  );
};

export default RefundsCalculator;
