/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import DialogContainer from '../../../../components/DialogContainer/styles';
import Body1 from '../../../../components/typography/Body1';
import {DialogBody} from '../../../../components/ModalBox/style';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import Dialog from '@material-ui/core/Dialog';
import Body4 from '../../../../components/typography/Body4';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import BoxMenuItem from '../../../../components/BoxMenuItem/styles';
import BoxMenuItems from '../../../../components/BoxMenuItems/styles';
import LinkIcon from '@material-ui/icons/Link';
import DialogTitle from '../../../../components/DialogTitle/styles';
import Loading from '../../../../components/shared/Utils/Loading';

function RecursionRelationModal(props) {
  const {isOpen, isLoading, relations, message, onCancel, onOk} = props;

  return (
    <Dialog open={isOpen} onClose={onCancel} scroll='body'>
      <DialogContainer className='DialogSm ConfirmModal'>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <DialogTitle className='marginBottomLg'>Attention</DialogTitle>
            <Body1 className='ConfirmModal-Title widthAuto'>{message}</Body1>
            <div className='RecursionRelation'>
              {relations.map((item, index) => {
                const [parent, child] = item;

                return (
                  <BoxMenuItems
                    key={index}
                    className='RecursionRelation_Row marginBottomXs'
                  >
                    <BoxMenuItem>
                      <Body1 className='RecursionRelation_ItemType'>
                        {parent.type === 'group' ? 'Group' : 'Modifier'}:
                      </Body1>
                      <div className='BoxMenuItemContainer'>
                        <Body4 className='RecursionRelation_ItemTitle'>
                          {parent.type === 'group'
                            ? parent.data.OptionName
                            : parent.data.Name}
                        </Body4>
                      </div>
                    </BoxMenuItem>
                    <LinkIcon className='RecursionRelation_Connect' />
                    <BoxMenuItem>
                      <Body1 className='RecursionRelation_ItemType'>
                        {child.type === 'group' ? 'Group' : 'Modifier'}:
                      </Body1>
                      <div className='BoxMenuItemContainer'>
                        <Body4 className='RecursionRelation_ItemTitle'>
                          {child.type === 'group'
                            ? child.data.OptionName
                            : child.data.Name}
                        </Body4>
                      </div>
                    </BoxMenuItem>
                  </BoxMenuItems>
                );
              })}
            </div>
            <DialogBody className='ConfirmModal-BtnPanel'>
              {!!relations.length && (
                <DialogActions className='marginTopNull'>
                  <DialogAction className='wAuto'>
                    <ButtonPrimary
                      className='ButtonSmall ButtonMin'
                      onClick={onOk}
                    >
                      <span>Clear all relations</span>
                    </ButtonPrimary>
                  </DialogAction>
                  <DialogAction className='wAuto'>
                    <ButtonOutline
                      className='ButtonSmall ButtonMin'
                      onClick={onCancel}
                    >
                      Cancel
                    </ButtonOutline>
                  </DialogAction>
                </DialogActions>
              )}
              {!relations.length && (
                <DialogActions className='marginTopNull'>
                  <DialogAction className='wAuto'>
                    <ButtonPrimary
                      className='ButtonSmall ButtonMin'
                      onClick={onOk}
                    >
                      <span>Publish</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogActions>
              )}
            </DialogBody>
          </>
        )}
      </DialogContainer>
    </Dialog>
  );
}

RecursionRelationModal.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  relations: PropTypes.array,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

RecursionRelationModal.defaultProps = {
  isOpen: false,
  isLoading: false,
  relations: [],
  message: '',
  onCancel: () => {},
  onOk: () => {}
};

export default RecursionRelationModal;
