import styled from 'styled-components';

export const RefundsBottomBar = styled.div`
  height: 163px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 1px -10px 11px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  position: fixed;
  bottom: 0;
  //left: 0;
  right: 0;
  z-index: 2;
  width: calc(100% - 272px);
  @media (max-width: 1250px) {
    width: 100%;
  }

  .refundBottomContainer {
    width: 100%;
    display: flex;
    max-width: 100%;
    padding: 0 130px 0 102px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 1250px) {
      max-width: 557px;
      padding: 0;
    }

    div:first-child {
      margin-bottom: 10px;
      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 27px;
        color: #172751;
        margin-bottom: 5px;
        margin-right: 20px;

        span {
          font-style: normal;
          font-weight: 700;
          color: #2f3d48;
          margin-left: 50px;
        }
      }
      .RefundError {
        position: inherit !important;
      }
    }
  }

  .refundButtonContainer {
    width: 100%;
    display: flex;
    button {
      margin: 0 10px;
    }
  }
`;

export const RefundsAmountErrorBanner = styled.div`
  min-height: 30px;
  width: 100%;
  background: #ff2d38;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  border-radius: 10px 10px 0 0;
  position: absolute;
  bottom: 100%;
  display: none;
  justify-content: center;
  align-items: center;

  &.error {
    display: flex;
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-align: center;
  }
`;

export const CancelRefundButton = styled.button`
  font-family: 'Poppins';
  height: 56px;
  width: 100%;
  max-width: 268px;
  //width: 268px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ff2d38;
  border: 2px solid #ff2d38;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  background: #fff;
`;

export const SubmitRefundButton = styled.button`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 268px;
  //width: 268px;
  font-family: 'Poppins';
  background: #ff2d38;
  box-shadow: 0px 8px 16px rgba(255, 66, 45, 0.32);
  border-radius: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  color: #fff;
  padding: 19px 26px;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;

  &.disabled {
    background-image: none;
    background-color: #ededed;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: none;
  }
`;
