/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment/moment';
import T from 'prop-types';
import {Dialog} from '@material-ui/core';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {DialogBody, DialogText} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import {
  PUBLISH_LOCATION,
  SET_STORE_HOLIDAYS,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../../../store/locationSettings/action';
import Modal from '../../../../components/Modals/Modal';
import {UberEatsImage} from '../../uberEats/styles';
import AnimateLocationDelivery from '../../../../assets/animate-delivery-red.gif';
import AnimateLocationPickup from '../../../../assets/animate-pizza-red.gif';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';

const ConfirmCancelHolidayModal = ({
  showCancelHolidaysModal,
  setShowCancelHolidaysModal,
  setHideBannerHolidays,
  onDisable,
  cancelHolidayStoreObject
}) => {
  const dispatch = useDispatch();

  const publishLocation = () => {
    dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallBack: () => {},
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  const cancelHolidaysStore = () => {
    const dataCancelHolidays = {
      storeHolidays: {
        isCCAgent: '',
        enableStoreTime: '',
        disableStoreTime: ''
      }
    };
    dispatch(SET_STORE_HOLIDAYS(dataCancelHolidays));
    setShowCancelHolidaysModal(false);

    dispatch(
      UPDATE_LOCATION_SETTINGS_DATA({
        payload: dataCancelHolidays,
        successCallback: () => {
          publishLocation();
        },
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );

    // dispatch(SET_STORE_HOLIDAYS(cancelHolidayStoreObject));
    // onDisable();
    // setShowCancelHolidaysModal(false);
    setHideBannerHolidays(true);
  };

  return (
    // <Dialog
    //   open={showCancelHolidaysModal}
    //   scroll='body'
    //   onClose={() => setShowCancelHolidaysModal(false)}
    // >
    //   <DialogContainer className='DialogSm2'>
    //     <DialogClose onClick={() => setShowCancelHolidaysModal(false)} />
    //     <DialogTitle style={{textAlign: 'center'}}>
    //       Are you sure you want to cancel?
    //     </DialogTitle>
    //     <DialogBody>
    //       <DialogActions className='marginTop25 justifyCenter'>
    //         <DialogAction className='w50'>
    //           <ButtonOutline
    //             type='button'
    //             className='dismiss'
    //             onClick={() => setShowCancelHolidaysModal(false)}
    //           >
    //             <span>No</span>
    //           </ButtonOutline>
    //         </DialogAction>
    //         <DialogAction className='w50'>
    //           <ButtonPrimary
    //             type='submit'
    //             onClick={() => {
    //               cancelHolidaysStore();
    //             }}
    //           >
    //             <span>Yes</span>
    //           </ButtonPrimary>
    //         </DialogAction>
    //       </DialogActions>
    //     </DialogBody>
    //   </DialogContainer>
    // </Dialog>

    <>
      {showCancelHolidaysModal && (
        <Modal
          onCloseAction={() => setShowCancelHolidaysModal(false)}
          title={
            <DialogTitle className='marginBottom2l'>
              Cancel future dates limitation?
            </DialogTitle>
          }
          width='715px'
          body={
            <>
              <UberEatsImage src={AnimateLocationPickup} />
              <DialogActions>
                <DialogAction className='w50'>
                  <ButtonOutline
                    onClick={() => setShowCancelHolidaysModal(false)}
                  >
                    <span>No</span>
                  </ButtonOutline>
                </DialogAction>
                <DialogAction className='w50'>
                  <ButtonPrimary
                    onClick={() => {
                      cancelHolidaysStore();
                    }}
                  >
                    <span>Yes</span>
                  </ButtonPrimary>
                </DialogAction>
              </DialogActions>
            </>
          }
        />
      )}
    </>
  );
};

ConfirmCancelHolidayModal.propTypes = {
  cancelHolidayStoreObject: T.func.isRequired,
  onDisable: T.func.isRequired,
  setShowCancelHolidaysModal: T.func.isRequired,
  showCancelHolidaysModal: T.bool.isRequired,
  setHideBannerHolidays: T.func.isRequired
};
export default ConfirmCancelHolidayModal;
