import styled from 'styled-components';

const Body1 = styled.div`
  font-size: 14px;
  color: #232d3a;
  white-space: pre-line;
  font-weight: 400;
  &.sm {
    font-size: 16px;
  }
  &.grey {
    color: #6e7d89;
  }
  &.grey2 {
    color: #929cb9;
  }
  &.RedText {
    color: #ff2d38;
  }
  &.relativeAndTop {
    position: relative;
    top: 2px;
  }
`;

export default Body1;
