/* eslint-disable */
import React, {useEffect} from 'react';
import T from 'prop-types';
import {Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import moment from 'moment';
import {connect} from 'react-redux';
import DialogDisableTime from '../../../../components/DialogDisableTime';
import {
  OPEN_DISABLE_MODAL,
  PUBLISH_LOCATION,
  SET_STATUS_BUTTON,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../../../store/locationSettings/action';
import {generateEnableTime} from '../helpers/GenerateEnableTime';
import {
  TIMESTAMP_MODIFIED_NAME,
  TIMESTAMP_TYPE
} from '../constants/DisableModalConstants';
import {disableSchema} from '../../../../helpers/services/formValidationSchemas/SelfCreateAccountSchema';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import {checkIsFutureOrder} from '../../../../helpers/functionUtils/ordersUtils';
import DisableModalWithFutureOrders from './DisableModalWithFutureOrders';
import {getSessionStorageItem} from '../../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../../helpers/sessionStorage/constants';
import DisableStoreForHolidayModal from './DisableStoreForHolidayModal';
import ConfirmCancelHolidayModal from './ConfirmCancelHolidayModal';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';
import {intervals} from '../helpers';

class LocationDisableModal extends React.Component {
  onCancel = () => {
    this.props.OPEN_DISABLE_MODAL({
      open: false
    });
  };

  onCloseDisableModal = () => {
    this.props.OPEN_DISABLE_MODAL({
      entityName: this.props.entityName,
      open: false
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      storeHolidayObject: {},
      validateDateError: false
    };
  }
  isOpenConfirmModal = () => {
    this.onCloseDisableModal();
    this.setState({openModal: true});
  };
  isCloseConfirmModal = () => {
    this.setState({openModal: false});
  };

  isOpenHolidayModal = () => {
    this.onCloseDisableModal();
    this.setState({openHolidayModal: true});
  };

  isCloseHolidayModal = () => {
    this.setState({openHolidayModal: false});
  };
  setValidateDateError = (value) => {
    this.setState({...this.state, validateDateError: value});
  };
  render() {
    const {openModal} = this.state;
    const {openHolidayModal} = this.state;
    const {
      isOpen,
      entityName,
      setGlobalFieldValue,
      currentLocationTimezone,
      locationTimeZone,
      isCheckFutureOrder,
      showCancelHolidaysModal,
      setShowCancelHolidaysModal,
      setHideBannerHolidays,
      data,
      setValuesHolidays,
      setFormSubmitting,
      dispatch,
      disableButton
    } = this.props;

    const checkIfSauceEmployee = () => {
      return getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
    };

    const disableHolidayStore = (values) => {
      const enableTimeStore = generateEnableTime(
        TIMESTAMP_TYPE[values.radioValue],
        currentLocationTimezone,
        values,
        this.props.openingHours
      );
      const disableTimeStore = generateEnableTime(
        TIMESTAMP_TYPE['custom-dates-request-disable'],
        currentLocationTimezone,
        values,
        this.props.openingHours
      );
      this.storeHolidayObject = {
        isCCAgent: !!checkIfSauceEmployee(),
        disableStoreReason: values.reasonValue,
        enableStoreTime: new Date(
          new Date(Number(enableTimeStore) * 1000).toISOString()
        ),
        disableStoreTime: new Date(
          new Date(Number(disableTimeStore) * 1000).toISOString()
        )
      };

      return this.storeHolidayObject;
    };

    const publishLocation = () => {
      dispatch(
        PUBLISH_LOCATION({
          preCallback: () => {
            dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
            dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
            this.onCancel();
          },
          postCallBack: () => {},
          errorCallback: () => {
            dispatch(STOP_GET_PUBLISH_STATUS_JOB());
            dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
            this.onCancel();
            dispatch(SET_STATUS_BUTTON(false));
          }
        })
      );
    };

    const cancelHolidayStoreObject = () => {
      this.storeHolidayObject = {
        isCCAgent: '',
        enableStoreTime: '',
        disableStoreTime: ''
      };

      return this.storeHolidayObject;
    };
    const saveSettings = (values) => {
      dispatch(SET_STATUS_BUTTON(true));

      if (values.reasonValue === 'Other') {
        // eslint-disable-next-line no-param-reassign
        values.reasonValue = values.reasonDescription;
      }
      const enableTime = generateEnableTime(
        TIMESTAMP_TYPE[values.radioValue],
        currentLocationTimezone,
        values,
        this.props.openingHours
      );
      if (showCancelHolidaysModal) {
        setGlobalFieldValue('storeHolidays', cancelHolidayStoreObject());
        setHideBannerHolidays(false);
      }
      if (values.radioValue === 'custom-dates-request') {
        disableHolidayStore(values);
        if (
          moment(moment(this.storeHolidayObject.enableStoreTime)).isBefore(
            moment(this.storeHolidayObject.disableStoreTime)
          )
        ) {
          this.setValidateDateError(true);
          return;
        }
        const diffDate = () => {
          const duration = moment.duration(
            moment(
              moment(this.storeHolidayObject?.enableStoreTime).utc().format()
            ).diff(
              moment(this.storeHolidayObject?.disableStoreTime).utc().format()
            )
          );
          const day = duration.asDays();
          return Math.floor(day);
        };

        if (!!checkIfSauceEmployee() || diffDate() <= 3) {
          setGlobalFieldValue('storeHolidays', disableHolidayStore(values));
          setHideBannerHolidays(false);
        }
        this.onCancel();
        return;
      }

      const enableDateTime = moment.unix(enableTime);
      sendGtmEvent(`${entityName}Disabled`, {
        until:
          typeof enableDateTime.toISOString === 'function' &&
          enableDateTime.toISOString(),
        reason: values.reasonValue
      });
      if (entityName === 'Store') {
        const storeObj = {
          ClosedManually: true,
          ClosedManuallyTime: new Date(),
          'notSyncedData.ClosedManually': true,
          ClosedManuallyReason: values.reasonValue,
          StoreDisableOption: TIMESTAMP_MODIFIED_NAME[values.radioValue],
          ClosedManuallyUntilTime: new Date(
            new Date(Number(enableTime) * 1000).toISOString()
          )
        };
        Object.entries(storeObj).forEach(([key, value]) => {
          if (key === 'ClosedManuallyTime') {
            setGlobalFieldValue(
              key,
              moment(value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
            );
          } else if (key === 'ClosedManuallyUntilTime') {
            setGlobalFieldValue(
              key,
              (Date.parse(String(value)) / 1000).toString()
            );
          } else {
            setGlobalFieldValue(key, value);
          }
        });
        setFormSubmitting(false);
        dispatch(
          UPDATE_LOCATION_SETTINGS_DATA({
            payload: storeObj,
            successCallback: () => {
              publishLocation();
            },
            errorCallback: () => {
              dispatch(STOP_GET_PUBLISH_STATUS_JOB());
              dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
            }
          })
        );
      } else if (entityName) {
        const entityObj = {
          [`DeliveryOptions.${entityName}`]: false,
          [`notSyncedData.DeliveryOptions.${entityName}`]: false,
          [`${entityName}EnableIn`]: enableTime,
          [`${entityName}DisableReason`]: values.reasonValue,
          [`${entityName}DisableOption`]: TIMESTAMP_MODIFIED_NAME[
            values.radioValue
          ]
        };
        Object.entries(entityObj).forEach(([key, value]) => {
          setGlobalFieldValue(key, value);
        });
        this.onCancel();
        // setFormSubmitting(true);
        dispatch(SET_STATUS_BUTTON(false));
      }
    };

    let dateToday = moment(new Date()).utcOffset(0, true).format();

    let dateOneWeek = new Date();
    dateOneWeek.setDate(dateOneWeek.getDate() + 7);
    dateOneWeek = moment(dateOneWeek).endOf('day').utcOffset(0, true).format();

    const intervalsArr = intervals('00:00', '23:45', 15);

    const roundMinutesToQuarter = (time) => {
      const interval = Math.ceil(time.minutes() / 15);
      switch (interval) {
        case 4:
          time.minutes(0);
          time.add(1, 'hours');
          return time;
        default:
          time.minutes(interval * 15);
          return time;
      }
    };

    const getCorrectTime = () => {
      let correctTimeByLocation = moment().utcOffset(Number(locationTimeZone));

      const isSameDay = moment(dateToday).isSame(correctTimeByLocation, 'day');
      if (!isSameDay) return '10:00 AM'; // for future days

      correctTimeByLocation = roundMinutesToQuarter(
        correctTimeByLocation
      ).format('h:mm A');
      const correctTimeIndex = intervalsArr.findIndex(
        (i) => i === correctTimeByLocation
      );

      const startTimeIndex = intervalsArr.findIndex((i) => i === '10:00 AM');

      if (correctTimeIndex < startTimeIndex) {
        return '10:00 AM';
      } else {
        return intervalsArr[correctTimeIndex];
      }
    };

    return (
      <>
        <Formik
          validationSchema={disableSchema}
          initialValues={{
            dateValue: new Date(),
            dateValues: [dateToday, dateOneWeek],
            entityName: '',
            periodValue: 'AM',
            reasonValue: '',
            selectValue: '',
            selectTimeValue: '10:00 AM',
            selectDisableTimeValue: getCorrectTime(),
            radioValue: 'one-hour',
            reasonDescription: ''
          }}
          onSubmit={(values) => {
            if (!!isCheckFutureOrder) {
              this.isOpenConfirmModal();
            } else {
              saveSettings(values);
            }
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            resetForm,
            setFieldValue,
            setTouched,
            errors,
            touched
          }) => (
            <form onSubmit={handleSubmit}>
              {isOpen && (
                <>
                  <ConnectedFocusError />
                  <DialogDisableTime
                    handleRadioValue={handleChange}
                    entityName={entityName}
                    valueDate={values.dateValue}
                    valueDates={values.dateValues}
                    selectValue={values.selectValue}
                    selectTimeValue={values.selectTimeValue}
                    selectDisableTimeValue={values.selectDisableTimeValue}
                    radioValue={values.radioValue}
                    handleValueDate={setFieldValue}
                    handleSelectValue={handleChange}
                    reasonValue={values.reasonValue}
                    reasonDescription={values.reasonDescription}
                    onDisable={handleSubmit}
                    onCancel={this.onCancel}
                    locationTimeZone={locationTimeZone}
                    formErrors={errors}
                    disableHolidayStore={disableHolidayStore(values)}
                    formTouched={touched}
                    setTouched={setTouched}
                    data={data}
                    onMakeRequest={() => this.isOpenHolidayModal()}
                    setValidateDateError={this.setValidateDateError}
                    validateDateError={this.state.validateDateError}
                    disableButton={disableButton}
                  />
                </>
              )}
              <DisableModalWithFutureOrders
                openModal={openModal}
                onDisable={() => saveSettings(values)}
                isCloseConfirmModal={() => this.isCloseConfirmModal()}
              />
              <DisableStoreForHolidayModal
                openHolidayModal={openHolidayModal}
                onDisable={() => saveSettings(values)}
                disableHolidayStore={disableHolidayStore(values)}
                isCloseHolidayModal={() => this.isCloseHolidayModal()}
                setValuesHolidays={setValuesHolidays}
                resetForm={resetForm}
                setFormSubmitting={setFormSubmitting}
                setGlobalFieldValue={setGlobalFieldValue}
                setHideBannerHolidays={setHideBannerHolidays}
              />
              <ConfirmCancelHolidayModal
                showCancelHolidaysModal={showCancelHolidaysModal}
                setShowCancelHolidaysModal={setShowCancelHolidaysModal}
                setHideBannerHolidays={setHideBannerHolidays}
                onDisable={() => saveSettings(values)}
                data={data}
                cancelHolidayStoreObject={cancelHolidayStoreObject()}
                isCloseHolidayModal={() => this.isCloseHolidayModal()}
              />
            </form>
          )}
        </Formik>
      </>
    );
  }
}

LocationDisableModal.propTypes = {
  isOpen: T.bool.isRequired,
  showCancelHolidaysModal: T.bool.isRequired,
  setShowCancelHolidaysModal: T.func.isRequired,
  setHideBannerHolidays: T.func.isRequired,
  isCheckFutureOrder: T.bool.isRequired,
  entityName: T.string.isRequired,
  setGlobalFieldValue: T.func.isRequired,
  OPEN_DISABLE_MODAL: T.func.isRequired,
  currentLocationTimezone: T.string.isRequired,
  openingHours: T.array.isRequired,
  data: T.array.isRequired,
  locationTimeZone: T.string.isRequired,
  setFormSubmitting: T.func.isRequired,
  dispatch: T.func.isRequired,
  disableButton: T.bool.isRequired
};

export default connect(
  (store) => ({
    isOpen: store.locationSettingsReducer.isDisableModalOpen,
    isCheckFutureOrder: store.activeOrdersReducer.activeOrders?.find((el) =>
      checkIsFutureOrder(el)
    ),
    entityName: store.locationSettingsReducer.confirmationEntityName,
    currentLocationTimezone: store.user.timezone,
    locationTimeZone: store.locationSettingsReducer.data.Timezone
  }),
  {
    OPEN_DISABLE_MODAL
  }
)(LocationDisableModal);
