import {
  put,
  call,
  take,
  race,
  delay,
  cancel,
  select,
  takeLatest
} from 'redux-saga/effects';
import moment from 'moment';

import {getStorageToken} from '../../../helpers/localStorage/authStorage';

import {
  RUN_BAD_DELIVERED_ORDERS_JOB,
  STOP_BAD_DELIVERED_ORDERS_JOB,
  SET_BAD_NEW_DELIVERED_ORDERS,
  SET_BAD_DELIVERED_ORDERS,
  SET_BAD_DELIVERED_ORDERS_COUNT,
  SET_BAD_TABLE_CONFIG,
  SET_BAD_ACTIVE_ORDERS,
  SET_BAD_NEW_ACTIVE_ORDERS,
  SET_BAD_PREV_ACTIVE_ORDERS
} from './action';
import {getBADOrdersDeliveredApi} from '../../../helpers/services/api/bookADrive';

const GET_NEW_ORDERS_INTERVAL = 25000;

const currentDeliveryOrders = ({bookADriverDeliveredOrdersReducer}) =>
  bookADriverDeliveredOrdersReducer.deliveredOrders;
const prevActiveOrders = ({bookADriverDeliveredOrdersReducer}) =>
  bookADriverDeliveredOrdersReducer.prevActiveOrders;
const currentDeliveryConfig = ({bookADriverDeliveredOrdersReducer}) =>
  bookADriverDeliveredOrdersReducer.config;

export function* getDeliveredOrdersJob() {
  while (true) {
    try {
      yield put(
        SET_BAD_TABLE_CONFIG({to: encodeURIComponent(new Date().toISOString())})
      );
      const config = yield select(currentDeliveryConfig);
      //  const data = [];
      const {data} = yield getBADOrdersDeliveredApi(config);

      const currentOrders = yield select(currentDeliveryOrders);
      const prevActiveOrdersList = yield select(prevActiveOrders);

      const deliveredOrdersList = data.data.rows.filter(
        (order) => order.status === 'delivered'
      );
      const activeOrdersList = data.data.rows.filter(
        (order) => order?.s2e_meta?.status !== 'Delivered'
      );

      yield put(SET_BAD_DELIVERED_ORDERS({data: deliveredOrdersList || []}));
      yield put(SET_BAD_DELIVERED_ORDERS_COUNT(data.data.total));
      yield put(SET_BAD_ACTIVE_ORDERS({data: activeOrdersList || []}));

      const nowDate = moment().utc().format();

      const diff = currentOrders
        ? deliveredOrdersList.filter(
            (newOrder) =>
              !currentOrders?.filter(
                (order) => order._id === newOrder._id
              )[0] &&
              moment(newOrder?.s2e_meta?.dropoff_eta)
                .utcOffset(0, true)
                .diff(nowDate, 'minutes') < 20 &&
              moment(newOrder?.s2e_meta?.dropoff_eta)
                .utcOffset(0, true)
                .diff(nowDate, 'minutes') > 0
          )
        : [];

      const newActive = activeOrdersList.filter(
        (newOrder) =>
          moment(newOrder?.s2e_meta?.pickup_eta)
            .utcOffset(0, true)
            .diff(nowDate, 'seconds') > 270 &&
          moment(newOrder?.s2e_meta?.pickup_eta)
            .utcOffset(0, true)
            .diff(nowDate, 'seconds') < 330 &&
          (prevActiveOrdersList?.length === 0 ||
            !prevActiveOrdersList?.filter(
              (order) => order._id === newOrder._id
            )[0])
      );

      if (diff.length > 0) {
        yield put(SET_BAD_NEW_DELIVERED_ORDERS(diff));
      }
      if (newActive.length > 0) {
        yield put(SET_BAD_NEW_ACTIVE_ORDERS(newActive));
        yield put(SET_BAD_PREV_ACTIVE_ORDERS(newActive));
      }
    } catch (error) {
      console.error(error);
    }

    yield delay(GET_NEW_ORDERS_INTERVAL);
  }
}

function* runGetDeliveredOrdersJob({payload}) {
  try {
    const isOnline = yield select(
      (state) => state.globalNotificationsReducer?.isNetworkOnline
    );
    const userData = yield select((state) => state.user.userData);
    const {token} = getStorageToken();

    if (!isOnline || !token || !userData.role || userData.role === 'sudo') {
      return;
    }

    const {stop} = yield race({
      job: call(() => getDeliveredOrdersJob(payload)),
      stop: take(STOP_BAD_DELIVERED_ORDERS_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* bookADriverDeliveredOrdersSaga() {
  yield takeLatest(RUN_BAD_DELIVERED_ORDERS_JOB, runGetDeliveredOrdersJob);
}

export default bookADriverDeliveredOrdersSaga;
