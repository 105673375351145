import {createReducer} from 'redux-act';
import {
  SAVE_STEP_DATA,
  SHOW_STEP_MODAL,
  GET_STEPS,
  GET_STEPS_SUCCESS,
  POST_STEP,
  POST_STEP_SUCCESS,
  SET_FINAL_SETUP,
  GET_COUNTRIES_SUCCESS,
  SET_ROUTE_STEP,
  CONTROL_MODALS,
  CONTROL_SIDEBAR_PROGRESS
} from './action';

const initialState = () => ({
  loading: false,
  pendingDataSetuped: false,
  isModalOpen: false,
  isConfirmedModalOpen: false,
  isWelcomeModalOpen: false,
  dataReceived: null,
  finalSetup: false,
  sidebarProgress: '',
  routeStep: 1,
  countries: [],
  step: 0,
  userId: '',
  PendingData: [
    {
      step: 0,
      userId: '',
      User: {},
      Merchant: {},
      Location: {}
    },
    {
      step: 1,
      userId: '',
      deliveryTypeUsage: '',
      aspectsToImprove: [],
      other: ''
    },
    {
      step: 2,
      userId: '',
      Phone: '',
      CountryCode: '',
      FirstName: '',
      LastName: '',
      email: '',
      RestaurantURL: '',
      role: ''
    }
  ]
});

const selfCreateAccountReducer = createReducer(
  {
    [SHOW_STEP_MODAL]: (state, {open}) => ({
      ...state,
      isModalOpen: open
    }),
    [SAVE_STEP_DATA]: (state, payload) => {
      const PendingData = [...state.PendingData];
      const index = PendingData.findIndex((item) => item.step === payload.step);
      PendingData.splice(
        index !== -1 ? index : payload.step,
        PendingData.length + 1,
        payload.data
      );
      return {
        ...state,
        PendingData,
        userId: payload.userId,
        step: payload.step
      };
    },
    [GET_STEPS]: (state) => ({
      ...state,
      loading: true
    }),
    [SET_FINAL_SETUP]: (state, payload) => ({
      ...state,
      finalSetup: payload
    }),
    [GET_STEPS_SUCCESS]: (state, {pendingDataSetuped, ...info}) => {
      return {
        ...state,
        step: info.step || 0,
        loading: false,
        dataReceived: true,
        pendingDataSetuped,
        ...info
      };
    },
    [SET_ROUTE_STEP]: (state, payload) => ({
      ...state,
      routeStep: payload
    }),
    [GET_COUNTRIES_SUCCESS]: (state, payload) => ({
      ...state,
      countries: payload
    }),
    [CONTROL_MODALS]: (state, {open, field}) => ({
      ...state,
      [field]: open
    }),
    [CONTROL_SIDEBAR_PROGRESS]: (state, payload) => ({
      ...state,
      sidebarProgress: payload
    })
  },
  initialState()
);

export default selfCreateAccountReducer;
