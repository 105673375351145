import React from 'react';

const IconSidebarSettings = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.49274 1.14883C7.48648 0.950864 8.51258 0.950387 9.50652 1.14743C9.57274 1.56264 9.7366 1.95833 9.98594 2.3051C10.2353 2.65187 10.5637 2.94079 10.9467 3.1504C11.3295 3.36072 11.7572 3.48637 12.1982 3.51799C12.6391 3.54962 13.0818 3.4864 13.4935 3.33306C14.17 4.05254 14.6824 4.89725 14.9985 5.81402C14.6533 6.07617 14.3746 6.40886 14.1833 6.78736C13.992 7.16587 13.893 7.58047 13.8935 8.00035C13.893 8.42037 13.9922 8.83509 14.1838 9.21362C14.3754 9.59216 14.6544 9.92477 15 10.1867C14.682 11.1027 14.1689 11.9467 13.4927 12.6662C13.0812 12.513 12.6386 12.4499 12.1978 12.4815C11.757 12.5131 11.3294 12.6387 10.9467 12.8489C10.564 13.0584 10.2359 13.3472 9.98665 13.6937C9.73745 14.0402 9.5736 14.4356 9.50726 14.8505C8.51362 15.0489 7.48752 15.0498 6.49347 14.8533C6.42758 14.4377 6.26385 14.0416 6.0145 13.6944C5.76514 13.3473 5.43657 13.058 5.05325 12.8482C4.67044 12.638 4.24268 12.5125 3.80176 12.481C3.36084 12.4495 2.91812 12.5128 2.50653 12.6662C1.82995 11.9465 1.31752 11.1016 1.00147 10.1846C1.34653 9.92258 1.62505 9.59011 1.81635 9.21186C2.00765 8.83361 2.10682 8.41929 2.1065 7.99965C2.10687 7.57966 2.00757 7.16499 1.816 6.78647C1.62444 6.40796 1.34552 6.07533 1 5.81332C1.318 4.8973 1.83107 4.05327 2.50726 3.33376C2.91881 3.48699 3.36143 3.55015 3.80221 3.51852C4.24298 3.4869 4.67059 3.36131 5.05325 3.1511C5.43599 2.94156 5.76414 2.65282 6.01335 2.30631C6.26255 1.9598 6.4264 1.56443 6.49274 1.14953V1.14883ZM8 10.0999C8.58614 10.0999 9.14828 9.87869 9.56275 9.48495C9.97722 9.09121 10.2101 8.55718 10.2101 8.00035C10.2101 7.44351 9.97722 6.90949 9.56275 6.51574C9.14828 6.122 8.58614 5.9008 8 5.9008C7.41385 5.9008 6.85172 6.122 6.43725 6.51574C6.02278 6.90949 5.78994 7.44351 5.78994 8.00035C5.78994 8.55718 6.02278 9.09121 6.43725 9.48495C6.85172 9.87869 7.41385 10.0999 8 10.0999Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarSettings;
