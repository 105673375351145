import React from 'react';
import T from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from '../DialogContainer/styles';
import DialogClose from '../DialogClose/styles';
import DialogTitle from '../DialogTitle/styles';
import DialogBody from '../DialogBody/styles';
import {DialogAction} from '../../routes/App/dashboard/styles';
import {ButtonOutline, ButtonPrimary} from '../buttons';

const Notification = ({entityName, minutes, applyFunction, extendFunction}) => {
  return (
    <Dialog open scroll='body' onClose={() => applyFunction()}>
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => applyFunction()} />
        <DialogTitle>
          <span>{entityName}</span> will be enabled in {minutes}&nbsp;minutes
        </DialogTitle>
        <DialogBody>
          <DialogAction className='DialogActions'>
            <ButtonOutline onClick={() => extendFunction()}>
              <span>Extend Longer</span>
            </ButtonOutline>
            <ButtonPrimary onClick={() => applyFunction()}>
              <span>Great</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

Notification.propTypes = {
  entityName: T.string.isRequired,
  minutes: T.string.isRequired,
  applyFunction: T.func.isRequired,
  extendFunction: T.func.isRequired
};

export default Notification;
