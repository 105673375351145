export const virtualNumberSchema = (values) => {
  const errors = {};

  if (!values.CountryCode.length && !values.correctNumber) {
    errors.CountryCode = 'required';
  }

  if (!values.Phone.length && !values.correctNumber) {
    errors.Phone = 'This field is required!';
  }

  return errors;
};

export const restaurantSloganSchema = (values) => {
  const errors = {};

  if (!values.locationName.length) {
    errors.locationName = 'This field is required!';
  }

  if (!values.languages.length) {
    errors.languages = 'Please select at least one language';
  }

  if (!values.slogan.length) {
    errors.slogan = 'This field is required!';
  }

  if (values.slogan.length > 100) {
    errors.slogan = 'Max length is 100 characters!';
  }

  return errors;
};
