import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {
  DashboardField,
  DashboardFieldDatepicker,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  DashboardHeader,
  DashboardHolder,
  DashboardInnerDescription,
  FieldInput,
  FieldLabel
} from 'routes/App/dashboard/styles';
import DatepickerS2E from 'components/Datepicker';
import LinkBack from 'components/LinkBack/styles';
import history from '../../../../history';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import SwitchS2E from '../../../../components/Switch';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Subtitle4 from '../../../../components/typography/Subtitle4';
import {ButtonInfo, ButtonPrimary} from '../../../../components/buttons';
import {twoDecimalsFormatter} from '../../../../helpers/functionUtils/helpFunctions';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import {
  getEmailMarketingToggleError,
  getIsEmailMarketingTurnOffModalOpen
} from '../../../../store/discounts/selectors';
import {
  OPEN_EMAIL_MARKETING_TURN_OFF_MODAL,
  SET_EMAIL_MARKETING_TOGGLE_ERROR
} from '../../../../store/discounts/action';
import EmailMarketingTurnOffModal from '../DiscountsList/Modals/EmailMarketingTurnOffModal';

const Settings = (props) => {
  const {
    formik,
    discount,
    enabledDiscount,
    setEnabledDiscount,
    enabledDate,
    setEnabledDate,
    buttonStatus,
    allowedServiceFeatures
  } = props;

  const dispatch = useDispatch();
  const isEmailMarketingToggleError = getEmailMarketingToggleError();
  const isEmailMarketingTurnOffModalOpen = getIsEmailMarketingTurnOffModalOpen();

  useEffect(() => {
    if (isEmailMarketingToggleError) {
      formik.setFieldError(
        'EmailMarketing',
        'Another discount was already selected for Email Marketing, you can use only one'
      );
    }
    return () => dispatch(SET_EMAIL_MARKETING_TOGGLE_ERROR(false));
  }, [isEmailMarketingToggleError]);

  return (
    <>
      <DashboardHeader className='mb10'>
        <LinkBack onClick={() => history.push('/discounts')}>
          Back to all Discounts
        </LinkBack>
      </DashboardHeader>
      <DashboardInner>
        <Subtitle5 className='marginBottomXs'>Discount wizard</Subtitle5>
        <Subtitle4>
          <span>{discount?.Name}</span>
        </Subtitle4>
        <DashboardHolder className='marginTopLg'>
          <DashboardFields>
            <DashboardField className='w100 dFlex'>
              <DashboardFieldLabel>
                <FieldLabel
                  className={cn('FieldLabelLg marginBtNull', {
                    isDisabled: discount.Status === 0
                  })}
                >
                  Name of Discount
                </FieldLabel>
              </DashboardFieldLabel>
              <DashboardFieldInner className='w60FLex FieldRelative'>
                <FieldInput
                  className={cn('', {
                    error: formik.errors.Name,
                    isDisabled: discount.Status === 0
                  })}
                  name='Name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.Name}
                  placeholder='Name of Discount'
                />
                <ErrorSignWrapper className='ErrorSignWrapper'>
                  {formik.errors?.Name ? (
                    <ErrorSign>{formik.errors.Name}</ErrorSign>
                  ) : null}
                </ErrorSignWrapper>
              </DashboardFieldInner>
              <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                <div className='DropInfo DropInfoRight DropInfoRightMob'>
                  This name will be available for customers to see
                </div>
              </ButtonInfo>
            </DashboardField>
            <DashboardField className='w100 dFlex'>
              <DashboardFields className='marginMinus'>
                <DashboardField className='wAuto w50mob isDisabled'>
                  <FieldLabel className='FieldLabelLg'>
                    Discount Type
                  </FieldLabel>
                  <FieldInput
                    className={`${
                      formik.errors.DiscountType && 'error'
                    } FieldNumberLg`}
                    name='DiscountType'
                    type='text'
                    onChange={() => {}}
                    value={formik.values.DiscountType === 1 ? '%' : '$'}
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.DiscountType &&
                    formik.touched?.DiscountType ? (
                      <ErrorSign>{formik.errors.DiscountType}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel
                    className={cn('FieldLabelLg', {
                      isDisabled: discount.Status === 0
                    })}
                  >
                    Discount Value
                  </FieldLabel>
                  <FieldInput
                    className={cn('FieldNumberLg', {
                      error: formik.errors.Discount,
                      isDisabled: discount.Status === 0
                    })}
                    name='Discount'
                    type='text'
                    onChange={(e) =>
                      formik.setFieldValue(
                        'Discount',
                        twoDecimalsFormatter(
                          e.target.value,
                          formik.values.Discount,
                          formik.values.DiscountType === 1 ? 90 : 9999.99
                        ) || ''
                      )
                    }
                    onBlur={(e) => {
                      const lastElement = e.target.value.slice(-1)[0];
                      if (lastElement === '.') {
                        formik.setFieldValue(
                          'Discount',
                          e.target.value.slice(0, -1)
                        );
                      }
                    }}
                    value={formik.values.Discount}
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.Discount && formik.touched?.Discount ? (
                      <ErrorSign>{formik.errors.Discount}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAuto w50mob'>
                  <FieldLabel
                    className={cn('FieldLabelLg', {
                      isDisabled: discount.Status === 0
                    })}
                  >
                    Min Order
                  </FieldLabel>
                  <FieldInput
                    className={cn('FieldNumberLg', {
                      error: formik.errors.MinOrder,
                      isDisabled: discount.Status === 0
                    })}
                    name='MinOrder'
                    type='text'
                    onChange={(e) =>
                      formik.setFieldValue(
                        'MinOrder',
                        twoDecimalsFormatter(
                          e.target.value,
                          formik.values.MinOrder,
                          9999.99
                        ) || ''
                      )
                    }
                    onBlur={(e) => {
                      const lastElement = e.target.value.slice(-1)[0];
                      if (lastElement === '.') {
                        formik.setFieldValue(
                          'MinOrder',
                          e.target.value.slice(0, -1)
                        );
                      }
                    }}
                    value={formik.values.MinOrder}
                    placeholder=''
                  />
                  <ErrorSignWrapper className='ErrorSignWrapper'>
                    {formik.errors?.MinOrder ? (
                      <ErrorSign>{formik.errors.MinOrder}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                  {/* <ErrorSignWrapper className='ErrorSignWrapper'> */}
                  {/*  {formik.errors?.DiscountAmount && */}
                  {/*  formik.touched?.MinOrder ? ( */}
                  {/*    <ErrorSign>{formik.errors.DiscountAmount}</ErrorSign> */}
                  {/*  ) : null} */}
                  {/* </ErrorSignWrapper> */}
                </DashboardField>
              </DashboardFields>
            </DashboardField>
            <DashboardField>
              <DashboardInnerDescription className='withIcon'>
                <span>
                  This discount will be available between the following dates,
                  all week, all day, or until the Usage Limit is reached.
                </span>
              </DashboardInnerDescription>
            </DashboardField>
            <DashboardField className='dFlex w100 DiscountDatepicker'>
              <FormControlLabel
                className='Say2EatCheckbox'
                control={
                  <Checkbox
                    labelStyle={{color: '#2F3D48'}}
                    iconStyle={{fill: '#FF2D38'}}
                    inputStyle={{color: '#FF2D38'}}
                    style={{
                      color: '#FF2D38',
                      opacity: discount.Status === 0 ? '0.3' : null
                    }}
                    onChange={(e) => {
                      const {checked} = e.target;
                      setEnabledDate(checked);
                      if (!checked) {
                        formik.setFieldValue('StartTime', null);
                        formik.setFieldValue('EndTime', null);
                      }
                    }}
                    checked={enabledDate}
                    disabled={discount.Status === 0}
                  />
                }
                label='From'
              />
              <DashboardFieldDatepicker>
                <DatepickerS2E
                  hideTextFrom
                  disablePast
                  disabled={!enabledDate || discount.Status === 0}
                  value={[
                    formik.values.StartTime
                      ? moment(formik.values.StartTime).utc().format('MM/DD/yy')
                      : null,
                    formik.values.EndTime
                      ? moment(formik.values.EndTime).utc().format('MM/DD/yy')
                      : null
                  ]}
                  onClose={(value) => {
                    if (value && value.length) {
                      formik.setFieldValue(
                        'StartTime',
                        moment(value[0])
                          .utcOffset(0, true)
                          .startOf('day')
                          .format()
                      );
                      formik.setFieldValue(
                        'EndTime',
                        moment(value[1])
                          .utcOffset(0, true)
                          .endOf('day')
                          .format()
                      );
                    }
                  }}
                />
              </DashboardFieldDatepicker>
              <ErrorSignWrapper
                className='ErrorSignWrapper'
                leftPosition='93px'
              >
                {formik.errors?.StartTime && formik.touched?.StartTime ? (
                  <ErrorSign>{formik.errors.StartTime}</ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100'>
              <SwitchS2E
                labelPlacement='start'
                label={`Promo Code ${enabledDiscount ? 'Enabled' : 'Disabled'}`}
                checked={enabledDiscount}
                onChange={(e) => {
                  if (!e.target.checked) {
                    // dispatch(SET_EMAIL_MARKETING_TOGGLE_ERROR(false));
                    formik.setFieldValue('OneTimeMode.IsEnabled', false);
                  }
                  setEnabledDiscount(e.target.checked);
                }}
                name='checkedActive'
                isDisabled={discount.Status === 0}
              />
            </DashboardField>
            {enabledDiscount && (
              <>
                <DashboardField className='dFlex w100'>
                  <DashboardFieldLabel>
                    <FieldLabel
                      className={cn('FieldLabelLg inline-label', {
                        isDisabled: discount.Status === 0
                      })}
                    >
                      Promo Code
                    </FieldLabel>
                  </DashboardFieldLabel>
                  <DashboardFieldInner className='w60 FieldRelative'>
                    <FieldInput
                      className={cn('', {
                        error: formik.errors.CouponCode,
                        isDisabled: discount.Status === 0
                      })}
                      name='CouponCode'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.CouponCode
                          ? formik.values.CouponCode.toUpperCase()
                          : ''
                      }
                      placeholder=''
                    />
                    <ErrorSignWrapper className='ErrorSignWrapper'>
                      {formik.errors?.CouponCode &&
                      formik.touched?.CouponCode ? (
                        <ErrorSign>{formik.errors.CouponCode}</ErrorSign>
                      ) : null}
                    </ErrorSignWrapper>
                  </DashboardFieldInner>
                </DashboardField>
                {allowedServiceFeatures.includes('one_time_coupon') && (
                  <DashboardField className='w100 noWrap'>
                    <SwitchS2E
                      labelPlacement='start'
                      label='Single Use'
                      checked={formik.values.OneTimeMode?.IsEnabled}
                      onChange={formik.handleChange}
                      name='OneTimeMode.IsEnabled'
                      isDisabled={discount.Status === 0}
                    />
                    <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                      <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                        Turning on Single Use will allow your Promo Code to be
                        redeemed only once per customer
                      </div>
                    </ButtonInfo>
                  </DashboardField>
                )}
              </>
            )}
            {allowedServiceFeatures.includes('email_campaigns_active') && (
              <DashboardFieldInner>
                <DashboardField className='w100 noWrap'>
                  <SwitchS2E
                    labelPlacement='start'
                    label='Email Marketing'
                    checked={formik.values.EmailMarketing}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        dispatch(OPEN_EMAIL_MARKETING_TURN_OFF_MODAL(true));
                      } else {
                        formik.setFieldValue('EmailMarketing', true);
                      }
                    }}
                    name='EmailMarketing'
                    isDisabled={discount.Status === 0}
                  />
                  <ButtonInfo className='Outline ButtonInfoDrop marginLeftXsPlus'>
                    <div className='DropInfo DropInfoLeft DropInfoLeftMob'>
                      Turning on Email Marketing will display this discount in
                      your email marketing campaigns.
                    </div>
                  </ButtonInfo>
                  <ErrorSignWrapper className='ErrorSignWrapper noWrap'>
                    {formik.errors?.EmailMarketing &&
                    formik.touched?.EmailMarketing ? (
                      <ErrorSign>{formik.errors.EmailMarketing}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFieldInner>
            )}
            <DashboardField className='w100 noWrap'>
              <SwitchS2E
                labelPlacement='start'
                label='Show on Storefront'
                checked={formik.values.ShowOnStorefront}
                onChange={formik.handleChange}
                name='ShowOnStorefront'
                isDisabled={discount.Status === 0}
              />
            </DashboardField>
          </DashboardFields>
        </DashboardHolder>

        <div className='card-buttons'>
          <ButtonPrimary
            className={cn('ButtonDashboard', {
              disabled: buttonStatus || discount.Status === 0
            })}
            onClick={(e) => {
              e.stopPropagation();
              sendGtmEvent('applyDiscountCreatedFinalButton', {
                PromoStatus: formik.values?.CouponCode?.length
                  ? 'enabled'
                  : 'disabled',
                Duration:
                  !formik.values.StartTime && !formik.values.EndTime
                    ? 'unlimited'
                    : moment(formik.values.EndTime)
                        .add(2, 'day')
                        .diff(moment(formik.values.StartTime), 'days')
              });

              if (
                formik.values.DiscountType === 0 &&
                formik.values.Discount >= formik.values.MinOrder
              ) {
                formik.setFieldError(
                  'MinOrder',
                  'Discount cannot be bigger than min order!'
                );
                return;
              }

              if (!enabledDiscount) {
                formik.setFieldValue('CouponCode', '');
              }

              formik.handleSubmit(e);
            }}
          >
            <span>Apply</span>
          </ButtonPrimary>
        </div>
      </DashboardInner>
      {isEmailMarketingTurnOffModalOpen && (
        <EmailMarketingTurnOffModal
          isOpen={isEmailMarketingTurnOffModalOpen}
          setFieldValue={formik.setFieldValue}
        />
      )}
    </>
  );
};

Settings.propTypes = {
  formik: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  enabledDiscount: PropTypes.bool.isRequired,
  setEnabledDiscount: PropTypes.func.isRequired,
  enabledDate: PropTypes.bool.isRequired,
  setEnabledDate: PropTypes.func.isRequired,
  buttonStatus: PropTypes.bool.isRequired,
  allowedServiceFeatures: PropTypes.array.isRequired
};

export default Settings;
