import styled from 'styled-components';
import IconMegaphoneButton from '../../../assets/icon-megaphone-button.svg';
import IconPlayButton from '../../../assets/icon-play-button.svg';

import {Body1} from '../../../components/typography';
import {DashboardFields} from '../dashboard/styles';

export const VirtualNumberPlay = styled(Body1)`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    display: inline-block;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(${IconPlayButton});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
`;

export const VirtualNumberMegaphone = styled(Body1)`
  display: flex;
  align-items: center;
  cursor: pointer;
  &::before {
    display: inline-block;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(${IconMegaphoneButton});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
`;

export const VirtualNumberFields = styled(DashboardFields)`
  max-width: 600px;
`;

export const YourVirtualNumber = styled.div`
  display: block;
  border: 3px solid #ff2d38;
  border-radius: 5px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: #ff2d38;
  padding: 30px;
  margin-bottom: 40px;
  @media (max-width: 1023px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 15px;
    font-size: 16px;
  }
`;

export const SuccessPhoto = styled.img`
  flex: 0 0 auto;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 0 0 5px;
  max-width: 400px;
  width: 100%;
`;
