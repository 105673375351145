import {createReducer} from 'redux-act';
import {
  CHANGE_AMOUNT_ITEMS_REFUND_ORDER,
  CHANGE_STATUS_CONFIRMATION_MODAL,
  CHANGE_STATUS_CREATE_MODAL,
  SET_DATA_FOR_REFUND,
  CLEAR_REFUND_DATA,
  SET_REFUND_LOADING,
  GET_DATA_FOR_REFUND_SUCCESS,
  CHANGE_QUANTITY_FOR_REFUND,
  CHECK_MODIFIER_FOR_REFUND,
  SELECT_ADJUST_FOR_REFUND,
  SELECT_TIPS_FOR_REFUND
} from 'store/orders/orderRefund/action';
import {
  getPreparedItems,
  groupForRestaurantRefund,
  initDataForRefundRequest,
  restaurantCheckedAllElements,
  unpackItems
} from 'helpers/functionUtils/refundHelper';
import {isEqual} from 'lodash';

const initialState = () => ({
  refundItems: {},
  isCreateModalOpen: false,
  isConfirmationModalOpen: false,
  dataForRefund: {},
  refundsLoading: false,
  orderRefundData: [],
  orderPriceAdjustments: [],
  priceAdjustmentIds: [],
  orderTips: null,
  tipForRefund: false
});

const refundReducer = createReducer(
  {
    [CHANGE_AMOUNT_ITEMS_REFUND_ORDER]: (state, {id, increment}) => {
      const resultObject = state.refundItems[id] || {quantityToRefund: 0};
      if (increment) {
        resultObject.quantityToRefund += 1;
      } else {
        resultObject.quantityToRefund -= 1;
      }

      return {
        ...state,
        refundItems: {...state.refundItems, [id]: resultObject}
      };
    },
    [CHANGE_STATUS_CONFIRMATION_MODAL]: (state, payload) => ({
      ...state,
      isConfirmationModalOpen: payload
    }),
    [CHANGE_STATUS_CREATE_MODAL]: (state, payload) => ({
      ...state,
      isCreateModalOpen: payload
    }),
    [SET_DATA_FOR_REFUND]: (state, payload) => ({
      ...state,
      dataForRefund: payload
    }),
    [CLEAR_REFUND_DATA]: () => ({
      ...initialState()
    }),
    [SET_REFUND_LOADING]: (state, payload) => ({
      ...state,
      refundsLoading: payload
    }),
    [GET_DATA_FOR_REFUND_SUCCESS]: (state, payload) => ({
      ...state,
      orderRefundData: groupForRestaurantRefund(
        initDataForRefundRequest(unpackItems(getPreparedItems(payload?.items)))
      ),
      orderPriceAdjustments: payload?.priceAdjustments || [],
      orderTips: payload?.tipsAmount
    }),
    [CHANGE_QUANTITY_FOR_REFUND]: (state, {path, value}) => {
      const items = [...state.orderRefundData]?.flat();
      const index = items.findIndex((el) => isEqual(el.path, path));
      items[index] = {
        ...items[index],
        selected: value
      };

      const updatedItems = restaurantCheckedAllElements(
        items[index],
        items,
        'selected',
        value
      );
      return {
        ...state,
        orderRefundData: groupForRestaurantRefund(updatedItems)
      };
    },
    [CHECK_MODIFIER_FOR_REFUND]: (state, {path, checked}) => {
      const items = [...state.orderRefundData]?.flat();
      const index = items.findIndex((el) => isEqual(el.path, path));

      items[index] = {
        ...items[index],
        checked
      };

      let updatedItems = restaurantCheckedAllElements(
        items[index],
        items,
        'checked',
        checked
      );

      if (!checked) {
        updatedItems = restaurantCheckedAllElements(
          updatedItems[index],
          updatedItems,
          'selected',
          1
        );
      }

      return {
        ...state,
        orderRefundData: groupForRestaurantRefund(updatedItems)
      };
    },
    [SELECT_ADJUST_FOR_REFUND]: (state, payload) => {
      let result = state.priceAdjustmentIds;
      if (result.includes(payload)) {
        result = result.filter((el) => el !== payload);
      } else {
        result.push(payload);
      }
      return {
        ...state,
        priceAdjustmentIds: result
      };
    },
    [SELECT_TIPS_FOR_REFUND]: (state, payload) => ({
      ...state,
      tipForRefund: payload
    })
  },
  initialState()
);

export default refundReducer;
