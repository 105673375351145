import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Headline from 'components/headline2/HeadlineComponentStyle';
import {FlexContainer, FlexItem} from 'components/layout';
import SearchField from 'components/fields/SearchField';
import {ButtonOutline} from 'components/buttons';
import {
  INSIGHTS_CONTROL_CHANGE_MODULE_STATUS,
  INSIGHTS_CONTROL_GET_LOCATION_INFO,
  INSIGHTS_CONTROL_GET_LOCATIONS
} from 'store/insightsControl/action';
import {DashboardContent} from 'components/DashboardContent/styles';
import Loading from 'components/shared/Utils/Loading';
import {IconArrowDown} from 'assets/IconArrowDown';
import {
  InsightsControlEl,
  InsightsControlModule
} from 'routes/App/Managers/InsightsControl/styles';
import {
  DashboardFieldLabel,
  DashboardTitle,
  FieldLabel
} from 'routes/App/dashboard/styles';
import {INSIGHTS_GROWTH_FEATURES_INFO} from 'helpers/constants/Insights';
import DialogField from 'components/DialogField/styles';
import SwitchS2E from 'components/Switch';

const InsightsControl = () => {
  const dispatch = useDispatch();
  const {locations, loadingTable} = useSelector(
    (state) => state.insightsControlReducer
  );

  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    dispatch(INSIGHTS_CONTROL_GET_LOCATIONS({searchField}));
  }, []);

  const onSearchClick = () => {
    dispatch(
      INSIGHTS_CONTROL_GET_LOCATIONS({
        search: encodeURIComponent(searchField)
      })
    );
  };

  const cateringStatuses = [
    {name: 'Enabled', value: 'Enabled'},
    {name: 'Disabled', value: 'Disabled'},
    {name: 'Not Available', value: 'NotAvailable'}
  ];

  const handleChange = (isExpanded, location) => {
    if (
      isExpanded &&
      (!location.locationInfo || !location.locationInfo.length)
    ) {
      dispatch(
        INSIGHTS_CONTROL_GET_LOCATION_INFO({
          locationId: location.id,
          locationName: location.name
        })
      );
    }
  };

  return (
    <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
      <Headline className='marginBottomXs'>Insights</Headline>
      <FlexContainer>
        <FlexItem flex='1 1 auto' className='marginRightXs'>
          <SearchField
            className='marginBottomXs'
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && onSearchClick()}
          />
        </FlexItem>
        <FlexItem>
          <ButtonOutline
            className={cn('ButtonMedium', {disabled: !searchField})}
            onClick={onSearchClick}
          >
            Search
          </ButtonOutline>
        </FlexItem>
      </FlexContainer>
      {loadingTable ? (
        <Loading />
      ) : (
        <>
          {locations?.map((location, index) => (
            <InsightsControlEl
              onChange={(e, isExpanded) => handleChange(isExpanded, location)}
            >
              <AccordionSummary
                expandIcon={<IconArrowDown />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                {location.name}
              </AccordionSummary>
              <AccordionDetails>
                {(!location.locationInfo || !location.locationInfo.length) && (
                  <Loading className='LoadingWrapperCenter' />
                )}
                {location.locationInfo?.map((item) => (
                  <InsightsControlModule
                    className={cn('', {
                      disabled:
                        INSIGHTS_GROWTH_FEATURES_INFO[item.title]
                          ?.disableInsightControlModule
                    })}
                  >
                    <DashboardTitle
                      className={cn('WithLine mBottom20', {
                        mBottom28:
                          item.title === 'catering' &&
                          item.status === 'NotAvailable'
                      })}
                    >
                      {INSIGHTS_GROWTH_FEATURES_INFO[item.title]?.name}
                    </DashboardTitle>
                    <DashboardFieldLabel className='widthAuto'>
                      {item.title === 'catering' &&
                      item.status === 'NotAvailable' ? (
                        <span style={{fontSize: '14px'}}>Not Available</span>
                      ) : (
                        <>
                          <FieldLabel className='FW400 marginBtNull marginR8'>
                            Disabled
                          </FieldLabel>
                          <SwitchS2E
                            onChange={(e) => {
                              dispatch(
                                INSIGHTS_CONTROL_CHANGE_MODULE_STATUS({
                                  title: item.title,
                                  status: e.target.checked
                                    ? 'Enabled'
                                    : 'Disabled',
                                  index,
                                  locationId: location.id
                                })
                              );
                            }}
                            name={item.field}
                            checked={item.status === 'Enabled'}
                          />
                          <FieldLabel className='FW400 marginBtNull'>
                            Enabled
                          </FieldLabel>
                        </>
                      )}
                    </DashboardFieldLabel>
                  </InsightsControlModule>
                ))}
              </AccordionDetails>
            </InsightsControlEl>
          ))}
        </>
      )}
    </DashboardContent>
  );
};

export default InsightsControl;
