import styled from 'styled-components';

const AnimatePointsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;

  span {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }

  &.White {
    span {
      color: #fff;
    }
  }
  &.Black {
    span {
      color: #232d3a;
    }
  }

  .PointOne {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0s;
  }

  .PointTwo {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
  }

  .PointThree {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &.CancellationRefund {
    width: 20%;
  }
`;

export default AnimatePointsContainer;
