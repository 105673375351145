import {createReducer} from 'redux-act';
import {CLEAR_BAD_ORDER, GET_BAD_ORDER_SUCCESS} from './action';

const initialState = () => ({
  order: {}
});

const bookADriverOpenDetails = createReducer(
  {
    [GET_BAD_ORDER_SUCCESS]: (state, payload) => ({
      ...state,
      order: payload
    }),
    [CLEAR_BAD_ORDER]: (state) => ({
      ...state,
      order: {}
    })
  },
  initialState()
);

export default bookADriverOpenDetails;
