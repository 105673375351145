import React from 'react';
import T from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import CheckboxS2E from '../../../components/Checkbox';
import Subtitle4 from '../../../components/typography/Subtitle4';
import {Body1} from '../../../components/typography';
import {VirtualNumberFields} from './styles';
import {
  DashboardActionCenter,
  DashboardField,
  FieldInput
} from '../dashboard/styles';
import {ButtonInfo, ButtonPrimary} from '../../../components/buttons';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../components/errorSign/errorSign';
import ButtonOutline from '../../../components/buttons/ButtonOutline/styles';

const AddSlogan = ({
  formik,
  isEdit,
  backAction,
  isActive,
  backFromEditActiveVNSlogan
}) => {
  const handleChange = (e) => {
    if (e.target.name) {
      if (formik.values.languages.includes(e.target.name)) {
        formik.setFieldValue(
          'languages',
          formik.values.languages.filter((el) => el !== e.target.name)
        );
      } else {
        formik.setFieldValue('languages', [
          ...formik.values.languages,
          e.target.name
        ]);
      }
    }
  };

  return (
    <>
      <Subtitle4 className='marginBottomSm'>
        {isEdit ? 'Edit location name' : 'Excellent!'}
      </Subtitle4>
      <Body1 className='marginBottom2Xs'>
        The provided location name will be used when the customer is calling
      </Body1>
      <VirtualNumberFields className='AlignCenter'>
        <DashboardField className='wAuto'>
          <Body1>Location name</Body1>
        </DashboardField>
        <DashboardField className='wAutoSpace'>
          <FieldInput
            name='locationName'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.locationName}
            className={
              formik.errors.locationName &&
              formik.touched.locationName &&
              'error'
            }
            placeholder='Location name'
          />
          <ErrorSignWrapper>
            <ErrorSign>
              {formik.touched.locationName && formik.errors.locationName}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
      </VirtualNumberFields>
      <br />
      {isEdit && (
        <Subtitle4 className='marginBottomSm'>Edit your slogan</Subtitle4>
      )}

      <Body1 className='marginBottom2Xs'>
        Customize your answering message with a slogan for a bit of flavor
      </Body1>
      <VirtualNumberFields>
        <DashboardField className='w100 noWrap AlignStartMob'>
          <ButtonInfo className='Outline ButtonInfoDrop WithoutPointer marginRightXs' />
          <Body1>
            For example: Dominos Pizza slogan is ‘the Pizza Delivery Experts’
          </Body1>
        </DashboardField>
      </VirtualNumberFields>
      <VirtualNumberFields className='AlignCenter'>
        <DashboardField className='wAuto'>
          <Body1>What is your slogan?</Body1>
        </DashboardField>
        <DashboardField className='wAutoSpace'>
          <FieldInput
            name='slogan'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.slogan}
            className={formik.errors.slogan && formik.touched.slogan && 'error'}
            placeholder='Fill it out here. 100 characters, max'
          />
          <ErrorSignWrapper>
            <ErrorSign>
              {formik.touched.slogan && formik.errors.slogan}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
      </VirtualNumberFields>
      <br />
      <Subtitle4 className='marginBottomSm'>Language</Subtitle4>
      <Body1 className='marginBottom2Xs'>
        Select your virtual answering language
      </Body1>
      <VirtualNumberFields className='AlignCenter'>
        <DashboardField className='w100'>
          <FormControl>
            <CheckboxS2E
              onChange={(e) => {
                handleChange(e);
              }}
              name='eng'
              checked={!!formik.values?.languages?.includes('eng')}
              label={
                formik.values?.languages?.length > 1 &&
                formik.values?.languages?.includes('eng')
                  ? 'English (default)'
                  : 'English'
              }
            />
          </FormControl>
        </DashboardField>
        <DashboardField className='w100 paddingTZero'>
          <FormControl>
            <CheckboxS2E
              onChange={(e) => {
                handleChange(e);
              }}
              name='spa'
              checked={!!formik.values?.languages?.includes('spa')}
              label='Spanish'
            />
          </FormControl>
        </DashboardField>
        <DashboardField className='w100 paddingTZero'>
          <ErrorSignWrapper leftPosition='10px' topPosition='-12px'>
            <ErrorSign>
              {formik.touched.languages && formik.errors.languages}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
      </VirtualNumberFields>
      <DashboardActionCenter>
        {isEdit && !isActive && (
          <ButtonOutline onClick={backAction}>
            <span>Back</span>
          </ButtonOutline>
        )}
        {isEdit && isActive && (
          <ButtonOutline onClick={backFromEditActiveVNSlogan}>
            <span>Back</span>
          </ButtonOutline>
        )}
        <ButtonPrimary
          type='submit'
          onClick={formik.handleSubmit}
          data-gtm={isEdit && 'createVirtualAnsweringButton'}
        >
          <span data-gtm={isEdit && 'createVirtualAnsweringButton'}>Next</span>
        </ButtonPrimary>
      </DashboardActionCenter>
    </>
  );
};

AddSlogan.propTypes = {
  formik: T.object.isRequired,
  isEdit: T.bool.isRequired,
  backAction: T.func.isRequired,
  isActive: T.bool.isRequired,
  backFromEditActiveVNSlogan: T.func.isRequired
};

export default AddSlogan;
