import {toast} from 'react-toastify';
import {put, takeEvery} from 'redux-saga/effects';
import {request} from '../../helpers/services/utils/request';
import {GET, PUT} from '../../helpers/services/constants/API_CONSTANTS';
import {
  GET_ADMIN_LOCATIONS,
  GET_ADMIN_MERCHANTS,
  GET_ADMIN_USERS,
  RESET_PASSWORD_ADMIN_API
} from '../../helpers/services/constants/API_ENDPOINTS';
import {buildUrl} from '../../helpers/functionUtils/AdminBuildUrl';
import {
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS,
  GET_USERS,
  GET_USERS_SUCCESS,
  RESET_PASSWORD_ADMIN,
  RESET_PASSWORD_ADMIN_SUCCESS
} from './actions';

function* getAdminUsers({payload = {}}) {
  try {
    const data = yield request(GET, buildUrl(GET_ADMIN_USERS(), payload));

    if (data) {
      yield put(GET_USERS_SUCCESS(data.data));
    }
  } catch (e) {
    toast.error(`Error occurred - please try again [${e}]`);
  }
}

function* getAdminMerchants() {
  try {
    const data = yield request(GET, GET_ADMIN_MERCHANTS());

    if (data?.data) {
      yield put(GET_MERCHANTS_SUCCESS(data.data));
    }
  } catch (e) {
    toast.error(`Error occurred - please try again [${e}]`);
  }
}

function* getAdminLocations({payload}) {
  try {
    const data = yield request(GET, GET_ADMIN_LOCATIONS(payload));

    if (data?.data) {
      yield put(GET_LOCATIONS_SUCCESS(data.data));
    }
  } catch (e) {
    toast.error(`Error occurred - please try again [${e}]`);
  }
}

function* resetPasswordAdmin({payload: {_id, password}}) {
  try {
    const {status} = yield request(PUT, RESET_PASSWORD_ADMIN_API(_id), {
      data: {password}
    });

    if (status === 204) {
      yield put(RESET_PASSWORD_ADMIN_SUCCESS());
      toast.success('Password has been reset successfully');
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* adminUsersSaga() {
  yield takeEvery(GET_USERS, getAdminUsers);
  yield takeEvery(GET_MERCHANTS, getAdminMerchants);
  yield takeEvery(GET_LOCATIONS, getAdminLocations);
  yield takeEvery(RESET_PASSWORD_ADMIN, resetPasswordAdmin);
}

export default adminUsersSaga;
