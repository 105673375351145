/* eslint-disable */
import {takeEvery, put, select} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  GET_LOCATION_SETTINGS_DATA,
  GET_LOCATION_SETTINGS_DATA_BY_ID,
  GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS,
  GET_LOCATION_SETTINGS_DATA_SUCCESS,
  POST_LOCATION_SETTINGS_DATA,
  POST_LOCATION_SETTINGS_DATA_SUCCESS,
  UPDATE_LOCATION_SETTINGS_DATA,
  UPDATE_LOCATION_SETTINGS_DATA_SUCCESS,
  GET_CALLING_PHONE_SUCCESS,
  GET_CALLING_PHONE,
  PUBLISH_LOCATION,
  UPDATE_LOCATION_ACCOUNT_TEMPLATE,
  CREATE_STORE_HOLIDAYS_REQUEST,
  CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS,
  SET_STATUS_BUTTON
} from './action';
import {
  getLocationSettingsDataApi,
  getLocationSettingsDataByIdApi,
  postLocationSettingsDataApi,
  updateLocationSettingsDataApi,
  getCallingPhoneApi,
  publishLocationAPi,
  updateTemplateLocationApi,
  createStoreHolidaysApi
} from '../../helpers/services/api/locationSettings';
import {GET_LOCATION_DATA} from '../global/user/action';
import {
  cropOpeningHours,
  daysMomentPickerParser,
  daysPickerArrayParser
} from '../../routes/App/settings/helpers';
import {getUsersLocations} from '../global/user/saga';
import {RUN_GET_PUBLISH_STATUS_JOB} from '../global/globalNotifications/action';
import {TASK_TYPE_SETTINGS_DATA_UPDATE} from '../global/globalNotifications/constants';

const locationId = ({user}) => user.locationId;
const configName = (state) => state.user.userData?.ConfigName;
const menuConfigId = (state) => state.user.activeLocation?.MenuConfigId;
const locationTaxPercents = (state) =>
  state.locationSettingsReducer.data.TaxPercents;
const locationAlcoholTax = (state) =>
  state.locationSettingsReducer.data.AlcoholTax;

export function* getLocationData({payload}) {
  try {
    const {data} = yield getLocationSettingsDataApi(payload);

    if (data) {
      yield put(GET_LOCATION_SETTINGS_DATA_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getLocationDataById() {
  try {
    const id = yield select(locationId);
    const {data} = yield getLocationSettingsDataByIdApi(id);

    data.WorkingDays = daysPickerArrayParser(
      daysMomentPickerParser(cropOpeningHours(data.OpeningHours))
    );

    if (data) {
      yield put(GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* updateLocationData({
  payload: {payload, successCallback, errorCallback}
}) {
  try {
    const id = yield select(locationId);

    const data = yield updateLocationSettingsDataApi(id, payload);

    if (data || data.status === 204) {
      yield put(UPDATE_LOCATION_SETTINGS_DATA_SUCCESS(data.data));
      yield put(GET_LOCATION_DATA());
      yield put(GET_LOCATION_SETTINGS_DATA_BY_ID({noLoader: true}));
      if (successCallback) successCallback();
    }
  } catch (error) {
    errorCallback();
    yield put(SET_STATUS_BUTTON(false));
    console.log('SAGA ERROR', error);
  }
}

export function* postLocation({payload: {payload, successCallback}}) {
  try {
    const {data} = yield postLocationSettingsDataApi(payload);
    if (data || data === '') {
      yield put(POST_LOCATION_SETTINGS_DATA_SUCCESS(data));
      yield put(GET_LOCATION_DATA());
      if (successCallback) successCallback();
    }
  } catch (error) {
    if (successCallback) successCallback();
    console.log('SAGA ERROR', error);
  }
}

export function* getCallingPhone({payload}) {
  try {
    const {data} = yield getCallingPhoneApi(payload.countryCode);

    if (data || data === '') {
      yield put(GET_CALLING_PHONE_SUCCESS(data));
      yield put(GET_LOCATION_DATA());
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

export function* publishLocation({payload}) {
  const {preCallback, postCallBack, errorCallback = () => {}} = payload;
  try {
    preCallback();
    yield put(
      RUN_GET_PUBLISH_STATUS_JOB({SyncType: TASK_TYPE_SETTINGS_DATA_UPDATE})
    );

    const menuId = yield select(menuConfigId);

    yield publishLocationAPi(menuId);
  } catch (error) {
    errorCallback();
    console.log('SAGA ERROR', error);
  }
}

export function* updateTemplateLocation({payload}) {
  try {
    const id = yield select(locationId);

    yield updateTemplateLocationApi(id, payload);
    yield getUsersLocations();
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* createStoreHolidays({payload}) {
  try {
    const id = yield select(locationId);

    const {data} = yield createStoreHolidaysApi(id, payload);
    if (data) {
      data.WorkingDays = daysPickerArrayParser(
        daysMomentPickerParser(cropOpeningHours(data.OpeningHours))
      );
      yield put(CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* locationSettingsSaga() {
  yield takeEvery(GET_LOCATION_SETTINGS_DATA, getLocationData);
  yield takeEvery(UPDATE_LOCATION_SETTINGS_DATA, updateLocationData);
  yield takeEvery(POST_LOCATION_SETTINGS_DATA, postLocation);
  yield takeEvery(GET_CALLING_PHONE, getCallingPhone);
  yield takeEvery(GET_LOCATION_SETTINGS_DATA_BY_ID, getLocationDataById);
  yield takeEvery(PUBLISH_LOCATION, publishLocation);
  yield takeEvery(UPDATE_LOCATION_ACCOUNT_TEMPLATE, updateTemplateLocation);
  yield takeEvery(CREATE_STORE_HOLIDAYS_REQUEST, createStoreHolidays);
}

export default locationSettingsSaga;
