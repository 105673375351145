import {createAction} from 'redux-act';

export const GET_LOCATION_SETTINGS_DATA_BY_ID = createAction(
  'locationSettings/GET_LOCATION_SETTINGS_DATA_BY_ID'
);

export const GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS = createAction(
  'locationSettings/GET_LOCATION_SETTINGS_DATA_BY_ID_SUCCESS'
);

export const GET_LOCATION_SETTINGS_DATA = createAction(
  'locationSettings/GET_LOCATION_SETTINGS_DATA'
);
export const GET_LOCATION_SETTINGS_DATA_SUCCESS = createAction(
  'locationSettings/GET_LOCATION_SETTINGS_DATA_SUCCESS'
);

export const UPDATE_LOCATION_SETTINGS_DATA = createAction(
  'locationSettings/UPDATE_LOCATION_SETTINGS_DATA'
);

export const UPDATE_LOCATION_SETTINGS_DATA_SUCCESS = createAction(
  'locationSettings/UPDATE_LOCATION_SETTINGS_DATA_SUCCESS'
);

export const POST_LOCATION_SETTINGS_DATA = createAction(
  'locationSettings/POST_LOCATION_SETTINGS_DATA'
);

export const POST_LOCATION_SETTINGS_DATA_SUCCESS = createAction(
  'locationSettings/POST_LOCATION_SETTINGS_DATA_SUCCESS'
);

// export const OPEN_LEAVE_MODAL = createAction(
//   'locationSettings/OPEN_LEAVE_MODAL'
// );

export const OPEN_LEAVE_MESSAGE = createAction(
  'locationSettings/OPEN_LEAVE_MESSAGE'
);

export const OPEN_CONFIRMATION_MODAL = createAction(
  'locationSettings/OPEN_CONFIRMATION_MODAL'
);

export const OPEN_DISABLE_MODAL = createAction(
  'locationSettings/OPEN_DISABLE_MODAL'
);

export const GET_CALLING_PHONE = createAction(
  'locationSettings/GET_CALLING_PHONE'
);

export const GET_CALLING_PHONE_SUCCESS = createAction(
  'locationSettings/GET_CALLING_PHONE_SUCCESS'
);

export const SET_PUBLISH_BUTTON_STATE = createAction(
  'locationSettings/SET_PUBLISH_BUTTON_STATE'
);

export const PUBLISH_LOCATION = createAction(
  'locationSettings/PUBLISH_LOCATION'
);

export const PUBLISH_LOCATION_SUCCESS = createAction(
  'locationSettings/PUBLISH_LOCATION_SUCCESS'
);

export const UPDATE_LOCATION_ACCOUNT_TEMPLATE = createAction(
  'locationSettings/UPDATE_LOCATION_ACCOUNT_TEMPLATE'
);

export const OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL = createAction(
  'locationSettings/OPEN_CHANGE_ADDRESS_CONFIRMATION_MODAL'
);

export const CREATE_STORE_HOLIDAYS_REQUEST = createAction(
  'locationSettings/CREATE_STORE_HOLIDAYS_REQUEST'
);
export const CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS = createAction(
  'locationSettings/CREATE_STORE_HOLIDAYS_REQUEST_SUCCESS'
);

export const SET_STORE_HOLIDAYS = createAction(
  'locationSettings/SET_STORE_HOLIDAYS'
);

export const SET_STATUS_BUTTON = createAction(
  'locationSettings/SET_STATUS_BUTTON'
);
