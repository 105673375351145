import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  Email: Yup.string().required('Email field is required!'),
  Password: Yup.string().required('Password field is required!')
  // Do NOT check passwords length at login, to support old passwords
  // .max(50, 'Password is too long max 50 symbols')
  // .min(6, 'Password is too short min 6 symbols')
});

export const ResetPasswordSchema = Yup.object().shape({
  Password: Yup.string()
    .required('Password field is required!')
    .max(50, 'Password is too long max 50 symbols')
    .min(6, 'Password is too short min 6 symbols'),
  PasswordConfirm: Yup.string()
    .oneOf([Yup.ref('Password'), null], 'Passwords must match')
    .required('Password confirm field is required')
});

export const ForgotPasswordSchema = Yup.object().shape({
  Email: Yup.string()
    .required('Email field is required!')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Email field is not valid'
    )
});
