import React from 'react';

const IconExportReport = () => {
  return (
    <svg
      width='19'
      // height='16'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* <path */}
      {/*  d='M14.8657 3.55566H12.0266V5.24029H14.8657C15.71 5.24029 16.365 5.67155 16.365 6.04273V13.5129C16.365 13.8841 15.71 14.3153 14.8657 14.3153H4.13413C3.28985 14.3153 2.63482 13.8841 2.63482 13.5129V6.04301C2.63482 5.67183 3.28985 5.24057 4.13413 5.24057H6.97272V3.55595H4.13413C2.34871 3.55595 0.950195 4.64842 0.950195 6.04301V13.5132C0.950195 14.908 2.34871 16.0002 4.13413 16.0002H14.866C16.6511 16.0002 18.0499 14.9078 18.0499 13.5132V6.04301C18.0497 4.64814 16.6511 3.55566 14.8657 3.55566Z' */}
      {/*  fill='white' */}
      {/* /> */}
      {/* <path */}
      {/*  d='M12.0488 8.11065C11.8331 8.11065 11.6178 8.19292 11.4533 8.35745L10.3987 9.41202L10.3987 5.75415L10.3987 4.06953L10.3987 0.842355C10.3987 0.377119 10.0216 4.36181e-05 9.55637 4.35775e-05C9.09113 4.35368e-05 8.71406 0.377119 8.71406 0.842354L8.71406 4.06953L8.71406 5.75415L8.71406 9.46902L7.60249 8.35745C7.43796 8.19292 7.22233 8.11065 7.00698 8.11065C6.79162 8.11065 6.57599 8.19292 6.41146 8.35745C6.0824 8.68623 6.0824 9.21969 6.41146 9.54848L8.92632 12.0633C9.09085 12.2279 9.3062 12.3096 9.52155 12.3096C9.5238 12.3096 9.52576 12.3096 9.52801 12.3096C9.53026 12.3096 9.53222 12.3096 9.53447 12.3096C9.74982 12.3096 9.96517 12.2279 10.1297 12.0633L12.6446 9.54848C12.9736 9.21969 12.9736 8.68623 12.6446 8.35745C12.4797 8.19292 12.2644 8.11065 12.0488 8.11065Z' */}
      {/*  fill='white' */}
      {/* /> */}
      <path
        d='M 14.8657 5.5557 H 12.0266 V 7.2403 H 14.8657 C 15.71 7.2403 16.365 7.6715 16.365 8.0427 V 15.5129 C 16.365 15.8841 15.71 16.3153 14.8657 16.3153 H 4.1341 C 3.2898 16.3153 2.6348 15.8841 2.6348 15.5129 V 8.043 C 2.6348 7.6718 3.2898 7.2406 4.1341 7.2406 H 6.9727 V 5.556 H 4.1341 C 2.3487 5.556 0.9502 6.6484 0.9502 8.043 V 15.5132 C 0.9502 16.908 2.3487 18.0002 4.1341 18.0002 H 14.866 C 16.6511 18.0002 18.0499 16.9078 18.0499 15.5132 V 8.043 C 18.0497 6.6481 16.6511 5.5557 14.8657 5.5557 Z'
        fill='white'
      />
      <path
        d='M 6.9632 4.1126 c 0.2112 0 0.4221 -0.0806 0.5833 -0.2417 l 1.0329 -1.0329 v 3.5827 v 1.65 v 3.1609 c 0 0.4557 0.3693 0.825 0.825 0.825 s 0.825 -0.3693 0.825 -0.825 V 8.0707 v -1.65 V 2.7822 l 1.0887 1.0887 c 0.1612 0.1612 0.3724 0.2417 0.5833 0.2417 s 0.4221 -0.0806 0.5833 -0.2417 c 0.3223 -0.322 0.3223 -0.8445 0 -1.1666 l -2.4632 -2.4632 C 9.8603 0.08 9.6494 0 9.4384 0 c -0.0022 0 -0.0041 0 -0.0063 0 s -0.0041 0 -0.0063 0 c -0.2109 0 -0.4219 0.08 -0.583 0.2412 l -2.4632 2.4632 c -0.3223 0.322 -0.3223 0.8445 0 1.1666 C 6.5411 4.0321 6.752 4.1126 6.9632 4.1126 z'
        fill='white'
      />
    </svg>
  );
};

export default IconExportReport;
