import {createReducer} from 'redux-act';
import {
  CREATE_REPLY,
  CREATE_REPLY_SUCCESS,
  GET_REPLIES_SUCCESS,
  GET_REVIEW,
  GET_REVIEW_SUCCESS,
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS
} from './action';

const initialState = () => ({
  review: {},
  reviewLoading: true,
  reviews: [],
  pagination: {
    pages: 0,
    page: 0,
    limit: 10,
    total: 0,
    sort: 'asc',
    sortBy: 'EndTime'
  },
  reviewsLoading: false,
  init: false
});

const reviewsReducer = createReducer(
  {
    [GET_REVIEW]: (state) => ({
      ...state,
      reviewLoading: true
    }),
    [GET_REVIEW_SUCCESS]: (state, payload) => {
      return {
        ...state,
        review: payload
        // loading: false,
      };
    },
    [GET_REPLIES_SUCCESS]: (state, payload) => ({
      ...state,
      review: {...state.review, replies: payload},
      reviewLoading: false
    }),
    [CREATE_REPLY]: (state) => {
      return {
        ...state,
        reviewLoading: false
      };
    },
    [CREATE_REPLY_SUCCESS]: (state, payload) => ({
      ...state,
      review: {...state.review, replies: [...state.review.replies, payload]},
      reviewLoading: false
    }),
    [GET_REVIEWS]: (state) => ({
      ...state,
      reviewsLoading: true
    }),
    [GET_REVIEWS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        reviews: payload.docs,
        reviewsLoading: false,
        init: true,
        pagination: {
          pages: payload.pages,
          page: payload.page,
          limit: payload.limit,
          total: payload.total,
          sort: payload.sort,
          sortBy: payload.sortBy
        }
      };
    }
  },

  initialState()
);

export default reviewsReducer;
