import React from 'react';

const IconSidebarEmailMarketing = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.07144 7.58105L7.89891 8.75755C7.42343 9.2347 6.58682 9.24498 6.10106 8.75755L4.92848 7.58105L0.717102 11.8061C0.873864 11.8786 1.0467 11.9218 1.23045 11.9218H12.7695C12.9533 11.9218 13.1261 11.8786 13.2828 11.8061L9.07144 7.58105Z'
      fill='#929CB9'
    />
    <path
      d='M12.7695 2.07812H1.23046C1.04671 2.07812 0.87387 2.12138 0.717163 2.19387L5.21732 6.70895C5.21762 6.70925 5.21797 6.70931 5.21827 6.70961C5.21857 6.70991 5.21863 6.71032 5.21863 6.71032L6.68187 8.17841C6.8373 8.33383 7.16274 8.33383 7.31816 8.17841L8.78111 6.71057C8.78111 6.71057 8.78146 6.70991 8.78176 6.70961C8.78176 6.70961 8.78242 6.70925 8.78272 6.70895L13.2828 2.19384C13.1261 2.12133 12.9533 2.07812 12.7695 2.07812Z'
      fill='#929CB9'
    />
    <path
      d='M0.130867 2.76752C0.0497656 2.93152 0 3.11358 0 3.3086V10.6914C0 10.8864 0.0497109 11.0685 0.13084 11.2325L4.34952 7.00014L0.130867 2.76752Z'
      fill='#929CB9'
    />
    <path
      d='M13.8691 2.76746L9.65051 7.00013L13.8691 11.2325C13.9502 11.0685 14 10.8865 14 10.6914V3.30859C14 3.11352 13.9502 2.93146 13.8691 2.76746Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarEmailMarketing;
