import React from 'react';
import SvgButtonIcn from '../components/misc/SvgButtonIcon';

export const IconPrinter = () => (
  <SvgButtonIcn /* TODO: don't do like this, this should be moved on a more specific level */
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    data-gtm='printButton'
  >
    <path
      data-gtm='printButton'
      d='M14.625 1C14.625 0.447715 14.1773 0 13.625 0H4.375C3.82272 0 3.375 0.447715 3.375 1V4.5H14.625V1Z'
      fill='#172751'
    />
    <path
      data-gtm='printButton'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 7.875C0 6.63236 1.00736 5.625 2.25 5.625H15.75C16.9926 5.625 18 6.63236 18 7.875V11.5C18 12.6046 17.1046 13.5 16 13.5H15.625C15.0727 13.5 14.625 13.9477 14.625 14.5V17C14.625 17.5523 14.1773 18 13.625 18H4.375C3.82272 18 3.375 17.5523 3.375 17V14.5C3.375 13.9477 2.92728 13.5 2.375 13.5H2C0.895431 13.5 0 12.6046 0 11.5V7.875ZM12.375 11.25H5.625V15.75H12.375V11.25ZM16.875 7.875C16.875 8.49632 16.3713 9 15.75 9C15.1287 9 14.625 8.49632 14.625 7.875C14.625 7.25368 15.1287 6.75 15.75 6.75C16.3713 6.75 16.875 7.25368 16.875 7.875Z'
      fill='#172751'
    />
  </SvgButtonIcn>
);

export default IconPrinter;
