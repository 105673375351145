import moment from 'moment';
import {BOARD_COLOR_PRIORITY} from 'helpers/constants/adminRealTImeBoard';

export const checkIsFutureOrderRTB = (pickup_time) => {
  const currentDateUTC = moment().utc();
  return moment(pickup_time).diff(currentDateUTC, 'minutes') > 60;
};

export const findLastOfColorAndUpdate = (orders) => {
  const updatedOrders = orders.map((el) => {
    return {...el, lastOfType: false};
  });
  const updatedOrdersReversed = updatedOrders.reverse();

  Object.entries(BOARD_COLOR_PRIORITY).forEach(([key, value]) => {
    const lastOfTypeIndex = updatedOrdersReversed.findIndex(
      (el) => el.colorPriority === value
    );
    if (lastOfTypeIndex >= 0) {
      updatedOrdersReversed[lastOfTypeIndex].lastOfType = true;
    }
  });

  return updatedOrdersReversed.reverse();
};

export const formatToLocalTime = (date, timezone = 0) => {
  const resultDate =
    date?.includes('Z') || date?.includes('+00:00') ? date : `${date}Z`;
  return moment(resultDate)
    .utc()
    .utcOffset(Number(timezone) || 0);
};

export const getMinutesDiffUTC = (date) =>
  moment(date).utc(true).diff(moment().utc(), 'minutes');

export const dateToSortAsc = (date) => {
  const maxDateTimestamp = moment('2280-01-01 00:00Z').unix();

  if (!date) {
    return maxDateTimestamp;
  }

  const sortingDateTimestamp = moment(date).utc().unix();

  return maxDateTimestamp - sortingDateTimestamp;
};
