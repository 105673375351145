import styled from 'styled-components';
import IconEta from '../../../../assets/icon-eta-clock.svg';

export const BillSection = styled.div`
  //??? 80mm
  max-width: 300px;
  min-width: 300px;
  @media (max-width: 700px) {
    max-width: 270px;
    min-width: 270px;
  }
  @media (max-width: 599px) {
    max-width: 300px;
    min-width: 300px;
  }
  @media (max-width: 400px) {
    min-width: 100%;
  }
`;

export const BillDottedLine = styled.hr`
  border: none;
  border-top: 1px dashed #929cb9;
  opacity: 0.7;
  color: #fff;
  background-color: #fff;
  height: 1px;

  &.withoutOpacity {
    border-top: 1.5px dashed #929cb9;
    opacity: 1;
  }
`;

export const OrderRSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 767px) {
    padding: 30px 0 0 20px;
  }
`;
export const OrderRTop = styled.div`
  flex: 0 0 auto;
`;
export const OrderRBottom = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  textarea {
    flex: 1 1 auto;
    height: inherit;
  }
`;
export const EtaClock = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #fca600;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: -18px;
    content: '';
    width: 15px;
    height: 15px;
    background-image: url('${IconEta}');
    background-repeat: no-repeat;
    background-size: contain;
  }
`;
export const OrderDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ButtonDriver {
    flex: 0 0 auto;
  }
  @media (max-width: 874px) {
    align-items: flex-start;
  }

  .OrderDetailsActions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -8px 8px -8px -8px;
    @media (max-width: 470px) {
      margin: -4px;
      flex-wrap: wrap;
    }

    button {
      margin: 8px;
      @media (max-width: 470px) {
        margin: 4px;
        flex: calc(100% - 8px);
        max-width: calc(100% - 8px);
      }
    }
  }

  @media (max-width: 650px) {
    display: block;

    & > * {
      &:nth-child(2) {
        margin-top: 15px;
        @media (max-width: 470px) {
          width: 100%;
          justify-content: center;
          margin-top: 8px;
        }
      }
    }
  }
`;
