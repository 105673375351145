import React from 'react';
import {useSelector} from 'react-redux';
import SatisfactionContainer from './SatisfactionContainer';
import {DashboardInners} from '../../../../../components/DashboardInners/styles';
import StatisticsPopularAnswer from './StatisticsPopularAnswer';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import normalizePercentageByDecimal from '../../../../../helpers/functionUtils/normalizePercentageByDecimal';

const TabAll = () => {
  const {
    StarsTotalCount,
    OneStarsCount,
    AverageSatisfactionRate,
    TwoStarsCount,
    ThreeStarsCount,
    FourStarsCount,
    FiveStarsCount,
    PopularSurveyAnswers
  } = useSelector((state) => state.feedbackReducer.data);

  const calculatePercentage = (field1, field2) => {
    const percentage = 100 / (field1 / field2);
    return Number.isNaN(percentage) ? 0 : percentage;
  };

  const calculateTotal = (popularSurveyAnswers) => {
    let sum = 0;
    popularSurveyAnswers.forEach((item) => {
      sum += item.Count;
    });
    return sum;
  };

  const [
    percentOneStar,
    percentTwoStar,
    percentThreeStar,
    percentFourStar,
    percentFiveStar
  ] = normalizePercentageByDecimal([
    calculatePercentage(StarsTotalCount, OneStarsCount),
    calculatePercentage(StarsTotalCount, TwoStarsCount),
    calculatePercentage(StarsTotalCount, ThreeStarsCount),
    calculatePercentage(StarsTotalCount, FourStarsCount),
    calculatePercentage(StarsTotalCount, FiveStarsCount)
  ]);

  return (
    <>
      <DashboardInners className='SmallLaptopGrid'>
        <DashboardInner className='w50'>
          <SatisfactionContainer
            title='Overall satisfaction grade'
            description='Average satisfaction rate for both delivery and pickup orders'
            ratingSatisfaction={AverageSatisfactionRate?.toFixed(1) || 0}
            percentOneStar={percentOneStar}
            responseOneStar={OneStarsCount}
            percentTwoStar={percentTwoStar}
            responseTwoStar={TwoStarsCount}
            percentThreeStar={percentThreeStar}
            responseThreeStar={ThreeStarsCount}
            percentFourStar={percentFourStar}
            responseFourStar={FourStarsCount}
            percentFiveStar={percentFiveStar}
            responseFiveStar={FiveStarsCount}
          />
        </DashboardInner>
        <DashboardInner className='w50 PopularAnswerInner'>
          <StatisticsPopularAnswer
            title='Most popular answers'
            description='Top answers from both Delivery and Pickup surveys'
            total={calculateTotal(PopularSurveyAnswers)}
            sumFirstQuestion={PopularSurveyAnswers[0]?.Count}
            firstQuestionText={PopularSurveyAnswers[0]?.Text}
            firstQuestionType={PopularSurveyAnswers[0]?.Type}
            sumSecondQuestion={PopularSurveyAnswers[1]?.Count}
            secondQuestionText={PopularSurveyAnswers[1]?.Text}
            secondQuestionType={PopularSurveyAnswers[1]?.Type}
            sumThirdQuestion={PopularSurveyAnswers[2]?.Count}
            thirdQuestionText={PopularSurveyAnswers[2]?.Text}
            thirdQuestionType={PopularSurveyAnswers[2]?.Type}
            sumFourthQuestion={PopularSurveyAnswers[3]?.Count}
            fourthQuestionText={PopularSurveyAnswers[3]?.Text}
            fourthQuestionType={PopularSurveyAnswers[3]?.Type}
          />
        </DashboardInner>
      </DashboardInners>
    </>
  );
};

export default TabAll;
