import {createReducer} from 'redux-act';
import {
  FORM_CHANGED,
  SHOW_EMAIL_CHECK_MODAL,
  OPEN_CANCELLATION_CONFIRMATION_MODAL,
  SET_LOADING_CANCELLATION
} from './action';

const initialState = () => ({
  isFormChanged: false,
  isEmailCheckModalOpen: false,
  isOpenCancellationModal: false,
  orderCancellationSource: null,
  orderCancellationInfo: null,
  orderCancellationDeliveryProvider: null,
  loadingCancellation: false
});

const modalsReducer = createReducer(
  {
    [FORM_CHANGED]: (state, {changed}) => ({
      ...state,
      isFormChanged: changed
    }),
    [SHOW_EMAIL_CHECK_MODAL]: (state, payload) => ({
      ...state,
      isEmailCheckModalOpen: Boolean(payload)
    }),
    [OPEN_CANCELLATION_CONFIRMATION_MODAL]: (state, payload) => {
      return {
        ...state,
        isOpenCancellationModal: payload.isOpen,
        orderCancellationInfo: payload.info,
        orderCancellationSource: payload.source,
        orderCancellationDeliveryProvider: payload.deliveryProvider
      };
    },
    [SET_LOADING_CANCELLATION]: (state, payload) => {
      return {
        ...state,
        loadingCancellation: payload
      };
    }
  },
  initialState()
);

export default modalsReducer;
