import * as Yup from 'yup';
import {URL_REGEXP} from '../constants/RegExpConstants';

const SalesSetupAddMenuSchema = Yup.object()
  .shape({
    MenuShowUrlInput: Yup.bool(),
    MenuUseUrl: Yup.string()
      .matches(URL_REGEXP, 'Please enter a valid URL!')
      .when(['data.MenuShowUrlInput', 'data.MenuUseUrl'], {
        is: (MenuShowUrlInput, MenuUseUrl) => MenuShowUrlInput && !MenuUseUrl,
        then: Yup.string()
          .required('This field cannot be empty!')
          .trim('The field cannot contain only spaces.'),
        otherwise: Yup.string().trim('The field cannot contain only spaces.')
      }),
    ImagesShowUrlInput: Yup.bool(),
    ImagesUseUrl: Yup.string()
      .matches(URL_REGEXP, 'Please enter a valid URL!')
      .when(['data.ImagesShowUrlInput', 'data.ImagesUseUrl'], {
        is: (ImagesShowUrlInput, ImagesUseUrl) =>
          ImagesShowUrlInput && !ImagesUseUrl,
        then: Yup.string()
          .required('This field cannot be empty!')
          .trim('The field cannot contain only spaces.'),
        otherwise: Yup.string().trim('The field cannot contain only spaces.')
      }),
    MenuShowUploadFile: Yup.bool(),
    MenuUploadFile: Yup.string().when(
      ['data.MenuShowUploadFile', 'data.MenuUploadFile'],
      {
        is: (MenuShowUploadFile, MenuUploadFile) =>
          MenuShowUploadFile && !MenuUploadFile,
        then: Yup.string().required('Please upload a file!')
      }
    ),
    ImagesShowUploadFile: Yup.bool(),
    ImagesUploadFile: Yup.string().when(
      ['data.ImagesShowUploadFile', 'data.ImagesUploadFile'],
      {
        is: (ImagesShowUploadFile, ImagesUploadFile) =>
          ImagesShowUploadFile && !ImagesUploadFile,
        then: Yup.string().required('Please upload a file!'),
        otherwise: Yup.string()
      }
    )
  })
  .test('global-ok', 'You must select one of the options!', function (value) {
    if (
      !value.data.ImagesShowUploadFile &&
      !value.data.WithoutImages &&
      !value.data.ImagesShowUrlInput &&
      !value.data.MenuBuildFromScratch &&
      !value.data.MenuShowUploadFile &&
      !value.data.MenuShowUrlInput
    ) {
      return this.createError({
        path: 'noOptionsAll',
        message: 'Please select one of the available options!'
      });
    }

    if (
      !value.data.ImagesShowUploadFile &&
      !value.data.WithoutImages &&
      !value.data.ImagesShowUrlInput
    ) {
      return this.createError({
        path: 'noOptionsImages',
        message: 'Please select one of the available options!'
      });
    }
    if (
      !value.data.MenuBuildFromScratch &&
      !value.data.MenuShowUploadFile &&
      !value.data.MenuShowUrlInput
    ) {
      return this.createError({
        path: 'noOptionsMenu',
        message: 'Please select one of the available options!'
      });
    }

    return true;
  });
// const SalesSetupAddMenuSchema = (values) => {
//   const errors = {};
//
//   // if (
//   //   !values.data.MenuShowUploadFile &&
//   //   !values.data.MenuBuildFromScratch &&
//   //   !values.data.MenuShowUrlInput
//   // ) {
//   //   errors.noOptions = 'Please choose one of the options provided!';
//   // }
//   //
//   // if (values.data.MenuShowUrlInput && !values.data.MenuUseUrl) {
//   //   errors.MenuUseUrl = 'This field cannot be left blank!';
//   // }
//   //
//   // if (
//   //   !values.data.ImagesShowUrlInput &&
//   //   !values.data.ImagesShowUploadFile &&
//   //   !values.data.WithoutImages
//   // ) {
//   //   errors.noOptionsImages = 'Please choose one of the options provided!';
//   // }
//   console.log(values.data.ImagesShowUploadFile);
//   console.log(!values.data.ImagesUseUrl.trim());
//   if (values.data.ImagesShowUploadFile && !values.data.ImagesUseUrl.trim()) {
//     errors.ImagesUseUrl = 'This field cannot be left blank!';
//   }
//
//   // if (values.data.CopyFromTheProvidedLink === 'No') {
//   //   const regex = new RegExp(URL_REGEXP);
//   //   if (values.data.CopyMenuFromOption === 'anotherLink') {
//   //     if (values.data.AnotherLinkFromWebsite === '') {
//   //       errors.AnotherLinkFromWebsite = 'This field cannot be left blank!';
//   //       return errors;
//   //     }
//   //     if (!regex.test(values.data.AnotherLinkFromWebsite)) {
//   //       errors.AnotherLinkFromWebsite = 'Please enter a valid URL!';
//   //       return errors;
//   //     }
//   //   }
//   //   if (values.data.CopyMenuFromOption === 'checkOrderingSystem') {
//   //     if (values.data.ThirdPartyOrderingSystem === '') {
//   //       errors.ThirdPartyOrderingSystem = 'This field cannot be left blank!';
//   //       return errors;
//   //     }
//   //     if (!regex.test(values.data.ThirdPartyOrderingSystem)) {
//   //       errors.ThirdPartyOrderingSystem = 'Please enter a valid URL!';
//   //       return errors;
//   //     }
//   //   }
//   //
//   //   if (values.data.CanUseProvidedSource === 'No') {
//   //     const regExp = new RegExp(URL_REGEXP);
//   //     if (values.data.AnotherSource === '') {
//   //       errors.AnotherSource = 'This field cannot be left blank!';
//   //       return errors;
//   //     }
//   //     if (!regExp.test(values.data.AnotherSource)) {
//   //       errors.AnotherSource = 'Please enter a valid URL!';
//   //       return errors;
//   //     }
//   //   }
//   // }
//   return errors;
// };

export default SalesSetupAddMenuSchema;
