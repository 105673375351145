import {createAction} from 'redux-act';

export const GET_USERS = createAction('usersManagement/GET_USERS');
export const GET_USERS_SUCCESS = createAction(
  'usersManagement/GET_USERS_SUCCESS'
);
export const CREATE_USER = createAction('usersManagement/CREATE_USER');
export const CREATE_USER_SUCCESS = createAction(
  'usersManagement/CREATE_USER_SUCCESS'
);
export const CREATE_USER_FAILED = createAction(
  'usersManagement/CREATE_USER_FAILED'
);
export const UPDATE_USER = createAction('usersManagement/UPDATE_USER');
export const UPDATE_USER_SUCCESS = createAction(
  'usersManagement/UPDATE_USER_SUCCESS'
);
export const DELETE_USER = createAction('usersManagement/DELETE_USER');
export const DELETE_USER_SUCCESS = createAction(
  'usersManagement/DELETE_USER_SUCCESS'
);
export const RESET_PASSWORD = createAction('usersManagement/RESET_PASSWORD');
export const RESET_PASSWORD_SUCCESS = createAction(
  'usersManagement/RESET_PASSWORD_SUCCESS'
);
export const GET_COUNTER = createAction('usersManagement/GET_COUNTER');
export const GET_COUNTER_SUCCESS = createAction(
  'usersManagement/GET_COUNTER_SUCCESS'
);
export const OPEN_CONFIRMATION_MODAL = createAction(
  'usersManagement/OPEN_CONFIRMATION_MODAL'
);

export const SUBMIT_USER_EDIT_ADD_WRAPPER = createAction(
  'user/SUBMIT_USER_EDIT_ADD_WRAPPER'
);
