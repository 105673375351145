import styled from 'styled-components';

const SelectFieldWrapper = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInput-underline {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
`;

export default SelectFieldWrapper;
