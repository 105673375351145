/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import T from 'prop-types';
import moment from 'moment';
import {Dialog} from '@material-ui/core';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {DialogBody, DialogText} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import {
  CREATE_STORE_HOLIDAYS_REQUEST,
  PUBLISH_LOCATION,
  SET_STORE_HOLIDAYS,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../../../store/locationSettings/action';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';

const DisableStoreForHolidayModal = ({
  openHolidayModal,
  isCloseHolidayModal,
  disableHolidayStore,
  onDisable,
  setValuesHolidays,
  resetForm,
  setFormSubmitting,
  setGlobalFieldValue,
  setHideBannerHolidays
}) => {
  const dispatch = useDispatch();
  const [userActions, setUserActions] = useState(false);
  const publishLocation = () => {
    dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallBack: () => {},
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };
  const diffDate = () => {
    const duration = moment.duration(
      moment(moment(disableHolidayStore?.enableStoreTime).utc().format()).diff(
        moment(disableHolidayStore?.disableStoreTime).utc().format()
      )
    );
    const day = duration.asDays();
    return Math.floor(day);
  };
  const disableStore = async () => {
    if (disableHolidayStore.isCCAgent || diffDate() <= 3) {
      dispatch(SET_STORE_HOLIDAYS(disableHolidayStore));
      setValuesHolidays(disableHolidayStore);
      // onDisable();
      isCloseHolidayModal();
      setUserActions(false);
      dispatch(
        UPDATE_LOCATION_SETTINGS_DATA({
          payload: {storeHolidays: disableHolidayStore},
          successCallback: () => {
            publishLocation();
            setFormSubmitting(false);
            setGlobalFieldValue('storeHolidays', undefined);
            resetForm({});
            setHideBannerHolidays(false);
          },
          errorCallback: () => {
            dispatch(STOP_GET_PUBLISH_STATUS_JOB());
            setFormSubmitting(false);
            resetForm({});
            setGlobalFieldValue('storeHolidays', undefined);
            dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
            setHideBannerHolidays(false);
          }
        })
      );
    } else {
      dispatch(CREATE_STORE_HOLIDAYS_REQUEST(disableHolidayStore));
      setUserActions(true);
    }
    // resetForm({});
  };

  return (
    <Dialog
      open={openHolidayModal}
      scroll='body'
      onClose={() => isCloseHolidayModal()}
    >
      <DialogContainer className='DialogSm2'>
        <DialogClose onClick={() => isCloseHolidayModal()} />
        <DialogTitle style={{textAlign: 'center'}}>
          {userActions ? (
            <>
              Our team will check your disable request and get back to you soon
            </>
          ) : (
            <>
              Are you sure you want to disable the store{' '}
              <span>
                from{' '}
                {moment(disableHolidayStore?.disableStoreTime).format(
                  'MMMM Do YYYY'
                )}{' '}
                till{' '}
                {moment(disableHolidayStore?.enableStoreTime).format(
                  'MMMM Do YYYY'
                )}
                ?
              </span>
            </>
          )}
        </DialogTitle>
        <DialogBody>
          <DialogActions className='marginTop25 justifyCenter'>
            {userActions ? (
              <>
                <DialogAction className='w50'>
                  <ButtonPrimary
                    type='submit'
                    onClick={() => {
                      isCloseHolidayModal();
                      setUserActions(false);
                    }}
                  >
                    <span>Ok</span>
                  </ButtonPrimary>
                </DialogAction>
              </>
            ) : (
              <>
                <DialogAction className='w50'>
                  <ButtonOutline
                    type='button'
                    className='dismiss'
                    onClick={() => isCloseHolidayModal()}
                  >
                    <span>No</span>
                  </ButtonOutline>
                </DialogAction>
                <DialogAction className='w50'>
                  <ButtonPrimary
                    type='submit'
                    onClick={() => {
                      disableStore();
                    }}
                  >
                    <span>Yes</span>
                  </ButtonPrimary>
                </DialogAction>
              </>
            )}
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DisableStoreForHolidayModal.propTypes = {
  disableHolidayStore: T.func.isRequired,
  setValuesHolidays: T.func.isRequired,
  isCloseHolidayModal: T.func.isRequired,
  openHolidayModal: T.bool.isRequired,
  resetForm: T.func.isRequired,
  setFormSubmitting: T.func.isRequired,
  setGlobalFieldValue: T.func.isRequired,
  setHideBannerHolidays: T.func.isRequired,
  onDisable: T.func.isRequired
};
export default DisableStoreForHolidayModal;
