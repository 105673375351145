import * as Yup from 'yup';

const phoneRegex = /^([+]1)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;

const StoreSettingsSchema = Yup.object()
  .shape({
    FullName: Yup.string()
      .required('Please enter location name')
      .trim()
      .max(50, 'Location name cannot be more than 50 characters'),
    Phone: Yup.string()
      .required('Please enter phone')
      .matches(phoneRegex, 'Please enter valid phone'),
    Address: Yup.string()
      .required('Please enter location address')
      .trim('Please enter location address'),
    City: Yup.string()
      .required(
        'Your address does not have any city specified. Please select another address or choose address with city specified!'
      )
      .trim('The field cannot contain only spaces.'),
    StreetNumber: Yup.string()
      .required(
        'Your address does not have any street number specified. Please select another address or choose address with street number specified!'
      )
      .trim('The field cannot contain only spaces.'),
    PickupEtaMinutes: Yup.number().min(1, 'ETA cannot be less than 1!'),
    // .positive('ETA cannot be a negative value!'),
    EtaMinutes: Yup.number()
      .min(1, 'ETA cannot be less than 1!')
      .positive('ETA cannot be a negative value!'),
    CountryCode: Yup.string()
      .required('Please enter county code')
      .min(1)
      .max(3),
    TaxPercents: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string()
          .max(20, 'Tax Name cannot contain more than 20 symbols!')
          .required('Please enter tax name!'),
        Value: Yup.string().required('Please enter tax value!')
      })
    ),
    // MailForWeeklyReport: Yup.string().email('Invalid email!'),
    /* MailForHumanResponseMessage: Yup.string()
      .nullable(true)
      .email('Invalid email!'), */
    WelcomeLogoURL: Yup.string().required('Please upload the store logo')
  })
  .test(
    'global-ok',
    'ETA cannot be less than Food Preparation Time',
    function (value) {
      if (
        value.InhouseDelivery &&
        value.EtaMinutes < value.PickupEtaMinutes + 10
      ) {
        return this.createError({
          path: 'EtaMinutes',
          message: `ETA should be at least 10 minutes ahead of Food Preparation Time (${
            value.PickupEtaMinutes + 10
          }) minutes`
        });
      }

      if (!value.PickupEtaMinutes) {
        return this.createError({
          path: 'PickupEtaMinutes',
          message: 'ETA cannot be less than 1!'
        });
      }

      if (value.Address && !value.FullAddress) {
        return this.createError({
          path: 'Address',
          message:
            'Please choose the suggested address from the dropdown or select it on the map!'
        });
      }
      return true;
    }
  );

export default StoreSettingsSchema;
