import styled from 'styled-components';
import {ReactSVG} from 'react-svg';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import DialogActions from '../../../components/DialogActions/styles';
import EmptyCircle from '../../../assets/uberEats/empty-circle.svg';
import CheckedIcon from '../../../assets/uberEats/checked-item.svg';

export const StepIndicators = styled.div`
  position: relative;
  margin: 15px 0 0 15px;
  @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 767px) {
    display: flex;
    align-items: flex-start;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    background: #f1f1f1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 30px;
    z-index: 1;
    @media (max-width: 767px) {
      width: 100%;
      height: 20px;
    }
  }
`;

export const StepIndicator = styled.div`
  padding-left: 30px;
  color: #929cb9;
  background-image: url('${EmptyCircle}');
  background-repeat: no-repeat;
  background-position: center left;
  margin-bottom: 16px;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 500;
  @media (max-width: 767px) {
    padding: 30px 5px 0 5px;
    background-position: top left 5px;
    flex: 1 1 auto;
    font-size: 14px;
  }

  &.passed {
    background-image: url('${CheckedIcon}');
    background-repeat: no-repeat;
    color: #232d3a;
  }

  &.current {
    font-weight: 500;
    color: #232d3a;
    font-size: 20px;
    @media (max-width: 900px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`;

export const IconWrapper = styled(ReactSVG)`
  margin-right: ${(props) => props.marginRight || '5px'};
  font-size: 0;
`;

export const RedSpan = styled.span`
  color: red;
`;

export const ByocContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const ByocContent = styled.div`
  flex: 1 1 auto;
  padding-right: 130px;

  @media (max-width: 1800px) {
    padding-right: 100px;
  }
  @media (max-width: 1700px) {
    padding-right: 80px;
  }
  @media (max-width: 1600px) {
    padding-right: 50px;
  }
  @media (max-width: 1440px) {
    padding-right: 40px;
  }
  @media (max-width: 900px) {
    padding-right: 25px;
  }
  @media (max-width: 767px) {
    order: 2;
    flex: 0 0 100%;
    padding-right: 0;
  }
`;

export const ByocSidebar = styled.div`
  flex: 0 0 auto;
  width: 280px;
  @media (max-width: 1440px) {
    width: 240px;
  }
  @media (max-width: 900px) {
    width: 180px;
  }
  @media (max-width: 767px) {
    order: 1;
    flex: 0 0 100%;
    width: 100%;
  }
`;

export const ByocItems = styled.div`
  margin: 24px 0 36px 0;
`;

export const ByocItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const WizardPaper = styled(DashboardInner)`
  margin-top: 0;
  min-height: 600px;
  padding: 20px 34px 40px 34px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

export const WizardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px -10px -10px -10px;
  @media (max-width: 1600px) {
    margin: 30px -7px -7px -7px;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (max-width: 599px) {
    justify-content: flex-start;
  }

  button {
    flex: 1 1 auto;
    margin: 10px;
    max-width: 240px;
    width: 100%;
    white-space: nowrap;
    @media (max-width: 1600px) {
      margin: 7px;
    }
    @media (max-width: 1350px) {
      max-width: 220px;

      &.ButtonNav {
        max-width: 136px;
        @media (max-width: 900px) {
          flex: 0 0 calc(50% - 10px);
          max-width: 100%;
        }
        @media (max-width: 599px) {
          flex: 0 0 calc(100% - 10px);
        }
      }
    }
    @media (max-width: 900px) {
      margin: 5px;
      flex: 0 0 calc(50% - 10px);
      max-width: 100%;
    }
    @media (max-width: 599px) {
      flex: 0 0 calc(100% - 10px);
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;

export const WizardDialogActions = styled(DialogActions)`
  button {
    margin: 0 10px 0 10px;
    min-width: 100px;
  }
`;
