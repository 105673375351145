import * as Yup from 'yup';

const BankAccountSchema = Yup.object().shape({
  BusinessLegalName: Yup.string().required(
    'Business legal name field is required!'
  ),
  BankAccountNumber: Yup.string().required(
    'Bank account number field is required!'
  ),
  VerifyBankAccount: Yup.string().required(
    'Verify bank account field is required!'
  ),
  RoutingNumber: Yup.string().required('Routing number field is required!')
});

export default BankAccountSchema;
