import {request} from '../utils/request';
import {GET, POST, PUT} from '../constants/API_CONSTANTS';
import {
  ACTIVATE_UBER_EATS_STORE,
  GET_UBER_EATS_MERCHANT_STATUS,
  GET_UBER_EATS_SINGLE_STORE,
  GET_UBER_EATS_STORES,
  SEND_SLACK_NOTIFICATION_BYOC,
  SEND_SLACK_NOTIFICATION_UE_DISCONNECT,
  GET_UBER_EATS_STORE_MENUS,
  POST_UBER_EATS_ONBOARDING_PROCESS
} from '../constants/API_ENDPOINTS';

const headersConfig = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_UBEREATS_AUTH_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const getUberEatsMerchantStatusApi = async (merchantId) => {
  return request(GET, GET_UBER_EATS_MERCHANT_STATUS(merchantId), null, {
    ...headersConfig
  });
};

export const getUberEatsStoresApi = async (merchantId) => {
  return request(GET, GET_UBER_EATS_STORES(merchantId), null, {
    ...headersConfig
  });
};

export const getUberEatsStoreApi = async (storeId) => {
  return request(GET, GET_UBER_EATS_SINGLE_STORE(storeId), null, {
    ...headersConfig
  });
};

export const connectUberEatsStoreApi = async (storeId, reqBody) => {
  return request(
    PUT,
    ACTIVATE_UBER_EATS_STORE(storeId),
    {reqBody},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_UBEREATS_AUTH_TOKEN}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );
};

// export const changeUberStatusApi = async (locationId, data) => {
//  return request(
//    POST,
//    GET_UBER_EATS_STATUS(locationId),
//    {data},
//    {silent: true}
//  );
// };

export const sendSlackNotificationUEDisconnect = async (data) => {
  return request(POST, SEND_SLACK_NOTIFICATION_UE_DISCONNECT(), {data});
};

export const sendSlackNotificationBYOCApi = async (data) => {
  return request(POST, SEND_SLACK_NOTIFICATION_BYOC(), {data});
};

export const getUberEatsStoreMenus = async (storeId) => {
  return request(GET, GET_UBER_EATS_STORE_MENUS(storeId), null, {
    ...headersConfig
  });
};

export const postUberEatsOnboardingProcess = async (data) => {
  return request(
    POST,
    POST_UBER_EATS_ONBOARDING_PROCESS(),
    {data},
    {
      ...headersConfig
    }
  );
};
