import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {RedBannerUnderlineText, RedBannerWrapper} from './styles';
import {IconUnPublish} from '../../assets/IconUnPublish';
import Body3 from '../typography/Body3';
import Modal from '../Modals/Modal';
import DialogTitle from '../DialogTitle/styles';
import {UberEatsImage} from '../../routes/App/uberEats/styles';
import AnimateLocationDelivery from '../../assets/animate-delivery-red.gif';
import AnimateLocationPickup from '../../assets/animate-pizza-red.gif';
import DialogActions from '../DialogActions/styles';
import DialogAction from '../DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from '../buttons';
import {
  PUBLISH_LOCATION,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../store/locationSettings/action';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../store/global/globalNotifications/action';

const RedBannerDisableStore = ({ClosedManuallyUntilTime}) => {
  const {pathname} = useLocation();

  const entityName = 'Store';
  const getServiceDisabledHint = (timestamp) => {
    const calcTime =
      typeof timestamp === 'object'
        ? Number(Date.parse(timestamp)) / 1000
        : timestamp;
    let text;
    if (calcTime) {
      text = moment(calcTime).utc().format('MM/DD/YYYY HH:mm');
    }
    return text;
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const onClose = () => {
    setIsOpenModal(false);
  };
  const dispatch = useDispatch();

  const publishLocation = () => {
    dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallBack: () => {},
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  const onEnable = () => {
    const storeObj = {
      ClosedManually: false,
      ClosedManuallyTime: null,
      'notSyncedData.ClosedManually': false,
      ClosedManuallyReason: null,
      StoreDisableOption: null,
      ClosedManuallyUntilTime: null
    };
    onClose();
    dispatch(
      UPDATE_LOCATION_SETTINGS_DATA({
        payload: storeObj,
        successCallback: () => {
          publishLocation();
        },
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  return (
    <>
      <RedBannerWrapper>
        <IconUnPublish />
        <Body3 className='textRedBanner White'>
          The store is disabled until{' '}
          {getServiceDisabledHint(ClosedManuallyUntilTime)}
          {pathname !== '/store-settings' && (
            <RedBannerUnderlineText onClick={() => setIsOpenModal(true)}>
              Click to enable
            </RedBannerUnderlineText>
          )}
        </Body3>
      </RedBannerWrapper>
      {isOpenModal && (
        <Modal
          onCloseAction={() => onClose()}
          title={
            <DialogTitle className='marginBottom2l'>
              Enable <span>{entityName}?</span>
            </DialogTitle>
          }
          width='715px'
          body={
            <>
              <UberEatsImage
                src={
                  entityName === 'Delivery'
                    ? AnimateLocationDelivery
                    : AnimateLocationPickup
                }
              />
              <DialogActions>
                <DialogAction className='w50'>
                  <ButtonOutline onClick={() => onClose()}>
                    <span>Keep it Disabled</span>
                  </ButtonOutline>
                </DialogAction>
                <DialogAction className='w50'>
                  <ButtonPrimary onClick={() => onEnable('store')}>
                    <span>Yes, Enable {entityName}</span>
                  </ButtonPrimary>
                </DialogAction>
              </DialogActions>
            </>
          }
        />
      )}
    </>
  );
};

RedBannerDisableStore.propTypes = {
  ClosedManuallyUntilTime: PropTypes.object.isRequired
};

RedBannerDisableStore.defaultProps = {};

export default RedBannerDisableStore;
