import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import {ButtonOutline} from '../../../components/buttons';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import {
  CHANGE_UBER_STATUS,
  CHANGE_UBER_STATUS_SUCCESS,
  CONNECT_UBEREATS_STORE,
  HANDLE_CONNECT_STORE_MODAL,
  HANDLE_DISCONNECT_MODAL,
  HANDLE_NOT_STORE_MODAL
} from '../../../store/uberEats/actions';
import {UberDisconnectButton} from './styles';
import ConnectModal from './modals/ConnectModal';
import Headline from '../../../components/Headline/styles';
import {DashboardHeader} from '../../../components/DashboardHeader/styles';
import UberEatsIcon from '../../../assets/uber-eats.svg';
import Body5 from '../../../components/typography/Body5';
import UberActions from './components/UberActions';
import ConnectUberPage from './components/ConnectUberPage';
import ConnectedUberPage from './components/ConnectedUberPage';
import BYOCConfiguredPage from './components/BYOCConfiguredPage';
import BYOCConnectedPage from './components/BYOCConnectedPage';
import {FlexContainer, FlexItem} from '../../../components/layout';
import {getSessionStorageItem} from '../../../helpers/sessionStorage';
import {BYOC_STEP_STORAGE_KEY} from '../byocWizard';
import DisconnectModal from './modals/DisconnectModal';
import StoreSelectModal from './modals/StoreSelectModal';
import axios from '../../../helpers/services/utils/axios';
import {SERVER_UBEREATS_URL} from '../../../helpers/services/constants/API_CONSTANTS';
import NotStoresModal from './modals/NotStoresModal';
import DisconnectFormModal from './modals/DisconnectModal/DisconnectFormModal';
import DisconnectSuccessModal from './modals/DisconnectModal/DiconnectSuccessModal';
import sendGtmEvent from '../../../helpers/services/utils/gtm';

const UberEats = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const uberEatsId = useSelector((state) => state.uberEatsReducer.uberEatsId);
  const locationId = useSelector((state) => state.user?.activeLocation?._id);
  const merchantId = useSelector((state) => state.user.userData?.merchant_id);

  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );
  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );

  const {
    connectStoreModalOpen,
    notStoresModalOpen,
    uberEatsStoreId,
    isUberEatsAccountConnected,
    uberEatsActiveStores
  } = useSelector((state) => state.uberEatsReducer);

  useEffect(() => {
    const BYOCStartedPage = getSessionStorageItem(BYOC_STEP_STORAGE_KEY);

    if (location.pathname === '/uber-eats/connect') {
      const query = location.search;
      const params = new URLSearchParams(query);
      const error = params.get('error');
      const success = params.get('success');
      if (error) {
        toast.error(error || 'Something went wrong! Please try again later!');
      } else if (success && success === 'true') {
        dispatch(HANDLE_CONNECT_STORE_MODAL(true));
        sendGtmEvent('uberEatsConnected', {
          uberEatsConnected: true
        });

        // dispatch(
        //   CONNECT_UBEREATS_STORE({
        //     postCallback: () => {
        //       toast.success(
        //         'Your UberEats account has been connected successfully!'
        //       );
        //     },
        //     requestBody: {locationId, isByocEnabled: false, metadata: []}
        //   })
        // );
      }
      history.push('/uber-eats');
    }
    if (
      !BYOCconnected &&
      !BYOCconfigured &&
      (BYOCStartedPage > 0 || BYOCStartedPage === 0)
    ) {
      history.push('/uber-eats/byoc-wizard');
    }
  }, []);

  const onConnect = () => {
    if (!isUberEatsAccountConnected) {
      dispatch(CHANGE_UBER_STATUS_SUCCESS(!UEConnected));
      //    if (!uberEatsInitiallyConnected) {
      window.open(
        `${process.env.REACT_APP_UBEREATS_API_URL}/api/uber-eats-adapter/v1/merchants/connect?id=${merchantId}&returnUrl=${process.env.REACT_APP_ROOT_DOMAIN}/uber-eats/connect`,
        '_self'
      );
    } else if (isUberEatsAccountConnected && uberEatsActiveStores?.length) {
      dispatch(HANDLE_CONNECT_STORE_MODAL(true));
      // dispatch(
      //   CONNECT_UBEREATS_STORE({
      //     postCallback: () => {
      //       toast.success(
      //         'Your UberEats account has been connected successfully!'
      //       );
      //     },
      //     requestBody: {locationId, isByocEnabled: false, metadata: []},
      //     uberEatsStoreId: uberEatsActiveStores[0]?.id
      //   })
      // );
    } else if (isUberEatsAccountConnected && !uberEatsActiveStores?.length) {
      dispatch(HANDLE_NOT_STORE_MODAL(true));
    }

    //  history.push('/uber-eats/byoc-wizard');

    //    } else {
    //      dispatch(CHANGE_UBER_STATUS({UberEatsId: uberEatsId, IsActive: true}));
    //    }
  };

  const onDisable = () => {
    dispatch(CHANGE_UBER_STATUS({UberEatsId: uberEatsId, IsActive: false}));
  };

  const onDisconnect = () => {
    axios
      .delete(
        `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/merchants/${merchantId}/disconnect`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_UBEREATS_AUTH_TOKEN}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then();
  };

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader>
        <Headline className='mTop5'>UberEats</Headline>
      </DashboardHeader>

      {!UEConnected && !BYOCconnected && !BYOCconfigured && (
        <ConnectUberPage onConnect={onConnect} />
      )}
      {UEConnected && !BYOCconnected && !BYOCconfigured && (
        <ConnectedUberPage />
      )}
      {UEConnected && BYOCconfigured && !BYOCconnected && (
        <BYOCConfiguredPage />
      )}
      {UEConnected && BYOCconfigured && BYOCconnected && <BYOCConnectedPage />}

      <DashboardInner className='mTop15'>
        <FlexContainer className='align-center UberEatsBox'>
          <FlexItem className='wAutoSpace'>
            <FlexContainer className='align-center'>
              <img
                src={UberEatsIcon}
                alt='uber-eats-logo'
                className='marginRightLg'
              />
              <Body5>
                {UEConnected
                  ? 'UberEats account is connected'
                  : 'UberEats account is disconnected'}
              </Body5>
            </FlexContainer>
          </FlexItem>
          <FlexItem className='wAuto marginLeftXsPlus'>
            {UEConnected ? (
              <UberDisconnectButton
                type='button'
                onClick={() => {
                  dispatch(HANDLE_DISCONNECT_MODAL(1));
                }}
                data-gtm='disconnectUberEatsButton'
              >
                Disconnect
              </UberDisconnectButton>
            ) : (
              <ButtonOutline
                type='button'
                className='ButtonLarge'
                onClick={() => {
                  onConnect();
                }}
                data-gtm='createUberEatsSmallButton'
              >
                Connect now
              </ButtonOutline>
            )}
          </FlexItem>
        </FlexContainer>
      </DashboardInner>
      <UberActions
        uberEatsEnabled={UEConnected}
        BYOCconfigured={BYOCconfigured}
        BYOCconnected={BYOCconnected}
      />
      <ConnectModal />
      <DisconnectModal />
      <StoreSelectModal open={connectStoreModalOpen} />
      <NotStoresModal open={notStoresModalOpen} />
      <DisconnectFormModal />
      <DisconnectSuccessModal />
    </DashboardContent>
  );
};

export const UberNew = () => {};

export default UberEats;
