import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import {Body1, Caption1} from '../../../../../components/typography';
import Subtitle6 from '../../../../../components/typography/Subtitle6';
import {
  SurveyItem,
  SurveyItemDescription,
  SurveyItemProgress,
  SurveyItemProgressBar,
  SurveyItems,
  SurveyItemsDescription
} from '../../styles';
import normalizePercentageByDecimal from '../../../../../helpers/functionUtils/normalizePercentageByDecimal';

const StatisticsPopularAnswer = (props) => {
  const {
    title,
    description,
    total,
    sumFirstQuestion,
    firstQuestionText,
    sumSecondQuestion,
    secondQuestionText,
    sumThirdQuestion,
    thirdQuestionText,
    sumFourthQuestion,
    fourthQuestionText,
    firstQuestionType,
    secondQuestionType,
    thirdQuestionType,
    fourthQuestionType
  } = props;

  const PopularSurveyAnswers = useSelector(
    (state) => state.feedbackReducer.data.PopularSurveyAnswers
  );

  const percentFirstQuestion = 100 / (total / sumFirstQuestion) || 0;
  const percentSecondQuestion = 100 / (total / sumSecondQuestion) || 0;
  const percentThirdQuestion = 100 / (total / sumThirdQuestion) || 0;
  const percentFourthQuestion = 100 / (total / sumFourthQuestion) || 0;

  const [
    firstQuestion,
    secondQuestion,
    thirdQuestion,
    fourthQuestion
  ] = normalizePercentageByDecimal([
    percentFirstQuestion,
    percentSecondQuestion,
    percentThirdQuestion,
    percentFourthQuestion
  ]);

  return (
    <>
      <div>
        <FlexContainer justifyContent='space-between'>
          <FlexItem>
            <Subtitle6 className='SubtitleMobSmall'>{title}</Subtitle6>
            <Body1 className='mTop2'>{description}</Body1>
          </FlexItem>
        </FlexContainer>
        {!PopularSurveyAnswers.length && (
          <Subtitle6 className='marginTopXl text-center'>
            The first stats will come in soon
          </Subtitle6>
        )}
        {!!PopularSurveyAnswers.length && (
          <SurveyItems className='MostPopularAnswers'>
            {PopularSurveyAnswers[0] && (
              <SurveyItem
                className={cn('', {
                  SurveyItemGreat: firstQuestionType === 0,
                  SurveyItemBad: firstQuestionType === 1
                })}
              >
                <FlexContainer justifyContent='space-between'>
                  <Caption1>{`${firstQuestionText} ${
                    PopularSurveyAnswers[0]?.SurveyType === 0
                      ? '(Pickup)'
                      : '(Delivery)'
                  }`}</Caption1>
                  <Body1 className='grey2 wAuto'>{sumFirstQuestion}</Body1>
                </FlexContainer>
                <SurveyItemProgress>
                  <SurveyItemProgressBar width={`${firstQuestion}%`} />
                </SurveyItemProgress>
              </SurveyItem>
            )}
            {PopularSurveyAnswers[1] && (
              <SurveyItem
                className={cn('', {
                  SurveyItemGreat: secondQuestionType === 0,
                  SurveyItemBad: secondQuestionType === 1
                })}
              >
                <FlexContainer justifyContent='space-between'>
                  <Caption1>{`${secondQuestionText} ${
                    PopularSurveyAnswers[1]?.SurveyType === 0
                      ? '(Pickup)'
                      : '(Delivery)'
                  }`}</Caption1>
                  <Body1 className='grey2 wAuto'>{sumSecondQuestion}</Body1>
                </FlexContainer>
                <SurveyItemProgress>
                  <SurveyItemProgressBar width={`${secondQuestion}%`} />
                </SurveyItemProgress>
              </SurveyItem>
            )}

            {PopularSurveyAnswers[2] && (
              <SurveyItem
                className={cn('', {
                  SurveyItemGreat: thirdQuestionType === 0,
                  SurveyItemBad: thirdQuestionType === 1
                })}
              >
                <FlexContainer justifyContent='space-between'>
                  <Caption1>{`${thirdQuestionText} ${
                    PopularSurveyAnswers[2]?.SurveyType === 0
                      ? '(Pickup)'
                      : '(Delivery)'
                  }`}</Caption1>
                  <Body1 className='grey2 wAuto'>{sumThirdQuestion}</Body1>
                </FlexContainer>
                <SurveyItemProgress>
                  <SurveyItemProgressBar width={`${thirdQuestion}%`} />
                </SurveyItemProgress>
              </SurveyItem>
            )}
            {PopularSurveyAnswers[3] && (
              <SurveyItem
                className={cn('', {
                  SurveyItemGreat: fourthQuestionType === 0,
                  SurveyItemBad: fourthQuestionType === 1
                })}
              >
                <FlexContainer justifyContent='space-between'>
                  <Caption1>{`${fourthQuestionText} ${
                    PopularSurveyAnswers[3]?.SurveyType === 0
                      ? '(Pickup)'
                      : '(Delivery)'
                  }`}</Caption1>
                  <Body1 className='grey2 wAuto'>{sumFourthQuestion}</Body1>
                </FlexContainer>
                <SurveyItemProgress>
                  <SurveyItemProgressBar width={`${fourthQuestion}%`} />
                </SurveyItemProgress>
              </SurveyItem>
            )}
          </SurveyItems>
        )}
      </div>
      {!!PopularSurveyAnswers.length && (
        <SurveyItemsDescription>
          <SurveyItemDescription className='SurveyItemDescriptionGreat'>
            <Body1>Great job! Keep it up!</Body1>
          </SurveyItemDescription>
          <SurveyItemDescription className='SurveyItemDescriptionBad'>
            <Body1>
              This metric requires improvement to increase satisfied customers
            </Body1>
          </SurveyItemDescription>
        </SurveyItemsDescription>
      )}
    </>
  );
};

export default StatisticsPopularAnswer;

StatisticsPopularAnswer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sumFirstQuestion: PropTypes.number.isRequired,
  firstQuestionType: PropTypes.number.isRequired,
  firstQuestionText: PropTypes.string.isRequired,
  sumSecondQuestion: PropTypes.number.isRequired,
  secondQuestionType: PropTypes.number.isRequired,
  secondQuestionText: PropTypes.string.isRequired,
  sumThirdQuestion: PropTypes.number.isRequired,
  thirdQuestionType: PropTypes.number.isRequired,
  thirdQuestionText: PropTypes.string.isRequired,
  sumFourthQuestion: PropTypes.number.isRequired,
  fourthQuestionType: PropTypes.number.isRequired,
  fourthQuestionText: PropTypes.string.isRequired
};
