import React, {useEffect, useState} from 'react';
import {RadioGroup} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch} from 'react-redux';
import {
  SetupActions,
  SetupCalculator,
  SetupCommission,
  SetupContainer,
  SetupFieldset,
  SetupHeader,
  SetupHolder,
  SetupLogo,
  SetupSection,
  SetupSidebar
} from './style';
import Body3 from '../../../../components/typography/Body3';
import SauceLogoRed from '../../../../assets/logo-sauce-red.svg';
import Subtitle0 from '../../../../components/typography/Subtitle0';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Caption4 from '../../../../components/typography/Caption4';
import {DashboardField, DashboardFields} from '../../../App/dashboard/styles';
import {FieldLabel} from '../../../../components/FieldLabel/styles';
import FieldInput from '../../../../components/FieldInput';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import RadioS2E from '../../../../components/Radiobox';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import {Body1} from '../../../../components/typography';
import DialogFields from '../../../../components/DialogFields/styles';
import DialogField from '../../../../components/DialogField/styles';
import {
  RangeSlider,
  RangeSliderBottom,
  RangeSliderTop
} from '../../../../components/RangeSlider/styles';
import RangeSliderS2E from '../../../../components/RangeSlider';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {
  Commission,
  CommissionInner,
  SaveMoney
} from '../../../App/account/styles';
import {stepData} from '../../../../store/selfCreateAccount/selectors';
import {
  POST_STEP,
  SAVE_STEP_DATA,
  SET_FINAL_SETUP,
  SHOW_STEP_MODAL
} from '../../../../store/selfCreateAccount/action';
import CustomProgressBar from '../../../../components/CustomProgressBar';

const SetupRestaurant = () => {
  const dispatch = useDispatch();
  const {UserId} = stepData();
  const [ordersPerDay, setOrdersPerDay] = useState(18);
  const [averageCheckSize, setAverageCheckSize] = useState(30);
  const [comission, setComission] = useState(25);
  const [calculations, setCalculations] = useState({
    comissionEveryYear: '',
    comissionPerDay: '',
    savingsEveryYear: ''
  });

  const say2eatCommisionRate = 10;

  const calculateComissionFees = (orders, averageCheck, averageComission) => {
    const everyYear = (orders * averageCheck * 365 * averageComission) / 100;
    const perDay = (orders * averageCheck * 365 * averageComission) / 100 / 365;
    const savingsEveryYear =
      everyYear - (orders * averageCheck * 365 * say2eatCommisionRate) / 100;

    setCalculations({
      comissionEveryYear: everyYear.toLocaleString(),
      comissionPerDay: perDay.toLocaleString(),
      savingsEveryYear: savingsEveryYear.toLocaleString()
    });
  };

  const handleSliderValue = (event, value, setState) => {
    setState(value);
    calculateComissionFees(ordersPerDay, averageCheckSize, comission);
  };

  useEffect(() => {
    calculateComissionFees(ordersPerDay, averageCheckSize, comission);
    const {data, ...info} = {
      userId: UserId || '',
      step: 7,
      data: {
        step: 7,
        userId: UserId || ''
      }
    };
    dispatch(SET_FINAL_SETUP(true));
    dispatch(SAVE_STEP_DATA({data, ...info}));
    dispatch(POST_STEP(info));
  }, []);

  return (
    <SetupSection>
      <SetupHolder>
        <SetupContainer>
          <SetupHeader>
            <SetupLogo src={SauceLogoRed} />
            <Subtitle5>
              <span>Setup</span> your restaurant
            </Subtitle5>
          </SetupHeader>
          <CustomProgressBar className='ProgressBar ProgressBarMob' />
          <Subtitle0>General Info</Subtitle0>
          <SetupFieldset>
            <Caption4>Your Info</Caption4>
            <DashboardFields>
              <DashboardField className='w25'>
                <FieldLabel>First Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w25'>
                <FieldLabel>Last Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='FieldNumber'>
                <FieldLabel>Country Code</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>Your phone number</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupFieldset>
            <Caption4>Restaurant Info</Caption4>
            <DashboardFields>
              <DashboardField className='w100'>
                <FieldLabel>Restaurant Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Restaurant URL</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Address</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Delivery special instructions</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='FieldNumber'>
                <FieldLabel>Country Code</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>The Restaurant&apos;s Phone Number</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='wAutoSpace'>
                <FieldLabel>Sales tax rate</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupActions>
            <ButtonOutline type='submit' className='ButtonBookCall'>
              Book a Call with Us
            </ButtonOutline>
            <ButtonPrimary>
              <span>Start your Free 14-Day Trial</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
        <SetupContainer className='SecondStep'>
          <Subtitle0>Start your free trial!</Subtitle0>
          <SetupFieldset>
            <Caption4>
              Do you use your own delivery people or use a third party delivery
              service?
            </Caption4>
            <RadioGroup>
              <DashboardFields>
                <DashboardField className='w100'>
                  <RadioS2E
                    type='radio'
                    label='Only my own delivery people'
                    name='SauceRadio'
                  />
                </DashboardField>
                <DashboardField className='w100'>
                  <RadioS2E
                    type='radio'
                    label='Only 3rd-party delivery services'
                    name='SauceRadio'
                  />
                </DashboardField>
                <DashboardField className='w100'>
                  <RadioS2E
                    type='radio'
                    label='Both my own and 3rd-party’s services'
                    name='SauceRadio'
                  />
                </DashboardField>
                <DashboardField className='w100'>
                  <RadioS2E
                    type='radio'
                    label='I don’t offer deliveries, at the moment'
                    name='SauceRadio'
                  />
                </DashboardField>
              </DashboardFields>
            </RadioGroup>
          </SetupFieldset>
          <SetupFieldset>
            <Caption4>
              Do you use your own delivery people or use a third party delivery
              service?
            </Caption4>
            <DashboardFields>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Cut delivery costs'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Convert customers from 3rd parties to ordering direct'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Improve digital guest experience'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Start taking orders through social media'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Improve your Google search ranking'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Increase repeat orderings'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                      />
                    }
                    label='Collect feedback from your customers'
                  />
                </CheckboxContainer>
              </DashboardField>
              <DashboardField className='w100'>
                <DashboardFields className='AlignCenter'>
                  <DashboardField className='wAuto'>
                    <CheckboxContainer>
                      <FormControlLabel
                        className='Say2EatCheckbox'
                        control={
                          <Checkbox
                            labelStyle={{color: '#172751'}}
                            iconStyle={{fill: '#FF2D38'}}
                            inputStyle={{color: '#FF2D38'}}
                            style={{color: '#FF2D38'}}
                          />
                        }
                        label='Other'
                      />
                    </CheckboxContainer>
                  </DashboardField>
                  <DashboardField className='wAutoSpace'>
                    <FieldInput />
                  </DashboardField>
                </DashboardFields>
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupActions className='SecondStep'>
            <ButtonOutline type='submit' className='ButtonLg ButtonOutline'>
              Back
            </ButtonOutline>
            <ButtonPrimary className='ButtonPrimary'>
              <span>Finish</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
        <SetupContainer>
          <Subtitle0>Let us do this for you!</Subtitle0>
          <Body1>
            Book a call with our team and get all the info and assistance you
            need.
          </Body1>
          <SetupFieldset>
            <DashboardFields>
              <DashboardField className='FieldNumber'>
                <FieldLabel>Country Code</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w66Minus'>
                <FieldLabel>Your Phone number</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
            <DashboardFields>
              <DashboardField className='w33'>
                <FieldLabel>First Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w33'>
                <FieldLabel>Last Name</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w33'>
                <FieldLabel>Your email address</FieldLabel>
                <FieldInput />
              </DashboardField>
              <DashboardField className='w100'>
                <FieldLabel>Restaurant’s URL</FieldLabel>
                <FieldInput />
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupFieldset>
            <Caption4>
              One question before we schedule the call: what is your role at
              restaurant name?
            </Caption4>
            <DashboardFields>
              <DashboardField className='w33 Say2EatSelect'>
                <FormControl>
                  <Select
                    name='selectValue'
                    inputProps={{'aria-label': 'value'}}
                  >
                    <MenuItem>Select</MenuItem>
                    <MenuItem>Select</MenuItem>
                    <MenuItem>Select</MenuItem>
                  </Select>
                </FormControl>
              </DashboardField>
            </DashboardFields>
          </SetupFieldset>
          <SetupActions className='SecondStep'>
            <ButtonOutline type='submit' className='ButtonLg ButtonOutline'>
              Back
            </ButtonOutline>
            <ButtonPrimary className='ButtonPrimary'>
              <span>Finish</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
        <SetupContainer>
          <Subtitle0>Third parties Delivery Fees Calculator</Subtitle0>
          <SetupCalculator>
            <DialogFields className='mBottom40'>
              <DialogField className='w100 marginBottomXs'>
                <FieldLabel className='FieldLabelBold'>
                  How many orders are you getting PER DAY from 3rd
                  party-delivery?
                </FieldLabel>
                <RangeSlider>
                  <RangeSliderTop>
                    <span>{ordersPerDay}</span> Orders
                  </RangeSliderTop>
                  <RangeSliderS2E
                    defaultValue={ordersPerDay}
                    step={1}
                    min={0}
                    max={70}
                    onChange={(event, value) =>
                      handleSliderValue(event, value, setOrdersPerDay)
                    }
                  />
                  <RangeSliderBottom>
                    <span>0 Orders</span>
                    <span>70 Orders</span>
                  </RangeSliderBottom>
                </RangeSlider>
              </DialogField>
              <DialogField className='w100 marginBottomXs'>
                <FieldLabel className='FieldLabelBold'>
                  What is your average check size?
                </FieldLabel>
                <RangeSlider>
                  <RangeSliderTop>
                    <span>$ {averageCheckSize}</span> Per Order
                  </RangeSliderTop>
                  <RangeSliderS2E
                    defaultValue={averageCheckSize}
                    step={1}
                    min={10}
                    max={70}
                    onChange={(event, value) =>
                      handleSliderValue(event, value, setAverageCheckSize)
                    }
                  />
                  <RangeSliderBottom>
                    <span>$ 10 Per Order</span>
                    <span>$ 70 Per Order</span>
                  </RangeSliderBottom>
                </RangeSlider>
              </DialogField>
              <DialogField className='w100'>
                <FieldLabel className='FieldLabelBold'>
                  What are the average commission rates you are paying to 3rd
                  parties?
                </FieldLabel>
                <RangeSlider>
                  <RangeSliderTop>
                    <span>{comission}%</span> Comission
                  </RangeSliderTop>
                  <RangeSliderS2E
                    defaultValue={comission}
                    step={1}
                    min={15}
                    max={40}
                    onChange={(event, value) =>
                      handleSliderValue(event, value, setComission)
                    }
                  />
                  <RangeSliderBottom>
                    <span>15%</span>
                    <span>40%</span>
                  </RangeSliderBottom>
                </RangeSlider>
              </DialogField>
            </DialogFields>
            <SetupCommission>
              <DialogTitle>Commission Fees You Pay Today</DialogTitle>
              <Commission>
                <CommissionInner>
                  <span>$ {calculations.comissionEveryYear}</span> Every Year
                </CommissionInner>
                <CommissionInner>
                  <span>$ {calculations.comissionPerDay}</span> Per day
                </CommissionInner>
              </Commission>
              <SaveMoney className='marginBottom2l SaveMoney'>
                <DialogTitle className='DialogTitleXl'>
                  Your Savings with Sauce
                </DialogTitle>
                <DialogTitle className='DialogTitleXl'>
                  <span>$ {calculations.savingsEveryYear}</span> Every Year
                </DialogTitle>
              </SaveMoney>
            </SetupCommission>
          </SetupCalculator>
          <SetupActions>
            <ButtonPrimary className='ButtonPrimary'>
              <span>Save Me savings calculation with Sauce</span>
            </ButtonPrimary>
          </SetupActions>
        </SetupContainer>
      </SetupHolder>
      <SetupSidebar>
        <Body3>
          Just <strong className='Red'>2 small steps</strong> in order to extend
          your reach and start deliver to your customers!
        </Body3>
        <CustomProgressBar className='ProgressBar ProgressBarDesktop' />
      </SetupSidebar>
    </SetupSection>
  );
};

export default SetupRestaurant;
