import React from 'react';
import {Popover} from '@material-ui/core';
import PropTypes from 'prop-types';
import StatusChip from './StatusChip';
import IconStatusSign from '../../../../../assets/IconStatusSign';

const GetStatusChip = ({text, state, show, onClick}) =>
  show && (
    <li>
      <div onClick={onClick}>
        <StatusChip state={state}>
          <IconStatusSign />
          <span>{text}</span>
        </StatusChip>
      </div>
    </li>
  );

GetStatusChip.propTypes = {
  text: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function StatusPopover({open, onClose, anchorEl, children}) {
  return (
    <Popover
      open={children.some((child) => child.props.show) && open}
      onClose={onClose}
      className='PopoverStatus'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <ul>{children}</ul>
    </Popover>
  );
}

StatusPopover.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

StatusPopover.defaultProps = {
  open: false,
  onClose: () => {},
  anchorEl: null
};
