import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {AlignCenter} from '../style';
import ButtonMoreS2E from '../../../../components/buttons/ButtonMore/styles';
import Popper from './Popper';

export default function ButtonMore({itemsData}) {
  const [showPopper, setShowPopper] = useState(false);

  return (
    <AlignCenter>
      <ButtonMoreS2E
        className='ButtonMore'
        onClick={(e) => setShowPopper(e.currentTarget)}
      />

      {showPopper && (
        <Popper
          itemsData={itemsData}
          onClose={() => setShowPopper(false)}
          anchorEl={showPopper}
        />
      )}
    </AlignCenter>
  );
}

ButtonMore.propTypes = {
  itemsData: PropTypes.array.isRequired
};
