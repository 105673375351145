import React from 'react';
import cn from 'classnames';
import T from 'prop-types';
import {
  ProgressBar,
  ProgressBarContainer,
  ProgressStep,
  ProgressSteps
} from './styles';

const CustomProgressBar = ({className, progress, secondStepTitle}) => {
  return (
    <ProgressBarContainer className={className}>
      <ProgressBar className='ProgressBar' />
      <ProgressSteps
        className={cn('ProgressSteps', {
          firstStepDone: progress === 'firstStepDone',
          secondStepDone: progress === 'secondStepDone',
          completeSteps: progress === 'allDone'
        })}
      >
        <ProgressStep className='ProgressStep'>
          <span>General Info</span>
        </ProgressStep>
        <ProgressStep className='ProgressStep'>
          <span>{secondStepTitle || 'Start Free Trial'}</span>
        </ProgressStep>
      </ProgressSteps>
    </ProgressBarContainer>
  );
};

CustomProgressBar.propTypes = {
  className: T.string.isRequired,
  progress: T.string.isRequired,
  secondStepTitle: T.string
};
CustomProgressBar.defaultProps = {
  secondStepTitle: 'Start Free Trial'
};

export default CustomProgressBar;
