import {nanoid} from 'nanoid';

const MERCHANT = {
  ID: 'id',
  MERCHANT_NAME: 'MerchantName',
  MERCHANT_ID: 'merchantId',
  LOCATION_ID: 'locationId',
  USER_FULL_NAME: 'ownerFullName',
  USER_EMAIL: 'userEmail',
  MERCHANT_URL: 'reviewedStatus',
  SHORT_URL: 'orderType',
  S2E_URL: 'date',
  ADDRESS: 'address',
  MENU_LINK: 'menuLink',
  IMAGES_LINK: 'imagesLink',
  IT_TEAM_CONTACT: 'teamContact',
  HOST_LINK: 'hostLink',
  HOST_USERNAME: 'hostUsername',
  HOST_PASSWORD: 'hostPassword',
  WEB_CONFIG_ID: 'WixWebOrderingConfigId',
  MENU_CONFIG_ID: 'WixMenuConfigId'
};

export const SALES_PACKAGES = [
  {id: nanoid(), packageName: '$0 package', value: 0},
  {id: nanoid(), packageName: '$1 package', value: 1},
  {id: nanoid(), packageName: '$2 package', value: 2}
];

export const HIDDEN_FEATURE_FLAGS = [
  'orders',
  'store_settings',
  'analytics',
  'feedback',
  'reports',
  'discounts',
  'virtual_number',
  'my_account',
  'team',
  'refunds',
  'help',
  'sales_reports',
  'customers_reports',
  'marketing',
  'one_time_coupon',
  'future_order_reminder',
  'uber_eats',
  'order_with_google_native_ordering',
  'pause_order_with_google_restaurant',
  'refunds_reports'
];

export default MERCHANT;
