/* eslint-disable */
export const setLevelNamesByCategoryTree = (tree, afterChangeAction) => {
  const setLevelItemType = (
    item,
    section,
    level,
    sourceParent,
    position,
    isOpenChildren
  ) => {
    switch (level) {
      case 1:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'category';
        item.ChildrenType = 'item';
        item.ChildrenTypePlural = 'items';
        item.OutOfStock = {
          Items: new Set(),
          Modifiers: new Set()
        };
        item.Items.forEach((el, index) => {
          setLevelItemType(
            el,
            section,
            level + 1,
            sourceParent,
            [...position, 'Items', index],
            false
          );
        });
        break;
      case 2:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'item';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        if (item.InStock === false) {
          section.OutOfStock.Items.add(item.PosId);
        }
        if (item.Invisible === true) {
          section.OutOfStock.Items.add(item.PosId);
        }
        item.OptionsGroups.forEach((el, index) => {
          setLevelItemType(
            el,
            section,
            level + 1,
            sourceParent,
            [...position, 'OptionsGroups', index],
            false
          );
        });
        break;
      case 3:
      case 5:
      case 7:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifierGroup';
        if (Array.isArray(item.Items)) {
          item.ChildrenType = 'modifier';
          item.ChildrenTypePlural = 'modifiers';
          item.Items.forEach((el, index) => {
            setLevelItemType(
              el,
              section,
              level + 1,
              sourceParent,
              [...position, 'Items', index],
              false
            );
          });
        }
        break;
      case 4:
      case 6:
      case 8:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifier';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        if (item.InStock === false) {
          section.OutOfStock.Modifiers.add(item.PosId);
        }
        if (item.Invisible === true) {
          section.OutOfStock.Modifiers.add(item.PosId);
        }
        if (Array.isArray(item.ModifierGroups)) {
          item.ModifierGroups.forEach((el, index) => {
            setLevelItemType(
              el,
              section,
              level + 1,
              sourceParent,
              [...position, 'ModifierGroups', index],
              false
            );
          });
        }

        break;
    }
  };

  tree.forEach((section, index) => {
    setLevelItemType(
      section,
      section,
      1,
      {
        type: 'category',
        id: section.MerchantSectionId
      },
      [index],
      false
    );
  });
};

export const setLevelNamesByItemGroupTree = (tree, afterChangeAction) => {
  const setLevelItemType = (
    item,
    level,
    sourceParent,
    position,
    isOpenChildren
  ) => {
    switch (level) {
      case 1:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'item';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        item.OptionsGroups.forEach((el, index) => {
          setLevelItemType(
            el,
            level + 1,
            sourceParent,
            [...position, 'OptionsGroups', index],
            false
          );
        });
        break;
      case 2:
      case 4:
      case 6:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifierGroup';
        item.ChildrenType = 'modifier';
        item.ChildrenTypePlural = 'modifiers';
        if (Array.isArray(item.Items)) {
          item.Items.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'Items', index],
              false
            );
          });
        }
        break;
      case 3:
      case 5:
      case 7:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifier';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        if (Array.isArray(item.ModifierGroups)) {
          item.ModifierGroups.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'ModifierGroups', index],
              false
            );
          });
        }

        break;
    }
  };

  tree.forEach((item, index) => {
    setLevelItemType(
      item,
      1,
      {
        type: 'item',
        id: item.PosId
      },
      [index],
      false
    );
  });
};

export const setLevelNamesByModifierGroupTree = (tree, afterChangeAction) => {
  const setLevelItemType = (
    item,
    level,
    sourceParent,
    position,
    isOpenChildren
  ) => {
    switch (level) {
      case 1:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifierGroup';
        item.ChildrenType = 'modifier';
        item.ChildrenTypePlural = 'modifiers';
        item.Items.forEach((el, index) => {
          setLevelItemType(
            el,
            level + 1,
            sourceParent,
            [...position, 'Items', index],
            false
          );
        });
        break;
      case 2:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifier';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        item.ModifierGroups.forEach((el, index) => {
          setLevelItemType(
            el,
            level + 1,
            sourceParent,
            [...position, 'ModifierGroups', index],
            false
          );
        });
        break;
      case 3:
      case 5:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifierGroup';
        item.ChildrenType = 'modifier';
        item.ChildrenTypePlural = 'modifiers';
        if (Array.isArray(item.Items)) {
          item.Items.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'Items', index],
              false
            );
          });
        }
        break;
      case 4:
      case 6:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifier';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        if (Array.isArray(item.ModifierGroups)) {
          item.ModifierGroups.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'ModifierGroups', index],
              false
            );
          });
        }

        break;
    }
  };

  tree.forEach((modifierGroup, index) => {
    setLevelItemType(
      modifierGroup,
      1,
      {
        type: 'modifierGroup',
        id: modifierGroup.PosId
      },
      [index],
      false
    );
  });
};

export const setLevelNamesByModifierTree = (tree, afterChangeAction) => {
  const setLevelItemType = (
    item,
    level,
    sourceParent,
    position,
    isOpenChildren
  ) => {
    switch (level) {
      case 1:
      case 3:
      case 5:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifier';
        item.ChildrenType = 'modifier group';
        item.ChildrenTypePlural = 'modifier groups';
        if (Array.isArray(item.ModifierGroups)) {
          item.ModifierGroups.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'ModifierGroups', index],
              false
            );
          });
        }
        break;
      case 2:
      case 4:
        item.$position = position;
        item.$isOpenChildren = isOpenChildren;
        item.$sourceParent = sourceParent;
        item.$afterChangeAction = afterChangeAction;
        item.ElementType = 'modifierGroup';
        item.ChildrenType = 'modifier';
        item.ChildrenTypePlural = 'modifiers';
        if (Array.isArray(item.Items)) {
          item.Items.forEach((el, index) => {
            setLevelItemType(
              el,
              level + 1,
              sourceParent,
              [...position, 'Items', index],
              false
            );
          });
        }
        break;
    }
  };

  tree.forEach((modifier, index) => {
    setLevelItemType(
      modifier,
      1,
      {
        type: 'modifier',
        id: modifier.PosId
      },
      [index],
      false
    );
  });
};

export const setMenuTree = (store, treeHead) => {
  const trees = {
    activeCategoriesTree: [],
    activeItemsTree: [],
    activeGroupsTree: [],
    activeModifiersTree: []
  };

  const {
    sectionsMap,
    itemsMap,
    groupsMap,
    modifiersMap,

    sectionsDirection,
    itemsDirection,
    groupsDirection,
    modifiersDirection,

    searchValue
  } = store;

  const searchTreeScheme = {
    category: {
      childrenField: 'Items',
      searchFields: ['Name', 'Notes']
    },
    item: {
      childrenField: 'OptionsGroups',
      searchFields: ['Name', 'Description', 'Notes']
    },
    modifierGroup: {
      childrenField: 'Items',
      searchFields: ['OptionName', 'Notes', 'OptionDescription']
    },
    modifier: {
      childrenField: 'ModifierGroups',
      searchFields: ['Name', 'Description']
    }
  };

  const isObjectSearched = {
    category: new Map(),
    item: new Map(),
    modifierGroup: new Map(),
    modifier: new Map()
  };

  const checkSearched = (item, searchValue) => {
    const {$id, $store, ElementType, $children} = item;
    const {searchFields} = searchTreeScheme[ElementType];
    const source = store[$store][$id];

    let searchString = searchFields.reduce((str, field) => {
      str += ` ${source[field] || ''}`;

      return str;
    }, '');

    searchString = searchString.toLowerCase();

    if (searchString.indexOf(searchValue) !== -1) {
      item.$search = {isShow: true, isEq: true};
    } else {
      item.$search = {isShow: false, isEq: false};
    }
  };

  // const setLevelItemType = (item, section, level, sourceParent, position, isOpenChildren) => {
  const setLevelItemType = (
    id,
    type,
    path,
    level,
    position,
    isOpenChildren,
    source,
    outOffStock,
    isOnlyOneLevel = false
  ) => {
    const treeElement = {};

    switch (level) {
      case 1:
        treeElement.$id = id;
        treeElement.$store = 'sectionsMap';
        treeElement.$position = position;
        treeElement.$isOpenChildren = isOpenChildren;
        treeElement.$path = path;
        treeElement.$search = null;
        treeElement.ElementType = type;
        treeElement.ChildrenType = 'item';
        treeElement.ChildrenTypePlural = 'items';
        treeElement.$children = [];
        treeElement.OutOfStock = {
          Items: new Set(),
          Modifiers: new Set()
        };

        treeElement.$search = null;

        if (
          !isOnlyOneLevel &&
          Array.isArray(source.Items) &&
          source.Items.length
        ) {
          treeElement.$children = source.Items.map((el, index) => {
            const item = itemsMap[el];

            return setLevelItemType(
              el,
              'item',
              [{id, type, store: 'sectionsMap'}],
              level + 1,
              [...position, '$children', index],
              false,
              item,
              treeElement.OutOfStock
            );
          });
        }

        break;
      case 2:
        treeElement.$id = id;
        treeElement.$store = 'itemsMap';
        treeElement.$position = position;
        treeElement.$isOpenChildren = isOpenChildren;
        treeElement.$path = path;
        treeElement.$search = null;
        treeElement.ElementType = type;
        treeElement.ChildrenType = 'modifier group';
        treeElement.ChildrenTypePlural = 'modifier groups';
        treeElement.$children = [];

        if (outOffStock && source.InStock === false) {
          outOffStock.Items.add(id);
        }

        if (outOffStock && source.Invisible === true) {
          outOffStock.Items.add(id);
        }

        if (
          !isOnlyOneLevel &&
          Array.isArray(source.OptionsGroups) &&
          source.OptionsGroups.length
        ) {
          treeElement.$children = source.OptionsGroups.map((el, index) => {
            const modifierGroup = groupsMap[el];

            return setLevelItemType(
              el,
              'modifierGroup',
              [...path, {id, type, store: 'itemsMap'}],
              level + 1,
              [...position, '$children', index],
              false,
              modifierGroup,
              outOffStock
            );
          });
        }

        break;
      case 3:
      case 5:
      case 7:
        treeElement.$id = id;
        treeElement.$type = type;
        treeElement.$store = 'groupsMap';
        treeElement.$position = position;
        treeElement.$isOpenChildren = isOpenChildren;
        treeElement.$path = path;
        treeElement.$search = null;
        treeElement.ElementType = type;
        treeElement.ChildrenType = 'modifier';
        treeElement.ChildrenTypePlural = 'modifiers';
        treeElement.$children = [];

        if (
          !isOnlyOneLevel &&
          Array.isArray(source.Items) &&
          source.Items.length
        ) {
          treeElement.$children = source.Items.map((el, index) => {
            const modifier = modifiersMap[el];

            return setLevelItemType(
              el,
              'modifierGroup',
              [...path, {id, type, store: 'groupsMap'}],
              level + 1,
              [...position, '$children', index],
              false,
              modifier,
              outOffStock
            );
          });
        }

        break;
      case 4:
      case 6:
      case 8:
        treeElement.$id = id;
        treeElement.$type = type;
        treeElement.$store = 'modifiersMap';
        treeElement.$position = position;
        treeElement.$isOpenChildren = isOpenChildren;
        treeElement.$path = path;
        treeElement.$search = null;
        treeElement.ElementType = type;
        treeElement.ChildrenType = 'modifier group';
        treeElement.ChildrenTypePlural = 'modifier groups';
        treeElement.$children = [];

        if (outOffStock && source.InStock === false) {
          outOffStock.Items.add(id);
        }

        if (outOffStock && source.Invisible === true) {
          outOffStock.Items.add(id);
        }

        if (
          !isOnlyOneLevel &&
          Array.isArray(source.ModifierGroups) &&
          source.ModifierGroups.length
        ) {
          treeElement.$children = source.ModifierGroups.map((el, index) => {
            const modifierGroup = groupsMap[el];

            return setLevelItemType(
              el,
              'modifierGroup',
              [...path, {id, type, store: 'modifiersMap'}],
              level + 1,
              [...position, '$children', index],
              false,
              modifierGroup,
              outOffStock
            );
          });
        }
        break;
    }

    return treeElement;
  };

  if (treeHead === 'CATEGORY') {
    sectionsDirection.forEach((id, index) => {
      const section = sectionsMap[id];

      const treeHead = setLevelItemType(
        section.MerchantSectionId,
        'category',
        [],
        1,
        [index],
        false,
        section
      );

      trees.activeCategoriesTree.push(treeHead);
    });
  }

  if (treeHead === 'ITEM') {
    itemsDirection.forEach((id, index) => {
      const item = itemsMap[id];
      const treeHead = setLevelItemType(
        item.PosId,
        'item',
        [],
        2,
        [index],
        false,
        item
      );

      trees.activeItemsTree.push(treeHead);
    });
  }

  if (treeHead === 'GROUP') {
    groupsDirection.forEach((id, index) => {
      const group = groupsMap[id];
      const treeHead = setLevelItemType(
        group.PosId,
        'modifierGroup',
        [],
        3,
        [index],
        false,
        group
      );

      trees.activeGroupsTree.push(treeHead);
    });
  }

  if (treeHead === 'MODIFIER') {
    modifiersDirection.forEach((id, index) => {
      const modifier = modifiersMap[id];
      const treeHead = setLevelItemType(
        modifier.PosId,
        'modifier',
        [],
        4,
        [index],
        false,
        modifier
      );

      trees.activeModifiersTree.push(treeHead);
    });
  }

  if (treeHead === 'OUT_OFF_STOCK') {
    itemsDirection
      .filter((item) => itemsMap[item].InStock === false)
      .forEach((id, index) => {
        const item = itemsMap[id];
        const treeHead = setLevelItemType(
          item.PosId,
          'item',
          [],
          2,
          [index],
          false,
          item,
          undefined,
          true
        );

        trees.activeItemsTree.push(treeHead);
      });

    modifiersDirection
      .filter((modifier) => modifiersMap[modifier].InStock === false)
      .forEach((id, index) => {
        const modifier = modifiersMap[id];
        const treeHead = setLevelItemType(
          modifier.PosId,
          'modifier',
          [],
          4,
          [index],
          false,
          modifier,
          undefined,
          true
        );

        trees.activeModifiersTree.push(treeHead);
      });
  }

  if (treeHead === 'HIDDEN') {
    sectionsDirection
      .filter((section) => sectionsMap[section].Visible === false)
      .forEach((id, index) => {
        const section = sectionsMap[id];
        const treeHead = setLevelItemType(
          section.MerchantSectionId,
          'category',
          [],
          1,
          [index],
          false,
          section
        );

        trees.activeCategoriesTree.push(treeHead);
      });

    itemsDirection
      .filter((item) => itemsMap[item].Invisible === true)
      .forEach((id, index) => {
        const item = itemsMap[id];
        const treeHead = setLevelItemType(
          item.PosId,
          'item',
          [],
          2,
          [index],
          false,
          item
        );

        trees.activeItemsTree.push(treeHead);
      });

    modifiersDirection
      .filter((modifier) => modifiersMap[modifier].Invisible === true)
      .forEach((id, index) => {
        const modifier = modifiersMap[id];
        const treeHead = setLevelItemType(
          modifier.PosId,
          'modifier',
          [],
          4,
          [index],
          false,
          modifier
        );

        trees.activeModifiersTree.push(treeHead);
      });
  }

  return trees;
};

export const setMenuBranches = (store) => {};

const TOAST_PREFIX = 'toast_';
const ORDER_MARK_PREFIX = 'ordermark_';
const ORDER_MARK_NEW_PREFIX = 'ordermarknew_';
const UBER_EATS_PREFIX = 'ubereats_';

export const checkIsMenuForbidden = (ConfigName) => {
  if (!ConfigName) {
    return true;
  }

  const configNameFormatted = ConfigName.toLowerCase();

  if (configNameFormatted.indexOf(TOAST_PREFIX) === 0) {
    return true;
  }

  if (configNameFormatted.indexOf(ORDER_MARK_PREFIX) === 0) {
    return true;
  }

  if (configNameFormatted.indexOf(ORDER_MARK_NEW_PREFIX) === 0) {
    return true;
  }

  return configNameFormatted.indexOf(UBER_EATS_PREFIX) === 0;
};
