import React, {useState} from 'react';
import T from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import {ButtonMore} from '../../../../../components/buttons';

// eslint-disable-next-line react/prop-types
const PopperActions = ({onDelete, onEdit}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  return (
    <>
      <ButtonMore className='ButtonMore' onClick={handleClickOpenMenu} />
      <Popper
        open={openMenu}
        anchorEl={anchorEl}
        placement='bottom-end'
        disablePortal
        className='MainDropDown'
        transition
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className='popper'>
                <MenuItem onClick={onEdit}>Edit</MenuItem>
                <MenuItem onClick={onDelete}>Delete</MenuItem>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

PopperActions.propTypes = {
  onDelete: T.func,
  onEdit: T.func
};

PopperActions.defaultProps = {
  onDelete: () => {},
  onEdit: () => {}
};

export default PopperActions;
