import {createAction} from 'redux-act';

export const GET_REVIEW = createAction('reviews/GET_REVIEW');
export const GET_REVIEW_SUCCESS = createAction('reviews/GET_REVIEW_SUCCESS');
export const GET_REVIEW_FAIL = createAction('reviews/GET_REVIEW_FAIL');
export const GET_REPLIES_SUCCESS = createAction('reviews/GET_REPLIES_SUCCESS');
export const GET_REPLIES_FAIL = createAction('reviews/GET_REPLIES_FAIL');

export const CREATE_REPLY = createAction('reviews/CREATE_REPLY');
export const CREATE_REPLY_SUCCESS = createAction(
  'reviews/CREATE_REPLY_SUCCESS'
);
export const CREATE_REPLY_FAIL = createAction('reviews/CREATE_REPLY_FAIL');

export const GET_REVIEWS = createAction('reviews/GET_REVIEWS');
export const GET_REVIEWS_SUCCESS = createAction('reviews/GET_REVIEWS_SUCCESS');
export const GET_REVIEWS_FAIL = createAction('reviews/GET_REVIEWS_FAIL');
