import React from 'react';

const IconPickupSurvey = () => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.2216 2.78083C12.5171 -0.923688 6.48749 -0.923688 2.78297 2.78083C0.988492 4.57601 0 6.96228 0 9.50014C0 12.038 0.988492 14.4243 2.78297 16.2187C4.6356 18.0714 7.06896 18.9973 9.50228 18.9973C11.9356 18.9973 14.369 18.0714 16.2216 16.2187C19.9261 12.5142 19.9261 6.48605 16.2216 2.78083ZM15.1809 15.178C12.0497 18.3091 6.95485 18.3091 3.82371 15.178C2.30748 13.6617 1.47207 11.645 1.47207 9.50014C1.47207 7.35531 2.30748 5.33854 3.82371 3.82157C6.95485 0.690437 12.0497 0.691179 15.1809 3.82157C18.3112 6.95271 18.3112 12.0476 15.1809 15.178Z'
      fill='#fff'
    />
    <path
      d='M12.6739 11.5348L10.5901 9.45398L12.6739 7.3732C12.9609 7.08616 12.9609 6.62024 12.6746 6.33242C12.3868 6.04389 11.9209 6.04464 11.6331 6.33168L9.54793 8.41394L7.46273 6.33168C7.17494 6.04464 6.70903 6.04389 6.42125 6.33242C6.1342 6.62021 6.1342 7.08612 6.42199 7.3732L8.50574 9.45398L6.42199 11.5348C6.1342 11.8218 6.1342 12.2877 6.42125 12.5755C6.56479 12.7198 6.75393 12.7912 6.94238 12.7912C7.13082 12.7912 7.31923 12.719 7.46277 12.5762L9.54796 10.494L11.6332 12.5762C11.7767 12.7198 11.9651 12.7912 12.1535 12.7912C12.342 12.7912 12.5311 12.719 12.6747 12.5755C12.9616 12.2877 12.9616 11.8218 12.6739 11.5348Z'
      fill='#fff'
    />
  </svg>
);

export default IconPickupSurvey;
