import styled from 'styled-components';

export const DashboardContent = styled.div`
  display: block;
  padding: 110px 100px 140px 372px;
  overflow-x: hidden;

  @media (max-width: 1650px) {
    padding: 110px 48px 140px 320px;
  }
  @media (max-width: 1440px) {
    padding: 110px 38px 140px 310px;
  }
  @media (max-width: 1250px) {
    padding: 120px 80px 50px 80px;
  }

  @media (max-width: 1200px) {
    padding: 96px 60px 140px 60px;
  }
  @media (max-width: 1100px) {
    padding: 96px 50px 220px 50px;
  }
  @media (max-width: 1000px) {
    padding: 96px 40px 220px 40px;
  }
  @media (max-width: 767px) {
    padding: 70px 30px 220px 30px;
  }

  @media (max-width: 599px) {
    padding: 101px 20px 220px 20px;
  }

  &.TabletPTop {
    @media (max-width: 1200px) {
      padding-top: 126px;
    }
    @media (max-width: 767px) {
      padding-top: 104px;
    }
  }

  .HeadlineDesktop {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  &.WithoutHeader {
    padding-top: 48px !important;
  }

  &.DashboardMenuEditor {
    @media (max-width: 599px) {
      overflow-x: auto;
    }

    & > div {
      @media (max-width: 599px) {
        min-width: 600px;
      }
    }
  }

  &.disabledSurvey {
    margin-top: 60px;
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  &.overflowInherit {
    overflow: inherit;
  }
  &.BgGrey {
    background-color: #f7f7fa;
  }

  &.WithoutDesktopHeader {
    padding-top: 80px;
    @media (max-width: 1300px) {
      padding-right: 20px;
      padding-left: 292px;
    }
    @media (max-width: 1279px) {
      padding-right: 15px;
      padding-left: 287px;
    }

    @media (max-width: 1250px) {
      padding: 120px 80px 50px 80px;
    }

    @media (max-width: 1200px) {
      padding: 96px 60px 140px 60px;
    }
    @media (max-width: 1100px) {
      padding: 96px 50px 220px 50px;
    }
    @media (max-width: 1000px) {
      padding: 96px 40px 220px 40px;
    }
    @media (max-width: 767px) {
      padding: 56px 30px 220px 30px;
    }
  }

  &.height100 {
    min-height: 100vh;
  }
  &.centerSpinner {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export default DashboardContent;
