import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import T from 'prop-types';
import {Radio} from '@material-ui/core';
import RadioContainer from './styles';

const RadioS2E = ({
  value,
  label,
  checked,
  name,
  onChange,
  type,
  onClick,
  className,
  disabled
}) => {
  return (
    <RadioContainer className={className}>
      <FormControlLabel
        className='Say2EatRadio'
        control={
          <Radio
            type={type || 'radio'}
            checked={checked}
            value={value}
            onClick={onClick}
            onChange={onChange}
            name={name}
            disabled={disabled}
            labelStyle={{color: '#172751'}}
            iconStyle={{fill: '#FF2D38'}}
            inputStyle={{color: '#FF2D38'}}
            style={{color: '#FF2D38'}}
          />
        }
        label={label}
      />
    </RadioContainer>
  );
};

RadioS2E.propTypes = {
  className: T.bool,
  value: T.string,
  type: T.string,
  label: T.string,
  name: T.string,
  checked: T.bool,
  disabled: T.bool,
  onChange: T.func,
  onClick: T.func
};

RadioS2E.defaultProps = {
  className: '',
  label: '',
  type: '',
  value: '',
  name: '',
  checked: false,
  disabled: false,
  onChange: () => {},
  onClick: () => {}
};

export default RadioS2E;
