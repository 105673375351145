import styled from 'styled-components';

export const WelcomeImage = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto;

  &.mBottom40 {
    margin-bottom: 40px;
  }
`;

export default WelcomeImage;
