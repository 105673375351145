import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch} from 'react-redux';
import {isEqual} from 'lodash';
import {FlexContainer, FlexItem} from 'components/layout';
import {REFUND} from 'helpers/constants/Refund';
import {IconCirclePlus} from 'assets/IconCirclePlus';
import {IconCircleMinus} from 'assets/IconCircleMinus';
import {
  RefundTableEl,
  RefundTableHeader,
  RefundTableWrapper
} from 'routes/App/orders/OrderDetails/Refund/RefundTable/styles';
import {IconArrowDown} from 'assets/IconArrowDown';
import CheckboxS2E from 'components/Checkbox';
import {
  CHANGE_QUANTITY_FOR_REFUND,
  CHECK_MODIFIER_FOR_REFUND,
  SELECT_ADJUST_FOR_REFUND,
  SELECT_TIPS_FOR_REFUND
} from 'store/orders/orderRefund/action';

const RefundTable = ({
  setAmountError,
  values,
  orderRefundData,
  orderPriceAdjustments,
  priceAdjustmentIds,
  orderTips,
  tipForRefund
}) => {
  const dispatch = useDispatch();

  const checkDisablingButton = (el, currentItem, path) => {
    const parentPath = [...path];
    parentPath.pop();
    const parentItem = el.find((i) => isEqual(parentPath, i.path));
    return currentItem.selected <= parentItem.selected && parentItem.checked;
  };
  const checkDisablingCheckbox = (el, currentItem, path) => {
    const parentPath = [...path];
    parentPath.pop();
    const parentItem = el.find((i) => isEqual(parentPath, i.path));
    return parentItem.checked;
  };

  return (
    <RefundTableWrapper
      style={{display: values.refundType === 'Full' ? 'none' : 'block'}}
      className='OrdersTableWrapper'
    >
      {((Array.isArray(orderRefundData) && orderRefundData?.length > 0) ||
        orderTips > 0 ||
        (Array.isArray(orderPriceAdjustments) &&
          orderPriceAdjustments?.length > 0)) && (
        <>
          <RefundTableHeader>
            <div className='RefundItemName'>Refund item</div>
            <div className='RefundItemCount'>Quantity</div>
            <div className='RefundItemPrice'>Amount</div>
          </RefundTableHeader>
          {orderRefundData.map((el, index) => (
            <RefundTableEl defaultExpanded>
              {el.map((item) => (
                <>
                  {item.path?.length === 1 && (
                    <AccordionSummary
                      expandIcon={<IconArrowDown />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                      className={cn('RefundAccordionSummary', {
                        disabled: el.length === 1
                      })}
                    >
                      <>
                        <FlexContainer
                          modifierLevel={item.path?.length}
                          className='RefundItemName'
                        >
                          <FormControl
                            className={cn({
                              modifier: item.path.length > 1
                            })}
                          >
                            <CheckboxS2E
                              checked={item.checked}
                              onChange={(e) => {
                                e.stopPropagation();
                                if (e.target.checked) {
                                  setAmountError(false);
                                }
                                dispatch(
                                  CHECK_MODIFIER_FOR_REFUND({
                                    path: item.path,
                                    checked: e.target.checked
                                  })
                                );
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              name='item'
                              label={
                                <>
                                  <span className='titleBold'>
                                    {item[REFUND.QUANTITY]}X
                                  </span>
                                  <span>{item[REFUND.NAME]}</span>
                                </>
                              }
                            />
                          </FormControl>
                        </FlexContainer>
                        {/* <FlexContainer className='TableCenter RefundItemName'>
                          <FlexItem className='bold'>
                            {item[REFUND.QUANTITY]}X
                          </FlexItem>
                          <FlexItem>{item[REFUND.NAME]}</FlexItem>
                        </FlexContainer> */}
                        {item.checked && (
                          <>
                            <FlexContainer className='ColumnCenter RefundItemCount'>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    CHANGE_QUANTITY_FOR_REFUND({
                                      path: item.path,
                                      value: item[REFUND.REFUND_QUANTITY] - 1
                                    })
                                  );
                                }}
                                className={
                                  item[REFUND.REFUND_QUANTITY] === 1
                                    ? 'minusButton disabledCounter'
                                    : 'minusButton activeCounter'
                                }
                              >
                                <IconCircleMinus />
                              </div>
                              <FlexItem className='quantityCounter'>
                                {item[REFUND.REFUND_QUANTITY] || 0}
                              </FlexItem>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setAmountError(false);
                                  dispatch(
                                    CHANGE_QUANTITY_FOR_REFUND({
                                      path: item.path,
                                      value: item[REFUND.REFUND_QUANTITY] + 1
                                    })
                                  );
                                }}
                                className={
                                  item[REFUND.REFUND_QUANTITY] ===
                                  item[REFUND.QUANTITY]
                                    ? 'plusButton disabledCounter'
                                    : 'plusButton activeCounter'
                                }
                              >
                                <IconCirclePlus />
                              </div>
                            </FlexContainer>
                            <FlexContainer className='ColumnEnd RefundItemPrice'>
                              <FlexItem>
                                $
                                {item[REFUND.REFUND_QUANTITY]
                                  ? (
                                      item[REFUND.REFUND_QUANTITY] *
                                      Number(item[REFUND.PRICE_WITH_TAX])
                                    ).toFixed(2)
                                  : 0}
                              </FlexItem>
                            </FlexContainer>
                          </>
                        )}
                      </>
                    </AccordionSummary>
                  )}
                  {item.path?.length > 1 && (
                    <AccordionDetails>
                      <>
                        <FlexContainer
                          modifierLevel={item.path?.length}
                          className='RefundItemName'
                        >
                          <FormControl
                            className={cn({
                              modifier: item.path.length > 1
                            })}
                          >
                            <CheckboxS2E
                              checked={item.checked}
                              onChange={(e) => {
                                e.stopPropagation();
                                if (e.target.checked) {
                                  setAmountError(false);
                                }
                                dispatch(
                                  CHECK_MODIFIER_FOR_REFUND({
                                    path: item.path,
                                    checked: e.target.checked
                                  })
                                );
                              }}
                              name='item'
                              label={
                                <>
                                  <span className='titleBold'>
                                    {item[REFUND.QUANTITY]}X
                                  </span>
                                  <span>{item[REFUND.NAME]}</span>
                                </>
                              }
                              disabled={checkDisablingCheckbox(
                                el,
                                item,
                                item.path
                              )}
                            />
                          </FormControl>
                        </FlexContainer>
                        <FlexContainer className='ColumnCenter RefundItemCount'>
                          {item.checked && (
                            <>
                              <div
                                onClick={() => {
                                  dispatch(
                                    CHANGE_QUANTITY_FOR_REFUND({
                                      path: item.path,
                                      value: item[REFUND.REFUND_QUANTITY] - 1
                                    })
                                  );
                                }}
                                className={
                                  item[REFUND.REFUND_QUANTITY] === 1 ||
                                  checkDisablingButton(el, item, item.path)
                                    ? 'minusButton disabledCounter'
                                    : 'minusButton activeCounter'
                                }
                              >
                                <IconCircleMinus />
                              </div>
                              <FlexItem className='quantityCounter'>
                                {item[REFUND.REFUND_QUANTITY] || 0}
                              </FlexItem>
                              <div
                                onClick={() => {
                                  setAmountError(false);
                                  dispatch(
                                    CHANGE_QUANTITY_FOR_REFUND({
                                      path: item.path,
                                      value: item[REFUND.REFUND_QUANTITY] + 1
                                    })
                                  );
                                }}
                                className={
                                  item[REFUND.REFUND_QUANTITY] ===
                                  item[REFUND.QUANTITY]
                                    ? 'plusButton disabledCounter'
                                    : 'plusButton activeCounter'
                                }
                              >
                                <IconCirclePlus />
                              </div>
                            </>
                          )}
                        </FlexContainer>
                        <FlexContainer className='ColumnEnd RefundItemPrice'>
                          {item.checked && (
                            <FlexItem>
                              $
                              {item[REFUND.REFUND_QUANTITY]
                                ? (
                                    item[REFUND.REFUND_QUANTITY] *
                                    Number(item[REFUND.PRICE_WITH_TAX])
                                  ).toFixed(2)
                                : 0}
                            </FlexItem>
                          )}
                        </FlexContainer>
                      </>
                    </AccordionDetails>
                  )}
                </>
              ))}
            </RefundTableEl>
          ))}
          {orderTips > 0 && (
            <RefundTableEl defaultExpanded>
              <AccordionSummary
                expandIcon={<IconArrowDown />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={cn('RefundAccordionSummary disabled', {})}
              >
                <>
                  <FlexContainer className='RefundItemName'>
                    <FormControl>
                      <CheckboxS2E
                        checked={tipForRefund}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.checked) {
                            setAmountError(false);
                          }
                          dispatch(SELECT_TIPS_FOR_REFUND(e.target.checked));
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        name='item'
                        label={
                          <>
                            <span>Tips</span>
                          </>
                        }
                      />
                    </FormControl>
                  </FlexContainer>
                  {tipForRefund && (
                    <FlexContainer className='ColumnEnd RefundItemPrice'>
                      <FlexItem>${orderTips}</FlexItem>
                    </FlexContainer>
                  )}
                </>
              </AccordionSummary>
            </RefundTableEl>
          )}
          {orderPriceAdjustments?.map((item) => (
            <RefundTableEl defaultExpanded>
              <AccordionSummary
                expandIcon={<IconArrowDown />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                className={cn('RefundAccordionSummary disabled', {})}
              >
                <>
                  <FlexContainer className='RefundItemName'>
                    <FormControl>
                      <CheckboxS2E
                        checked={priceAdjustmentIds.includes(
                          item.priceAdjustmentId
                        )}
                        onChange={(e) => {
                          e.stopPropagation();
                          if (e.target.checked) {
                            setAmountError(false);
                          }
                          dispatch(
                            SELECT_ADJUST_FOR_REFUND(item.priceAdjustmentId)
                          );
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        name='item'
                        label={
                          <>
                            <span>Adjustment: {item?.reason}</span>
                          </>
                        }
                      />
                    </FormControl>
                  </FlexContainer>
                  {priceAdjustmentIds.includes(item.priceAdjustmentId) && (
                    <FlexContainer className='ColumnEnd RefundItemPrice'>
                      <FlexItem>${item.totalAmount}</FlexItem>
                    </FlexContainer>
                  )}
                </>
              </AccordionSummary>
            </RefundTableEl>
          ))}
        </>
      )}
    </RefundTableWrapper>
  );
};

RefundTable.propTypes = {
  setAmountError: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  orderRefundData: PropTypes.array.isRequired,
  orderPriceAdjustments: PropTypes.array.isRequired,
  priceAdjustmentIds: PropTypes.array.isRequired,
  orderTips: PropTypes.number.isRequired,
  tipForRefund: PropTypes.bool.isRequired
};

export default RefundTable;
