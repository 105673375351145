import React from 'react';

export const IconCategory = () => (
  <svg
    width='24'
    height='27'
    viewBox='0 0 24 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M23.1484 6.57606V19.5622L11.9998 26.0552L0.851147 19.5622V6.57606L11.9998 0.0830078L23.1484 6.57606ZM3.96392 7.17972L11.9998 11.8567L20.0357 7.17972L11.9998 2.50269L3.96392 7.17972ZM21.0716 8.99574L13.0357 13.6728V23.0319L21.0716 18.3549V8.99574ZM10.9639 23.0319V13.6728L2.928 8.99574V18.3549L10.9639 23.0319Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconCategory;
