import styled from 'styled-components';
import IconCalendarXl from '../../../assets/icon-calendar-xl.svg';

export const SettingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrapper = styled.div`
  margin-bottom: 20px;
  padding: 18px 29px 38px 29px;
  position: relative;
  overflow-x: hidden;
  background-color: #fff;
  border: 1px solid rgba(146, 156, 185, 0.5);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 670px;
  width: 100%;
  @media (max-width: 1023px) {
    padding: 25px 20px;
  }
  @media (max-width: 767px) {
    padding: 25px 15px;
  }
  &.w100 {
    max-width: 100%;
  }
  &.mBottom0 {
    margin-bottom: 0;
  }
  &.smPaddings {
    padding: 25px 30px;
  }
`;

export const LocationFormActions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -80px;
  right: -30px;
  @media (max-width: 1250px) {
    top: -77px;
  }
  @media (max-width: 1024px) {
    right: -24px;
  }
  @media (max-width: 767px) {
    top: -74px;
    right: -15px;
  }

  &.PublishOverlay {
    z-index: 13;

    body {
      overflow: hidden !important;
    }
  }

  .LocationFormSave {
    position: relative;
  }

  .ButtonSave {
    width: 160px;
    padding: 10px 5px;
    overflow: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    svg {
      width: 26px;
      height: 26px;
      z-index: 7;
      flex: 0 0 auto;
      @media (max-width: 599px) {
        width: 24px;
        height: 24px;
      }
    }
    span {
      padding-left: 10px;
      @media (max-width: 599px) {
        display: none;
      }
    }
    @media (max-width: 599px) {
      width: 44px;
      height: 44px;
      padding: 9px 5px;
    }
  }
`;

export const StoreDisabledBanner = styled.div`
  background-color: #fff;
  border: 1px solid rgba(146, 156, 185, 0.5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 40px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: #2f3d48;
  max-width: 1350px;
  margin: 32px auto;
  position: relative;

  .StoreDisabledBannerRside {
    .bottomText {
      margin-top: 4px;
      @media (max-width: 767px) {
        font-size: 13px;
      }
      @media (max-width: 599px) {
        font-size: 12.5px;
        line-height: 1.1;
      }
      .LinkButton {
        @media (max-width: 767px) {
          font-size: 13px;
        }
        @media (max-width: 599px) {
          font-size: 12.5px;
        }
      }
    }
  }

  @media (max-width: 1700px) {
    font-size: 18px;
    padding: 34px 20px;
    max-width: 1080px;
  }
  @media (max-width: 1500px) {
    font-size: 17px;
    padding: 30px 18px;
    max-width: 1000px;
  }
  @media (max-width: 1400px) {
    font-size: 16px;
    padding: 28px 18px;
    max-width: 940px;
  }
  @media (max-width: 1360px) {
    padding: 24px 14px;
    max-width: 880px;
    font-size: 15px;
  }
  @media (max-width: 767px) {
    padding: 24px 18px;
    align-items: flex-start;
  }
  @media (max-width: 599px) {
    font-size: 14px;
  }
  @media (max-width: 440px) {
    font-size: 13px;
    padding: 20px 15px;
    align-items: flex-start;
  }

  &::before {
    display: inline-block;
    flex: 0 0 auto;
    margin-right: 12px;
    content: '';
    width: 48px;
    height: 48px;
    background-image: url(${IconCalendarXl});
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 1599px) {
      width: 40px;
      height: 40px;
    }
    @media (max-width: 1439px) {
      width: 36px;
      height: 36px;
    }
    @media (max-width: 1400px) {
      width: 34px;
      height: 34px;
    }
    @media (max-width: 1360px) {
      width: 30px;
      height: 30px;
      margin-right: 7px;
    }
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
    @media (max-width: 599px) {
      width: 26px;
      height: 26px;
    }
    @media (max-width: 440px) {
      width: 22px;
      height: 22px;
    }
  }
`;

export const DatePickerString = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  margin: 20px 0;
  @media (max-width: 550px) {
    display: block;
    padding: 0;
    margin: 20px 0 0 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .DatePickerInner {
    flex: 0 0 auto;
    width: 100%;
    max-width: 272px;
    padding-left: 22px;

    @media (max-width: 650px) {
      width: 50%;
    }
    @media (max-width: 550px) {
      width: 100%;
    }
    .MuiFormControl-root {
      max-width: 150px;
      margin-top: 10px;
    }
  }
`;
