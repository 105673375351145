import styled from 'styled-components';

export const EmailMarketingSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  flex: 0 0 auto;
  span {
    color: #172751;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
export const EmailMarketingImage = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 35px auto;
`;

export const EmailMarketingBodyTitle = styled.div`
  display: block;
  margin: 0 auto 33px auto;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 800px;
  color: #172751;

  @media (max-width: 599px) {
    margin-bottom: 17px;
  }

  span {
    color: #ff2d38;
  }
`;

export const EmailMarketingButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
`;

export const EmailMarketingFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const EmailMarketingFooterBlock = styled.div`
  width: 33.333333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: 30px;
    width: 50%;
  }

  @media (max-width: 599px) {
    width: 100%;
    padding: 10px 0;
  }

  &.WithBorders {
    border-left: 1px solid #dfe2ea;
    border-right: 1px solid #dfe2ea;

    @media (max-width: 767px) {
      border-right: 0;
    }

    @media (max-width: 599px) {
      border-left: 0;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
  }
  .FooterBlockTitle {
    color: #2f3d48;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 10px 0 10px 0;
  }
  .FooterBlockText {
    color: #172751;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const EmailMarketingNote = styled.div`
  color: #929cb9;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #ff2d38;
  }

  &.mTop23 {
    margin-top: 23px;
  }
`;

export const ResultTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #2f3d48;
  margin-bottom: 17px;
  margin-top: 34px;

  &.MobileTitle {
    display: none;

    @media (max-width: 535px) {
      display: block;
    }
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  margin: 0 -11px;
  flex-wrap: wrap;
`;

export const ResultBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 11px;
  // margin: 0 -21px;
  @media (max-width: 535px) {
    width: 100%;
  }
`;

export const SalesDrivenContainer = styled.div`
  display: flex;
  margin: -11px;
  @media (max-width: 535px) {
    flex-direction: column;
  }
`;

export const SalesDrivenBlock = styled.div`
  width: calc(100% - 22px);
  background-color: rgb(23, 201, 129, 0.1);
  box-shadow: 0 8px 16px rgba(221, 221, 221, 0.32);
  border-radius: 10px;
  padding: 0 21px;
  margin: 11px;
  @media (max-width: 535px) {
    padding-top: 20px;
  }
`;

export const EmailMarketingCarsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -13px;
`;

export const EmailMarketingCard = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 13px;
  padding: 0 21px;
  width: calc(50% - 26px);
  flex: 0 0 auto;
  justify-content: space-between;
  flex-direction: row;
  position: relative;

  @media (max-width: 1005px) {
    width: calc(100% - 26px);
  }
  @media (max-width: 535px) {
    padding-top: 20px;
  }
`;
export const EmailMarketingCardInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

export const EmailMarketingCardNameContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 63px;
  .cardIcon {
    width: 28px;
    height: 28px;
    margin-right: 18px;

    &.isBigIcon {
      width: 38px;
      height: 38px;
    }
  }
  @media (max-width: 535px) {
    min-height: inherit;
  }
`;

export const EmailMarketingCardName = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
  &.green {
    color: #17c981;
  }
`;

export const EmailMarketingCardValue = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 42px;
  font-weight: 275;
  line-height: 63px;
  text-align: left;

  &.green {
    color: #058953;
  }
`;

export const EmailMarketingSpinner = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
`;

export default EmailMarketingImage;
