import React from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import Subtitle14px from 'components/typography/Subtitle14px';
import {FlexContainer, FlexItem} from 'components/layout';
import AutocompleteField from 'components/AutocompleteField/AutocompleteField';
import FieldInput from 'routes/App/merchants/ActiveUsers/components/FieldInput';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {ButtonOutline} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';

const EditSudoUserModal = ({
  currentSudoUser,
  onClose,
  allSudoUserFeatureFlags,
  allowedFeatures,
  updateUser
}) => {
  const formik = useFormik({
    initialValues: currentSudoUser,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateUser(values.id, {
        first_name: values.first_name,
        last_name: values.last_name,
        AllowedFeatures: values.AllowedFeatures
      });
    }
  });

  return (
    <Dialog open scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='marginBottom2l'>
          Editing {currentSudoUser.first_name} {currentSudoUser.last_name}
        </DialogTitle>
        <DialogBody>
          <Subtitle14px className='mT19'>First name</Subtitle14px>
          <FlexContainer
            justifyContent='space-between'
            className='align-center'
          >
            <FlexItem flex={1}>
              <FieldInput
                name='first_name'
                value={formik.values.first_name}
                onChange={formik.handleChange}
              />
            </FlexItem>
          </FlexContainer>
          <Subtitle14px className='mT19'>Last name</Subtitle14px>
          <FlexContainer
            justifyContent='space-between'
            className='align-center'
          >
            <FlexItem flex={1}>
              <FieldInput
                name='last_name'
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </FlexItem>
          </FlexContainer>
          <Subtitle14px className='mB9 mT19'>
            Allowed feature flags
          </Subtitle14px>
          <FlexContainer
            justifyContent='space-between'
            className='align-center'
          >
            <FlexItem flex={1}>
              <AutocompleteField
                multiple
                disableCloseOnSelect
                value={formik.values.AllowedFeatures}
                options={
                  allowedFeatures.includes('user_management')
                    ? allSudoUserFeatureFlags
                    : allSudoUserFeatureFlags.filter(
                        (el) => el !== 'user_management'
                      )
                }
                optionLabelName='id'
                name='AllowedFeatures'
                onChange={(e, value) => {
                  formik.setFieldValue('AllowedFeatures', value);
                }}
              />
            </FlexItem>
          </FlexContainer>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w40'>
              <ButtonOutline type='button' onClick={onClose}>
                <span>Close</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w40'>
              <ButtonPrimary type='button' onClick={formik.submitForm}>
                <span>Save</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EditSudoUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentSudoUser: PropTypes.object.isRequired,
  allSudoUserFeatureFlags: PropTypes.array.isRequired,
  allowedFeatures: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default EditSudoUserModal;
