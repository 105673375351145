import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {ButtonOutline} from '../../../../components/buttons';
import ConfirmModal from '../Modals/ConfirmModal';
import {BULK_UPDATE} from '../../../../store/menuEditor/action';

function OutOffStockButton(props) {
  const dispatch = useDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const {disabled, activeTab, selectedItems, selectedModifiers} = props;

  return (
    <>
      <ButtonOutline
        className='ButtonSmall Orange OutOfStock'
        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
      >
        <div className='OutOfStockNumber'>86</div>
        Out of stock
      </ButtonOutline>
      <ConfirmModal
        isOpen={isConfirmOpen}
        isLoading={isConfirmLoading}
        message='Are you sure you want to change the selected elements?'
        onOk={() => {
          const payload = {
            preCallback: () => setIsConfirmLoading(true),
            postCallback: () => {
              setIsConfirmLoading(false);
              setIsConfirmOpen(false);
            }
          };

          if (selectedItems) {
            payload.items = {
              ids: Object.keys(selectedItems),
              data: {
                InStock: false,
                OutOfStockToNext: false
              }
            };
          }

          if (selectedModifiers) {
            payload.modifiers = {
              ids: Object.keys(selectedModifiers),
              data: {
                InStock: false,
                OutOfStockToNext: false
              }
            };
          }

          dispatch(BULK_UPDATE(payload));
        }}
        onCancel={() => {
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}

OutOffStockButton.propTypes = {
  disabled: PropTypes.bool,
  activeTab: PropTypes.string,
  selectedItems: PropTypes.object,
  selectedModifiers: PropTypes.object
};

OutOffStockButton.defaultProps = {
  disabled: false,
  activeTab: null,
  selectedItems: null,
  selectedModifiers: null
};

export default OutOffStockButton;
