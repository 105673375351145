import {createReducer} from 'redux-act';
import {
  CLEAR_FEEDBACK_DATA,
  GET_FEEDBACK_DATA_SUCCESS,
  SET_FILTER,
  SET_LOADING
} from './action';

const initialFilters = () => ({
  startDate: null,
  endDate: null,
  datePickerSelectIndex: 0,
  tabIndex: 0
});

const initialState = () => ({
  data: null,
  loading: false,
  filters: initialFilters()
});

const feedbackReducer = createReducer(
  {
    [GET_FEEDBACK_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload.data ? payload.data : {}
    }),
    [SET_LOADING]: (state, payload) => ({
      ...state,
      loading: payload
    }),
    [CLEAR_FEEDBACK_DATA]: () => ({
      ...initialState()
    }),
    [SET_FILTER]: (state, payload) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        }
      };
    }
  },
  initialState()
);

export default feedbackReducer;
