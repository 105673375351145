/* eslint-disable no-unused-vars */
import React, {useState, useEffect, memo} from 'react';
import {useDispatch} from 'react-redux';
import LocationForm from './LocationForm/LocationForm';
import {locationId} from '../../../store/locationSettings/selectors';
import {GET_LOCATION_SETTINGS_DATA_BY_ID} from '../../../store/locationSettings/action';
import Headline from '../../../components/Headline/styles';
import {ButtonPrimary} from '../../../components/buttons';
import IconAdd from '../../../assets/IconAdd';
import {DaysPickerContextProvider} from './context/DaysPickerContext';
import {DashboardHeader} from '../../../components/DashboardHeader/styles';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import LocationPublishButton from './LocationPublishButton';

const Settings = () => {
  const dispatch = useDispatch();
  const locId = locationId();
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    dispatch(GET_LOCATION_SETTINGS_DATA_BY_ID());
  }, [dispatch, locId]);

  return (
    <DaysPickerContextProvider>
      <DashboardContent className='DashboardContent'>
        <DashboardHeader>
          <Headline className='mTop5'>Store Settings</Headline>
          {/* <ButtonPrimary
              className='ButtonAdd'
              type='button'
              onClick={() =>
                  !isCreating ? setIsCreating(true) : setIsCreating(false)
              }
          >
            <IconAdd />
            {!isCreating ? <span>Add new</span> : <span>Go Back</span>}
          </ButtonPrimary> */}
        </DashboardHeader>
        <DashboardInner>
          <LocationForm isCreating={isCreating} setIsCreating={setIsCreating} />
        </DashboardInner>
      </DashboardContent>
    </DaysPickerContextProvider>
  );
};

export default memo(Settings);
