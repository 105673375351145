import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useFormik, Formik, Form} from 'formik';
import {RadioGroup} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import DialogFields from '../../../../../components/DialogFields/styles';
import DialogField from '../../../../../components/DialogField/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import CheckboxS2E from '../../../../../components/Checkbox';
import DialogActions from '../../../../../components/DialogActions/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline} from '../../../../../components/buttons';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import {FlexContainer} from '../../../../../components/layout';
import {
  OPEN_ACTIONS_MADE_MODAL,
  SEND_ORDER_ACTIONS
} from '../../../../../store/realTimeBoard/action';
import RealDeliveryBoardActionsMadeSchema from '../../../../../helpers/services/formValidationSchemas/RealDeliveryBoardActionsMadeSchema';
import DialogClose from '../../../../../components/DialogClose/styles';
import FieldTextarea from '../../../../../components/FieldTextarea';

export default function ActionsMadeModal() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [otherFieldName, setOtherFieldName] = useState('Other');
  const [other, setOther] = useState(false);
  const {openActionsModal, currentOrderId} = useSelector(
    (state) => state.realTimeBoardReducer
  );
  const {userData} = useSelector((state) => state.user);

  const initial = {
    'Booked a driver': false,
    deliveryId: '',
    'Called the consumer': false,
    'Called the restaurant': false,
    'Called the courier': false,
    Cancelled: false,
    other: ''
  };

  const closeDialog = () => {
    dispatch(OPEN_ACTIONS_MADE_MODAL({open: false}));
  };

  const generateActionObject = (actionName, deliveryId) => {
    const result = {
      created: moment().utc().format(),
      userId: userData?.id,
      name: actionName
    };
    return deliveryId
      ? {
          ...result,
          deliveryId
        }
      : result;
  };

  const generateFieldName = (field) => {};
  return (
    <Formik
      initialValues={initial}
      innerRef={formikRef}
      validationSchema={RealDeliveryBoardActionsMadeSchema}
      onSubmit={(values) => {
        const body = [];
        Object.keys(values).forEach((key) => {
          if (values[key]) {
            switch (key) {
              case 'Booked a driver':
                body.push(generateActionObject(key, values.deliveryId));
                break;
              case 'deliveryId':
                break;
              case 'other':
                body.push(generateActionObject(values[key]));
                break;
              default:
                body.push(generateActionObject(key));
            }
          }
        });
        dispatch(
          SEND_ORDER_ACTIONS({data: {actions: body}, id: currentOrderId})
        );
        closeDialog();
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        submitForm,
        touched,
        setFieldError,
        setFieldTouched
      }) => {
        return (
          <Dialog open={openActionsModal} onClose={closeDialog} scroll='body'>
            <DialogContainer className='DialogSm'>
              <DialogTitle className='marginBottomLg'>Actions made</DialogTitle>
              <DialogClose onClick={closeDialog} />
              <DialogBody className='wAuto'>
                <DialogFields>
                  <DialogField className='dFlex w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('Booked a driver', e.target.checked);
                          setFieldError('deliveryId', null);
                          setFieldTouched('deliveryId', false);
                        }}
                        name='Booked a driver'
                        checked={values?.['Booked a driver']}
                        label='Booked a driver'
                      />
                    </FormControl>
                    {values?.['Booked a driver'] && (
                      <FormControl>
                        <FieldLabel>BaD Sauce Delivery ID</FieldLabel>
                        <FieldInput
                          className={cn('wAuto', {
                            error:
                              errors.deliveryId && touched.deliveryId && 'error'
                          })}
                          type='text'
                          onChange={(e) => {
                            setFieldValue('deliveryId', e.target.value);
                            setOtherFieldName(e.target.value);
                          }}
                          value={values?.deliveryId}
                        />
                        <ErrorSignWrapper className='positionInitial'>
                          <ErrorSign>
                            {errors.deliveryId &&
                              touched.deliveryId &&
                              errors.deliveryId}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </FormControl>
                    )}
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue(
                            'Called the consumer',
                            e.target.checked
                          );
                        }}
                        name='Called the consumer'
                        checked={values?.['Called the consumer']}
                        label='Called the consumer'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue(
                            'Called the restaurant',
                            e.target.checked
                          );
                        }}
                        name='Called the restaurant'
                        checked={values?.['Called the restaurant']}
                        label='Called the restaurant'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('Called the courier', e.target.checked);
                        }}
                        name='Called the courier'
                        checked={values?.['Called the courier']}
                        label='Called the courier'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('Cancelled', e.target.checked);
                        }}
                        name='Cancelled'
                        checked={values?.Cancelled}
                        label='Cancelled'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className='w100'>
                    <FieldTextarea
                      type='text'
                      className='FieldTextareaMd'
                      placeholder='Other'
                      onChange={(e) => {
                        setFieldValue('other', e.target.value);
                        setOtherFieldName(e.target.value);
                      }}
                      value={values?.other}
                    />
                  </DialogField>
                </DialogFields>
                <DialogActions>
                  <DialogAction className='w50'>
                    <ButtonOutline onClick={closeDialog}>Cancel</ButtonOutline>
                  </DialogAction>
                  <DialogAction className='w50'>
                    <ButtonPrimary
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      <span>Save</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogActions>
              </DialogBody>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
}
