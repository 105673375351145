import React from 'react';
import Subtitle0 from '../../../../../components/typography/Subtitle0';
import {Body0} from '../../../../../components/typography';
import {FeedbackActionCenter, ImgSurvey} from '../../styles';
import ImgSurveySoon from '../../../../../assets/img-survey-soon.svg';
import PrimaryLinkDefault from '../../../../../components/buttons/PrimaryLinkDefault/styles';

const SoonContainer = () => {
  return (
    <>
      <Subtitle0 className='text-center marginBottom2Xs'>
        <span className='breakLine'>
          Your Feedback survey is active, the first results will come in soon
        </span>
      </Subtitle0>
      <ImgSurvey className='ImgSurveySoon' src={ImgSurveySoon} />
      <Body0 className='text-center Medium marginTopXXl'>
        In the meantime you can check out the different survey forms{' '}
      </Body0>
      <FeedbackActionCenter>
        <PrimaryLinkDefault
          target='_blank'
          href={`${process.env.REACT_APP_SURVEY_PAGES_URL}/delivery/demo/demo`}
        >
          <span>Delivery survey</span>
        </PrimaryLinkDefault>
        <PrimaryLinkDefault
          target='_blank'
          href={`${process.env.REACT_APP_SURVEY_PAGES_URL}/pickup/demo/demo`}
        >
          <span>Pickup survey</span>
        </PrimaryLinkDefault>
      </FeedbackActionCenter>
    </>
  );
};

export default SoonContainer;
