import {createAction} from 'redux-act';

export const CHANGE_AMOUNT_ITEMS_REFUND_ORDER = createAction(
  'refund/CHANGE_AMOUNT_ITEMS_REFUND_ORDER'
);
export const CHANGE_STATUS_CONFIRMATION_MODAL = createAction(
  'refund/CHANGE_STATUS_CONFIRMATION_MODAL'
);
export const CHANGE_STATUS_CREATE_MODAL = createAction(
  'refund/CHANGE_STATUS_CREATE_MODAL'
);
export const SET_DATA_FOR_REFUND = createAction('refund/SET_DATA_FOR_REFUND');
export const CLEAR_REFUND_DATA = createAction('refund/CLEAR_REFUND_DATA');
export const UPDATE_REFUND_DATA = createAction('refund/UPDATE_REFUND_DATA');
export const SET_REFUND_LOADING = createAction('refund/SET_REFUND_LOADING');

export const GET_DATA_FOR_REFUND = createAction('refund/GET_DATA_FOR_REFUND');
export const GET_DATA_FOR_REFUND_SUCCESS = createAction(
  'refund/GET_DATA_FOR_REFUND_SUCCESS'
);

export const CHANGE_QUANTITY_FOR_REFUND = createAction(
  'refund/CHANGE_QUANTITY_FOR_REFUND'
);
export const CHECK_MODIFIER_FOR_REFUND = createAction(
  'refund/CHECK_MODIFIER_FOR_REFUND'
);
export const SELECT_ADJUST_FOR_REFUND = createAction(
  'refund/SELECT_ADJUST_FOR_REFUND'
);
export const SELECT_TIPS_FOR_REFUND = createAction(
  'refund/SELECT_TIPS_FOR_REFUND'
);
