import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {DashboardContent} from '../../../../../components/DashboardContent/styles';
import {DashboardHeader} from '../../../../../components/DashboardHeader/styles';
import LinkBack from '../../../../../components/LinkBack/styles';
import {DashboardField} from '../../../dashboard/styles';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import {ButtonOutline} from '../../../../../components/buttons';
import {OrderActions, OrderForm} from './styles';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';

export default function AbstractAdjustRefund({content, actionButton}) {
  const {params} = useRouteMatch();
  const history = useHistory();

  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <DashboardContent className='DashboardContent'>
        <DashboardHeader className='whiteBgTablet'>
          <LinkBack
            onClick={() => history.push(`/orders/${params.id}/details`)}
          >
            Back to Order Details
          </LinkBack>
        </DashboardHeader>
        <DashboardInner className='paddingLg'>
          {content}
          <OrderForm>
            <DashboardField className='w50'>
              <FieldLabel className='FieldLabelLg'>Refund</FieldLabel>
              <FieldInput />
            </DashboardField>
          </OrderForm>
          <OrderForm>
            <DashboardField className='w50 Say2EatSelect'>
              <FieldLabel className='FieldLabelLg'>Reason</FieldLabel>
              <FormControl>
                <Select
                  onChange={handleChange}
                  value={value}
                  inputProps={{'aria-label': 'value'}}
                >
                  <MenuItem value={10}>Select</MenuItem>
                  <MenuItem value={20}>Select 2</MenuItem>
                  <MenuItem value={30}>Select 3</MenuItem>
                </Select>
              </FormControl>
            </DashboardField>
            <DashboardField className='w100'>
              <FieldInput value='Why? (this will be presented to the user)' />
            </DashboardField>
          </OrderForm>
          <OrderActions>
            <ButtonOutline className='ButtonLg'>Cancel</ButtonOutline>
            {actionButton}
          </OrderActions>
        </DashboardInner>
      </DashboardContent>
    </>
  );
}

AbstractAdjustRefund.propTypes = {
  content: PropTypes.object.isRequired,
  actionButton: PropTypes.object.isRequired
};
