import {createReducer} from 'redux-act';
import {
  DELETE_MENU_QUEUE_ITEM,
  DELETE_MENU_QUEUE_ITEM_SUCCESS,
  GET_MENU_QUEUE,
  GET_MENU_QUEUE_SUCCESS,
  SAVE_MENU_QUEUE_ITEM,
  SAVE_MENU_QUEUE_ITEM_SUCCESS,
  UPDATE_MENU_QUEUE_ITEM,
  UPDATE_MENU_QUEUE_ITEM_SUCCESS
} from './action';
import {updateObjectInArrayByKey} from '../../helpers/functionUtils/arrayUtils';

const initialState = () => ({
  data: [],
  loading: false
});

const menuQueueReducer = createReducer(
  {
    [GET_MENU_QUEUE]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_MENU_QUEUE_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      loading: false
    }),
    [UPDATE_MENU_QUEUE_ITEM]: (state) => ({
      ...state,
      loading: true
    }),
    [SAVE_MENU_QUEUE_ITEM]: (state) => ({
      ...state,
      loading: true
    }),
    [SAVE_MENU_QUEUE_ITEM_SUCCESS]: (state, payload) => ({
      ...state,
      data: [payload, ...state.data],
      loading: false
    }),
    [UPDATE_MENU_QUEUE_ITEM_SUCCESS]: (state, payload) => ({
      ...state,
      data: updateObjectInArrayByKey(state.data, payload, 'id'),
      loading: false
    }),
    [DELETE_MENU_QUEUE_ITEM_SUCCESS]: (state, payload) => ({
      ...state,
      data: state.data.filter(({id}) => id !== payload),
      loading: false
    }),
    [DELETE_MENU_QUEUE_ITEM]: (state) => ({
      ...state,
      loading: true
    })
  },
  initialState()
);

export default menuQueueReducer;
