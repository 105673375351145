import {GET, POST} from '../constants/API_CONSTANTS';
import {
  API_GENERATE_REPORT_BYOC,
  API_EXPORT_REPORT_BYOC,
  API_GENERATE_REPORT_BOOK_A_DRIVER,
  API_EXPORT_REPORT_BOOK_A_DRIVER,
  API_GENERATE_REPORT_PAYOUT_SUMMARY,
  API_EXPORT_REPORT_PAYOUT_SUMMARY,
  API_GENERATE_REPORT_SALES_SUMMARY,
  API_EXPORT_REPORT_SALES_SUMMARY,
  API_GENERATE_REPORT_REFUNDS_SUMMARY,
  API_GET_REPORTS_CONTENT_REFUNDS_SUMMARY,
  API_EXPORT_REPORT_REFUNDS_SUMMARY,
  API_GENERATE_REPORT_FEEDBACK_SUMMARY,
  API_GET_REPORTS_CONTENT_FEEDBACK_SUMMARY,
  API_EXPORT_REPORT_FEEDBACK_SUMMARY,
  API_GENERATE_REPORTS_CUSTOMERS,
  API_EXPORT_REPORT_CUSTOMERS
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

const generateReportApiEndpoints = {
  0: API_GENERATE_REPORT_SALES_SUMMARY,
  1: API_GENERATE_REPORTS_CUSTOMERS,
  2: API_GENERATE_REPORT_PAYOUT_SUMMARY,
  3: API_GENERATE_REPORT_BYOC,
  4: API_GENERATE_REPORT_BOOK_A_DRIVER,
  5: API_GENERATE_REPORT_REFUNDS_SUMMARY,
  6: API_GENERATE_REPORT_FEEDBACK_SUMMARY
};

export const generateReportApi = async (
  startDate,
  endDate,
  locationId,
  reportType
) => {
  const data = {
    locationId,
    period:
      !startDate && !endDate
        ? null
        : {
            from: startDate,
            to: endDate
          }
  };

  return request(
    POST,
    generateReportApiEndpoints[reportType],
    {
      data
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REPORTS_API_TOKEN}`
      }
    }
  );
};

const getReportsContentApiEndpoints = {
  // 3: API_GET_REPORTS_ORDERS_BYOC,
  // 4: API_GET_REPORTS_ORDERS_BOOK_A_DRIVER
  5: API_GET_REPORTS_CONTENT_REFUNDS_SUMMARY,
  6: API_GET_REPORTS_CONTENT_FEEDBACK_SUMMARY
};

export const getReportsApi = async (reportId, reportType, skip) => {
  return request(
    GET,
    getReportsContentApiEndpoints[reportType](reportId, skip),
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REPORTS_API_TOKEN}`
      }
    }
  );
};

const exportApiEndpoints = {
  0: API_EXPORT_REPORT_SALES_SUMMARY,
  1: API_EXPORT_REPORT_CUSTOMERS,
  2: API_EXPORT_REPORT_PAYOUT_SUMMARY,
  3: API_EXPORT_REPORT_BYOC,
  4: API_EXPORT_REPORT_BOOK_A_DRIVER,
  5: API_EXPORT_REPORT_REFUNDS_SUMMARY,
  6: API_EXPORT_REPORT_FEEDBACK_SUMMARY
};

export const getXlsReportsApi = async (reportId, reportType) => {
  return request(
    POST,
    exportApiEndpoints[reportType](reportId),
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REPORTS_API_TOKEN}`
      },
      responseType: 'blob'
    }
  );
};
