import styled from 'styled-components';
import MainRLPdngs from '../MainRLPaddings/styles';

const TopLineNav = styled.div`
  @media (max-width: 1200px) {
    padding: 14px 60px;
    background: #fff;
    margin: 0 -60px;
  }
`;

export default TopLineNav;
