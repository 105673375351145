import styled from 'styled-components';

export const SearchPanel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;

  .SearchPanelInput {
    max-width: 500px;
    width: 100%;
    flex: 0 0 auto;
    margin-right: 20px;
  }
`;

export default SearchPanel;
