import React from 'react';
import PropTypes from 'prop-types';
import {Dialog} from '@material-ui/core';
import {DialogList, DialogListItem, DialogTitleLine} from './styles';
import {Body1} from '../../../../../components/typography';
import Caption3 from '../../../../../components/typography/Caption3';
import DialogActions from '../../../../../components/DialogActions/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline} from '../../../../../components/buttons';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';

export default function AdjustOrRefund({
  onClose,
  openAdjustPayment,
  openRefundPayment
}) {
  return (
    <Dialog open scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle>Adjust Payment or Refund</DialogTitle>
        <DialogList className='marginBottomXl'>
          <DialogListItem>
            <Body1>Customer’s name:</Body1>
            <Caption3>Catherine Zapata</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>Check size:</Body1>
            <Caption3>$ 12</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>Customer’s phone number:</Body1>
            <Caption3>+38 050 698 56 69</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>Order date:</Body1>
            <Caption3>date</Caption3>
          </DialogListItem>
          <DialogListItem>
            <Body1>Order time:</Body1>
            <Caption3>time</Caption3>
          </DialogListItem>
        </DialogList>
        <DialogTitleLine>What would you like to do?</DialogTitleLine>
        <DialogActions>
          <DialogAction className='w50' onClick={openAdjustPayment}>
            <ButtonOutline className='ButtonPlus'>Adjust Payment</ButtonOutline>
          </DialogAction>
          <DialogAction className='w50' onClick={openRefundPayment}>
            <ButtonOutline className='ButtonMinusOrange'>
              Refund Payment
            </ButtonOutline>
          </DialogAction>
        </DialogActions>
      </DialogContainer>
    </Dialog>
  );
}

AdjustOrRefund.propTypes = {
  onClose: PropTypes.func.isRequired,
  openAdjustPayment: PropTypes.func.isRequired,
  openRefundPayment: PropTypes.func.isRequired
};
