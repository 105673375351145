/* eslint-disable */
import {toast} from 'react-toastify';
import {
  takeEvery,
  takeLatest,
  put,
  select,
  call,
  take,
  race,
  delay,
  cancel
} from 'redux-saga/effects';
import {
  FETCH_LOCATIONS_NOTIFICATIONS,
  FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS,
  OPEN_OFFLINE_MODAL,
  OPEN_ONLINE_MODAL,
  PATCH_DELIVERY_OPTIONS,
  PATCH_DELIVERY_OPTIONS_SUCCESS,
  POLL_STOP,
  RUN_GET_PUBLISH_STATUS_JOB,
  SET_NETWORK_CONNECTION,
  SET_OFFLINE_TOAST_ID,
  SET_ONLINE_STATUS,
  SET_PUBLISH_STATUS,
  SHOW_PUBLISH_PROGRESSBAR,
  STOP_GET_PUBLISH_STATUS_JOB,
  TOGGLE_STORE,
  TOGGLE_STORE_SUCCESS
} from './action';
import {
  getBotStatusApi,
  getLocationsNotificationsApi,
  patchDeliveryOptionsApi,
  toggleStoreApi
} from '../../../helpers/services/api/globalNotifications';
import {SET_UBER_CONFIGURATION} from '../user/action';
import {getStorageToken} from '../../../helpers/localStorage/authStorage';
import delayFunc from '../../../helpers/functionUtils/delayFunction';
import {CHECK_IS_ALLOW_MENU_PUBLISHING} from '../../menuEditor/action';

const PUBLISH_STATUS_INTERVAL = 5000;
const POLLING_DELAY = 60000;
const locationId = ({user}) => user.locationId;
const isOnline = ({globalNotificationsReducer}) =>
  globalNotificationsReducer.isNetworkOnline;
const getToken = ({authReducer}) => authReducer.token;
const locations = ({user}) => user.locations;
const location = ({user}) => user.activeLocation;

export function* notificationsStatusWatchWorker() {
  const online = yield select(isOnline);
  const availableLocations = yield select(locations);

  if (!online || !availableLocations.length) {
    return;
  }

  yield race({
    task: call(notificationsStatusPollingWorker),
    cancel: take(POLL_STOP)
  });
}

export function* notificationsStatusPollingWorker() {
  while (true) {
    try {
      const online = yield select(isOnline);
      const id = yield select(locationId);
      const activeLocation = yield select(location);
      if (online) {
        const {data} = yield getLocationsNotificationsApi(id);
        yield put(FETCH_LOCATIONS_NOTIFICATIONS_SUCCESS(data));
        if (
          data?.UberEats &&
          activeLocation?.UberEats &&
          activeLocation?.UberEats.Byoc.IsEnabled &&
          data?.UberEats.active
        ) {
          yield put(SET_UBER_CONFIGURATION(data.UberEats));
        }
      }

      yield delay(POLLING_DELAY);
    } catch (error) {
      yield put(POLL_STOP());
    }
  }
}

export function* patchDeliveryOptions({payload}) {
  try {
    const id = yield select(locationId);
    const token = yield select(getToken);

    const {promise, ...body} = {
      id: id,
      ...payload
    };

    const {data} = yield patchDeliveryOptionsApi(token, body);

    if (data || data === '') {
      yield put(PATCH_DELIVERY_OPTIONS_SUCCESS(data));

      payload.promise(true);
      toast.success('Delivery options have been updated successfully');
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* toggleStore({payload}) {
  try {
    const id = yield select(locationId);
    const token = yield select(getToken);

    const {promise, ...body} = {
      id: id,
      ...payload
    };
    const {data} = yield toggleStoreApi(token, body);

    if (data || data === '') {
      yield put(TOGGLE_STORE_SUCCESS(data));

      payload.promise(true);
      toast.success(
        body.ClosedManually
          ? 'Store has been disabled successfully'
          : 'Store has been enable successfully'
      );
    }
  } catch (e) {
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

function* setNetworkConnection({payload}) {
  const toastId = yield select(
    (state) => state.globalNotificationsReducer.offlineToastId
  );
  const beenOffline = yield select(
    (state) => state.globalNotificationsReducer.beenOffline
  );

  if (!payload) {
    const id = toast.warning(
      <div>
        You are offline now.Once you are online again, we will notify you.
      </div>,
      {
        autoClose: false,
        closeOnClick: false
      }
    );
    yield put(OPEN_OFFLINE_MODAL(true));

    yield put(SET_ONLINE_STATUS(true));
    yield put(SET_OFFLINE_TOAST_ID(id));
  } else {
    toast.dismiss(toastId);
    if (beenOffline) {
      yield put(OPEN_ONLINE_MODAL(true));
    }
  }
}

export function* getPublishStatusJob(SyncType) {
  while (true) {
    try {
      const menuConfig = yield select(
        (state) => state.user.activeLocation?.MenuConfigId
      );

      const res = yield getBotStatusApi(menuConfig, SyncType);

      yield put(SET_PUBLISH_STATUS(res?.data));

      const onlyErrorTasks =
        res?.data.length &&
        res?.data.every((task) => task?.WithError && !task?.InProcess);
      // if res.length
      if (onlyErrorTasks) {
        toast.error('Publish process failed. Please try again in 10 min.');

        yield put(SHOW_PUBLISH_PROGRESSBAR(false)); // TODO: move this on toast close

        yield delay(5000);

        yield put(
          CHECK_IS_ALLOW_MENU_PUBLISHING({
            menuConfigId: menuConfig,
            SyncType
          })
        );

        yield put(STOP_GET_PUBLISH_STATUS_JOB(true));
      }
      // if (!res?.data.length) {
      //   yield put(STOP_GET_PUBLISH_STATUS_JOB());
      // }
    } catch (error) {
      yield put(STOP_GET_PUBLISH_STATUS_JOB());
      yield put(SHOW_PUBLISH_PROGRESSBAR(false));
      console.log('SAGA ERROR', error);
    }

    yield delay(PUBLISH_STATUS_INTERVAL);
  }
}

function* runGetPublishStatusJob({payload}) {
  try {
    const {SyncType} = payload;
    const isOnline = yield select(
      (state) => state.globalNotificationsReducer?.isNetworkOnline
    );

    const {token} = getStorageToken();

    if (
      //      !!window.AndroidDashboard || ???????????????????????????????? TODO: DO WE NEED THIS CHECK?
      !isOnline ||
      !token
    ) {
      return;
    }

    yield call(delayFunc, 5);

    const {stop} = yield race({
      job: call(() => getPublishStatusJob(SyncType)),
      stop: take(STOP_GET_PUBLISH_STATUS_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    yield put(STOP_GET_PUBLISH_STATUS_JOB());
    yield put(SHOW_PUBLISH_PROGRESSBAR(false));
    console.log('SAGA ERROR', error);
  }
}

function* globalNotificationsSaga() {
  yield takeLatest(RUN_GET_PUBLISH_STATUS_JOB, runGetPublishStatusJob);
  yield takeEvery(
    FETCH_LOCATIONS_NOTIFICATIONS,
    notificationsStatusWatchWorker
  );
  yield takeEvery(PATCH_DELIVERY_OPTIONS, patchDeliveryOptions);
  yield takeEvery(TOGGLE_STORE, toggleStore);
  yield takeEvery(SET_NETWORK_CONNECTION, setNetworkConnection);
}

export default globalNotificationsSaga;
