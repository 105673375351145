import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Subtitle0 = styled(AbstractText)`
  font-size: 30px;
  font-weight: 700;
  color: #232d3a;
  @media (max-width: 599px) {
    font-size: 24px;
  }

  span {
    color: #ff2d38;
  }
  span.breakLine {
    display: block;
    color: #232d3a;
  }
`;

export default Subtitle0;
