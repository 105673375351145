import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Checkbox} from '@material-ui/core';
import T from 'prop-types';
import CheckboxContainer from './styles';

const CheckboxS2E = ({
  onChange,
  name,
  label,
  checked,
  disabled,
  variant,
  onClick
}) => {
  return (
    <CheckboxContainer>
      <FormControlLabel
        className='Say2EatCheckbox'
        control={
          <Checkbox
            labelStyle={{color: '#172751'}}
            iconStyle={{
              fill: `${variant === 'orange' ? '#FF8412' : '#FF2D38'}`
            }}
            inputStyle={{
              color: `${variant === 'orange' ? '#FF8412' : '#FF2D38'}`
            }}
            style={{
              color: `${variant === 'orange' ? '#FF8412' : '#FF2D38'}`,
              opacity: `${disabled ? '0.3' : '1'}`
            }}
            onChange={onChange}
            name={name}
            checked={checked}
            disabled={disabled}
          />
        }
        label={label}
        onClick={onClick}
      />
    </CheckboxContainer>
  );
};

CheckboxS2E.propTypes = {
  onChange: T.func.isRequired,
  name: T.string.isRequired,
  label: T.string,
  checked: T.bool.isRequired,
  variant: T.string,
  disabled: T.bool,
  onClick: T.func
};

CheckboxS2E.defaultProps = {
  label: '',
  variant: '',
  disabled: false,
  onClick: () => {}
};

export default CheckboxS2E;
