import {takeEvery, put, select} from 'redux-saga/effects';
import {
  getInsightsGrowthInfo,
  getLocationsWithInsights,
  postInsightGrowthModules
} from 'helpers/services/api/insights';
import {
  INSIGHTS_CONTROL_CHANGE_MODULE_STATUS,
  INSIGHTS_CONTROL_GET_LOCATION_INFO,
  INSIGHTS_CONTROL_GET_LOCATION_INFO_SUCCESS,
  INSIGHTS_CONTROL_GET_LOCATIONS,
  INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS,
  SET_LOCATION_TABLE_LOADING
} from 'store/insightsControl/action';

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

function* getLocations({payload}) {
  try {
    const {data} = yield getLocationsWithInsights(payload.search);
    if (data) {
      yield put(INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS(data.data));
    }
  } catch (e) {
    yield put(SET_LOCATION_TABLE_LOADING(false));
    console.log('SAGA ERROR', e);
  }
}

function* getInsightsModules({payload}) {
  try {
    const {data} = yield getInsightsGrowthInfo(payload.locationId);
    if (data) {
      yield put(INSIGHTS_CONTROL_GET_LOCATION_INFO_SUCCESS(data));
    }
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* changeInsightsModuleStatus({payload: {locationId, title, status}}) {
  try {
    yield postInsightGrowthModules(
      {
        status,
        locationModule: capitalizeFirstLetter(title)
      },
      locationId
    );
  } catch (e) {
    console.log('SAGA ERROR', e);
  }
}

function* insightsControlSaga() {
  yield takeEvery(INSIGHTS_CONTROL_GET_LOCATIONS, getLocations);
  yield takeEvery(INSIGHTS_CONTROL_GET_LOCATION_INFO, getInsightsModules);
  yield takeEvery(
    INSIGHTS_CONTROL_CHANGE_MODULE_STATUS,
    changeInsightsModuleStatus
  );
}

export default insightsControlSaga;
