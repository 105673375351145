import React, {useEffect} from 'react';
import {Redirect, Route, useHistory, useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import history from '../../../history';
import InsightsGrowth from './Growth';
import InsightsPerformance from './Performance';
import {FEATURE_RESTRICTION_IDS} from '../../../helpers/constants/restrictions';
import RestrictedArea from '../../../components/layout/RestrictedArea';

const InsightsRoutes = () => {
  const match = useRouteMatch();

  const {
    location: {pathname}
  } = useHistory();
  const serviceFeatures = useSelector(
    (state) => state.user.activeLocation.AllowedServiceFeatures
  );

  useEffect(() => {
    if (pathname === '/insights') {
      if (
        serviceFeatures.includes(FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE)
      ) {
        history.push('/insights/performance');
      } else if (
        serviceFeatures.includes(FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH)
      ) {
        history.push('/insights/growth');
      } else {
        history.push('/');
      }
    }
  }, [pathname]);

  return (
    <>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE]}
      >
        <Route
          exact
          path={`${match.path}/performance`}
          component={InsightsPerformance}
        />
      </RestrictedArea>
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH]}
      >
        <Route exact path={`${match.path}/growth`} component={InsightsGrowth} />
      </RestrictedArea>
      <Route
        path='/'
        render={() => {
          let path;
          if (
            pathname.startsWith('/insights/performance') &&
            serviceFeatures.includes(
              FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
            )
          ) {
            path = `${match.path}/performance`;
          } else if (
            pathname.startsWith('/insights/growth') &&
            serviceFeatures.includes(FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH)
          ) {
            path = `${match.path}/growth`;
          } else {
            path = '/insights';
          }

          return <Redirect to={path} />;
        }}
      />
    </>
  );
};

export default InsightsRoutes;
