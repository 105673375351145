export const selectedDaysArray = () => ({
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
  Sun: false
});

export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const startActiveHours = [
  {
    Duration: 1440,
    Start: 0
  },
  {
    Duration: 1440,
    Start: 1440
  },
  {
    Duration: 1440,
    Start: 2880
  },
  {
    Duration: 1440,
    Start: 4320
  },
  {
    Duration: 1440,
    Start: 5760
  },
  {
    Duration: 1440,
    Start: 7200
  },
  {
    Duration: 1440,
    Start: 8640
  }
];

export const startCustomHours = [
  {Start: 600, Duration: 720},
  {Start: 2040, Duration: 720},
  {Start: 3480, Duration: 720},
  {Start: 4920, Duration: 720},
  {Start: 6360, Duration: 720},
  {Start: 7800, Duration: 720},
  {Start: 9240, Duration: 720}
];

export const selectOptions = [
  {name: 'During opening hours', type: 1},
  {name: 'Custom hours', type: 2}
];

export const priceFormatter = (newTax, prevTax = '') => {
  if (newTax === '') {
    return '';
  }

  const formedNewTax = newTax.replace(',', '.').replace('%', '');
  let resTax = parseFloat(formedNewTax);

  if (!resTax && resTax !== 0) {
    return prevTax || '';
  }

  // if (formedNewTax.length > 5) {
  //   return prevTax;
  // }

  if (formedNewTax.indexOf('.') !== formedNewTax.lastIndexOf('.')) {
    return prevTax;
  }

  if (formedNewTax.slice(-1) === '.') {
    resTax = `${resTax}.`;
  }

  if (String(resTax).split('.')[1]?.length > 2) {
    return prevTax;
  }

  if (formedNewTax.slice(-2) === '.0') {
    return formedNewTax;
  }

  return resTax;
};
