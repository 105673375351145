import styled from 'styled-components';

export const FieldLabel = styled.label`
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: #929cb9;
  margin-bottom: 4px;

  &.FieldLabelInline {
    display: inline-block;
  }
  &.FieldLabelLg {
    font-weight: 500;
    color: #2f3d48;
    font-size: 14px;
  }
  &.FieldLabelBold {
    font-size: 16px;
    font-weight: 700;
    color: #232d3a;
  }
  &.mBottomMd {
    margin-bottom: 8px;
  }
  span {
    color: #ff2d38;
  }
  &.positionRelative {
    position: relative;
  }
  &.error {
    color: #ba0302;
    border: none;
  }
  &.modalLabel {
    font-size: 14px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    @media (max-width: 410px) {
      font-size: 10px;
    }
  }
`;

export default FieldLabel;
