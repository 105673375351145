import styled from 'styled-components';

export const OrderAdjustFooter = styled.div`
  border-top: 1px dashed #929cb9;
  padding-top: 20px;
`;

export const OrderAdjustFooterLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;

  &.bold {
    font-weight: 800;
  }
`;
