import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {DashboardInner} from 'components/DashboardInner/styles';
import {FieldLabel} from 'components/FieldLabel/styles';
import {DashboardField, DashboardFields} from 'routes/App/dashboard/styles';
import Field from 'components/Field/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {ButtonPrimary} from 'components/buttons';
import {
  FIND_DELIVERY_CLEAR_ORDER,
  FIND_DELIVERY_GET_ORDER,
  FIND_DELIVERY_SET_ERROR
} from 'store/findDelivery/action';

const FindDeliverySearch = () => {
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const [valueField, setValueField] = useState('');
  const errorMessage = useSelector(
    (state) => state.findDeliveryReducer.findError
  );
  const orderData = useSelector((state) => state.findDeliveryReducer.orderData);

  useEffect(() => {
    if (!orderData && searchInput?.current) {
      searchInput?.current?.focus();
    }
  }, [orderData]);

  return (
    <DashboardInner className='RefundsCalculator RCSearch withoutShadow'>
      <DashboardFields className='DashboardFields alignItemsBottom marginMinus'>
        <DashboardField className='wAutoSpace'>
          <FieldLabel className='mBottomMd'>
            Sauce Delivery ID, Invoice ID or Provider order ID
          </FieldLabel>
          <Field className='SearchPanelInput withClear'>
            <input
              className={cn('FieldSearch Lg Outline BgGrey colorGrey', {
                error: !!errorMessage,
                notEmpty: !!valueField
              })}
              type='search'
              placeholder='Search by ID'
              onChange={(e) => {
                setValueField(e.target.value);
                dispatch(FIND_DELIVERY_SET_ERROR(null));
              }}
              id='searchByID'
              value={valueField}
              disabled={orderData}
              ref={searchInput}
            />
          </Field>
          <ErrorSignWrapper>
            <ErrorSign className='colorRed'>
              {errorMessage && errorMessage}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='wAuto'>
          {orderData ? (
            <ButtonPrimary
              className='ButtonShowOrder'
              onClick={() => {
                setValueField('');
                dispatch(FIND_DELIVERY_CLEAR_ORDER());
              }}
            >
              <span>Clear ID</span>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              className='ButtonShowOrder'
              onClick={() => {
                if (valueField) {
                  dispatch(FIND_DELIVERY_GET_ORDER(valueField));
                }
              }}
            >
              <span>Find Delivery</span>
            </ButtonPrimary>
          )}
        </DashboardField>
      </DashboardFields>
    </DashboardInner>
  );
};

export default FindDeliverySearch;
