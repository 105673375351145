import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IconEmailMarketingAllTimes from 'assets/icon-email-marketing-all-times.svg';
import IconEmailMarketingLastDays from 'assets/icon-email-marketing-last-days.svg';
import IconEmailMarketingSent from 'assets/icon-email-marketing-sent.svg';
import IconEmailMarketingDelivered from 'assets/icon-email-marketing-delivered.svg';
import {
  EmailMarketingCard,
  EmailMarketingCardInfo,
  EmailMarketingCardName,
  EmailMarketingCardNameContainer,
  EmailMarketingCardValue,
  EmailMarketingCarsContainer,
  EmailMarketingNote,
  EmailMarketingSpinner,
  ResultBlock,
  ResultContainer,
  ResultTitle,
  SalesDrivenBlock,
  SalesDrivenContainer
} from 'routes/App/emailMarketing/components/styles';
import {
  GET_MARKETING_STATISTIC,
  SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER
} from 'store/emailMarketing/action';
import Loading from 'components/shared/Utils/Loading';

const ResultPage = () => {
  const dispatch = useDispatch();
  const {statistic, spinner} = useSelector(
    (state) => state.emailMarketingReducer
  );
  const {locationId} = useSelector((state) => state.user);

  useEffect(() => {
    if (locationId) {
      dispatch(SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER(true));
      dispatch(GET_MARKETING_STATISTIC());
    }
  }, [locationId]);

  return (
    <>
      {spinner ? (
        <EmailMarketingSpinner>
          <Loading />
        </EmailMarketingSpinner>
      ) : (
        <>
          <>
            <ResultTitle>Sales driven</ResultTitle>
            <SalesDrivenContainer>
              <SalesDrivenBlock>
                <EmailMarketingCardInfo>
                  <EmailMarketingCardNameContainer>
                    <img
                      className='cardIcon'
                      src={IconEmailMarketingAllTimes}
                      alt=''
                    />
                    <EmailMarketingCardName className='green'>
                      All time
                    </EmailMarketingCardName>
                  </EmailMarketingCardNameContainer>
                  <EmailMarketingCardValue className='green'>
                    $
                    {new Intl.NumberFormat('en-GB', {
                      maximumFractionDigits: 0
                    }).format(statistic.allSummary?.salesDriven || 0)}
                  </EmailMarketingCardValue>
                </EmailMarketingCardInfo>
              </SalesDrivenBlock>
              <SalesDrivenBlock>
                <EmailMarketingCardInfo>
                  <EmailMarketingCardNameContainer>
                    <img
                      className='cardIcon'
                      src={IconEmailMarketingLastDays}
                      alt=''
                    />
                    <EmailMarketingCardName className='green'>
                      Last 30 days
                    </EmailMarketingCardName>
                  </EmailMarketingCardNameContainer>
                  <EmailMarketingCardValue className='green'>
                    $
                    {new Intl.NumberFormat('en-GB', {
                      maximumFractionDigits: 0
                    }).format(statistic.lastMonthSummary?.salesDriven || 0)}
                  </EmailMarketingCardValue>
                </EmailMarketingCardInfo>
              </SalesDrivenBlock>
            </SalesDrivenContainer>
            <ResultContainer>
              <ResultBlock>
                <ResultTitle>All time</ResultTitle>
                <EmailMarketingCarsContainer>
                  <EmailMarketingCard>
                    <EmailMarketingCardInfo>
                      <EmailMarketingCardNameContainer>
                        <img
                          className='cardIcon'
                          src={IconEmailMarketingSent}
                          alt=''
                        />
                        <EmailMarketingCardName>
                          Emails sent
                        </EmailMarketingCardName>
                      </EmailMarketingCardNameContainer>
                      <EmailMarketingCardValue>
                        {new Intl.NumberFormat('en-GB', {
                          maximumFractionDigits: 0
                        }).format(statistic.allSummary?.sent || 0)}
                      </EmailMarketingCardValue>
                    </EmailMarketingCardInfo>
                  </EmailMarketingCard>
                  <EmailMarketingCard>
                    <EmailMarketingCardInfo>
                      <EmailMarketingCardNameContainer>
                        <img
                          className='cardIcon'
                          src={IconEmailMarketingDelivered}
                          alt=''
                        />
                        <EmailMarketingCardName>
                          Emails delivered
                        </EmailMarketingCardName>
                      </EmailMarketingCardNameContainer>
                      <EmailMarketingCardValue>
                        {new Intl.NumberFormat('en-GB', {
                          maximumFractionDigits: 0
                        }).format(statistic.allSummary?.delivered || 0)}
                      </EmailMarketingCardValue>
                    </EmailMarketingCardInfo>
                  </EmailMarketingCard>
                </EmailMarketingCarsContainer>
              </ResultBlock>
              <ResultBlock>
                <ResultTitle>Last 30 days</ResultTitle>
                <EmailMarketingCarsContainer>
                  <EmailMarketingCard>
                    <EmailMarketingCardInfo>
                      <EmailMarketingCardNameContainer>
                        <img
                          className='cardIcon'
                          src={IconEmailMarketingSent}
                          alt=''
                        />
                        <EmailMarketingCardName>
                          Emails sent
                        </EmailMarketingCardName>
                      </EmailMarketingCardNameContainer>
                      <EmailMarketingCardValue>
                        {new Intl.NumberFormat('en-GB', {
                          maximumFractionDigits: 0
                        }).format(statistic.lastMonthSummary?.sent || 0)}
                      </EmailMarketingCardValue>
                    </EmailMarketingCardInfo>
                  </EmailMarketingCard>
                  <EmailMarketingCard>
                    <EmailMarketingCardInfo>
                      <EmailMarketingCardNameContainer>
                        <img
                          className='cardIcon'
                          src={IconEmailMarketingDelivered}
                          alt=''
                        />
                        <EmailMarketingCardName>
                          Emails delivered
                        </EmailMarketingCardName>
                      </EmailMarketingCardNameContainer>
                      <EmailMarketingCardValue>
                        {new Intl.NumberFormat('en-GB', {
                          maximumFractionDigits: 0
                        }).format(statistic.lastMonthSummary?.delivered || 0)}
                      </EmailMarketingCardValue>
                    </EmailMarketingCardInfo>
                  </EmailMarketingCard>
                </EmailMarketingCarsContainer>
              </ResultBlock>
            </ResultContainer>

            <EmailMarketingNote className='mTop23'>
              <span>*</span> Sales from guests who placed an order within 30
              days of receiving mail
            </EmailMarketingNote>
          </>
        </>
      )}
    </>
  );
};

export default ResultPage;
