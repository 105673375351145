import {POST, GET, DELETE, PUT} from '../constants/API_CONSTANTS';
import {
  API_CREATE_DISCOUNT,
  API_CRUD_DISCOUNT,
  API_CRUD_DISCOUNTS,
  PUBLISH_DISCOUNTS
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';
import {buildUrl} from '../../functionUtils/AdminBuildUrl';
import {TASK_TYPE_SETTINGS_DATA_UPDATE} from '../../../store/global/globalNotifications/constants';

export const getDiscountsApi = async (status, payload) => {
  return request(GET, buildUrl(API_CRUD_DISCOUNTS(status), payload));
};

export const getDiscountByIdApi = async (discountId) => {
  return request(GET, API_CRUD_DISCOUNT(discountId));
};

export const postDiscountApi = async (data) => {
  return request(POST, API_CREATE_DISCOUNT(), {data});
};

export const deleteDiscountApi = async (discountId) => {
  return request(DELETE, API_CRUD_DISCOUNT(discountId));
};

export const updateDiscountApi = async (discountId, data) => {
  return request(PUT, API_CRUD_DISCOUNT(discountId), {data});
};

export const publishDiscountsAPi = async (menuConfigId) => {
  return request(
    POST,
    PUBLISH_DISCOUNTS(menuConfigId),
    {},
    {headers: {'Sync-Type': TASK_TYPE_SETTINGS_DATA_UPDATE}}
  );
};
