import {createReducer} from 'redux-act';
import {GET_SALES_COUNT, GET_SALES_COUNT_SUCCESS} from './action';

const initialState = () => ({
  salesCount: [],
  timestamp: 0,
  salesCountLoading: false
});

const analyticsSalesCountReducer = createReducer(
  {
    [GET_SALES_COUNT]: (state) => ({
      ...state,
      salesCountLoading: true
    }),
    [GET_SALES_COUNT_SUCCESS]: (state, payload) =>
      payload.timestamp > state.timestamp
        ? {
            ...state,
            salesCount: payload.data,
            timestamp: payload.timestamp,
            salesCountLoading: false
          }
        : state
  },
  initialState()
);

export default analyticsSalesCountReducer;
