import styled from 'styled-components';

// TODO: a bit specific version, but may be extended in future
const ElevatedPaper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(146, 156, 185, 0.5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

export default ElevatedPaper;
