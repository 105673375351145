import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import DialogFields from '../../../../../components/DialogFields/styles';
import DialogField from '../../../../../components/DialogField/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {
  OPEN_ADDITION_INFO_MODAL,
  CLEAR_ORDER_ACTIONS
} from '../../../../../store/realTimeBoard/action';
import {
  BOARD,
  BOARD_COLOR_PRIORITY,
  BOARD_STATUS
} from '../../../../../helpers/constants/adminRealTImeBoard';

function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export default function AdditionInfoModal() {
  const dispatch = useDispatch();
  const {additionInfo, orderActions} = useSelector(
    (state) => state.realTimeBoardReducer
  );
  const closeDialog = () => {
    dispatch(OPEN_ADDITION_INFO_MODAL({open: false}));
    dispatch(CLEAR_ORDER_ACTIONS());
  };

  const displayedReasonsArr = [
    BOARD_COLOR_PRIORITY.ORANGE,
    BOARD_COLOR_PRIORITY.RED
  ];

  const nth = function (d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return (
    <Dialog open={additionInfo} onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogM'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomLg'>ADDITIONAL INFO</DialogTitle>
        <DialogBody className='wAuto'>
          <DialogFields className='column'>
            {additionInfo[BOARD.RISK_LEVEL]?.value &&
              displayedReasonsArr.includes(
                additionInfo[BOARD.RISK_LEVEL]?.value
              ) &&
              additionInfo[BOARD.RISK_LEVEL]?.reasons?.length && (
                <>
                  <DialogField className='bold smallPadding'>
                    <span>
                      Moving to{' '}
                      {additionInfo[BOARD.RISK_LEVEL]?.value ===
                      BOARD_COLOR_PRIORITY.ORANGE
                        ? 'orange'
                        : 'red'}{' '}
                      reason:
                    </span>
                  </DialogField>
                  {additionInfo[BOARD.RISK_LEVEL]?.reasons?.map((item) => (
                    <>
                      <DialogField className='rdbModal smallPadding'>
                        <span className='alignLeft fullWidth'>
                          &#8226; {item}
                        </span>
                      </DialogField>
                    </>
                  ))}
                </>
              )}
            <DialogField
              className={cn('bold smallPadding', {
                mTop20:
                  additionInfo[BOARD.RISK_LEVEL]?.value &&
                  displayedReasonsArr.includes(
                    additionInfo[BOARD.RISK_LEVEL]?.value
                  ) &&
                  additionInfo[BOARD.RISK_LEVEL]?.reasons?.length
              })}
            >
              <span>Previous dispatcher actions:</span>
            </DialogField>
            {orderActions.length ? (
              orderActions?.map((item) => {
                const time = moment.utc(item.created).local();

                return (
                  <>
                    <ul
                      style={{width: 'calc(100% - 17px)', marginLeft: '17px'}}
                    >
                      <li>
                        <DialogField className='rdbModal smallPadding'>
                          <span>Action taken:</span>
                          <span>{item.actions.join(', ')}</span>
                        </DialogField>
                        <DialogField className='rdbModal smallPadding'>
                          <span>Date & time:</span>
                          <span>
                            {`${time.format('MMM DD')}^${nth(
                              time.format('DD')
                            )}^ @ ${time.format('HH:mm:ss')}`}
                          </span>
                        </DialogField>
                      </li>
                    </ul>
                  </>
                );
              })
            ) : (
              <>
                <ul style={{width: 'calc(100% - 17px)', marginLeft: '17px'}}>
                  <li>
                    <DialogField className='rdbModal smallPadding'>
                      <span>Action taken:</span>
                      <span>N/A</span>
                    </DialogField>
                    <DialogField className='rdbModal smallPadding'>
                      <span>Date & time:</span>
                      <span>N/A</span>
                    </DialogField>
                  </li>
                </ul>
              </>
            )}

            <DialogField className='bold smallPadding mTop20'>
              <span>Order details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Order amount</span>
              <span>
                $
                {additionInfo[BOARD.ORDER_AMOUNT]
                  ? additionInfo[BOARD.ORDER_AMOUNT] / 100
                  : 'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Number of items</span>
              <span>{additionInfo[BOARD.ORDER_ITEMS_COUNT] || 'N/A'}</span>
            </DialogField>
            {additionInfo[BOARD.DELIVERY_COMPANY] && (
              <DialogField className='rdbModal smallPadding'>
                <span>Delivery Company name</span>
                <span>
                  {additionInfo[BOARD.STATUS] === BOARD_STATUS.SCHEDULED ||
                  additionInfo[BOARD.STATUS] === BOARD_STATUS.FUTURE_ORDER ||
                  ((additionInfo[BOARD.STATUS] === BOARD_STATUS.CANCELLED ||
                    additionInfo[BOARD.STATUS] ===
                      BOARD_STATUS.CANCELLED_BY_SAUCE ||
                    additionInfo[BOARD.STATUS] ===
                      BOARD_STATUS.CANCELLED_BY_PROVIDER ||
                    additionInfo[BOARD.STATUS] ===
                      BOARD_STATUS.CANCELLED_BY_CUSTOMER) &&
                    additionInfo[BOARD.IS_FUTURE_ORDER])
                    ? 'N/A'
                    : capitalizeFirstLetter(
                        additionInfo[BOARD.DELIVERY_COMPANY] || 'N/A'
                      )}
                </span>
              </DialogField>
            )}
            {/* <DialogField className='rdbModal smallPadding'>
              <span>Delivery Company phone</span>
              <span>{additionInfo[BOARD.DELIVERY_COMPANY_PHONE] || 'N/A'}</span>
            </DialogField> */}
            <DialogField className='rdbModal smallPadding'>
              <span>Delivery Company Order ID</span>
              <span>{additionInfo[BOARD.ORDER_ID] || 'N/A'}</span>
            </DialogField>
            {additionInfo[BOARD.UBER_ID] && (
              <DialogField className='rdbModal smallPadding'>
                <span>Uber Order ID</span>
                <span>{additionInfo[BOARD.UBER_ID]?.substr(-5)}</span>
              </DialogField>
            )}
            {additionInfo[BOARD.DELIVERY_ID] && (
              <DialogField className='rdbModal smallPadding'>
                <span>Sauce Delivery Order ID</span>
                <span>{additionInfo[BOARD.DELIVERY_ID]}</span>
              </DialogField>
            )}
            <DialogField className='rdbModal smallPadding'>
              <span>Sauce Order ID</span>
              <span>{additionInfo[BOARD.CORE_ID] || 'N/A'}</span>
            </DialogField>

            {((additionInfo[BOARD.DROPOFF_ADDRESS] &&
              (additionInfo[BOARD.DROPOFF_ADDRESS]?.apt ||
                additionInfo[BOARD.DROPOFF_ADDRESS]?.floor ||
                additionInfo[BOARD.DROPOFF_ADDRESS]?.entrance ||
                additionInfo[BOARD.DROPOFF_ADDRESS]?.notes)) ||
              additionInfo[BOARD.CORE_ORD_NOTE]) && (
              <DialogField className='rdbModal smallPadding'>
                <span>Order Notes</span>
                <span>
                  {additionInfo[BOARD.DROPOFF_ADDRESS]?.apt &&
                    `Apt: ${additionInfo[BOARD.DROPOFF_ADDRESS]?.apt} `}
                  {additionInfo[BOARD.DROPOFF_ADDRESS]?.floor &&
                    `Floor: ${additionInfo[BOARD.DROPOFF_ADDRESS]?.floor} `}
                  {additionInfo[BOARD.DROPOFF_ADDRESS]?.entrance &&
                    `Entrance: ${
                      additionInfo[BOARD.DROPOFF_ADDRESS]?.entrance
                    } `}
                  {additionInfo[BOARD.DROPOFF_ADDRESS]?.notes &&
                    `Notes: ${additionInfo[BOARD.DROPOFF_ADDRESS]?.notes} `}
                  {additionInfo[BOARD.CORE_ORD_NOTE] &&
                    `Notes: ${additionInfo[BOARD.CORE_ORD_NOTE]}`}
                </span>
              </DialogField>
            )}

            <DialogField className='bold smallPadding mTop20'>
              <span>Location details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Merchant name</span>
              <span>{additionInfo[BOARD.MERCHANT_NAME] || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location name</span>
              <span>{additionInfo[BOARD.LOCATION_NAME] || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location address</span>
              <span>
                {additionInfo[BOARD.PICKUP_ADDRESS]?.address || 'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location phone</span>
              <span>{additionInfo[BOARD.PICKUP_ADDRESS]?.phone || 'N/A'}</span>
            </DialogField>

            <DialogField className='bold smallPadding mTop20'>
              <span>Customer details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer name</span>
              <span>{additionInfo[BOARD.CUSTOMER_NAME] || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer address</span>
              <span>
                {additionInfo[BOARD.DROPOFF_ADDRESS]?.address || 'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer phone</span>
              <span>{additionInfo[BOARD.DROPOFF_ADDRESS]?.phone || 'N/A'}</span>
            </DialogField>

            <DialogField className='bold smallPadding mTop20'>
              <span>Courier details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Courier name</span>
              <span>{additionInfo[BOARD.COURIER]?.name || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Courier phone</span>
              <span>
                {additionInfo[BOARD.COURIER]?.phone_for_customer || 'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Vehicle Type</span>
              <span>
                {capitalizeFirstLetter(
                  additionInfo[BOARD.COURIER]?.vehicle_type || 'N/A'
                )}
              </span>
            </DialogField>
            {/* <DialogField className='rdbModal smallPadding'>
              <span>Vehicle Make</span>
              <span>{additionInfo[BOARD.COURIER]?.vehicle_make || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Vehicle Model</span>
              <span>{additionInfo[BOARD.COURIER]?.vehicle_model || 'N/A'}</span>
            </DialogField> */}
          </DialogFields>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}
