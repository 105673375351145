/* eslint-disable */
import {PUBLISH_STATUS_ENABLE} from '../../constants';
import {arrayFlip} from '../../../../helpers/arrayHelper.js';
import {createSingleBranch} from '../MenuBranch.js';
import {getOuterJoin} from '../../../../helpers/objectHelper.js';

function updateGroup(store, id, data) {
  if (!store.groupsMap.hasOwnProperty(id)) {
    return store;
  }

  //клонирование основных объектов группы
  const prevData = store.groupsMap[id] || {};
  const group = {...prevData, ...data};
  const branch = createSingleBranch(group, 'modifierGroup');
  const activeBranch = {...store.activeGroupsBranchMap[id]};

  branch.$children = [...group.Items];
  activeBranch.$children = [...group.Items];

  //обновление связей для родительских Items
  const ParentItemsDiff = getOuterJoin(
    arrayFlip(prevData.MenuItems, false),
    arrayFlip(group.MenuItems, true)
  );

  if (Object.keys(ParentItemsDiff).length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId in ParentItemsDiff) {
      if (!store.itemsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const item = {...store.itemsMap[PosId]};

      item.OptionsGroups = ParentItemsDiff[PosId]
        ? [...item.OptionsGroups, id]
        : item.OptionsGroups.filter(PosId => PosId !== id);
      store.itemsMap[PosId] = item;
      isChangeMap = true;

      if (store.itemsBranchMap.hasOwnProperty(PosId)) {
        store.itemsBranchMap[PosId].$children = [...item.OptionsGroups];
        store.itemsBranchMap[PosId] = {...store.itemsBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeItemsBranchMap.hasOwnProperty(PosId)) {
          store.activeItemsBranchMap[PosId].$children = [...item.OptionsGroups];
          store.activeItemsBranchMap[PosId] = {...store.activeItemsBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }

      if (isChangeMap) {
        store.itemsMap = {...store.itemsMap};
      }

      if(isChangeBranchMap) {
        store.itemsBranchMap = {...store.itemsBranchMap};
      }

      if(isChangeActiveBranchMap) {
        store.activeItemsBranchMap = {...store.activeItemsBranchMap};
      }
    }
  }

  //обновление связей для родительских Modifiers
  const ParentModifiersDiff = getOuterJoin(
    arrayFlip(prevData.Modifiers, false),
    arrayFlip(group.Modifiers, true)
  );

  if (Object.keys(ParentModifiersDiff).length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId in ParentModifiersDiff) {
      if (!store.modifiersMap.hasOwnProperty(PosId)) {
        continue;
      }

      const modifier = {...store.modifiersMap[PosId]};

      modifier.ModifierGroups = ParentModifiersDiff[PosId]
        ? [...modifier.ModifierGroups, id]
        : modifier.ModifierGroups.filter(sid => sid !== id);
      store.modifiersMap[PosId] = modifier;
      isChangeMap = true;

      if (store.modifiersBranchMap.hasOwnProperty(PosId)) {
        store.modifiersBranchMap[PosId].$children = [...modifier.ModifierGroups];
        store.modifiersBranchMap[PosId] = {...store.modifiersBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeModifiersBranchMap.hasOwnProperty(PosId)) {
          store.activeModifiersBranchMap[PosId].$children = [...modifier.ModifierGroups];
          store.activeModifiersBranchMap[PosId] = {...store.activeModifiersBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.modifiersMap = {...store.modifiersMap};
    }

    if(isChangeBranchMap) {
      store.modifiersBranchMap = {...store.modifiersBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeModifiersBranchMap = {...store.activeModifiersBranchMap};
    }
  }

  //обновление связей для дочерних Modifiers
  const ModifiersDiff = getOuterJoin(
    arrayFlip(prevData.Items, false),
    arrayFlip(group.Items, true)
  );

  if (Object.keys(ModifiersDiff).length) {
    let isChangeMap = false;

    for (const PosId in ModifiersDiff) {
      if (!store.modifiersMap.hasOwnProperty(PosId)) {
        continue;
      }

      const modifier = {...store.modifiersMap[PosId]};

      modifier.ParentModifierGroups = ModifiersDiff[PosId]
        ? [...modifier.ParentModifierGroups, id]
        : modifier.ParentModifierGroups.filter(sid => sid !== id);
      store.modifiersMap[PosId] = modifier;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.modifiersMap = {...store.modifiersMap};
    }
  }

  //перезапись основных объектов группы
  store.groupsMap[id] = group;
  store.groupsBranchMap[id] = branch;
  store.activeGroupsBranchMap[id] = activeBranch;

  return store;
}

export function updateOneGroup(prevStore, payload) {
  let store = {...prevStore};
  const {id, data} = payload;

  store = updateGroup(store, id, data);

  return getNextStore(store);
}

export function updateManyGroups(prevStore, payload) {
  let store = {...prevStore};
  const {ids, data} = payload;

  ids.forEach(id => (store = updateGroup(store, id, data)));

  return getNextStore(store);
}

function clearGroup(store, groupId) {
  const oldGroup = store.groupsMap[groupId];

  //чистим связи в родительских Items
  if (oldGroup.MenuItems.length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId of oldGroup.MenuItems) {
      if (!store.itemsMap.hasOwnProperty(PosId)) {
        continue;
      }

      const item = {...store.itemsMap[PosId]};
      item.OptionsGroups = item.OptionsGroups.filter(sid => sid !== groupId);
      store.itemsMap[PosId] = item;
      isChangeMap = true;

      if (store.itemsBranchMap.hasOwnProperty(PosId)) {
        store.itemsBranchMap[PosId].$children = [...item.OptionsGroups];
        store.itemsBranchMap[PosId] = {...store.itemsBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeItemsBranchMap.hasOwnProperty(PosId)) {
          store.activeItemsBranchMap[PosId].$children = [...item.OptionsGroups];
          store.activeItemsBranchMap[PosId] = {...store.activeItemsBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }

      if (isChangeMap) {
        store.itemsMap = {...store.itemsMap};
      }

      if (isChangeBranchMap) {
        store.itemsBranchMap = {...store.itemsBranchMap};
      }

      if (isChangeActiveBranchMap) {
        store.activeItemsBranchMap = {...store.activeItemsBranchMap};
      }
    }
  }

  //чистим связи в родительских Modifiers
  if (oldGroup.Modifiers.length) {
    let isChangeMap = false;
    let isChangeBranchMap = false;
    let isChangeActiveBranchMap = false;

    for (const PosId of oldGroup.Modifiers) {
      if (!store.modifiersMap.hasOwnProperty(PosId)) {
        continue;
      }

      const modifier = {...store.modifiersMap[PosId]};
      modifier.ModifierGroups = modifier.ModifierGroups.filter(sid => sid !== groupId);
      store.modifiersMap[PosId] = modifier;
      isChangeMap = true;

      if (store.modifiersBranchMap.hasOwnProperty(PosId)) {
        store.modifiersBranchMap[PosId].$children = [...modifier.ModifierGroups];
        store.modifiersBranchMap[PosId] = {...store.modifiersBranchMap[PosId]};
        isChangeBranchMap = true;

        if (store.activeModifiersBranchMap.hasOwnProperty(PosId)) {
          store.activeModifiersBranchMap[PosId].$children = [...modifier.ModifierGroups];
          store.activeModifiersBranchMap[PosId] = {...store.activeModifiersBranchMap[PosId]};
          isChangeActiveBranchMap = true;
        }
      }
    }

    if (isChangeMap) {
      store.modifiersMap = {...store.modifiersMap};
    }

    if(isChangeBranchMap) {
      store.modifiersBranchMap = {...store.modifiersBranchMap};
    }

    if(isChangeActiveBranchMap) {
      store.activeModifiersBranchMap = {...store.activeModifiersBranchMap};
    }
  }

  //чистим связи для дочерних Modifiers
  if (oldGroup.Items.length) {
    let isChangeMap = false;

    for (const PosId of oldGroup.Items) {
      if (!store.modifiersMap.hasOwnProperty(PosId)) {
        continue;
      }

      const modifier = {...store.modifiersMap[PosId]};
      modifier.ParentModifierGroups = modifier.ParentModifierGroups.filter(id => id !== groupId);
      store.modifiersMap[PosId] = modifier;
      isChangeMap = true;
    }

    if (isChangeMap) {
      store.modifiersMap = {...store.modifiersMap};
    }
  }

  delete store.groupsMap[groupId];
  delete store.groupsBranchMap[groupId];
  delete store.activeGroupsBranchMap[groupId];

  return store;
}

export function clearOneGroup (prevStore, groupId) {
  let store = {...prevStore};
  const filterDirection = (id) => id !== groupId;

  store = clearGroup(store, groupId);
  store.groupsDirection = store.groupsDirection.filter(filterDirection);
  store.activeGroupsDirection = store.activeGroupsDirection.filter(filterDirection);

  return getNextStore(store);
}

export function clearManyGroups (prevStore, ids=[]) {
  let store = {...prevStore};
  const idsMap = arrayFlip(ids);
  const filterDirection = (id) => !idsMap.hasOwnProperty(id);

  ids.forEach(groupId => (store = clearGroup(store, groupId)));
  store.groupsDirection = store.groupsDirection.filter(filterDirection);
  store.activeGroupsDirection = store.activeGroupsDirection.filter(filterDirection);

  return getNextStore(store);
}

function getNextStore(store) {
  return {
    ...store,
    groupsMap: {...store.groupsMap},
    groupsBranchMap: {...store.groupsBranchMap},
    activeGroupsBranchMap: {...store.activeGroupsBranchMap},
    publishStatus: PUBLISH_STATUS_ENABLE
  }
}
