import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import {DashboardField, DashboardFields} from '../../../../../dashboard/styles';
import FieldInput from '../../../../../../../components/FieldInput';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../../components/buttons';
import {
  selectRestaurantUrl,
  stepData,
  WelcomeModalData
} from '../../../../../../../store/setupRestaurant/selectors';
import {
  CONTROL_MODALS,
  CONTROL_SIDEBAR_PROGRESS,
  SAVE_STEP_DATA,
  SETUP_USER_ACCOUNT
} from '../../../../../../../store/setupRestaurant/action';
import {
  ErrorSignWrapper,
  ErrorSign
} from '../../../../../../../components/errorSign/errorSign';
import {
  SetupActions,
  SetupContainer,
  SetupFieldset
} from '../../../../../../Auth/registration/signUpNew/style';
import Subtitle0 from '../../../../../../../components/typography/Subtitle0';
import Caption4 from '../../../../../../../components/typography/Caption4';
import {CardsButtonGrid} from '../../../../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../../../../components/CardsButton/styles';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import FileUploader from '../../../../../../../components/FileUploader';
import WelcomeModal from '../../../../modals/WelcomeModal';
import SalesSetupAddMenuSchema from '../../../../../../../helpers/services/formValidationSchemas/SalesSetupAddMenuSchema';
import allowedFileFormats from '../../../../../../../helpers/constants/signUp';
import MobileProgressBar from '../../../Components/MobileProgressBar';

const OldAddMenu = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    step1: {
      MenuUseUrl,
      MenuUploadFile,
      MenuBuildFromScratch,
      ImagesUseUrl,
      ImagesUploadFile,
      WithoutImages
    },
    pendingDataSetuped,
    UserId
  } = stepData();

  const restaurantUrl = selectRestaurantUrl();
  const isWelcomeModalOpen = WelcomeModalData();

  useEffect(() => {
    dispatch(
      CONTROL_SIDEBAR_PROGRESS(
        pendingDataSetuped ? 'allDone' : 'secondStepDone'
      )
    );
  }, [pendingDataSetuped]);

  const initValues = {
    userId: UserId || '',
    step: 1,
    data: {
      step: 1,
      userId: UserId || '',
      MenuShowUrlInput: false,
      MenuShowUploadFile: false,
      MenuUseUrl: restaurantUrl || MenuUseUrl || '',
      MenuUploadFile: MenuUploadFile || '',
      MenuBuildFromScratch: MenuBuildFromScratch || false,
      ImagesShowUrlInput: false,
      ImagesUseUrl: restaurantUrl || ImagesUseUrl || '',
      ImagesShowUploadFile: false,
      ImagesUploadFile: ImagesUploadFile || '',
      WithoutImages: WithoutImages || false
    }
  };

  const onBack = () => {
    history.push('/my-account/sales-setup/general-info');
  };

  const onCardClick = (
    fieldKey,
    fieldValue,
    setFieldFunction,
    isMenuCards,
    formikValues,
    setErrors
  ) => {
    setErrors({});
    if (isMenuCards) {
      setFieldFunction(
        'data',
        {
          ...formikValues.data,
          MenuShowUrlInput: false,
          MenuUseUrl: '',
          MenuShowUploadFile: false,
          MenuUploadFile: '',
          MenuBuildFromScratch: false
        },
        false
      );
    } else {
      setFieldFunction(
        'data',
        {
          ...formikValues.data,
          ImagesShowUrlInput: false,
          ImagesUseUrl: '',
          ImagesUploadFile: '',
          ImagesShowUploadFile: false,
          WithoutImages: false
        },
        false
      );
    }
    setFieldFunction(fieldKey, fieldValue, false);
  };
  return (
    <>
      <Formik
        initialValues={initValues}
        validationSchema={SalesSetupAddMenuSchema}
        onSubmit={(values) => {
          setIsSubmitting(true);
          const {data, ...info} = values;
          dispatch(SAVE_STEP_DATA({...values, isOldSalesSetup: true}));
          dispatch(
            SETUP_USER_ACCOUNT({
              payload: info,
              successCallback: () => {
                dispatch(
                  CONTROL_MODALS({open: true, field: 'isWelcomeModalOpen'})
                );
                setIsSubmitting(false);
              }
            })
          );
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          errors,
          setFieldTouched,
          setErrors,
          touched,
          validateField
        }) => (
          <Form>
            <SetupContainer>
              <MobileProgressBar />
              <Subtitle0>Add your menu</Subtitle0>
              <SetupFieldset>
                <Caption4 className='positionRelative'>
                  Where should we copy your menu from?
                  <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
                    {errors?.noOptionsMenu || errors?.noOptionsAll ? (
                      <ErrorSign>
                        {errors.noOptionsMenu || errors?.noOptionsAll}
                      </ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </Caption4>
                <CardsButtonGrid className='CardsButtonGridOrange'>
                  <CardsButton
                    className={values.data.MenuShowUrlInput && 'isActive'}
                    onClick={() => {
                      if (!values.data.MenuShowUrlInput) {
                        onCardClick(
                          'data.MenuShowUrlInput',
                          true,
                          setFieldValue,
                          true,
                          values,
                          setErrors
                        );
                      }
                      if (restaurantUrl) {
                        setFieldValue('data.MenuUseUrl', restaurantUrl, false);
                      }
                      setFieldTouched('data.MenuUseUrl', false, false);
                    }}
                  >
                    <span>Use URL</span>
                  </CardsButton>
                  <CardsButton
                    className={values.data.MenuShowUploadFile && 'isActive'}
                    onClick={() => {
                      if (!values.data.MenuShowUploadFile) {
                        onCardClick(
                          'data.MenuShowUploadFile',
                          true,
                          setFieldValue,
                          true,
                          values,
                          setErrors
                        );
                      }
                    }}
                  >
                    <span>Upload file</span>
                  </CardsButton>
                  <CardsButton
                    className={values.data.MenuBuildFromScratch && 'isActive'}
                    onClick={() => {
                      if (!values.data.MenuBuildFromScratch) {
                        onCardClick(
                          'data.MenuBuildFromScratch',
                          true,
                          setFieldValue,
                          true,
                          values,
                          setErrors
                        );
                      }
                    }}
                  >
                    <span>Build from scratch</span>
                  </CardsButton>
                </CardsButtonGrid>
                <DashboardFields>
                  {values.data.MenuShowUrlInput && (
                    <DashboardField className='w100'>
                      <FieldLabel>URL</FieldLabel>
                      <FieldInput
                        placeholder='Please paste the link, here'
                        type='text'
                        value={values.data.MenuUseUrl}
                        onChange={handleChange}
                        name='data.MenuUseUrl'
                        className={cn({
                          error: errors?.MenuUseUrl && touched?.data?.MenuUseUrl
                        })}
                      />
                      <ErrorSignWrapper>
                        {errors?.MenuUseUrl && touched?.data?.MenuUseUrl ? (
                          <ErrorSign>{errors.MenuUseUrl}</ErrorSign>
                        ) : null}
                      </ErrorSignWrapper>
                    </DashboardField>
                  )}
                  {values.data.MenuShowUploadFile && (
                    <DashboardField className='w100'>
                      <FieldLabel className='positionRelative'>
                        Upload file
                        <ErrorSignWrapper
                          leftPosition='70px'
                          topPosition='-13px'
                        >
                          {errors?.MenuUploadFile &&
                          touched?.data?.MenuUploadFile ? (
                            <ErrorSign>{errors.MenuUploadFile}</ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </FieldLabel>
                      <FileUploader
                        url={values.data.MenuUploadFile}
                        onSetFile={(url) => {
                          setFieldValue('data.MenuUploadFile', url, true);
                          validateField('data.MenuUploadFile');
                        }}
                        isPhoto={false}
                        allowedFileFormats={allowedFileFormats}
                      />
                    </DashboardField>
                  )}
                </DashboardFields>
              </SetupFieldset>
              <SetupFieldset>
                <Caption4 className='positionRelative'>
                  {`Can we use the images provided in the ${restaurantUrl} you
                  shared with us?`}
                  <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
                    {errors?.noOptionsImages || errors?.noOptionsAll ? (
                      <ErrorSign>
                        {errors?.noOptionsImages || errors?.noOptionsAll}
                      </ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </Caption4>
                <CardsButtonGrid className='CardsButtonGridOrange'>
                  <CardsButton
                    className={values.data.ImagesShowUrlInput && 'isActive'}
                    onClick={() => {
                      if (!values.data.ImagesShowUrlInput) {
                        onCardClick(
                          'data.ImagesShowUrlInput',
                          true,
                          setFieldValue,
                          false,
                          values,
                          setErrors
                        );
                        if (restaurantUrl) {
                          setFieldValue(
                            'data.ImagesUseUrl',
                            restaurantUrl,
                            false
                          );
                        }
                        setFieldTouched('data.ImagesUseUrl', '', false);
                      }
                    }}
                  >
                    <span>Use URL</span>
                  </CardsButton>
                  <CardsButton
                    className={values.data.ImagesShowUploadFile && 'isActive'}
                    onClick={() => {
                      if (!values.data.ImagesShowUploadFile) {
                        onCardClick(
                          'data.ImagesShowUploadFile',
                          true,
                          setFieldValue,
                          false,
                          values,
                          setErrors
                        );
                      }
                    }}
                  >
                    <span>Upload file</span>
                  </CardsButton>
                  <CardsButton
                    className={values.data.WithoutImages && 'isActive'}
                    onClick={() => {
                      if (!values.data.WithoutImages) {
                        onCardClick(
                          'data.WithoutImages',
                          true,
                          setFieldValue,
                          false,
                          values,
                          setErrors
                        );
                      }
                    }}
                  >
                    <span>Without images</span>
                  </CardsButton>
                </CardsButtonGrid>
                <DashboardFields>
                  {values.data.ImagesShowUrlInput && (
                    <DashboardField className='w100'>
                      <FieldLabel>URL</FieldLabel>
                      <FieldInput
                        placeholder='Please paste the link, here'
                        type='text'
                        value={values.data.ImagesUseUrl}
                        onChange={handleChange}
                        name='data.ImagesUseUrl'
                        className={cn({
                          error:
                            errors?.ImagesUseUrl && touched?.data?.ImagesUseUrl
                        })}
                      />
                      <ErrorSignWrapper>
                        {errors?.ImagesUseUrl && touched?.data?.ImagesUseUrl ? (
                          <ErrorSign>{errors.ImagesUseUrl}</ErrorSign>
                        ) : null}
                      </ErrorSignWrapper>
                    </DashboardField>
                  )}
                  {values.data.ImagesShowUploadFile && (
                    <DashboardField className='w100'>
                      <FieldLabel className='positionRelative'>
                        Upload file
                        <ErrorSignWrapper
                          leftPosition='70px'
                          topPosition='-13px'
                        >
                          {errors?.ImagesUploadFile &&
                          touched?.data?.ImagesUploadFile ? (
                            <ErrorSign>{errors.ImagesUploadFile}</ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </FieldLabel>
                      <FileUploader
                        url={values.data.ImagesUploadFile}
                        onSetFile={(url) => {
                          setFieldValue('data.ImagesUploadFile', url, false);
                          validateField('data.ImagesUploadFile');
                        }}
                        allowedFileFormats={allowedFileFormats}
                        isPhoto={false}
                      />
                    </DashboardField>
                  )}
                </DashboardFields>
              </SetupFieldset>
              <SetupActions className='SecondStep'>
                <ButtonOutline
                  type='button'
                  className={cn('ButtonOutline', {
                    disabled: isSubmitting
                  })}
                  onClick={onBack}
                >
                  Back
                </ButtonOutline>

                <ButtonPrimary
                  type='submit'
                  className={cn('ButtonPrimary', {
                    disabled: isSubmitting
                  })}
                >
                  <span>Finish</span>
                </ButtonPrimary>
              </SetupActions>
            </SetupContainer>
          </Form>
        )}
      </Formik>
      {isWelcomeModalOpen && <WelcomeModal openModalAction={CONTROL_MODALS} />}
    </>
  );
};

export default OldAddMenu;
