/* eslint-disable */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import {DialogBody} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import AnimateDeliverySuccess from '../../../../assets/DeliverSuccess.gif';
import {OrderSuccessImage} from './styles';
import {ButtonOutline} from '../../../../components/buttons';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import {
  RUN_BAD_DELIVERED_ORDERS_JOB,
  STOP_BAD_DELIVERED_ORDERS_JOB,
  CLEAR_BAD_NEW_DELIVERED_ORDERS
} from '../../../../store/global/bookADriverDelivered/action';

const BookADriverDeliverySuccessModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {newDeliveredOrders} = useSelector(
    (state) => state.bookADriverDeliveredOrdersReducer
  );
  const {length} = newDeliveredOrders || [];

  const onClose = () => {
    dispatch(CLEAR_BAD_NEW_DELIVERED_ORDERS());
    dispatch(STOP_BAD_DELIVERED_ORDERS_JOB());
    dispatch(RUN_BAD_DELIVERED_ORDERS_JOB());
  };

  return (
    <Dialog open={length > 0} scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle>
          Order #{newDeliveredOrders[0]?.s2e_order_id.substr(-5, 5)} was
          delivered <span>successfully</span>{' '}
        </DialogTitle>
        <DialogBody>
          <OrderSuccessImage src={AnimateDeliverySuccess} />
          <DialogActions>
            <DialogAction className='w50'>
              <ButtonOutline onClick={onClose}>Close</ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                onClick={() => {
                  onClose();
                  history.push(
                    `/book-a-driver/history/${newDeliveredOrders[0]?._id}/details`
                  );
                }}
              >
                <span>Go to order details</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

BookADriverDeliverySuccessModal.propTypes = {};

export default BookADriverDeliverySuccessModal;
