import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {MenuItem, SidebarMenuButtonRedirect, SidebarNew} from './styles';
import ClosableSidebarButton from './ClosableSidebarButton';

const SidebarMenuItem = ({
  icon,
  text,
  route,
  feature,
  extraNewTagDisplayLogic = () => true,
  closeSidebarEvent,
  activeClassName = 'isActive',
  subItems,
  arrowIcon
}) => {
  const featureItems = useSelector(
    (state) => state.user.userData?.featureItems
  );

  const isAllowedFeature = featureItems.find(
    (item) => item.id === feature && item.isNewFeature
  );

  return (
    <>
      {text === 'Help' ? (
        <MenuItem>
          <SidebarMenuButtonRedirect
            activeClassName='isActive'
            href='https://support.getsauce.com/'
            target='_blank'
          >
            {icon}
            <span>{text}</span>
            {!isAllowedFeature
              ? null
              : extraNewTagDisplayLogic() && <SidebarNew>New</SidebarNew>}
          </SidebarMenuButtonRedirect>
        </MenuItem>
      ) : (
        <MenuItem>
          <ClosableSidebarButton
            closeSidebar={closeSidebarEvent}
            activeClassName={subItems ? 'isDisabled' : activeClassName}
            to={route}
          >
            {icon}
            <span>
              {text}
              {arrowIcon}
            </span>
            {!isAllowedFeature
              ? null
              : extraNewTagDisplayLogic() && <SidebarNew>New</SidebarNew>}
          </ClosableSidebarButton>
          {subItems}
        </MenuItem>
      )}
    </>
  );
};

SidebarMenuItem.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  route: PropTypes.string,
  feature: PropTypes.string,
  extraNewTagDisplayLogic: PropTypes.func,
  closeSidebarEvent: PropTypes.func,
  subItems: PropTypes.element,
  arrowIcon: PropTypes.element
};

SidebarMenuItem.defaultProps = {
  extraNewTagDisplayLogic: () => true,
  activeClassName: 'isActive',
  closeSidebarEvent: () => {},
  route: '',
  subItems: null,
  arrowIcon: null,
  feature: '',
  icon: null
};

export default SidebarMenuItem;
