import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SearchField from './styles';
import TeamTable from './TeamTable';
import {TeamHeadCells} from '../../../../helpers/constants/Table';
import Counter from './Counter';
import {
  DELETE_USER,
  GET_USERS
  // RESET_PASSWORD
} from '../../../../store/usersManagement/action';
import Pagination from './Pagination';
import {TeamTableContainer, TeamTableWrapper} from '../styles';

// eslint-disable-next-line react/prop-types
const Team = ({onEdit, onResetPassword, onDelete}) => {
  const dispatch = useDispatch();
  const [searchModifiers, setSearchModifiers] = useState({});
  const mergeModifiers = (value) =>
    setSearchModifiers({...searchModifiers, ...value});
  const {userData} = useSelector((state) => state.user);

  const {
    loading,
    data,
    counter,
    pagination: {totalDocs}
  } = useSelector((state) => state.usersManagementReducer);

  useEffect(() => {
    if (userData) dispatch(GET_USERS(searchModifiers));
  }, [JSON.stringify(searchModifiers)]);

  return (
    <>
      <SearchField
        onKeyPress={(e) => {
          if (e.key === 'Enter') mergeModifiers({search: e.target.value});
        }}
        placeholder='Search'
      />
      {totalDocs > 0 && (
        <>
          <TeamTableWrapper>
            <TeamTableContainer>
              <Counter values={counter} />
              <TeamTable
                currentUserId={userData.id}
                loading={loading}
                onSort={mergeModifiers}
                searchModifiers={searchModifiers}
                items={data}
                headCells={TeamHeadCells}
                onEdit={onEdit}
                onDelete={onDelete}
                // onResetPassword={(user) => dispatch(RESET_PASSWORD(user))}
                onResetPassword={onResetPassword}
              />
            </TeamTableContainer>
          </TeamTableWrapper>
          <Pagination
            searchModifiers={searchModifiers}
            mergeModifiers={mergeModifiers}
          />
        </>
      )}

      {!loading && totalDocs === 0 && <div>Users were not found</div>}
    </>
  );
};

export default Team;
