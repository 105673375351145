import React from 'react';

const IconPublishNew = () => (
  <svg
    width='31'
    height='31'
    viewBox='0 0 31 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.4166 13.792C25.0741 13.792 24.7455 13.9281 24.5033 14.1703C24.2611 14.4125 24.125 14.7411 24.125 15.0837V20.5254C24.1239 21.4799 23.7443 22.3949 23.0695 23.0698C22.3946 23.7447 21.4795 24.1243 20.5251 24.1253H10.4748C9.52041 24.1243 8.60536 23.7447 7.93047 23.0698C7.25559 22.3949 6.87599 21.4799 6.87496 20.5254V15.0837C6.87496 14.7411 6.73887 14.4125 6.49664 14.1703C6.25441 13.9281 5.92586 13.792 5.58329 13.792C5.24072 13.792 4.91218 13.9281 4.66995 14.1703C4.42771 14.4125 4.29163 14.7411 4.29163 15.0837V20.5254C4.29334 22.1648 4.94533 23.7365 6.10453 24.8958C7.26374 26.055 8.83547 26.7069 10.4748 26.7087H20.5251C22.1645 26.7069 23.7362 26.055 24.8954 24.8958C26.0546 23.7365 26.7066 22.1648 26.7083 20.5254V15.0837C26.7083 14.7411 26.5722 14.4125 26.33 14.1703C26.0877 13.9281 25.7592 13.792 25.4166 13.792Z'
      fill='white'
    />
    <path
      d='M10.3107 11.4359L14.2084 7.70146V17.9584C14.2084 18.3009 14.3445 18.6295 14.5867 18.8717C14.8289 19.114 15.1575 19.25 15.5 19.25C15.8426 19.25 16.1712 19.114 16.4134 18.8717C16.6556 18.6295 16.7917 18.3009 16.7917 17.9584V7.70146L20.6893 11.4516C20.9329 11.6869 21.2592 11.8171 21.5979 11.8142C21.9365 11.8112 22.2605 11.6754 22.5 11.4359C22.7395 11.1964 22.8753 10.8725 22.8783 10.5338C22.8812 10.1951 22.751 9.86883 22.5157 9.62522L16.4132 3.67017C16.171 3.42802 15.8425 3.29199 15.5 3.29199C15.1575 3.29199 14.8291 3.42802 14.5868 3.67017L8.48429 9.60949C8.249 9.8531 8.11881 10.1794 8.12175 10.518C8.1247 10.8567 8.26054 11.1807 8.50003 11.4202C8.73951 11.6597 9.06348 11.7955 9.40215 11.7984C9.74082 11.8014 10.0671 11.6712 10.3107 11.4359Z'
      fill='white'
    />
  </svg>
);

export default IconPublishNew;
