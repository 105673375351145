import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardContent} from '../../dashboard/styles';
import Settings from './Settings';
import {
  getButtonStatus,
  getDiscount,
  getDiscountsLoading
} from '../../../../store/discounts/selectors';
import Loading from '../../../../components/shared/Utils/Loading';
import DiscountSchema from '../../../../helpers/services/formValidationSchemas/DiscountSchema';
import {
  CREATE_DISCOUNT,
  PUBLISH_DISCOUNTS,
  SET_WARNING_MODAL,
  UPDATE_DISCOUNT,
  SET_BUTTON_STATUS
} from '../../../../store/discounts/action';
import {DISCOUNT_TARGET} from '../../../../constants/Discounts';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';

import('./index.css');

const DiscountWizardContainer = () => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const discount = getDiscount();
  const buttonStatus = getButtonStatus();
  const loading = getDiscountsLoading();
  const [enabledDiscount, setEnabledDiscount] = useState(
    discount?._id ? !!discount.CouponCode : true
  );
  const [enabledDate, setEnabledDate] = useState(
    discount.StartTime || discount.EndTime
  );
  const {AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );
  const publishDiscounts = () => {
    dispatch(
      PUBLISH_DISCOUNTS({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  useEffect(() => {
    dispatch(SET_WARNING_MODAL(false));
  }, []);

  return (
    <DashboardContent className='DashboardContent'>
      {loading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            ...discount
          }}
          innerRef={formikRef}
          validationSchema={DiscountSchema}
          enableReinitialize
          onSubmit={(values, {setFieldError}) => {
            const payload = {...values};
            if (enabledDiscount && !payload.CouponCode) {
              setFieldError('CouponCode', 'Promo Code field cannot be empty!');
              return;
            }
            if (
              (enabledDate && !payload.StartTime) ||
              (enabledDate && !payload.EndTime)
            ) {
              setFieldError('StartTime', 'Please select start and end date!');
              return;
            }
            if (payload.CouponCode) {
              payload.CouponCode = payload.CouponCode.toUpperCase();
            }
            dispatch(SET_BUTTON_STATUS(true));
            if (values._id) {
              dispatch(
                UPDATE_DISCOUNT({
                  payload: {
                    id: payload._id,
                    discount: payload
                  },
                  postCallback: () => {
                    publishDiscounts();
                  },
                  errorCallback: () => {
                    dispatch(STOP_GET_PUBLISH_STATUS_JOB());
                    dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
                  }
                })
              );
            } else {
              payload.DiscountTarget = DISCOUNT_TARGET.cart;
              //  payload.Status = DISCOUNT_STATUS.active;
              dispatch(
                CREATE_DISCOUNT({
                  payload,
                  postCallback: () => publishDiscounts(),
                  errorCallback: () => {
                    dispatch(STOP_GET_PUBLISH_STATUS_JOB());
                    dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
                  }
                })
              );
            }
          }}
        >
          {(formik) => (
            <>
              <ConnectedFocusError />
              <Settings
                discount={discount}
                formik={formik}
                enabledDiscount={enabledDiscount}
                setEnabledDiscount={setEnabledDiscount}
                enabledDate={enabledDate}
                setEnabledDate={setEnabledDate}
                buttonStatus={buttonStatus}
                allowedServiceFeatures={AllowedServiceFeatures}
              />
              {/* {!showAdvanced && ( */}
              {/*  <div className='show-advanced'> */}
              {/*    <div */}
              {/*      className='advanced-settings-button' */}
              {/*      onClick={() => setShowAdvanced(true)} */}
              {/*    > */}
              {/*      Click to see Advanced Settings */}
              {/*    </div> */}
              {/*  </div> */}
              {/* )} */}

              {/* {showAdvanced && ( */}
              {/*  <AdvancedSettingsComponent */}
              {/*    discount={discount} */}
              {/*    onClose={() => setShowAdvanced(false)} */}
              {/*    formik={formik} */}
              {/*  /> */}
              {/* )} */}
            </>
          )}
        </Formik>
      )}
    </DashboardContent>
  );
};

export default DiscountWizardContainer;
