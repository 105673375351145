import styled from 'styled-components';

const RadioContainer = styled.div`
  display: inline-block;
  .MuiIconButton-root {
    padding: 0 8px;
    &:hover {
      background-color: transparent !important;
    }
  }
  .MuiFormControlLabel-label {
    position: relative;
    top: 1px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    color: #232d3a;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  &.orange {
    .MuiSvgIcon-root {
      color: #ff8412;
    }
    .MuiTouchRipple-root {
      color: #ff8412;
    }
  }
`;

export default RadioContainer;
