import {createReducer} from 'redux-act';
import {
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL,
  SET_WEBSITE_TEMPLATE,
  CONFIRM_EMAIL_MODAL,
  CONFIRM_EMAIL_ERROR,
  RESEND_CONFIRMATION,
  GET_ACCOUNT_STEPS_STATUS,
  GET_ACCOUNT_STEPS_STATUS_SUCCESS,
  CONNECT_STRIPE_ACCOUNT,
  CONNECT_STRIPE_ACCOUNT_SUCCESS,
  SHOW_STEPPER_AFTER_CALENDLY,
  BOOK_A_CALL,
  BOOK_A_CALL_SUCCESS,
  BOOK_A_CALL_FAILED,
  TOGGLE_CALENDLY,
  SET_WEBSITE_TEMPLATE_SUCCESS
} from './action';

const initialState = () => ({
  isConfirmEmailModalOpen: false,
  isSettingTemplate: false,
  emailSent: false,
  isEmailSending: false,
  showStepperAfterCalendly: false,
  isOpenCalendly: false,
  accountStatus: {
    accountTemplate: false,
    bankInfo: false,
    connectedStripe: false,
    emailConfirmed: false,
    googleMyConnected: false,
    wasAddedMembers: false,
    callBooked: false
  }
});

const accountReducer = createReducer(
  {
    [SET_WEBSITE_TEMPLATE]: (state) => ({
      ...state,
      isSettingTemplate: true
    }),
    [SET_WEBSITE_TEMPLATE_SUCCESS]: (state) => ({
      ...state,
      isSettingTemplate: false
    }),
    [TOGGLE_CALENDLY]: (state, payload) => ({
      ...state,
      isOpenCalendly: payload
    }),
    [RESEND_CONFIRMATION]: (state) => ({
      ...state,
      isEmailSending: true
    }),
    [CONFIRM_EMAIL]: (state) => ({
      ...state,
      emailSent: false,
      isEmailSending: true
    }),
    [CONFIRM_EMAIL_SUCCESS]: (state) => ({
      ...state,
      emailSent: true,
      isEmailSending: false
    }),
    [CONFIRM_EMAIL_ERROR]: (state) => ({
      ...state,
      emailSent: false,
      isEmailSending: false
    }),
    [CONFIRM_EMAIL_MODAL]: (state, {open}) => ({
      ...state,
      isConfirmEmailModalOpen: open
    }),

    [GET_ACCOUNT_STEPS_STATUS]: (state) => ({
      ...state
    }),
    [GET_ACCOUNT_STEPS_STATUS_SUCCESS]: (state, payload) => ({
      ...state,
      accountStatus: payload
    }),
    [CONNECT_STRIPE_ACCOUNT]: (state) => ({
      ...state
    }),
    [CONNECT_STRIPE_ACCOUNT_SUCCESS]: (state) => ({
      ...state,
      accountStatus: {...state.accountStatus, oldConnectedStripe: true}
    }),
    [SHOW_STEPPER_AFTER_CALENDLY]: (state, payload) => ({
      ...state,
      showStepperAfterCalendly: payload
    }),
    [BOOK_A_CALL]: (state) => ({
      ...state
    }),
    [BOOK_A_CALL_SUCCESS]: (state, payload) => ({
      ...state,
      accountStatus: {...state.accountStatus, callBooked: payload.callBooked}
    }),
    [BOOK_A_CALL_FAILED]: (state) => ({
      ...state,
      accountStatus: {...state.accountStatus, callBooked: false}
    })
  },
  initialState()
);

export default accountReducer;
