import styled from 'styled-components';

const Body5 = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0;
  color: #2f3d48;
`;

export default Body5;
