import styled from 'styled-components';
import {Body1} from '../../../components/typography';
import IconDoneGrey from '../../../assets/icon-done-grey.svg';
import IconSettingBlue from '../../../assets/icon-setting-blue.svg';
import IconMoneyHandLeft from '../../../assets/left-hand.gif';
import IconMoneyHandRight from '../../../assets/right-hand.gif';
import IconStartMakingMoney from '../../../assets/icon-start-making-money.svg';
import IconStartMakingMoneyBlue from '../../../assets/icon-start-making-money-blue.svg';
import IconVerifyEmail from '../../../assets/icon-verify-email.svg';
import IconAddLink from '../../../assets/icon-add-link.svg';
import IconBuildLanding from '../../../assets/icon-build-land.svg';
import IconAddTeamMembers from '../../../assets/icon-add-team-members.svg';

export const AccountSteps = styled.div`
  display: block;
  margin-top: 30px;
`;

export const SuccessImage = styled.img`
  display: block;
  max-width: 328px;
  width: 100%;
  margin: 0 auto 40px auto;

  &.Large {
    max-width: 600px;
    margin: 40px auto 0 auto;
  }
`;

export const SuccessImageOrder = styled.div`
  margin: 0 auto 40px auto;
  position: relative;
  max-width: 500px;

  span {
    position: absolute;
    top: 92px;
    left: 122px;
    background-color: #ff2d38;
    width: 76px;
    height: 76px;
    border-radius: 50%;
    z-index: 1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 76px;
    text-align: center;
    color: #fff;
    animation: AnimateNumber 0.8s linear;
    @media (max-width: 600px) {
      top: 14vw;
      left: 22vw;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 35px;
    }
    @media (max-width: 440px) {
      top: 10vw;
      left: 18vw;
    }
  }

  @keyframes AnimateNumber {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  img {
    display: block;
    width: 100%;
    height: 360px;
    margin: 0 auto;
    @media (max-width: 600px) {
      height: auto;
    }
  }
`;

export const MemberPicture = styled.img`
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export const AccountStep = styled.div`
  display: block;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  border-radius: 10px;
  padding: 24px 26px;
  margin-bottom: 10px;
  position: relative;

  @media (max-width: 767px) {
    padding: 14px 16px;
  }
  @media (max-width: 440px) {
    padding: 14px 12px;
  }

  button {
    position: absolute;
    top: 15px;
    right: 26px;
    pointer-events: inherit;
    min-width: 92px;
    z-index: 1;

    @media (max-width: 767px) {
      top: 7px;
      right: 16px;

      &.ButtonSmall {
        padding: 7px 20px;
        min-width: auto;
        font-size: 14px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.isDone {
    pointer-events: none;

    .AccountStepName {
      text-decoration: line-through;
      color: #929cb9;
      opacity: 0.4;
      pointer-events: none;

      &::before {
        background-image: url(${IconDoneGrey}) !important;
      }
    }

    .AccountStepDescription {
      color: #929cb9;
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &.isActive {
    cursor: pointer;

    .AccountStepName {
      color: #ff2d38;
    }
  }

  &.isActiveBlue {
    cursor: pointer;

    .AccountStepName {
      color: #232d3a;
    }
  }

  &.isDisable {
    pointer-events: none;

    .AccountStepName {
      color: #929cb9;
      opacity: 0.4;
      pointer-events: none;

      &.StartMakingMoney {
        &:before {
          background-image: url(${IconStartMakingMoneyBlue});
        }
      }
    }

    .AccountStepDescription {
      color: #929cb9;
      opacity: 0.4;
      pointer-events: none;
    }
  }
`;
export const AccountStepName = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.1;
  color: #232d3a;
  position: relative;
  padding-right: 120px;
  @media (max-width: 767px) {
    font-size: 18px;
    padding-right: 80px;
  }
  @media (max-width: 440px) {
    font-size: 16px;
    padding-right: 110px;
  }

  &::before {
    flex: 0 0 auto;
    content: '';
    display: inline-block;
    width: 38px;
    height: 38px;
    background-image: url(${IconSettingBlue});
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    position: relative;
    top: -8px;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      margin-right: 15px;
      top: -5px;
    }
    @media (max-width: 440px) {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
  }

  &.StartMakingMoney {
    color: #ff2d38;

    &::before {
      background-image: url(${IconStartMakingMoney});
    }
  }

  &.VerifyEmail {
    &::before {
      background-image: url(${IconVerifyEmail});
    }
  }

  &.AddLink {
    &::before {
      background-image: url(${IconAddLink});
    }
  }

  &.BuildLanding {
    &::before {
      background-image: url(${IconBuildLanding});
    }
  }

  &.AddTeamMembers {
    &::before {
      background-image: url(${IconAddTeamMembers});
    }
  }
`;
export const AccountStepDescription = styled(Body1)`
  display: block;
  margin-top: 5px;
  padding-left: 58px;
  @media (max-width: 767px) {
    padding-left: 45px;
  }
  @media (max-width: 370px) {
    padding-left: 36px;
    font-size: 13px;
  }

  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #2f3d48;
    border-bottom: 1px solid #2f3d48;
    transition-property: border-bottom-color;
    transition-duration: 0.3s;
    @media (max-width: 1300px) {
      font-size: 13.5px;
    }
    @media (max-width: 1250px) {
      font-size: 14px;
    }
    @media (max-width: 370px) {
      font-size: 13px;
    }
  }
`;
export const AccountTemplates = styled.div`
  display: block;
  margin-top: 30px;
  max-width: 940px;
`;
export const AccountTemplate = styled.div`
  display: flex;
  align-items: stretch;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    display: block;
    max-width: 400px;
    width: 100%;
    margin: 0 auto 20px auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
export const AccountTemplateImage = styled.div`
  flex: 0 0 auto;
  width: 50%;
  border-radius: 5px 0 0 5px;
  overflow: hidden;
  position: relative;

  video {
    position: absolute;
    width: 114%;
    height: 114%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::-internal-media-controls-overlay-cast-button {
      display: none !important;
    }
  }

  @media (max-width: 1000px) {
    height: 200px;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
`;
export const AccountTemplateAction = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding: 88px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px 1px 1px 0;
  border-radius: 0 5px 5px 0;
  border-style: solid;
  border-color: rgba(146, 156, 185, 0.5);
  @media (max-width: 1000px) {
    width: 100%;
    border-width: 0 1px 1px 1px;
    padding: 10px 20px;
    border-radius: 0 0 5px 5px;
  }

  .ButtonLg {
    width: 100%;
    max-width: 360px;
  }
`;
export const BankAccountForm = styled.div`
  display: block;
  margin-top: 30px;
  max-width: 560px;

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const Commission = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px -38px 0 -38px;
`;
export const CommissionInner = styled.div`
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.1;
  color: #232d3a;
  padding: 0 38px;
  @media (max-width: 599px) {
    font-size: 18px;
    padding: 0 20px;
  }

  &:first-child {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      width: 2px;
      height: 34px;
      background-color: #232d3a;
      transform: translateY(-50%);
    }
  }

  span {
    color: #fca600;
    padding-right: 6px;
  }
`;
export const SaveMoney = styled.div`
  display: block;
  margin-top: 100px;
  text-align: center;
  position: relative;
  padding: 0 90px;

  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: '';
    width: 90px;
    height: 170px;
    background-image: url(${IconMoneyHandLeft});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    content: '';
    width: 90px;
    height: 170px;
    background-image: url(${IconMoneyHandRight});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  * {
    line-height: 1.4;
  }
`;

export const DialogFeedback = styled.div`
  margin-top: 55px;
`;
export const DialogFeedbackItem = styled.div`
  margin-bottom: 75px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const DialogFeedbackPhoto = styled.div`
  display: block;
  height: 265px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
`;
export const DialogFeedbackBottom = styled.div`
  margin-top: 20px;
`;
export const DialogFeedbackDescription = styled.div`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.4;
  color: #232d3a;
`;
export const DialogFeedbackCompany = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
`;
export const DialogFeedbackLogo = styled.img`
  display: block;
  max-width: 96px;
`;
export const DialogFeedbackInfo = styled.div`
  display: block;
  padding-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #232d3a;

  strong {
    display: block;
  }

  p {
    display: block;
    margin-top: 2px;
  }
`;

export const ActivateVideoGif = styled.img`
  display: block;
  width: 100%;
`;
export const DashboardHold = styled.div`
  display: block;
  width: 100%;
  max-width: 740px;
`;
export const FieldCopy = styled.div`
  position: relative;

  textarea {
    padding-bottom: 54px;
  }

  .ButtonOutline {
    position: absolute;
    bottom: 35px;
    right: 10px;
  }
`;

export const SetupLogout = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 30px;
  left: 30px;
  padding-right: 36px;

  @media (max-width: 1600px) {
    position: absolute;
    max-width: 100px;
  }

  @media (max-width: 1250px) {
    left: 30px;
    bottom: 30px;
  }

  @media (max-width: 767px) {
    left: 20px;
    bottom: 20px;
  }

  *:nth-child(1) {
    position: relative;
    top: 1px;
  }
`;
