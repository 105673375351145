import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import BoardTable from './BoardTable';
import {OrdersContainer} from './styles';
import Headline from '../../../../components/Headline/styles';
import {
  CLEAR_ORDERS_RTB,
  SET_SORTING,
  OPEN_ADDITION_INFO_MODAL,
  RUN_ORDER_RTB_JOB,
  STOP_ORDER_RTB_JOB,
  OPEN_OWNED_MODAL,
  RDB_REMOVE_ORDER_MODAL,
  SEND_ORDER_ACTIONS
} from '../../../../store/realTimeBoard/action';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import AdditionInfoModal from './Modals/AdditionalInfoModal';
import {DashboardHeaderActions} from '../../../../components/DashboardHeaderActions/styles';
import ActionsMadeModal from './Modals/ActionsMadeModal';
import Modal from '../../../../components/Modals/Modal';
import ConfirmModal from '../../../../components/ModalBox/ConfirmModal';

const RealTimeBoard = () => {
  const dispatch = useDispatch();

  const {
    orders,
    pinOrders,
    loading,
    sorting: {sort, sortBy},
    lastUpdateTime,
    openActionsModal,
    openAdditionInfoModal,
    openOwnedModal,
    ownedModalText,
    removeOrderModal
  } = useSelector((state) => state.realTimeBoardReducer);

  const {userData} = useSelector((state) => state.user);

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property.sortBy && sort === 'asc';
    dispatch(
      SET_SORTING({
        sort: isAsc ? 'desc' : 'asc',
        sortBy: property.sortBy
      })
    );
  };

  const createSortHandler = (property) => {
    handleRequestSort(property);
  };

  useEffect(() => {
    dispatch(STOP_ORDER_RTB_JOB());
    dispatch(RUN_ORDER_RTB_JOB());

    return () => {
      dispatch(STOP_ORDER_RTB_JOB());
      // dispatch(CLEAR_ORDERS_RTB());
    };
  }, []);
  return (
    <>
      <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
        <DashboardHeader className='alwaysFlex'>
          <div>
            <Headline>Real time delivery dashboard</Headline>
          </div>
          <DashboardHeaderActions>
            {lastUpdateTime && (
              <span className='redText'>
                Last update: {moment(lastUpdateTime).format('h:mm:ss a')}
              </span>
            )}
          </DashboardHeaderActions>
        </DashboardHeader>
        <OrdersContainer>
          <BoardTable
            orders={orders}
            pinOrders={pinOrders}
            onSort={createSortHandler}
            orderBy={sortBy}
            orderDirection={sort}
            loading={loading}
          />
        </OrdersContainer>
      </DashboardContent>
      {openAdditionInfoModal && <AdditionInfoModal />}
      {openActionsModal && <ActionsMadeModal />}
      {openOwnedModal && (
        <Modal
          onCloseAction={() => dispatch(OPEN_OWNED_MODAL({open: false}))}
          body={ownedModalText}
          classParameter='DialogSm2'
        />
      )}
      <ConfirmModal
        isOpen={removeOrderModal.open}
        message={`Remove order ${
          removeOrderModal.core_invoice_id || ''
        } from the board?`}
        btnOkTitle='Yes'
        btnCancelTitle='No'
        onCancel={() => dispatch(RDB_REMOVE_ORDER_MODAL({open: false}))}
        onOk={() => {
          dispatch(
            SEND_ORDER_ACTIONS({
              data: {
                actions: [
                  {
                    created: moment().utc().format(),
                    userId: userData.id,
                    name: 'Remove order'
                  }
                ]
              },
              id: removeOrderModal.internal_delivery_id
            })
          );
          dispatch(RDB_REMOVE_ORDER_MODAL({open: false}));
        }}
      />
    </>
  );
};

export default RealTimeBoard;
