import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {ButtonOutline} from '../../../../components/buttons';
import ConfirmModal from '../Modals/ConfirmModal';
import {BULK_DELETE} from '../../../../store/menuEditor/action';
import {IconDelete} from '../../../../assets/IconDelete';

function ShowButton(props) {
  const dispatch = useDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const {
    disabled,
    activeTab,
    selectedCategories,
    selectedItems,
    selectedGroups,
    selectedModifiers
  } = props;

  return (
    <>
      <ButtonOutline
        className='ButtonSmall Delete'
        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
      >
        <IconDelete />
        Delete
      </ButtonOutline>
      <ConfirmModal
        isOpen={isConfirmOpen}
        isLoading={isConfirmLoading}
        message='Are you sure you want to delete the selected elements?'
        onOk={() => {
          const payload = {
            preCallback: () => setIsConfirmLoading(true),
            postCallback: () => {
              setIsConfirmLoading(false);
              setIsConfirmOpen(false);
            }
          };

          if (selectedCategories) {
            payload.categories = {ids: Object.keys(selectedCategories)};
          }

          if (selectedItems) {
            payload.items = {ids: Object.keys(selectedItems)};
          }

          if (selectedGroups) {
            payload.groups = {ids: Object.keys(selectedGroups)};
          }

          if (selectedModifiers) {
            payload.modifiers = {ids: Object.keys(selectedModifiers)};
          }

          dispatch(BULK_DELETE(payload));
        }}
        onCancel={() => {
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}

ShowButton.propTypes = {
  disabled: PropTypes.bool,
  activeTab: PropTypes.string,
  selectedCategories: PropTypes.object,
  selectedItems: PropTypes.object,
  selectedGroups: PropTypes.object,
  selectedModifiers: PropTypes.object
};

ShowButton.defaultProps = {
  disabled: false,
  activeTab: null,
  selectedCategories: null,
  selectedItems: null,
  selectedGroups: null,
  selectedModifiers: null
};

export default ShowButton;
