import {createAction} from 'redux-act';

export const GET_UBEREATS_DATA = createAction('uberEats/GET_UBEREATS_DATA');
export const GET_UBEREATS_DATA_SUCCESS = createAction(
  'uberEats/GET_UBEREATS_DATA_SUCCESS'
);
export const GET_UBEREATS_STORES_SUCCESS = createAction(
  'uberEats/GET_UBEREATS_STORES_SUCCESS'
);

export const SET_UBEREATS_ACCOUNT = createAction(
  'uberEats/SET_UBEREATS_ACCOUNT'
);
export const SET_UBEREATS_STORE_ID = createAction(
  'uberEats/SET_UBEREATS_STORE_ID'
);

export const CONNECT_UBEREATS_STORE = createAction(
  'uberEats/CONNECT_UBEREATS_STORE'
);
export const CONNECT_UBEREATS_STORE_SUCCESS = createAction(
  'uberEats/CONNECT_UBEREATS_STORE_SUCCESS'
);
export const CHANGE_UBER_STATUS = createAction('uberEats/CHANGE_UBER_STATUS');
export const UBER_BANNER_SHOW = createAction('uberEats/UBER_BANNER_SHOW');
export const CHANGE_UBER_STATUS_SUCCESS = createAction(
  'uberEats/CHANGE_UBER_STATUS_SUCCESS'
);
export const DISCONNECT_UBER_EATS = createAction(
  'uberEats/DISCONNECT_UBER_EATS'
);
export const DISCONNECT_UBER_EATS_SUCCESS = createAction(
  'uberEats/DISCONNECT_UBER_EATS_SUCCESS'
);
export const HANDLE_CONNECT_MODAL = createAction(
  'uberEats/HANDLE_CONNECT_MODAL'
);
export const HANDLE_CONNECT_STORE_MODAL = createAction(
  'uberEats/HANDLE_CONNECT_STORE_MODAL'
);
export const HANDLE_NOT_STORE_MODAL = createAction(
  'uberEats/HANDLE_NOT_STORE_MODAL'
);
export const HANDLE_DISCONNECT_MODAL = createAction(
  'uberEats/HANDLE_DISCONNECT_MODAL'
);
export const CHANGE_BYOC_CONFIGURATION_STATUS = createAction(
  'uberEats/CHANGE_BYOC_CONFIGURATION_STATUS'
);
export const CHANGE_BYOC_CONNECTION_STATUS = createAction(
  'uberEats/CHANGE_BYOC_CONNECTION_STATUS'
);

export const SEND_SLACK_NOTIF = createAction('uberEats/SEND_SLACK_NOTIF');

export const FINISH_BYOC_CONFIG = createAction('uberEats/FINISH_BYOC_CONFIG');

export const CLEAR_DATA = createAction('uberEats/CLEAR_DATA');
