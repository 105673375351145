import React from 'react';
import PropTypes from 'prop-types';

const SoundItem = ({
  title,
  play,
  onHandleChange,
  selectedItem,
  sound,
  onSoundStop
}) => {
  const isSound = title === sound;
  const isSelected = selectedItem === title;
  const handleClick = (event) => {
    event.stopPropagation();
    return isSound ? onSoundStop() : play(title);
  };
  return (
    <div
      onClick={() => onHandleChange(title)}
      className={`SoundItem ${isSelected ? 'isActive' : ''}`}
    >
      <div className='SoundItemTitle'>{title}</div>
      <div
        className={`SoundItemPlay ${isSound ? 'isPlay' : ''}`}
        onClick={handleClick}
      >
        Listen
      </div>
    </div>
  );
};

SoundItem.propTypes = {
  title: PropTypes.string.isRequired,
  selectedItem: PropTypes.string.isRequired,
  sound: PropTypes.string.isRequired,
  play: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onSoundStop: PropTypes.func.isRequired
};

export default SoundItem;
