import React from 'react';
import {useFormik} from 'formik';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import {FieldLabel} from 'components/FieldLabel/styles';
import {ButtonPrimary} from 'components/buttons';
import FieldPassword from 'components/FieldPassword';
import {ResetPasswordSchema} from 'helpers/services/formValidationSchemas/AuthSchema';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {
  AuthBottom,
  AuthForm,
  AuthLink,
  AuthLinks,
  AuthLogo,
  AuthSection,
  AuthTitle
} from 'routes/Auth/styles';
import {UPDATE_PASSWORD} from 'store/global/auth/action';
import SauceLogo from 'assets/logo-sauce.svg';

const ResetPassword = ({match}) => {
  const dispatch = useDispatch();

  const {token} = match.params;

  const formik = useFormik({
    initialValues: {
      Password: '',
      PasswordConfirm: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, {resetForm}) => {
      dispatch(UPDATE_PASSWORD({password: values.Password, token}));
      resetForm();
    }
  });

  return (
    <AuthSection className='h100vh ResetPassword'>
      <form onSubmit={formik.handleSubmit}>
        <AuthLogo src={SauceLogo} />
        <AuthForm>
          <AuthTitle>New password</AuthTitle>
          <DialogFields>
            <DialogField className='w100 mTopMd'>
              <FieldLabel className='FieldLabelLg mBottomMd'>
                New Password
              </FieldLabel>
              <FieldPassword
                className={
                  formik.errors.Password && formik.touched.Password && 'error'
                }
                name='Password'
                value={formik.values.Password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Password'
              />
              {formik.errors.Password && formik.touched.Password && (
                <ErrorSignWrapper>
                  <ErrorSign>{formik.errors.Password}</ErrorSign>
                </ErrorSignWrapper>
              )}
            </DialogField>
            <DialogField className='w100'>
              <FieldLabel className='FieldLabelLg mBottomMd'>
                Confirm New Password
              </FieldLabel>
              <FieldPassword
                className={
                  formik.errors.PasswordConfirm &&
                  formik.touched.PasswordConfirm &&
                  'error'
                }
                name='PasswordConfirm'
                value={formik.values.PasswordConfirm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Password confirm'
              />
              {formik.errors.PasswordConfirm && formik.touched.PasswordConfirm && (
                <ErrorSignWrapper>
                  <ErrorSign>{formik.errors.PasswordConfirm}</ErrorSign>
                </ErrorSignWrapper>
              )}
            </DialogField>
            <DialogField className='w100 Center mTopMd'>
              <ButtonPrimary type='submit' className='widthXlCenter'>
                <span>Save Password</span>
              </ButtonPrimary>
            </DialogField>
          </DialogFields>
        </AuthForm>
      </form>
      <AuthBottom>
        <span>© {new Date().getFullYear()} Sauce</span>
        <AuthLinks>
          <AuthLink
            href={process.env.REACT_APP_TERMS_SERVICE_URL}
            target='_blank'
          >
            Terms of Service
          </AuthLink>
          <AuthLink
            href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            target='_blank'
          >
            Privacy Policy
          </AuthLink>
        </AuthLinks>
      </AuthBottom>
    </AuthSection>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.object.isRequired
};

ResetPassword.defaultProps = {};

export default ResetPassword;
