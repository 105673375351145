import styled from 'styled-components';

const Textarea = styled.textarea`
  display: block;
  font-family: 'Poppins';
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #2f3d48;
  padding: 19px 17px;
  height: 56px;
  min-height: 56px;
  outline: none;
  width: 100%;
  resize: vertical;
  &.FieldTextareaMd {
    height: 88px;
    min-height: 88px;
    padding: 18px 17px;
  }
  &.FieldTextareaLg {
    height: 126px;
    min-height: 126px;
  }
  &.error {
    border-color: red;
  }
  &.RTBv2Error {
    border-color: #ba0302;
  }
  &.ClosedMessage {
    height: 77px;
  }
  &.WithoutResize {
    resize: inherit;
  }
  &.WithButton {
    padding-right: 240px;
  }
  &::placeholder {
    color: #929cb9;
  }
`;

export default Textarea;
