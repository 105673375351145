import {createReducer} from 'redux-act';
import {
  GET_BAD_ACTIVE_ORDERS,
  GET_BAD_ACTIVE_ORDERS_COUNT,
  GET_BAD_ACTIVE_ORDERS_SUCCESS,
  SET_BAD_TABLE_CONFIG
} from './action';

const initialConfig = () => ({
  page: '0',
  perPage: '25',
  sortField: 's2e_meta.pickup_eta',
  sortDir: 'desc',
  to: encodeURIComponent(new Date().toISOString()),
  from: '2022-01-10T00%3A00%3A00.000Z'
});

const initialState = () => ({
  activeOrders: [],
  orderCount: 0,
  config: initialConfig(),
  loading: false
});

const activeBookADriverOrdersReducer = createReducer(
  {
    [GET_BAD_ACTIVE_ORDERS_SUCCESS]: (state, data) => ({
      ...state,
      activeOrders: data,
      loading: false
    }),
    [GET_BAD_ACTIVE_ORDERS]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_BAD_ACTIVE_ORDERS_COUNT]: (state, data) => ({
      ...state,
      orderCount: data
    }),
    [SET_BAD_TABLE_CONFIG]: (state, data) => {
      return {
        ...state,
        config: {
          ...state.config,
          ...data
        }
      };
    }
  },
  initialState()
);

export default activeBookADriverOrdersReducer;
