// eslint-disable-next-line import/prefer-default-export
export const checkNetworkConnection = (onSuccess, onError) => {
  fetch('https://www.google.com/', {
    mode: 'no-cors'
  })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};
