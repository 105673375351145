import styled from 'styled-components';

const backgroundColor = {
  completed: 'transparent',
  overdue: '#FF8412',
  canceled: 'transparent',
  unknown: 'transparent'
};

const borderColor = {
  completed: '#17C981',
  overdue: '#FF8412',
  canceled: '#929CB9',
  unknown: '#929CB9'
};

const textColor = {
  completed: '#172751',
  canceled: '#172751',
  unknown: '#172751'
};

const iconColor = {
  completed: '#17C981',
  canceled: '#929CB9',
  unknown: '#929CB9'
};

const StatusChip = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  svg circle {
    fill: ${({state}) => iconColor[state] || '#fff'};
  }
  svg {
    @media (max-width: 1000px) {
      width: 8px;
      height: 8px;
    }
  }
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: ${({state}) => textColor[state] || '#fff'};
    margin-left: 6px;
    text-transform: capitalize;
    @media (max-width: 1000px) {
      font-size: 12px;
    }
  }

  background: ${({state}) => backgroundColor[state] || '#17C981'};
  border-radius: 30px;
  padding: 4px 18px;
  outline: none;
  border: 2px solid ${({state}) => borderColor[state] || '#17C981'};
  border-radius: 30px;
  box-shadow: 0px 8px 16px rgba(21, 206, 117, 0);

  @media (max-width: 1000px) {
    padding: 2px 14px;
  }

  &.ButtonSm {
    font-weight: 500;
    font-size: 14px;
    padding: 10px 19px;
  }
  &.ButtonMd {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 24px;
    @media (max-width: 1000px) {
      font-size: 14px;
    }
  }
`;

export default StatusChip;
