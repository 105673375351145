import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import Modal from '../../../../../components/Modals/Modal';
import {
  DashboardInnerDescription,
  DialogAction
} from '../../../dashboard/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import IconTypeDollar from '../../../../../assets/IconDollarBig';
import IconTypePercent from '../../../../../assets/IconPercentBig';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import FieldInput from '../../../../../components/FieldInput';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {CardsButtonGrid} from '../../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../../components/CardsButton/styles';
import {
  taxFormatter,
  twoDecimalsFormatter
} from '../../../../../helpers/functionUtils/helpFunctions';

const ModalSelectDiscount = ({
  onCloseAction,
  submitAction,
  handleChange,
  getValue,
  getError,
  getTouched
}) => {
  const selectedDiscountType = getValue('DiscountType');
  const getClassName = (value) =>
    value === selectedDiscountType ? 'isActive' : null;

  const types = [
    {Icon: <IconTypePercent />, value: 1},
    {Icon: <IconTypeDollar />, value: 0}
  ];

  const isInvalid = (value, type) =>
    selectedDiscountType === value && getError(type) && getTouched(type);

  useEffect(() => {
    handleChange('Discount', '');
    handleChange('MinOrder', '');
  }, [selectedDiscountType]);

  return (
    <Modal
      onCloseAction={onCloseAction}
      title={
        <Box>
          <DialogTitle className='marginBottomLg'>
            Select a discount
          </DialogTitle>
          <DashboardInnerDescription>
            Discount apply only if min/max are met at check level
          </DashboardInnerDescription>
        </Box>
      }
      body={
        <>
          <CardsButtonGrid className='CardsButtonGridSelectDiscount'>
            {types.map(({Icon, value}) => (
              <CardsButton
                key={`DiscountType_${value}`}
                className={getClassName(value)}
                onClick={() => {
                  handleChange('DiscountType', value);
                }}
              >
                <FlexContainer className='FlexContainerDiscount'>
                  <FlexItem className='FlexItemIcon'>{Icon}</FlexItem>
                  <FlexItem className='FlexItem'>
                    <FieldInput
                      className={isInvalid(value, 'Discount') && 'error'}
                      type='text'
                      value={
                        selectedDiscountType === value
                          ? getValue('Discount')
                          : ''
                      }
                      name='Discount'
                      onBlur={(e) => {
                        const lastElement = e.target.value.slice(-1)[0];
                        if (lastElement === '.') {
                          handleChange('Discount', e.target.value.slice(0, -1));
                        }
                      }}
                      onChange={(e) => {
                        if (value === 1) {
                          handleChange(
                            'Discount',
                            taxFormatter(
                              e.target.value,
                              getValue('Discount'),
                              90
                            )
                          );
                        } else {
                          handleChange(
                            'Discount',
                            twoDecimalsFormatter(
                              e.target.value,
                              getValue('Discount'),
                              9999.99
                            ) || ''
                          );
                        }
                      }}
                    />
                    {!!isInvalid(value, 'Discount') && (
                      <ErrorSignWrapper topPosition='0px' leftPosition='0px'>
                        <ErrorSign className='smallFont'>
                          {getError('Discount')}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    )}
                  </FlexItem>
                </FlexContainer>
                <FlexContainer className='FlexContainerDiscount'>
                  <FlexItem className='FlexItemTitle'>
                    <Box
                      fontSize={12}
                      color='rgba(146, 156, 185, 1)'
                      fontWeight='bold'
                      width='100%'
                    >
                      Min order {value ? ' (optional)' : ''}
                    </Box>
                  </FlexItem>
                  <FlexItem className='FlexItem'>
                    <FieldInput
                      className={isInvalid(value, 'MinOrder') && 'error'}
                      value={
                        selectedDiscountType === value
                          ? getValue('MinOrder')
                          : ''
                      }
                      onBlur={(e) => {
                        const lastElement = e.target.value.slice(-1)[0];
                        if (lastElement === '.') {
                          handleChange('MinOrder', e.target.value.slice(0, -1));
                        }
                      }}
                      type='text'
                      name='MinOrder'
                      onChange={(e) => {
                        handleChange(
                          'MinOrder',
                          twoDecimalsFormatter(
                            e.target.value,
                            getValue('MinOrder'),
                            9999.99
                          ) || ''
                        );
                      }}
                    />

                    {!!isInvalid(value, 'MinOrder') && (
                      <ErrorSignWrapper topPosition='0px' leftPosition='0px'>
                        <ErrorSign className='smallFont'>
                          {getError('MinOrder')}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    )}
                  </FlexItem>
                </FlexContainer>
              </CardsButton>
            ))}
          </CardsButtonGrid>
          <DialogAction>
            <ButtonPrimary
              type='submit'
              className={
                (isInvalid(selectedDiscountType, 'MinOrder') ||
                  isInvalid(selectedDiscountType, 'Discount')) &&
                'disabled'
              }
              onClick={(e) => submitAction(e)}
            >
              <span>Create Discount</span>
            </ButtonPrimary>
          </DialogAction>
        </>
      }
    />
  );
};

ModalSelectDiscount.propTypes = {
  onCloseAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  getTouched: PropTypes.func.isRequired
};

export default ModalSelectDiscount;
