import {createReducer} from 'redux-act';
import {
  setLevelNamesByCategoryTree,
  setLevelNamesByItemGroupTree,
  setLevelNamesByModifierTree
} from '../../helpers/menuEditor';
import {
  GET_HIDDEN_TAB_SUCCESS,
  GET_HIDDEN_TAB,
  SET_HIDDEN_SECTIONS,
  CLEAR_HIDDEN_SECTIONS,
  SET_HIDDEN_ITEMS,
  CLEAR_HIDDEN_ITEMS,
  SET_HIDDEN_MODIFIERS,
  CLEAR_HIDDEN_MODIFIERS,
  CLEAR_HIDDEN_STORE,
  FETCH_SINGLE_CATEGORY_TREE,
  SET_SINGLE_CATEGORY_TREE,
  FETCH_SINGLE_ITEM_TREE,
  SET_SINGLE_ITEM_TREE,
  FETCH_SINGLE_MODIFIER_TREE,
  SET_SINGLE_MODIFIER_TREE,
  LOAD_HIDDEN_COUNTERS,
  SET_HIDDEN_COUNTERS
} from './action';

const initialState = () => ({
  itemsData: [],
  itemsLoading: false,
  itemsPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  allModifiersLoading: false,
  allModifiersData: [],
  allModifiersPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  overviewLoading: false,
  overviewData: [],
  overviewPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name',
    hasNextPage: true,
    hasPrevPage: false,
    prevPage: 0
  },
  outOfStock: 0,
  sectionsListLoading: false,
  itemsListLoading: false,
  modifiersListLoading: false,
  sectionsList: null,
  itemsList: null,
  modifiersList: null,
  isLoadHiddenCounters: false,
  hiddenCounters: {
    sections: 0,
    items: 0,
    modifiers: 0
  }
});

const menuEditorHiddenReducer = createReducer(
  {
    [SET_HIDDEN_SECTIONS]: (state, payload) => {
      setLevelNamesByCategoryTree(payload, FETCH_SINGLE_CATEGORY_TREE);

      return {
        ...state,
        sectionsList: payload,
        hiddenCounters: {
          ...state.hiddenCounters,
          sections: payload.length
        }
      };
    },
    [SET_SINGLE_CATEGORY_TREE]: (state, payload) => {
      const category = payload;
      let {hiddenCounters} = state;
      let sectionsList = null;

      if (category.Visible) {
        sectionsList = state.sectionsList.filter(
          (item) => item.MerchantSectionId !== category.MerchantSectionId
        );
        hiddenCounters = {
          ...hiddenCounters,
          sections: sectionsList.length
        };
      } else {
        setLevelNamesByCategoryTree([category], FETCH_SINGLE_CATEGORY_TREE);
        sectionsList = state.overviewData.map((item) =>
          item.MerchantSectionId !== category.MerchantSectionId
            ? item
            : category
        );
      }

      return {
        ...state,
        sectionsList,
        hiddenCounters
      };
    },
    [CLEAR_HIDDEN_SECTIONS]: (state) => ({
      ...state,
      sectionsList: [],
      hiddenCounters: {
        ...state.hiddenCounters,
        sections: 0
      }
    }),

    [SET_HIDDEN_ITEMS]: (state, payload) => {
      setLevelNamesByItemGroupTree(payload, FETCH_SINGLE_ITEM_TREE);

      return {
        ...state,
        itemsList: payload,
        hiddenCounters: {
          ...state.hiddenCounters,
          items: payload.length
        }
      };
    },
    [SET_SINGLE_ITEM_TREE]: (state, payload) => {
      const menuItem = payload;
      let {hiddenCounters} = state;
      let itemsList = null;

      if (!menuItem.Invisible) {
        itemsList = state.itemsList.filter(
          (item) => item.PosId !== menuItem.PosId
        );
        hiddenCounters = {
          ...hiddenCounters,
          items: itemsList.length
        };
      } else {
        setLevelNamesByItemGroupTree([menuItem], FETCH_SINGLE_ITEM_TREE);
        itemsList = state.itemsList.map((item) =>
          item.PosId !== menuItem.PosId ? item : menuItem
        );
      }

      return {
        ...state,
        itemsList,
        hiddenCounters
      };
    },
    [CLEAR_HIDDEN_ITEMS]: (state) => ({
      ...state,
      itemsList: [],
      hiddenCounters: {
        ...state.hiddenCounters,
        items: 0
      }
    }),

    [SET_HIDDEN_MODIFIERS]: (state, payload) => {
      setLevelNamesByModifierTree(payload, FETCH_SINGLE_MODIFIER_TREE);

      return {
        ...state,
        modifiersList: payload,
        hiddenCounters: {
          ...state.hiddenCounters,
          modifiers: payload.length
        }
      };
    },
    [SET_SINGLE_MODIFIER_TREE]: (state, payload) => {
      const modifier = payload;
      let {hiddenCounters} = state;
      let modifiersList = null;

      if (!modifier.Invisible) {
        modifiersList = state.modifiersList.filter(
          (item) => item.PosId !== modifier.PosId
        );
        hiddenCounters = {
          ...hiddenCounters,
          modifiers: modifiersList.length
        };
      } else {
        setLevelNamesByModifierTree([modifier], FETCH_SINGLE_MODIFIER_TREE);
        modifiersList = state.modifiersList.map((item) =>
          item.PosId !== modifier.PosId ? item : modifier
        );
      }

      return {
        ...state,
        modifiersList,
        hiddenCounters
      };
    },
    [CLEAR_HIDDEN_MODIFIERS]: (state) => ({
      ...state,
      modifiersList: [],
      hiddenCounters: {
        ...state.hiddenCounters,
        modifiers: 0
      }
    }),

    [LOAD_HIDDEN_COUNTERS]: (state, payload) => ({
      ...state,
      isLoadHiddenCounters: Boolean(payload)
    }),

    [SET_HIDDEN_COUNTERS]: (state, payload) => ({
      ...state,
      hiddenCounters: payload
    }),

    [CLEAR_HIDDEN_STORE]: () => initialState(),

    [GET_HIDDEN_TAB]: (state) => ({
      ...state,
      itemsLoading: true,
      allModifiersLoading: true,
      overviewLoading: true
    }),

    [GET_HIDDEN_TAB_SUCCESS]: (state, payload) => {
      return {
        ...state,
        allModifiersPagination: {
          pages: payload.data.totalPages,
          page: 1,
          limit: 1000,
          total: payload.data.totalDocs,
          sort: 'desc',
          sortBy: 'Name'
        },
        itemsLoading: false,
        allModifiersLoading: false,
        overviewLoading: false,

        itemsData: payload.d2.docs,
        allModifiersData: payload.data.docs,
        itemsPagination: {
          pages: payload.d2.totalPages,
          page: 1,
          limit: 1000,
          total: payload.d2.totalDocs,
          sort: 'desc',
          sortBy: 'Name'
        },
        overviewData: payload.d3.docs,
        overviewPagination: {
          pages: payload.d3.totalPages,
          page: 1,
          limit: 1000,
          total: payload.d3.totalDocs,
          sort: 'desc',
          sortBy: 'Name',
          hasNextPage: true,
          hasPrevPage: false,
          prevPage: 0
        }
      };
    }
  },
  initialState()
);

export default menuEditorHiddenReducer;
