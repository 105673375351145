// eslint-disable-next-line import/prefer-default-export
export const TIMESTAMP = {
  ONE_HOUR: 'one-hour',
  OPENING_TIME: 'opening-time',
  DATE_HOUR: 'date-and-hour',
  CUSTOM_DATES_REQUEST: 'custom-dates-request',
  CUSTOM_DATES_REQUEST_DISABLE: 'custom-dates-request-disable',
  OTHERWISE_CHOSEN: 'otherwise-chosen'
};

export const TIMESTAMP_TYPE = {
  [TIMESTAMP.ONE_HOUR]: 'one-hour',
  [TIMESTAMP.OPENING_TIME]: 'opening-time',
  [TIMESTAMP.DATE_HOUR]: 'date-and-hour',
  [TIMESTAMP.CUSTOM_DATES_REQUEST]: 'custom-dates-request',
  [TIMESTAMP.CUSTOM_DATES_REQUEST_DISABLE]: 'custom-dates-request-disable',
  [TIMESTAMP.OTHERWISE_CHOSEN]: 'otherwise-chosen'
};

export const TIMESTAMP_MODIFIED_NAME = {
  [TIMESTAMP.ONE_HOUR]: 'One Hour',
  [TIMESTAMP.OPENING_TIME]: 'Until Next Opening Time',
  [TIMESTAMP.DATE_HOUR]: 'Specific Date And Hour',
  [TIMESTAMP.OTHERWISE_CHOSEN]: 'Otherwise Chosen'
};
