const replacer = function (key, value) {
  let returnVal = value;
  if (this[key] instanceof Date) {
    returnVal = this[key].toString();
  }

  return this[key] === undefined ? null : returnVal;
};

export const setCustomRange = (name, date) => {
  localStorage.setItem(name, JSON.stringify(date, replacer));
};

export const getCustomRange = (name) => {
  return JSON.parse(localStorage.getItem(name));
};
