import {GET, PUT, PATCH, POST, UPDATE} from '../constants/API_CONSTANTS';
import {
  API_BAD_ORDER,
  API_BAD_ORDER_HISTORY,
  API_BAD_ORDER_DETAILS,
  API_BAD_ORDER_ACTIVE,
  API_DISPATCH_INFO,
  API_BAD_ADDRESSES,
  API_BAD_DETAILS_NOTES,
  API_BAD_ORDER_DELIVERED,
  API_BAD_CHECK_ADDRESS
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getBADOrderApi = async (id) => {
  return request(GET, API_BAD_ORDER_DETAILS(id));
};
export const getBADOrdersActiveApi = async (config) => {
  return request(GET, API_BAD_ORDER_ACTIVE(config), null, {config});
};
export const getBADOrdersDeliveredApi = async (config) => {
  return request(GET, API_BAD_ORDER_DELIVERED(config), null, {config});
};
export const getBADOrdersHistoryApi = async (config) => {
  return request(GET, API_BAD_ORDER_HISTORY(config), null, {config});
};
export const postBADOrderApi = async (data) => {
  return request(POST, API_BAD_ORDER(), {data});
};
export const getAddressesBADApi = async (phone) => {
  return request(GET, API_BAD_ADDRESSES(phone));
};
export const postBADDetailsNotesApi = async (id, data) => {
  return request(POST, API_BAD_DETAILS_NOTES(id), {data});
};
export const postBADCheckAddress = async (data) => {
  return request(POST, API_BAD_CHECK_ADDRESS(), {data});
};
