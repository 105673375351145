import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import DialogTitle from 'components/DialogTitle/styles';
import DialogContainer from 'components/DialogContainer/styles';
import {ButtonOutline} from 'components/buttons';
import DialogBody from 'components/DialogBody/styles';
import {OPEN_ADJUST_SUCCESS_MODAL} from 'store/orders/orderAdjust/action';
import DialogAction from 'components/DialogAction/styles';
import DialogActions from 'components/DialogActions/styles';

const AdjustSuccessModal = ({isOpen}) => {
  const dispatch = useDispatch();
  return (
    <Dialog open={isOpen}>
      <DialogContainer className='DialogSm Overflow'>
        <DialogTitle className='DialogTitleMarginBottom'>
          Order price has been updated
        </DialogTitle>
        <DialogBody>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonOutline
                onClick={() => dispatch(OPEN_ADJUST_SUCCESS_MODAL(false))}
              >
                <span>Ok</span>
              </ButtonOutline>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

AdjustSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};
export default AdjustSuccessModal;
