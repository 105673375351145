import styled from 'styled-components';
import IconClose from '../../assets/icon-close.svg';
import IconCloseWhite from '../../assets/icon-close-white.svg';
import IconCloseRedXl from '../../assets/icon-close-red-xl.svg';

const DialogClose = styled.div`
  position: absolute;
  top: 21px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
  @media (max-width: 767px) {
    top: 12px;
    right: 12px;
  }

  &:hover {
    transform: rotate(90deg);
  }

  &.white {
    background-image: url(${IconCloseWhite});
  }
  &.disable {
    pointer-events: none;
    opacity: 0.4;
  }
  &.xlRed {
    background-image: url(${IconCloseRedXl});
    background-size: 20px;
    @media (max-width: 767px) {
      background-size: 18px;
    }
    &.disable {
      background-image: url(${IconClose});
    }
  }
`;

export default DialogClose;
