import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

export default function RestrictedArea({
  children,
  restrictions: areaRestrictions = [],
  features = [],
  extraAccessLogic = () => true
}) {
  const {userData: {restrictions = []} = {}} = useSelector(
    (state) => state.user
  );

  const enabledFeatures =
    useSelector(
      (state) => state.user?.activeLocation?.AllowedServiceFeatures
    ) || [];

  const intersected = restrictions.filter((restriction) =>
    areaRestrictions.includes(restriction)
  );

  const allowedFeatures = features.filter(
    (feature) => !enabledFeatures.includes(feature)
  );

  return intersected.length > 0 || allowedFeatures.length > 0
    ? null
    : !!extraAccessLogic() && children;
}

RestrictedArea.propTypes = {
  children: PropTypes.element.isRequired,
  restrictions: PropTypes.array.isRequired,
  features: PropTypes.array,
  extraAccessLogic: PropTypes.func
};

RestrictedArea.defaultProps = {
  features: [],
  extraAccessLogic: () => true
};
