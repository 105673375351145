import {put, takeEvery} from 'redux-saga/effects';
import {GET_CUSTOMERS_COUNT, GET_CUSTOMERS_COUNT_SUCCESS} from './action';
import {getCustomersCountApi} from '../../helpers/services/api/analytics';

function* getCustomersCount({payload}) {
  try {
    const {data} = yield getCustomersCountApi(
      payload.startDate,
      payload.endDate
    );
    if (data) {
      yield put(GET_CUSTOMERS_COUNT_SUCCESS(data.data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* analyticsCustomersCountSaga() {
  yield takeEvery(GET_CUSTOMERS_COUNT, getCustomersCount);
}

export default analyticsCustomersCountSaga;
