import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {endOfDay, startOfDay} from 'date-fns';
import {getStorageDate} from '../../helpers/localStorage/DateStorage';

const DatePickerContext = createContext();
const DatePickerContextUpdate = createContext();

export const useDatePickerContext = () => {
  return useContext(DatePickerContext);
};

export const useDatePickerContextUpdate = () => {
  return useContext(DatePickerContextUpdate);
};
export const ThemeProvider = ({children}) => {
  ThemeProvider.propTypes = {
    children: PropTypes.objectOf(PropTypes.any)
  };

  ThemeProvider.defaultProps = {
    children: {}
  };

  const [date, setDate] = useState();
  const updateDate = (arr) => {
    setDate(arr);
  };
  useEffect(() => {
    const savedDate = getStorageDate('Date');

    if (savedDate) {
      setDate([
        savedDate[0] ? new Date(savedDate[0]) : null,
        savedDate[1] ? new Date(savedDate[1]) : null
      ]);
    } else {
      setDate([
        new Date(
          startOfDay(new Date()).getTime() -
            new Date().getTimezoneOffset() * 60000
        ).toISOString(),
        new Date(
          endOfDay(new Date()).getTime() -
            new Date().getTimezoneOffset() * 60000
        ).toISOString()
      ]);
    }
  }, []);
  return (
    <DatePickerContext.Provider value={date}>
      <DatePickerContextUpdate.Provider value={updateDate}>
        {children}
      </DatePickerContextUpdate.Provider>
    </DatePickerContext.Provider>
  );
};
