import React from 'react';

const IconSidebarInsights = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_23805_21371)'>
      <path
        d='M7 2.01658C6.7585 2.01658 6.5625 1.82058 6.5625 1.57908V0.4375C6.5625 0.196 6.7585 0 7 0C7.2415 0 7.4375 0.196 7.4375 0.4375V1.57908C7.4375 1.82058 7.2415 2.01658 7 2.01658Z'
        fill='#929CB9'
      />
      <path
        d='M10.8331 3.6044C10.7211 3.6044 10.6091 3.56182 10.5239 3.47607C10.353 3.30515 10.353 3.02807 10.5239 2.85715L11.3313 2.04982C11.5022 1.8789 11.7793 1.8789 11.9502 2.04982C12.1211 2.22074 12.1211 2.49782 11.9502 2.66873L11.1429 3.47607C11.0571 3.56124 10.9451 3.6044 10.8331 3.6044Z'
        fill='#929CB9'
      />
      <path
        d='M13.5625 7.4375H12.4209C12.1794 7.4375 11.9834 7.2415 11.9834 7C11.9834 6.7585 12.1794 6.5625 12.4209 6.5625H13.5625C13.804 6.5625 14 6.7585 14 7C14 7.2415 13.804 7.4375 13.5625 7.4375Z'
        fill='#929CB9'
      />
      <path
        d='M11.6404 12.078C11.5284 12.078 11.4164 12.0355 11.3313 11.9497L10.5239 11.1424C10.353 10.9715 10.353 10.6944 10.5239 10.5235C10.6949 10.3525 10.9719 10.3525 11.1429 10.5235L11.9502 11.3308C12.1211 11.5017 12.1211 11.7788 11.9502 11.9497C11.8644 12.0355 11.7524 12.078 11.6404 12.078Z'
        fill='#929CB9'
      />
      <path
        d='M2.35947 12.078C2.24747 12.078 2.13547 12.0355 2.05031 11.9497C1.87939 11.7788 1.87939 11.5017 2.05031 11.3308L2.85764 10.5235C3.02856 10.3525 3.30564 10.3525 3.47656 10.5235C3.64747 10.6944 3.64747 10.9715 3.47656 11.1424L2.66922 11.9497C2.58347 12.0355 2.47147 12.078 2.35947 12.078Z'
        fill='#929CB9'
      />
      <path
        d='M1.57908 7.4375H0.4375C0.196 7.4375 0 7.2415 0 7C0 6.7585 0.196 6.5625 0.4375 6.5625H1.57908C1.82058 6.5625 2.01658 6.7585 2.01658 7C2.01658 7.2415 1.82058 7.4375 1.57908 7.4375Z'
        fill='#929CB9'
      />
      <path
        d='M3.16681 3.6044C3.05481 3.6044 2.94281 3.56182 2.85764 3.47607L2.05031 2.66873C1.87939 2.49782 1.87939 2.22074 2.05031 2.04982C2.22122 1.8789 2.49831 1.8789 2.66922 2.04982L3.47656 2.85715C3.64747 3.02807 3.64747 3.30515 3.47656 3.47607C3.39022 3.56124 3.27881 3.6044 3.16681 3.6044Z'
        fill='#929CB9'
      />
      <path
        d='M8.75 12.25V12.9792C8.75 13.5392 8.28917 14 7.72917 14H6.27083C5.78083 14 5.25 13.6267 5.25 12.81V12.25H8.75Z'
        fill='#929CB9'
      />
      <path
        d='M9.5726 3.82668C8.61593 3.05085 7.35593 2.74751 6.1251 3.01001C4.57927 3.33085 3.3251 4.59085 3.00427 6.13668C2.6776 7.72335 3.2726 9.29835 4.54427 10.2608C4.88843 10.5175 5.1276 10.9142 5.20927 11.375V11.3808C5.22093 11.375 5.23843 11.375 5.2501 11.375H8.7501C8.76177 11.375 8.7676 11.375 8.77927 11.3808V11.375C8.86093 10.9317 9.12343 10.5233 9.52593 10.2083C10.5118 9.42668 11.0834 8.26001 11.0834 7.00001C11.0834 5.76335 10.5351 4.60835 9.5726 3.82668ZM9.1876 7.29168C8.94843 7.29168 8.7501 7.09335 8.7501 6.85418C8.7501 5.96751 8.0326 5.25001 7.14593 5.25001C6.90677 5.25001 6.70843 5.05168 6.70843 4.81251C6.70843 4.57335 6.90677 4.37501 7.14593 4.37501C8.51093 4.37501 9.6251 5.48918 9.6251 6.85418C9.6251 7.09335 9.42677 7.29168 9.1876 7.29168Z'
        fill='#929CB9'
      />
      <path
        d='M5.20923 11.375H5.25006C5.2384 11.375 5.2209 11.375 5.20923 11.3808V11.375Z'
        fill='#929CB9'
      />
      <path
        d='M8.77917 11.375V11.3808C8.7675 11.375 8.76167 11.375 8.75 11.375H8.77917Z'
        fill='#929CB9'
      />
    </g>
    <defs>
      <clipPath id='clip0_23805_21371'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconSidebarInsights;
