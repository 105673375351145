import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {ButtonPrimary} from '../../../../components/buttons';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Body3 from '../../../../components/typography/Body3';
import CallIcon from '../../../../assets/uberEats/call.svg';
import EmailIcon from '../../../../assets/uberEats/email.svg';
import SignContractIcon from '../../../../assets/uberEats/sign-contract.svg';
import {ByocItem, ByocItems, IconWrapper} from '../styles';
import Body6 from '../../../../components/typography/Body6';
import Body7 from '../../../../components/typography/Body7';

const ByocFirst = (props) => {
  return (
    <div>
      <Subtitle5>Process Overview</Subtitle5>
      <Body3 className='marginTopMd'>
        Delivering UberEats orders with Sauce couriers takes just a few simple
        steps to activate and can save you up to $[savings] yearly:
      </Body3>
      <ByocItems>
        <ByocItem>
          <IconWrapper wrapper='div' src={CallIcon} marginRight='10px' />
          <Body3>
            <b>Call</b> UberEats support
          </Body3>
        </ByocItem>
        <ByocItem>
          <IconWrapper wrapper='div' src={EmailIcon} marginRight='10px' />
          <Body3>
            <b>Answer</b> the email UberEats sends you.
          </Body3>
        </ByocItem>
        <ByocItem>
          <IconWrapper
            wrapper='div'
            src={SignContractIcon}
            marginRight='10px'
          />
          <Body3>
            <b>Digitally</b> sign a new contract UberEats sends you.
          </Body3>
        </ByocItem>
      </ByocItems>
      <Body7>
        If you have any questions about the process, just click the chat button.
      </Body7>

      {props.actionsRef
        ? ReactDOM.createPortal(
            <ButtonPrimary className='ButtonNav' onClick={props.goNextAction}>
              <span>Next</span>
            </ButtonPrimary>,
            props.actionsRef
          )
        : null}
    </div>
  );
};

ByocFirst.propTypes = {
  actionsRef: PropTypes.object.isRequired,
  goNextAction: PropTypes.func.isRequired
};

export default ByocFirst;
