import {createAction} from 'redux-act';

export const GET_ITEM_BY_ID = createAction('menuEditorItem/GET_ITEM_BY_ID');
export const GET_ITEM_BY_ID_SUCCESS = createAction(
  'menuEditorItem/GET_ITEM_BY_ID_SUCCESS'
);

export const GET_ITEM_MODAL = createAction(
  'menuEditorItemsModal/GET_ITEM_MODAL'
);
export const GET_ITEM_MODAL_SUCCESS = createAction(
  'menuEditorItems/GET_ITEM_MODAL_SUCCESS'
);

export const GET_ITEMS = createAction('menuEditorItems/GET_ITEMS');
export const GET_ITEMS_SUCCESS = createAction(
  'menuEditorItems/GET_ITEMS_SUCCESS'
);

export const POST_ITEM = createAction('menuEditorItem/POST_ITEM');
export const POST_ITEM_SUCCESS = createAction(
  'menuEditorItem/POST_ITEM_SUCCESS'
);

export const UPDATE_ITEM = createAction('menuEditorItem/UPDATE_ITEM');
export const UPDATE_ITEM_SUCCESS = createAction(
  'menuEditorItem/UPDATE_ITEM_SUCCESS'
);

export const PATCH_ITEM = createAction('menuEditorItem/PATCH_ITEM');
export const PATCH_ITEM_SUCCESS = createAction(
  'menuEditorItem/PATCH_ITEM_SUCCESS'
);

export const DELETE_ITEM = createAction('menuEditorItem/DELETE_ITEM');
export const DELETE_ITEM_SUCCESS = createAction(
  'menuEditorItem/DELETE_ITEM_SUCCESS'
);

export const CLEAR_DATA = createAction('menuEditorItem/CLEAR_DATA');

export const CLEAR_ITEM_BY_ID = createAction('menuEditorItem/CLEAR_ITEM_BY_ID');
export const FETCH_SINGLE_ITEM_TREE = createAction(
  'menuEditorItem/FETCH_SINGLE_ITEM_TREE'
);
export const FETCH_SINGLE_ITEM_SUCCESS = createAction(
  'menuEditorItem/FETCH_SINGLE_ITEM_SUCCESS'
);
