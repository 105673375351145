import styled from 'styled-components';

const Subtitle6 = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #232d3a;
  line-height: 1.3;

  span {
    color: #ff2d38;
  }

  &.SubtitleMobSmall {
    @media (max-width: 599px) {
      font-size: 18px;
    }
  }
`;

export default Subtitle6;
