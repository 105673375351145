import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {GET_ORDER_SUCCESS as GET_ORDER_SUCCESS_LIST} from '../../../../store/orders/orderList/action';
import {ORDER, ORDER_STATUS} from '../../../../helpers/constants/orders';
import OrdersTable from '../components/OrdersTable';
import {
  CHANGE_STATUS_ACTIVE,
  RUN_GET_ACTIVE_ORDERS_JOB,
  SET_ORDERS_SORTING,
  STOP_GET_ACTIVE_ORDERS_JOB
} from '../../../../store/global/orders/action';
import {
  CHANGE_STATUS,
  GET_ORDER_SUCCESS
} from '../../../../store/orders/orderDetails/action';
import {OrdersContainer} from './styles';
import {OPEN_CANCELLATION_CONFIRMATION_MODAL} from '../../../../store/global/modals/action';

const ActiveOrders = ({print}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isNetworkOnline = useSelector(
    (state) => state.globalNotificationsReducer.isNetworkOnline
  );
  const {sort, sortBy} = useSelector(
    (state) => state.activeOrdersReducer.sorting
  );
  const activeOrders = useSelector(
    (state) => state.activeOrdersReducer.activeOrders
  );
  const loading = useSelector((state) => state.activeOrdersReducer.loading);
  const changeStatusLoading = useSelector(
    (state) => state.activeOrdersReducer.changeStatusLoading
  );
  const Timezone = useSelector((state) => state.user?.activeLocation.Timezone);

  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );
  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );

  useEffect(() => {
    dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
    dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
  }, []);

  const onStatusChange = (id, status, previousStatus, deliveryProvider) => {
    if (status !== previousStatus && !changeStatusLoading) {
      if (status === ORDER_STATUS.CANCELLED) {
        dispatch(
          OPEN_CANCELLATION_CONFIRMATION_MODAL({
            isOpen: true,
            info: {id, status, previousStatus},
            source: 'active',
            deliveryProvider
          })
        );
      } else {
        dispatch(CHANGE_STATUS_ACTIVE({id, status, previousStatus}));
        dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
        dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
      }
    }
  };

  const openDetails = (data) => {
    dispatch(GET_ORDER_SUCCESS({Timezone, ...data}));
    history.push(`/orders/active/${data[ORDER.ID]}/details`, {
      windowPosition: window.scrollY
    });
  };

  const printOrder = (data) => {
    dispatch(GET_ORDER_SUCCESS_LIST(data));
    setTimeout(() => print(), 500);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property.sortBy && sort === 'asc';
    dispatch(
      SET_ORDERS_SORTING({
        sort: isAsc ? 'desc' : 'asc',
        sortBy: property.sortBy
      })
    );
  };

  function descendingComparator(a, b, descendingOrderBy) {
    if (b[descendingOrderBy] < a[descendingOrderBy]) {
      return -1;
    }
    if (b[descendingOrderBy] > a[descendingOrderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(comparatorOrder, comparatorOrderBy) {
    return comparatorOrder === 'desc'
      ? (a, b) => descendingComparator(a, b, comparatorOrderBy)
      : (a, b) => -descendingComparator(a, b, comparatorOrderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sortOrder = comparator(a[0], b[0]);
      if (sortOrder !== 0) return sortOrder;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => {
    handleRequestSort(property);
  };

  const onDeliverWithUsClick = () => {
    history.push('/uber-eats');
  };
  return (
    <OrdersContainer>
      <OrdersTable
        onStatusChange={onStatusChange}
        openDetails={openDetails}
        printOrder={printOrder}
        orders={stableSort(
          activeOrders || [],
          getComparator(sort, sortBy === 'eta_time_for_merchant' && 'date')
        )}
        onSort={createSortHandler}
        orderBy={sortBy}
        orderDirection={sort}
        loading={loading}
        isNetworkOnline={isNetworkOnline}
        changeStatusLoading={changeStatusLoading}
        onDeliverWithUsClick={onDeliverWithUsClick}
        showDeliverWithUsButton={UEConnected && !BYOCconnected}
        showSavingPigIcon={BYOCconnected}
      />
    </OrdersContainer>
  );
};

ActiveOrders.propTypes = {
  print: PropTypes.func.isRequired
};

export default ActiveOrders;
