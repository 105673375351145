import React from 'react';

export const IconPercent = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_8793_3988)'>
      <path
        d='M2.41884 21.5337C2.15239 21.2667 2.00342 20.9023 2.00342 20.5216C2.00342 20.1339 2.15122 19.7777 2.42757 19.5008L19.4194 2.47465C19.6858 2.20768 20.0493 2.05859 20.4287 2.05859C20.8082 2.05859 21.1716 2.20768 21.4381 2.47465C21.7045 2.74164 21.8535 3.10607 21.8535 3.48674C21.8535 3.8674 21.7045 4.23183 21.4381 4.49882L4.43751 21.5337C4.17108 21.8007 3.80762 21.9498 3.42818 21.9498C3.04873 21.9498 2.68528 21.8007 2.41884 21.5337Z'
        fill='#929CB9'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        d='M11.6488 5.12207C11.6488 7.8076 9.46617 9.9939 6.78728 9.9939C4.1084 9.9939 1.92578 7.8076 1.92578 5.12207C1.92578 2.43654 4.1084 0.250244 6.78728 0.250244C9.46617 0.250244 11.6488 2.43654 11.6488 5.12207ZM6.78728 3.11527C5.68052 3.11527 4.78402 4.0143 4.78402 5.12207C4.78402 6.22984 5.68052 7.12888 6.78728 7.12888C7.89405 7.12888 8.79055 6.22984 8.79055 5.12207C8.79055 4.0143 7.89405 3.11527 6.78728 3.11527Z'
        fill='#929CB9'
        stroke='white'
        strokeWidth='0.5'
      />
      <path
        d='M12.2271 18.8782C12.2271 16.1926 14.4097 14.0063 17.0886 14.0063C19.7674 14.0063 21.9501 16.1926 21.9501 18.8782C21.9501 21.5637 19.7674 23.75 17.0886 23.75C14.4097 23.75 12.2271 21.5637 12.2271 18.8782ZM17.0886 20.8937C18.1953 20.8937 19.0918 19.9947 19.0918 18.8869C19.0918 17.7791 18.1953 16.8801 17.0886 16.8801C15.9818 16.8801 15.0853 17.7791 15.0853 18.8869C15.0853 19.9947 15.9818 20.8937 17.0886 20.8937Z'
        fill='#929CB9'
        stroke='white'
        strokeWidth='0.5'
      />
    </g>
    <defs>
      <clipPath id='clip0_8793_3988'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconPercent;
