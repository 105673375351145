import React from 'react';
import {Tab, Tabs} from '@material-ui/core';
import {Link, useLocation, useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import IconHidden from '../../../assets/IconHidden';
import {DashboardTabs} from '../dashboard/styles';

export default function MenuTabs() {
  const {url} = useRouteMatch();
  const location = useLocation();

  const sectionsDirection = useSelector(
    (state) => state.menuEditorReducer.sectionsDirection
  );
  const itemsDirection = useSelector(
    (state) => state.menuEditorReducer.itemsDirection
  );
  const groupsDirection = useSelector(
    (state) => state.menuEditorReducer.groupsDirection
  );
  const modifiersDirection = useSelector(
    (state) => state.menuEditorReducer.modifiersDirection
  );

  return (
    <DashboardTabs className='TabsMenuEditor'>
      <Tabs value={location.pathname}>
        <Tab
          label={`Categories (${sectionsDirection.length})`}
          value={`${url}/categories`}
          component={Link}
          to={`${url}/categories`}
        />
        <Tab
          label={`Items (${itemsDirection.length})`}
          value={`${url}/items`}
          component={Link}
          to={`${url}/items`}
        />
        <Tab
          label={`Modifier Groups (${groupsDirection.length})`}
          value={`${url}/groups`}
          component={Link}
          to={`${url}/groups`}
        />
        <Tab
          label={`Modifiers (${modifiersDirection.length})`}
          value={`${url}/modifiers`}
          component={Link}
          to={`${url}/modifiers`}
        />
        <Tab
          icon={<div className='TabOutOfStockNumber'>86</div>}
          label='Out of Stock'
          value={`${url}/out-of-stock`}
          className='TabOutOfStock'
          component={Link}
          to={`${url}/out-of-stock`}
        />
        <Tab
          icon={
            <div className='TabHiddenIcon'>
              <IconHidden />
            </div>
          }
          label='Hidden'
          value={`${url}/hidden`}
          className='TabHidden'
          component={Link}
          to={`${url}/hidden`}
        />
      </Tabs>
    </DashboardTabs>
  );
}
