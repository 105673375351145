import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Body2 = styled(AbstractText)`
  font-size: 12px;
  font-weight: normal;

  &.SelectItemCategory {
    padding: 10px 10px 0 17px;
    color: #929cb9;
  }
`;

export default Body2;
