export const REFUND = {
  ID: 'id',
  NAME: 'name',
  PRICE: 'pricePerUnit',
  PRICE_WITH_TAX: 'amount',
  QUANTITY: 'quantity',
  REFUND_QUANTITY: 'selected',
  REFUNDAMOUNT: 'refundAmount',
  TOTAL: 'total'
};

export default {REFUND};
