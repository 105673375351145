import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Dialog from '@material-ui/core/Dialog';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DialogAction,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogHint,
  DialogLinkButton,
  DialogLinkInner,
  FieldInput,
  FieldLabel,
  FieldTextarea
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  CREATE_ITEM_MODAL,
  CREATE_MODIFIER_GROUP_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import FileUploader from '../../../../components/FileUploader';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {POST_MODIFIER_GROUP} from '../../../../store/menuEditorModifierGroups/action';
import RulesFormField from '../../../../components/RulesFormField';
import {validate} from '../../../../helpers/services/formValidationSchemas/ModifierGroupSchema';
import {ButtonInfo} from '../../../../components/buttons';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

const CreateModifierGroupModal = () => {
  const dispatch = useDispatch();

  const isCreateModifierGroupModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateModifierGroupModalOpen
  );
  const getNewModifierGroupFormData = useSelector(
    (state) => state.menuEditorModalsReducer.getNewModifierGroupFormData
  );

  const itemsMap = useSelector((state) => state.menuEditorReducer.itemsMap);
  const itemsDirection = useSelector(
    (state) => state.menuEditorReducer.itemsDirection
  );
  const itemsList = itemsDirection.map((PosId) => itemsMap[PosId]);

  const modifiersMap = useSelector(
    (state) => state.menuEditorReducer.modifiersMap
  );
  const modifiersDirection = useSelector(
    (state) => state.menuEditorReducer.modifiersDirection
  );
  const modifiersList = modifiersDirection.map((PosId) => modifiersMap[PosId]);

  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [expandedModifier, setExpandedModifier] = useState(false);

  useEffect(() => {
    return () => {
      setExpanded(false);
      setExpandedModifier(false);
      setSelectedItems([]);
      setSelectedModifiers([]);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      OptionName: '',
      Notes: '',
      ExternalId: '',
      ExternalData: '',
      PictureURL: fileUrl,
      MinSelection: 0,
      MaxSelection: -1,
      isRequiredItems: false,
      isRequiredUnlimited: true,
      MinItems: [],
      MaxItems: []
    },
    validate: (values) => validate(values, selectedModifiers),
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      if (typeof getNewModifierGroupFormData === 'function' && !formik.errors) {
        getNewModifierGroupFormData({...values});

        dispatch(
          CREATE_ITEM_MODAL({
            open: true
          })
        );
      } else {
        dispatch(
          POST_MODIFIER_GROUP({
            ...values,
            OptionName: String(values.OptionName).trim(),
            PictureURL: fileUrl,
            MenuItems: [...selectedItems.map((item) => item.PosId)],
            Items: [...selectedModifiers.map((modifier) => modifier.PosId)]
          })
        );
        sendGtmEvent('menuEditorNewEntityAdded', {
          entity: 'modifier group'
        });
      }

      dispatch(CREATE_MODIFIER_GROUP_MODAL({open: false}));
      resetForm();
    }
  });

  useEffect(() => {
    if (selectedModifiers.length > 0) {
      setExpandedModifier('modifiers');
    }
    if (selectedItems.length > 0) {
      setExpanded('items');
    }

    formik.setErrors({...formik.errors, MaxSelection: '', MinSelection: ''});
  }, [
    selectedModifiers,
    selectedItems,
    formik.values.MinSelection,
    formik.values.MaxSelection
  ]);

  const removeElement = (id, array, setState) => {
    const filter = array.filter((group) => group.PosId !== id);
    setState(filter);
  };

  const handleAccordionItemsChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAccordionModifiersChange = (panel) => (event, isExpanded) => {
    setExpandedModifier(isExpanded ? panel : false);
  };

  useDidMountEffect(() => {
    if (formik.values.isRequiredUnlimited === false) {
      formik.setFieldValue('MaxSelection', 1);
    }
    if (formik.values.isRequiredUnlimited) {
      formik.setFieldValue('MaxSelection', -1);
    }
  }, [formik.values.isRequiredUnlimited]);

  useDidMountEffect(() => {
    if (formik.values.isRequiredItems === false) {
      formik.setFieldValue('MinSelection', 0);
      formik.setFieldError('MinSelection', '');
    }

    if (formik.values.isRequiredItems === true) {
      formik.setFieldValue('MinSelection', 1);
      formik.setFieldError('MinSelection', '');
    }
  }, [formik.values.isRequiredItems]);

  return (
    <Dialog
      onClose={() => dispatch(CREATE_MODIFIER_GROUP_MODAL({open: false}))}
      open={isCreateModifierGroupModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogXl'>
        <DialogClose
          onClick={() => dispatch(CREATE_MODIFIER_GROUP_MODAL({open: false}))}
        />
        <DialogTitle className='verticalCenter'>
          Create a <span>Modifier Group</span>
          <ButtonInfo className='Outline ButtonInfoDrop'>
            <div className='DropInfo DropInfoCenter'>
              <ul className='mainItems'>
                <li>
                  <strong>Modifier Groups </strong>
                  are sets of individual Modifier options. To organize your
                  Modifier Options, you can use Modifier Groups.
                </li>
              </ul>
              <p>
                Modifier Groups such as these WILL NOT appear on receipts or
                tickets.
              </p>
              <ul className='childItems'>
                <li>
                  <i>
                    Salad Dressing Choice or Side Dishes Choice is a Modifier
                    Group in this example.
                  </i>
                </li>
              </ul>
            </div>
          </ButtonInfo>
        </DialogTitle>

        <form onSubmit={formik.handleSubmit}>
          <DialogFields>
            <DialogField className='w50'>
              <FieldLabel className='FieldLabelLg'>
                Modifier Group name
              </FieldLabel>
              <FieldInput
                className={
                  formik.errors.OptionName &&
                  formik.touched.OptionName &&
                  'error'
                }
                name='OptionName'
                value={formik.values.OptionName}
                onChange={formik.handleChange}
                placeholder='Write a modifier group name here'
              />
              <ErrorSignWrapper>
                {formik.errors.OptionName && formik.touched.OptionName && (
                  <ErrorSign>{formik.errors.OptionName}</ErrorSign>
                )}
              </ErrorSignWrapper>
            </DialogField>
          </DialogFields>

          <DialogFields className='DialogFieldsSeparate'>
            <RulesFormField formik={formik} />
          </DialogFields>

          <DialogFields>
            <DialogField className='w50'>
              <FieldLabel>Photo</FieldLabel>
              <FileUploader
                url={fileUrl}
                onSetFile={setFileUrl}
                allowedFileFormats={['image/png', 'image/jpeg', 'image/jpg']}
              />
            </DialogField>
            <DialogField className='w50 pTop50'>
              <DialogHint>
                <span>
                  Add the unique identifier you can use at your restaurant (ex.
                  SKU)
                </span>
              </DialogHint>
              <DialogFieldInners className='DialogFieldInnersGroup'>
                <DialogFieldInner className='w50'>
                  <FieldLabel>External ID</FieldLabel>
                  <FieldInput
                    name='ExternalId'
                    onChange={formik.handleChange}
                    value={formik.values.ExternalId}
                    placeholder='External ID'
                  />
                </DialogFieldInner>
                <DialogFieldInner className='w50'>
                  <FieldLabel>Exernal Data</FieldLabel>
                  <FieldInput
                    name='ExternalData'
                    onChange={formik.handleChange}
                    value={formik.values.ExternalData}
                    placeholder='Exernal Data'
                  />
                </DialogFieldInner>
              </DialogFieldInners>
            </DialogField>
            <DialogField className='w100'>
              <FieldLabel className='FieldLabelLg'>
                Notes (for internal use)
              </FieldLabel>
              <FieldTextarea
                className={formik.errors.Notes && 'error'}
                name='Notes'
                value={formik.values.Notes}
                onChange={formik.handleChange}
                placeholder='Write your notes here'
              />

              <ErrorSignWrapper>
                <ErrorSign>{formik.errors.Notes}</ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
          </DialogFields>

          <DialogFields className='DialogFieldsSeparate'>
            <DialogField className='w50 Say2EatSelect'>
              <DialogFields>
                {!!selectedModifiers.length && (
                  <DialogField className='w100'>
                    <AccordionItem className='AccordionItemModal'>
                      <Accordion
                        onChange={handleAccordionModifiersChange('modifiers')}
                        expanded={
                          expandedModifier === 'modifiers' ||
                          expandedModifier === true
                        }
                      >
                        <AccordionSummary
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <AccordionItemName className='AccordionItemName'>
                            {`Modifiers including (${selectedModifiers.length})`}
                          </AccordionItemName>
                        </AccordionSummary>
                        <AccordionItemBody>
                          <DialogFields>
                            {selectedModifiers.map((group) => (
                              <DialogField className='w100 withRemove noPaddingLeft'>
                                <FieldInput
                                  name=''
                                  readOnly
                                  value={group.Name}
                                  placeholder={group.Name}
                                />
                                <DialogFieldRemove
                                  onClick={() =>
                                    removeElement(
                                      group.PosId,
                                      selectedModifiers,
                                      setSelectedModifiers
                                    )
                                  }
                                />
                              </DialogField>
                            ))}
                          </DialogFields>
                        </AccordionItemBody>
                      </Accordion>
                    </AccordionItem>
                  </DialogField>
                )}
                <DialogLinkInner>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          selectedItems: selectedModifiers,
                          queryParams: {limit: 1000, outStock: 'all'},
                          itemType: 'modifier',
                          itemList: modifiersList,
                          loading: false,
                          callBack: setSelectedModifiers,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add a Modifier
                  </DialogLinkButton>
                </DialogLinkInner>
              </DialogFields>
            </DialogField>

            <DialogField className='w50 Say2EatSelect'>
              <DialogFields>
                {!!selectedItems.length && (
                  <DialogField className='w100'>
                    <AccordionItem className='AccordionItemModal'>
                      <Accordion
                        onChange={handleAccordionItemsChange('items')}
                        expanded={expanded === 'items' || expanded === true}
                      >
                        <AccordionSummary
                          aria-controls='panel1a-content'
                          id='panel1a-header'
                        >
                          <AccordionItemName className='AccordionItemName'>
                            {`Parent Items (${selectedItems.length})`}
                          </AccordionItemName>
                        </AccordionSummary>
                        <AccordionItemBody>
                          <DialogFields>
                            {selectedItems.map((group) => (
                              <DialogField className='w100 withRemove noPaddingLeft'>
                                <FieldInput
                                  name=''
                                  readOnly
                                  value={group.Name}
                                  placeholder={group.Name}
                                />
                                <DialogFieldRemove
                                  onClick={() =>
                                    removeElement(
                                      group.PosId,
                                      selectedItems,
                                      setSelectedItems
                                    )
                                  }
                                />
                              </DialogField>
                            ))}
                          </DialogFields>
                        </AccordionItemBody>
                      </Accordion>
                    </AccordionItem>
                  </DialogField>
                )}

                <DialogLinkInner>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          selectedItems,
                          itemType: 'item',
                          itemList: itemsList,
                          loading: false,
                          callBack: setSelectedItems,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add parent Item
                  </DialogLinkButton>
                </DialogLinkInner>
              </DialogFields>
            </DialogField>
          </DialogFields>

          <DialogAction>
            <ButtonPrimary type='submit'>
              <span>Create a Modifier Group</span>
            </ButtonPrimary>
          </DialogAction>
        </form>
      </DialogContainer>
    </Dialog>
  );
};

export default memo(CreateModifierGroupModal);
