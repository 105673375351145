import styled from 'styled-components';
import {
  ExpandLess as MaterialLess,
  ExpandMore as MaterialMore
} from '@material-ui/icons';

export const ExpandMore = styled(MaterialMore)`
  cursor: pointer;
  position: relative;
  top: 2px;
`;

export const ExpandLess = styled(MaterialLess)`
  cursor: pointer;
  position: relative;
  top: 2px;
`;

export const FileLink = styled.a`
  font-size: 12px;
  color: #4b5963;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }
`;
