import {useSelector} from 'react-redux';

const appVersionData = () =>
  useSelector((state) => {
    return {
      open: state.appVersionReducer.isNotificationModalOpen
    };
  });

export default appVersionData;
