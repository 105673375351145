import styled from 'styled-components';
import IconPublish from '../../assets/icon-publish.svg';
import BgPublish from '../../assets/bg-publish.svg';

export const PublishProgressContainer = styled.div`
  position: fixed;
  top: 74px;
  left: 272px;
  width: calc(100% - 272px);
  z-index: 9;
  @media (max-width: 1250px) {
    top: 96px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 70px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }

  .ProgressBar {
    .ProgressBarContainer {
      border-radius: 0;
      background-color: #ffcb66;

      & > div {
        position: relative;
        overflow: hidden;

        @media (max-width: 767px) {
          height: 40px !important;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          content: '';
          background-image: url('${BgPublish}');
          background-repeat: repeat-x;
          background-position: center;
          animation: progressBarStripes 1.5s linear infinite;
        }
      }
    }

    .publishLabel {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      min-width: 120px;
      line-height: 1;
      color: #fff;
      padding: 0 26px 0 51px;
      position: relative;
      z-index: 2;
      @media (max-width: 767px) {
        padding: 0 20px 0 40px;
        font-size: 17px;
        min-width: 96px;
      }
      @media (max-width: 599px) {
        padding: 0 15px 0 35px;
      }
      @media (max-width: 440px) {
        padding: 0 10px 0 35px;
      }

      &::before {
        position: absolute;
        top: 50%;
        margin-top: -18px;
        left: 0;
        content: '';
        width: 36px;
        height: 36px;
        background-image: url('${IconPublish}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        animation: AnimateCircle 2.7s infinite linear;
        @media (max-width: 767px) {
          width: 26px;
          height: 26px;
          margin-top: -13px;
        }
      }
    }
  }

  &.animateStop {
    .ProgressBar {
      .ProgressBarContainer {
        & > div {
          &::before {
            animation: none;
          }
        }
      }
      .publishLabel {
        &::before {
          animation: none;
        }
      }
    }
  }

  @keyframes AnimateLinear {
    from {
      transform-origin: 0 0;
      transform: translateX(0);
    }
    to {
      transform-origin: 0 0;
      transform: translateX(100%);
    }
  }

  @keyframes progressBarStripes {
    from {
      background-position-x: 0;
    }
    to {
      background-position-x: 5rem;
    }
  }

  @keyframes AnimateCircle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const UnPublishContainer = styled.div`
  left: 272px;
  top: 74px;
  height: 60px;
  width: calc(100% - 272px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #ff2d38;
  z-index: 6;
  padding: 8px 20px;
  @media (max-width: 1250px) {
    top: 96px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 70px;
    height: 40px;
    padding: 5px 20px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }
  & > div {
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1300px) {
      font-size: 18px;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }
  svg {
    margin-right: 14px;
    flex: 0 0 auto;
    @media (max-width: 1000px) {
      width: 30px;
      height: 35px;
    }
    @media (max-width: 767px) {
      width: 24px;
      height: 29px;
      margin-right: 10px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }

  a.Lg {
    margin-left: 5px;
    @media (max-width: 1440px) {
      font-size: 18px;
    }
    @media (max-width: 1300px) {
      font-size: 18px;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }
`;

export const UnderlineButton = styled.button`
  display: inline-block;
  font-style: normal;
  border: none;
  border-bottom: 1px solid #fff;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  font-family: 'Poppins';
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
  line-height: 1;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
  &:hover {
    border-bottom-color: transparent;
  }
  &.noClickEvent {
    pointer-events: none;
    border: none;
  }
`;
