import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';

export const RefundTableWrapper = styled.div`
  //height: 400px;
  //overflow: auto;
  .MuiTable-root {
    tr {
      &:first-child {
        th {
          &:nth-child(1) {
            width: 70%;
            @media (max-width: 1250px) {
              width: 35%;
            }
          }

          &:nth-child(2) {
            width: 20%;
            @media (max-width: 1250px) {
              width: 60%;
            }
          }

          &:nth-child(3) {
            width: 10%;
            @media (max-width: 1250px) {
              width: 5%;
            }
          }
        }
      }
    }
  }
  &.OrdersTableWrapper {
    thead {
      th {
        &:nth-child(2) {
          text-align: center;
          padding: 0 !important;
          width: 0 !important;
        }
        &:nth-child(3) {
          text-align: center;
          width: 0 !important;
          padding: 0 63px 0 0 !important;
        }
      }
    }
    tbody {
      tr {
        height: 71px;
        td {
          &:first-child {
            padding-left: 36px;
          }
          &:nth-child(3) {
            padding: 0 63px 0 0 !important;
            width: 0 !important;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const RefundTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 94px 0 36px;
  margin-bottom: -12px;
  .RefundItemName {
    flex: 1 1 auto;
  }
  .RefundItemCount {
    flex: 0 0 auto;
    width: 120px;
    text-align: center;
  }
  .RefundItemPrice {
    flex: 0 0 auto;
    width: 120px;
    text-align: center;
  }
`;
export const RefundTableEl = styled(Accordion)`
  width: 100%;
  padding: 3px 62px 12px 36px;
  border-radius: 10px;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.2),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  margin: 20px 0;
  // display: flex;
  //align-items: center;
  // justify-content: space-between;
  &.MuiAccordion-rounded {
    border-radius: 10px !important;
    position: static;
  }
  .RefundAccordionSummary {
    display: flex;
    justify-content: space-between;
    height: 100%;
    &.disabled {
      cursor: default;
      .MuiAccordionSummary-content {
        cursor: default;
      }
      .MuiAccordionSummary-expandIcon {
        opacity: 0;
        pointer-events: none;
        cursor: default;
        visibility: hidden;
      }
    }
  }
  &.MuiAccordion-root {
    &.Mui-expanded {
      margin: 20px 0;
      &:first-child {
        margin-top: 20px;
      }
    }
  }
  .MuiAccordionSummary-root {
    padding: 0;
    min-height: 48px;
    &.Mui-expanded {
      min-height: 48px;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiCollapse-container {
    margin: -6px 0;
  }
  .MuiAccordionDetails-root {
    padding: 6px 28px 6px 0;
  }
  .RefundItemName {
    flex: 1 1 auto;
    .titleBold {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .RefundItemCount {
    flex: 0 0 auto;
    width: 120px;
  }
  .RefundItemPrice {
    flex: 0 0 auto;
    width: 120px;
  }
`;
