import React from 'react';
import PropTypes from 'prop-types';
import {ButtonPrimary} from '../../../../components/buttons';
import SuccessImageGif from '../../../../assets/bakery-red.gif';
import {DialogAction, DialogSubTitle} from '../../dashboard/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import Modal from '../../../../components/Modals/Modal';
import {SuccessPhoto} from '../styles';

export default function SuccessActivateVN({onClose}) {
  return (
    <Modal
      onCloseAction={onClose}
      title={
        <>
          <DialogSubTitle className='mBottomLarge'>
            <SuccessPhoto src={SuccessImageGif} />
          </DialogSubTitle>
          <DialogTitle className='memberConfirmation'>
            Well done!
            <br />
            Get ready for more orders
          </DialogTitle>
        </>
      }
      body={
        <DialogAction className='mTopLarge'>
          <ButtonPrimary className='ButtonMin2' onClick={onClose}>
            <span>Ok</span>
          </ButtonPrimary>
        </DialogAction>
      }
    />
  );
}

SuccessActivateVN.propTypes = {
  onClose: PropTypes.func
};

SuccessActivateVN.defaultProps = {
  onClose: () => {}
};
