import {createReducer} from 'redux-act';
import moment from 'moment';
import {
  FIND_DELIVERY_CLEAR_ORDER,
  FIND_DELIVERY_GET_ORDER,
  FIND_DELIVERY_GET_ORDER_SUCCESS,
  FIND_DELIVERY_SET_ERROR,
  FIND_DELIVERY_SET_LOADING
} from 'store/findDelivery/action';
import {
  FIND_DELIVERY,
  FIND_DELIVERY_STATUS,
  FIND_DELIVERY_TRACKING_LINKS_TYPES
} from 'helpers/constants/findDelivery';

const initialState = () => ({
  orderData: null,
  loading: false,
  findError: null
});

const checkIsFutureOrder = (pickup_time) => {
  const currentDateUTC = moment().utc();
  return moment(pickup_time).diff(currentDateUTC, 'minutes') > 60;
};

const findDeliveryReducer = createReducer(
  {
    [FIND_DELIVERY_GET_ORDER]: (state) => {
      return {
        ...state,
        orderData: null,
        loading: true
      };
    },
    [FIND_DELIVERY_GET_ORDER_SUCCESS]: (state, payload) => {
      let order;
      if (Array.isArray(payload) && payload?.length === 1) {
        order = payload[0];
      } else if (Array.isArray(payload) && payload?.length > 1) {
        const nonCancelledOrders = payload?.find(
          (item) =>
            item[FIND_DELIVERY.STATUS]?.type !== FIND_DELIVERY_STATUS.CANCELLED
        );
        if (nonCancelledOrders) {
          order = nonCancelledOrders;
        } else {
          order = payload[0];
        }
      } else {
        order = null;
      }
      return {
        ...state,
        orderData: order
          ? {
              ...order,
              id: order.id,
              status: checkIsFutureOrder(order.pickup?.eta)
                ? {type: 'Future'}
                : order.status,
              restaurantName: order.location?.name,
              restaurantTier: order.location?.tier,
              restaurantTimezone: order.location?.timezone?.offset,
              restaurantFirst10Orders: order?.location?.servedFirst10Orders,
              customerName: order?.customer?.name,
              customerPhone: order?.customer?.phone,
              courierDistanceToRestaurant: order.delivery?.distanceMeters,
              ...(order.trackingLinks && {
                userTrackingLink:
                  order.trackingLinks.find(
                    (el) => el.type === FIND_DELIVERY_TRACKING_LINKS_TYPES.USER
                  )?.url || null
              }),
              ...(order.trackingLinks && {
                restaurantTrackingLink:
                  order.trackingLinks.find(
                    (el) =>
                      el.type === FIND_DELIVERY_TRACKING_LINKS_TYPES.RESTAURANT
                  )?.url || null
              }),
              ...(order.trackingLinks && {
                oldDispatchProviderTrackingLink:
                  order.trackingLinks.find(
                    (el) =>
                      el.type ===
                      FIND_DELIVERY_TRACKING_LINKS_TYPES.OLD_PROVIDER
                  )?.url || null
              }),
              ...(order.trackingLinks && {
                newDispatchProviderTrackingLink:
                  order.trackingLinks.find(
                    (el) =>
                      el.type ===
                      FIND_DELIVERY_TRACKING_LINKS_TYPES.NEW_PROVIDER
                  )?.url || null
              })
            }
          : null,
        loading: payload
      };
    },
    [FIND_DELIVERY_SET_LOADING]: (state, payload) => {
      return {
        ...state,
        loading: payload
      };
    },
    [FIND_DELIVERY_SET_ERROR]: (state, payload) => {
      return {
        ...state,
        findError: payload
      };
    },
    [FIND_DELIVERY_CLEAR_ORDER]: () => {
      return {
        ...initialState()
      };
    }
  },
  initialState()
);

export default findDeliveryReducer;
