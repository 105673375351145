import React, {useEffect} from 'react';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import Headline from 'components/Headline/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import ImageIntegrationsGoogle from 'assets/image-integrations-google.png';
import ImageIntegrationsFacebook from 'assets/image-integrations-facebook.png';
import {useDispatch, useSelector} from 'react-redux';
import {
  MarketingIntegrationsBlock,
  MarketingIntegrationsCheck,
  MarketingIntegrationsCheckBlock,
  MarketingIntegrationsContainer,
  MarketingIntegrationsImage,
  MarketingIntegrationsText,
  MarketingIntegrationsTitle
} from 'routes/App/MarketingIntegrations/MainPage/styles';
import IconCheck from 'assets/icon-marketing-integrations-check.svg';
import {
  MARKETING_INTEGRATIONS_DISABLE,
  MARKETING_INTEGRATIONS_GET_INFO,
  MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE
} from 'store/marketingIntegrations/action';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import FullScreenLoading from 'components/shared/Utils/FullScreenLoading/FullScreenLoading';

const MarketingIntegrationsMainPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {locationId} = useSelector((state) => state.user);
  const {googleTagId, metaPixelId, loading} = useSelector(
    (state) => state.marketingIntegrationsReducer
  );

  useEffect(() => {
    dispatch(MARKETING_INTEGRATIONS_GET_INFO());
  }, [locationId]);

  const redirectToDetails = (page) => {
    dispatch(MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE(page));
    history.push('/marketing-integrations/details');
  };

  return (
    <>
      <DashboardContent>
        <DashboardHeader>
          <Headline className='mTop5'>Marketing Integrations</Headline>
        </DashboardHeader>
        <MarketingIntegrationsContainer>
          <DashboardInner
            className={cn(
              'DashboardInnerW50 PaddingMarketingIntegrations withoutShadow',
              {
                CheckedIntegrationsBlock: googleTagId
              }
            )}
          >
            <MarketingIntegrationsBlock>
              <div>
                <MarketingIntegrationsImage
                  src={ImageIntegrationsGoogle}
                  className={cn('', {CheckedIntegrations: googleTagId})}
                />
              </div>
              {googleTagId ? (
                <DialogActions className='w100 DialogActionsNoWrap justifySpaceBetween'>
                  <DialogAction className='w30'>
                    <ButtonOutline
                      onClick={() => {
                        redirectToDetails('google-analytics');
                      }}
                    >
                      <span>Edit</span>
                    </ButtonOutline>
                  </DialogAction>
                  <DialogAction className='wAuto0'>
                    <MarketingIntegrationsCheckBlock>
                      <MarketingIntegrationsCheck>
                        <img
                          className='checked'
                          src={IconCheck}
                          alt='checked'
                        />
                      </MarketingIntegrationsCheck>
                      <span>Connected</span>
                    </MarketingIntegrationsCheckBlock>
                  </DialogAction>
                </DialogActions>
              ) : (
                <>
                  <MarketingIntegrationsTitle>
                    Connect Google Analytics
                  </MarketingIntegrationsTitle>
                  <MarketingIntegrationsText>
                    Discover where your visitors come from and how they behave
                    on your site
                  </MarketingIntegrationsText>
                  <DialogActions className='DialogActionsNoWrap justifyCenter'>
                    <DialogAction className='w262'>
                      <ButtonPrimary
                        onClick={() => {
                          redirectToDetails('google-analytics');
                        }}
                      >
                        <span>Connect</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </>
              )}
            </MarketingIntegrationsBlock>
          </DashboardInner>
          <DashboardInner
            className={cn(
              'DashboardInnerW50 PaddingMarketingIntegrations withoutShadow',
              {
                CheckedIntegrationsBlock: metaPixelId
              }
            )}
          >
            <MarketingIntegrationsBlock className='MetaBlock'>
              <MarketingIntegrationsImage
                className={cn('MetaIcon', {CheckedIntegrations: metaPixelId})}
                src={ImageIntegrationsFacebook}
              />
              {metaPixelId ? (
                <DialogActions className='w100 DialogActionsNoWrap justifySpaceBetween'>
                  <DialogAction className='w30'>
                    <ButtonOutline
                      onClick={() => {
                        redirectToDetails('meta-pixel');
                      }}
                    >
                      <span>Edit</span>
                    </ButtonOutline>
                  </DialogAction>
                  <DialogAction className='wAuto0'>
                    <MarketingIntegrationsCheckBlock>
                      <MarketingIntegrationsCheck>
                        <img
                          className='checked'
                          src={IconCheck}
                          alt='checked'
                        />
                      </MarketingIntegrationsCheck>
                      <span>Connected</span>
                    </MarketingIntegrationsCheckBlock>
                  </DialogAction>
                </DialogActions>
              ) : (
                <>
                  <MarketingIntegrationsTitle>
                    Connect Meta Pixel
                  </MarketingIntegrationsTitle>
                  <MarketingIntegrationsText>
                    Track and optimize your advertising campaigns on Facebook
                    and Instagram
                  </MarketingIntegrationsText>
                  <DialogActions>
                    <DialogAction className='w262'>
                      <ButtonPrimary
                        onClick={() => {
                          redirectToDetails('meta-pixel');
                        }}
                      >
                        <span>Connect</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </>
              )}
            </MarketingIntegrationsBlock>
          </DashboardInner>
        </MarketingIntegrationsContainer>
      </DashboardContent>
      {loading && <FullScreenLoading />}
    </>
  );
};

export default MarketingIntegrationsMainPage;
