import {createReducer} from 'redux-act';
import {GET_CUSTOMERS_COUNT, GET_CUSTOMERS_COUNT_SUCCESS} from './action';

const initialState = () => ({
  customersCount: [],
  customersCountLoading: false
});

const analyticsCustomersCountReducer = createReducer(
  {
    [GET_CUSTOMERS_COUNT]: (state) => ({
      ...state,
      customersCountLoading: true
    }),
    [GET_CUSTOMERS_COUNT_SUCCESS]: (state, payload) => ({
      ...state,
      customersCount: payload,
      customersCountLoading: false
    })
  },
  initialState()
);

export default analyticsCustomersCountReducer;
