import {createReducer} from 'redux-act';
import {
  GET_BAD_HISTORY_ORDERS_SUCCESS,
  GET_BAD_HISTORY_ORDERS_COUNT,
  SET_BAD_HISTORY_TABLE_CONFIG,
  SET_BAD_HISTORY_NEXT_PAGE,
  CLEAR_BAD_HISTORY_ORDERS,
  GET_BAD_HISTORY_ORDERS
} from './action';

const initialConfig = () => ({
  page: 0,
  perPage: 15,
  sortField: 's2e_meta.pickup_eta',
  sortDir: 'desc',
  to: encodeURIComponent(new Date().toISOString()),
  from: '2022-01-10T00%3A00%3A00.000Z'
});

const initialState = () => ({
  historyOrders: [],
  historyOrdersCount: 0,
  config: initialConfig(),
  hasNextPage: true,
  loading: false
});

const bookADriverListReducer = createReducer(
  {
    [GET_BAD_HISTORY_ORDERS_SUCCESS]: (state, data) => ({
      ...state,
      historyOrders: data,
      loading: false
    }),
    [GET_BAD_HISTORY_ORDERS]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_BAD_HISTORY_ORDERS_COUNT]: (state, data) => ({
      ...state,
      historyOrdersCount: data
    }),
    [SET_BAD_HISTORY_TABLE_CONFIG]: (state, data) => {
      return {
        ...state,
        config: {
          ...state.config,
          ...data
        }
      };
    },
    [SET_BAD_HISTORY_NEXT_PAGE]: (state) => {
      return {
        ...state,
        hasNextPage: state.historyOrders.length < state.historyOrdersCount,
        config: {
          ...state.config,
          page: state.config.page + 1
        }
      };
    },
    [CLEAR_BAD_HISTORY_ORDERS]: (state) => ({
      ...state,
      historyOrders: [],
      config: {
        ...state.config,
        page: 0
      }
    })
  },
  initialState()
);

export default bookADriverListReducer;
