import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Formik, Form} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {diff} from 'deep-object-diff';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import {
  CREATE_ITEM_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DashboardField,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogLinkButton,
  FieldInput,
  FieldLabel,
  FieldTextarea
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import ItemSchema from '../../../../helpers/services/formValidationSchemas/ItemSchema';
import FileUploader from '../../../../components/FileUploader';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {POST_ITEM} from '../../../../store/menuEditorItems/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import {getAvailabilityHours, getAvailabilityHoursForServer} from '../helpers';
import {
  priceFormatter,
  selectOptions,
  startActiveHours,
  startCustomHours
} from '../constants';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import SelectWrapper from '../../../../components/select/SelectComponentStyles';
import DaysPickerContainer from '../DaysPickerContainer';

const CreateItemModal = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const isCreateItemModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateItemModalOpen
  );
  const isSubmodal = useSelector(
    (state) => state.menuEditorModalsReducer.isSubmodal
  );

  const entityId = useSelector(
    (state) => state.menuEditorModalsReducer.entityId
  );
  const itemData = useSelector(
    (state) => state.menuEditorReducer.itemsMap[entityId]
  );

  const sectionsMap = useSelector(
    (state) => state.menuEditorReducer.sectionsMap
  );
  const sectionsDirection = useSelector(
    (state) => state.menuEditorReducer.sectionsDirection
  );
  const sectionsList = sectionsDirection.map(
    (MerchantSectionId) => sectionsMap[MerchantSectionId]
  );

  const groupsMap = useSelector((state) => state.menuEditorReducer.groupsMap);
  const groupsDirection = useSelector(
    (state) => state.menuEditorReducer.groupsDirection
  );
  const groupsList = groupsDirection.map((PosId) => groupsMap[PosId]);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedModifiersGroup, setSelectedModifiersGroup] = useState([]);
  const [fileUrl, setFileUrl] = useState('');
  const [expanded, setExpanded] = useState(false);

  const openingDays = useDaysPickerContext();

  useEffect(() => {
    if (itemData && Object.keys(itemData).length) {
      setSelectedModifiersGroup(
        itemData.OptionsGroups.map((PosId) => groupsMap[PosId])
      );
      setSelectedCategories(
        itemData.Sections.map(
          (MerchantSectionId) => sectionsMap[MerchantSectionId]
        )
      );
    }

    return () => {
      setFileUrl('');
      setExpanded(false);
      setSelectedCategories([]);
      setSelectedModifiersGroup([]);
    };
  }, [itemData]);

  const initialValues = {
    Name: itemData?.Name || '',
    Price: itemData?.Price || '',
    Notes: itemData?.Notes || '',
    Brand: itemData?.Brand || '',
    ABV: itemData?.ABV || '',
    Description: itemData?.Description || '',
    PictureURL: itemData?.PictureURL || '',
    ExternalId: itemData?.ExternalId || '',
    NonDeliverable: true,
    PickUp: true,
    ContainsAlcohol: itemData?.ContainsAlcohol || false,
    AvailabilityHours: getAvailabilityHours(startActiveHours),
    OpeningHoursChanged: [],
    Availability: 'During opening hours'
  };

  const shouldAllowFormReinitialize = diff(
    initialValues,
    formikRef?.current?.values
  );

  // const formik = useFormik({
  //   initialValues: {
  //     Name: itemData?.Name || '',
  //     Price: itemData?.Price || '',
  //     Notes: itemData?.Notes || '',
  //     Brand: itemData?.Brand || '',
  //     ABV: itemData?.ABV || '',
  //     Description: itemData?.Description || '',
  //     PictureURL: itemData?.PictureURL || '',
  //     ExternalId: itemData?.ExternalId || '',
  //     NonDeliverable: true,
  //     PickUp: true,
  //     ContainsAlcohol: itemData?.ContainsAlcohol || false
  //   },
  //   enableReinitialize: true,
  //   validationSchema: ItemSchema,
  //   onSubmit: (values, {resetForm}) => {
  //     dispatch(
  //       POST_ITEM({
  //         ...values,
  //         Name: String(values.Name).trim(),
  //         NonDeliverable: !values.NonDeliverable,
  //         PictureURL: fileUrl,
  //         OptionsGroups: [
  //           ...selectedModifiersGroup.map((group) => group.PosId)
  //         ],
  //         Sections: [
  //           ...selectedCategories.map((category) => category.MerchantSectionId)
  //         ]
  //       })
  //     );
  //     sendGtmEvent('menuEditorNewEntityAdded', {
  //       entity: 'item'
  //     });
  //     dispatch(CREATE_ITEM_MODAL({open: false}));
  //     resetForm();
  //     setSelectedCategories([]);
  //     setSelectedModifiersGroup([]);
  //   }
  // });

  useEffect(() => {
    if (formikRef.current) {
      const {
        values,
        resetForm,
        setFieldValue,
        isSubmitting
      } = formikRef.current;

      // if (!isSubmitting) {
      //   resetForm({...values});
      // }
      setFieldValue('OpeningHoursChanged', values.OpeningHoursChanged);
    }
  }, [formikRef.current]);

  useEffect(() => {
    if (selectedModifiersGroup.length > 0) {
      setExpanded(true);
    }
  }, [selectedModifiersGroup]);

  const handleModalClose = () => {
    dispatch(CREATE_ITEM_MODAL({open: false}));
    formikRef?.current?.resetForm();
    setSelectedModifiersGroup([]);
    setSelectedCategories([]);
  };

  const removeModifierGroup = (id) => {
    const filter = selectedModifiersGroup.filter((group) => group.PosId !== id);
    setSelectedModifiersGroup(filter);
  };

  const removeCategory = (id) => {
    const filter = selectedCategories.filter(
      (group) => group.MerchantSectionId !== id
    );
    setSelectedCategories(filter);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      enableReinitialize={
        !Object.keys(shouldAllowFormReinitialize || {}).length
      }
      validationSchema={ItemSchema}
      onSubmit={(values, {resetForm}) => {
        const body = {
          ...values,
          Name: String(values.Name).trim(),
          NonDeliverable: !values.NonDeliverable,
          PictureURL: fileUrl,
          OptionsGroups: [
            ...selectedModifiersGroup.map((group) => group.PosId)
          ],
          Sections: [
            ...selectedCategories.map((category) => category.MerchantSectionId)
          ],
          AvailabilityHours:
            values.Availability === 'Custom hours'
              ? getAvailabilityHoursForServer(openingDays)
              : startActiveHours
        };
        delete body.Availability;
        delete body.OpeningHoursChanged;
        dispatch(POST_ITEM(body));
        sendGtmEvent('menuEditorNewEntityAdded', {
          entity: 'item'
        });
        dispatch(CREATE_ITEM_MODAL({open: false}));
        resetForm();
        setSelectedCategories([]);
        setSelectedModifiersGroup([]);
      }}
    >
      {({values, handleChange, submitForm, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            onClose={() => handleModalClose()}
            open={isCreateItemModalOpen}
            scroll='body'
          >
            <DialogContainer className='DialogXl'>
              <DialogClose onClick={() => handleModalClose()} />
              <DialogTitle>
                Create a New <span>Item</span>
              </DialogTitle>

              <DialogBody>
                <Form>
                  <DialogFields>
                    <DialogField className='w50'>
                      <FieldLabel>Item name</FieldLabel>
                      <FieldInput
                        className={errors.Name && touched.Name && 'error'}
                        name='Name'
                        value={values.Name}
                        placeholder='Write item name here'
                        onChange={handleChange}
                      />
                      <ErrorSignWrapper>
                        {errors.Name && touched.Name && (
                          <ErrorSign>{errors.Name}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                    <DialogField className='wSm'>
                      <FieldLabel>Price</FieldLabel>
                      <FieldInput
                        name='Price'
                        className={errors.Price && touched.Price && 'error'}
                        value={values.Price}
                        placeholder='$'
                        onChange={(e) => {
                          const price = priceFormatter(
                            e.target.value,
                            values.Price
                          );
                          setFieldValue('Price', price);
                        }}
                        onBlur={(e) => {
                          const lastElement = e.target.value.slice(-1)[0];
                          if (lastElement === '.') {
                            setFieldValue('Price', e.target.value.slice(0, -1));
                          }
                        }}
                      />
                      <ErrorSignWrapper>
                        {errors.Price && touched.Price && (
                          <ErrorSign>{errors.Price}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                    {/* <DialogField className='w40 dFlex'> */}
                    {/*  <DialogFieldTitle className='mRight'> */}
                    {/*    Available on */}
                    {/*  </DialogFieldTitle> */}
                    {/*  <DialogCheckboxes> */}
                    {/*    <DialogCheckboxesItem> */}
                    {/*      <CheckboxContainer> */}
                    {/*        <FormControlLabel */}
                    {/*          className='Say2EatCheckbox' */}
                    {/*          control={ */}
                    {/*            <Checkbox */}
                    {/*              labelStyle={{color: '#172751'}} */}
                    {/*              iconStyle={{fill: '#FF2D38'}} */}
                    {/*              inputStyle={{color: '#FF2D38'}} */}
                    {/*              style={{color: '#FF2D38'}} */}
                    {/*              onChange={formik.handleChange} */}
                    {/*              name='NonDeliverable' */}
                    {/*              checked={formik.values.NonDeliverable} */}
                    {/*            /> */}
                    {/*          } */}
                    {/*          label='Delivery' */}
                    {/*        /> */}
                    {/*      </CheckboxContainer> */}
                    {/*    </DialogCheckboxesItem> */}
                    {/*    <DialogCheckboxesItem> */}
                    {/*      <CheckboxContainer> */}
                    {/*        <FormControlLabel */}
                    {/*          className='Say2EatCheckbox' */}
                    {/*          control={ */}
                    {/*            <Checkbox */}
                    {/*              labelStyle={{color: '#172751'}} */}
                    {/*              iconStyle={{fill: '#FF2D38'}} */}
                    {/*              inputStyle={{color: '#FF2D38'}} */}
                    {/*              style={{color: '#FF2D38'}} */}
                    {/*              onChange={formik.handleChange} */}
                    {/*              name='PickUp' */}
                    {/*              checked={formik.values.PickUp} */}
                    {/*            /> */}
                    {/*          } */}
                    {/*          label='Pick up' */}
                    {/*        /> */}
                    {/*      </CheckboxContainer> */}
                    {/*    </DialogCheckboxesItem> */}
                    {/*  </DialogCheckboxes> */}
                    {/* </DialogField> */}
                    <DialogField className='w100 dFlex'>
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              onChange={handleChange}
                              name='ContainsAlcohol'
                              checked={values.ContainsAlcohol}
                            />
                          }
                          label='Contains Alcohol'
                        />
                      </CheckboxContainer>
                    </DialogField>
                    <DialogField className='w100'>
                      <FieldLabel>Item description</FieldLabel>
                      <FieldTextarea
                        name='Description'
                        className={
                          errors.Description && touched.Description && 'error'
                        }
                        value={values.Description}
                        placeholder='Write item description here'
                        onChange={handleChange}
                      />
                      <ErrorSignWrapper>
                        {errors.Description && touched.Description && (
                          <ErrorSign>{errors.Description}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>

                    <DialogField className='w50'>
                      <FieldLabel>Photo</FieldLabel>
                      <FileUploader
                        url={fileUrl}
                        onSetFile={setFileUrl}
                        allowedFileFormats={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg'
                        ]}
                      />
                    </DialogField>

                    <DialogField className='w50 pTop'>
                      <DialogFieldInners>
                        <DialogFieldInner className='w33'>
                          <FieldLabel>ExternalId</FieldLabel>
                          <FieldInput
                            name='ExternalId'
                            value={values.ExternalId}
                            onChange={handleChange}
                            placeholder='ExternalId'
                          />
                        </DialogFieldInner>
                        <DialogFieldInner className='w33'>
                          <FieldLabel>Brand</FieldLabel>
                          <FieldInput
                            name='Brand'
                            value={values.Brand}
                            onChange={handleChange}
                            placeholder='Brand'
                          />
                        </DialogFieldInner>
                        <DialogFieldInner className='w33'>
                          <FieldLabel>ABV</FieldLabel>
                          <FieldInput
                            name='ABV'
                            value={values.ABV}
                            onChange={handleChange}
                            placeholder='ABV'
                          />
                        </DialogFieldInner>
                      </DialogFieldInners>
                    </DialogField>

                    <DialogField className='w30'>
                      <DashboardField className='wAutoSpace paddingL0'>
                        <FieldLabel>Availability</FieldLabel>
                        <SelectWrapper className='SelectMd w100'>
                          <FormControl className='select-formcontrol'>
                            <Select
                              value={values.Availability}
                              name='Availability'
                              onChange={(e) => {
                                setFieldValue('Availability', e.target.value);
                                if (e.target.value === 'Custom hours') {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startCustomHours)
                                  );
                                } else {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startActiveHours)
                                  );
                                }
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                getContentAnchorEl: null
                              }}
                            >
                              {selectOptions.map((item) => (
                                <MenuItem
                                  className='reportTypeItem'
                                  key={nanoid()}
                                  value={item.name}
                                  disableRipple
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </SelectWrapper>
                      </DashboardField>
                    </DialogField>
                    {values.Availability === 'Custom hours' && (
                      <DialogField className='w100'>
                        <DaysPickerContainer
                          WorkingDays={values?.AvailabilityHours}
                        />
                      </DialogField>
                    )}

                    <DialogField className='w100'>
                      <FieldLabel className='FieldLabelLg'>
                        Notes (for internal use)
                      </FieldLabel>
                      <FieldInput
                        name='Notes'
                        value={values.Notes}
                        onChange={handleChange}
                        placeholder='Write your notes here'
                      />
                    </DialogField>

                    {!isSubmodal && (
                      <DialogField className='w50 Say2EatSelect'>
                        {!!selectedModifiersGroup.length && (
                          <AccordionItem className='AccordionItemModal'>
                            <Accordion
                              onChange={handleAccordionChange('modifierGroups')}
                              expanded={
                                expanded === 'modifierGroups' ||
                                expanded === true
                              }
                            >
                              <AccordionSummary
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                              >
                                <AccordionItemName className='AccordionItemName'>
                                  {`Modifier groups including (${selectedModifiersGroup.length})`}
                                </AccordionItemName>
                              </AccordionSummary>
                              <AccordionItemBody>
                                <DialogFields>
                                  {selectedModifiersGroup.map((group) => (
                                    <DialogField
                                      key={group.PosId}
                                      className='w100 withRemove'
                                    >
                                      <FieldInput
                                        name=''
                                        readOnly
                                        value={group.OptionName}
                                        placeholder={group.OptionName}
                                      />
                                      <DialogFieldRemove
                                        onClick={() =>
                                          removeModifierGroup(group.PosId)
                                        }
                                      />
                                    </DialogField>
                                  ))}
                                </DialogFields>
                              </AccordionItemBody>
                            </Accordion>
                          </AccordionItem>
                        )}
                        <DialogField className='Center w100'>
                          <DialogLinkButton
                            onClick={() => {
                              dispatch(
                                SELECT_ITEM_MODAL_OPEN({
                                  open: true,
                                  itemType: 'modifierGroup',
                                  itemList: groupsList,
                                  selectedItems: selectedModifiersGroup,
                                  loading: false,
                                  callBack: setSelectedModifiersGroup,
                                  closeModal: () => {
                                    dispatch(
                                      SELECT_ITEM_MODAL_OPEN({
                                        open: false,
                                        itemType: '',
                                        queryParams: {},
                                        itemList: [],
                                        loading: false,
                                        selectedItems: [],
                                        callBack: () => {},
                                        closeModal: () => {}
                                      })
                                    );
                                  }
                                })
                              );
                            }}
                          >
                            Add a Modifier Group
                          </DialogLinkButton>
                        </DialogField>
                      </DialogField>
                    )}

                    {!isSubmodal && (
                      <DialogField className='w50 Say2EatSelect'>
                        {!!selectedCategories.length && (
                          <AccordionItem className='AccordionItemModal'>
                            <Accordion
                              onChange={handleAccordionChange('categories')}
                              expanded={
                                expanded === 'categories' || expanded === true
                              }
                            >
                              <AccordionSummary
                                aria-controls='panel1a-content'
                                id='panel1a-header'
                              >
                                <AccordionItemName className='AccordionItemName'>
                                  {`Parent categories (${selectedCategories.length})`}
                                </AccordionItemName>
                              </AccordionSummary>
                              <AccordionItemBody>
                                <DialogFields>
                                  {selectedCategories.map((section) => (
                                    <DialogField
                                      className='w100 withRemove'
                                      key={section.MerchantSectionId}
                                    >
                                      <FieldInput
                                        name=''
                                        readOnly
                                        value={section.Name}
                                        placeholder={section.Name}
                                      />
                                      <DialogFieldRemove
                                        onClick={() =>
                                          removeCategory(
                                            section.MerchantSectionId
                                          )
                                        }
                                      />
                                    </DialogField>
                                  ))}
                                </DialogFields>
                              </AccordionItemBody>
                            </Accordion>
                          </AccordionItem>
                        )}
                        <DialogField className='Center w100'>
                          <DialogLinkButton
                            onClick={() => {
                              dispatch(
                                SELECT_ITEM_MODAL_OPEN({
                                  open: true,
                                  itemType: 'category',
                                  itemList: sectionsList,
                                  selectedItems: selectedCategories,
                                  loading: false,
                                  callBack: setSelectedCategories,
                                  closeModal: () => {
                                    dispatch(
                                      SELECT_ITEM_MODAL_OPEN({
                                        open: false,
                                        itemType: '',
                                        queryParams: {},
                                        itemList: [],
                                        loading: false,
                                        selectedItems: [],
                                        callBack: () => {},
                                        closeModal: () => {}
                                      })
                                    );
                                  }
                                })
                              );
                            }}
                          >
                            Add Parent Categories
                          </DialogLinkButton>
                        </DialogField>
                      </DialogField>
                    )}
                  </DialogFields>

                  <DialogAction>
                    <ButtonPrimary type='submit'>
                      <span>Create an Item</span>
                    </ButtonPrimary>
                  </DialogAction>
                </Form>
              </DialogBody>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreateItemModal;
