import {
  put,
  delay,
  race,
  call,
  take,
  cancel,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  GET_ORDERS_RTB_SUCCESS,
  RUN_ORDER_RTB_JOB,
  STOP_ORDER_RTB_JOB,
  SEND_ORDER_ACTIONS,
  OPEN_ADDITION_INFO_MODAL,
  OPEN_ADDITION_INFO_MODAL_SUCCESS,
  REMOVE_ORDER
} from 'store/realTimeBoardV2/action';
import {
  getOrdersRTBV2Api,
  sendOrderActionsV2Api,
  getOrderRTBV2Api,
  removeOrderV2Api
} from 'helpers/services/api/realTimeBoardV2';

const GET_ORDERS_RTB_INTERVAL = 30000;

export function* getOrdersRTB() {
  while (true) {
    try {
      const {data} = yield getOrdersRTBV2Api();

      if (data) {
        yield put(GET_ORDERS_RTB_SUCCESS(data.orders));
      }
    } catch (error) {
      toast.error('Dispatch Sync Error');
      console.log('SAGA ERROR', error);
    }
    yield delay(GET_ORDERS_RTB_INTERVAL);
  }
}

function* runGetOrdersRTBJob() {
  try {
    const {stop} = yield race({
      job: call(() => getOrdersRTB()),
      stop: take(STOP_ORDER_RTB_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* sendOrderActions({payload}) {
  try {
    const resp = yield sendOrderActionsV2Api(payload.data, payload.id);

    const toastText = 'Actions successfully reported!';

    if (resp.status === 200) {
      toast.success(toastText, {
        autoClose: 2000
      });
    }

    yield put(STOP_ORDER_RTB_JOB());
    yield put(RUN_ORDER_RTB_JOB());
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getOrderRTB({payload}) {
  try {
    const {data} = yield getOrderRTBV2Api(payload.id);

    if (data) {
      yield put(OPEN_ADDITION_INFO_MODAL_SUCCESS({data, open: true}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* removeOrderRTB({payload}) {
  try {
    yield removeOrderV2Api(payload.id);

    yield put(STOP_ORDER_RTB_JOB());
    yield put(RUN_ORDER_RTB_JOB());
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* orderRTBV2Saga() {
  yield takeLatest(RUN_ORDER_RTB_JOB, runGetOrdersRTBJob);
  yield takeEvery(SEND_ORDER_ACTIONS, sendOrderActions);
  yield takeEvery(OPEN_ADDITION_INFO_MODAL, getOrderRTB);
  yield takeEvery(REMOVE_ORDER, removeOrderRTB);
}

export default orderRTBV2Saga;
