import styled from 'styled-components';
import IconHint from '../../assets/icon-lightbulb-outline.svg';

export const HintText = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${IconHint});
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: 0;
  }

  span {
    padding-left: 10px;
    margin-left: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.2;
    color: #969ba0;
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 2px;
      height: 18px;
      background-color: #ff2d38;
      content: '';
    }
  }

  &.HintTextCenter {
    justify-content: center;

    span {
      padding-left: 8px;
      margin-left: 8px;
    }
  }

  &.mTop10 {
    margin-top: 10px;
  }

  &.mTop20 {
    margin-top: 20px;
  }

  &.DialogHintTablet {
    @media (max-width: 1000px) {
      flex: 1 1 auto;
    }
  }
  &.withoutIcon {
    &::before {
      display: none;
    }
  }
`;

export default HintText;
