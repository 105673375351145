import React from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import DialogClose from 'components/DialogClose/styles';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {
  BOARD_COLOR_PRIORITY,
  BOARD_V2_STATUS,
  BOARD_V2,
  BOARD_V2_DISPATCH_VERSION_VALUE
} from 'helpers/constants/adminRealTImeBoard';
import {
  OPEN_ADDITION_INFO_MODAL_SUCCESS,
  CLEAR_ORDER_ACTIONS
} from 'store/realTimeBoardV2/action';
import {ProviderTrackingLink} from 'routes/App/Managers/realTimeBoardV2/styles';

export default function AdditionInfoModal() {
  const dispatch = useDispatch();
  const {additionInfo, orderActions} = useSelector(
    (state) => state.realTimeBoardV2Reducer
  );
  const closeDialog = () => {
    dispatch(OPEN_ADDITION_INFO_MODAL_SUCCESS({open: false, data: null}));
    dispatch(CLEAR_ORDER_ACTIONS());
  };

  const displayedReasonsArr = [
    BOARD_COLOR_PRIORITY.ORANGE,
    BOARD_COLOR_PRIORITY.RED
  ];

  const actionTypeName = (action) => {
    let type = action?.type;
    if (action.description) {
      type = action.description;
    } else if (action.bookADriverSauceOrderId) {
      type = `Book a driver (${action.bookADriverSauceOrderId})`;
    }

    return type;
  };

  return (
    <Dialog open={additionInfo} onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogM'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomLg'>ADDITIONAL INFO</DialogTitle>
        <DialogBody className='wAuto'>
          <DialogFields className='column'>
            {additionInfo[BOARD_V2.RISK_LEVEL]?.value &&
              displayedReasonsArr.includes(
                additionInfo[BOARD_V2.RISK_LEVEL]?.value
              ) &&
              additionInfo[BOARD_V2.RISK_LEVEL]?.reasons?.length && (
                <>
                  <DialogField className='bold smallPadding'>
                    <span>
                      Moving to{' '}
                      {additionInfo[BOARD_V2.RISK_LEVEL]?.value ===
                      BOARD_COLOR_PRIORITY.ORANGE
                        ? 'orange'
                        : 'red'}{' '}
                      reason:
                    </span>
                  </DialogField>
                  {additionInfo[BOARD_V2.RISK_LEVEL]?.reasons?.map((item) => (
                    <>
                      <DialogField className='rdbModal smallPadding'>
                        <span className='alignLeft fullWidth'>{item}</span>
                      </DialogField>
                    </>
                  ))}
                </>
              )}
            <DialogField
              className={cn('bold smallPadding', {
                mTop20:
                  additionInfo[BOARD_V2.RISK_LEVEL]?.value &&
                  displayedReasonsArr.includes(
                    additionInfo[BOARD_V2.RISK_LEVEL]?.value
                  ) &&
                  additionInfo[BOARD_V2.RISK_LEVEL]?.reasons?.length
              })}
            >
              <span>Previous dispatcher actions:</span>
            </DialogField>
            {additionInfo[BOARD_V2.ACTIONS].length ? (
              additionInfo[BOARD_V2.ACTIONS]?.map((item) => {
                return (
                  <>
                    <DialogField className='rdbModal smallPadding'>
                      <span>Action taken:</span>
                      <span>{actionTypeName(item)}</span>
                    </DialogField>
                    <DialogField className='rdbModal smallPadding'>
                      <span>Date & time:</span>
                      <span>
                        {moment
                          .utc(item.createdAt)
                          .utcOffset(additionInfo[BOARD_V2.TIMEZONE] || 0)
                          .format('MMMM DD HH:mm:ss')}
                      </span>
                    </DialogField>
                  </>
                );
              })
            ) : (
              <>
                <DialogField className='rdbModal smallPadding'>
                  <span>Action taken:</span>
                  <span>N/A</span>
                </DialogField>
                <DialogField className='rdbModal smallPadding'>
                  <span>Date & time:</span>
                  <span>N/A</span>
                </DialogField>
              </>
            )}

            <DialogField className='bold smallPadding mTop20'>
              <span>Order details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Order amount</span>
              <span>
                $
                {additionInfo[BOARD_V2.ORDER_AMOUNT]
                  ? additionInfo[BOARD_V2.ORDER_AMOUNT] / 100
                  : 'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Number of items</span>
              <span>{additionInfo[BOARD_V2.ORDER_ITEMS_COUNT] || 'N/A'}</span>
            </DialogField>

            <DialogField className='rdbModal smallPadding'>
              <span>Delivery Company name</span>
              <span className='capitalizeFirstLetter'>
                {!additionInfo[BOARD_V2.DELIVERY]?.provider ||
                additionInfo[BOARD_V2.STATUS]?.type ===
                  BOARD_V2_STATUS.FUTURE_ORDER
                  ? 'N/A'
                  : `${additionInfo[BOARD_V2.DELIVERY]?.provider || 'N/A'}`}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Delivery Company Order ID</span>
              <span>
                {!additionInfo[BOARD_V2.DELIVERY]?.provider ||
                additionInfo[BOARD_V2.STATUS]?.type ===
                  BOARD_V2_STATUS.FUTURE_ORDER
                  ? 'N/A'
                  : additionInfo[BOARD_V2.DELIVERY]?.internalId}
              </span>
            </DialogField>

            {additionInfo[BOARD_V2.DELIVERY]?.uberId && (
              <DialogField className='rdbModal smallPadding'>
                <span>Uber Order ID</span>
                <span>{additionInfo[BOARD_V2.DELIVERY]?.uberId}</span>
              </DialogField>
            )}
            {(additionInfo[BOARD_V2.OLD_DISPATCH_PROVIDER_TRACKING_LINK] ||
              additionInfo[BOARD_V2.NEW_DISPATCH_PROVIDER_TRACKING_LINK]) && (
              <DialogField className='rdbModal smallPadding'>
                <span>Provider tracking link</span>
                <ProviderTrackingLink
                  href={
                    additionInfo[
                      BOARD_V2.OLD_DISPATCH_PROVIDER_TRACKING_LINK
                    ] ||
                    additionInfo[BOARD_V2.NEW_DISPATCH_PROVIDER_TRACKING_LINK]
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  {additionInfo[BOARD_V2.DELIVERY]?.provider
                    ? `${
                        additionInfo[BOARD_V2.DELIVERY]?.provider
                      } Tracking Link`
                    : 'Tracking Link'}
                </ProviderTrackingLink>
              </DialogField>
            )}
            {additionInfo[BOARD_V2.DELIVERY] && (
              <DialogField className='rdbModal smallPadding'>
                <span>Sauce Delivery Order ID</span>
                <span>{additionInfo[BOARD_V2.DELIVERY]?.id || 'N/A'}</span>
              </DialogField>
            )}
            <DialogField className='rdbModal smallPadding'>
              <span>Sauce Order ID</span>
              <span>{additionInfo[BOARD_V2.CORE_ID] || 'N/A'}</span>
            </DialogField>

            {((additionInfo[BOARD_V2.DROPOFF]?.address &&
              (additionInfo[BOARD_V2.DROPOFF]?.address?.apartment ||
                additionInfo[BOARD_V2.DROPOFF]?.address?.floor ||
                additionInfo[BOARD_V2.DROPOFF]?.address?.entrance)) ||
              additionInfo[BOARD_V2.CORE_ORD_NOTE]) && (
              <DialogField className='rdbModal smallPadding'>
                <span>Order Notes</span>
                <span>
                  {additionInfo[BOARD_V2.DROPOFF]?.address?.apartment &&
                    `Apt: ${
                      additionInfo[BOARD_V2.DROPOFF]?.address?.apartment
                    } `}
                  {additionInfo[BOARD_V2.DROPOFF]?.address?.floor &&
                    `Floor: ${additionInfo[BOARD_V2.DROPOFF]?.address?.floor} `}
                  {additionInfo[BOARD_V2.DROPOFF]?.address?.entrance &&
                    `Entrance: ${
                      additionInfo[BOARD_V2.DROPOFF]?.address?.entrance
                    } `}
                  {additionInfo[BOARD_V2.CORE_ORD_NOTE] &&
                    `Notes: ${additionInfo[BOARD_V2.CORE_ORD_NOTE]}`}
                </span>
              </DialogField>
            )}
            {additionInfo[BOARD_V2.DISPATCH_VERSION] ===
              BOARD_V2_DISPATCH_VERSION_VALUE.DISPATCH_V2 &&
              additionInfo[BOARD_V2.DELIVERY]?.providers?.length > 0 && (
                <>
                  <DialogField className='smallPadding'>
                    <span>All providers&#39; orders:</span>
                  </DialogField>
                  <ul style={{width: 'calc(100% - 24px)', marginLeft: '24px'}}>
                    {additionInfo[BOARD_V2.DELIVERY]?.providers?.map(
                      (provider) => (
                        <>
                          {provider && (
                            <li>
                              <DialogField className='smallPadding'>
                                <span>
                                  {provider?.name}
                                  {' - '}
                                </span>
                                <span>
                                  {provider?.internalId || 'null'}
                                  {' - '}
                                </span>
                                <span>{provider?.status}</span>
                              </DialogField>
                            </li>
                          )}
                        </>
                      )
                    )}
                  </ul>
                </>
              )}
            <DialogField className='rdbModal smallPadding'>
              <span>Number of couriers changed</span>
              <span>
                {additionInfo[BOARD_V2.DELIVERY]?.driverChangeCount >= 0
                  ? additionInfo[BOARD_V2.DELIVERY]?.driverChangeCount
                  : '0'}
              </span>
            </DialogField>

            <DialogField className='bold smallPadding mTop20'>
              <span>Location details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Merchant name</span>
              <span>{additionInfo[BOARD_V2.MERCHANT]?.name || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location name</span>
              <span>{additionInfo[BOARD_V2.LOCATION]?.name || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location address</span>
              <span>
                {additionInfo[BOARD_V2.PICKUP]?.address?.formattedAddress ||
                  'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Location phone</span>
              <span>{additionInfo[BOARD_V2.LOCATION]?.phone || 'N/A'}</span>
            </DialogField>

            <DialogField className='bold smallPadding mTop20'>
              <span>Customer details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer name</span>
              <span>{additionInfo[BOARD_V2.CUSTOMER_NAME] || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer address</span>
              <span>
                {additionInfo[BOARD_V2.DROPOFF]?.address?.formattedAddress ||
                  'N/A'}
              </span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Consumer phone</span>
              <span>{additionInfo[BOARD_V2.CUSTOMER_PHONE] || 'N/A'}</span>
            </DialogField>

            <DialogField className='bold smallPadding mTop20'>
              <span>Courier details:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Courier name</span>
              <span>{additionInfo[BOARD_V2.COURIER]?.name || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Courier phone</span>
              <span>{additionInfo[BOARD_V2.COURIER]?.phone || 'N/A'}</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Vehicle Type</span>
              <span className='capitalizeFirstLetter'>
                {additionInfo[BOARD_V2.COURIER]?.vehicle?.type || 'N/A'}
              </span>
            </DialogField>
            <DialogField className='bold smallPadding mTop20'>
              <span>Delivery times:</span>
            </DialogField>
            <DialogField className='rdbModal smallPadding'>
              <span>Delivery creation</span>
              <span>
                {moment(additionInfo[BOARD_V2.CREATED_AT])
                  ?.utcOffset(additionInfo[BOARD_V2.TIMEZONE] || 0)
                  ?.format('MMMM DD HH:mm:ss')}
              </span>
            </DialogField>
            {(additionInfo[BOARD_V2.STATUS]?.type ===
              BOARD_V2_STATUS.CANCELLED ||
              additionInfo[BOARD_V2.STATUS]?.type ===
                BOARD_V2_STATUS.CANCELLATION_FAILED) && (
              <>
                <DialogField className='bold smallPadding mTop20'>
                  <span>Cancellation information:</span>
                </DialogField>
                <DialogField className='rdbModal smallPadding'>
                  <span>Cancellation side</span>
                  <span>{additionInfo[BOARD_V2.STATUS]?.source || 'N/A'}</span>
                </DialogField>
                <DialogField className='rdbModal smallPadding'>
                  <span>Cancellation comment</span>
                  <span>{additionInfo[BOARD_V2.STATUS]?.reason || 'N/A'}</span>
                </DialogField>
              </>
            )}
          </DialogFields>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}
