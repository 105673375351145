/* eslint-disable no-unneeded-ternary */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useSound from 'use-sound';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldRemove,
  DialogLinkButton,
  FieldInput
} from '../../../dashboard/styles';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {validate} from '../../../../../helpers/services/formValidationSchemas/OrdersNotificationsSchema';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {
  GET_LOCATION_ID_DATA,
  PATCH_LOCATION_ACTION
} from '../../../../../store/orders/orderList/action';
import Loading from '../../../../../components/shared/Utils/Loading';
import DialogFields from '../../../../../components/DialogFields/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../../components/Checkbox/styles';
import {BorderedButton, ButtonOutline} from '../../../../../components/buttons';
import CloudPrint from './CloudPrint';
import ButtonInfo from '../../../../../components/buttons/ButtonInfo/styles';
import SoundItem from '../components/SoundItem';
import {AuthLink} from '../../../../Auth/styles';
import TastySound from '../../../../../assets/Tasty.mp3';
import SaucySound from '../../../../../assets/Saucy.mp3';
import FlavorySound from '../../../../../assets/Flavory.mp3';
import {SoundItems} from '../styles';
import sendGtmEvent from '../../../../../helpers/services/utils/gtm';

export default function OrdersNotifications({onClose}) {
  const [showCloudPrintModal, setShowCloudPrintModal] = useState(false);
  const [sound, setSound] = useState('');
  const [playTastySound, exposedTastySound] = useSound(TastySound, {
    loop: true
  });
  const [playSaucySound, exposedSaucySound] = useSound(SaucySound, {
    loop: true
  });
  const [playFlavorySound, exposedFlavorySound] = useSound(FlavorySound, {
    loop: true
  });

  const dispatch = useDispatch();
  const {
    modalLoading,
    locationData: {OrderNotifications}
  } = useSelector((state) => state.ordersHistoryReducer);

  const {userData} = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(GET_LOCATION_ID_DATA());
  }, []);

  const soundsActions = {
    Tasty: {play: playTastySound, stop: exposedTastySound.stop},
    Saucy: {play: playSaucySound, stop: exposedSaucySound.stop},
    Flavory: {play: playFlavorySound, stop: exposedFlavorySound.stop}
  };

  const onSoundStop = () => {
    if (!sound) return;
    soundsActions[sound].stop();
    setSound('');
  };

  const onHandlePlay = (key) => {
    onSoundStop();
    setSound(key);
    soundsActions[key].play();
  };

  const handleClose = () => {
    onSoundStop();
    onClose();
  };

  const defEmail = OrderNotifications?.Email || [`${userData.email}`];
  const defFax = OrderNotifications?.Fax || '';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      RingOnTablet:
        OrderNotifications?.RingOnTablet === undefined
          ? true
          : OrderNotifications?.RingOnTablet,
      Fax: defFax,
      isActiveFax: OrderNotifications?.isActiveFax,
      Email: defEmail,
      isActiveEmail: OrderNotifications?.isActiveEmail,
      RingSoundName: OrderNotifications?.RingSoundName || 'Tasty',
      StarPrintUserEnabled: Boolean(OrderNotifications?.StarPrintUserEnabled)
    },
    validate,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (values, {resetForm}) => {
      const result = {
        OrderNotifications: {
          Fax: values.Fax.split(', ').map((fax) => fax.trim()),
          ...values
        }
      };

      if (
        JSON.stringify(defFax) !== JSON.stringify(result.OrderNotifications.Fax)
      ) {
        sendGtmEvent('faxChanged', {
          old: defFax,
          new: result.OrderNotifications.Fax
        });
      }

      if (
        JSON.stringify(defEmail) !==
        JSON.stringify(result.OrderNotifications.Email)
      ) {
        sendGtmEvent('emailChanged', {
          old: defEmail,
          new: result.OrderNotifications.Email
        });
      }

      dispatch(
        PATCH_LOCATION_ACTION({
          data: result,
          actions: () => {
            handleClose();
            resetForm();
          }
        })
      );
    }
  });

  const manageCloudPrintModal = () => {
    setShowCloudPrintModal((prevState) => !prevState);
  };

  const removeEmailSection = (index) => {
    const formikEmails = [...formik.values.Email];
    formikEmails.splice(index, 1);
    formik.setFieldValue('Email', formikEmails);
  };

  const soundsArr = ['Tasty', 'Saucy', 'Flavory'];

  const handleChangeEmailNotification = (event, checked) => {
    formik.setFieldValue('isActiveEmail', checked);
    if (!checked && formik.errors?.Email) {
      const invalidIndex = formik.errors.Email.reduce(
        (acc, error, errorIndex) => {
          if (error) acc.push(errorIndex);
          return acc;
        },
        []
      );
      if (invalidIndex.length) {
        const newValues = formik.values.Email.map((value, valueIndex) => {
          const isSameIndex = invalidIndex.some((i) => i === valueIndex);
          if (isSameIndex) return '';
          return value;
        }).filter((value) => value);
        formik.setFieldValue('Email', newValues);
      }
    }
  };

  return (
    <Dialog open onClose={handleClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        {modalLoading ? (
          <Loading />
        ) : (
          <>
            <DialogClose onClick={handleClose} />
            <DialogTitle className='marginBottomXl'>
              Orders Notifications
            </DialogTitle>
            <DialogBody>
              <CheckboxContainer>
                <FormControlLabel
                  className='Say2EatCheckbox marginBottomMd'
                  control={
                    <Checkbox
                      labelStyle={{color: '#172751'}}
                      iconStyle={{fill: '#FF2D38'}}
                      inputStyle={{color: '#FF2D38'}}
                      style={{color: '#FF2D38'}}
                      checked={formik.values.RingOnTablet}
                      onChange={(event, checked) =>
                        formik.setFieldValue('RingOnTablet', checked)
                      }
                    />
                  }
                  label='Ring on Dashboard'
                />
                <ButtonInfo className='Outline ButtonInfoDrop'>
                  <div className='DropInfo DropInfoCenter DropInfoRightMob'>
                    Enable or Disable the order notification ring sound. This
                    option will be applied across all your connected devices.
                  </div>
                </ButtonInfo>
              </CheckboxContainer>
              {formik.values.RingOnTablet && (
                <SoundItems>
                  {soundsArr.map((key) => (
                    <SoundItem
                      onHandleChange={() => {
                        formik.setFieldValue('RingSoundName', key);
                      }}
                      sound={sound}
                      title={key}
                      selectedItem={formik.values.RingSoundName}
                      play={onHandlePlay}
                      onSoundStop={onSoundStop}
                    />
                  ))}
                </SoundItems>
              )}

              <FlexContainer className='marginBottomMd' alignItems='center'>
                <FlexItem flex={1}>
                  <FlexContainer>
                    <CheckboxContainer>
                      <FormControlLabel
                        className='Say2EatCheckbox'
                        control={
                          <Checkbox
                            labelStyle={{color: '#172751'}}
                            iconStyle={{fill: '#FF2D38'}}
                            inputStyle={{color: '#FF2D38'}}
                            style={{color: '#FF2D38'}}
                            checked={formik.values.StarPrintUserEnabled}
                            onChange={(event, checked) =>
                              formik.setFieldValue(
                                'StarPrintUserEnabled',
                                checked
                              )
                            }
                          />
                        }
                        label='Printer'
                      />
                    </CheckboxContainer>
                    <ButtonInfo className='Outline ButtonInfoDrop'>
                      <div className='DropInfo DropInfoLeft'>
                        Enable your CloudPrint username and password. To get
                        more details on connecting your CloudPrinter, please
                        visit this
                        <AuthLink
                          href='https://support.getsauce.com/cloud-print-prints-your-tablet-orders'
                          target='_blank'
                        >
                          page
                        </AuthLink>
                      </div>
                    </ButtonInfo>
                  </FlexContainer>
                </FlexItem>
                {formik.values.StarPrintUserEnabled && (
                  <FlexItem flex={2}>
                    <ButtonOutline
                      className='ButtonSmall'
                      onClick={manageCloudPrintModal}
                    >
                      Cloud Print Configurations
                    </ButtonOutline>
                  </FlexItem>
                )}
              </FlexContainer>

              {formik.values.Email.map((email, index) => (
                <FlexContainer className='align-center FlexContainerNotification'>
                  <FlexItem flex={1}>
                    {index === 0 && (
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              checked={
                                formik.values.isActiveEmail ? true : false
                              }
                              onChange={handleChangeEmailNotification}
                            />
                          }
                          label='Email'
                        />
                        <ButtonInfo className='Outline ButtonInfoDrop '>
                          <div className='DropInfo DropInfoLeft'>
                            Send email order notification. The email
                            notification will be sent automatically. To add
                            multiple email addresses, click on add email. Please
                            provide valid emails, ex. sauce@info.com
                          </div>
                        </ButtonInfo>
                      </CheckboxContainer>
                    )}
                  </FlexItem>

                  {formik.values.isActiveEmail && (
                    <FlexItem flex={2}>
                      <DialogFields>
                        <DialogField className='DialogField w100 withRemove'>
                          <FieldInput
                            className={cn({
                              error:
                                formik.errors.Email &&
                                formik.touched.Email &&
                                formik.touched.Email[index] &&
                                formik.errors.Email[index],
                              isDisabled:
                                formik.values.Email[index + 1] !== undefined
                            })}
                            name={`Email[${index}]`}
                            value={formik.values.Email[index]}
                            onChange={formik.handleChange}
                            disabled={!formik.values.isActiveEmail}
                            onBlur={formik.handleBlur}
                            placeholder='email@email.com'
                          />
                          <ErrorSignWrapper>
                            {formik.errors.Email &&
                              formik.touched.Email &&
                              formik.touched.Email[index] &&
                              formik.errors.Email[index] && (
                                <ErrorSign>
                                  {formik.errors.Email &&
                                    formik.errors.Email[index]}
                                </ErrorSign>
                              )}
                          </ErrorSignWrapper>
                          {index > 0 && (
                            <DialogFieldRemove
                              onClick={() => removeEmailSection(index)}
                            />
                          )}
                        </DialogField>
                      </DialogFields>
                    </FlexItem>
                  )}
                </FlexContainer>
              ))}
              {formik.values.isActiveEmail && (
                <FlexContainer className='paddingBottomXs marginBottomMd FlexContainerNotification'>
                  <FlexItem flex={1} />
                  <FlexItem flex={2}>
                    <DialogFields>
                      <DialogField className='Center w100 withRemove'>
                        <DialogLinkButton
                          className={
                            (formik.errors.Email && formik.touched.Email) ||
                            !formik.values.Email[formik.values.Email.length - 1]
                              ? 'disabled'
                              : null
                          }
                          onClick={() => {
                            const emails = [...formik.values.Email, ''];
                            formik.setFieldValue('Email', emails);
                          }}
                        >
                          Add email
                        </DialogLinkButton>
                      </DialogField>
                    </DialogFields>
                  </FlexItem>
                </FlexContainer>
              )}
              <FlexContainer className='marginBottomMd marginTopMd align-center FlexContainerNotification'>
                <FlexItem flex={1}>
                  <CheckboxContainer>
                    <FormControlLabel
                      className='Say2EatCheckbox'
                      control={
                        <Checkbox
                          labelStyle={{color: '#172751'}}
                          iconStyle={{fill: '#FF2D38'}}
                          inputStyle={{color: '#FF2D38'}}
                          style={{color: '#FF2D38'}}
                          checked={formik.values.isActiveFax ? true : false}
                          onChange={(event, checked) =>
                            formik.setFieldValue('isActiveFax', checked)
                          }
                        />
                      }
                      label='Fax'
                    />
                    <ButtonInfo className='Outline ButtonInfoDrop'>
                      <div className='DropInfo DropInfoLeft'>
                        Send a fax order notification. The email notification
                        will be sent automatically, and the fax number field
                        should contain a valid fax number. To add multiple fax
                        numbers, please provide the fax numbers separated by
                        commas and spaces, ex. +11234567890, +11234567890
                      </div>
                    </ButtonInfo>
                  </CheckboxContainer>
                </FlexItem>
                {formik.values.isActiveFax && 'isDisabled' && (
                  <FlexItem flex={2}>
                    <DialogFields>
                      <DialogField className='DialogField w100 withRemove'>
                        <FieldInput
                          className={cn(
                            formik.touched.Fax && formik.errors.Fax && 'error'
                          )}
                          name='Fax'
                          value={formik.values.Fax}
                          onChange={formik.handleChange}
                          disabled={!formik.values.isActiveFax}
                          onBlur={formik.handleBlur}
                          placeholder='fax'
                        />
                        <ErrorSignWrapper>
                          {formik.touched.Fax && formik.errors.Fax && (
                            <ErrorSign>{formik.errors.Fax}</ErrorSign>
                          )}
                        </ErrorSignWrapper>
                      </DialogField>
                    </DialogFields>
                  </FlexItem>
                )}
              </FlexContainer>
              {/* <FlexContainer alignItems='center' className='marginBottomMd'> */}
              {/*  <FlexItem flex={1}> */}
              {/*    <FormControlLabel */}
              {/*      className='Say2EatCheckbox' */}
              {/*      control={ */}
              {/*        <Checkbox */}
              {/*          labelStyle={{color: '#172751'}} */}
              {/*          iconStyle={{fill: '#FF2D38'}} */}
              {/*          inputStyle={{color: '#FF2D38'}} */}
              {/*          style={{color: '#FF2D38'}} */}
              {/*          checked={formik.values.isActiveVoiceCall} */}
              {/*          onChange={(event, checked) => */}
              {/*            formik.setFieldValue('isActiveVoiceCall', checked) */}
              {/*          } */}
              {/*        /> */}
              {/*      } */}
              {/*      label='VoiceCall' */}
              {/*    /> */}
              {/*  </FlexItem> */}
              {/*  <FlexItem flex={2}> */}
              {/*    <DialogField className='DialogField withRemove'> */}
              {/*      <FieldInput */}
              {/*        className={cn( */}
              {/*          formik.touched.VoiceCall && */}
              {/*          formik.errors.VoiceCall && */}
              {/*          'error', */}
              {/*          !formik.values.isActiveVoiceCall && 'isDisabled' */}
              {/*        )} */}
              {/*        name='VoiceCall' */}
              {/*        value={formik.values.VoiceCall} */}
              {/*        disabled={!formik.values.isActiveVoiceCall} */}
              {/*        onChange={formik.handleChange} */}
              {/*        onBlur={formik.handleBlur} */}
              {/*        placeholder='voiceCall' */}
              {/*      /> */}
              {/*      <ErrorSignWrapper> */}
              {/*        {formik.touched.VoiceCall && formik.errors.VoiceCall && ( */}
              {/*          <ErrorSign>{formik.errors.VoiceCall}</ErrorSign> */}
              {/*        )} */}
              {/*      </ErrorSignWrapper> */}
              {/*    </DialogField> */}
              {/*  </FlexItem> */}
              {/* </FlexContainer> */}
              {/* <FlexContainer alignItems='center' className='marginBottomMd'> */}
              {/*  <FlexItem flex={1}> */}
              {/*    <FormControlLabel */}
              {/*      className='Say2EatCheckbox' */}
              {/*      control={ */}
              {/*        <Checkbox */}
              {/*          labelStyle={{color: '#172751'}} */}
              {/*          iconStyle={{fill: '#FF2D38'}} */}
              {/*          inputStyle={{color: '#FF2D38'}} */}
              {/*          style={{color: '#FF2D38'}} */}
              {/*          checked={formik.values.isActiveSMS} */}
              {/*          onChange={(event, checked) => */}
              {/*            formik.setFieldValue('isActiveSMS', checked) */}
              {/*          } */}
              {/*        /> */}
              {/*      } */}
              {/*      label='SMS' */}
              {/*    /> */}
              {/*  </FlexItem> */}
              {/*  <FlexItem flex={2}> */}
              {/*    <DialogField className='DialogField withRemove'> */}
              {/*      <FieldInput */}
              {/*        className={cn( */}
              {/*          formik.touched.SMS && formik.errors.SMS && 'error', */}
              {/*          !formik.values.isActiveSMS && 'isDisabled' */}
              {/*        )} */}
              {/*        name='SMS' */}
              {/*        value={formik.values.SMS} */}
              {/*        onChange={formik.handleChange} */}
              {/*        onBlur={formik.handleBlur} */}
              {/*        disabled={!formik.values.isActiveSMS} */}
              {/*        placeholder='sms' */}
              {/*      /> */}
              {/*      <ErrorSignWrapper> */}
              {/*        {formik.touched.SMS && formik.errors.SMS && ( */}
              {/*          <ErrorSign>{formik.errors.SMS}</ErrorSign> */}
              {/*        )} */}
              {/*      </ErrorSignWrapper> */}
              {/*    </DialogField> */}
              {/*  </FlexItem> */}
              {/* </FlexContainer> */}
              <DialogAction>
                <ButtonPrimary
                  type='submit'
                  onClick={formik.handleSubmit}
                  className={formik.isSubmitting && 'disabled'}
                >
                  <span>Apply</span>
                </ButtonPrimary>
              </DialogAction>
            </DialogBody>
          </>
        )}
        {showCloudPrintModal && <CloudPrint onClose={manageCloudPrintModal} />}
      </DialogContainer>
    </Dialog>
  );
}

OrdersNotifications.propTypes = {
  onClose: PropTypes.func
};

OrdersNotifications.defaultProps = {
  onClose: () => {}
};
