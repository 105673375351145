import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  position: relative;
  padding: 0 0 5px 30px;

  &.ProgressBarDesktop {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  &.ProgressBarMob {
    display: none;
    padding: 0 0 28px 5px;
    @media (max-width: 1250px) {
      display: block;
    }

    .ProgressBar {
      width: 100%;
      height: 14px;
      top: inherit;
      bottom: 0;
    }

    .ProgressStep {
      padding: 0;

      span {
        display: none;
        color: #929cb9 !important;
      }

      &:first-child {
        z-index: 2;

        &::before {
          top: 35px;
          left: 4px;
          width: 50%;
          height: 4px !important;
        }

        &:after {
          left: 50%;
          top: 30px;
          width: 14px;
          height: 14px;
        }
      }

      &:nth-child(2) {
        &::before {
          top: 35px;
          left: 4px;
          width: 75%;
          height: 4px !important;
        }

        &:after {
          left: 75%;
          top: 30px;
          width: 14px;
          height: 14px;
        }
      }
    }

    .ProgressSteps {
      &.firstStepDone {
        .ProgressStep {
          &:first-child {
            span {
              display: block;
            }
          }
          &:nth-child(2) {
            &::before {
              top: 19px;
            }

            &:after {
              top: 14px;
            }
          }
        }
      }

      &.secondStepDone {
        .ProgressStep {
          &:nth-child(2) {
            span {
              display: block;
            }
          }
        }
      }
      &.completeSteps {
        .ProgressStep {
          &:nth-child(2) {
            span {
              display: block;
            }
            &::before {
              width: calc(100% - 10px);
            }
          }
        }
      }
    }
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-color: #f9f9f9;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
`;

export const ProgressSteps = styled.div`
  &.firstStepDone {
    .ProgressStep {
      &:first-child {
        &::before {
          height: 100%;
          background: linear-gradient(
            277.15deg,
            #232d3a 20.92%,
            #6e7d89 94.87%
          );
          transition-property: height;
          transition-duration: 0.6s;
        }

        &:after {
          transition-delay: 0.15s;
          background-color: #2f3d48;
          border-color: #2f3d48;
        }
      }

      span {
        transition-delay: 0.2s;
        color: #172751;
      }
    }
  }

  &.secondStepDone {
    .ProgressStep {
      &:first-child {
        &::before {
          height: 100%;
          background: linear-gradient(
            277.31deg,
            #ff2d38 14.35%,
            #f0868c 94.32%
          );
          transition: none;
        }

        &:after {
          background-color: #ff2d38;
          border-color: #ff2d38;
          transition: none;
        }

        span {
          color: #ff2d38;
          transition: none;
        }
      }

      &:nth-child(2) {
        &::before {
          height: 100%;
          background: linear-gradient(
            277.15deg,
            #232d3a 20.92%,
            #6e7d89 94.87%
          );
          transition-property: height;
          transition-duration: 0.6s;
        }

        &:after {
          transition-delay: 0.2s;
          background-color: #2f3d48;
          border-color: #2f3d48;
        }

        span {
          transition-delay: 0.2s;
          color: #172751;
        }
      }
    }
  }

  &.completeSteps {
    .ProgressStep {
      &::before {
        height: 100%;
        background: linear-gradient(
          277.31deg,
          #ff2d38 14.35%,
          #f0868c 94.32%
        ) !important;
        transition: none;
      }

      &:after {
        background-color: #ff2d38 !important;
        border-color: #ff2d38 !important;
        transition: none;
      }

      span {
        color: #ff2d38;
        transition: none;
      }
    }
  }
`;

export const ProgressStep = styled.div`
  position: relative;
  padding-top: 60px;

  &::before {
    position: absolute;
    content: '';
    top: -10px;
    left: -23px;
    width: 6px;
    height: 0;
    max-height: 80px;
    border-radius: 30px;
    background: transparent;
    z-index: 1;
    transition-property: height;
    transition-duration: 0.6s;
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: -30px;
    border: 2px solid #929cb9;
    width: 20px;
    height: 20px;
    z-index: 2;
    border-radius: 50%;
    box-sizing: border-box;
    transition-property: background-color, border-color;
    transition-duration: 0.6s;
  }

  span {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: #929cb9;
    transition-property: color;
    transition-duration: 0.6s;
    @media (max-width: 1250px) {
      color: #929cb9;
      font-size: 14px;
    }
  }

  &:first-child {
    &::before {
      max-height: 62px;
      top: 7px;
    }
  }
`;
