import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Headline from '../../../components/headline2/HeadlineComponentStyle';
import UsersSearchField from './UsersSearchField/UsersSearchField';
import UsersTable from './UsersTable/UsersTable';
import UsersTablePagination from './UsersTable/UsersTablePagination';
import {MainPaper} from '../../../components/layout';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import ManageUser from './modals/ManageUser';
import {
  CLEAR_SET_RESET_PASSWORD_USER_ID,
  CLEAR_USER
} from '../../../store/adminUsers/actions';
import {
  selectedResetPasswordUser,
  selectedUser
} from '../../../store/adminUsers/selectors';
import ResetPassword from './modals/ResetPassword';

const Users = () => {
  const selectedRecord = useSelector(selectedUser);
  const resetPasswordId = useSelector(selectedResetPasswordUser);
  const dispatch = useDispatch();

  const closeDialog = () => {
    dispatch(CLEAR_USER());
  };
  const closeResetPasswordDialog = () => {
    dispatch(CLEAR_SET_RESET_PASSWORD_USER_ID());
  };

  return (
    <DashboardContent className='DashboardContentWithoutHeader'>
      <Headline className='marginBottomXs'>Users List</Headline>
      <MainPaper>
        <UsersSearchField />
        <UsersTable />
        <UsersTablePagination />
      </MainPaper>
      {selectedRecord && <ManageUser onClose={closeDialog} />}
      {resetPasswordId && <ResetPassword onClose={closeResetPasswordDialog} />}
    </DashboardContent>
  );
};

export default Users;
