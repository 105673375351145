import React from 'react';
import {useSelector} from 'react-redux';
import SatisfactionContainer from './SatisfactionContainer';
import {DashboardInners} from '../../../../../components/DashboardInners/styles';
import StatisticsSurvey from './StatisticsSurvey';
import {feedbackRankingTypes} from '../../constants';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import normalizePercentageByDecimal from '../../../../../helpers/functionUtils/normalizePercentageByDecimal';

const TabDelivery = () => {
  const {
    StarsTotalCount,
    OneStarsCount,
    AverageSatisfactionRate,
    TwoStarsCount,
    ThreeStarsCount,
    FourStarsCount,
    FiveStarsCount,
    BadRankingTotal,
    SurveyAnswers,
    GoodRankingTotal
  } = useSelector((state) => state.feedbackReducer.data);

  const calculatePercentage = (field1, field2) => {
    const percentage = 100 / (field1 / field2);
    return Number.isNaN(percentage) ? 0 : percentage;
  };
  const displayAnswersStats = (fieldName, fieldValue, surveyArr) =>
    surveyArr?.find((el) => el.Name === fieldName && el.Value === fieldValue)
      ?.Occurrence || 0;

  const [
    percentOneStar,
    percentTwoStar,
    percentThreeStar,
    percentFourStar,
    percentFiveStar
  ] = normalizePercentageByDecimal([
    calculatePercentage(StarsTotalCount, OneStarsCount),
    calculatePercentage(StarsTotalCount, TwoStarsCount),
    calculatePercentage(StarsTotalCount, ThreeStarsCount),
    calculatePercentage(StarsTotalCount, FourStarsCount),
    calculatePercentage(StarsTotalCount, FiveStarsCount)
  ]);
  return (
    <>
      <DashboardInner>
        <SatisfactionContainer
          title='Overall delivery satisfaction score'
          description='Average overall satisfaction rate for delivery orders'
          ratingSatisfaction={AverageSatisfactionRate?.toFixed(1) || 0}
          percentOneStar={percentOneStar}
          responseOneStar={OneStarsCount}
          percentTwoStar={percentTwoStar}
          responseTwoStar={TwoStarsCount}
          percentThreeStar={percentThreeStar}
          responseThreeStar={ThreeStarsCount}
          percentFourStar={percentFourStar}
          responseFourStar={FourStarsCount}
          percentFiveStar={percentFiveStar}
          responseFiveStar={FiveStarsCount}
        />
      </DashboardInner>
      <DashboardInners>
        <StatisticsSurvey
          title='1-3 Stars - Oh no!'
          description='Please share what went wrong?'
          total={BadRankingTotal}
          sumFirstQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            0,
            SurveyAnswers
          )}
          firstQuestionText='Missing items'
          sumSecondQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            1,
            SurveyAnswers
          )}
          secondQuestionText='Packaging issues'
          sumThirdQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            2,
            SurveyAnswers
          )}
          thirdQuestionText='Food quality'
        />
        <StatisticsSurvey
          title='4-5 Stars - Alright!'
          description='What did you like best?'
          total={GoodRankingTotal}
          sumFirstQuestion={displayAnswersStats(
            feedbackRankingTypes.GoodFeedback,
            0,
            SurveyAnswers
          )}
          firstQuestionText='Food was delivered faster than I expected'
          sumSecondQuestion={displayAnswersStats(
            feedbackRankingTypes.GoodFeedback,
            1,
            SurveyAnswers
          )}
          secondQuestionText='I received exactly what I ordered'
          sumThirdQuestion={displayAnswersStats(
            feedbackRankingTypes.GoodFeedback,
            2,
            SurveyAnswers
          )}
          thirdQuestionText='The food was delicious'
          sumFourthQuestion={displayAnswersStats(
            feedbackRankingTypes.GoodFeedback,
            3,
            SurveyAnswers
          )}
          fourthQuestionText='Great value for money'
        />
      </DashboardInners>
      {/* <DashboardInner> */}
      {/*  <SatisfactionContainer */}
      {/*    title='Courier satisfaction score' */}
      {/*    description='Average courier satisfaction rate for delivery orders' */}
      {/*    ratingSatisfaction={ */}
      {/*      DeliveryStats?.AverageSatisfactionRate?.toFixed(1) || 0 */}
      {/*    } */}
      {/*    percentOneStar={calculatePercentage( */}
      {/*      DeliveryStats?.StarsTotalCount, */}
      {/*      DeliveryStats?.OneStarsCount */}
      {/*    )} */}
      {/*    responseOneStar={DeliveryStats?.OneStarsCount} */}
      {/*    percentTwoStar={calculatePercentage( */}
      {/*      DeliveryStats?.StarsTotalCount, */}
      {/*      DeliveryStats?.TwoStarsCount */}
      {/*    )} */}
      {/*    responseTwoStar={DeliveryStats?.TwoStarsCount} */}
      {/*    percentThreeStar={calculatePercentage( */}
      {/*      DeliveryStats?.StarsTotalCount, */}
      {/*      DeliveryStats?.ThreeStarsCount */}
      {/*    )} */}
      {/*    responseThreeStar={DeliveryStats?.ThreeStarsCount} */}
      {/*    percentFourStar={calculatePercentage( */}
      {/*      DeliveryStats?.StarsTotalCount, */}
      {/*      DeliveryStats?.FourStarsCount */}
      {/*    )} */}
      {/*    responseFourStar={DeliveryStats?.FourStarsCount} */}
      {/*    percentFiveStar={calculatePercentage( */}
      {/*      DeliveryStats?.StarsTotalCount, */}
      {/*      DeliveryStats?.FiveStarsCount */}
      {/*    )} */}
      {/*    responseFiveStar={DeliveryStats?.FiveStarsCount} */}
      {/*  /> */}
      {/* </DashboardInner> */}
      {/* <DashboardInners> */}
      {/*  <StatisticsSurvey */}
      {/*    title='1-3 Stars - Oh no!' */}
      {/*    description='Please share with us what went wrong?' */}
      {/*    total={DeliveryStats?.BadRankingTotal} */}
      {/*    sumFirstQuestion={displayAnswersStats( */}
      {/*      feedbackRankingTypes.BadDeliveryFeedback, */}
      {/*      0, */}
      {/*      SurveyAnswers */}
      {/*    )} */}
      {/*    firstQuestionText='Delivery never arrived' */}
      {/*    sumSecondQuestion={displayAnswersStats( */}
      {/*      feedbackRankingTypes.BadDeliveryFeedback, */}
      {/*      1, */}
      {/*      SurveyAnswers */}
      {/*    )} */}
      {/*    secondQuestionText='Delivery was late' */}
      {/*    sumThirdQuestion={displayAnswersStats( */}
      {/*      feedbackRankingTypes.BadDeliveryFeedback, */}
      {/*      2, */}
      {/*      SurveyAnswers */}
      {/*    )} */}
      {/*    thirdQuestionText='Bad drop-off' */}
      {/*    sumFourthQuestion={displayAnswersStats( */}
      {/*      feedbackRankingTypes.BadDeliveryFeedback, */}
      {/*      3, */}
      {/*      SurveyAnswers */}
      {/*    )} */}
      {/*    fourthQuestionText='Bad communication' */}
      {/*  /> */}
      {/*  <StatisticsSurvey */}
      {/*    title='4-5 Stars - Alright!' */}
      {/*    description='What did you like best in your order?' */}
      {/*    total={DeliveryStats?.GoodRankingTotal} */}
      {/*    sumFirstQuestion={ */}
      {/*      SurveyAnswers?.find( */}
      {/*        (el) => */}
      {/*          el.Name === feedbackRankingTypes.GoodDeliveryFeedback && */}
      {/*          el.Value === 0 */}
      {/*      )?.Occurrence || 0 */}
      {/*    } */}
      {/*    firstQuestionText='Fast delivery' */}
      {/*    sumSecondQuestion={ */}
      {/*      SurveyAnswers?.find( */}
      {/*        (el) => */}
      {/*          el.Name === feedbackRankingTypes.GoodDeliveryFeedback && */}
      {/*          el.Value === 1  */}
      {/*      )?.Occurrence || 0  */}
      {/*    }  */}
      {/*    secondQuestionText='Friendly service'  */}
      {/*    sumThirdQuestion={  */}
      {/*      SurveyAnswers?.find(  */}
      {/*        (el) =>  */}
      {/*          el.Name === feedbackRankingTypes.GoodDeliveryFeedback &&  */}
      {/*          el.Value === 2  */}
      {/*      )?.Occurrence || 0  */}
      {/*    }  */}
      {/*    thirdQuestionText='Efficient courier'  */}
      {/*    sumFourthQuestion={  */}
      {/*      SurveyAnswers?.find(  */}
      {/*        (el) => */}
      {/*          el.Name === feedbackRankingTypes.GoodDeliveryFeedback &&  */}
      {/*          el.Value === 3  */}
      {/*      )?.Occurrence || 0  */}
      {/*    }  */}
      {/*    fourthQuestionText='Good communication'  */}
      {/*  />  */}
      {/* </DashboardInners>  */}
    </>
  );
};

export default TabDelivery;
