import {put, select, takeEvery} from 'redux-saga/effects';

import {getBADOrdersHistoryApi} from '../../../helpers/services/api/bookADrive';
import {
  GET_BAD_HISTORY_ORDERS_SUCCESS,
  GET_BAD_HISTORY_ORDERS_COUNT,
  GET_BAD_HISTORY_ORDERS,
  SET_BAD_HISTORY_TABLE_CONFIG,
  SET_BAD_HISTORY_NEXT_PAGE
} from './action';

function* getBookADriverHistoryOrders({payload}) {
  try {
    yield put(
      SET_BAD_HISTORY_TABLE_CONFIG({
        to: encodeURIComponent(new Date().toISOString())
      })
    );
    const {config, historyOrders} = yield select(
      (state) => state.bookADriverListReducer
    );
    const {data} = yield getBADOrdersHistoryApi(config);
    if (data) {
      yield put(
        GET_BAD_HISTORY_ORDERS_SUCCESS([...historyOrders, ...data.data.rows])
      );
      yield put(GET_BAD_HISTORY_ORDERS_COUNT(data.data.totalCount));
      yield put(SET_BAD_HISTORY_NEXT_PAGE());
    }
    return true;
  } catch (error) {
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* bookADriverListSaga() {
  yield takeEvery(GET_BAD_HISTORY_ORDERS, getBookADriverHistoryOrders);
}

export default bookADriverListSaga;
