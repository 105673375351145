/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {useSelector} from 'react-redux';
import {
  DashboardSection,
  DashboardAccordions,
  AccordionItems,
  AccordionItem,
  AccordionItemBody
} from '../../dashboard/styles';
import TabPanel from '../../../../components/shared/TabPanel';
import Dgd from '../DgD';
import {
  CATEGORY_SCHEME,
  ITEM_SCHEME,
  MODIFIER_SCHEME
} from '../../../../store/menuEditor/helper/MenuTree';
import CategoryAccordionTitle from '../components/CategoryAccordionTitle';
import ItemAccordionTitle from '../components/ItemAccordionTitle';
import ModifierAccordionTitle from '../components/ModifierAccordionTitle';

const HiddenView = (props) => {
  const selectedCategories = useSelector(
    (store) => store.menuEditorReducer.selectedCategories
  );
  const selectedItems = useSelector(
    (store) => store.menuEditorReducer.selectedItems
  );
  const selectedModifiers = useSelector(
    (store) => store.menuEditorReducer.selectedModifiers
  );

  const {
    sectionsData,
    itemsData,
    modifiersData,
    handleAccordionOpen,
    expanded
  } = props;

  return (
    <TabPanel>
      <DashboardSection className='Mt20'>
        <AccordionItems>
          <AccordionItem>
            <Accordion
              expanded={expanded.categories === 'categories'}
              onChange={() => handleAccordionOpen('categories')}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <CategoryAccordionTitle sectionsData={sectionsData} />
              </AccordionSummary>
              <AccordionItemBody>
                <DashboardAccordions>
                  {Boolean(sectionsData.length) && (
                    <Dgd
                      withoutMainDrag
                      items={sectionsData}
                      itemScheme={CATEGORY_SCHEME}
                      withBulkCheckbox
                      bulkSelectedMap={selectedCategories}
                    />
                  )}
                </DashboardAccordions>
              </AccordionItemBody>
            </Accordion>
          </AccordionItem>

          <AccordionItem>
            <Accordion
              expanded={expanded.items === 'items'}
              onChange={() => {
                handleAccordionOpen('items');
              }}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <ItemAccordionTitle itemsData={itemsData} />
                </div>
              </AccordionSummary>
              <AccordionItemBody>
                <DashboardAccordions>
                  {Boolean(itemsData.length) && (
                    <Dgd
                      withoutMainDrag
                      items={itemsData}
                      itemScheme={ITEM_SCHEME}
                      withBulkCheckbox
                      bulkSelectedMap={selectedItems}
                    />
                  )}
                </DashboardAccordions>
              </AccordionItemBody>
            </Accordion>
          </AccordionItem>

          <AccordionItem>
            <Accordion
              expanded={expanded.modifiers === 'modifiers'}
              onChange={() => handleAccordionOpen('modifiers')}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <ModifierAccordionTitle modifiersData={modifiersData} />
              </AccordionSummary>
              <AccordionItemBody>
                <DashboardAccordions>
                  {Boolean(modifiersData.length) && (
                    <Dgd
                      withoutMainDrag
                      items={modifiersData}
                      itemScheme={MODIFIER_SCHEME}
                      withBulkCheckbox
                      bulkSelectedMap={selectedModifiers}
                    />
                  )}
                </DashboardAccordions>
              </AccordionItemBody>
            </Accordion>
          </AccordionItem>
        </AccordionItems>
      </DashboardSection>
    </TabPanel>
  );
};

HiddenView.propTypes = {
  modifiersData: PropTypes.array.isRequired,
  sectionsData: PropTypes.array.isRequired,
  itemsData: PropTypes.array.isRequired,
  expanded: PropTypes.objectOf(PropTypes.bool),
  handleAccordionOpen: PropTypes.func
};

HiddenView.defaultProps = {
  expanded: {},
  handleAccordionOpen: () => {}
};
export default HiddenView;
