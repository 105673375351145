import {takeEvery, select} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {PATCH_USER_BANK_INFO} from './action';
import history from '../../history';
import {patchUserBankInfo} from '../../helpers/services/api/user';
import userId from '../global/user/selectors';

function* addUserBankInfoSaga({payload}) {
  try {
    const id = yield select(userId);
    yield patchUserBankInfo(id, payload);
    yield toast.success('Success verify your bank account');
    yield history.push('/my-account');
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* analyticsCustomersCountSaga() {
  yield takeEvery(PATCH_USER_BANK_INFO, addUserBankInfoSaga);
}

export default analyticsCustomersCountSaga;
