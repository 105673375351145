import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Dialog} from '@material-ui/core';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogBody from '../../../../components/DialogBody/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Body3 from '../../../../components/typography/Body3';
import {Body1, Body2} from '../../../../components/typography';
import {FlexContainer} from '../../../../components/layout';
import {IconWrapper} from '../styles';
import TipIcon from '../../../../assets/uberEats/tip-icon.svg';
import Body4 from '../../../../components/typography/Body4';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';

const ByocFourth = (props) => {
  const [showWaitingContractModal, setShowWaitingContractModal] = useState(
    false
  );
  const [showFinishModal, setShowFinishModal] = useState(false);

  return (
    <div>
      <Subtitle5>Sign Contract</Subtitle5>
      <br />
      <Body3>Digitally sign the contract UberEats sent you.</Body3>
      <br />

      <Body1 className='marginBottom2Xs'>Tips</Body1>

      <FlexContainer>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 className='marginBottom2Xs' color='#969BA0'>
          The contract usually arrives by email within a few hours (up to 48).
          Check your email frequently.
        </Body2>
      </FlexContainer>

      <FlexContainer>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 className='marginBottom2Xs' color='#969BA0'>
          Once you sign that contract you will be live within 10-20 min.
        </Body2>
      </FlexContainer>

      {props.actionsRef
        ? ReactDOM.createPortal(
            <>
              <ButtonOutline
                className='ButtonLg'
                onClick={() => setShowWaitingContractModal(true)}
              >
                Waiting for contract
              </ButtonOutline>
              <ButtonPrimary onClick={() => setShowFinishModal(true)}>
                <span>Finish</span>
              </ButtonPrimary>
            </>,
            props.actionsRef
          )
        : null}

      {showWaitingContractModal && (
        <WaitingForContractModal
          onClose={() => setShowWaitingContractModal(false)}
        />
      )}

      {showFinishModal && (
        <FinishModal
          onClose={() => setShowFinishModal(false)}
          onOk={props.goNextAction}
        />
      )}
    </div>
  );
};

const WaitingForContractModal = (props) => {
  return (
    <Dialog open scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogTitle>Your UberEats contract is on its way</DialogTitle>
        <DialogBody>
          <Body4 className='marginTopXl text-center'>
            Check your email frequently today. Once you <b>digitally</b> sign
            it, the activation process is complete and you should be live in
            less than an hour.
          </Body4>
          <DialogActions>
            <DialogAction className='dFlexCenter'>
              <ButtonPrimary className='w200' onClick={props.onClose}>
                <span>Got it</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

const FinishModal = (props) => {
  return (
    <Dialog open scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogTitle>Finished the 3 activation steps?</DialogTitle>
        <DialogBody>
          <Body4 className='marginTopXl' textAlign='center'>
            The activation process is between you and UberEats, we will only
            know its working when the first UberEat order arrives.
          </Body4>
          <DialogActions>
            <DialogAction className='w50'>
              <ButtonOutline className='ButtonLg' onClick={props.onClose}>
                No
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary onClick={props.onOk} data-gtm='BYOCActivated'>
                <span data-gtm='BYOCActivated'>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ByocFourth.propTypes = {
  actionsRef: PropTypes.object.isRequired,
  goNextAction: PropTypes.func.isRequired
};

WaitingForContractModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

FinishModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
};

export default ByocFourth;
