export const ORDER = {
  ID: 'id',
  EXPIRED_TIME: 'expiredTime',
  DATE: 'date',
  USER_NAME: 'userName',
  USER_PHONE: 'userPhone',
  AMOUNT: 'amount',
  ORDER_STATUS: 'orderStatus',
  ORDER_TYPE: 'orderType',
  PAYMENT_TYPE: 'paymentType',
  DELIVERY_PROVIDER: 'deliveryProvider',
  PLATFORM: 'Platform',
  AHEAD_TIME: 'OrderAheadTime',
  REFUND: 'refund',
  INVOICE_ID: 'invoiceID',
  ORDER_CANCELLATION_STATUS: 'orderCancellationStatus'
};

export const ORDER_STATUS = {
  NEW: 'New',
  ACCEPTED: 'Accepted',
  COMPLETED: 'Completed',
  CANCELLED: 'Canceled',
  OVERDUE: 'Overdue',
  FUTURE: 'Future Order'
};

export const ORDER_CANCELLATION_STATUS = {
  CANCELLED_SUCCESSFULLY: 'CancelledSuccessfully'
};

export const ORDER_CHANNEL = {
  ALL: 'All',
  UBEREATS_CHANNEL: 'UberEats',
  SAUCE_CHANNEL: 'Sauce'
};

export const ORDER_CHANNELS = {
  [ORDER_CHANNEL.UBEREATS_CHANNEL]: 'UberEats',
  [ORDER_CHANNEL.SAUCE_CHANNEL]: 'Sauce'
};

export const ORDER_MISC = {
  ALL: 'All',
  REFUND: 'Refund'
};

export const ORDER_MISC_MANY = {
  [ORDER_MISC.REFUND]: 'Refund'
};

export const ORDER_PAYMENT_METHOD = {
  ALL: 'All',
  CREDIT_CARD: 'Credit card',
  GOOGLE_PAY: 'Google pay',
  APPLE_PAY: 'Apple pay'
};

export const ORDER_PAYMENT_METHODS = {
  [ORDER_PAYMENT_METHOD.CREDIT_CARD]: 'Credit Card',
  [ORDER_PAYMENT_METHOD.GOOGLE_PAY]: 'Google Pay',
  [ORDER_PAYMENT_METHOD.APPLE_PAY]: 'Apple Pay'
};

export const ORDER_TYPE = {
  PICKUP: 'PickUp',
  DELIVERY: 'Delivery',
  SAUCE_DISPATCH: 'SauceDispatch',
  DINE_IN: 'DineIn',
  ALL: 'All',
  CURBSIDE_PICKUP: 'CurbsidePickup',
  UBER_DELIVERY: 'UberEatsDelivery',
  UBER_PICKUP: 'UberPickUp'
};

export const ORDER_TYPES = {
  [ORDER_TYPE.UBER_DELIVERY]: 'UberEats delivery',
  [ORDER_TYPE.SAUCE_DISPATCH]: 'Sauce delivery',
  [ORDER_TYPE.PICKUP]: 'Pick up',
  [ORDER_TYPE.CURBSIDE_PICKUP]: 'Curbside pickup',
  [ORDER_TYPE.DELIVERY]: 'Restaurant Delivery',
  [ORDER_TYPE.DINE_IN]: 'Dine in'
};
export const ORDER_TYPES_ALL = {
  [ORDER_TYPE.ALL]: 'All'
};

export const HUBSPOT_TICKET_STATUS = {
  CLOSED: '29224352',
  CLOSED_STAGE: '4'
};

export const CLOUD_PRINT_URL = 'https://say2print.azurewebsites.net/starprint';

export const OLD_DISPATCH_PROVIDER = 'Say2eat Dispatch';
export const NEW_DISPATCH_PROVIDER = 'Say2eat Dispatch V2';

export const MIN_ADJUST_AMOUNT = 0.5;
export const MAX_ADJUST_AMOUNT = 500;

export const AVAILABLE_ORDER_STATUSES = [
  'Scheduled',
  'Assigned',
  'OnRouteToPickup'
];

export default {ORDER};
