import {createAction} from 'redux-act';

export const FORM_CHANGED = createAction('modals/FORM_CHANGED');
export const SHOW_EMAIL_CHECK_MODAL = createAction(
  'modals/SHOW_EMAIL_CHECK_MODAL'
);
export const UPDATE_EMAIL = createAction('modals/UPDATE_EMAIL');

export const OPEN_CANCELLATION_CONFIRMATION_MODAL = createAction(
  'modals/OPEN_CANCELLATION_CONFIRMATION_MODAL'
);

export const SET_LOADING_CANCELLATION = createAction(
  'modals/SET_LOADING_CANCELLATION'
);
