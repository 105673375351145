import styled from 'styled-components';

const FlexItem = styled.div`
  flex: ${(props) => props.flex || null};
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.bold {
    font-weight: bold;
  }
  &.orderIconContainer {
    font-size: 0;
    flex: 0 0 auto;
    margin-right: 8px;
    @media (max-width: 1000px) {
      margin: 0 0 4px 0;
    }
    img {
      display: block;
      max-width: 44px;
      width: 100%;
      @media (max-width: 1024px) {
        max-width: 30px;
      }
      @media (max-width: 767px) {
        max-width: 24px;
      }
    }
  }
  &.w100 {
    width: 100%;
  }
  &.centerText {
    text-align: center;
  }
  &.FlexItmValue {
    max-width: 141px;
    * {
      word-wrap: break-word;
      text-align: right;
    }
  }
  &.wAutoSpace {
    flex: 1 1 auto;
  }
  &.wAuto {
    flex: 0 0 auto;
  }
  &.dFlex {
    display: flex;
  }
  &.OrderDetailsLside {
    margin-right: 32px;
    @media (max-width: 700px) {
      margin-right: 10px;
    }
    @media (max-width: 599px) {
      margin-right: 0;
    }
  }

  &.alignSelfCenter {
    align-self: center;
  }
  &.OrderTdDelivery,
  &.SavingInfo {
    @media (max-width: 1700px) {
      display: none;
    }
  }
  &.marginTopXs {
    margin-top: 12px;
  }
`;

export default FlexItem;
