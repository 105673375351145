import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useDispatch, useSelector} from 'react-redux';
import {DialogAction, DialogBody} from '../../../dashboard/styles';
import {Body1, Caption1} from '../../../../../components/typography';
import {FlexContainer} from '../../../../../components/layout';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import ButtonInfo from '../../../../../components/buttons/ButtonInfo/styles';
import {
  PATCH_LOCATION_ACTION,
  TURN_OFF_COMPLETED_NOTIFICATION
} from '../../../../../store/orders/orderList/action';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../../components/Checkbox/styles';

export default function OrdersSettings({onClose}) {
  const dispatch = useDispatch();

  const {locationName} = useSelector((state) => state.user);

  const {
    locationData: {RushHourPerMinute},
    testOrderLoading
  } = useSelector((state) => state.ordersHistoryReducer);
  const {ShowCompletedOrderNotification} = useSelector(
    (state) => state.user.userData
  );

  const [checkbox, setCheckbox] = useState(!!RushHourPerMinute);
  const [completedNotification, setCompletedNotification] = useState(
    ShowCompletedOrderNotification
  );
  const [rushHourPerMinute, setRushHourPerMinute] = useState(
    RushHourPerMinute || null
  );

  const handleSubmit = () => {
    if (rushHourPerMinute) {
      dispatch(
        PATCH_LOCATION_ACTION({
          RushHourPerMinute: checkbox ? rushHourPerMinute : null
        })
      );
      onClose();
    }
  };

  const handleCompletedNotification = (event) => {
    const {checked} = event.target;
    setCompletedNotification(checked);
    dispatch(TURN_OFF_COMPLETED_NOTIFICATION(checked));
  };

  return (
    <Dialog open onClose={onClose} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle className='marginBottomXl'>Orders Settings</DialogTitle>
        <DialogBody>
          <div
            className='marginBottomMd'
            style={{display: 'flex', alignItems: 'center'}}
          >
            <Body1 className='inlineBlock marginRightSm'>Now Editing:</Body1>
            <Caption1>{locationName}</Caption1>
          </div>

          {/* <Headline className='light marginBottomXs'> */}
          {/*  <Subtitle4>Order Throlling</Subtitle4> */}
          {/* </Headline> */}

          {/* <div className='marginBottomMd'> */}
          {/*  <Body1> */}
          {/*    Limit the number of orders scheduled for the same time per */}
          {/*    location. Auto change pickup and delivery ETAs accordingly for */}
          {/*    rush hour */}
          {/*  </Body1> */}
          {/* </div> */}

          <div className='marginBottomMd'>
            <FlexContainer className='align-center'>
              <CheckboxContainer>
                <FormControlLabel
                  className='Say2EatCheckbox'
                  control={
                    <Checkbox
                      labelStyle={{color: '#172751'}}
                      iconStyle={{fill: '#FF2D38'}}
                      inputStyle={{color: '#FF2D38'}}
                      style={{color: '#FF2D38'}}
                      checked={completedNotification}
                      onChange={(event) => handleCompletedNotification(event)}
                    />
                  }
                  label='Show Completed Status Notification'
                />
              </CheckboxContainer>
              <ButtonInfo className='Outline ButtonInfoDrop'>
                <div className='DropInfo DropInfoCenter DropInfoRightMob'>
                  Control whether you want to see a Completed notification
                  whenever order status changes to Completed
                </div>
              </ButtonInfo>
            </FlexContainer>
          </div>

          {/* <FlexContainer className='marginBottomXl' alignItems='center'> */}
          {/*  <FlexItem className='marginRightXs'> */}
          {/*    <Body1>Set a max of</Body1> */}
          {/*  </FlexItem> */}
          {/*  <FlexItem className='marginRightXs'> */}
          {/*    <FieldInput */}
          {/*      type='number' */}
          {/*      className={cn('Sm', !checkbox && 'isDisabled')} */}
          {/*      disabled={!checkbox} */}
          {/*      value={rushHourPerMinute} */}
          {/*      max={100} */}
          {/*      onChange={(e) => { */}
          {/*        const val = e.target.value; */}
          {/*        if (val.length < 5) { */}
          {/*          setRushHourPerMinute(val); */}
          {/*        } */}
          {/*      }} */}
          {/*    /> */}
          {/*  </FlexItem> */}
          {/*  <FlexItem> */}
          {/*    <Body1>accepted orders for number of minutes</Body1> */}
          {/*  </FlexItem> */}
          {/* </FlexContainer> */}

          {/* <Headline className='light marginBottomXs'> */}
          {/*  <Subtitle4>Test Order</Subtitle4> */}
          {/* </Headline> */}

          {/* <div className='marginBottomXs'> */}
          {/*  <Body1> */}
          {/*    Send yourself a test order to make sure everything is functioning */}
          {/*    correctly */}
          {/*  </Body1> */}
          {/* </div> */}

          {/* <BorderedButton */}
          {/*  onClick={() => dispatch(SEND_TEST_ORDER())} */}
          {/*  className={cn('ButtonSmall', testOrderLoading && 'isDisabled')} */}
          {/* > */}
          {/*  Send Test Order */}
          {/* </BorderedButton> */}

          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>Close</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

OrdersSettings.propTypes = {
  onClose: PropTypes.func
};

OrdersSettings.defaultProps = {
  onClose: () => {}
};
