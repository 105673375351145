import React, {useRef, useState} from 'react';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import {Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import CheckboxS2E from 'components/Checkbox';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline} from 'components/buttons';
import {FieldLabel} from 'components/FieldLabel/styles';
import FieldInput from 'components/FieldInput';
import {
  OPEN_ACTIONS_MADE_MODAL,
  SEND_ORDER_ACTIONS
} from 'store/realTimeBoardV2/action';
import RealDeliveryBoardV2ActionsMadeSchema from 'helpers/services/formValidationSchemas/RealDeliveryBoardV2ActionsMadeSchema';
import DialogClose from 'components/DialogClose/styles';
import FieldTextarea from 'components/FieldTextarea';

export default function ActionsMadeModal() {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {openActionsModal, currentOrderId} = useSelector(
    (state) => state.realTimeBoardV2Reducer
  );
  const {userData} = useSelector((state) => state.user);

  const initial = {
    bookedADriver: false,
    bookADriverSauceOrderId: '',
    calledCustomer: false,
    calledLocation: false,
    calledCourier: false,
    cancelled: false,
    custom: false,
    description: ''
  };

  const closeDialog = () => {
    dispatch(OPEN_ACTIONS_MADE_MODAL({open: false}));
  };

  return (
    <Formik
      initialValues={initial}
      innerRef={formikRef}
      validationSchema={RealDeliveryBoardV2ActionsMadeSchema}
      onSubmit={(values) => {
        const body = {};
        Object.keys(values).forEach((key) => {
          if (values[key] === true) {
            body[key] = true;
          }
        });
        if (values.bookedADriver) {
          body.bookADriverSauceOrderId = values.bookADriverSauceOrderId;
        }
        if (values.custom) {
          body.description = values.description;
        }
        dispatch(SEND_ORDER_ACTIONS({data: body, id: currentOrderId}));
        closeDialog();
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        submitForm,
        touched,
        setFieldError,
        setFieldTouched
      }) => {
        return (
          <Dialog open={openActionsModal} onClose={closeDialog} scroll='body'>
            <DialogContainer className='DialogSm'>
              <DialogTitle className='marginBottomLg'>Actions made</DialogTitle>
              <DialogClose onClick={closeDialog} />
              <DialogBody className='wAuto'>
                <DialogFields>
                  <DialogField className='dFlex w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('bookedADriver', e.target.checked);
                          setFieldError('bookADriverSauceOrderId', null);
                          setFieldTouched('bookADriverSauceOrderId', false);
                        }}
                        name='bookedADriver'
                        checked={values?.bookedADriver}
                        label='Booked a driver'
                      />
                    </FormControl>
                    {values?.bookedADriver && (
                      <FormControl>
                        <FieldLabel>BaD Sauce Delivery ID</FieldLabel>
                        <FieldInput
                          className={cn('wAuto', {
                            error:
                              errors.bookADriverSauceOrderId &&
                              touched.bookADriverSauceOrderId &&
                              'error'
                          })}
                          type='text'
                          onChange={(e) => {
                            setFieldValue(
                              'bookADriverSauceOrderId',
                              e.target.value
                            );
                          }}
                          value={values?.bookADriverSauceOrderId}
                        />
                        <ErrorSignWrapper className='positionInitial'>
                          <ErrorSign>
                            {errors.bookADriverSauceOrderId &&
                              touched.bookADriverSauceOrderId &&
                              errors.bookADriverSauceOrderId}
                          </ErrorSign>
                        </ErrorSignWrapper>
                      </FormControl>
                    )}
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('calledCustomer', e.target.checked);
                        }}
                        name='calledCustomer'
                        checked={values?.calledCustomer}
                        label='Called the consumer'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('calledLocation', e.target.checked);
                        }}
                        name='calledLocation'
                        checked={values?.calledLocation}
                        label='Called the restaurant'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('calledCourier', e.target.checked);
                        }}
                        name='calledCourier'
                        checked={values?.calledCourier}
                        label='Called the courier'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('cancelled', e.target.checked);
                        }}
                        name='cancelled'
                        checked={values?.cancelled}
                        label='Cancelled'
                      />
                    </FormControl>
                  </DialogField>
                  <DialogField className=' w100'>
                    <FormControl>
                      <CheckboxS2E
                        onChange={(e) => {
                          setFieldValue('custom', e.target.checked);
                          setFieldError('description', null);
                          setFieldTouched('description', false);
                        }}
                        name='custom'
                        checked={values?.custom}
                        label='Other'
                      />
                    </FormControl>
                  </DialogField>
                  {values?.custom && (
                    <DialogField className='w100'>
                      <FieldTextarea
                        type='text'
                        className={cn('FieldTextareaMd', {
                          RTBv2Error: errors.description && touched.description
                        })}
                        placeholder='Other'
                        onChange={(e) => {
                          setFieldValue('description', e.target.value);
                        }}
                        value={values?.description}
                      />
                      <ErrorSignWrapper className='positionInitial'>
                        <ErrorSign>
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    </DialogField>
                  )}
                </DialogFields>
                <DialogActions>
                  <DialogAction className='w50'>
                    <ButtonOutline onClick={closeDialog}>Cancel</ButtonOutline>
                  </DialogAction>
                  <DialogAction className='w50'>
                    <ButtonPrimary
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      <span>Save</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogActions>
              </DialogBody>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
}
