/* eslint-disable */
import React, {useState} from 'react';
import T from 'prop-types';
import {toast} from 'react-toastify';
import {nanoid} from 'nanoid';
import {useDispatch} from 'react-redux';
import {DELETE, UPLOAD} from '../../store/global/uploadFile/action';
import {
  UploadFile,
  UploadPhoto,
  UploadPhotoContainer,
  UploadPhotoDelete,
  UploadPhotoFile,
  UploadPhotoImage,
  UploadPhotoInner
} from '../../routes/App/dashboard/styles';
import Loading from '../shared/Utils/Loading';
import cn from 'classnames';

const FileUploader = (props) => {
  const [inputId] = useState(nanoid());
  const [currentFile, setCurrentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const {url, onSetFile, allowedFileFormats, isPhoto, className, name} = props;
  const dispatch = useDispatch();

  const onFileChange = (e) => {
    const [file] = e.currentTarget.files;

    const maxAllowedSize = isPhoto ? 5 * 1024 * 1024 : 100 * 1024 * 1024;
    if (file && file.size > maxAllowedSize) {
      toast.error(
        `You cannot upload files larger than ${isPhoto ? '5MB' : '100MB'}`
      );
      return;
    }

    if (allowedFileFormats.length && !allowedFileFormats.includes(file.type)) {
      toast.error(`Allowed file formats are [${allowedFileFormats}]`);
      return;
    }

    if (file) {
      const fileForm = new FormData();
      fileForm.append('file', file);
      dispatch(
        UPLOAD({
          setLoading,
          form: fileForm,
          callback: (fileUrl) => {
            onSetFile(fileUrl);
          }
        })
      );
      setCurrentFile(file);
    }
  };
  const onFileDelete = () => {
    const fileName = url.split('/').pop();

    if (fileName) {
      dispatch(
        DELETE({
          setLoading,
          file: fileName,
          callback: () => {
            onSetFile('');
            setCurrentFile(null);
          }
        })
      );
    }
  };

  const getPhotoComponent = () => (
    <UploadPhotoInner>
      {isPhoto ? (
        <UploadPhotoImage src={url} />
      ) : (
        <UploadFile>
          <i>{currentFile?.name}</i>
          {currentFile && '- has been uploaded successfully!'}
        </UploadFile>
      )}

      <UploadPhotoDelete onClick={onFileDelete} />

      <UploadPhotoFile for={inputId} className={className}>
        <input type='file' name={name} id={inputId} onChange={onFileChange} />
        <UploadPhotoContainer>
          <span>{isPhoto ? 'Upload another photo' : 'Upload file'}</span>
          <p>
            {isPhoto
              ? 'The quality of images must be at least 600 * 600 px'
              : 'You can upload file .doc .docx .exel. Max size 100 MB'}
          </p>
        </UploadPhotoContainer>
      </UploadPhotoFile>
    </UploadPhotoInner>
  );
  const getLoadComponent = () => (
    <UploadPhotoFile className={className} for={inputId}>
      <input
        type='file'
        name={name}
        id={inputId}
        onChange={onFileChange}
        accept={allowedFileFormats ? allowedFileFormats.join(', ') : '/*'}
      />
      <UploadPhotoContainer>
        <span>{isPhoto ? 'Upload another photo' : 'Upload file'}</span>
        <p>
          {isPhoto
            ? 'The quality of images must be at least 600 * 600 px'
            : 'You can upload file .doc .docx .exel. Max size 100 MB'}
        </p>
      </UploadPhotoContainer>
    </UploadPhotoFile>
  );

  return (
    <>
      {loading ? (
        <Loading justifyContent='flex-start' margin='20px 10px' />
      ) : (
        <UploadPhoto>
          {url ? getPhotoComponent() : getLoadComponent()}
        </UploadPhoto>
      )}
    </>
  );
};

FileUploader.defaultProps = {
  url: '',
  onSetFile: () => {},
  allowedFileFormats: [],
  isPhoto: true,
  name: '',
  className: ''
};

FileUploader.propTypes = {
  url: T.string,
  onSetFile: T.func,
  allowedFileFormats: T.array,
  isPhoto: T.bool,
  className: T.string,
  name: T.string
};

export default FileUploader;
