import styled from 'styled-components';
import {ReactSVG} from 'react-svg';
import {Body1} from '../../../components/typography';
import {ButtonOutline} from '../../../components/buttons';
import Body3 from '../../../components/typography/Body3';
import Subtitle3 from '../../../components/typography/Subtitle3';
import DecorCard from '../../../assets/decor-card.svg';

export const UberEatsImage = styled.img`
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

export const UberEatsButtonGrid = styled.div`
  max-width: 600px;
  margin: 60px auto 0 auto;
`;

export const UberEatsText = styled(Body1)`
  max-width: 460px;
  margin: 0 auto;
`;

export const UberEatsInners = styled.div`
  max-width: 720px;
  margin: 60px auto 0 auto;
  display: flex;

  &.UberEatsInnersActions {
    max-width: 600px;
    margin-top: 20px;
    & > div {
      padding: 0 20px;
      &::after {
        display: none;
      }
    }
  }
`;
export const UberEatsInner = styled.div`
  flex: 0 0 50%;
  padding: 0 60px;

  button {
    width: 100%;
    margin-top: 40px;
  }

  &:first-child {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #929cb9;
    }
  }
`;

export const UberEatsImageGrid = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 20px -5px 42px -5px;
  @media (max-width: 1023px) {
    flex-wrap: wrap;
    max-width: 620px;
    margin: 20px auto 40px auto;
  }
  @media (max-width: 440px) {
    display: block;
  }

  &.UberEatsImageGridSteps {
    img {
      width: calc(100% + 60px);
      display: block;
      margin: 15px 0;
      @media (max-width: 1600px) {
        margin: 15px -20px;
        width: calc(100% + 40px);
      }
      @media (max-width: 1350px) {
        margin: 15px -12px;
        width: calc(100% + 24px);
      }
      @media (max-width: 1250px) {
        margin: 15px -18px;
        width: calc(100% + 36px);
      }
    }
  }
`;

export const UberEatsImageBox = styled.div`
  background-color: #fff;
  box-shadow: 1px 5px 10px rgba(108, 49, 151, 0.08),
    1px -8px 18px rgba(108, 49, 151, 0.06);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 30px;
  max-width: 290px;
  width: 100%;
  flex: 1 1 auto;
  margin: 5px;
  @media (max-width: 1600px) {
    padding: 18px 20px;
  }

  @media (max-width: 1350px) {
    padding: 18px 12px;
  }
  @media (max-width: 1250px) {
    padding: 18px;
  }
  @media (max-width: 1023px) {
    flex: 0 0 calc(50% - 10px);
    max-width: 100%;
  }
  @media (max-width: 440px) {
    margin: 0 0 10px 0;
  }

  img {
    margin-bottom: 15px;
    display: block;
    width: 100%;
  }

  strong {
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0;
    text-align: center;
    max-width: 90%;
    @media (max-width: 1600px) {
      max-width: 100%;
    }
    @media (max-width: 1350px) {
      font-size: 19px;
    }
    @media (max-width: 1250px) {
      font-size: 20px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`;

export const UberOrderedList = styled.div`
  ol {
    margin-left: 20px;
  }
  li {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 8px;

    &::marker {
      text-align: left;
      color: #ff2d38;
    }
  }
`;

export const UberDisconnectButton = styled(ButtonOutline)`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  color: #929cb9;
  &:hover {
    background: none;
  }
  padding: 10px 19px;
`;

export const StatusText = styled(Body3)`
  color: ${(props) => props.color || '#929cb9'};
  span {
    &.Red {
      color: #ff2d38;
    }
    &.fw700 {
      font-weight: 700;
    }
  }
`;

export const UberEatsIconBlock = styled.div`
  background-color: #fff;
  box-shadow: 1px 5px 10px rgba(108, 49, 151, 0.08),
    1px -8px 18px rgba(108, 49, 151, 0.06);
  border-radius: 24px;
  min-height: 380px;
  min-width: 290px;
  max-height: 380px;
  max-width: 290px;
  padding: 30px;
`;

export const UberEatsPromoPapers = styled.div`
  display: flex;
  align-items: stretch;
  max-width: 1200px;
  margin: 50px auto 0 auto;
  @media (max-width: 1023px) {
    display: block;
    margin: 40px 0 0 0;
  }
  @media (max-width: 767px) {
    margin-top: 20px;
  }
  @media (max-width: 599px) {
    margin-top: 10px;
  }
`;

export const UberEatsPromoPaper = styled.div`
  background-color: #fff;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  padding: 35px 35px 25px 35px;
  margin: 5px;
  flex: 0 0 calc(50% - 10px);
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: calc(100% - 16px);
    left: 0;
    width: 100%;
    height: 25px;
    background-image: url('${DecorCard}');
    background-repeat: repeat-x;
    background-position: bottom left;
  }

  @media (max-width: 1023px) {
    padding: 35px 30px;
    margin: 0 0 35px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    padding: 30px 24px;
  }
  @media (max-width: 599px) {
    padding: 30px 15px;
  }
`;

export const UERedDot = styled.span`
  margin-left: -10px;
  color: #ff2d38;
`;

export const UEIcon24 = styled(ReactSVG)`
  margin-right: 5px;
`;

export const FaqIcon = styled(ReactSVG)`
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 0;
  transition-property: transform;
  transition-duration: 0.4s;
  svg {
    min-width: 18px;
    min-height: 18px;
  }
`;

export const FaqTitle = styled(Subtitle3)`
  cursor: pointer;
  position: relative;
  padding: 0 30px 10px 0;
  @media (max-width: 1023px) {
    padding: 0 30px 5px 0;
  }
  &:hover {
    .FaqIcon {
      transform: rotate(90deg);
    }
  }
`;

export const FaqBlock = styled.div`
  padding: 25px 0 17px 0;
  border-bottom: 1px solid rgba(146, 156, 185, 0.3);
  @media (max-width: 1023px) {
    padding: 20px 0 15px 0;
  }
`;
