import {createAction} from 'redux-act';

export const CLEAR_STATE = createAction('discounts/CLEAR_STATE');
export const CLEAR_DISCOUNTS = createAction('discounts/CLEAR_DISCOUNTS');
export const GET_DISCOUNTS = createAction('discounts/GET_DISCOUNTS');
export const GET_DISCOUNTS_SUCCESS = createAction(
  'discounts/GET_DISCOUNTS_SUCCESS'
);
export const GET_DISCOUNT = createAction('discounts/GET_DISCOUNT');
export const GET_DISCOUNT_SUCCESS = createAction(
  'discounts/GET_DISCOUNT_SUCCESS'
);
export const CREATE_DISCOUNT = createAction('discounts/CREATE_DISCOUNT');
export const CREATE_DISCOUNT_SUCCESS = createAction(
  'discounts/CREATE_DISCOUNT_SUCCESS'
);
export const SET_BUTTON_STATUS = createAction('discounts/SET_BUTTON_STATUS');
export const SET_LOADING = createAction('discounts/SET_LOADING');
export const UPDATE_DISCOUNT = createAction('discounts/UPDATE_DISCOUNT');
export const UPDATE_DISCOUNT_SUCCESS = createAction(
  'discounts/UPDATE_DISCOUNT_SUCCESS'
);
export const DELETE_DISCOUNT = createAction('discounts/DELETE_DISCOUNT');
export const DELETE_DISCOUNT_SUCCESS = createAction(
  'discounts/DELETE_DISCOUNT_SUCCESS'
);
export const DUPLICATE_DISCOUNT = createAction('discounts/DUPLICATE_DISCOUNT');
export const DUPLICATE_DISCOUNT_SUCCESS = createAction(
  'discounts/DUPLICATE_DISCOUNT_SUCCESS'
);
export const PUSH_MODALS_STACK = createAction('discounts/PUSH_MODALS_STACK');
export const SHIFT_MODALS_STACK = createAction('discounts/SHIFT_MODALS_STACK');
export const SET_DISCOUNT = createAction('discounts/SET_DISCOUNT');
export const PUBLISH_DISCOUNTS = createAction('discounts/PUBLISH_LOCATION');
export const PUBLISH_DISCOUNTS_SUCCESS = createAction(
  'discounts/PUBLISH_LOCATION_SUCCESS'
);
export const SET_PUBLISH_BUTTON_STATE = createAction(
  'discounts/SET_PUBLISH_BUTTON_STATE'
);
export const SET_WARNING_MODAL = createAction('discounts/SET_WARNING_MODAL');
export const SET_SORTING = createAction('discounts/SET_SORTING');

export const SET_EMAIL_MARKETING_TOGGLE_ERROR = createAction(
  'discounts/SET_EMAIL_MARKETING_TOGGLE_ERROR'
);

export const OPEN_EMAIL_MARKETING_TURN_OFF_MODAL = createAction(
  'discounts/OPEN_EMAIL_MARKETING_TURN_OFF_MODAL'
);
