import React from 'react';
import T from 'prop-types';
import {UnPublishContainer} from '../../../../../components/Publish/styles';
import Body3 from '../../../../../components/typography/Body3';
import {BannerClose} from '../../../../../components/Banner/styles';
import {FeedbackBannerWrapper} from './style';

const FeedbackBanner = ({onClose}) => {
  return (
    <UnPublishContainer>
      <FeedbackBannerWrapper>
        <Body3 className='White'>
          <strong>New and FREE feature for a limited time!</strong> Get
          customer&lsquo;s feedback right as orders are completed and be in the
          know
        </Body3>
        <BannerClose onClick={onClose} />
      </FeedbackBannerWrapper>
    </UnPublishContainer>
  );
};

FeedbackBanner.propTypes = {
  onClose: T.func
};

FeedbackBanner.defaultProps = {
  onClose: () => {}
};

export default FeedbackBanner;
