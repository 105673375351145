import styled from 'styled-components';

const FieldPasswordContainer = styled.div`
  &.error {
    border: 1px solid #ba0302;
    border-radius: 6px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    position: relative;
    background-color: #f7f7fa;
    border: 1px solid #d5d8de;
    border-radius: 6px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #232d3a;
    height: 56px;
    outline: none;
    width: 100%;
    padding: 0;
    &.error {
      border: 1px solid #ba0302;
    }
    input {
      padding-right: 75px;
    }
    fieldset {
      display: none;
    }
    .MuiInputAdornment-root {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 100%;
      max-height: inherit;
    }
  }
`;

export default FieldPasswordContainer;
