import * as Yup from 'yup';

export default Yup.object().shape({
  MinOrder: Yup.number('Min order field must be a number').positive(
    'Min order field must be positive'
  ),
  Discount: Yup.number('Discount field must be a number')
    .required('This field is required')
    .positive('This field must be positive'),
  Name: Yup.string()
    .required('Name field is required')
    .trim()
    .max(50, 'Max length of discount name is 50 characters'),
  CouponCode: Yup.string()
    .matches(
      /^([a-zA-Z0-9_\-\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/,
      'Promo code can contain only numbers and letters as well as -, _ symbols'
    )
    .max(20, 'Your promo code is too long, max length is 20 characters!')
});
