/* eslint-disable */
import React, {Component} from 'react';
import T from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SearchInput from './SearchInput';
import {
  DialogLeftSidebar,
  DialogList,
  ListDescription
} from '../../../dashboard/styles';

class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterItems: props.itemList
    };
  }

  onFilterItems = (searchValue) => {
    let filterItems = [];

    if (!searchValue) {
      filterItems = this.props.itemList;
    } else {
      filterItems = this.props.itemList.filter((item) => {
        if (item.Name) {
          return item.Name.toLowerCase().includes(searchValue.toLowerCase());
        }

        return item.OptionName.toLowerCase().includes(
          searchValue.toLowerCase()
        );
      });
    }

    this.setState({filterItems});
  };

  render() {
    const {selectedItems, itemType, activeItem} = this.props;

    return (
      <DialogLeftSidebar>
        <SearchInput filterList={this.onFilterItems} />
        <DialogList>
          <List>
            {this.state.filterItems.map((item) => {
              return (
                <ListItem
                  button
                  onClick={() => {
                    this.props.onItemSelect(item);
                  }}
                  className={`
                    ${
                      activeItem && activeItem.PosId
                        ? activeItem.PosId === item.PosId && 'isActive'
                        : activeItem &&
                          activeItem.MerchantSectionId ===
                            item.MerchantSectionId &&
                          'isActive'
                    }               
                    ${
                      selectedItems.find((selectedItem) => {
                        if (itemType === 'category') {
                          return (
                            selectedItem.MerchantSectionId ===
                            item.MerchantSectionId
                          );
                        }
                        return selectedItem.PosId === item.PosId;
                      }) && 'isSelect'
                    }`}
                >
                  <ListDescription>
                    <strong>{item.Name || item.OptionName}</strong>
                    {/* <p>POS Name: {item.Name || item.OptionName}</p> */}
                    {itemType === 'item' && <p>Kitchen Name: {item.Name}</p>}

                    {itemType === 'modifier' && <p>Price: ${item.Price}</p>}

                    {itemType === 'modifierGroup' && (
                      <p>
                        {`${item.Items.slice(0, 4)
                          .map((items) => items.Name)
                          .join(', ')} ${
                          item.Items.length > 4
                            ? `and ${item.Items.length - 4} more`
                            : ''
                        }`}
                      </p>
                    )}
                    {itemType === 'modifierGroupParent' && (
                      <p>
                        {`${item.Items.slice(0, 4)
                          .map((items) => items.Name)
                          .join(', ')} ${
                          item.Items.length > 4
                            ? `and ${item.Items.length - 4} more`
                            : ''
                        }`}
                      </p>
                    )}
                  </ListDescription>
                  <ListItemSecondaryAction>
                    <FormControlLabel
                      className='Say2EatCheckbox'
                      control={
                        <Checkbox
                          edge='end'
                          checked={selectedItems.find((selectedItem) => {
                            if (itemType === 'category') {
                              return (
                                selectedItem.MerchantSectionId ===
                                item.MerchantSectionId
                              );
                            }
                            return selectedItem.PosId === item.PosId;
                          })}
                          iconStyle={{fill: '#FF2D38'}}
                          inputStyle={{color: '#FF2D38'}}
                          style={{color: '#FF2D38'}}
                          onChange={() => this.props.onItemChange(item)}
                        />
                      }
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </DialogList>
      </DialogLeftSidebar>
    );
  }
}
LeftSidebar.defaultProps = {selectedItems: []};
LeftSidebar.propTypes = {
  activeItem: T.object.isRequired,
  itemList: T.array.isRequired,
  selectedItems: T.array,
  itemType: T.string.isRequired,
  onItemSelect: T.func.isRequired,
  onItemChange: T.func.isRequired
};

export default LeftSidebar;
