import styled from 'styled-components';
import IconCalendar from '../../assets/icon-calendar.svg';
import IconSelect from '../../assets/icon-arrow-down-dark.svg';

const DatepickerContainer = styled.div`
  display: inline-block;
  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  .MuiPickersDateRangePickerInput-root {
    .Delimiter {
      margin: 0 10px;
      font-size: 14px;
      &.DelimiterMiddle {
        margin: 0 22px;
      }
    }
    .MuiFormHelperText-root {
      display: none;
    }
  }
  .MuiInputBase-root {
    height: 46px;
    border: 1px solid #e6e6e6;
    border-radius: 40px;
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      right: 12px;
      content: '';
      background-image: url('${IconSelect}');
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: center;
      z-index: 1;
    }
    &.Mui-focused {
      &::after {
        background-image: url('${IconSelect}');
        transform: rotate(180deg);
      }
    }
    &:hover {
      border-color: #d5d8de;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
  .MuiInputBase-input {
    padding: 0 54px 0 65px;
    height: 46px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #232d3a;
    width: 120px;
    display: inline-block;
    background-image: url('${IconCalendar}');
    background-repeat: no-repeat;
    background-position: center left 22px;

    &::placeholder {
      color: #232d3a;
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(65px, 16px) scale(1);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #232d3a;
    transition: 0s;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
    transform: translate(65px, 16px) scale(1);
  }
`;

export default DatepickerContainer;
