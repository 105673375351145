import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import Account from './MyAccount';
import SelfCreateAccount from './SetupAccount/SeflSetup';
import CreateAccount from './SetupAccount/SalesSetup';

const AccountRoutes = () => {
  const {path} = useRouteMatch();

  return (
    <>
      <Route exact path={`${path}/`} component={Account} />
    </>
  );
};

export default AccountRoutes;
