import {datadogLogs} from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
  site: process.env.REACT_APP_DD_SITE || '',
  env: process.env.REACT_APP_ENV,
  service: process.env.REACT_APP_DD_SERVICE_NAME,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  version: process.env.REACT_APP_VERSION
});
export default datadogLogs;
