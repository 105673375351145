import React from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {SetupSidebar} from '../../../../../Auth/registration/signUpNew/style';
import Body3 from '../../../../../../components/typography/Body3';
import CustomProgressBar from '../../../../../../components/CustomProgressBar';
import {SidebarProgress} from '../../../../../../store/selfCreateAccount/selectors';
import {SidebarProgressSales} from '../../../../../../store/setupRestaurant/selectors';
import {userData} from '../../../../../../store/global/account/selectors';

const SidebarStepper = () => {
  const {isSignUp2} = useSelector(userData);
  const {pathname} = useLocation();
  const progress = () => {
    if (isSignUp2) {
      return SidebarProgress();
    }
    return SidebarProgressSales();
  };

  return (
    <SetupSidebar>
      <Body3>
        Just <strong className='Red'>2 small steps</strong> in order to extend
        your reach and start deliver to your customers!
      </Body3>

      <CustomProgressBar
        className='ProgressBar ProgressBarDesktop'
        progress={progress()}
        secondStepTitle={
          (pathname.includes('self-setup/book-call') && 'Book A Call') ||
          (pathname.includes('self-setup/generalInfo') &&
            'Start free trial/Book a call') ||
          ((pathname.includes('sales-setup/general-info') ||
            pathname.includes('sales-setup-new/general-info')) &&
            'Add menu') ||
          ((pathname.includes('sales-setup/add-menu') ||
            pathname.includes('sales-setup-new/add-menu')) &&
            'Add menu')
        }
      />
    </SetupSidebar>
  );
};

export default SidebarStepper;
