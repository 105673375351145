import {put, takeEvery} from 'redux-saga/effects';
import {
  FIND_DELIVERY_GET_ORDER,
  FIND_DELIVERY_GET_ORDER_SUCCESS,
  FIND_DELIVERY_SET_ERROR
} from 'store/findDelivery/action';
import {findDeliveryGetOrderApi} from 'helpers/services/api/findDelivery';

function* getOrder({payload}) {
  try {
    const {data} = yield findDeliveryGetOrderApi(payload?.trim());

    if (data && Array.isArray(data.orders) && data.orders.length) {
      yield put(FIND_DELIVERY_GET_ORDER_SUCCESS(data.orders));
    } else {
      yield put(FIND_DELIVERY_SET_ERROR('* not found'));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    const {response} = error;
    if (response && response?.data) {
      yield put(
        FIND_DELIVERY_SET_ERROR(
          response?.data?.status === 404
            ? '* not found'
            : '* something went wrong'
        )
      );
    }
  }
}

function* findDeliverySaga() {
  yield takeEvery(FIND_DELIVERY_GET_ORDER, getOrder);
}

export default findDeliverySaga;
