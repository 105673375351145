import React from 'react';

const IconSidebarHelp = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M42.6899 3.84961H39.076C38.1729 3.84961 37.438 4.58436 37.438 5.48749V12.0094C37.438 12.9125 38.1729 13.6474 39.076 13.6474H42.6899C43.593 13.6474 44.3279 12.9125 44.3279 12.0094V5.48749C44.3279 4.58436 43.5931 3.84961 42.6899 3.84961ZM42.4542 11.7737H39.3117V5.72336H42.4542V11.7737Z'
      fill='#FF2D38'
    />
    <path
      d='M61.7261 10.2454L56.1618 1.13275C55.7352 0.434 54.9616 0 54.1428 0H4.29336C2.98898 0 1.92773 1.06125 1.92773 2.36575V61.6341C1.92773 62.9388 2.98898 64 4.29336 64H59.707C61.0113 64 62.0726 62.9388 62.0726 61.6343V11.4781C62.0727 11.043 61.953 10.6169 61.7261 10.2454ZM14.829 1.87375H31.0634V14.024C31.0634 14.6007 31.2055 15.1445 31.4552 15.6237H16.4287C15.5466 15.6237 14.829 14.9061 14.829 14.024V1.87375ZM60.199 61.6343C60.199 61.9055 59.9783 62.1264 59.707 62.1264H55.1897V36.8705C55.1897 35.2654 53.884 33.9596 52.2789 33.9596H11.3557C9.75061 33.9596 8.44486 35.2654 8.44486 36.8705V41.4689C8.44486 41.9864 8.86423 42.4058 9.38173 42.4058C9.89923 42.4058 10.3186 41.9864 10.3186 41.4689V36.8705C10.3186 36.2986 10.7839 35.8334 11.3557 35.8334H52.279C52.8508 35.8334 53.3161 36.2986 53.3161 36.8705V62.1263H10.3186V45.8129C10.3186 45.2954 9.89923 44.876 9.38173 44.876C8.86423 44.876 8.44486 45.2954 8.44486 45.8129V62.1263H4.29336C4.02211 62.1263 3.80136 61.9055 3.80136 61.6341V2.36575C3.80136 2.0945 4.02198 1.87363 4.29336 1.87363H12.9552V14.0239C12.9552 15.9391 14.5134 17.4972 16.4286 17.4972H47.2287C49.1441 17.4972 50.7021 15.9391 50.7021 14.0239V11.3825C50.7021 10.865 50.2827 10.4456 49.7652 10.4456C49.2477 10.4456 48.8284 10.865 48.8284 11.3825V14.024C48.8284 14.9061 48.1107 15.6237 47.2286 15.6237H34.5368C33.6547 15.6237 32.9371 14.9061 32.9371 14.024V1.87375H48.8286V7.06238C48.8286 7.57987 49.248 7.99925 49.7655 7.99925C50.283 7.99925 50.7024 7.57987 50.7024 7.06238V1.87375H54.143C54.3132 1.87375 54.4741 1.964 54.5629 2.10925L60.127 11.2218C60.1742 11.299 60.1991 11.3876 60.1991 11.4781V61.6343H60.199Z'
      fill='#FF2D38'
    />
    <path
      d='M15.015 41.7585H48.6199C49.1374 41.7585 49.5567 41.3391 49.5567 40.8216C49.5567 40.3041 49.1374 39.8848 48.6199 39.8848H15.015C14.4975 39.8848 14.0781 40.3041 14.0781 40.8216C14.0781 41.3391 14.4976 41.7585 15.015 41.7585Z'
      fill='#FF2D38'
    />
    <path
      d='M15.015 47.197H48.6199C49.1374 47.197 49.5567 46.7776 49.5567 46.2601C49.5567 45.7426 49.1374 45.3232 48.6199 45.3232H15.015C14.4975 45.3232 14.0781 45.7426 14.0781 46.2601C14.0781 46.7776 14.4976 47.197 15.015 47.197Z'
      fill='#FF2D38'
    />
    <path
      d='M15.015 52.6364H48.6199C49.1374 52.6364 49.5567 52.2171 49.5567 51.6996C49.5567 51.1821 49.1374 50.7627 48.6199 50.7627H15.015C14.4975 50.7627 14.0781 51.1821 14.0781 51.6996C14.0781 52.2171 14.4976 52.6364 15.015 52.6364Z'
      fill='#FF2D38'
    />
    <path
      d='M15.015 58.0749H48.6199C49.1374 58.0749 49.5567 57.6555 49.5567 57.138C49.5567 56.6205 49.1374 56.2012 48.6199 56.2012H15.015C14.4975 56.2012 14.0781 56.6205 14.0781 57.138C14.0781 57.6555 14.4976 58.0749 15.015 58.0749Z'
      fill='#FF2D38'
    />
  </svg>
);

export default IconSidebarHelp;
