import React from 'react';

const IconSidebarMenuEditor = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.91381 1H13.9347C14.5239 1 15 1.47609 15 2.06532V6.08619C15 6.67542 14.5239 7.15151 13.9347 7.15151H9.91381C9.32458 7.15151 8.84849 6.67542 8.84849 6.08619V2.06532C8.84849 1.47609 9.32458 1 9.91381 1Z'
      fill='#929CB9'
    />
    <path
      d='M2.06542 1H6.08629C6.67552 1 7.15161 1.47609 7.15161 2.06532V6.08619C7.15161 6.67542 6.67552 7.15151 6.08629 7.15151H2.06542C1.47619 7.15151 1.0001 6.67542 1.0001 6.08619V2.06532C1.0001 1.47609 1.47619 1 2.06542 1Z'
      fill='#929CB9'
    />
    <path
      d='M9.91381 8.84851H13.9347C14.5239 8.84851 15 9.32461 15 9.91383V13.9347C15 14.5239 14.5239 15 13.9347 15H9.91381C9.32458 15 8.84849 14.5239 8.84849 13.9347V9.91383C8.84849 9.32461 9.32458 8.84851 9.91381 8.84851Z'
      fill='#929CB9'
    />
    <path
      d='M2.06542 8.84851H6.08629C6.67552 8.84851 7.15161 9.32461 7.15161 9.91383V13.9347C7.15161 14.5239 6.67552 15 6.08629 15H2.06542C1.47619 15 1.0001 14.5239 1.0001 13.9347V9.91383C1.0001 9.32461 1.47619 8.84851 2.06542 8.84851Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarMenuEditor;
