export const setPopperActions = (
  editAction,
  duplicateAction,
  disableAction,
  deleteAction,
  status
) => {
  if (status === 'active') {
    return [
      {
        title: 'Edit',
        action: (row) => editAction(row)
      },
      {
        title: 'Duplicate',
        action: (row) => duplicateAction(row)
      },
      {
        title: 'Disable',
        action: (row) => disableAction(row)
      },
      {
        title: 'Delete',
        action: (row) => deleteAction(row)
      }
    ];
  }

  return [
    {
      title: 'Duplicate',
      action: (row) => duplicateAction(row)
    },
    {
      title: 'Delete',
      action: (row) => deleteAction(row)
    }
  ];
};

export const getDiscountName = (discountObject) => {
  const {Discount, MinOrder, DiscountType} = discountObject;

  return MinOrder
    ? `${DiscountType === 0 ? '$' : ''}${Discount}${
        DiscountType === 1 ? '%' : ''
      } off $${MinOrder} order`
    : `${DiscountType === 0 ? '$' : ''}${Discount}${
        DiscountType === 1 ? '%' : ''
      }`;
};
