export function getAppIp() {
  return window.DeviceIP || 'UNKNOWN_IP';
}

export function getAppType() {
  const {AndroidDashboard} = window;

  return AndroidDashboard ? 'Android Web App' : 'Web App';
}

export function getAppVersion() {
  let appVersion =
    localStorage.getItem('appVersion') || '0.0.0-unknown-frontend';
  const {AndroidDashboard} = window;

  if (AndroidDashboard && typeof AndroidDashboard.getVersion === 'function') {
    appVersion = AndroidDashboard.getVersion();
  }

  return appVersion;
}
