import styled from 'styled-components';

const DialogBody = styled.div`
  margin-top: 15px;
  &.positionRelative {
    position: relative;
  }
  @media (max-width: 1440px) {
    margin-top: 5px;
  }
  &.mTop40 {
    margin-top: 40px;
  }

  .RefundBlock {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 17px;
    display: flex;

    span {
      &:first-child {
        width: 140px;
      }
      &:last-child {
        width: 410px;
        font-weight: 600;
        word-break: break-word;
      }
    }
  }
`;

export default DialogBody;
