/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {DashboardContent} from '../../../../../components/DashboardContent/styles';
import LinkBack from '../../../../../components/LinkBack/styles';
import {DashboardHeader} from '../../../../../components/DashboardHeader/styles';
import Body3 from '../../../../../components/typography/Body3';
import Headline from '../../../../../components/Headline/styles';
import TemplateVideo1 from '../../../../../assets/land-1.mp4';
import TemplateVideo2 from '../../../../../assets/land-2.mp4';
import TemplateVideo3 from '../../../../../assets/land-3.mp4';
import TemplateVideo4 from '../../../../../assets/land-4.mp4';
import {
  AccountTemplate,
  AccountTemplateAction,
  AccountTemplateImage,
  AccountTemplates
} from '../../styles';
import {ButtonOutline} from '../../../../../components/buttons';
import {
  accountInfo,
  userInfo
} from '../../../../../store/global/account/selectors';
import history from '../../../../../history';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import {UPDATE_LOCATION_ACCOUNT_TEMPLATE} from '../../../../../store/locationSettings/action';

const CreateLanding = () => {
  const dispatch = useDispatch();
  const user = useSelector(userInfo);
  const {isSettingTemplate} = useSelector(accountInfo);
  const [activeButton, setActiveButton] = useState(
    user?.activeLocation?.AccountTemplate || ''
  );

  useEffect(() => {
    if (user?.activeLocation?.AccountTemplate) history.push('/my-account');
  }, [dispatch, user?.activeLocation?.AccountTemplate]);

  const activateButton = (template) => {
    if (activeButton === template) {
      setActiveButton('');
    } else {
      setActiveButton(template);
    }
  };
  const handleSubmit = (button) => {
    dispatch(UPDATE_LOCATION_ACCOUNT_TEMPLATE({AccountTemplate: button}));
  };

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader className='whiteBgTablet'>
        <LinkBack>
          <NavLink to='/my-account'>Back to My Account</NavLink>
        </LinkBack>
      </DashboardHeader>
      <DashboardInner className='paddingLg'>
        <Headline className='Sm marginBottomSm'>Create a Landing Page</Headline>
        <Body3>
          Choose one of our ready-made pages to build your website quickly
        </Body3>
        <AccountTemplates>
          <AccountTemplate>
            <AccountTemplateImage>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video loop autoPlay muted disableRemotePlayback>
                <source
                  src={TemplateVideo1}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            </AccountTemplateImage>
            <AccountTemplateAction>
              <ButtonOutline
                className={cn('ButtonLg', {
                  active: activeButton === 'CheckmateSauce',
                  disabled:
                    isSettingTemplate && activeButton !== 'CheckmateSauce'
                })}
                onClick={() => {
                  activateButton('CheckmateSauce');
                  handleSubmit('CheckmateSauce');
                }}
              >
                Checkmate Sauce
              </ButtonOutline>
            </AccountTemplateAction>
          </AccountTemplate>
          <AccountTemplate>
            <AccountTemplateImage>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video loop autoPlay muted disableRemotePlayback>
                <source
                  src={TemplateVideo2}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            </AccountTemplateImage>
            <AccountTemplateAction>
              <ButtonOutline
                className={cn('ButtonLg', {
                  active: activeButton === 'DarkSauce',
                  disabled: isSettingTemplate && activeButton !== 'DarkSauce'
                })}
                onClick={() => {
                  activateButton('DarkSauce');
                  handleSubmit('DarkSauce');
                }}
              >
                Dark Sauce
              </ButtonOutline>
            </AccountTemplateAction>
          </AccountTemplate>
          <AccountTemplate>
            <AccountTemplateImage>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video loop autoPlay muted disableRemotePlayback>
                <source
                  src={TemplateVideo4}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            </AccountTemplateImage>
            <AccountTemplateAction>
              <ButtonOutline
                className={cn('ButtonLg', {
                  active: activeButton === 'MegaSauce',
                  disabled: isSettingTemplate && activeButton !== 'MegaSauce'
                })}
                onClick={() => {
                  activateButton('MegaSauce');
                  handleSubmit('MegaSauce');
                }}
              >
                Mega Sauce
              </ButtonOutline>
            </AccountTemplateAction>
          </AccountTemplate>
          <AccountTemplate>
            <AccountTemplateImage>
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video loop autoPlay muted disableRemotePlayback>
                <source
                  src={TemplateVideo3}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            </AccountTemplateImage>
            <AccountTemplateAction>
              <ButtonOutline
                className={cn('ButtonLg', {
                  active: activeButton === 'WhiteSauce',
                  disabled: isSettingTemplate && activeButton !== 'WhiteSauce'
                })}
                onClick={() => {
                  activateButton('WhiteSauce');
                  handleSubmit('WhiteSauce');
                }}
              >
                White Sauce
              </ButtonOutline>
            </AccountTemplateAction>
          </AccountTemplate>
        </AccountTemplates>
      </DashboardInner>
    </DashboardContent>
  );
};

export default CreateLanding;
