import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import cn from 'classnames';
import {
  DialogAction,
  DialogBody,
  DialogField,
  FieldLabel
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import FieldPassword from '../../../../components/FieldPassword';
import {RESET_PASSWORD} from '../../../../store/usersManagement/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';

export default function ResetPassword({open, onClose, modalModel}) {
  const dispatch = useDispatch();
  const validate = ({password, confirmPassword}) => {
    const errors = {};
    if (password !== confirmPassword)
      errors.confirmPassword =
        'Your new password should be equal to the confirmation password!';
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validate,
    onSubmit: (values, {resetForm}) => {
      dispatch(
        RESET_PASSWORD({_id: modalModel._id, Password: values.password})
      );
      resetForm();
      onClose();
    }
  });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} scroll='body' onClose={closeDialog}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle>
          Change User <span>Password</span>
        </DialogTitle>
        <DialogBody>
          <DialogField className='DialogField'>
            <FieldLabel className='inline-label'>New Password</FieldLabel>
            <FieldPassword
              name='password'
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </DialogField>
          <DialogField className='DialogField'>
            <FieldLabel className='inline-label'>
              Confirm The New Password
            </FieldLabel>
            <FieldPassword
              name='confirmPassword'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              className={cn({
                error:
                  formik.errors.confirmPassword &&
                  formik.touched.confirmPassword
              })}
            />
            <ErrorSignWrapper>
              <ErrorSign>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword}
              </ErrorSign>
            </ErrorSignWrapper>
          </DialogField>

          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>Change Password</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

ResetPassword.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object
};

ResetPassword.defaultProps = {
  open: false,
  onClose: () => {},
  modalModel: {}
};
