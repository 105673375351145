import React from 'react';

const IconSidebarUberEats = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.3172 2.34976L5.19094 5.891L2.75586 4.53827L8.7741 1.18311C8.9091 1.10563 9.0711 1.10563 9.21285 1.18311L11.3172 2.34976Z'
      fill='#929CB9'
    />
    <path
      d='M15.2376 4.53862L9.00393 8.02524L6.66953 6.72649L6.33203 6.53279L12.465 2.99219L12.8025 3.18525L15.2376 4.53862Z'
      fill='#929CB9'
    />
    <path
      d='M8.50393 9.02959L8.49718 16.3667L2.52675 12.849C2.358 12.7487 2.25 12.5473 2.25 12.3308V5.54297L4.78015 6.95032V9.42461C4.78015 9.74152 5.00965 10.0044 5.2864 10.0044C5.56315 10.0044 5.79265 9.74152 5.79265 9.42461V7.52253L6.13016 7.70798L8.50393 9.02959Z'
      fill='#929CB9'
    />
    <path
      d='M15.7434 5.55078L9.51652 9.02216L9.50977 16.3593L15.7502 12.6803L15.7434 5.55078Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarUberEats;
