import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import T from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Switch} from '@material-ui/core';
import SwitchContainer from './styles';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 45,
    height: 22,
    padding: 0,
    margin: theme.spacing(1)
  },

  switchBase: {
    color: (props) => props.color || '#36998B',
    padding: 2,
    '&.Component-switchBase-4.Component-checked-7': {},
    '&$checked': {
      transform: 'translateX(23px)',
      color: theme.palette.common.white,

      '& + $track': {
        backgroundColor: (props) => props.color || '#36998B',
        opacity: 1,
        transition: '0.2s'
      }
    }
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: 'none',
    transition: '0.2s'
  },
  track: {
    borderRadius: 22 / 2,
    border: (props) => `1px solid ${props.color || '#36998B'}`,
    backgroundColor: '#fff',
    opacity: 1,
    transitionDuration: '0.2s',
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {
    '.MuiSwitch-track': {
      backgroundColor: (props) => props.color || '#fff'
    }
  },
  focusVisible: {}
}))(({classes, ...props}) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});

const SwitchS2E = ({
  onChange,
  name,
  checked,
  label,
  labelPlacement,
  color,
  isDisabled,
  className
}) => {
  return (
    <SwitchContainer>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={checked}
            onChange={onChange}
            name={name}
            color={color}
            backgroundColor='red'
          />
        }
        labelPlacement={labelPlacement || 'end'}
        label={label}
        style={isDisabled ? {opacity: 0.3, pointerEvents: 'none'} : {}}
        className={className}
      />
    </SwitchContainer>
  );
};

SwitchS2E.propTypes = {
  color: T.string,
  onChange: T.func.isRequired,
  name: T.string.isRequired,
  label: T.string,
  labelPlacement: T.string,
  checked: T.bool.isRequired,
  isDisabled: T.bool,
  className: T.string
};

SwitchS2E.defaultProps = {
  label: '',
  labelPlacement: '',
  color: '',
  isDisabled: false,
  className: ''
};

export default SwitchS2E;
