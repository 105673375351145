import {request} from 'helpers/services/utils/request';
import {GET} from 'helpers/services/constants/API_CONSTANTS';
import {
  ACTIVE_MERCHANT_BY_ID,
  ACTIVE_MERCHANTS
} from 'helpers/services/constants/API_ENDPOINTS';
import {buildUrl} from 'helpers/functionUtils/AdminBuildUrl';

export const getActiveMerchantsApi = async (payload) => {
  return request(GET, buildUrl(ACTIVE_MERCHANTS(), payload));
};
export const getActiveMerchantByIdApi = async (id) => {
  return request(GET, ACTIVE_MERCHANT_BY_ID(id));
};
