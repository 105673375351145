import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import DialogFields from '../../../../../../../components/DialogFields/styles';
import DialogField from '../../../../../../../components/DialogField/styles';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import {ButtonPrimary} from '../../../../../../../components/buttons';
import DialogTitle from '../../../../../../../components/DialogTitle/styles';
import {Commission, CommissionInner, SaveMoney} from '../../../../styles';
import RangeSliderS2E from '../../../../../../../components/RangeSlider';
import {
  RangeSlider,
  RangeSliderBottom,
  RangeSliderTop
} from '../../../../../../../components/RangeSlider/styles';
import {
  SetupActions,
  SetupCalculator,
  SetupCommission,
  SetupContainer,
  SetupHeader,
  SetupLogo
} from '../../../../../../Auth/registration/signUpNew/style';
import Subtitle0 from '../../../../../../../components/typography/Subtitle0';
import {PATCH_USER_DATA} from '../../../../../../../store/global/user/action';
import SauceLogoRed from '../../../../../../../assets/logo-sauce-red.svg';
import Subtitle5 from '../../../../../../../components/typography/Subtitle5';

const DeliveryCalculator = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ordersPerDay, setOrdersPerDay] = useState(18);
  const [averageCheckSize, setAverageCheckSize] = useState(30);
  const [comission, setComission] = useState(25);
  const [calculations, setCalculations] = useState({
    comissionEveryYear: '',
    comissionPerDay: '',
    savingsEveryYear: ''
  });

  const say2eatCommisionRate = 10;

  const calculateComissionFees = (orders, averageCheck, averageComission) => {
    const everyYear = (orders * averageCheck * 365 * averageComission) / 100;
    const perDay = (orders * averageCheck * 365 * averageComission) / 100 / 365;
    const savingsEveryYear =
      everyYear - (orders * averageCheck * 365 * say2eatCommisionRate) / 100;

    setCalculations({
      comissionEveryYear: everyYear.toLocaleString(),
      comissionPerDay: perDay.toLocaleString(),
      savingsEveryYear: savingsEveryYear.toLocaleString()
    });
  };

  const handleSliderValue = (event, value, setState) => {
    setState(value);
    calculateComissionFees(ordersPerDay, averageCheckSize, comission);
  };

  useEffect(() => {
    calculateComissionFees(ordersPerDay, averageCheckSize, comission);
  }, []);

  const onSubmit = () => {
    dispatch(PATCH_USER_DATA({pendingDataSetuped: true}));
    history.push('/');
  };

  return (
    <SetupContainer className='calculatorPosition'>
      <SetupHeader>
        <SetupLogo src={SauceLogoRed} />
        <Subtitle5>
          <span>Setup</span> your restaurant
        </Subtitle5>
      </SetupHeader>
      <Subtitle0>Third parties Delivery Fees Calculator</Subtitle0>
      <SetupCalculator>
        <DialogFields className='mBottom40'>
          <DialogField className='w100 marginBottomXs'>
            <FieldLabel className='FieldLabelBold'>
              How many orders are you getting PER DAY from 3rd party-delivery?
            </FieldLabel>
            <RangeSlider>
              <RangeSliderTop>
                <span>{ordersPerDay}</span> Orders
              </RangeSliderTop>
              <RangeSliderS2E
                defaultValue={ordersPerDay}
                step={1}
                min={0}
                max={70}
                onChange={(event, value) =>
                  handleSliderValue(event, value, setOrdersPerDay)
                }
              />
              <RangeSliderBottom>
                <span>0 Orders</span>
                <span>70 Orders</span>
              </RangeSliderBottom>
            </RangeSlider>
          </DialogField>
          <DialogField className='w100 marginBottomXs'>
            <FieldLabel className='FieldLabelBold'>
              What is your average check size?
            </FieldLabel>
            <RangeSlider>
              <RangeSliderTop>
                <span>$ {averageCheckSize}</span> Per Order
              </RangeSliderTop>
              <RangeSliderS2E
                defaultValue={averageCheckSize}
                step={1}
                min={10}
                max={70}
                onChange={(event, value) =>
                  handleSliderValue(event, value, setAverageCheckSize)
                }
              />
              <RangeSliderBottom>
                <span>$ 10 Per Order</span>
                <span>$ 70 Per Order</span>
              </RangeSliderBottom>
            </RangeSlider>
          </DialogField>
          <DialogField className='w100'>
            <FieldLabel className='FieldLabelBold'>
              What are the average commission rates you are paying to 3rd
              parties?
            </FieldLabel>
            <RangeSlider>
              <RangeSliderTop>
                <span>{comission}%</span> Comission
              </RangeSliderTop>
              <RangeSliderS2E
                defaultValue={comission}
                step={1}
                min={15}
                max={40}
                onChange={(event, value) =>
                  handleSliderValue(event, value, setComission)
                }
              />
              <RangeSliderBottom>
                <span>15%</span>
                <span>40%</span>
              </RangeSliderBottom>
            </RangeSlider>
          </DialogField>
        </DialogFields>
        <SetupCommission>
          <DialogTitle>Commission Fees You Pay Today</DialogTitle>
          <Commission>
            <CommissionInner>
              <span>$ {calculations.comissionEveryYear}</span> Every Year
            </CommissionInner>
            <CommissionInner>
              <span>$ {calculations.comissionPerDay}</span> Per day
            </CommissionInner>
          </Commission>
          <SaveMoney className='marginBottom2l SaveMoney'>
            <DialogTitle className='DialogTitleXl'>
              Your Savings with Sauce
            </DialogTitle>
            <DialogTitle className='DialogTitleXl'>
              <span>$ {calculations.savingsEveryYear}</span> Every Year
            </DialogTitle>
          </SaveMoney>
        </SetupCommission>
      </SetupCalculator>
      <SetupActions>
        <ButtonPrimary
          className='ButtonPrimary ButtonPrimaryCenter'
          type='submit'
          onClick={onSubmit}
        >
          <span>Save Me $ {calculations.savingsEveryYear} with Sauce</span>
        </ButtonPrimary>
      </SetupActions>
    </SetupContainer>
  );
};

export default DeliveryCalculator;
