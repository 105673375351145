import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from '../../../../components/DialogContainer/styles';
import {DialogAction, DialogBody} from '../../dashboard/styles';
import {ButtonPrimary} from '../../../../components/buttons';

const BADStoreAddressErrorModal = (props) => {
  const {onClose, open} = props;
  return (
    <Dialog open={open} scroll='body'>
      <DialogContainer className='DialogSm'>
        <DialogBody>
          <div className='SpanCenter'>
            Your store address is not valid. Please change the address in
            &#34;Store Settings&#34;
          </div>
          <DialogAction>
            <ButtonPrimary onClick={onClose}>
              <span>Go to Store Setting</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

BADStoreAddressErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default BADStoreAddressErrorModal;
