import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Dialog} from '@material-ui/core';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogBody from '../../../../components/DialogBody/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Body3 from '../../../../components/typography/Body3';
import Body4 from '../../../../components/typography/Body4';
import {Body2} from '../../../../components/typography';
import {RedSpan, IconWrapper, ByocItems, ByocItem} from '../styles';
import TipIcon from '../../../../assets/uberEats/tip-icon.svg';
import {FlexContainer} from '../../../../components/layout';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogAction from '../../../../components/DialogAction/styles';

export default function ByocThird(props) {
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showDeliveryZonesModal, setShowDeliveryZonesModal] = useState(false);

  return (
    <div>
      <Subtitle5>Answer Email</Subtitle5>
      <Body3 className='marginTopMd'>
        <b>Answer</b> the activation email that UberEats sent you as soon as you
        can with the following information:
      </Body3>
      <ByocItems>
        <ByocItem>
          <Body3>
            <b>
              <RedSpan>1.</RedSpan> Customer delivery fee:
            </b>{' '}
            set $4.99 (we will help you to optimize it 7 days after you go
            live).
          </Body3>
        </ByocItem>
        <ByocItem className='marginBottom2Xs'>
          <Body3>
            <b>
              <RedSpan>2.</RedSpan> Delivery radius:
            </b>{' '}
            set 5 miles (further distances will be configured with us in 7
            days).
          </Body3>
        </ByocItem>
        <ByocItem className='marginBottom2Xs'>
          <Body3>
            <b>
              <RedSpan>3.</RedSpan> Estimated delivery time:
            </b>{' '}
            set 30-50 min.
          </Body3>
        </ByocItem>
        <ByocItem className='marginBottom2Xs'>
          <Body3>
            <b>
              <RedSpan>4.</RedSpan> Do you need a bluetooth printer:
            </b>{' '}
            it‘s your call (we recommend you have one).
          </Body3>
        </ByocItem>
      </ByocItems>
      <Body4 className='marginBottom2Xs'>Tips</Body4>
      <FlexContainer className='marginBottom2Xs'>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 color='#969BA0'>
          The email usually arrives within a few hours (up to 48). Check your
          email frequently.
        </Body2>
      </FlexContainer>
      <FlexContainer>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 color='#969BA0'>
          You can easily change the information you provide later on your
          dashabord.
        </Body2>
      </FlexContainer>

      {props.actionsRef
        ? ReactDOM.createPortal(
            <>
              <ButtonOutline
                className='ButtonLg'
                onClick={() => setShowEmailModal(true)}
              >
                Waiting for email
              </ButtonOutline>
              <ButtonPrimary
                onClick={() =>
                  BYOCconfigured
                    ? props.goNextAction()
                    : setShowDeliveryZonesModal(true)
                }
              >
                <span>Answered email</span>
              </ButtonPrimary>
            </>,
            props.actionsRef
          )
        : null}

      {showEmailModal && (
        <EmailWaitingModal onClose={() => setShowEmailModal(false)} />
      )}

      {!BYOCconfigured && showDeliveryZonesModal && (
        <DeliveryZonesModal
          onClose={props.goNextAction}
          sendSlackNotif={props.sendSlackNotif}
        />
      )}
    </div>
  );
}

ByocThird.propTypes = {
  actionsRef: PropTypes.object.isRequired,
  goNextAction: PropTypes.func.isRequired,
  sendSlackNotif: PropTypes.func.isRequired
};

const EmailWaitingModal = (props) => {
  return (
    <Dialog open scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogTitle>Your UberEats email is on its way</DialogTitle>
        <DialogBody>
          <Body4 className='marginTopXl' textAlign='center'>
            Check your email frequently today. Answer as suggested immediately
            when you receive it.
          </Body4>
          <DialogActions className='justifyCenter'>
            <DialogAction className='dFlexCenter'>
              <ButtonPrimary className='w200' onClick={props.onClose}>
                <span>Got it</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EmailWaitingModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

const DeliveryZonesModal = (props) => {
  return (
    <Dialog open scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogBody>
          <DialogTitle>Delivery Zone Issues</DialogTitle>
          <DialogBody>
            <Body4 className='marginTopXl' textAlign='center'>
              Do you have any toll roads or long bridges inside your 5 miles
              zone?
            </Body4>
          </DialogBody>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonOutline
                className='ButtonLg'
                onClick={() => {
                  props.onClose();
                  props.sendSlackNotif('No');
                }}
              >
                No
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                onClick={() => {
                  props.onClose();
                  props.sendSlackNotif('Yes');
                }}
              >
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DeliveryZonesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  sendSlackNotif: PropTypes.func.isRequired
};
