import styled from 'styled-components';
import IconArrowButton from '../../../assets/icon-arrow-down-white.svg';
import IconHand from '../../../assets/icon-hand.svg';
import Body3 from '../../../components/typography/Body3';

export const SignUpSection = styled.div`
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  @media (max-width: 1000px) {
    min-height: inherit;
    display: block;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    height: 100vh;

    &.isActive {
      .SignUpFeedback {
        transform: translateY(0vh);
        .SignUpFeedbackButton {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.isActive {
      .SignUpFeedback {
        transform: translateY(0vh);
      }
    }
  }
`;
export const SignUpFeedback = styled.div`
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
    position: absolute;
    top: 100vh;
    left: 0;
    transform: translateY(-100%);
    transition-property: transform;
    z-index: 4;
    transition-duration: 0.5s;
  }
`;
export const SignUpFeedbackTop = styled.div`
  display: none;
  position: absolute;
  top: -13px;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 13px;
  background: #525f6b;
  @media (max-width: 1000px) {
    display: block;
  }
  @media (max-width: 767px) {
    height: 10px;
    top: -10px;
  }
`;
export const SignUpFeedbackButton = styled.div`
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-56px);
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: #525f6b;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 88px;
    height: 88px;
    transform: translateX(-44px);
    top: -36px;
  }

  &::before {
    position: absolute;
    top: 18px;
    left: 34px;
    content: '';
    width: 15px;
    height: 27px;
    background-image: url(${IconArrowButton});
    background-repeat: no-repeat;
    background-size: contain;
    transition-property: transform;
    transition-duration: 0.3s;
    transform: rotate(0);
    @media (max-width: 767px) {
      left: 23px;
      top: 11px;
    }
  }
  &::after {
    position: absolute;
    top: 18px;
    right: 30px;
    content: '';
    width: 20px;
    height: 25px;
    background-image: url(${IconHand});
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 767px) {
      right: 24px;
      top: 11px;
    }
  }
`;
export const SignUpFeedbackPhoto = styled.div`
  flex: 1 1 auto;
  max-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  @media (max-width: 1000px) {
    height: 320px;
    position: relative;
    z-index: 2;
  }
  @media (max-width: 767px) {
    height: 240px;
    position: relative;
    z-index: 3;
  }
  @media (max-width: 560px) {
    height: 220px;
  }
  @media (max-width: 440px) {
    height: 200px;
  }
  @media (max-width: 400px) {
    height: 180px;
  }
`;
export const SignUpFeedbackBottom = styled.div`
  flex: 0 0 auto;
  padding: 32px 70px 40px 70px;

  @media (max-width: 1650px) {
    padding: 32px 50px 40px 50px;
  }
  @media (max-width: 1300px) {
    padding: 30px 40px 40px 40px;
  }
  @media (max-width: 1100px) {
    padding: 25px 30px 30px 30px;
  }
  @media (max-width: 1000px) {
    padding: 25px 90px 30px 90px;
    background-color: #fff;
  }
  @media (max-width: 767px) {
    padding: 18px 68px 24px 68px;
  }
  @media (max-width: 560px) {
    padding: 18px 25px;
  }
  @media (max-width: 440px) {
    padding: 18px 20px;
  }
`;
export const SignUpFeedbackDescription = styled.div`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.4;
  color: #2f3d48;
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 560px) {
    font-size: 14px;
  }
`;
export const SignUpFeedbackCompany = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
  @media (max-width: 560px) {
    margin-top: 15px;
  }
`;
export const SignUpFeedbackLogo = styled.img`
  display: block;
  max-width: 200px;
  @media (max-width: 1440px) {
    max-width: 180px;
  }
  @media (max-width: 1250px) {
    max-width: 160px;
  }
  @media (max-width: 767px) {
    max-width: 120px;
  }
`;
export const SignUpFeedbackInfo = styled.div`
  display: block;
  padding-left: 26px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;

  @media (max-width: 1250px) {
    font-size: 16px;
    padding-left: 16px;
  }
  @media (max-width: 560px) {
    font-size: 14px;
  }

  p {
    display: block;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
export const SignUpForm = styled.div`
  flex: 0 0 auto;
  width: 50%;
  background-color: #fff;
  padding: 80px 20px 64px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1650px) {
    padding-top: 90px;
  }
  @media (max-width: 1440px) {
    padding: 80px 20px 55px 20px;
  }
  @media (max-width: 1300px) {
    padding: 50px 20px 40px 20px;
  }
  @media (max-width: 1000px) {
    padding-top: 100px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    padding-bottom: 0;
  }
  @media (max-width: 767px) {
    padding: 65px 15px 0 15px;
  }
  @media (max-width: 440px) {
    padding: 45px 10px 0 10px;
    min-height: inherit;
  }

  .DialogFieldsSignUp {
    max-width: 626px;
    margin: 0 auto;
    @media (max-width: 1650px) {
      max-width: 596px;
    }
    @media (max-width: 1440px) {
      max-width: 566px;
    }

    @media (max-width: 1300px) {
      max-width: 476px;
    }
    button.w400 {
      @media (max-width: 1300px) {
        max-width: 340px;
      }
      @media (max-width: 440px) {
        max-width: 100%;
      }
    }
  }
`;

export const SignUpLogo = styled.img`
  display: block;
  max-width: 208px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1800px) {
    max-width: 300px;
  }
  @media (max-width: 1700px) {
    max-width: 280px;
  }
  @media (max-width: 1500px) {
    max-width: 260px;
  }
  @media (max-width: 1400px) {
    max-width: 240px;
  }
  @media (max-width: 767px) {
    max-width: 210px;
  }
  @media (max-width: 440px) {
    max-width: 200px;
  }
`;

export const SignUpTitle = styled.div`
  display: block;
  text-align: center;
  margin-top: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2;
  color: #2f3d48;
  span {
    color: #ff2d38;
  }
  @media (max-width: 1650px) {
    font-size: 38px;
    margin-top: 55px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-top: 50px;
  }
  @media (max-width: 1300px) {
    font-size: 30px;
    margin-top: 40px;
  }
  @media (max-width: 1100px) {
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
export const SignUpText = styled.div`
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: #2f3d48;
  @media (max-width: 1440px) {
    font-size: 17px;
  }
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

export const SignUpBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #2f3d48;

  @media (max-width: 767px) {
    margin-top: 25px;
  }
`;
export const SignUpLink = styled.a`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  color: #2f3d48;
  margin: 0 4px;
  text-decoration: none;
  border-bottom: 1px solid #172751;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  &:hover {
    border-bottom-color: transparent;
  }
  @media (max-width: 1440px) {
    font-size: 17px;
  }
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;
export const SignUpBottomText = styled(Body3)`
  @media (max-width: 1440px) {
    font-size: 17px;
  }
  @media (max-width: 1300px) {
    font-size: 16px;
  }
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px -15px 0 -15px;
  @media (max-width: 1350px) {
    margin: 10px -10px 0 -10px;
  }
  @media (max-width: 800px) {
    margin: 10px -15px 0 -15px;
  }
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export const LogosItem = styled.img`
  display: inline-block;
  max-width: 146px;
  max-height: 110px;
  margin: 0 15px;

  @media (max-width: 1700px) {
    max-width: 120px;
    max-height: 100px;
  }
  @media (max-width: 1600px) {
    max-width: 110px;
  }
  @media (max-width: 1440px) {
    max-width: 105px;
    max-height: 90px;
  }
  @media (max-width: 1350px) {
    max-width: 95px;
    margin: 0 10px;
  }
  @media (max-width: 1200px) {
    max-width: 85px;
  }
  @media (max-width: 1100px) {
    max-width: 80px;
  }
  @media (max-width: 1000px) {
    max-width: 120px;
  }
  @media (max-width: 800px) {
    max-width: 95px;
    margin: 0 15px;
  }
`;
