import styled from 'styled-components';

const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 26px;

  @media (max-width: 1350px) {
    margin-right: 16px;
  }

  @media (max-width: 599px) {
    display: block;
    margin: 0;
  }

  span {
    white-space: nowrap;
    margin-right: 14px;
    @media (max-width: 599px) {
      margin: 0 0 10px 0;
      display: block;
    }
  }

  .Say2EatDrop {
    min-width: 300px;
    max-width: 400px;
    @media (max-width: 599px) {
      max-width: 100%;
    }

    @media (max-width: 390px) {
      min-width: 0;
    }
  }
`;

export default DatePickerContainer;
