/* eslint-disable */
import React, {Component} from 'react';
import T from 'prop-types';
import {connect} from 'react-redux';
import DraGnDropList from '../components/DraGnDropList';
import TreeItem from './TreeItem';
import {UPDATE_SORTING_ORDER} from '../../../../store/menuEditor/action';

class DgDList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      elements: [...this.props.items],
      items: this.props.items.map(this.mapItems)
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.items !== nextProps.items) {
      this.setState({
        elements: [...nextProps.items],
        items: nextProps.items.map(this.mapItems)
      });

      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.withBulkCheckbox !== prevProps.withBulkCheckbox ||
      this.props.bulkSelectedMap !== prevProps.bulkSelectedMap
    ) {
      this.setState({
        elements: [...this.props.items],
        items: this.props.items.map(this.mapItems)
      });
    }
  }

  arraysEqual = (a1, a2) => JSON.stringify(a1) === JSON.stringify(a2);

  setDNDItems = (items) => {
    const {parentId, parentType} = this.props;
    const elements = items.map((item) => item.id);

    if (!this.arraysEqual(elements, this.state.elements)) {
      this.props.sortTree({
        ParentId: parentId,
        ParentType: parentType,
        Elements: elements
      });
    }
  };

  mapItems = (id) => {
    const {
      withoutDrag,
      itemScheme,
      withBulkCheckbox,
      bulkSelectedMap
    } = this.props;

    return {
      id,
      withoutDrag,
      withBulkCheckbox,
      bulkSelectedMap,
      componentProps: {
        itemId: id,
        itemScheme
      },
      ChildComponent: TreeItem
    };
  };

  render() {
    const {items} = this.state;

    return (
      <DraGnDropList
        changeOrderAction={this.setDNDItems}
        dragDataList={items}
        items={items}
      />
    );
  }
}

DgDList.propTypes = {
  items: T.array,
  itemScheme: T.string,
  parentId: T.string,
  parentType: T.string,
  withoutDrag: T.bool
};

DgDList.defaultProps = {
  items: [],
  itemScheme: '',
  parentId: '0',
  parentType: 'menu',
  withoutDrag: false
};

export default connect(null, {
  sortTree: UPDATE_SORTING_ORDER
})(DgDList);
