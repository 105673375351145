import * as Yup from 'yup';

const routeRegex = /^\/([a-zA-Z0-9_-]+)(\/([a-zA-Z0-9_-]+))*\/?$/;

const FeatureFlagsSchema = Yup.object().shape({
  source: Yup.string()
    .required('Mandatory filed')
    .trim('The field cannot contain only spaces.'),
  name: Yup.string()
    .required('Mandatory filed')
    .trim('The field cannot contain only spaces.'),
  route: Yup.string()
    .nullable()
    .notRequired()
    .when('source', {
      is: 'Dashboard',
      then: Yup.string()
        .nullable()
        .notRequired()
        .matches(routeRegex, 'Please enter valid route')
    })
});

export default FeatureFlagsSchema;
