import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  CancelRefundButton,
  RefundsAmountErrorBanner,
  RefundsBottomBar,
  SubmitRefundButton
} from './styles';
import roundTheNumber from '../../../../../../helpers/functionUtils/roundTheNumber';
import {CLEAR_REFUND_DATA} from '../../../../../../store/orders/orderRefund/action';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';

export default function RefundBottomBar({
  totalAmount,
  totalQuantity,
  submitForm,
  amountError,
  setAmountError,
  values,
  refundItems,
  orderPriceAdjustments,
  orderTips
}) {
  const history = useHistory();
  const {params} = useRouteMatch();
  const dispatch = useDispatch();

  const redirectToOrderDetails = (state = {}) => {
    dispatch(CLEAR_REFUND_DATA());
    history.push(
      params.back === 'history'
        ? `/orders/history/${params.id}/details`
        : `/orders/active/${params.id}/details`,
      state
    );
  };

  return (
    <>
      <RefundsBottomBar>
        <RefundsAmountErrorBanner
          className={cn({
            error: values.refundType !== 'Full' && amountError
          })}
        >
          {/* {totalAmount > stripeAmount && (
            <span>
              For this order, you can partially refund up to ${(stripeAmount)?.toFixed(2)}.
              For larger amounts contact Sauce support.
            </span>
          )} */}
          {amountError && <span>Select at least 1 item to refund</span>}
        </RefundsAmountErrorBanner>
        <div className='refundBottomContainer'>
          <div>
            {values.refundType === 'Full' ? (
              <p>Your customer will be refunded in full</p>
            ) : (
              <p>
                Total + tax: <span>${roundTheNumber(totalAmount)}</span>
              </p>
            )}
          </div>
          <div className='refundButtonContainer'>
            <CancelRefundButton
              onClick={() => {
                redirectToOrderDetails();
              }}
            >
              <span>Cancel</span>
            </CancelRefundButton>
            <SubmitRefundButton
              type='submit'
              onClick={() => {
                if (totalQuantity === 0) {
                  setAmountError(true);
                }
                submitForm();
              }}
              className={cn('', {
                disabled:
                  !refundItems.length &&
                  !orderPriceAdjustments?.length &&
                  !orderTips &&
                  values.refundType === 'Partial'
              })}
            >
              <span>Make Refund </span>
            </SubmitRefundButton>
          </div>
        </div>
      </RefundsBottomBar>
    </>
  );
}

RefundBottomBar.propTypes = {
  totalAmount: PropTypes.number.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  submitForm: PropTypes.func.isRequired,
  amountError: PropTypes.bool.isRequired,
  setAmountError: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  refundItems: PropTypes.array.isRequired,
  orderPriceAdjustments: PropTypes.array.isRequired,
  orderTips: PropTypes.number.isRequired
};
