import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {Checkbox} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {ButtonPrimary} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {DialogText} from '../../../dashboard/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {
  CHANGE_UBER_STATUS_SUCCESS,
  HANDLE_CONNECT_MODAL
} from '../../../../../store/uberEats/actions';
import CheckboxContainer from '../../../../../components/Checkbox/styles';

const ConnectModal = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const {connectModalOpen} = useSelector((state) => state.uberEatsReducer);

  /* TODO: FOR DEMO PURPOSES ONLY. DELETE AFTERWARDS! */
  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );

  const handleChangeCheckbox = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    return () => setChecked(false);
  }, [connectModalOpen]);

  const onConnect = () => {
    dispatch(HANDLE_CONNECT_MODAL(false));
    //    TODO: FOR DEMO PURPOSES ONLY
    dispatch(CHANGE_UBER_STATUS_SUCCESS(!UEConnected));
    toast.success('UberEats account is connected to Sauce');
    //    if (!uberEatsInitiallyConnected) {
    //      window.open(
    //        `${process.env.REACT_APP_UBEREATS_API_URL}/api/auth/uberEats/${locationId}`,
    //        '_self'
    //      );
    //    } else {
    //      dispatch(CHANGE_UBER_STATUS({UberEatsId: uberEatsId, IsActive: true}));
    //    }
  };

  const onClose = () => {
    dispatch(HANDLE_CONNECT_MODAL(false));
  };

  return (
    <Dialog onClose={() => onClose()} scroll='body' open={connectModalOpen}>
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => onClose()} />
        <DialogTitle className='DialogTitleOrange'>Please note:</DialogTitle>
        <DialogBody>
          <DialogText className='align-justify'>
            Connecting UberEats allows getting orders on Sauce dashboard and
            changing the menu on UberEats with the one you have in Sauce menu.
          </DialogText>
          <CheckboxContainer className='justifyCenter marginTopMd marginBottomMd'>
            <FormControlLabel
              className='Say2EatCheckbox'
              control={
                <Checkbox
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  onChange={handleChangeCheckbox}
                  checked={checked}
                />
              }
              label='Allow overriding UberEats menu'
            />
          </CheckboxContainer>
          <DialogAction>
            <ButtonPrimary
              className={!checked && 'disabled'}
              onClick={onConnect}
            >
              <span>Connect</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default ConnectModal;
