import {createAction} from 'redux-act';

export const CLEAR_ORDERS_RTB = createAction(
  'realTimeBoardV2/CLEAR_ORDERS_RTB'
);

export const GET_ORDERS_RTB_SUCCESS = createAction(
  'realTimeBoardV2/GET_ORDERS_RTB_SUCCESS'
);

export const RUN_ORDER_RTB_JOB = createAction(
  'realTimeBoardV2/RUN_ORDER_RTB_JOB'
);
export const STOP_ORDER_RTB_JOB = createAction(
  'realTimeBoardV2/STOP_ORDER_RTB_JOB'
);

export const GET_ORDER_ACTIONS_SUCCESS = createAction(
  'realTimeBoardV2/GET_ORDER_ACTIONS_SUCCESS'
);

export const SEND_ORDER_ACTIONS = createAction(
  'realTimeBoardV2/SEND_ORDER_ACTIONS'
);

export const CLEAR_ORDER_ACTIONS = createAction(
  'realTimeBoardV2/CLEAR_ORDER_ACTIONS'
);

export const OPEN_ACTIONS_MADE_MODAL = createAction(
  'realTimeBoardV2/OPEN_ACTIONS_MADE_MODAL'
);

export const OPEN_ADDITION_INFO_MODAL = createAction(
  'realTimeBoardV2/OPEN_ADDITION_INFO_MODAL'
);
export const OPEN_ADDITION_INFO_MODAL_SUCCESS = createAction(
  'realTimeBoardV2/OPEN_ADDITION_INFO_MODAL_SUCCESS'
);

export const RDB_REMOVE_ORDER_MODAL = createAction(
  'realTimeBoardV2/RDB_REMOVE_ORDER_MODAL'
);

export const REMOVE_ORDER = createAction('realTimeBoardV2/REMOVE_ORDER');
