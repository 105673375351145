import {useLocation} from 'react-router-dom';
import React, {useMemo} from 'react';

const useQuery = () => {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
