import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const generateEnableTime = (
  timestampValue,
  currentLocationTimezone,
  values,
  openingHours
) => {
  switch (timestampValue) {
    case 'one-hour': {
      return currentLocationTimezone.charAt(0) === '-'
        ? moment()
            .add(1, 'hours')
            .subtract(currentLocationTimezone.replace('-', ''), 'hours')
            .unix()
        : moment().add(1, 'hours').add(currentLocationTimezone, 'hours').unix();
    }
    case 'opening-time': {
      const startWeek = moment().utc().startOf('week');
      const currentMinute =
        currentLocationTimezone.charAt(0) === '-'
          ? moment()
              .utc()
              .subtract(currentLocationTimezone.replace('-', ''), 'hours')
              .diff(startWeek, 'minutes')
          : moment()
              .startOf('week')
              .utc()
              .add(currentLocationTimezone.replace('+', ''), 'hours')
              .diff(startWeek, 'minutes');

      const nextOpeningDay = openingHours.filter(
        (hour) => hour.Start > currentMinute
      )[0];

      if (!nextOpeningDay) {
        return moment()
          .utc()
          .add(1, 'weeks')
          .startOf('week')
          .add(openingHours[0]?.Start, 'm')
          .unix();
      }

      return moment()
        .utc()
        .startOf('week')
        .add(nextOpeningDay.Start, 'minutes')
        .unix();
    }
    case 'date-and-hour': {
      const utcDate = moment(values.dateValue)
        .utcOffset(0, true)
        .startOf('day');

      const timeValue = moment(values.selectValue, ['h:mm A']);

      return utcDate
        .add(timeValue.hours(), 'hours')
        .add(timeValue.minutes(), 'minutes')
        .unix();
    }
    case 'custom-dates-request': {
      return moment(
        moment(values.dateValues[1])
          .utc()
          .startOf('day')
          .add(
            moment(`${values.selectTimeValue}`, ['h:mm A']).format('HH:mm'),
            'hours'
          )
      )
        .utc()
        .unix();
    }
    case 'custom-dates-request-disable': {
      return moment(
        moment(values.dateValues[0])
          .utc()
          .startOf('day')
          .add(
            moment(`${values.selectDisableTimeValue}`, ['h:mm A']).format(
              'HH:mm'
            ),
            'hours'
          )
      )
        .utc()
        .unix();
    }
    default:
      return null;
  }
};
