import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import PropTypes from 'prop-types';
import {DialogBody} from '../../../routes/App/dashboard/styles';
import DialogContainer from '../../DialogContainer/styles';
import DialogClose from '../../DialogClose/styles';

const Modal = ({
  onCloseAction,
  title,
  body,
  width,
  classParameter = 'DialogMd'
}) => {
  Modal.propTypes = {
    body: PropTypes.element,
    title: PropTypes.element,
    width: PropTypes.string,
    onCloseAction: PropTypes.func,
    classParameter: PropTypes.string
  };

  Modal.defaultProps = {
    body: '',
    title: '',
    width: '',
    onCloseAction: () => {},
    classParameter: 'DialogMd'
  };

  return (
    <Dialog open scroll='body' onClose={onCloseAction}>
      <DialogContainer className={classParameter}>
        <DialogClose onClick={onCloseAction} />
        <div>{title}</div>
        <DialogBody>{body}</DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default Modal;
