import styled from 'styled-components';

const DialogFields = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px 10px -10px;
  &.column {
    display: flex;
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.mTop40 {
    margin-top: 40px;
  }

  &.mBottom0 {
    margin-bottom: 0;
  }

  &.dFlexNoWrap {
    flex-wrap: nowrap;
  }

  &.mBottom40 {
    margin-bottom: 40px;
  }
  &.wAuto {
    flex: 1 1 auto;
  }

  &.column {
    flex-direction: column;
  }

  &.verticalCenter {
    align-items: center;
  }

  &.verticalBottom {
    align-items: flex-end;
  }

  &.mobInline {
    .w50 {
      @media (max-width: 1000px) {
        flex: 0 0 50%;
      }
      @media (max-width: 460px) {
        flex: 0 0 100%;
        &.mobileOrder1 {
          order: 1;
        }
        &.mobileOrder2 {
          order: 2;
        }
      }
    }
  }

  &.DialogFieldsSeparate {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      height: 1px;
      background-color: rgba(146, 156, 185, 0.3);
      content: '';
    }
  }
  .RadioGroup {
    width: 100%;
  }
`;

export default DialogFields;
