import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment/moment';
import {DashboardFields} from 'routes/App/dashboard/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import {
  FindDeliveryResultContainer,
  FindDeliveryResultItem,
  FindDeliveryResultItemRside,
  FindDeliveryResultItems
} from 'routes/App/Managers/FindDelivery/styles';
import Body6 from 'components/typography/Body6';
import {
  FIND_DELIVERY,
  FIND_DELIVERY_DISPATCH_VERSION,
  FIND_DELIVERY_DISPATCH_VERSION_VALUE,
  FIND_DELIVERY_STATUS
} from 'helpers/constants/findDelivery';
import {ProviderTrackingLink} from 'routes/App/Managers/realTimeBoardV2/styles';

const FindDeliveryResult = () => {
  const {orderData} = useSelector((state) => state.findDeliveryReducer);

  return (
    <>
      <DashboardInner className='RefundsCalculator withoutShadow paddingLg'>
        <DashboardFields className='noWrapDesktop AlignCenter marginMinus'>
          {/* Moving to red zone */}

          {orderData[FIND_DELIVERY.RISK_LEVEL] &&
            orderData[FIND_DELIVERY.RISK_LEVEL]?.value === 3 &&
            Array.isArray(orderData[FIND_DELIVERY.RISK_LEVEL]?.reasons) &&
            orderData[FIND_DELIVERY.RISK_LEVEL]?.reasons?.length > 0 &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.DELIVERED &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.CANCELLED &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.RETURNED && (
              <FindDeliveryResultContainer>
                <Body6 className='fw700 mB7'>Moving to red zone:</Body6>
                <FindDeliveryResultItems className='noBorder'>
                  {orderData[FIND_DELIVERY.RISK_LEVEL]?.reasons?.map((item) => (
                    <FindDeliveryResultItem>
                      <Body6 className='fw400'>{item}</Body6>
                    </FindDeliveryResultItem>
                  ))}
                </FindDeliveryResultItems>
              </FindDeliveryResultContainer>
            )}

          {/* Dispatcher action */}

          {Array.isArray(orderData[FIND_DELIVERY.ACTIONS]) &&
            orderData[FIND_DELIVERY.ACTIONS]?.length > 0 &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.DELIVERED &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.CANCELLED &&
            orderData[FIND_DELIVERY.DELIVERY]?.status !==
              FIND_DELIVERY_STATUS.RETURNED && (
              <FindDeliveryResultContainer>
                <Body6 className='fw700 mB7'>Dispatcher action:</Body6>
                <FindDeliveryResultItems className='noBorder'>
                  {orderData[FIND_DELIVERY.ACTIONS]?.map((item) => (
                    <>
                      <FindDeliveryResultItem>
                        <Body6 className='list-title fw400'>Action taken</Body6>
                        <FindDeliveryResultItemRside>
                          <Body6 className='rightBlock fw400'>
                            {item?.type}
                          </Body6>
                        </FindDeliveryResultItemRside>
                      </FindDeliveryResultItem>
                      <FindDeliveryResultItem>
                        <Body6 className='list-title fw400'>Date & Time</Body6>
                        <FindDeliveryResultItemRside>
                          <Body6 className='rightBlock fw400'>
                            {moment
                              .utc(item?.createdAt)
                              .utcOffset(orderData[FIND_DELIVERY.TIMEZONE] || 0)
                              .format('MMMM DD HH:mm:ss')}
                          </Body6>
                        </FindDeliveryResultItemRside>
                      </FindDeliveryResultItem>
                      {item?.bookADriverSauceOrderId && (
                        <FindDeliveryResultItem>
                          <Body6 className='list-title fw400'>
                            Delivery ID
                          </Body6>
                          <FindDeliveryResultItemRside>
                            <Body6 className='rightBlock fw400'>
                              {item?.bookADriverSauceOrderId}
                            </Body6>
                          </FindDeliveryResultItemRside>
                        </FindDeliveryResultItem>
                      )}
                      {item?.description && (
                        <FindDeliveryResultItem>
                          <Body6 className='list-title fw400'>
                            Description
                          </Body6>
                          <FindDeliveryResultItemRside>
                            <Body6 className='rightBlock fw400'>
                              {item?.description}
                            </Body6>
                          </FindDeliveryResultItemRside>
                        </FindDeliveryResultItem>
                      )}
                      <br />
                    </>
                  ))}
                </FindDeliveryResultItems>
              </FindDeliveryResultContainer>
            )}

          {/* Order details */}

          <FindDeliveryResultContainer>
            <Body6 className='fw700 mB7'>Order details:</Body6>
            <FindDeliveryResultItems className='noBorder'>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Invoice ID</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData?.coreOrderId}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Order amount</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    ${(orderData[FIND_DELIVERY.ORDER_AMOUNT] / 100).toFixed(2)}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Delivery tips</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    ${(orderData[FIND_DELIVERY.TIP] / 100).toFixed(2)}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Number of items</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.ORDER_ITEMS_COUNT]}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Is test order</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY]?.isTest ? 'Yes' : 'No'}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              {orderData[FIND_DELIVERY]?.specialNote && (
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>
                    Notes for restaurant
                  </Body6>
                  <FindDeliveryResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      {orderData[FIND_DELIVERY]?.specialNote}
                    </Body6>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
              )}
            </FindDeliveryResultItems>
          </FindDeliveryResultContainer>

          {/* Dispatch details */}

          <FindDeliveryResultContainer>
            <Body6 className='fw700 mB7'>Dispatch details:</Body6>
            <FindDeliveryResultItems className='noBorder'>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Dispatch version</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {
                      FIND_DELIVERY_DISPATCH_VERSION_VALUE[
                        orderData[FIND_DELIVERY.DISPATCH_VERSION]
                      ]
                    }
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Delivery type</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY]?.isFutureOrder
                      ? 'Future'
                      : 'ASAP'}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Is contactless</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY]?.isContactless ? 'Yes' : 'No'}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Sauce delivery ID</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.DELIVERY]?.id}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Final DC name</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.DELIVERY]?.provider}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Final DC delivery ID</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.DELIVERY]?.internalId}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Uber delivery id</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.DELIVERY]?.uberId || 'N/A'}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              {(orderData[FIND_DELIVERY.OLD_DISPATCH_PROVIDER_TRACKING_LINK] ||
                orderData[
                  FIND_DELIVERY.NEW_DISPATCH_PROVIDER_TRACKING_LINK
                ]) && (
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>
                    Provider tracking link
                  </Body6>
                  <FindDeliveryResultItemRside>
                    <ProviderTrackingLink
                      href={
                        orderData[
                          FIND_DELIVERY.OLD_DISPATCH_PROVIDER_TRACKING_LINK
                        ] ||
                        orderData[
                          FIND_DELIVERY.NEW_DISPATCH_PROVIDER_TRACKING_LINK
                        ]
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      {orderData[FIND_DELIVERY.DELIVERY]?.provider
                        ? `${
                            orderData[FIND_DELIVERY.DELIVERY]?.provider
                          } Tracking Link`
                        : 'Tracking Link'}
                    </ProviderTrackingLink>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
              )}
              {orderData[FIND_DELIVERY.DISPATCH_VERSION] ===
                FIND_DELIVERY_DISPATCH_VERSION.DISPATCH_V2 &&
                orderData[FIND_DELIVERY.DELIVERY]?.providers?.length > 0 && (
                  <>
                    <FindDeliveryResultItem>
                      <Body6 className='list-title fw400'>
                        All providers&#39; orders:
                      </Body6>
                    </FindDeliveryResultItem>
                    {orderData[FIND_DELIVERY.DELIVERY]?.providers?.map(
                      (provider) => (
                        <FindDeliveryResultItem>
                          {provider && (
                            <Body6 className='list-title fw400 listPoint'>
                              <span>
                                {provider?.name}
                                {' - '}
                              </span>
                              <span>
                                {provider?.internalId || 'null'}
                                {' - '}
                              </span>
                              <span>{provider?.status}</span>
                            </Body6>
                          )}
                        </FindDeliveryResultItem>
                      )
                    )}
                  </>
                )}
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>
                  Number of couriers changed
                </Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.DELIVERY]?.driverChangeCount >= 0
                      ? orderData[FIND_DELIVERY.DELIVERY]?.driverChangeCount
                      : '0'}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              {orderData[FIND_DELIVERY.STATUS]?.type ===
                FIND_DELIVERY_STATUS.DELIVERED && (
                <>
                  <FindDeliveryResultItem>
                    <Body6 className='list-title fw400'>
                      Delivery information:
                    </Body6>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      Delivery Date & Time
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {moment
                          .utc(orderData?.updatedAt)
                          .utcOffset(orderData[FIND_DELIVERY.TIMEZONE] || 0)
                          .format('MMMM DD HH:mm:ss')}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      Proof of delivery
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {orderData[FIND_DELIVERY.DELIVERY]?.proofOfDelivery
                          ?.photoUrl ? (
                          <ProviderTrackingLink
                            href={
                              orderData[FIND_DELIVERY.DELIVERY]?.proofOfDelivery
                                ?.photoUrl
                            }
                            target='_blank'
                            rel='noreferrer'
                          >
                            link
                          </ProviderTrackingLink>
                        ) : (
                          <FindDeliveryResultItemRside>
                            <Body6 className='rightBlock fw400'>N/A</Body6>
                          </FindDeliveryResultItemRside>
                        )}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                </>
              )}
              {(orderData[FIND_DELIVERY.STATUS]?.type ===
                FIND_DELIVERY_STATUS.CANCELLED ||
                orderData[FIND_DELIVERY.STATUS]?.type ===
                  FIND_DELIVERY_STATUS.RETURNED) && (
                <>
                  <FindDeliveryResultItem>
                    <Body6 className='list-title fw400'>
                      Cancellation information:
                    </Body6>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      Was picked up
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {orderData[FIND_DELIVERY.STATUS]?.isCancelledAfterPickup
                          ? 'Yes'
                          : 'No'}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      {orderData[FIND_DELIVERY.STATUS]?.type ===
                      FIND_DELIVERY_STATUS.CANCELLED
                        ? 'Cancelled'
                        : 'Returned'}{' '}
                      Date & Time
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {moment
                          .utc(orderData?.updatedAt)
                          .utcOffset(orderData[FIND_DELIVERY.TIMEZONE] || 0)
                          .format('MMMM DD HH:mm:ss')}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      Cancellation source
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {orderData[FIND_DELIVERY.STATUS]?.source || 'N/A'}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                  <FindDeliveryResultItem className='list-title'>
                    <Body6 className='list-title fw400 listPoint'>
                      Cancellation comment
                    </Body6>
                    <FindDeliveryResultItemRside>
                      <Body6 className='rightBlock fw400'>
                        {orderData[FIND_DELIVERY.STATUS]?.reason || 'N/A'}
                      </Body6>
                    </FindDeliveryResultItemRside>
                  </FindDeliveryResultItem>
                </>
              )}
            </FindDeliveryResultItems>
          </FindDeliveryResultContainer>

          {/* Location details */}

          <FindDeliveryResultContainer>
            <Body6 className='fw700 mB7'>Location details:</Body6>
            <FindDeliveryResultItems className='noBorder'>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Merchant name</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.MERCHANT]?.name}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Locations name</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.LOCATION]?.name}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Location address</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.PICKUP]?.address?.formattedAddress}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Location phone</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.LOCATION]?.phone}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
            </FindDeliveryResultItems>
          </FindDeliveryResultContainer>

          {/* Customer details */}

          <FindDeliveryResultContainer>
            <Body6 className='fw700 mB7'>Customer details:</Body6>
            <FindDeliveryResultItems className='noBorder'>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Customer name</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.CUSTOMER_NAME]}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Customer phone</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.CUSTOMER_PHONE]}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Customer email</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {orderData[FIND_DELIVERY.CUSTOMER]?.email}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              <FindDeliveryResultItem>
                <Body6 className='list-title fw400'>Customer address</Body6>
                <FindDeliveryResultItemRside>
                  <Body6 className='rightBlock fw400'>
                    {`${
                      orderData[FIND_DELIVERY.DROPOFF]?.address
                        ?.formattedAddress
                    } ${
                      orderData[FIND_DELIVERY.DROPOFF]?.address?.apartment
                        ? `Apt: ${
                            orderData[FIND_DELIVERY.DROPOFF]?.address?.apartment
                          }`
                        : ''
                    } ${
                      orderData[FIND_DELIVERY.DROPOFF]?.address?.floor
                        ? `Floor: ${
                            orderData[FIND_DELIVERY.DROPOFF]?.address?.floor
                          }`
                        : ''
                    } ${
                      orderData[FIND_DELIVERY.DROPOFF]?.address?.entrance
                        ? `Entrance: ${
                            orderData[FIND_DELIVERY.DROPOFF]?.address?.entrance
                          }`
                        : ''
                    } ${
                      orderData[FIND_DELIVERY.DROPOFF]?.address?.unit
                        ? `Unit: ${
                            orderData[FIND_DELIVERY.DROPOFF]?.address?.unit
                          }`
                        : ''
                    }`}
                  </Body6>
                </FindDeliveryResultItemRside>
              </FindDeliveryResultItem>
              {orderData[FIND_DELIVERY.DROPOFF]?.address?.notes && (
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>Delivery notes</Body6>
                  <FindDeliveryResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      {orderData[FIND_DELIVERY.DROPOFF]?.address?.notes}
                    </Body6>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
              )}
            </FindDeliveryResultItems>
          </FindDeliveryResultContainer>

          {/* Courier details */}

          {orderData[FIND_DELIVERY.COURIER] && (
            <FindDeliveryResultContainer>
              <Body6 className='fw700 mB7'>Courier details:</Body6>
              <FindDeliveryResultItems className='noBorder'>
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>Courier name</Body6>
                  <FindDeliveryResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      {orderData[FIND_DELIVERY.COURIER]?.name || 'N/A'}
                    </Body6>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>Courier phone</Body6>
                  <FindDeliveryResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      {orderData[FIND_DELIVERY.COURIER]?.phone || 'N/A'}
                    </Body6>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
                <FindDeliveryResultItem>
                  <Body6 className='list-title fw400'>Vehicle type</Body6>
                  <FindDeliveryResultItemRside>
                    <Body6 className='rightBlock fw400'>
                      {orderData[FIND_DELIVERY.COURIER]?.vehicle?.type || 'N/A'}
                    </Body6>
                  </FindDeliveryResultItemRside>
                </FindDeliveryResultItem>
              </FindDeliveryResultItems>
            </FindDeliveryResultContainer>
          )}
        </DashboardFields>
      </DashboardInner>
    </>
  );
};

export default FindDeliveryResult;
