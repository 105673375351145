import React from 'react';

export const IconItem = () => (
  <svg
    width='21'
    height='26'
    viewBox='0 0 21 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.08113 16.9543H8.12242L6.70639 24.725C6.66293 24.9635 6.70088 25.2096 6.81415 25.424C6.92741 25.6383 7.10942 25.8083 7.33094 25.9068C7.55246 26.0053 7.80064 26.0264 8.03563 25.9669C8.27062 25.9073 8.47878 25.7706 8.62669 25.5785L20.0178 10.7864C20.1411 10.6264 20.217 10.4351 20.2371 10.2342C20.2572 10.0332 20.2206 9.83066 20.1314 9.64945C20.0423 9.46824 19.9041 9.31562 19.7327 9.20891C19.5612 9.10219 19.3633 9.04564 19.1613 9.04566H12.1201L13.5361 1.27502C13.5796 1.03653 13.5416 0.790358 13.4283 0.576029C13.3151 0.361701 13.1331 0.191654 12.9116 0.0931952C12.69 -0.00526342 12.4419 -0.0264206 12.2069 0.0331212C11.9719 0.092663 11.7637 0.229449 11.6158 0.421513L0.224674 15.2136C0.101419 15.3736 0.0254431 15.5649 0.00535922 15.7658C-0.0147246 15.9668 0.0218866 16.1693 0.111043 16.3506C0.200199 16.5318 0.338335 16.6844 0.509793 16.7911C0.681252 16.8978 0.879176 16.9544 1.08113 16.9543ZM10.6099 5.27182L9.76014 9.93292C9.73176 10.0888 9.73797 10.2489 9.77832 10.4021C9.81868 10.5553 9.8922 10.6977 9.99369 10.8194C10.0952 10.941 10.2221 11.0388 10.3656 11.106C10.5091 11.1731 10.6656 11.2079 10.824 11.2079H16.964L9.63262 20.7282L10.4823 16.0671C10.5107 15.9112 10.5045 15.7511 10.4642 15.5979C10.4238 15.4447 10.3503 15.3023 10.2488 15.1806C10.1473 15.059 10.0203 14.9612 9.87686 14.894C9.73339 14.8269 9.57691 14.7921 9.41851 14.7921H3.27845L10.6099 5.27182Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconItem;
