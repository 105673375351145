import {takeEvery, put, select} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  INSIGHTS_GROWTH_GET_INFO,
  INSIGHTS_GROWTH_GET_INFO_SUCCESS,
  INSIGHTS_GROWTH_POST_CONTACT_US,
  INSIGHTS_GROWTH_SET_LOADING
} from 'store/insights/growth/action';
import {
  getInsightsGrowthInfo,
  postContactUsForm
} from 'helpers/services/api/insights';
import sendGA from 'helpers/services/utils/ga';

function* getInsightsGrowsInfo() {
  try {
    sendGA('Insights Growth', 'Insights Growth', null, null);
    const locationId = yield select((state) => state.user.locationId);
    const {data} = yield getInsightsGrowthInfo(locationId);
    if (data) {
      yield put(INSIGHTS_GROWTH_GET_INFO_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(INSIGHTS_GROWTH_SET_LOADING(false));
  }
}

function* postContactUs({payload}) {
  try {
    const {status} = yield postContactUsForm(payload);
    if (status === 200) {
      sendGA(
        `Insights ${payload.insightsType}`,
        'Contact us submitted',
        `${payload.name}`,
        null
      );
      toast.success('Form was successfully submitted');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* insightsGrowsSaga() {
  yield takeEvery(INSIGHTS_GROWTH_GET_INFO, getInsightsGrowsInfo);
  yield takeEvery(INSIGHTS_GROWTH_POST_CONTACT_US, postContactUs);
}

export default insightsGrowsSaga;
