import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {isMobileOnly} from 'react-device-detect';
import {
  DialogAction,
  DialogBody,
  DialogImageCenter
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import ImageMenuEditing from '../../../../assets/img-menu-editor-mobile.png';
import {DialogSubTitle} from '../../../../components/DialogSubTitle/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogClose from '../../../../components/DialogClose/styles';

const MenuEditingOnMobileModal = () => {
  const [open, setOpen] = React.useState(true);
  const onCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open={isMobileOnly && open} onClose={onCloseDialog} scroll='body'>
      <DialogClose onClick={onCloseDialog} />
      <DialogContainer className='DialogXs'>
        <DialogTitle className='DialogTitleMd'>
          <span>
            Menu editing is <br />a serious matter.
          </span>
        </DialogTitle>
        <DialogSubTitle className='textMd'>
          To make editing your menu as easy and error-free as possible,
          recommend to <span>use a tablet or larger screen.</span>
        </DialogSubTitle>
        <DialogBody>
          <DialogImageCenter src={ImageMenuEditing} />
          <DialogAction>
            <ButtonPrimary
              className='ButtonMenuEditing'
              onClick={onCloseDialog}
            >
              <span>Ok</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default MenuEditingOnMobileModal;
