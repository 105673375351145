import React from 'react';

const IconSidebarDiscounts = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_9864_20812)'>
      <path
        d='M6.99997 9.95368C7.25871 9.95368 7.46846 9.74394 7.46846 9.4852C7.46846 9.22647 7.25871 9.01672 6.99997 9.01672C6.74124 9.01672 6.53149 9.22647 6.53149 9.4852C6.53149 9.74394 6.74124 9.95368 6.99997 9.95368Z'
        fill='#929CB9'
      />
      <path
        d='M6.99997 4.98323C7.25871 4.98323 7.46846 4.77348 7.46846 4.51474C7.46846 4.25601 7.25871 4.04626 6.99997 4.04626C6.74124 4.04626 6.53149 4.25601 6.53149 4.51474C6.53149 4.77348 6.74124 4.98323 6.99997 4.98323Z'
        fill='#929CB9'
      />
      <path
        d='M13.6226 0.377754C13.3793 0.134148 13.0557 0 12.7112 0H7.80333C7.45905 0 7.13535 0.134094 6.89188 0.37759L0.377645 6.89183C0.134094 7.13535 0 7.45902 0 7.80322C0 8.14748 0.134148 8.47115 0.377645 8.71448L5.28541 13.6223C5.52882 13.8659 5.85249 14 6.19675 14C6.54098 14 6.86465 13.8659 7.10817 13.6224L13.6224 7.10812C13.8659 6.86465 14 6.54095 14 6.19667V1.28879C14 0.944344 13.8659 0.620648 13.6226 0.377754ZM7.00003 3.22593C7.71066 3.22593 8.28882 3.80409 8.28882 4.51473C8.28882 5.22536 7.71066 5.80352 7.00003 5.80352C6.28939 5.80352 5.71123 5.22536 5.71123 4.51473C5.71123 3.80409 6.28939 3.22593 7.00003 3.22593ZM7.00003 10.774C6.28939 10.774 5.71123 10.1959 5.71123 9.48522C5.71123 8.77458 6.28939 8.19643 7.00003 8.19643C7.71066 8.19643 8.28882 8.77458 8.28882 9.48522C8.28882 10.1959 7.71066 10.774 7.00003 10.774ZM9.63594 7.41013H4.36412C4.1376 7.41013 3.95396 7.22649 3.95396 6.99997C3.95396 6.77346 4.1376 6.58982 4.36412 6.58982H9.63594C9.86245 6.58982 10.0461 6.77346 10.0461 6.99997C10.0461 7.22649 9.86248 7.41013 9.63594 7.41013ZM11.3932 3.01692C11.1668 3.01692 10.9831 2.83317 10.9831 2.60676C10.9831 2.38036 11.1668 2.19661 11.3932 2.19661C11.6196 2.19661 11.8034 2.38036 11.8034 2.60676C11.8034 2.83317 11.6196 3.01692 11.3932 3.01692Z'
        fill='#929CB9'
      />
    </g>
    <defs>
      <clipPath id='clip0_9864_20812'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconSidebarDiscounts;
