const normalizePercentageByDecimal = (input = []) => {
  const rounded = input.map((x, i) => ({
    number: Math.floor(x),
    decimal: x % 1,
    index: i
  }));

  const decimalSorted = [...rounded].sort((a, b) => b.decimal - a.decimal);

  const sum = rounded.reduce((pre, curr) => pre + curr.number, 0);
  const error = 100 - sum;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < error; i++) {
    const element = decimalSorted[i];
    if (element?.number) {
      // eslint-disable-next-line no-plusplus
      element.number++;
    }
  }

  const result = [...decimalSorted].sort((a, b) => a.index - b.index);

  return result.map((x) => x.number);
};

export default normalizePercentageByDecimal;
