import {createReducer} from 'redux-act';
import {
  SAVE_STEP_DATA,
  GET_SETUP_USER_ACCOUNT,
  GET_SETUP_USER_ACCOUNT_SUCCESS,
  SETUP_USER_ACCOUNT_MODAL,
  SET_FINAL_SETUP,
  GET_COUNTRIES_SUCCESS,
  SET_RESTAURANT_URL,
  GET_SHORT_LINK_SUCCESS,
  CONTROL_MODALS,
  CONTROL_SIDEBAR_PROGRESS,
  SAVE_UBER_EATS_STORES_ONBOARDING,
  CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
  UBER_EATS_SELECT_STORE_ONBOARDING,
  UBER_EATS_ONBOARDING_SAVE_MENUS,
  UBER_EATS_ONBOARDING_MENUS_LOADING,
  UBER_EATS_ONBOARDING_SAVE_MENU,
  SAVE_STEP_DATA_FOR_REQUEST
} from './action';

const initialState = () => ({
  loading: false,
  finalSetup: false,
  dataReceived: null,
  pendingDataSetuped: false,
  isWelcomeModalOpen: false,
  RestaurantURL: '',
  type: 'menu',
  sidebarProgress: '',
  step: 0,
  isOldSalesSetup: false,
  userId: '',
  countries: [],
  isUberEatsConnected: false,
  uberEatsStoresOnboarding: [],
  uberEatsStoreSelectModalOpen: false,
  uberEatsStoreMenusOnboarding: [],
  uberEatsMenusLoading: false,
  PendingData: [
    {
      step: 0,
      userId: '',
      User: {},
      Merchant: {},
      Location: {}
    },
    {
      step: 1,
      userId: '',
      MenuShowUrlInput: true,
      MenuShowUploadFile: false,
      MenuUseUrl: '',
      MenuUploadFile: '',
      MenuBuildFromScratch: false,
      ImagesShowUrlInput: true,
      ImagesUseUrl: '',
      ImagesShowUploadFile: false,
      ImagesUploadFile: '',
      WithoutImages: false,
      platformSelect: 0,
      MenuUberEats: false,
      MenuEmail: false,
      lowerAllPrices: true,
      roundingValue: 'To00',
      inflationType: '%',
      inflationInputAmount: 2,
      inflationInputPercentage: 10,
      lowerAllPricesURL: true,
      roundingValueURL: 'To00',
      inflationTypeURL: '%',
      inflationInputAmountURL: 2,
      inflationInputPercentageURL: 10,
      lowerAllPricesEmail: false,
      roundingValueEmail: 'To00',
      inflationTypeEmail: '%',
      inflationInputAmountEmail: 2,
      inflationInputPercentageEmail: 10,
      uberEatsSelectedMenu: '',
      uberEatsSelectedStoreId: '',
      UEDeliveryCommission: null,
      UEPickupCommission: null,
      menuImagesCheckedURL: true,
      menuImagesCheckedEmail: false,
      urlMenuImagesURL: false,
      urlMenuImagesURLValue: '',
      urlMenuImagesEmail: false,
      emailMenuImagesURL: false,
      emailMenuImagesURLValue: '',
      emailMenuImagesEmail: false,
      OtterPriceSourceLink: '',
      OtterUsername: '',
      OtterPassword: '',
      OrdermarkType: 'Credentials',
      OrdermarkUsername: '',
      OrdermarkPassword: '',
      OrdermarkPriceSourceLink: '',
      OrdermarkLocationId: ''
    }
  ],
  PendingDataForRequest: null
});

const setupRestaurantReducer = createReducer(
  {
    [SETUP_USER_ACCOUNT_MODAL]: (state, {open}) => ({
      ...state,
      isModalOpen: open
    }),
    [SAVE_STEP_DATA]: (state, payload) => {
      const PendingData = [
        ...state.PendingData.filter((item) => item.step !== payload.step),
        payload.data
      ].sort((a, b) => a.step - b.step);

      // const PendingData = [...state.PendingData];

      // const index = PendingData.findIndex((item) => item.step === payload.step);
      // PendingData.splice(
      //   index !== -1 ? index : payload.step,
      //   PendingData.length + 1,
      //   payload.data
      // );
      return {
        ...state,
        PendingData,
        userId: payload.userId,
        step: payload.step,
        isOldSalesSetup: payload.isOldSalesSetup
      };
    },
    [SAVE_STEP_DATA_FOR_REQUEST]: (state, payload) => {
      const PendingData = [
        ...state.PendingData.filter((item) => item.step !== payload.step),
        payload.data
      ].sort((a, b) => a.step - b.step);

      // const PendingData = [...state.PendingData];

      // const index = PendingData.findIndex((item) => item.step === payload.step);
      // PendingData.splice(
      //   index !== -1 ? index : payload.step,
      //   PendingData.length + 1,
      //   payload.data
      // );
      return {
        ...state,
        PendingDataForRequest: PendingData
      };
    },
    [GET_SHORT_LINK_SUCCESS]: (state, payload) => {
      return {
        ...state,
        shortLink: payload?.shortLink
      };
    },

    [GET_SETUP_USER_ACCOUNT]: (state) => ({
      ...state,
      loading: true
    }),
    [SET_FINAL_SETUP]: (state, payload) => ({
      ...state,
      finalSetup: payload
    }),
    [GET_SETUP_USER_ACCOUNT_SUCCESS]: (
      state,
      {pendingDataSetuped, ...info}
    ) => {
      return {
        ...state,
        step: info.step || 0,
        loading: false,
        // dataReceived: state.ueStatusReceived,
        dataReceived: true,
        pendingDataSetuped,
        // userAccountReceived: true,
        ...info
      };
    },
    [GET_COUNTRIES_SUCCESS]: (state, payload) => ({
      ...state,
      countries: payload
    }),
    [SET_RESTAURANT_URL]: (state, payload) => ({
      ...state,
      RestaurantURL: payload
    }),
    [CONTROL_MODALS]: (state, {open, field}) => ({
      ...state,
      [field]: open
    }),
    [CONTROL_SIDEBAR_PROGRESS]: (state, payload) => ({
      ...state,
      sidebarProgress: payload
    }),

    [SAVE_UBER_EATS_STORES_ONBOARDING]: (state, payload) => {
      return {
        ...state,
        uberEatsStoresOnboarding: payload
      };
    },

    // [CHANGE_UBER_EATS_CONNECTION_STATUS_ONBOARDING]: (state, payload) => {
    //   return {
    //     ...state,
    //     isUberEatsConnected: payload,
    //     ueStatusReceived: true,
    //     dataReceived: state.userAccountReceived
    //   };
    // },

    [UBER_EATS_SELECT_STORE_ONBOARDING]: (state, payload) => {
      const {PendingData} = state;
      if (PendingData.length > 1 && PendingData[1].step === 1) {
        PendingData[1].uberEatsSelectedStoreId = payload;
        PendingData[1].uberEatsSelectedMenu = '';
      } else {
        PendingData.push({step: 1, uberEatsSelectedStoreId: payload});
      }
      return {
        ...state,
        PendingData,
        uberEatsStoreMenusOnboarding: []
      };
    },

    [UBER_EATS_ONBOARDING_SAVE_MENUS]: (state, payload) => {
      return {
        ...state,
        uberEatsStoreMenusOnboarding: payload
      };
    },

    [UBER_EATS_ONBOARDING_MENUS_LOADING]: (state, payload) => {
      return {
        ...state,
        uberEatsMenusLoading: payload
      };
    },

    [UBER_EATS_ONBOARDING_SAVE_MENU]: (state, payload) => {
      const {PendingData} = state;
      if (PendingData.length > 1 && PendingData[1].step === 1) {
        PendingData[1].uberEatsSelectedMenu = payload;
      } else {
        PendingData.push({step: 1, uberEatsSelectedMenu: payload});
      }

      return {
        ...state,
        PendingData
      };
    }
  },
  initialState()
);

export default setupRestaurantReducer;
