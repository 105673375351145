import {put, takeEvery, select} from 'redux-saga/effects';
import {
  getOrderRefundData,
  updateOrderRefund
} from 'helpers/services/api/orders';
import {
  CHANGE_STATUS_CONFIRMATION_MODAL,
  CHANGE_STATUS_CREATE_MODAL,
  GET_DATA_FOR_REFUND,
  GET_DATA_FOR_REFUND_SUCCESS,
  SET_REFUND_LOADING,
  UPDATE_REFUND_DATA
} from 'store/orders/orderRefund/action';
import {CHANGE_REFUNDS_HISTORY} from 'store/orders/orderList/action';
import {CHANGE_REFUNDS_ACTIVE} from 'store/global/orders/action';
import {ORDER_UNMOUNT} from 'store/orders/orderDetails/action';

export function* updateRefund({payload: {data, orderType}}) {
  try {
    const {invoiceId} = yield select(
      (state) => state.refundReducer.dataForRefund
    );
    const locationId = yield select((state) => state.user.locationId);

    const refunds = yield updateOrderRefund(invoiceId, {...data, locationId});
    if (orderType === 'history') {
      yield put(CHANGE_REFUNDS_HISTORY(refunds?.data));
    } else {
      yield put(CHANGE_REFUNDS_ACTIVE(refunds?.data));
    }
    if (refunds) {
      yield put(CHANGE_STATUS_CREATE_MODAL(false));
      yield put(CHANGE_STATUS_CONFIRMATION_MODAL(true));
      yield put(SET_REFUND_LOADING(false));
    }
  } catch (error) {
    yield put(CHANGE_STATUS_CREATE_MODAL(false));
    yield put(SET_REFUND_LOADING(false));
    yield put(CHANGE_STATUS_CONFIRMATION_MODAL(true));
    yield put(ORDER_UNMOUNT());
    console.log('SAGA ERROR', error);
  }
}

function* getRefundData({payload: {invoiceId}}) {
  try {
    const {data} = yield getOrderRefundData(invoiceId);
    if (data) {
      yield put(GET_DATA_FOR_REFUND_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* orderRefundSaga() {
  yield takeEvery(UPDATE_REFUND_DATA, updateRefund);
  yield takeEvery(GET_DATA_FOR_REFUND, getRefundData);
}

export default orderRefundSaga;
