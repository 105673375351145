/* eslint-disable */
import React, {useEffect} from 'react';
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import MenuLoader from './MenuLoader';
import HeadLine from './HeadLine';
import SearchBlock from './SearchBlock';
import MenuTabs from './MenuTabs';
import ModalViewer from './ModalViewer';
import CategoriesContainer from './Categories';
import ItemsContainer from './Items';
import ModifierGroupsContainer from './ModifierGroups';
import ModifiersContainer from './Modifiers';
import OutOfStockContainer from './OutOfStock/OutOfStockContainer';
import HiddenContainer from './Hidden/HiddenContainer';
import {DialogWrapper} from '../dashboard/styles';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import Loading from '../../../components/shared/Utils/Loading';
import {CLEAR_MENU_DATA} from '../../../store/menuEditor/action';

const MenuEditor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {url, path} = useRouteMatch();

  const isMenuReady = useSelector(
    (state) => state.menuEditorReducer.isMenuReady
  );
  const isMenuLoading = useSelector(
    (state) => state.menuEditorReducer.isMenuLoading
  );

  useEffect(() => {
    if (location.pathname === '/menu-editor') {
      history.push(`${url}/categories`);
    }

    return () => dispatch(CLEAR_MENU_DATA());
  }, []);

  return (
    <DashboardContent className='DashboardMenuEditor DashboardContent'>
      <MenuLoader />
      {isMenuLoading && <Loading />}
      {isMenuReady && (
        <>
          <HeadLine />
          <div>
            <MenuTabs />
            <SearchBlock />
            <Switch>
              <Route
                path={`${path}/categories`}
                component={CategoriesContainer}
              />
              <Route path={`${path}/items`} component={ItemsContainer} />
              <Route
                path={`${path}/groups`}
                component={ModifierGroupsContainer}
              />
              <Route
                path={`${path}/modifiers`}
                component={ModifiersContainer}
              />
              <Route
                path={`${path}/out-of-stock`}
                component={OutOfStockContainer}
              />
              <Route path={`${path}/hidden`} component={HiddenContainer} />
              <Redirect from='/' to={`${path}/categories`} />
            </Switch>
          </div>
          <ModalViewer />
          <DialogWrapper />
        </>
      )}
    </DashboardContent>
  );
};

export default MenuEditor;
