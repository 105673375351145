import styled from 'styled-components';

const Caption4 = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2f3d48;
  @media (max-width: 599px) {
    font-size: 14px;
  }

  &.positionRelative {
    position: relative;
  }
  &.smMarginB {
    margin-bottom: 13px;
  }
`;

export default Caption4;
