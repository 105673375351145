import React, {memo} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {getModalsStack} from '../../../../../store/discounts/selectors';
import {DISCOUNT_MODALS} from '../../../../../helpers/constants/discounts';
import {
  PUSH_MODALS_STACK,
  SET_DISCOUNT,
  SHIFT_MODALS_STACK
} from '../../../../../store/discounts/action';
import ModalTurnOn from './ModalTurnOn';
import ModalSelectDiscount from './ModalSelectDiscount';
import ModalNewDiscount from './ModalNewDiscount';
import ModalConfirm from './ModalConfirm';
import DiscountSchema from '../../../../../helpers/services/formValidationSchemas/DiscountSchema';
import {getDiscountName} from '../../helpers';
import sendGtmEvent from '../../../../../helpers/services/utils/gtm';

const Modals = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const modalsStack = getModalsStack();

  const formik = useFormik({
    initialValues: {
      reason: '',
      Discount: '',
      DiscountType: null,
      Name: 'Name of Discount',
      MinOrder: '',
      CouponCode: '',
      StartTime: null,
      EndTime: null,
      ShowOnStorefront: false,
      EmailMarketing: false
    },
    validationSchema: DiscountSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formattedName = {...values, Name: getDiscountName(values).trim()};
      dispatch(SHIFT_MODALS_STACK());
      dispatch(SET_DISCOUNT(formattedName));
      history.push('/discounts/wizard');
    }
  });

  const onCloseAction = () => {
    formik.resetForm();
    dispatch(SHIFT_MODALS_STACK());
  };

  const handleChangeField = (field, value) =>
    formik.setFieldValue(field, value);

  const getValue = (field) => formik.values[field];

  const getError = (field) => formik.errors[field];

  const getTouched = (field) => formik.touched[field];

  const displayModal = (modals) => {
    switch (modals[0].type) {
      case DISCOUNT_MODALS.NEW:
        return (
          <ModalNewDiscount
            onCloseAction={onCloseAction}
            submitAction={(e, data) => {
              e.stopPropagation();
              sendGtmEvent('applyDiscountMethodButton', {
                method: data
              });
              formik.handleSubmit();
              dispatch(SHIFT_MODALS_STACK());
              dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS.SELECT}));
            }}
          />
        );
      case DISCOUNT_MODALS.SELECT:
        return (
          <ModalSelectDiscount
            handleChange={handleChangeField}
            getValue={getValue}
            getError={getError}
            getTouched={getTouched}
            onCloseAction={onCloseAction}
            submitAction={(e) => {
              e.stopPropagation();
              if (
                getValue('DiscountType') === 0 &&
                getValue('Discount') >= getValue('MinOrder')
              ) {
                formik.setFieldError(
                  'MinOrder',
                  'Discount cannot be bigger than min order!'
                );
                return;
              }
              sendGtmEvent('DiscountAmountSelectedButton', {
                minOrder: getValue('MinOrder'),
                percentage:
                  getValue('DiscountType') === 1 ? getValue('Discount') : '',
                amount:
                  getValue('DiscountType') !== 1 ? getValue('Discount') : ''
              });
              formik.handleSubmit();
            }}
          />
        );
      case DISCOUNT_MODALS.TURN_ON:
        return (
          <ModalTurnOn
            handleChange={handleChangeField}
            getValue={getValue}
            onCloseAction={onCloseAction}
            submitAction={(e) => {
              e.stopPropagation();
              sendGtmEvent('applyDiscountReasonButton', {
                reason: getValue('reason')
              });
              dispatch(SHIFT_MODALS_STACK());
              dispatch(PUSH_MODALS_STACK({type: DISCOUNT_MODALS.NEW}));
            }}
          />
        );
      case DISCOUNT_MODALS.DELETE:
        return (
          <ModalConfirm
            onCloseAction={onCloseAction}
            actionType='Delete'
            discount={modals[0]?.discount}
          />
        );
      case DISCOUNT_MODALS.DISABLE:
        return (
          <ModalConfirm
            onCloseAction={onCloseAction}
            actionType='Disable'
            discount={modals[0]?.discount}
          />
        );

      default:
        return null;
    }
  };

  if (!modalsStack.length) {
    return null;
  }
  return displayModal(modalsStack);
};

export default memo(Modals);
