import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import cn from 'classnames';

// import {DELETE_USER} from '../../../../store/usersManagement/action';
import {DialogAction, DialogBody} from '../../../../../dashboard/styles';
import DialogTitle from '../../../../../../../components/DialogTitle/styles';
import DialogClose from '../../../../../../../components/DialogClose/styles';
import DialogContainer from '../../../../../../../components/DialogContainer/styles';
import ButtonPrimary from '../../../../../../../components/buttons/ButtonPrimary/styles';
import {Caption2} from '../../../../../../../components/typography';

const EmailConfirmPopup = ({open, onClose}) => {
  // const dispatch = useDispatch();
  //
  // const ACTION = (mail) => {
  //   console.log(mail);
  // };
  //
  const handleSubmit = () => {
    // dispatch(ACTION(modalModel));
    // ACTION(email);
    onClose();
  };

  return (
    <Dialog
      style={{background: 'rgba(47,61,72,0.7)'}}
      open={open}
      onClose={onClose}
    >
      <DialogContainer className='DialogSm'>
        <DialogTitle>Email sent successfully</DialogTitle>
        <Caption2
          style={{margin: '25px 0 50px', textAlign: 'center', fontSize: '15px'}}
        >
          Reply to this email with your menu in a PDF format. If you cannot find
          it in your inbox, check your spam folder.{' '}
        </Caption2>

        <DialogBody>
          <DialogAction className='marginTop60'>
            <ButtonPrimary onClick={handleSubmit}>
              <span>Got it</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

EmailConfirmPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

EmailConfirmPopup.defaultProps = {
  open: false,
  onClose: () => {}
};

export default EmailConfirmPopup;
