/* eslint-disable */
export function getOuterJoin(obj1, obj2) {
  Object.keys(obj2).map((key) => {
    if (obj1.hasOwnProperty(key)) {
      delete obj1[key];
      delete obj2[key];
    }
  });

  return {...obj1, ...obj2};
}
