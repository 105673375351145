import {createReducer} from 'redux-act';
import {
  DELETE_MODIFIER,
  DELETE_MODIFIER_SUCCESS,
  GET_ALL_MODIFIER,
  GET_ALL_MODIFIER_SUCCESS,
  GET_MODIFIER_BY_ID,
  GET_MODIFIER_BY_ID_SUCCESS,
  PATCH_MODIFIER,
  PATCH_MODIFIER_SUCCESS,
  POST_MODIFIER,
  POST_MODIFIER_SUCCESS,
  UPDATE_MODIFIER,
  UPDATE_MODIFIER_SUCCESS
} from './action';

const initialState = () => ({
  modifierByIdData: {},
  modifierByIdLoading: false,
  allModifiersData: [],
  allModifiersPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  allModifiersLoading: false,
  outOfStock: 0,
  uploadFileLoading: false,
  rerenderModifiers: false
});

const menuEditorModifierReducer = createReducer(
  {
    [GET_MODIFIER_BY_ID]: (state) => ({
      ...state,
      modifierByIdLoading: true
    }),
    [GET_MODIFIER_BY_ID_SUCCESS]: (state, payload) => ({
      ...state,
      modifierByIdLoading: false,
      modifierByIdData: payload
    }),

    [PATCH_MODIFIER]: (state) => ({
      ...state
    }),
    [PATCH_MODIFIER_SUCCESS]: (state) => ({
      ...state,
      rerenderModifiers: !state.rerenderModifiers
    }),

    [GET_ALL_MODIFIER]: (state) => ({
      ...state,
      allModifiersLoading: true
    }),
    [GET_ALL_MODIFIER_SUCCESS]: (state, payload) => ({
      ...state,
      allModifiersData: payload.docs,
      allModifiersLoading: false,
      allModifiersPagination: {
        pages: payload.totalPages,
        page: payload.page,
        limit: payload.limit,
        total: payload.totalDocs,
        sort: 'desc',
        sortBy: 'Name'
      }
    }),

    [POST_MODIFIER]: (state) => ({
      ...state
    }),
    [POST_MODIFIER_SUCCESS]: (state) => ({
      ...state,
      rerenderModifiers: !state.rerenderModifiers
    }),
    [UPDATE_MODIFIER]: (state) => ({
      ...state
    }),
    [UPDATE_MODIFIER_SUCCESS]: (state) => ({
      ...state,
      rerenderModifiers: !state.rerenderModifiers
    }),
    [DELETE_MODIFIER]: (state) => ({
      ...state
    }),
    [DELETE_MODIFIER_SUCCESS]: (state) => ({
      ...state,
      rerenderModifiers: !state.rerenderModifiers
    })
  },
  initialState()
);

export default menuEditorModifierReducer;
