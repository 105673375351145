import React from 'react';
import T from 'prop-types';
import {TextField} from '@material-ui/core';
import {StaticDatePicker} from '@material-ui/pickers';
import DatepickerContainer from './styles';

/* eslint-disable */

const SingleDatepicker = ({onChange, value, maxDate}) => {
  return (
    <DatepickerContainer>
      <StaticDatePicker
        value={value}
        disablePast
        minDate={new Date()}
        maxDate={
          maxDate || new Date().setFullYear(new Date().getFullYear() + 10)
        }
        onChange={onChange}
        renderInput={(props) => <TextField {...props} />}
      />
    </DatepickerContainer>
  );
};

SingleDatepicker.propTypes = {
  onChange: T.func,
  value: T.array
};

SingleDatepicker.defaultProps = {
  onChange: () => {}
};

export default SingleDatepicker;
