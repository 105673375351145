/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import ProgressBar from '@ramonak/react-progress-bar';
import {PublishProgressContainer} from '../styles';
import {
  SET_FINISH_SPEED,
  SET_NEW_TASK_ADDED,
  SHOW_PUBLISH_PROGRESSBAR,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../store/global/globalNotifications/action';
import useInterval from '../../../hooks/useInterval';

const PublishProgressBar = () => {
  const dispatch = useDispatch();
  const publishStatus = useSelector(
    (state) => state.globalNotificationsReducer.publishStatus
  );
  const botIsBusy = useSelector(
    (state) => state.globalNotificationsReducer.botIsBusy
  );
  const newTaskAdded = useSelector(
    (state) => state.globalNotificationsReducer.newTaskAdded
  );
  const finishSpeed = useSelector(
    (state) => state.globalNotificationsReducer.finishSpeed
  );

  const [running, setRunning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [newStartProgress, setNewStartProgress] = useState(0);
  const [jobProgress, setJobProgress] = useState(0);
  const [delaySpeed, setDelaySpeed] = useState(0);
  const [progressStop, setProgressStop] = useState(false);

  useEffect(() => {
    setRunning(true);
  }, []);

  const stopProgressAndShowSuccessMessage = () => {
    setTimeout(() => {
      setProgressStop(true);
    }, 10);
    setTimeout(() => {
      dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
      dispatch(STOP_GET_PUBLISH_STATUS_JOB(true));
    }, 550);

    setTimeout(() => {
      toast.success('Well done. Your changes are LIVE', {
        autoClose: 2000
      });
    }, 800);
  };

  const startTime = 5000;
  const jobTime = 70000;
  const doneTime = 5000;
  const botBusyTime = 80000;

  const fullStep = 100;
  const startStep = 10;
  const botBusyStep = 55;
  const jobMaxStep = 85;
  const jobStep = jobMaxStep - startStep;

  useInterval(() => {
    if (running) {
      if (jobProgress < 10) {
        setDelaySpeed(Math.trunc(startTime / startStep));
      } else if (botIsBusy && jobProgress > 9 && jobProgress < 55) {
        setDelaySpeed(Math.trunc(botBusyTime / (fullStep - botBusyStep)));
      } else if (
        !botIsBusy &&
        publishStatus?.length &&
        jobProgress > 9 &&
        jobProgress < 85
      ) {
        setDelaySpeed(Math.trunc(jobTime / jobStep));
      } else if (
        !finishSpeed &&
        !publishStatus?.length &&
        publishStatus !== null
      ) {
        dispatch(SET_FINISH_SPEED(true));
        setDelaySpeed(Math.trunc(doneTime / (fullStep - progress)));
      }
      if (
        (botIsBusy && jobProgress < 55) ||
        (!botIsBusy && jobProgress < 85) ||
        (!publishStatus?.length && jobProgress <= 99)
      ) {
        setJobProgress(jobProgress + 1);
        setProgress(
          newStartProgress +
            Math.floor(((100 - newStartProgress) / 100) * jobProgress + 1)
        );
      }
      if (newTaskAdded) {
        dispatch(SET_NEW_TASK_ADDED(false));
        dispatch(SET_FINISH_SPEED(false));
        setNewStartProgress(progress);
        setJobProgress(0);
      }
      if (progress > 99) {
        setRunning(false);
        stopProgressAndShowSuccessMessage();
      }
    }
  }, delaySpeed);

  return (
    <PublishProgressContainer
      className={cn('', {
        animateStop: progressStop
      })}
    >
      <ProgressBar
        className='ProgressBar'
        barContainerClassName='ProgressBarContainer'
        bgColor='#FCA600'
        height='60px'
        labelClassName='publishLabel'
        completed={progress}
        maxCompleted={100}
        transitionDuration={`${Math.trunc(delaySpeed % 60000) / 1000}s`}
        transitionTimingFunction='linear'
      />
    </PublishProgressContainer>
  );
};

PublishProgressBar.propTypes = {};

PublishProgressBar.defaultProps = {};

export default PublishProgressBar;
