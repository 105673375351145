import Dialog from '@material-ui/core/Dialog';
import T from 'prop-types';
import React from 'react';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {DialogBody} from 'routes/App/dashboard/styles';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';

const RemoveOrderModal = ({isOpen, message, onCancel, onOk}) => {
  return (
    <Dialog open={isOpen}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom verticalCenter'>
          {message}
        </DialogTitle>
        <DialogBody>
          <DialogActions>
            <DialogAction className='DialogActions w50'>
              <ButtonOutline onClick={onCancel}>
                <span>No</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='DialogActions w50'>
              <ButtonPrimary onClick={onOk}>
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

RemoveOrderModal.propTypes = {
  isOpen: T.bool.isRequired,
  message: T.string.isRequired,
  onCancel: T.func.isRequired,
  onOk: T.func.isRequired
};
export default RemoveOrderModal;
