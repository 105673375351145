import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import {
  MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE,
  MARKETING_INTEGRATIONS_DISABLE,
  MARKETING_INTEGRATIONS_ENABLE
} from 'store/marketingIntegrations/action';
import LinkBack from 'components/LinkBack/styles';
import {TopLineNav} from 'components/layout';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardInner} from 'components/DashboardInner/styles';
import {
  MarketingIntegrationsDetailsDisconnectBlock,
  MarketingIntegrationsDetailsFields,
  MarketingIntegrationsDetailsImportantBlock,
  MarketingIntegrationsDetailsOl,
  MarketingIntegrationsDetailsSubTitle,
  MarketingIntegrationsDetailsTitle,
  MarketingIntegrationsDetailsUl
} from 'routes/App/MarketingIntegrations/DetailsPage/styles';
import {
  DashboardField,
  FieldInput,
  FieldLabel
} from 'routes/App/dashboard/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import MarketingIntegrationsSchema from 'helpers/services/formValidationSchemas/MarketingIntegrationsSchema';
import sendGA from 'helpers/services/utils/ga';

const MarketingIntegrationsDetailsPage = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {googleTagId, metaPixelId, showKey} = useSelector(
    (state) => state.marketingIntegrationsReducer
  );

  const history = useHistory();
  const initValues = {
    googleTagId,
    metaPixelId,
    showKey
  };

  const redirectToMainPage = () => {
    history.push('/marketing-integrations');
  };

  useEffect(() => {
    if (!showKey) {
      redirectToMainPage();
    }
    return () => {
      dispatch(MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE());
    };
  }, [showKey]);
  return (
    <>
      <DashboardContent>
        <TopLineNav>
          <LinkBack
            className='DarkColor'
            onClick={() => {
              dispatch(MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE());
              redirectToMainPage();
            }}
          >
            Back to Marketing Integrations
          </LinkBack>
        </TopLineNav>
        <Formik
          initialValues={initValues}
          innerRef={formikRef}
          validationSchema={MarketingIntegrationsSchema}
          onSubmit={(values) => {
            let body;
            if (values.showKey === 'google-analytics') {
              body = {
                integration: values.showKey,
                data: {googleTagId: values.googleTagId}
              };
            } else if (values.showKey === 'meta-pixel') {
              body = {
                integration: values.showKey,
                data: {metaPixelId: values.metaPixelId}
              };
            }
            dispatch(
              MARKETING_INTEGRATIONS_ENABLE({
                body,
                postAction: redirectToMainPage
              })
            );
            sendGA(
              'Marketing Integrations',
              (googleTagId && showKey === 'google-analytics') ||
                (metaPixelId && showKey === 'meta-pixel')
                ? 'Edit Connection'
                : 'Create Connection',
              showKey === 'google-analytics'
                ? 'Google Analytics'
                : 'Meta Pixel',
              null
            );
          }}
        >
          {({values, errors, touched, handleChange, submitForm}) => (
            <DashboardInner className='DashboardInnerW50 PaddingInsightsPerformanceDatePicker withoutShadow IntegrationsDetails'>
              <MarketingIntegrationsDetailsTitle>
                Connecting{' '}
                {showKey === 'google-analytics'
                  ? 'Google Analytics'
                  : 'Meta Pixel'}
              </MarketingIntegrationsDetailsTitle>
              <MarketingIntegrationsDetailsSubTitle>
                How to connect
              </MarketingIntegrationsDetailsSubTitle>
              <MarketingIntegrationsDetailsOl>
                <li>
                  Follow{' '}
                  <a
                    href='https://support.getsauce.com/marketing-integrations'
                    rel='noreferrer'
                    target='_blank'
                  >
                    instructions
                  </a>{' '}
                  to{' '}
                  {showKey === 'google-analytics'
                    ? 'get Google Analytics Code G-XXXXXXXXX'
                    : 'set up a Meta pixel.'}
                </li>
                <li>
                  Paste your{' '}
                  {showKey === 'google-analytics'
                    ? 'Google tag ID'
                    : 'Meta Pixel ID'}
                </li>
              </MarketingIntegrationsDetailsOl>
              <MarketingIntegrationsDetailsFields>
                <DashboardField className='w80 paddingTBZero paddingL0'>
                  <FieldLabel className='FieldLabelLg'>
                    {showKey === 'google-analytics' ? 'Google tag' : 'Meta ID'}
                  </FieldLabel>
                  <FieldInput
                    className={
                      ((errors.googleTagId && touched.googleTagId) ||
                        (errors.metaPixelId && touched.metaPixelId)) &&
                      'error'
                    }
                    name={
                      showKey === 'google-analytics'
                        ? 'googleTagId'
                        : 'metaPixelId'
                    }
                    value={
                      showKey === 'google-analytics'
                        ? values.googleTagId
                        : values.metaPixelId
                    }
                    onChange={handleChange}
                    placeholder={
                      showKey === 'google-analytics'
                        ? 'G-XXXXXXXXX'
                        : 'XXXXXXXXX'
                    }
                  />
                  <ErrorSignWrapper leftPosition='0px' topPosition='3px'>
                    <ErrorSign>
                      {(touched.googleTagId && errors.googleTagId) ||
                        (touched.metaPixelId && errors.metaPixelId)}
                    </ErrorSign>
                  </ErrorSignWrapper>
                </DashboardField>
                <ButtonPrimary type='button' onClick={submitForm}>
                  <span>Save</span>
                </ButtonPrimary>
              </MarketingIntegrationsDetailsFields>
              <MarketingIntegrationsDetailsSubTitle>
                Important
              </MarketingIntegrationsDetailsSubTitle>
              {showKey === 'google-analytics' && (
                <MarketingIntegrationsDetailsUl>
                  <li>
                    It may take some time to see statistics in your Google
                    Analytics account.
                  </li>
                  <li>
                    <a
                      href='https://support.getsauce.com/marketing-integrations'
                      rel='noreferrer'
                      target='_blank'
                    >
                      Learn more
                    </a>{' '}
                    about events that Sauce is sending to Google Analytics
                  </li>
                </MarketingIntegrationsDetailsUl>
              )}
              {showKey === 'meta-pixel' && (
                <MarketingIntegrationsDetailsImportantBlock>
                  <a
                    href='https://support.getsauce.com/marketing-integrations'
                    rel='noreferrer'
                    target='_blank'
                  >
                    Learn more
                  </a>{' '}
                  about events that Sauce is sending to Meta Pixel
                </MarketingIntegrationsDetailsImportantBlock>
              )}
              {((googleTagId && showKey === 'google-analytics') ||
                (metaPixelId && showKey === 'meta-pixel')) && (
                <MarketingIntegrationsDetailsDisconnectBlock>
                  <div
                    onClick={() => {
                      dispatch(
                        MARKETING_INTEGRATIONS_DISABLE({
                          integration:
                            showKey === 'google-analytics'
                              ? 'google-analytics'
                              : 'meta-pixel',
                          postAction: redirectToMainPage
                        })
                      );
                      sendGA(
                        'Marketing Integrations',
                        'Disconnect',
                        showKey === 'google-analytics'
                          ? 'Google Analytics'
                          : 'Meta Pixel',
                        null
                      );
                    }}
                  >
                    Disconnect
                  </div>
                </MarketingIntegrationsDetailsDisconnectBlock>
              )}
            </DashboardInner>
          )}
        </Formik>
      </DashboardContent>
    </>
  );
};

export default MarketingIntegrationsDetailsPage;
