import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {useSelector} from 'react-redux';
import {
  DashboardSection,
  DashboardAccordions,
  AccordionItems,
  AccordionItem,
  AccordionItemName,
  AccordionItemBody
} from '../../dashboard/styles';
import TabPanel from '../../../../components/shared/TabPanel';
import Dgd from '../DgD';
import {
  ITEM_SCHEME,
  MODIFIER_SCHEME
} from '../../../../store/menuEditor/helper/MenuTree';
import ItemAccordionTitle from '../components/ItemAccordionTitle';
import ModifierAccordionTitle from '../components/ModifierAccordionTitle';

const OutOfStockView = (props) => {
  const selectedItems = useSelector(
    (store) => store.menuEditorReducer.selectedItems
  );
  const selectedModifiers = useSelector(
    (store) => store.menuEditorReducer.selectedModifiers
  );

  const {itemsData, modifiersData, expanded, handleAccordionChange} = props;

  return (
    <TabPanel>
      <DashboardSection className='Mt20'>
        <AccordionItems>
          <AccordionItem>
            <Accordion
              expanded={expanded.items === 'items'}
              onChange={() => handleAccordionChange('items')}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <ItemAccordionTitle itemsData={itemsData} />
                </div>
              </AccordionSummary>
              <AccordionItemBody>
                <DashboardAccordions>
                  {Boolean(itemsData.length) && (
                    <Dgd
                      withoutMainDrag
                      items={itemsData}
                      itemScheme={ITEM_SCHEME}
                      withBulkCheckbox
                      bulkSelectedMap={selectedItems}
                    />
                  )}
                </DashboardAccordions>
              </AccordionItemBody>
            </Accordion>
          </AccordionItem>

          <AccordionItem>
            <Accordion
              expanded={expanded.modifiers === 'modifiers'}
              onChange={() => handleAccordionChange('modifiers')}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <ModifierAccordionTitle modifiersData={modifiersData} />
              </AccordionSummary>
              <AccordionItemBody>
                <DashboardAccordions>
                  {Boolean(modifiersData.length) && (
                    <Dgd
                      withoutMainDrag
                      items={modifiersData}
                      itemScheme={MODIFIER_SCHEME}
                      withBulkCheckbox
                      bulkSelectedMap={selectedModifiers}
                    />
                  )}
                </DashboardAccordions>
              </AccordionItemBody>
            </Accordion>
          </AccordionItem>
        </AccordionItems>
      </DashboardSection>
    </TabPanel>
  );
};

OutOfStockView.propTypes = {
  modifiersData: PropTypes.array.isRequired,
  itemsData: PropTypes.array.isRequired,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  handleAccordionChange: PropTypes.func.isRequired
};

export default OutOfStockView;
