import {createReducer} from 'redux-act';
import {
  MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE,
  MARKETING_INTEGRATIONS_DISABLE_SUCCESS,
  MARKETING_INTEGRATIONS_GET_INFO,
  MARKETING_INTEGRATIONS_GET_INFO_SUCCESS,
  MARKETING_INTEGRATIONS_SET_LOADING,
  MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE
} from 'store/marketingIntegrations/action';

const initialState = () => ({
  loading: false,
  googleTagId: null,
  metaPixelId: null,
  showKey: null
});

const marketingIntegrationsReducer = createReducer(
  {
    [MARKETING_INTEGRATIONS_GET_INFO]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [MARKETING_INTEGRATIONS_GET_INFO_SUCCESS]: (state, payload) => {
      return {
        ...state,
        googleTagId: payload.googleAnalytics?.isEnabled
          ? payload.googleAnalytics?.googleTagId
          : null,
        metaPixelId: payload.metaPixel?.isEnabled
          ? payload.metaPixel?.metaPixelId
          : null,
        loading: false
      };
    },
    [MARKETING_INTEGRATIONS_SHOW_CURRENT_INTEGRATION_PAGE]: (
      state,
      payload
    ) => {
      return {
        ...state,
        showKey: payload
      };
    },
    [MARKETING_INTEGRATIONS_CLOSE_CURRENT_INTEGRATION_PAGE]: (state) => {
      return {
        ...state,
        showKey: null
      };
    },
    [MARKETING_INTEGRATIONS_DISABLE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        ...payload
      };
    },
    [MARKETING_INTEGRATIONS_SET_LOADING]: (state, payload) => {
      return {
        ...state,
        loading: payload
      };
    }
  },
  initialState()
);

export default marketingIntegrationsReducer;
