import React from 'react';
import T from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {DashboardInner} from 'components/DashboardInner/styles';
import Body5 from 'components/typography/Body5';
import Body0 from 'components/typography/Body0';
import {ButtonPrimary} from 'components/buttons';
import {
  FindDeliveryHeaderItem,
  FindDeliveryHeaderItems
} from 'routes/App/Managers/FindDelivery/styles';
import {
  FIND_DELIVERY,
  FIND_DELIVERY_STATUS_NAME
} from 'helpers/constants/findDelivery';

const FindDeliveryHeader = ({orderData}) => {
  return (
    <DashboardInner className='RefundsCalculator withoutShadow'>
      <FindDeliveryHeaderItems>
        <FindDeliveryHeaderItem>
          <Body5 className='FindDeliveryHeaderTitle marginBottomXs2'>
            Location Name:
          </Body5>
          <Body0 className='FindDeliveryHeaderValue wordWrap'>
            {orderData[FIND_DELIVERY.LOCATION]?.name}
          </Body0>
        </FindDeliveryHeaderItem>
        <FindDeliveryHeaderItem>
          <Body5 className='FindDeliveryHeaderTitle marginBottomXs2'>
            Customer Name:
          </Body5>
          <Body0 className='FindDeliveryHeaderValue wordWrap'>
            {orderData[FIND_DELIVERY.CUSTOMER_NAME]}
          </Body0>
        </FindDeliveryHeaderItem>
        <FindDeliveryHeaderItem>
          <Body5 className='FindDeliveryHeaderTitle marginBottomXs2'>
            Order Date:
          </Body5>
          <Body0 className='FindDeliveryHeaderValue'>
            {moment
              .utc(orderData[FIND_DELIVERY.PICKUP]?.deadline)
              .utcOffset(orderData[FIND_DELIVERY.TIMEZONE] || 0)
              .format('MM.DD.yy HH:mm a')}
          </Body0>
        </FindDeliveryHeaderItem>
        <FindDeliveryHeaderItem>
          <Body5 className='FindDeliveryHeaderTitle marginBottomXs2'>
            Delivery Status:
          </Body5>
          <Body0 className='FindDeliveryHeaderValue'>
            {FIND_DELIVERY_STATUS_NAME[orderData[FIND_DELIVERY.STATUS]?.type]}
          </Body0>
        </FindDeliveryHeaderItem>
        <FindDeliveryHeaderItem className='Buttons'>
          <FindDeliveryHeaderItem className='FindDeliveryHeaderButtons'>
            <ButtonPrimary
              onClick={() => {
                window.open(
                  orderData[FIND_DELIVERY.RESTAURANT_TRACKING_LINK],
                  '_blank'
                );
              }}
              className={cn('ButtonSmallXs', {
                disabled: !orderData[FIND_DELIVERY.RESTAURANT_TRACKING_LINK]
              })}
            >
              <span>R</span>
            </ButtonPrimary>
            <ButtonPrimary
              onClick={() => {
                window.open(
                  orderData[FIND_DELIVERY.USER_TRACKING_LINK],
                  '_blank'
                );
              }}
              className={cn('ButtonSmallXs', {
                disabled: !orderData[FIND_DELIVERY.USER_TRACKING_LINK]
              })}
            >
              <span>U</span>
            </ButtonPrimary>
          </FindDeliveryHeaderItem>
        </FindDeliveryHeaderItem>
      </FindDeliveryHeaderItems>
    </DashboardInner>
  );
};

FindDeliveryHeader.propTypes = {
  orderData: T.object.isRequired
};

export default FindDeliveryHeader;
