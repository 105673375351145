import {v4 as uuid} from 'uuid';

export const getSessionIdsFromStorage = () => {
  return sessionStorage.getItem('session_ids');
};

export const setToSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
export const getCurrentSessionIdFromStorage = (userId) => {
  let sessionId = null;
  const sessionIds = sessionStorage.getItem('session_ids');
  if (sessionIds) {
    try {
      const parseSessionIds = JSON.parse(sessionIds);
      if (parseSessionIds && Array.isArray(parseSessionIds)) {
        const currentUser = parseSessionIds.find((el) => {
          return Object.keys(el)[0] === userId ? el : undefined;
        });
        sessionId = currentUser ? currentUser[userId] : null;
      }
    } catch (error) {
      console.error('getCurrentSessionIdFromStorageError', error);
    }
  }
  return sessionId;
};
export const setSessionIdToStorage = (userId) => {
  if (!getCurrentSessionIdFromStorage(userId)) {
    const sessionIds = getSessionIdsFromStorage();
    if (sessionIds) {
      try {
        const parseSessionIds = JSON.parse(sessionIds);
        parseSessionIds.push({[userId]: uuid()});
        setToSessionStorage('session_ids', parseSessionIds);
      } catch (error) {
        setToSessionStorage('session_ids', [{[userId]: uuid()}]);
        console.error('setSessionIdToStorageError', error);
      }
    } else {
      setToSessionStorage('session_ids', [{[userId]: uuid()}]);
    }
  }
};
