import styled from 'styled-components';

export const RefundTopBarContainer = styled.div`
  .DialogTitleLeft {
    margin-bottom: 40px;
  }
  .refundsTopPanelText {
    margin-bottom: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2f3d48;
  }
`;

export const RefundTopBarSelects = styled.div`
  display: flex;
  flex-wrap: wrap;

  .refundSelectGroup {
    display: flex;
    flex-wrap: wrap;

    .freeTextInput {
      max-width: 621px;
    }
  }

  .refundSelectContainer {
    width: 304px;
    margin-bottom: 5px;

    &.disabled {
      opacity: 0.5;
    }

    &:first-child {
      margin-right: 13px;
    }

    span {
      display: inline-block;
      margin-bottom: 5px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #2f3d48;

      span {
        color: #ff2d38;
        margin: 0;
      }
    }

    .MuiSelect-select {
      font-size: 14px;
      max-height: 19px;
    }

    .MuiFormControl-root {
      &.error {
        .MuiInputBase-root {
          border-color: #ff2d38;
        }
      }
    }
  }

  .freeTextInputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    width: 100%;
  }

  .mRight13 {
    margin-right: 13px;
  }
`;

export const RefundOtherTextareaContainer = styled.div`
  margin-top: 20px;
  position: relative;
  span {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #2f3d48;

    span {
      color: #ff2d38;
      margin: 0;
    }
  }

  textarea {
    min-height: 105px;
    &.error {
      border-color: #ff2d38;
    }
  }
  .otherWordsCounter {
    position: absolute;
    right: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: right;
    color: #929cb9;

    span {
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #929cb9;
    }
  }
`;
