import {createAction} from 'redux-act';

export const SET_ACTIVE_ORDERS = createAction('orders/SET_ACTIVE_ORDERS');
export const CLEAR_ACTIVE_ORDERS = createAction('orders/CLEAR_ACTIVE_ORDERS');
export const SET_NEW_ORDERS = createAction('orders/SET_NEW_ORDERS');
export const SET_ORDERS_SORTING = createAction('orders/SET_ORDERS_SORTING');
export const CLEAR_NEW_ORDERS = createAction('orders/CLEAR_NEW_ORDERS');
export const RUN_GET_ACTIVE_ORDERS_JOB = createAction(
  'orders/RUN_GET_ACTIVE_ORDERS_JOB'
);
export const STOP_GET_ACTIVE_ORDERS_JOB = createAction(
  'orders/STOP_GET_ACTIVE_ORDERS_JOB'
);
export const CHANGE_REFUNDS_ACTIVE = createAction(
  'orders/CHANGE_REFUNDS_ACTIVE'
);
export const CHANGE_STATUS_ACTIVE = createAction('orders/CHANGE_STATUS_ACTIVE');
export const CHANGE_STATUS_ACTIVE_SUCCESS = createAction(
  'orders/CHANGE_STATUS_ACTIVE_SUCCESS'
);
export const CHANGE_CURRENT_LOCATION_TIMEZONE = createAction(
  'orders/CHANGE_CURRENT_LOCATION_TIMEZONE'
);
