// eslint-disable-next-line import/prefer-default-export
export const feedbackType = {
  0: 'all',
  1: 'delivery',
  2: 'pickup'
};

export const feedbackRankingTypes = {
  GoodFeedback: 'LikeBest',
  BadFeedback: 'WhatWentWrong',
  GoodDeliveryFeedback: 'DeliveryLikeBest',
  BadDeliveryFeedback: 'DeliveryWhatWentWrong'
};

export const FIRST_SURVEY_PRODUCTION_DATE = '2022-07-25T18:10:02.375Z';
