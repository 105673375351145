import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {GET_APP_VERSION} from '../../store/global/appVersion/action';

function AppVersion({children}) {
  const dispatch = useDispatch();
  const version = useSelector((store) => store.appVersionReducer.version);

  useEffect(() => {
    dispatch(GET_APP_VERSION());

    const checkVersion = setInterval(() => {
      dispatch(GET_APP_VERSION());
    }, 600000);

    return () => {
      clearInterval(checkVersion);
    };
  }, []);

  return <>{children}</>;
}

AppVersion.propTypes = {
  children: PropTypes.object
};

AppVersion.defaultProps = {
  children: null
};

export default AppVersion;
