import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog} from '@material-ui/core';
import {Form, Formik} from 'formik';
import cn from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {DialogBody, FieldInput} from '../../../dashboard/styles';
import DialogFields from '../../../../../components/DialogFields/styles';
import DialogField from '../../../../../components/DialogField/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import DialogActions from '../../../../../components/DialogActions/styles';
import DialogAction from '../../../../../components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from '../../../../../components/buttons';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import {
  CREATE_FEATURE,
  OPEN_CREATE_FEATURE_MODAL
} from '../../../../../store/featureManagement/action';
import FeatureFlagsSchema from '../../../../../helpers/services/formValidationSchemas/FeatureFlagsSchema';
import Input from '../../../../../components/FieldInput/style';
import SelectWrapper from '../../../../../components/select/SelectComponentStyles';
import SwitchS2E from '../../../../../components/Switch';

const CreateFeatureModal = ({isOpen}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const closeModal = () => {
    dispatch(OPEN_CREATE_FEATURE_MODAL(false));
  };

  const initialValues = {
    source: 'Dashboard',
    name: '',
    route: null,
    showOnTablet: false,
    isNewFeature: true,
    icon: null,
    enabled: false
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={FeatureFlagsSchema}
      onSubmit={(values, {resetForm}) => {
        const body = {...values};
        if (body.source === 'Storefront') {
          body.route = null;
          body.icon = null;
          body.showOnTablet = false;
          body.isNewFeature = true;
          body.enabled = false;
        }
        body.id = body.name
          .toLowerCase()
          .replace(/\s+/g, '_')
          .replace(/[_]{2,}/g, '_');
        dispatch(CREATE_FEATURE(body));
        closeModal();
        resetForm();
      }}
    >
      {({values, handleChange, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            open={isOpen}
            onClose={() => {
              closeModal();
            }}
            scroll='body'
          >
            <DialogContainer className='DialogMdPadding'>
              <DialogTitle className='DialogTitleMarginBottom'>
                Add new feature flag
              </DialogTitle>

              <DialogBody>
                <Form>
                  <DialogFields>
                    <DialogField className='w100'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.Name && touched.Name && 'error'
                        })}
                      >
                        Source <span>*</span>
                      </FieldLabel>
                      <SelectWrapper className='w100'>
                        <Select
                          name='source'
                          value={values.source}
                          onChange={handleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                          renderValue={(value) => (
                            <Input
                              className={
                                errors.source && touched?.source && 'error'
                              }
                              value={value}
                              placeholder='Select a reason'
                            />
                          )}
                        >
                          <MenuItem value='Dashboard'>Dashboard</MenuItem>
                          <MenuItem value='Storefront'>Storefront</MenuItem>
                        </Select>
                        {errors.source && touched?.source ? (
                          <ErrorSignWrapper>
                            <ErrorSign>{errors.source}</ErrorSign>
                          </ErrorSignWrapper>
                        ) : null}
                      </SelectWrapper>
                    </DialogField>
                    <DialogField className='w100'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.name && touched.name && 'error'
                        })}
                      >
                        Name <span>*</span>
                      </FieldLabel>
                      <FieldInput
                        className={errors.name && touched.name && 'error'}
                        name='name'
                        value={values.name}
                        placeholder='Name'
                        onChange={handleChange}
                        onBlur={(e) => {
                          setFieldValue('name', e.target.value?.trim());
                        }}
                      />
                      <ErrorSignWrapper className='positionRight2'>
                        {errors.name && touched.name && (
                          <ErrorSign>{errors.name}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                  </DialogFields>
                  {values.source === 'Dashboard' && (
                    <DialogFields>
                      <DialogField className='w100'>
                        <FieldLabel
                          className={cn('FieldLabelLg modalLabel', {
                            error: errors.route && touched.route && 'error'
                          })}
                        >
                          Route
                        </FieldLabel>
                        <FieldInput
                          className={errors.route && touched.route && 'error'}
                          name='route'
                          value={values.route}
                          placeholder='Route'
                          onChange={handleChange}
                        />
                        <ErrorSignWrapper className='positionRight2'>
                          {errors.route && touched.route && (
                            <ErrorSign>{errors.route}</ErrorSign>
                          )}
                        </ErrorSignWrapper>
                      </DialogField>
                      <DialogField className='w100'>
                        <FieldLabel className='FieldLabelLg modalLabel'>
                          Icon
                        </FieldLabel>
                        <FieldInput
                          name='icon'
                          value={values.icon}
                          placeholder='Icon'
                          onChange={handleChange}
                        />
                      </DialogField>
                      <DialogField>
                        <SwitchS2E
                          onChange={handleChange}
                          name='enabled'
                          checked={values.enabled}
                          label='Enabled feature'
                          labelPlacement='start'
                        />
                      </DialogField>
                      <DialogField>
                        <SwitchS2E
                          onChange={handleChange}
                          name='isNewFeature'
                          checked={values.isNewFeature}
                          label='New feature mark'
                          labelPlacement='start'
                        />
                      </DialogField>
                      <DialogField>
                        <SwitchS2E
                          onChange={handleChange}
                          name='showOnTablet'
                          checked={values.showOnTablet}
                          label='Show on tablet'
                          labelPlacement='start'
                        />
                      </DialogField>
                    </DialogFields>
                  )}
                  <DialogActions className='justifyCenter'>
                    <DialogAction className='w40'>
                      <ButtonOutline
                        type='button'
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <span>Cancel</span>
                      </ButtonOutline>
                    </DialogAction>
                    <DialogAction className='w40'>
                      <ButtonPrimary type='submit'>
                        <span>Submit</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </Form>
              </DialogBody>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

CreateFeatureModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};
export default CreateFeatureModal;
