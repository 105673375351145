import React from 'react';
import PropTypes from 'prop-types';

export default function TabPanel(props) {
  const {children, value, index, ...other} = props;
  TabPanel.propTypes = {
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired
  };

  TabPanel.defaultProps = {};

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
