import {put, takeEvery, select} from 'redux-saga/effects';
import {saveAs} from 'file-saver';
import moment from 'moment';
import {toast} from 'react-toastify';
import {
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  EXPORT_REPORTS,
  STOP_EXPORT_REPORTS_LOADING,
  STOP_REPORTS_LOADING,
  SET_REPORT_VISITED_STATISTIC
} from './action';
import {
  getXlsReportsApi,
  generateReportApi,
  getReportsApi
} from '../../helpers/services/api/reports';
import axiosAPI from '../../helpers/services/utils/axios';
import {SERVER_URL} from '../../helpers/services/constants/API_CONSTANTS';
import {getStorageToken} from '../../helpers/localStorage/authStorage';
import {postBADOrderApi} from '../../helpers/services/api/bookADrive';
import {
  getUserStatisticApi,
  postUserStatisticApi
} from '../../helpers/services/api/user';
import {getSessionStorageItem} from '../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../helpers/sessionStorage/constants';

function* generateReport() {
  const {token} = getStorageToken();
  const dateRange = yield select((state) => state.reportsReducer.dateRange);
  const reportType = yield select((state) => state.reportsReducer.reportType);
  const locationId = yield select((state) => state.user.locationId);
  const {_id: userID} = yield select((store) => store.user.userData);
  const userRole = yield select((store) => store.user.userData.role);

  try {
    const report = yield generateReportApi(
      dateRange[0],
      dateRange[1],
      locationId,
      reportType
    );

    if (reportType === 0) {
      yield put(
        GENERATE_REPORT_SUCCESS({
          totalSummary: report.data.totalSummary,
          reportData: {
            dataRows: report.data.dailySummaries,
            reportTypeInner: reportType,
            reportPeriod: dateRange
          },
          reportId: report.data.id,
          metadata: report.data.metadata
        })
      );
    }

    if (reportType === 1) {
      yield put(
        GENERATE_REPORT_SUCCESS({
          reportData: {
            dataRows: report?.data?.summaries.sort((a, b) => {
              return (
                new Date(a.lastOrderCreationDate) -
                new Date(b.lastOrderCreationDate)
              );
            }),
            reportTypeInner: reportType,
            reportPeriod: dateRange
          },
          reportId: report?.data?.id
        })
      );
    }

    if (reportType === 2) {
      yield put(
        GENERATE_REPORT_SUCCESS({
          reportData: {
            dataRows: report.data.payouts,
            reportTypeInner: reportType,
            reportPeriod: dateRange
          },
          reportId: report.data.id,
          metadata: report.data.metadata
        })
      );
    }

    if (reportType === 5) {
      const dataContent = yield getReportsApi(report.data.id, reportType);

      yield put(
        GENERATE_REPORT_SUCCESS({
          totalSummary: {totalAmount: report.data.totalAmount},
          reportData: {
            dataRows: dataContent.data.data,
            reportTypeInner: reportType,
            reportPeriod: dateRange
          },
          reportId: report.data.id
        })
      );
    }

    if (reportType === 6) {
      const dataContent = yield getReportsApi(report.data.id, reportType);

      yield put(
        GENERATE_REPORT_SUCCESS({
          totalSummary: {totalAmount: report.data.totalAmount},
          reportData: {
            dataRows: dataContent.data.data,
            reportTypeInner: reportType,
            reportPeriod: dateRange
          },
          reportId: report.data.id
        })
      );
    }

    const employeeView = getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);

    const {data} = yield getUserStatisticApi({
      userId: userID,
      tag: 'reports',
      sourceType: 'webApp',
      role: `${employeeView ? 'sudo' : userRole}`
    });

    yield put(SET_REPORT_VISITED_STATISTIC(data?.quantityData));

    yield postUserStatisticApi({
      userID,
      url: report.config.url,
      tag: 'reports',
      sourceType: `${!window.AndroidDashboard ? 'webApp' : 'androidApp'}`,
      statusCode: report.status,
      role: `${employeeView ? 'sudo' : userRole}`
    });
  } catch (error) {
    yield put(STOP_REPORTS_LOADING());
    console.log('SAGA ERROR', error);
  }
}
// This function may be used in future for BookADriver and BYOC reports:

// function* getReports() {
//   const reportType = yield select((state) => state.reportsReducer.reportType);
//   const skip = yield select((state) => state.reportsReducer.skip);
//   const reportId = yield select((state) => state.reportsReducer.reportId);
//
//   try {
//     const {data} = yield getReportsApi(reportId, skip, reportType);
//
//     if (data) {
//       yield put(GET_REPORTS_SUCCESS({data}));
//     }
//   } catch (error) {
//     yield put(STOP_REPORTS_LOADING());
//     console.log('SAGA ERROR', error);
//   }
// }

function* exportReports() {
  const reportId = yield select((state) => state.reportsReducer.reportId);
  const {reportTypeInner, reportPeriod} = yield select(
    (state) => state.reportsReducer.data
  );

  const reportsNames = {0: 'Sales', 2: 'Payout', 5: 'Refunds'};
  const formattedDate =
    `${moment(reportPeriod[0]).format('M.D.YYYY')}` ===
    `${moment(reportPeriod[1]).format('M.D.YYYY')}`
      ? `${moment(reportPeriod[0]).format('M.D.YYYY')}`
      : `${moment(reportPeriod[0]).format('M.D.YYYY')}-${moment(
          reportPeriod[1]
        ).format('M.D.YYYY')}`;

  let fileName = `Sauce ${reportsNames[reportTypeInner]} report for ${formattedDate}.xlsx`;

  try {
    const resp = yield getXlsReportsApi(reportId, reportTypeInner);

    if (resp) {
      // if there is a header with a filename, then we take the filename from it
      if (
        resp.headers['content-disposition'] &&
        resp.headers['content-disposition'].indexOf('filename="') > -1
      ) {
        const indexStart =
          resp.headers['content-disposition'].indexOf('filename="') + 10;
        const indexEnd = resp.headers['content-disposition'].indexOf(
          '"',
          indexStart
        );
        fileName = resp.headers['content-disposition'].substring(
          indexStart,
          indexEnd
        );
      }

      saveAs(new Blob([resp.data], {type: ''}), fileName);
      yield put(STOP_EXPORT_REPORTS_LOADING());
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield toast.error('Something went wrong');
    yield put(STOP_EXPORT_REPORTS_LOADING());
  }
}

function* reportsSaga() {
  yield takeEvery(EXPORT_REPORTS, exportReports);
  yield takeEvery(GENERATE_REPORT, generateReport);
  // yield takeEvery(GET_REPORTS, getReports);
}

export default reportsSaga;
