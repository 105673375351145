import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import propTypes from 'prop-types';
import {DashboardContent} from '../../components/DashboardContent/styles';
import {DashboardHeader} from '../../components/DashboardHeader/styles';
import {PageErrorBack, PageErrorTitle} from './styles';
import Headline from '../../components/Headline/styles';
import Body3 from '../../components/typography/Body3';
import {usePrevious} from '../../hooks/usePrevious';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const location = useLocation();
  const history = useHistory();
  const locationId = useSelector((state) => state.user?.activeLocation?._id);
  const prevRoute = usePrevious(location?.pathname);
  const prevLocation = usePrevious(locationId);

  useEffect(() => {
    if (prevRoute && location?.pathname !== prevRoute) {
      resetErrorBoundary();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (prevLocation && locationId && prevLocation !== locationId) {
      resetErrorBoundary();
    }
  }, [locationId]);

  return (
    <DashboardContent className='DashboardContent'>
      <DashboardHeader className='Center'>
        <PageErrorTitle>404</PageErrorTitle>
      </DashboardHeader>
      <Headline className='Center marginBottomXl'>
        Oops, Something went wrong
      </Headline>
      <Body3 className='text-center marginBottomXl'>
        Sorry, we-re not able to find what you were looking for.
      </Body3>
      <PageErrorBack
        onClick={() => {
          history.push('/');
          resetErrorBoundary();
        }}
      >
        <span>Take me home</span>
      </PageErrorBack>
    </DashboardContent>
  );
};

ErrorFallback.propTypes = {
  error: propTypes.object.isRequired,
  resetErrorBoundary: propTypes.func.isRequired
};

export default ErrorFallback;
