import React from 'react';

const IconSidebarTeam = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.9789 7.47363H10.8962C11.0066 7.77578 11.0669 8.10186 11.0669 8.44171V12.5337C11.0669 12.6754 11.0422 12.8114 10.9974 12.9379H12.7873C13.4559 12.9379 13.9999 12.3939 13.9999 11.7253V9.49459C13.9999 8.38024 13.0933 7.47363 11.9789 7.47363Z'
      fill='#929CB9'
    />
    <path
      d='M2.93303 8.44171C2.93303 8.10183 2.99333 7.77578 3.10369 7.47363H2.02098C0.906611 7.47363 0 8.38024 0 9.49461V11.7253C0 12.394 0.54395 12.9379 1.21259 12.9379H3.00257C2.9577 12.8114 2.93303 12.6754 2.93303 12.5337V8.44171Z'
      fill='#929CB9'
    />
    <path
      d='M8.23765 6.42078H5.76243C4.64807 6.42078 3.74146 7.32739 3.74146 8.44176V12.5338C3.74146 12.757 3.92242 12.938 4.14565 12.938H9.85443C10.0777 12.938 10.2586 12.757 10.2586 12.5338V8.44176C10.2586 7.32739 9.35202 6.42078 8.23765 6.42078Z'
      fill='#929CB9'
    />
    <path
      d='M7.00006 1.06201C5.65989 1.06201 4.56958 2.15232 4.56958 3.49252C4.56958 4.40156 5.07131 5.19549 5.81227 5.61232C6.16372 5.81002 6.5689 5.923 7.00006 5.923C7.43122 5.923 7.8364 5.81002 8.18785 5.61232C8.92884 5.19549 9.43054 4.40154 9.43054 3.49252C9.43054 2.15235 8.34023 1.06201 7.00006 1.06201Z'
      fill='#929CB9'
    />
    <path
      d='M2.73223 3.32739C1.72994 3.32739 0.914551 4.14278 0.914551 5.14507C0.914551 6.14736 1.72994 6.96275 2.73223 6.96275C2.98647 6.96275 3.22857 6.91009 3.4485 6.81542C3.82874 6.65172 4.14227 6.36193 4.33627 5.99894C4.47244 5.74417 4.54991 5.45354 4.54991 5.14507C4.54991 4.14281 3.73452 3.32739 2.73223 3.32739Z'
      fill='#929CB9'
    />
    <path
      d='M11.2679 3.32739C10.2656 3.32739 9.4502 4.14278 9.4502 5.14507C9.4502 5.45356 9.52766 5.7442 9.66383 5.99894C9.85784 6.36195 10.1714 6.65174 10.5516 6.81542C10.7715 6.91009 11.0136 6.96275 11.2679 6.96275C12.2702 6.96275 13.0856 6.14736 13.0856 5.14507C13.0856 4.14278 12.2702 3.32739 11.2679 3.32739Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarTeam;
