import {select, put, takeEvery} from 'redux-saga/effects';
import {
  getItemsUnavailableApi,
  getModifiersUnavailableApi,
  getUnavailableOverviewApi,
  getItemTree,
  getSectionTree,
  getModifierTree,
  getHiddenCounters
} from '../../helpers/services/api/menuEditor';
import {
  GET_HIDDEN_TAB,
  GET_HIDDEN_TAB_SUCCESS,
  FETCH_HIDDEN_SECTIONS,
  SET_HIDDEN_SECTIONS,
  CLEAR_HIDDEN_SECTIONS,
  FETCH_HIDDEN_ITEMS,
  SET_HIDDEN_ITEMS,
  CLEAR_HIDDEN_ITEMS,
  FETCH_HIDDEN_MODIFIERS,
  SET_HIDDEN_MODIFIERS,
  CLEAR_HIDDEN_MODIFIERS,
  FETCH_SINGLE_CATEGORY_TREE,
  SET_SINGLE_CATEGORY_TREE,
  FETCH_SINGLE_ITEM_TREE,
  SET_SINGLE_ITEM_TREE,
  FETCH_SINGLE_MODIFIER_TREE,
  SET_SINGLE_MODIFIER_TREE,
  FETCH_HIDDEN_COUNTERS,
  LOAD_HIDDEN_COUNTERS,
  SET_HIDDEN_COUNTERS
} from './action';

function* fetchHiddenSections() {
  try {
    const overviewParams = yield select(
      ({menuEditorHiddenReducer}) => menuEditorHiddenReducer.overviewPagination
    );
    const {data} = yield getUnavailableOverviewApi({
      ...overviewParams,
      Visible: false,
      page: 1,
      limit: 1000
    });

    if (data) {
      yield put({
        type: SET_HIDDEN_SECTIONS,
        payload: data.docs
      });
    } else {
      yield put({type: CLEAR_HIDDEN_SECTIONS});
    }
  } catch (error) {
    console.log('Error [fetchHiddenSections] saga: ', error);
  }
}

function* fetchHiddenItems() {
  try {
    const itemsParams = yield select(
      ({menuEditorHiddenReducer}) => menuEditorHiddenReducer.itemsPagination
    );

    const {data} = yield getItemsUnavailableApi({
      ...itemsParams,
      Invisible: true,
      page: 1,
      limit: 1000
    });

    if (data) {
      yield put({
        type: SET_HIDDEN_ITEMS,
        payload: data.docs
      });
    } else {
      yield put({type: CLEAR_HIDDEN_ITEMS});
    }
  } catch (error) {
    console.log('Error [fetchHiddenItems] saga: ', error);
  }
}

function* fetchHiddenModifiers() {
  try {
    const modifiersParams = yield select(
      ({menuEditorHiddenReducer}) => menuEditorHiddenReducer.modifiersPagination
    );
    const {data} = yield getModifiersUnavailableApi({
      ...modifiersParams,
      Invisible: true,
      page: 1,
      limit: 1000
    });

    if (data) {
      yield put({
        type: SET_HIDDEN_MODIFIERS,
        payload: data.docs
      });
    } else {
      yield put({type: CLEAR_HIDDEN_MODIFIERS});
    }
  } catch (error) {
    console.log('Error [fetchHiddenModifiers] saga: ', error);
  }
}

function* fetchHiddenCounters() {
  try {
    yield put(LOAD_HIDDEN_COUNTERS(true));

    const {data} = yield getHiddenCounters();

    if (data) {
      yield put(SET_HIDDEN_COUNTERS(data));
    }

    yield put(LOAD_HIDDEN_COUNTERS(false));
  } catch (error) {
    console.log('Error [fetchHiddenCounters]: ', error);
  }
}

function* getHiddenTab() {
  try {
    const getItemsState = ({menuEditorItemReducer}) =>
      menuEditorItemReducer.itemsPagination;
    const getAllModifiersState = ({menuEditorModifierReducer}) =>
      menuEditorModifierReducer.allModifiersPagination;
    const getOverviewState = ({menuEditorCategoryReducer}) =>
      menuEditorCategoryReducer.overviewPagination;

    const modifiersParams = yield select(getAllModifiersState);
    const itemsParams = yield select(getItemsState);
    const overviewParams = yield select(getOverviewState);

    const paginationModifiers = {
      ...modifiersParams,
      // fields: payload.fields,
      // EVG: payload.EVG,
      Invisible: true,
      page: 1,
      limit: 1000
    };
    const paginationItems = {
      ...itemsParams,
      Invisible: true,
      page: 1,
      limit: 1000
    };

    const paginationCategories = {
      ...overviewParams,
      Visible: false,
      page: 1,
      limit: 1000
    };

    const {data} = yield getModifiersUnavailableApi(paginationModifiers);
    const {data: d2} = yield getItemsUnavailableApi(paginationItems);
    const {data: d3} = yield getUnavailableOverviewApi(paginationCategories);

    if (d2 && data && d3) {
      yield put(GET_HIDDEN_TAB_SUCCESS({data, d2, d3}));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* fetchSingleCategoryTree({payload}) {
  try {
    const {data} = yield getSectionTree(payload);

    if (data) {
      yield put(SET_SINGLE_CATEGORY_TREE(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

function* fetchSingleItemTree({payload}) {
  try {
    const {data} = yield getItemTree(payload);

    if (data) {
      yield put(SET_SINGLE_ITEM_TREE(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

function* fetchSingleModifierTree({payload}) {
  try {
    const {data} = yield getModifierTree(payload);

    if (data) {
      yield put(SET_SINGLE_MODIFIER_TREE(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

function* menuEditorHiddenSaga() {
  yield takeEvery(GET_HIDDEN_TAB, getHiddenTab);
  yield takeEvery(FETCH_HIDDEN_SECTIONS, fetchHiddenSections);
  yield takeEvery(FETCH_HIDDEN_ITEMS, fetchHiddenItems);
  yield takeEvery(FETCH_HIDDEN_MODIFIERS, fetchHiddenModifiers);
  yield takeEvery(FETCH_SINGLE_CATEGORY_TREE, fetchSingleCategoryTree);
  yield takeEvery(FETCH_SINGLE_ITEM_TREE, fetchSingleItemTree);
  yield takeEvery(FETCH_SINGLE_MODIFIER_TREE, fetchSingleModifierTree);
  yield takeEvery(FETCH_HIDDEN_COUNTERS, fetchHiddenCounters);
}

export default menuEditorHiddenSaga;
