import React from 'react';
import T from 'prop-types';
import {CounterBadge, CounterCount, TeamCounter} from './styles';

const Counter = ({values}) => (
  <TeamCounter>
    {['All', 'Admins', 'Managers', 'Accountants', 'Employees'].map((key) => (
      <CounterBadge>
        {key} <CounterCount>{values[key]}</CounterCount>
      </CounterBadge>
    ))}
  </TeamCounter>
);

Counter.propTypes = {
  values: T.object
};

Counter.defaultProps = {
  values: {}
};

export default Counter;
