import React from 'react';
import PropTypes from 'prop-types';
import {DashboardField, DashboardFields} from '../../../dashboard/styles';
import {Subtitle1} from '../../../../../components/typography';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';

const EMail = ({formik}) => {
  return (
    <DashboardFields>
      <DashboardField className='w100 dFlex dFlexNoWrap justifySB'>
        <Subtitle1>Tell us who to send this to.</Subtitle1>
      </DashboardField>
      <DashboardField className='w100'>
        <FieldLabel>Their name</FieldLabel>
        <FieldInput
          name='ItTeamName'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Name'
          value={formik.values.ItTeamName}
          className={
            formik.errors.ItTeamName && formik.touched.ItTeamName && 'error'
          }
        />
        <ErrorSignWrapper>
          <ErrorSign>
            {formik.touched.ItTeamName && formik.errors.ItTeamName}
          </ErrorSign>
        </ErrorSignWrapper>
      </DashboardField>
      <DashboardField className='w100'>
        <FieldLabel>Their email address</FieldLabel>
        <FieldInput
          name='ItTeamEmail'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Email'
          value={formik.values.ItTeamEmail}
          className={
            formik.errors.ItTeamEmail && formik.touched.ItTeamEmail && 'error'
          }
        />
        <ErrorSignWrapper>
          <ErrorSign>
            {formik.touched.ItTeamEmail && formik.errors.ItTeamEmail}
          </ErrorSign>
        </ErrorSignWrapper>
      </DashboardField>
    </DashboardFields>
  );
};

EMail.propTypes = {
  formik: PropTypes.object.isRequired
};

export default EMail;
