import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {ButtonPrimary} from 'components/buttons';
import {DialogAction} from 'routes/App/dashboard/styles';
import {OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL} from 'store/orders/orderDetails/action';

const CancelDeliveryOrderResultModal = (props) => {
  const dispatch = useDispatch();

  const {open, message} = props;
  return (
    <Dialog open={open}>
      <DialogContainer className='DialogSm Overflow'>
        <DialogTitle className='DialogTitleMarginBottom'>{message}</DialogTitle>
        <DialogBody>
          <DialogAction>
            <ButtonPrimary
              onClick={() =>
                dispatch(
                  OPEN_CANCEL_DELIVERY_ORDER_RESULT_MODAL({
                    isOpenModal: false,
                    message: null
                  })
                )
              }
            >
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

CancelDeliveryOrderResultModal.propTypes = {
  message: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default CancelDeliveryOrderResultModal;
