import React from 'react';

export const IconDollar = () => (
  <svg
    width='23'
    height='26'
    viewBox='0 0 23 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_8793_3997)'>
      <path
        d='M18 16.147C18 17.0654 17.7762 17.9288 17.3285 18.7371C16.8988 19.5453 16.2541 20.225 15.3946 20.7761C14.553 21.3272 13.5503 21.6579 12.3864 21.7681V24H10.6405V21.7681C8.9573 21.6028 7.59642 21.0609 6.55785 20.1424C5.51928 19.2239 5 18.0115 5 16.5052H8.27686C8.33058 17.2216 8.55441 17.8186 8.94835 18.2962C9.34229 18.7555 9.90634 19.0494 10.6405 19.178V13.116C9.44077 12.8037 8.46488 12.4914 7.71281 12.1791C6.96074 11.8485 6.31612 11.3341 5.77893 10.6361C5.25964 9.938 5 8.98278 5 7.77038C5 6.24569 5.51928 4.98737 6.55785 3.99541C7.59642 2.98507 8.9573 2.39725 10.6405 2.23192V0H12.3864V2.23192C13.9621 2.37888 15.2245 2.89323 16.1736 3.77497C17.1405 4.63835 17.6866 5.83238 17.812 7.35706H14.5351C14.4814 6.7876 14.2665 6.28243 13.8905 5.84156C13.5145 5.38232 13.0131 5.07003 12.3864 4.90471V10.8565C13.5861 11.1504 14.562 11.4627 15.314 11.7933C16.0661 12.1056 16.7018 12.6108 17.2211 13.3088C17.7404 13.9885 18 14.9346 18 16.147ZM8.1157 7.60505C8.1157 8.35821 8.33058 8.94604 8.76033 9.36854C9.20799 9.79104 9.83471 10.1309 10.6405 10.3881V4.82204C9.87052 4.91389 9.25276 5.19862 8.78719 5.67624C8.33953 6.15385 8.1157 6.79678 8.1157 7.60505ZM12.3864 19.178C13.1742 19.0494 13.792 18.7279 14.2397 18.2135C14.6873 17.6808 14.9112 17.0471 14.9112 16.3123C14.9112 15.5775 14.6873 15.008 14.2397 14.6039C13.8099 14.1814 13.1921 13.8416 12.3864 13.5844V19.178Z'
        fill='#929CB9'
      />
    </g>
    <defs>
      <clipPath id='clip0_8793_3997'>
        <rect width='23' height='26' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconDollar;
