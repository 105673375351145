import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OutOfStockView from './OutOfStockView';
import {SET_ACTIVE_TAB} from '../../../../store/menuEditor/action';
import ElementsNotFoundMessage from '../ElementsNotFoundMessage';
import SearchBlock from '../SearchBlock';

const OutOfStockContainer = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.menuEditorReducer.activeTab);
  const itemsList = useSelector(
    (state) => state.menuEditorReducer.activeItemsDirection
  );
  const modifiersList = useSelector(
    (state) => state.menuEditorReducer.activeModifiersDirection
  );

  useEffect(() => dispatch(SET_ACTIVE_TAB('OUT_OFF_STOCK')), []);

  const [expanded, setExpanded] = useState({
    items: false,
    modifiers: false
  });

  const handleAccordionOpen = (accordionName) => {
    if (expanded[accordionName] === accordionName) {
      setExpanded((prevState) => {
        return {
          ...prevState,
          [accordionName]: false
        };
      });

      return;
    }

    setExpanded((prevState) => {
      return {
        ...prevState,
        [accordionName]: accordionName
      };
    });
  };

  if ((!itemsList && !modifiersList) || activeTab !== 'OUT_OFF_STOCK') {
    return null;
  }

  if (!itemsList.length && !modifiersList.length) {
    return (
      <ElementsNotFoundMessage message='You don’t have any out of stock elements yet.' />
    );
  }

  return (
    <>
      <OutOfStockView
        expanded={expanded}
        handleAccordionChange={handleAccordionOpen}
        itemsData={itemsList}
        modifiersData={modifiersList}
      />
    </>
  );
};

export default OutOfStockContainer;
