const cuisineTypes = [
  'Asian',
  'Bakery',
  'Bar / Pub',
  'BBQ & Grill',
  'Brazilian',
  'Breakfast',
  'Brunch',
  'Burgers',
  'Butcher',
  'Cafe',
  'Cake Shop',
  'Caribbean',
  'Chicken',
  'Crepes',
  'Cuban',
  'Deli',
  'Diner',
  'Dominican',
  'French',
  'Greek / Turkish',
  'Health Food',
  'Ice Cream',
  'Indian',
  'Israeli',
  'Italian',
  'Japanese',
  'Juice Bar',
  'Kosher',
  'Latin',
  'Liquor Store',
  'Meal Prep',
  'Mediterranean',
  'Mexican',
  'Peruvian',
  'Pizza',
  'Poke Bowls',
  'Sandwich',
  'Seafood',
  'Smoke Shop',
  'Spanish',
  'Vegan',
  'Vegetarian',
  'Other'
];

export default cuisineTypes;
