import React from 'react';

const IconSidebarAnalytics = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.18182 8.63636H1.63636C1.46759 8.63636 1.30573 8.70341 1.18639 8.82275C1.06705 8.94209 1 9.10395 1 9.27273V14.3636C1 14.5324 1.06705 14.6943 1.18639 14.8136C1.30573 14.933 1.46759 15 1.63636 15H4.18182C4.35059 15 4.51245 14.933 4.6318 14.8136C4.75114 14.6943 4.81818 14.5324 4.81818 14.3636V9.27273C4.81818 9.10395 4.75114 8.94209 4.6318 8.82275C4.51245 8.70341 4.35059 8.63636 4.18182 8.63636ZM14.3636 6.09091H11.8182C11.6494 6.09091 11.4875 6.15795 11.3682 6.2773C11.2489 6.39664 11.1818 6.5585 11.1818 6.72727V14.3636C11.1818 14.5324 11.2489 14.6943 11.3682 14.8136C11.4875 14.933 11.6494 15 11.8182 15H14.3636C14.5324 15 14.6943 14.933 14.8136 14.8136C14.933 14.6943 15 14.5324 15 14.3636V6.72727C15 6.5585 14.933 6.39664 14.8136 6.2773C14.6943 6.15795 14.5324 6.09091 14.3636 6.09091ZM9.27273 1H6.72727C6.5585 1 6.39664 1.06705 6.2773 1.18639C6.15795 1.30573 6.09091 1.46759 6.09091 1.63636V14.3636C6.09091 14.5324 6.15795 14.6943 6.2773 14.8136C6.39664 14.933 6.5585 15 6.72727 15H9.27273C9.4415 15 9.60336 14.933 9.7227 14.8136C9.84204 14.6943 9.90909 14.5324 9.90909 14.3636V1.63636C9.90909 1.46759 9.84204 1.30573 9.7227 1.18639C9.60336 1.06705 9.4415 1 9.27273 1Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarAnalytics;
