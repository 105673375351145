import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Dialog} from '@material-ui/core';
import DialogBody from '../../../../../../../components/DialogBody/styles';
import ConfirmedImage from '../../../../../../../assets/confirmed-image.png';
import {SuccessImage} from '../../../../styles';
import {DialogAction, DialogFieldText} from '../../../../../dashboard/styles';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../../components/buttons';
import DialogContainer from '../../../../../../../components/DialogContainer/styles';
import DialogTitle from '../../../../../../../components/DialogTitle/styles';
import {PATCH_USER_DATA} from '../../../../../../../store/global/user/action';

const ConfirmedBookCall = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(PATCH_USER_DATA({pendingDataSetuped: true}));
    window.open('http://blog.getsauce.com/', '_blank');
  };

  return (
    <Dialog open scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='marginBottomXl'>Confirmed!</DialogTitle>
        <DialogBody>
          <DialogFieldText className='text-center fontBold'>
            Our team is looking forward to meeting you and assisting you in any
            way possible!
          </DialogFieldText>
          <SuccessImage src={ConfirmedImage} />
          {/* <DialogFieldText className='text-center'> */}
          {/*    restaurant name <br/> */}
          {/*    12:00 PM — 12:20 PM, Tuesday, June 8th, 2021 <br/> */}
          {/*    Eastern time — US & Canada <br/> */}
          {/* </DialogFieldText> */}
          {/* <DialogFieldText className='text-center pxy25px fontBold'> */}
          {/*  A calendar invitation has been sent to your  email address. */}
          {/* </DialogFieldText> */}
          <DialogAction>
            <ButtonOutline
              className='ButtonOutline marginRightXs'
              type='button'
              onClick={() => {
                dispatch(PATCH_USER_DATA({pendingDataSetuped: true}));
                history.push('/my-account');
              }}
            >
              Go To Dashboard
            </ButtonOutline>
            <ButtonPrimary
              className='ButtonPrimary'
              type='submit'
              onClick={handleSubmit}
            >
              <span>Check Out Our Blog</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default ConfirmedBookCall;
