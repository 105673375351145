import styled from 'styled-components';

const DialogActions = styled.div`
  display: flex;
  align-items: center;
  margin: 40px -10px 0 -10px;
  flex-wrap: wrap;
  &.justifyCenter {
    justify-content: center;
  }
  &.marginTopNull {
    margin-top: 0;
  }
  &.DialogActionsMin {
    max-width: 300px;
  }
  &.DialogActionsNoWrap {
    flex-wrap: nowrap;
    @media (max-width: 520px) {
      flex-wrap: wrap;
    }
  }
  &.w100 {
    width: 100%;
  }
  &.justifySpaceBetween {
    justify-content: space-between;
  }
`;

export default DialogActions;
