import React from 'react';

// eslint-disable-next-line react/prop-types
const IconLoading = ({color = '#929CB9'}) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g cliPath='url(#clip0_9624_9883)'>
      <path
        d='M12 7.5C10.7591 7.5 9.75 6.49088 9.75 5.25V2.25C9.75 1.00912 10.7591 0 12 0C13.2409 0 14.25 1.00912 14.25 2.25V5.25C14.25 6.49088 13.2405 7.5 12 7.5ZM12 1.5C11.5864 1.5 11.25 1.83675 11.25 2.25V5.25C11.25 5.66325 11.5864 6 12 6C12.4136 6 12.75 5.66325 12.75 5.25V2.25C12.75 1.83675 12.4136 1.5 12 1.5Z'
        fill={color}
      />
      <path
        d='M12 24C10.7591 24 9.75 22.9909 9.75 21.75V18.75C9.75 17.5091 10.7591 16.5 12 16.5C13.2409 16.5 14.25 17.5091 14.25 18.75V21.75C14.25 22.9909 13.2405 24 12 24ZM12 18C11.5864 18 11.25 18.3364 11.25 18.75V21.75C11.25 22.1636 11.5864 22.5 12 22.5C12.4136 22.5 12.75 22.1636 12.75 21.75V18.75C12.75 18.3364 12.4136 18 12 18Z'
        fill={color}
      />
      <path
        d='M7.22728 9.47647C6.62615 9.47647 6.06103 9.24247 5.63615 8.81722L3.51478 6.69622C2.63765 5.81834 2.63765 4.39109 3.51478 3.51434C3.93965 3.08947 4.50478 2.85547 5.1059 2.85547C5.70703 2.85547 6.27215 3.08947 6.69703 3.51472L8.8184 5.63572C9.69552 6.51322 9.69552 7.94084 8.8184 8.81759C8.39315 9.24247 7.8284 9.47647 7.22728 9.47647ZM5.10628 4.35547C4.90565 4.35547 4.7174 4.43347 4.57565 4.57522C4.4339 4.71697 4.35628 4.90522 4.35628 5.10509C4.35628 5.30534 4.43428 5.49397 4.57603 5.63572L6.6974 7.75672C6.9809 8.04022 7.47515 8.04022 7.75865 7.75672C8.05078 7.46459 8.05078 6.98872 7.75828 6.69622L5.6369 4.57522C5.49478 4.43347 5.30653 4.35547 5.10628 4.35547Z'
        fill={color}
      />
      <path
        d='M18.8943 21.1443C18.2932 21.1443 17.7284 20.9103 17.3032 20.4851L15.1822 18.3641C14.7569 17.9392 14.5229 17.3737 14.5229 16.7729C14.5229 16.1718 14.7573 15.6071 15.1822 15.1818C15.6067 14.7573 16.1718 14.5233 16.7729 14.5233C17.3741 14.5233 17.9388 14.7573 18.3637 15.1822L20.4851 17.3036C21.3622 18.1807 21.3622 19.6083 20.4847 20.4851C20.0602 20.9103 19.4951 21.1443 18.8943 21.1443ZM16.7729 16.0233C16.5727 16.0233 16.3841 16.1013 16.2427 16.2427C16.1009 16.3844 16.0229 16.5727 16.0229 16.7729C16.0229 16.9732 16.1009 17.1618 16.2427 17.3036L18.3637 19.4246C18.6468 19.7077 19.1407 19.7081 19.4242 19.4246C19.7167 19.1321 19.7171 18.6562 19.4246 18.3641L17.3032 16.2427C17.1618 16.1013 16.9732 16.0233 16.7729 16.0233Z'
        fill={color}
      />
      <path
        d='M5.25011 14.2494H2.25011C1.00886 14.2486 0.000113386 13.2395 0.000488386 11.9994C0.000863386 10.7589 1.00999 9.74977 2.24974 9.74977L5.25011 9.74902C6.49136 9.75015 7.50086 10.7596 7.50086 11.9994C7.49974 13.2406 6.48986 14.2494 5.25011 14.2494ZM5.24974 11.249L2.25049 11.2498C1.83686 11.2498 1.50049 11.5861 1.50049 12.0001C1.50049 12.4134 1.83649 12.7494 2.25049 12.7494H5.25011C5.66374 12.7494 6.00049 12.4126 6.00086 11.9986C6.00086 11.5858 5.66374 11.2494 5.24974 11.249Z'
        fill={color}
      />
      <path
        d='M21.7502 14.2496H18.751H18.7502C18.1491 14.2496 17.5844 14.0156 17.1591 13.5904C16.7342 13.1651 16.5002 12.6 16.501 11.9989C16.501 11.3989 16.7346 10.8338 17.1595 10.4089C17.5844 9.984 18.1495 9.75 18.7506 9.75H21.7506C22.9911 9.75 24.0002 10.7591 24.0002 12C24.0006 12.6011 23.7666 13.1659 23.3417 13.5908C22.9165 14.0156 22.3514 14.2496 21.7502 14.2496ZM18.7502 12.7496H21.7502C21.9509 12.7496 22.1395 12.6716 22.2812 12.5299C22.4222 12.3885 22.5002 12.2006 22.5002 12.0004C22.5002 11.5864 22.1639 11.25 21.7506 11.25H18.7506C18.5504 11.25 18.3621 11.328 18.2204 11.4698C18.079 11.6111 18.001 11.7994 18.001 11.9992C18.001 12.2002 18.0786 12.3881 18.22 12.5299C18.3614 12.6716 18.55 12.7496 18.7502 12.7496Z'
        fill={color}
      />
      <path
        d='M5.10522 21.1442C4.50447 21.1442 3.93934 20.9102 3.51484 20.4853C2.63809 19.6067 2.63847 18.1798 3.51484 17.3031L5.63547 15.1813C6.06109 14.7565 6.62622 14.5225 7.22734 14.5225C7.82847 14.5225 8.39359 14.7565 8.81809 15.1813C9.69447 16.06 9.69447 17.4872 8.81772 18.3636L6.69634 20.4853C6.27109 20.9102 5.70634 21.1442 5.10522 21.1442ZM7.22734 16.0225C7.02672 16.0225 6.83809 16.1008 6.69597 16.2426L4.57572 18.3636C4.28322 18.6557 4.28359 19.1323 4.57609 19.4252C4.85809 19.708 5.35197 19.7076 5.63584 19.4245L7.75684 17.3031C8.04934 17.011 8.04897 16.5343 7.75647 16.2411C7.61622 16.1005 7.42797 16.0225 7.22734 16.0225Z'
        fill={color}
      />
      <path
        d='M16.7729 9.47647C16.1714 9.47647 15.6063 9.24209 15.1814 8.81647C14.7573 8.39234 14.5229 7.82722 14.5229 7.22647C14.5229 6.62534 14.7569 6.06059 15.1818 5.63572L17.3032 3.51434C17.7281 3.08947 18.2928 2.85547 18.8936 2.85547C19.4947 2.85547 20.0598 3.08984 20.4851 3.51509C20.9099 3.93959 21.1443 4.50434 21.1443 5.10547C21.1443 5.70622 20.9099 6.27097 20.4851 6.69659L18.3641 8.81684C17.9396 9.24209 17.3744 9.47647 16.7729 9.47647ZM18.8939 4.35509C18.6937 4.35509 18.5058 4.43309 18.3641 4.57447L16.2427 6.69584C16.1013 6.83759 16.0233 7.02584 16.0233 7.22609C16.0233 7.42634 16.1013 7.61422 16.2427 7.75559C16.5269 8.04059 17.0204 8.03984 17.3036 7.75634L19.4246 5.63572C19.5663 5.49359 19.6447 5.30497 19.6447 5.10472C19.6447 4.90484 19.5667 4.71697 19.4253 4.57559C19.2824 4.43309 19.0942 4.35509 18.8939 4.35509Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_9624_9883'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconLoading;
