import * as Yup from 'yup';

const EditNewMerchantSchema = Yup.object()
  .shape({
    merchant: Yup.object({
      Locations: Yup.array().of(
        Yup.object().shape({
          Address: Yup.string()
            .required('Please enter location address')
            .trim('Please enter location address'),
          StreetNumber: Yup.string()
            .required(
              'Your address does not have any street number specified. Please select another address or choose address with street number specified!'
            )
            .trim('The field cannot contain only spaces.')
        })
      )
    })
  })
  .test(
    'global-ok',
    'Please choose the suggested address from the dropdown or select it on the map!',
    function (value) {
      if (
        value?.merchant?.Locations[0]?.FullAddress &&
        !value?.merchant?.Locations[0]?.Address
      ) {
        return this.createError({
          path: 'Address',
          message: 'Please choose the suggested address from the dropdown!'
        });
      }
      return true;
    }
  );

export default EditNewMerchantSchema;
