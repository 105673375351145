import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import moment from 'moment';
import ListItemText from '@material-ui/core/ListItemText';
import DatePicker from '../../../../components/feature/DatePicker';
import {
  getCustomRange,
  setCustomRange
} from '../../../../helpers/localStorage/CustomRangeStorage';
import {
  getStoragePeriod,
  setStoragePeriod
} from '../../../../helpers/localStorage/DatePeriodStorage';
import {setStorageDate} from '../../../../helpers/localStorage/DateStorage';
import DatePickerContainer from './styles';
import {ButtonPrimary} from '../../../../components/buttons';

const ReportsDatepickerModule = ({
  date,
  updateDate,
  updateSelectedDateIndex,
  localStorageObject: {
    storageDateName,
    storageCustomRangeName,
    storageDatePeriodName
  },
  defaultSelectIndex,
  reportType,
  Timezone
}) => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectIndex);
  const [dateValue, setDateValue] = useState([null, null]);

  useEffect(() => {
    setDateValue(getCustomRange(storageCustomRangeName) || [null, null]);
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const index = getStoragePeriod(storageDatePeriodName);
    if (index !== undefined && index !== null) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex(defaultSelectIndex);
    }
  }, [defaultSelectIndex]);

  const handleListItemClick = (event, index) => {
    if (index !== 6) {
      setOpen(false);
    }
    setSelectedIndex(index);
    updateSelectedDateIndex(index);
    if (storageDatePeriodName) {
      setStoragePeriod(storageDatePeriodName, index);
    }
    // setOpen(true);
  };

  const setDate = () => {
    if (
      selectedDate.length &&
      selectedDate[0] !== null &&
      selectedDate[1] !== null
    ) {
      updateDate(selectedDate, selectedIndex);
      if (storageDateName) {
        setStorageDate(storageDateName, selectedDate);
      }
    }
  };

  const updateDatePeriod = (startPeriod, endPeriod) => {
    setSelectedDate([]);
    const periodCurrentDate = [startPeriod, endPeriod];
    updateDate(periodCurrentDate, selectedIndex);
    if (storageDateName && storageCustomRangeName) {
      setStorageDate(storageDateName, periodCurrentDate);
      setCustomRange(storageCustomRangeName, []);
    }
  };

  const datepickerOnChange = (datePickerDate) => {
    setDateValue(datePickerDate);
    if (storageCustomRangeName) {
      setCustomRange(storageCustomRangeName, datePickerDate);
    }
  };

  const locationDate = () => {
    return moment().utcOffset(Timezone, false).utc();
  };

  return (
    <DatePickerContainer>
      <span>Time period</span>
      <div className='Say2EatDrop '>
        <FormControl variant='outlined'>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            multiple
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            value={date}
            renderValue={() => {
              return [
                'All period',
                `${moment(date[0]).format('ddd MMM DD yyyy')}`,
                `${moment(date[0]).format('ddd MMM DD yyyy')} - ${moment(
                  date[1]
                ).format('ddd MMM DD yyyy')}`,
                `${moment(date[0]).format('ddd MMM DD yyyy')} - ${moment(
                  date[1]
                ).format('ddd MMM DD yyyy')}`,
                `${moment(date[0]).format('ddd MMM DD yyyy')} - ${moment(
                  date[1]
                ).format('ddd MMM DD yyyy')}`,
                `${moment(date[0]).format('ddd MMM DD yyyy')} - ${moment(
                  date[1]
                ).format('ddd MMM DD yyyy')}`,
                `${moment(date[0]).format('ddd MMM DD yyyy')} - ${moment(
                  date[1]
                ).format('ddd MMM DD yyyy')}`
              ][selectedIndex];
            }}
            // label="Custom range"
            open={open}
            onClose={handleOpen}
            onOpen={handleOpen}
            onChange={() => {}}
          >
            <div className='DropDown DropFilterDate'>
              <div className='DropDownHolder'>
                <div className='DropDownList'>
                  <List
                    component='nav'
                    className='DropDownMenu'
                    aria-label='main mailbox folders'
                  >
                    <ListItem
                      button
                      selected={selectedIndex === 0}
                      onClick={(event) => {
                        updateDatePeriod(null, null);
                        setShowDatePicker(false);
                        handleListItemClick(event, 0);
                      }}
                    >
                      <ListItemText primary='All period' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 1}
                      onClick={(event) => {
                        setShowDatePicker(false);
                        handleListItemClick(event, 1);
                        updateDatePeriod(
                          locationDate().startOf('day').format(),
                          locationDate().endOf('day').format()
                        );
                      }}
                    >
                      <ListItemText primary='Today' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 2}
                      onClick={(event) => {
                        setShowDatePicker(false);
                        handleListItemClick(event, 2);
                        updateDatePeriod(
                          locationDate().startOf('isoWeek').format(),
                          locationDate().endOf('isoWeek').format()
                        );
                      }}
                    >
                      <ListItemText primary='This week' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 3}
                      onClick={(event) => {
                        setShowDatePicker(false);
                        handleListItemClick(event, 3);

                        updateDatePeriod(
                          locationDate()
                            .subtract(1, 'week')
                            .startOf('isoWeek')
                            .format(),
                          locationDate()
                            .subtract(1, 'week')
                            .endOf('isoWeek')
                            .format()
                        );
                      }}
                    >
                      <ListItemText primary='Last week' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 4}
                      onClick={(event) => {
                        setShowDatePicker(false);
                        handleListItemClick(event, 4);

                        updateDatePeriod(
                          locationDate().startOf('month').format(),
                          locationDate().endOf('month').format()
                        );
                      }}
                    >
                      <ListItemText primary='This month' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 5}
                      onClick={(event) => {
                        setShowDatePicker(false);
                        handleListItemClick(event, 5);

                        updateDatePeriod(
                          locationDate()
                            .subtract(1, 'month')
                            .startOf('month')
                            .format(),
                          locationDate()
                            .subtract(1, 'month')
                            .endOf('month')
                            .format()
                        );
                      }}
                    >
                      <ListItemText primary='Last month' />
                    </ListItem>
                    <ListItem
                      button
                      selected={selectedIndex === 6}
                      onClick={(event) => {
                        handleListItemClick(event, 6);
                        setOpen(true);
                        setShowDatePicker(!showDatePicker);
                      }}
                    >
                      <ListItemText primary='Custom Range' />
                    </ListItem>
                  </List>
                </div>
                {showDatePicker && (
                  <div className='DropDownDatepicker'>
                    <DatePicker
                      setSelectedDate={setSelectedDate}
                      dateValue={dateValue}
                      datepickerOnChange={datepickerOnChange}
                      disableFuture
                    />
                    <div className='DropDownActions'>
                      <ButtonPrimary
                        className='ButtonSmall'
                        onClick={() => {
                          setDate();
                          handleOpen();
                        }}
                      >
                        <span>Apply</span>
                      </ButtonPrimary>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Select>
        </FormControl>
      </div>
    </DatePickerContainer>
  );
};

ReportsDatepickerModule.propTypes = {
  date: PropTypes.instanceOf(Array).isRequired,
  updateDate: PropTypes.func.isRequired,
  updateSelectedDateIndex: PropTypes.func,
  localStorageObject: PropTypes.shape({
    storageDateName: PropTypes.string,
    storageCustomRangeName: PropTypes.string,
    storageDatePeriodName: PropTypes.string
  }),
  defaultSelectIndex: PropTypes.number,
  reportType: PropTypes.number,
  Timezone: PropTypes.string
};

ReportsDatepickerModule.defaultProps = {
  updateSelectedDateIndex: () => {},
  localStorageObject: {
    storageDateName: '',
    storageCustomRangeName: '',
    storageDatePeriodName: ''
  },
  defaultSelectIndex: 1,
  reportType: 0,
  Timezone: '0'
};

export default ReportsDatepickerModule;
