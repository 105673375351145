import styled from 'styled-components';

export const CircularProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 195px;
  height: 195px;
  background: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .CircularProgress {
    svg {
      circle {
        color: rgba(255, 45, 56, 1);
      }
    }
  }
`;

export const FullScreenLoadingContainer = styled.div`
  position: fixed;
  background: rgba(47, 61, 72, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1300;
`;
