import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {toast} from 'react-toastify';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {
  DashboardField,
  DashboardFields,
  DashboardText,
  FieldInputDollar,
  FieldInputPercent
} from '../../../../../dashboard/styles';
import FieldInput from '../../../../../../../components/FieldInput';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../../components/buttons';
import {
  selectRestaurantUrl,
  SignUpData,
  stepData,
  WelcomeModalData
} from '../../../../../../../store/setupRestaurant/selectors';
import {
  CONTROL_MODALS,
  CONTROL_SIDEBAR_PROGRESS,
  GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
  GET_UBER_EATS_STORES_ONBOARDING,
  SAVE_STEP_DATA,
  SETUP_USER_ACCOUNT,
  INITIATE_ONBOARDING_MENU_PROCESS,
  SAVE_STEP_DATA_FOR_REQUEST
} from '../../../../../../../store/setupRestaurant/action';
import {
  ErrorSignWrapper,
  ErrorSign
} from '../../../../../../../components/errorSign/errorSign';
import {
  SetupActions,
  SetupContainer,
  SetupFieldset
} from '../../../../../../Auth/registration/signUpNew/style';
import Subtitle0 from '../../../../../../../components/typography/Subtitle0';
import Caption4 from '../../../../../../../components/typography/Caption4';
import {CardsButtonGrid} from '../../../../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../../../../components/CardsButton/styles';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import FileUploader from '../../../../../../../components/FileUploader';
import WelcomeModal from '../../../../modals/WelcomeModal';
import SalesSetupAddMenuSchema from '../../../../../../../helpers/services/formValidationSchemas/SalesSetupAddMenuSchema';
import allowedFileFormats from '../../../../../../../helpers/constants/signUp';
import MobileProgressBar from '../../../Components/MobileProgressBar';
import SelectComponent from '../../../../../../../components/select/SelectComponent';
import {ReactComponent as UberEatsLogo} from '../../../../../../../assets/uber-eats-logo.svg';
import {ReactComponent as PlanetLogo} from '../../../../../../../assets/icon-palnet.svg';
import {ReactComponent as EmailLogo} from '../../../../../../../assets/icon-email.svg';
import Caption1 from '../../../../../../../components/typography/Caption1';
import CheckboxS2E from '../../../../../../../components/Checkbox';
import {Caption2} from '../../../../../../../components/typography';
import EmailModal from './EmailModal';
import EmailConfirmPopup from './EmailConfirmPopup';
import RoundingRadio from './roundingRadio';
import UberPricesChangeControl from './uberPricesChangeControl';
import SalesSetupUberEatsMenuPullingSchema from '../../../../../../../helpers/services/formValidationSchemas/SalesSetupUberEatsMenuPullingSchema';
import UberEatsOnboardingStoreSelectModal from './UberEatsOnboardingStoreSelectModal';
import ObPriceReductionModule from './obPriceReductionModule';
import ImagesConnect from './ImagesConnect';
import Textarea from '../../../../../../../components/FieldTextarea/style';

const AddMenu = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openEmailConfirmPopup, setOpenEmailConfirmPopup] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef();

  const {
    step1: {
      MenuUseUrl,
      // MenuUploadFile,
      // MenuBuildFromScratch,
      ImagesUseUrl,
      // ImagesUploadFile,
      WithoutImages,
      platformSelect,
      MenuUberEats,
      MenuShowUrlInput,
      MenuEmail,
      lowerAllPrices,
      roundingValue,
      inflationType,
      inflationInputAmount,
      inflationInputPercentage,
      roundingValueURL,
      inflationTypeURL,
      inflationInputAmountURL,
      inflationInputPercentageURL,
      lowerAllPricesURL,
      roundingValueEmail,
      inflationTypeEmail,
      inflationInputAmountEmail,
      inflationInputPercentageEmail,
      lowerAllPricesEmail,
      uberEatsSelectedStoreId,
      uberEatsSelectedMenu,
      UEDeliveryCommission,
      UEPickupCommission,
      menuImagesCheckedURL,
      menuImagesCheckedEmail,
      urlMenuImagesURL,
      urlMenuImagesURLValue,
      urlMenuImagesEmail,
      emailMenuImagesURL,
      emailMenuImagesURLValue,
      emailMenuImagesEmail,
      OtterPriceSourceLink,
      OtterUsername,
      OtterPassword,
      OrdermarkType,
      OrdermarkUsername,
      OrdermarkPassword,
      OrdermarkPriceSourceLink,
      OrdermarkLocationId,
      SquareMenuInstruction
    },
    pendingDataSetuped,
    UserId,
    uberEatsStoresOnboarding,
    isUberEatsConnected,
    uberEatsStoreSelectModalOpen,
    uberEatsStoreMenusOnboarding,
    uberEatsMenusLoading
  } = stepData();

  const merchantId = useSelector((state) => state.user.userData.merchant_id);
  const restaurantUrl = selectRestaurantUrl();
  const isWelcomeModalOpen = WelcomeModalData();

  useEffect(() => {
    dispatch(
      CONTROL_SIDEBAR_PROGRESS(
        pendingDataSetuped ? 'allDone' : 'secondStepDone'
      )
    );
  }, [pendingDataSetuped]);

  const initValues = {
    userId: UserId || '',
    step: 1,
    uberEatsStoresOnboarding:
      uberEatsStoresOnboarding !== undefined ? uberEatsStoresOnboarding : [],
    isUberEatsConnected:
      isUberEatsConnected !== undefined ? isUberEatsConnected : false,
    uberEatsStoreSelectModalOpen,
    uberEatsStoreMenusOnboarding,
    data: {
      step: 1,
      userId: UserId || '',
      MenuShowUrlInput: MenuShowUrlInput || false,
      MenuShowUploadFile: false,
      MenuUseUrl: MenuUseUrl || restaurantUrl || '',
      // MenuUploadFile: MenuUploadFile || '',
      // MenuBuildFromScratch: MenuBuildFromScratch || false,
      ImagesShowUrlInput: false,
      ImagesUseUrl: ImagesUseUrl || restaurantUrl || '',
      // ImagesShowUploadFile: false,
      // ImagesUploadFile: ImagesUploadFile || '',
      WithoutImages: WithoutImages || false,
      platformSelect: platformSelect || 0,
      MenuUberEats: MenuUberEats || false,
      MenuEmail: MenuEmail || false,
      lowerAllPrices: lowerAllPrices !== undefined ? lowerAllPrices : true,
      roundingValue: roundingValue || 'To00',
      inflationType: inflationType || '%',
      inflationInputAmount:
        inflationInputAmount !== undefined ? inflationInputAmount : 2,
      inflationInputPercentage:
        inflationInputPercentage !== undefined ? inflationInputPercentage : 10,
      lowerAllPricesURL:
        lowerAllPricesURL !== undefined ? lowerAllPricesURL : true,
      roundingValueURL: roundingValueURL || 'To00',
      inflationTypeURL: inflationTypeURL || '%',
      inflationInputAmountURL:
        inflationInputAmountURL !== undefined ? inflationInputAmountURL : 2,
      inflationInputPercentageURL:
        inflationInputPercentageURL !== undefined
          ? inflationInputPercentageURL
          : 10,
      lowerAllPricesEmail:
        lowerAllPricesEmail !== undefined ? lowerAllPricesEmail : false,
      roundingValueEmail: roundingValueEmail || 'To00',
      inflationTypeEmail: inflationTypeEmail || '%',
      inflationInputAmountEmail:
        inflationInputAmountEmail !== undefined ? inflationInputAmountEmail : 2,
      inflationInputPercentageEmail:
        inflationInputPercentageEmail !== undefined
          ? inflationInputPercentageEmail
          : 10,
      uberEatsSelectedStoreId,
      uberEatsSelectedMenu,
      UEDeliveryCommission: UEDeliveryCommission || null,
      UEPickupCommission: UEPickupCommission || null,
      menuImagesCheckedURL:
        menuImagesCheckedURL !== undefined ? menuImagesCheckedURL : true,
      urlMenuImagesURL:
        urlMenuImagesURL !== undefined ? urlMenuImagesURL : false,
      urlMenuImagesURLValue: urlMenuImagesURLValue || '',
      urlMenuImagesEmail:
        urlMenuImagesEmail !== undefined ? urlMenuImagesEmail : false,
      menuImagesCheckedEmail:
        menuImagesCheckedEmail !== undefined ? menuImagesCheckedEmail : false,
      emailMenuImagesURL:
        emailMenuImagesURL !== undefined ? emailMenuImagesURL : false,
      emailMenuImagesURLValue: emailMenuImagesURLValue || '',
      emailMenuImagesEmail:
        emailMenuImagesEmail !== undefined ? emailMenuImagesEmail : false,
      OtterPriceSourceLink: OtterPriceSourceLink || '',
      OtterUsername: OtterUsername || '',
      OtterPassword: OtterPassword || '',
      OrdermarkType: OrdermarkType || 'Credentials',
      OrdermarkUsername: OrdermarkUsername || '',
      OrdermarkPassword: OrdermarkPassword || '',
      OrdermarkPriceSourceLink: OrdermarkPriceSourceLink || '',
      OrdermarkLocationId: OrdermarkLocationId || '',
      SquareMenuInstruction: SquareMenuInstruction || ''
    }
  };

  const onBack = () => {
    history.push('/my-account/sales-setup-new/general-info');
  };

  const onMenuUberEatsClick = (changedData) => {
    dispatch(
      SAVE_STEP_DATA({
        ...formikRef?.current?.values,
        data: {...changedData}
      })
    );
    if (!isUberEatsConnected) {
      localStorage.setItem(
        'newMenuOnboardingFormValues',
        JSON.stringify({...formikRef?.current?.values, data: {...changedData}})
      );

      window.open(
        `${process.env.REACT_APP_UBEREATS_API_URL}/api/uber-eats-adapter/v1/merchants/connect?id=${merchantId}&returnUrl=${process.env.REACT_APP_ROOT_DOMAIN}/my-account/sales-setup-new/add-menu/uber-eats-connect`,
        '_self'
      );
    }

    if (isUberEatsConnected && uberEatsStoresOnboarding?.length < 1) {
      dispatch(GET_UBER_EATS_STORES_ONBOARDING());
    }
    if (
      isUberEatsConnected &&
      uberEatsStoresOnboarding?.length > 0 &&
      (!uberEatsSelectedStoreId || !uberEatsSelectedMenu)
    ) {
      dispatch(
        CONTROL_MODALS({open: true, field: 'uberEatsStoreSelectModalOpen'})
      );
    }
  };

  // useEffect(() => {
  //   if (isUberEatsConnected && uberEatsStoresOnboarding?.length < 1) {
  //     dispatch(GET_UBER_EATS_STORES_ONBOARDING());
  //   }
  //   if (
  //     isUberEatsConnected &&
  //     uberEatsStoresOnboarding?.length > 0 &&
  //     (!uberEatsSelectedStoreId || !uberEatsSelectedMenu)
  //   ) {
  //     dispatch(
  //       CONTROL_MODALS({open: true, field: 'uberEatsStoreSelectModalOpen'})
  //     );
  //   }
  // }, []);

  // useEffect(() => {
  //   if (
  //     uberEatsStoresOnboarding &&
  //     uberEatsStoresOnboarding.length > 0 &&
  //     (!uberEatsSelectedStoreId || !uberEatsSelectedMenu)
  //   ) {
  //     dispatch(
  //       CONTROL_MODALS({open: true, field: 'uberEatsStoreSelectModalOpen'})
  //     );
  //   }
  // }, [uberEatsStoresOnboarding]);

  const UECommissionFormatter = (value) => {
    let res = value;
    if (value.length > 1 && value[0] === '0') {
      res = res[1];
    }
    res = res.replace(/[^0-9]/g, '');
    if (res > 99) {
      res = res.slice(0, -1);
    }
    return res ? Number(res) : res;
  };

  return (
    <>
      <SetupContainer>
        <Subtitle0>Create a Sauce menu</Subtitle0>
        <Caption4 style={{marginTop: '10px'}}>
          First, select where you see your Sauce orders:
        </Caption4>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={initValues}
          validationSchema={SalesSetupUberEatsMenuPullingSchema}
          onSubmit={async (values) => {
            if (
              (values.data.platformSelect === 0 ||
                values.data.platformSelect === 2 ||
                values.data.platformSelect === 5) &&
              !values.data.MenuUberEats &&
              !values.data.MenuEmail &&
              !values.data.MenuShowUrlInput
            ) {
              formikRef?.current?.setErrors({
                noOptionsAll: 'Please select one of the available options!'
              });
            } else if (
              (values.data.platformSelect === 0 ||
                values.data.platformSelect === 2 ||
                values.data.platformSelect === 5) &&
              ((values.data.MenuShowUrlInput &&
                !values.data.menuImagesCheckedURL &&
                !values.data.urlMenuImagesURL &&
                !values.data.urlMenuImagesEmail) ||
                (values.data.MenuEmail &&
                  !values.data.menuImagesCheckedEmail &&
                  !values.data.emailMenuImagesURL &&
                  !values.data.emailMenuImagesEmail))
            ) {
              formikRef?.current?.setErrors({
                noImagesSource: 'Please select one of the available options!'
              });
            }
            // else if (
            //   (values.data.platformSelect === 0 ||
            //     values.data.platformSelect === 5) &&
            //   values.data.MenuUberEats &&
            //   (!values.data.uberEatsSelectedStoreId ||
            //     !values.data.uberEatsSelectedMenu)
            // ) {
            //   formikRef?.current?.setErrors({
            //     noUberStoreOrMenu: 'Please select UberEats store and menu'
            //   });
            // }
            else {
              let valueDataForRequest = values.data;
              if (values?.data?.platformSelect === 3) {
                valueDataForRequest = {
                  step: values?.data?.step,
                  userId: values?.data?.userId,
                  platformSelect: 'Otter to POS',
                  OtterUsername: values?.data?.OtterUsername,
                  OtterPassword: values?.data?.OtterPassword,
                  OtterPriceSourceLink: values?.data?.OtterPriceSourceLink
                };
              } else if (
                values?.data?.platformSelect === 0 ||
                values?.data?.platformSelect === 2 ||
                values?.data?.platformSelect === 5
              ) {
                valueDataForRequest = {
                  step: values?.data?.step,
                  userId: values?.data?.userId,
                  ...(values.data.platformSelect === 0 && {
                    platformSelect: 'Sauce Tablet'
                  }),
                  ...(values.data.platformSelect === 2 && {
                    platformSelect: 'Otter tablet'
                  }),
                  ...(values.data.platformSelect === 5 && {
                    platformSelect: 'Checkmate'
                  }),
                  ...(values.data.MenuUberEats && {
                    MenuUberEats: values.data.MenuUberEats,
                    UEDeliveryCommission: values.data.UEDeliveryCommission,
                    UEPickupCommission: values.data.UEPickupCommission,
                    lowerAllPrices: values.data.lowerAllPrices,
                    ...(values.data.lowerAllPrices && {
                      inflationType: values.data.inflationType,
                      ...(values.data.inflationType === '%' && {
                        inflationInputPercentage:
                          values.data.inflationInputPercentage,
                        roundingValue: values.data.roundingValue
                      }),
                      ...(values.data.inflationType === '$' && {
                        inflationInputAmount: values.data.inflationInputAmount
                      })
                    })
                  }),
                  ...(values.data.MenuShowUrlInput && {
                    MenuShowUrlInput: values.data.MenuShowUrlInput,
                    MenuUseUrl: values.data.MenuUseUrl,
                    lowerAllPrices: values.data.lowerAllPricesURL,
                    ...(values.data.lowerAllPricesURL && {
                      inflationType: values.data.inflationTypeURL,
                      ...(values.data.inflationTypeURL === '%' && {
                        inflationInputPercentage:
                          values.data.inflationInputPercentageURL,
                        roundingValue: values.data.roundingValueURL
                      }),
                      ...(values.data.inflationTypeURL === '$' && {
                        inflationInputAmount:
                          values.data.inflationInputAmountURL
                      })
                    }),
                    menuImagesCheckedURL: values.data.menuImagesCheckedURL,
                    ...(!values.data.menuImagesCheckedURL && {
                      ...(values.data.urlMenuImagesURL && {
                        MenuImagesURL: values.data.urlMenuImagesURL,
                        MenuImagesURLValue: values.data.urlMenuImagesURLValue
                      }),
                      ...(values.data.urlMenuImagesEmail && {
                        MenuImagesEmail: values.data.urlMenuImagesEmail
                      })
                    })
                  }),
                  ...(values.data.MenuEmail && {
                    MenuEmail: values.data.MenuEmail,
                    lowerAllPrices: values.data.lowerAllPricesEmail,
                    ...(values.data.lowerAllPricesEmail && {
                      inflationType: values.data.inflationTypeEmail,
                      ...(values.data.inflationTypeEmail === '%' && {
                        inflationInputPercentage:
                          values.data.inflationInputPercentageEmail,
                        roundingValue: values.data.roundingValueEmail
                      }),
                      ...(values.data.inflationTypeEmail === '$' && {
                        inflationInputAmount:
                          values.data.inflationInputAmountEmail
                      })
                    }),
                    menuImagesCheckedEmail: values.data.menuImagesCheckedEmail,
                    ...(!values.data.menuImagesCheckedEmail && {
                      ...(values.data.emailMenuImagesURL && {
                        MenuImagesURL: values.data.emailMenuImagesURL,
                        MenuImagesURLValue: values.data.emailMenuImagesURLValue
                      }),
                      ...(values.data.emailMenuImagesEmail && {
                        MenuImagesEmail: values.data.emailMenuImagesEmail
                      })
                    })
                  })
                };
              } else if (values?.data?.platformSelect === 4) {
                valueDataForRequest = {
                  step: values?.data?.step,
                  userId: values?.data?.userId,
                  platformSelect: 'Ordermark',
                  OrdermarkType: values?.data?.OrdermarkType,
                  ...(values?.data?.OrdermarkType === 'Credentials' && {
                    OrdermarkUsername: values?.data?.OrdermarkUsername,
                    OrdermarkPassword: values?.data?.OrdermarkPassword,
                    OrdermarkPriceSourceLink:
                      values?.data?.OrdermarkPriceSourceLink
                  }),
                  ...(values?.data?.OrdermarkType === 'LocationId' && {
                    OrdermarkLocationId: values?.data?.OrdermarkLocationId
                  })
                };
              } else if (values?.data?.platformSelect === 1) {
                valueDataForRequest = {
                  step: values?.data?.step,
                  userId: values?.data?.userId,
                  platformSelect: 'Toast'
                };
              } else if (values?.data?.platformSelect === 6) {
                valueDataForRequest = {
                  step: values?.data?.step,
                  userId: values?.data?.userId,
                  platformSelect: 'Square',
                  SquareMenuInstruction: values?.data?.SquareMenuInstruction
                };
              }
              setIsSubmitting(true);
              dispatch(
                SAVE_STEP_DATA({
                  ...values,
                  isOldSalesSetup: false
                })
              );
              dispatch(
                SAVE_STEP_DATA_FOR_REQUEST({
                  ...values,
                  data: valueDataForRequest
                })
              );
              // dispatch(
              //   INITIATE_ONBOARDING_MENU_PROCESS({
              //     payload: values,
              //     successCallback: () => {
              //       dispatch(
              //         CONTROL_MODALS({open: true, field: 'isWelcomeModalOpen'})
              //       );
              //       setIsSubmitting(false);
              //     }
              //   })
              // );
              const {data, ...info} = values;
              dispatch(
                SETUP_USER_ACCOUNT({
                  payload: info,
                  successCallback: () => {
                    dispatch(
                      CONTROL_MODALS({open: true, field: 'isWelcomeModalOpen'})
                    );
                    setIsSubmitting(false);
                  }
                })
              );
            }
          }}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            errors,
            setFieldTouched,
            setErrors,
            touched,
            validateField,
            setTouched
          }) => (
            <Form>
              <ConnectedFocusError />
              <SelectComponent
                specialClasses='full-with-select'
                onChange={(event) => {
                  setTouched({});
                  setErrors({});
                  setFieldValue(
                    'data.platformSelect',
                    event.target.value,
                    false
                  );
                }}
                value={values.data.platformSelect}
                options={[
                  {FullName: 'Sauce Tablet', _id: 0},
                  {FullName: 'Toast', _id: 1},
                  {FullName: 'Otter tablet', _id: 2},
                  {FullName: 'Otter to POS', _id: 3},
                  {FullName: 'Ordermark', _id: 4},
                  {FullName: 'Square', _id: 6}
                  // {FullName: 'Checkmate', _id: 5}
                  // {FullName: 'Kitchenhub', _id: 5}
                ]}
              />

              <SetupContainer>
                {/* <MobileProgressBar /> */}

                {(values.data.platformSelect === 0 ||
                  values.data.platformSelect === 2 ||
                  values.data.platformSelect === 5) && (
                  <>
                    <SetupFieldset style={{marginTop: '50px'}}>
                      <Caption4 className='positionRelative'>
                        Where should we take your menu from?
                        <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
                          {errors?.noOptionsAll || errors?.noUberStoreOrMenu ? (
                            <ErrorSign>
                              {errors?.noOptionsAll ||
                                errors?.noUberStoreOrMenu}
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </Caption4>
                      <CardsButtonGrid className='CardsButtonGridOrange CardsButtonGridRed'>
                        <CardsButton
                          className={cn('btnWithLogo', 'increasedMargin', {
                            isActive: values.data.MenuUberEats
                          })}
                          onClick={() => {
                            setErrors({});
                            setTouched({});
                            const changedData = {
                              ...values.data,
                              MenuUberEats: true,
                              MenuEmail: false,
                              MenuShowUrlInput: false
                            };
                            setFieldValue('data', changedData);
                            // onMenuUberEatsClick(changedData);
                          }}
                        >
                          <UberEatsLogo />
                        </CardsButton>
                        <CardsButton
                          className={cn('increasedMargin', {
                            isActive: values.data.MenuShowUrlInput
                          })}
                          onClick={() => {
                            setErrors({});
                            setTouched({});
                            const changedData = {
                              ...values.data,
                              MenuUberEats: false,
                              MenuEmail: false,
                              MenuShowUrlInput: true
                            };
                            setFieldValue('data', changedData);
                          }}
                        >
                          <PlanetLogo />
                          <span>URL</span>
                        </CardsButton>
                        <CardsButton
                          className={cn('increasedMargin', {
                            isActive: values.data.MenuEmail
                          })}
                          onClick={() => {
                            setErrors({});
                            setTouched({});
                            const changedData = {
                              ...values.data,
                              MenuUberEats: false,
                              MenuEmail: true,
                              MenuShowUrlInput: false
                            };
                            setFieldValue('data', changedData);
                          }}
                        >
                          <EmailLogo />
                          <span>Email</span>
                        </CardsButton>
                      </CardsButtonGrid>
                      <DashboardField className='paddingL0'>
                        <Caption1 style={{marginTop: '25px', fontWeight: 400}}>
                          <span className='grey'>* </span>Extracting your menu
                          from UberEats will allow you to go live much faster
                        </Caption1>
                      </DashboardField>
                      <DashboardFields>
                        {values.data.MenuUberEats && (
                          <>
                            <DashboardField className='wAutoSpace paddingL0'>
                              <DashboardField className='w100 dFlex'>
                                <DashboardText className='marginRight2l'>
                                  I currently pay UberEats:
                                </DashboardText>
                              </DashboardField>
                            </DashboardField>
                            <DashboardField className='w100 dFlex align-center'>
                              <FieldInputPercent>
                                <FieldInput
                                  type='text'
                                  value={values.data.UEDeliveryCommission}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'data.UEDeliveryCommission',
                                      UECommissionFormatter(event.target.value)
                                    );
                                  }}
                                  name='data.UEDeliveryCommission'
                                  className={cn('inputAmount', {
                                    error:
                                      errors?.data?.UEDeliveryCommission &&
                                      touched?.data?.UEDeliveryCommission
                                  })}
                                  // className='inputAmount
                                />
                                <ErrorSignWrapper
                                  leftPosition='0px'
                                  topPosition='0px'
                                >
                                  {errors?.data?.UEDeliveryCommission &&
                                  touched?.data?.UEDeliveryCommission ? (
                                    <ErrorSign className='noWrap'>
                                      {errors?.data?.UEDeliveryCommission}
                                    </ErrorSign>
                                  ) : null}
                                </ErrorSignWrapper>
                              </FieldInputPercent>
                              <DashboardText className='marginLeft2l'>
                                <b>Delivery</b> commission.
                                <span className='asterisk'> *</span>
                              </DashboardText>
                            </DashboardField>
                            <DashboardField className='w100 dFlex align-center'>
                              <FieldInputPercent>
                                <FieldInput
                                  type='text'
                                  value={values.data.UEPickupCommission}
                                  onChange={(event) => {
                                    setFieldValue(
                                      'data.UEPickupCommission',
                                      UECommissionFormatter(event.target.value)
                                    );
                                  }}
                                  name='data.UEPickupCommission'
                                  className={cn('inputAmount', {
                                    error:
                                      errors?.data?.UEPickupCommission &&
                                      touched?.data?.UEPickupCommission
                                  })}
                                  // className='inputAmount
                                />
                                <ErrorSignWrapper
                                  leftPosition='0px'
                                  topPosition='0px'
                                >
                                  {errors?.data?.UEPickupCommission &&
                                  touched?.data?.UEPickupCommission ? (
                                    <ErrorSign className='noWrap'>
                                      {errors?.data?.UEPickupCommission}
                                    </ErrorSign>
                                  ) : null}
                                </ErrorSignWrapper>
                              </FieldInputPercent>
                              <DashboardText className='marginLeft2l'>
                                <b>Pickup</b> commission.
                                <span className='asterisk'> *</span>
                              </DashboardText>
                            </DashboardField>
                            <DashboardField>
                              <Caption4
                                style={{marginTop: '30px'}}
                                className='positionRelative'
                              >
                                Invite Sauce from your UberEats Manager
                              </Caption4>
                              <Caption1
                                style={{marginTop: '23px', fontWeight: 400}}
                              >
                                1. Login to{' '}
                                <span style={{fontWeight: 700}}>
                                  <a
                                    href='https://merchants.ubereats.com/manager'
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    merchants.ubereats.com/manager.
                                  </a>
                                </span>
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                2. Click “Users” on the left side menu.
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                3. Click the black “+ Add User” button.
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                4. For full name, enter “Sauce” in both fields.
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                5. Enter “onboarding@getsauce.com“ in the email
                                field.
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                6. Select “Manager” role.
                              </Caption1>
                              <Caption1
                                style={{marginTop: '5px', fontWeight: 400}}
                              >
                                7. Click “Add” to finish the process.
                              </Caption1>
                            </DashboardField>
                            <ObPriceReductionModule
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                            />
                          </>
                        )}
                        {values.data.MenuShowUrlInput && (
                          <>
                            <DashboardField className='w100 mTop30'>
                              <FieldLabel className='FieldLabelLg'>
                                URL
                              </FieldLabel>
                              <FieldInput
                                placeholder='Paste your link here'
                                type='text'
                                value={values.data.MenuUseUrl}
                                onChange={handleChange}
                                name='data.MenuUseUrl'
                                className={cn('blackPlaceholder', {
                                  error:
                                    errors?.data?.MenuUseUrl &&
                                    touched?.data?.MenuUseUrl
                                })}
                              />
                              <ErrorSignWrapper>
                                {errors?.data?.MenuUseUrl &&
                                touched?.data?.MenuUseUrl ? (
                                  <ErrorSign>
                                    {errors?.data?.MenuUseUrl}
                                  </ErrorSign>
                                ) : null}
                              </ErrorSignWrapper>
                            </DashboardField>
                            <ObPriceReductionModule
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                            />
                            <DashboardField className='paddingTBZero mTop50'>
                              <CheckboxS2E
                                onChange={handleChange}
                                checked={values?.data?.menuImagesCheckedURL}
                                name='data.menuImagesCheckedURL'
                                label='Take the menu images from the same source as the data'
                              />
                            </DashboardField>
                            {!values?.data?.menuImagesCheckedURL && (
                              <ImagesConnect
                                setTouched={setTouched}
                                setFieldValue={setFieldValue}
                                values={values}
                                setErrors={setErrors}
                                errors={errors}
                                touched={touched}
                                setFieldTouched={setFieldTouched}
                              />
                            )}
                          </>
                        )}
                        {values.data.MenuEmail && (
                          <>
                            <DashboardField className='w100 dFlex justifyCenter mTop50'>
                              <Caption1
                                style={{fontWeight: 400, marginBottom: '10px'}}
                              >
                                Please send your menu to{' '}
                                <span style={{fontWeight: 700}}>
                                  onboarding@getsauce.com
                                </span>
                              </Caption1>
                            </DashboardField>
                            <ObPriceReductionModule
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                            />
                            <DashboardField className='paddingTBZero mTop50'>
                              <CheckboxS2E
                                onChange={handleChange}
                                checked={values?.data?.menuImagesCheckedEmail}
                                name='data.menuImagesCheckedEmail'
                                label='Take the menu images from the same source as the data'
                              />
                            </DashboardField>
                            {!values?.data?.menuImagesCheckedEmail && (
                              <ImagesConnect
                                setTouched={setTouched}
                                setFieldValue={setFieldValue}
                                values={values}
                                setErrors={setErrors}
                                errors={errors}
                                touched={touched}
                                setFieldTouched={setFieldTouched}
                              />
                            )}
                          </>
                        )}
                      </DashboardFields>
                    </SetupFieldset>
                  </>
                )}
                {values.data.platformSelect === 1 && (
                  <>
                    <SetupFieldset className='mTop40'>
                      <Caption2>
                        <b>Note:</b> You will be connected to the “Online
                        Orders: Toast” menu. All changes you make on Toast will
                        automatically be reflected in your Sauce menu.
                      </Caption2>
                    </SetupFieldset>
                  </>
                )}
                {values.data.platformSelect === 3 && (
                  <>
                    <SetupFieldset className='mTop40'>
                      <Caption4 className='positionRelative smMarginB'>
                        Enter your Otter credentials and the price source link
                      </Caption4>
                      <DashboardFields>
                        <DashboardField className='w50'>
                          <FieldLabel className='FieldLabelLg'>
                            Otter Username <span>*</span>
                          </FieldLabel>
                          <FieldInput
                            // placeholder='Paste your link here'
                            type='text'
                            value={values.data.OtterUsername}
                            onChange={handleChange}
                            name='data.OtterUsername'
                            className={cn('blackPlaceholder', {
                              error:
                                (errors?.data?.OtterUsername &&
                                  touched?.data?.OtterUsername) ||
                                (errors?.data?.OtterUsername &&
                                  errors?.data?.OtterUsername ===
                                    'English characters only')
                            })}
                            autoComplete='new-password'
                          />
                          <ErrorSignWrapper>
                            {(errors?.data?.OtterUsername &&
                              touched?.data?.OtterUsername) ||
                            (errors?.data?.OtterUsername &&
                              errors?.data?.OtterUsername ===
                                'English characters only') ? (
                              <ErrorSign>
                                {errors?.data?.OtterUsername}
                              </ErrorSign>
                            ) : null}
                          </ErrorSignWrapper>
                        </DashboardField>
                        <DashboardField className='w50'>
                          <FieldLabel className='FieldLabelLg'>
                            Otter Password <span>*</span>
                          </FieldLabel>
                          <FieldInput
                            // placeholder='Paste your link here'
                            type='password'
                            value={values.data.OtterPassword}
                            onChange={handleChange}
                            name='data.OtterPassword'
                            className={cn('blackPlaceholder', {
                              error:
                                errors?.data?.OtterPassword &&
                                touched?.data?.OtterPassword
                            })}
                            autoComplete='new-password'
                          />
                          <ErrorSignWrapper>
                            {errors?.data?.OtterPassword &&
                            touched?.data?.OtterPassword ? (
                              <ErrorSign>
                                {errors?.data?.OtterPassword}
                              </ErrorSign>
                            ) : null}
                          </ErrorSignWrapper>
                        </DashboardField>
                      </DashboardFields>
                      <DashboardField className='w100 paddingL0 paddingR0'>
                        <FieldLabel className='FieldLabelLg'>
                          Price Source Link <span>*</span>
                        </FieldLabel>
                        <FieldInput
                          // placeholder='Paste your link here'
                          type='text'
                          value={values.data.OtterPriceSourceLink}
                          onChange={handleChange}
                          name='data.OtterPriceSourceLink'
                          className={cn('blackPlaceholder', {
                            error:
                              errors?.data?.OtterPriceSourceLink &&
                              touched?.data?.OtterPriceSourceLink
                          })}
                        />
                        <ErrorSignWrapper leftPosition='2px'>
                          {errors?.data?.OtterPriceSourceLink &&
                          touched?.data?.OtterPriceSourceLink ? (
                            <ErrorSign>
                              {errors?.data?.OtterPriceSourceLink}
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DashboardField>
                    </SetupFieldset>
                  </>
                )}
                {values.data.platformSelect === 4 && (
                  <>
                    <SetupFieldset className='mTop40'>
                      <Caption4 className='positionRelative smMarginB'>
                        Enter your Ordermark credentials OR location ID
                      </Caption4>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='values.data.OrdermarkType'
                        value={values.data.OrdermarkType}
                        onChange={(event) => {
                          setFieldValue(
                            'data.OrdermarkType',
                            event.target.value,
                            false
                          );
                        }}
                      >
                        <FormControlLabel
                          className='Say2EatRadio'
                          value='Credentials'
                          control={
                            <Radio
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                            />
                          }
                          label='Credentials'
                        />
                        <FormControlLabel
                          className='Say2EatRadio'
                          value='LocationId'
                          control={
                            <Radio
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                            />
                          }
                          label='Location ID'
                        />
                      </RadioGroup>
                      {values.data.OrdermarkType === 'Credentials' && (
                        <>
                          <DashboardFields>
                            <DashboardField className='w50'>
                              <FieldLabel className='FieldLabelLg'>
                                User name <span>*</span>
                              </FieldLabel>
                              <FieldInput
                                // placeholder='Paste your link here'
                                type='text'
                                value={values.data.OrdermarkUsername}
                                onChange={handleChange}
                                name='data.OrdermarkUsername'
                                className={cn('blackPlaceholder', {
                                  error:
                                    (errors?.data?.OrdermarkUsername &&
                                      touched?.data?.OrdermarkUsername) ||
                                    (errors?.data?.OrdermarkUsername &&
                                      errors?.data?.OrdermarkUsername ===
                                        'English characters only')
                                })}
                                autoComplete='new-password'
                              />
                              <ErrorSignWrapper>
                                {(errors?.data?.OrdermarkUsername &&
                                  touched?.data?.OrdermarkUsername) ||
                                (errors?.data?.OrdermarkUsername &&
                                  errors?.data?.OrdermarkUsername ===
                                    'English characters only') ? (
                                  <ErrorSign>
                                    {errors?.data?.OrdermarkUsername}
                                  </ErrorSign>
                                ) : null}
                              </ErrorSignWrapper>
                            </DashboardField>
                            <DashboardField className='w50'>
                              <FieldLabel className='FieldLabelLg'>
                                Password <span>*</span>
                              </FieldLabel>
                              <FieldInput
                                // placeholder='Paste your link here'
                                type='password'
                                value={values.data.OrdermarkPassword}
                                onChange={handleChange}
                                name='data.OrdermarkPassword'
                                className={cn('blackPlaceholder', {
                                  error:
                                    errors?.data?.OrdermarkPassword &&
                                    touched?.data?.OrdermarkPassword
                                })}
                                autoComplete='new-password'
                              />
                              <ErrorSignWrapper>
                                {errors?.data?.OrdermarkPassword &&
                                touched?.data?.OrdermarkPassword ? (
                                  <ErrorSign>
                                    {errors?.data?.OrdermarkPassword}
                                  </ErrorSign>
                                ) : null}
                              </ErrorSignWrapper>
                            </DashboardField>
                          </DashboardFields>
                          <DashboardField className='w100 paddingL0 paddingR0'>
                            <FieldLabel className='FieldLabelLg'>
                              Price Source Link <span>*</span>
                            </FieldLabel>
                            <FieldInput
                              // placeholder='Paste your link here'
                              type='text'
                              value={values.data.OrdermarkPriceSourceLink}
                              onChange={handleChange}
                              name='data.OrdermarkPriceSourceLink'
                              className={cn('blackPlaceholder', {
                                error:
                                  errors?.data?.OrdermarkPriceSourceLink &&
                                  touched?.data?.OrdermarkPriceSourceLink
                              })}
                            />
                            <ErrorSignWrapper leftPosition='2px'>
                              {errors?.data?.OrdermarkPriceSourceLink &&
                              touched?.data?.OrdermarkPriceSourceLink ? (
                                <ErrorSign>
                                  {errors?.data?.OrdermarkPriceSourceLink}
                                </ErrorSign>
                              ) : null}
                            </ErrorSignWrapper>
                          </DashboardField>
                        </>
                      )}
                      {values.data.OrdermarkType === 'LocationId' && (
                        <>
                          <DashboardField className='w100 paddingL0 paddingR0'>
                            <FieldLabel className='FieldLabelLg'>
                              Location ID <span>*</span>
                            </FieldLabel>
                            <FieldInput
                              // placeholder='Paste your link here'
                              type='text'
                              value={values.data.OrdermarkLocationId}
                              onChange={handleChange}
                              name='data.OrdermarkLocationId'
                              className={cn('blackPlaceholder', {
                                error:
                                  (errors?.data?.OrdermarkLocationId &&
                                    touched?.data?.OrdermarkLocationId) ||
                                  (errors?.data?.OrdermarkLocationId &&
                                    errors?.data?.OrdermarkLocationId ===
                                      'English characters only')
                              })}
                            />
                            <ErrorSignWrapper leftPosition='2px'>
                              {(errors?.data?.OrdermarkLocationId &&
                                touched?.data?.OrdermarkLocationId) ||
                              (errors?.data?.OrdermarkLocationId &&
                                errors?.data?.OrdermarkLocationId ===
                                  'English characters only') ? (
                                <ErrorSign>
                                  {errors?.data?.OrdermarkLocationId}
                                </ErrorSign>
                              ) : null}
                            </ErrorSignWrapper>
                          </DashboardField>
                        </>
                      )}
                    </SetupFieldset>
                  </>
                )}

                {values.data.platformSelect === 6 && (
                  <>
                    <SetupFieldset className='mTop40'>
                      <Caption4 className='positionRelative'>
                        Connect Square and Sauce accounts by:
                      </Caption4>
                      <Caption1 style={{marginTop: '23px', fontWeight: 400}}>
                        <ul className='big-steps'>
                          <li>
                            Add Sauce as a user in Square (doesn’t appear in all
                            Square accounts)
                            <ul className='small-steps'>
                              <li>Login to your Square account</li>
                              <li>Click &quot;Team&quot; in the left menu</li>
                              <li>Click &quot;+ Team member&quot;</li>
                              <li>Add onboarding@getsauce.com as a user</li>
                              <li>
                                Fill the data and select <b>administrator</b>{' '}
                                permission
                              </li>
                            </ul>
                          </li>
                          <li>
                            Connect accounts via link:
                            <ul className='small-steps'>
                              <li>Login to your Square account</li>
                              <li>
                                Paste this{' '}
                                <span style={{fontWeight: 700}}>
                                  <a
                                    href={`${process.env.REACT_APP_CONNECT_SQUARE_LINK}`}
                                    target='_blank'
                                    rel='noreferrer'
                                  >
                                    link
                                  </a>
                                </span>{' '}
                                in the same device browser
                              </li>
                              <li>Click the blue &quot;Allow&quot; button</li>
                            </ul>
                          </li>
                        </ul>
                      </Caption1>
                    </SetupFieldset>
                    <SetupFieldset className='mTop40'>
                      <Caption4 className='positionRelative'>
                        Menu configuration
                      </Caption4>

                      <DashboardField className='w50 paddingL0 paddingR0'>
                        <FieldLabel className='FieldLabelLg'>
                          Menu instructions <span>*</span>
                        </FieldLabel>
                        <Textarea
                          // placeholder='Paste your link here'
                          type='text'
                          style={{resize: 'none'}}
                          value={values.data.SquareMenuInstruction}
                          onChange={handleChange}
                          placeholder='The integration pulls from Square 100% of your menu items, tell us which ones do you want to keep.
Add a menu link, or any other menu item instructions.'
                          name='data.SquareMenuInstruction'
                          className={cn('FieldTextareaLg blackPlaceholder', {
                            error:
                              (errors?.data?.SquareMenuInstruction &&
                                touched?.data?.SquareMenuInstruction) ||
                              (errors?.data?.SquareMenuInstruction &&
                                errors?.data?.SquareMenuInstruction ===
                                  'English characters only')
                          })}
                          autoComplete='new-password'
                        />
                        <ErrorSignWrapper>
                          {(errors?.data?.SquareMenuInstruction &&
                            touched?.data?.SquareMenuInstruction) ||
                          (errors?.data?.SquareMenuInstruction &&
                            errors?.data?.SquareMenuInstruction ===
                              'English characters only') ? (
                            <ErrorSign>
                              {errors?.data?.SquareMenuInstruction}
                            </ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                      </DashboardField>
                    </SetupFieldset>
                  </>
                )}

                <SetupActions className='SecondStep CenteredBtn'>
                  <ButtonOutline
                    type='button'
                    className={cn('ButtonOutline', {
                      disabled: isSubmitting
                    })}
                    onClick={() => {
                      dispatch(SAVE_STEP_DATA(values));
                      onBack();
                    }}
                  >
                    Back
                  </ButtonOutline>

                  <ButtonPrimary
                    type='submit'
                    className={cn('ButtonPrimary', {
                      disabled: isSubmitting
                    })}
                  >
                    <span>Finish</span>
                  </ButtonPrimary>
                </SetupActions>
              </SetupContainer>
            </Form>
          )}
        </Formik>

        {isWelcomeModalOpen && (
          <WelcomeModal openModalAction={CONTROL_MODALS} />
        )}
      </SetupContainer>
      <EmailModal
        open={openEmailModal}
        defaultEmail='My Email'
        onClose={() => {
          setOpenEmailModal(false);
          setOpenEmailConfirmPopup(true);
        }}
      />
      <EmailConfirmPopup
        open={openEmailConfirmPopup}
        onClose={() => {
          setOpenEmailConfirmPopup(false);
        }}
      />
      <UberEatsOnboardingStoreSelectModal
        open={uberEatsStoreSelectModalOpen}
        stores={uberEatsStoresOnboarding}
        menus={uberEatsStoreMenusOnboarding}
        selectedStore={uberEatsSelectedStoreId}
        menusLoading={uberEatsMenusLoading}
        selectedMenu={uberEatsSelectedMenu}
        parentValues={formikRef?.current?.values}
      />
    </>
  );
};

export default AddMenu;
