import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {
  CircularProgressContainer,
  FullScreenLoadingContainer
} from './FullScreenLoadingStyles';

const FullScreenLoading = () => {
  return (
    <FullScreenLoadingContainer>
      <CircularProgressContainer className='circularProgressContainer'>
        {/* <CircularProgress */}
        {/*  className='CircularProgress' */}
        {/*  size={95} */}
        {/*  thickness={7} */}
        {/* /> */}
        <CircularProgress className='CircularProgress' />
      </CircularProgressContainer>
    </FullScreenLoadingContainer>
  );
};

export default FullScreenLoading;
