import {
  put,
  select,
  delay,
  race,
  call,
  take,
  cancel,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  getOrderActionsApi,
  getOrdersRTBApi,
  sendOrderActionsApi
} from '../../helpers/services/api/realTimeBoard';
import {
  GET_ORDERS_RTB_SUCCESS,
  RUN_ORDER_RTB_JOB,
  STOP_ORDER_RTB_JOB,
  SEND_ORDER_ACTIONS,
  GET_ORDER_ACTIONS,
  GET_ORDER_ACTIONS_SUCCESS,
  OPEN_OWNED_MODAL
} from './action';

const GET_ORDERS_RTB_INTERVAL = 30000;

export function* getOrderRTB() {
  while (true) {
    try {
      // const {sorting} = yield select(
      //     (state) => state.realTimeBoardReducer
      // );
      // let sortingField = sorting.sortBy;
      // if (sorting?.sort === 'desc') {
      //   sortingField = `-${sortingField}`
      // }
      // const config = `complete=false&active=true&limit=50&sort=${sortingField}`
      const {data} = yield getOrdersRTBApi();

      if (data) {
        yield put(GET_ORDERS_RTB_SUCCESS(data));
      }
    } catch (error) {
      toast.error('Dispatch Sync Error');
      console.log('SAGA ERROR', error);
    }
    yield delay(GET_ORDERS_RTB_INTERVAL);
  }
}

function* runGetOrdersRTBJob() {
  try {
    const {stop} = yield race({
      job: call(() => getOrderRTB()),
      stop: take(STOP_ORDER_RTB_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* sendOrderActions({payload}) {
  try {
    const resp = yield sendOrderActionsApi(payload.data, payload.id);

    let toastText = 'Actions successfully reported!';

    if (payload.data?.actions?.some((action) => action.name === 'Own order')) {
      toastText = 'Order assigned to you!';
    }
    if (
      payload.data?.actions?.some((action) => action.name === 'Release order')
    ) {
      toastText = 'Order un-assigned!';
    }
    if (
      payload.data?.actions?.some((action) => action.name === 'Remove order')
    ) {
      toastText = 'Order removed!';
    }

    if (resp?.data?.msg && resp?.data?.msg === 'already exists') {
      yield put(
        OPEN_OWNED_MODAL({
          open: true,
          text: `Order "${payload?.core_order_id}" is currently handled by ${resp?.data?.support_owner_info?.username}`
        })
      );
    } else {
      toast.success(toastText, {
        autoClose: 2000
      });
    }

    yield put(STOP_ORDER_RTB_JOB());
    yield put(RUN_ORDER_RTB_JOB());
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getOrderActions({payload}) {
  try {
    const {data} = yield getOrderActionsApi(payload.id);

    if (data) {
      yield put(GET_ORDER_ACTIONS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* orderRTBSaga() {
  yield takeLatest(RUN_ORDER_RTB_JOB, runGetOrdersRTBJob);
  yield takeEvery(SEND_ORDER_ACTIONS, sendOrderActions);
  yield takeEvery(GET_ORDER_ACTIONS, getOrderActions);
}

export default orderRTBSaga;
