import {GET, PATCH, POST, PUT} from '../constants/API_CONSTANTS';
import {
  GET_LOCATION_SETTINGS_DATA,
  POST_LOCATION_SETTINGS_DATA,
  UPDATE_LOCATION_SETTINGS_DATA,
  GET_LOCATION_SETTINGS_DATA_BY_ID,
  GET_CALLING_PHONE,
  PUBLISH_LOCATION,
  UPDATE_LOCATION_ACCOUNT_TEMPLATE,
  UPDATE_FEEDBACK_SETTINGS,
  UPDATE_FEEDBACK_BANNER_STATUS,
  CREATE_STORE_HOLIDAYS
} from '../constants/LOCATION_SETTINGS_API_ENDPOINTS';
import {request} from '../utils/request';
import {TASK_TYPE_SETTINGS_DATA_UPDATE} from '../../../store/global/globalNotifications/constants';

export const getLocationSettingsDataApi = async () => {
  return request(GET, GET_LOCATION_SETTINGS_DATA());
};

export const getLocationSettingsDataByIdApi = async (id) => {
  return request(GET, GET_LOCATION_SETTINGS_DATA_BY_ID(id));
};

export const updateLocationSettingsDataApi = async (id, data) => {
  return request(PUT, UPDATE_LOCATION_SETTINGS_DATA(id), {data});
};

export const postLocationSettingsDataApi = async (data) => {
  return request(POST, POST_LOCATION_SETTINGS_DATA(), {data});
};

export const getCallingPhoneApi = async (countryCode) => {
  return request(GET, GET_CALLING_PHONE(countryCode));
};

export const publishLocationAPi = async (menuConfigId) => {
  return request(
    POST,
    PUBLISH_LOCATION(menuConfigId),
    {},
    {headers: {'Sync-Type': TASK_TYPE_SETTINGS_DATA_UPDATE}}
  );
};

export const updateTemplateLocationApi = async (id, data) => {
  return request(PATCH, UPDATE_LOCATION_ACCOUNT_TEMPLATE(id), {data});
};

export const updateFeedbackSettingsApi = async (id, data) => {
  return request(PATCH, UPDATE_FEEDBACK_SETTINGS(id), {data});
};

export const updateFeedbackBannerStatusApi = async (id, data) => {
  return request(PATCH, UPDATE_FEEDBACK_BANNER_STATUS(id), {data});
};

export const createStoreHolidaysApi = async (id, data) => {
  return request(POST, CREATE_STORE_HOLIDAYS(id), {data});
};
