import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import cn from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {openPopupWidget} from 'react-calendly';
import {FieldLabel} from '../../../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../../../components/FieldInput';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../../components/buttons';
import {DashboardField, DashboardFields} from '../../../../../dashboard/styles';
import {
  ConfirmedModalData,
  SignUpData,
  stepData
} from '../../../../../../../store/selfCreateAccount/selectors';
import {bookCallWithUsSchema} from '../../../../../../../helpers/services/formValidationSchemas/SelfCreateAccountSchema';
import {
  CONTROL_SIDEBAR_PROGRESS,
  POST_STEP,
  SAVE_STEP_DATA
} from '../../../../../../../store/selfCreateAccount/action';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../../components/errorSign/errorSign';
import {
  countryCodeFormatter,
  phoneNumberFormatter
} from '../../../../../../../helpers/functionUtils/helpFunctions';
import {SHOW_STEPPER_AFTER_CALENDLY} from '../../../../../../../store/global/account/action';
import {
  SetupActions,
  SetupContainer,
  SetupFieldset
} from '../../../../../../Auth/registration/signUpNew/style';
import Subtitle0 from '../../../../../../../components/typography/Subtitle0';
import {Body1} from '../../../../../../../components/typography';
import Caption4 from '../../../../../../../components/typography/Caption4';
import ConfirmedBookCall from '../СonfirmedBookCall';
import MobileProgressBar from '../../../Components/MobileProgressBar';
import FieldMaskInput from '../../../../../../../components/FieldMaskInput';

const BookCallWithUs = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    step0,
    step1,
    step2: {
      CountryCode,
      Phone,
      FirstName,
      LastName,
      email,
      RestaurantURL,
      role
    },
    restaurantName,
    UserId,
    pendingDataSetuped
  } = stepData();
  const userData = SignUpData();
  const isOpen = ConfirmedModalData();

  useEffect(() => {
    dispatch(
      CONTROL_SIDEBAR_PROGRESS(
        pendingDataSetuped ? 'allDone' : 'secondStepDone'
      )
    );
  }, [pendingDataSetuped]);

  const openCalendlyWidget = (values) => {
    openPopupWidget({
      url: process.env.REACT_APP_CALENDLY_URL,
      prefill: {
        email: values.email,
        name: `${values.FirstName} ${values.LastName}`,
        firstName: values.FirstName,
        lastName: values.LastName,
        customAnswers: {
          a1: restaurantName,
          a2: values.CountryCode + values.Phone,
          a4: values.role
        }
      }
    });
  };
  const formik = useFormik({
    initialValues: {
      CountryCode:
        CountryCode ||
        step0?.User?.PhoneCountryCode ||
        step1?.CountryCode ||
        '',
      Phone: Phone || step1?.Phone || step0?.User?.UserPhoneNumber || '',
      FirstName: FirstName || step0?.User?.FirstName || '',
      LastName: LastName || step0?.User?.LastName || '',
      email: email || userData.email || '',
      RestaurantURL:
        RestaurantURL ||
        step0?.Merchant?.RestaurantURL ||
        userData.RestaurantURL ||
        '',
      role: role || ''
    },
    validationSchema: bookCallWithUsSchema,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const {data, ...info} = {
        userId: UserId || '',
        step: 2,
        data: {
          step: 2,
          userId: UserId || '',
          ...values
        }
      };
      dispatch(SAVE_STEP_DATA({data, ...info}));
      dispatch(
        POST_STEP({
          payload: info,
          successCallback: () => {
            setIsSubmitting(false);
            dispatch(SHOW_STEPPER_AFTER_CALENDLY(true));
            dispatch(CONTROL_SIDEBAR_PROGRESS('allDone'));
            openCalendlyWidget(values);
          }
        })
      );
    }
  });

  const onBack = () => {
    history.push('/my-account/self-setup/general-info');
  };
  return (
    <SetupContainer>
      <form onSubmit={formik.handleSubmit}>
        <MobileProgressBar />
        <Subtitle0>Let us do this for you!</Subtitle0>
        <Body1>
          Book a call with our team and get all the info and assistance you
          need.
        </Body1>
        <SetupFieldset>
          <DashboardFields>
            <DashboardField className='FieldNumber'>
              <FieldLabel>Country Code</FieldLabel>
              <FieldInput
                type='tel'
                autocomplete='tel-country-code'
                name='CountryCode'
                disabled
                onChange={(e) => {
                  const code = countryCodeFormatter(e.target.value);
                  formik.setFieldValue('CountryCode', code);
                }}
                onBlur={formik.handleBlur}
                placeholder='+1'
                value={formik.values.CountryCode}
                className={
                  formik.errors.CountryCode &&
                  formik.touched.CountryCode &&
                  'error'
                }
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.CountryCode && formik.errors.CountryCode}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w66Minus'>
              <FieldLabel>Your Phone number</FieldLabel>
              <FieldMaskInput
                mask='(999) 999-9999'
                type='tel'
                name='Phone'
                autocomplete='tel-national'
                onBlur={(e) => {
                  const regExp = /^\([\d]{3}\) [\d]{3}-[\d]{1,}$/g;
                  if (!regExp.test(e.target.value)) {
                    formik.setFieldValue('Phone', '');
                    formik.setErrors({
                      ...formik.errors,
                      Phone: 'Incorrect phone format'
                    });
                  }
                  formik.handleBlur(e);
                }}
                onChange={(e) => {
                  const code = phoneNumberFormatter(e.target.value);
                  formik.setFieldValue('Phone', code);
                }}
                placeholder='Phone'
                value={formik.values.Phone}
                className={
                  formik.errors.Phone && formik.touched.Phone && 'error'
                }
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.Phone && formik.errors.Phone}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
          </DashboardFields>
          <DashboardFields>
            <DashboardField className='w33'>
              <FieldLabel>First Name</FieldLabel>
              <FieldInput
                name='FirstName'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='First Name'
                value={formik.values.FirstName}
                className={
                  formik.errors.FirstName && formik.touched.FirstName && 'error'
                }
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.FirstName && formik.errors.FirstName}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w33'>
              <FieldLabel>Last Name</FieldLabel>
              <FieldInput
                name='LastName'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='Last Name'
                value={formik.values.LastName}
                className={
                  formik.errors.LastName && formik.touched.LastName && 'error'
                }
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.LastName && formik.errors.LastName}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w33'>
              <FieldLabel>Your email address</FieldLabel>
              <FieldInput
                name='email'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='email'
                value={formik.values.email}
                className={
                  formik.errors.email && formik.touched.email && 'error'
                }
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.email && formik.errors.email}
                </ErrorSign>
              </ErrorSignWrapper>
            </DashboardField>
            <DashboardField className='w100'>
              <FieldLabel>Restaurant’s URL</FieldLabel>
              <FieldInput
                name='RestaurantURL'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder='Restaurant’s URL'
                value={formik.values.RestaurantURL}
                className={cn({
                  error:
                    formik.errors?.RestaurantURL &&
                    formik.touched?.RestaurantURL
                })}
              />
              <ErrorSignWrapper>
                {formik.errors?.RestaurantURL &&
                formik.touched?.RestaurantURL ? (
                  <ErrorSign>{formik.errors?.RestaurantURL}</ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DashboardField>
          </DashboardFields>
        </SetupFieldset>
        <SetupFieldset>
          <Caption4>
            {`One question before we schedule the call: what is your role at
          ${restaurantName}?`}
          </Caption4>
          <DashboardFields>
            <DashboardField className='w33 Say2EatSelect'>
              <FormControl>
                <Select
                  name='role'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder='Role'
                  value={formik.values.role}
                  error={formik.errors.role && formik.touched.role}
                  className={
                    formik.errors.role && formik.touched.role && 'error'
                  }
                  inputProps={{'aria-label': 'value'}}
                >
                  <MenuItem value='owner'>Owner</MenuItem>
                  <MenuItem value='manager'>Manager</MenuItem>
                  <MenuItem value='staff'>Staff</MenuItem>
                </Select>
                <ErrorSignWrapper className='positionBottom'>
                  <ErrorSign>
                    {formik.touched.role && formik.errors.role}
                  </ErrorSign>
                </ErrorSignWrapper>
              </FormControl>
            </DashboardField>
          </DashboardFields>
        </SetupFieldset>
        <SetupActions className='SecondStep'>
          <ButtonOutline
            className={cn('ButtonOutline', {
              disabled: isSubmitting
            })}
            type='button'
            onClick={onBack}
          >
            Back
          </ButtonOutline>
          <ButtonPrimary
            className={cn('ButtonPrimary', {
              disabled: isSubmitting
            })}
            type='submit'
            onClick={formik.handleSubmit}
          >
            <span>Book a Call</span>
          </ButtonPrimary>
        </SetupActions>
      </form>
      {isOpen && <ConfirmedBookCall />}
    </SetupContainer>
  );
};

BookCallWithUs.propTypes = {};

export default BookCallWithUs;
