import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {DialogAction, DialogBody} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import Body1 from '../../../../components/typography/Body1';

const OrderFail = (props) => {
  const {onClose, open, failData} = props;
  return (
    <Dialog open={open} scroll='body' onClose={onClose}>
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={onClose} />
        <DialogTitle>
          <span>Failed</span> to book a driver.
        </DialogTitle>
        <DialogBody>
          <Body1 className='text-center marginTopLg'>
            {' '}
            {!failData && <span>Please try again later.</span>}
            {failData && failData.message && <span>{failData.message}</span>}
            {failData && failData.dropoff_phone_number && (
              <span>{failData.dropoff_phone_number}</span>
            )}
          </Body1>
          <DialogAction>
            <ButtonPrimary className='ButtonOk' onClick={onClose}>
              <span>Ok</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

OrderFail.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  failData: PropTypes.object.isRequired
};

export default OrderFail;
