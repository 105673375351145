import React from 'react';
import T from 'prop-types';
import Input from './style';

const FieldInput = ({
  value,
  name,
  onChange,
  type,
  className,
  placeholder,
  disabled,
  maxLength,
  autoComplete,
  ...props
}) => {
  return (
    <Input
      maxLength={maxLength}
      disabled={disabled}
      className={className}
      type={type}
      value={value}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={autoComplete}
      {...props}
    />
  );
};

FieldInput.propTypes = {
  value: T.oneOfType([T.string, T.number]),
  name: T.string,
  onChange: T.func.isRequired,
  type: T.string,
  className: T.string,
  placeholder: T.string,
  disabled: T.bool,
  maxLength: T.string,
  autoComplete: T.string
};

FieldInput.defaultProps = {
  value: '',
  name: '',
  type: 'text',
  className: '',
  placeholder: '',
  disabled: false,
  maxLength: null,
  autoComplete: 'on'
};

export default FieldInput;
