import styled from 'styled-components';
import CloseIcon from '../../assets/icon-close-white.svg';
import {Body1} from '../typography';

export const BannerWrapper = styled.div`
  position: fixed;
  top: 73px;
  right: 0;
  width: calc(100% - 272px);
  min-height: 60px;
  z-index: 5;
  background-color: #17c981;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 23px;
  @media (max-width: 1250px) {
    top: 96px;
    width: 100%;
  }
  @media (max-width: 1023px) {
    padding: 0 15px;
  }
  @media (max-width: 767px) {
    top: 70px;
    min-height: 40px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }
  @media (max-width: 460px) {
    padding: 0 10px;
  }

  &.hide {
    opacity: 0;
    transition: opacity 0.3s linear;
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    pointer-events: none;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;

  &.NotLink {
    cursor: default;
  }
`;

export const BannerClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${CloseIcon});
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  cursor: pointer;
  width: 14px;
  height: 14px;
  transition-property: transform;
  transition-duration: 0.3s;
  &:hover {
    transform: rotate(90deg);
  }
`;
export const BannerIcon = styled.div`
  flex: 0 0 auto;
  font-size: 0;
  margin-right: 12px;
  @media (max-width: 460px) {
    margin-right: 8px;
  }
  svg {
    max-width: 44px;
    @media (max-width: 1023px) {
      max-width: 36px;
    }
    @media (max-width: 767px) {
      max-width: 26px;
    }
    @media (max-width: 460px) {
      max-width: 24px;
    }
  }
`;

export const BannerUnderlineText = styled(Body1)`
  font-size: 18px;
  color: #fff;
  flex: 0 1 auto;
  text-align: left;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 1023px) {
    font-size: 15px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 1.1;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
  span {
    text-decoration: underline;
    font-weight: 700;
  }
`;
