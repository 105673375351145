import {createReducer} from 'redux-act';
import {
  GET_INFO_VN,
  GET_INFO_VN_SUCCESS,
  SET_LOADING,
  SET_SHOW_MODAL,
  SET_STATUS_FOR_EDIT,
  SET_PHONE_NUMBER_UPDATED,
  DISABLED_VN,
  SET_IS_EDIT_FLAG
} from './action';

const initialState = () => ({
  info: {},
  loading: false,
  isShowSuccessModal: false,
  isEdit: false,
  phoneNumberUpdated: false
});

const usersManagementReducer = createReducer(
  {
    [GET_INFO_VN]: (state) => ({
      ...state
    }),
    [GET_INFO_VN_SUCCESS]: (state, payload) => ({
      ...state,
      info: payload
    }),
    [SET_LOADING]: (state, payload) => ({
      ...state,
      loading: payload
    }),
    [SET_SHOW_MODAL]: (state, payload) => ({
      ...state,
      isShowSuccessModal: payload
    }),
    [SET_STATUS_FOR_EDIT]: (
      state,
      {status = state.info.status, isEdit = state.isEdit}
    ) => ({
      ...state,
      isEdit,
      info: {...state.info, status}
    }),
    [SET_PHONE_NUMBER_UPDATED]: (state, payload) => ({
      ...state,
      phoneNumberUpdated: payload
    }),
    [DISABLED_VN]: (state) => ({
      ...state
    }),
    [SET_IS_EDIT_FLAG]: (state) => ({
      ...state,
      isEdit: false
    })
  },
  initialState()
);

export default usersManagementReducer;
