import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import {Checkbox} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogHint,
  DialogLinkButton,
  FieldInput,
  FieldLabel
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  CREATE_MODIFIER_GROUP_MODAL,
  CREATE_MODIFIER_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import ModifierSchema from '../../../../helpers/services/formValidationSchemas/ModifierSchema';
import FileUploader from '../../../../components/FileUploader';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import {POST_MODIFIER} from '../../../../store/menuEditorModifier/action';
import {ButtonInfo} from '../../../../components/buttons';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';
import {priceFormatter} from '../constants';

const CreateModifierModal = () => {
  const dispatch = useDispatch();

  const isCreateModifierModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateModifierModalOpen
  );
  const getNewModifierFormData = useSelector(
    (state) => state.menuEditorModalsReducer.getNewModifierFormData
  );

  const groupsMap = useSelector((state) => state.menuEditorReducer.groupsMap);
  const groupsDirection = useSelector(
    (state) => state.menuEditorReducer.groupsDirection
  );
  const groupsList = groupsDirection.map((PosId) => groupsMap[PosId]);

  const [fileUrl, setFileUrl] = useState('');

  const [childrenExpanded, setChildrenExpanded] = useState([]);
  const [parentExpanded, setParentExpanded] = useState([]);
  const [selectedModifiersGroups, setSelectedModifierGroups] = useState([]);
  const [
    selectedParentModifiersGroups,
    setSelectedParentModifierGroups
  ] = useState([]);

  useEffect(() => {
    return () => {
      setParentExpanded(false);
      setChildrenExpanded(false);
      setSelectedModifierGroups([]);
      setSelectedParentModifierGroups([]);
    };
  }, []);

  const removeModifierGroup = (id) => {
    setSelectedModifierGroups(
      selectedModifiersGroups.filter((group) => group.PosId !== id)
    );
  };

  const removeParentModifierGroup = (id) => {
    setSelectedParentModifierGroups(
      selectedParentModifiersGroups.filter((group) => group.PosId !== id)
    );
  };

  const formik = useFormik({
    initialValues: {
      Name: '',
      // Notes: '',
      Price: '',
      PictureURL: fileUrl,
      Description: '',
      ModifierGroups: [],
      // Vegan: false,
      // Vegetarian: false,
      // GlutenFree: false,
      isRequiredModifiers: false,
      ExternalId: '',
      ExternalData: '',
      MaxAmount: '1',
      ContainsAlcohol: false
    },
    validationSchema: ModifierSchema,
    onSubmit: (values, {resetForm, setErrors}) => {
      if (values.isRequiredModifiers && selectedModifiersGroups.length === 0) {
        setErrors({ModifierGroups: 'You must select a modifier group!'});
        return;
      }
      if (typeof getNewModifierFormData === 'function') {
        getNewModifierFormData({...values});

        dispatch(
          CREATE_MODIFIER_GROUP_MODAL({
            open: true
          })
        );
      } else {
        dispatch(
          POST_MODIFIER({
            ...values,
            Name: String(values.Name).trim(),
            PictureURL: fileUrl,
            ModifierGroups: [
              ...selectedModifiersGroups.map((group) => group.PosId)
            ],
            ParentModifierGroups: [
              ...selectedParentModifiersGroups.map((group) => group.PosId)
            ]
          })
        );
        sendGtmEvent('menuEditorNewEntityAdded', {
          entity: 'modifier'
        });
      }

      dispatch(CREATE_MODIFIER_MODAL({open: false}));
      resetForm();
    }
  });

  return (
    <Dialog
      onClose={() => dispatch(CREATE_MODIFIER_MODAL({open: false}))}
      open={isCreateModifierModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogLg'>
        <DialogClose
          onClick={() => dispatch(CREATE_MODIFIER_MODAL({open: false}))}
        />
        <DialogTitle className='verticalCenter'>
          Create a New <span>Modifier</span>
          <ButtonInfo className='Outline ButtonInfoDrop'>
            <div className='DropInfo DropInfoCenter'>
              <ul className='mainItems'>
                <li>
                  <strong>Modifiers are </strong>
                  individual Modifier options that are used to change, add to,
                  or further define Items that are being ordered.
                </li>
              </ul>
              <p>
                Modifiers such as these WILL appear on receipts or orders
                tickets.{' '}
              </p>
              <ul className='childItems'>
                <li>
                  <i>
                    The types of Salad Dressing choices such as Ketchup and Mayo
                    are the Modifiers in this example.
                  </i>
                </li>
              </ul>
            </div>
          </ButtonInfo>
        </DialogTitle>
        <DialogBody>
          <form onSubmit={formik.handleSubmit}>
            <DialogFields>
              <DialogField className='w60'>
                <FieldLabel>Modifier name</FieldLabel>
                <FieldInput
                  name='Name'
                  value={formik.values.Name}
                  onChange={formik.handleChange}
                  placeholder='Write a modifier name here'
                />
                <ErrorSignWrapper>
                  {formik.errors.Name && (
                    <ErrorSign>{formik.errors.Name}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
              <DialogField className='wSm'>
                <FieldLabel>Price</FieldLabel>
                <FieldInput
                  name='Price'
                  placeholder='$'
                  value={formik.values.Price}
                  onChange={(e) => {
                    const price = priceFormatter(
                      e.target.value,
                      formik?.values?.Price
                    );
                    formik?.setFieldValue('Price', price);
                  }}
                  onBlur={(e) => {
                    const lastElement = e.target.value.slice(-1)[0];
                    if (lastElement === '.') {
                      formik?.setFieldValue(
                        'Price',
                        e.target.value.slice(0, -1)
                      );
                    }
                  }}
                />
                <ErrorSignWrapper>
                  {formik.errors.Price && (
                    <ErrorSign>{formik.errors.Price}</ErrorSign>
                  )}
                </ErrorSignWrapper>
              </DialogField>
              <DialogField className='w100 dFlex'>
                <CheckboxContainer>
                  <FormControlLabel
                    className='Say2EatCheckbox'
                    control={
                      <Checkbox
                        labelStyle={{color: '#172751'}}
                        iconStyle={{fill: '#FF2D38'}}
                        inputStyle={{color: '#FF2D38'}}
                        style={{color: '#FF2D38'}}
                        onChange={formik.handleChange}
                        name='ContainsAlcohol'
                        checked={formik.values.ContainsAlcohol}
                      />
                    }
                    label='Contains Alcohol'
                  />
                </CheckboxContainer>
              </DialogField>
              <DialogField className='w60'>
                <FieldLabel>Photo</FieldLabel>
                <FileUploader
                  url={fileUrl}
                  onSetFile={setFileUrl}
                  allowedFileFormats={['image/png', 'image/jpeg', 'image/jpg']}
                />
              </DialogField>
              <DialogField className='w100 dFlex noWrapTablet mTop20'>
                <DialogHint className='DialogHintTablet'>
                  <span>
                    Add the unique identifier you can use at your restaurant
                    (ex. SKU)
                  </span>
                </DialogHint>
                <DialogFieldInners className='DialogFieldInnersExternal'>
                  <DialogFieldInner className='w50'>
                    <FieldLabel>External ID</FieldLabel>
                    <FieldInput
                      name='ExternalId'
                      onChange={formik.handleChange}
                      value={formik.values.ExternalId}
                      placeholder='External ID'
                    />
                  </DialogFieldInner>
                  <DialogFieldInner className='w50'>
                    <FieldLabel>Exernal Data</FieldLabel>
                    <FieldInput
                      name='ExternalData'
                      onChange={formik.handleChange}
                      value={formik.values.ExternalData}
                      placeholder='Exernal Data'
                    />
                  </DialogFieldInner>
                </DialogFieldInners>
              </DialogField>

              <DialogField className='w50 Say2EatSelect'>
                {!!selectedModifiersGroups.length && (
                  <AccordionItem className='AccordionItemModal'>
                    <Accordion
                      onChange={() => setChildrenExpanded(!childrenExpanded)}
                      expanded={childrenExpanded}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <AccordionItemName className='AccordionItemName'>
                          {`Modifier Groups (${selectedModifiersGroups.length})`}
                        </AccordionItemName>
                      </AccordionSummary>
                      <AccordionItemBody>
                        <DialogFields>
                          {selectedModifiersGroups.map((group) => (
                            <DialogField
                              key={group.PosId}
                              className='w100 withRemove'
                            >
                              <FieldInput
                                name=''
                                readOnly
                                value={group.OptionName}
                                placeholder={group.OptionName}
                              />
                              <DialogFieldRemove
                                onClick={() => removeModifierGroup(group.PosId)}
                              />
                            </DialogField>
                          ))}
                        </DialogFields>
                      </AccordionItemBody>
                    </Accordion>
                  </AccordionItem>
                )}
                <DialogField className='Center w100'>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          itemType: 'modifierGroupParent',
                          itemList: groupsList,
                          selectedItems: selectedModifiersGroups,
                          loading: false,
                          callBack: setSelectedModifierGroups,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add Modifier Groups
                  </DialogLinkButton>
                </DialogField>
              </DialogField>

              <DialogField className='w50 Say2EatSelect'>
                {!!selectedParentModifiersGroups.length && (
                  <AccordionItem className='AccordionItemModal'>
                    <Accordion
                      onChange={() => setParentExpanded(!parentExpanded)}
                      expanded={parentExpanded}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <AccordionItemName className='AccordionItemName'>
                          {`Parent Modifier Groups (${selectedParentModifiersGroups.length})`}
                        </AccordionItemName>
                      </AccordionSummary>
                      <AccordionItemBody>
                        <DialogFields>
                          {selectedParentModifiersGroups.map((group) => (
                            <DialogField
                              key={group.PosId}
                              className='w100 withRemove'
                            >
                              <FieldInput
                                name=''
                                readOnly
                                value={group.OptionName}
                                placeholder={group.OptionName}
                              />
                              <DialogFieldRemove
                                onClick={() =>
                                  removeParentModifierGroup(group.PosId)
                                }
                              />
                            </DialogField>
                          ))}
                        </DialogFields>
                      </AccordionItemBody>
                    </Accordion>
                  </AccordionItem>
                )}
                <DialogField className='Center w100'>
                  <DialogLinkButton
                    onClick={() => {
                      dispatch(
                        SELECT_ITEM_MODAL_OPEN({
                          open: true,
                          itemType: 'modifierGroup',
                          itemList: groupsList,
                          selectedItems: selectedParentModifiersGroups,
                          loading: false,
                          callBack: setSelectedParentModifierGroups,
                          closeModal: () => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: false,
                                itemType: '',
                                queryParams: {},
                                itemList: [],
                                loading: false,
                                selectedItems: [],
                                callBack: () => {},
                                closeModal: () => {}
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    Add Parent Modifier Groups
                  </DialogLinkButton>
                </DialogField>
              </DialogField>
            </DialogFields>
            <DialogAction>
              <ButtonPrimary type='submit'>
                <span>Create a Modifier</span>
              </ButtonPrimary>
            </DialogAction>
          </form>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default CreateModifierModal;
