import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import cn from 'classnames';
import {useLocation} from 'react-router-dom';
import SelectWrapper from './SelectComponentStyles';

const SelectComponent = (props) => {
  const {
    options = [],
    onChange,
    defaultValue = '',
    value,
    defaultLabel,
    IconComponent,
    specialClasses
  } = props;

  const ITEM_HEIGHT = 38.2;

  return (
    <SelectWrapper>
      <FormControl className={`select-formcontrol ${specialClasses}`}>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          defaultValue={defaultValue}
          IconComponent={IconComponent}
          MenuProps={{
            // disableScrollLock: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 8
              }
            }
          }}
        >
          {options.length > 1 && (
            <MenuItem value='' disabled>
              {defaultLabel}
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem
              key={nanoid()}
              data-timezone={item.Timezone}
              data-isToast={item.isToast}
              data-fullItem={JSON.stringify(item)}
              value={item._id}
            >
              {item.FullName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectWrapper>
  );
};

export default SelectComponent;

SelectComponent.propTypes = {
  value: PropTypes.string,
  defaultLabel: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  IconComponent: PropTypes.elementType,
  specialClasses: PropTypes.string
};

SelectComponent.defaultProps = {
  value: '',
  defaultLabel: '',
  defaultValue: '',
  IconComponent: undefined,
  specialClasses: ''
};
