import {createAction} from 'redux-act';

export const GET_CATEGORY = createAction('menuEditorCategory/GET_CATEGORY');
export const GET_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/GET_CATEGORY_SUCCESS'
);

export const GET_CATEGORY_MODAL = createAction(
  'menuEditorCategory/GET_CATEGORY_MODAL'
);
export const GET_CATEGORY_MODAL_SUCCESS = createAction(
  'menuEditorCategory/GET_CATEGORY_MODAL_SUCCESS'
);

export const GET_CATEGORY_BY_ID = createAction(
  'menuEditorCategoryById/GET_CATEGORY_BY_ID'
);
export const GET_CATEGORY_BY_ID_SUCCESS = createAction(
  'menuEditorCategoryById/GET_CATEGORY_BY_ID'
);

export const POST_CATEGORY = createAction('menuEditorCategory/POST_CATEGORY');
export const POST_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/POST_CATEGORY_SUCCESS'
);

export const UPDATE_CATEGORY = createAction(
  'menuEditorCategory/UPDATE_CATEGORY'
);
export const UPDATE_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/UPDATE_CATEGORY_SUCCESS'
);

export const PATCH_CATEGORY = createAction('menuEditorCategory/PATCH_CATEGORY');
export const PATCH_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/PATCH_CATEGORY_SUCCESS'
);

export const DELETE_CATEGORY = createAction(
  'menuEditorCategory/DELETE_CATEGORY'
);
export const DELETE_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/DELETE_CATEGORY_SUCCESS'
);

export const OPEN_CREATE_CATEGORY_MODAL = createAction(
  'menuEditorCategoryModal/OPEN_CREATE_CATEGORY_MODAL'
);
export const OPEN_EDIT_CATEGORY_MODAL = createAction(
  'menuEditorCategoryModal/OPEN_EDIT_CATEGORY_MODAL'
);

export const CLEAR_CATEGORY_DATA = createAction(
  'menuEditorCategoryModal/CLEAR_CATEGORY_DATA'
);

export const FETCH_SINGLE_CATEGORY_TREE = createAction(
  'menuEditorCategory/FETCH_SINGLE_CATEGORY_TREE'
);
export const FETCH_SINGLE_CATEGORY_SUCCESS = createAction(
  'menuEditorCategory/FETCH_SINGLE_CATEGORY_SUCCESS'
);
export const SET_CATEGORY_SORT = createAction(
  'menuEditorCategory/SET_CATEGORY_SORT'
);
