import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import IconIndicator from '../../../../assets/indicator-icon.svg';
import IconPLay from '../../../../assets/icon-play.svg';
import IconPause from '../../../../assets/icon-pause.svg';

// eslint-disable-next-line import/prefer-default-export
export const DashboardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin: 0 -8px;
    flex-wrap: wrap;
  }
  button,
  a {
    margin-right: 16px;
    @media (max-width: 767px) {
      margin: 8px;
      flex: 0 0 auto;
    }
    @media (max-width: 650px) {
      flex: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }
    @media (max-width: 470px) {
      margin: 4px;
      flex: calc(100% - 8px);
      max-width: calc(100% - 8px);
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .DiscountsSave {
    margin-right: 16px;
    @media (max-width: 767px) {
      flex: 0 0 auto;
      margin-right: 8px;

      button {
        width: 100%;
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
    @media (max-width: 650px) {
      flex: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }
    @media (max-width: 470px) {
      margin: 4px;
      flex: calc(100% - 8px);
      max-width: calc(100% - 8px);
    }
    &:last-child {
      margin-right: 0;
    }
    button {
      margin: 0;
    }
  }
  &.OrderActions {
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
  &.DiscountActions {
    @media (max-width: 767px) {
      flex-wrap: nowrap;
    }
    @media (max-width: 470px) {
      flex-wrap: wrap;
    }
  }
`;

export const BillContainer = styled.div`
  padding: 24px 20px;
  @media print {
    background-color: white !important;
    print-color-adjust: exact;
  }
`;
export const BillFutureOrderHead = styled.div`
  width: 100%;
  height: 64px;
  background-color: black;
  font-weight: bold;
  font-size: 27px;
  color: white;
  text-align: center;
  line-height: 64px;
  font-family: 'PT Sans';
  @media print {
    background-color: black !important;
    color: white;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  &.CanceledOrder {
    font-size: 21px;
  }
`;
export const BillEtaLabel = styled.div`
  height: 23px;
  line-height: 23px;
  background-color: black;
  font-size: 13px;
  color: white;
  text-align: center;
  font-weight: 700;
  padding: 0 6px;
  font-family: 'PT Sans';
  @media print {
    background-color: black !important;
    color: white;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
`;

export const BillEtaValue = styled.div`
  font-family: 'PT Sans';
  font-weight: 400;
  font-size: 13px;
  text-align: right;
  span {
    font-weight: 700;
  }
`;
export const OrderEta = styled.div`
  white-space: pre;
  position: relative;
  @media (max-width: 1440px) {
    white-space: inherit;
  }
`;

export const NewLineBlock = styled.div`
  display: block;
  width: 100%;
  margin-left: 0;
  a {
    margin-left: 0 !important;
  }
`;
export const OrderTabs = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 -10px;
`;
export const OrderTab = styled(NavLink)`
  text-decoration: none;
  flex: 0 0 calc(50% - 20px);
  margin: 0 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 18px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition-property: background-color;
  transition-duration: 0.3s;
  @media (max-width: 1024px) {
    padding: 14px;
  }
  @media (max-width: 767px) {
    flex: 0 0 calc(50% - 14px);
    margin: 0 7px;
  }
  @media (max-width: 599px) {
    padding: 12px;
  }

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 14px;
    right: 14px;
    height: 2px;
    background-color: #ff2d38;
    border-radius: 4px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  &::after {
    position: absolute;
    content: '';
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 8px;
    background-image: url(${IconIndicator});
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  .Caption {
    font-weight: 400;
    margin-bottom: 10px;
    color: #424b5a;
    transition-property: color;
    transition-duration: 0.3s;
    &.marginBottom0 {
      margin-bottom: 0;
    }
    @media (max-width: 1024px) {
      margin-bottom: 5px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      margin-bottom: 2px;
    }
  }
  &:hover {
    background-color: #fff5f6;
  }
  &.isActive {
    background-color: #fff5f6;
    .Caption {
      font-weight: 700;
    }
    &::before,
    &::after {
      opacity: 1;
    }
  }
  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const OrdersContainer = styled.div`
  .MuiTable-root {
    tr {
      &:first-child {
        th {
          &:nth-child(1) {
            width: 23%;
            @media (max-width: 1000px) {
              width: 20%;
            }
          }

          &:nth-child(2) {
            width: 24%;
          }

          &:nth-child(3) {
            width: 13%;
            @media (max-width: 1000px) {
              width: 13%;
            }
          }

          //&:nth-child(4) {
          //  width: 13%;
          //  @media (max-width: 1000px) {
          //    width: 13%;
          //  }
          //}

          &:nth-child(4) {
            width: fit-content;
          }

          &:nth-child(5) {
            width: 18%;
          }

          &:nth-child(6) {
            width: 3%;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    overflow-x: scroll;
    margin: 0 -30px;
    & > div {
      padding: 0 30px 20px 30px;
      min-width: 760px;
    }

    table {
      min-width: 700px;
    }
  }
  @media (max-width: 599px) {
    margin: 0 -20px;
    & > div {
      padding: 0 20px 20px 20px;
    }
  }
`;

export const SoundItems = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 -5px 40px -5px;
  .SoundItem {
    flex: 0 0 calc(33.333333% - 10px);
    margin: 5px;
    border: 1px solid #929cb9;
    border-radius: 5px;
    cursor: pointer;
    transition-property: border-color;
    transition-duration: 0.3s;

    &.isActive {
      position: relative;
      &::before {
        pointer-events: none;
        position: absolute;
        content: '';
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        box-sizing: border-box;
        border: 3px solid #2f3d48;
        border-radius: 5px;
      }
      .SoundItemTitle {
        font-weight: bold;
        color: #2f3d48;
      }
      .SoundItemPlay {
        color: #2f3d48;
      }
    }
    &:hover {
      border-color: #2f3d48;
      .SoundItemTitle,
      .SoundItemPlay {
        color: #2f3d48;
      }
    }
  }
  .SoundItemTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: #929cb9;
    display: block;
    padding: 27px 10px;
    border-bottom: 1px solid rgba(146, 156, 185, 0.5);
    transition-property: color;
    transition-duration: 0.3s;
    @media (max-width: 767px) {
      padding: 18px 5px;
      font-size: 15px;
    }
    @media (max-width: 440px) {
      font-size: 14px;
    }
  }
  .SoundItemPlay {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: #929cb9;
    transition-property: color;
    transition-duration: 0.3s;
    @media (max-width: 767px) {
      padding: 12px 5px;
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 13px;
    }

    &::before {
      display: inline-block;
      content: '';
      width: 30px;
      height: 30px;
      background-image: url(${IconPLay});
      background-size: contain;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 10px;
      @media (max-width: 767px) {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }
      @media (max-width: 440px) {
        width: 20px;
        height: 20px;
      }
    }
    &.isPlay {
      &::before {
        background-image: url(${IconPause});
      }
    }
  }
`;
