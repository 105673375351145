import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subYears
} from 'date-fns';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import {useSelector} from 'react-redux';
import DatePicker from '../feature/DatePicker';
import {
  getCustomRange,
  setCustomRange
} from '../../helpers/localStorage/CustomRangeStorage';
import {
  getStoragePeriod,
  setStoragePeriod
} from '../../helpers/localStorage/DatePeriodStorage';
import {setStorageDate} from '../../helpers/localStorage/DateStorage';
import {ButtonPrimary} from '../buttons';

const DatepickerModule = ({
  date,
  updateDate,
  updateSelectedDateIndex,
  localStorageObject: {
    storageDateName,
    storageCustomRangeName,
    storageDatePeriodName
  },
  defaultSelectIndex,
  updatedAllPeriods
}) => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectIndex);
  const [dateValue, setDateValue] = useState([null, null]);

  const {Timezone, CreationDate, createdAt} = useSelector(
    (state) => state.user.activeLocation
  );

  useEffect(() => {
    setDateValue(getCustomRange(storageCustomRangeName) || [null, null]);
  }, []);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const index = getStoragePeriod(storageDatePeriodName);
    if (index !== undefined && index !== null) {
      setSelectedIndex(index);
    } else {
      setSelectedIndex(defaultSelectIndex);
    }
  }, []);

  const handleListItemClick = (event, index) => {
    if (index !== 6) {
      setOpen(false);
    }
    setSelectedIndex(index);
    updateSelectedDateIndex(index);
    if (storageDatePeriodName) {
      setStoragePeriod(storageDatePeriodName, index);
    }
    // setOpen(true);
  };

  const setDate = () => {
    if (
      selectedDate.length &&
      selectedDate[0] !== null &&
      selectedDate[1] !== null
    ) {
      updateDate(selectedDate, selectedIndex);
      if (storageDateName) {
        setStorageDate(storageDateName, selectedDate);
      }
    }
  };

  const updateDatePeriod = (startPeriod, endPeriod) => {
    setSelectedDate([]);
    const periodCurrentDate = [startPeriod, endPeriod];
    updateDate(periodCurrentDate, selectedIndex);
    if (storageDateName && storageCustomRangeName) {
      setStorageDate(storageDateName, periodCurrentDate);
      setCustomRange(storageCustomRangeName, []);
    }
  };

  // const updateDatePeriodCurrent = (startPeriod, endPeriod) => {
  //   setSelectedDate([]);
  //
  //   const periodCurrentDate = [
  //     startPeriod(new Date(), {weekStartsOn: 1}),
  //     endPeriod(new Date(), {weekStartsOn: 1})
  //   ];
  //   updateDate(periodCurrentDate, selectedIndex);
  //   if (storageDateName && storageCustomRangeName) {
  //     setStorageDate(storageDateName, periodCurrentDate);
  //     setCustomRange(storageCustomRangeName, []);
  //   }
  // };
  //
  // const updateDatePeriodPrevious = (
  //   startPeriod,
  //   endPeriod,
  //   addDate,
  //   allPeriod
  // ) => {
  //   let periodPreviousDate;
  //   if (allPeriod) {
  //     periodPreviousDate = [
  //       subYears(startOfDay(new Date()), 40),
  //       addWeeks(endOfDay(new Date()), 1)
  //     ];
  //   } else {
  //     periodPreviousDate = [
  //       startPeriod(addDate(new Date(), -1), {weekStartsOn: 1}),
  //       endPeriod(addDate(new Date(), -1), {weekStartsOn: 1})
  //     ];
  //   }
  //
  //   setSelectedDate([]);
  //   updateDate(periodPreviousDate, selectedIndex);
  //   if (storageDateName && storageCustomRangeName) {
  //     setStorageDate(storageDateName, periodPreviousDate);
  //     setCustomRange(storageCustomRangeName, []);
  //   }
  // };

  const datepickerOnChange = (datePickerDate) => {
    setDateValue(datePickerDate);
    if (storageCustomRangeName) {
      setCustomRange(storageCustomRangeName, datePickerDate);
    }
  };

  const locationDate = () => {
    const localTime = moment().utc().add(Number(Timezone), 'hours');
    const format = localTime.format();
    return moment(format);
  };

  // const locationDate = () => {
  //   return moment().utcOffset(Timezone, false);
  // };

  const toLocationDate = (alterDate) => {
    return moment(alterDate)
      .utcOffset(Timezone, false)
      .utc()
      .format('ddd MMM DD yyyy');
  };

  const getFromDateForAllPeriods = () => {
    let result;
    if (
      (CreationDate && !createdAt) ||
      moment(CreationDate).isSameOrBefore(createdAt)
    ) {
      result = CreationDate;
    } else if (
      (createdAt && !CreationDate) ||
      moment(createdAt).isSameOrBefore(CreationDate)
    ) {
      result = createdAt;
    } else {
      result = locationDate().subtract(40, 'year').startOf('day').format();
    }
    return result;
  };

  return (
    <div className='Say2EatDrop DropPeriod'>
      <FormControl variant='outlined'>
        <InputLabel
          id='demo-simple-select-outlined-label'
          className='customRangeLabel'
        >
          {
            [
              'All period',
              'Today',
              'This week',
              'Last week',
              'This month',
              'Last month',
              'Custom range'
            ][selectedIndex]
          }
        </InputLabel>
        <Select
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          multiple
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          value={date}
          renderValue={() => {
            return [
              null,
              `${toLocationDate(date[0])}`,
              `${toLocationDate(date[0])} - ${toLocationDate(date[1])}`,
              `${
                moment.parseZone(date[0]).utcOffset()
                  ? moment(date[0])
                      .utcOffset(0, false)
                      .format('ddd MMM DD yyyy')
                  : toLocationDate(date[0])
              } - ${
                moment.parseZone(date[1]).utcOffset()
                  ? moment(date[1]).utcOffset(0, true).format('ddd MMM DD yyyy')
                  : toLocationDate(date[1])
              }`,
              `${toLocationDate(date[0])} - ${toLocationDate(date[1])}`,
              `${toLocationDate(date[0])} - ${toLocationDate(date[1])}`,
              `${toLocationDate(date[0])} - ${toLocationDate(date[1])}`
            ][selectedIndex];
          }}
          // label="Custom range"
          open={open}
          onClose={handleOpen}
          onOpen={handleOpen}
          onChange={() => {}}
        >
          <div className='DropDown DropFilterDate'>
            <div className='DropDownHolder'>
              <div className='DropDownList'>
                <List
                  component='nav'
                  className='DropDownMenu'
                  aria-label='main mailbox folders'
                >
                  <ListItem
                    button
                    selected={selectedIndex === 0}
                    onClick={(event) => {
                      const allPeriod = true;
                      // updateDatePeriodPrevious(...Array(3), allPeriod);
                      updateDatePeriod(
                        updatedAllPeriods ? null : getFromDateForAllPeriods(),
                        updatedAllPeriods
                          ? null
                          : locationDate().endOf('isoWeek').format()
                      );
                      setShowDatePicker(false);
                      handleListItemClick(event, 0);
                    }}
                  >
                    <ListItemText primary='All period' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 1}
                    onClick={(event) => {
                      setShowDatePicker(false);
                      handleListItemClick(event, 1);
                      // updateDatePeriodCurrent(startOfDay, endOfDay);
                      updateDatePeriod(
                        locationDate().utc().startOf('day').format(),
                        locationDate().utc().endOf('day').format()
                      );
                    }}
                  >
                    <ListItemText primary='Today' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 2}
                    onClick={(event) => {
                      setShowDatePicker(false);
                      handleListItemClick(event, 2);
                      // updateDatePeriodCurrent(startOfWeek, endOfWeek);
                      updateDatePeriod(
                        locationDate().utc().startOf('isoWeek').format(),
                        locationDate().utc().endOf('isoWeek').format()
                      );
                    }}
                  >
                    <ListItemText primary='This week' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 3}
                    onClick={(event) => {
                      setShowDatePicker(false);
                      handleListItemClick(event, 3);

                      // const allPeriod = false;
                      // updateDatePeriodPrevious(
                      //   startOfWeek,
                      //   endOfWeek,
                      //   addWeeks,
                      //   allPeriod
                      // );
                      updateDatePeriod(
                        locationDate()
                          .utc()
                          .subtract(1, 'week')
                          .startOf('isoWeek')
                          .format(),
                        locationDate()
                          .utc()
                          .subtract(1, 'week')
                          .endOf('isoWeek')
                          .format()
                      );
                    }}
                  >
                    <ListItemText primary='Last week' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 4}
                    onClick={(event) => {
                      setShowDatePicker(false);
                      handleListItemClick(event, 4);

                      // updateDatePeriodCurrent(startOfMonth, endOfMonth);
                      updateDatePeriod(
                        locationDate().utc().startOf('month').format(),
                        locationDate().utc().endOf('month').format()
                      );
                    }}
                  >
                    <ListItemText primary='This month' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 5}
                    onClick={(event) => {
                      setShowDatePicker(false);
                      handleListItemClick(event, 5);
                      // const allPeriod = false;
                      // updateDatePeriodPrevious(
                      //   startOfMonth,
                      //   endOfMonth,
                      //   addMonths,
                      //   allPeriod
                      // );
                      updateDatePeriod(
                        locationDate()
                          .utc()
                          .subtract(1, 'month')
                          .startOf('month')
                          .format(),
                        locationDate()
                          .utc()
                          .subtract(1, 'month')
                          .endOf('month')
                          .format()
                      );
                    }}
                  >
                    <ListItemText primary='Last month' />
                  </ListItem>
                  <ListItem
                    button
                    selected={selectedIndex === 6}
                    onClick={(event) => {
                      handleListItemClick(event, 6);
                      setOpen(true);
                      setShowDatePicker(!showDatePicker);
                    }}
                  >
                    <ListItemText primary='Custom Range' />
                  </ListItem>
                </List>
              </div>
              {showDatePicker && (
                <div className='DropDownDatepicker'>
                  <DatePicker
                    setSelectedDate={setSelectedDate}
                    dateValue={dateValue}
                    datepickerOnChange={datepickerOnChange}
                  />
                  <div className='DropDownActions'>
                    <ButtonPrimary
                      className='ButtonSmall'
                      onClick={() => {
                        setDate();
                        handleOpen();
                      }}
                    >
                      <span>Apply</span>
                    </ButtonPrimary>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Select>
      </FormControl>
    </div>
  );
};

DatepickerModule.propTypes = {
  date: PropTypes.instanceOf(Array).isRequired,
  updateDate: PropTypes.func.isRequired,
  updateSelectedDateIndex: PropTypes.func,
  localStorageObject: PropTypes.shape({
    storageDateName: PropTypes.string,
    storageCustomRangeName: PropTypes.string,
    storageDatePeriodName: PropTypes.string
  }),
  defaultSelectIndex: PropTypes.number,
  updatedAllPeriods: PropTypes.bool
};

DatepickerModule.defaultProps = {
  updateSelectedDateIndex: () => {},
  localStorageObject: {
    storageDateName: '',
    storageCustomRangeName: '',
    storageDatePeriodName: ''
  },
  defaultSelectIndex: 2,
  updatedAllPeriods: false
};

export default DatepickerModule;
