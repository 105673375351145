import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {ButtonPrimary, ButtonOutline} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import {DialogText} from '../../../dashboard/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {HANDLE_DISCONNECT_MODAL} from '../../../../../store/uberEats/actions';
import DialogActions from '../../../../../components/DialogActions/styles';
import Body4 from '../../../../../components/typography/Body4';

const DisconnectSuccessModal = () => {
  const dispatch = useDispatch();
  const disconnectModalOpen = useSelector(
    (state) => state.uberEatsReducer.disconnectModalOpen
  );

  const onClose = () => {
    dispatch(HANDLE_DISCONNECT_MODAL(0));
  };

  return (
    <Dialog open={disconnectModalOpen === 3}>
      <DialogContainer className='DialogSm2'>
        <DialogBody>
          <DialogTitle>The form was sent successfully</DialogTitle>
          <DialogBody>
            <Body4 className='marginTopXl' textAlign='center'>
              Our team will contact you soon.
            </Body4>
          </DialogBody>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonPrimary
                onClick={() => {
                  dispatch(HANDLE_DISCONNECT_MODAL(0));
                }}
              >
                <span>Got it</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default DisconnectSuccessModal;
