import React, {useEffect} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {LOAD_CHAT} from '../../../store/global/hubspotChat/action';

function HubspotChat() {
  const dispatch = useDispatch();
  const hubspotInfo = useSelector((state) => state.hubspotChatReducer);
  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.widget.open();
    }
    return () => {
      // window?.HubSpotConversations?.widget?.close();
      // window?.HubSpotConversations?.widget?.clear({resetWidget: true});
    };
  }, []);

  return (
    <div>
      {hubspotInfo.isLoading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100vh'
        >
          <CircularProgress className='CircularProgress' />
        </Box>
      )}
      <div style={{display: 'none'}}>Chat</div>
    </div>
  );
}

export default HubspotChat;
