import * as Yup from 'yup';

const UserManagementSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email!')
    .required('Email field is required!'),
  first_name: Yup.string().required('First name field is required!'),
  last_name: Yup.string().required('Last name field is required!'),
  role: Yup.string().required('Role field is required!')
});

export default UserManagementSchema;
