import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import {
  DialogAction,
  DialogBody,
  DialogField,
  FieldInput
} from '../../../dashboard/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import {
  SAVE_MENU_QUEUE_ITEM,
  UPDATE_MENU_QUEUE_ITEM
} from '../../../../../store/menuQueue/action';
import SelectWrapper from '../../../../../components/select/SelectComponentStyles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';

export default function AddMenuQueueItem({open, onClose, modalModel}) {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      wix_id: '',
      menu_site_name: '',
      site_link: '',
      web_site_type: '',
      assign: false,
      ...modalModel
    },
    onSubmit: (values, {resetForm}) => {
      if (values.id) {
        dispatch(UPDATE_MENU_QUEUE_ITEM(values));
      } else {
        dispatch(SAVE_MENU_QUEUE_ITEM(values));
      }
      resetForm();
      onClose();
    }
  });

  useEffect(() => {
    [
      'id',
      'wix_id',
      'menu_site_name',
      'site_link',
      'web_site_type',
      'template',
      'assign'
    ].forEach((field) => formik.setFieldValue(field, modalModel[field], false));
  }, [modalModel]);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Dialog open={open} onClose={closeDialog} scroll='body'>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={closeDialog} />
        <DialogTitle className='marginBottomXl'>
          {modalModel.id ? 'Edit' : 'Add'} Menu Queue Item
        </DialogTitle>
        <DialogBody>
          <DialogField className='DialogField'>
            <FieldInput
              placeholder='Wix Id'
              name='wix_id'
              onChange={formik.handleChange}
              value={formik.values.wix_id}
            />
          </DialogField>
          <DialogField className='DialogField'>
            <FieldInput
              placeholder='Menu Site Name'
              name='menu_site_name'
              onChange={formik.handleChange}
              value={formik.values.menu_site_name}
            />
          </DialogField>
          <DialogField className='DialogField'>
            <FieldInput
              placeholder='Site Link'
              name='site_link'
              onChange={formik.handleChange}
              value={formik.values.site_link}
            />
          </DialogField>
          <DialogField className='DialogField'>
            <SelectWrapper>
              <FormControl className='select-formcontrol'>
                <Select
                  label='Site Type'
                  name='web_site_type'
                  onChange={formik.handleChange}
                  value={formik.values.web_site_type}
                >
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem key={nanoid()} value='Menu'>
                    Menu
                  </MenuItem>
                  <MenuItem key={nanoid()} value='Web'>
                    Web
                  </MenuItem>
                </Select>
              </FormControl>
            </SelectWrapper>
          </DialogField>
          <DialogField className='DialogField'>
            <FieldInput
              placeholder='Template'
              name='template'
              className={
                formik.values.web_site_type !== 'Web' ? 'isDisabled' : ''
              }
              onChange={formik.handleChange}
              value={formik.values.template}
            />
          </DialogField>
          <DialogField className='DialogField'>
            <FormControlLabel
              className='Say2EatCheckbox marginBottomMd'
              control={
                <Checkbox
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#36998B'}}
                  inputStyle={{color: '#36998B'}}
                  style={{color: '#36998B'}}
                  name='assign'
                  onChange={formik.handleChange}
                  checked={formik.values.assign}
                />
              }
              labelPlacement='end'
              label='Assign'
            />
          </DialogField>
          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>Apply</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

AddMenuQueueItem.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object
};

AddMenuQueueItem.defaultProps = {
  open: false,
  onClose: () => {},
  modalModel: {}
};
