import React, {useEffect} from 'react';
import cn from 'classnames';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useSelector, useDispatch} from 'react-redux';
import {openPopupWidget} from 'react-calendly';
import {AccountStep, AccountStepDescription, AccountStepName} from '../styles';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {SHOW_EMAIL_CHECK_MODAL} from '../../../../store/global/modals/action';
import {
  CONFIRM_EMAIL_MODAL,
  CONNECT_STRIPE_ACCOUNT,
  GET_ACCOUNT_STEPS_STATUS,
  RESEND_CONFIRMATION,
  SHOW_STEPPER_AFTER_CALENDLY
} from '../../../../store/global/account/action';
import ConfirmEmailModal from '../modals/ConfirmEmailModal';
import {AccountSteps} from '../../menuEditor/styles';
import Body3 from '../../../../components/typography/Body3';
import Headline from '../../../../components/Headline/styles';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {
  accountInfo,
  userInfo
} from '../../../../store/global/account/selectors';
import {generalInfoSelf} from '../../../../store/selfCreateAccount/selectors';
import {generalInfo} from '../../../../store/setupRestaurant/selectors';
import SubtitleWithLine from '../../../../components/typography/SubtitleWithLine';
import {FEATURE_RESTRICTION_IDS} from '../../../../helpers/constants/restrictions';
import RestrictedArea from '../../../../components/layout/RestrictedArea';

const Account = () => {
  // TODO: Remove when status on stripe connection is available
  const dispatch = useDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {userId} = useSelector(generalInfo);
  const {userId: userIdSelf} = useSelector(generalInfoSelf);
  const {
    isConfirmEmailModalOpen,
    emailSent,
    isEmailSending,
    accountStatus: {
      emailConfirmed,
      wasAddedMembers,
      oldConnectedStripe,
      connectedStripe,
      pendingDataSetuped,
      callBooked
    }
  } = useSelector(accountInfo);
  const {
    locationId,
    locations,
    userData: {
      first_name,
      last_name,
      status,
      isValidEmail,
      isSignUp2,
      email,
      setup_step,
      role,
      merchant_name
    },
    activeLocation: {AccountTemplate}
  } = useSelector(userInfo);

  useEffect(() => {
    dispatch(GET_ACCOUNT_STEPS_STATUS());
  }, [locationId]);

  const onEmailVerifyStepClick = () => {
    if (!isValidEmail) {
      dispatch(SHOW_EMAIL_CHECK_MODAL(true));
    } else {
      dispatch(CONFIRM_EMAIL_MODAL({open: true}));
    }
  };

  const handleCalendlyWidget = () => {
    dispatch(SHOW_STEPPER_AFTER_CALENDLY(false));
    openPopupWidget({
      url: process.env.REACT_APP_CALENDLY_URL,
      prefill: {
        email,
        name: `${first_name} ${last_name}`,
        firstName: first_name,
        lastName: last_name,
        customAnswers: {
          a1: merchant_name.slice(0, merchant_name.indexOf('-')),
          a4: role
        }
      }
    });
  };

  // eslint-disable-next-line consistent-return
  const renderBookCallButton = () => {
    if (userId || userIdSelf || setup_step >= 0) {
      return (
        <ButtonPrimary
          type='button'
          className='ButtonBookCall'
          onClick={() => handleCalendlyWidget()}
        >
          <span>Book {callBooked ? 'another' : 'a'} Call With Us</span>
        </ButtonPrimary>
      );
    }
  };

  const openConnectStripePage = (e) => {
    e.stopPropagation();
    window.open(process.env.REACT_APP_STRIPE_LINK, '_blank');
  };

  const resendEmailLink = (e) => {
    e.stopPropagation();
    dispatch(
      RESEND_CONFIRMATION({
        email,
        callBack: () => toast.success('Email has been sent successfully')
      })
    );
  };

  return (
    <DashboardContent className='DashboardContent'>
      {/* {isSignUp2 ? <SelfCreateAccount /> : <CreateAccount />} */}
      <DashboardHeader className='marginBottomXs'>
        <Headline className='mTop5'>
          Welcome to Sauce {first_name ? `, ${first_name}!` : '!'}
        </Headline>
        {/* {renderBookCallButton()} */}
      </DashboardHeader>
      <Body3>
        Complete these tasks at your own pace to get up and running with Sauce.
      </Body3>
      <SubtitleWithLine className='marginTopXl'>General steps</SubtitleWithLine>
      <AccountSteps>
        {role === 'admin' ? (
          <>
            <AccountStep
              className={cn({
                isDone: pendingDataSetuped || status === 'ACTIVE',
                isActive: !pendingDataSetuped && status !== 'ACTIVE'
              })}
              onClick={() => {
                if (isSignUp2) {
                  history.push(`${path}self-setup`);
                } else {
                  history.push(`${path}sales-setup`);
                }
              }}
            >
              <AccountStepName className='AccountStepName'>
                Create an account
              </AccountStepName>
              <AccountStepDescription className='AccountStepDescription'>
                {pendingDataSetuped
                  ? 'You did it! Now let’s dive in. '
                  : `To keep your info safe we just sent an email to: ${email} 
                        Didn’t receive the email? Resend email verification Or, contact our 
                       support team. `}
              </AccountStepDescription>
            </AccountStep>
            <AccountStep
              className={cn({
                isDisable: !pendingDataSetuped,
                isDone: connectedStripe,
                isActive: !connectedStripe
              })}
              onClick={(e) => {
                openConnectStripePage(e);
                if (!oldConnectedStripe) {
                  dispatch(CONNECT_STRIPE_ACCOUNT());
                }
              }}
            >
              {/* {connectedStripe && ( */}
              {/*  <ButtonOutline */}
              {/*    className='ButtonSmall eventOn' */}
              {/*    onClick={openConnectStripePage} */}
              {/*  > */}
              {/*    Edit */}
              {/*  </ButtonOutline> */}
              {/* )} */}
              <AccountStepName className='AccountStepName StartMakingMoney'>
                Start making money
              </AccountStepName>
              <AccountStepDescription className='AccountStepDescription'>
                Connect your account, so we can promptly forward you your
                earnings
              </AccountStepDescription>
            </AccountStep>
            <AccountStep
              className={cn({
                isDisable: !pendingDataSetuped || !oldConnectedStripe,
                isDone: emailConfirmed,
                isActiveBlue: !emailConfirmed
              })}
              onClick={onEmailVerifyStepClick}
            >
              {/* {emailConfirmed && ( */}
              <ButtonOutline
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(SHOW_EMAIL_CHECK_MODAL(true));
                }}
                className='ButtonSmall eventOn'
              >
                Edit
              </ButtonOutline>
              {/* )} */}
              <AccountStepName className='AccountStepName VerifyEmail'>
                Verify your email to get started
              </AccountStepName>
              <AccountStepDescription className='AccountStepDescription'>
                To keep your info safe, we just sent an email to{' '}
                {email || 'email address'}.<br />
                Didn`t get an email? For a new email verification,{' '}
                <span
                  onClick={(e) => {
                    resendEmailLink(e);
                  }}
                >
                  click here
                </span>
              </AccountStepDescription>
            </AccountStep>

            <AccountStep
              className={cn({
                isDisable: locations?.length > 1,
                isDone: locations?.length > 1
              })}
              onClick={() => history.push('/my-account/add-a-link')}
            >
              <AccountStepName className='AccountStepName AddLink'>
                Add link to your website
              </AccountStepName>
              <AccountStepDescription className='AccountStepDescription'>
                To start getting orders add Sauce URL to your website
              </AccountStepDescription>
            </AccountStep>

            <SubtitleWithLine className='marginTopXl'>
              Optional steps
            </SubtitleWithLine>
            <br />
            {/* <AccountStep */}
            {/*  className={cn({ */}
            {/*    isDisable: !pendingDataSetuped || !oldConnectedStripe, */}
            {/*    isDone: googleMyConnected, */}
            {/*    isActiveBlue: !googleMyConnected */}
            {/*  })} */}
            {/*  onClick={() => { */}
            {/*    dispatch(CONNECT_GOOGLE_MY_BUSINESS(dispatch)); */}
            {/*  }} */}
            {/* > */}
            {/*  <AccountStepName className='AccountStepName'> */}
            {/*    Connect your Google My Business */}
            {/*  </AccountStepName> */}
            {/*  <AccountStepDescription className='AccountStepDescription'> */}
            {/*    Takes about 4 minutes and gives you much secret data about your */}
            {/*    business. */}
            {/*  </AccountStepDescription> */}
            {/* </AccountStep> */}
            {role === 'admin' && (
              <AccountStep
                className={cn({
                  isDisable: !pendingDataSetuped || !oldConnectedStripe,
                  isDone: AccountTemplate,
                  isActiveBlue: !AccountTemplate
                })}
                onClick={() => history.push('/my-account/create-landing')}
              >
                <AccountStepName className='AccountStepName BuildLanding'>
                  Build a Landing Page template
                </AccountStepName>
                <AccountStepDescription className='AccountStepDescription'>
                  {AccountTemplate}
                </AccountStepDescription>
              </AccountStep>
            )}
            {/* <AccountStep */}
            {/*  className={cn({ */}
            {/*    isDisable: !pendingDataSetuped || !bankInfo, */}
            {/*    isDone: bankInfo, */}
            {/*    isActiveBlue: !bankInfo */}
            {/*  })} */}
            {/*  onClick={() => history.push('/my-account/verify-your-bank-account')} */}
            {/* > */}
            {/*  <AccountStepName className='AccountStepName'> */}
            {/*    Verify your bank account */}
            {/*  </AccountStepName> */}
            {/*  <AccountStepDescription className='AccountStepDescription'> */}
            {/*    value */}
            {/*  </AccountStepDescription> */}
            {/* </AccountStep> */}
            <RestrictedArea
              restrictions={[]}
              features={[FEATURE_RESTRICTION_IDS.TEAM]}
            >
              <AccountStep
                className={cn({
                  isDisable: !pendingDataSetuped || !oldConnectedStripe,
                  isDone: wasAddedMembers,
                  isActiveBlue: !wasAddedMembers
                })}
                onClick={() => history.push('/my-account/team-members')}
              >
                <AccountStepName className='AccountStepName AddTeamMembers'>
                  Add your team members
                </AccountStepName>
                <AccountStepDescription className='AccountStepDescription'>
                  More team members, more orders, more fun.
                </AccountStepDescription>
              </AccountStep>
            </RestrictedArea>
          </>
        ) : (
          <AccountStep
            className={cn({
              isDone: emailConfirmed,
              isActiveBlue: !emailConfirmed
            })}
            onClick={onEmailVerifyStepClick}
          >
            {/* {emailConfirmed && ( */}
            <ButtonOutline
              onClick={(event) => {
                event.stopPropagation();
                dispatch(SHOW_EMAIL_CHECK_MODAL(true));
              }}
              className='ButtonSmall eventOn'
            >
              Edit
            </ButtonOutline>
            {/* )} */}
            <AccountStepName className='AccountStepName VerifyEmail'>
              Verify your email to get started
            </AccountStepName>
            <AccountStepDescription className='AccountStepDescription'>
              To keep your info safe we just sent an email to:{' '}
              {email || 'email address'}
              <br />
              Didn’t receive the email? Resend email verification or contact our
              support team
            </AccountStepDescription>
          </AccountStep>
        )}
      </AccountSteps>
      {isConfirmEmailModalOpen && (
        <ConfirmEmailModal
          onCloseAction={() => dispatch(CONFIRM_EMAIL_MODAL({open: false}))}
          emailSent={emailSent}
          isEmailSending={isEmailSending}
        />
      )}
    </DashboardContent>
  );
};

export default Account;
