import {
  GET,
  PUT,
  PATCH,
  POST,
  UPDATE
} from 'helpers/services/constants/API_CONSTANTS';
import {
  API_ACTIVE_ORDERS,
  API_CALCULATE_ORDER_ADJUST,
  API_CANCEL_ORDER_DELIVERY,
  API_CREATE_ORDER_ADJUST,
  API_DISPATCH_INFO,
  API_GET_DELIVERY_STATUS,
  API_GET_ORDER_REFUND_DATA,
  API_ORDER,
  API_ORDER_REFUND,
  API_ORDERS,
  API_TEST_ORDER,
  LOCATION_CLOUD_PRINT,
  LOCATION_DATA,
  LOCATION_NOTIFICATIONS,
  PATCH_LOCATION
} from 'helpers/services/constants/API_ENDPOINTS';
import {request} from 'helpers/services/utils/request';

export const putOrderApi = async (id, data) => {
  return request(PUT, API_ORDER(id), {data});
};

export const getOrderApi = async (id) => {
  return request(GET, API_ORDER(id));
};
export const getDispatchInfoApi = async (id) => {
  return request(GET, API_DISPATCH_INFO(id));
};

export const getActiveOrdersApi = async (config) => {
  return request(GET, API_ACTIVE_ORDERS(), null, {silent: config?.silent});
};

export const getLocationDataApi = async (locationId) => {
  return request(GET, LOCATION_DATA(locationId));
};

export const getLocationNotificationsApi = async (locationId, userId) => {
  return request(GET, LOCATION_NOTIFICATIONS(locationId, userId));
};

export const getCloudPrintConfigurationsApi = async (locationId) => {
  return request(GET, LOCATION_CLOUD_PRINT(locationId));
};

export const updateCloudPrintConfigurationsApi = async (locationId, data) => {
  return request(PUT, LOCATION_CLOUD_PRINT(locationId), {data});
};

export const patchLocationApi = async ({locationId, id, data}) => {
  return request(PATCH, PATCH_LOCATION(locationId, id), {data});
};

export const postTestOrder = async () => {
  return request(POST, API_TEST_ORDER());
};
export const updateOrderRefund = async (invoiceId, data) => {
  return request(POST, API_ORDER_REFUND(invoiceId), {data});
};

export const calculateOrderAdjust = async (invoiceId, data) => {
  return request(POST, API_CALCULATE_ORDER_ADJUST(invoiceId), {data});
};
export const createOrderAdjust = async (invoiceId, data) => {
  return request(POST, API_CREATE_ORDER_ADJUST(invoiceId), {data});
};

export const getOrderRefundData = async (invoiceId) => {
  return request(GET, API_GET_ORDER_REFUND_DATA(invoiceId));
};

export const getDeliveryStatusApi = async (deliveryId) => {
  return request(GET, API_GET_DELIVERY_STATUS(deliveryId));
};
export const cancelOrderDelivery = async (deliveryId, data) => {
  return request(POST, API_CANCEL_ORDER_DELIVERY(deliveryId), {data});
};
