import React from 'react';
import {useSelector} from 'react-redux';

const withRestrictions = (Component) => (props) => {
  const {userData: {restrictions = []} = {}} = useSelector(
    (state) => state.user
  );

  return <Component {...props} restrictions={restrictions} />;
};

export default withRestrictions;
