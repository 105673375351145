import {takeEvery, put, select} from 'redux-saga/effects';
import {diff} from 'deep-object-diff';

import {
  INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO,
  INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS,
  INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING,
  INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO,
  INSIGHTS_PERFORMANCE_GET_INFO,
  INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS,
  INSIGHTS_PERFORMANCE_SET_LOADING_PAGE,
  INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS
} from 'store/insights/performance/action';

import {
  getInsightsPerformanceSettings,
  postInsightsPerformanceSettings,
  getInsightsPerformanceInfo,
  getInsightsPerformanceSettingsDates
} from 'helpers/services/api/insights';
import sendGA from 'helpers/services/utils/ga';
import {INSIGHTS_PERFORMANCE_SETTINGS_NAMES} from 'helpers/constants/Insights/performance';

function* getSettings() {
  try {
    const locationId = yield select((state) => state.user.locationId);
    sendGA('Insights Performance', 'Insights Performance Popup', null, null);
    const {data} = yield getInsightsPerformanceSettings(locationId);
    if (data) {
      yield put(INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING(false));
  }
}

function* postSettings({payload}) {
  try {
    const {status} = yield postInsightsPerformanceSettings(payload);
    if (status === 200) {
      const {settingsInfo} = yield select(
        (state) => state.insightsPerformanceReducer
      );
      yield put(INSIGHTS_PERFORMANCE_GET_INFO(payload.date));
      const diffValue = diff(settingsInfo, payload.body);
      Object.keys(diffValue).forEach((key) => {
        sendGA(
          'Insights Performance',
          'Insights Settings Edited',
          `${INSIGHTS_PERFORMANCE_SETTINGS_NAMES[key]}`,
          diffValue[key]
        );
      });
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getSettingsDates() {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {data} = yield getInsightsPerformanceSettingsDates(locationId);
    if (data) {
      yield put(INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getPerformanceInfo({payload}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    sendGA('Insights Performance', 'Insights Performance', null, null);
    const {data} = yield getInsightsPerformanceInfo(
      locationId,
      payload[0],
      payload[1]
    );
    if (data) {
      yield put(INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS(data));
      yield getSettingsDates();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(INSIGHTS_PERFORMANCE_SET_LOADING_PAGE(false));
  }
}

function* insightsPerformanceSaga() {
  yield takeEvery(INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO, getSettings);
  yield takeEvery(INSIGHTS_PERFORMANCE_POST_SETTINGS_INFO, postSettings);
  yield takeEvery(INSIGHTS_PERFORMANCE_GET_INFO, getPerformanceInfo);
}

export default insightsPerformanceSaga;
