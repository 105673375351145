import React, {useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogAction,
  DialogBody,
  DialogText
} from '../../../routes/App/dashboard/styles';
import ButtonPrimary from '../../buttons/ButtonPrimary/styles';
import {CHANGED_APP_VERSION_MODAL} from '../../../store/global/appVersion/action';
import appVersionData from '../../../store/global/appVersion/selectors';
import DialogContainer from '../../DialogContainer/styles';
import DialogClose from '../../DialogClose/styles';
import DialogTitle from '../../DialogTitle/styles';

const AppVersionNotification = () => {
  const dispatch = useDispatch();
  const {open} = appVersionData();

  const timeout = useRef(null);

  useEffect(() => {
    if (open) {
      timeout.current = setTimeout(() => {
        window.location.reload();
      }, 60000);
    }

    return () => clearTimeout(timeout.current);
  }, []);

  return (
    <Dialog
      onClose={() => {
        dispatch(CHANGED_APP_VERSION_MODAL({open: false}));
        clearTimeout(timeout.current);
      }}
      open={open}
    >
      <DialogContainer className='DialogSm'>
        <DialogClose
          onClick={() => {
            dispatch(CHANGED_APP_VERSION_MODAL({open: false}));
            clearTimeout(timeout.current);
          }}
        />
        <DialogTitle className='DialogTitleRed'>
          Current app version is outdated!
        </DialogTitle>
        <DialogBody>
          <DialogText>
            A new version of the app has been released, please save all unsaved
            changes and click Refresh!
          </DialogText>

          <DialogAction>
            <ButtonPrimary onClick={() => window.location.reload()}>
              <span>Refresh Now!</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default AppVersionNotification;
