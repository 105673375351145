export default function sendGA(
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) {
  try {
    window.ga(
      'send',
      'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    );
  } catch (error) {
    console.error(error);
  }
}
