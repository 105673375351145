import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TabPickup from './TabPickup';
import FeedbackTabs from './FeedbackTabs';
import TabPanel from '../../../../../components/shared/TabPanel';
import {FeedbackPanel} from './FeedbackPanel';
import {
  GET_FEEDBACK_DATA,
  SET_FILTER
} from '../../../../../store/feedback/action';
import TabDelivery from './TabDelivery';
import TabAll from './TabAll';
import FullScreenLoading from '../../../../../components/shared/Utils/FullScreenLoading/FullScreenLoading';

const Statistics = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.feedbackReducer.loading);
  const data = useSelector((state) => state.feedbackReducer.data);
  const tabIndex = useSelector(
    (state) => state.feedbackReducer.filters.tabIndex
  );
  const locationId = useSelector((state) => state.user?.activeLocation?._id);
  const onTabChange = (event, index) => {
    dispatch(SET_FILTER({tabIndex: index}));
  };

  useEffect(() => {
    dispatch(GET_FEEDBACK_DATA());
  }, [tabIndex, locationId]);

  if (loading || !data) {
    return <FullScreenLoading />;
  }
  return (
    <>
      <FeedbackTabs value={tabIndex} handleChange={onTabChange} />
      <FeedbackPanel />

      <TabPanel value={tabIndex} index={0}>
        <TabAll />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <TabDelivery />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <TabPickup />
      </TabPanel>
    </>
  );
};

export default Statistics;
