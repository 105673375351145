import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {Dialog} from '@material-ui/core';
import DialogField from '../../../../../components/DialogField/styles';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../components/FieldInput';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {DialogAction, DialogBody} from '../../../dashboard/styles';
import DialogFields from '../../../../../components/DialogFields/styles';
import {DialogSubTitle} from '../../../../../components/DialogSubTitle/styles';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import {
  DEPLOY_OB_AUTOMATION,
  FACEBOOK_INFO_MODAL
} from '../../../../../store/merchants/action';
import Loading from '../../../../../components/shared/Utils/Loading';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';

const BaseField = ({
  formik,
  name,
  placeholder,
  dialogFieldClass,
  type,
  ...otherProps
}) => (
  <DialogField className={dialogFieldClass}>
    <FieldLabel>{placeholder}</FieldLabel>
    <FieldInput
      type={type}
      value={_.get(formik.values, name)}
      onChange={formik.handleChange}
      name={name}
      placeholder={placeholder}
      className={cn({
        error: _.get(formik.errors, name) && _.get(formik.touched, name)
      })}
      {...otherProps}
    />
    <ErrorSignWrapper>
      {_.get(formik.errors, name) && _.get(formik.touched, name) ? (
        <ErrorSign>{_.get(formik.errors, name)}</ErrorSign>
      ) : null}
    </ErrorSignWrapper>
  </DialogField>
);

BaseField.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dialogFieldClass: PropTypes.string,
  type: PropTypes.string
};

BaseField.defaultProps = {dialogFieldClass: 'w100', type: 'text'};

export default function FacebookInfo({initialValues, loading}) {
  const dispatch = useDispatch();
  const formik = useFormik({
    onSubmit: () => {
      dispatch(DEPLOY_OB_AUTOMATION(formik.values));
    },
    initialValues
  });

  return (
    <Dialog
      open
      scroll='body'
      onClose={() => dispatch(FACEBOOK_INFO_MODAL({}))}
    >
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={() => dispatch(FACEBOOK_INFO_MODAL({}))} />
        <DialogTitle className='marginBottomXl'>Facebook Config</DialogTitle>
        <DialogBody>
          <DialogFields className='mBottom40'>
            <BaseField
              formik={formik}
              name='configName'
              placeholder='Config Name'
              dialogFieldClass='w50'
              disabled
            />
            <BaseField
              formik={formik}
              name='appName'
              placeholder='App Name'
              dialogFieldClass='w50'
              disabled
            />
          </DialogFields>
          <DialogSubTitle>Facebook App Access</DialogSubTitle>
          <DialogFields className='mBottom40'>
            <BaseField
              formik={formik}
              name='facebookPage.id'
              placeholder='Id'
              dialogFieldClass='w50'
            />
            <BaseField
              formik={formik}
              name='facebookPage.accessToken'
              placeholder='Access Token'
              dialogFieldClass='w50'
            />
            <BaseField
              formik={formik}
              name='facebookPage.appId'
              placeholder='App Id'
              dialogFieldClass='w50'
            />
            <BaseField
              formik={formik}
              name='facebookPage.appSecret'
              placeholder='App Secret'
              dialogFieldClass='w50'
            />
          </DialogFields>
        </DialogBody>
        <DialogAction>
          {loading ? (
            <Loading />
          ) : (
            <ButtonPrimary onClick={formik.handleSubmit}>
              <span>Activate</span>
            </ButtonPrimary>
          )}
        </DialogAction>
      </DialogContainer>
    </Dialog>
  );
}

FacebookInfo.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool
};

FacebookInfo.defaultProps = {loading: false, initialValues: {merchant: {}}};
