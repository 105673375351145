import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useEffect} from 'react';
import {CONNECT_GOOGLE_MY_BUSINESS_REDIRECT} from '../../../store/global/account/action';

const GoogleMyBusiness = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const code = new URLSearchParams(history.location.search).get('code');

  useEffect(() => {
    if (code) {
      dispatch(CONNECT_GOOGLE_MY_BUSINESS_REDIRECT({code}));
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  }, [code]);

  return null;
};

export default GoogleMyBusiness;
