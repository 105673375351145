import React from 'react';

export const IconItem = () => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_13924_111688)'>
      <path
        d='M14.35 11.2964C13.0294 11.2964 11.9551 12.3707 11.9551 13.6914C11.9551 15.012 13.0294 16.0863 14.35 16.0863C15.6709 16.0863 16.745 15.012 16.745 13.6914C16.745 12.3707 15.6707 11.2964 14.35 11.2964ZM14.35 14.8888C13.6896 14.8888 13.1526 14.3517 13.1526 13.6914C13.1526 13.031 13.6896 12.4939 14.35 12.4939C15.0104 12.4939 15.5475 13.031 15.5475 13.6914C15.5475 14.3518 15.0104 14.8888 14.35 14.8888Z'
        fill='white'
      />
      <path
        d='M6.16743 11.2964C4.84681 11.2964 3.77246 12.3707 3.77246 13.6914C3.77246 15.012 4.84681 16.0863 6.16743 16.0863C7.48804 16.0863 8.56239 15.012 8.56239 13.6914C8.56239 12.3707 7.48804 11.2964 6.16743 11.2964ZM6.16743 14.8888C5.50703 14.8888 4.96994 14.3517 4.96994 13.6914C4.96994 13.031 5.50703 12.4939 6.16743 12.4939C6.82764 12.4939 7.36491 13.031 7.36491 13.6914C7.36491 14.3518 6.82782 14.8888 6.16743 14.8888Z'
        fill='white'
      />
      <path
        d='M15.9629 4.44104C15.8611 4.23887 15.6541 4.11133 15.4278 4.11133H12.2744V5.30881H15.0585L16.6889 8.55158L17.7591 8.01349L15.9629 4.44104Z'
        fill='white'
      />
      <path
        d='M12.5539 13.1123H8.02344V14.3098H12.5539V13.1123Z'
        fill='white'
      />
      <path
        d='M4.37061 13.1123H2.29501C1.96429 13.1123 1.69629 13.3803 1.69629 13.711C1.69629 14.0417 1.96433 14.3098 2.29501 14.3098H4.37065C4.70137 14.3098 4.96937 14.0417 4.96937 13.711C4.96937 13.3803 4.70133 13.1123 4.37061 13.1123Z'
        fill='white'
      />
      <path
        d='M18.8741 9.45229L17.6964 7.93548C17.5833 7.78938 17.4086 7.70395 17.2236 7.70395H12.8727V3.51278C12.8727 3.18207 12.6047 2.91406 12.274 2.91406H2.29501C1.96429 2.91406 1.69629 3.1821 1.69629 3.51278C1.69629 3.84347 1.96433 4.11151 2.29501 4.11151H11.6753V8.30268C11.6753 8.6334 11.9433 8.9014 12.274 8.9014H16.9304L17.8024 10.0246V13.1125H16.1458C15.8151 13.1125 15.5471 13.3806 15.5471 13.7113C15.5471 14.042 15.8151 14.31 16.1458 14.31H18.4011C18.7318 14.31 18.9998 14.0419 18.9998 13.7113V9.81952C18.9998 9.6866 18.9555 9.55727 18.8741 9.45229Z'
        fill='white'
      />
      <path
        d='M4.33048 10.0786H1.57626C1.24554 10.0786 0.977539 10.3467 0.977539 10.6773C0.977539 11.0081 1.24558 11.2761 1.57626 11.2761H4.33045C4.66116 11.2761 4.92917 11.008 4.92917 10.6773C4.92921 10.3467 4.66116 10.0786 4.33048 10.0786Z'
        fill='white'
      />
      <path
        d='M5.70798 7.72363H0.598723C0.268041 7.72363 0 7.99167 0 8.32239C0 8.65311 0.268041 8.92112 0.598723 8.92112H5.70798C6.0387 8.92112 6.3067 8.65307 6.3067 8.32239C6.3067 7.99171 6.0387 7.72363 5.70798 7.72363Z'
        fill='white'
      />
      <path
        d='M6.68552 5.36865H1.57626C1.24554 5.36865 0.977539 5.63669 0.977539 5.96738C0.977539 6.29809 1.24558 6.5661 1.57626 6.5661H6.68552C7.01624 6.5661 7.28424 6.29806 7.28424 5.96738C7.28428 5.63669 7.01624 5.36865 6.68552 5.36865Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_13924_111688'>
        <rect width='19' height='19' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconItem;
