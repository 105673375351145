import styled from 'styled-components';

export const BADRadioButtons = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;
  min-width: 275px;
  display: flex;
  align-items: center;
  &.mTop {
    margin-top: 18px;
  }

  &.mLeft20 {
    margin-left: 20px;
  }

  &.mRight20 {
    margin-right: 20px;
  }

  &.bold {
    font-weight: 500;
  }
`;

export default BADRadioButtons;
