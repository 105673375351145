import React, {useEffect, useState} from 'react';
import {Dialog} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import DialogBody from '../../../../components/DialogBody/styles';
import NewOrderImageGif from '../../../../assets/bell_red.gif';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {DialogAction} from '../../dashboard/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import {SuccessImageOrder} from '../../account/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import {
  CLEAR_NEW_ORDERS,
  RUN_GET_ACTIVE_ORDERS_JOB,
  STOP_GET_ACTIVE_ORDERS_JOB
} from '../../../../store/global/orders/action';

const GoToAllOrdersButton = ({onClose, history}) => {
  return (
    <ButtonPrimary
      onClick={() => {
        onClose();
        history.push('/orders');
      }}
    >
      <span>Go to Active Orders</span>
    </ButtonPrimary>
  );
};

GoToAllOrdersButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const GoBackToAllOrdersButton = ({onClose}) => {
  return (
    <ButtonPrimary
      onClick={() => {
        onClose();
      }}
    >
      <span>Go Back to Active Orders</span>
    </ButtonPrimary>
  );
};

GoBackToAllOrdersButton.propTypes = {
  onClose: PropTypes.func.isRequired
};

const NewOrderModal = ({play, onSoundStop}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = useLocation();
  const [gif, setGif] = useState(null);
  const onOrdersPage = pathname === '/orders/active';
  const {newOrders} = useSelector((state) => state.activeOrdersReducer);
  const RingOnTablet = useSelector(
    (state) => state.user.userData?.RingOnTablet
  );
  const {userData} = useSelector((state) => state.user);
  const {length} = newOrders || [];

  useEffect(() => {
    if (length) {
      setGif(NewOrderImageGif);
    } else setGif(null);
  }, [length]);

  const onNotificationRing = () => {
    const ring = RingOnTablet;

    if (newOrders.length > 0) {
      if (ring) {
        play();
      }
    }
  };

  useEffect(() => {
    onNotificationRing();
  }, [newOrders]);

  const onClose = () => {
    onSoundStop();
    dispatch(CLEAR_NEW_ORDERS());
    dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
    dispatch(RUN_GET_ACTIVE_ORDERS_JOB({onPollAction: onSoundStop}));
  };

  return (
    <Dialog open={length > 0} scroll='body' onClose={onClose}>
      <DialogContainer className='DialogM DialogDark'>
        <DialogClose onClick={onClose} className='white' />
        <DialogBody>
          <SuccessImageOrder>
            <span>{length}</span>
            <img src={gif} alt='' />
          </SuccessImageOrder>
          <DialogTitle className='marginBottomXs Xl'>
            {`You have ${length} new order${length > 1 ? 's' : ''}!`}
          </DialogTitle>
          <DialogAction>
            {onOrdersPage ? (
              <GoBackToAllOrdersButton onClose={onClose} />
            ) : (
              <GoToAllOrdersButton onClose={onClose} history={history} />
            )}
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

NewOrderModal.propTypes = {
  play: PropTypes.func.isRequired,
  onSoundStop: PropTypes.func.isRequired
};

export default NewOrderModal;
