import {createReducer} from 'redux-act';
import {
  INSIGHTS_PERFORMANCE_GET_INFO,
  INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS,
  INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO,
  INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS,
  INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING,
  INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL,
  INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL,
  INSIGHTS_PERFORMANCE_SET_LOADING_PAGE,
  INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS
} from 'store/insights/performance/action';
import {
  INSIGHTS_PERFORMANCE_CARDS_INFO,
  INSIGHTS_PERFORMANCE_SETTINGS_VALUES
} from 'helpers/constants/Insights/performance';

const initialState = () => ({
  loading: false,
  performanceInfo: [
    {
      title: 'emailMarketingRevenue',
      value: 0
    },
    {
      title: 'googleRevenue',
      value: 0
    },
    {
      title: 'commissionsSaved',
      value: 0
    },

    {
      title: 'positiveGmbReviews',
      value: 0
    },
    {
      title: 'returnCustomersOrders',
      value: 0
    },

    {
      title: 'acceleratedDeliveries',
      value: 0
    },
    {
      title: 'feedbackHandling',
      value: 0
    },
    {
      title: 'virtualAnsweringCalls',
      value: 0
    }
  ],
  settingsLoading: false,
  settingsInfo: {
    averageDeliveryCommission:
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageDeliveryCommission
        .defaultValue,
    averagePickupCommission:
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averagePickupCommission.defaultValue,
    averageMonthlyCateringOrders:
      INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageMonthlyCateringOrders
        .defaultValue
  },
  isSettingsModalOpen: false,
  modalInfo: {
    isOpen: false,
    feature: null
  }
});

const insightsPerformanceReducer = createReducer(
  {
    [INSIGHTS_PERFORMANCE_GET_INFO]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [INSIGHTS_PERFORMANCE_GET_INFO_SUCCESS]: (state, payload) => {
      return {
        ...state,
        performanceInfo: Object.keys(INSIGHTS_PERFORMANCE_CARDS_INFO)
          .filter((el) => payload[el] !== null)
          .map((item) => ({
            title: item,
            value: payload[item] !== null && payload[item] ? payload[item] : 0,
            category: INSIGHTS_PERFORMANCE_CARDS_INFO[item]?.category
          })),
        loading: false
      };
    },
    [INSIGHTS_PERFORMANCE_GET_SETTINGS_DATES_SUCCESS]: (state, payload) => {
      const updatedPerformanceInfo = state.performanceInfo.map((item) => {
        const newValue = payload[item.title]?.startCollectingDate || null;
        if (newValue !== null && INSIGHTS_PERFORMANCE_CARDS_INFO[item.title]) {
          return {
            ...item,
            startCollectingDate: newValue
          };
        }
        return item;
      });
      return {
        ...state,
        performanceInfo: updatedPerformanceInfo
      };
    },
    [INSIGHTS_PERFORMANCE_OPEN_SETTINGS_MODAL]: (state, payload) => {
      return {
        ...state,
        isSettingsModalOpen: payload
      };
    },
    [INSIGHTS_PERFORMANCE_OPEN_CONTACT_US_MODAL]: (state, payload) => {
      return {
        ...state,
        modalInfo: payload
      };
    },
    [INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO]: (state) => {
      return {
        ...state,
        settingsLoading: true
      };
    },
    [INSIGHTS_PERFORMANCE_GET_SETTINGS_INFO_SUCCESS]: (state, payload) => {
      return {
        ...state,
        settingsInfo: {
          ...state.settingsInfo,
          averageDeliveryCommission: payload.averageDeliveryCommission,
          averagePickupCommission: payload.averagePickupCommission,
          averageMonthlyCateringOrders:
            payload.averageMonthlyCateringOrders ||
            INSIGHTS_PERFORMANCE_SETTINGS_VALUES.averageMonthlyCateringOrders
              .defaultValue
        },
        settingsLoading: false
      };
    },
    [INSIGHTS_PERFORMANCE_SET_SETTINGS_LOADING]: (state, payload) => {
      return {
        ...state,
        settingsLoading: payload
      };
    },
    [INSIGHTS_PERFORMANCE_SET_LOADING_PAGE]: (state, payload) => {
      return {
        ...state,
        loading: payload
      };
    }
  },
  initialState()
);

export default insightsPerformanceReducer;
