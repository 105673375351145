import React from 'react';

const IconPublishMessage = () => (
  <svg
    width='64'
    height='64'
    viewBox='0 0 64 64'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M33.0292 62.2708H30.4606C30.2312 62.2708 30.0112 62.1796 29.849 62.0174C29.6868 61.8552 29.5957 61.6353 29.5957 61.4059C29.5957 61.1765 29.6868 60.9565 29.849 60.7943C30.0112 60.6321 30.2312 60.541 30.4606 60.541H33.0292C33.2586 60.541 33.4786 60.6321 33.6408 60.7943C33.803 60.9565 33.8941 61.1765 33.8941 61.4059C33.8941 61.6353 33.803 61.8552 33.6408 62.0174C33.4786 62.1796 33.2586 62.2708 33.0292 62.2708Z'
      fill='#FF2D38'
    />
    <path
      d='M59.6757 62.27H39.0832C38.8539 62.27 38.6339 62.1789 38.4717 62.0167C38.3095 61.8545 38.2184 61.6345 38.2184 61.4052C38.2184 61.1758 38.3095 60.9558 38.4717 60.7936C38.6339 60.6314 38.8539 60.5403 39.0832 60.5403H59.6757C59.9051 60.5403 60.125 60.4492 60.2872 60.287C60.4494 60.1248 60.5406 59.9048 60.5406 59.6754V4.32409C60.5406 4.09471 60.4494 3.87473 60.2872 3.71254C60.125 3.55034 59.9051 3.45922 59.6757 3.45922H4.32433C4.09495 3.45922 3.87497 3.55034 3.71278 3.71254C3.55059 3.87473 3.45947 4.09471 3.45947 4.32409V59.6754C3.45947 59.9048 3.55059 60.1248 3.71278 60.287C3.87497 60.4492 4.09495 60.5403 4.32433 60.5403H15.8443C16.0737 60.5403 16.2937 60.6314 16.4559 60.7936C16.6181 60.9558 16.7092 61.1758 16.7092 61.4052C16.7092 61.6345 16.6181 61.8545 16.4559 62.0167C16.2937 62.1789 16.0737 62.27 15.8443 62.27H4.32433C3.6362 62.27 2.97626 61.9967 2.48968 61.5101C2.00309 61.0235 1.72974 60.3636 1.72974 59.6754V4.32409C1.72974 3.63596 2.00309 2.97601 2.48968 2.48943C2.97626 2.00285 3.6362 1.72949 4.32433 1.72949H59.6757C60.3638 1.72949 61.0238 2.00285 61.5103 2.48943C61.9969 2.97601 62.2703 3.63596 62.2703 4.32409V59.6754C62.2703 60.3636 61.9969 61.0235 61.5103 61.5101C61.0238 61.9967 60.3638 62.27 59.6757 62.27Z'
      fill='#FF2D38'
    />
    <path
      d='M24.4065 62.2708H21.8984C21.6691 62.2708 21.4491 62.1796 21.2869 62.0174C21.1247 61.8552 21.0336 61.6353 21.0336 61.4059C21.0336 61.1765 21.1247 60.9565 21.2869 60.7943C21.4491 60.6321 21.6691 60.541 21.8984 60.541H24.4065C24.6359 60.541 24.8559 60.6321 25.0181 60.7943C25.1803 60.9565 25.2714 61.1765 25.2714 61.4059C25.2714 61.6353 25.1803 61.8552 25.0181 62.0174C24.8559 62.1796 24.6359 62.2708 24.4065 62.2708Z'
      fill='#FF2D38'
    />
    <path
      d='M61.4054 16.4329H2.5946C2.36522 16.4329 2.14524 16.3417 1.98305 16.1795C1.82086 16.0174 1.72974 15.7974 1.72974 15.568C1.72974 15.3386 1.82086 15.1186 1.98305 14.9564C2.14524 14.7942 2.36522 14.7031 2.5946 14.7031H61.4054C61.6348 14.7031 61.8548 14.7942 62.017 14.9564C62.1792 15.1186 62.2703 15.3386 62.2703 15.568C62.2703 15.7974 62.1792 16.0174 62.017 16.1795C61.8548 16.3417 61.6348 16.4329 61.4054 16.4329Z'
      fill='#FF2D38'
    />
    <path
      d='M10.5262 12.3461C9.89465 12.3463 9.27722 12.1592 8.75201 11.8084C8.22679 11.4577 7.81739 10.959 7.57558 10.3756C7.33377 9.79213 7.27042 9.15009 7.39353 8.53063C7.51663 7.91118 7.82068 7.34215 8.2672 6.8955C8.71373 6.44885 9.28268 6.14466 9.9021 6.02138C10.5215 5.8981 11.1636 5.96129 11.7471 6.20294C12.3306 6.44459 12.8293 6.85385 13.1802 7.37897C13.5311 7.90409 13.7184 8.52147 13.7184 9.15304C13.7166 9.99919 13.3797 10.8102 12.7815 11.4086C12.1833 12.007 11.3724 12.3441 10.5262 12.3461ZM10.5262 7.69056C10.2367 7.69038 9.95368 7.77608 9.7129 7.93681C9.47212 8.09754 9.28443 8.32608 9.17356 8.59351C9.0627 8.86093 9.03365 9.15524 9.09008 9.43918C9.14652 9.72312 9.28591 9.98394 9.49061 10.1886C9.69532 10.3934 9.95614 10.5327 10.2401 10.5892C10.524 10.6456 10.8183 10.6166 11.0858 10.5057C11.3532 10.3948 11.5817 10.2071 11.7424 9.96636C11.9032 9.72558 11.9889 9.44254 11.9887 9.15304C11.988 8.76538 11.8337 8.39379 11.5596 8.11966C11.2855 7.84554 10.9139 7.69124 10.5262 7.69056Z'
      fill='#FF2D38'
    />
    <path
      d='M18.631 12.3461C17.9994 12.3463 17.382 12.1592 16.8567 11.8084C16.3315 11.4577 15.9221 10.959 15.6803 10.3756C15.4385 9.79213 15.3752 9.15009 15.4983 8.53063C15.6214 7.91118 15.9254 7.34215 16.3719 6.8955C16.8185 6.44885 17.3874 6.14466 18.0068 6.02138C18.6263 5.8981 19.2683 5.96129 19.8518 6.20294C20.4353 6.44459 20.9341 6.85385 21.285 7.37897C21.6359 7.90409 21.8232 8.52147 21.8232 9.15304C21.8213 9.99919 21.4845 10.8102 20.8862 11.4086C20.288 12.007 19.4771 12.3441 18.631 12.3461ZM18.631 7.69056C18.3415 7.69038 18.0584 7.77608 17.8176 7.93681C17.5769 8.09754 17.3892 8.32608 17.2783 8.59351C17.1674 8.86093 17.1384 9.15524 17.1948 9.43918C17.2513 9.72312 17.3906 9.98394 17.5953 10.1886C17.8001 10.3934 18.0609 10.5327 18.3448 10.5892C18.6288 10.6456 18.9231 10.6166 19.1905 10.5057C19.4579 10.3948 19.6865 10.2071 19.8472 9.96636C20.0079 9.72558 20.0936 9.44254 20.0934 9.15304C20.093 8.76531 19.9388 8.39358 19.6646 8.11941C19.3904 7.84524 19.0187 7.69101 18.631 7.69056Z'
      fill='#FF2D38'
    />
    <path
      d='M26.7356 12.3461C26.1039 12.347 25.4861 12.1604 24.9605 11.8101C24.4348 11.4598 24.0249 10.9614 23.7825 10.378C23.5402 9.7946 23.4763 9.15244 23.599 8.53276C23.7218 7.91308 24.0255 7.34372 24.4719 6.89673C24.9183 6.44973 25.4872 6.14519 26.1068 6.02163C26.7263 5.89808 27.3685 5.96106 27.9522 6.20261C28.5359 6.44416 29.0349 6.85342 29.3859 7.37861C29.737 7.90381 29.9244 8.52133 29.9244 9.15304C29.9225 9.99859 29.5861 10.8091 28.9887 11.4074C28.3912 12.0057 27.5812 12.3432 26.7356 12.3461ZM26.7356 7.69056C26.446 7.6897 26.1626 7.77482 25.9214 7.93513C25.6801 8.09544 25.4919 8.32374 25.3805 8.59111C25.2691 8.85847 25.2395 9.15288 25.2956 9.43705C25.3516 9.72122 25.4908 9.98237 25.6953 10.1874C25.8999 10.3925 26.1607 10.5322 26.4447 10.5889C26.7288 10.6456 27.0233 10.6168 27.2909 10.506C27.5585 10.3953 27.7873 10.2076 27.9481 9.96672C28.109 9.72587 28.1948 9.44269 28.1946 9.15304C28.1942 8.76591 28.0404 8.3947 27.767 8.12063C27.4936 7.84656 27.1227 7.69193 26.7356 7.69056Z'
      fill='#FF2D38'
    />
    <path
      d='M55.8019 10.0178H33.2436C33.0143 10.0178 32.7943 9.9267 32.6321 9.7645C32.4699 9.60231 32.3788 9.38233 32.3788 9.15295C32.3788 8.92358 32.4699 8.70359 32.6321 8.5414C32.7943 8.37921 33.0143 8.28809 33.2436 8.28809H55.8019C56.0313 8.28809 56.2513 8.37921 56.4134 8.5414C56.5756 8.70359 56.6668 8.92358 56.6668 9.15295C56.6668 9.38233 56.5756 9.60231 56.4134 9.7645C56.2513 9.9267 56.0313 10.0178 55.8019 10.0178Z'
      fill='#FF2D38'
    />
    <path
      d='M42.9233 50.8376H21.0768C20.8474 50.8376 20.6274 50.7465 20.4652 50.5843C20.303 50.4221 20.2119 50.2021 20.2119 49.9727V44.6149C20.2119 44.3855 20.303 44.1655 20.4652 44.0033C20.6274 43.8411 20.8474 43.75 21.0768 43.75C21.3062 43.75 21.5261 43.8411 21.6883 44.0033C21.8505 44.1655 21.9416 44.3855 21.9416 44.6149V49.1122H42.0584V44.6149C42.0584 44.3855 42.1495 44.1655 42.3117 44.0033C42.4739 43.8411 42.6939 43.75 42.9233 43.75C43.1527 43.75 43.3727 43.8411 43.5348 44.0033C43.697 44.1655 43.7882 44.3855 43.7882 44.6149V49.977C43.787 50.2057 43.6954 50.4245 43.5333 50.5858C43.3713 50.7471 43.1519 50.8376 42.9233 50.8376Z'
      fill='#FF2D38'
    />
    <path
      d='M32.0001 44.4086C31.7707 44.4086 31.5508 44.3175 31.3886 44.1553C31.2264 43.9931 31.1353 43.7731 31.1353 43.5437V27.0006C31.1353 26.7712 31.2264 26.5512 31.3886 26.3891C31.5508 26.2269 31.7707 26.1357 32.0001 26.1357C32.2295 26.1357 32.4495 26.2269 32.6117 26.3891C32.7739 26.5512 32.865 26.7712 32.865 27.0006V43.5437C32.865 43.7731 32.7739 43.9931 32.6117 44.1553C32.4495 44.3175 32.2295 44.4086 32.0001 44.4086Z'
      fill='#FF2D38'
    />
    <path
      d='M36.2861 32.1515C36.0568 32.1515 35.8368 32.0603 35.6747 31.8981L31.9999 28.2233L28.3251 31.8981C28.162 32.0557 27.9435 32.1429 27.7167 32.1409C27.49 32.1389 27.273 32.048 27.1127 31.8876C26.9523 31.7273 26.8614 31.5103 26.8594 31.2836C26.8574 31.0568 26.9446 30.8383 27.1022 30.6752L31.3884 26.389C31.5506 26.2268 31.7705 26.1357 31.9999 26.1357C32.2292 26.1357 32.4491 26.2268 32.6113 26.389L36.8976 30.6752C37.0185 30.7962 37.1009 30.9503 37.1342 31.118C37.1676 31.2858 37.1504 31.4596 37.085 31.6176C37.0196 31.7756 36.9087 31.9107 36.7665 32.0057C36.6243 32.1008 36.4572 32.1515 36.2861 32.1515Z'
      fill='#FF2D38'
    />
  </svg>
);

export default IconPublishMessage;
