import React, {useState} from 'react';
import {useFormik} from 'formik';
import {useDispatch} from 'react-redux';
import FieldInput from 'components/FieldInput';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import {FieldLabel} from 'components/FieldLabel/styles';
import CheckboxS2E from 'components/Checkbox';
import LinkButton from 'components/LinkButton/styles';
import {ButtonPrimary} from 'components/buttons';
import FieldPassword from 'components/FieldPassword';
import {LoginSchema} from 'helpers/services/formValidationSchemas/AuthSchema';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import {
  AuthBottom,
  AuthFeedback,
  AuthFeedbackButton,
  AuthFeedbackContainer,
  AuthFeedbackTop,
  AuthForm,
  AuthLink,
  AuthLinks,
  AuthLogo,
  AuthMainSection,
  AuthReferContainer,
  AuthSection,
  AuthTitle
} from 'routes/Auth/styles';
import {LOGIN_ACTION} from 'store/global/auth/action';
import {Body0} from 'components/typography';
import SauceLogo from 'assets/logo-sauce.svg';

const Login = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      Email: '',
      Password: '',
      RememberMe: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(LOGIN_ACTION(values));
    }
  });

  const [upFeedback, setUpFeedback] = useState(true);

  return (
    <AuthMainSection className={upFeedback ? 'isActive' : null}>
      <AuthFeedback className='AuthFeedback'>
        <AuthFeedbackTop>
          <AuthFeedbackButton
            className='AuthFeedbackButton'
            onClick={() => setUpFeedback((prev) => !prev)}
          />
        </AuthFeedbackTop>
        <AuthFeedbackContainer>
          <AuthReferContainer>
            <span className='ReferTitle'>Refer a Friend and Earn $500!</span>
            <div className='ReferText'>
              Love Sauce? Don’t keep it to yourself.
              <br />
              If you know another restaurant that would benefit from Sauce,{' '}
              <a
                rel='noreferrer'
                target='_blank'
                href='http://www.getsauce.com/refer?utm_source=dashboard&utm_medium=login&utm_campaign=referral_dashboard&utm_content=sauce'
              >
                refer them to us
              </a>{' '}
              and you could pocket $500!
              <span className='RedText'>*</span>
              <br />
              <span className='SmText'>
                <span className='RedText'>*</span>Terms apply
              </span>
            </div>
          </AuthReferContainer>
        </AuthFeedbackContainer>
      </AuthFeedback>
      <AuthSection className='Referral'>
        <form onSubmit={formik.handleSubmit}>
          <AuthLogo src={SauceLogo} />
          <AuthForm>
            <AuthTitle>Log in</AuthTitle>
            <DialogFields>
              <DialogField className='w100 mTopMd'>
                <FieldLabel className='FieldLabelLg mBottomMd'>
                  Email
                </FieldLabel>
                <FieldInput
                  className={
                    formik.errors.Email && formik.touched.Email && 'error'
                  }
                  name='Email'
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Email'
                />
                {formik.errors.Email && formik.touched.Email && (
                  <ErrorSignWrapper>
                    <ErrorSign>{formik.errors.Email}</ErrorSign>
                  </ErrorSignWrapper>
                )}
              </DialogField>
              <DialogField className='w100'>
                <FieldLabel className='FieldLabelLg mBottomMd'>
                  Password
                </FieldLabel>
                <FieldPassword
                  className={
                    formik.errors.Password && formik.touched.Password && 'error'
                  }
                  name='Password'
                  value={formik.values.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Password'
                />
                {formik.errors.Password && formik.touched.Password && (
                  <ErrorSignWrapper>
                    <ErrorSign>{formik.errors.Password}</ErrorSign>
                  </ErrorSignWrapper>
                )}
              </DialogField>
              <DialogField className='w100 dFlex justifySB'>
                <CheckboxS2E
                  name='RememberMe'
                  onChange={(event, checked) =>
                    formik.setFieldValue('RememberMe', checked)
                  }
                  checked={formik.values.RememberMe}
                  label='Remember me'
                />
                <LinkButton to='/forgot-password'>Forgot password</LinkButton>
              </DialogField>
              <DialogField className='w100 Center mTopMd'>
                <ButtonPrimary type='submit' className='widthXlCenter'>
                  <span>Log in</span>
                </ButtonPrimary>
              </DialogField>
              <DialogField className='w100 Center mTop20'>
                <Body0>
                  Do not have an account?{' '}
                  <LinkButton className='Md' to='/sign-up-sales-new'>
                    Sign Up!
                  </LinkButton>
                </Body0>
              </DialogField>
            </DialogFields>
          </AuthForm>
        </form>
        <AuthBottom>
          <span>© {new Date().getFullYear()} Sauce</span>
          <AuthLinks>
            <AuthLink
              href={process.env.REACT_APP_TERMS_SERVICE_URL}
              target='_blank'
            >
              Terms of Service
            </AuthLink>
            <AuthLink
              href={process.env.REACT_APP_PRIVACY_POLICY_URL}
              target='_blank'
            >
              Privacy Policy
            </AuthLink>
          </AuthLinks>
        </AuthBottom>
      </AuthSection>
    </AuthMainSection>
  );
};

export default Login;
