import {toast} from 'react-toastify';
import {put, takeEvery, select} from 'redux-saga/effects';
import {
  CHANGED_APP_VERSION_MODAL,
  GET_APP_VERSION,
  GET_APP_VERSION_SUCCESS
} from './action';
import {request} from '../../../helpers/services/utils/request';
import {GET} from '../../../helpers/services/constants/API_CONSTANTS';

const getCurrentAppVersion = ({appVersionReducer}) => appVersionReducer.version;

function* getVersion() {
  try {
    const currentAppVersion = yield select(getCurrentAppVersion);
    const {data} = yield request(GET, `${window.location.origin}/version.txt`);
    const version = String(data).trim();

    console.log('VERSION CHECKED', currentAppVersion, version);

    if (!currentAppVersion && version) {
      console.log('VERSION get first time', currentAppVersion, version);

      localStorage.setItem('appVersion', version);
      yield put(GET_APP_VERSION_SUCCESS(version));

      return;
    }

    if (currentAppVersion && version && currentAppVersion !== version) {
      console.log('VERSION updated', currentAppVersion, version);

      localStorage.setItem('appVersion', version);
      yield put(GET_APP_VERSION_SUCCESS(version));
      yield put(CHANGED_APP_VERSION_MODAL({open: true}));

      return;
    }

    if (currentAppVersion === version) {
      console.log('VERSION is not updated', currentAppVersion, version);

      return;
    }
  } catch (error) {
    toast.error(`Error occurred - please try again [${error}]`);

    yield put(GET_APP_VERSION_SUCCESS('0.0.0-error'));
  }
}

function* appVersionSaga() {
  yield takeEvery(GET_APP_VERSION, getVersion);
}

export default appVersionSaga;
