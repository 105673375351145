import styled from 'styled-components';
import MainRLPaddings from '../MainRLPaddings/styles';

const MainContainer = styled(MainRLPaddings)`
  display: block;
  padding-top: 22px;

  @media (max-width: 1600px) {
    padding-top: 22px;
  }

  @media (max-width: 1250px) {
    padding-top: 12px;
  }
`;

export default MainContainer;
