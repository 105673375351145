import {
  put,
  select,
  takeEvery,
  delay,
  race,
  call,
  take,
  cancel,
  takeLatest
} from 'redux-saga/effects';
import {getBADOrdersActiveApi} from '../../../helpers/services/api/bookADrive';
import {
  GET_BAD_ACTIVE_ORDERS_SUCCESS,
  GET_BAD_ACTIVE_ORDERS_COUNT,
  RUN_BAD_ACTIVE_ORDERS_JOB,
  STOP_BAD_ACTIVE_ORDERS_JOB,
  SET_BAD_TABLE_CONFIG,
  GET_BAD_ACTIVE_ORDERS
} from './action';
import {getStorageToken} from '../../../helpers/localStorage/authStorage';

const GET_NEW_ORDERS_INTERVAL = 60000;

function* getBookADriverActiveOrders(payload) {
  while (true) {
    try {
      yield put(
        SET_BAD_TABLE_CONFIG({to: encodeURIComponent(new Date().toISOString())})
      );
      const {config} = yield select(
        (state) => state.activeBookADriverOrdersReducer
      );
      const {data} = yield getBADOrdersActiveApi(config);
      if (data) {
        yield put(GET_BAD_ACTIVE_ORDERS_SUCCESS(data.data.rows));
        yield put(GET_BAD_ACTIVE_ORDERS_COUNT(data.data.totalCount));
      }
    } catch (error) {
      console.log('SAGA ERROR', error);
      return false;
    }

    yield delay(GET_NEW_ORDERS_INTERVAL);
  }
}

function* runGetActiveOrdersJob({payload}) {
  try {
    const isOnline = yield select(
      (state) => state.globalNotificationsReducer?.isNetworkOnline
    );
    const userData = yield select((state) => state.user.userData);
    const {token} = getStorageToken();
    if (!isOnline || !token || !userData.role || userData.role === 'sudo') {
      return;
    }

    const {stop} = yield race({
      job: call(() => getBookADriverActiveOrders(payload)),
      stop: take(STOP_BAD_ACTIVE_ORDERS_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* activeBookADriverOrdersSaga() {
  yield takeEvery(GET_BAD_ACTIVE_ORDERS, getBookADriverActiveOrders);
  yield takeLatest(RUN_BAD_ACTIVE_ORDERS_JOB, runGetActiveOrdersJob);
}

export default activeBookADriverOrdersSaga;
