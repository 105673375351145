import {
  GET,
  SERVER_DISPATCH_URL,
  DISPATCH_TOKEN,
  POST
} from '../constants/API_CONSTANTS';
import {GET_ORDERS_RTB} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getOrdersRTBApi = async () => {
  return request(
    GET,
    GET_ORDERS_RTB(),
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_RDB_SERVICE_TOKEN}`
      }
    }
  );
};

export const sendOrderActionsApi = async (data, id) => {
  return request(
    POST,
    `${SERVER_DISPATCH_URL}/api/order/ldbevents?delivery_id=${id}`,
    {
      data: {
        data
      }
    },
    {
      headers: {
        Authorization: DISPATCH_TOKEN
      }
    }
  );
};

export const getOrderActionsApi = async (id) => {
  return request(
    GET,
    `${SERVER_DISPATCH_URL}/api/order/ldbevents?delivery_id=${id}`,
    {},
    {
      headers: {
        Authorization: DISPATCH_TOKEN
      }
    }
  );
};

export default getOrdersRTBApi;
