import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';
import {useLocation} from 'react-router-dom';
import cn from 'classnames';
import {
  Logo,
  SidebarArrow,
  SidebarContainer,
  SidebarHolder,
  SidebarMenu,
  SidebarOverlay,
  SidebarProfile,
  SidebarProfileInfo,
  SidebarProfileLogoutButton,
  SidebarProfileName,
  SidebarProfilePicture,
  SidebarProfilePosition,
  SidebarWrapper
} from './styles';
import SauceLogo from '../../../assets/logo-sauce.svg';
import IconSidebarReports from '../../../assets/IconSidebarReports';
import IconSidebarAnalytics from '../../../assets/IconSidebarAnalytics';
import IconSidebarMenuEditor from '../../../assets/IconSidebarMenuEditor';
import IconSidebarSettings from '../../../assets/IconSidebarSettings';
import IconSidebarHelp from '../../../assets/IconSidebarHelp';
import {LOGOUT_USER} from '../../../store/global/auth/action';
import IconSidebarOrders from '../../../assets/IconSidebarOrders';
import RestrictedArea from '../RestrictedArea';
import IconSidebarTeam from '../../../assets/IconSidebarTeam';
import IconSidebarAccount from '../../../assets/IconSidebarAccount';
import IconSidebarVNumber from '../../../assets/IconSidebarVNumber';
import IconSidebarDiscounts from '../../../assets/IconSidebarDiscounts';
import {IconSidebarBookADriver} from '../../../assets/IconSidebarBookADriver';
import {
  FEATURE_RESTRICTION_IDS,
  getInsightsRestriction
} from '../../../helpers/constants/restrictions';
import IconSidebarUberEats from '../../../assets/IconSidebarUberEats';
import SidebarMenuItem from './SidebarMenuItem';
import IconSidebarFeedback from '../../../assets/IconSidebarFeedback';
import IconSidebarEmailMarketing from '../../../assets/IconSidebarEmailMarketing';
import IconSidebarInsights from '../../../assets/IconSidebarInsights';
import IconSidebarArrow from '../../../assets/icon-sidebar-arrow.svg';
import IconIntegrations from '../../../assets/IconIntegrations';

const Sidebar = ({showSidebar, setShowSidebar}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();

  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );

  const serviceFeatures = useSelector(
    (state) => state.user?.activeLocation?.AllowedServiceFeatures
  );

  const closeSidebarEvent = () => {
    setShowSidebar(!showSidebar);
  };
  // const activeLocation = useSelector((state) => state.user?.activeLocation);

  const email = user?.userData?.email;
  const emailName = email.substring(0, email.lastIndexOf('@'));
  const emailDomain = email.substring(email.lastIndexOf('@'));

  return (
    <SidebarWrapper className={showSidebar ? 'Sidebar isOpen' : 'Sidebar'}>
      <SidebarContainer>
        <Logo>
          <img src={SauceLogo} alt='logo' />
        </Logo>
        <SidebarHolder>
          <>
            <SidebarMenu>
              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.ORDERS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarOrders />}
                  text='Orders'
                  route='/orders'
                  feature={FEATURE_RESTRICTION_IDS.ORDERS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarBookADriver />}
                  text='Book a Driver'
                  route='/book-a-driver'
                  feature={FEATURE_RESTRICTION_IDS.BOOK_A_DRIVER}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.MENU_EDITOR]}
                extraAccessLogic={() => !user.isToast}
              >
                <SidebarMenuItem
                  icon={<IconSidebarMenuEditor />}
                  text='Menu Editor'
                  route='/menu-editor/categories'
                  feature={FEATURE_RESTRICTION_IDS.MENU_EDITOR}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={['locationSettingsEdit']}
                features={[FEATURE_RESTRICTION_IDS.STORE_SETTINGS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarSettings />}
                  text='Store Settings'
                  route='/store-settings'
                  feature={FEATURE_RESTRICTION_IDS.STORE_SETTINGS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>
              <RestrictedArea
                restrictions={[]}
                features={[getInsightsRestriction(serviceFeatures)]}
              >
                <SidebarMenuItem
                  feature={getInsightsRestriction(serviceFeatures)}
                  text='Insights'
                  route='/insights'
                  icon={<IconSidebarInsights />}
                  subItems={
                    <SidebarMenu
                      className={cn({
                        isOpen: location.pathname.indexOf('/insights') === 0
                      })}
                    >
                      {serviceFeatures.includes(
                        FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
                      ) && (
                        <SidebarMenuItem
                          route='/insights/performance'
                          text='Performance'
                          icon={null}
                        />
                      )}
                      {serviceFeatures.includes(
                        FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH
                      ) && (
                        <SidebarMenuItem
                          route='/insights/growth'
                          text='Growth'
                          icon={null}
                        />
                      )}
                    </SidebarMenu>
                  }
                  arrowIcon={
                    serviceFeatures.includes(
                      FEATURE_RESTRICTION_IDS.INSIGHTS_GROWTH
                    ) &&
                    serviceFeatures.includes(
                      FEATURE_RESTRICTION_IDS.INSIGHTS_PERFORMANCE
                    ) ? (
                      <SidebarArrow
                        src={IconSidebarArrow}
                        alt=''
                        className={cn({
                          isOpen: location.pathname.indexOf('/insights') === 0
                        })}
                      />
                    ) : null
                  }
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.EMAIL_MARKETING]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarEmailMarketing />}
                  text='Email Marketing'
                  route='/marketing'
                  feature={FEATURE_RESTRICTION_IDS.EMAIL_MARKETING}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={['analyticsAccess']}
                features={[FEATURE_RESTRICTION_IDS.ANALYTICS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarAnalytics />}
                  text='Analytics'
                  route='/analytics'
                  feature={FEATURE_RESTRICTION_IDS.ANALYTICS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.DISCOUNTS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarDiscounts />}
                  text='Discounts'
                  route='/discounts'
                  feature={FEATURE_RESTRICTION_IDS.DISCOUNTS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>
              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.REPORTS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarReports />}
                  text='Reports'
                  route='/reports'
                  feature={FEATURE_RESTRICTION_IDS.REPORTS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={['virtualNumberAccess']}
                features={[FEATURE_RESTRICTION_IDS.VIRTUAL_NUMBERS]}
                extraAccessLogic={() =>
                  user.userData.status === 'ACTIVE' &&
                  Boolean(user.activeLocation?.GeneratedLink) &&
                  Boolean(user.activeLocation?.GeneratedLinkURL)
                }
              >
                <SidebarMenuItem
                  icon={<IconSidebarVNumber />}
                  text='Virtual Answering'
                  route='/virtual-number'
                  feature={FEATURE_RESTRICTION_IDS.VIRTUAL_NUMBERS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              {!isMobile && (
                <RestrictedArea
                  restrictions={[]}
                  features={[FEATURE_RESTRICTION_IDS.FEEDBACK]}
                  extraAccessLogic={() => user.userData.status === 'ACTIVE'}
                >
                  <SidebarMenuItem
                    icon={<IconSidebarFeedback />}
                    text='Customer Feedback'
                    route='/feedback'
                    feature={FEATURE_RESTRICTION_IDS.FEEDBACK}
                    closeSidebarEvent={closeSidebarEvent}
                  />
                </RestrictedArea>
              )}

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
                extraAccessLogic={() => user.userData.status === 'ACTIVE'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarUberEats />}
                  text='UberEats'
                  route='/uber-eats'
                  feature={FEATURE_RESTRICTION_IDS.UBER_EATS}
                  extraNewTagDisplayLogic={() =>
                    !BYOCconnected &&
                    !!user?.activeLocation?.hasMoreThan10Orders
                  }
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>
              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.MARKETING_INTEGRATIONS]}
              >
                <SidebarMenuItem
                  icon={<IconIntegrations />}
                  text='Marketing Integrations'
                  route='/marketing-integrations'
                  feature={FEATURE_RESTRICTION_IDS.MARKETING_INTEGRATIONS}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>
              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.MY_ACCOUNT]}
              >
                <SidebarMenuItem
                  icon={<IconSidebarAccount />}
                  text='My Account'
                  route='/my-account'
                  feature={FEATURE_RESTRICTION_IDS.MY_ACCOUNT}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.TEAM]}
                extraAccessLogic={() => user.userData.status !== 'NEW'}
              >
                <SidebarMenuItem
                  icon={<IconSidebarTeam />}
                  text='Team'
                  route='/team'
                  feature={FEATURE_RESTRICTION_IDS.TEAM}
                  closeSidebarEvent={closeSidebarEvent}
                />
              </RestrictedArea>

              <RestrictedArea
                restrictions={[]}
                features={[FEATURE_RESTRICTION_IDS.HELP]}
              >
                <SidebarMenuItem
                  icon={<IconSidebarHelp />}
                  text='Help'
                  feature={FEATURE_RESTRICTION_IDS.HELP}
                />
              </RestrictedArea>
            </SidebarMenu>
            {/* {user.userData.status === 'ACTIVE' && ( */}
            {/* <SidebarMenuButtonRedirect */}
            {/*   onClick={() => { */}
            {/*     dispatch(RETURN_TO_OLD_DASHBOARD({uri: `/discounts/${activeLocation._id}`})); */}
            {/*   }} */}
            {/* > */}
            {/*   <IconSidebarDashboard /> */}
            {/*   Discounts */}
            {/* </SidebarMenuButtonRedirect> */}
            {/* )} */}
          </>
        </SidebarHolder>
      </SidebarContainer>
      {user.userData && Object.keys(user.userData).length !== 0 && (
        <SidebarProfile>
          <SidebarProfilePicture>
            {user.userData.email.slice(0, 1).toUpperCase()}
          </SidebarProfilePicture>
          {/* <SidebarProfilePhoto src={UserPhoto} alt="" /> */}
          <SidebarProfileInfo>
            <SidebarProfileName>
              <span>{emailName}</span>
              <span>{emailDomain}</span>
            </SidebarProfileName>
            <SidebarProfilePosition>
              {user.userData.MerchantOriginName}
            </SidebarProfilePosition>
            <SidebarProfileLogoutButton
              onClick={() => {
                dispatch(LOGOUT_USER());
              }}
              href={`${process.env.REACT_APP_OLD_DASHBOARD_URL}/logout`}
            />
          </SidebarProfileInfo>
        </SidebarProfile>
      )}
      <SidebarOverlay
        onClick={() => setShowSidebar(!showSidebar)}
        className='SidebarOverlay'
      />
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired
};
export default Sidebar;
