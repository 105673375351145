import {createAction} from 'redux-act';

export const GENERATE_REPORT = createAction('reports/GENERATE_REPORT');
export const GENERATE_REPORT_SUCCESS = createAction(
  'reports/GENERATE_REPORT_SUCCESS'
);
export const GET_REPORTS = createAction('reports/GET_REPORTS');
export const GET_REPORTS_SUCCESS = createAction('reports/GET_REPORTS_SUCCESS');
export const GET_REPORT_CUSTOMERS_SUCCESS = createAction(
  'reports/GET_REPORT_CUSTOMERS_SUCCESS'
);
export const GET_REPORT_SALES_SUMMARY_SUCCESS = createAction(
  'reports/GET_REPORT_SALES_SUMMARY_SUCCESS'
);

export const GET_REPORT_BOOK_A_DRIVER_SUCCESS = createAction(
  'reports/GET_REPORT_BOOK_A_DRIVER_SUCCESS'
);

export const EXPORT_REPORTS = createAction('reports/EXPORT_REPORTS');
export const STOP_EXPORT_REPORTS_LOADING = createAction(
  'reports/STOP_EXPORT_REPORTS_LOADING'
);

export const GET_REPORT_CUSTOMERS = createAction(
  'reports/GET_REPORT_CUSTOMERS'
);
export const GET_REPORT_SALES_SUMMARY = createAction(
  'reports/GET_REPORT_SALES_SUMMARY'
);
export const GET_REPORT_PAYOUT_SUMMARY = createAction(
  'reports/GET_REPORT_PAYOUT_SUMMARY'
);
export const GET_REPORT_BOOK_A_DRIVER = createAction(
  'reports/GET_REPORT_BOOK_A_DRIVER'
);
export const GET_REPORT_BYOC = createAction('reports/GET_REPORT_BYOC');

export const STOP_REPORTS_LOADING = createAction(
  'reports/STOP_REPORTS_LOADING'
);

export const SET_REPORTS_DATE_INTERVAL = createAction(
  'reports/SET_REPORTS_DATE_INTERVAL'
);

export const SET_DATEPICKER_INDEX = createAction(
  'reports/SET_DATEPICKER_INDEX'
);

export const SET_REPORT_TYPE = createAction('reports/SET_REPORT_TYPE');

export const REPORTS_PAGINATE = createAction('reports/REPORTS_PAGINATE');

export const SET_REPORT_VISITED_STATISTIC = createAction(
  'reports/SET_REPORT_VISITED_STATISTIC'
);
