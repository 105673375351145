import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

const LinkButton = styled(NavLink)`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  color: #ff2d38;
  cursor: pointer;
  border-bottom: 1px solid #ff2d38;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  &:hover {
    border-bottom-color: transparent;
  }
  &.Md {
    font-size: 16px;
    font-weight: 700;
  }
  &.Lg {
    font-size: 18px;
    font-weight: 700;
    @media (max-width: 1440px) {
      font-size: 17px;
    }
    @media (max-width: 1300px) {
      font-size: 16px;
    }
  }

  &.White {
    color: #fff;
    border-bottom-color: #fff;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  &.noClickEvent {
    pointer-events: none;
    border: none;
  }
`;

export default LinkButton;
