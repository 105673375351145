import {createReducer} from 'redux-act';
import {
  setLevelNamesByItemGroupTree,
  setLevelNamesByModifierTree
} from '../../helpers/menuEditor';
import {
  CLEAR_OUTSTOCK_ITEMS,
  CLEAR_OUTSTOCK_MODIFIERS,
  CLEAR_OUTSTOCK_STORE,
  GET_OUT_STOCK,
  GET_OUT_STOCK_SUCCESS,
  GET_OUT_STOCK_TAB,
  GET_OUT_STOCK_TAB_SUCCESS,
  SET_OUTSTOCK_ITEMS,
  SET_OUTSTOCK_MODIFIERS,
  FETCH_SINGLE_ITEM_TREE,
  SET_SINGLE_ITEM_TREE,
  FETCH_SINGLE_MODIFIER_TREE,
  SET_SINGLE_MODIFIER_TREE,
  SET_OUTSTOCK_COUNTERS,
  LOAD_OUTSTOCK_COUNTERS
} from './action';

const initialState = () => ({
  itemsData: [],
  itemsLoading: false,
  rerenderItems: false,
  itemsPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  allModifiersData: [],
  allModifiersPagination: {
    pages: 1,
    page: 1,
    limit: 8,
    total: 0,
    sort: 'desc',
    sortBy: 'Name'
  },
  allModifiersLoading: false,
  outOfStock: 0,
  itemsListLoading: false,
  modifiersListLoading: false,
  itemsList: null,
  modifiersList: null,
  isLoadOutOfStockCounters: false,
  outOfStockCounters: {
    items: 0,
    modifiers: 0
  }
});

const menuEditorOutStockReducer = createReducer(
  {
    [SET_OUTSTOCK_ITEMS]: (state, payload) => {
      setLevelNamesByItemGroupTree(payload, FETCH_SINGLE_ITEM_TREE);

      return {
        ...state,
        itemsList: payload,
        outOfStockCounters: {
          ...state.outOfStockCounters,
          items: payload.length
        }
      };
    },
    [SET_SINGLE_ITEM_TREE]: (state, payload) => {
      const menuItem = payload;
      let {outOfStockCounters} = state;
      let itemsList = null;

      if (menuItem.InStock) {
        itemsList = state.itemsList.filter(
          (item) => item.PosId !== menuItem.PosId
        );
        outOfStockCounters = {
          ...outOfStockCounters,
          items: itemsList.length
        };
      } else {
        setLevelNamesByItemGroupTree([menuItem], FETCH_SINGLE_ITEM_TREE);
        itemsList = state.itemsList.map((item) =>
          item.PosId !== menuItem.PosId ? item : menuItem
        );
      }

      return {
        ...state,
        itemsList,
        outOfStockCounters
      };
    },
    [CLEAR_OUTSTOCK_ITEMS]: (state) => ({
      ...state,
      itemsList: [],
      outOfStockCounters: {
        ...state.outOfStockCounters,
        items: 0
      }
    }),

    [SET_OUTSTOCK_MODIFIERS]: (state, payload) => {
      setLevelNamesByModifierTree(payload, FETCH_SINGLE_MODIFIER_TREE);

      return {
        ...state,
        modifiersList: payload,
        outOfStockCounters: {
          ...state.outOfStockCounters,
          modifiers: payload.length
        }
      };
    },
    [SET_SINGLE_MODIFIER_TREE]: (state, payload) => {
      const modifier = payload;
      let {outOfStockCounters} = state;
      let modifiersList = null;

      if (modifier.InStock) {
        modifiersList = state.modifiersList.filter(
          (item) => item.PosId !== modifier.PosId
        );
        outOfStockCounters = {
          ...outOfStockCounters,
          modifiers: modifiersList.length
        };
      } else {
        setLevelNamesByModifierTree([modifier], FETCH_SINGLE_MODIFIER_TREE);
        modifiersList = state.modifiersList.map((item) =>
          item.PosId !== modifier.PosId ? item : modifier
        );
      }

      return {
        ...state,
        modifiersList,
        outOfStockCounters
      };
    },
    [CLEAR_OUTSTOCK_MODIFIERS]: (state) => ({
      ...state,
      modifiersList: [],
      outOfStockCounters: {
        ...state.outOfStockCounters,
        modifiers: 0
      }
    }),

    [LOAD_OUTSTOCK_COUNTERS]: (state, payload) => ({
      ...state,
      isLoadOutOfStockCounters: Boolean(payload)
    }),

    [SET_OUTSTOCK_COUNTERS]: (state, payload) => ({
      ...state,
      outOfStockCounters: payload
    }),

    [CLEAR_OUTSTOCK_STORE]: () => initialState(),

    [GET_OUT_STOCK]: (state) => ({
      ...state
    }),
    [GET_OUT_STOCK_SUCCESS]: (state, payload) => ({
      ...state,
      outOfStock: payload.total
    }),
    [GET_OUT_STOCK_TAB]: (state) => ({
      ...state,
      itemsLoading: true,
      allModifiersLoading: true
    }),
    [GET_OUT_STOCK_TAB_SUCCESS]: (state, payload) => {
      return {
        ...state,
        allModifiersPagination: {
          pages: payload.data.totalPages,
          page: 1,
          limit: 1000,
          total: payload.data.totalDocs,
          sort: 'desc',
          sortBy: 'Name'
        },
        itemsLoading: false,
        allModifiersLoading: false,
        itemsData: payload.d2.docs,
        allModifiersData: payload.data.docs,
        itemsPagination: {
          pages: payload.d2.totalPages,
          page: 1,
          limit: 1000,
          total: payload.d2.totalDocs,
          sort: 'desc',
          sortBy: 'Name'
        }
      };
    }
  },
  initialState()
);

export default menuEditorOutStockReducer;
