import styled from 'styled-components';
import Headline from '../../components/Headline/styles';
import {ButtonPrimary} from '../../components/buttons';

export const PageErrorTitle = styled(Headline)`
  display: block;
  font-size: 200px;
  padding-top: 80px;
  @media (max-width: 1250px) {
    font-size: 150px;
    padding-top: 20px;
  }
`;

export const PageErrorBack = styled(ButtonPrimary)`
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
`;
