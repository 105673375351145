export const DISCOUNT_STATUS = {
  active: 1,
  history: 0
};

export const DISCOUNT_TARGET = {
  cart: 0,
  item: 1,
  delivery: 2,
  combo: 3
};

export const DISCOUNT_TYPE = {
  fixedAmount: 0,
  percentage: 1
};
