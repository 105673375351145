import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogAction,
  DialogBody,
  DialogText
} from '../../../routes/App/dashboard/styles';
import ButtonPrimary from '../../buttons/ButtonPrimary/styles';
import {SESSION_EXPIRED_MODAL_CLOSE} from '../../../store/global/sessionExpiredModal/action';
import DialogContainer from '../../DialogContainer/styles';
import DialogClose from '../../DialogClose/styles';
import DialogTitle from '../../DialogTitle/styles';

const WarningModal = () => {
  const dispatch = useDispatch();

  const {
    sessionExpiredReducer: {open}
  } = useSelector((state) => state);

  return (
    <Dialog
      onClose={() => dispatch(SESSION_EXPIRED_MODAL_CLOSE())}
      open={open}
      scroll='body'
    >
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => dispatch(SESSION_EXPIRED_MODAL_CLOSE())} />
        <DialogTitle className='DialogTitleOrange'>
          Your session is expired :(
        </DialogTitle>
        <DialogBody>
          <DialogText className='DialogTextSm'>
            Your session has expired or you have been logged out, click OK to
            log In again!
          </DialogText>

          <DialogAction>
            <ButtonPrimary
              onClick={() => dispatch(SESSION_EXPIRED_MODAL_CLOSE())}
            >
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default WarningModal;
