import React from 'react';

const IconPrint = () => {
  return (
    <svg
      data-gtm='printButton'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        data-gtm='printButton'
        d='M19.625 5C19.625 4.44772 19.1773 4 18.625 4H9.375C8.82272 4 8.375 4.44772 8.375 5V8.5H19.625V5Z'
        fill='#2F3D48'
      />
      <path
        data-gtm='printButton'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 11.875C5 10.6324 6.00736 9.625 7.25 9.625H20.75C21.9926 9.625 23 10.6324 23 11.875V15.5C23 16.6046 22.1046 17.5 21 17.5H20.625C20.0727 17.5 19.625 17.9477 19.625 18.5V21C19.625 21.5523 19.1773 22 18.625 22H9.375C8.82272 22 8.375 21.5523 8.375 21V18.5C8.375 17.9477 7.92728 17.5 7.375 17.5H7C5.89543 17.5 5 16.6046 5 15.5V11.875ZM17.375 15.25H10.625V19.75H17.375V15.25ZM21.875 11.875C21.875 12.4963 21.3713 13 20.75 13C20.1287 13 19.625 12.4963 19.625 11.875C19.625 11.2537 20.1287 10.75 20.75 10.75C21.3713 10.75 21.875 11.2537 21.875 11.875Z'
        fill='#2F3D48'
      />
    </svg>
  );
};

export default IconPrint;
