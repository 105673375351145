import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import {FormControl} from '@material-ui/core';
import cn from 'classnames';
import DialogTitle from 'components/DialogTitle/styles';
import DialogContainer from 'components/DialogContainer/styles';
import DialogBody from 'components/DialogBody/styles';
import DialogField from 'components/DialogField/styles';
import Textarea from 'components/FieldTextarea/style';
import DialogFields from 'components/DialogFields/styles';
import {DialogSubTitle} from 'components/DialogSubTitle/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import {
  CANCEL_DELIVERY_ORDER,
  OPEN_CANCEL_DELIVERY_ORDER_MODAL
} from 'store/orders/orderDetails/action';
import Loading from 'components/shared/Utils/Loading';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import CancelDeliveryOrderSchema from 'helpers/services/formValidationSchemas/CancelDeliveryOrderSchema';

const CancelDeliveryOrderModal = ({
  open,
  deliveryId,
  orderId,
  loadingCancellation
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const initialValues = {
    reasonComment: null
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={CancelDeliveryOrderSchema}
      onSubmit={(values, {resetForm}) => {
        let reasonComment = values.reasonComment.trim();
        reasonComment = reasonComment.replace(/\s+/g, ' ');
        reasonComment = reasonComment.replace(/\n+/g, '\n');

        dispatch(
          CANCEL_DELIVERY_ORDER({
            deliveryId,
            orderId,
            reasonComment
          })
        );
        resetForm();
      }}
    >
      {({values, handleChange, submitForm, errors, touched, resetForm}) => {
        return (
          <Dialog open={open} scroll='body'>
            <DialogContainer className='DialogMd'>
              <DialogTitle className='DialogTitleMarginBottom'>
                Do you want to cancel driver?
              </DialogTitle>
              <DialogSubTitle className='textCenter mBottomLarge'>
                Please note, delivery fee is non-refundable after courier has
                been assigned
              </DialogSubTitle>
              <DialogBody>
                <Form>
                  <DialogFields className='wAuto'>
                    <DialogField className='w100 AdjustmentModalField'>
                      <FormControl className='AdjustmentTextarea'>
                        <Textarea
                          style={
                            errors.reasonComment && touched.reasonComment
                              ? {
                                  width: '100%',
                                  resize: 'none',
                                  border: '1px solid #ba0302'
                                }
                              : {width: '100%', resize: 'none'}
                          }
                          className={cn('FieldTextareaLg', {
                            error: errors.reasonComment && touched.reasonComment
                          })}
                          name='reasonComment'
                          value={values.reasonComment}
                          onChange={handleChange}
                          placeholder='Enter cancellation comment'
                        />
                      </FormControl>
                      {errors?.reasonComment && touched?.reasonComment ? (
                        <ErrorSignWrapper>
                          <ErrorSign>{errors.reasonComment}</ErrorSign>
                        </ErrorSignWrapper>
                      ) : null}
                    </DialogField>
                  </DialogFields>
                  <DialogActions className='justifyCenter'>
                    <DialogAction className='w40'>
                      <ButtonOutline
                        type='button'
                        onClick={() => {
                          resetForm();
                          dispatch(OPEN_CANCEL_DELIVERY_ORDER_MODAL(false));
                        }}
                      >
                        <span>Go back</span>
                      </ButtonOutline>
                    </DialogAction>
                    <DialogAction className='w40'>
                      <ButtonPrimary type='button' onClick={submitForm}>
                        <span>Cancel driver</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </Form>
              </DialogBody>
            </DialogContainer>
            {loadingCancellation && <Loading className='AdjustModal' />}
          </Dialog>
        );
      }}
    </Formik>
  );
};

CancelDeliveryOrderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  deliveryId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  loadingCancellation: PropTypes.bool.isRequired
};

export default CancelDeliveryOrderModal;
