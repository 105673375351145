/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import {takeEvery, select, put} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  deleteOverviewApi,
  getOverviewByIdApi,
  getOverwiewApi,
  postOverviewApi,
  updateOverviewApi,
  patchSectionApi,
  getSectionTree
} from '../../helpers/services/api/menuEditor';
import {
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_BY_ID,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_MODAL,
  GET_CATEGORY_MODAL_SUCCESS,
  GET_CATEGORY_SUCCESS,
  POST_CATEGORY,
  POST_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  PATCH_CATEGORY_SUCCESS,
  PATCH_CATEGORY,
  FETCH_SINGLE_CATEGORY_TREE,
  FETCH_SINGLE_CATEGORY_SUCCESS
} from './action';

import {
  ADD_NEW_SECTION,
  SET_UPDATED_SECTION,
  CLEAR_SECTION
} from '../menuEditor/action';

import CustomToast from '../../components/shared/Utils/CustomToast';

const getOverviewState = ({menuEditorCategoryReducer}) =>
  menuEditorCategoryReducer.overviewPagination;
const getOverviewModalState = ({menuEditorCategoryReducer}) =>
  menuEditorCategoryReducer.overviewModalPagination;
const entityId = ({menuEditorModalsReducer}) =>
  menuEditorModalsReducer.entityId;

function* getAllOverview({payload}) {
  try {
    const overviewParams = yield select(getOverviewState);
    const pagination = {
      ...overviewParams,
      ...payload
    };
    const {data} = yield getOverwiewApi(pagination);

    if (data) {
      yield put(GET_CATEGORY_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* fetchSingleCategoryTree({payload}) {
  try {
    const {data} = yield getSectionTree(payload);

    if (data) {
      yield put(FETCH_SINGLE_CATEGORY_SUCCESS(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleCategoryTree]: ', error);
  }
}

function* getAllOverviewModal({payload}) {
  try {
    const overviewParams = yield select(getOverviewModalState);
    const pagination = {
      ...overviewParams,
      ...payload
    };
    const {data} = yield getOverwiewApi(pagination);

    if (data) {
      yield put(GET_CATEGORY_MODAL_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* getOverviewById({payload}) {
  try {
    const {data} = yield getOverviewByIdApi(payload);

    if (data) {
      yield put(GET_CATEGORY_BY_ID_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* postNewOverview({payload}) {
  try {
    const {data} = yield postOverviewApi(payload);

    if (data) {
      yield put(ADD_NEW_SECTION({id: data.MerchantSectionId, data}));
      toast.success('Category has been created');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* updateOverview({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield updateOverviewApi(id, form);

    if (data) {
      yield put(SET_UPDATED_SECTION({id, data: form}));
      toast.success('Category has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* patchOverview({payload: {form}}) {
  try {
    const {id: sectionId, ...body} = form;
    const id = yield select(entityId);
    const {data} = yield patchSectionApi(id || sectionId, body);

    if (data) {
      yield put(SET_UPDATED_SECTION({id: id || sectionId, data: body}));
      toast.success('Category has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* deleteOverview({payload}) {
  try {
    const id = yield select(entityId);
    const {data} = yield deleteOverviewApi(id || payload);

    if (data) {
      yield put(CLEAR_SECTION(id));
      toast.success('Category has been delete');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* menuEditorCategorySaga() {
  yield takeEvery(GET_CATEGORY, getAllOverview);
  yield takeEvery(FETCH_SINGLE_CATEGORY_TREE, fetchSingleCategoryTree);
  yield takeEvery(POST_CATEGORY, postNewOverview);
  yield takeEvery(UPDATE_CATEGORY, updateOverview);
  yield takeEvery(DELETE_CATEGORY, deleteOverview);
  yield takeEvery(GET_CATEGORY_BY_ID, getOverviewById);
  yield takeEvery(GET_CATEGORY_MODAL, getAllOverviewModal);
  yield takeEvery(PATCH_CATEGORY, patchOverview);
}

export default menuEditorCategorySaga;
