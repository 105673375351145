import {createReducer} from 'redux-act';
import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_STATE_CHANGE,
  LOGOUT_USER_SUCCESS,
  AUTHENTICATION_FETCHING_SUCCESS,
  SAVE_USER_EMAIL,
  SAVE_USER_EMAIL_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SET_ENTERED_EMAIL,
  SELF_SIGNUP_USER,
  SELF_SIGNUP_USER_SUCCESS,
  SELF_SIGNUP_USER_FAILED,
  SIGNUP_USER_FAILED,
  SET_FETCHING
} from './action';

const initialState = () => ({
  isAuthenticated: false,
  isEmailEntered: false,
  token: null,
  RestaurantURL: '',
  fetchingUserData: false,
  forgotPasswordLoading: false,
  forgotPasswordSuccess: false,
  isOldSalesSetup: false
});

const authReducer = createReducer(
  {
    [AUTH_USER_SUCCESS]: (state, payload) => ({
      ...state,
      isAuthenticate: true,
      token: payload
    }),
    [AUTH_USER]: (state) => ({
      ...state,
      fetchingUserData: true
    }),
    [AUTHENTICATION_FETCHING_SUCCESS]: (state) => ({
      ...state,
      fetchingUserData: false
    }),
    [FORGOT_PASSWORD]: (state) => ({
      ...state,
      forgotPasswordLoading: true
    }),
    [FORGOT_PASSWORD_STATE_CHANGE]: (state, payload) => ({
      ...state,
      forgotPasswordLoading: payload.loading,
      forgotPasswordSuccess: payload.success
    }),
    [LOGOUT_USER_SUCCESS]: (state) => ({
      ...state,
      isAuthenticated: false,
      fetchingUserData: false,
      token: null
    }),
    [SAVE_USER_EMAIL]: (state, {Email}) => ({
      ...state,
      userEmail: Email
    }),
    [SAVE_USER_EMAIL_SUCCESS]: (state) => ({
      ...state
    }),
    [SIGNUP_USER]: (state, {RestaurantURL, Email, isOldSalesSetup}) => ({
      ...state,
      RestaurantURL,
      userEmail: Email,
      isOldSalesSetup
    }),
    [SIGNUP_USER_SUCCESS]: (state) => ({
      ...state,
      fetchingUserData: false
    }),
    [SIGNUP_USER_FAILED]: (state) => ({
      ...state,
      fetchingUserData: false
    }),
    [SELF_SIGNUP_USER]: (state, {RestaurantURL, Email}) => ({
      ...state,
      RestaurantURL,
      userEmail: Email
    }),
    [SELF_SIGNUP_USER_SUCCESS]: (state) => ({
      ...state,
      fetchingUserData: false
    }),
    [SELF_SIGNUP_USER_FAILED]: (state) => ({
      ...state,
      fetchingUserData: false
    }),
    [SET_ENTERED_EMAIL]: (state, payload) => ({
      ...state,
      isEmailEntered: payload
    }),
    [SET_FETCHING]: (state, payload) => ({
      ...state,
      fetchingUserData: payload
    })
  },
  initialState()
);

export default authReducer;
