import jwt_decode from 'jwt-decode';

export const getStorageToken = () => {
  const sessionAccess = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const sessionRefresh = sessionStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_KEY
  );

  const localAccess = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const localRefresh = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_KEY
  );

  return {
    token: sessionAccess || localAccess,
    refreshToken: sessionRefresh || localRefresh
  };
};

export const setInAvailableStorage = (token, refreshToken) => {
  const localAccess = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const localRefresh = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_KEY
  );

  const sessionAccess = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const sessionRefresh = sessionStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_KEY
  );

  if (sessionAccess && sessionRefresh) {
    sessionStorage.setItem(
      process.env.REACT_APP_REFRESH_TOKEN_KEY,
      refreshToken
    );
    sessionStorage.setItem(process.env.REACT_APP_TOKEN_KEY, token);
    return;
  }
  if (localAccess && localRefresh) {
    localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, token);
    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_KEY, refreshToken);
  }
};

export const decodeToken = () => {
  const {token} = getStorageToken();
  if (!token) return null;
  return jwt_decode(token);
};

export const clearStorage = () => {
  localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
  sessionStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
  localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_KEY);
};
