/* eslint-disable */
export const buildUrl = (url, {page, search, sort}) => {
  if (page) {
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + `page=${page + 1}`;
  }

  if (sort) {
    url =
      url +
      (url.indexOf('?') === -1 ? '?' : '&') +
      `sortBy=${sort.sortBy}&sort=${sort.sortDirection}`;
  }

  if (search) {
    url = url + (url.indexOf('?') === -1 ? '?' : '&') + `search=${search}`;
  }

  return url;
};
