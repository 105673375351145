import {createAction} from 'redux-act';

export const SELECT_ITEM_MODAL_OPEN = createAction(
  'menuEditorModal/SELECT_ITEM_MODAL'
);
export const CREATE_CATEGORY_MODAL = createAction(
  'menuEditorModal/CREATE_CATEGORY_MODAL'
);
export const EDIT_CATEGORY_MODAL = createAction(
  'menuEditorModal/EDIT_CATEGORY_MODAL'
);
export const ADD_NEW_MODAL = createAction('menuEditorModal/ADD_NEW_MODAL');
export const SOLD_OUT_MODAL = createAction('menuEditorModal/SOLD_OUT_MODAL');
export const DELETE_MODAL = createAction('menuEditorModal/DELETE_MODAL');
export const HIDDEN_MODAL = createAction('menuEditorModal/HIDDEN_MODAL');

export const EDIT_NOTE_MODAL = createAction('menuEditorModal/EDIT_NOTE_MODAL');
export const CREATE_ITEM_MODAL = createAction(
  'menuEditorModal/CREATE_ITEM_MODAL'
);
export const EDIT_ITEM_MODAL = createAction('menuEditorModal/EDIT_ITEM_MODAL');
export const CREATE_MODIFIER_MODAL = createAction(
  'menuEditorModal/CREATE_MODIFIER_MODAL'
);
export const CREATE_MODIFIER_GROUP_MODAL = createAction(
  'menuEditorModal/CREATE_MODIFIER_GROUP_MODAL'
);
export const EDIT_MODIFIER_GROUP_MODAL = createAction(
  'menuEditorModal/EDIT_MODIFIER_GROUP_MODAL'
);
export const EDIT_MODIFIER_MODAL = createAction(
  'menuEditorModal/EDIT_MODIFIER_MODAL'
);
