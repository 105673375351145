import React, {useState} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../components/shared/TabPanel';
import SalesByHours from '../../../components/feature/Tabs/SalesByHours';
import SalesByItems from '../../../components/feature/Tabs/SalesByItems';
import {
  DashboardDatepicker,
  DashboardInnerBody,
  DashboardInnerDescription,
  DashboardInnerHeader,
  DashboardInners,
  DashboardSelectWrapper,
  DashboardTabs
} from './styles';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import Headline from '../../../components/Headline/styles';
import SubTitle from '../../../components/SubTitle/styles';
import ButtonInfo from '../../../components/buttons/ButtonInfo/styles';
import TotalSales from '../../../components/feature/Tabs/TotalSales';
import TotalCustomers from '../../../components/feature/Tabs/TotalCustomers';
import {
  useDatePickerContext,
  useDatePickerContextUpdate
} from '../../../components/Datepicker/DatePickerContext';
import DatepickerModule from '../../../components/DatepickerModule';
import {DashboardContent} from '../../../components/DashboardContent/styles';

const Dashboard = () => {
  const [value, setValue] = useState(0);
  const date = useDatePickerContext();
  const updateDate = useDatePickerContextUpdate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardContent className='DashboardContent'>
      <>
        <Headline className='mTop5 HeadlineDesktop'>Analytics</Headline>
        <DashboardTabs className='DashboardTabsAnalytics'>
          <Tabs value={value} onChange={handleChange} elevation={0}>
            <Tab label='All Analytics' />
            <Tab label='Sales Count' />
            <Tab label='Customers Count' />
            <Tab label='Sales per Hour' />
            <Tab label='Sales per Item' />
          </Tabs>
        </DashboardTabs>
        <DashboardSelectWrapper>
          <DashboardDatepicker>
            <DatepickerModule
              date={date}
              updateDate={updateDate}
              defaultSelectIndex={2}
              localStorageObject={{
                storageDateName: 'Date',
                storageCustomRangeName: 'CustomRange',
                storageDatePeriodName: 'DatePeriod'
              }}
              updatedAllPeriods='true'
            />
          </DashboardDatepicker>
        </DashboardSelectWrapper>

        <TabPanel value={value} index={0}>
          <DashboardInners>
            <DashboardInner className='DashboardInnerW50'>
              <DashboardInnerHeader>
                <SubTitle>Sales Count</SubTitle>
              </DashboardInnerHeader>
              <DashboardInnerBody className='WithoutMargin'>
                <TotalSales />
              </DashboardInnerBody>
            </DashboardInner>
            <DashboardInner className='DashboardInnerW50'>
              <DashboardInnerHeader>
                <SubTitle>Customers Count</SubTitle>
                {/* <ButtonInfo className="Outline ButtonInfoDrop">
                <div className="DropInfo">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed
                </div>
              </ButtonInfo> */}
              </DashboardInnerHeader>
              <DashboardInnerBody className='WithoutMargin'>
                <TotalCustomers />
              </DashboardInnerBody>
            </DashboardInner>
          </DashboardInners>
          <DashboardInner>
            <DashboardInnerHeader>
              <SubTitle>Sales per Hour</SubTitle>
              <DashboardInnerDescription>
                Average Hourly Sales Number
              </DashboardInnerDescription>
            </DashboardInnerHeader>
            <DashboardInnerBody>
              <SalesByHours />
            </DashboardInnerBody>
          </DashboardInner>
          <DashboardInner>
            <DashboardInnerHeader
              style={{
                justifyContent: 'flex-start'
              }}
            >
              <SubTitle>Sales per Item</SubTitle>
              <ButtonInfo
                className='Outline ButtonInfoDrop'
                style={{
                  marginLeft: '11px'
                }}
              >
                <div className='DropInfo DropInfoCenter Small'>
                  The rank and performance of your items based on Sales per Item
                  (Orders) and by Generated Revenue per Item‌ ‌(Sales)
                </div>
              </ButtonInfo>
            </DashboardInnerHeader>
            <DashboardInnerBody>
              <SalesByItems />
            </DashboardInnerBody>
          </DashboardInner>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DashboardInner>
            <DashboardInnerHeader>
              <SubTitle>Sales Count</SubTitle>
            </DashboardInnerHeader>
            <DashboardInnerBody className='WithoutMargin'>
              <TotalSales />
            </DashboardInnerBody>
          </DashboardInner>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DashboardInner>
            <DashboardInnerHeader>
              <SubTitle>Customers Count</SubTitle>
            </DashboardInnerHeader>
            <DashboardInnerBody className='WithoutMargin'>
              <TotalCustomers />
            </DashboardInnerBody>
          </DashboardInner>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <DashboardInner>
            <DashboardInnerHeader>
              <SubTitle>Sales per Hour</SubTitle>
              <DashboardInnerDescription>
                Average Hourly Sales Number
              </DashboardInnerDescription>
            </DashboardInnerHeader>
            <DashboardInnerBody>
              <SalesByHours />
            </DashboardInnerBody>
          </DashboardInner>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <DashboardInner>
            <DashboardInnerHeader
              style={{
                justifyContent: 'flex-start'
              }}
            >
              <SubTitle>Sales per Item</SubTitle>

              <ButtonInfo
                className='Outline ButtonInfoDrop'
                style={{
                  marginLeft: '11px'
                }}
              >
                <div className='DropInfo DropInfoCenter Small'>
                  The rank and performance of your items based on Sales per Item
                  (Orders) and by Generated Revenue per Item(Sales)
                </div>
              </ButtonInfo>
            </DashboardInnerHeader>
            <DashboardInnerBody>
              <SalesByItems />
            </DashboardInnerBody>
          </DashboardInner>
        </TabPanel>
      </>
    </DashboardContent>
  );
};

export default Dashboard;
