import {DELETE, GET, PATCH, POST, PUT} from '../constants/API_CONSTANTS';
import {
  GET_SETUP_ACCOUNT,
  SAVE_USER_EMAIL,
  SETUP_ACCOUNT,
  SIGNUP_USER,
  USER_DATA,
  USER_LOCATIONS_DATA,
  UPDATE_USER,
  POST_STEP_ACCOUNT,
  GET_STEPS_ACCOUNT,
  SELF_SIGNUP_USER,
  API_GET_USERS,
  API_GET_COUNTER,
  API_DELETE_USER,
  API_CREATE_USER,
  API_USER_UPDATE_PASSWORD,
  GET_COUNTRIES_API,
  PATCH_USER,
  SET_BYOC_CONFIGURATION_STATUS,
  GET_USER_STATISTIC,
  POST_USER_STATISTIC
} from '../constants/API_ENDPOINTS';
// eslint-disable-next-line import/no-cycle
import {request} from '../utils/request';

export const getUserStatisticApi = async (data) => {
  return request(GET, GET_USER_STATISTIC(data));
};

export const postUserStatisticApi = async (data) => {
  return request(POST, POST_USER_STATISTIC(), {data});
};

export const getUserLocationsData = async () => {
  return request(GET, USER_LOCATIONS_DATA());
};

export const getUserData = async () => {
  return request(GET, USER_DATA());
};

export const setupUserAccountApi = async (data, lastStep) => {
  return request(POST, SETUP_ACCOUNT(lastStep), {data});
};

export const getSetupUserAccountApi = async (userId) => {
  return request(GET, GET_SETUP_ACCOUNT(userId));
};

export const postStepSelfCreateApi = async (data, lastStep) => {
  return request(POST, POST_STEP_ACCOUNT(lastStep), {data});
};

export const getStepsSelfCreateApi = async (userId) => {
  return request(GET, GET_STEPS_ACCOUNT(userId));
};

export const getCountriesForStepsApi = async (search) => {
  return request(GET, GET_COUNTRIES_API(search));
};

export const saveUserEmailApi = async (data) => {
  return request(POST, SAVE_USER_EMAIL(), {data});
};

export const signUpUser = async (email, data) => {
  return request(POST, SIGNUP_USER(email), {data});
};

export const selfSignUpUserApi = async (email, data) => {
  return request(POST, SELF_SIGNUP_USER(email), {data});
};

export const patchUserBankInfo = async (id, data) => {
  return request(PUT, UPDATE_USER(id), {data});
};

export const getUsersApi = async (
  merchant_name,
  page = 1,
  sort = 'asc',
  search = '',
  limit = 10
) => {
  return request(GET, API_GET_USERS(merchant_name, page, sort, search, limit));
};

export const getCounterApi = async (merchant_name) => {
  return request(GET, API_GET_COUNTER(merchant_name));
};

export const deleteUserApi = async (id) => {
  return request(DELETE, API_DELETE_USER(id));
};

export const createUserApi = async (data) => {
  return request(POST, API_CREATE_USER(), {data});
};

export const updateUsersApi = async (id, data) => {
  return request(PUT, UPDATE_USER(id), {data});
};

export const patchUsersApi = async (id, data) => {
  return request(
    PATCH,
    PATCH_USER(id),
    {data},
    {
      headers: {
        Accept: 'application/merge-patch+json'
      }
    }
  );
};

export const resetPasswordApi = async (id, data) => {
  return request(PUT, API_USER_UPDATE_PASSWORD(id), {data});
};

export const changeBYOCConfigurationStatusApi = async (locationId, status) => {
  return request(PUT, SET_BYOC_CONFIGURATION_STATUS(locationId, status), {
    silent: true
  });
};
