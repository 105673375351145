import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const DashboardHeaderActions = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    margin: 0 -8px;
    flex-wrap: wrap;
  }
  .redText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ff2d38;
  }
  button,
  a {
    margin-right: 16px;
    @media (max-width: 767px) {
      margin: 8px;
      flex: 0 0 auto;
    }
    @media (max-width: 650px) {
      flex: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }
    @media (max-width: 470px) {
      margin: 4px;
      flex: calc(100% - 8px);
      max-width: calc(100% - 8px);
    }
    &:last-child {
      margin-right: 0;
    }
    &.ButtonSurvey {
      @media (max-width: 550px) {
        flex: calc(100% - 16px);
        max-width: calc(100% - 16px);
      }
      @media (max-width: 470px) {
        flex: calc(100% - 8px);
        max-width: calc(100% - 8px);
      }
    }
  }
`;
