import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';

export const InsightsControlEl = styled(Accordion)`
  width: 100%;
  padding: 12px 62px 12px 36px;
  border-radius: 10px;
  vertical-align: middle;
  background-color: #fff;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.2),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  margin: 20px 0;
  // display: flex;
  //align-items: center;
  // justify-content: space-between;
  &.MuiAccordion-rounded {
    border-radius: 10px !important;
    position: static;
  }

  &.MuiAccordion-root {
    &.Mui-expanded {
      margin: 20px 0;
      &:first-child {
        margin-top: 20px;
      }
    }
  }
  .MuiCollapse-wrapper {
    margin-top: 10px;
  }
  .MuiAccordionSummary-root {
    padding: 0;
    min-height: 48px;
    &.Mui-expanded {
      min-height: 48px;
    }
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0 28px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -20px -20px 0 -20px;
  }
`;

export const InsightsControlModule = styled.div`
  margin: 20px;
  max-width: 185px;
  width: 100%;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
  }
`;
