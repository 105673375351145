import {createAction} from 'redux-act';

export const GET_MODIFIER_GROUP_MODAL = createAction(
  'modifiersGroupModal/GET_MODIFIER_GROUP_MODAL'
);
export const GET_MODIFIER_GROUP_MODAL_SUCCESS = createAction(
  'modifiersGroupModal/GET_MODIFIER_GROUP_MODAL_SUCCESS'
);

export const GET_MODIFIERS = createAction('modifiers/GET_MODIFIERS');
export const GET_MODIFIERS_SUCCESS = createAction(
  'modifiers/GET_MODIFIERS_SUCCESS'
);

export const POST_MODIFIER_GROUP = createAction(
  'menuEditorModifierGroup/POST_MODIFIER_GROUP'
);
export const POST_MODIFIER_GROUP_SUCCESS = createAction(
  'menuEditorModifierGroup/POST_MODIFIER_GROUP_SUCCESS'
);

export const UPDATE_MODIFIER_GROUP = createAction(
  'menuEditorModifierGroup/UPDATE_MODIFIER_GROUP'
);
export const UPDATE_MODIFIER_GROUP_SUCCESS = createAction(
  'menuEditorModifierGroup/UPDATE_MODIFIER_GROUP_SUCCESS'
);

export const DELETE_MODIFIER_GROUP = createAction(
  'menuEditorModifierGroup/DELETE_MODIFIER_GROUP'
);
export const DELETE_MODIFIER_GROUP_SUCCESS = createAction(
  'menuEditorModifierGroup/DELETE_MODIFIER_GROUP_SUCCESS'
);

export const GET_MODIFIER_GROUP_BY_ID = createAction(
  'menuEditorModifierGroup/GET_MODIFIER_GROUP_BY_ID'
);
export const GET_MODIFIER_GROUP_BY_ID_SUCCESS = createAction(
  'menuEditorModifierGroup/GET_MODIFIER_GROUP_BY_ID_SUCCESS'
);

export const CLEAR_MODIFIERS_GROUPS_DATA = createAction(
  'menuEditorModifierGroup/CLEAR_MODIFIERS_GROUPS_DATA'
);

export const FETCH_SINGLE_MODIFIER_GROUP_TREE = createAction(
  'menuEditorModifierGroup/FETCH_SINGLE_MODIFIER_GROUP_TREE'
);

export const FETCH_SINGLE_MODIFIER_GROUP_SUCCESS = createAction(
  'menuEditorModifierGroup/FETCH_SINGLE_MODIFIER_GROUP_SUCCESS'
);
