export const initializeGeneralInfo = (
  UserId,
  {
    User: {FirstName, LastName, UserPhoneNumber, PhoneCountryCode} = {},
    Merchant: {MerchantName, RestaurantURL} = {},
    Location: {
      Address,
      City,
      Country,
      DeliveryInstructions,
      FullAddress,
      GoogleMapURL,
      CountryCode,
      Latitude,
      Longitude,
      Phone,
      SalesTaxRate,
      State,
      ZipCode,
      Location: {lat = '', long = ''} = {},
      CuisineType,
      StructuredAddress
    } = {},
    ...info
  }
) => ({
  userId: UserId || '',
  step: info.step || 0,
  User: {
    FirstName: FirstName || '',
    LastName: LastName || '',
    UserPhoneNumber: UserPhoneNumber || '',
    PhoneCountryCode: '+1'
  },
  Merchant: {
    MerchantName: MerchantName || '',
    RestaurantURL: RestaurantURL || ''
  },
  SalesTaxRate: SalesTaxRate || '',
  DeliveryInstructions: DeliveryInstructions || '',
  Address: Address || '',
  City: City || '',
  Country: Country || '',
  State: State || '',
  Phone: Phone?.replace(CountryCode, '') || '',
  CountryCode: '+1',
  ZipCode: ZipCode || '',
  FullAddress: FullAddress || '',
  GoogleMapURL: GoogleMapURL || '',
  Latitude: Latitude || '',
  Longitude: Longitude || '',
  Location: Location || {lat: '', long: ''},
  CuisineType: CuisineType || '',
  StructuredAddress: StructuredAddress || {
    CountryCode: '',
    StateCode: '',
    City: '',
    StreetName: '',
    StreetNumber: '',
    Unit: null,
    Entrance: null,
    Floor: null,
    Apt: null,
    ZipCode: '',
    Latitude: 0.0,
    Longitude: 0.0,
    FormattedAddress: '',
    Note: null,
    Metadata: null
  }
});

export const serverDataFormatter = (values, UserId) => {
  const {step, userId, User, Merchant, Phone, ...location} = values;
  const result = {
    step,
    userId,
    User,
    Merchant,
    Location: {Phone: location?.CountryCode + Phone, ...location}
  };
  return {
    userId: UserId || '',
    step: 0,
    data: result
  };
};
