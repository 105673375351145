import styled from 'styled-components';

export const CardsButton = styled.div`
  border: 1px solid #929cb9;
  border-radius: 5px;
  padding: 24px 10px 18px;
  cursor: pointer;
  min-width: 110px;
  max-width: 110px;
  min-height: 110px;
  max-height: 110px;
  flex: 0 0 auto;
  margin: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &::before {
    border: 3px solid #ff2d38;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    box-sizing: border-box;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  svg {
    path {
      transition-property: fill;
      transition-duration: 0.3s;
    }
  }

  span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
    color: #929cb9;
    margin-top: 10px;
    transition-property: color;
    transition-duration: 0.3s;
  }

  &.isActive {
    svg {
      path {
        fill: #ff2d38;
      }
    }

    span {
      color: #2f3d48;
    }

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
    }
    span {
      color: #ff2d38;
    }
  }
  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.increasedMargin {
    margin-left: 0;
    margin-right: 18px;
  }
`;

export default CardsButton;
