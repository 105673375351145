import styled from 'styled-components';
import IconSort from '../../../../assets/icon-sort.svg';
import {Body1} from '../../../../components/typography';

export const TableSort = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  color: #2f3d48;
  display: inline-block;
  padding-right: 20px;
  position: relative;
  font-family: 'Poppins';
  @media (max-width: 1700px) {
    font-size: 14px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 11px;
    height: 15px;
    background-image: url('${(props) => props.icon || IconSort}');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      width: 10px;
      height: 14px;
    }
  }
`;

export const TableText = styled(Body1)`
  display: inline-block;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const UserName = styled(Body1)`
  display: block;
  padding-top: 5px;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
`;

export const TableIcon = styled.div`
  min-height: 32px;
  margin-bottom: 9px;
`;

export const TableImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
`;
