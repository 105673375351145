// eslint-disable-next-line import/prefer-default-export
export const findLastFilledStep = (
  isSignUp2,
  pendingData,
  isUberEatsConnected
) => {
  if (isSignUp2) {
    switch (pendingData[pendingData.length - 1]?.step) {
      case 0:
        return 'general-info';
      case 1:
        return 'free-trial';
      case 2:
        return 'book-call';
      default:
        return 'general-info';
    }
  }
  // if (isUberEatsConnected) {
  //   return 'add-menu';
  // }
  switch (pendingData[pendingData.length - 1]?.step) {
    case 0:
      return 'general-info';
    case 1:
      return 'add-menu';

    default:
      return 'general-info';
  }
};
