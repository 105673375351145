import * as Yup from 'yup';

export const OrdersNotificationsSchema = Yup.object().shape({
  Email: Yup.array().of(
    Yup.string()
      .required('Email field is required!')
      .email(() => 'Email is not a valid')
  ),
  SMS: Yup.string()
    .max(20, 'SMS is too long')
    .required('SMS field is required!'),

  Fax: Yup.string()
    .max(20, 'Fax is too long')
    .required('Fax field is required!'),

  VoiceCall: Yup.string()
    .max(20, 'VoiceCall is too long')
    .required('VoiceCall field is required!')
});

export const validate = (values) => {
  const indexNumber = {
    0: 'First',
    1: 'Second',
    2: 'Third',
    3: 'Fourth',
    4: 'Fifth',
    5: 'Sixth',
    6: 'Seventh',
    7: 'Eighth',
    8: 'Ninth',
    9: 'Tenth'
  };
  const errors = {
    Email: []
  };
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegex = /^[+]?[0-9]?[\s]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  if (values.Email.length && values.isActiveEmail) {
    values.Email.forEach((email, index) => {
      if (!email.length) {
        errors.Email[index] = 'Email field is required!';
      } else if (!emailRegex.test(email)) {
        errors.Email[index] = 'Email is not valid';
      }
    });
  }

  if (!values.Fax.length && values.isActiveFax) {
    errors.Fax = 'Fax field is required!';
  }

  const faxValues = values.Fax.split(', ')
    .filter((el) => el.trim())
    .map((fax) => !phoneRegex.test(fax.trim().replace(',', '')));

  if (values.Fax.length && faxValues.includes(true)) {
    errors.Fax = `${
      indexNumber[faxValues.indexOf(true)]
    } entered fax number is not valid!`;
  }

  // if (values.Fax.length > 20 && values.isActiveFax) {
  //   errors.Fax = 'Fax is too long';
  // }

  if (!errors.Email.length) {
    delete errors.Email;
  }

  return errors;
};
