import styled from 'styled-components';
import IconIndicator from '../../../assets/indicator-icon.svg';
import IconSort from '../../../assets/icon-sort.svg';
import IconPrevPag from '../../../assets/icon-prev-pagination.svg';
import IconNextPag from '../../../assets/icon-next-pagination.svg';
import IconDropDark from '../../../assets/icon-arrow-down-dark.svg';
import IconDropRed from '../../../assets/icon-arrow-down-red.svg';
import IconLayers from '../../../assets/icon-layers.svg';
import IconDelivery from '../../../assets/icon-delivery.svg';
import IconPickup from '../../../assets/icon-pickup.svg';
import IconClose from '../../../assets/icon-close.svg';
import IconHint from '../../../assets/icon-lightbulb-outline.svg';
import IconRemove from '../../../assets/icon-close-red.svg';
import IconCopy from '../../../assets/icon-copy-red.svg';
import IconUploadFile from '../../../assets/icon-image-add.svg';
import body1 from '../../../components/layout/body1/styles';
import ButtonPrimary from '../../../components/buttons/ButtonPrimary/styles';
import Field from '../../../components/Field/styles';

export const DashboardContent = styled.div`
  display: block;
  padding: 110px 100px 200px 372px;
  overflow-x: hidden;

  @media (max-width: 1650px) {
    padding: 110px 48px 200px 320px;
  }
  @media (max-width: 1440px) {
    padding: 110px 38px 200px 310px;
  }
  @media (max-width: 1250px) {
    padding: 120px 80px 170px 80px;
  }

  .HeadlineDesktop {
    @media (max-width: 1250px) {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    padding: 96px 60px 170px 60px;
  }
  @media (max-width: 1100px) {
    padding: 96px 50px 170px 50px;
  }
  @media (max-width: 1000px) {
    padding: 96px 40px 70px 40px;
  }
  @media (max-width: 767px) {
    padding: 74px 30px 70px 30px;
  }

  &.TabletPTop {
    @media (max-width: 1200px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1100px) {
      padding: 110px 80px 170px 80px;
    }
    @media (max-width: 1000px) {
      padding: 110px 40px 70px 40px;
    }
  }
`;
export const DashboardAction = styled.div`
  display: flex;
  margin: 40px -10px 0 -10px;
  button {
    max-width: 300px;
    width: 100%;
    margin: 0 10px;
    &.w350 {
      max-width: 350px;
    }
    &.w250 {
      max-width: 250px;
      @media (max-width: 767px) {
        max-width: 350px;
      }
    }
  }
  @media (max-width: 767px) {
    display: block;
    margin: 30px 0 0 0;

    button {
      margin: 10px auto;
      display: block;
    }
  }
`;
export const DashboardActionCenter = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px -10px 0 -10px;
  button,
  a {
    max-width: 320px;
    width: 100%;
    margin: 0 10px;
    &.w350 {
      max-width: 350px;
    }
    &.w250 {
      max-width: 250px;
      @media (max-width: 767px) {
        max-width: 350px;
      }
    }
  }
  @media (max-width: 767px) {
    display: block;
    margin: 30px 0 0 0;

    button {
      margin: 10px auto;
      display: block;
    }
  }

  &.mTop25 {
    margin-top: 25px;
  }
  &.DashboardActionVN {
    @media (max-width: 1500px) {
      flex-wrap: wrap;
      margin: 30px -10px -10px -10px;
      @media (max-width: 550px) {
        margin: 30px 0 0 0;
      }
      button,
      a {
        margin: 10px;
        flex: 0 0 calc(40% - 20px);
        max-width: 100%;
        @media (max-width: 1439px) {
          flex: 0 0 calc(45% - 20px);
        }
        @media (max-width: 1270px) {
          flex: 0 0 calc(50% - 20px);
        }

        @media (max-width: 900px) {
          flex: 0 0 auto;
          max-width: 400px;
        }
        @media (max-width: 550px) {
          margin: 10px 0;
        }
      }
    }
  }
`;
export const DashboardDatepicker = styled.div`
  display: block;

  width: calc(50% - 10px);
  @media (max-width: 1650px) {
    width: 75%;
  }
  @media (max-width: 1250px) {
    width: 100%;
  }
`;
export const DashboardHeader = styled.div`
  @media (max-width: 1200px) {
    margin: 0 -80px;
    background-color: #fff;
    padding: 17.5px 80px;
  }
  @media (max-width: 1000px) {
    margin: 0 -60px;
    padding: 15.5px 60px;
  }

  &.mb10 {
    margin-bottom: 10px;
  }
`;
export const ButtonApply = styled(ButtonPrimary)``;

export const DashboardTabs = styled.div`
  display: block;
  margin-top: 16px;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 25px;
  @media (max-width: 1650px) {
    padding: 0 23px;
  }
  @media (max-width: 1300px) {
    padding: 0 10px;
  }
  @media (max-width: 1250px) {
    margin-top: 0;
  }
  @media (max-width: 1200px) {
    margin: 0 -80px;
    border-radius: 0;
    padding: 0;
  }
  @media (max-width: 1000px) {
    margin: 0 -40px 15px -40px;
  }
  @media (max-width: 767px) {
    margin: 0 -30px 15px -30px;
  }
  @media (max-width: 599px) {
    margin: 0 -20px 15px -20px;
  }

  .MuiTabs-flexContainer {
    @media (max-width: 1200px) {
      justify-content: space-between;
      padding: 0 80px;
    }
    @media (max-width: 1000px) {
      padding: 0 60px;
    }
    @media (max-width: 767px) {
      padding: 0 30px;
    }
    @media (max-width: 599px) {
      padding: 0 10px;
    }
    @media (max-width: 440px) {
      padding: 0 5px;
    }
  }

  .MuiTabs-root {
    overflow: inherit;

    .MuiTabs-fixed {
      overflow: inherit !important;
    }
  }

  .MuiButtonBase-root {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 1;
    color: #172751;
    text-transform: none;
    margin: 0 32px;
    padding: 22px 18px;
    opacity: 1;
    min-width: inherit;

    @media (max-width: 1650px) {
      margin: 0 22px;
      padding: 18px 10px;
    }
    @media (max-width: 1440px) {
      margin: 0 12px;
    }
    @media (max-width: 1300px) {
      font-size: 17px;
      margin: 0 5px;
      padding: 18px 8px;
    }
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 1.2;
      min-width: 120px;
    }
    @media (max-width: 1000px) {
      font-size: 14px;
      padding: 18px 5px;
      margin: 0 2px;
    }
    @media (max-width: 767px) {
      padding: 18px 2px;
      min-width: inherit;
      font-size: 13px;
    }

    &.TabOutOfStock {
      @media (max-width: 860px) {
        max-width: 90px !important;
      }
      @media (max-width: 767px) {
        max-width: 78px !important;
      }

      .MuiTab-wrapper {
        display: flex;
        align-items: center;
        flex-direction: inherit;
      }
    }

    &.TabHidden {
      .MuiTab-wrapper {
        display: flex;
        align-items: center;
        flex-direction: inherit;
      }
    }

    &.MuiTab-labelIcon {
      min-height: inherit;
    }

    .TabHiddenIcon {
      margin-bottom: 0 !important;
      margin-right: 6px;
      @media (max-width: 767px) {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .TabOutOfStockNumber {
      flex: 0 0 auto;
      background-color: #fca600;
      width: 22px;
      height: 22px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      color: #fff;
      margin: 0 6px 0 0 !important;
      @media (max-width: 767px) {
        margin: 0 3px 0 0 !important;
      }
    }
  }

  &.DashboardTabsAnalytics {
    .MuiButtonBase-root {
      @media (max-width: 599px) {
        flex: 1 1 auto;
        margin: 0;
        padding: 16px 2px;
      }
    }
  }

  &.TabsMenuEditor {
    margin-top: 10px;
    @media (max-width: 1200px) {
      margin: 10px 0 0 0;
      border-radius: 10px;
      .MuiTabs-flexContainer {
        justify-content: space-between;
        padding: 0;
      }
    }
    .MuiButtonBase-root {
      padding: 20px 12px;
      margin: 0 18px;

      @media (max-width: 1700px) {
        padding: 16px 10px;
        margin: 0 12px;
      }
      @media (max-width: 1500px) {
        margin: 0 5px;
        font-size: 17px;
      }

      @media (max-width: 1400px) {
        padding: 16px 8px;
        margin: 0 4px;
      }
      @media (max-width: 1350px) {
        font-size: 16px;
      }

      @media (max-width: 1200px) {
        min-width: inherit;
        padding: 15px 8px;
      }

      @media (max-width: 1000px) {
        font-size: 14px;
        padding: 15px 4px;
        flex: 1 1 auto;
      }

      @media (max-width: 767px) {
        min-width: inherit;
        margin: 0 4px;
        padding: 15px 0;
        font-size: 13px;
        max-width: 90px;
      }
      @media (max-width: 700px) {
        font-size: 12px;
        max-width: 85px;
      }
    }
  }

  &.FeedbackTabs {
    .MuiTab-wrapper {
      display: flex;
      align-items: center;
      flex-direction: inherit;
      span {
        padding-left: 6px;
        font-weight: 300;
      }
    }
    margin-top: 20px;
    @media (max-width: 1200px) {
      margin: 10px 0 0 0;
      border-radius: 10px;
      .MuiTabs-flexContainer {
        justify-content: space-between;
        padding: 0;
      }
    }
    .MuiButtonBase-root {
      padding: 20px 12px;
      margin: 0 18px;

      @media (max-width: 1700px) {
        padding: 16px 10px;
        margin: 0 12px;
      }
      @media (max-width: 1500px) {
        margin: 0 5px;
        font-size: 17px;
      }

      @media (max-width: 1400px) {
        padding: 16px 8px;
        margin: 0 4px;
      }
      @media (max-width: 1350px) {
        font-size: 16px;
      }

      @media (max-width: 1200px) {
        min-width: inherit;
        padding: 15px 8px;
      }

      @media (max-width: 1000px) {
        font-size: 14px;
        padding: 15px 4px;
        flex: 1 1 auto;
      }

      @media (max-width: 767px) {
        min-width: inherit;
        margin: 0 4px;
        padding: 15px 0;
        font-size: 13px;
        max-width: 90px;
      }
      @media (max-width: 700px) {
        font-size: 12px;
        max-width: 85px;
      }
    }
  }

  .Mui-selected {
    font-weight: 700;
  }

  .MuiTabs-indicator {
    background-color: #ff2d38;
    border-radius: 4px;

    &::after {
      position: absolute;
      content: '';
      top: 95%;
      left: 50%;
      transform: translateX(-50%);
      width: 36px;
      height: 8px;
      background-image: url(${IconIndicator});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

export const DashboardInners = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 -10px;

  @media (max-width: 1650px) {
    display: block;
    margin: 0;
  }
`;

export const DashboardSection = styled.div`
  margin-top: 28px;
  position: relative;
  background-color: transparent;

  &.Mt30 {
    margin-top: 30px;
  }
`;
export const DashboardSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.flex-end {
    justify-content: flex-end;
  }
  &.align-end {
    align-items: flex-end;
  }

  &.DashboardSectionHeaderPanel {
    @media (max-width: 768px) {
      min-height: 46px;
    }
  }
`;

export const DashboardSectionInner = styled.div`
  display: inline-block;

  &.W50 {
    flex: 0 0 50%;
    @media (max-width: 1650px) {
      flex: 0 0 75%;
    }
    @media (max-width: 1400px) {
      flex: 1 1 auto;
      margin-right: 18px;
    }
    @media (max-width: 1250px) {
      flex: 1 1 auto;
      margin-right: 44px;
    }
    @media (max-width: 1000px) {
      margin-right: 26px;
    }
  }
`;

export const DashboardInnerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DashboardInnerDescription = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.2;
  color: #929cb9;
  padding-left: 5px;
  border-left: 2px solid #ff2d38;

  button {
    margin-left: 10px;
  }

  &.mLeft20 {
    margin-left: 22px;
    @media (max-width: 550px) {
      margin: 10px 0 0 0;
    }
  }

  &.withIcon {
    padding-left: 0;
    border-left: 0;
    &::before {
      display: inline-block;
      content: '';
      width: 20px;
      height: 20px;
      background-image: url(${IconHint});
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      flex: 0 0 auto;
    }

    span {
      padding-left: 10px;
      margin-left: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1;
      color: #2f3d48;
      position: relative;
      @media (max-width: 767px) {
        font-size: 12px;
      }

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 2px;
        height: 18px;
        background-color: #ff2d38;
        content: '';
      }
    }
  }
`;
export const DashboardInnerBody = styled.div`
  margin-top: 28px;

  @media (max-width: 1000px) {
    margin-top: 18px;
  }

  &.WithoutMargin {
    margin: 0;
  }
`;

export const SauceChart = styled.div`
  display: block;
  position: relative;
  .SauceChartLabel {
    color: #666;
    position: absolute;
    bottom: -2px;
    right: 0;
    line-height: 1;
    @media (max-width: 767px) {
      bottom: 3px;
    }
  }
  @media (max-width: 767px) {
    overflow-x: auto;
  }

  & > div {
    position: relative;
    @media (max-width: 767px) {
      min-width: 700px;
      padding-bottom: 5px;
    }
  }

  .recharts-responsive-container {
    width: 100% !important;
    height: 350px !important;
  }
`;

export const DashboardBoxes = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
`;
export const DashboardBox = styled.div`
  &:first-child {
    padding-right: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    @media (max-width: 1800px) {
      padding-right: 28px;
    }
    @media (max-width: 1650px) {
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      padding-right: 15px;
    }
  }

  &:last-child {
    border-left: 2px solid rgba(23, 39, 81, 0.1);
    padding: 2px 0 2px 38px;
    flex: 1 1 auto;

    @media (max-width: 1800px) {
      padding-left: 28px;
    }
    @media (max-width: 1650px) {
      padding-left: 20px;
    }
    @media (max-width: 767px) {
      padding-left: 15px;
    }
  }
`;
export const DashboardBoxNumber = styled.div`
  flex: 0 0 auto;
  max-width: 70%;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 1;
  color: #2f3d48;
  white-space: nowrap;
  @media (max-width: 767px) {
    font-size: 38px;
  }
  @media (max-width: 440px) {
    font-size: 30px;
  }
`;
export const DashboardBoxPercent = styled(body1)`
  flex: 0 0 auto;
  font-weight: 600;
  padding-left: 30px;
  margin-left: 30px;
  position: relative;

  @media (max-width: 1650px) {
    margin-left: 20px;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.PercentRise {
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 12px solid #17c981;
    }
  }

  &.PercentDecrease {
    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 12px solid #f46e0c;
    }
  }
`;
export const DashboardBoxText = styled.div`
  display: block;
  align-items: flex-end;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #232d3a;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 440px) {
    font-size: 14px;
  }

  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.3;
    color: #2f3d48;
    display: inline-block;
    padding-right: 6px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
    @media (max-width: 440px) {
      font-size: 20px;
      padding-right: 4px;
    }
  }
`;

export const TableSort = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: #232d3a;
  display: inline-block;
  padding-right: 20px;
  position: relative;
  font-family: 'Poppins';
  @media (max-width: 1440px) {
    font-size: 14px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 11px;
    height: 15px;
    background-image: url('${(props) => props.icon || IconSort}');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      width: 10px;
      height: 14px;
    }
  }
`;
export const TableImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
`;
export const TableInline = styled.div`
  display: flex;
  align-items: center;
`;

export const TableText = styled(body1)`
  display: inline-block;
`;
export const DashboardTable = styled.div`
  display: block;
  .MuiTable-root {
    min-width: 767px;
  }

  .MuiTableCell-root {
    border-bottom: 0;
    padding: 10px 10px 10px 0;
  }

  .MuiTableCell-head {
    padding: 10px 10px 10px 0;
  }

  .MuiTypography-root {
    font-family: 'Poppins';
  }

  .TableCellName {
    width: 45%;
  }
`;

export const TablePagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid rgba(0, 80, 106, 0.1);
  padding: 16px 0 0 0;
  margin-top: 20px;

  @media (max-width: 599px) {
    display: block;
  }

  .pagination {
    display: flex;
    align-items: center;
    @media (max-width: 599px) {
      margin-top: 10px;
    }

    &.remove {
      display: none;
    }
    li {
      display: inline-block;
      margin: 0 3px;
      outline: none;

      a {
        display: block;
        width: 38px;
        height: 38px;
        background-color: #ededed;
        border-radius: 4px;
        text-align: center;
        line-height: 38px;
        color: #3e4954;
        transition-property: background-color, color;
        transition-duration: 0.3s;
        cursor: pointer;
        outline: none;

        &:hover {
          background-color: #ff2d38;
          color: #fff;
        }
      }

      &.active {
        a {
          background-color: #ff2d38;
          color: #fff;
        }
      }

      &.previous {
        a {
          text-indent: -9999px;
          background-color: transparent;
          background-image: url('${IconPrevPag}');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 21px;
        }

        &.disabled {
          display: none;
        }
      }

      &.next {
        a {
          text-indent: -9999px;
          background-color: transparent;
          background-image: url('${IconNextPag}');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 21px;
        }

        &.disabled {
          display: none;
        }
      }
    }
  }
`;

export const TablePaginationText = styled(body1)`
  display: inline-block;
  color: #929cb9;
  font-size: 16px;
  font-weight: 500;
`;

export const NoResultText = styled(body1)`
  font-size: 16px;

  &.noLoadMore {
    text-align: center;
    margin-top: 25px;
  }
`;

export const DashboardSelectWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  @media (max-width: 1650px) {
    width: 75%;
  }
  @media (max-width: 1250px) {
    flex-direction: column;
    align-items: baseline;
    width: 100%;
  }
`;

export const HourItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -30px;
  @media (max-width: 1650px) {
    margin: 0 -24px;
  }
  @media (max-width: 1300px) {
    margin: 0 -16px;
  }
  @media (max-width: 1100px) {
    margin: 0 -10px;
  }
  @media (max-width: 1000px) {
    display: block;
    margin: 0;
  }
`;

export const HourItem = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 30px;
  border-right: 2px solid #ff2d38;
  @media (max-width: 1650px) {
    padding: 3px 24px;
  }
  @media (max-width: 1300px) {
    padding: 3px 16px;
  }
  @media (max-width: 1100px) {
    padding: 3px 10px;
  }
  @media (max-width: 1000px) {
    padding: 6px 0;
    border: none;
  }

  &:last-child {
    border-right: none;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.1;
    color: #2f3d48;
    padding-right: 12px;
    @media (max-width: 1650px) {
      font-size: 14px;
    }
    @media (max-width: 1300px) {
      padding-right: 8px;
    }
    @media (max-width: 1000px) {
      min-width: 250px;
    }
  }

  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.1;
    color: #2f3d48;
    @media (max-width: 1650px) {
      font-size: 14px;
    }
  }
`;

export const DashboardInnerActions = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 28px;
  }
`;

export const DashboardAccordions = styled.div`
  display: block;
  margin-top: 30px;
  position: relative;
  .AccordionInner {
    &:last-child {
      &::before {
        display: block !important;
        opacity: 1 !important;
        content: '';
        position: absolute;
        top: 27px;
        left: 35px;
        width: 1px;
        height: calc(100% - 40px);
        background-color: #fff;
        z-index: 3;
        transition-property: opacity;
        transition-duration: 0.2s;
        @media (max-width: 1650px) {
          left: 33px;
        }
        @media (max-width: 1440px) {
          left: 34px;
        }
      }
    }
  }

  & > .DraGnDropList {

    & > .DraGnDropItem {
      position: relative;
      margin-bottom: 10px;
      .MuiAccordionSummary-content.Mui-expanded {
        .AccordionHeaderItems {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .MuiAccordionDetails-root {
    flex-direction: column;
    padding-bottom: 25px;
    display: block;
    padding-top: 0;
  }

  .MuiAccordion-root {
    box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
    border-radius: 10px !important;
    margin: 0 0 10px 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }

    &::before {
      display: none;
    }

    &.Mui-disabled {
      background-color: #fff;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;

      .AccordionHeaderItems {
        &::after {
          display: none;
        }
      }
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
    padding: 15px 0;
  }

  .MuiAccordionSummary-root {
    min-height: inherit;
    padding: 0 16px 0 31px;
    @media (max-width: 1000px) {
      padding-left: 41px;
    }

    .Mui-expanded {
      min-height: inherit;
    }

    &.Mui-disabled {
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      opacity: inherit;
    }
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .Mui-expanded {
    & > .AccordionHeaderItems {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .AccordionInnerParent {
    position: relative;

    &::after {
      display: block;
      opacity: 0;
      content: '';
      position: absolute;
      top: 75px;
      left: 65px;
      width: 1px;
      height: calc(100% - 120px);
      border-left: 1px dashed #dfe2ea;
      z-index: 2;
      transition-property: opacity;
      transition-duration: 0.2s;
      @media (max-width: 1650px) {
        left: 49px;
        top: 74px;
      }
      @media (max-width: 1440px) {
        left: 63px;
        top: 72px;
      }
    }

    &.Mui-expanded {
      &::after {
        opacity: 1;
      }
    }
  }

  .AccordionInnerChild {
    position: relative;
  }

  .AccordionInner {
    width: 100%;
    box-shadow: none;
    position: relative;
    background-color: transparent;

    &::after {
      display: block;
      opacity: 0;
      content: '';
      position: absolute;
      top: 46px;
      left: 102px;
      width: 1px;
      height: calc(100% - 90px);
      border-left: 1px dashed #dfe2ea;
      z-index: 1;
      transition-property: opacity;
      transition-duration: 0.2s;
      @media (max-width: 1650px) {
        left: 99px;
      }
      @media (max-width: 1440px) {
        left: 100px;
      }
    }

    &.Mui-expanded {
      &::after {
        opacity: 1;
      }
    }

    & > div {
      padding: 0;
    }

    .MuiButtonBase-root {
      padding: 0 0 0 81px;
      min-height: inherit;
      @media (max-width: 1650px) {
        padding-left: 76px;
      }
      @media (max-width: 1440px) {
        padding-left: 77px;
      }
    }

    .AccordionHeaderImage {
      width: 46px;
      height: 46px;
      position: relative;
      
      @media (max-width: 1000px) {
        width: 40px;
        height: 40px;
      }

      &::before {
        position: absolute;
        background-color: #dfe2ea;
        top: 50%;
        transform: translateY(-4px);
        left: -50px;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        z-index: 2;
        @media (max-width: 1650px) {
          left: -47px;
        }
      }

      &::after {
        position: absolute;
        top: 50%;
        left: -45px;
        content: '';
        width: 45px;
        border-top: 1px dashed #dfe2ea;
        z-index: 1;
        @media (max-width: 1650px) {
          width: 43px;
          left: -43px;
        }
      }
    }

    .AccordionHeaderName {
      font-size: 18px;
      font-weight: 500;
      @media (max-width: 1650px) {
        font-size: 17px;
      }
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 1000px) {
        font-size: 15px;
      }
    }

    .ButtonMore {
      margin-right: -1px;
    }

    .MuiAccordionSummary-content {
      margin-top: 0;
      padding-top: 0;
    }

    &.AccordionInnerMain {
      &::after {
        height: calc(100% - 110px);
      }

      .ButtonMore {
        margin-top: 12px;
      }
    }

    &.AccordionInnerParent {
      padding-left: 51px !important;
      @media (max-width: 1650px) {
        padding-left: 50px !important;
      }

      &::after {
        left: 154px;
        height: calc(100% - 110px);
        @media (max-width: 1650px) {
          left: 149px;
        }
      }

      &::before {
        left: 86px;
        @media (max-width: 1650px) {
          left: 83px;
        }
        @media (max-width: 1440px) {
          left: 84px;
        }
      }

      .ButtonMore {
        margin-right: -16px;
      }
    }

    &.AccordionInnerChild {
      padding-left: 52px !important;
      @media (max-width: 1650px) {
        padding-left: 50px !important;
      }

      &::after {
        left: 154px;
        height: calc(100% - 90px);
        @media (max-width: 1650px) {
          left: 149px;
        }
      }

      &::before {
        left: 87px;
        @media (max-width: 1650px) {
          left: 83px;
        }
      }

      .ButtonMore {
        margin-right: -32px;
      }

      .AccordionDescriptionItem {
        @media (max-width: 1650px) {
          margin-left: 63px;
        }

        .AccordionHeaderDescription {
          @media (max-width: 1440px) {
            padding-left: 53px;
          }
        }

        .ButtonMore {
          margin-right: -48px !important;
          margin-top: 5px;
        }

        &:last-child {
          padding-bottom: 0;

          &::before {
            display: block !important;
            opacity: 1 !important;
            content: '';
            position: absolute;
            top: 29px;
            left: 20px;
            width: 1px;
            height: calc(100% - 40px);
            background-color: #fff;
            z-index: 3;
            transition-property: opacity;
            transition-duration: 0.2s;
            @media (max-width: 1650px) {
              left: 20px;
              top: 26px;
            }
            @media (max-width: 1440px) {
              top: 24px;
            }
          }
        }
      }
    }

    &.AccordionInnerChild2 {
      padding-left: 52px !important;
      @media (max-width: 1650px) {
        padding-left: 50px !important;
      }

      &::after {
        left: 154px;
        height: calc(100% - 90px);
        @media (max-width: 1650px) {
          left: 149px;
        }
      }

      &::before {
        left: 86px;
        @media (max-width: 1650px) {
          left: 83px;
        }
      }

      .ButtonMore {
        margin-right: -48px;
      }

      .AccordionDescriptionItem {
        @media (max-width: 1650px) {
          margin-left: 63px;
        }

        .AccordionHeaderDescription {
          @media (max-width: 1440px) {
            padding-left: 53px;
          }
        }

        .ButtonMore {
          margin-right: -64px !important;
          margin-top: 5px;
        }

        &:last-child {
          padding-bottom: 0;

          &::before {
            display: block !important;
            opacity: 1 !important;
            content: '';
            position: absolute;
            top: 29px;
            left: 20px;
            width: 1px;
            height: calc(100% - 40px);
            background-color: #fff;
            z-index: 3;
            transition-property: opacity;
            transition-duration: 0.2s;
            @media (max-width: 1650px) {
              left: 20px;
              top: 26px;
            }
            @media (max-width: 1440px) {
              top: 24px;
            }
          }
        }
      }
    }

    &.AccordionInnerChild3 {
      padding-left: 52px !important;
      @media (max-width: 1650px) {
        padding-left: 50px !important;
      }

      &::after {
        left: 154px;
        height: calc(100% - 90px);
        @media (max-width: 1650px) {
          left: 149px;
        }
      }

      &::before {
        left: 86px;
        @media (max-width: 1650px) {
          left: 83px;
        }
      }

      .ButtonMore {
        margin-right: -64px;
      }

      .AccordionDescriptionItem {
        @media (max-width: 1650px) {
          margin-left: 63px;
        }

        .AccordionHeaderDescription {
          @media (max-width: 1440px) {
            padding-left: 53px;
          }
        }

        .ButtonMore {
          margin-right: -80px !important;
          margin-top: 5px;
        }

        &:last-child {
          padding-bottom: 0;

          &::before {
            display: block !important;
            opacity: 1 !important;
            content: '';
            position: absolute;
            top: 29px;
            left: 20px;
            width: 1px;
            height: calc(100% - 40px);
            background-color: #fff;
            z-index: 3;
            transition-property: opacity;
            transition-duration: 0.2s;
            @media (max-width: 1650px) {
              left: 20px;
              top: 26px;
            }
            @media (max-width: 1440px) {
              top: 24px;
            }
          }
        }
      }
    }
  }

  .AccordionDescriptionItem {
    .AccordionHeaderImage {
      width: 46px;
      height: 46px;
      position: relative;
      @media (max-width: 1650px) {
        width: 43px;
        height: 43px;
      }
      @media (max-width: 1440px) {
        width: 40px;
        height: 40px;
      }

      &::before {
        position: absolute;
        background-color: #dfe2ea;
        top: 50%;
        transform: translateY(-4px);
        left: -50px;
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        z-index: 2;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: -45px;
        content: '';
        width: 45px;
        border-top: 1px dashed #dfe2ea;
        z-index: 1;
      }
    }

    .AccordionHeaderName {
      font-size: 18px;
      font-weight: 500;
      @media (max-width: 1650px) {
        font-size: 17px;
      }
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      @media (max-width: 1000px) {
        font-size: 15px;
      }
    }

    .ButtonMore {
      margin-right: -1px !important;
    }
  }

  &.withDgD {
    .Accordion {
      &::after {
        display: block;
        opacity: 0;
        content: '';
        position: absolute;
        top: 76px;
        left: 65px;
        width: 1px;
        height: calc(100% - 120px);
        border-left: 1px dashed #dfe2ea;
        z-index: 2;
        transition-property: opacity;
        transition-duration: 0.2s;
        @media (max-width: 1650px) {
          left: 64px;
          top: 73px;
          height: calc(100% - 115px);
        }
        @media (max-width: 1440px) {
          left: 63px;
          top: 72px;
        }
        @media (max-width: 1000px) {
          left: 60px;
          top: 58px;
          height: calc(100% - 100px);
        }
      }

      &.Mui-expanded {
        &::after {
          opacity: 1;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0 10px 0 97px;
        min-height: inherit;
        @media (max-width: 1650px) {
          padding: 0 10px 0 90px;
        }
        @media (max-width: 1440px) {
          padding: 0 10px 0 85px;
        }
        @media (max-width: 1000px) {
          padding: 0 10px 0 82px;
        }

        .DraGnDropItem {
          position: relative;

          &:last-child {
            &::before {
              display: block;
              opacity: 1;
              content: '';
              position: absolute;
              top: 27px;
              left: -32px;
              width: 1px;
              height: calc(100% - 40px);
              background-color: #fff;
              z-index: 3;
              transition-property: opacity;
              transition-duration: 0.2s;
              @media (max-width: 1650px) {
                left: -26px;
              }
              @media (max-width: 1440px) {
                left: -22px;
                top: 27px;
              }
              @media (max-width: 767px) {
                left: -22px;
                top: 24px;
              }
            }
          }

          &.DragDropIcon {
            position: absolute;
          }

          .DragAndDropIcon {
            width: 24px;
            margin-top: 12px;
          }

          .ButtonMore {
            margin-top: 12px;
          }

          .MuiAccordionDetails-root {
            padding: 0 0 0 67px;
            min-height: inherit;
            @media (max-width: 1650px) {
              padding: 0 0 0 62px;
            }
            @media (max-width: 1440px) {
              padding: 0 0 0 59px;
            }
            @media (max-width: 767px) {
              padding: 0 0 0 50px;
            }
          }

          .DraGnDropItem {

            &:last-child {
              &::before {
                top: 27px;
                left: -45px;
                @media (max-width: 1650px) {
                  left: -40px;
                }
                @media (max-width: 1440px) {
                  left: -36px;
                }
                @media (max-width: 1000px) {
                  left: -39px;
                  top: 24px;
                }
                @media (max-width: 767px) {
                  left: -30px;
                }
              }
            }
          }
        }

        .Accordion {
          position: relative;
          width: 100%;
          box-shadow: none;
          background-color: transparent;

          &::after {
            display: block;
            opacity: 0;
            content: '';
            position: absolute;
            top: 46px;
            left: 22px;
            width: 1px;
            height: calc(100% - 86px);
            border-left: 1px dashed #dfe2ea;
            z-index: 1;
            transition-property: opacity;
            transition-duration: 0.2s;
            @media (max-width: 1650px) {
              left: 22px;
            }
            @media (max-width: 1440px) {
              left: 23px;
            }
            @media (max-width: 1000px) {
              left: 20px;
              top: 40px;
              height: calc(100% - 78px);
            }
          }

          &.Mui-expanded {
            &::after {
              opacity: 1;
            }
          }

          & > div {
            padding: 0;
          }

          .MuiButtonBase-root {
            min-height: inherit;
            padding: 0;
          }

          .AccordionHeaderImage {
            width: 46px;
            height: 46px;
            position: relative;
            @media (max-width: 1000px) {
              width: 40px;
              height: 40px;
            }

            &::before {
              position: absolute;
              background-color: #dfe2ea;
              top: 50%;
              transform: translateY(-4px);
              left: -36px;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              z-index: 2;
              @media (max-width: 1650px) {
                left: -30px;
              }
              @media (max-width: 1440px) {
                left: -26px;
              }
              @media (max-width: 767px) {
                left: -25px;
              }
            }

            &::after {
              position: absolute;
              top: 50%;
              left: -35px;
              content: '';
              width: 39px;
              border-top: 1px dashed #dfe2ea;
              z-index: 1;
              @media (max-width: 1650px) {
                width: 28px;
                left: -28px;
              }
              @media (max-width: 1440px) {
                width: 25px;
                left: -25px;
              }
              @media (max-width: 767px) {
                width: 25px;
                left: -25px;
              }
            }
          }

          .AccordionHeaderName {
            font-size: 18px;
            font-weight: 500;
            @media (max-width: 1650px) {
              font-size: 17px;
            }
            @media (max-width: 1440px) {
              font-size: 16px;
            }
            @media (max-width: 1000px) {
              font-size: 15px;
            }
          }

          .MuiAccordionSummary-content {
            margin-top: 0;
            padding-top: 0;
          }

          .Accordion {
            .AccordionHeaderImage {
              &::before {
                left: -49px;
                @media (max-width: 1650px) {
                  left: -44px;
                }
                @media (max-width: 1440px) {
                  left: -40px;
                }
                @media (max-width: 1000px) {
                  left: -43px;
                }
                @media (max-width: 767px) {
                  left: -34px;
                }
              }

              &::after {
                left: -40px;
                @media (max-width: 1650px) {
                  width: 40px;
                  left: -40px;
                }
                @media (max-width: 1440px) {
                  width: 37px;
                  left: -37px;
                }
                @media (max-width: 767px) {
                  width: 30px;
                  left: -30px;
                }
              }
            }
          }
        }
      }
    }
`;

export const AccordionHeader = styled.div`
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &.searched {
    background: #f2f1f6;
  }

  @media (max-width: 1000px) {
    padding: 3px 10px 3px 0;
  }

  .MuiButtonBase-root {
    flex: 1 1 auto;
  }

  .DragAndDropIcon {
    width: 24px;
    flex: 0 0 auto;
    margin: 27px 5px 0 5px;
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    &:hover {
      opacity: 0.7;
    }
    &:active,
    &:focus {
      transform: scale(0.85);
    }

    @media (max-width: 1000px) {
      margin-top: 23px;
    }
  }

  .VisibleIcon {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin: 0 5px;

    svg {
      width: 100%;
    }

    @media (max-width: 1440px) {
      width: 20px;
      height: 20px;
      top: -3px;
      position: relative;
    }
  }

  .ButtonMore {
    flex: 0 0 auto;
    margin: 27px 5px 0 5px;
    @media (max-width: 1000px) {
      margin-top: 23px;
    }
  }

  .MuiAccordionSummary-content {
    display: flex;
    align-items: flex-start;
  }
`;
export const AccordionHeaderImage = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #2f3d48;
  border-radius: 4px;
  flex: 0 0 auto;
  position: relative;
  z-index: 2;
  @media (max-width: 1650px) {
    width: 48px;
    height: 48px;
  }
  @media (max-width: 1440px) {
    width: 46px;
    height: 46px;
  }
  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
    position: relative;
    z-index: 2;
  }
`;
export const AccordionHeaderInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 14px 0 0 20px;
  flex: 1 1 auto;
  @media (max-width: 1650px) {
    padding-left: 15px;
  }
  @media (max-width: 1440px) {
    padding: 15px 0 0 12px;
  }
  @media (max-width: 1000px) {
    padding: 7px 0 0 10px;
  }
`;
export const AccordionHeaderDescription = styled.div`
  flex: 0 0 100%;
  margin-top: 15px;
  @media (max-width: 1650px) {
    margin-top: 10px;
  }

  &.noMarginTop {
    margin-top: 0;
  }
`;
export const AccordionHeaderName = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  color: #2f3d48;
  padding-right: 20px;
  border-right: 2px solid #ff2d38;
  word-wrap: break-word;
  margin-right: 20px;

  &.noBorderRight {
    border-right: 0;
    padding-right: 0;
  }

  @media (max-width: 1650px) {
    font-size: 18px;
    padding-right: 25px;
  }
  @media (max-width: 1440px) {
    font-size: 16px;
    padding-right: 20px;
    margin-right: 15px;
  }
  @media (max-width: 1300px) {
    padding-right: 10px;
    margin-right: 10px;
  }
  @media (max-width: 1000px) {
    margin: 5px 10px 0 0;
    font-size: 15px;
  }
`;
export const AccordionHeaderPrice = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;
  padding: 0 20px;
  border-right: 2px solid #ff2d38;
  margin-right: 20px;

  &.noBorderRight {
    border-right: none;
    padding-right: 0;
    padding-left: 25px;
  }

  @media (max-width: 1650px) {
    padding: 0 25px;
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    padding: 0 15px 0 15px;
    font-size: 14px;
    top: 1px;
  }
  @media (max-width: 1300px) {
    padding: 0 10px;
    margin-right: 10px;
  }
  @media (max-width: 1000px) {
    margin: 5px 10px 0 0;
    padding-left: 0;
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${IconLayers});
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
  }

  &:last-child {
    border-right: 0;
    padding-right: 0;
  }
`;
export const AccordionHeaderDelivery = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;
  padding: 0 0 0 20px;
  // border-right: 2px solid #17c981;
  border-left: 2px solid #ff2d38;
  @media (max-width: 1650px) {
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    padding: 0 15px;
    font-size: 14px;
  }
  @media (max-width: 1300px) {
    padding: 0 10px;
  }
  @media (max-width: 1000px) {
    margin: 5px 0 0 0;
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${IconDelivery});
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
  }
`;

export const AccordionHeaderPickup = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;
  padding: 0 20px;
  border-right: 2px solid #ff2d38;
  border-left: 2px solid #ff2d38;
  margin-left: 20px;
  @media (max-width: 1650px) {
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    padding: 0 15px;
    font-size: 14px;
    margin-left: 15px;
  }
  @media (max-width: 1300px) {
    padding: 0 10px;
    margin-left: 0;
  }
  @media (max-width: 1000px) {
    margin: 5px 10px 0 0;
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${IconPickup});
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
  }
`;
export const AccordionHeaderItems = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  color: #929cb9;
  position: relative;
  padding-right: 40px;

  &::after {
    position: absolute;
    top: -2px;
    right: 0;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(${IconDropRed});
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    transition-property: transform;
    transition-duration: 0.3s;
    @media (max-width: 1440px) {
      top: -3px;
    }
  }

  @media (max-width: 1650px) {
    font-size: 15px;
    top: 1px;
  }
  @media (max-width: 1440px) {
    font-size: 14px;
    padding-right: 30px;
  }
  @media (max-width: 1000px) {
    margin: 5px 0 0 0;
  }
`;

// export const AccordionHeaderDescription = styled.div`
//   flex: 0 0 100%;
//   padding-left: 70px;
//   margin-top: 15px;
// `;

export const AccordionDescription = styled.div`
  display: block;
  width: 100%;

  &.AccordionDescriptionItems {
    .AccordionDescriptionItem {
      &:first-child {
        padding-top: 0;
      }
    }
  }
`;

export const AccordionDescriptionItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 66px;
  padding-left: 66px;
  padding-top: 20px;
  justify-content: space-between;
  position: relative;
  @media (max-width: 1650px) {
    margin-left: 60px;
  }
  @media (max-width: 1440px) {
    margin-left: 61px;
  }

  &:first-child {
    padding-top: 0;
  }

  &.MLeftMin {
    margin-left: 15px;
  }

  .AccordionHeaderName {
    padding-right: 0;
    border-right: 0;
  }

  .ButtonMore {
    margin-right: -17px !important;
  }
`;

export const AccordionDescriptionInner = styled.div`
  display: flex;
  align-items: center;
`;
export const AccordionDescriptionText = styled.div`
  display: flex;
  flex: 0 0 100%;
  // align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;
  margin-bottom: 10px;

  @media (max-width: 1650px) {
    font-size: 16px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
    align-items: center;
  }
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    display: inline-block;
    font-weight: 500;
    margin-right: 5px;
    flex: 0 0 auto;
    @media (max-width: 1650px) {
      font-size: 16px;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
      position: relative;
      top: -1px;
    }
  }

  &.OutOfStock {
    font-size: 16px;
    color: #fca600;
    align-items: center;
    @media (max-width: 1440px) {
      font-size: 12px;
      align-items: center;
    }

    span {
      background-color: #fca600;
      width: 22px;
      height: 22px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      color: #fff;
      margin: 0 10px 0 0;
      position: relative;
      @media (max-width: 1440px) {
        width: 20px;
        height: 20px;
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
`;
export const AccordionHeaderText = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #2f3d48;
  margin-left: 35px;
  @media (max-width: 1440px) {
    margin-left: 25px;
  }

  &.OutOfStock {
    font-size: 16px;
    color: #fca600;
    top: 2px;
    @media (max-width: 1440px) {
      font-size: 12px;
      position: relative;
      margin-left: 15px;
    }
    @media (max-width: 1300px) {
      margin-left: 10px;
    }

    span {
      background-color: #fca600;
      width: 22px;
      height: 22px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 22px;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      color: #fff;
      margin: 0 10px 0 0;
      @media (max-width: 1440px) {
        font-size: 10px;
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
      @media (max-width: 1300px) {
        margin-right: 6px;
      }
    }
  }
`;

export const AccordionItems = styled.div`
  display: block;
  margin-top: 40px;
`;

export const AccordionItem = styled.div`
  display: block;
  margin-bottom: 27px;

  &:last-child {
    margin-bottom: 0;
  }

  & > .MuiAccordion-root {
    background-color: transparent;
    box-shadow: none;

    & > .MuiAccordionSummary-root {
      min-height: inherit !important;
      padding: 0;

      & > .MuiAccordionSummary-content {
        margin: 0;

        &.space-between {
          justify-content: space-between;
        }

        &.Mui-expanded {
          margin: 0 !important;

          & > .AccordionItemName {
            &::after {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
    }
  }

  &.AccordionItemModal {
    margin-top: 5px;

    .AccordionItemName {
      color: #2f3d48;
      font-size: 18px;
      @media (max-width: 1650px) {
        font-size: 18px;
      }
    }

    .DialogField {
      padding-left: 0;
    }
  }
`;

export const AccordionItemName = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #fca600;
  padding-right: 40px;
  position: relative;
  @media (max-width: 1650px) {
    font-size: 19px;
  }
  @media (max-width: 1440px) {
    font-size: 18px;
    padding-right: 34px;
  }

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    content: '';
    width: 24px;
    height: 24px;
    background-image: url(${IconDropDark});
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    transition-property: transform;
    transition-duration: 0.3s;
  }

  &.AccordionItemName {
    display: inline-flex;
    align-items: center;
    .BulkCheckbox {
      flex: 0 0 auto;
      margin-right: 6px;
      & > * {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }
`;

export const AccordionItemBody = styled.div`
  display: block;
`;
export const DialogSubTitle = styled.div`
  display: block;
  font-style: normal;
  color: #2f3d48;
  margin-bottom: 15px;
  text-align: center;
  &.mBottomLarge {
    margin-bottom: 35px;
  }
  &.textMedium {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }
  &.textMd {
    font-size: 16px;
    max-width: 320px;
    margin: 12px auto;
    font-weight: 500;
    span {
      display: block;
      color: #5988dc;
    }
  }
`;
export const DialogImageCenter = styled.img`
  display: block;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`;
export const DialogText = styled.div`
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.24px;
  color: #293759;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  &.align-justify {
    text-align: justify;
  }

  &.DialogTextSm {
    max-width: 300px;
    margin: 0 auto;
    span {
      color: #17c981;
    }
  }
  &.smText {
    font-size: 16px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`;
export const DialogBody = styled.div`
  margin-top: 15px;
  @media (max-width: 1440px) {
    margin-top: 5px;
  }
  .SpanCenter {
    text-align: center;
  }
`;
export const DialogHint = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  &::before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${IconHint});
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: -4px;
  }

  span {
    padding-left: 10px;
    margin-left: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.2;
    color: #969ba0;
    position: relative;

    &::before {
      position: absolute;
      top: -2px;
      left: 0;
      width: 2px;
      height: 18px;
      background-color: #ff2d38;
      content: '';
    }
  }

  &.DialogHintCenter {
    justify-content: center;

    span {
      padding-left: 8px;
      margin-left: 8px;
    }
  }

  &.mTop10 {
    margin-top: 10px;
  }

  &.mTop20 {
    margin-top: 20px;
  }

  &.DialogHintTablet {
    @media (max-width: 1000px) {
      flex: 1 1 auto;
    }
  }
`;
export const DialogItems = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: 25px -4px 0 -4px;

  &.DialogItemsNewModal {
    @media (max-width: 767px) {
      flex-wrap: wrap;

      & > div {
        flex: 0 0 calc(50% - 8px);
        max-width: inherit;
      }
    }
  }

  &.AlignLeft {
    justify-content: flex-start;
  }

  &.w70 {
    width: 70%;
  }

  &.DialogItemsTurnOnDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: 300px;
      flex: 0 0 calc(50% - 8px);
      &::before {
        border-color: #ff2d38;
      }
      &:hover {
        border-color: #ff2d38;
      }
      &.isActive {
        svg {
          path {
            fill: #ff2d38;
          }
        }
        span {
          color: #ff2d38;
        }
      }
    }
  }

  &.DialogItemsNewDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: 230px;
      flex: 0 0 calc(50% - 8px);
      &::before {
        border-color: #36998b;
      }
      &:hover {
        border-color: #36998b;
      }
      &.isActive {
        svg {
          path {
            fill: #36998b;
          }
        }
        span {
          color: #36998b;
        }
      }
    }
  }

  &.DialogItemsSelectDiscount {
    flex-wrap: wrap;

    & > div {
      max-width: 230px;
      max-height: 190px;
      padding: 15px 15px 20px;
      flex: 0 0 calc(33.3333333% - 8px);
      span {
        font-weight: bold;
        margin: 25px 0 20px;
        text-align: left;
        width: 100%;
      }
      &::before {
        border-color: #36998b;
        pointer-events: none;
      }
      &:hover {
        border-color: #36998b;
      }
      &.isActive {
        svg {
          path {
            fill: #36998b;
          }
        }
        span {
          color: rgba(23, 201, 129, 1);
        }
      }
    }
  }

  &.DialogItemsSoldOut {
    margin-top: 35px;

    & > div {
      min-width: inherit;
      max-width: inherit;
      flex: 0 0 calc(33.3333333% - 8px);
      min-height: 75px;
      padding: 18px;
      justify-content: center;

      &::before {
        border-color: #fca600;
      }

      span {
        margin-top: 0;
      }

      &:hover {
        border-color: #fca600;
      }
    }

    &.mTop5 {
      margin-top: 5px;
    }

    &.mTop0 {
      margin-top: 0;
    }

    &.isDisabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &.DialogItemsSoldOutModifier {
    & > div {
      max-width: 160px;
    }
  }

  &.DialogItemsRefundTypePicker {
    margin: 20px 0px 27px 0px;
    justify-content: space-between;
    & > div {
      margin: 0;
      max-width: none;
      width: calc(50% - 7px);
    }
  }
`;

export const DialogItem = styled.div`
  border: 1px solid #929cb9;
  border-radius: 5px;
  padding: 24px 10px 18px;
  cursor: pointer;
  min-width: 110px;
  max-width: 110px;
  min-height: 110px;
  max-height: 110px;
  flex: 0 0 auto;
  margin: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &::before {
    border: 3px solid #ff2d38;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    box-sizing: border-box;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  &.isDisabled {
    pointer-events: none;
    opacity: 0.5;
  }

  svg {
    path {
      transition-property: fill;
      transition-duration: 0.3s;
    }
  }

  span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
    color: #929cb9;
    margin-top: 10px;
    transition-property: color;
    transition-duration: 0.3s;
  }

  &.isActive {
    svg {
      path {
        //fill: #17c981;
        fill: #ff2d38;
      }
    }

    span {
      color: #2f3d48;
    }

    .redText {
      font-weight: 700;
      color: #ff2d38;
    }

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;
export const DialogItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;
export const DialogAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  @media (max-width: 1000px) {
    margin-top: 30px;
  }

  &.noMarginTop {
    margin-top: 0;
  }

  &.marginTop25 {
    margin-top: 25px;
  }

  &.marginTop50 {
    margin-top: 50px;
  }

  &.marginTop60 {
    margin-top: 60px;
  }

  button {
    max-width: 390px;
    width: 100%;

    &.MaxContentWidth {
      width: max-content;
      padding: 19px 24px;
    }

    &.ButtonMin {
      max-width: 160px;
    }
    &.ButtonMin2 {
      max-width: 220px;
    }
    &.ButtonMin3 {
      max-width: 200px;
    }

    &.ButtonMax {
      max-width: 520px;
    }
  }

  &.DialogActionEnd {
    justify-content: flex-end;
    margin-top: 40px;
  }
  &.DialogActionFlexCenterAndMargin {
    justify-content: center;
    margin-top: 64px;
    @media (max-width: 1023px) {
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  &.mTopLarge {
    margin-top: 69px;
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  &.DialogActions {
    margin: 35px -10px 0 -10px;
    button {
      margin: 0 10px;
      max-width: 250px;
    }
  }
`;
export const DialogCheckbox = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const DialogDays = styled.div`
  margin-top: 20px;
  display: block;
`;
export const DialogDaysLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.1;
  color: #929cb9;
  opacity: 0.5;
`;
export const DialogDaysInners = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -13px;
`;
export const DialogDaysInner = styled.div`
  display: inline-block;
  flex: 1 1 auto;
  width: 124px;
  padding: 10px 13px;

  &.SelectDay {
    flex: 0 0 160px;
  }
`;

export const DialogFields = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px 10px -10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.DialogFieldsSeparate {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 20px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      height: 1px;
      background-color: rgba(146, 156, 185, 0.3);
      content: '';
    }
  }
`;
export const DialogField = styled.div`
  padding: 13px 10px;
  position: relative;

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  & > div {
    &.isDisabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  &.w40 {
    flex: 0 0 40%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }

  &.w30 {
    flex: 0 0 30%;
  }

  &.w50 {
    flex: 0 0 50%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }

  &.w60 {
    flex: 0 0 60%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }

  &.w100 {
    flex: 0 0 100%;
  }

  &.wSm {
    flex: 0 0 104px;
  }

  &.Center {
    text-align: center;
  }

  &.pTop {
    padding-top: 20px;
  }

  &.pTop50 {
    padding-top: 50px;
    @media (max-width: 1300px) {
      padding-top: 41px;
    }
  }

  &.withRemove {
    position: relative;
    padding-right: 35px;
  }

  &.dFlex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &.dFlexNoWrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  &.dFlexBaseline {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }
  &.SelectRole {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    & > *:nth-child(1) {
      min-width: 120px;
    }
  }
  &.mTop20 {
    margin-top: 20px;
  }

  &.noPadding {
    padding: 0;
  }

  &.noWrapTablet {
    @media (max-width: 1000px) {
      flex-wrap: nowrap;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  &.dFlexSpaceBetween {
    justify-content: space-between;
  }
  &.mB8 {
    margin-bottom: 8px;
  }
  &.mB37 {
    margin-bottom: 37px;
  }
  &.w97px {
    max-width: 97px;
    padding: 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #2f3d48;
  }
  .StaticRangeDatePickerText {
    padding: 0 12px 18px 12px;
  }
`;
export const FieldLabel = styled.label`
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.24px;
  color: #929cb9;
  margin-bottom: 4px;
  &.marginBtNull {
    margin-bottom: 0;
  }

  &.FieldLabelInline {
    display: inline-block;
  }
  &.FieldLabelLg {
    font-weight: 500;
    color: #2f3d48;
    font-size: 14px;
    margin-bottom: 3px;
  }
  &.FW400 {
    font-weight: 400;
  }
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.marginR8 {
    margin-right: 8px;
  }
  &.darkTitle {
    color: #2f3d48;
  }
`;

export const FieldInput = styled.input`
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;
  padding: 0 17px;
  height: 56px;
  outline: none;
  width: 100%;
  opacity: 1;

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:disabled {
    background-image: none;
    background-color: #fff;
    box-shadow: none;
    cursor: default;
    border-color: #ededed;
  }

  &.error {
    border: 1px solid #ba0302;
  }

  &.FieldNumber {
    width: 110px;
  }
  &.FieldNumberLg {
    width: 150px;
    @media (max-width: 599px) {
      width: 100%;
    }
  }

  &.mLeft {
    margin-left: 16px;
  }

  &.Sm {
    width: 70px;
  }

  &::placeholder {
    color: #929cb9;
  }
`;
export const FieldTextarea = styled.textarea`
  display: block;
  font-family: 'Poppins';
  background-color: #f7f7fa;
  border: 1px solid #d5d8de;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;
  padding: 20px 17px;
  height: 56px;
  min-height: 56px;
  outline: none;
  width: 100%;
  resize: vertical;

  &.FieldTextareaLg {
    height: 126px;
    min-height: 126px;
  }

  &::placeholder {
    color: #929cb9;
  }
  &.FieldTextareaRefunds {
    resize: none;
  }
  &.error {
    border: 1px solid #ba0302;
  }
`;
export const DialogLinkButton = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  color: #ff2d38;
  cursor: pointer;
  border-bottom: 1px solid #ff2d38;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;

  &:hover {
    border-bottom-color: transparent;
  }

  &.Sm {
    font-size: 14px;
    font-weight: 500;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
`;
export const DialogLinkInner = styled.div`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;
export const DialogFieldString = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.mTop10 {
    margin-top: 10px;
  }

  &.noWrap {
    flex-wrap: nowrap;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
    }
  }
`;
export const DialogFieldTitle = styled.div`
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1;
  color: #2f3d48;

  &.mRight {
    margin-right: 16px;
  }
`;
export const DialogFieldText = styled.div`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05em;
  color: #2f3d48;
  position: relative;
  &.mRight {
    margin-right: 16px;
  }

  &.pxy25px {
    padding: 25px 0;
  }

  &.fontBold {
    font-weight: 500;
  }

  &.flexAuto {
    flex: 1 1 auto;
    @media (max-width: 1000px) {
      flex: inherit;
    }
  }
  &.fontWeight400 {
    font-weight: 400;
  }
  &.ff {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
export const DialogFieldInners = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 5px -5px 0 -5px;

  &.DialogFieldInnersExternal {
    max-width: 320px;
    margin: -22px 0 0 20px;
    @media (max-width: 1000px) {
      max-width: 100%;
      flex: 0 0 58%;
      margin-right: -5px;
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
      margin: 0 -5px;
    }
  }

  &.DialogFieldInnersGroup {
    margin-top: 5px;
  }
`;
export const DialogFieldInner = styled.div`
  padding: 5px;

  &.w33 {
    flex: 0 0 auto;
    width: 33.3333333%;
  }

  &.w50 {
    flex: 0 0 auto;
    width: 50%;
  }
`;
export const UploadPhoto = styled.div`
  display: block;
`;
export const UploadPhotoInner = styled.div`
  display: flex;
  align-items: center;
`;
export const UploadPhotoImage = styled.img`
  display: block;
  width: 126px;
  height: 126px;
  object-fit: cover;
  object-position: center;
  flex: 0 0 auto;
`;
export const UploadFile = styled.div`
  display: block;
  width: 126px;
  height: 126px;
  object-fit: cover;
  object-position: center;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: normal;
  color: #232d3a;
  white-space: pre-line;

  i {
    font-size: 14px;
    font-weight: normal;
    color: #232d3a;
    word-break: break-all;
  }
`;
export const DialogFieldRemove = styled.div`
  display: block;
  position: absolute;
  bottom: 26px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${IconRemove});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
  &.relative {
    position: relative;
    bottom: 0;
  }
`;
export const DialogFieldCopy = styled.div`
  display: block;
  position: absolute;
  bottom: 26px;
  right: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${IconCopy});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
  &.relative {
    position: relative;
    bottom: 0;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
`;
export const UploadPhotoDelete = styled.div`
  display: block;
  margin-left: 9px;
  margin-right: 9px;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${IconRemove});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
export const UploadPhotoFile = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  min-height: 126px;
  background-color: #f7f7fa;
  border: 1px dashed #d5d8de;
  border-radius: 5px;
  padding: 18px;
  cursor: pointer;
  max-width: 323px;
  @media (max-width: 1300px) {
    padding: 14px;
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }

  &.width100 {
    width: 100%;
    max-width: 100%;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }

  &.error {
    border-color: #ba0302;
  }

  &:hover {
    span {
      border-bottom-color: transparent;
    }
  }
`;
export const UploadPhotoContainer = styled.div`
  display: block;
  padding-left: 62px;
  position: relative;
  @media (max-width: 1440px) {
    padding-left: 56px;
  }
  @media (max-width: 1300px) {
    padding-left: 48px;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 56px;
    height: 56px;
    background-image: url(${IconUploadFile});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: 1300px) {
      width: 43px;
      height: 43px;
    }
  }

  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #ff2d38;
    border-bottom: 1px solid #ff2d38;
    transition-property: border-bottom-color;
    transition-duration: 0.3s;
    @media (max-width: 1300px) {
      font-size: 13.5px;
    }
    @media (max-width: 1250px) {
      font-size: 14px;
    }
  }

  p {
    display: block;
    margin-top: 7px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 1.4;
    color: #929cb9;
  }
`;
export const DialogCategories = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 10px 10px 0;
  flex: 1 1 auto;
  position: relative;

  & > div {
    width: 100%;
  }

  .MuiFilledInput-root {
    padding: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;
export const DialogCategory = styled.div`
  display: inline-block;
  margin: 4px;
  font-style: normal;
  font-weight: normal;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 1;
  color: #36998b;
  background-color: rgba(54, 153, 139, 0.15);
  border-radius: 4px;
`;
export const DialogCheckboxes = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .MuiFormControlLabel-root {
    margin-right: 21px;
  }
`;
export const DialogCheckboxesItem = styled.div`
  flex: 0 0 auto;
  font-size: 0;
`;

export const DialogWrapper = styled.div`
  .DialogLg {
    background-color: #fff;
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
  }
`;

export const DialogHolder = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 599px) {
    display: block;
  }
`;

export const DialogLeftSidebar = styled.div`
  flex: 0 0 48%;
`;
export const DialogSearch = styled.div`
  position: relative;

  input {
    padding-right: 58px;
    background-color: #f7f7fa;
    border: 1px solid #d5d8de;
    border-radius: 6px;
  }
`;
export const DialogSearchField = styled(Field)`
  position: relative;

  input {
    padding-right: 58px;
    background-color: #f7f7fa !important;
    border: 1px solid #d5d8de !important;
  }
`;
export const DialogSearchRemove = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url(${IconClose});
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;
export const DialogList = styled.div`
  display: block;
  margin-top: 20px;
  max-height: 500px;
  overflow-y: auto;

  .MuiListItem-root {
    padding: 10px 58px 10px 20px;
    transition-property: background-color;
    transition-duration: 0.3s;

    @media (max-width: 767px) {
      padding: 10px 43px 10px 12px;
    }

    &::before {
      transition-property: opacity;
      transition-duration: 0.3s;
    }

    &:hover {
      background-color: #f7f7fa !important;
    }

    &.isActive {
      background-color: #f7f7fa !important;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: #ff2d38;
        @media (max-width: 767px) {
          width: 3px;
        }
      }
    }
  }

  .MuiListItemSecondaryAction-root {
    right: 21px;
    .MuiFormControlLabel-root {
      margin: 0;
    }
    @media (max-width: 767px) {
      right: 13px;
    }
  }

  .MuiListItem-button {
    &.isSelect {
      background-color: #f7f7fa !important;
    }
  }
`;
export const ListDescription = styled.div`
  display: inline-block;

  strong {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.2;
    color: #2f3d48;
  }

  p {
    display: block;
    margin-top: 1px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: #929cb9;
  }
`;
export const DialogRightSidebar = styled.div`
  flex: 0 0 52%;
  padding-left: 40px;
  @media (max-width: 1100px) {
    padding-left: 20px;
  }
  @media (max-width: 767px) {
    padding-left: 15px;
  }
  @media (max-width: 599px) {
    padding: 20px 0 0 0;
  }
`;
export const DialogItemName = styled.div`
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #232d3a;
  margin-bottom: 10px;
`;
export const DialogItemDescription = styled.div`
  display: block;
  margin-bottom: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: #172751;
`;
export const DialogItemStrings = styled.div`
  margin-top: 15px;
`;
export const DialogItemString = styled.div`
  display: flex;
  align-content: center;
  padding: 8px 0;
  //border-bottom: 1px solid #d5d8de;
  border: none;

  strong {
    flex: 0 0 90px;
    display: inline-block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #2f3d48;
  }

  p {
    display: block;
    flex: 1 1 auto;
    padding-left: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #2f3d48;
  }
`;
export const DialogItemBox = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const DialogItemTable = styled.div`
  margin-top: 10px;

  th.MuiTableCell-head {
    padding: 8px 8px 8px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #2f3d48;
    border: none;
  }

  td.MuiTableCell-root {
    padding: 8px 8px 8px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #2f3d48;
    border: none;
  }
`;

export const DashboardTitle = styled.div`
  display: block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  color: #2f3d48;
  &.WithLine {
    display: flex;
    align-items: center;
    white-space: nowrap;
    &::after {
      display: inline-block;
      width: 100%;
      height: 1px;
      flex: 1 1 auto;
      margin-left: 12px;
      opacity: 0.3;
      background-color: #929cb9;
      content: '';
    }
  }
  &.mBottom {
    margin-bottom: 10px;
  }
  &.titleMd {
    font-size: 16px;
    line-height: 24px;
  }
  &.title24 {
    font-size: 24px;
    @media (max-width: 1250px) {
      font-size: 20px;
    }
  }
  &.titleBold {
    font-weight: 700;
  }
  &.InsightsPerformanceTitle {
    margin-top: 35px;
  }
  &.mBottom20 {
    margin-bottom: 20px;
  }
  &.mBottom28 {
    margin-bottom: 28px;
  }
  &.InsightsGrowthTitle {
    margin: 30px 26px 18px 0;
  }
`;

export const DashboardHolder = styled.div`
  margin-bottom: 25px;
  &.w100 {
    width: 100%;
  }
  &.mBottom0 {
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.DashboardHolderWithBorder {
    border-bottom: 1px solid rgb(146, 156, 185, 0.3);
    padding-bottom: 35px;
  }
  &.LocationForm {
    padding-bottom: 25px;
    @media (max-width: 767px) {
      padding-bottom: 20px;
    }
  }
`;

export const DashboardHeadline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const DashboardFields = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;

  &:last-child {
    margin-bottom: 0;
  }
  &.alignItemsBottom {
    align-items: flex-end;
  }
  &.AlignCenter {
    align-items: center;
  }
  &.noWrap {
    flex-wrap: nowrap;
  }
  &.dColumn {
    flex-direction: column;
  }
  &.marginMinus {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  &.marginTop {
    margin-top: 20px;
  }
`;
export const DashboardDisableStore = styled.div`
  &.disabled {
    opacity: 0.4;
    .LocationForm {
      padding-bottom: 132px;
      @media (max-width: 767px) {
        padding-bottom: 160px;
      }
    }
  }
  &.disabled * {
    pointer-events: none;
  }
  &.hide {
    display: none;
  }
`;

export const DashboardStoreToggleSection = styled.div`
  display: block;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100%;
  z-index: 2;
  @media (max-width: 767px) {
    top: 105px;
  }
`;
export const DashboardFormWrapper = styled.div`
  position: relative;
`;
export const FieldInputDollar = styled.div`
  position: relative;
  &::before {
    position: absolute;
    top: 50%;
    left: 17px;
    content: '$';
    transform: translateY(-50%);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #2f3d48;
  }
  input {
    padding-left: 25px;
  }
`;
export const FieldInputPercent = styled.div`
  position: relative;
  &::after {
    position: absolute;
    top: 50%;
    right: 30px;
    content: '%';
    transform: translateY(-50%);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #2f3d48;
  }
  input {
    padding-left: 25px;
  }
  &.FieldInputSm {
    max-width: 97px;
    &::after {
      left: 42px;
      transform: translateY(-45%);
    }
    input {
      padding: 18px 6px 17px 16px;
    }
  }
`;
export const DashboardField = styled.div`
  padding: 13px 10px;
  position: relative;
  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.w30 {
    flex: 0 0 30%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }

  &.paddingL0 {
    padding-left: 0;
  }

  &.paddingR0 {
    padding-right: 0;
  }
  &.padding0 {
    padding: 0;
  }

  &.w33 {
    flex: 0 0 33.3333333%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }
  &.w25 {
    flex: 0 0 25%;
    @media (max-width: 767px) {
      flex: 0 0 50%;
    }
    @media (max-width: 599px) {
      flex: 0 0 100%;
    }
  }
  &.w75 {
    flex: 0 0 75%;
  }
  &.wAutoFix {
    flex: 0 0 auto;
  }
  &.wAuto {
    flex: 0 0 auto;
    @media (max-width: 599px) {
      flex: 0 0 100%;
    }

    &.labelFieldFix {
      width: 200px;
      padding-right: 10px;
      @media (max-width: 767px) {
        width: 170px;
      }
    }
  }
  &.w50mob {
    @media (max-width: 599px) {
      flex: 0 0 50%;
    }
  }
  &.wAutoMob {
    flex: 0 0 auto;
    @media (max-width: 599px) {
      flex: 0 0 100%;
    }
  }
  &.wAutoSpace {
    flex: 1 1 auto;
  }
  &.w66Minus {
    flex: 0 0 calc(66.6666667% - 110px);
    @media (max-width: 767px) {
      flex: 1 1 auto;
    }
  }
  &.w60Plus {
    flex: 0 0 auto;
    width: calc(60% + 200px);
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  &.w50Minus {
    flex: 0 0 calc(50% - 110px);
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }
  &.w30Plus {
    flex: 0 0 calc(30% + 100px);
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }
  &.w100 {
    flex: 0 0 100%;
  }
  &.w50 {
    flex: 0 0 50%;
    @media (max-width: 599px) {
      flex: 0 0 100%;
    }
  }
  &.FieldNumber {
    flex: 0 0 110px;
  }
  &.mTop {
    margin-top: 15px;
  }
  &.mTop10 {
    margin-top: 10px;
  }
  &.mTop20 {
    margin-top: 20px;
  }
  &.mTop30 {
    margin-top: 30px;
  }
  &.mTop50 {
    margin-top: 50px;
  }
  &.mTop60 {
    margin-top: 60px;
  }
  &.mBottom {
    margin-bottom: 30px;
  }
  &.dFlex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &.dFlexNoWrapAuto {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    @media (max-width: 1000px) {
      width: 100%;
      display: block;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  &.noWrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  &.noWrapDesktop {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    @media (max-width: 599px) {
      flex-wrap: wrap;
    }
  }
  &.justifySB {
    justify-content: space-between;
  }

  &.justifyCenter {
    justify-content: center;
  }

  &.gap10 {
    gap: 10px;
  }

  &.flexColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.AlignStart {
    align-items: flex-start;
  }
  &.AlignStartMob {
    @media (max-width: 599px) {
      align-items: flex-start;
    }
  }
  &.justifyCenter {
    display: flex;
    justify-content: center;
    .DashboardFieldButton {
      max-width: 310px;
      width: 100%;
      margin: 0 auto;
    }
    .ButtonBookADriver {
      max-width: 300px;
      width: 100%;
      margin: 30px auto 0 auto;
    }
  }
  &.disabled {
  }

  &.DashboardFieldActions {
    display: flex;
    justify-content: center;

    .DashboardFieldButton {
      max-width: 250px;
      width: 100%;
      margin: 0 20px;
    }
  }

  &.PickerWidth100 {
    & > * {
      width: 100%;
    }
    .MuiFormControl-root {
      width: 100%;
    }
    .Say2EatSelectTime {
      width: 100%;
      padding: 0;
    }
    &.BaDDatepicker {
      .MuiInputBase-input {
        width: 135px;
      }
    }
  }
  &.PickerWidth50 {
    & > * {
      width: 50%;
    }
    .MuiFormControl-root {
      width: 100%;
    }
    .Say2EatSelectTime {
      width: 100%;
      padding: 0;
    }
  }

  &.DashboardFieldAccordion {
    .AccordionItemName {
      font-size: 20px;
    }
    .MuiAccordionSummary-content {
      align-items: center;
    }
  }
  &.withRemove {
    position: relative;
    padding-right: 35px;
  }
  &.withClear {
    position: relative;
    input {
      padding-right: 35px;
    }
  }
  &.DiscountDatepicker {
    @media (max-width: 767px) {
      display: block;

      .MuiFormControlLabel-root {
        margin-bottom: 20px;
      }
    }
  }

  &.paddingTZero {
    padding-top: 0;
  }

  &.paddingTBZero {
    padding-top: 0;
    padding-bottom: 0;
  }
  .inputsWrapper {
    display: flex;
  }

  .inputAmount {
    width: 85px;
  }
  &.width140 {
    max-width: 140px;
  }
  &.width170 {
    max-width: 170px;
  }
  &.width220 {
    max-width: 220px;
  }
  &.width260 {
    max-width: 260px;
  }
  &.w80 {
    width: 80%;
  }
  &.FeatureFlagsAdvanceMode {
    margin: -30px -3px -20px 0;
    display: flex;
    justify-content: end;
  }
`;

export const DashboardFieldDatepicker = styled.div`
  .MuiPickersDateRangePickerInput-root {
    @media (max-width: 767px) {
      align-items: flex-start;
      .MuiInputBase-input {
        width: 100%;
      }
      .Delimiter.DelimiterMiddle {
        margin: 10px 20px !important;
      }
    }
  }
`;

export const DashboardFieldLabel = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 200px;
  &.marginTopMd {
    @media (max-width: 900px) {
      margin-top: 0;
    }
  }
  &.smallLabel {
    width: 120px;
  }
  &.widthAuto {
    width: auto;
    padding-right: 20px;
  }
  &.OrderFilterLabel {
    width: auto;
    padding-right: 20px;
    @media (max-width: 1000px) {
      display: inline-block;
      position: relative;
      top: 14px;
    }
    @media (max-width: 767px) {
      top: 0;
    }
  }
  @media (max-width: 767px) {
    flex: 0 0 100%;
    margin-bottom: 10px;
  }
  &.DashboardFieldLabelCenter {
    margin-top: 18px;
    @media (max-width: 950px) {
      margin-top: 0;
    }
  }
`;

export const DashboardStoreToggleWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  .ButtonInfoDrop {
    margin-top: 7px;
  }
`;

export const DashboardFieldInner = styled.div`
  display: block;

  &.FieldRelative {
    position: relative;

    .ErrorSignWrapper {
      top: 100%;
      left: 0;
    }
  }

  &.w60 {
    width: 100%;
    max-width: 822px;
    @media (max-width: 1600px) {
      flex: 1 1 auto;
      width: auto;
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
  &.w60FLex {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 822px;
    @media (max-width: 1600px) {
      flex: 1 1 auto;
      width: auto;
    }
  }
  &.flexAuto {
    flex: 1 1;
  }
  &.widthWithoutLabel {
    flex: 1 1 auto;
    width: auto;
    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
  &.w100Auto {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
    @media (max-width: 550px) {
      flex-wrap: wrap;
    }
    input {
      width: 100%;
      max-width: 822px;
      @media (max-width: 600px) {
        flex: 0 0 100%;
      }
    }
    .DashboardInnerDescription {
      flex: 1 1 auto;
      margin: 19px 0 0 20px;
      @media (max-width: 1350px) {
        margin-left: 18px;
        max-width: 160px;
      }
      @media (max-width: 550px) {
        margin: 10px 0 0 0;
        max-width: 100%;
      }
    }
  }
  &.w474 {
    width: 474px;
  }
  &.dFlex {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &.OrderFilterSelect {
    flex: 0 0 auto;
    width: 100%;
    min-width: 360px;
    max-width: 360px;
    @media (max-width: 1600px) {
      min-width: 300px;
      max-width: 300px;
    }
    @media (max-width: 1440px) {
      min-width: 240px;
      max-width: 240px;
    }
    @media (max-width: 1200px) {
      min-width: 215px;
      max-width: 215px;
    }
    @media (max-width: 1000px) {
      max-width: inherit;
      min-width: inherit;
      width: calc(100% - 53px);
      display: inline-block;
    }
    @media (max-width: 767px) {
      width: 100%;
      display: block;
    }
  }
`;
export const DashboardLinkButton = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;
  color: #17c981;
  cursor: pointer;
  border-bottom: 1px solid #17c981;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;

  &.Orange {
    color: #ff8412;
    border-bottom-color: #ff8412;
  }

  &.Sm {
    font-size: 14px;
    font-weight: 500;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    border-bottom-color: transparent;
  }
`;
export const DashboardSelectables = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -4px;

  &.mTop {
    margin-top: 20px;
  }

  &.w60 {
    width: calc(60% + 208px);
    @media (max-width: 1440px) {
      width: 100%;
    }
  }
`;
export const DashboardSelectable = styled.div`
  margin: 4px;
  border: 1px solid #929cb9;
  border-radius: 5px;
  padding: 25px 10px 18px;
  cursor: pointer;
  min-width: 110px;
  min-height: 110px;
  max-height: 110px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.w33 {
    width: calc(33.3333333% - 8px);
  }

  &::before {
    border: 3px solid #36998b;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    box-sizing: border-box;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  svg {
    path {
      transition-property: fill;
      transition-duration: 0.3s;
    }
  }

  span {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.1;
    text-align: center;
    color: #929cb9;
    margin-top: 10px;
    transition-property: color;
    transition-duration: 0.3s;
  }

  &.isActive {
    svg {
      path {
        fill: #36998b;
      }
    }

    span {
      color: #36998b;
    }

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    border-color: #36998b;
    span {
      color: #36998b;
    }
    svg {
      path {
        fill: #36998b;
      }
    }
  }
`;

export const DashboardSelectableIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;
export const DashboardDays = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -6px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const DashboardDay = styled.div`
  display: inline-block;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ff2d38;
  text-align: center;
  width: 54px;
  height: 54px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 51px;
  color: #2f3d48;
  cursor: pointer;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  pointer-events: all;
  @media (max-width: 700px) {
    margin: 6px;
  }
  @media (max-width: 500px) {
    margin: 3px;
    width: 48px;
    height: 48px;
    font-size: 13px;
    line-height: 46px;
  }

  &.isActive {
    background-color: #ff2d38;
    font-weight: bold;
    color: #fff;
  }

  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    transition: all 0.2s linear;
    background-color: #ff2d38;
    color: #fff;
    &:not(.isActive) {
      opacity: 0.6;
    }
  }
`;
export const DashboardTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -7px 10px -7px;
  padding-left: 20px;
  position: relative;

  &.marginB15 {
    margin-bottom: 15px;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }

  &.pLeft0 {
    padding-left: 0;
  }

  &.mLeft {
    margin: 0 -20px 10px -20px;
    padding-left: 0;
  }
`;
export const DashboardTimeInner = styled.div`
  display: inline-block;
  padding: 0 7px;
  flex: 0 0 auto;
  @media (max-width: 700px) {
    padding: 7px;
  }
  &.Say2EatSelectTime,
  &.Say2EatSelectTimeOrange {
    width: 160px;
  }
  &.Say2EatSelectTimeWidth100 {
    width: 100%;
  }
  &.Say2EatSelectTimeFormat {
    width: 100px;
  }
  &.DashboardTimeDelete {
    font-size: 0;
    @media (max-width: 500px) {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.pRight {
    padding-right: 30px;
    @media (max-width: 1023px) {
      padding-right: 0;
    }
  }
`;

export const DashboardTimeInnerMenu = styled.div`
  display: inline-block;
  padding: 0 7px;
  flex: 0 0 auto;
  @media (max-width: 700px) {
    padding: 7px;
  }
  &.Say2EatSelectTime,
  &.Say2EatSelectTimeOrange {
    width: 147px;
    .MuiFormControl-root {
      &.error {
        border: 1px solid #ba0302;
        input {
          color: #ba0302;
        }
      }
      .MuiInputBase-root {
        input {
          border-radius: 6px;
        }
      }
    }
  }
  &.Say2EatSelectTimeFormat {
    width: 147px;
  }
  &.DashboardTimeDelete {
    font-size: 0;
    @media (max-width: 500px) {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  &.pRight {
    padding-right: 30px;
    @media (max-width: 1023px) {
      padding-right: 0;
    }
  }
`;
export const DashboardTimeDelimiter = styled.div`
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;
  padding: 0 20px;
  @media (max-width: 1023px) {
    padding: 0 5px;
  }
  @media (max-width: 500px) {
    flex: 0 0 100%;
    text-align: center;
  }
`;
export const DashboardTimeDelete = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url(${IconRemove});
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;
export const DashboardText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
  color: #2f3d48;

  &.mTop {
    margin-top: 18px;
  }

  &.mLeft20 {
    margin-left: 20px;
  }

  &.mRight20 {
    margin-right: 20px;
  }

  &.mAround {
    margin: 12px 12px 12px 0;
  }

  &.mobMinRight {
    @media (max-width: 479px) {
      margin-right: 10px;
    }
    @media (max-width: 350px) {
      margin-right: 5px;
    }
  }

  &.mobMinLeft {
    @media (max-width: 479px) {
      margin-left: 10px;
    }
    @media (max-width: 350px) {
      margin-left: 5px;
    }
  }

  &.bold {
    font-weight: 500;
  }

  &.mobFixWidth {
    @media (max-width: 479px) {
      max-width: 125px;
    }
    @media (max-width: 390px) {
      max-width: 84px;
    }
    @media (max-width: 350px) {
      max-width: 83px;
    }
  }

  .asterisk {
    color: #ba0302;
  }
  strong {
    font-weight: 700;
  }
`;
export const DashboardInnerClose = styled.div`
  position: absolute;
  top: 21px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;

  &:hover {
    transform: rotate(90deg);
  }
`;
