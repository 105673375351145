import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FindDeliveryContainer} from 'routes/App/Managers/FindDelivery/styles';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import Headline from 'components/Headline/styles';
import FindDeliverySearch from 'routes/App/Managers/FindDelivery/components/FindDeliverySearch';
import FindDeliveryHeader from 'routes/App/Managers/FindDelivery/components/FindDeliveryHeader';
import FindDeliveryResult from 'routes/App/Managers/FindDelivery/components/FindDeliveryResults';
import {FIND_DELIVERY_CLEAR_ORDER} from 'store/findDelivery/action';

const FindDelivery = () => {
  const {orderData} = useSelector((state) => state.findDeliveryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(FIND_DELIVERY_CLEAR_ORDER());
    };
  }, []);

  return (
    <FindDeliveryContainer>
      <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
        <DashboardHeader>
          <Headline>Find Delivery</Headline>
        </DashboardHeader>
        <FindDeliverySearch />
        {orderData !== null && (
          <>
            <FindDeliveryHeader orderData={orderData} />
            <Headline className='FindDeliverySub'>Details</Headline>
            <FindDeliveryResult />
          </>
        )}
      </DashboardContent>
    </FindDeliveryContainer>
  );
};

export default FindDelivery;
