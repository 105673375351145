import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Visibility} from '@material-ui/icons';
import {useDispatch} from 'react-redux';
import {ButtonOutline} from '../../../../components/buttons';
import ConfirmModal from '../Modals/ConfirmModal';
import {BULK_UPDATE} from '../../../../store/menuEditor/action';

function ShowButton(props) {
  const dispatch = useDispatch();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const {
    disabled,
    activeTab,
    selectedCategories,
    selectedItems,
    selectedModifiers
  } = props;

  return (
    <>
      <ButtonOutline
        className='ButtonSmall Orange Hidden'
        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
      >
        <Visibility />
        Show
      </ButtonOutline>
      <ConfirmModal
        isOpen={isConfirmOpen}
        isLoading={isConfirmLoading}
        message='Are you sure you want to change the selected elements?'
        onOk={() => {
          const payload = {
            preCallback: () => setIsConfirmLoading(true),
            postCallback: () => {
              setIsConfirmLoading(false);
              setIsConfirmOpen(false);
            }
          };

          if (selectedCategories) {
            payload.categories = {
              ids: Object.keys(selectedCategories),
              data: {
                Visible: true
              }
            };
          }

          if (selectedItems) {
            payload.items = {
              ids: Object.keys(selectedItems),
              data: {
                Invisible: false
              }
            };
          }

          if (selectedModifiers) {
            payload.modifiers = {
              ids: Object.keys(selectedModifiers),
              data: {
                Invisible: false
              }
            };
          }

          dispatch(BULK_UPDATE(payload));
        }}
        onCancel={() => {
          setIsConfirmOpen(false);
        }}
      />
    </>
  );
}

ShowButton.propTypes = {
  disabled: PropTypes.bool,
  activeTab: PropTypes.string,
  selectedCategories: PropTypes.object,
  selectedItems: PropTypes.object,
  selectedModifiers: PropTypes.object
};

ShowButton.defaultProps = {
  disabled: false,
  activeTab: null,
  selectedCategories: null,
  selectedItems: null,
  selectedModifiers: null
};

export default ShowButton;
