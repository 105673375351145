import styled from 'styled-components';

export const MarketingIntegrationsDetailsTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 15px;
`;

export const MarketingIntegrationsDetailsSubTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const MarketingIntegrationsDetailsOl = styled.ol`
  counter-reset: item;
  padding-left: 0;
  margin-bottom: 21px;

  li {
    list-style-type: none;
    counter-increment: item;
    font-size: 14px;
    font-weight: 400;

    &::before {
      content: counter(item) '.';
      font-weight: bold;
      margin-right: 2px;
    }
    a {
      color: #ff2d38;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
    }
    &:first-child {
      &::before {
        margin-right: 5px;
      }
    }
  }
`;

export const MarketingIntegrationsDetailsFields = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 25px;
  button {
    padding: 19px 36px;
  }
`;

export const MarketingIntegrationsDetailsUl = styled.ul`
  margin-left: 15px;
  li {
    font-weight: 400;
    font-size: 14px;
    a {
      color: #ff2d38;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

export const MarketingIntegrationsDetailsImportantBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  a {
    color: #ff2d38;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const MarketingIntegrationsDetailsDisconnectBlock = styled.div`
  margin-top: 35px;
  border-top: 1px solid #f2f1f6;
  div {
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    margin: 31px 0 10px 0;
    color: #ff2d38;
  }
`;
