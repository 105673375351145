import React, {useState, useEffect} from 'react';
import T from 'prop-types';
import {useDispatch} from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import {getWarningNote} from '../../helpers/localStorage/MenuEditorNote';
import ButtonMore from '../buttons/ButtonMore/styles';
import {
  CREATE_ITEM_MODAL,
  DELETE_MODAL,
  HIDDEN_MODAL,
  EDIT_CATEGORY_MODAL,
  EDIT_ITEM_MODAL,
  EDIT_MODIFIER_GROUP_MODAL,
  EDIT_MODIFIER_MODAL,
  EDIT_NOTE_MODAL,
  SOLD_OUT_MODAL
} from '../../store/menuEditorModals/action';
import {
  DELETE_CATEGORY,
  PATCH_CATEGORY
} from '../../store/menuEditorCategory/action';
import {DELETE_MODIFIER_GROUP} from '../../store/menuEditorModifierGroups/action';
import {DELETE_ITEM, PATCH_ITEM} from '../../store/menuEditorItems/action';
import {
  DELETE_MODIFIER,
  PATCH_MODIFIER
} from '../../store/menuEditorModifier/action';

// eslint-disable-next-line react/prop-types
const PopperСomponent = ({entityId, entity, name, sourceParent}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [placement, setPlacement] = useState();
  const [visible, setVisible] = useState(
    name === 'category' ? entity.Visible : entity.Invisible
  );

  const handleClickOpenMenu = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(newPlacement === openMenu ? '' : newPlacement);
    setPlacement('bottom-end');
  };
  // dispatch(EDIT_MODIFIER_GROUP_MODAL({open: true}));
  const clickAwayHandler = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    if (name === 'category') {
      setVisible(entity.Visible);
    }

    if (name === 'item' || name === 'modifier') {
      setVisible(!entity.Invisible);
    }
  }, [entity]);

  const parseActions = (entityName) => {
    if (entityName === 'category') {
      return {
        edit: EDIT_CATEGORY_MODAL,
        delete: DELETE_CATEGORY,
        patch: PATCH_CATEGORY
      };
    }

    if (entityName === 'modifier') {
      return {
        edit: EDIT_MODIFIER_MODAL,
        delete: DELETE_MODIFIER,
        patch: PATCH_MODIFIER,
        soldOut: SOLD_OUT_MODAL
      };
    }

    if (entityName === 'item') {
      return {
        edit: EDIT_ITEM_MODAL,
        delete: DELETE_ITEM,
        duplicate: CREATE_ITEM_MODAL,
        patch: PATCH_ITEM,
        soldOut: SOLD_OUT_MODAL
      };
    }

    return {
      edit: EDIT_MODIFIER_GROUP_MODAL,
      delete: DELETE_MODIFIER_GROUP
    };
  };

  return (
    <>
      <ButtonMore
        className='ButtonMore'
        onClick={handleClickOpenMenu(entityId)}
      />

      <Popper
        open={openMenu === entityId || openMenu === true}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal
        className='MainDropDown'
        transition
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className='popper'>
                <MenuItem
                  onClick={() =>
                    getWarningNote('showEditNote') === false
                      ? dispatch(
                          parseActions(name).edit({
                            open: true,
                            entityId,
                            oldEntityData: entity,
                            entityDuplicateInfo: {
                              ...entity
                            }
                          })
                        )
                      : dispatch(
                          EDIT_NOTE_MODAL({
                            open: true,
                            name,
                            entityId,
                            oldEntityData: entity,
                            entityDuplicateInfo: {
                              ...entity
                            }
                          })
                        )
                  }
                >
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() =>
                    getWarningNote('showDeleteNote') === false
                      ? dispatch(parseActions(name).delete(entityId))
                      : dispatch(
                          DELETE_MODAL({
                            open: true,
                            name,
                            entityId
                          })
                        )
                  }
                >
                  Delete
                </MenuItem>

                {name === 'item' && (
                  <MenuItem
                    onClick={() =>
                      dispatch(
                        CREATE_ITEM_MODAL({
                          open: true,
                          entityId,
                          entityDuplicateInfo: {
                            ...entity
                          }
                        })
                      )
                    }
                  >
                    Duplicate
                  </MenuItem>
                )}

                {name === 'modifier' && (
                  <MenuItem
                    className='SoldOut'
                    onClick={() =>
                      dispatch(
                        parseActions(name).soldOut({
                          open: true,
                          name,
                          entityId,
                          oldEntityData: entity,
                          entityDuplicateInfo: {
                            ...entity
                          }
                        })
                      )
                    }
                  >
                    86 Out of Stock
                  </MenuItem>
                )}

                {name !== 'modifierGroup' && (
                  <MenuItem
                    onClick={() => {
                      if (
                        getWarningNote('showHiddenNote') === false &&
                        name === 'category'
                      ) {
                        dispatch(
                          parseActions(name).patch({
                            oldEntityData: entity,
                            form: {id: entityId, Visible: !visible}
                          })
                        );
                      } else if (
                        (getWarningNote('showHiddenNote') === false &&
                          name === 'item') ||
                        (getWarningNote('showHiddenNote') === false &&
                          name === 'modifier')
                      ) {
                        dispatch(
                          parseActions(name).patch({
                            oldEntityData: entity,
                            form: {id: entityId, Invisible: visible}
                          })
                        );
                      } else {
                        dispatch(
                          HIDDEN_MODAL({
                            open: true,
                            name,
                            entityId,
                            oldEntityData: entity,
                            hiddenModalStatus: visible ? 'hide' : 'visible'
                          })
                        );
                      }
                    }}
                  >
                    {visible ? 'Hide' : 'Visible'}
                  </MenuItem>
                )}

                {name === 'item' && (
                  <MenuItem
                    className='SoldOut'
                    onClick={() =>
                      dispatch(
                        parseActions(name).soldOut({
                          open: true,
                          name,
                          entityId,
                          oldEntityData: entity,
                          entityDuplicateInfo: {
                            ...entity
                          }
                        })
                      )
                    }
                  >
                    86 Out of Stock
                  </MenuItem>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

PopperСomponent.propTypes = {
  entity: T.object.isRequired,
  name: T.string.isRequired,
  delete_entity_modal: T.func,
  edit_entity_modal: T.func
};

PopperСomponent.defaultProps = {
  delete_entity_modal: () => {},
  edit_entity_modal: () => {}
};

export default PopperСomponent;
