import styled from 'styled-components';

export const InsightsPerformanceSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  .DatePickerTitle {
    margin-right: 14px;
    font-size: 14px;
    white-space: nowrap;
  }
  @media (max-width: 750px) {
    flex-direction: column;
    align-items: baseline;
    .DatePickerTitle {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

export const InsightsPerformanceDatePicker = styled.div`
  display: block;

  width: calc(50% - 10px);

  @media (max-width: 1650px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 1340px) {
    width: calc(60% - 10px);
  }
  @media (max-width: 1100px) {
    width: calc(60% - 10px);
  }
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const InsightsPerformanceCardsContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: 10px -13px -13px -13px;

  @media (max-width: 535px) {
    margin: 10px 0 0 0;
  }
`;

export const InsightsPerformanceCard = styled.div`
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 13px;
  padding: 16px 20px 0 23px;
  width: calc(33.333333% - 26px);
  flex: 0 0 auto;
  justify-content: space-between;
  flex-direction: row;
  position: relative;

  @media (max-width: 1085px) {
    width: calc(33.333333% - 26px);
  }
  @media (max-width: 820px) {
    width: calc(50% - 26px);
  }
  @media (max-width: 535px) {
    width: 100%;
    margin: 0 0 13px 0;
  }
  &:first-child {
    .DropInfoUpInsightsPerformance {
      @media (max-width: 1250px) {
        right: inherit;
        left: -36px;

        &::after {
          right: inherit;
          left: 14px;
        }
      }
    }
  }
  &:nth-child(4n + 5) {
    .DropInfoUpInsightsPerformance {
      @media (max-width: 1250px) {
        right: inherit;
        left: -36px;

        &::after {
          right: inherit;
          left: 14px;
        }
      }
      @media (max-width: 1085px) {
        right: -18px;
        left: initial;

        &::after {
          right: 29px;
          left: initial;
        }
      }
    }
  }
  &:nth-child(3n + 4) {
    .DropInfoUpInsightsPerformance {
      @media (max-width: 1085px) {
        right: inherit;
        left: -36px;

        &::after {
          right: inherit;
          left: 14px;
        }
      }
      @media (max-width: 820px) {
        right: -18px;
        left: initial;

        &::after {
          right: 29px;
          left: initial;
        }
      }
    }
  }
  &:nth-child(odd) {
    .DropInfoUpInsightsPerformance {
      @media (max-width: 820px) {
        right: inherit;
        left: -36px;

        &::after {
          right: inherit;
          left: 14px;
        }
      }
      @media (max-width: 535px) {
        right: -18px;
        left: initial;

        &::after {
          right: 29px;
          left: initial;
        }
      }
    }
  }
`;

export const InsightsPerformanceCardInfo = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

export const InsightsPerformanceCardNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 45px;
  .cardIcon {
    width: 20px;
    height: 20px;
    margin-right: 18px;

    &.isBigIcon {
      width: 38px;
      height: 38px;
    }
  }
  @media (max-width: 535px) {
    min-height: inherit;
  }
`;

export const InsightsPerformanceCardName = styled.div`
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
`;

export const InsightsPerformanceCardValue = styled.div`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 42px;
  font-weight: 275;
  line-height: 63px;
  text-align: left;
`;

export const InsightsPerformanceCardButton = styled.div`
  // padding: 7px 0 6px 16px;
  height: 34px;
  div {
    // width: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
  span {
    color: #ff2d38;
    cursor: pointer;
  }
`;
