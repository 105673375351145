import {toast} from 'react-toastify';
import store from '../../../store';
import axiosAPI from './interseptors';
import {getAppIp, getAppType, getAppVersion} from '../../app.helper';

/**
 * Request function
 * @param method {'post' | 'get' | 'put' | 'patch' | 'delete'}
 * @param url {string}
 * @param data {object=}
 * @param config {object=}
 * @return {import('axios').AxiosResponse<any>}
 */
export const request = async (method, url, data, config) => {
  try {
    const axiosInstance = axiosAPI;

    axiosInstance.defaults.headers.common[
      'X-App-Type'
    ] = `${getAppType()} ${getAppVersion()}`;
    axiosInstance.defaults.headers.common['X-App-Ip'] = `${getAppIp()}`;

    return await axiosInstance({
      method,
      url,
      ...data,
      headers: config?.headers,
      responseType: config?.responseType
    });
  } catch (error) {
    const {response} = error;
    const {
      globalNotificationsReducer: {isNetworkOnline}
    } = store.getState();

    if (error.message === 'Cancel Offline') {
      return undefined;
    }

    if (isNetworkOnline) {
      if (!config?.silent) {
        if (response) {
          if (response.data.errors && response.data.errors.length) {
            response.data.errors.forEach(({message}) => toast.error(message));
          }

          if (response.data.message) {
            toast.error(response.data.message);
          }
        } else {
          toast.error(`Error occurred - please try again [${error}]`);
        }
      }

      throw error;
    }

    return false;
  }
};

export const testAutomationService = async (method, url, data, config) => {
  const newConf = config || {};

  return request(method, url, data, {
    ...newConf,
    headers: {
      Authorization:
        'Basic cHJvZGV2QGdldHNhdWNlLmNvbTpFN2pLUURVczdhamRYd2tzMElzOGY0WWpUU0ZqWlF2Sw=='
    }
  });
};
