import React from 'react';
import T from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingWrapper from './LoadingStyles';

const Loading = (props) => {
  const {margin, justifyContent, className} = props;
  return (
    <LoadingWrapper
      className={className}
      margin={margin}
      justifyContent={justifyContent}
    >
      <CircularProgress className='CircularProgress' />
    </LoadingWrapper>
  );
};

Loading.defaultProps = {
  margin: '',
  justifyContent: '',
  className: ''
};

Loading.propTypes = {
  margin: T.string,
  className: T.string,
  justifyContent: T.string
};
export default Loading;
