import * as Yup from 'yup';

const courierSelectSchema = (fault, refundType, typeString) => {
  if (fault === 'Courier' && refundType === typeString) {
    return Yup.string().required('Mandatory field');
  }
  return Yup.string();
};

const CCSelectSchema = (tier1Refund, refundType, typeString) => {
  if (tier1Refund && refundType === typeString) {
    return Yup.string().required('Mandatory field');
  }
  return Yup.string();
};

const RefundSchema = Yup.object().shape({
  refundType: Yup.string().required(),
  faultFull: Yup.string().required('Select a responsible party'),
  faultPartial: Yup.string().required('Select a responsible party'),
  reasonFull: Yup.string().when('refundType', (refundType) => {
    if (refundType === 'Full') {
      return Yup.string().required('Select a reason');
    }
    return Yup.string();
  }),
  reasonPartial: Yup.string().when('refundType', (refundType) => {
    if (refundType === 'Partial') {
      return Yup.string().required('Select a reason');
    }
    return Yup.string();
  }),
  otherReasonTextFull: Yup.string().when(
    ['reasonFull', 'refundType'],
    (reasonFull, refundType) => {
      if (reasonFull === 'Other' && refundType === 'Full') {
        return Yup.string().required('Specify the reason for refund');
      }
      return Yup.string();
    }
  ),
  otherReasonTextPartial: Yup.string().when(
    ['reasonPartial', 'refundType'],
    (reasonPartial, refundType) => {
      if (reasonPartial === 'Other' && refundType === 'Partial') {
        return Yup.string().required('Specify the reason for refund');
      }
      return Yup.string();
    }
  ),
  didReceiveOrderFull: Yup.string().when(
    ['faultFull', 'refundType'],
    (faultFull, refundType) =>
      courierSelectSchema(faultFull, refundType, 'Full')
  ),
  didReceiveOrderPartial: Yup.string().when(
    ['faultPartial', 'refundType'],
    (faultPartial, refundType) =>
      courierSelectSchema(faultPartial, refundType, 'Partial')
  ),
  didRemakeOrderFull: Yup.string().when(
    ['faultFull', 'refundType'],
    (faultFull, refundType) =>
      courierSelectSchema(faultFull, refundType, 'Full')
  ),
  didRemakeOrderPartial: Yup.string().when(
    ['faultPartial', 'refundType'],
    (faultPartial, refundType) =>
      courierSelectSchema(faultPartial, refundType, 'Partial')
  ),
  requestedByFull: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) => CCSelectSchema(tier1Refund, refundType, 'Full')
  ),
  requestedByPartial: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) =>
      CCSelectSchema(tier1Refund, refundType, 'Partial')
  ),
  requestToFull: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) => CCSelectSchema(tier1Refund, refundType, 'Full')
  ),
  requestToPartial: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) =>
      CCSelectSchema(tier1Refund, refundType, 'Partial')
  ),
  freeTextCCFull: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) => CCSelectSchema(tier1Refund, refundType, 'Full')
  ),
  freeTextCCPartial: Yup.string().when(
    ['tier1Refund', 'refundType'],
    (tier1Refund, refundType) =>
      CCSelectSchema(tier1Refund, refundType, 'Partial')
  )
});

export default RefundSchema;
