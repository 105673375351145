import React from 'react';

export const IconDollarBig = () => (
  <svg
    width='13'
    height='24'
    viewBox='0 0 13 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13 16.147C13 17.0654 12.7762 17.9288 12.3285 18.7371C11.8988 19.5453 11.2541 20.225 10.3946 20.7761C9.55303 21.3272 8.55027 21.6579 7.38636 21.7681V24H5.6405V21.7681C3.9573 21.6028 2.59642 21.0609 1.55785 20.1424C0.519284 19.2239 0 18.0115 0 16.5052H3.27686C3.33058 17.2216 3.55441 17.8186 3.94835 18.2962C4.34229 18.7555 4.90634 19.0494 5.6405 19.178V13.116C4.44077 12.8037 3.46488 12.4914 2.71281 12.1791C1.96074 11.8485 1.31612 11.3341 0.778926 10.6361C0.259642 9.938 0 8.98278 0 7.77038C0 6.24569 0.519284 4.98737 1.55785 3.99541C2.59642 2.98507 3.9573 2.39725 5.6405 2.23192V0H7.38636V2.23192C8.96212 2.37888 10.2245 2.89323 11.1736 3.77497C12.1405 4.63835 12.6866 5.83238 12.812 7.35706H9.53512C9.48141 6.7876 9.26653 6.28243 8.8905 5.84156C8.51446 5.38232 8.01308 5.07003 7.38636 4.90471V10.8565C8.58609 11.1504 9.56198 11.4627 10.314 11.7933C11.0661 12.1056 11.7018 12.6108 12.2211 13.3088C12.7404 13.9885 13 14.9346 13 16.147ZM3.1157 7.60505C3.1157 8.35821 3.33058 8.94604 3.76033 9.36854C4.20799 9.79104 4.83471 10.1309 5.6405 10.3881V4.82204C4.87052 4.91389 4.25276 5.19862 3.78719 5.67624C3.33953 6.15385 3.1157 6.79678 3.1157 7.60505ZM7.38636 19.178C8.17424 19.0494 8.79201 18.7279 9.23967 18.2135C9.68733 17.6808 9.91116 17.0471 9.91116 16.3123C9.91116 15.5775 9.68733 15.008 9.23967 14.6039C8.80992 14.1814 8.19215 13.8416 7.38636 13.5844V19.178Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconDollarBig;
