import {GET, POST} from 'helpers/services/constants/API_CONSTANTS';
import {request} from 'helpers/services/utils/request';
import {
  API_DISABLE_MARKETING_INTEGRATION,
  API_ENABLE_MARKETING_INTEGRATION,
  API_GET_MARKETING_INTEGRATIONS_INFO
} from 'helpers/services/constants/API_ENDPOINTS';

export const getMarketingIntegrationsInfo = (locationId) => {
  return request(GET, API_GET_MARKETING_INTEGRATIONS_INFO(locationId));
};

export const enableMarketingIntegration = (locationId, integration, query) => {
  return request(
    POST,
    API_ENABLE_MARKETING_INTEGRATION(locationId, integration, query)
  );
};

export const disableMarketingIntegration = (locationId, integration) => {
  return request(
    POST,
    API_DISABLE_MARKETING_INTEGRATION(locationId, integration)
  );
};
