import {createReducer} from 'redux-act';
import {endOfWeek, startOfWeek} from 'date-fns';
import {
  CLEAR_ORDERS,
  GET_LOCATION_ID_DATA_SUCCESS,
  GET_ORDERS,
  SET_NEXT_PAGE,
  ORDERS_UNMOUNT,
  SEND_TEST_ORDER,
  SEND_TEST_ORDER_SUCCESS,
  SET_FILTER,
  GET_ORDER_SUCCESS,
  SET_SORTING,
  CLEAR_FILTERS,
  SET_ORDERS,
  SET_ORDERS_EMPTY,
  GET_LOCATION_ID_DATA,
  OPEN_STATUS_CHANGE_MODAL,
  CHANGE_STATUS_HISTORY_SUCCESS,
  CHANGE_STATUS_HISTORY,
  GET_CLOUD_PRINT_CONFIGURATIONS,
  GET_CLOUD_PRINT_CONFIGURATIONS_SUCCESS,
  CLEAR_CLOUD_PRINT_CONFIGURATIONS,
  CHANGE_REFUNDS_HISTORY,
  OPEN_CANCELLATION_CONFIRMATION_MODAL
} from './action';
import {
  ORDER,
  ORDER_CHANNEL,
  ORDER_STATUS,
  ORDER_TYPE,
  ORDER_MISC,
  ORDER_PAYMENT_METHOD
} from '../../../helpers/constants/orders';
import {calculateOverdueAndFuture} from '../../../helpers/functionUtils/ordersUtils';

const initialFilters = () => ({
  startDate: '',
  endDate: '',
  datePickerSelectIndex: 2,
  orderChannels: [ORDER_CHANNEL.ALL],
  orderTypes: [ORDER_TYPE.ALL],
  orderMiscMany: [ORDER_MISC.ALL],
  orderPaymentsMethods: [ORDER_PAYMENT_METHOD.ALL],
  orderStatus: [ORDER_STATUS.NEW, ORDER_STATUS.ACCEPTED, ORDER_STATUS.OVERDUE]
});

const initialSorting = () => ({
  sort: 'asc',
  sortBy: 'eta_time_for_merchant'
});

const initialPaging = () => ({
  page: 0,
  limit: 15,
  total: 0
});

const initialState = {
  orders: [],
  loading: false,
  filter: initialFilters(),
  sorting: initialSorting(),
  ...initialPaging(),
  hasNextPage: false,
  locationData: {
    OrderNotifications: {}
  },
  cloudPrintConfigurations: null,
  cloudPrintLoading: false,
  modalLoading: false,
  testOrderLoading: false,
  printRecord: {},
  isStatusChangeModalOpen: false,
  changeStatusLoading: null,
  isOpenCancellationModal: false
};

const ordersHistoryReducer = createReducer(
  {
    [CHANGE_STATUS_HISTORY]: (state, payload) => {
      return {
        ...state,
        changeStatusLoading: payload.id
      };
    },
    [CHANGE_STATUS_HISTORY_SUCCESS]: (state, payload) => {
      const cloneOrders = [...state.orders];
      const index = cloneOrders.findIndex(
        (order) => order[ORDER.ID] === payload[ORDER.ID]
      );

      if (index !== -1) {
        if (payload[ORDER.ORDER_STATUS]) {
          cloneOrders[index][ORDER.ORDER_STATUS] = payload[ORDER.ORDER_STATUS];
        } else {
          cloneOrders.splice(index, 1);
        }
      }

      return {
        ...state,
        orders: cloneOrders,
        changeStatusLoading: null
      };
    },
    [CHANGE_REFUNDS_HISTORY]: (state, payload) => {
      const cloneOrders = [...state.orders];
      const index = cloneOrders.findIndex(
        (order) => order[ORDER.ID] === payload[ORDER.ID]
      );

      if (index !== -1) {
        cloneOrders[index][ORDER.REFUND] = payload[ORDER.REFUND];
      }
      return {
        ...state,
        orders: cloneOrders,
        changeStatusLoading: null
      };
    },
    [GET_ORDERS]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [SEND_TEST_ORDER]: (state) => {
      return {
        ...state,
        testOrderLoading: true
      };
    },
    [SEND_TEST_ORDER_SUCCESS]: (state) => {
      return {
        ...state,
        testOrderLoading: false
      };
    },
    [ORDERS_UNMOUNT]: (state) => ({
      ...state,
      ...initialState
    }),
    [CLEAR_ORDERS]: (state) => ({
      ...state,
      orders: [],
      page: 0
    }),
    [SET_NEXT_PAGE]: (state, payload) => {
      const {docs} = payload;

      return {
        ...state,
        total: state.total + docs.length,
        hasNextPage: payload.hasNextPage,
        page: payload.page + 1
      };
    },
    [GET_ORDER_SUCCESS]: (state, payload) => {
      return {
        ...state,
        printRecord: payload
      };
    },
    [SET_ORDERS]: (state, payload) => {
      const orders = payload?.data.map((order) =>
        calculateOverdueAndFuture({Timezone: payload?.Timezone, ...order})
      );
      return {
        ...state,
        orders,
        loading: false
      };
    },
    [SET_ORDERS_EMPTY]: (state) => {
      return {
        ...state,
        orders: [],
        loading: false
      };
    },
    [GET_LOCATION_ID_DATA]: (state) => {
      return {
        ...state,
        modalLoading: true
      };
    },
    [GET_LOCATION_ID_DATA_SUCCESS]: (state, payload) => {
      return {
        ...state,
        locationData: payload,
        modalLoading: false
      };
    },
    [SET_FILTER]: (state, payload) => {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload
        }
      };
    },
    [SET_SORTING]: (state, payload) => {
      return {
        ...state,
        sorting: {
          ...state.sorting,
          ...payload
        }
      };
    },
    [CLEAR_FILTERS]: (state) => {
      return {
        ...state,
        filter: initialFilters(),
        sorting: initialSorting(),
        ...initialPaging()
      };
    },
    [OPEN_STATUS_CHANGE_MODAL]: (state, payload) => ({
      ...state,
      isStatusChangeModalOpen: payload
    }),
    [GET_CLOUD_PRINT_CONFIGURATIONS]: (state) => ({
      ...state,
      cloudPrintLoading: true
    }),
    [GET_CLOUD_PRINT_CONFIGURATIONS_SUCCESS]: (state, payload) => ({
      ...state,
      cloudPrintConfigurations: payload,
      cloudPrintLoading: false
    }),
    [CLEAR_CLOUD_PRINT_CONFIGURATIONS]: (state) => ({
      ...state,
      cloudPrintLoading: false,
      cloudPrintConfigurations: null
    }),
    [OPEN_CANCELLATION_CONFIRMATION_MODAL]: (state, payload) => ({
      ...state,
      isOpenCancellationModal: payload
    })
  },
  initialState
);

export default ordersHistoryReducer;
