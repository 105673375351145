import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import {useDispatch} from 'react-redux';
import T from 'prop-types';
import cn from 'classnames';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {DialogBody, DialogText} from 'routes/App/dashboard/styles';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {OPEN_CANCELLATION_CONFIRMATION_MODAL} from 'store/global/modals/action';
import {
  CHANGE_STATUS_ACTIVE,
  RUN_GET_ACTIVE_ORDERS_JOB,
  STOP_GET_ACTIVE_ORDERS_JOB
} from 'store/global/orders/action';
import {CHANGE_STATUS_HISTORY} from 'store/orders/orderList/action';
import {CHANGE_STATUS} from 'store/orders/orderDetails/action';
import {
  NEW_DISPATCH_PROVIDER,
  OLD_DISPATCH_PROVIDER
} from 'helpers/constants/orders';
import AnimatePoints from 'components/AnimatePoints';

const OrderCancellationConfirmationModal = ({
  openModal,
  cancellationInfo,
  cancellationSource,
  cancellationDeliveryProvider,
  loadingCancellation
}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(
      OPEN_CANCELLATION_CONFIRMATION_MODAL({
        isOpen: false,
        info: null,
        source: null,
        deliveryProvider: null
      })
    );
  };

  const confirmCancellation = () => {
    if (cancellationSource === 'active') {
      dispatch(CHANGE_STATUS_ACTIVE(cancellationInfo));
      dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
      dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
    } else if (cancellationSource === 'history') {
      dispatch(CHANGE_STATUS_HISTORY(cancellationInfo));
      dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
      dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
    } else if (cancellationSource === 'details') {
      dispatch(CHANGE_STATUS(cancellationInfo));
    }
  };

  return (
    <Dialog open={openModal}>
      <DialogContainer className='DialogMd'>
        <DialogTitle className='DialogTitleMarginBottom verticalCenter'>
          Are you sure you want to cancel the order?
        </DialogTitle>

        <DialogBody>
          {(cancellationDeliveryProvider === OLD_DISPATCH_PROVIDER ||
            cancellationDeliveryProvider === NEW_DISPATCH_PROVIDER) && (
            <DialogText className='smText'>
              The order delivery will also be canceled. Additional fees may be
              charged after the courier has been assigned.
            </DialogText>
          )}
          <DialogActions>
            <DialogAction className='DialogActions w50'>
              <ButtonOutline
                className={cn('', {disabled: loadingCancellation})}
                onClick={onClose}
              >
                <span>No, go back</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='DialogActions w50'>
              <ButtonPrimary
                className={cn('', {disabled: loadingCancellation})}
                onClick={confirmCancellation}
              >
                {loadingCancellation ? (
                  <div className='CancellationRefundButton'>
                    Cancelling{' '}
                    <AnimatePoints className='White CancellationRefund' />
                  </div>
                ) : (
                  <span>Yes, cancel</span>
                )}
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

OrderCancellationConfirmationModal.propTypes = {
  openModal: T.bool.isRequired,
  cancellationInfo: T.object.isRequired,
  cancellationSource: T.string.isRequired,
  cancellationDeliveryProvider: T.string.isRequired,
  loadingCancellation: T.bool.isRequired
};

export default OrderCancellationConfirmationModal;
