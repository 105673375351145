/* eslint-disable */
import memoize from 'fast-memoize';
import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import CustomToast from '../../components/shared/Utils/CustomToast';
import {
  deleteModifierGroupApi,
  getModifierGroupByIdApi,
  getModifiersApi,
  postModifierGroupApi,
  updateModifierGroupApi,
  getModifierGroupTree
} from '../../helpers/services/api/menuEditor';
import {
  DELETE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP_SUCCESS,
  GET_MODIFIER_GROUP_BY_ID,
  GET_MODIFIER_GROUP_BY_ID_SUCCESS,
  GET_MODIFIER_GROUP_MODAL,
  GET_MODIFIER_GROUP_MODAL_SUCCESS,
  GET_MODIFIERS,
  GET_MODIFIERS_SUCCESS,
  POST_MODIFIER_GROUP,
  POST_MODIFIER_GROUP_SUCCESS,
  UPDATE_MODIFIER_GROUP,
  // UPDATE_MODIFIER_GROUP_SUCCESS,
  FETCH_SINGLE_MODIFIER_GROUP_TREE,
  FETCH_SINGLE_MODIFIER_GROUP_SUCCESS
} from './action';
import {
  ADD_NEW_GROUP,
  SET_UPDATED_GROUP,
  CLEAR_GROUP
} from '../menuEditor/action';

const toJsState = (state) => {
  return state.toJS();
};

const entityId = ({menuEditorModalsReducer}) =>
  menuEditorModalsReducer.entityId;
const getModifiersState = ({menuEditorModifierGroupsReducer}) =>
  menuEditorModifierGroupsReducer.modifiersPagination;

export const getState = memoize(toJsState);

// const getToken = ({auth}) => auth.token;

export function* getModifiers({payload}) {
  try {
    const itemsParams = yield select(getModifiersState);
    const pagination = {
      ...itemsParams,
      ...payload
    };

    const {data} = yield getModifiersApi(pagination);

    if (data) {
      yield put(GET_MODIFIERS_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* fetchSingleModifierGroupTree({payload}) {
  try {
    const {data} = yield getModifierGroupTree(payload);

    if (data) {
      yield put(FETCH_SINGLE_MODIFIER_GROUP_SUCCESS(data));
    }
  } catch (error) {
    console.log('Error [fetchSingleModifierGroupTree]: ', error);
  }
}

export function* getModifierGroupById({payload}) {
  try {
    const {data} = yield getModifierGroupByIdApi(payload);
    if (data) {
      yield put(GET_MODIFIER_GROUP_BY_ID_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* getModifiersModal({payload}) {
  try {
    const itemsParams = yield select(getModifiersState);
    const pagination = {
      ...itemsParams,
      ...payload
    };

    const {data} = yield getModifiersApi(pagination);

    if (data) {
      yield put(GET_MODIFIER_GROUP_MODAL_SUCCESS(data));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* postNewModifierGroup({payload}) {
  try {
    const {data} = yield postModifierGroupApi(payload);

    if (data) {
      yield put(ADD_NEW_GROUP({id: data.PosId, data}));
      toast.success('Modifier Group has been created');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}
export function* updateModifierGroup({payload: {form}}) {
  try {
    const id = yield select(entityId);
    const {data} = yield updateModifierGroupApi(id, form);

    if (data || data === '') {
      yield put(SET_UPDATED_GROUP({id, data: form}));
      toast.success('Modifier Group has been updated');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

export function* deleteModifierGroup({payload}) {
  try {
    const id = yield select(entityId);
    const {data} = yield deleteModifierGroupApi(id || payload);

    if (data || data === '') {
      yield put(CLEAR_GROUP(id || payload));
      toast.success('Modifier Group has been delete');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* menuEditorModifierGroupsSaga() {
  yield takeEvery(GET_MODIFIERS, getModifiers);
  yield takeEvery(GET_MODIFIER_GROUP_MODAL, getModifiersModal);
  yield takeEvery(POST_MODIFIER_GROUP, postNewModifierGroup);
  yield takeEvery(UPDATE_MODIFIER_GROUP, updateModifierGroup);
  yield takeEvery(DELETE_MODIFIER_GROUP, deleteModifierGroup);
  yield takeEvery(GET_MODIFIER_GROUP_BY_ID, getModifierGroupById);
  yield takeEvery(
    FETCH_SINGLE_MODIFIER_GROUP_TREE,
    fetchSingleModifierGroupTree
  );
  //   yield takeEvery(GET_ITEM_MODAL, getItemsModal);
}

export default menuEditorModifierGroupsSaga;
