import * as Yup from 'yup';

export const ModifierGroupSchema = Yup.object().shape({
  OptionName: Yup.string()
    .max(750, 'Modifier group name is too long')
    .required('Modifier group name field is required!')
});

export const validate = (values, selectedModifiers) => {
  const errors = {};
  // Name validation
  if (values.OptionName.length > 50) {
    errors.OptionName = 'Modifier group name is too long';
  }
  if (values.OptionName.length < 3) {
    errors.OptionName = 'Modifier group is too short';
  }
  if (!values.OptionName.trim().length) {
    errors.OptionName = 'Modifier group name field is required!';
  }

  // Min amount validations
  if (values.MinSelection > selectedModifiers.length) {
    errors.MinSelection =
      'This field cannot be more than amount of selected modifiers!';
  }

  if (values.isRequiredItems && values.MinSelection < 1) {
    errors.MinSelection = 'This field cannot be less than 1';
  }

  if (values.isRequiredItems && values.MinSelection === '') {
    errors.MinSelection = 'This field cannot be empty';
  }
  if (
    values.isRequiredItems &&
    !values.isRequiredUnlimited &&
    values.MinSelection > values.MaxSelection
  ) {
    errors.MinSelection = 'This field cannot be more than MaxSelection';
  }

  // Max amount validations
  if (values.MaxSelection > selectedModifiers.length) {
    errors.MaxSelection =
      'This field cannot be more than amount of selected modifiers!';
  }

  if (values.isRequiredUnlimited === false && values.MaxSelection < 1) {
    errors.MaxSelection = 'This field cannot be less than 1';
  }

  if (
    !values.isRequiredUnlimited &&
    values.MinSelection > values.MaxSelection
  ) {
    errors.MaxSelection = 'This field cannot be less than MinSelection';
  }

  return errors;
};
