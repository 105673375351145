import React from 'react';
import PropTypes from 'prop-types';
import StatusChip from './StatusChip';
import IconStatusSign from '../../../../../assets/IconStatusSign';
import StatusPopover from './StatusPopover';
import {ORDER, ORDER_STATUS} from '../../../../../helpers/constants/orders';
import {checkIsFutureOrder} from '../../../../../helpers/functionUtils/ordersUtils';

const getGtmVar = (state) => {
  return 'orderStatus'.concat(state[0].toUpperCase() + state.slice(1));
};

const GetStatusChip = ({text, state, show, onClick}) =>
  show && (
    <li>
      <div onClick={onClick} data-gtm={getGtmVar(state)}>
        <StatusChip state={state} data-gtm={getGtmVar(state)}>
          <IconStatusSign data-gtm={getGtmVar(state)} />
          <span data-gtm={getGtmVar(state)}>{text}</span>
        </StatusChip>
      </div>
    </li>
  );

GetStatusChip.propTypes = {
  text: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function StatusButton({order, onStatusChange}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <StatusChip
        state={
          order[ORDER.ORDER_STATUS] && order[ORDER.ORDER_STATUS].toLowerCase()
        }
        onClick={handleClick}
      >
        <IconStatusSign />
        <span>{order[ORDER.ORDER_STATUS] || 'New'}</span>
      </StatusChip>
      <StatusPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        onItemClick={(data) => {
          handleClose();
          onStatusChange(data);
        }}
        order={order}
      >
        <GetStatusChip
          text='New'
          state='new'
          show={
            order[ORDER.ORDER_STATUS] !== ORDER_STATUS.NEW &&
            order[ORDER.ORDER_STATUS] !== ORDER_STATUS.FUTURE &&
            !checkIsFutureOrder(order)
          }
          onClick={() => {
            handleClose();
            onStatusChange(ORDER_STATUS.NEW);
          }}
        />
        <GetStatusChip
          text='Accepted'
          state='accepted'
          show={order[ORDER.ORDER_STATUS] !== ORDER_STATUS.ACCEPTED}
          onClick={() => {
            handleClose();
            onStatusChange(ORDER_STATUS.ACCEPTED);
          }}
        />
        <GetStatusChip
          text='Completed'
          state='completed'
          show={
            order[ORDER.ORDER_STATUS] !== ORDER_STATUS.COMPLETED
            // order[ORDER.ORDER_STATUS] !== ORDER_STATUS.FUTURE &&
            // !checkIsFutureOrder(order)
          }
          onClick={() => {
            handleClose();
            onStatusChange(ORDER_STATUS.COMPLETED);
          }}
        />
        <GetStatusChip
          text='Cancelled'
          state='canceled'
          show={
            order[ORDER.ORDER_STATUS] !== ORDER_STATUS.CANCELLED
            //  order[ORDER.ORDER_STATUS] !== ORDER_STATUS.FUTURE &&
            //  checkIsFutureOrder(order)
          }
          onClick={() => {
            handleClose();
            onStatusChange(ORDER_STATUS.CANCELLED);
          }}
        />
      </StatusPopover>
    </div>
  );
}

StatusButton.propTypes = {
  order: PropTypes.object,
  onStatusChange: PropTypes.func
};

StatusButton.defaultProps = {
  order: {},
  onStatusChange: () => {}
};
