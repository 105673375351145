import {createAction} from 'redux-act';

export const GET_BAD_HISTORY_ORDERS = createAction(
  'bookADriveHistoryList/GET_BAD_HISTORY_ORDERS'
);
export const GET_BAD_HISTORY_ORDERS_SUCCESS = createAction(
  'bookADriveHistoryList/GET_BAD_HISTORY_ORDERS_SUCCESS'
);
export const GET_BAD_HISTORY_ORDERS_COUNT = createAction(
  'bookADriveHistoryList/GET_BAD_HISTORY_ORDERS_COUNT'
);
export const SET_BAD_HISTORY_TABLE_CONFIG = createAction(
  'bookADriveHistoryList/SET_BAD_HISTORY_TABLE_CONFIG'
);
export const SET_BAD_HISTORY_NEXT_PAGE = createAction(
  'bookADriveHistoryList/SET_BAD_HISTORY_NEXT_PAGE'
);
export const CLEAR_BAD_HISTORY_ORDERS = createAction(
  'bookADriveHistoryList/CLEAR_BAD_HISTORY_ORDERS'
);
