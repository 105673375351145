import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import {OrderAlert, TierRestaurant} from './styles';
import {
  IconActualLate,
  IconBookADriver,
  IconCanceledByDelivery,
  IconCanceledByRest,
  IconCanceledBySauce,
  IconCatering,
  IconFutureOrderAlert,
  IconLargeOrder,
  IconLongDeliveryDistance,
  IconNewRest,
  IconPotentialLate,
  IconRestAboutToClose,
  IconRestClosed,
  IconTips,
  IconUnassignedFewTimes,
  IconXLOrder
} from '../../../../../assets/orderAlertsIcons';
import roundTheNumber from '../../../../../helpers/functionUtils/roundTheNumber';
import {
  BOARD,
  BOARD_CANCEL_SOURCE,
  BOARD_REQUEST_SOURCE,
  BOARD_STATUS
} from '../../../../../helpers/constants/adminRealTImeBoard';
import {formatToLocalTime} from '../helpers';

const getMinutesDiffUTC = (date) =>
  moment(date).utc(true).diff(moment().utc(), 'minutes');

const tiersAvgOrdersText = {
  0: '0',
  1: '10 or more',
  2: '3 - 10',
  3: '1 - 3',
  4: '0 - 1'
};

const currLocTimeFromWeekStart = (timezone) => {
  const startWeek = moment().utc().startOf('week');
  const currTimeFromWeekStart = moment().utc().diff(startWeek, 'minutes');

  return currTimeFromWeekStart + Number(timezone) * 60;
};

const pickupTimeFromWeekStart = (pickupTime, timezone) => {
  const startWeek = moment(pickupTime).startOf('week').format();
  const currTimeFromWeekStart = moment(pickupTime).diff(startWeek, 'minutes');
  return currTimeFromWeekStart + Number(timezone) * 60;
};

const isManuallyClosedOnDate = (order, date) => {
  const dateInLocale = formatToLocalTime(date, order[BOARD.TIMEZONE]);

  if (!order[BOARD.CLOSED_MANUALLY_UNTIL_TIME]) {
    return false;
  }
  return (
    moment(dateInLocale)
      .utc(true)
      .diff(moment(order[BOARD.CLOSED_MANUALLY_UNTIL_TIME]).utc(), 'seconds') <
    0
  );
};

export const TierRestaurantComponent = (data) => {
  const tierValue = Number(data[BOARD.LOCATION_TIER]);
  if (tierValue >= 0) {
    return (
      <OrderAlert>
        <TierRestaurant>T{tierValue}</TierRestaurant>
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Tier {tierValue} restaurant with {tiersAvgOrdersText[tierValue]} daily
          orders on avg.
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const PotentialLateComponent = (data) => {
  if (
    getMinutesDiffUTC(data[BOARD.PICKUP_DEADLINE_TIME]) > 0 &&
    getMinutesDiffUTC(data[BOARD.PICKUP_DEADLINE_TIME]) < 15 &&
    data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED
  ) {
    return (
      <OrderAlert>
        <IconPotentialLate />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Potential late - unassigned order with less than 15 min for pickup
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const ActualLateComponent = (data) => {
  if (
    getMinutesDiffUTC(data[BOARD.PICKUP_DEADLINE_TIME]) <= 0 &&
    data[BOARD.STATUS] === BOARD_STATUS.SCHEDULED
  ) {
    return (
      <OrderAlert>
        <IconActualLate />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Actual late - late for pickup
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const LargeOrderComponent = (data) => {
  if (!data[BOARD.IS_CATERING] && data[BOARD.ORDER_AMOUNT] / 100 >= 100) {
    return (
      <OrderAlert>
        {data[BOARD.ORDER_AMOUNT] / 100 >= 200 ? (
          <IconXLOrder />
        ) : (
          <IconLargeOrder />
        )}
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Large order - ${data[BOARD.ORDER_AMOUNT] / 100}
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const TipsComponent = (data) => {
  if (data[BOARD.TIP]) {
    return (
      <OrderAlert>
        <IconTips amount={Math.trunc(data[BOARD.TIP] / 100)} />
        <div className='OrderAlertInfo'>
          ${roundTheNumber(data[BOARD.TIP] / 100)} tip
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const RestAboutToCloseComponent = (data) => {
  if (
    data[BOARD.TIMEZONE] &&
    data[BOARD.OPENING_HOURS] &&
    data[BOARD.STATUS] !== BOARD_STATUS.DELIVERED &&
    data[BOARD.STATUS] !== BOARD_STATUS.CANCELLED
  ) {
    const currentTimeLoc = currLocTimeFromWeekStart(data[BOARD.TIMEZONE]);

    const openTimeArr = data[BOARD.OPENING_HOURS].map((obj) =>
      Number(obj.Start)
    );

    // Need to filter the closing time if it is equal to the opening time,
    // and if it is equal to the end of the week time (24*60*7) and
    // there is an open time from the beginning of the week (0)
    const closeTimeArr = data[BOARD.OPENING_HOURS]
      .map((obj) => Number(obj.Start) + Number(obj.Duration))
      .filter((item) =>
        item === 24 * 60 * 7
          ? !openTimeArr.includes(0)
          : !openTimeArr.includes(item)
      );

    const aboutToClose = closeTimeArr.find(
      (item) => item - currentTimeLoc > 0 && item - currentTimeLoc < 30
    );

    if (aboutToClose !== undefined) {
      return (
        <OrderAlert>
          <IconRestAboutToClose />
          <div className='OrderAlertInfo OrderAlertInfoLg'>
            An active order for a restaurant that will be closed in{' '}
            {aboutToClose - currentTimeLoc} min
          </div>
        </OrderAlert>
      );
    }
  }
  return null;
};

export const RestClosedComponent = (data) => {
  if (
    data[BOARD.TIMEZONE] &&
    data[BOARD.OPENING_HOURS] &&
    data[BOARD.STATUS] !== BOARD_STATUS.DELIVERED &&
    data[BOARD.STATUS] !== BOARD_STATUS.CANCELLED
  ) {
    const currentOrderTime = pickupTimeFromWeekStart(
      data[BOARD.PICKUP_DEADLINE_TIME],
      data[BOARD.TIMEZONE]
    );
    const locIsOpenNow = data[BOARD.OPENING_HOURS].some((range) => {
      return (
        range.Start <= currentOrderTime &&
        currentOrderTime < Number(range.Start) + Number(range.Duration)
      );
    });

    if (
      !locIsOpenNow ||
      isManuallyClosedOnDate(data, data[BOARD.PICKUP_DEADLINE_TIME])
    ) {
      return (
        <OrderAlert>
          <IconRestClosed />
          <div className='OrderAlertInfo OrderAlertInfoLg'>
            An order for a closed restaurant
          </div>
        </OrderAlert>
      );
    }
  }
  return null;
};

export const CanceledBySauceComponent = (data) => {
  if (
    data[BOARD.STATUS] === BOARD_STATUS.CANCELLED &&
    data[BOARD.CANCEL_SOURCE] &&
    data[BOARD.CANCEL_SOURCE] === BOARD_CANCEL_SOURCE.D_DASHBOARD
  ) {
    return (
      <OrderAlert>
        <IconCanceledBySauce />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Order was canceled by Sauce
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CanceledByRestComponent = (data) => {
  if (
    data[BOARD.STATUS] === BOARD_STATUS.CANCELLED &&
    data[BOARD.CANCEL_SOURCE] &&
    data[BOARD.CANCEL_SOURCE] === BOARD_CANCEL_SOURCE.TRACKING_PAGE
  ) {
    return (
      <OrderAlert>
        <IconCanceledByRest />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Order was canceled by the restaurant
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CanceledByDeliveryComponent = (data) => {
  if (
    data[BOARD.STATUS] === BOARD_STATUS.CANCELLED &&
    data[BOARD.CANCEL_SOURCE] &&
    data[BOARD.CANCEL_SOURCE] === BOARD_CANCEL_SOURCE.DELIVERY_SERVICE
  ) {
    return (
      <OrderAlert>
        <IconCanceledByDelivery />
        <div className='OrderAlertInfo OrderAlertInfoLg wordWrap'>
          Order was canceled by the delivery company{' '}
          {data[BOARD.STATUS_REASON] ? `- ${data[BOARD.STATUS_REASON]}` : ''}
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const CateringComponent = (data) => {
  if (data[BOARD.IS_CATERING]) {
    return (
      <OrderAlert>
        <IconCatering />
        <div className='OrderAlertInfo'>Catering order</div>
      </OrderAlert>
    );
  }
  return null;
};

export const NewRestComponent = (data) => {
  if (!data[BOARD.FIRST_TEN_ORDERS]) {
    return (
      <OrderAlert>
        <IconNewRest />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          A new restaurant with less than 10 orders
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const LongDeliveryDistanceComponent = (data) => {
  if (data[BOARD.DRIVING_DISTANCE]) {
    const distanceInMiles = Number(data[BOARD.DRIVING_DISTANCE]) / 1000 / 1.609;

    if (distanceInMiles >= 10) {
      return (
        <OrderAlert>
          <IconLongDeliveryDistance />
          <div className='OrderAlertInfo OrderAlertInfoMd'>
            A long delivery of {roundTheNumber(distanceInMiles)} miles
          </div>
        </OrderAlert>
      );
    }
  }

  return null;
};

export const UnassignedFewTimesComponent = (data) => {
  if (data[BOARD.DRIVER_CHANGE_COUNT] && data[BOARD.DRIVER_CHANGE_COUNT] >= 5) {
    return (
      <OrderAlert>
        <IconUnassignedFewTimes />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Unassigned a few times - order was unassigned{' '}
          {data[BOARD.DRIVER_CHANGE_COUNT]} times
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const IconBookADriverComponent = (data) => {
  if (
    data[BOARD.REQUEST_SOURCE] &&
    data[BOARD.REQUEST_SOURCE] === BOARD_REQUEST_SOURCE.BOOKADRIVER
  ) {
    return (
      <OrderAlert>
        <IconBookADriver />
        <div className='OrderAlertInfo OrderAlertInfoMd'>
          Book a Driver order
        </div>
      </OrderAlert>
    );
  }
  return null;
};

export const FutureOrderAlertComponent = (data) => {
  if (data[BOARD.IS_FUTURE_ORDER]) {
    return (
      <OrderAlert>
        <IconFutureOrderAlert />
        <div className='OrderAlertInfo OrderAlertInfoLg'>
          Future order{' '}
          {moment(data[BOARD.PICKUP_TIME])
            .utc(true)
            .utcOffset(Number(data[BOARD.TIMEZONE]))
            .format('MMMM D, h:mm A')}
        </div>
      </OrderAlert>
    );
  }
  return null;
};
