import {createAction} from 'redux-act';

export const RUN_BAD_ACTIVE_ORDERS_JOB = createAction(
  'bookADriveList/RUN_BAD_ACTIVE_ORDERS_JOB'
);
export const STOP_BAD_ACTIVE_ORDERS_JOB = createAction(
  'bookADriveList/STOP_BAD_ACTIVE_ORDERS_JOB'
);
export const GET_BAD_ACTIVE_ORDERS = createAction(
  'bookADriveList/GET_BAD_ACTIVE_ORDERS'
);
export const GET_BAD_ACTIVE_ORDERS_SUCCESS = createAction(
  'bookADriveList/GET_BAD_ACTIVE_ORDERS_SUCCESS'
);

export const GET_BAD_ACTIVE_ORDERS_COUNT = createAction(
  'bookADriveList/GET_BAD_ACTIVE_ORDERS_COUNT'
);

export const SET_BAD_TABLE_CONFIG = createAction(
  'bookADriveList/SET_BAD_TABLE_CONFIG'
);
