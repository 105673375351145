import React, {useState} from 'react';
import T from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import {ButtonMore} from '../buttons';
import RestrictedArea from '../layout/RestrictedArea';

const PopperActions = ({row, popperActions}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  return (
    <div className='PopperAction'>
      <ButtonMore className='ButtonMore' onClick={handleClickOpenMenu} />
      <Popper
        open={openMenu}
        anchorEl={anchorEl}
        placement='bottom-end'
        disablePortal
        className='MainDropDown'
        transition
      >
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className='popper'>
                {popperActions().map((action) => (
                  <RestrictedArea restrictions={[action.restriction]}>
                    <MenuItem onClick={() => action.action(row)}>
                      {action.title}
                    </MenuItem>
                  </RestrictedArea>
                ))}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

PopperActions.propTypes = {
  row: T.object.isRequired,
  popperActions: T.array.isRequired
};

export default PopperActions;
