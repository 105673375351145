import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {
  Header,
  HeaderBurger,
  HeaderLogo,
  HeaderLside,
  HeaderRside
} from '../../../../components/layout/nav/styles';
import SauceLogoWhite from '../../../../assets/logo-sauce-white.svg';
import Field from '../../../../components/Field/styles';
import {SearchPanel} from './SearchPanel/styles';

const Navbar = ({showSidebar, setShowSidebar}) => {
  const location = useLocation();
  return (
    <Header className='Header DesktopTransparent'>
      <HeaderLside>
        <HeaderBurger
          className={showSidebar ? 'isOpen' : ''}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <span />
        </HeaderBurger>
        <HeaderLogo>
          <img src={SauceLogoWhite} alt='logo' />
        </HeaderLogo>
      </HeaderLside>
      {/* <HeaderRside>
                {location.pathname === '/managers/real-time-board' && <SearchPanel>
                    <Field className='SearchPanelInput'>
                        <input
                            className='FieldSearch DarkSearchIcon'
                            type='search'
                            placeholder='Search'
                            value=''
                        />
                    </Field>
                </SearchPanel>}
            </HeaderRside> */}
    </Header>
  );
};

Navbar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired
};

export default Navbar;
