/*eslint-disable*/
import MarkerImg from '../../../../assets/location-marker.svg';

let marker; // global object for marker

const selectCity = (cityArr) => {
  return cityArr.filter((city) => city)[0] || '';
};

export const initLocationAfterMapPick = async (
  data,
  setFieldValue,
  map,
  mapMarker,
  getCallingCode = () => {},
  setCountryState = () => {},
  countryList = [],
  inputValue,
  structuredAddress,
  isAdminPage = null
) => {
  const googleMapPlaceConfigs = {
    address: 'route',
    city: 'locality',
    subCity: 'sublocality',
    country: 'country',
    zip: 'postal_code',
    state: 'administrative_area_level_1',
    streetNumber: 'street_number',
    postalTown: 'postal_town',
    administrative_area_level_3: 'administrative_area_level_3',
    neighborhood: 'neighborhood'
  };
  const {address_components, formatted_address, url, place_id} = data;

  if (!address_components) return null;

  const getStreetNumber = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.streetNumber)
        )
        .pop()?.long_name || ''
    );
  };
  const getStreet = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.address)
        )
        .pop()?.long_name || ''
    );
  };
  const getCity = (address_components) => {
    return (
      address_components
        .filter((geoData) => geoData.types.includes(googleMapPlaceConfigs.city))
        .pop()?.long_name || ''
    );
  };
  const getSubCity = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.subCity)
        )
        .pop()?.long_name || ''
    );
  };
  const getNeighborhood = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.neighborhood)
        )
        .pop()?.long_name || ''
    );
  };
  const getAdministrativeAreaLevel3 = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(
            googleMapPlaceConfigs.administrative_area_level_3
          )
        )
        .pop()?.short_name || ''
    );
  };
  const getPostalTown = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.postalTown)
        )
        .pop()?.long_name || ''
    );
  };
  const getCountry = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.country)
        )
        .pop()?.long_name || ''
    );
  };
  const getCountryShortName = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.country)
        )
        .pop()?.short_name || ''
    );
  };
  const getCountryCode = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.country)
        )
        .pop()?.short_name || ''
    );
  };
  const getState = (address_components) => {
    return (
      address_components
        .filter((geoData) =>
          geoData.types.includes(googleMapPlaceConfigs.state)
        )
        .pop()?.short_name || ''
    );
  };
  const getZip = (address_components) => {
    return (
      address_components
        .filter((geoData) => geoData.types.includes(googleMapPlaceConfigs.zip))
        .pop()?.long_name || ''
    );
  };

  // const address =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.address)
  //     )
  //     .pop()?.long_name || '';
  // const streetNumber =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.streetNumber)
  //     )
  //     .pop()?.long_name || '';
  //
  // const city =
  //   address_components
  //     .filter((geoData) => geoData.types.includes(googleMapPlaceConfigs.city))
  //     .pop()?.long_name || '';
  // const subCity =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.subCity)
  //     )
  //     .pop()?.long_name || '';
  // const neighborhood =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.neighborhood)
  //     )
  //     .pop()?.long_name || '';
  // const administrative_area_level_3 =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(
  //         googleMapPlaceConfigs.administrative_area_level_3
  //       )
  //     )
  //     .pop()?.short_name || '';
  // const postalTown =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.postalTown)
  //     )
  //     .pop()?.long_name || '';
  // const country =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.country)
  //     )
  //     .pop()?.long_name || '';
  // const countryCode =
  //   address_components
  //     .filter((geoData) =>
  //       geoData.types.includes(googleMapPlaceConfigs.country)
  //     )
  //     .pop()?.short_name || '';
  // const state =
  //   address_components
  //     .filter((geoData) => geoData.types.includes(googleMapPlaceConfigs.state))
  //     .pop()?.short_name || '';
  // const zip =
  //   address_components
  //     .filter((geoData) => geoData.types.includes(googleMapPlaceConfigs.zip))
  //     .pop()?.long_name || '';

  setFieldValue(
    isAdminPage ? 'merchant.Locations[0].City' : 'City',
    selectCity([
      getCity(address_components).length && getCity(address_components),
      getSubCity(address_components).length && getSubCity(address_components),
      getNeighborhood(address_components).length &&
        getNeighborhood(address_components),
      getAdministrativeAreaLevel3(address_components).length &&
        getAdministrativeAreaLevel3(address_components),
      getPostalTown(address_components).length &&
        getPostalTown(address_components)
    ])
  );

  if (getCountryCode(address_components)) {
    const country = countryList.find(
      (a) => a.code === getCountryCode(address_components)
    ) || {
      name: '',
      code: '',
      callingCode: ''
    };
    setCountryState(country);
    // setFieldValue('CountryCode', `+${country.callingCode}`);
    // getCallingCode(countryCode);
  }

  let location = {
    lat: data.geometry.location.lat(),
    lng: data.geometry.location.lng()
  };

  let latlng = new google.maps.LatLng(location.lat, location.lng);

  // if (initMarker) {
  //   marker = initMarker;
  // } else if (marker) {
  //   marker.setPosition(location);
  // } else {
  //   marker = new google.maps.Marker({
  //     position: location,
  //     map: map,
  //     icon: MarkerImg
  //   });
  // }

  let addressObj = {
    City: selectCity([
      getCity(address_components).length && getCity(address_components),
      getSubCity(address_components).length && getSubCity(address_components),
      getNeighborhood(address_components).length &&
        getNeighborhood(address_components),
      getAdministrativeAreaLevel3(address_components).length &&
        getAdministrativeAreaLevel3(address_components),
      getPostalTown(address_components).length &&
        getPostalTown(address_components)
    ]),
    StreetNumber: getStreetNumber(address_components),
    Street: getStreet(address_components),
    State: getState(address_components),
    Country: getCountry(address_components),
    CountryShort: getCountryShortName(address_components),
    ZipCode: getZip(address_components),
    PlaceId: place_id,
    FullAddress: formatted_address,
    GoogleMapURL: url,
    Location: location,
    Latitude: location.lat,
    Longitude: location.lng
  };

  let newAddressObj = {
    ...structuredAddress,
    CountryCode: getCountryShortName(address_components),
    StateCode: getState(address_components),
    City: selectCity([
      getCity(address_components).length && getCity(address_components),
      getSubCity(address_components).length && getSubCity(address_components),
      getNeighborhood(address_components).length &&
        getNeighborhood(address_components),
      getAdministrativeAreaLevel3(address_components).length &&
        getAdministrativeAreaLevel3(address_components),
      getPostalTown(address_components).length &&
        getPostalTown(address_components)
    ]),
    StreetName: getStreet(address_components),
    StreetNumber: getStreetNumber(address_components),
    ZipCode: getZip(address_components),
    Latitude: location.lat,
    Longitude: location.lng,
    FormattedAddress: formatted_address
  };

  if (!getStreetNumber(address_components)) {
    const geocoder = new google.maps.Geocoder();
    await geocoder.geocode({address: inputValue}, (results, status) => {
      if (results.length) {
        const geocoderAddress = results[0]?.address_components;
        const geocoderLocation = {
          lat: results[0]?.geometry.location.lat(),
          lng: results[0]?.geometry.location.lng()
        };
        location.lat = results[0]?.geometry.location.lat();
        location.lng = results[0]?.geometry.location.lng();

        latlng = new google.maps.LatLng(location.lat, location.lng);
        addressObj.City = selectCity([
          getCity(geocoderAddress).length && getCity(geocoderAddress),
          getSubCity(geocoderAddress).length && getSubCity(geocoderAddress),
          getNeighborhood(geocoderAddress).length &&
            getNeighborhood(geocoderAddress),
          getAdministrativeAreaLevel3(geocoderAddress).length &&
            getAdministrativeAreaLevel3(geocoderAddress),
          getPostalTown(geocoderAddress).length &&
            getPostalTown(geocoderAddress)
        ]);
        newAddressObj.City = selectCity([
          getCity(geocoderAddress).length && getCity(geocoderAddress),
          getSubCity(geocoderAddress).length && getSubCity(geocoderAddress),
          getNeighborhood(geocoderAddress).length &&
            getNeighborhood(geocoderAddress),
          getAdministrativeAreaLevel3(geocoderAddress).length &&
            getAdministrativeAreaLevel3(geocoderAddress),
          getPostalTown(geocoderAddress).length &&
            getPostalTown(geocoderAddress)
        ]);
        addressObj.StreetNumber = getStreetNumber(geocoderAddress);
        newAddressObj.StreetNumber = getStreetNumber(geocoderAddress);
        addressObj.Street = getStreet(geocoderAddress);
        newAddressObj.StreetName = getStreet(geocoderAddress);
        addressObj.State = getState(geocoderAddress);
        newAddressObj.StateCode = getState(geocoderAddress);
        addressObj.Country = getCountry(geocoderAddress);
        newAddressObj.CountryCode = getCountry(geocoderAddress);
        addressObj.ZipCode = getZip(geocoderAddress);
        newAddressObj.ZipCode = getZip(geocoderAddress);
        addressObj.PlaceId = results[0]?.place_id;
        addressObj.FullAddress = results[0]?.formatted_address;
        newAddressObj.FormattedAddress = results[0]?.formatted_address;
        addressObj.Address = results[0]?.formatted_address;
        addressObj.GoogleMapURL = '';
        addressObj.Location = location;
        addressObj.Latitude = location.lat;
        newAddressObj.Latitude = location.lat;
        addressObj.Longitude = location.lng;
        newAddressObj.Longitude = location.lng;
      }
    });
  } else {
    addressObj.Address = formatted_address;
  }

  for (let key in addressObj) {
    await setFieldValue(
      isAdminPage ? `merchant.Locations[0].${key}` : key,
      addressObj[key]
    );
  }

  await setFieldValue(
    isAdminPage
      ? 'merchant.Locations[0].StructuredAddress'
      : 'StructuredAddress',
    newAddressObj
  );

  if (map) {
    // map.setZoom(15);
    map.setCenter(latlng);
  }

  if (mapMarker) {
    mapMarker.setPosition(location);
  }
};

export const checkIfStreetNumberWithHyphen = (placeId, streetNumber) => {
  return (
    placeId?.length > 0 &&
    typeof streetNumber === 'string' &&
    streetNumber?.indexOf('-') !== -1
  );
};
