import React, {useState} from 'react';
import cn from 'classnames';
import {Form, Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {
  Logos,
  LogosItem,
  SignUpBottomText,
  SignUpFeedback,
  SignUpFeedbackBottom,
  SignUpFeedbackButton,
  SignUpFeedbackCompany,
  SignUpFeedbackDescription,
  SignUpFeedbackInfo,
  SignUpFeedbackLogo,
  SignUpFeedbackPhoto,
  SignUpFeedbackTop,
  SignUpForm,
  SignUpLogo,
  SignUpSection,
  SignUpTitle
} from '../registration/style';
import {
  ErrorSignWrapper,
  ErrorSign
} from '../../../components/errorSign/errorSign';
import PhotoFeedback from '../../../assets/signUp1.jpg';
import IconFeedback from '../../../assets/logo-slice-factory.png';
import SauceLogo from '../../../assets/logo-sauce.svg';
import DialogField from '../../../components/DialogField/styles';
import {FieldLabel} from '../../../components/FieldLabel/styles';
import FieldInput from '../../../components/FieldInput';
import {ButtonPrimary} from '../../../components/buttons';
import DialogFields from '../../../components/DialogFields/styles';
import FieldPassword from '../../../components/FieldPassword';
import LogoBagkok from '../../../assets/logo-bangkok-happy-bowl.png';
import LogoCarlos from '../../../assets/logo-carlos-okellys.png';
import LogoCarabao from '../../../assets/logo-carabao-express.png';
import LogoHolbox from '../../../assets/logo-holbox.png';
import LogoPizza from '../../../assets/logo-uncle-maddios-pizza.jpg';
import {ResetPasswordSchema} from '../../../helpers/services/formValidationSchemas/AuthSchema';
import {UPDATE_MEMBER_PASSWORD} from '../../../store/global/auth/action';

const ActivateMember = ({match}) => {
  const dispatch = useDispatch();
  const initValues = {
    Password: '',
    PasswordConfirm: ''
  };

  const {email, token} = match.params;

  const [upFeedback, setUpFeedback] = useState('false');

  const handleToggle = () => {
    setUpFeedback(!upFeedback);
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize
      validationSchema={ResetPasswordSchema}
      onSubmit={(values, {resetForm}) => {
        dispatch(
          UPDATE_MEMBER_PASSWORD({
            password: values.Password,
            token,
            action: () => resetForm(),
            email
          })
        );
      }}
    >
      {({values, handleChange, errors, touched, handleBlur}) => (
        <Form>
          <ConnectedFocusError />
          <SignUpSection className={upFeedback ? 'isActive' : null}>
            <SignUpFeedback className='SignUpFeedback'>
              <SignUpFeedbackTop>
                <SignUpFeedbackButton
                  className='SignUpFeedbackButton'
                  onClick={handleToggle}
                />
              </SignUpFeedbackTop>
              <SignUpFeedbackPhoto
                style={{backgroundImage: `url(${PhotoFeedback})`}}
              />

              <SignUpFeedbackBottom>
                <SignUpFeedbackDescription>
                  &quot;Within a few weeks we had seen a clear increase in
                  overall online sales and doubled our mobile app sales.&quot;
                </SignUpFeedbackDescription>
                <SignUpFeedbackCompany>
                  <SignUpFeedbackLogo src={IconFeedback} />
                  <SignUpFeedbackInfo>
                    <p>Dom DiDiana.</p>
                    <p>
                      President <br /> The Slice Factory
                    </p>
                  </SignUpFeedbackInfo>
                </SignUpFeedbackCompany>
              </SignUpFeedbackBottom>
            </SignUpFeedback>
            <SignUpForm>
              <div className='marginBottomLg'>
                <SignUpLogo src={SauceLogo} />
                <SignUpTitle>
                  <span>Sauce up</span> your Online Sales
                </SignUpTitle>

                <DialogFields className='DialogFieldsSignUp'>
                  <DialogField className='w100 mTopMd'>
                    <FieldLabel className='FieldLabelLg mBottomMd'>
                      Your email address <span>*</span>
                    </FieldLabel>
                    <FieldInput
                      type='email'
                      value={email}
                      name='Email'
                      placeholder='Your email address'
                      className='isDisabled'
                    />

                    <ErrorSignWrapper>
                      <ErrorSign>
                        {errors.Email && touched.Email && errors.Email}
                      </ErrorSign>
                    </ErrorSignWrapper>
                  </DialogField>
                  <DialogField className='w100 '>
                    <FieldLabel className='FieldLabelLg mBottomMd'>
                      New Password <span>*</span>
                    </FieldLabel>
                    <FieldPassword
                      onBlur={handleBlur}
                      value={values.Password}
                      onChange={handleChange}
                      name='Password'
                      className={cn({
                        error: errors.Password && touched.Password
                      })}
                      placeholder='Password'
                    />

                    <ErrorSignWrapper>
                      <ErrorSign>
                        {errors.Password && touched.Password && errors.Password}
                      </ErrorSign>
                    </ErrorSignWrapper>
                  </DialogField>

                  <DialogField className='w100 '>
                    <FieldLabel className='FieldLabelLg mBottomMd'>
                      Confirm New Password <span>*</span>
                    </FieldLabel>
                    <FieldPassword
                      value={values.PasswordConfirm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='PasswordConfirm'
                      placeholder='Password confirm'
                      className={cn({
                        error: errors.PasswordConfirm && touched.PasswordConfirm
                      })}
                    />
                    <ErrorSignWrapper>
                      <ErrorSign>
                        {errors.PasswordConfirm &&
                          touched.PasswordConfirm &&
                          errors.PasswordConfirm}
                      </ErrorSign>
                    </ErrorSignWrapper>
                  </DialogField>
                  <DialogField className='w100 Center mTopMd'>
                    <ButtonPrimary className='w400' type='submit'>
                      <span>Let&apos;s Do This!</span>
                    </ButtonPrimary>
                  </DialogField>
                </DialogFields>
              </div>
              <div>
                <SignUpBottomText className='text-center'>
                  Trusted by local restaurants in over 30 states. Restaurants
                  like yours.
                </SignUpBottomText>
                <Logos>
                  <LogosItem src={LogoBagkok} />
                  <LogosItem src={LogoCarlos} />
                  <LogosItem src={LogoCarabao} />
                  <LogosItem src={LogoHolbox} />
                  <LogosItem src={LogoPizza} />
                </Logos>
              </div>
            </SignUpForm>
          </SignUpSection>
        </Form>
      )}
    </Formik>
  );
};

ActivateMember.propTypes = {
  match: PropTypes.object.isRequired
};

export default ActivateMember;
