/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Loading from '../shared/Utils/Loading';
import {ButtonOutline, ButtonPrimary} from '../buttons';
import DialogContainer from '../DialogContainer/styles.js';
import DialogClose from '../DialogClose/styles.js';
import {DialogBody} from './style.js';
import Body1 from '../typography/Body1';
import DialogActions from '../DialogActions/styles';
import DialogAction from '../DialogAction/styles';

function ConfirmModal(props) {
  const {
    isLoading,
    isOpen,
    message,
    btnOkTitle,
    btnCancelTitle,
    onCancel,
    onOk
  } = props;

  const onOkClick = () => {
    onOk();
  };

  const onCancelClick = () => {
    onCancel();
  };

  return (
    <Dialog open={isOpen} scroll='body' onClose={onCancelClick}>
      <DialogContainer className='DialogXs ConfirmModal'>
        <DialogClose onClick={onCancelClick} />
        <Body1 className='ConfirmModal-Title'>{message}</Body1>
        <DialogBody className='ConfirmModal-BtnPanel'>
          {isLoading && <Loading />}
          {!isLoading && (
            <DialogActions className='marginTopNull justifyCenter'>
              <DialogAction className='wAuto'>
                <ButtonPrimary
                  className='ButtonSmall ButtonMin'
                  onClick={onOkClick}
                >
                  <span>{btnOkTitle}</span>
                </ButtonPrimary>
              </DialogAction>
              <DialogAction className='wAuto'>
                <ButtonOutline
                  className='ButtonSmall ButtonMin'
                  onClick={onCancelClick}
                >
                  {btnCancelTitle}
                </ButtonOutline>
              </DialogAction>
            </DialogActions>
          )}
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  btnOkTitle: PropTypes.string,
  btnCancelTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func
};

ConfirmModal.defaultProps = {
  isLoading: false,
  isOpen: false,
  message: '',
  btnOkTitle: 'Confirm',
  btnCancelTitle: 'Cancel',
  onCancel: () => {},
  onOk: () => {}
};

export default ConfirmModal;
