import styled from 'styled-components';

export const MarketingIntegrationsContainer = styled.div`
  display: flex;
  margin: 0 -16px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
export const MarketingIntegrationsBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0 38px 0;
  &.MetaBlock {
    padding-top: 53px;
  }
`;

export const MarketingIntegrationsCheckBlock = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    color: #17c981;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const MarketingIntegrationsCheck = styled.div`
  display: flex;
  .checked {
    width: 20px;
    height: 20px;
  }
`;

export const MarketingIntegrationsImage = styled.img`
  max-width: 233px;
  max-height: 32px;
  margin-bottom: 34px;
  &.CheckedIntegrations {
    margin-bottom: 54px;
  }
  &.MetaIcon {
    max-width: 40px;
    max-height: 46px;
    margin-bottom: 34px;
    &.CheckedIntegrations {
      margin-bottom: 54px;
    }
  }
`;

export const MarketingIntegrationsTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const MarketingIntegrationsText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
`;

export const MarketingIntegrationsButtons = styled.div`
  display: flex;
`;
