import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import {useDatePickerContext} from '../../Datepicker/DatePickerContext';
import Loading from '../../shared/Utils/Loading';
import {
  DashboardBox,
  DashboardBoxes,
  DashboardBoxNumber,
  DashboardBoxText,
  NoResultText
} from '../../../routes/App/dashboard/styles';
import {GET_SALES_COUNT} from '../../../store/analyticsSalesCount/action';
import roundTheNumber from '../../../helpers/functionUtils/roundTheNumber';

const TotalSales = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const {salesCount, salesCountLoading} = useSelector(
    (state) => state.analyticsSalesCountReducer
  );

  const date = useDatePickerContext();

  useEffect(() => {
    if (timezone) {
      dispatch(
        GET_SALES_COUNT({
          startDate: date[0]
            ? moment(date[0]).utcOffset(timezone, false).utc().format()
            : null,
          endDate: date[1]
            ? moment(date[1]).utcOffset(timezone, false).utc().format()
            : null
        })
      );
    }
  }, [date, locationId, dispatch, timezone]);

  function nFormatter(num) {
    if (num >= 1000000000) {
      return `${(num / 1000000000).toFixed(2).replace(/\.0$/, '')}G`;
    }
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(2).replace(/\.0$/, '')}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(2).replace(/\.0$/, '')}K`;
    }
    return num;
  }

  return (
    <>
      {salesCountLoading && <Loading />}
      {Object.keys(salesCount).length !== 0 && !salesCountLoading && (
        <DashboardBoxes>
          <DashboardBox>
            <DashboardBoxNumber>
              ${nFormatter(salesCount.totalSum)}
            </DashboardBoxNumber>
            {/* <DashboardBoxPercent className="PercentRise">2.5%</DashboardBoxPercent> */}
          </DashboardBox>
          <DashboardBox>
            <DashboardBoxText>
              <strong>{salesCount.orders}</strong> Orders (total)
            </DashboardBoxText>
            <DashboardBoxText>
              <strong>${roundTheNumber(salesCount.avgOrderSize)}</strong> Order
              Value (avg.)
            </DashboardBoxText>
          </DashboardBox>
        </DashboardBoxes>
      )}

      {!salesCountLoading && Object.keys(salesCount).length === 0 && (
        <Box display='flex' alignItems='center' justifyContent='center' m={1}>
          <NoResultText
            variant='body1'
            component='h6'
            align='right'
            className='noResults'
          >
            No results found for this date range!
          </NoResultText>
        </Box>
      )}
    </>
  );
};

export default TotalSales;
