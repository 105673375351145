import {createReducer} from 'redux-act';
import moment from 'moment';
import {
  GET_ORDERS_RTB_SUCCESS,
  CLEAR_ORDERS_RTB,
  SET_SORTING,
  RUN_ORDER_RTB_JOB,
  OPEN_ACTIONS_MADE_MODAL,
  PIN_ORDER,
  UNPIN_ORDER,
  OPEN_ADDITION_INFO_MODAL,
  GET_ORDER_ACTIONS_SUCCESS,
  CLEAR_ORDER_ACTIONS,
  OPEN_OWNED_MODAL,
  RDB_REMOVE_ORDER_MODAL
} from './action';
import {
  dateToSortAsc,
  getMinutesDiffUTC
} from '../../routes/App/Managers/realTimeBoard/helpers';
import {
  BOARD_COLOR_PRIORITY,
  BOARD_ROW_BLINK,
  BOARD_STATUS
} from '../../helpers/constants/adminRealTImeBoard';

const initialSorting = () => ({
  sort: 'desc',
  sortBy: 'pickup_deadline'
});

const initialState = () => ({
  orders: [],
  sorting: initialSorting(),
  pinOrders: [],
  loading: false,
  lastUpdateTime: null,
  openActionsModal: false,
  currentOrderId: null,
  openAdditionInfoModal: false,
  additionInfo: {},
  orderActions: [],
  openOwnedModal: false,
  ownedModalText: '',
  removeOrderModal: {
    open: false,
    internal_delivery_id: null,
    core_invoice_id: null
  }
});

const sortByField = (field, dir) => {
  return (a, b) => {
    if (a[field] > b[field]) {
      return dir === 'asc' ? 1 : -1;
    }
    if (a[field] < b[field]) {
      return dir === 'asc' ? -1 : 1;
    }
    return 0;
  };
};

const lastArrayIndex = (arr = [], order) => {
  const filterArr = arr?.filter(
    (item) =>
      order?.customer_email === item?.customer_email &&
      order?.pickup_address?.name === item?.pickup_address?.name &&
      order?.dropoff_address?.name === item?.dropoff_address?.name &&
      (order?.dropoff_address?.address === item?.dropoff_address?.address ||
        (order?.dropoff_address?.number === item?.dropoff_address?.number &&
          order?.dropoff_address?.street === item?.dropoff_address?.street &&
          order?.dropoff_address?.city === item?.dropoff_address?.city &&
          order?.dropoff_address?.state === item?.dropoff_address?.state &&
          order?.dropoff_address?.zip === item?.dropoff_address?.zip)) &&
      (order?.pickup_address?.address === item?.pickup_address?.address ||
        (order?.pickup_address?.number === item?.pickup_address?.number &&
          order?.pickup_address?.street === item?.pickup_address?.street &&
          order?.pickup_address?.city === item?.pickup_address?.city &&
          order?.pickup_address?.state === item?.pickup_address?.state &&
          order?.pickup_address?.zip === item?.pickup_address?.zip))
  );
  let index = null;
  if (filterArr.length) {
    index = arr.findIndex(
      (el) => el.id === filterArr[filterArr.length - 1]?.id
    );
  }
  return index;
};

const sortByColors = (a, b) => {
  if (Number(a.sortArray.join('')) > Number(b.sortArray.join(''))) {
    return -1;
  }
  if (Number(a.sortArray.join('')) < Number(b.sortArray.join(''))) {
    return 1;
  }
  return 0;
};

const checkLastSameOrder = (currentOrder, orders = []) => {
  const filterArr = orders.filter(
    (item) => item.parentDeliveryId === currentOrder.parentDeliveryId
  );
  // because unshift child order in getOrdersWithParents
  return filterArr[0] === currentOrder;
};

const getOrdersWithParents = (orders) => {
  const ordersWithParents = [];

  orders.forEach((order) => {
    if (
      order &&
      order.parentDeliveryId &&
      !ordersWithParents.find((el) => el.id === order.id)
    ) {
      const parentOrder = orders.find(
        (el) => el.delivery_id === order.parentDeliveryId
      );
      ordersWithParents.unshift({
        ...order,
        colorPriority: !parentOrder.isFutureOrder
          ? parentOrder.colorPriority
          : order.colorPriority
        // isSameOrder: true
      });
    }
    // order.ldb_events?.forEach(
    //   (event) =>
    //     event?.data?.actions.forEach((action) => {
    //       if (action.deliveryId) {
    //         const childOrder = orders.find(
    //           (el) => el.delivery_id === action.deliveryId
    //         );
    //         const parentOrder = ordersWithParents.find(
    //           (el) => el.delivery_id === order.delivery_id
    //         );
    //         if (
    //           childOrder &&
    //           !ordersWithParents.find((el) => el.id === childOrder.id)
    //         ) {
    //           ordersWithParents.unshift({
    //             ...childOrder,
    //             parentDeliveryId:
    //               parentOrder && parentOrder.parentDeliveryId
    //                 ? parentOrder.parentDeliveryId
    //                 : order?.delivery_id,
    //             isSameOrder: true
    //           });
    //         }
    //       }
    //     })
    //   // event?.data?.actions.forEach((action) =>
    //   //         action.deliveryId && ordersWithParents.push({
    //   //           ...order,
    //   //           parentDeliveryId: action.deliveryId
    //   //         })
    //   // )
    // );
  });
  ordersWithParents.forEach((order, index) => {
    if (checkLastSameOrder(order, ordersWithParents)) {
      ordersWithParents[index] = {
        ...ordersWithParents[index],
        isLastOrder: true
      };
    }
  });
  return ordersWithParents;
};

const cutChildOrderFromAList = (orders, orderToMove) => {
  const indexFrom = orders.indexOf(
    orders.find((order) => order.delivery_id === orderToMove.delivery_id)
  );

  orders.splice(indexFrom, 1);

  return orders;
};

const pasteAnOrderUnderItsParent = (orders, orderToMove) => {
  const indexTo =
    orders.indexOf(
      orders.find((order) => order.delivery_id === orderToMove.parentDeliveryId)
    ) + 1;
  orders.splice(indexTo, 0, orderToMove);
  // update parent order order
  orders.splice(indexTo - 1, 1, {
    ...orders[indexTo - 1],
    isFirstOrder: true,
    isSameOrder: true,
    colorPriority: !orderToMove.isFutureOrder
      ? orderToMove.colorPriority
      : orders[indexTo - 1].colorPriority
  });
  return orders;
};

const sortByDeliveryId = (orders) => {
  const ordersWithParents = getOrdersWithParents(orders);

  ordersWithParents.forEach((orderToMove) => {
    cutChildOrderFromAList(orders, orderToMove);
    pasteAnOrderUnderItsParent(orders, orderToMove);
  });

  return orders;
};

const sortOrders = (orders, sortField, sortDir) => {
  // const newOrders = orders?.sort(sortByField('pickup_deadline', 'desc'));
  const resultOrders = [];
  orders.forEach((order) => {
    const lastIndex = lastArrayIndex(resultOrders, order);
    if (lastIndex !== null) {
      resultOrders.splice(lastIndex + 1, 0, order);
    } else {
      resultOrders.push(order);
    }
  });

  const sortedByColors = resultOrders.sort((a, b) => sortByColors(a, b));

  return sortByDeliveryId(sortedByColors);
};

const findSameOrders = (currentOrder, orders) => {
  const filteredArr = orders.filter(
    (order) =>
      currentOrder.delivery_id === order.delivery_id ||
      (currentOrder.parentDeliveryId &&
        order.parentDeliveryId &&
        currentOrder.parentDeliveryId === order.parentDeliveryId) ||
      (currentOrder.parentDeliveryId &&
        currentOrder.parentDeliveryId === order.delivery_id) ||
      (order.parentDeliveryId &&
        currentOrder.delivery_id === order.parentDeliveryId)
  );
  const result = [];
  filteredArr?.forEach((order) => {
    result.push({...order, lastOfType: false});
  });
  return result;
};

const checkPinOrders = (pinOrders, allOrders) => {
  const newPinOrders = [];
  pinOrders.forEach((item) => {
    const currPinnedOrderInAllOrders = allOrders.find(
      (el) => el.id === item.id
    );
    if (currPinnedOrderInAllOrders) {
      const groupPinOrders = findSameOrders(
        currPinnedOrderInAllOrders,
        allOrders
      );

      groupPinOrders.forEach((order) => {
        if (
          !newPinOrders.find(
            (el) => el.internal_delivery_id === order.internal_delivery_id
          )
        ) {
          newPinOrders.push(order);
        }
      });
    }
  });
  return sortByDeliveryId(newPinOrders);
};

const filterArray = (filter, array) => {
  return array.filter((item) => filter?.every((el) => el.id !== item.id));
};

const realTimeBoardReducer = createReducer(
  {
    [RUN_ORDER_RTB_JOB]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_ORDERS_RTB_SUCCESS]: (state, payload) => {
      const defaultTimestamp = moment('2022-01-01 00:00Z').unix();

      const filteredOrders = [];

      payload?.forEach((order) => {
        const oldOrder = state.orders.find((el) => el.id === order._id) || {};

        let blinkCount;
        let priorityUpdatedOn = oldOrder.priorityUpdatedOn
          ? oldOrder.priorityUpdatedOn
          : defaultTimestamp;
        let {lastBlinkTime} = oldOrder;

        let priority = order.riskLevel?.value || BOARD_COLOR_PRIORITY.GREEN;

        if (order.isFutureOrder) {
          priority = BOARD_COLOR_PRIORITY.WHITE;
        }

        if (oldOrder.colorPriority !== priority) {
          priorityUpdatedOn = moment().utc().unix();

          if (priority === BOARD_COLOR_PRIORITY.RED) {
            lastBlinkTime = moment().utc();
            blinkCount = BOARD_ROW_BLINK.THREE_TIMES;
          }

          if (priority === BOARD_COLOR_PRIORITY.ORANGE) {
            blinkCount = BOARD_ROW_BLINK.ONCE;
          }
        }

        // Add 1 time blinking if order has red priority for 180 seconds (3 minute)
        if (
          oldOrder.colorPriority === priority &&
          oldOrder.colorPriority === BOARD_COLOR_PRIORITY.RED &&
          oldOrder.lastBlinkTime &&
          getMinutesDiffUTC(oldOrder.lastBlinkTime) <= -3
        ) {
          blinkCount = BOARD_ROW_BLINK.ONCE;
          lastBlinkTime = moment().utc();
        }

        filteredOrders.push({
          ...order,
          id: order._id,
          status:
            order.isFutureOrder && order.status === BOARD_STATUS.SCHEDULED
              ? 'Future Order'
              : order.status,
          restaurant_name: order.pickup_address?.name,
          customer_name: order.dropoff_address?.name,
          order_items: order.manifest_items?.length,
          courierDistanceToRestaurant: order.distance_to_pickup,
          colorPriority: priority,
          priorityUpdatedOn,
          sortArray: [priority, dateToSortAsc(order.pickup_deadline)],
          blinkCount,
          lastBlinkTime
        });
      });
      const newPinOrders = checkPinOrders(
        JSON.parse(sessionStorage.getItem('rdbPinOrders')) || [],
        filteredOrders
      );
      sessionStorage.setItem('rdbPinOrders', JSON.stringify(newPinOrders));
      return {
        ...state,
        orders: filterArray(
          newPinOrders,
          sortOrders(
            filteredOrders
            // state?.sorting?.sortBy,
            // state?.sorting?.sort
          )
        ),
        pinOrders: newPinOrders,
        loading: false,
        lastUpdateTime: new Date()
      };
    },
    [CLEAR_ORDERS_RTB]: (state) => ({
      ...state,
      orders: [],
      pinOrders: [],
      sorting: initialSorting(),
      lastUpdateTime: null
    }),
    [SET_SORTING]: (state, payload) => {
      return {
        ...state,
        sorting: {
          ...state.sorting,
          ...payload
        },
        orders: sortOrders(state.orders, payload?.sortBy, payload?.sort)
      };
    },
    [OPEN_ACTIONS_MADE_MODAL]: (state, payload) => ({
      ...state,
      openActionsModal: payload.open,
      currentOrderId: payload.id
    }),
    [OPEN_ADDITION_INFO_MODAL]: (state, payload) => {
      return {
        ...state,
        openAdditionInfoModal: payload.open,
        additionInfo: payload.data
      };
    },
    [OPEN_OWNED_MODAL]: (state, payload) => ({
      ...state,
      openOwnedModal: payload.open,
      ownedModalText: payload.text
    }),
    [PIN_ORDER]: (state, payload) => {
      const pinnedOrders = findSameOrders(payload, state.orders);
      const newPinOrders = state.pinOrders;
      newPinOrders.unshift(...pinnedOrders);
      sessionStorage.setItem('rdbPinOrders', JSON.stringify(newPinOrders));
      return {
        ...state,
        orders: filterArray(
          newPinOrders,
          sortOrders(
            state.orders
            // state?.sorting?.sortBy,
            // state?.sorting?.sort
          )
        ),
        pinOrders: newPinOrders
      };
    },
    [UNPIN_ORDER]: (state, payload) => {
      const newPinOrders = filterArray(
        findSameOrders(payload, state.pinOrders),
        state.pinOrders
      );
      const unpinnedOrders = findSameOrders(payload, state.pinOrders);
      const newOrders = state.orders;
      newOrders.push(...unpinnedOrders);
      sessionStorage.setItem('rdbPinOrders', JSON.stringify(newPinOrders));
      return {
        ...state,
        orders: filterArray(
          newPinOrders,
          sortOrders(
            newOrders
            // state?.sorting?.sortBy,
            // state?.sorting?.sort
          )
        ),
        pinOrders: newPinOrders
      };
    },
    [GET_ORDER_ACTIONS_SUCCESS]: (state, payload) => {
      const newActionArray = [];
      payload?.forEach((item) => {
        const container = {};
        const actionName = [];
        item?.data?.actions?.forEach((action) => {
          actionName.push(action.name);
        });
        container.created = item.created;
        container.actions = actionName;
        newActionArray.push(container);
      });
      return {
        ...state,
        orderActions: newActionArray
      };
    },
    [CLEAR_ORDER_ACTIONS]: (state) => ({
      ...state,
      orderActions: []
    }),
    [RDB_REMOVE_ORDER_MODAL]: (
      state,
      {open, internal_delivery_id, core_invoice_id}
    ) => ({
      ...state,
      removeOrderModal: {
        open,
        internal_delivery_id,
        core_invoice_id
      }
    })
  },
  initialState()
);

export default realTimeBoardReducer;
