/* eslint-disable */
import React, {useState} from 'react';
import cn from 'classnames';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import {RadioGroup} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import T from 'prop-types';
import {
  DashboardTime,
  DashboardTimeDelimiter,
  DashboardTimeInner,
  DialogBody,
  DialogField
} from '../../routes/App/dashboard/styles';
import {ButtonOutline, ButtonPrimary} from '../buttons';
import RadioS2E from '../Radiobox';
import DialogFields from '../DialogFields/styles';
import DialogContainer from '../DialogContainer/styles';
import SingleDatepickerS2E from '../SingleDatepicker';
import {intervals} from '../../routes/App/settings/helpers';
import DialogClose from '../DialogClose/styles';
import DialogTitle from '../DialogTitle/styles';
import SelectWrapper from '../select/SelectComponentStyles';
import Input from '../FieldInput/style';
import {maxLenFormatter} from '../../helpers/functionUtils/helpFunctions';
import DialogActions from '../DialogActions/styles';
import DialogAction from '../DialogAction/styles';
import FieldLabel from '../FieldLabel/styles';
import Textarea from '../FieldTextarea/style';
import {ErrorSign, ErrorSignWrapper} from '../errorSign/errorSign';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../helpers/sessionStorage/constants';
import {getSessionStorageItem} from '../../helpers/sessionStorage';
import DatePicker from '../feature/DatePicker';
import {DatePickerString} from '../../routes/App/settings/styles';
import Body4 from '../typography/Body4';
import DisableStoreForHolidayModal from '../../routes/App/settings/Modals/DisableStoreForHolidayModal';
import Body1 from '../typography/Body1';
import Body2 from '../typography/Body2';

const disableStoreReasons = [
  'Too busy',
  'Short-Staffed',
  'My business is temporarily closed',
  'My business is permanently closed',
  'Other'
];

const computeSelectReasonValue = (value) => {
  return disableStoreReasons.indexOf(value) !== -1 ? value : '';
};

const DialogDisableTime = ({
  entityName,
  valueDate,
  valueDates,
  handleValueDate,
  selectValue,
  selectTimeValue,
  selectDisableTimeValue,
  handleSelectValue,
  radioValue,
  onDisable,
  onCancel,
  reasonValue,
  handleRadioValue,
  locationTimeZone,
  formErrors,
  formTouched,
  setTouched,
  reasonDescription,
  onMakeRequest,
  disableHolidayStore,
  validateDateError,
  setValidateDateError,
  data,
  disableButton
}) => {
  const intervalsArr = intervals('00:00', '23:45', 15);

  const checkIfSauceEmployee = () => {
    return getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
  };

  const [selectedDate, setSelectedDate] = useState([]);

  const datepickerOnChange = (datePickerDate) => {
    setSelectedDate(datePickerDate);
    handleValueDate('dateValues', datePickerDate);
  };

  const roundMinutesToQuarter = (time) => {
    const interval = Math.ceil(time.minutes() / 15);
    switch (interval) {
      case 4:
        time.minutes(0);
        time.add(1, 'hours');
        return time;
      default:
        time.minutes(interval * 15);
        return time;
    }
  };
  const isDisableTime = (time, indexTime) => {
    let correctTimeByLocation = moment().utcOffset(Number(locationTimeZone));
    const isSameDay = moment(valueDate).isSame(correctTimeByLocation, 'day');
    if (!isSameDay) return false; // for future days
    correctTimeByLocation = roundMinutesToQuarter(correctTimeByLocation).format(
      'h:mm A'
    );
    const correctTimeIndex = intervalsArr.findIndex(
      (i) => i === correctTimeByLocation
    );
    return indexTime < correctTimeIndex;
  };
  const isDisableTimeForHolidays = (time, indexTime) => {
    let correctTimeByLocation = moment().utcOffset(Number(locationTimeZone));
    const isSameDay = moment(valueDates[0]).isSame(
      correctTimeByLocation,
      'day'
    );
    if (!isSameDay) return false; // for future days
    correctTimeByLocation = roundMinutesToQuarter(correctTimeByLocation).format(
      'h:mm A'
    );
    const correctTimeIndex = intervalsArr.findIndex(
      (i) => i === correctTimeByLocation
    );
    return indexTime < correctTimeIndex;
  };

  const isEnableTimeForHolidays = (time, indexTime) => {
    let correctDisableTimeByLocation = moment(
      disableHolidayStore?.disableStoreTime
    ).utc();
    const isSameDisablingDay = moment(valueDates[1]).isSame(
      valueDates[0],
      'day'
    );

    if (!isSameDisablingDay) return false; // for future days

    correctDisableTimeByLocation = roundMinutesToQuarter(
      correctDisableTimeByLocation
    ).format('h:mm A');
    const correctTimeIndex = intervalsArr.findIndex(
      (i) => i === correctDisableTimeByLocation
    );
    return indexTime < correctTimeIndex;
  };

  let max3days = new Date();
  max3days.setDate(max3days.getDate() + 3);

  const diffDate = (values) => {
    const duration = moment.duration(
      moment(moment(values?.enableStoreTime).utc().format()).diff(
        moment(values?.disableStoreTime).utc().format()
      )
    );
    const day = duration.asDays();
    return Math.floor(day);
  };

  return (
    <Dialog open scroll='body' onClose={() => onCancel()}>
      <DialogContainer className='DialogM'>
        <DialogClose onClick={() => onCancel()} />
        <DialogTitle>
          Disable <span className='orange'>{entityName}</span>
        </DialogTitle>
        <DialogBody>
          <DialogFields>
            <RadioGroup className='RadioGroup'>
              <DialogField className='w100'>
                <RadioS2E
                  type='radio'
                  className='orange'
                  value='one-hour'
                  name='radioValue'
                  checked={radioValue === 'one-hour'}
                  onChange={(e) => {
                    handleRadioValue(e);
                    handleValueDate('selectValue', '', false);
                  }}
                  label=' Pause for 1 hour'
                />
              </DialogField>
              <DialogField className='w100'>
                <RadioS2E
                  type='radio'
                  className='orange'
                  onChange={(e) => {
                    handleRadioValue(e);
                    handleValueDate('selectValue', '', false);
                  }}
                  checked={radioValue === 'opening-time'}
                  value='opening-time'
                  name='radioValue'
                  label='Until next opening time'
                />
              </DialogField>
              <DialogField className='w100'>
                <RadioS2E
                  type='radio'
                  className='orange'
                  checked={radioValue === 'date-and-hour'}
                  onChange={(e) => {
                    handleRadioValue(e);
                    handleValueDate('selectValue', '');
                  }}
                  name='radioValue'
                  value='date-and-hour'
                  label='Until specific date and hour'
                />
              </DialogField>
              {data?.IsMultiTenant && entityName === 'Store' && (
                <DialogField className='w100 dFlex'>
                  <div
                    className={cn('', {
                      isDisabled:
                        (data?.StoreHolidays?.disableStoreTime &&
                          data?.StoreHolidays?.enableStoreTime) ||
                        (data?.DashboardMetadata?.IsCreateHolidaysRequest &&
                          !checkIfSauceEmployee())
                    })}
                  >
                    <RadioS2E
                      type='radio'
                      className='orange'
                      value='custom-dates-request'
                      name='radioValue'
                      checked={radioValue === 'custom-dates-request'}
                      onChange={(e) => {
                        handleRadioValue(e);
                      }}
                      label='Future dates'
                    />
                  </div>
                  {((data?.StoreHolidays?.disableStoreTime &&
                    data?.StoreHolidays?.enableStoreTime) ||
                    (data?.DashboardMetadata?.IsCreateHolidaysRequest &&
                      !checkIfSauceEmployee())) && (
                    <Body1 className='RedText relativeAndTop'>
                      You can’t disable the store for multiple future dates
                    </Body1>
                  )}
                </DialogField>
              )}
              {radioValue !== 'custom-dates-request' ? (
                <DialogField
                  className={cn('w100', {
                    isDisabled: radioValue !== 'date-and-hour'
                  })}
                >
                  <DashboardTime className='pLeft0'>
                    <DashboardTimeInner className='Say2EatSelect Say2EatSelectOrange Say2EatSelectLight Say2EatSelectTimeOrange'>
                      <FormControl>
                        <Select
                          onChange={handleSelectValue}
                          value={selectValue}
                          name='selectValue'
                          inputProps={{'aria-label': 'value'}}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {intervalsArr.map((period, index) => (
                            <MenuItem
                              value={period}
                              disabled={isDisableTime(period, index)}
                            >
                              {period}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.selectValue ? (
                          <ErrorSignWrapper className='positionBottom'>
                            <ErrorSign>{formErrors.selectValue}</ErrorSign>
                          </ErrorSignWrapper>
                        ) : null}
                      </FormControl>
                    </DashboardTimeInner>
                    <DashboardTimeDelimiter>on</DashboardTimeDelimiter>
                    <DashboardTimeInner>
                      <SingleDatepickerS2E
                        value={valueDate}
                        maxDate={max3days}
                        onChange={(newValue) =>
                          handleValueDate('dateValue', newValue)
                        }
                      />
                    </DashboardTimeInner>
                  </DashboardTime>
                </DialogField>
              ) : (
                <DialogField className='w100 DropDown StaticRangeDatePicker'>
                  <Body1 className='StaticRangeDatePickerText'>
                    To disable the store for more than 3 days you will need to
                    submit a request for approval.
                  </Body1>
                  <DatePicker
                    disablePast={true}
                    minDate={new Date()}
                    setSelectedDate={setSelectedDate}
                    dateValue={valueDates}
                    datepickerOnChange={datepickerOnChange}
                    dateHolidays={true}
                  />
                  <DatePickerString>
                    <DashboardTimeInner className='DatePickerInner Say2EatSelect Say2EatSelectOrange Say2EatSelectLight Say2EatSelectTime Say2EatSelectTimeWidth100'>
                      <Body4>Disabling time</Body4>
                      <FormControl>
                        <Select
                          onChange={(e) => {
                            handleSelectValue(e);
                            setValidateDateError(false);
                          }}
                          value={selectDisableTimeValue}
                          name='selectDisableTimeValue'
                          inputProps={{'aria-label': 'value'}}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {intervalsArr.map((period, index) => (
                            <MenuItem
                              value={period}
                              disabled={isDisableTimeForHolidays(period, index)}
                            >
                              {period}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.selectDisableTimeValue ? (
                          <ErrorSignWrapper className='positionBottom'>
                            <ErrorSign>
                              {formErrors.selectDisableTimeValue}
                            </ErrorSign>
                          </ErrorSignWrapper>
                        ) : null}
                      </FormControl>
                    </DashboardTimeInner>
                    <DashboardTimeInner className='DatePickerInner Say2EatSelect Say2EatSelectOrange Say2EatSelectLight Say2EatSelectTime Say2EatSelectTimeWidth100'>
                      <Body4>Enabling time</Body4>
                      <FormControl>
                        <Select
                          onChange={(e) => {
                            handleSelectValue(e);
                            setValidateDateError(false);
                          }}
                          value={selectTimeValue}
                          name='selectTimeValue'
                          inputProps={{'aria-label': 'value'}}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {intervalsArr.map((period, index) => (
                            <MenuItem
                              value={period}
                              disabled={isEnableTimeForHolidays(period, index)}
                            >
                              {period}
                            </MenuItem>
                          ))}
                        </Select>
                        {formErrors.selectTimeValue ? (
                          <ErrorSignWrapper className='positionBottom'>
                            <ErrorSign>{formErrors.selectTimeValue}</ErrorSign>
                          </ErrorSignWrapper>
                        ) : null}
                        {validateDateError ? (
                          <ErrorSignWrapper className='positionBottom'>
                            <ErrorSign>Please select correct time</ErrorSign>
                          </ErrorSignWrapper>
                        ) : null}
                      </FormControl>
                    </DashboardTimeInner>
                  </DatePickerString>
                </DialogField>
              )}
            </RadioGroup>
          </DialogFields>
          <DialogFields>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <DialogField className='w100'>
                <FieldLabel>
                  <span>*</span>Reason
                </FieldLabel>
                <SelectWrapper className='w50'>
                  <Select
                    displayEmpty
                    placeholder='Choice the reason'
                    name={'reasonValue'}
                    value={computeSelectReasonValue(reasonValue)}
                    onChange={(event) => {
                      const {value} = event.target;
                      handleValueDate('reasonValue', value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                    renderValue={(value) => (
                      <Input
                        className={
                          formErrors.reasonValue &&
                          formTouched?.reasonValue &&
                          'error'
                        }
                        value={value}
                        placeholder='Select a reason'
                      />
                    )}
                  >
                    {disableStoreReasons.map((elem) => (
                      <MenuItem value={elem}>{elem}</MenuItem>
                    ))}
                  </Select>
                  {formErrors.reasonValue && formTouched?.reasonValue ? (
                    <ErrorSignWrapper>
                      <ErrorSign>{formErrors.reasonValue}</ErrorSign>
                    </ErrorSignWrapper>
                  ) : null}
                </SelectWrapper>
              </DialogField>

              {reasonValue === 'Other' && (
                <DialogField className='w100'>
                  <Textarea
                    style={
                      formErrors.reasonDescription
                        ? {
                            width: '100%',
                            resize: 'none',
                            border: '1px solid #ba0302'
                          }
                        : {width: '100%', resize: 'none'}
                    }
                    className={cn('FieldTextareaLg', {
                      error: formErrors.reasonDescription
                    })}
                    value={reasonDescription}
                    onChange={(event) => {
                      handleValueDate(
                        'reasonDescription',
                        maxLenFormatter(
                          event.target.value,
                          reasonDescription,
                          150
                        )
                      );
                    }}
                    placeholder={'Other reason'}
                  />
                  {formErrors.reasonDescription ? (
                    <ErrorSignWrapper>
                      <ErrorSign>{formErrors.reasonDescription}</ErrorSign>
                    </ErrorSignWrapper>
                  ) : null}
                </DialogField>
              )}
            </div>
          </DialogFields>
          <DialogActions>
            <DialogAction className='w50'>
              <ButtonOutline onClick={() => onCancel()}>Cancel</ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              {radioValue === 'custom-dates-request' ? (
                <>
                  <ButtonPrimary
                    onClick={() => {
                      if (
                        Object.keys(formErrors).length ||
                        moment(
                          moment(disableHolidayStore?.enableStoreTime)
                        ).isBefore(
                          moment(disableHolidayStore?.disableStoreTime)
                        )
                      ) {
                        setTouched({reasonValue: true});
                        if (Object.keys(formErrors).length) {
                          setTouched({reasonValue: true});
                        }
                        if (
                          moment(
                            moment(disableHolidayStore?.enableStoreTime)
                          ).isBefore(
                            moment(disableHolidayStore?.disableStoreTime)
                          )
                        ) {
                          setValidateDateError(true);
                        }
                      } else {
                        onMakeRequest();
                        onCancel();
                      }
                    }}
                    type='submit'
                  >
                    <span>
                      {!!checkIfSauceEmployee() ||
                      diffDate(disableHolidayStore) <= 3
                        ? 'Disable future date'
                        : 'Make request'}
                    </span>
                  </ButtonPrimary>
                </>
              ) : (
                <ButtonPrimary
                  className={cn('', {
                    disabled: disableButton
                  })}
                  onClick={() => onDisable()}
                  type='submit'
                >
                  <span>Disable {entityName}</span>
                </ButtonPrimary>
              )}
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DialogDisableTime.propTypes = {
  entityName: T.string.isRequired,
  locationTimeZone: T.string.isRequired,
  disableHolidayStore: T.object.isRequired,
  data: T.object.isRequired,
  disableButton: T.bool.isRequired
};

export default DialogDisableTime;
