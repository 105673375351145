import styled from 'styled-components';

export const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  @media (max-width: 1200px) {
    width: 878px;
    height: 500px;
  }
  @media (max-width: 1042px) {
    width: 768px;
    height: 400px;
  }
  @media (max-width: 950px) {
    width: 600px;
    height: 300px;
  }
  @media (max-width: 767px) {
    width: 500px;
    height: 300px;
  }
  @media (max-width: 650px) {
    width: 400px;
    height: 200px;
  }
  @media (max-width: 599px) {
    width: 360px;
    height: 180px;
  }
  @media (max-width: 480px) {
    width: 320px;
    height: 160px;
  }
  @media (max-width: 450px) {
    width: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default PlayerWrapper;
