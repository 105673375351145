/* eslint-disable */
import React, {memo, useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {Form, Formik} from 'formik';
import {ConnectedFocusError} from 'focus-formik-error';
import {stepData} from '../../../../../../store/selfCreateAccount/selectors';
import {generalInfoSchema} from '../../../../../../helpers/services/formValidationSchemas/SelfCreateAccountSchema';
import {
  SAVE_STEP_DATA,
  POST_STEP,
  CONTROL_SIDEBAR_PROGRESS
} from '../../../../../../store/selfCreateAccount/action';
import {FieldLabel} from '../../../../../../components/FieldLabel/styles';
import FieldInput from '../../../../../../components/FieldInput';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';
import AutocompletedMapField from '../../../../settings/LocationMap/AutocompletedMapField';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../components/buttons';
import {
  countryCodeFormatter,
  phoneNumberFormatter,
  taxFormatter
} from '../../../../../../helpers/functionUtils/helpFunctions';
import {
  initializeGeneralInfo,
  serverDataFormatter
} from '../../../helplers/GeneralInfoInit';
import {
  SetupActions,
  SetupContainer,
  SetupFieldset,
  SetupHeader,
  SetupLogo
} from '../../../../../Auth/registration/signUpNew/style';
import Caption4 from '../../../../../../components/typography/Caption4';
import {DashboardField, DashboardFields} from '../../../../dashboard/styles';
import SauceLogoRed from '../../../../../../assets/logo-sauce-red.svg';
import Subtitle5 from '../../../../../../components/typography/Subtitle5';
import Subtitle0 from '../../../../../../components/typography/Subtitle0';
import {useHistory} from 'react-router-dom';
import MobileProgressBar from '../../Components/MobileProgressBar';
import FieldMaskInput from '../../../../../../components/FieldMaskInput';
import ButtonClear from '../../../../../../components/buttons/ButtonClear/styles';
import cuisineTypes from '../../../constants/setupConts';
import SelectWrapper from '../../../../../../components/select/SelectComponentStyles';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {UserId, countries, step0} = stepData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirectLink, setRedirectLink] = useState(null);
  const [, setCountryState] = useState({
    name: '',
    code: ''
  });
  useEffect(() => {
    dispatch(CONTROL_SIDEBAR_PROGRESS('firstStepDone'));
  }, []);

  return (
    <Formik
      initialValues={initializeGeneralInfo(UserId, step0)}
      enableReinitialize
      validationSchema={generalInfoSchema}
      onSubmit={async (values) => {
        setIsSubmitting(true);
        const {data, ...info} = serverDataFormatter(values, UserId);
        dispatch(SAVE_STEP_DATA({data, ...info}));
        dispatch(
          POST_STEP({
            payload: info,
            successCallback: () => {
              setIsSubmitting(false);
              history.push(redirectLink);
            }
          })
        );
      }}
    >
      {({
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        errors,
        touched,
        setFieldValue,
        setErrors
      }) => (
        <Form autoComplete='off' role='presentation' name='generalInfoSelf'>
          <ConnectedFocusError />
          <SetupContainer>
            <SetupHeader>
              <SetupLogo src={SauceLogoRed} />
              <Subtitle5>
                <span>Setup</span> your restaurant
              </Subtitle5>
            </SetupHeader>
            <MobileProgressBar />
            <Subtitle0>General Info</Subtitle0>
            <SetupFieldset>
              <Caption4>Your Info</Caption4>
              <DashboardFields>
                <DashboardField className='w25'>
                  <FieldLabel>
                    First Name <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    autoComplete='nope'
                    type='text'
                    value={values.User.FirstName}
                    onChange={handleChange}
                    name='User.FirstName'
                    placeholder='First Name'
                    className={cn({
                      error:
                        (errors?.User?.FirstName && touched?.User?.FirstName) ||
                        (errors?.User?.FirstName &&
                          errors?.User?.FirstName === 'English characters only')
                    })}
                  />
                  <ErrorSignWrapper>
                    {(errors?.User?.FirstName && touched?.User?.FirstName) ||
                    (errors?.User?.FirstName &&
                      errors?.User?.FirstName === 'English characters only') ? (
                      <ErrorSign>{errors?.User?.FirstName}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='w25'>
                  <FieldLabel>
                    Last Name <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    type='text'
                    value={values.User.LastName}
                    onChange={handleChange}
                    name='User.LastName'
                    placeholder='Last Name'
                    className={cn({
                      error:
                        (errors?.User?.LastName && touched?.User?.LastName) ||
                        (errors?.User?.LastName &&
                          errors?.User?.LastName === 'English characters only')
                    })}
                  />
                  <ErrorSignWrapper>
                    {(errors?.User?.LastName && touched?.User?.LastName) ||
                    (errors?.User?.LastName &&
                      errors?.User?.LastName === 'English characters only') ? (
                      <ErrorSign>{errors?.User?.LastName}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='FieldNumber'>
                  <FieldLabel>
                    Country Code <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    onBlur={handleBlur}
                    disabled
                    value={values.User.PhoneCountryCode}
                    onChange={(e) => {
                      const code = countryCodeFormatter(e.target.value);
                      setFieldValue('User.PhoneCountryCode', code);
                    }}
                    name='User.PhoneCountryCode'
                    placeholder='+1'
                    className={cn({
                      error:
                        errors?.User?.PhoneCountryCode &&
                        touched?.User?.PhoneCountryCode
                    })}
                  />
                  <ErrorSignWrapper>
                    {errors?.User?.PhoneCountryCode &&
                    touched?.User?.PhoneCountryCode ? (
                      <ErrorSign>{errors?.User?.PhoneCountryCode}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAutoSpace'>
                  <FieldLabel>
                    Your phone number <span>*</span>
                  </FieldLabel>
                  <FieldMaskInput
                    mask='(999) 999-9999'
                    value={values.User.UserPhoneNumber}
                    onChange={(e) => {
                      const code = phoneNumberFormatter(e.target.value);
                      setFieldValue('User.UserPhoneNumber', code);
                    }}
                    name='User.UserPhoneNumber'
                    placeholder='Your phone number'
                    className={cn({
                      error:
                        errors?.User?.UserPhoneNumber &&
                        touched?.User?.UserPhoneNumber
                    })}
                  />
                  <ErrorSignWrapper leftPosition='20px'>
                    {errors?.User?.UserPhoneNumber &&
                    touched?.User?.UserPhoneNumber ? (
                      <ErrorSign>{errors?.User?.UserPhoneNumber}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFields>
            </SetupFieldset>
            <SetupFieldset>
              <Caption4>Restaurant Info</Caption4>
              <DashboardFields>
                <DashboardField className='w100'>
                  <FieldLabel>
                    Restaurant Name <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    type='text'
                    value={values.Merchant.MerchantName}
                    onChange={handleChange}
                    name='Merchant.MerchantName'
                    placeholder='Restaurant Name'
                    className={cn({
                      error:
                        (errors.Merchant?.MerchantName &&
                          touched.Merchant?.MerchantName) ||
                        (errors?.Merchant?.MerchantName &&
                          errors?.Merchant?.MerchantName ===
                            'English characters only')
                    })}
                  />

                  <ErrorSignWrapper>
                    {(errors.Merchant?.MerchantName &&
                      touched.Merchant?.MerchantName) ||
                    (errors?.Merchant?.MerchantName &&
                      errors?.Merchant?.MerchantName ===
                        'English characters only') ? (
                      <ErrorSign>{errors.Merchant?.MerchantName}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='w100'>
                  <FieldLabel>Restaurant URL</FieldLabel>
                  <FieldInput
                    type='text'
                    value={values.Merchant.RestaurantURL}
                    onChange={handleChange}
                    name='Merchant.RestaurantURL'
                    placeholder='Restaurant URL'
                    className={cn({
                      error:
                        errors.Merchant?.RestaurantURL &&
                        touched.Merchant?.RestaurantURL
                    })}
                  />

                  <ErrorSignWrapper>
                    {errors.Merchant?.RestaurantURL &&
                    touched.Merchant?.RestaurantURL ? (
                      <ErrorSign>{errors.Merchant?.RestaurantURL}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='w100 withClear'>
                  <FieldLabel>
                    Address <span>*</span>
                  </FieldLabel>
                  {countries.length && (
                    <>
                      <AutocompletedMapField
                        value={values.FullAddress}
                        onChange={handleChange}
                        name='FullAddress'
                        fieldToClear='Address'
                        placeholder='Address'
                        formReference='generalInfoSelf'
                        setFieldValue={setFieldValue}
                        setCountryState={setCountryState}
                        countryList={countries}
                        structuredAddress={values.StructuredAddress}
                        className={cn('AutoComplete', {
                          error:
                            (errors.FullAddress && touched.FullAddress) ||
                            (errors.City && touched.City)
                        })}
                      />
                      {!!values.FullAddress && (
                        <ButtonClear
                          onClick={() => {
                            setFieldValue('FullAddress', '');
                          }}
                        />
                      )}
                      <ErrorSignWrapper>
                        <ErrorSign>
                          {errors.FullAddress && touched.FullAddress
                            ? errors.FullAddress
                            : errors.City && touched.City && errors.City}
                        </ErrorSign>
                      </ErrorSignWrapper>
                    </>
                  )}
                </DashboardField>
                <DashboardField className='w100'>
                  <FieldLabel>
                    Cuisine Type <span>*</span>
                  </FieldLabel>
                  <SelectWrapper className='SelectMd w100'>
                    <FormControl className='select-formcontrol'>
                      <Select
                        value={values.CuisineType}
                        name='CuisineType'
                        onChange={(e) => {
                          setFieldValue('CuisineType', e.target.value);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <span style={{color: '#929cb9'}}>
                                Cuisine Type
                              </span>
                            );
                          }

                          return selected;
                        }}
                        className={cn({
                          error: errors.CuisineType && touched.CuisineType
                        })}
                        MenuProps={{
                          disableScrollLock: true,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                      >
                        <MenuItem disabled value=''>
                          Cuisine Type
                        </MenuItem>
                        {cuisineTypes.map((item) => (
                          <MenuItem key={nanoid()} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </SelectWrapper>
                  <ErrorSignWrapper leftPosition='20px'>
                    {errors?.CuisineType && touched?.CuisineType ? (
                      <ErrorSign>{errors?.CuisineType}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='w100'>
                  <FieldLabel>Delivery Special Instructions</FieldLabel>
                  <FieldInput
                    type='text'
                    value={values.DeliveryInstructions}
                    onChange={handleChange}
                    name='DeliveryInstructions'
                    placeholder='Delivery Special Instructions'
                    className={cn({
                      error:
                        (errors?.DeliveryInstructions &&
                          touched?.DeliveryInstructions) ||
                        (errors?.DeliveryInstructions &&
                          errors?.DeliveryInstructions ===
                            'English characters only')
                    })}
                  />

                  <ErrorSignWrapper>
                    {(errors?.DeliveryInstructions &&
                      touched?.DeliveryInstructions) ||
                    (errors?.DeliveryInstructions &&
                      errors?.DeliveryInstructions ===
                        'English characters only') ? (
                      <ErrorSign>{errors?.DeliveryInstructions}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='FieldNumber'>
                  <FieldLabel>
                    Country Code <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    autoComplete='nope'
                    disabled
                    onBlur={handleBlur}
                    value={values.CountryCode}
                    onChange={(e) => {
                      const code = countryCodeFormatter(e.target.value);
                      setFieldValue('CountryCode', code);
                    }}
                    name='CountryCode'
                    placeholder='+1'
                    className={cn({
                      error: errors.CountryCode && touched.CountryCode
                    })}
                  />
                  <ErrorSignWrapper>
                    {errors.CountryCode && touched.CountryCode ? (
                      <ErrorSign>{errors.CountryCode}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAutoSpace'>
                  <FieldLabel>
                    The Restaurant&apos;s Phone Number <span>*</span>
                  </FieldLabel>
                  <FieldMaskInput
                    mask='(999) 999-9999'
                    value={values.Phone}
                    onChange={(e) => {
                      const code = phoneNumberFormatter(e.target.value);
                      setFieldValue('Phone', code);
                    }}
                    name='Phone'
                    placeholder='Phone'
                    className={cn({
                      error: errors.Phone && touched.Phone
                    })}
                  />
                  <ErrorSignWrapper>
                    {errors.Phone && touched.Phone ? (
                      <ErrorSign>{errors.Phone}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
                <DashboardField className='wAutoSpace'>
                  <FieldLabel>
                    Sales tax rate, % <span>*</span>
                  </FieldLabel>
                  <FieldInput
                    onBlur={(e) => {
                      const lastElement = e.target.value.slice(-1)[0];
                      if (lastElement === '.') {
                        setFieldValue(
                          'SalesTaxRate',
                          e.target.value.slice(0, -1)
                        );
                      }
                    }}
                    value={values.SalesTaxRate}
                    onChange={(event) => {
                      const tax = taxFormatter(
                        event.target.value,
                        values.SalesTaxRate
                      );
                      setFieldValue('SalesTaxRate', tax);
                    }}
                    name='SalesTaxRate'
                    placeholder='Sales tax rate, %'
                    className={cn({
                      error: errors?.SalesTaxRate && touched?.SalesTaxRate
                    })}
                  />

                  <ErrorSignWrapper>
                    {errors?.SalesTaxRate && touched?.SalesTaxRate ? (
                      <ErrorSign>{errors?.SalesTaxRate}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFields>
            </SetupFieldset>
            <SetupActions>
              <ButtonOutline
                type='submit'
                onClick={(e) => {
                  setRedirectLink('/my-account/self-setup/book-call');
                  handleSubmit(e);
                }}
                className={cn('ButtonLg ButtonBookCall', {
                  disabled: isSubmitting
                })}
              >
                Book a Call with Us
              </ButtonOutline>
              <ButtonPrimary
                type='submit'
                onClick={(e) => {
                  setRedirectLink('/my-account/self-setup/free-trial');
                  handleSubmit(e);
                }}
                className={cn({
                  disabled: isSubmitting
                })}
              >
                <span>Start your Free 14-Day Trial</span>
              </ButtonPrimary>
            </SetupActions>
          </SetupContainer>
        </Form>
      )}
    </Formik>
  );
};

export default memo(GeneralInfo);
