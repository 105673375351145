/* eslint-disable */
import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {
  GenerateButton,
  ReportsDataPickerContainer,
  ReportsDatepicker,
  ReportsInfo,
  ReportTypePickerContainer
} from './styles';
import ReportsDatepickerModule from '../ReportsPageDatepickerModule';
import IconGenerateReportButton from '../../../../assets/iconGenerateReportButton';
import ReportsTypePicker from './ReportsTypePicker';
import {
  SET_REPORTS_DATE_INTERVAL,
  SET_REPORT_TYPE
} from '../../../../store/reports/action';

const ReportsDataPicker = ({generateButtonHandler}) => {
  const {
    dateRange,
    reportType,
    generationLoading,
    stripeIntersected,
    datePickerTabIndex
  } = useSelector((state) => state.reportsReducer);
  const dispatch = useDispatch();
  const Timezone = useSelector((state) => state.user.activeLocation.Timezone);

  const updateDate = (dateArray) => {
    const formattedDateArray = [
      dateArray[0]
        ? moment(dateArray[0])
            .utcOffset(Timezone, false)
            .utc()
            .format('YYYY-MM-DD')
        : null,
      dateArray[1]
        ? moment(dateArray[1])
            .utcOffset(Timezone, false)
            .utc()
            .format('YYYY-MM-DD')
        : null
    ];

    dispatch(SET_REPORTS_DATE_INTERVAL(formattedDateArray));
  };
  const setReportType = (type) => {
    dispatch(SET_REPORT_TYPE(type));
  };

  return (
    <ReportsDataPickerContainer>
      <div className='dataPickersContainer'>
        <ReportTypePickerContainer>
          <span>Report type</span>
          <ReportsTypePicker
            reportType={reportType}
            setReportType={setReportType}
          />
        </ReportTypePickerContainer>
        <ReportsDatepicker>
          <ReportsDatepickerModule
            className='reportsDatepicker'
            date={dateRange}
            updateDate={updateDate}
            defaultSelectIndex={datePickerTabIndex}
            reportType={reportType}
            Timezone={Timezone}
          />
          <GenerateButton
            className={cn({
              animate: generationLoading
            })}
            onClick={generateButtonHandler}
          >
            <IconGenerateReportButton />
            <span>Generate</span>
          </GenerateButton>
        </ReportsDatepicker>
      </div>
      <div className='infoReportsContainer'>
        <ReportsInfo>
          {stripeIntersected === 'merchant-wise'
            ? 'This report covers all of your locations'
            : stripeIntersected === 'several'
            ? 'This report covers some of your locations'
            : 'Export report to view full data'}
        </ReportsInfo>
      </div>
    </ReportsDataPickerContainer>
  );
};
export default ReportsDataPicker;
