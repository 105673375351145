import React from 'react';

const IconSidebarFeedback = () => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.8924 0.431641H3.52329C3.29716 0.431641 3.11395 0.615391 3.11395 0.841797V6.56375C3.86306 5.39811 3.5508 5.88393 4.57438 4.29148C4.80735 3.92918 5.20329 3.71289 5.63395 3.71289C6.40546 3.71289 7.33473 4.41748 7.33473 5.53453C7.33014 6.02997 7.24948 6.52131 7.09219 6.99605H7.57727C8.69716 6.99605 9.56642 7.97819 9.43118 9.09113L9.33466 9.88684H11.4191C11.5935 9.88684 11.7647 9.93578 11.9132 10.0279L13.3747 10.9355C13.6476 11.1046 14 10.9076 14 10.5868V3.54473C14 1.82809 12.6058 0.431641 10.8924 0.431641ZM11.9 6.17383H8.48235C8.25622 6.17383 8.07301 5.99035 8.07301 5.76367C8.07301 5.53727 8.25622 5.35352 8.48235 5.35352H11.9C12.1262 5.35352 12.3094 5.53727 12.3094 5.76367C12.3094 5.99035 12.1262 6.17383 11.9 6.17383ZM11.9 4.5332H8.48235C8.25622 4.5332 8.07301 4.34973 8.07301 4.12305C8.07301 3.89664 8.25622 3.71289 8.48235 3.71289H11.9C12.1262 3.71289 12.3094 3.89664 12.3094 4.12305C12.3094 4.34973 12.1262 4.5332 11.9 4.5332ZM11.9 2.89258H5.16118C4.93477 2.89258 4.75157 2.7091 4.75157 2.48242C4.75157 2.25602 4.93477 2.07227 5.16118 2.07227H11.9C12.1262 2.07227 12.3094 2.25602 12.3094 2.48242C12.3094 2.7091 12.1262 2.89258 11.9 2.89258Z'
      fill='#929CB9'
    />
    <path
      d='M7.57719 7.81625H6.47989C6.17724 7.81625 5.98209 7.49772 6.11375 7.22918C6.36739 6.70795 6.50876 6.12865 6.51434 5.52676V5.51637C6.51434 4.94598 6.04025 4.55936 5.66696 4.53445C5.50809 4.52242 5.3525 4.59789 5.26446 4.73488C3.53196 7.43024 3.64283 7.27993 3.50543 7.41676C3.51071 7.48134 3.50899 7.19875 3.50899 12.0444C3.82358 12.1626 4.08455 12.4278 4.68832 12.4278H7.36774C7.8427 12.4278 8.24356 12.0723 8.30071 11.5995L8.6168 8.9923C8.69282 8.36723 8.20582 7.81625 7.57719 7.81625Z'
      fill='#929CB9'
    />
    <path
      d='M2.28613 7.10693H0.402664C0.180113 7.10693 0 7.28713 0 7.50861V12.2079C0 12.4277 0.178008 12.6096 0.402664 12.6096H0.407203C1.57412 12.5967 1.15306 12.6014 2.29067 12.5883C2.51095 12.5858 2.68879 12.4067 2.68879 12.1866V7.50861C2.68879 7.28713 2.50843 7.10693 2.28613 7.10693Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarFeedback;
