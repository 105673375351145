import styled from 'styled-components';
import IconMore from '../../../assets/icon-more-dark.svg';
import IconMoreGrey from '../../../assets/icon-more-grey.svg';

const ButtonMore = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background-image: url('${IconMore}');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  background-color: transparent;
  display: inline-block;
  transition-property: opacity;
  transition-duration: 0.3s;
  display: ${(props) => (props.hidden ? 'none' : '')};

  &:hover {
    opacity: 0.7;
  }
  &.ButtonMoreGrey {
    background-image: url('${IconMoreGrey}');
  }
`;

export default ButtonMore;
