import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Dialog} from '@material-ui/core';
import DialogBody from '../../../../components/DialogBody/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {SuccessImage} from '../../account/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import Body4 from '../../../../components/typography/Body4';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';
import {OPEN_OFFLINE_MODAL} from '../../../../store/global/globalNotifications/action';
import useNetwork from '../../../../hooks/useNetwork';
import {gif} from './offline-gif';

const OfflineModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [onlineGif, setOnlineGif] = useState(null);
  const [, updateNetwork] = useNetwork();

  const isOpen = useSelector(
    (state) => state.globalNotificationsReducer.isOfflineModalOpen
  );
  useEffect(() => {
    setOnlineGif(gif());
    if (!isOpen) setOnlineGif(null);
  }, [isOpen]);

  return (
    <Dialog open={isOpen} scroll='body'>
      <DialogContainer className='DialogM'>
        <DialogBody>
          <DialogTitle className='marginBottomXs'>
            It seems that you are not online right now :(
          </DialogTitle>
          <SuccessImage src={onlineGif} />
          <Body4 className='text-center'>
            To run the app, turn on WiFi, Mobile Data, or your Hot Spot.
            <br />
            <br />
            <b>
              While you wait, your orders are available for viewing. <br />
              Once you are online again, we will notify you.
            </b>
          </Body4>
          <DialogActions>
            <DialogAction className='w50'>
              <ButtonOutline
                className='ButtonLg'
                onClick={() => updateNetwork()}
              >
                Retry
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                onClick={() => {
                  dispatch(OPEN_OFFLINE_MODAL(false));
                  history.push('/orders');
                }}
              >
                <span>Take Me to Orders</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default OfflineModal;
