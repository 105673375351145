/* eslint-disable */
import {
  sortByNumber,
  sortByString,
  createMap,
  createBranchMap
} from './MenuList';
import {setActiveBranchMapsForSearch, setActiveBranchMaps} from './MenuList';

export function initMenuStore(store, payload) {
  let {sections, items, groups, modifiers, ...data} = payload;

  const nextStore = {...store, ...data};

  sections = sortByNumber(sections, 'SortingKey');
  items = sortByString(items, 'Name');
  groups = sortByString(groups, 'OptionName');
  modifiers = sortByString(modifiers, 'Name');

  nextStore.sectionsDirection = sections.map((item) => item.MerchantSectionId);
  nextStore.itemsDirection = items.map((item) => item.PosId);
  nextStore.groupsDirection = groups.map((item) => item.PosId);
  nextStore.modifiersDirection = modifiers.map((item) => item.PosId);

  nextStore.sectionsMap = createMap(sections, 'MerchantSectionId');
  nextStore.itemsMap = createMap(items, 'PosId');
  nextStore.groupsMap = createMap(groups, 'PosId');
  nextStore.modifiersMap = createMap(modifiers, 'PosId');

  nextStore.sectionsBranchMap = createBranchMap(sections, 'category');
  nextStore.itemsBranchMap = createBranchMap(items, 'item');
  nextStore.groupsBranchMap = createBranchMap(groups, 'modifierGroup');
  nextStore.modifiersBranchMap = createBranchMap(modifiers, 'modifier');

  return nextStore;
}

export function setActiveTree(store, activeTab, search) {
  const searchFormatted = search ? search.toLowerCase() : '';
  const nextStore = {
    ...store,
    activeTab,
    searchValue: searchFormatted
  };

  if (search) {
    return setActiveBranchMapsForSearch(nextStore, activeTab, searchFormatted);
  }

  return setActiveBranchMaps(nextStore, activeTab);
}
