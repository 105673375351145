import {put, takeEvery} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  deleteFileApi,
  uploadFileApi
} from '../../../helpers/services/api/menuEditor';
import {DELETE, UPLOAD, UPLOAD_SUCCESS} from './action';

function* deleteFile({payload: {file, callback, setLoading}}) {
  try {
    setLoading(true);
    const {status} = yield deleteFileApi(file);

    if (status === 204 || status === 201) {
      callback();
    } else {
      toast.error('Error file deleted!');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  } finally {
    setLoading(false);
  }
}

function* uploadFile({payload: {form, callback, setLoading}}) {
  try {
    setLoading(true);
    const {status, data} = yield uploadFileApi({
      headers: {'Content-Type': 'multipart/form-data'},
      data: form
    });

    if (status === 201) {
      callback(data.url);
    } else {
      toast.error('Error file uploaded!');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  } finally {
    setLoading(false);
  }
}

function* UploadSaga() {
  yield takeEvery(UPLOAD, uploadFile);
  yield takeEvery(DELETE, deleteFile);
}

export default UploadSaga;
