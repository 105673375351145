import * as Yup from 'yup';
import {URL_REGEXP} from '../constants/RegExpConstants';

const regex = new RegExp(URL_REGEXP);

const SignUpSchema = Yup.object().shape({
  Email: Yup.string()
    .email('Invalid email!')
    .trim('The field cannot contain only spaces.')
    .required('Email field is required!'),
  Password: Yup.string()
    .min(6, 'Your password is too short, min length is 6 characters!')
    .max(50, 'Your password is too long, max length is 50 characters!')
    .trim('The field cannot contain only spaces.')
    .required('Password field is required!'),
  ConfirmPassword: Yup.string()
    .required('Confirm Password field is required!')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this?.parent?.Password === value;
    })
});

export default SignUpSchema;
