import styled from 'styled-components';

const StatusType = styled.div`
  display: block;
  position: relative;
  padding-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #2f3d48;

  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-5px);
    left: 0;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &.delivered {
    &::before {
      background-color: #17c981;
    }
  }
  &.cancelled {
    &::before {
      background-color: #ff2d38;
    }
  }
  &.delivery-failed {
    &::before {
      background-color: #ff2d38;
    }
  }
  &.scheduled {
    &::before {
      background-color: #ff8412;
    }
  }
  &.assigned {
    &::before {
      background-color: #929cb9;
    }
  }
  &.pickup-enroute {
    &::before {
      background-color: #929cb9;
    }
  }
  &.picked-up {
    &::before {
      background-color: #2f80ed;
    }
  }
  &.dropoff-enroute {
    &::before {
      background-color: #2f80ed;
    }
  }
`;

export default StatusType;
