import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import {DELETE_USER} from '../../../../store/usersManagement/action';
import {DialogAction, DialogBody} from '../../dashboard/styles';
import {ButtonOutline} from '../../../../components/buttons';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';

const DeleteUserModalConfirm = ({open, onClose, modalModel}) => {
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(DELETE_USER(modalModel));

    onClose();
  };

  return (
    <Dialog open={open} scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle>
          Are you sure you want <br /> to remove this user?
        </DialogTitle>
        <DialogBody>
          <DialogAction className='marginTop50'>
            <ButtonOutline onClick={onClose} className='marginRightSm'>
              <span>Cancel</span>
            </ButtonOutline>
            <ButtonPrimary onClick={handleSubmit}>
              <span>Remove</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DeleteUserModalConfirm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalModel: PropTypes.object
};

DeleteUserModalConfirm.defaultProps = {
  open: false,
  onClose: () => {},
  modalModel: {}
};

export default DeleteUserModalConfirm;
