/* eslint-disable */
import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogAction,
  DialogBody,
  DialogField,
  FieldLabel
} from '../dashboard/styles';
import FieldInput from '../../../components/FieldInput';
import ButtonPrimary from '../../../components/buttons/ButtonPrimary/styles';
import {
  SHOW_EMAIL_CHECK_MODAL,
  UPDATE_EMAIL
} from '../../../store/global/modals/action';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../components/errorSign/errorSign';
import {EMAIL_REGEXP} from '../../../helpers/services/constants/RegExpConstants';
import DialogContainer from '../../../components/DialogContainer/styles';
import DialogClose from '../../../components/DialogClose/styles';
import DialogTitle from '../../../components/DialogTitle/styles';

class EmailCheckModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: {
        value: null,
        error: null
      }
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      this.props.userData !== nextProps.userData &&
      !nextProps.userData.isValidEmail
    ) {
      this.props.SHOW_EMAIL_CHECK_MODAL(true);

      return false;
    }

    return true;
  }

  componentDidMount() {
    if (!this.props.userData.isValidEmail) {
      this.props.SHOW_EMAIL_CHECK_MODAL(true);
    }
  }

  onModalClose = () => {
    this.props.SHOW_EMAIL_CHECK_MODAL(false);
  };

  onEmailFieldChange = (e) => {
    this.setState({
      email: {
        value: e.target.value,
        error: null
      }
    });
  };

  onSubmitEmail = () => {
    const {email} = this.state;

    if (!EMAIL_REGEXP.test(String(email.value).toLowerCase())) {
      this.setState({
        email: {
          value: email.value,
          error: 'Email is not valid'
        }
      });
    } else {
      this.setState(
        {
          email: {
            value: null,
            error: null
          }
        },
        () => {
          this.props.UPDATE_EMAIL({email: email.value});
        }
      );
    }
  };

  render() {
    const {isOpen} = this.props;
    const {email} = this.state;

    if (!isOpen) {
      return null;
    }

    return (
      <Dialog
        open={this.props.isOpen}
        scroll='body'
        onClose={this.onModalClose}
      >
        <DialogContainer className='DialogSm'>
          <DialogClose onClick={this.onModalClose} />
          <DialogTitle className='DialogTitleOrange'>
            Please, enter you email:
          </DialogTitle>
          <DialogBody>
            <DialogField className='w50'>
              <FieldLabel className='FieldLabelLg'>Email: </FieldLabel>
              <FieldInput
                name='email'
                value={email.value}
                onChange={this.onEmailFieldChange}
                className={cn({error: !!email.error})}
                placeholder='Write you email here'
              />
              <ErrorSignWrapper>
                {!!email.error && <ErrorSign>{email.error}</ErrorSign>}
              </ErrorSignWrapper>
            </DialogField>

            <DialogAction>
              <ButtonPrimary onClick={this.onSubmitEmail}>
                <span>SAVE</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogBody>
        </DialogContainer>
      </Dialog>
    );
  }
}

export default connect(
  (store) => ({
    isOpen: store.modalsReducer.isEmailCheckModalOpen,
    userData: store.user.userData
  }),
  {
    SHOW_EMAIL_CHECK_MODAL,
    UPDATE_EMAIL
  }
)(EmailCheckModal);
