import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {Caption2} from '../../../../../../../components/typography';
import FieldInput from '../../../../../../../components/FieldInput';
import RadioInputsWrapper from './styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../../components/errorSign/errorSign';

const numberFormatter = (value) => {
  let res = value;
  if (value.length > 1 && value[0] === '0') {
    res = res[1];
  }
  res =
    res.indexOf('.') >= 0
      ? res.substr(0, res.indexOf('.')) + res.substr(res.indexOf('.'), 3)
      : res;
  if (res > 99) {
    res = res.slice(0, -1);
  }
  return res ? Number(res) : res;
};

const UberPricesChangeControl = ({values, setFieldValue, errors, touched}) => {
  let inputValueSource;
  let inflationTypeValue;
  let inflationTypeField;

  if (values?.data?.MenuShowUrlInput) {
    inputValueSource =
      values?.data?.inflationTypeURL === '%'
        ? 'inflationInputPercentageURL'
        : 'inflationInputAmountURL';
    inflationTypeValue = values.data.inflationTypeURL;
    inflationTypeField = 'data.inflationTypeURL';
  } else if (values?.data?.MenuEmail) {
    inputValueSource =
      values?.data?.inflationTypeEmail === '%'
        ? 'inflationInputPercentageEmail'
        : 'inflationInputAmountEmail';
    inflationTypeValue = values.data.inflationTypeEmail;
    inflationTypeField = 'data.inflationTypeEmail';
  } else {
    inputValueSource =
      values?.data?.inflationType === '%'
        ? 'inflationInputPercentage'
        : 'inflationInputAmount';
    inflationTypeValue = values.data.inflationType;
    inflationTypeField = 'data.inflationType';
  }

  return (
    <RadioInputsWrapper>
      <Caption2 className='captionRadioPrices'>
        My existing {values?.data?.MenuUberEats ? 'UberEats' : ''} prices were
        inflated by
      </Caption2>
      <div className='inputsWrapper'>
        <div style={{position: 'relative'}}>
          <FieldInput
            type='number'
            value={values.data[inputValueSource]}
            onChange={(event) => {
              setFieldValue(
                `data.${inputValueSource}`,
                numberFormatter(event.target.value)
              );
            }}
            // name={`data.${inputValueSource}`}
            className={cn('inputAmount', {
              error:
                errors?.data &&
                errors?.data[inputValueSource] &&
                touched?.data &&
                touched?.data[inputValueSource]
            })}
            // className='inputAmount'
          />
          <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
            {errors?.data && errors?.data[inputValueSource] ? (
              <ErrorSign>{errors?.data[inputValueSource]}</ErrorSign>
            ) : null}
          </ErrorSignWrapper>
        </div>

        <RadioGroup
          row
          aria-labelledby='demo-row-radio-buttons-group-label'
          name='row-radio-buttons-group'
          value={inflationTypeValue}
          onChange={(event) => {
            setFieldValue(inflationTypeField, event.target.value, false);
          }}
        >
          <FormControlLabel
            className='Say2EatRadio'
            value='%'
            control={
              <Radio
                labelStyle={{color: '#172751'}}
                iconStyle={{fill: '#FF2D38'}}
                inputStyle={{color: '#FF2D38'}}
                style={{color: '#FF2D38'}}
              />
            }
            label='%'
          />
          <FormControlLabel
            className='Say2EatRadio'
            value='$'
            control={
              <Radio
                labelStyle={{color: '#172751'}}
                iconStyle={{fill: '#FF2D38'}}
                inputStyle={{color: '#FF2D38'}}
                style={{color: '#FF2D38'}}
              />
            }
            label='$'
          />
        </RadioGroup>
      </div>
    </RadioInputsWrapper>
  );
};

UberPricesChangeControl.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired
};

export default UberPricesChangeControl;
