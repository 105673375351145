import * as Yup from 'yup';
import {
  URL_REGEXP,
  VALID_DOUBLE_REGEXP,
  STRING_REGEXP
} from '../constants/RegExpConstants';

const phoneRegex = /^([+]1)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;

export const generalInfoSchema = Yup.object()
  .shape({
    User: Yup.object({
      FirstName: Yup.string()
        .required('This field is required!')
        .matches(new RegExp(STRING_REGEXP), 'English characters only')
        .trim('The field cannot contain only spaces.'),
      LastName: Yup.string()
        .required('This field is required!')
        .matches(new RegExp(STRING_REGEXP), 'English characters only')
        .trim('The field cannot contain only spaces.'),
      PhoneCountryCode: Yup.string()
        .required('This field is required!')
        .trim('The field cannot contain only spaces.'),
      UserPhoneNumber: Yup.string()
        .required('This field is required!')
        .matches(phoneRegex, 'Incorrect phone format')
        .trim('The field cannot contain only spaces.')
    }),
    Merchant: Yup.object({
      MerchantName: Yup.string()
        .required('This field is required!')
        .matches(new RegExp(STRING_REGEXP), 'English characters only')
        .trim('The field cannot contain only spaces.'),
      RestaurantURL: Yup.string().trim('The field cannot contain only spaces.')
      //  .url('Please enter a valid URL!')
      // .matches(new RegExp(URL_REGEXP), 'Please enter a valid URL!')
    }),
    DeliveryInstructions: Yup.string()
      .matches(new RegExp(STRING_REGEXP), 'English characters only')
      .trim('The field cannot contain only spaces.'),
    CuisineType: Yup.string().required('Select a Cuisine Type'),
    SalesTaxRate: Yup.string()
      .required('This field is required!')
      .typeError('Please enter a number value')
      //    .matches(VALID_DOUBLE_REGEXP, 'Please enter a valid value')
      .test(
        'is_SalesTaxRate',
        'Please enter a value between 1 and 100',
        function (value) {
          return (value >= 1 && value <= 100) || value === undefined;
        }
      ),
    FullAddress: Yup.string()
      .required('This field is required!')
      .trim('The field cannot contain only spaces.'),
    City: Yup.string()
      .required(
        'Your address does not have any city specified. Please select another address or choose address with city specified!'
      )
      .trim('The field cannot contain only spaces.'),
    Country: Yup.string()
      .required('This field is required!')
      .trim('The field cannot contain only spaces.'),
    State: Yup.string().trim('The field cannot contain only spaces.'),
    CountryCode: Yup.string()
      .required('This field is required!')
      .trim('The field cannot contain only spaces.'),
    Phone: Yup.string()
      .required('This field is required!')
      .matches(phoneRegex, 'Incorrect phone format')
      .trim('The field cannot contain only spaces.'),
    ZipCode: Yup.string().trim('The field cannot contain only spaces.')
  })
  .test(
    'global-ok',
    'Please choose the suggested address from the dropdown or select it on the map!',
    function (value) {
      if (value.FullAddress && !value.Address) {
        return this.createError({
          path: 'FullAddress',
          message: 'Please choose the suggested address from the dropdown!'
        });
      }
      return true;
    }
  );

export const bookCallWithUsSchema = Yup.object().shape({
  FirstName: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  LastName: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  email: Yup.string()
    .email('Invalid email!')
    .required(
      'This field is required!'.trim('The field cannot contain only spaces.')
    ),
  CountryCode: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  RestaurantURL: Yup.string().trim('The field cannot contain only spaces.'),
  // .matches(new RegExp(URL_REGEXP), 'Please enter a valid URL!'),
  Phone: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  role: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.')
});

export const bookCallSchema = Yup.object().shape({
  CountryCode: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  Phone: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.')
});

export const disableSchema = Yup.object().shape({
  reasonValue: Yup.string()
    .required('This field is required!')
    .trim('The field cannot contain only spaces.'),
  selectValue: Yup.string().when('radioValue', (radioValue) => {
    if (radioValue === 'date-and-hour') {
      return Yup.string().required('This field is required!');
    }
    return Yup.string();
  }),
  selectTimeValue: Yup.string().when('radioValue', (radioValue) => {
    if (radioValue === 'custom-dates-request') {
      return Yup.string().required('This field is required!');
    }
    return Yup.string();
  }),
  selectDisableTimeValue: Yup.string().when('radioValue', (radioValue) => {
    if (radioValue === 'custom-dates-request') {
      return Yup.string().required('This field is required!');
    }
    return Yup.string();
  }),
  reasonDescription: Yup.string().when('reasonValue', (reasonValue) => {
    if (reasonValue === 'Other') {
      return Yup.string()
        .required('This field is required!')
        .trim('The field cannot contain only spaces.');
    }
    return Yup.string();
  })
});
