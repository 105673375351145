import React from 'react';
import {isMobile} from 'react-device-detect';
import PropTypes from 'prop-types';
import {SidebarMenuButton} from './styles';

const ClosableSidebarButton = ({
  //  isActive,
  closeSidebar,
  activeClassName,
  to,
  children
}) => {
  return (
    <SidebarMenuButton
      //      isActive={isActive}
      activeClassName={activeClassName}
      to={to}
      onClick={() => {
        if (isMobile) {
          closeSidebar();
        }
      }}
    >
      {children}
    </SidebarMenuButton>
  );
};

ClosableSidebarButton.propTypes = {
  //  isActive: PropTypes.func,
  closeSidebar: PropTypes.func.isRequired,
  activeClassName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default ClosableSidebarButton;
