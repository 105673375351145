import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import moment from 'moment/moment';
import {Dialog} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {RedBannerUnderlineText, RedBannerWrapper} from './styles';
import {IconUnPublish} from '../../assets/IconUnPublish';
import Body3 from '../typography/Body3';
import DialogContainer from '../DialogContainer/styles';
import DialogClose from '../DialogClose/styles';
import DialogTitle from '../DialogTitle/styles';
import {DialogBody} from '../../routes/App/dashboard/styles';
import DialogActions from '../DialogActions/styles';
import DialogAction from '../DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from '../buttons';
import {
  PUBLISH_LOCATION,
  SET_STORE_HOLIDAYS,
  UPDATE_LOCATION_SETTINGS_DATA
} from '../../store/locationSettings/action';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  SHOW_UNPUBLISH_BANNER,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../store/global/globalNotifications/action';
import Modal from '../Modals/Modal';
import {UberEatsImage} from '../../routes/App/uberEats/styles';
import AnimateLocationPickup from '../../assets/animate-pizza-red.gif';

const RedBannerHolidays = ({startDateHolidays, endDateHolidays}) => {
  const {pathname} = useLocation();
  const formattedDate = (dateObj) => {
    return moment()
      .utc()
      .set({
        year: dateObj?.Year,
        month: dateObj?.Month - 1,
        date: dateObj?.Day,
        hour: dateObj?.Time?.Hour,
        minute: dateObj?.Time?.Minute
      });
  };

  const [showCancelHolidaysModal, setShowCancelHolidaysModal] = useState(false);

  const dispatch = useDispatch();

  const publishLocation = () => {
    dispatch(
      PUBLISH_LOCATION({
        preCallback: () => {
          dispatch(SHOW_UNPUBLISH_BANNER({showBanner: false, types: []}));
          dispatch(SHOW_PUBLISH_PROGRESSBAR(true));
        },
        postCallBack: () => {},
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };
  const cancelHolidaysStore = () => {
    const dataCancelHolidays = {
      storeHolidays: {
        isCCAgent: '',
        enableStoreTime: '',
        disableStoreTime: ''
      }
    };
    dispatch(SET_STORE_HOLIDAYS(dataCancelHolidays));
    setShowCancelHolidaysModal(false);

    dispatch(
      UPDATE_LOCATION_SETTINGS_DATA({
        payload: dataCancelHolidays,
        successCallback: () => {
          publishLocation();
        },
        errorCallback: () => {
          dispatch(STOP_GET_PUBLISH_STATUS_JOB());
          dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
        }
      })
    );
  };

  return (
    <>
      <RedBannerWrapper className='RedBannerHolidays'>
        <IconUnPublish />
        <Body3 className='textRedBanner White'>
          The store will not receive orders between{' '}
          {formattedDate(startDateHolidays).format('MM/DD/YYYY [at] h:mm A')}{' '}
          until{' '}
          {formattedDate(endDateHolidays).format('MM/DD/YYYY [at] h:mm A')}
          {pathname !== '/store-settings' && (
            <RedBannerUnderlineText
              onClick={() => setShowCancelHolidaysModal(true)}
            >
              Click to cancel
            </RedBannerUnderlineText>
          )}
        </Body3>
      </RedBannerWrapper>
      <>
        {showCancelHolidaysModal && (
          <Modal
            onCloseAction={() => setShowCancelHolidaysModal(false)}
            title={
              <DialogTitle className='marginBottom2l'>
                Cancel future dates limitation?
              </DialogTitle>
            }
            width='715px'
            body={
              <>
                <UberEatsImage src={AnimateLocationPickup} />
                <DialogActions>
                  <DialogAction className='w50'>
                    <ButtonOutline
                      onClick={() => setShowCancelHolidaysModal(false)}
                    >
                      <span>No</span>
                    </ButtonOutline>
                  </DialogAction>
                  <DialogAction className='w50'>
                    <ButtonPrimary
                      onClick={() => {
                        cancelHolidaysStore();
                      }}
                    >
                      <span>Yes</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogActions>
              </>
            }
          />
        )}
      </>
    </>
  );
};

RedBannerHolidays.propTypes = {
  startDateHolidays: PropTypes.object.isRequired,
  endDateHolidays: PropTypes.object.isRequired
};

RedBannerHolidays.defaultProps = {};

export default RedBannerHolidays;
