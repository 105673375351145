import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import {RadioGroup} from '@material-ui/core';
import {
  DialogAction,
  DialogBody,
  DialogField,
  DialogSubTitle,
  FieldInput,
  FieldLabel
} from '../../dashboard/styles';
import DialogFields from '../../../../components/DialogFields/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';
import RadioS2E from '../../../../components/Radiobox';
import {HintText} from '../../../../components/HintText/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {adminUsersData} from '../../../../store/adminUsers/selectors';
import LocationSelect from '../../../../components/SelectField/LocationSelect';
import AutocompleteField from '../../../../components/AutocompleteField/AutocompleteField';
import {
  CLEAR_LOCATIONS,
  CLEAR_USER,
  GET_LOCATIONS,
  GET_MERCHANTS,
  GET_USERS
} from '../../../../store/adminUsers/actions';
import {UPDATE_USER} from '../../../../store/usersManagement/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import UserManageSchema from '../../../../helpers/services/formValidationSchemas/UserManageSchema';

const ManageUser = ({onClose}) => {
  const dispatch = useDispatch();
  const {
    selectedRecord,
    locations,
    merchants,
    paging: {page},
    search,
    sort
  } = useSelector(adminUsersData);

  useEffect(() => {
    dispatch(GET_MERCHANTS());
    dispatch(GET_LOCATIONS(selectedRecord.merchant_id));
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: UserManageSchema,
    onSubmit: (values) => {
      const {merchant, Merchant, ...rest} = values;
      const serverData = {
        ...rest,
        merchant_name: values.merchant.ConfigName,
        merchant_id: values.merchant.id
      };
      dispatch(
        UPDATE_USER({
          ...serverData,
          callBack: () => {
            dispatch(CLEAR_USER());
            dispatch(GET_USERS({page, search, sort}));
          }
        })
      );
    },
    initialValues: {
      ...selectedRecord,
      locations_whitelist: selectedRecord?.locations_whitelist
        ? selectedRecord?.locations_whitelist.map((i) => i._id)
        : [],
      merchant:
        {
          ConfigName: selectedRecord.merchant_name,
          MerchantName: selectedRecord.merchant_name,
          id: selectedRecord.merchant_id
        } || {}
    }
  });

  const onAutocompleteChange = (e, value) => {
    formik.setFieldValue('merchant', value);
    if (value) {
      dispatch(GET_LOCATIONS(value?.id));
    } else {
      dispatch(CLEAR_LOCATIONS());
    }
  };

  return (
    <Dialog open scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle className='marginBottomLg'>
          {selectedRecord._id ? 'Edit' : 'Add New'} <span>User</span>
        </DialogTitle>
        <DialogSubTitle className='textMedium'>
          {selectedRecord._id
            ? 'Edit user and set permissions'
            : 'Add a new user to your team and set their permissions'}
        </DialogSubTitle>
        <DialogBody>
          <DialogFields>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>Email</FieldLabel>
              <FieldInput
                name='email'
                onChange={formik.handleChange}
                value={formik.values.email}
                className={cn({
                  error: formik.errors.email && formik.touched.email
                })}
              />
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.email && formik.errors.email}
                </ErrorSign>
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='DialogField w100'>
              <FieldLabel className='inline-label'>First Name</FieldLabel>
              <FieldInput
                name='first_name'
                onChange={formik.handleChange}
                value={formik.values.first_name}
              />
            </DialogField>
            <DialogField className='DialogField w100 '>
              <FieldLabel className='inline-label'>Last Name</FieldLabel>
              <FieldInput
                name='last_name'
                onChange={formik.handleChange}
                value={formik.values.last_name}
              />
            </DialogField>
            <DialogField className='w100 Say2EatSelect'>
              <FieldLabel className='inline-label'>
                Select A Merchant
              </FieldLabel>
              <AutocompleteField
                name='merchant'
                value={formik.values.merchant}
                options={merchants}
                optionLabelName='ConfigName'
                onChange={(e, value) => onAutocompleteChange(e, value)}
                className={cn({
                  error: formik?.errors?.merchant && formik?.touched?.merchant
                })}
              />
              <ErrorSignWrapper>
                {formik?.errors?.merchant && formik?.touched?.merchant ? (
                  <ErrorSign>{formik?.errors?.merchant}</ErrorSign>
                ) : null}
              </ErrorSignWrapper>
            </DialogField>
            <DialogField className='w100 Say2EatSelect marginBottomXs'>
              <FieldLabel className='inline-label'>
                Location whitelist
              </FieldLabel>
              <LocationSelect
                multiple
                name='locations_whitelist'
                value={formik.values.locations_whitelist}
                onChange={formik.handleChange}
                optionLabelName='FullName'
                menuItems={locations}
              />
            </DialogField>

            <DialogField>
              <FieldLabel className='FieldLabelLg w100'>Select role</FieldLabel>
              <ErrorSignWrapper>
                <ErrorSign>
                  {formik.touched.role && formik.errors.role}
                </ErrorSign>
              </ErrorSignWrapper>
              <DialogFields>
                <RadioGroup
                  aria-label='role'
                  name='role'
                  value={formik.values.role}
                  onChange={formik.handleChange}
                >
                  <DialogField className='w100 SelectRole'>
                    <RadioS2E
                      name='role'
                      value='admin'
                      label='Admin'
                      onClick={formik.handleChange}
                      checked={formik.values.role === 'admin'}
                    />
                    <HintText className='withoutIcon'>
                      <span>
                        Add and edit users, and do anything a Manager can do
                      </span>
                    </HintText>
                  </DialogField>
                  <DialogField className='w100 SelectRole'>
                    <RadioS2E
                      name='role'
                      value='manager'
                      label='Manager'
                      onClick={formik.handleChange}
                      checked={formik.values.role === 'manager'}
                    />
                    <HintText className='withoutIcon'>
                      <span>
                        Access Reports, Orders, Financials, Analytics, and
                        Passwords
                      </span>
                    </HintText>
                  </DialogField>
                  <DialogField className='w100 SelectRole'>
                    <RadioS2E
                      name='role'
                      value='accounting'
                      label='Accountant'
                      onClick={formik.handleChange}
                      checked={formik.values.role === 'accounting'}
                    />
                    <HintText className='withoutIcon'>
                      <span>Access Orders, Financials, and Analytics</span>
                    </HintText>
                  </DialogField>
                  <DialogField className='w100 SelectRole'>
                    <RadioS2E
                      name='role'
                      value='employee'
                      label='Employee'
                      onClick={formik.handleChange}
                      checked={formik.values.role === 'employee'}
                    />
                    <HintText className='withoutIcon'>
                      <span>Access Orders and get notified of new orders</span>
                    </HintText>
                  </DialogField>
                </RadioGroup>
              </DialogFields>
            </DialogField>
          </DialogFields>
          <DialogAction>
            <ButtonPrimary onClick={formik.submitForm}>
              <span>{selectedRecord._id ? 'Edit User' : 'Add New User'}</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

ManageUser.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ManageUser;
