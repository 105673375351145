import React from 'react';

// eslint-disable-next-line react/prop-types
export const IconCategory = ({customAttribute, value}) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...{[customAttribute]: value}}
  >
    <path
      {...{[customAttribute]: value}}
      d='M27.1023 17.2931L27.1023 14.7082L16.8544 14.7082L16.8544 4.4604H14.2696L14.2696 14.7082L4.02177 14.7082L4.02177 17.2931L14.2696 17.2931L14.2696 27.5409H16.8544L16.8544 17.2931L27.1023 17.2931Z'
      fill='white'
    />
  </svg>
);

export default IconCategory;
