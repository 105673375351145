import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {ButtonPrimary, ButtonOutline} from '../../../../../components/buttons';
import DialogAction from '../../../../../components/DialogAction/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import DialogBody from '../../../../../components/DialogBody/styles';
import DialogContainer from '../../../../../components/DialogContainer/styles';
import {HANDLE_DISCONNECT_MODAL} from '../../../../../store/uberEats/actions';
import DialogActions from '../../../../../components/DialogActions/styles';
import Body4 from '../../../../../components/typography/Body4';

const DisconnectModal = () => {
  const dispatch = useDispatch();
  const disconnectModalOpen = useSelector(
    (state) => state.uberEatsReducer.disconnectModalOpen
  );

  const onClose = () => {
    dispatch(HANDLE_DISCONNECT_MODAL(0));
  };

  return (
    <Dialog open={disconnectModalOpen === 1} scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogBody>
          <DialogTitle>
            Are you sure you want to disconnect UberEats from Sauce?
          </DialogTitle>
          <DialogBody>
            <Body4 className='marginTopXl' textAlign='center'>
              You will no longer be able to update menus on all platforms, view
              orders in one place and reach new customers.
            </Body4>
          </DialogBody>
          <DialogActions className='justifyCenter'>
            <DialogAction className='w50'>
              <ButtonOutline className='ButtonLg' onClick={() => onClose()}>
                Back
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                data-gtm='disconnectButtonPopUp'
                onClick={() => {
                  dispatch(HANDLE_DISCONNECT_MODAL(2));
                }}
              >
                <span data-gtm='disconnectButtonPopUp'>Disconnect</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default DisconnectModal;
