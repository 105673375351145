import {reverseString} from './reverseString';
// eslint-disable-next-line import/prefer-default-export
export const groupDigital = (num) => {
  const emptyStr = '';
  const group_regex = /\d{3}/g;

  // delete extra comma by regex replace.
  const trimComma = (str) => str.replace(/^[,]+|[,]+$/g, emptyStr);

  const str = num + emptyStr;
  const [integer, decimal] = str.split('.');

  const conversed = reverseString(integer);

  const grouped = trimComma(
    reverseString(conversed.replace(/\d{3}/g, (match) => `${match},`))
  );

  return !decimal ? grouped : `${grouped}.${decimal}`;
};
