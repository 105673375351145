import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import {FocusError} from 'focus-formik-error';
import {RadioGroup} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {
  ButtonOutline,
  ButtonPrimary
} from '../../../../../../../components/buttons';
import {DashboardField, DashboardFields} from '../../../../../dashboard/styles';
import RadioS2E from '../../../../../../../components/Radiobox';
import {
  stepData,
  WelcomeModalData
} from '../../../../../../../store/selfCreateAccount/selectors';
import {
  CONTROL_MODALS,
  CONTROL_SIDEBAR_PROGRESS,
  POST_STEP,
  SAVE_STEP_DATA
} from '../../../../../../../store/selfCreateAccount/action';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../../components/errorSign/errorSign';
import {
  SetupActions,
  SetupContainer,
  SetupFieldset,
  SetupHeader,
  SetupLogo
} from '../../../../../../Auth/registration/signUpNew/style';
import Subtitle0 from '../../../../../../../components/typography/Subtitle0';
import Caption4 from '../../../../../../../components/typography/Caption4';
import CheckboxContainer from '../../../../../../../components/Checkbox/styles';
import FieldInput from '../../../../../../../components/FieldInput';
import SauceLogoRed from '../../../../../../../assets/logo-sauce-red.svg';
import Subtitle5 from '../../../../../../../components/typography/Subtitle5';
import CheckboxS2E from '../../../../../../../components/Checkbox';
import WelcomeModal from '../../../../modals/WelcomeModal';
import MobileProgressBar from '../../../Components/MobileProgressBar';

const FreeTrial = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const {
    step1: {deliveryTypeUsage, aspectsToImprove, other},
    UserId,
    pendingDataSetuped
  } = stepData();

  useEffect(() => {
    dispatch(
      CONTROL_SIDEBAR_PROGRESS(
        pendingDataSetuped ? 'allDone' : 'secondStepDone'
      )
    );
  }, [pendingDataSetuped]);

  const isWelcomeModalOpen = WelcomeModalData();
  const validate = (values) => {
    const errors = {};

    if (values.deliveryTypeUsage === '') {
      errors.deliveryTypeUsage = 'Please choose one of the options provided';
    }

    if (isOtherChecked && values.other === '') {
      errors.other =
        'You cannot leave this field blank. Please fill it or uncheck!';
    }

    if (!values.other && !values.aspectsToImprove.length) {
      errors.aspectsToImprove = 'Please choose one of the options provided';
    }

    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryTypeUsage: deliveryTypeUsage || '',
      aspectsToImprove: aspectsToImprove || [],
      other: other || ''
    },
    validate,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const {data, ...info} = {
        userId: UserId || '',
        step: 1,
        data: {
          step: 1,
          userId: UserId || '',
          ...values
        }
      };
      dispatch(SAVE_STEP_DATA({data, ...info}));
      dispatch(
        POST_STEP({
          payload: info,
          successCallback: () => {
            dispatch(CONTROL_MODALS({open: true, field: 'isWelcomeModalOpen'}));
            dispatch(CONTROL_SIDEBAR_PROGRESS('allDone'));
            setIsSubmitting(false);
          }
        })
      );
    }
  });

  const handleCheckboxChange = (e) => {
    const {checked, name} = e.target;
    if (checked) {
      formik.setFieldValue('aspectsToImprove', [
        ...formik.values.aspectsToImprove,
        name
      ]);
    } else {
      formik.setFieldValue(
        'aspectsToImprove',
        formik.values.aspectsToImprove.filter((v) => v !== name)
      );
    }
  };

  const onBack = () => {
    history.push('/my-account/self-setup/general-info');
    dispatch(CONTROL_SIDEBAR_PROGRESS('firstStepDone'));
  };

  return (
    <SetupContainer className='SecondStep'>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <SetupHeader>
          <SetupLogo src={SauceLogoRed} />
          <Subtitle5>
            <span>Setup</span> your restaurant
          </Subtitle5>
        </SetupHeader>
        <MobileProgressBar />
        <Subtitle0>Start your free trial!</Subtitle0>
        <SetupFieldset>
          <Caption4 className='marginBottomXs positionRelative'>
            Do you use your own delivery people or use a third party delivery
            service?
            <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
              {formik.errors?.deliveryTypeUsage &&
              formik.touched?.deliveryTypeUsage ? (
                <ErrorSign>{formik.errors?.deliveryTypeUsage}</ErrorSign>
              ) : null}
            </ErrorSignWrapper>
          </Caption4>
          <RadioGroup>
            <DashboardFields>
              <DashboardField className='w100'>
                <RadioS2E
                  type='radio'
                  value='myOwnDeliveryPeople'
                  checked={
                    formik.values.deliveryTypeUsage === 'myOwnDeliveryPeople'
                  }
                  name='deliveryTypeUsage'
                  label='Only my own delivery people'
                  onChange={formik.handleChange}
                />
              </DashboardField>
              <DashboardField className='w100'>
                <RadioS2E
                  type='radio'
                  value='3rdPartyDeliveryServices'
                  checked={
                    formik.values.deliveryTypeUsage ===
                    '3rdPartyDeliveryServices'
                  }
                  name='deliveryTypeUsage'
                  label='Only 3rd-party delivery services'
                  onChange={formik.handleChange}
                />
              </DashboardField>
              <DashboardField className='w100'>
                <RadioS2E
                  type='radio'
                  value='3rdPartyAndOwnDeliveryPeople'
                  checked={
                    formik.values.deliveryTypeUsage ===
                    '3rdPartyAndOwnDeliveryPeople'
                  }
                  name='deliveryTypeUsage'
                  label='Both my own and 3rd-party’s services'
                  onChange={formik.handleChange}
                />
              </DashboardField>
              <DashboardField className='w100'>
                <RadioS2E
                  type='radio'
                  value='NoDeliveries'
                  checked={formik.values.deliveryTypeUsage === 'NoDeliveries'}
                  name='deliveryTypeUsage'
                  label='I don’t offer deliveries, at the moment'
                  onChange={formik.handleChange}
                />
              </DashboardField>
            </DashboardFields>
          </RadioGroup>
        </SetupFieldset>
        <SetupFieldset>
          <Caption4 className='marginBottomXs positionRelative'>
            Do you use your own delivery people or use a third party delivery
            service?
            <ErrorSignWrapper leftPosition='0px' topPosition='0px'>
              {formik.errors?.aspectsToImprove &&
              formik.touched?.aspectsToImprove ? (
                <ErrorSign>{formik.errors?.aspectsToImprove}</ErrorSign>
              ) : null}
            </ErrorSignWrapper>
          </Caption4>
          <DashboardFields>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Cut delivery costs'
                  label='Cut delivery costs'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Cut delivery costs'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Convert customers from 3rd parties to ordering direct'
                  label='Convert customers from 3rd parties to ordering direct'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Convert customers from 3rd parties to ordering direct'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Improve digital guest experience'
                  label='Improve digital guest experience'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Improve digital guest experience'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Start taking orders through social media'
                  label='Start taking orders through social media'
                  checked={formik.values.aspectsToImprove.includes(
                    'Start taking orders through social media'
                  )}
                  onChange={handleCheckboxChange}
                  variant='green'
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Improve your Google search ranking'
                  label='Improve your Google search ranking'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Improve your Google search ranking'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Increase repeat ordering'
                  label='Increase repeat ordering'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Increase repeat ordering'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <CheckboxContainer>
                <CheckboxS2E
                  name='Collect feedback from your customers'
                  label='Collect feedback from your customers'
                  variant='green'
                  checked={formik.values.aspectsToImprove.includes(
                    'Collect feedback from your customers'
                  )}
                  onChange={handleCheckboxChange}
                />
              </CheckboxContainer>
            </DashboardField>
            <DashboardField className='w100'>
              <DashboardFields className='AlignCenter'>
                <DashboardField className='wAuto'>
                  <CheckboxContainer>
                    <CheckboxS2E
                      name='other'
                      checked={isOtherChecked}
                      label='Other'
                      onChange={() => {
                        setIsOtherChecked(!isOtherChecked);
                        formik.setFieldError('other', '');
                        formik.setFieldValue('other', '');
                      }}
                      variant='green'
                    />
                  </CheckboxContainer>
                </DashboardField>
                <DashboardField className='wAutoSpace'>
                  <FieldInput
                    type='text'
                    name='other'
                    value={isOtherChecked ? formik.values.other : ''}
                    onChange={formik.handleChange}
                    className={cn({
                      isDisabled: !isOtherChecked,
                      error: formik.errors?.other && formik.touched?.other
                    })}
                  />
                  <ErrorSignWrapper>
                    {formik.errors?.other && formik.touched?.other ? (
                      <ErrorSign>{formik.errors.other}</ErrorSign>
                    ) : null}
                  </ErrorSignWrapper>
                </DashboardField>
              </DashboardFields>
            </DashboardField>
          </DashboardFields>
        </SetupFieldset>
        <SetupActions className='SecondStep'>
          <ButtonOutline
            className={cn('ButtonOutline', {
              disabled: isSubmitting
            })}
            onClick={onBack}
          >
            Back
          </ButtonOutline>
          <ButtonPrimary
            type='submit'
            className={cn('ButtonPrimary', {
              disabled: isSubmitting
            })}
          >
            <span>Finish</span>
          </ButtonPrimary>
        </SetupActions>
      </form>
      {isWelcomeModalOpen && <WelcomeModal openModalAction={CONTROL_MODALS} />}
    </SetupContainer>
  );
};

export default FreeTrial;
