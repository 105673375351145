import styled from 'styled-components';

const Body0 = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: #232d3a;

  &.Medium {
    font-weight: 500;
  }

  &.Green {
    color: #17c981;
  }
  &.Red {
    color: #ff2d38;
  }
  span.grey {
    color: #929cb9;
  }

  span.breakLine {
    display: block;
  }
`;

export default Body0;
