import React from 'react';
import T from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Input,
  MenuItem,
  Select
} from '@material-ui/core';
import SelectFieldWrapper from './style';
import {Body2} from '../typography';

const SelectField = ({
  value,
  onChange,
  menuItemsTypes,
  menuItemsAll,
  menuItemsChannel,
  menuItemsMisc,
  menuItemsPaymentMethod,
  input,
  className,
  optionLabelName,
  emptyLabel,
  ...props
}) => {
  return (
    <SelectFieldWrapper>
      <Select
        className={className}
        value={value}
        onChange={onChange}
        input={input || <Input />}
        renderValue={(selected) => selected.join(', ')}
        {...props}
      >
        {!menuItemsChannel.length +
          !menuItemsTypes.length +
          !menuItemsMisc.length +
          !menuItemsAll.length +
          !menuItemsPaymentMethod.length && (
          <MenuItem disabled>{emptyLabel || 'No locations here!'} </MenuItem>
        )}
        <Body2 className='SelectItemCategory'>Order channel</Body2>
        {menuItemsChannel.map((item) => (
          <MenuItem
            key={item._id || item.id || item}
            value={item._id || item.id || item}
          >
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={
                    value.indexOf(item[optionLabelName]) > -1 ||
                    value.indexOf(item) > -1 ||
                    value.indexOf('All') > -1
                  }
                />
              }
            />
            {item[optionLabelName] || item}
          </MenuItem>
        ))}
        <Body2 className='SelectItemCategory'>Order type</Body2>
        {menuItemsTypes.map((item) => (
          <MenuItem
            key={item._id || item.id || item}
            value={item._id || item.id || item}
          >
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={
                    value.indexOf(item[optionLabelName]) > -1 ||
                    value.indexOf(item) > -1 ||
                    value.indexOf('All') > -1
                  }
                />
              }
            />
            {item[optionLabelName] || item}
          </MenuItem>
        ))}
        <Body2 className='SelectItemCategory'>Payment method</Body2>
        {menuItemsPaymentMethod.map((item) => (
          <MenuItem
            key={item._id || item.id || item}
            value={item._id || item.id || item}
          >
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={
                    value.indexOf(item[optionLabelName]) > -1 ||
                    value.indexOf(item) > -1 ||
                    value.indexOf('All') > -1
                  }
                />
              }
            />
            {item[optionLabelName] || item}
          </MenuItem>
        ))}
        <Body2 className='SelectItemCategory'>Misc</Body2>
        {menuItemsMisc.map((item) => (
          <MenuItem
            key={item._id || item.id || item}
            value={item._id || item.id || item}
          >
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={
                    value.indexOf(item[optionLabelName]) > -1 ||
                    value.indexOf(item) > -1 ||
                    value.indexOf('All') > -1
                  }
                />
              }
            />
            {item[optionLabelName] || item}
          </MenuItem>
        ))}
        <Body2 className='SelectItemCategory'>All</Body2>
        {menuItemsAll.map((item) => (
          <MenuItem
            key={item._id || item.id || item}
            value={item._id || item.id || item}
          >
            <FormControlLabel
              className='Say2EatCheckbox Say2EatCheckboxSoldOut'
              control={
                <Checkbox
                  name='Invisible'
                  labelStyle={{color: '#172751'}}
                  iconStyle={{fill: '#FF2D38'}}
                  inputStyle={{color: '#FF2D38'}}
                  style={{color: '#FF2D38'}}
                  checked={
                    value.indexOf(item[optionLabelName]) > -1 ||
                    value.indexOf(item) > -1 ||
                    value.indexOf('All') > -1
                  }
                />
              }
            />

            {item[optionLabelName] || item}
          </MenuItem>
        ))}
      </Select>
    </SelectFieldWrapper>
  );
};

SelectField.propTypes = {
  value: T.array,
  name: T.string,
  onChange: T.func.isRequired,
  menuItemsChannel: T.array.isRequired,
  menuItemsTypes: T.array.isRequired,
  menuItemsMisc: T.array.isRequired,
  menuItemsPaymentMethod: T.array.isRequired,
  menuItemsAll: T.array.isRequired,
  className: T.string,
  optionLabelName: T.string,
  input: T.oneOfType([T.elementType, T.element]),
  emptyLabel: T.string
};

SelectField.defaultProps = {
  value: [],
  name: '',
  className: '',
  input: null,
  optionLabelName: '',
  emptyLabel: ''
};

export default SelectField;
