import styled from 'styled-components';
import IconClear from '../../../assets/icon-close-red.svg';

const ButtonClear = styled.button`
  position: absolute;
  right: 17px;
  bottom: 29px;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  outline: none;
  background-image: url('${IconClear}');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  background-color: transparent;
  display: inline-block;
  transition-property: opacity;
  transition-duration: 0.3s;

  &:hover {
    opacity: 0.7;
  }
`;

export default ButtonClear;
