import {useSelector} from 'react-redux';

export const locationData = () =>
  useSelector((state) => state.locationSettingsReducer.data);

export const getLocationLoading = () =>
  useSelector((state) => state.locationSettingsReducer.loading);
export const locationId = () => useSelector((state) => state.user.locationId);

// export const leaveModal = () =>
//   useSelector((state) => state.locationSettingsReducer.isLeaveModalOpen);

export const leaveMessage = () =>
  useSelector((state) => state.locationSettingsReducer.isLeaveMessageOpen);

export const locations = () => useSelector((state) => state.user.locations);

export const callingCode = () =>
  useSelector((state) => {
    return {callingCode: state.locationSettingsReducer.callingCode};
  });

export const locationDataUpdated = () =>
  useSelector((state) => state.locationSettingsReducer.data?.updatedAt || null);

export const locationBotSync = () =>
  useSelector(
    (state) => state.locationSettingsReducer.data?.LastBotSync || null
  );

export const publishStatusSelector = () =>
  useSelector((state) => state.locationSettingsReducer.publishStatus);
