/* eslint-disable  */
import React, {memo, useEffect, useState} from 'react';
import T from 'prop-types';
import {useFormikContext} from 'formik';
import {nanoid} from 'nanoid';
import {
  DashboardDays,
  DashboardField,
  DashboardFields,
  DialogField
} from '../../dashboard/styles';
import DaysPickerDays from '../DaysPickerDays';
import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import DaysPickerTimeSelect from '../DaysPickerTimeSelect';
import {generateTimeRanges} from '../helpers';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const allDaySelected = (day) => day?.timePeriods?.[0]?.duration === '0:00';

const DayItem = ({day}) => {
  const [displayHoursSelection, setDisplayHoursSelection] = useState(
    allDaySelected(day)
  );

  const [bufferTimeSlots, setBufferTimeSlots] = useState(null);

  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();

  // useEffect(() => {
  //   const arr = [...openingDays];
  //   const elementId = arr.findIndex((d) => d.id === day.id);
  //   if (!arr[elementId].timePeriods.length) {
  //     setDisplayHoursSelection(true);
  //   }
  // }, [openingDays]);

  // useEffect(() => {
  //   if (displayHoursSelection) {
  //     const arr = [...openingDays];
  //     const element = arr.findIndex((d) => d.id === day.id);
  //
  //     // setBufferTimeSlots(
  //     //   openingDays[element].timePeriods.length
  //     //     ? openingDays[element].timePeriods
  //     //     : null
  //     // );
  //     arr[element].timePeriods = [
  //       {
  //         id: nanoid(),
  //         startOfWorkingDay: '10:00 AM',
  //         endOfWorkingDay: '10:00 PM',
  //         duration: '12:00',
  //         hoursIntervals: Object.keys(generateTimeRanges(15, 'en', 10, 22))
  //       }
  //     ];
  //     setOpeningDays(arr);
  //   } else {
  //     // if (bufferTimeSlots) {
  //     //   const arr = [...openingDays];
  //     //   const element = arr.findIndex((d) => d.id === day.id);
  //     //   arr[element].timePeriods = bufferTimeSlots;
  //     //   setBufferTimeSlots(null);
  //     // }
  //     const arr = [...openingDays];
  //     const element = arr.findIndex((d) => d.id === day.id);
  //     arr[element].timePeriods = [
  //       {
  //         id: nanoid(),
  //         startOfWorkingDay: '10:00 AM',
  //         endOfWorkingDay: '10:00 PM',
  //         duration: '12:00',
  //         hoursIntervals: Object.keys(generateTimeRanges(15, 'en', 10, 22))
  //       }
  //     ];
  //   }
  //
  //   setFieldValue(
  //     'OpeningHoursChanged',
  //     isValueChanged(OpeningHoursChanged, 'displayHoursSelection')
  //   );
  // }, [displayHoursSelection]);

  return (
    <>
      <DashboardFields className='w100 AlignCenter'>
        <DashboardField className='wAuto'>
          <DashboardDays>
            <DaysPickerDays day={day} />
          </DashboardDays>
        </DashboardField>

        <DashboardField className='wAuto'>
          <DaysPickerTimeSelect timePeriods={day.timePeriods} day={day} />
        </DashboardField>
      </DashboardFields>
    </>
  );
};

DayItem.propTypes = {
  day: T.object.isRequired
};

DayItem.defaultProps = {};

export default memo(DayItem);
