import {createAction} from 'redux-act';

export const GET_OUT_STOCK = createAction('menuEditorOutStock/GET_OUT_STOCK');
export const GET_OUT_STOCK_SUCCESS = createAction(
  'menuEditorOutStock/GET_OUT_STOCK_SUCCESS'
);
export const GET_OUT_STOCK_TAB = createAction(
  'menuEditorOutStock/GET_OUT_STOCK_TAB'
);

export const GET_OUT_STOCK_TAB_SUCCESS = createAction(
  'menuEditorOutStock/GET_OUT_STOCK_TAB_SUCCESS'
);

export const FETCH_OUTSTOCK_ITEMS = createAction(
  'menuEditor/FETCH_OUTSTOCK_ITEMS'
);
export const SET_OUTSTOCK_ITEMS = 'menuEditor/SET_OUTSTOCK_ITEMS';
export const CLEAR_OUTSTOCK_ITEMS = 'menuEditor/CLEAR_OUTSTOCK_ITEMS';

export const FETCH_OUTSTOCK_MODIFIERS = createAction(
  'menuEditor/FETCH_OUTSTOCK_MODIFIERS'
);
export const SET_OUTSTOCK_MODIFIERS = 'menuEditor/SET_OUTSTOCK_MODIFIERS';
export const CLEAR_OUTSTOCK_MODIFIERS = 'menuEditor/CLEAR_OUTSTOCK_MODIFIERS';

export const CLEAR_OUTSTOCK_STORE = createAction(
  'menuEditor/CLEAR_OUTSTOCK_STORE'
);

export const FETCH_SINGLE_ITEM_TREE = createAction(
  'menuEditorOutOffStock/FETCH_SINGLE_ITEM_TREE'
);
export const SET_SINGLE_ITEM_TREE = createAction(
  'menuEditorOutOffStock/SET_SINGLE_ITEM_TREE'
);

export const FETCH_SINGLE_MODIFIER_TREE = createAction(
  'menuEditorOutOffStock/FETCH_SINGLE_MODIFIER_TREE'
);
export const SET_SINGLE_MODIFIER_TREE = createAction(
  'menuEditorOutOffStock/SET_SINGLE_MODIFIER_TREE'
);

export const FETCH_OUTSTOCK_COUNTERS = createAction(
  'menuEditorOutOffStock/FETCH_OUTSTOCK_COUNTERS'
);
export const LOAD_OUTSTOCK_COUNTERS = createAction(
  'menuEditorOutOffStock/LOAD_OUTSTOCK_COUNTERS'
);
export const SET_OUTSTOCK_COUNTERS = createAction(
  'menuEditorOutOffStock/SET_OUTSTOCK_COUNTERS'
);
