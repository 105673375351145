/* eslint-disable react/prop-types */
import React from 'react';
import T from 'prop-types';
import {Dialog} from '@material-ui/core';
import {ButtonOutline, ButtonPrimary} from '../../../../components/buttons';
import {DialogBody, DialogText} from '../../dashboard/styles';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import DialogActions from '../../../../components/DialogActions/styles';
import DialogAction from '../../../../components/DialogAction/styles';

const DisableModalWithFutureOrders = ({
  openModal,
  onDisable,
  isCloseConfirmModal
}) => {
  return (
    <Dialog open={openModal} scroll='body'>
      <DialogContainer className='DialogSm2'>
        <DialogClose onClick={() => isCloseConfirmModal()} />
        <DialogTitle style={{textAlign: 'center'}}>Close store</DialogTitle>
        <DialogBody>
          <DialogText>
            Please note that you have active orders!
            <br />
            Close anyway?
          </DialogText>
          <DialogActions className='marginTop25'>
            <DialogAction className='w50'>
              <ButtonOutline
                type='button'
                className='dismiss'
                onClick={() => isCloseConfirmModal()}
              >
                <span>No</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction className='w50'>
              <ButtonPrimary
                type='button'
                onClick={() => {
                  onDisable();
                  isCloseConfirmModal();
                }}
              >
                <span>Yes</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

DisableModalWithFutureOrders.propTypes = {
  openModal: T.bool.isRequired,
  onDisable: T.func.isRequired,
  isCloseConfirmModal: T.func.isRequired
};
export default DisableModalWithFutureOrders;
