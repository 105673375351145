import React from 'react';
import {Wrapper} from '../styles';
import DayItem from './DayItem';
import {useDaysPickerContext} from '../context/DaysPickerContext';

const DaysPicker = () => {
  const openingDays = useDaysPickerContext();

  return (
    <>
      {openingDays.length > 0 &&
        openingDays.map((day) => (
          <Wrapper key={`day-${day.id}`}>
            <DayItem day={day} withoutClose={openingDays.length === 1} />
          </Wrapper>
        ))}
    </>
  );
};

DaysPicker.propTypes = {};

export default DaysPicker;
