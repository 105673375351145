import styled from 'styled-components';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding-right: 3px;
  .MuiFormControlLabel-root {
    margin: 0;
    .MuiTypography-root {
      order: 1;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1;
      color: #232d3a;
    }
    .MuiSwitch-root {
      order: 2;
      margin-left: 0;

      .MuiSwitch-track {
        background-color: #e9e9ea;
        border: 1px solid #e9e9ea;
      }
      .MuiSwitch-switchBase {
        color: #fff;
      }
      .MuiIconButton-root:hover {
        background-color: transparent;
      }
      .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: #17c981;
        border: 1px solid #17c981;
      }
    }
    &.red {
      .MuiSwitch-root {
        .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
          background-color: #ff2d38;
          border: 1px solid #ff2d38;
        }
      }
      &.endPlacement {
        .MuiSwitch-root {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }
  }
`;

export default SwitchContainer;
