import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {ButtonScroll} from './styles';

const ButtonScrollTop = () => {
  return (
    <ButtonScroll
      top={160}
      component={<ArrowUpwardIcon />}
      color='#fff'
      smooth
    />
  );
};

export default ButtonScrollTop;
