import {useSelector} from 'react-redux';

export const getDiscounts = () =>
  useSelector((state) => state.discountsReducer.discounts);

export const getDiscount = () =>
  useSelector((state) => state.discountsReducer.discount);

export const getButtonStatus = () =>
  useSelector((state) => state.discountsReducer.buttonStatus);

export const getHasNextPage = () =>
  useSelector((state) => state.discountsReducer.hasNextPage);

export const getDiscountsPage = () =>
  useSelector((state) => state.discountsReducer.page);

export const getLocationId = () =>
  useSelector((state) => state.user.locationId);

export const getModalsStack = () =>
  useSelector((state) => state.discountsReducer.modalsStack);

export const getDiscountsLoading = () =>
  useSelector((state) => state.discountsReducer.loading);

export const getDiscountsDataUpdated = () =>
  useSelector((state) => state.discountsReducer?.updatedAt || '');

export const getDiscountsBotSync = () =>
  useSelector((state) => state.discountsReducer?.LastBotSync || '');

export const getPublishStatusSelector = () =>
  useSelector((state) => state.discountsReducer.publishStatus);

export const getOrderBySorting = () =>
  useSelector((state) => state.discountsReducer.sorting.sortBy);

export const getSortDirection = () =>
  useSelector((state) => state.discountsReducer.sorting.sort);

export const getTotalDocsCount = () =>
  useSelector((state) => state.discountsReducer.totalDocsCount);

export const getEmailMarketingToggleError = () =>
  useSelector((state) => state.discountsReducer.isEmailMarketingToggleError);

export const getIsEmailMarketingTurnOffModalOpen = () =>
  useSelector(
    (state) => state.discountsReducer.isEmailMarketingTurnOffModalOpen
  );
