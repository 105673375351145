import styled from 'styled-components';

const DialogTitle = styled.div`
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  color: #2f3d48;
  @media (max-width: 600px) {
    font-size: 20px;
  }

  &.DialogTitleMarginBottom {
    margin-bottom: 31px;
  }

  span {
    color: #ff2d38;
  }

  &.DialogTitleOrange {
    color: #fca600;
  }
  &.DialogTitleRed {
    color: #ff2d38;
  }

  &.DialogTitleLeft {
    text-align: left;
  }

  &.Xl {
    font-size: 34px;
    @media (max-width: 600px) {
      font-size: 28px;
    }
    @media (max-width: 440px) {
      font-size: 24px;
    }
  }

  &.DialogTitleMd {
    font-size: 18px;
    margin: 0 auto;
  }
  &.memberConfirmation {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 1.2;

    @media (max-width: 767px) {
      font-size: 24px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
      br {
        display: none;
      }
    }
  }
  &.verticalCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 7px;
    }
  }
  &.mB17 {
    margin-bottom: 17px;
  }
`;

export default DialogTitle;
