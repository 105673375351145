import React from 'react';

const IconPartialRefund = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_14545_111849)'>
        <path
          d='M24.813 16.8924C24.0809 16.5005 23.1968 16.5432 22.5058 17.0038L18.879 19.4217C18.763 18.2696 17.7877 17.3672 16.6055 17.3672H12.7969L12.3 16.9946C11.3106 16.2524 10.0845 15.8438 8.84762 15.8438C7.80498 15.8438 6.78128 16.1273 5.88717 16.6637L5.68745 16.7836V16.0977C5.68745 15.677 5.3464 15.3359 4.92573 15.3359H0.761719C0.341047 15.3359 0 15.677 0 16.0977V25.2383C0 25.659 0.341047 26 0.761719 26H4.92578C5.34645 26 5.6875 25.659 5.6875 25.2383V24.9844H17.685C18.4633 24.9844 19.213 24.7502 19.8528 24.3072L25.0317 20.7217C25.638 20.302 26 19.6113 26 18.8739C26 18.0435 25.5452 17.2842 24.813 16.8924ZM4.16406 24.4766H1.52344V16.8594H4.16406V24.4766ZM24.1646 19.4692L18.9856 23.0546C18.6017 23.3204 18.152 23.4609 17.685 23.4609H5.6875V18.5602L6.67103 17.9701C7.32839 17.5757 8.08102 17.3672 8.84762 17.3672C9.75696 17.3672 10.6584 17.6677 11.3859 18.2133L12.0859 18.7383C12.2178 18.8372 12.3782 18.8906 12.543 18.8906H16.6055C17.0255 18.8906 17.3672 19.2323 17.3672 19.6523C17.3672 20.0724 17.0255 20.4141 16.6055 20.4141H11.0195C10.5989 20.4141 10.2578 20.7551 10.2578 21.1758C10.2578 21.5965 10.5989 21.9375 11.0195 21.9375H17.0061C17.5592 21.9375 18.0949 21.7754 18.5554 21.4684L23.3509 18.2714C23.6765 18.0543 23.9792 18.1739 24.0942 18.2355C24.2092 18.297 24.4766 18.4824 24.4766 18.8739C24.4766 19.1114 24.36 19.334 24.1646 19.4692Z'
          fill='#929CB9'
        />
      </g>
      <path
        d='M8.08333 0C5.83179 0 4 1.83179 4 4.08333C4 6.33487 5.83179 8.16667 8.08333 8.16667C10.3349 8.16667 12.1667 6.33487 12.1667 4.08333C12.1667 1.83179 10.3349 0 8.08333 0ZM8.08333 7.20563C6.36171 7.20563 4.96103 5.80496 4.96103 4.08333C4.96103 2.36171 6.36171 0.961033 8.08333 0.961033C9.80496 0.961033 11.2056 2.36171 11.2056 4.08333C11.2056 5.80496 9.80496 7.20563 8.08333 7.20563Z'
        fill='#929CB9'
      />
      <path
        d='M8.1014 4.56413C8.26474 4.56413 8.39762 4.69701 8.39762 4.86035C8.39762 5.02369 8.26474 5.15657 8.1014 5.15657C7.96111 5.15657 7.83906 5.0571 7.81122 4.92004C7.7624 4.67957 7.52788 4.52426 7.28732 4.57301C7.04681 4.62186 6.89147 4.85641 6.94029 5.09692C7.02089 5.49374 7.29798 5.81191 7.65703 5.95813V6.04525C7.65703 6.29064 7.85598 6.48958 8.10137 6.48958C8.34676 6.48958 8.54571 6.29064 8.54571 6.04525V5.95848C8.97947 5.78232 9.28627 5.35662 9.28627 4.86035C9.28627 4.207 8.75472 3.67546 8.10137 3.67546C7.93803 3.67546 7.80515 3.54257 7.80515 3.37923C7.80515 3.21589 7.93803 3.08301 8.10137 3.08301C8.24166 3.08301 8.36371 3.18248 8.39155 3.31954C8.44037 3.56002 8.67507 3.71539 8.91545 3.66657C9.15593 3.61772 9.3113 3.38317 9.26248 3.14267C9.18191 2.74585 8.90482 2.4277 8.54574 2.28148V2.19434C8.54574 1.94894 8.34679 1.75 8.1014 1.75C7.85601 1.75 7.65706 1.94894 7.65706 2.19434V2.2811C7.2233 2.45726 6.9165 2.88297 6.9165 3.37923C6.9165 4.03258 7.44805 4.56413 8.1014 4.56413Z'
        fill='#929CB9'
      />
      <path
        d='M18.3499 5.83203C16.0984 5.83203 14.2666 7.66382 14.2666 9.91536C14.2666 12.1669 16.0984 13.9987 18.3499 13.9987C20.6015 13.9987 22.4333 12.1669 22.4333 9.91536C22.4333 7.66382 20.6015 5.83203 18.3499 5.83203ZM18.3499 13.0377C16.6283 13.0377 15.2276 11.637 15.2276 9.91536C15.2276 8.19374 16.6283 6.79306 18.3499 6.79306C20.0716 6.79306 21.4722 8.19374 21.4722 9.91536C21.4722 11.637 20.0716 13.0377 18.3499 13.0377Z'
        fill='#929CB9'
      />
      <path
        d='M18.368 10.3962C18.5313 10.3962 18.6642 10.529 18.6642 10.6924C18.6642 10.8557 18.5313 10.9886 18.368 10.9886C18.2277 10.9886 18.1057 10.8891 18.0778 10.7521C18.029 10.5116 17.7945 10.3563 17.5539 10.405C17.3134 10.4539 17.1581 10.6884 17.2069 10.9289C17.2875 11.3258 17.5646 11.6439 17.9236 11.7902V11.8773C17.9236 12.1227 18.1226 12.3216 18.368 12.3216C18.6134 12.3216 18.8123 12.1227 18.8123 11.8773V11.7905C19.2461 11.6143 19.5529 11.1886 19.5529 10.6924C19.5529 10.039 19.0213 9.50749 18.368 9.50749C18.2046 9.50749 18.0717 9.3746 18.0717 9.21126C18.0717 9.04792 18.2046 8.91504 18.368 8.91504C18.5083 8.91504 18.6303 9.01451 18.6582 9.15157C18.707 9.39205 18.9417 9.54742 19.1821 9.4986C19.4225 9.44975 19.5779 9.2152 19.5291 8.9747C19.4485 8.57788 19.1714 8.25973 18.8123 8.11352V8.02637C18.8123 7.78098 18.6134 7.58203 18.368 7.58203C18.1226 7.58203 17.9237 7.78098 17.9237 8.02637V8.11313C17.4899 8.2893 17.1831 8.715 17.1831 9.21126C17.1831 9.86461 17.7146 10.3962 18.368 10.3962Z'
        fill='#929CB9'
      />
      <defs>
        <clipPath id='clip0_14545_111849'>
          <rect width='26' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPartialRefund;
