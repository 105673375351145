import styled from 'styled-components';
import Caption4 from '../../../../../components/typography/Caption4';

export const DialogList = styled.ul`
  display: block;
  margin-top: 28px;

  &.DialogListMin {
    li {
      *:first-child {
        flex: 0 0 180px;
      }
    }
  }
`;
export const DialogListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }

  *:first-child {
    flex: 0 0 250px;
  }
  *:nth-child(2) {
    flex: 1 1 auto;
    padding-left: 10px;
  }
`;
export const DialogTitleLine = styled(Caption4)`
  display: flex;
  align-items: center;

  &::after {
    content: '';
    flex: 1 1 auto;
    margin-left: 10px;
    background-color: rgba(146, 156, 185, 0.3);
    height: 1px;
  }
`;
