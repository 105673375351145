import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {ButtonPrimary} from '../../../../components/buttons';
import {
  PUBLISH_STATUS_DISABLE,
  PUBLISH_STATUS_ENABLE,
  PUBLISH_STATUS_WAITING
} from '../../../../store/locationSettings/constants';
import {PUBLISH_LOCATION} from '../../../../store/locationSettings/action';
import UploadButton from '../../../../assets/UploadButton';
import ConfirmModal from '../../../../components/ModalBox/ConfirmModal';
import InfoModal from '../../../../components/ModalBox/InfoModal';
import {
  locationBotSync,
  locationDataUpdated,
  publishStatusSelector
} from '../../../../store/locationSettings/selectors';
import {
  SHOW_PUBLISH_PROGRESSBAR,
  STOP_GET_PUBLISH_STATUS_JOB
} from '../../../../store/global/globalNotifications/action';

const LocationPublishButton = ({isSubmitting}) => {
  const dispatch = useDispatch();
  const publishStatus = publishStatusSelector();
  const lastUpdatedForm = locationDataUpdated();
  const lastBotSync = locationBotSync();
  const [confirmBox, setConfirmBox] = useState(null);
  const [infoBox, setInfoBox] = useState(null);

  useEffect(() => {
    if (
      publishStatus === PUBLISH_STATUS_ENABLE &&
      lastUpdatedForm > lastBotSync
    ) {
      setInfoBox({
        message:
          'You have unpublished changes. They will not appear on the website until you publish them.',
        btnOkTitle: 'OK',
        onOk: () => setInfoBox(null)
      });
    }
  }, []);

  const onLocationPublishResult = (data) => {
    setConfirmBox(null);
    if (!data) {
      return;
    }

    const {status, message} = data;
    setInfoBox({
      message,
      btnOkTitle: 'OK',
      onOk: () => setInfoBox(null)
    });
  };

  const onLocationPublish = () => {
    setConfirmBox({
      isLoading: false,
      message: 'Are you sure you want to publish your changes now?',
      btnOkTitle: 'Yes, I want to publish',
      btnCancelTitle: 'No, I want to do more changes',
      onOk: () => {
        dispatch(
          PUBLISH_LOCATION({
            preCallback: () => {
              setConfirmBox({...confirmBox, isLoading: true});
            },
            postCallBack: onLocationPublishResult,
            errorCallback: () => {
              dispatch(STOP_GET_PUBLISH_STATUS_JOB());
              dispatch(SHOW_PUBLISH_PROGRESSBAR(false));
            }
          })
        );
      },
      onCancel: () => {
        setConfirmBox(null);
      }
    });
  };

  const confirmOnCancel = () => setConfirmBox(null);

  const labels = {
    [PUBLISH_STATUS_DISABLE]: 'Publish',
    [PUBLISH_STATUS_ENABLE]: 'Publish',
    [PUBLISH_STATUS_WAITING]: 'Waiting...'
  };

  return (
    <>
      <ButtonPrimary
        type='button'
        onClick={
          publishStatus === PUBLISH_STATUS_ENABLE
            ? onLocationPublish
            : undefined
        }
        className={cn('ButtonAdd ButtonMedium', {
          disabled: publishStatus !== PUBLISH_STATUS_ENABLE || isSubmitting
        })}
      >
        <UploadButton />
        <span className='titleBtn'>{labels[publishStatus]}</span>
        {/* <CheckPublishButtonStatus /> */}
      </ButtonPrimary>

      {!!confirmBox && (
        <ConfirmModal
          isOpen
          isLoading={!!confirmBox.isLoading}
          message={confirmBox.message}
          btnOkTitle={confirmBox.btnOkTitle}
          btnCancelTitle={confirmBox.btnCancelTitle}
          onOk={confirmBox.onOk}
          onCancel={confirmOnCancel}
        />
      )}

      {!!infoBox && (
        <InfoModal
          isOpen
          message={infoBox.message}
          btnOkTitle={infoBox.btnOkTitle}
          onOk={infoBox.onOk}
        />
      )}
    </>
  );
};

LocationPublishButton.propTypes = {
  isSubmitting: T.bool
};

LocationPublishButton.defaultProps = {
  isSubmitting: false
};

export default LocationPublishButton;
