import * as Yup from 'yup';

const RealDeliveryBoardV2ActionsMadeSchema = Yup.object().shape({
  bookedADriver: Yup.bool(),
  bookADriverSauceOrderId: Yup.string().when(
    ['bookedADriver'],
    (bookedADriver) => {
      if (bookedADriver) {
        return Yup.string().required(
          'BaD Sauce Delivery ID is mandatory for BaD'
        );
      }
      return Yup.string();
    }
  ),
  description: Yup.string().when(['custom'], (custom) => {
    if (custom) {
      return Yup.string().required('Description is mandatory for Other field');
    }
    return Yup.string();
  })
});

export default RealDeliveryBoardV2ActionsMadeSchema;
