import {createReducer} from 'redux-act';
import {
  CLEAR_SUDO_USER,
  GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS,
  GET_SUDO_USER_SUCCESS,
  GET_SUDO_USERS,
  GET_SUDO_USERS_SUCCESS,
  SEARCH_CHANGE,
  PAGE_CHANGE,
  CLEAR_FILTERS
} from 'store/adminSudoUsers/action';

const initialPaging = {
  total: 0,
  limit: 10,
  pages: 0,
  page: 0
};

const initialSort = {
  sortBy: 'first_name',
  sortDirection: 'asc'
};

const initialState = () => ({
  loading: false,
  paging: initialPaging,
  search: '',
  sort: initialSort,
  sudoUsers: [],
  currentSudoUser: null,
  allSudoUserFeatureFlags: []
});

const adminSudoUsersReducer = createReducer(
  {
    [GET_SUDO_USERS]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [GET_SUDO_USERS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        sudoUsers: payload.docs,
        paging: {
          ...state.paging,
          total: payload.totalDocs,
          pages: payload.totalPages
        },
        loading: false
      };
    },
    [GET_ALL_SUDO_USER_FEATURE_FLAGS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        allSudoUserFeatureFlags: payload.map((item) => item.id)
      };
    },
    [PAGE_CHANGE]: (state, payload) => {
      return {
        ...state,
        paging: {
          ...state.paging,
          page: payload
        }
      };
    },
    [SEARCH_CHANGE]: (state, payload) => {
      return {
        ...state,
        search: payload,
        paging: initialPaging
      };
    },
    [GET_SUDO_USER_SUCCESS]: (state, payload) => {
      return {
        ...state,
        currentSudoUser: payload
      };
    },
    [CLEAR_SUDO_USER]: (state) => {
      return {
        ...state,
        currentSudoUser: null
      };
    },
    [CLEAR_FILTERS]: (state) => {
      return {
        ...state,
        search: '',
        sort: initialSort,
        paging: initialPaging
      };
    }
  },
  initialState()
);

export default adminSudoUsersReducer;
