import {createAction} from 'redux-act';

export const INSIGHTS_CONTROL_GET_LOCATIONS = createAction(
  'insightsControl/INSIGHTS_CONTROL_GET_LOCATIONS'
);
export const INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS = createAction(
  'insightsControl/INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS'
);

export const INSIGHTS_CONTROL_GET_LOCATION_INFO = createAction(
  'insightsControl/INSIGHTS_CONTROL_GET_LOCATION_INFO'
);
export const INSIGHTS_CONTROL_GET_LOCATION_INFO_SUCCESS = createAction(
  'insightsControl/INSIGHTS_CONTROL_GET_LOCATION_INFO'
);
export const INSIGHTS_CONTROL_CHANGE_MODULE_STATUS = createAction(
  'insightsControl/INSIGHTS_CONTROL_CHANGE_MODULE_STATUS'
);

export const SET_LOCATION_TABLE_LOADING = createAction(
  'insightsControl/SET_LOCATION_TABLE_LOADING'
);
