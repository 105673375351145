import {GET, POST} from '../constants/API_CONSTANTS';
import {request} from '../utils/request';
import {
  GET_HUBSPOT_INFO,
  POST_HUBSPOT_COMMENT
} from '../constants/API_ENDPOINTS';
import {getAppIp, getAppType, getAppVersion} from '../../app.helper';

export const hubspotComment = async (hubspotPayload, text) => {
  const data = {
    conversationId: hubspotPayload.conversation.conversationId,
    text
  };

  return request(POST, POST_HUBSPOT_COMMENT(), {data});
};

export const getHubspotInfo = async (token, locationId) => {
  return request(
    GET,
    `${process.env.REACT_APP_API_URL}/hubspot-info?locationId=${locationId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-App-Type': `${getAppType()} ${getAppVersion()}`,
        'X-App-Ip': `${getAppIp()}`
      }
    }
  );
};
