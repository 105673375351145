import {createAction} from 'redux-act';

export const OPEN_ADJUST_MODAL = createAction('orderAdjust/OPEN_ADJUST_MODAL');
export const CALCULATE_ADJUST = createAction('orderAdjust/CALCULATE_ADJUST');
export const CALCULATE_ADJUST_SUCCESS = createAction(
  'orderAdjust/CALCULATE_ADJUST_SUCCESS'
);
export const CREATE_ADJUST = createAction('orderAdjust/CREATE_ADJUST');
export const OPEN_ADJUST_SUCCESS_MODAL = createAction(
  'orderAdjust/OPEN_ADJUST_SUCCESS_MODAL'
);
export const OPEN_ADJUST_ERROR_MODAL = createAction(
  'orderAdjust/OPEN_ADJUST_ERROR_MODAL'
);
