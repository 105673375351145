import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Subtitle5 = styled(AbstractText)`
  font-size: 24px;
  font-weight: 700;
  color: #232d3a;

  span {
    color: #ff2d38;
  }
`;

export default Subtitle5;
