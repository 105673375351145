import styled from 'styled-components';
import CloseIcon from '../../assets/icon-close-white.svg';
import {Body1} from '../typography';

export const RedBannerWrapper = styled.div`
  left: 272px;
  top: 74px;
  height: 60px;
  width: calc(100% - 272px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #ff2d38;
  z-index: 7;
  padding: 8px 20px;
  &.RedBannerHolidays {
    z-index: 8;
  }
  @media (max-width: 1250px) {
    top: 96px;
    left: 0;
    width: 100%;
  }
  @media (max-width: 767px) {
    top: 70px;
    height: 40px;
    padding: 5px 20px;
  }
  @media (max-width: 599px) {
    top: 102px;
  }
  @media (max-width: 440px) {
    padding: 5px 8px;
    height: auto;
  }
  .textRedBanner {
    text-align: left;
    @media (max-width: 1599px) {
      font-size: 16px;
    }
    @media (max-width: 1300px) {
      font-size: 16px;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 599px) {
      font-size: 11px;
    }
  }
  svg {
    margin-right: 14px;
    flex: 0 0 auto;
    @media (max-width: 1000px) {
      width: 30px;
      height: 35px;
    }
    @media (max-width: 767px) {
      width: 24px;
      height: 29px;
      margin-right: 10px;
    }
    @media (max-width: 440px) {
      margin-right: 6px;
      width: 20px;
      height: 25px;
    }
  }
`;

export const RedBannerUnderlineText = styled(Body1)`
  display: inline-block;
  font-style: normal;
  border: none;
  border-bottom: 1px solid #fff;
  transition-property: border-bottom-color;
  transition-duration: 0.3s;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
  line-height: 1;

  @media (max-width: 1599px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 599px) {
    font-size: 11px;
  }
  &:hover {
    border-bottom-color: transparent;
  }
  &.noClickEvent {
    pointer-events: none;
    border: none;
  }
`;
