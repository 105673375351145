import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {IconButton, InputAdornment, OutlinedInput} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import T from 'prop-types';
import FieldPasswordContainer from './style';

const FieldPassword = ({
  value,
  name,
  onChange,
  className,
  placeholder,
  disabled,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <FieldPasswordContainer className={className}>
      <FormControl>
        <OutlinedInput
          id='outlined-adornment-password'
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
          {...props}
        />
      </FormControl>
    </FieldPasswordContainer>
  );
};

FieldPassword.propTypes = {
  value: T.oneOfType([T.string, T.number]).isRequired,
  name: T.string,
  onChange: T.func.isRequired,
  className: T.string,
  placeholder: T.string,
  disabled: T.bool
};

FieldPassword.defaultProps = {
  name: '',
  className: '',
  placeholder: '',
  disabled: false
};

export default FieldPassword;
