import React from 'react';
import ReactDOM from 'react-dom';
import {ReactSVG} from 'react-svg';
import PropTypes from 'prop-types';
import {DialogSubTitle} from '../../../../components/DialogSubTitle/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import Body3 from '../../../../components/typography/Body3';
import {Body1, Body2} from '../../../../components/typography';
import TipIcon from '../../../../assets/uberEats/tip-icon.svg';
import {FlexContainer} from '../../../../components/layout';
import {IconWrapper} from '../styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import Body4 from '../../../../components/typography/Body4';

const ByocSecond = (props) => {
  return (
    <div>
      <Subtitle5>Call UberEats</Subtitle5>
      <Body3 className='marginTopMd'>
        <b>Call</b> UberEats now at +1 (937) 721-2763 and ask their
        representative to deliver UberEats orders with your own couriers.
      </Body3>
      <br />
      <Body4 className='marginBottom2Xs'>Tips</Body4>
      <FlexContainer className='marginBottom2Xs'>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 color='#969BA0'>The call itself takes 5-10 min.</Body2>
      </FlexContainer>

      <FlexContainer>
        <IconWrapper wrapper='span' src={TipIcon} />
        <Body2 color='#969BA0'>
          Sometimes there might be some holding time.
        </Body2>
      </FlexContainer>

      {props.actionsRef
        ? ReactDOM.createPortal(
            <ButtonPrimary className='ButtonNav' onClick={props.goNextAction}>
              <span>Next</span>
            </ButtonPrimary>,
            props.actionsRef
          )
        : null}
    </div>
  );
};

ByocSecond.propTypes = {
  actionsRef: PropTypes.object.isRequired,
  goNextAction: PropTypes.func.isRequired
};

export default ByocSecond;
