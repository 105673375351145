import styled from 'styled-components';

const MainSection = styled.div`
  display: block;
  padding: 74px 0 200px 272px;

  @media (max-width: 1600px) {
    padding: 74px 0 200px 272px;
  }

  @media (max-width: 1250px) {
    padding: 96px 0 170px 0;
  }

  @media (max-width: 1200px) {
    padding: 96px 0 170px 0;
  }

  @media (max-width: 1000px) {
    padding: 96px 0 70px 0;
  }
  @media (max-width: 767px) {
    padding-top: 74px;
  }
  @media (max-width: 599px) {
    padding-top: 56px;
  }
`;

export default MainSection;
