import styled from 'styled-components';

export const RangeSlider = styled.div`
  margin-top: 10px;
`;
export const RangeSliderTop = styled.div`
  display: block;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3;
  color: #2f3d48;
  font-size: 16px;

  span {
    font-size: 20px;
    color: #ff2d38;
    padding-right: 6px;
  }
`;
export const RangeSliderBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #929cb9;
  }
`;
export const RangeSliderContainer = styled.div`
  display: block;
  .MuiSlider-root {
    height: 6px;
    border-radius: 30px;
  }
  .MuiSlider-rail {
    background-color: #e8eaee;
    border-radius: 30px;
    height: 6px;
  }
  .MuiSlider-track {
    background-color: #ff2d38;
    height: 6px;
    border-radius: 30px;
  }
  .MuiSlider-thumb {
    background-image: linear-gradient(
      105.63deg,
      #ff2d38 39.07%,
      #f0868c 96.81%
    );
    width: 32px;
    height: 32px;
    margin-top: -13px;
    margin-left: -15px;
    box-shadow: 0px 8px 16px rgba(255, 45, 56, 0.32) !important;
    border-radius: 30px;
    &::after {
      display: none !important;
    }
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 8px 16px rgba(21, 206, 117, 0.32);
  }
`;
