import styled from 'styled-components';

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #232d3a;

  span {
    color: #ff2d38;
  }
`;

export default Subtitle;
