import styled from 'styled-components';

const DialogField = styled.div`
  padding: 13px 10px;
  position: relative;

  &.isDisabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.w30 {
    flex: 0 0 30%;
  }

  &.w40 {
    flex: 0 0 40%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }
  &.w33 {
    flex: 0 0 33.3333333%;
    @media (max-width: 1000px) {
      flex: 0 0 50%;
    }
  }

  &.w50 {
    flex: 0 0 50%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
    &.action {
      @media (max-width: 1000px) {
        flex: 0 0 50%;
      }
    }
  }

  &.w60 {
    flex: 0 0 60%;
    @media (max-width: 1000px) {
      flex: 0 0 100%;
    }
  }

  &.w70 {
    flex: 0 0 70%;
  }

  &.w100 {
    flex: 0 0 100%;
  }

  &.wSm {
    flex: 0 0 104px;
  }

  &.countryCode {
    flex: 0 0 110px;
  }
  &.wAuto {
    flex: 1 1 auto;
  }

  &.Center {
    text-align: center;
  }

  &.TextJustify {
    text-align: justify;
  }

  &.pTop {
    padding-top: 20px;
  }

  &.pTop50 {
    padding-top: 50px;
    @media (max-width: 1300px) {
      padding-top: 41px;
    }
  }
  &.withRemove {
    position: relative;
    padding-right: 35px;
  }
  &.smallPadding {
    padding: 0 0 2px 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &.bold {
    font-weight: bold;
  }
  &.rdbModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    word-break: break-word;

    span {
      width: 35%;
      &:last-child {
        width: 65%;
        text-align: right;
      }

      &.alignLeft {
        text-align: left;
      }

      &.fullWidth {
        width: 100%;
      }
      &.capitalizeFirstLetter {
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  &.RCModal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    word-break: break-word;

    span,
    a {
      width: 55%;
      padding-right: 10px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3;
      color: #000;

      &:first-child {
        &::before {
          display: inline-block;
          content: '*';
          color: #ff2d38;
          position: relative;
          top: 3px;
          padding-right: 2px;
        }
      }
      &:last-child {
        width: 45%;
      }

      &.alignLeft {
        text-align: left;
      }

      &.fullWidth {
        width: 100%;
      }
    }
  }
  &.dFlex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &.justifySB {
    justify-content: space-between;
  }
  &.dFlexNoWrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  &.mTop20 {
    margin-top: 20px;
    @media (max-width: 1440px) {
      margin-top: 10px;
    }
  }
  &.mTopMd {
    margin-top: 40px;
    @media (max-width: 1440px) {
      margin-top: 30px;
    }
    @media (max-width: 560px) {
      margin-top: 25px;
    }
  }

  &.noWrapTablet {
    @media (max-width: 1000px) {
      flex-wrap: nowrap;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: #232d3a;
  }
  button,
  .ButtonLg {
    max-width: 390px;
    width: 100%;

    &.MaxContentWidth {
      width: max-content;
      padding: 19px 24px;
    }

    &.ButtonMin {
      max-width: 160px;
    }
  }
  &.FieldPassword {
    .MuiFormControl-root {
      width: 100%;
      fieldset {
        display: none;
      }
      .MuiInputBase-root {
        background-color: #f7f7fa;
        border: 1px solid #d5d8de;
      }
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0);
      }
    }
  }

  &.ButtonAbsoluteTextarea {
    position: relative;

    button {
      position: absolute;
      bottom: 22px;
      right: 22px;
      max-width: 200px;
    }
  }

  iframe {
    width: 100%;
    height: 270px;
  }

  .smallText {
    font-size: 10px;
    color: #929cb9;
  }
  &.p0 {
    padding: 0;
  }
  &.TextUnderline {
    text-decoration: underline;
  }
  &.RefundCalculatorErrorModal {
    span {
      font-weight: bold;
      color: #17c981;
      &.failed {
        color: #ff2d38;
      }
    }
  }
  &.AdjustmentModalField {
    .AdjustmentTextarea {
      width: 100%;
    }
  }
`;

export default DialogField;
