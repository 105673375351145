import {createReducer} from 'redux-act';
import {
  GET_MARKETING_STATISTIC_SUCCESS,
  OPEN_CONTACT_FORM_MODAL,
  OPEN_INFORMATION_MODAL,
  SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER
} from './action';

const initialState = () => ({
  isOpenModal: false,
  isOpenInformationModal: false,
  statistic: {
    lastMonthSummary: {
      sent: 0,
      delivered: 0,
      salesDriven: 0
    },
    allSummary: {
      sent: 0,
      delivered: 0,
      salesDriven: 0
    }
  },
  spinner: false
});

const emailMarketingReducer = createReducer(
  {
    [OPEN_CONTACT_FORM_MODAL]: (state, payload) => ({
      ...state,
      isOpenModal: payload
    }),
    [OPEN_INFORMATION_MODAL]: (state, payload) => ({
      ...state,
      isOpenInformationModal: payload
    }),
    [GET_MARKETING_STATISTIC_SUCCESS]: (state, payload) => ({
      ...state,
      statistic: payload,
      spinner: false
    }),
    [SET_EMAIL_MARKETING_RESULT_PAGE_SPINNER]: (state, payload) => ({
      ...state,
      spinner: payload
    })
  },
  initialState()
);

export default emailMarketingReducer;
