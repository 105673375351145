import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Subtitle3 = styled(AbstractText)`
  font-size: 19px;
  font-weight: 700;
  color: #232d3a;
`;

export default Subtitle3;
