import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {DashboardInner} from '../../../../../../components/DashboardInner/styles';
import DialogTitle from '../../../../../../components/DialogTitle/styles';
import Body6 from '../../../../../../components/typography/Body6';
import RefundTypePicker from './refundTypePicker';
import RefundFaultSelect from './refundFaultSelect';
import RefundReasonSelect from './refundReasonSelect';
import DidReceiveOrderSelect from './didReceiveOrderSelect';
import {FieldTextarea} from '../../../../dashboard/styles';
import {
  RefundOtherTextareaContainer,
  RefundTopBarContainer,
  RefundTopBarSelects
} from './styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';
import DidRemakeOrderSelect from './didRemakeOrderSelect';
import CCRefundSelect from './CCRefundSelect';
import FieldInput from '../../../../../../components/FieldInput';

export default function RefundTopBar({
  values,
  errors,
  setFieldValue,
  setTouched,
  touched,
  setAmountError,
  refundItems,
  orderPriceAdjustments,
  orderTips
}) {
  const handleChangeField = (field, value) => {
    setTouched({}, false);
    setFieldValue(field, value);
    setAmountError(false);
  };

  const fault = values.refundType === 'Full' ? 'faultFull' : 'faultPartial';
  const reason = values.refundType === 'Full' ? 'reasonFull' : 'reasonPartial';
  const otherReasonText =
    values.refundType === 'Full'
      ? 'otherReasonTextFull'
      : 'otherReasonTextPartial';
  const freeTextCC =
    values.refundType === 'Full' ? 'freeTextCCFull' : 'freeTextCCPartial';

  const selectFaultHandler = (e) => {
    handleChangeField(fault, e.target.value);
    handleChangeField(reason, e.target.value === 'Customer' ? 'Other' : '');
  };

  return (
    <DashboardInner className='paddingRefundsTopPanel'>
      <RefundTopBarContainer>
        <DialogTitle className='DialogTitleLeft'>Refunds</DialogTitle>
        {!refundItems.length &&
        !orderPriceAdjustments?.length &&
        !orderTips &&
        values.refundType === 'Partial' ? (
          <Body6 className='refundsTopPanelText'>
            The order subtotal was refunded in full. If you wish to refund the
            customer the entire order value click “Full refund”
          </Body6>
        ) : (
          <Body6 className='refundsTopPanelText'>
            Refunds may take 5 – 10 business days to appear on a customer’s
            statement.
          </Body6>
        )}
        <RefundTypePicker
          refundType={values.refundType}
          handleChange={handleChangeField}
        />
        <RefundTopBarSelects>
          <div className='refundSelectGroup'>
            <div className='refundSelectContainer'>
              <span>
                Responsible party<span>&nbsp;*</span>
              </span>
              <RefundFaultSelect
                errors={errors}
                touched={touched}
                values={values}
                selectFaultHandler={selectFaultHandler}
              />
            </div>
            <div
              className={cn('refundSelectContainer', {
                disabled: values[fault] === '' || values[fault] === 'Customer',
                mRight13: values.tier1Refund
              })}
            >
              <span>
                Reason for refund
                {values[fault] !== 'Customer' && <span>&nbsp;*</span>}
              </span>
              <RefundReasonSelect
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                setTouched={setTouched}
              />
            </div>
          </div>
          {values.tier1Refund && (
            <div className='refundSelectGroup'>
              <div className='refundSelectContainer mRight13'>
                <span>
                  Refund requested by<span>&nbsp;*</span>
                </span>
                <CCRefundSelect
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  options={[
                    {name: 'Restaurant'},
                    {name: 'End Customer'},
                    {name: 'Sauce'}
                  ]}
                  selectValue={
                    values.refundType === 'Full'
                      ? 'requestedByFull'
                      : 'requestedByPartial'
                  }
                />
              </div>
              <div className='refundSelectContainer mRight13'>
                <span>
                  Refund request to<span>&nbsp;*</span>
                </span>
                <CCRefundSelect
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  options={[
                    {name: 'Restaurant'},
                    {name: 'End Customer'},
                    {name: 'Restaurant + End Customer'},
                    {name: 'Sauce'}
                  ]}
                  selectValue={
                    values.refundType === 'Full'
                      ? 'requestToFull'
                      : 'requestToPartial'
                  }
                />
              </div>
              <div className='refundSelectContainer freeTextInputContainer'>
                <span>
                  Please provide additional info for the payment team:
                  <span>&nbsp;*</span>
                </span>
                <FieldInput
                  className={cn('freeTextInput mRight13', {
                    error: errors[freeTextCC] && touched[freeTextCC]
                  })}
                  value={values[freeTextCC]}
                  onChange={(e) => setFieldValue(freeTextCC, e.target.value)}
                  name={freeTextCC}
                />
                <ErrorSignWrapper className='positionBottom'>
                  <ErrorSign>
                    {touched[freeTextCC] && errors[freeTextCC]}
                  </ErrorSign>
                </ErrorSignWrapper>
              </div>
            </div>
          )}
        </RefundTopBarSelects>
        <RefundOtherTextareaContainer
          style={{display: values[reason] === 'Other' ? 'block' : 'none'}}
        >
          <span>
            Other<span>&nbsp;*</span>
          </span>
          <FieldTextarea
            name='otherReasonText'
            maxLength={500}
            value={values[otherReasonText]}
            onChange={(e) => setFieldValue(otherReasonText, e.target.value)}
            className={cn('FieldTextareaRefunds', {
              error:
                errors[otherReasonText] && touched[otherReasonText] && 'error'
            })}
            placeholder='Specify the reason for refund '
          />
          <ErrorSignWrapper className='positionBottom'>
            <ErrorSign>
              {touched[otherReasonText] && errors[otherReasonText]}
            </ErrorSign>
          </ErrorSignWrapper>
          <p className='otherWordsCounter'>
            <span>{values[otherReasonText].length}</span>/500
          </p>
        </RefundOtherTextareaContainer>
      </RefundTopBarContainer>
    </DashboardInner>
  );
}

RefundTopBar.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  setAmountError: PropTypes.func.isRequired,
  refundItems: PropTypes.array.isRequired,
  orderPriceAdjustments: PropTypes.array.isRequired,
  orderTips: PropTypes.number.isRequired
};
