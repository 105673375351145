import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Notification from '../../../components/Notification';
import {
  isNotificationModalOpen,
  modalsStack
} from '../../../store/global/globalNotifications/selectors';
import {SHOW_NOTIFICATION_MODAL} from '../../../store/global/globalNotifications/action';
import {OPEN_DISABLE_MODAL} from '../../../store/locationSettings/action';

const LocationNotification = () => {
  const [minutes, setMinutes] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLocationTimezone = useSelector((state) => state.user.timezone);
  const locationId = useSelector((state) => state.user.locationId);
  const consecutiveModalsStack = useSelector(modalsStack);
  const isModalOpen = useSelector(isNotificationModalOpen);

  const isStoreDisabled = () =>
    consecutiveModalsStack.length &&
    consecutiveModalsStack.find((modal) => modal?.ClosedManually);

  const {
    EntityName,
    DeliveryEnableIn,
    PickupEnableIn,
    ClosedManuallyUntilTime,
    ClosedManually
  } = consecutiveModalsStack.length && consecutiveModalsStack[0];

  useEffect(() => {
    const deliveryObj = {
      minutesBeforeExpires:
        DeliveryEnableIn || PickupEnableIn || ClosedManuallyUntilTime
    };

    const now =
      currentLocationTimezone && currentLocationTimezone.charAt(0) === '-'
        ? moment()
            .subtract(currentLocationTimezone.replace('-', ''), 'hours')
            .unix()
        : moment().add(currentLocationTimezone, 'hours').unix();

    const expirationDate = moment(
      moment.unix(deliveryObj?.minutesBeforeExpires)
    )
      .utc()
      .unix();

    const minutesUntilExpiration = Math.floor((expirationDate - now) / 60);
    if (
      minutesUntilExpiration === 60 ||
      minutesUntilExpiration === 30 ||
      minutesUntilExpiration === 15
    ) {
      setMinutes(minutesUntilExpiration);
      if (
        (isStoreDisabled() && EntityName === 'Pickup') ||
        (isStoreDisabled() && EntityName === 'Delivery')
      ) {
        return;
      }
      if (!isModalOpen) {
        dispatch(SHOW_NOTIFICATION_MODAL(true));
      }
    }
  }, [consecutiveModalsStack, locationId]);

  const extendLonger = () => {
    dispatch(SHOW_NOTIFICATION_MODAL(false));
    history.push('/store-settings');
    dispatch(
      OPEN_DISABLE_MODAL({
        entityName: EntityName,
        open: true
      })
    );
  };

  const closeFunction = () => {
    dispatch(SHOW_NOTIFICATION_MODAL(false));
  };

  return (
    <>
      {isModalOpen && (
        <Notification
          entityName={EntityName}
          extendFunction={extendLonger}
          minutes={minutes}
          applyFunction={closeFunction}
        />
      )}
    </>
  );
};

export default LocationNotification;
