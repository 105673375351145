import styled from 'styled-components';

export const DashboardInners = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 12px -12px -12px -12px;

  &.SmallLaptopGrid {
    & > div.w50 {
      @media (max-width: 1360px) {
        flex: 0 0 calc(100% - 24px);
      }
    }
  }
`;

export default DashboardInners;
