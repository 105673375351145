import React, {useState, useEffect, useRef} from 'react';
import cn from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import {Formik, Form} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormControl} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import {diff} from 'deep-object-diff';
import {
  EDIT_ITEM_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DashboardField,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogItem,
  DialogItems,
  DialogLinkButton,
  FieldInput,
  FieldLabel,
  FieldTextarea
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import ItemSchema from '../../../../helpers/services/formValidationSchemas/ItemSchema';
import FileUploader from '../../../../components/FileUploader';
import {UPDATE_ITEM} from '../../../../store/menuEditorItems/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import DaysPickerContainer from '../DaysPickerContainer';
import {
  checkDuringHours,
  getAvailabilityHours,
  getAvailabilityHoursForServer
} from '../helpers';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import SelectWrapper from '../../../../components/select/SelectComponentStyles';
import {
  startActiveHours,
  selectOptions,
  startCustomHours,
  priceFormatter
} from '../constants';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../components/errorSign/errorSign';

const EditItemModal = () => {
  const dispatch = useDispatch();

  const isEditItemModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditItemModalOpen
  );

  const entityId = useSelector(
    (state) => state.menuEditorModalsReducer.entityId
  );
  const entityDuplicateInfo = useSelector(
    (state) => state.menuEditorModalsReducer.entityDuplicateInfo
  );

  const itemData = useSelector(
    (state) => state.menuEditorReducer.itemsMap[entityId]
  );

  const sectionsMap = useSelector(
    (state) => state.menuEditorReducer.sectionsMap
  );
  const sectionsDirection = useSelector(
    (state) => state.menuEditorReducer.sectionsDirection
  );
  const sectionsList = sectionsDirection.map(
    (MerchantSectionId) => sectionsMap[MerchantSectionId]
  );

  const openingDays = useDaysPickerContext();

  const groupsMap = useSelector((state) => state.menuEditorReducer.groupsMap);
  const groupsDirection = useSelector(
    (state) => state.menuEditorReducer.groupsDirection
  );
  const groupsList = groupsDirection.map((PosId) => groupsMap[PosId]);
  const formikRef = useRef();

  let initOutStateValue = 0;
  const {InStock = true, OutOfStockToNext = false} = entityDuplicateInfo || {};

  if (OutOfStockToNext) {
    initOutStateValue = 4;
  } else if (!OutOfStockToNext && !InStock) {
    initOutStateValue = 3;
  }

  const [outState, setOutState] = useState(initOutStateValue);
  const [fileUrl, setFileUrl] = useState('');
  const [expanded, setExpanded] = useState(false);

  const [selectedModifiersGroup, setSelectedModifiersGroup] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (itemData?.PictureURL !== '') {
      setFileUrl(itemData.PictureURL);
    }

    setSelectedModifiersGroup(
      itemData.OptionsGroups.map((PosId) => groupsMap[PosId])
    );
    setSelectedCategories(
      itemData.Sections.map(
        (MerchantSectionId) => sectionsMap[MerchantSectionId]
      )
    );

    return () => {
      setExpanded(false);
      setSelectedCategories([]);
      setSelectedModifiersGroup([]);
    };
  }, []);

  const initialValues = {
    Name: itemData?.Name || '',
    Price: itemData?.Price || '',
    Description: itemData?.Description || '',
    PictureURL: itemData?.PictureURL || '',
    Notes: itemData?.Notes || '',
    Brand: itemData?.Brand || '',
    ABV: itemData?.ABV || '',
    NonDeliverable: itemData?.NonDeliverable === false,
    PickUp: itemData?.NonDeliverable === true ? false : !!itemData?.PickUp,
    OldSections: itemData?.Sections || [],
    OldOptionGroups: itemData?.OptionsGroups || [],
    ExternalId: itemData?.ExternalId || '',
    Invisible: itemData?.Invisible || false,
    ContainsAlcohol: itemData?.ContainsAlcohol || false,
    AvailabilityHours: getAvailabilityHours(
      itemData?.AvailabilityHours?.length
        ? itemData?.AvailabilityHours
        : startActiveHours
    ),
    OpeningHoursChanged: [],
    Availability:
      checkDuringHours(itemData?.AvailabilityHours) === 7 ||
      !itemData?.AvailabilityHours?.length
        ? 'During opening hours'
        : 'Custom hours'
  };

  const shouldAllowFormReinitialize = diff(
    initialValues,
    formikRef?.current?.values
  );

  // const formik = useFormik({
  //   initialValues: {
  //     Name: itemData?.Name || '',
  //     Price: itemData?.Price || '',
  //     Description: itemData?.Description || '',
  //     PictureURL: itemData?.PictureURL || '',
  //     Notes: itemData?.Notes || '',
  //     Brand: itemData?.Brand || '',
  //     ABV: itemData?.ABV || '',
  //     NonDeliverable: itemData?.NonDeliverable === false,
  //     PickUp: itemData?.NonDeliverable === true ? false : !!itemData?.PickUp,
  //     OldSections: itemData?.Sections || [],
  //     OldOptionGroups: itemData?.OptionsGroups || [],
  //     ExternalId: itemData?.ExternalId || '',
  //     Invisible: itemData?.Invisible || false,
  //     ContainsAlcohol: itemData?.ContainsAlcohol || false,
  //     AvailabilityHours: itemData?.AvailabilityHours || []
  //   },
  //   enableReinitialize: true,
  //   validationSchema: ItemSchema,
  //   onSubmit: (values, {resetForm}) => {
  //     dispatch(
  //       UPDATE_ITEM({
  //         form: {
  //           ...values,
  //           Name: String(values.Name).trim(),
  //           NonDeliverable: !values.NonDeliverable,
  //           PictureURL: fileUrl,
  //           InStock: outState !== 3 && outState !== 4,
  //           OutOfStockToNext: outState === 4,
  //           OptionsGroups: [
  //             ...selectedModifiersGroup.map((group) => group.PosId)
  //           ],
  //           Sections: [
  //             ...selectedCategories.map(
  //               (category) => category.MerchantSectionId
  //             )
  //           ]
  //         }
  //       })
  //     );
  //     dispatch(EDIT_ITEM_MODAL({open: false}));
  //     resetForm();
  //   }
  // });

  const removeModifierGroup = (id) => {
    const filter = selectedModifiersGroup.filter((group) => group.PosId !== id);
    setSelectedModifiersGroup(filter);
  };

  useEffect(() => {
    if (formikRef.current) {
      const {
        values,
        resetForm,
        setFieldValue,
        isSubmitting
      } = formikRef.current;

      // if (!isSubmitting) {
      //   resetForm({...values});
      // }
      setFieldValue('OpeningHoursChanged', values.OpeningHoursChanged);
    }
  }, [formikRef.current]);

  const removeCategory = (id) => {
    const filter = selectedCategories.filter(
      (group) => group.MerchantSectionId !== id
    );
    setSelectedCategories(filter);
  };

  const handleSetOutOfStock = (isActive, value) => {
    if (isActive && outState !== value) {
      setOutState(value);
    }
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      enableReinitialize={
        !Object.keys(shouldAllowFormReinitialize || {}).length
      }
      validationSchema={ItemSchema}
      onSubmit={(values, {resetForm}) => {
        const body = {
          ...values,
          Name: String(values.Name).trim(),
          NonDeliverable: !values.NonDeliverable,
          PictureURL: fileUrl,
          InStock: outState !== 3 && outState !== 4,
          OutOfStockToNext: outState === 4,
          OptionsGroups: [
            ...selectedModifiersGroup.map((group) => group.PosId)
          ],
          Sections: [
            ...selectedCategories.map((category) => category.MerchantSectionId)
          ],
          AvailabilityHours:
            values.Availability === 'Custom hours'
              ? getAvailabilityHoursForServer(openingDays)
              : startActiveHours
        };
        delete body.Availability;
        delete body.OpeningHoursChanged;
        dispatch(
          UPDATE_ITEM({
            form: body
          })
        );
        dispatch(EDIT_ITEM_MODAL({open: false}));
        resetForm();
      }}
    >
      {({values, handleChange, submitForm, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            onClose={() => dispatch(EDIT_ITEM_MODAL({open: false}))}
            open={isEditItemModalOpen}
            scroll='body'
          >
            <DialogContainer className='DialogXl'>
              <DialogClose
                onClick={() => dispatch(EDIT_ITEM_MODAL({open: false}))}
              />
              <DialogTitle>
                Edit an <span>Item</span>
              </DialogTitle>
              <Form>
                <DialogBody>
                  <DialogFields>
                    <DialogField className='w50'>
                      <FieldLabel>Item name</FieldLabel>
                      <FieldInput
                        name='Name'
                        value={values.Name}
                        onChange={handleChange}
                        placeholder='Write item name here'
                      />
                    </DialogField>
                    <DialogField className='wSm'>
                      <FieldLabel>Price</FieldLabel>
                      <FieldInput
                        name='Price'
                        className={errors.Price && touched.Price && 'error'}
                        value={values.Price}
                        onChange={(e) => {
                          const price = priceFormatter(
                            e.target.value,
                            values.Price
                          );
                          setFieldValue('Price', price);
                        }}
                        onBlur={(e) => {
                          const lastElement = e.target.value.slice(-1)[0];
                          if (lastElement === '.') {
                            setFieldValue('Price', e.target.value.slice(0, -1));
                          }
                        }}
                        placeholder='$'
                      />
                      <ErrorSignWrapper>
                        {errors.Price && touched.Price && (
                          <ErrorSign>{errors.Price}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                    {/* <DialogField className='wSm'>
                <FieldLabel>Tax</FieldLabel>
                <FieldInput name='' placeholder='%' />
              </DialogField> */}
                    <DialogField className='w50'>
                      <FieldLabel>Item description</FieldLabel>
                      <FieldTextarea
                        name='Description'
                        className={cn('FieldTextareaLg', {
                          error: errors.Description && touched.Description
                        })}
                        value={values.Description}
                        placeholder='Write item description here'
                        onChange={handleChange}
                      />
                      <ErrorSignWrapper>
                        {errors.Description && touched.Description && (
                          <ErrorSign>{errors.Description}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>

                    <DialogField className='w50 pTop'>
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox Say2EatCheckboxSoldOut'
                          control={
                            <Checkbox
                              name='Invisible'
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              checked={values.Invisible}
                              onChange={handleChange}
                            />
                          }
                          label='Hide and remove from menu'
                        />
                      </CheckboxContainer>
                      <DialogItems
                        className={cn('DialogItemsSoldOut mTop5', {
                          isDisabled: !outState >= 1
                        })}
                      />
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox Say2EatCheckboxSoldOut'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              checked={outState >= 1}
                              onChange={() => setOutState(outState ? 0 : 1)}
                              name='ItemSoldOut'
                            />
                          }
                          label='Mark Item as Sold Out (86)'
                        />
                      </CheckboxContainer>
                      <DialogItems
                        className={cn('DialogItemsSoldOut mTop5 AlignLeft', {
                          isDisabled: !outState >= 1
                        })}
                      >
                        <DialogItem
                          className={cn({isActive: outState === 3})}
                          onClick={() => handleSetOutOfStock(!!outState, 3)}
                        >
                          <span>Mark as Unavailable</span>
                        </DialogItem>
                        <DialogItem
                          className={cn({isActive: outState === 4})}
                          onClick={() => handleSetOutOfStock(!!outState, 4)}
                        >
                          <span>Mark as Unavailable until tomorrow</span>
                        </DialogItem>
                      </DialogItems>
                    </DialogField>
                    {/* <DialogField className='w40 dFlex'> */}
                    {/*  <DialogFieldTitle className='mRight'> */}
                    {/*    Available on */}
                    {/*  </DialogFieldTitle> */}
                    {/*  <DialogCheckboxes> */}
                    {/*    <DialogCheckboxesItem> */}
                    {/*      <CheckboxContainer> */}
                    {/*        <FormControlLabel */}
                    {/*          className='Say2EatCheckbox' */}
                    {/*          control={ */}
                    {/*            <Checkbox */}
                    {/*              labelStyle={{color: '#172751'}} */}
                    {/*              iconStyle={{fill: '#FF2D38'}} */}
                    {/*              inputStyle={{color: '#FF2D38'}} */}
                    {/*              style={{color: '#FF2D38'}} */}
                    {/*              onChange={handleChange} */}
                    {/*              name='NonDeliverable' */}
                    {/*              checked={values.NonDeliverable} */}
                    {/*            /> */}
                    {/*          } */}
                    {/*          label='Delivery' */}
                    {/*        /> */}
                    {/*      </CheckboxContainer> */}
                    {/*    </DialogCheckboxesItem> */}
                    {/*    <DialogCheckboxesItem> */}
                    {/*      <CheckboxContainer> */}
                    {/*        <FormControlLabel */}
                    {/*          className='Say2EatCheckbox' */}
                    {/*          control={ */}
                    {/*            <Checkbox */}
                    {/*              labelStyle={{color: '#172751'}} */}
                    {/*              iconStyle={{fill: '#FF2D38'}} */}
                    {/*              inputStyle={{color: '#FF2D38'}} */}
                    {/*              style={{color: '#FF2D38'}} */}
                    {/*              onChange={handleChange} */}
                    {/*              name='PickUp' */}
                    {/*              checked={values.PickUp} */}
                    {/*            /> */}
                    {/*          } */}
                    {/*          label='Pick up' */}
                    {/*        /> */}
                    {/*      </CheckboxContainer> */}
                    {/*    </DialogCheckboxesItem> */}
                    {/*  </DialogCheckboxes> */}
                    {/* </DialogField> */}
                    <DialogField className='w100 dFlex'>
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              onChange={handleChange}
                              name='ContainsAlcohol'
                              checked={values.ContainsAlcohol}
                            />
                          }
                          label='Contains Alcohol'
                        />
                      </CheckboxContainer>
                    </DialogField>
                    <DialogField className='w50'>
                      <FieldLabel>Photo</FieldLabel>
                      <FileUploader
                        url={fileUrl}
                        onSetFile={setFileUrl}
                        allowedFileFormats={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg'
                        ]}
                      />
                    </DialogField>

                    <DialogField className='w50 pTop'>
                      <DialogFieldInners>
                        <DialogFieldInner className='w33'>
                          <FieldLabel>Brand</FieldLabel>
                          <FieldInput
                            name='Brand'
                            value={values.Brand}
                            onChange={handleChange}
                            placeholder='Brand'
                          />
                        </DialogFieldInner>
                        <DialogFieldInner className='w33'>
                          <FieldLabel>ABV</FieldLabel>
                          <FieldInput
                            name='ABV'
                            value={values.ABV}
                            onChange={handleChange}
                            placeholder='ABV'
                          />
                        </DialogFieldInner>
                      </DialogFieldInners>
                    </DialogField>
                    <DialogField className='w30'>
                      <DashboardField className='wAutoSpace paddingL0'>
                        <FieldLabel>Availability</FieldLabel>
                        <SelectWrapper className='SelectMd w100'>
                          <FormControl className='select-formcontrol'>
                            <Select
                              value={values.Availability}
                              name='Availability'
                              onChange={(e) => {
                                setFieldValue('Availability', e.target.value);
                                if (e.target.value === 'Custom hours') {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startCustomHours)
                                  );
                                } else {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startActiveHours)
                                  );
                                }
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                getContentAnchorEl: null
                              }}
                            >
                              {selectOptions.map((item) => (
                                <MenuItem
                                  className='reportTypeItem'
                                  key={nanoid()}
                                  value={item.name}
                                  disableRipple
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </SelectWrapper>
                      </DashboardField>
                    </DialogField>
                    {values.Availability === 'Custom hours' && (
                      <DialogField className='w100'>
                        <DaysPickerContainer
                          WorkingDays={values?.AvailabilityHours}
                        />
                      </DialogField>
                    )}
                    <DialogField className='w100'>
                      <FieldLabel className='FieldLabelLg'>
                        Notes (for internal use)
                      </FieldLabel>
                      <FieldInput
                        name='Notes'
                        value={values.Notes}
                        onChange={handleChange}
                        placeholder='Write your notes here'
                      />
                    </DialogField>

                    <DialogField className='w50 Say2EatSelect'>
                      {!!selectedModifiersGroup.length && (
                        <AccordionItem className='AccordionItemModal'>
                          <Accordion
                            onChange={handleAccordionChange('modifierGroups')}
                            expanded={
                              expanded === 'modifierGroups' || expanded === true
                            }
                          >
                            <AccordionSummary
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <AccordionItemName className='AccordionItemName'>
                                {`Modifier groups including (${selectedModifiersGroup.length})`}
                              </AccordionItemName>
                            </AccordionSummary>
                            <AccordionItemBody>
                              <DialogFields>
                                {selectedModifiersGroup.map((group) => (
                                  <DialogField
                                    key={group.PosId}
                                    className='w100 withRemove'
                                  >
                                    <FieldInput
                                      name=''
                                      readOnly
                                      value={group.OptionName}
                                      placeholder={group.OptionName}
                                    />
                                    <DialogFieldRemove
                                      onClick={() =>
                                        removeModifierGroup(group.PosId)
                                      }
                                    />
                                  </DialogField>
                                ))}
                              </DialogFields>
                            </AccordionItemBody>
                          </Accordion>
                        </AccordionItem>
                      )}
                      <DialogField className='Center w100'>
                        <DialogLinkButton
                          onClick={() => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: true,
                                itemType: 'modifierGroup',
                                itemList: groupsList,
                                selectedItems: selectedModifiersGroup,
                                loading: false,
                                callBack: setSelectedModifiersGroup,
                                closeModal: () => {
                                  dispatch(
                                    SELECT_ITEM_MODAL_OPEN({
                                      open: false,
                                      itemType: '',
                                      queryParams: {},
                                      itemList: [],
                                      loading: false,
                                      selectedItems: [],
                                      callBack: () => {},
                                      closeModal: () => {}
                                    })
                                  );
                                }
                              })
                            );
                          }}
                        >
                          Add a Modifier Group
                        </DialogLinkButton>
                      </DialogField>
                    </DialogField>

                    <DialogField className='w50 Say2EatSelect'>
                      {!!selectedCategories.length && (
                        <AccordionItem className='AccordionItemModal'>
                          <Accordion
                            onChange={handleAccordionChange('categories')}
                            expanded={
                              expanded === 'categories' || expanded === true
                            }
                          >
                            <AccordionSummary
                              aria-controls='panel1a-content'
                              id='panel1a-header'
                            >
                              <AccordionItemName className='AccordionItemName'>
                                {`Parent categories (${selectedCategories.length})`}
                              </AccordionItemName>
                            </AccordionSummary>
                            <AccordionItemBody>
                              <DialogFields>
                                {selectedCategories.map((section) => (
                                  <DialogField
                                    className='w100 withRemove'
                                    key={section.MerchantSectionId}
                                  >
                                    <FieldInput
                                      name=''
                                      readOnly
                                      value={section.Name}
                                      placeholder={section.Name}
                                    />
                                    <DialogFieldRemove
                                      onClick={() =>
                                        removeCategory(
                                          section.MerchantSectionId
                                        )
                                      }
                                    />
                                  </DialogField>
                                ))}
                              </DialogFields>
                            </AccordionItemBody>
                          </Accordion>
                        </AccordionItem>
                      )}
                      <DialogField className='Center w100'>
                        <DialogLinkButton
                          onClick={() => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: true,
                                itemType: 'category',
                                itemList: sectionsList,
                                selectedItems: selectedCategories,
                                loading: false,
                                callBack: setSelectedCategories,
                                closeModal: () => {
                                  dispatch(
                                    SELECT_ITEM_MODAL_OPEN({
                                      open: false,
                                      itemType: '',
                                      queryParams: {},
                                      itemList: [],
                                      loading: false,
                                      selectedItems: [],
                                      callBack: () => {},
                                      closeModal: () => {}
                                    })
                                  );
                                }
                              })
                            );
                          }}
                        >
                          Add Parent Categories
                        </DialogLinkButton>
                      </DialogField>
                    </DialogField>
                  </DialogFields>

                  <DialogAction>
                    <ButtonPrimary onClick={() => submitForm()}>
                      <span>Save Changes</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogBody>
              </Form>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default EditItemModal;
