import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import CreateButton from './CreateButton';
import PublishButton from './PublishButton';
import Headline from '../../../../components/Headline/styles';

export default function HeadLine() {
  const buttonScrollTopLimit = 0;
  const [buttonIsFixed, setButtonIsFixed] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setButtonIsFixed(
        e.target.documentElement.scrollTop > buttonScrollTopLimit
      );
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <Headline className='HeadLineMenu'>
      Menu Editor
      <div
        className={cn('buttons', {
          isFixed: buttonIsFixed
        })}
      >
        <PublishButton />
        <CreateButton />
      </div>
    </Headline>
  );
}
