import {createReducer} from 'redux-act';
import {
  GET_ADDRESSES_SUCCESS,
  GET_BAD_ORDER_DETAIL_SUCCESS,
  HANDLE_FAIL_MODAL,
  HANDLE_SUCCESS_MODAL,
  POST_BAD_ORDER,
  POST_BAD_ORDER_SUCCESS,
  SET_BAD_ORDER_DETAIL_SUCCESS,
  CLEAR_BAD_ORDER_DETAIL,
  CHECK_ADDRESS_SUCCESS,
  CHECK_ADDRESS
} from './action';

const initialState = () => ({
  order: {},
  orderDetail: {},
  openSuccessModal: false,
  openFailModal: false,
  successData: {},
  failData: {},
  addresses: {},
  checkAddress: {},
  checkAddressLoading: false
});

const bookADriverCreateReducer = createReducer(
  {
    [POST_BAD_ORDER]: (state) => ({
      ...state
    }),
    [POST_BAD_ORDER_SUCCESS]: (state, data) => ({
      ...state,
      order: data
    }),
    [GET_BAD_ORDER_DETAIL_SUCCESS]: (state, data) => ({
      ...state,
      order: data
    }),
    [SET_BAD_ORDER_DETAIL_SUCCESS]: (state, data) => ({
      ...state,
      order: data
    }),
    [CLEAR_BAD_ORDER_DETAIL]: (state) => ({
      ...state,
      order: {},
      addresses: {},
      checkAddress: {},
      checkAddressLoading: false
    }),
    [HANDLE_SUCCESS_MODAL]: (state, data) => ({
      ...state,
      openSuccessModal: data.openSuccessModal,
      successData: data.successData
    }),
    [HANDLE_FAIL_MODAL]: (state, data) => ({
      ...state,
      openFailModal: data.openFailModal,
      failData: data.failData
    }),
    [GET_ADDRESSES_SUCCESS]: (state, data) => ({
      ...state,
      addresses: data
    }),
    [CHECK_ADDRESS]: (state) => ({
      ...state,
      checkAddressLoading: true
    }),
    [CHECK_ADDRESS_SUCCESS]: (state, data) => ({
      ...state,
      checkAddress: data,
      checkAddressLoading: false
    })
  },
  initialState()
);

export default bookADriverCreateReducer;
