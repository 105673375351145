import React, {useEffect} from 'react';
import {Route, useHistory, useRouteMatch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Orders from './OrdersList';
import OrderDetails from './OrderDetails';
import AdjustPayment from './AdjustPayment';
import OrderRefund from './OrderDetails/Refund';
import {
  FEATURE_RESTRICTION_IDS,
  STRIPE_ACCOUNT_RESTRICTION_NAMES
} from '../../../helpers/constants/restrictions';
import RestrictedArea from '../../../components/layout/RestrictedArea';

const orderListHidden = (pathname = '') => {
  const replaced = pathname.split('/').join('');
  return replaced !== 'ordersactive' && replaced !== 'ordershistory';
};

const OrdersRoutes = () => {
  const match = useRouteMatch();
  const {
    location: {pathname}
  } = useHistory();
  const history = useHistory();
  const serviceFeatures = useSelector(
    (state) => state.user.activeLocation.AllowedServiceFeatures
  );
  const {activeLocation} = useSelector((state) => state.user);

  useEffect(() => {
    if (
      pathname.includes('/refund') &&
      (!serviceFeatures.includes(FEATURE_RESTRICTION_IDS.REFUNDS) ||
        activeLocation.StripeAccountType ===
          STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD)
    ) {
      history.push(pathname.substring(0, pathname.indexOf('/refund')));
    }
  }, []);

  return (
    <>
      <Route
        path={`${match.path}/`}
        render={() => <Orders hidden={orderListHidden(pathname)} />}
      />
      <Route
        exact
        path={`${match.path}/:back/:id/details`}
        component={OrderDetails}
      />
      <RestrictedArea
        restrictions={[]}
        features={[FEATURE_RESTRICTION_IDS.REFUNDS]}
        extraAccessLogic={() =>
          activeLocation.StripeAccountType !==
          STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD
        }
      >
        <Route
          exact
          path={`${match.path}/:back/:id/details/refund`}
          component={OrderRefund}
        />
      </RestrictedArea>
      <Route
        exact
        path={`${match.path}/:id/details/adjust`}
        render={() => <AdjustPayment />}
      />
    </>
  );
};

export default OrdersRoutes;
