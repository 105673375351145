import styled, {keyframes} from 'styled-components';

export const PulsatingDotContainer = styled.div`
  position: relative;
`;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${({color}) => color};
  border-radius: 50%;
  position: absolute;

  &.orderDetails {
    top: 5px;
  }

  &.ordersTable {
    top: 10px;
    left: -26px;
  }
`;

const pulsate = keyframes`
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
`;

export const Pulsating = styled.div`
  border: 3px solid ${({color}) => color};
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: ${pulsate} 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;

  &.orderDetails {
    left: -5px;
  }

  &.ordersTable {
    top: 5px;
    left: -31px;
  }
`;
