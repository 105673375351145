import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Box} from '@material-ui/core';
import moment from 'moment';
import cn from 'classnames';
import {ReportButtonPrimary} from './ReportsTable/styles';
import Headline from '../../../components/Headline/styles';
import Loading from '../../../components/shared/Utils/Loading';
import {
  EXPORT_REPORTS,
  GENERATE_REPORT,
  SET_DATEPICKER_INDEX,
  SET_REPORT_TYPE,
  SET_REPORT_VISITED_STATISTIC,
  SET_REPORTS_DATE_INTERVAL
} from '../../../store/reports/action';
import {DashboardContent} from '../../../components/DashboardContent/styles';
import {DashboardHeader} from '../../../components/DashboardHeader/styles';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import IconExportReport from '../../../assets/IconExportReport';
import ReportsDataPicker from './ReportsDataPicker/ReportsDataPicker';
import ReportsTable from './ReportsTable/ReportsTable';
import {
  FIELDS_CUSTOMERS,
  FIELDS_SALES_SUMMARY,
  FIELDS_PAYOUT_SUMMARY,
  FIELDS_BOOK_A_DRIVER,
  FIELDS_BYOC,
  TOTAL_SALES_SUMMARY,
  TOTAL_PAYOUT_SUMMARY,
  FIELDS_REFUNDS_SUMMARY,
  TOTAL_REFUNDS_SUMMARY,
  FIELDS_FEEDBACK_SUMMARY,
  TOTAL_CUSTOMERS
} from '../../../helpers/constants/reports/index';
import ModalReportVideo from './modals/ReportVideo';
import {TopLineNav} from '../../../components/layout';
import LinkBack from '../../../components/LinkBack/styles';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import {getSessionStorageItem} from '../../../helpers/sessionStorage';
import SESSION_SAUCE_EMPLOYEE_NAME from '../../../helpers/sessionStorage/constants';
import {STRIPE_ACCOUNT_RESTRICTION_NAMES} from '../../../helpers/constants/restrictions';
import FullScreenLoading from '../../../components/shared/Utils/FullScreenLoading/FullScreenLoading';

const ReportsContainer = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const [previousLocationState, setPreviousLocationState] = useState(null);
  const {
    dataLoading,
    generationLoading,
    exportLoading,
    data,
    reportType,
    totalRow,
    dataCount,
    dateRange,
    quantityData
  } = useSelector((state) => state.reportsReducer);
  const Timezone = useSelector((state) => state.user.activeLocation.Timezone);
  const isCheckStripeAccountExpress = useSelector((state) =>
    state.user.locations.find(
      (el) =>
        el.StripeAccountType ===
        STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_EXPRESS
    )
  );

  const employeeView = getSessionStorageItem(SESSION_SAUCE_EMPLOYEE_NAME);
  const fieldSelector = {
    0: FIELDS_SALES_SUMMARY,
    1: FIELDS_CUSTOMERS,
    2: FIELDS_PAYOUT_SUMMARY,
    3: FIELDS_BYOC,
    4: FIELDS_BOOK_A_DRIVER,
    5: FIELDS_REFUNDS_SUMMARY,
    6: FIELDS_FEEDBACK_SUMMARY
  };
  const totalFieldSelector = {
    0: TOTAL_SALES_SUMMARY,
    1: TOTAL_CUSTOMERS,
    2: TOTAL_PAYOUT_SUMMARY,
    5: TOTAL_REFUNDS_SUMMARY
    //    6: TOTAL_FEEDBACK_SUMMARY
  };

  const [initialReq, setInitialReq] = useState(false);
  const [fields, setFields] = useState(fieldSelector[reportType]);
  const [totalField, setTotalField] = useState(totalFieldSelector[reportType]);

  const defaultDateArr = [
    moment()
      .utcOffset(Timezone, false)
      .utc()
      .subtract(1, 'week')
      .startOf('isoWeek')
      .format('YYYY-MM-DD'),
    moment()
      .utcOffset(Timezone, false)
      .utc()
      .subtract(1, 'week')
      .endOf('isoWeek')
      .format('YYYY-MM-DD')
  ];

  const generateButtonHandler = () => {
    setFields(fieldSelector[reportType]);
    setTotalField(totalFieldSelector[reportType]);
    dispatch(GENERATE_REPORT());
  };

  useEffect(() => {
    if (
      location?.state ||
      (location?.state === undefined &&
        !Object.keys(previousLocationState || {}).length)
    ) {
      setPreviousLocationState(() => location.state);
    }
  }, [location?.state]);

  useDidMountEffect(() => {
    if (
      previousLocationState?.fromFeedback &&
      previousLocationState?.datePickerConfig
    ) {
      const {
        startDate,
        endDate,
        datePickerSelectIndex
      } = previousLocationState?.datePickerConfig;
      dispatch(SET_REPORT_TYPE(6));
      dispatch(SET_REPORTS_DATE_INTERVAL([startDate, endDate]));
      dispatch(SET_DATEPICKER_INDEX(datePickerSelectIndex));
    } else {
      dispatch(SET_REPORTS_DATE_INTERVAL(defaultDateArr));
      dispatch(GENERATE_REPORT());
    }
  }, [previousLocationState]);

  useDidMountEffect(() => {
    if (
      reportType === 6 &&
      !initialReq &&
      previousLocationState?.fromFeedback
    ) {
      generateButtonHandler();
      setInitialReq(() => true);
    }
  }, [reportType]);

  useEffect(() => {
    return function resetSettings() {
      dispatch(SET_REPORT_VISITED_STATISTIC(null));
      dispatch(SET_REPORT_TYPE(2));
      dispatch(SET_REPORTS_DATE_INTERVAL(defaultDateArr));
      dispatch(SET_DATEPICKER_INDEX(3));
    };
  }, []);

  // useEffect(() => {
  //   dispatch(GET_REPORTS());
  // }, [skip]);

  return (
    <>
      <DashboardContent className='DashboardContent'>
        {previousLocationState?.fromFeedback && (
          <TopLineNav>
            <LinkBack
              className='marginBottomXs'
              onClick={() => {
                history.push('/feedback');
              }}
            >
              Back to Feedback
            </LinkBack>
          </TopLineNav>
        )}
        <DashboardHeader className='alwaysFlex'>
          <div>
            <Headline>Reports</Headline>
          </div>
          <Box textAlign='center' style={{width: '188px', minHeight: '46px'}}>
            {exportLoading ? (
              <Loading className='LoadingExportButton' />
            ) : (
              <ReportButtonPrimary
                className={cn({
                  disabled:
                    reportType !== data.reportTypeInner ||
                    dateRange[0] !== data.reportPeriod[0] ||
                    dateRange[1] !== data.reportPeriod[1] ||
                    (data.dataRows && data.dataRows.length < 1)
                })}
                data-gtm='exportAReportButton'
                primary
                onClick={() => {
                  dispatch(EXPORT_REPORTS());
                }}
              >
                <div>
                  <IconExportReport />
                </div>
                <span data-gtm='exportAReportButton'>Export</span>
              </ReportButtonPrimary>
            )}
          </Box>
        </DashboardHeader>
        <DashboardInner className='paddingReportsDataPicker'>
          <ReportsDataPicker generateButtonHandler={generateButtonHandler} />
        </DashboardInner>
        {generationLoading ? (
          <FullScreenLoading />
        ) : (
          <ReportsTable
            loading={dataLoading}
            fields={fields}
            dataObject={data}
            totalField={totalField}
            totalRow={totalRow}
            dataCount={dataCount}
          />
        )}
      </DashboardContent>
      {isCheckStripeAccountExpress &&
        !employeeView &&
        !window.AndroidDashboard &&
        quantityData === 0 && <ModalReportVideo />}
    </>
  );
};

export default ReportsContainer;
