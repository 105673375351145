import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../components/errorSign/errorSign';

export default function RefundReasonSelect({
  values,
  setFieldValue,
  errors,
  touched,
  setTouched
}) {
  const options = {
    '': [],
    Restaurant: [
      {name: 'Missing or wrong items'},
      {name: 'Food quality issue'},
      {name: 'Food was damaged'},
      {name: 'Order was canceled'},
      {name: 'Other'}
    ],
    Customer: [{name: 'Other'}]
  };

  const fault = values.refundType === 'Full' ? 'faultFull' : 'faultPartial';
  const reason = values.refundType === 'Full' ? 'reasonFull' : 'reasonPartial';

  return (
    <Box sx={{minWidth: 120}} className='Say2EatDrop'>
      <FormControl
        fullWidth
        variant='outlined'
        disabled={values[fault] === '' || values[fault] === 'Customer'}
        className={cn({
          error: errors[reason] && touched[reason] && 'error'
        })}
      >
        <Select
          displayEmpty
          name='reason'
          className='refundSelect'
          id='refund-reason-select'
          value={values[reason]}
          onChange={(e) => {
            setTouched({}, false);
            setFieldValue(reason, e.target.value);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem disableRipple disabled className='reportTypeItem' value=''>
            Select a reason
          </MenuItem>
          {options[values[fault]].map((item) => (
            <MenuItem
              className='reportTypeItem'
              key={nanoid()}
              value={item.name}
              disableRipple
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <ErrorSignWrapper className='positionBottom'>
          <ErrorSign>{touched[reason] && errors[reason]}</ErrorSign>
        </ErrorSignWrapper>
      </FormControl>
    </Box>
  );
}

RefundReasonSelect.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  setTouched: PropTypes.func.isRequired
};
