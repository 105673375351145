import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import HiddenView from './HiddenView';
import {SET_ACTIVE_TAB} from '../../../../store/menuEditor/action';
import ElementsNotFoundMessage from '../ElementsNotFoundMessage';

const HiddenContainer = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.menuEditorReducer.activeTab);
  const sectionsList = useSelector(
    (state) => state.menuEditorReducer.activeSectionsDirection
  );
  const itemsList = useSelector(
    (state) => state.menuEditorReducer.activeItemsDirection
  );
  const modifiersList = useSelector(
    (state) => state.menuEditorReducer.activeModifiersDirection
  );

  useEffect(() => dispatch(SET_ACTIVE_TAB('HIDDEN')), []);

  const [expanded, setExpanded] = useState({
    items: false,
    modifiers: false,
    categories: false
  });

  const handleAccordionOpen = (accordionName) => {
    if (expanded[accordionName] === accordionName) {
      setExpanded((prevState) => {
        return {
          ...prevState,
          [accordionName]: false
        };
      });
      return;
    }
    setExpanded((prevState) => {
      return {
        ...prevState,
        [accordionName]: accordionName
      };
    });
  };

  if (
    (!sectionsList && !itemsList && !modifiersList) ||
    activeTab !== 'HIDDEN'
  ) {
    return null;
  }

  if (!sectionsList.length && !itemsList.length && !modifiersList.length) {
    return (
      <ElementsNotFoundMessage message='You don’t have any hidden elements yet.' />
    );
  }

  return (
    <>
      <HiddenView
        expanded={expanded}
        handleAccordionOpen={handleAccordionOpen}
        sectionsData={sectionsList}
        itemsData={itemsList}
        modifiersData={modifiersList}
      />
    </>
  );
};

export default HiddenContainer;
