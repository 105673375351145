import styled from 'styled-components';

export const InsightsGrowsMetricContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;
export const InsightsGrowsMetric = styled.div`
  width: 100%;
  max-width: 485px;
  height: 100%;
  max-height: 280px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 19px 20px 22px 24px;
  box-shadow: 0 8px 16px 0 rgba(221, 221, 221, 0.32);
  .growthMetricTitle {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    span {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      color: #2f3d48;
    }
  }
  svg {
    width: 100%;
    max-width: 341px;
    height: 100%;
    max-height: 184px;
    .activeElement {
      transform-origin: 186px 182px;
      transform: ${(props) =>
        props.rotateValue ? `rotate(${props.rotateValue}deg)` : 'rotate(0deg)'};
      transition: 2s;
    }
  }
`;

export const InsightsGrowthFeaturesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
  margin: 18px -13px -13px -13px;
`;

export const InsightsGrowthFeatureBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 13px;
  width: calc(25% - 26px);
  flex: 0 0 auto;
  justify-content: space-between;
  @media (max-width: 1085px) {
    width: calc(33.333333% - 26px);
  }
  @media (max-width: 820px) {
    width: calc(50% - 26px);
  }
  @media (max-width: 535px) {
    width: 100%;
  }
`;

export const InsightsGrowthFeatureTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 16px 0 16px;
  .featureIcon {
    width: 28px;
    height: 28px;
  }
  .featureChecked {
    width: 20px;
    height: 20px;
  }
`;

export const InsightsGrowthFeatureName = styled.div`
  margin-top: 11px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 0 16px;
  width: 100%;
`;

export const InsightsGrowthFeatureDescription = styled.div`
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 10px;
  @media (max-width: 1370px) {
    font-size: 10px;
  }
  @media (max-width: 1250px) {
    font-size: 12px;
  }
  @media (max-width: 1130px) {
    font-size: 10px;
  }
  @media (max-width: 1085px) {
    font-size: 12px;
  }
  @media (max-width: 850px) {
    font-size: 10px;
  }
  @media (max-width: 820px) {
    font-size: 12px;
  }
  @media (max-width: 550px) {
    font-size: 10px;
  }
  @media (max-width: 535px) {
    font-size: 12px;
  }
`;

export const InsightsGrowthFeatureMotivation = styled.div`
  background-color: rgb(229, 229, 229, 0.3);
  padding: 7px 16px 6px 16px;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  @media (max-width: 1370px) {
    font-size: 8px;
  }
  @media (max-width: 1250px) {
    font-size: 10px;
  }
  @media (max-width: 1140px) {
    font-size: 8px;
  }
  @media (max-width: 1085px) {
    font-size: 10px;
  }
  @media (max-width: 850px) {
    font-size: 8px;
  }
  @media (max-width: 820px) {
    font-size: 10px;
  }
  @media (max-width: 550px) {
    font-size: 8px;
  }
  @media (max-width: 535px) {
    font-size: 10px;
  }
`;

export const InsightsGrowthFeatureButtons = styled.div`
  padding: 7px 0 6px 16px;
  display: flex;
  align-items: center;
  height: 34px;
  div {
    // width: 50%;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    @media (max-width: 1400px) {
      font-size: 13px;
    }
    @media (max-width: 1285px) {
      font-size: 12px;
    }
    @media (max-width: 1250px) {
      font-size: 14px;
    }
  }
  a {
    color: #ff2d38;
    text-decoration: none;
    margin-right: 20px;
  }
  span {
    color: #ff2d38;
    cursor: pointer;
  }
`;
