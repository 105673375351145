import * as Yup from 'yup';

const phoneRegex = /^([+]1)?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const BYOCDisconnectSchema = Yup.object().shape({
  FullName: Yup.string()
    .max(250, 'Full name is too long')
    .trim('The field cannot contain only spaces.')
    .required('Full name field is required!'),
  Email: Yup.string()
    .matches(emailRegex, 'Please enter valid email!')
    .required('Email field is required!'),
  Phone: Yup.string()
    .matches(phoneRegex, 'Please enter valid phone')
    .required('Phone field is required!'),
  Reason: Yup.string()
    .max(750, 'Disconnect reason is too long')
    .trim('The field cannot contain only spaces.')
    .required('Disconnect reason field is required!')
});

export default BYOCDisconnectSchema;
