import React from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import {ButtonPrimary} from '../../../../components/buttons';
import {UberEatsImageBox, UberEatsImageGrid} from '../styles';
import ComissionIcon from '../../../../assets/uberEats/icon-connect-comission.svg';
import CustomersIcon from '../../../../assets/uberEats/icon-connect-customers.svg';
import OrdersIcon from '../../../../assets/uberEats/icon-connect-orders.svg';
import PlatformsIcon from '../../../../assets/uberEats/icon-connect-platforms.svg';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle0 from '../../../../components/typography/Subtitle0';
import {DashboardActionCenter} from '../../dashboard/styles';

const ConnectUberPage = ({onConnect}) => {
  // const merchantId = useSelector((state) => state.user.userData?.merchant_id);
  // const history = useHistory();
  // const dispatch = useDispatch();
  // const UEConnected = useSelector(
  //   (state) => state.uberEatsReducer.uberEatsData?.isActivated
  // );
  // const {isUberEatsAccountConnected} = useSelector(
  //   (state) => state.uberEatsReducer
  // );
  // const onConnect = () => {
  //   if (!isUberEatsAccountConnected) {
  //     dispatch(CHANGE_UBER_STATUS_SUCCESS(!UEConnected));
  //     //    if (!uberEatsInitiallyConnected) {
  //     window.open(
  //       `${process.env.REACT_APP_UBEREATS_API_URL}/api/uber-eats-adapter/v1/merchants/connect?id=${merchantId}&returnUrl=${process.env.REACT_APP_ROOT_DOMAIN}/uber-eats/connect`,
  //       '_self'
  //     );
  //   } else {
  //     history.push('/uber-eats/byoc-wizard');
  //   }
  // };

  const UBER_PROMO_CARDS = [
    {icon: ComissionIcon, text: 'Cut UberEats comission', key: nanoid()},
    {icon: CustomersIcon, text: 'Reach new customers', key: nanoid()},
    {icon: OrdersIcon, text: 'Manage all orders in one place', key: nanoid()},
    {icon: PlatformsIcon, text: 'Sync menus on all platforms', key: nanoid()}
  ];

  return (
    <DashboardInner className='paddingXXl mTop30'>
      <>
        <Subtitle0 className='text-center'>
          Connect your UberEats account to Sauce{' '}
        </Subtitle0>
        <UberEatsImageGrid>
          {UBER_PROMO_CARDS.length &&
            UBER_PROMO_CARDS.map((img) => (
              <UberEatsImageBox key={img.key}>
                <img src={img.icon} alt='promo-icon' />
                <strong>{img.text}</strong>
              </UberEatsImageBox>
            ))}
        </UberEatsImageGrid>
        <DashboardActionCenter>
          <ButtonPrimary
            className='w460'
            onClick={() => {
              onConnect();
            }}
            data-gtm='createUberEatsBigButton'
          >
            <span data-gtm='createUberEatsBigButton'>Connect UberEats</span>
          </ButtonPrimary>
        </DashboardActionCenter>
      </>
    </DashboardInner>
  );
};

ConnectUberPage.propTypes = {
  onConnect: PropTypes.func.isRequired
};

export default ConnectUberPage;
