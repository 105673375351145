import styled from 'styled-components';

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.Center {
    justify-content: center;
  }

  @media (max-width: 1200px) {
    margin-top: 35px;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
    display: block;
    &.AlwaysFlex {
      display: flex;
    }
  }
  @media (max-width: 599px) {
    margin-top: 20px;
    display: block;
    &.AlwaysFlex {
      display: flex;
    }
  }

  h2 {
    @media (max-width: 599px) {
      margin-bottom: 10px;
    }
  }

  button {
    flex: 0 0 auto;
  }

  &.whiteBgTablet {
    @media (max-width: 1200px) {
      margin: 0 -80px;
      background-color: #fff;
      padding: 17.5px 80px;
    }
    @media (max-width: 1000px) {
      margin: 0 -60px;
      padding: 15.5px 60px;
    }
  }
  &.OrderHeader {
    @media (max-width: 767px) {
      display: block;
    }
  }
  &.alwaysFlex {
    display: flex;
    @media (max-width: 767px) {
      display: flex;
    }
    @media (max-width: 599px) {
      display: flex;
    }
  }

  &.realTimeBoardV2 {
    display: flex;
    flex-wrap: wrap;
  }

  &.flex0 {
    flex: 0 0 auto;
  }
`;

export default DashboardHeader;
