import React from 'react';
import {useSelector} from 'react-redux';
import SatisfactionContainer from './SatisfactionContainer';
import {DashboardInners} from '../../../../../components/DashboardInners/styles';
import StatisticsSurvey from './StatisticsSurvey';
import {feedbackRankingTypes} from '../../constants';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import normalizePercentageByDecimal from '../../../../../helpers/functionUtils/normalizePercentageByDecimal';

const TabPickup = () => {
  const {
    StarsTotalCount,
    OneStarsCount,
    AverageSatisfactionRate,
    TwoStarsCount,
    ThreeStarsCount,
    FourStarsCount,
    FiveStarsCount,
    BadRankingTotal,
    SurveyAnswers,
    GoodRankingTotal
  } = useSelector((state) => state.feedbackReducer.data);

  const calculatePercentage = (field1, field2) => {
    const percentage = 100 / (field1 / field2);
    return Number.isNaN(percentage) ? 0 : percentage;
  };
  const displayAnswersStats = (fieldName, fieldValue, surveyArr) =>
    surveyArr?.find((el) => el.Name === fieldName && el.Value === fieldValue)
      ?.Occurrence || 0;

  const [
    percentOneStar,
    percentTwoStar,
    percentThreeStar,
    percentFourStar,
    percentFiveStar
  ] = normalizePercentageByDecimal([
    calculatePercentage(StarsTotalCount, OneStarsCount),
    calculatePercentage(StarsTotalCount, TwoStarsCount),
    calculatePercentage(StarsTotalCount, ThreeStarsCount),
    calculatePercentage(StarsTotalCount, FourStarsCount),
    calculatePercentage(StarsTotalCount, FiveStarsCount)
  ]);
  return (
    <>
      <DashboardInner>
        <SatisfactionContainer
          title='Pickup satisfaction grade'
          description='Average satisfaction rate for pickup orders'
          ratingSatisfaction={AverageSatisfactionRate?.toFixed(1) || 0}
          percentOneStar={percentOneStar}
          responseOneStar={OneStarsCount}
          percentTwoStar={percentTwoStar}
          responseTwoStar={TwoStarsCount}
          percentThreeStar={percentThreeStar}
          responseThreeStar={ThreeStarsCount}
          percentFourStar={percentFourStar}
          responseFourStar={FourStarsCount}
          percentFiveStar={percentFiveStar}
          responseFiveStar={FiveStarsCount}
        />
      </DashboardInner>
      <DashboardInners>
        <StatisticsSurvey
          title='1-3 Stars - Oh no!'
          description='Please share what went wrong?'
          total={BadRankingTotal}
          sumFirstQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            0,
            SurveyAnswers
          )}
          firstQuestionText='Order wasn&#10076;t ready on time'
          sumSecondQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            1,
            SurveyAnswers
          )}
          secondQuestionText='Missing items'
          sumThirdQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            2,
            SurveyAnswers
          )}
          thirdQuestionText='Packaging issues'
          sumFourthQuestion={displayAnswersStats(
            feedbackRankingTypes.BadFeedback,
            3,
            SurveyAnswers
          )}
          fourthQuestionText='Food quality'
        />
        <StatisticsSurvey
          title='4-5 Stars - Alright!'
          description='What did you like best?'
          total={GoodRankingTotal}
          sumFirstQuestion={
            SurveyAnswers?.find(
              (el) =>
                el.Name === feedbackRankingTypes.GoodFeedback && el.Value === 0
            )?.Occurrence || 0
          }
          firstQuestionText='The order was ready on time'
          sumSecondQuestion={
            SurveyAnswers?.find(
              (el) =>
                el.Name === feedbackRankingTypes.GoodFeedback && el.Value === 1
            )?.Occurrence || 0
          }
          secondQuestionText='I received exactly what I ordered'
          sumThirdQuestion={
            SurveyAnswers?.find(
              (el) =>
                el.Name === feedbackRankingTypes.GoodFeedback && el.Value === 2
            )?.Occurrence || 0
          }
          thirdQuestionText='The food was delicious'
          sumFourthQuestion={
            SurveyAnswers?.find(
              (el) =>
                el.Name === feedbackRankingTypes.GoodFeedback && el.Value === 3
            )?.Occurrence || 0
          }
          fourthQuestionText='Great value for money'
        />
      </DashboardInners>
    </>
  );
};

export default TabPickup;
