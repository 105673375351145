import styled from 'styled-components';
import IconSearch from '../../assets/icon-search.svg';
import IconSearchDark from '../../assets/icon-search-dark.svg';

const Field = styled.div`
  display: block;
  position: relative;

  &.withClear {
    input {
      padding-right: 30px;

      @media (max-width: 1360px) {
        padding-right: 26px;
      }
    }
    .ButtonClear {
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      @media (max-width: 1360px) {
        right: 6px;
      }
    }
  }

  input {
    display: block;
    width: 100%;
    height: 56px;
    padding: 0 16px;
    background-color: #f7f7fa;
    border: 1px solid #d5d8de;
    border-radius: 6px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: rgba(23, 39, 81, 1);

    &::placeholder {
      color: rgba(23, 39, 81, 0.6);
    }

    &.FieldSearch {
      padding-left: 48px;
      background-image: url('${IconSearch}');
      background-repeat: no-repeat;
      background-position: center left 17px;
      background-color: #fff;
      height: 44px;
      border-radius: 10px;
      border-color: #fff;
      @media (max-width: 1440px) {
        padding-left: 38px;
        background-position: center left 12px;
      }
      @media (max-width: 1360px) {
        padding-left: 30px;
        background-position: center left 8px;
      }
      &.Lg {
        height: 56px;
        padding-left: 52px;
        background-position: center left 20px;
        font-weight: 700;
        border-radius: 6px;
        transition-property: padding-left;
        transition-duration: 0.3s;
        &::placeholder {
          font-weight: 400;
        }
        &:focus,
        &.notEmpty {
          background-image: none;
          padding-left: 16px;
        }
      }
    }
    &.textUppercase {
      text-transform: uppercase;
      &::placeholder {
        text-transform: none;
      }
    }
    &.DarkSearchIcon {
      background-image: url('${IconSearchDark}');
    }
    &.Outline {
      background-color: transparent;
      border: 1px solid #d5d8de;

      &::placeholder {
        color: #929cb9;
      }
    }
    &.colorGrey {
      color: #929cb9;
    }
    &.BgGrey {
      background-color: #f7f7fa;
      border-color: #d5d8de;
    }

    &[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
    &.error {
      border: 1px solid #ff2d38;
      background-color: #f9e4e5;
      color: #ff2d38;
      &::placeholder {
        color: #ff2d38;
        font-weight: 700 !important;
      }
    }
  }

  textarea {
    display: block;
    width: 100%;
    height: 125px;
    padding: 12px 16px;
    background-color: #f7f7fa;
    border: 1px solid #d5d8de;
    border-radius: 6px;
    outline: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: rgba(23, 39, 81, 1);

    &::placeholder {
      color: rgba(23, 39, 81, 0.6);
    }
    &.BgGrey {
      background-color: #f7f7fa;
      border-color: #d5d8de;
    }
  }
`;

export default Field;
