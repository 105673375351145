import IconInsightsPerformancePositiveReviews from 'assets/insights/icon-insights-performance-positive-reviews.svg';
import IconInsightsPerformanceDelivery from 'assets/insights/icon-insights-performance-delivery.svg';
import IconInsightsPerformanceFeedbackHandling from 'assets/insights/icon-insights-performance-feedback-handling.svg';
import IconInsightsPerformanceEmailMarketing from 'assets/insights/icon-insights-performance-email-marketing.svg';
import IconInsightsPerformanceRevenue from 'assets/insights/icon-insights-performance-revenue.svg';
import IconInsightsPerformanceGoogleRevenue from 'assets/insights/icon-insights-performance-google-revenue.svg';
import IconInsightsPerformanceSauceRevenue from 'assets/insights/icon-insights-performance-sauce-revenue.svg';
import IconInsightsPerformanceCommissionsSaved from 'assets/insights/icon-insights-performance-commissions-saved.svg';
import IconInsightsPerformanceReturnCustomers from 'assets/insights/icon-insights-performance-return-customers.svg';
import IconInsightsPerformanceCallsToOnlineOrders from 'assets/insights/icon-insights-performance-calls-to-online-orders.svg';
import IconInsightsPerformanceSupportSessions from 'assets/insights/icon-insights-performance-support-sessions.svg';
import IconInsightsPerformanceCallsAnswered from 'assets/insights/icon-insights-performance-calls-answered.svg';
import IconInsightsPerformanceCatering from 'assets/insights/icon-insights-performance-catering.svg';

export const INSIGHTS_PERFORMANCE_CARDS_INFO = {
  emailMarketingRevenue: {
    name: 'Email Marketing Revenue',
    whatText:
      'Amount of  revenue Sauce generated for you via our full service email marketing activities.',
    whyText:
      'Email marketing is still one of the most effective ways to boost direct online sales. Want to generate more orders easily?',
    icon: IconInsightsPerformanceEmailMarketing,
    contactUsButton: false,
    nameForRequest: 'email-marketing',
    withDollarSymbol: true,
    isWhyClickHereContactUs: true,
    clickHereText: 'Click here',
    clickHereAdditionalText: 'to contact our team.',
    category: 'revenue'
  },
  googleRevenue: {
    name: 'Revenue through Google',
    whatText:
      'Revenue generated solely via your exclusive Google ordering button.',
    whyText:
      'Optimizing your Google presence with your direct order online boosts your revenue.',
    icon: IconInsightsPerformanceGoogleRevenue,
    withDollarSymbol: true,
    category: 'revenue'
  },
  commissionsSaved: {
    name: 'Commission Saved',
    whatText:
      'Amount of commissions we saved for you with direct online ordering. Calculated in comparison to 3rd party commissions (as entered in your settings).',
    whyText: 'The more we save the more money in your pocket!',
    icon: IconInsightsPerformanceCommissionsSaved,
    settingsButton: true,
    withDollarSymbol: true,
    isWhyClickHereSettings: true,
    clickHereText: 'Click here',
    clickHereAdditionalText:
      'to adjust the delivery commission you pay 3rd parties.',
    category: 'revenue'
  },

  positiveGmbReviews: {
    name: '5-Star Reviews',
    whatText:
      'The number of times customers completed a 5 star Sauce feedback survey.',
    whyText:
      'Positive reviews indicate customer satisfaction. The more 5-star reviews you get from customers, the better you are doing.',
    icon: IconInsightsPerformancePositiveReviews,
    withDollarSymbol: false,
    category: 'retention'
  },
  returnCustomersOrders: {
    name: 'Returning Customer Orders',
    whatText:
      'Number of orders generated by customers who ordered online directly from you more than once.',
    whyText:
      'The higher the number of returning customer orders, the stronger the community you build around your restaurant.',
    icon: IconInsightsPerformanceReturnCustomers,
    withDollarSymbol: false,
    category: 'retention'
  },

  acceleratedDeliveries: {
    name: 'Express Deliveries',
    whatText: 'Orders that were delivered faster then expected.',
    whyText:
      'Quick deliveries boost customer direct online loyalty and encourage positive word-of-mouth for your restaurant.',
    icon: IconInsightsPerformanceDelivery,
    withDollarSymbol: false,
    withPercentSymbol: true,
    category: 'customerExperience'
  },
  feedbackHandling: {
    name: 'Feedback Handled',
    whatText:
      'Amount of calls Sauce has made to end customers in order to deal with negative reviews in your post order survey.',
    whyText:
      'Turning unhappy customers into happy ones will help you  increase your loyal customers base.',
    icon: IconInsightsPerformanceFeedbackHandling,
    withDollarSymbol: false,
    category: 'customerExperience'
  },
  virtualAnsweringCalls: {
    name: 'Calls Answered',
    whatText:
      'Number of calls handled by our virtual answering system and live agents.',
    whyText:
      'By managing these calls, Sauce lets you focus on your business while ensuring customers receive prompt support.',
    icon: IconInsightsPerformanceCallsAnswered,
    category: 'customerExperience'
  }

  // sauceRevenue: {
  //   name: 'Sauce Generated Revenue',
  //   whatText:
  //     'Amount of revenue created solely via Sauce digital channels (your exclusive Google ordering  button, Virtual Answering, Email Marketing, Flyers and Phone Ordering)',
  //   whyText:
  //     'Sauce is consistently working to increase your profit. Not happy with the results?',
  //   icon: IconInsightsPerformanceSauceRevenue,
  //   nameForRequest: 'sauce-revenue',
  //   contactUsButton: true,
  //   isWhyClickHereContactUs: true,
  //   withDollarSymbol: true,
  //   clickHereText: 'Click here',
  //   clickHereAdditionalText: 'to contact our team.'
  // },

  // virtualAnsweringOrders: {
  //   name: 'Calls into Online Orders',
  //   whatText:
  //     'Number of calls that converted to a direct online order via Sauce’s Virtual Answering feature.',
  //   whyText:
  //     'The higher this number, the more revenue you generate and the more operational savings you will have.',
  //   icon: IconInsightsPerformanceCallsToOnlineOrders,
  //   nameForRequest: 'virtual-answering',
  //   contactUsButton: true
  //   // isWhyClickHereContactUs: true
  //   // clickHereText: 'Click here',
  //   // clickHereAdditionalText: 'to contact our team.'
  // },
  // virtualAnsweringSupportSessions: {
  //   name: 'Support Sessions',
  //   whatText:
  //     'Amount of customers that had reached out and contacted Sauce Support, instead of contacting the restaurant.',
  //   whyText:
  //     'Let Sauce look after customer, you should be focusing on creating great food!',
  //   icon: IconInsightsPerformanceSupportSessions
  // },
  // cateringSavings: {
  //   name: 'Catering Savings',
  //   whatText:
  //     'Amount of money Sauce has saved for you on catering order commissions. You are charged for catering just 5% by Sauce, while you are being charged (catering fee from settings) on other platforms.',
  //   whyText:
  //     'Online catering delivery with Sauce is cheaper, faster and more reliable.',
  //   icon: IconInsightsPerformanceCatering,
  //   contactUsButton: true,
  //   nameForRequest: 'catering',
  //   settingsButton: true,
  //   withDollarSymbol: true,
  //   isWhatClickHereSettings: true,
  //   clickHereText: 'Click here',
  //   clickHereAdditionalText: 'to edit the catering commission.'
  // }
};

export const INSIGHTS_PERFORMANCE_CATEGORY = {
  REVENUE: 'revenue',
  RETENTION: 'retention',
  CUSTOMER_EXPERIENCE: 'customerExperience'
};

export const INSIGHTS_PERFORMANCE_SETTINGS_VALUES = {
  averageDeliveryCommission: {
    minValue: 0,
    maxValue: 40,
    defaultValue: 30
  },
  averagePickupCommission: {
    minValue: 6,
    maxValue: 40,
    defaultValue: 20
  },
  averageMonthlyCateringOrders: {
    defaultValue: '0'
  }
};

export const INSIGHTS_PERFORMANCE_SETTINGS_NAMES = {
  averageDeliveryCommission: 'delivery',
  averagePickupCommission: 'pickup',
  averageMonthlyCateringOrders: 'catering'
};

export const INSIGHTS_PERFORMANCE_SETTINGS_CATERING_VALUES = [
  {title: '0', value: '0'},
  {title: '1-2', value: '1-2'},
  {title: '3-5', value: '3-5'},
  {title: '6+', value: '6+'}
];
