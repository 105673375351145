import styled from 'styled-components';

const Subtitle14px = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #232f37;

  &.mT19 {
    margin-top: 19px;
  }
  &.mB9 {
    margin-bottom: 9px;
  }
`;

export default Subtitle14px;
