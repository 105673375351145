import React from 'react';
import {DashboardContent} from '../dashboard/styles';
import {SuccessImage} from '../account/styles';
import {gif} from '../noMatch/no-match-gif';
import {DashboardInner} from '../../../components/DashboardInner/styles';
import Headline from '../../../components/Headline/styles';

const NoMatchPage = () => {
  return (
    <DashboardContent>
      <DashboardInner className='paddingLg'>
        <Headline className='Center'>
          {' '}
          You have no service features enabled for this location
        </Headline>
        <SuccessImage className='Large' src={gif} />
      </DashboardInner>
    </DashboardContent>
  );
};

export default NoMatchPage;
