import {put, takeEvery, select} from 'redux-saga/effects';
import {
  CALCULATE_ADJUST,
  CALCULATE_ADJUST_SUCCESS,
  CREATE_ADJUST,
  OPEN_ADJUST_ERROR_MODAL,
  OPEN_ADJUST_SUCCESS_MODAL
} from 'store/orders/orderAdjust/action';
import {
  calculateOrderAdjust,
  createOrderAdjust
} from 'helpers/services/api/orders';
import {GET_ORDER} from 'store/orders/orderDetails/action';

function* calculateAdjust({payload: {data, invoiceId}}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {data: calculationResult} = yield calculateOrderAdjust(invoiceId, {
      ...data,
      locationId
    });
    if (calculationResult) {
      yield put(CALCULATE_ADJUST_SUCCESS(calculationResult));
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}
function* createAdjust({payload: {data, invoiceId, orderId}}) {
  try {
    const locationId = yield select((state) => state.user.locationId);
    const {
      data: {status, error}
    } = yield createOrderAdjust(invoiceId, {
      ...data,
      locationId
    });
    if (status === 'Succeeded') {
      yield put(OPEN_ADJUST_SUCCESS_MODAL(true));
      yield put(GET_ORDER(orderId));
    } else if (error && error.message) {
      yield put(
        OPEN_ADJUST_ERROR_MODAL({isOpen: true, message: error.message})
      );
    }
  } catch (error) {
    yield put(
      OPEN_ADJUST_ERROR_MODAL({isOpen: true, message: 'Something went wrong!'})
    );
    console.log('SAGA ERROR', error);
  }
}

function* orderAdjustSaga() {
  yield takeEvery(CALCULATE_ADJUST, calculateAdjust);
  yield takeEvery(CREATE_ADJUST, createAdjust);
}

export default orderAdjustSaga;
