import * as Yup from 'yup';
import phoneRegex from '../../constants/regex';

const NameAndPhoneSchema = Yup.object().shape({
  Name: Yup.string()
    .max(100, 'Name is too long')
    .required('Mandatory filed')
    .trim('The field cannot contain only spaces.'),
  Phone: Yup.string()
    .required('Mandatory filed')
    .matches(phoneRegex, 'Please enter valid phone')
});

export default NameAndPhoneSchema;
