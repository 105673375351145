import React from 'react';

const IconSidebarReports = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.4444 1H2.55556C1.7 1 1 1.7 1 2.55556V13.4444C1 14.3 1.7 15 2.55556 15H13.4444C14.3 15 15 14.3 15 13.4444V2.55556C15 1.7 14.3 1 13.4444 1ZM9.55556 11.8889H4.11111V10.3333H9.55556V11.8889ZM11.8889 8.77778H4.11111V7.22222H11.8889V8.77778ZM11.8889 5.66667H4.11111V4.11111H11.8889V5.66667Z'
      fill='#929CB9'
    />
  </svg>
);

export default IconSidebarReports;
