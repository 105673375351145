import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Subtitle from '../../../../../../components/typography/Subtitle1';
import {
  DialogItem,
  DialogItemIcon,
  DialogItems
} from '../../../../dashboard/styles';
import IconFullRefund from '../../../../../../assets/IconFullRefund';
import IconPartialRefund from '../../../../../../assets/IconPartialRefund';

export default function RefundTypePicker({refundType, handleChange}) {
  return (
    <>
      <Subtitle>Refund Type</Subtitle>
      <DialogItems className='DialogItemsRefundTypePicker'>
        <DialogItem
          className={cn({
            isActive: refundType === 'Full'
          })}
          onClick={() => handleChange('refundType', 'Full')}
        >
          <DialogItemIcon>
            <IconFullRefund />
          </DialogItemIcon>
          <span className='redText'>Full refund</span>
        </DialogItem>
        <DialogItem
          className={cn({
            isActive: refundType === 'Partial'
          })}
          onClick={() => handleChange('refundType', 'Partial')}
        >
          <DialogItemIcon>
            <IconPartialRefund />
          </DialogItemIcon>
          <span className='redText'>Partial refund</span>
        </DialogItem>
      </DialogItems>
    </>
  );
}

RefundTypePicker.propTypes = {
  refundType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};
