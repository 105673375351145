/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';
import Loading from '../../../../components/shared/Utils/Loading';
import {TablePagination, TablePaginationText} from '../../dashboard/styles';
import NoReportsScreen from './NoReportsScreen';
import {TableContainer, TableText} from './styles';

const ReportsTable = ({
  fields,
  orderBy,
  orderDirection,
  onSort,
  dataObject,
  totalField,
  loading,
  rowKeyPath,
  totalRow,
  dataCount,
  ...props
}) => {
  const reportType = dataObject?.reportTypeInner;
  const data = dataObject?.dataRows;
  const renderRow =
    props.renderRow ||
    ((row, children) => (
      <TableRow
        key={rowKeyPath ? row[rowKeyPath] : nanoid()}
        tabIndex={-1}
        className='tableRow'
      >
        {children}
      </TableRow>
    ));

  const ITEMS_PER_PAGE_REPORTS = 30;

  const [currentPage, setCurrentPage] = useState(0);

  const offset = currentPage * ITEMS_PER_PAGE_REPORTS;

  const pageCount = Math.ceil(data.length / ITEMS_PER_PAGE_REPORTS);

  const handlePageClick = ({selected: selectedPage}) => {
    setCurrentPage(selectedPage);
  };

  // const handlePageClick = ({selected: selectedPage}) => {
  //   dispatch(REPORTS_PAGINATE(selectedPage * ITEMS_PER_PAGE_REPORTS));
  // };

  const paginationStyle = {
    display: `${data.length <= ITEMS_PER_PAGE_REPORTS ? 'none' : 'flex'}`,
    visibility: `${!data.length ? 'hidden' : 'visible'}`,
    margin: 'auto',
    padding: '15px 28px',
    background: '#fff',
    borderRadius: '10px',
    verticalAlign: 'middle',
    border: 'none',
    backgroundColor: '#fff',
    boxShadow:
      '1px 10px 10px rgba(108, 49, 151, 0.08), 10px 10px 20px rgba(108, 49, 151, 0.06), 30px 30px 30px rgba(108, 49, 151, 0.04)'
  };

  return (
    <TableContainer
      className={cn({
        customersReportTable: reportType === 1,
        refundsReportTable: reportType === 5
      })}
    >
      <Table id='reports-table'>
        {loading && !data.length ? (
          <>
            <br />
            <TableBody>
              <TableRow className='tableRow'>
                <TableCell>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          </>
        ) : (
          <>
            {!data.length ? (
              <NoReportsScreen />
            ) : (
              <>
                <TableHead sx={{position: 'sticky', top: 0}}>
                  <TableRow>
                    {fields.map((field) => (
                      <TableCell
                        key={nanoid()}
                        sortDirection={
                          orderBy === field.fieldName ? orderDirection : false
                        }
                        className={cn('headTableCell', {
                          cellVerticalAlignStart: reportType === 6
                        })}
                      >
                        {field.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(offset, offset + ITEMS_PER_PAGE_REPORTS)
                    .map((row, index) => {
                      return (
                        <>
                          {renderRow(
                            row,
                            fields.map((field) => (
                              <>
                                <TableCell
                                  key={nanoid()}
                                  className={cn({
                                    minWithColumn: reportType === 0
                                  })}
                                >
                                  <TableText className='reportsTablePadding'>
                                    {field.renderer
                                      ? field.renderer(row)
                                      : row[field.fieldName]}
                                  </TableText>
                                </TableCell>
                              </>
                            ))
                          )}
                          <div style={{margin: '0 0 16px 0'}} />
                        </>
                      );
                    })}
                  <TableRow
                    style={{
                      display: `${reportType !== 1 ? 'table-row' : 'none'}`
                    }}
                    className='tableRow'
                  >
                    {totalField.map((field) => (
                      <TableCell key={nanoid()}>
                        <TableText className='reportsTotal'>
                          {field.renderer ? field.renderer(totalRow) : null}
                        </TableText>
                      </TableCell>
                    ))}
                  </TableRow>
                  <div style={{margin: '0 0 16px 0'}} />
                </TableBody>
              </>
            )}
          </>
        )}
      </Table>
      <TablePagination style={paginationStyle}>
        <TablePaginationText>
          Showing{' '}
          {ITEMS_PER_PAGE_REPORTS > data.length
            ? data.length
            : data.slice(offset, offset + ITEMS_PER_PAGE_REPORTS).length}{' '}
          from {data.length}
        </TablePaginationText>
        <ReactPaginate
          previousLabel='previous'
          nextLabel='next'
          breakLabel='...'
          breakClassName='break-me'
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={cn('pagination', {
            remove: data.length === 0
          })}
          subContainerClassName='pages pagination'
          activeClassName='active'
        />
      </TablePagination>
      {/* {loading && data.length > 0 && <Loading />} */}
    </TableContainer>
  );
};

ReportsTable.propTypes = {
  fields: PropTypes.array.isRequired,
  totalField: PropTypes.array,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  dataObject: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  rowKeyPath: PropTypes.string,
  renderRow: PropTypes.func,
  customClass: PropTypes.string,
  totalRow: PropTypes.object,
  dataCount: PropTypes.number
};

ReportsTable.defaultProps = {
  orderBy: '',
  orderDirection: '',
  loading: false,
  onSort: () => {},
  rowKeyPath: null,
  renderRow: null,
  customClass: null,
  totalRow: {},
  dataCount: 0,
  totalField: []
};

export default ReportsTable;
