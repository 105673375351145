import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Collapse} from '@material-ui/core';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import {ButtonPrimary} from '../../../../components/buttons';
import {Body1} from '../../../../components/typography';
import {DashboardInner} from '../../../../components/DashboardInner/styles';
import Subtitle0 from '../../../../components/typography/Subtitle0';
import Body3 from '../../../../components/typography/Body3';
import {
  FaqBlock,
  FaqIcon,
  FaqTitle,
  UberEatsImageBox,
  UberEatsImageGrid,
  UberEatsPromoPaper,
  UberEatsPromoPapers,
  UEIcon24,
  UERedDot
} from '../styles';
import Subtitle3 from '../../../../components/typography/Subtitle3';
import BYOCStep1Icon from '../../../../assets/uberEats/byoc-step1.svg';
import BYOCStep2Icon from '../../../../assets/uberEats/byoc-step2.svg';
import BYOCStep3Icon from '../../../../assets/uberEats/byoc-step3.svg';
import BYOCStep4Icon from '../../../../assets/uberEats/byoc-step4.svg';
import {DashboardActionCenter} from '../../dashboard/styles';
import Subtitle5 from '../../../../components/typography/Subtitle5';
import UberEatsIcon24 from '../../../../assets/uberEats/ubereats24.svg';
import DisplayField from '../../../../components/fields/DisplayField';
import SauceIcon from '../../../../assets/uberEats/sauce24.svg';
import PlusIcon from '../../../../assets/uberEats/plus.svg';
import CrossIcon from '../../../../assets/uberEats/cross.svg';

const faq = [
  {
    title: 'How does this service benefit my business?',
    text:
      'Using Sauce couriers for your UberEats orders allows you to cut your UberEats delivery costs, while improving the level of service you are providing customers. '
  },
  {
    title: 'What is the delivery zone?',
    text:
      'At first you will start with a radius of up to 5 miles from your restaurant. After a week of operation our customer success team will analyze your performance and recommend you to modify/extend it in order to generate the most revenues possible. '
  },
  {
    title: 'How much do I pay for delivery order?',
    text:
      'You pay UberEats a reduced commission (usually 50% of what you pay them today) and you pay Sauce a discounted delivery fee of $2.99 for up to 5 miles delivery.'
  },
  {
    title: 'How much do I pay for a pick up order?',
    text:
      'You only pay UberEats a discounted commission (based on your arrangement with them). There is no charge to Sauce. '
  },
  {
    // eslint-disable-next-line
    title: "What happens to the customer's delivery fee?",
    text:
      // eslint-disable-next-line
      "Once UberEats transfers the customer's delivery fee to you, Sauce charges it back from you to pay for the delivery."
  },
  {
    title: 'What happens to the tip?',
    text:
      'UberEats transfers the tip directly to you, Sauce charges it back from you to allocate directly to the driver (in full!).'
  },
  {
    title: 'Can I cancel this service?',
    text:
      'Although we hope you will be satisfied with our service, you can cancel it anytime without extra charges. '
  }
];

const ConnectUberPage = () => {
  const history = useHistory();

  const onConnect = () => {
    history.push('/uber-eats/byoc-wizard');
  };
  const [opened, setOpened] = useState('');

  return (
    <>
      <DashboardInner className='paddingXXl'>
        <Subtitle0 textAlign='center'>
          Use Sauce couriers for your UberEats orders and cut commission fees by
          50%
        </Subtitle0>
        <Body3 className='marginTopSm' textAlign='center'>
          Here is how it works:
        </Body3>
        <UberEatsImageGrid className='UberEatsImageGridSteps'>
          <UberEatsImageBox>
            <strong>Step 1</strong>
            <img src={BYOCStep1Icon} alt='' />
            <Body1>You receive a new order from UberEats</Body1>
          </UberEatsImageBox>
          <UberEatsImageBox>
            <strong>Step 2</strong>
            <img src={BYOCStep2Icon} alt='' />
            <Body1>
              Sauce handles the delivery and customer support for you
            </Body1>
          </UberEatsImageBox>
          <UberEatsImageBox>
            <strong>Step 3</strong>
            <img src={BYOCStep3Icon} alt='' />
            <Body1>
              You pay a <b>reduced comission to UberEats</b> and a discounted
              delivery fee
            </Body1>
          </UberEatsImageBox>
          <UberEatsImageBox>
            <strong>Step 4</strong>
            <img src={BYOCStep4Icon} alt='' />
            <Body1>
              You end up with more money in your pocket for every order
            </Body1>
          </UberEatsImageBox>
        </UberEatsImageGrid>
        <DashboardActionCenter>
          <ButtonPrimary
            className='w460'
            onClick={onConnect}
            data-gtm='activateBYOCBigButton'
          >
            <span data-gtm='activateBYOCBigButton'>
              Use Sauce Couriers for UberEats Orders
            </span>
          </ButtonPrimary>
        </DashboardActionCenter>
      </DashboardInner>

      <DashboardInner className='paddingXXl'>
        <Subtitle0 textAlign='center'>Sauce Delivery Cost Example:</Subtitle0>
        <UberEatsPromoPapers>
          <UberEatsPromoPaper>
            <Subtitle5 className='marginBottomLg' textAlign='center'>
              You pay
            </Subtitle5>
            <FlexContainer className='marginBottom2Xs'>
              <FlexItem>
                <UEIcon24 wrapper='span' src={UberEatsIcon24} />
              </FlexItem>
              <FlexItem>
                <Subtitle3>Ubereats</Subtitle3>
              </FlexItem>
            </FlexContainer>

            <DisplayField
              label={<Body3>UberEats commission</Body3>}
              value={
                <Body3>
                  <b>-50%</b>
                </Body3>
              }
              className='marginBottomXs'
              spaceBetween
            />

            <br />

            <FlexContainer className='marginBottom2Xs'>
              <FlexItem>
                <UEIcon24 wrapper='span' src={SauceIcon} />
              </FlexItem>
              <FlexItem>
                <Subtitle3> Sauce</Subtitle3>
              </FlexItem>
            </FlexContainer>

            <DisplayField
              label={<Body3>Additional software fee</Body3>}
              value={
                <Body3>
                  <b>$0.00</b>
                </Body3>
              }
              className='marginBottom2Xs'
              spaceBetween
            />
            <DisplayField
              label={<Body3>Delivery fee (up to 5 miles)</Body3>}
              value={
                <Body3>
                  <b>$2.99</b>
                </Body3>
              }
              className='marginBottomXs'
              spaceBetween
            />
          </UberEatsPromoPaper>
          <UberEatsPromoPaper>
            <Subtitle5 className='marginBottomLg' textAlign='center'>
              Your customer pays
            </Subtitle5>
            <DisplayField
              label={<Body3>Subtotal</Body3>}
              value={
                <Body3>
                  <b>$22.5</b>
                </Body3>
              }
              className='marginBottom2Xs'
              spaceBetween
            />
            <DisplayField
              label={
                <Body3>
                  <UERedDot>* </UERedDot>Delivery fee (up to 5 miles)
                </Body3>
              }
              value={
                <Body3>
                  <b>$4.99</b>
                </Body3>
              }
              className='marginBottom2Xs'
              spaceBetween
            />
            <DisplayField
              label={
                <Body3>
                  <UERedDot>* </UERedDot>Tip
                </Body3>
              }
              value={
                <Body3>
                  <b>$5</b>
                </Body3>
              }
              className='marginBottomXs'
              spaceBetween
            />
            <br />
            <Body1>
              <UERedDot>* </UERedDot>UberEats transfers you the customer
              delivery fee and tip, and you transfer it to Sauce.
            </Body1>
          </UberEatsPromoPaper>
        </UberEatsPromoPapers>
      </DashboardInner>

      <DashboardInner className='paddingXl'>
        <Subtitle0 className='marginBottomLg' textAlign='center'>
          Frequently Asked Questions
        </Subtitle0>

        {faq.map((q, index) => {
          const indx = index.toString();

          return (
            <>
              <FaqBlock>
                <FaqTitle
                  onClick={() =>
                    setOpened(
                      opened.indexOf(indx.toString()) > -1
                        ? opened.replaceAll(indx.toString(), '')
                        : opened.concat(indx.toString())
                    )
                  }
                >
                  {q.title}{' '}
                  <FaqIcon
                    className='FaqIcon'
                    wrapper='div'
                    width={opened.indexOf(indx) > -1 ? 18 : 19}
                    src={opened.indexOf(indx) > -1 ? CrossIcon : PlusIcon}
                  />
                </FaqTitle>
                <Collapse in={opened.indexOf(indx) > -1}>
                  <Body3>{q.text}</Body3>
                </Collapse>
              </FaqBlock>
            </>
          );
        })}
      </DashboardInner>
    </>
  );
};

export default ConnectUberPage;
