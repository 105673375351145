import React, {useEffect, useState} from 'react';
import T from 'prop-types';
import cn from 'classnames';
import {useDispatch} from 'react-redux';
import {Body1} from '../../../components/typography';
import {YourVirtualNumber, VirtualNumberMegaphone} from './styles';
import {DashboardActionCenter} from '../dashboard/styles';
import {ButtonOutline, ButtonPrimary} from '../../../components/buttons';
import {SET_PHONE_NUMBER_UPDATED} from '../../../store/virtualNumber/action';
import {phoneFormatForView} from '../../../helpers/functionUtils/helpFunctions';

const ActivateVirtualNumber = ({
  formik,
  virtualNumber,
  status,
  editAction,
  twilioAudioUrl,
  editPhoneNumber,
  editSlogan,
  phoneNumberUpdated,
  disabledVN
}) => {
  const dispatch = useDispatch();
  const [toggleAudio, setToggleAudio] = useState(true);
  const [audio] = useState(new Audio(twilioAudioUrl));

  useEffect(() => {
    audio.addEventListener('ended', () => setToggleAudio(true));
    return () => audio.pause();
  }, []);

  useEffect(() => () => dispatch(SET_PHONE_NUMBER_UPDATED(false)), []);

  const handlePlayAudio = () => {
    if (toggleAudio) {
      audio.play();
    } else audio.pause();
    setToggleAudio(!toggleAudio);
  };
  return (
    <>
      <Body1 className='marginBottomXl'>
        {status !== 'ACTIVATED' && 'Congratulations! '}
        This is your {status !== 'ACTIVATED' && 'new'} Virtual Answering number:{' '}
        {phoneFormatForView(virtualNumber)}
      </Body1>
      <VirtualNumberMegaphone
        onClick={handlePlayAudio}
        className='marginBottomXl'
      >
        Listen to what your customers will now hear when they call your
        restaurant
      </VirtualNumberMegaphone>
      <Body1>
        <YourVirtualNumber>
          This is your new Virtual Answering number:
          <br />
          {phoneFormatForView(virtualNumber)}
        </YourVirtualNumber>
      </Body1>
      <Body1 className='marginBottomXl'>
        The virtual number reduces hassle and boosts sales by enabling callers
        to place orders by way of an SMS link to their phones from which to
        place online orders
      </Body1>
      {(phoneNumberUpdated || status !== 'ACTIVATED') && (
        <Body1 className='marginBottomXl'>
          It’s recommended you update the phone number provided on your website
          and Google My Business account. Simply replace the existing number
          with the following number: {phoneFormatForView(virtualNumber)}
        </Body1>
      )}
      {status === 'ACTIVATED' && (
        <DashboardActionCenter className='DashboardActionVN'>
          <ButtonOutline
            className='EditVAP'
            data-gtm='editSloganButton'
            onClick={editSlogan}
          >
            Edit Virtual Answering parameters
          </ButtonOutline>
          <ButtonOutline
            data-gtm='editPhoneNumberButton'
            onClick={editPhoneNumber}
          >
            Edit Phone Number
          </ButtonOutline>
          <ButtonPrimary
            data-gtm='disableVirtualAnsweringButton'
            onClick={disabledVN}
          >
            <span data-gtm='disableVirtualAnsweringButton'>
              Disable Virtual Answering
            </span>
          </ButtonPrimary>
        </DashboardActionCenter>
      )}
      {status === 'PENDING' && (
        <DashboardActionCenter>
          <ButtonOutline onClick={editAction} className='ButtonLg w250'>
            Edit
          </ButtonOutline>
          <ButtonPrimary
            data-gtm='activateVirtualAnsweringButton'
            type='submit'
            className={cn('w350', {
              disabled: status === 'ACTIVATED'
            })}
            onClick={status !== 'ACTIVATED' ? formik.handleSubmit : undefined}
          >
            <span data-gtm='activateVirtualAnsweringButton'>
              Activate Virtual Answering
            </span>
          </ButtonPrimary>
        </DashboardActionCenter>
      )}
    </>
  );
};

ActivateVirtualNumber.propTypes = {
  formik: T.object.isRequired,
  virtualNumber: T.string.isRequired,
  status: T.string.isRequired,
  editAction: T.func.isRequired,
  twilioAudioUrl: T.string.isRequired,
  editPhoneNumber: T.func.isRequired,
  editSlogan: T.func.isRequired,
  phoneNumberUpdated: T.bool.isRequired,
  disabledVN: T.func.isRequired
};

export default ActivateVirtualNumber;
