import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  DialogBody,
  DialogAction,
  DialogText,
  DialogCheckbox
} from '../../dashboard/styles';
import {HIDDEN_MODAL} from '../../../../store/menuEditorModals/action';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {setWarningNote} from '../../../../helpers/localStorage/MenuEditorNote';
import {PATCH_CATEGORY} from '../../../../store/menuEditorCategory/action';
import {PATCH_ITEM} from '../../../../store/menuEditorItems/action';
import {PATCH_MODIFIER} from '../../../../store/menuEditorModifier/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import sendGtmEvent from '../../../../helpers/services/utils/gtm';

const HiddenModal = () => {
  const dispatch = useDispatch();

  const modalNoteEntityName = useSelector(
    (state) => state.menuEditorModalsReducer.modalNoteEntityName
  );
  const isHiddenModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isHiddenModalOpen
  );
  const hiddenModalStatus = useSelector(
    (state) => state.menuEditorModalsReducer.hiddenModalStatus
  );
  const oldEntityData = useSelector(
    (state) => state.menuEditorModalsReducer.oldEntityData
  );

  const [checked, setChecked] = useState(false);

  const handleChangeCheckbox = () => {
    setChecked((prev) => !prev);
    setWarningNote('showHiddenNote', checked);
  };

  const submitAction = () => {
    if (modalNoteEntityName === 'category') {
      const Visible = hiddenModalStatus !== 'hide';

      dispatch(PATCH_CATEGORY({oldEntityData, form: {Visible}}));
      sendGtmEvent(`menuEditorEntity${Visible ? 'Visible' : 'Hidden'}`, {
        entity: 'category'
      });
    }

    if (modalNoteEntityName === 'item') {
      const Visible = hiddenModalStatus !== 'hide';
      dispatch(PATCH_ITEM({oldEntityData, form: {Invisible: !Visible}}));
      sendGtmEvent(`menuEditorEntity${Visible ? 'Visible' : 'Hidden'}`, {
        entity: 'item'
      });
    }

    if (modalNoteEntityName === 'modifier') {
      const Visible = hiddenModalStatus !== 'hide';
      dispatch(PATCH_MODIFIER({oldEntityData, form: {Invisible: !Visible}}));
      sendGtmEvent(`menuEditorEntity${Visible ? 'Visible' : 'Hidden'}`, {
        entity: 'modifier'
      });
    }

    dispatch(HIDDEN_MODAL({open: false}));
  };

  return (
    <Dialog
      onClose={() => dispatch(HIDDEN_MODAL({open: false}))}
      open={isHiddenModalOpen}
      scroll='body'
    >
      <DialogContainer className='DialogSm'>
        <DialogClose onClick={() => dispatch(HIDDEN_MODAL({open: false}))} />
        <DialogTitle className='DialogTitleOrange'>Please note:</DialogTitle>
        <DialogBody>
          <DialogText className='DialogTextSm'>
            This {modalNoteEntityName} will be{' '}
            {hiddenModalStatus === 'hide' ? 'hidden' : 'visible'} across the
            entire menu.
          </DialogText>
          <DialogCheckbox>
            <CheckboxContainer>
              <FormControlLabel
                className='Say2EatCheckbox'
                control={
                  <Checkbox
                    labelStyle={{color: '#172751'}}
                    iconStyle={{fill: '#FF2D38'}}
                    inputStyle={{color: '#FF2D38'}}
                    style={{color: '#FF2D38'}}
                    onChange={handleChangeCheckbox}
                    name='checkedNote'
                    checked={checked}
                  />
                }
                label='Don’t show this message again'
              />
            </CheckboxContainer>
          </DialogCheckbox>
          <DialogAction>
            <ButtonPrimary onClick={() => submitAction()}>
              <span>OK</span>
            </ButtonPrimary>
          </DialogAction>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default HiddenModal;
