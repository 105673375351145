import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {AccordionItemName} from '../../dashboard/styles';
import BulkCheckbox from './BulkCheckbox';
import {MODIFIER_SCHEME} from '../../../../store/menuEditor/helper/MenuTree';

function ModifierAccordionTitle(props) {
  const {modifiersData} = props;
  const selectedModifiers = useSelector(
    (store) => store.menuEditorReducer.selectedModifiers
  );
  const isChecked =
    selectedModifiers &&
    Object.keys(selectedModifiers).length === modifiersData.length;

  return (
    <AccordionItemName className='AccordionItemName'>
      {!!modifiersData.length && (
        <div className='BulkCheckbox'>
          <BulkCheckbox id='all' type={MODIFIER_SCHEME} checked={isChecked} />
        </div>
      )}
      Modifiers ({modifiersData.length})
    </AccordionItemName>
  );
}

ModifierAccordionTitle.propTypes = {
  modifiersData: PropTypes.array.isRequired
};

export default ModifierAccordionTitle;
