import {createReducer} from 'redux-act';
import {
  SESSION_EXPIRED_MODAL_CLOSE,
  SESSION_EXPIRED_MODAL_OPEN
} from './action';

const initialState = () => ({
  open: false
});

const sessionExpiredReducer = createReducer(
  {
    [SESSION_EXPIRED_MODAL_OPEN]: (state) => ({
      ...state,
      open: true
    }),
    [SESSION_EXPIRED_MODAL_CLOSE]: (state) => ({
      ...state,
      open: false
    })
  },
  initialState()
);
export default sessionExpiredReducer;
