import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import SearchField from '../../../../components/fields/SearchField';
import {BorderedButton, ButtonOutline} from '../../../../components/buttons';
import {GET_USERS, SEARCH_CHANGE} from '../../../../store/adminUsers/actions';
import {adminUsersData} from '../../../../store/adminUsers/selectors';

const UsersSearchField = () => {
  const {
    paging: {page},
    sort
  } = useSelector(adminUsersData);

  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState();
  const [isClearIconVisible, setIsClearIconVisible] = useState(false);

  const onSearchField = (e) => {
    const {value} = e.target;
    setSearchField(value);
    if (value === '') {
      setIsClearIconVisible(false);
    }
  };

  const onClearSearchClick = () => {
    setSearchField('');
    dispatch(SEARCH_CHANGE(''));
    dispatch(GET_USERS({page, search: '', sort}));
    setIsClearIconVisible(false);
  };

  const onSearchClick = () => {
    dispatch(SEARCH_CHANGE(searchField));
    dispatch(GET_USERS({page, search: encodeURIComponent(searchField), sort}));
    setIsClearIconVisible(true);
  };
  return (
    <FlexContainer>
      <FlexItem flex='1 1 auto' className='marginRightXs'>
        <SearchField
          className='marginBottomXs'
          value={searchField}
          onChange={(e) => onSearchField(e)}
          onClear={searchField && onClearSearchClick}
          isClearIconVisible={isClearIconVisible}
          onKeyPress={(e) =>
            e.key === 'Enter' && searchField && onSearchClick()
          }
        />
      </FlexItem>
      <FlexItem>
        <ButtonOutline
          className={cn('ButtonMedium', {disabled: !searchField})}
          onClick={onSearchClick}
        >
          <b>Search</b>
        </ButtonOutline>
      </FlexItem>
    </FlexContainer>
  );
};

export default UsersSearchField;
