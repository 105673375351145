import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {
  Header,
  HeaderBurger,
  HeaderLocation,
  HeaderLogo,
  HeaderLside,
  HeaderRside,
  LocationSelectWrapper
} from './styles';
import SauceLogoWhite from '../../../assets/logo-sauce-white.svg';
import SelectComponent from '../../select/SelectComponent';
import {
  GET_LOCATION,
  GET_LOCATION_ID,
  GET_TIMEZONE
} from '../../../store/global/user/action';
import stringToBoolean from '../../../helpers/stringToBoolean';

const Navbar = ({showSidebar, setShowSidebar}) => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userData);
  const locations = useSelector((state) => state.user.locations);
  const _id = useSelector(
    (state) => state.user?.locations?.activeLocation?._id
  );
  const locationId = useSelector((state) => state.user.locationId);
  const isOnline = useSelector(
    (state) => state.globalNotificationsReducer.isNetworkOnline
  );

  const handleLocationChange = (event) => {
    dispatch(
      GET_LOCATION_ID({
        locationId: event.target.value,
        isToast: stringToBoolean(
          event.currentTarget.getAttribute('data-isToast')
        )
      })
    );
    dispatch(GET_TIMEZONE(event.currentTarget.getAttribute('data-timezone')));
    dispatch(
      GET_LOCATION(
        JSON.parse(event.currentTarget.getAttribute('data-fullItem'))
      )
    );
  };

  return (
    <Header className='Header'>
      <HeaderLside>
        <HeaderBurger
          className={showSidebar ? 'isOpen' : ''}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <span />
        </HeaderBurger>
        <HeaderLogo>
          <img src={SauceLogoWhite} alt='logo' />
        </HeaderLogo>
      </HeaderLside>

      <HeaderRside>
        <HeaderLocation>
          {isOnline &&
            userData &&
            !userData.locations_whitelist &&
            userData.status === 'ACTIVE' && (
              <LocationSelectWrapper>
                <SelectComponent
                  onChange={handleLocationChange}
                  options={locations}
                  value={_id || locationId}
                  defaultLabel='Choose the location'
                />
              </LocationSelectWrapper>
            )}
        </HeaderLocation>
      </HeaderRside>
    </Header>
  );
};

Navbar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired
};

export default Navbar;
