import {
  put,
  select,
  takeEvery,
  delay,
  race,
  call,
  take,
  cancel,
  takeLatest
} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  GET_BAD_ORDER,
  GET_BAD_ORDER_SUCCESS,
  STOP_BAD_ORDER_DETAIL_JOB,
  RUN_BAD_ORDER_DETAIL_JOB,
  SET_BAD_ORDER_DETAIL_NOTES
} from './action';
import {
  getBADOrderApi,
  postBADDetailsNotesApi
} from '../../../helpers/services/api/bookADrive';
import {getStorageToken} from '../../../helpers/localStorage/authStorage';

const GET_ORDER_DETAIL_INTERVAL = 60000;

export function* getBADOrder(payload) {
  while (true) {
    try {
      const {data} = yield getBADOrderApi(payload);

      if (data) {
        yield put(GET_BAD_ORDER_SUCCESS(data));
      }
    } catch (error) {
      console.log('SAGA ERROR', error);
      return false;
    }
    yield delay(GET_ORDER_DETAIL_INTERVAL);
  }
}

function* runGetOrderDetailsJob({payload}) {
  try {
    const isOnline = yield select(
      (state) => state.globalNotificationsReducer?.isNetworkOnline
    );
    const userData = yield select((state) => state.user.userData);
    const {token} = getStorageToken();
    if (!isOnline || !token || !userData.role || userData.role === 'sudo') {
      return;
    }

    const {stop} = yield race({
      job: call(() => getBADOrder(payload)),
      stop: take(STOP_BAD_ORDER_DETAIL_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* postBADDetailsNotes({payload}) {
  try {
    const data = yield postBADDetailsNotesApi(payload.id, {
      notes: payload.notes
    });
    if (data) {
      toast.success('Internal notes has been saved!');
    }
  } catch (error) {
    console.log('SAGA ERROR', error);
  }
}

function* orderBADDetailsSaga() {
  yield takeEvery(GET_BAD_ORDER, getBADOrder);
  yield takeEvery(SET_BAD_ORDER_DETAIL_NOTES, postBADDetailsNotes);
  yield takeLatest(RUN_BAD_ORDER_DETAIL_JOB, runGetOrderDetailsJob);
}

export default orderBADDetailsSaga;
