/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik, useFormik} from 'formik';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, FormControlLabel} from '@material-ui/core';
import {diff} from 'deep-object-diff';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import {
  EDIT_CATEGORY_MODAL,
  SELECT_ITEM_MODAL_OPEN
} from '../../../../store/menuEditorModals/action';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemName,
  DashboardField,
  DialogAction,
  DialogBody,
  DialogField,
  DialogFieldInner,
  DialogFieldInners,
  DialogFieldRemove,
  DialogFields,
  DialogHint,
  DialogLinkButton,
  FieldInput,
  FieldLabel,
  FieldTextarea
} from '../../dashboard/styles';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import FileUploader from '../../../../components/FileUploader';
import {UPDATE_CATEGORY} from '../../../../store/menuEditorCategory/action';
import DialogContainer from '../../../../components/DialogContainer/styles';
import DialogClose from '../../../../components/DialogClose/styles';
import DialogTitle from '../../../../components/DialogTitle/styles';
import CheckboxContainer from '../../../../components/Checkbox/styles';
import {useDaysPickerContext} from '../context/DaysPickerContext';
import {
  checkDuringHours,
  getAvailabilityHours,
  getAvailabilityHoursForServer
} from '../helpers';
import {selectOptions, startActiveHours, startCustomHours} from '../constants';
import SelectWrapper from '../../../../components/select/SelectComponentStyles';
import DaysPickerContainer from '../DaysPickerContainer';

const EditCategoryModal = () => {
  const dispatch = useDispatch();

  const isEditCategoryModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditCategoryModalOpen
  );

  const entityId = useSelector(
    (state) => state.menuEditorModalsReducer.entityId
  );
  const category = useSelector(
    (state) => state.menuEditorReducer.sectionsMap[entityId]
  );

  const itemsMap = useSelector((state) => state.menuEditorReducer.itemsMap);
  const itemsDirection = useSelector(
    (state) => state.menuEditorReducer.itemsDirection
  );
  const itemsList = itemsDirection.map((PosId) => itemsMap[PosId]);

  const [selectedItems, setSelectedItems] = useState([]);

  const [fileUrl, setFileUrl] = useState('');
  const [expanded, setExpanded] = useState(false);

  const formikRef = useRef();
  const openingDays = useDaysPickerContext();
  const initialValues = {
    Name: category?.Name || '',
    Notes: category?.Notes || '',
    ExternalId: category?.ExternalId || '',
    ExternalData: category?.ExternalData || '',
    OldItems: category?.Items || [],
    ContainsAlcohol: category?.ContainsAlcohol || false,
    AvailabilityHours: getAvailabilityHours(
      category?.AvailabilityHours?.length
        ? category?.AvailabilityHours
        : startActiveHours
    ),
    OpeningHoursChanged: [],
    Availability:
      checkDuringHours(category?.AvailabilityHours) === 7 ||
      !category?.AvailabilityHours?.length
        ? 'During opening hours'
        : 'Custom hours'
  };
  const shouldAllowFormReinitialize = diff(
    initialValues,
    formikRef?.current?.values
  );

  useEffect(() => {
    if (formikRef.current) {
      const {
        values,
        resetForm,
        setFieldValue,
        isSubmitting
      } = formikRef.current;

      // if (!isSubmitting) {
      //   resetForm({...values});
      // }
      setFieldValue('OpeningHoursChanged', values.OpeningHoursChanged);
    }
  }, [formikRef.current]);

  useEffect(() => {
    if (category?.PictureURL !== '') {
      setFileUrl(category?.PictureURL);
    }

    setSelectedItems(category?.Items?.map((PosId) => itemsMap[PosId]));

    return () => {
      setExpanded(false);
      setSelectedItems([]);
    };
  }, [entityId]);

  // const formik = useFormik({
  //   initialValues: {
  //     Name: category?.Name || '',
  //     Notes: category?.Notes || '',
  //     ExternalId: category?.ExternalId || '',
  //     ExternalData: category?.ExternalData || '',
  //     OldItems: category?.Items || [],
  //     ContainsAlcohol: category?.ContainsAlcohol || false
  //   },
  //   enableReinitialize: true,
  //   onSubmit: (values) => {
  //     dispatch(
  //       UPDATE_CATEGORY({
  //         form: {
  //           ...values,
  //           Name: String(values.Name).trim(),
  //           PictureURL: fileUrl !== '' ? fileUrl : category?.PictureURL,
  //           Items: [...selectedItems.map((item) => item.PosId)]
  //         }
  //       })
  //     );
  //     dispatch(EDIT_CATEGORY_MODAL({open: false}));
  //   }
  // });

  // console.log(aaa(openingDaysServerParser(openingDays)));

  const removeItem = (id) => {
    setSelectedItems(selectedItems.filter((group) => group.PosId !== id));
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      enableReinitialize={
        !Object.keys(shouldAllowFormReinitialize || {}).length
      }
      onSubmit={(values, {resetForm}) => {
        const body = {
          ...values,
          Name: String(values.Name).trim(),
          PictureURL: fileUrl !== '' ? fileUrl : category?.PictureURL,
          Items: [...selectedItems.map((item) => item.PosId)],
          AvailabilityHours:
            values.Availability === 'Custom hours'
              ? getAvailabilityHoursForServer(openingDays)
              : startActiveHours
        };
        delete body.Availability;
        delete body.OpeningHoursChanged;
        dispatch(
          UPDATE_CATEGORY({
            form: body
          })
        );
        dispatch(EDIT_CATEGORY_MODAL({open: false}));
      }}
    >
      {({values, handleChange, submitForm, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            onClose={() => dispatch(EDIT_CATEGORY_MODAL({open: false}))}
            open={isEditCategoryModalOpen}
            scroll='body'
          >
            <DialogContainer className='DialogXl'>
              <DialogClose
                onClick={() => dispatch(EDIT_CATEGORY_MODAL({open: false}))}
              />
              <DialogTitle>
                Edit <span>Category</span>
              </DialogTitle>
              <Form>
                <DialogBody>
                  <DialogFields>
                    <DialogField className='w50'>
                      <FieldLabel className='FieldLabelLg'>
                        Category name
                      </FieldLabel>
                      <FieldInput
                        name='Name'
                        onChange={handleChange}
                        value={values.Name}
                        placeholder='Write category name here'
                      />
                    </DialogField>
                    <DialogField className='w100 dFlex'>
                      <CheckboxContainer>
                        <FormControlLabel
                          className='Say2EatCheckbox'
                          control={
                            <Checkbox
                              labelStyle={{color: '#172751'}}
                              iconStyle={{fill: '#FF2D38'}}
                              inputStyle={{color: '#FF2D38'}}
                              style={{color: '#FF2D38'}}
                              onChange={handleChange}
                              name='ContainsAlcohol'
                              checked={values.ContainsAlcohol}
                            />
                          }
                          label='Contains Alcohol'
                        />
                      </CheckboxContainer>
                    </DialogField>

                    <DialogField className='w50'>
                      <FieldLabel>Photo</FieldLabel>
                      <FileUploader
                        url={fileUrl}
                        onSetFile={setFileUrl}
                        allowedFileFormats={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg'
                        ]}
                      />
                    </DialogField>

                    <DialogField className='w50'>
                      <FieldLabel>Notes (for internal use)</FieldLabel>
                      <FieldTextarea
                        className='FieldTextareaLg'
                        name='Notes'
                        value={values.Notes}
                        onChange={handleChange}
                      />
                    </DialogField>

                    <DialogField className='w30'>
                      <DashboardField className='wAutoSpace paddingL0'>
                        <FieldLabel>Availability</FieldLabel>
                        <SelectWrapper className='SelectMd w100'>
                          <FormControl className='select-formcontrol'>
                            <Select
                              value={values.Availability}
                              name='Availability'
                              onChange={(e) => {
                                setFieldValue('Availability', e.target.value);
                                if (e.target.value === 'Custom hours') {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startCustomHours)
                                  );
                                } else {
                                  setFieldValue(
                                    'AvailabilityHours',
                                    getAvailabilityHours(startActiveHours)
                                  );
                                }
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                getContentAnchorEl: null
                              }}
                            >
                              {selectOptions.map((item) => (
                                <MenuItem
                                  className='reportTypeItem'
                                  key={nanoid()}
                                  value={item.name}
                                  disableRipple
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </SelectWrapper>
                      </DashboardField>
                    </DialogField>
                    {values.Availability === 'Custom hours' && (
                      <DialogField className='w100'>
                        <DaysPickerContainer
                          WorkingDays={values?.AvailabilityHours}
                        />
                      </DialogField>
                    )}

                    <DialogField className='w100 dFlex noWrapTablet mTop20'>
                      <DialogHint className='DialogHintTablet'>
                        <span>
                          Add the unique identifier you can use at your
                          restaurant (ex. SKU)
                        </span>
                      </DialogHint>
                      <DialogFieldInners className='DialogFieldInnersExternal'>
                        <DialogFieldInner className='w50'>
                          <FieldLabel>Exernal Id</FieldLabel>
                          <FieldInput
                            name='ExternalId'
                            onChange={handleChange}
                            value={values.ExternalId}
                            placeholder='External ID'
                          />
                        </DialogFieldInner>
                        <DialogFieldInner className='w50'>
                          <FieldLabel>Exernal Data</FieldLabel>
                          <FieldInput
                            name='ExternalData'
                            onChange={handleChange}
                            value={values.ExternalData}
                            placeholder='Exernal Data'
                          />
                        </DialogFieldInner>
                      </DialogFieldInners>
                    </DialogField>

                    <DialogField className='w100 Say2EatSelect'>
                      <AccordionItem className='AccordionItemModal'>
                        <Accordion
                          onChange={handleAccordionChange('items')}
                          expanded={expanded === 'items' || expanded === true}
                        >
                          <AccordionSummary
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <AccordionItemName className='AccordionItemName'>
                              Items Including
                              {selectedItems.length > 0
                                ? ` (${selectedItems.length})`
                                : ''}
                            </AccordionItemName>
                          </AccordionSummary>
                          <AccordionItemBody>
                            <DialogFields>
                              {selectedItems.length > 0 &&
                                selectedItems.map((group) => (
                                  <DialogField className='w100 withRemove'>
                                    <FieldInput
                                      name=''
                                      value={group?.Name}
                                      placeholder={group?.Name}
                                      readOnly
                                    />
                                    <DialogFieldRemove
                                      onClick={() => removeItem(group.PosId)}
                                    />
                                  </DialogField>
                                ))}
                            </DialogFields>
                          </AccordionItemBody>
                        </Accordion>
                      </AccordionItem>
                      <DialogField className='Center w100'>
                        <DialogLinkButton
                          onClick={() => {
                            dispatch(
                              SELECT_ITEM_MODAL_OPEN({
                                open: true,
                                selectedItems,
                                itemType: 'item',
                                itemList: itemsList,
                                loading: false,
                                callBack: setSelectedItems,
                                closeModal: () => {
                                  dispatch(
                                    SELECT_ITEM_MODAL_OPEN({
                                      open: false,
                                      itemType: '',
                                      queryParams: {},
                                      itemList: [],
                                      loading: false,
                                      selectedItems: [],
                                      callBack: () => {},
                                      closeModal: () => {}
                                    })
                                  );
                                }
                              })
                            );
                          }}
                        >
                          Add an Item
                        </DialogLinkButton>
                      </DialogField>
                    </DialogField>
                  </DialogFields>
                  <DialogAction>
                    <ButtonPrimary type='submit'>
                      <span>Save Changes</span>
                    </ButtonPrimary>
                  </DialogAction>
                </DialogBody>
              </Form>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default EditCategoryModal;
