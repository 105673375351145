import React, {memo, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import T from 'prop-types';
import cn from 'classnames';
import {DashboardDay} from '../../dashboard/styles';

import {
  useDaysPickerContext,
  useDaysPickerContextUpdate
} from '../context/DaysPickerContext';
import {isValueChanged} from '../../../../helpers/functionUtils/dayAndTimePickerUtils';
import {daysOfWeek} from '../constants';

const initLocalSelDays = (selectedDays) => {
  const next = {};

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const day in selectedDays) {
    next[day] = {
      active: false
    };
  }

  return next;
};

const DaysRow = ({selectedDays, day}) => {
  const [localSelectedDays, setLocalSelectedDays] = useState(
    initLocalSelDays(selectedDays)
  );
  const openingDays = useDaysPickerContext();
  const setOpeningDays = useDaysPickerContextUpdate();
  const {
    setFieldValue,
    values: {OpeningHoursChanged}
  } = useFormikContext();

  useEffect(() => {
    const nextLocalSelectedDays = initLocalSelDays(selectedDays);

    day.days.forEach((dayName) => {
      if (
        Object.prototype.hasOwnProperty.call(nextLocalSelectedDays, dayName)
      ) {
        nextLocalSelectedDays[dayName] = {
          active: true
        };
      }
    });

    setLocalSelectedDays(nextLocalSelectedDays);
  }, [selectedDays]);

  const selectDay = (e, weekDay, id) => {
    let nextDays = [...openingDays];
    let activeIndex = null;
    nextDays.forEach((_day, index) => {
      if (_day.id === id) {
        if (day.days.includes(weekDay)) {
          // eslint-disable-next-line no-param-reassign
          day.days = day.days.filter((item) => item !== weekDay);
        } else {
          day.days.push(weekDay);
        }

        activeIndex = index;
      }
    });

    const availableDaySlots = openingDays.reduce(
      (acc, elem) => acc + elem.days.length,
      0
    );

    if (availableDaySlots === 7) {
      nextDays = nextDays.filter((d) => d.days.length !== 0);
    } else {
      nextDays[activeIndex] = {...day};
    }

    setOpeningDays(nextDays);
    setFieldValue(
      'OpeningHoursChanged',
      isValueChanged(OpeningHoursChanged, weekDay)
    );
  };

  return (
    <>
      {daysOfWeek.map((d) => (
        <DashboardDay
          key={`day-name-${d}`}
          onClick={(e) => selectDay(e, d, day.id)}
          className={cn({
            isActive: localSelectedDays[d].active
          })}
        >
          {d}
        </DashboardDay>
      ))}
    </>
  );
};

DaysRow.propTypes = {
  selectedDays: T.oneOfType([T.array, T.object]).isRequired,
  day: T.object.isRequired
};

export default memo(DaysRow);
