import React from 'react';
import moment from 'moment';
import roundTheNumber from '../../functionUtils/roundTheNumber';
import {ORDER_TYPES} from '../orders';
import {feedbackTypes} from '../feedback';

const OrderType = [
  'PickUp',
  'Delivery',
  'SauceDispatch',
  'DineIn',
  'UberEatsDelivery',
  'UberPickUp'
];

const renderDate = (whatRender, whatRenderIfNoData = '') => {
  return whatRender ? moment(whatRender).format('LL') : whatRenderIfNoData;
};

const renderAmounts = (
  whatRender,
  whatRenderIfNoData = '$0.00',
  isNegative = false
) => {
  if (whatRender) {
    let strNumber = roundTheNumber(whatRender);
    if (strNumber.startsWith('-')) {
      strNumber =
        strNumber.length > 7
          ? [strNumber.slice(0, -6), ',', strNumber.slice(-6)]
              .join('')
              .replace('-', '-$')
          : strNumber.replace('-', '-$');
      return strNumber;
    }
    strNumber =
      strNumber.length > 6
        ? [strNumber.slice(0, -6), ',', strNumber.slice(-6)].join('')
        : strNumber;
    return isNegative ? `-$${strNumber}` : `$${strNumber}`;
  }
  return whatRenderIfNoData;
};

const outOfRangePayoutsText = (rowData, whatRenderIfNoData = '') => {
  if (
    rowData.coveredDatesByPayouts.length &&
    rowData.coveredDatesByPayouts.length > 0
  ) {
    const outOfRangeDatesArr = rowData.coveredDatesByPayouts.filter(
      (date) => date !== rowData.salesDate
    );

    if (outOfRangeDatesArr.length > 0) {
      const showDatesArr = rowData.coveredDatesByPayouts
        .sort()
        .map((date) => moment(date).format('MMM D'));

      return (
        <span className='reportsGrayText'>
          Orders from {showDatesArr[0]}-{showDatesArr[showDatesArr.length - 1]}
        </span>
      );
    }
    return whatRenderIfNoData;
  }
  return whatRenderIfNoData;
};

export const FIELDS_CUSTOMERS = [
  {
    title: 'Last Order',
    renderer: (cellText) => (
      <div className='cellNoWrap'>
        {renderDate(cellText.lastOrderCreationDate, '-')}
      </div>
    )
  },
  {
    title: 'Name',
    renderer: (cellText) => <div>{cellText.name || ''}</div>
  },
  {
    title: 'Email',
    renderer: (cellText) => <div>{cellText.email || ''}</div>
  },
  {
    title: 'Phone',
    renderer: (cellText) => <div>{cellText.phone || ''}</div>
  },
  {
    title: 'Avg. Order',
    renderer: (cellText) => <div>{renderAmounts(cellText.spendPerOrder)}</div>
  }
];

export const FIELDS_FEEDBACK_SUMMARY = [
  {
    title: 'Date',
    titleStyles: {},
    renderer: (cellText) => (
      <div className='cellNoWrap'>{renderDate(cellText.createdDate, '-')}</div>
    )
  },
  {
    title: 'Order type',
    renderer: (cellText) => (
      <div>{feedbackTypes[cellText.orderDeliveryType] || '-'}</div>
    )
  },
  {
    title: 'Score',
    renderer: (cellText) => (
      <div>{`${cellText.overallExperience}/5` || '-'}</div>
    )
  },
  {
    title: 'Google review',
    renderer: (cellText) => (
      <div>{cellText.googleReviewClicked ? 'Yes' : 'No'}</div>
    )
  },
  {
    title: 'Additional information',
    titleStyles: {},
    renderer: (cellText) => (
      <div className='cellWordBreak'>
        {cellText?.additionalInformation || '-'}
      </div>
    )
  },
  {
    title: 'Customer Name',
    renderer: (cellText) => <div>{cellText.customerName || '-'}</div>
  },
  {
    title: 'Customer phone',
    renderer: (cellText) => (
      <div>
        {cellText.customerPhone
          ? cellText.customerPhone.replace(/say2eat_-|say2eat_/, '')
          : '-'}
      </div>
    )
  }
];

export const FIELDS_SALES_SUMMARY = [
  {
    title: 'Order Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{renderDate(cellText.salesDate, '-')}</div>
    )
  },
  {
    title: 'Subtotal',
    renderer: (cellText) => <div>{renderAmounts(cellText.subtotal)}</div>
  },
  {
    title: 'Tax',
    renderer: (cellText) => <div>{renderAmounts(cellText.tax)}</div>
  },
  {
    title: 'Tip',
    renderer: (cellText) => <div>{renderAmounts(cellText.tip)}</div>
  },
  {
    title: 'Discount',
    renderer: (cellText) => (
      <div>
        {renderAmounts(cellText.discount, <>&nbsp;&nbsp;$0.00</>, true)}
      </div>
    )
  },
  {
    title: 'Gross',
    renderer: (cellText) => <div>{renderAmounts(cellText.gross)}</div>
  },
  {
    title: (
      <div
      // style={{textAlign: 'center'}}
      >
        <span>
          Delivery and
          <br />
          Processing Fees
        </span>
      </div>
    ),
    renderer: (cellText) => (
      <div
      // className='centeredText'
      >
        <span>
          {renderAmounts(
            cellText.transactionAndDeliveryFees,
            <>&nbsp;&nbsp;$0.00</>,
            true
          )}
        </span>
      </div>
    )
  },
  {
    title: 'Refunds',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.refunds, <>&nbsp;&nbsp;$0.00</>, true)}</div>
    )
  }
  // {
  //   title: 'Payout Date',
  //   renderer: (cellText) => {
  //     if (cellText.payouts && cellText.payouts.length) {
  //       return (
  //         <>
  //           {cellText.payouts.map((item) => {
  //             return (
  //               <div className='reportsPayoutStatusContainer'>
  //                 <div className='cellNoWrap'>
  //                   {renderDate(item.arrivalDate, 'Future transaction')}
  //                 </div>
  //               </div>
  //             );
  //           })}
  //           {outOfRangePayoutsText(cellText)}
  //         </>
  //       );
  //     }
  //     return <div>Future transaction</div>;
  //   }
  // },
  // {
  //   title: 'Payout Amount',
  //   renderer: (cellText) => {
  //     if (cellText.payouts && cellText.payouts.length) {
  //       return (
  //         cellText.payouts.map((item) => {
  //           return (
  //             <div className='reportsPayoutStatusContainer'>
  //               <div className='cellNoWrap'>
  //                 {renderAmounts(item.amount, '-')}
  //               </div>
  //               {item.status === 'failed' && (
  //                 <span className='reportsPayoutStatus'>
  //                     Transaction failed
  //                   </span>
  //               )}
  //             </div>
  //           );
  //         })
  //       );
  //     }
  //     return <div>-</div>;
  //   }
  // }
];

export const FIELDS_PAYOUT_SUMMARY = [
  {
    title: 'Payout Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{renderDate(cellText.arrivalDate, '-')}</div>
    )
  },
  {
    title: 'Payout Amount',
    renderer: (cellText) => (
      <div className='reportsPayoutStatusContainer'>
        <div>{renderAmounts(cellText.amount)}</div>
        {cellText.status === 'failed' && (
          <span className='reportsPayoutStatus'>Transaction failed</span>
        )}
      </div>
    )
  }
];

export const FIELDS_BOOK_A_DRIVER = [
  {
    title: 'Order Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>
        {renderDate(cellText.order.placedAt.local, '-')}
      </div>
    )
  },
  {
    title: 'Order Time',
    renderer: (cellText) => (
      <div className='cellNoWrap'>
        {cellText.order.placedAt.local
          ? moment(cellText.order.placedAt.local).format('LT')
          : '-'}
      </div>
    )
  },
  {
    title: 'Subtotal',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.subtotal, '-')}</div>
    )
  },
  {
    title: 'Courier Tip',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.courierTip, '-')}</div>
    )
  },
  {
    title: 'Delivery Fee',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.deliveryFee, '-')}</div>
    )
  },
  {
    title: 'Customer Name',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{cellText.order.customerName}</div>
    )
  },
  {
    title: 'Customer Address',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{cellText.order.customerEmail}</div>
    )
  }
];

export const FIELDS_BYOC = [
  {
    title: 'Order Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>
        {renderDate(cellText.order.placedAt.local, '-')}
      </div>
    )
  },
  {
    title: 'Orders',
    renderer: (cellText) => (
      <div>{cellText.order.orders ? cellText.order.orders : '-'}</div>
    )
  },
  {
    title: 'Subtotal',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.subtotal, '-')}</div>
    )
  },
  {
    title: 'Tax',
    renderer: (cellText) => <div>{renderAmounts(cellText.order.tax, '-')}</div>
  },
  {
    title: 'Gross',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.gross, '-')}</div>
    )
  },
  {
    title: 'Delivery fee',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.order.deliveryFee, '-')}</div>
    )
  }
];

export const FIELDS_REFUNDS_SUMMARY = [
  {
    title: 'Order Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{renderDate(cellText.orderDate, '-')}</div>
    )
  },
  {
    title: 'Refund Request Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>{renderDate(cellText.requestDate, '-')}</div>
    )
  },
  {
    title: 'Order Type',
    renderer: (cellText) => (
      <div>{ORDER_TYPES[OrderType[cellText.orderDeliveryType]] || '-'}</div>
    )
  },
  {
    title: (
      <div
      // style={{textAlign: 'center'}}
      >
        <span>Refund Amount</span>
      </div>
    ),
    renderer: (cellText) => (
      <div
      // className='centeredText'
      >
        <span>
          {renderAmounts(cellText.amount, <>&nbsp;&nbsp;$0.00</>, true)}
        </span>
      </div>
    )
  },
  {
    title: 'Payment Date',
    renderer: (cellText) => (
      <div className='cellNoWrap'>
        {renderDate(cellText.withheldDate, 'Pending')}
      </div>
    )
  },
  {
    title: 'Customer Name',
    renderer: (cellText) => <div>{cellText.customerName || '-'}</div>
  },
  {
    title: 'Order ID',
    renderer: (cellText) => (
      <div>
        {cellText.orderInvoiceId
          ? cellText.orderInvoiceId.replace(/say2eat_-|say2eat_/, '')
          : '-'}
      </div>
    )
  }
];

export const TOTAL_SALES_SUMMARY = [
  {
    title: 'Order Date',
    renderer: () => <div>Total</div>
  },
  {
    title: 'Subtotal',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.subtotal, '$0.00')}</div>
    )
  },
  {
    title: 'Tax',
    renderer: (cellText) => <div>{renderAmounts(cellText.tax, '$0.00')}</div>
  },
  {
    title: 'Tip',
    renderer: (cellText) => <div>{renderAmounts(cellText.tip, '$0.00')}</div>
  },
  {
    title: 'Discount',
    renderer: (cellText) => (
      <div>
        {renderAmounts(cellText.discount, <>&nbsp;&nbsp;$0.00</>, true)}
      </div>
    )
  },
  {
    title: 'Gross',
    renderer: (cellText) => <div>{renderAmounts(cellText.gross, '$0.00')}</div>
  },
  {
    title: 'Delivery and Processing Fees',
    renderer: (cellText) => (
      <div
      // className='centeredText'
      >
        <span>
          {renderAmounts(
            cellText.transactionAndDeliveryFees,
            <>&nbsp;&nbsp;$0.00</>,
            true
          )}
        </span>
      </div>
    )
  },
  {
    title: 'Refunds',
    renderer: (cellText) => (
      <div>{renderAmounts(cellText.refunds, <>&nbsp;&nbsp;$0.00</>, true)}</div>
    )
  }
  // {
  //   title: 'Payout Date',
  //   renderer: () => <div>-</div>
  // },
  // {
  //   title: 'Payout Amount',
  //   renderer: (cellText) => (
  //     <div className='reportsPayoutStatusContainer'>
  //       <div>{renderAmounts(cellText.payoutAmount)}</div>
  //       {cellText.payoutStatus && (
  //         <span className='reportsPayoutStatus'>Transaction failed</span>
  //       )}
  //     </div>
  //   )
  // }
];

export const TOTAL_CUSTOMERS = [];

export const TOTAL_PAYOUT_SUMMARY = [
  {
    title: 'Payout Date',
    renderer: () => <div>Total</div>
  },
  {
    title: 'Payout Amount',
    renderer: (cellText) => (
      <div className='reportsPayoutStatusContainer'>
        <div>{renderAmounts(cellText.amount)}</div>
        {cellText.status && (
          <span className='reportsPayoutStatus'>Transaction failed</span>
        )}
      </div>
    )
  }
];

export const TOTAL_REFUNDS_SUMMARY = [
  {
    title: 'Order Date',
    renderer: () => <div>Total</div>
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  },
  {
    title: 'Payout Amount',
    renderer: (cellText) => (
      <div
      // className='centeredText'
      >
        <span>
          {renderAmounts(cellText.totalAmount, <>&nbsp;&nbsp;$0.00</>, true)}
        </span>
      </div>
    )
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  }
];

export const TOTAL_FEEDBACK_SUMMARY = [
  {
    title: 'Order Date',
    renderer: () => <div>Total</div>
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  },
  {
    title: 'Payout Amount',
    renderer: (cellText) => (
      <div
      // className='centeredText'
      >
        <span>
          {renderAmounts(cellText.totalAmount, <>&nbsp;&nbsp;$0.00</>, true)}
        </span>
      </div>
    )
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  },
  {
    renderer: () => ''
  }
];
