import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MenuQueueHeadCells} from '../../../../helpers/constants/Table';
import {ReviewsInner} from './MenuQueueContainerStyle';
import Headline from '../../../../components/headline2/HeadlineComponent';
import QueueTable from './QueueTable/QueueTable';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import IconAdd from '../../../../assets/IconAdd';
import {
  DELETE_MENU_QUEUE_ITEM,
  GET_MENU_QUEUE
} from '../../../../store/menuQueue/action';
import AddMenuQueueItem from './Modal/AddMenuQueueItem';
import {DashboardContent} from '../../../../components/DashboardContent/styles';

const QueueContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalModel, setModalModel] = useState({});
  const dispatch = useDispatch();
  const {loading, data} = useSelector((state) => state.menuQueueReducer);
  useEffect(() => {
    dispatch(GET_MENU_QUEUE());
  }, []);

  const buttonInfo = (
    <ButtonPrimary
      className='ButtonAdd ButtonMedium'
      onClick={() => setOpenModal(!openModal)}
    >
      <IconAdd />
      <span>Add New</span>
    </ButtonPrimary>
  );

  return (
    <DashboardContent className='DashboardContent DashboardContentWithoutHeader'>
      <Headline label='Menu Queue' buttonInfo={buttonInfo} />
      <ReviewsInner>
        <QueueTable
          loading={loading}
          headCells={MenuQueueHeadCells}
          onDelete={(id) => dispatch(DELETE_MENU_QUEUE_ITEM(id))}
          onEdit={(row) => {
            setModalModel(row);
            setOpenModal(true);
          }}
          items={data}
        />
      </ReviewsInner>
      <AddMenuQueueItem
        open={openModal}
        modalModel={modalModel}
        onClose={() => {
          setOpenModal(!openModal);
          setModalModel({});
        }}
      />
    </DashboardContent>
  );
};

QueueContainer.propTypes = {};

QueueContainer.defaultProps = {};

export default QueueContainer;
