import React from 'react';
import PropTypes from 'prop-types';
import FlexCntnr from '../../layout/FlexContainer/styles';
import FlexItm from '../../layout/FlexItem/styles';

const DisplayField = ({
  spaceBetween,
  space,
  shrinkLabel,
  label,
  value,
  flexWrap,
  flexItemFullWidth,
  ...other
}) => (
  <FlexCntnr
    justifyContent={spaceBetween ? 'space-between' : null}
    flexWrap={flexWrap}
    {...other}
  >
    <FlexItm
      className={`marginRight${space}`}
      flex={!shrinkLabel && '0 0 auto'}
    >
      {label}
    </FlexItm>
    <FlexItm
      className={!flexItemFullWidth && 'FlexItmValue'}
      a={shrinkLabel && '0 0 auto'}
    >
      {value}
    </FlexItm>
  </FlexCntnr>
);

DisplayField.propTypes = {
  space: PropTypes.string,
  label: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  shrinkLabel: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  flexWrap: PropTypes.bool,
  flexItemFullWidth: PropTypes.bool
};

DisplayField.defaultProps = {
  space: 'Md',
  shrinkLabel: false,
  spaceBetween: false,
  flexWrap: false,
  flexItemFullWidth: false
};

export default DisplayField;
