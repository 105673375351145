import styled from 'styled-components';

const Body6 = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #232d3a;
  &.fw400 {
    font-weight: 400;
  }
  &.fw700 {
    font-weight: 700;
  }
  &.mB7 {
    margin-bottom: 7px;
  }
`;

export default Body6;
