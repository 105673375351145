import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {SET_ACTIVE_TAB} from '../../../../store/menuEditor/action';
import {DashboardAccordions, DashboardSection} from '../../dashboard/styles';
import Dgd from '../DgD';
import TabPanel from '../../../../components/shared/TabPanel';
import {ITEM_SCHEME} from '../../../../store/menuEditor/helper/MenuTree';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import ButtonScrollTop from '../../../../components/buttons/ButtonScrollTop';
import ElementsNotFoundMessage from '../ElementsNotFoundMessage';

const ItemsContainer = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.menuEditorReducer.activeTab);
  const activeTree = useSelector(
    (state) => state.menuEditorReducer.activeItemsDirection
  );
  const selectedItems = useSelector(
    (store) => store.menuEditorReducer.selectedItems
  );

  const pageCount = 20;
  const [page, setPage] = useState(1);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    dispatch(SET_ACTIVE_TAB('ITEM'));
  }, []);

  useEffect(() => {
    setItemList(activeTree.slice(0, pageCount));
    setPage(1);
  }, [activeTree]);

  useEffect(() => {
    setItemList(activeTree.slice(0, pageCount * page));
  }, [page]);

  const hasNextPage = itemList.length < activeTree.length;
  const onPageChange = () => {
    setPage(page + 1);
  };

  if (!activeTree || activeTab !== 'ITEM') {
    return null;
  }

  if (!activeTree.length) {
    return (
      <ElementsNotFoundMessage message='You don’t have any created items yet.' />
    );
  }

  return (
    <>
      <InfiniteScroll
        dataLength={activeTree.length}
        next={onPageChange}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'inherit'
        }}
        hasMore={hasNextPage}
      >
        <TabPanel>
          <DashboardSection className='Mt20'>
            <DashboardAccordions>
              {Boolean(itemList.length) && (
                <Dgd
                  withoutMainDrag
                  items={itemList}
                  itemScheme={ITEM_SCHEME}
                  withBulkCheckbox
                  bulkSelectedMap={selectedItems}
                />
              )}
            </DashboardAccordions>
          </DashboardSection>
        </TabPanel>
        <ButtonScrollTop />
      </InfiniteScroll>
      {hasNextPage && (
        <ButtonPrimary
          type='button'
          className='loadMore'
          onClick={onPageChange}
        >
          <span>Load More</span>
        </ButtonPrimary>
      )}
    </>
  );
};

export default ItemsContainer;
