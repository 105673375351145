import {createAction} from 'redux-act';

export const FIND_DELIVERY_GET_ORDER = createAction(
  'findDelivery/FIND_DELIVERY_GET_ORDER'
);
export const FIND_DELIVERY_GET_ORDER_SUCCESS = createAction(
  'findDelivery/FIND_DELIVERY_GET_ORDER_SUCCESS'
);
export const FIND_DELIVERY_SET_LOADING = createAction(
  'findDelivery/FIND_DELIVERY_SET_LOADING'
);

export const FIND_DELIVERY_CLEAR_ORDER = createAction(
  'findDelivery/FIND_DELIVERY_CLEAR_ORDER'
);

export const FIND_DELIVERY_SET_ERROR = createAction(
  'findDelivery/FIND_DELIVERY_SET_ERROR'
);
