import {createReducer} from 'redux-act';
import {
  CHANGE_BYOC_CONFIGURATION_STATUS,
  CHANGE_BYOC_CONNECTION_STATUS,
  CHANGE_UBER_STATUS_SUCCESS,
  DISCONNECT_UBER_EATS,
  DISCONNECT_UBER_EATS_SUCCESS,
  CLEAR_DATA,
  CONNECT_UBEREATS_STORE_SUCCESS,
  GET_UBEREATS_DATA,
  GET_UBEREATS_DATA_SUCCESS,
  GET_UBEREATS_STORES_SUCCESS,
  HANDLE_CONNECT_MODAL,
  HANDLE_CONNECT_STORE_MODAL,
  HANDLE_DISCONNECT_MODAL,
  HANDLE_NOT_STORE_MODAL,
  SET_UBEREATS_ACCOUNT,
  UBER_BANNER_SHOW,
  SET_UBEREATS_STORE_ID
} from './actions';

const initialState = () => ({
  connectModalOpen: false,
  disconnectModalOpen: 0,
  disconnectFormValue: {},
  uberEatsEnabled: false,
  uberBannerShow: false,
  uberEatsId: '',
  BYOCconnected: false,
  BYOCconfigured: false,
  uberEatsInitiallyConnected: false,
  loading: false,
  uberEatsData: null,
  uberEatsActiveStores: [],
  isUberEatsAccountConnected: false,
  connectStoreModalOpen: false,
  notStoresModalOpen: false,
  uberEatsStoreId: ''
});

const uberEatsReducer = createReducer(
  {
    [GET_UBEREATS_DATA]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_UBEREATS_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      uberEatsData: payload,
      loading: false
    }),
    [GET_UBEREATS_STORES_SUCCESS]: (state, payload) => ({
      ...state,
      uberEatsActiveStores: payload
    }),
    [SET_UBEREATS_ACCOUNT]: (state, payload) => ({
      ...state,
      isUberEatsAccountConnected: payload
    }),
    [SET_UBEREATS_STORE_ID]: (state, payload) => ({
      ...state,
      uberEatsStoreId: payload
    }),

    [CHANGE_UBER_STATUS_SUCCESS]: (state, payload) => ({
      ...state,
      uberEatsData: payload
    }),
    [CONNECT_UBEREATS_STORE_SUCCESS]: (state, payload) => ({
      ...state,
      uberEatsData: payload
    }),
    [DISCONNECT_UBER_EATS_SUCCESS]: (state, payload) => ({
      ...state,
      disconnectFormValue: payload
    }),
    [CHANGE_BYOC_CONFIGURATION_STATUS]: (state, payload) => ({
      ...state,
      BYOCconfigured: payload
    }),
    [CHANGE_BYOC_CONNECTION_STATUS]: (state, payload) => ({
      ...state,
      BYOCconnected: payload
    }),
    [CLEAR_DATA]: () => ({
      ...initialState()
    }),
    [HANDLE_CONNECT_MODAL]: (state, open) => ({
      ...state,
      connectModalOpen: open
    }),
    [HANDLE_CONNECT_STORE_MODAL]: (state, open) => ({
      ...state,
      connectStoreModalOpen: open
    }),
    [HANDLE_NOT_STORE_MODAL]: (state, open) => ({
      ...state,
      notStoresModalOpen: open
    }),
    [HANDLE_DISCONNECT_MODAL]: (state, open) => ({
      ...state,
      disconnectModalOpen: open
    }),
    [UBER_BANNER_SHOW]: (state, payload) => ({
      ...state,
      uberBannerShow: payload
    })
  },
  initialState()
);

export default uberEatsReducer;
