import React from 'react';
import T from 'prop-types';
import AnimatePointsContainer from './styles';

const AnimatePoints = ({className}) => {
  return (
    <AnimatePointsContainer className={className}>
      <span className='PointOne'>.</span>
      <span className='PointTwo'>.</span>
      <span className='PointThree'>.</span>
    </AnimatePointsContainer>
  );
};

AnimatePoints.propTypes = {
  className: T.bool
};

AnimatePoints.defaultProps = {className: ''};

export default AnimatePoints;
