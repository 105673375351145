import {GET, POST} from '../constants/API_CONSTANTS';
import {
  RC_GET_ORDER,
  RC_GENERATE_ORDER_REFUND,
  RC_MAKE_ORDER_REFUND
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const rcGetOrderApi = async (invoiceId) => {
  return request(
    GET,
    RC_GET_ORDER(invoiceId),
    {},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REFUND_CALCULATOR_API_TOKEN}`
      }
    }
  );
};

export const rcGenerateOrderRefundApi = async (id, data) => {
  return request(
    POST,
    RC_GENERATE_ORDER_REFUND(id),
    {data},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REFUND_CALCULATOR_API_TOKEN}`
      }
    }
  );
};
export const rcMakeOrderRefundApi = async (id, data) => {
  return request(
    POST,
    RC_MAKE_ORDER_REFUND(id),
    {data},
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_REFUND_CALCULATOR_API_TOKEN}`
      }
    }
  );
};
