import React, {memo} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import DiscountsList from './DiscountsList';
import DiscountWizardContainer from './DiscountWizard/DiscountWizardContainer';

const DiscountsRoutes = () => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      {/* <Route exact path={`${path}/build`} component={BuildDiscount} /> */}
      <Route path={`${path}/wizard`} component={DiscountWizardContainer} />
      <Route path={`${path}/`} component={DiscountsList} />
    </Switch>
  );
};

export default memo(DiscountsRoutes);
