import styled from 'styled-components';
import AbstractText from '../AbstractText';

const Body3 = styled(AbstractText)`
  font-size: 18px;
  font-weight: normal;
  color: #232d3a;
  @media (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 440px) {
    font-size: 14px;
  }
  &.Green {
    color: #17c981;
  }
  &.Red {
    color: #ff2d38;
  }
  span.Red {
    color: #ff2d38;
  }
  &.White {
    color: #fff;
  }

  strong.Red {
    color: #ff2d38;
  }
`;

export default Body3;
