import React, {useEffect, useState} from 'react';
import {nanoid} from 'nanoid';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
  FEATURE_RESTRICTION_IDS,
  STRIPE_ACCOUNT_RESTRICTION_NAMES
} from '../../../../helpers/constants/restrictions';

export default function ReportsTypePicker({reportType, setReportType}) {
  const handleChange = (event) => {
    setReportType(event.target.value);
  };
  const {
    AllowedServiceFeatures,
    StripeAccountType,
    hasReceivedSurveys
  } = useSelector((state) => state.user.activeLocation);

  const defaultOptions = [
    {
      name: 'Sales summary',
      type: 0,
      featureFlag: FEATURE_RESTRICTION_IDS.SALES_REPORTS,
      stripeAccountRestriction:
        STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD
    },
    {
      name: 'Customers summary',
      type: 1,
      featureFlag: FEATURE_RESTRICTION_IDS.CUSTOMERS_REPORTS
    },
    {name: 'Payout summary', type: 2},
    // {name: 'BYOC', type: 3},
    // {name: 'Book a Driver', type: 4},
    {
      name: 'Refunds summary',
      type: 5,
      featureFlag: FEATURE_RESTRICTION_IDS.REFUNDS_REPORTS,
      stripeAccountRestriction:
        STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD
    },
    {
      name: 'Feedback summary',
      type: 6,
      featureFlag: FEATURE_RESTRICTION_IDS.FEEDBACK && !hasReceivedSurveys
    }
  ];

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      defaultOptions.filter((item) => {
        return (
          (!item.featureFlag ||
            AllowedServiceFeatures.includes(item.featureFlag)) &&
          (!item.stripeAccountRestriction ||
            item.stripeAccountRestriction !== StripeAccountType)
        );
      })
    );
    setReportType(2);
  }, [AllowedServiceFeatures]);

  return (
    <Box sx={{minWidth: 120}} className='Say2EatDrop'>
      <FormControl fullWidth variant='outlined'>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={reportType}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {options.map((item) => (
            <MenuItem
              className='reportTypeItem'
              key={nanoid()}
              value={item.type}
              disableRipple
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

ReportsTypePicker.propTypes = {
  reportType: PropTypes.number.isRequired,
  setReportType: PropTypes.func.isRequired
};
