import styled from 'styled-components';
import IconConnect from '../../../../assets/icon-connect.svg';

export const OrdersContainer = styled.div`
  .MuiTable-root {
    .MuiTableCell-head {
      font-size: 14px;
      color: #172751;
    }

    .headTableCell {
      & > * {
        font-size: 14px;
        color: #172751;
      }
    }

    .MuiTableCell-root {
      padding: 5px 2px;

      &:first-child {
        padding-left: 28px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }

    thead {
      min-height: 61px;
      height: 61px;

      tr {
        th {
          &:first-child {
            min-width: 170px !important;
          }

          &:nth-child(2) {
            width: 10% !important;
          }

          &:nth-child(3) {
            min-width: 68px !important;
          }

          &:nth-child(4) {
            min-width: 68px !important;
          }

          &:nth-child(5) {
            width: 10% !important;
          }

          &:nth-child(6) {
            width: 10% !important;
          }

          &:nth-child(7) {
            width: 15% !important;
          }

          &:nth-child(8) {
            min-width: 100px !important;
          }

          &:nth-last-child(3) {
            min-width: 154px !important;
            @media (max-width: 1420px) {
              min-width: 120px !important;
            }
          }

          &:nth-last-child(2) {
            min-width: 140px !important;
            @media (max-width: 1420px) {
              min-width: 126px !important;
            }
          }

          &:last-child {
            min-width: 70px !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            min-width: 170px !important;
          }

          &:nth-child(2) {
            width: 10% !important;
          }

          &:nth-child(3) {
            min-width: 68px !important;
          }

          &:nth-child(4) {
            min-width: 68px !important;
          }

          &:nth-child(5) {
            width: 10% !important;
          }

          &:nth-child(6) {
            width: 10% !important;
          }

          &:nth-child(7) {
            width: 15% !important;
          }

          &:nth-last-child(3) {
            min-width: 154px !important;
            @media (max-width: 1420px) {
              min-width: 120px !important;
            }
          }

          &:nth-last-child(2) {
            min-width: 140px !important;
            @media (max-width: 1420px) {
              min-width: 126px !important;
            }
          }

          &:last-child {
            min-width: 70px !important;
          }

          @media (max-width: 1420px) {
            * {
              font-size: 13px;
            }
          }
          @media (max-width: 1360px) {
            * {
              font-size: 12.5px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
    margin: 0 -30px;
    & > div {
      padding: 0 30px 20px 30px;
      min-width: 760px;
    }

    table {
      min-width: 700px;
    }
  }
  @media (max-width: 599px) {
    margin: 0 -20px;
    & > div {
      padding: 0 20px 20px 20px;
    }
  }

  tbody {
    td {
      text-align: center;
    }
  }

  .MuiTable-root .MuiTableRow-root.tableRow.sameRow {
    border-radius: 0;

    @keyframes hideWhenBlinking {
      0% {
        opacity: 0;
      }
      99.9% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.blink1times {
      box-shadow: 1px 10px 10px rgb(108 49 151 / 1.5%),
        10px 10px 20px rgb(108 49 151 / 1%),
        30px 30px 30px rgb(108 49 151 / 0.5%);
      td {
        &:first-child {
          &::before {
            animation-name: hideWhenBlinking;
            animation-duration: 0.99s;
            animation-iteration-count: 1;
          }
        }
      }
      &.sameLast {
        box-shadow: 1px 10px 10px rgb(108 49 151 / 20%),
          10px 10px 20px rgb(108 49 151 / 6%),
          30px 30px 30px rgb(108 49 151 / 4%);
      }
    }

    &.blink3times {
      box-shadow: 1px 10px 10px rgb(108 49 151 / 1.5%),
        10px 10px 20px rgb(108 49 151 / 1%),
        30px 30px 30px rgb(108 49 151 / 0.5%);
      td {
        &:first-child {
          &::before {
            animation-name: hideWhenBlinking;
            animation-duration: 2.99s;
            animation-iteration-count: 1;
          }
        }
      }
      &.sameLast {
        box-shadow: 1px 10px 10px rgb(108 49 151 / 20%),
          10px 10px 20px rgb(108 49 151 / 6%),
          30px 30px 30px rgb(108 49 151 / 4%);
      }
    }

    td {
      position: relative;
      border-top-width: 0;
      border-bottom-width: 0;

      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &::before {
          position: absolute;
          pointer-events: none;
          content: '';
          left: -4px;
          top: 100%;
          transform: translateY(-50%);
          width: 41px;
          height: 41px;
          background-image: url(${IconConnect});
          background-repeat: no-repeat;
          background-size: contain;
          z-index: 2;
        }
      }

      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &::after {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #bdbdbd;
        content: '';
        z-index: 1;
      }

      &:first-child {
        &::after {
          left: 30px;
        }
      }

      &:last-child {
        &::after {
          right: 22px;
        }
      }
    }

    & + div.TableBottomIndent {
      margin: 0 !important;
    }
  }

  .MuiTable-root .MuiTableRow-root.tableRow.sameFirst {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    td {
      border-top-width: 2px;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  .MuiTable-root .MuiTableRow-root.tableRow.sameLast {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    & + div.TableBottomIndent {
      margin: 5px !important;
    }

    &.rdbLastOfTypeRow {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      & + div.TableBottomIndent {
        margin: 16px !important;
      }
    }

    td {
      border-top: none;
      border-bottom-width: 2px;

      &::after {
        display: none;
      }

      &::before {
        display: none;
      }

      &:first-child {
        border-top: none;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 0;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0;
      }
    }
  }
`;

export const ProviderTrackingLink = styled.a`
  color: #ff2d38;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export default OrdersContainer;
