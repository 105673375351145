import styled from 'styled-components';

const PublishOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2f3d48;
  opacity: 0.7;
  z-index: 1;
`;

export default PublishOverlay;
