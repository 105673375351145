import styled from 'styled-components';

export const NewPasswordImage = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 60px auto;
  max-width: 330px;
`;

export default NewPasswordImage;
