import {createAction} from 'redux-act';

export const GET_INFO_VN = createAction('virtualNumber/GET_INFO_VN');
export const GET_INFO_VN_SUCCESS = createAction(
  'virtualNumber/GET_INFO_VN_SUCCESS'
);
export const CREATE_VN = createAction('virtualNumber/CREATE_VN');
export const UPDATE_VN = createAction('virtualNumber/UPDATE_VN');
export const CREATE_TWILIO = createAction('virtualNumber/CREATE_TWILIO');
export const ACTIVATE_VN = createAction('virtualNumber/ACTIVATE_VN');
export const SET_SHOW_MODAL = createAction('virtualNumber/SET_SHOW_MODAL');
export const SET_LOADING = createAction('virtualNumber/SET_LOADING');
export const SET_STATUS_FOR_EDIT = createAction(
  'virtualNumber/SET_STATUS_FOR_EDIT'
);
export const SET_PHONE_NUMBER_UPDATED = createAction(
  'virtualNumber/SET_PHONE_NUMBER_UPDATED'
);
export const DISABLED_VN = createAction('virtualNumber/DISABLED_VN');

export const SET_IS_EDIT_FLAG = createAction('virtualNumber/SET_IS_EDIT_FLAG');
