import {createAction} from 'redux-act';

export const CLEAR_BAD_DELIVERED_ORDERS = createAction(
  'bookADriveList/CLEAR_BAD_DELIVERED_ORDERS'
);
export const RUN_BAD_DELIVERED_ORDERS_JOB = createAction(
  'bookADriveList/RUN_BAD_DELIVERED_ORDERS_JOB'
);
export const STOP_BAD_DELIVERED_ORDERS_JOB = createAction(
  'bookADriveList/STOP_BAD_DELIVERED_ORDERS_JOB'
);
export const SET_BAD_NEW_DELIVERED_ORDERS = createAction(
  'bookADriveList/SET_BAD_NEW_DELIVERED_ORDERS'
);
export const SET_BAD_DELIVERED_ORDERS = createAction(
  'bookADriveList/SET_BAD_DELIVERED_ORDERS'
);
export const SET_BAD_ACTIVE_ORDERS = createAction(
  'bookADriveList/SET_BAD_ACTIVE_ORDERS'
);
export const SET_BAD_NEW_ACTIVE_ORDERS = createAction(
  'bookADriveList/SET_BAD_NEW_ACTIVE_ORDERS'
);
export const SET_BAD_PREV_ACTIVE_ORDERS = createAction(
  'bookADriveList/SET_BAD_PREV_ACTIVE_ORDERS'
);
export const CLEAR_BAD_NEW_ACTIVE_ORDERS = createAction(
  'bookADriveList/CLEAR_BAD_NEW_ACTIVE_ORDERS'
);
export const SET_BAD_DELIVERED_ORDERS_COUNT = createAction(
  'bookADriveList/SET_BAD_DELIVERED_ORDERS_COUNT'
);
export const CLEAR_BAD_NEW_DELIVERED_ORDERS = createAction(
  'bookADriveList/CLEAR_BAD_NEW_DELIVERED_ORDERS'
);
export const SET_BAD_TABLE_CONFIG = createAction(
  'bookADriveList/SET_BAD_TABLE_CONFIG'
);
