import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/Modals/Modal';
import {DialogAction, DialogItemIcon} from '../../../dashboard/styles';
import IconTypeBuild from '../../../../../assets/IconBuild';
import IconTypeWizard from '../../../../../assets/IconWizard';
import ButtonPrimary from '../../../../../components/buttons/ButtonPrimary/styles';
import DialogTitle from '../../../../../components/DialogTitle/styles';
import {CardsButtonGrid} from '../../../../../components/CardsButtonGrid/styles';
import {CardsButton} from '../../../../../components/CardsButton/styles';

const ModalNewDiscount = ({onCloseAction, submitAction}) => {
  return (
    <Modal
      onCloseAction={onCloseAction}
      title={<DialogTitle>What would you like to do?</DialogTitle>}
      body={
        <>
          <CardsButtonGrid className='CardsButtonGridNewDiscount'>
            <CardsButton className='isDisabled'>
              <DialogItemIcon>
                <IconTypeBuild />
              </DialogItemIcon>
              <span>Build your Own Discount (Coming soon)</span>
            </CardsButton>
            <CardsButton className='isActive'>
              <DialogItemIcon>
                <IconTypeWizard />
              </DialogItemIcon>
              <span>Use our Discount Wizard</span>
            </CardsButton>
          </CardsButtonGrid>
          <DialogAction>
            <ButtonPrimary onClick={(e) => submitAction(e, 'Wizard')}>
              <span>Apply</span>
            </ButtonPrimary>
          </DialogAction>
        </>
      }
    />
  );
};

ModalNewDiscount.propTypes = {
  onCloseAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default ModalNewDiscount;
