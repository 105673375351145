import React from 'react';

export const IconItem = () => (
  <svg
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.69 8.47545L15.3852 0.183882C15.1355 -0.0166566 14.7935 -0.0567745 14.5042 0.0815563C14.2153 0.219938 14.0314 0.511479 14.0314 0.831915V4.22148C13.6031 4.24377 13.1695 4.28353 12.7328 4.34C11.821 1.81206 9.39802 0 6.55959 0C2.94248 0 0 2.94248 0 6.55959C0 10.1767 2.94248 13.1196 6.55959 13.1196C10.1772 13.1196 13.1196 10.1767 13.1196 6.55959C13.1196 6.36175 13.1103 6.16608 13.0931 5.9727C13.6939 5.90069 14.2867 5.86332 14.8633 5.86332C15.323 5.86332 15.6957 5.49103 15.6957 5.03135V2.56887L23.8413 9.12308L15.6958 15.6777V15.4583C15.6958 14.9986 15.3231 14.6263 14.8634 14.6263C14.4037 14.6263 14.0314 14.9986 14.0314 15.4583V17.4151C14.0314 17.7355 14.2153 18.0271 14.5042 18.1655C14.6186 18.22 14.7412 18.247 14.8634 18.247C15.0504 18.247 15.2347 18.1848 15.3857 18.0636L25.69 9.77157C25.886 9.61384 26.0001 9.37496 26.0001 9.12349C26.0001 8.87201 25.8865 8.63364 25.69 8.47545ZM6.55959 11.4558C3.86001 11.4558 1.66388 9.25918 1.66388 6.55914C1.66388 3.85955 3.86001 1.66342 6.55959 1.66342C9.25918 1.66342 11.4558 3.85955 11.4558 6.55914C11.4558 9.25918 9.25918 11.4558 6.55959 11.4558Z'
      fill='#B1B8CC'
    />
    <path
      d='M14.8635 14.0487C15.3232 14.0487 15.6959 13.676 15.6959 13.2162C15.6959 12.7566 15.3233 12.3843 14.8635 12.3843C11.0391 12.3843 7.95191 13.8584 6.39351 16.4282C5.28752 18.2521 5.03696 20.4987 5.63096 22.8436C4.2118 21.4673 2.6087 19.1161 2.6087 15.517C2.6087 14.8229 2.68127 14.1492 2.82412 13.515C2.92508 13.0671 2.64339 12.6218 2.19498 12.5208C1.74835 12.419 1.30172 12.7016 1.20036 13.15C1.0309 13.9039 0.944824 14.7003 0.944824 15.5174C0.944824 22.9919 6.81672 25.8064 7.06683 25.9226C7.17906 25.9749 7.2985 26.0001 7.41702 26.0001C7.63965 26.0001 7.85822 25.9113 8.01823 25.7432C8.26432 25.4854 8.31927 25.0996 8.15433 24.7837C6.69911 21.9931 6.57876 19.3319 7.81632 17.2912C9.06602 15.2308 11.6344 14.0487 14.8635 14.0487Z'
      fill='#B1B8CC'
    />
    <path
      d='M7.39196 6.21484V3.23138C7.39196 2.7717 7.01927 2.39941 6.55999 2.39941C6.10072 2.39941 5.72803 2.7717 5.72803 3.23138V6.55965C5.72803 6.7805 5.81593 6.99186 5.97183 7.14821L7.46945 8.64538C7.6317 8.80763 7.84489 8.88872 8.05761 8.88872C8.2708 8.88872 8.48393 8.80763 8.64572 8.64492C8.97068 8.31996 8.97068 7.79315 8.64572 7.46819L7.39196 6.21484Z'
      fill='#B1B8CC'
    />
  </svg>
);

export default IconItem;
