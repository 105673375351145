import {createReducer} from 'redux-act';
import {
  INSIGHTS_CONTROL_CHANGE_MODULE_STATUS,
  INSIGHTS_CONTROL_GET_LOCATION_INFO_SUCCESS,
  INSIGHTS_CONTROL_GET_LOCATIONS,
  INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS,
  SET_LOCATION_TABLE_LOADING
} from './action';
import {INSIGHTS_GROWTH_FEATURES_INFO} from '../../helpers/constants/Insights';

const initialState = () => ({
  loadingTable: false,
  locations: [],
  search: ''
});

const insightsControlReducer = createReducer(
  {
    [INSIGHTS_CONTROL_GET_LOCATIONS]: (state) => {
      return {
        ...state,
        loadingTable: true
      };
    },
    [INSIGHTS_CONTROL_GET_LOCATIONS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        loadingTable: false,
        locations: payload
      };
    },
    [INSIGHTS_CONTROL_GET_LOCATION_INFO_SUCCESS]: (state, payload) => {
      const payloadKeys = Object.keys(payload);
      const featuresKeys = Object.keys(INSIGHTS_GROWTH_FEATURES_INFO);
      const filteredPayloadKeys = featuresKeys.filter((el) =>
        payloadKeys.includes(el)
      );
      const newFeatures = filteredPayloadKeys.map((item) => {
        return {
          title: item,
          status: payload[item]?.status
        };
      });
      newFeatures.sort((a, b) => {
        if (a.title === 'catering') return 1;
        if (a.status === b.status) {
          return 0;
        }
        return a.status === 'Enabled' ? 1 : -1;
      });
      const locations = [...state.locations];
      const locationIndex = locations.findIndex((el) => el.id === payload.id);
      locations[locationIndex] = {
        ...locations[locationIndex],
        locationInfo: newFeatures
      };
      return {
        ...state,
        locations
      };
    },
    [INSIGHTS_CONTROL_CHANGE_MODULE_STATUS]: (state, payload) => {
      const locations = [...state.locations];
      const newLocationInfo = [...locations[payload.index].locationInfo];
      const currentModule = newLocationInfo.find(
        (el) => el.title === payload.title
      );
      currentModule.status = payload.status;
      return {
        ...state,
        locations
      };
    },
    [SET_LOCATION_TABLE_LOADING]: (state, payload) => {
      return {
        ...state,
        loadingTable: payload
      };
    }
  },
  initialState()
);

export default insightsControlReducer;
