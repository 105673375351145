import {request} from '../utils/request';
import {GET} from '../constants/API_CONSTANTS';
import {buildUrl} from '../../functionUtils/AdminBuildUrl';
import {
  API_CRUD_DISCOUNTS,
  API_FEEDBACK_STATS
} from '../constants/API_ENDPOINTS';

export const getFeedbackStatsApi = async (feedbackType, startDate, endDate) => {
  return request(GET, API_FEEDBACK_STATS(feedbackType, startDate, endDate));
};
export const test = async (status, payload) => {
  return request(GET, buildUrl(API_CRUD_DISCOUNTS(status), payload));
};
