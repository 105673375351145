import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {Form, Formik} from 'formik';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import cn from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import {nanoid} from 'nanoid';
import PropTypes from 'prop-types';
import DialogContainer from '../../../../../../../components/DialogContainer/styles';
import DialogClose from '../../../../../../../components/DialogClose/styles';
import DialogTitle from '../../../../../../../components/DialogTitle/styles';
import {
  DialogField,
  DialogSubTitle,
  FieldLabel
} from '../../../../../dashboard/styles';
import DialogBody from '../../../../../../../components/DialogBody/styles';
import SelectFieldWrapper from '../../../../../../../components/SelectField/style';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../../../components/errorSign/errorSign';
import DialogAction from '../../../../../../../components/DialogAction/styles';
import {ButtonPrimary} from '../../../../../../../components/buttons';
import ConfirmStoreSelectModal from '../../../../../uberEats/modals/StoreSelectModal/confirmStoreSelectModal';
import {
  CONTROL_MODALS,
  SAVE_STEP_DATA,
  UBER_EATS_ONBOARDING_SAVE_MENU,
  UBER_EATS_SELECT_STORE_ONBOARDING
} from '../../../../../../../store/setupRestaurant/action';
import Loading from '../../../../../../../components/shared/Utils/Loading';

const UberEatsOnboardingStoreSelectModal = ({
  open,
  stores,
  menus,
  selectedStore,
  menusLoading,
  selectedMenu,
  parentValues
}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const locationName = useSelector(
    (state) => state.user?.activeLocation?.FullName
  );

  const initValues = {
    store:
      selectedStore ||
      stores?.filter((item) => item?.status === 'ONLINE')[0]?.id ||
      stores[0]?.id,
    storeName:
      stores?.filter((item) => item?.id === selectedStore)[0]?.name ||
      stores?.filter((item) => item?.status === 'ONLINE')[0]?.name ||
      stores[0]?.name,
    menu: selectedMenu || menus[0]?.menuType,
    menuName:
      menus?.filter((item) => item?.menuType === selectedMenu)[0]?.menuName ||
      menus[0]?.menuName
  };

  const onClose = () => {
    dispatch(
      CONTROL_MODALS({open: false, field: 'uberEatsStoreSelectModalOpen'})
    );
  };

  useEffect(() => {
    dispatch(
      UBER_EATS_SELECT_STORE_ONBOARDING(
        selectedStore ||
          stores?.filter((item) => item?.status === 'ONLINE')[0]?.id ||
          stores[0]?.id
      )
    );
    if (menus && menus.length > 0) {
      const retrieveMenu =
        menus?.filter(
          (item) =>
            item?.storeId === selectedStore && item?.menuType === selectedMenu
        )[0]?.menuType || '';
      dispatch(UBER_EATS_ONBOARDING_SAVE_MENU(retrieveMenu));
    }
  }, [stores]);

  return (
    <>
      {stores.length > 0 ? (
        <Formik
          initialValues={initValues}
          enableReinitialize
          innerRef={formikRef}
          onSubmit={(values) => {
            if (!values.store) {
              formikRef?.current?.setErrors({
                store: 'Please select UberEats store'
              });
            }
            if (!values.menu) {
              formikRef?.current?.setErrors({
                menu: 'Please select menu'
              });
            }
            if (values.store && values.menu) {
              dispatch(UBER_EATS_ONBOARDING_SAVE_MENU(values.menu));
              onClose();

              dispatch(
                SAVE_STEP_DATA({
                  ...parentValues,
                  data: {
                    ...parentValues.data,
                    MenuUberEats: true,
                    MenuEmail: false,
                    MenuShowUrlInput: false,
                    uberEatsSelectedMenu: values.menu
                  }
                })
              );
            }
          }}
        >
          {({values, errors, touched, setFieldValue, submitForm}) => {
            return (
              <Form>
                <Dialog open={open} scroll='body'>
                  <DialogContainer className='DialogSm'>
                    <DialogClose onClick={onClose} />
                    <DialogTitle className='DialogTitle marginBottomLg'>
                      Select UberEats menu
                    </DialogTitle>
                    <DialogSubTitle>
                      Make sure you choose the right store and the right menu
                      from that store
                    </DialogSubTitle>
                    <DialogBody>
                      <DialogField className='w100 Say2EatSelect'>
                        <FieldLabel className='inline-label'>
                          Select A Store
                        </FieldLabel>
                        <SelectFieldWrapper>
                          <Select
                            value={values.store}
                            name='store'
                            onChange={(e) => {
                              setFieldValue('store', e.target.value);
                              setFieldValue(
                                'storeName',
                                stores.find(
                                  (item) => e.target.value === item.id
                                )?.name
                              );
                              dispatch(
                                UBER_EATS_SELECT_STORE_ONBOARDING(
                                  e.target.value
                                )
                              );
                            }}
                            className={cn({
                              error: errors?.store && touched?.store
                            })}
                            displayEmpty
                            MenuProps={{
                              disableScrollLock: true,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            {stores.map((item) => (
                              <MenuItem key={nanoid()} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </SelectFieldWrapper>
                        <ErrorSignWrapper>
                          {errors?.store && touched?.store ? (
                            <ErrorSign>{errors?.store}</ErrorSign>
                          ) : null}
                        </ErrorSignWrapper>
                        <FieldLabel className='inline-label'>
                          Select A Menu
                        </FieldLabel>
                        {menusLoading || menus.length < 1 ? (
                          <Loading className='LoadingExportButton' />
                        ) : (
                          <>
                            <SelectFieldWrapper>
                              <Select
                                value={values.menu}
                                name='menu'
                                onChange={(e) => {
                                  setFieldValue('menu', e.target.value);
                                  setFieldValue(
                                    'menuName',
                                    stores.find(
                                      (item) => e.target.value === item.id
                                    )?.name
                                  );
                                  dispatch(
                                    UBER_EATS_ONBOARDING_SAVE_MENU(
                                      e.target.value
                                    )
                                  );
                                }}
                                className={cn({
                                  error: errors?.store && touched?.store
                                })}
                                displayEmpty
                                MenuProps={{
                                  disableScrollLock: true,
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left'
                                  },
                                  getContentAnchorEl: null
                                }}
                              >
                                {menus.map((item) => (
                                  <MenuItem
                                    key={nanoid()}
                                    value={item.menuType}
                                  >
                                    {item.menuName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </SelectFieldWrapper>
                            <ErrorSignWrapper>
                              {errors?.menu && touched?.menu ? (
                                <ErrorSign>{errors?.menu}</ErrorSign>
                              ) : null}
                            </ErrorSignWrapper>
                          </>
                        )}
                      </DialogField>
                      <DialogAction>
                        <ButtonPrimary onClick={submitForm} type='submit'>
                          <span>Confirm</span>
                        </ButtonPrimary>
                      </DialogAction>
                    </DialogBody>
                  </DialogContainer>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

UberEatsOnboardingStoreSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  stores: PropTypes.array.isRequired,
  menus: PropTypes.array,
  selectedStore: PropTypes.string,
  menusLoading: PropTypes.bool,
  selectedMenu: PropTypes.string,
  parentValues: PropTypes.object
};

UberEatsOnboardingStoreSelectModal.defaultProps = {
  menus: [],
  selectedStore: '',
  menusLoading: false,
  selectedMenu: '',
  parentValues: {}
};

export default UberEatsOnboardingStoreSelectModal;
