import {createAction} from 'redux-act';

export const INSIGHTS_GROWTH_GET_INFO = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_GET_INFO'
);
export const INSIGHTS_GROWTH_GET_INFO_SUCCESS = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_GET_INFO_SUCCESS'
);

export const INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL'
);

export const INSIGHTS_GROWTH_POST_CONTACT_US = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_POST_CONTACT_US'
);

export const INSIGHTS_GROWTH_SET_LOADING = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_SET_LOADING'
);

export const INSIGHTS_GROWTH_CLEAR_INFO = createAction(
  'insightsGrowth/INSIGHTS_GROWTH_CLEAR_INFO'
);
