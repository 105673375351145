import React from 'react';
import {useSelector} from 'react-redux';
import AddNewModal from './Modals/AddNewModal';
import CreateCategoryModal from './Modals/CreateCategoryModal';
import CreateItemModal from './Modals/CreateItemModal';
import EditItemModal from './Modals/EditItemModal';
import EditCategoryModal from './Modals/EditCategoryModal';
import DeleteModal from './Modals/DeleteModal';
import HiddenModal from './Modals/HiddenModal';
import EditModalNote from './Modals/EditModalNote';
import SoldOutModal from './Modals/SoldOutModal';
import CreateModifierModal from './Modals/CreateModifierModal';
import CreateModifierGroupModal from './Modals/CreateModifierGroupModal';
import EditModifierGroupModal from './Modals/EditModifierGroupModal';
import EditModifierModal from './Modals/EditModifierModal';
import SelectItemModal from './Modals/SelectItemModal';
import {DaysPickerContextProvider} from './context/DaysPickerContext';

export default function ModalsView() {
  const isAddNewModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isAddNewModalOpen
  );
  const isEditItemModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditItemModalOpen
  );
  const isCreateItemModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateItemModalOpen
  );
  const isCreateCategoryModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateCategoryModalOpen
  );
  const isEditCategoryModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditCategoryModalOpen
  );
  const isDeleteModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isDeleteModalOpen
  );
  const isHiddenModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isHiddenModalOpen
  );
  const isEditNoteModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditNoteModalOpen
  );
  const isSoldOutModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isSoldOutModalOpen
  );
  const isCreateModifierModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateModifierModalOpen
  );
  const isCreateModifierGroupModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isCreateModifierGroupModalOpen
  );
  const isEditModifierGroupModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditModifierGroupModalOpen
  );
  const isEditModifierModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isEditModifierModalOpen
  );
  const isSelectItemModalOpen = useSelector(
    (state) => state.menuEditorModalsReducer.isSelectItemModalOpen
  );

  return (
    <>
      {isAddNewModalOpen && <AddNewModal />}
      {isCreateCategoryModalOpen && (
        <DaysPickerContextProvider>
          <CreateCategoryModal />
        </DaysPickerContextProvider>
      )}
      {isCreateItemModalOpen && (
        <DaysPickerContextProvider>
          <CreateItemModal />
        </DaysPickerContextProvider>
      )}
      {isEditItemModalOpen && (
        <DaysPickerContextProvider>
          <EditItemModal />
        </DaysPickerContextProvider>
      )}
      {isEditCategoryModalOpen && (
        <DaysPickerContextProvider>
          <EditCategoryModal />
        </DaysPickerContextProvider>
      )}
      {isDeleteModalOpen && <DeleteModal />}
      {isHiddenModalOpen && <HiddenModal />}
      {isEditNoteModalOpen && <EditModalNote />}
      {isSoldOutModalOpen && <SoldOutModal />}
      {isCreateModifierModalOpen && <CreateModifierModal />}
      {isCreateModifierGroupModalOpen && <CreateModifierGroupModal />}
      {isEditModifierGroupModalOpen && <EditModifierGroupModal />}
      {isEditModifierModalOpen && <EditModifierModal />}
      {isSelectItemModalOpen && <SelectItemModal />}
    </>
  );
}
