import {GET, POST} from '../constants/API_CONSTANTS';
import {
  GET_ALL_REVIEWS,
  GET_REVIEW_BY_ID,
  REPLIED_REVIEWS_BY_USER,
  REPLIES
} from '../constants/API_ENDPOINTS';
import {request} from '../utils/request';

export const getAllReviewsApi = async ({
  page,
  limit,
  sort = 'asc',
  sortBy = 'EndTime'
}) => {
  const params = {page, limit, sort, sortBy};
  return request(GET, GET_ALL_REVIEWS(params));
};

export const getReviewByIdApi = async (id) => {
  return request(GET, GET_REVIEW_BY_ID(id));
};

export const getRepliesApi = async (id) => {
  return request(GET, REPLIES(id));
};

export const createReplyApi = async ({id, data}) => {
  return request(POST, REPLIES(id), {data});
};

export const getRepliedReviewsByUserApi = async (reviewId, userId) => {
  return request(GET, REPLIED_REVIEWS_BY_USER(reviewId, userId));
};
