import React from 'react';

const IconHidden = () => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.6748 0.32519C21.5726 0.222148 21.451 0.140362 21.3171 0.0845486C21.1831 0.0287354 21.0394 0 20.8943 0C20.7491 0 20.6054 0.0287354 20.4715 0.0845486C20.3375 0.140362 20.2159 0.222148 20.1137 0.32519L0.32519 20.1137C0.222148 20.2159 0.140362 20.3375 0.0845486 20.4715C0.0287354 20.6054 0 20.7491 0 20.8943C0 21.0394 0.0287354 21.1831 0.0845486 21.3171C0.140362 21.451 0.222148 21.5726 0.32519 21.6748C0.42739 21.7779 0.54898 21.8596 0.682948 21.9155C0.816916 21.9713 0.960608 22 1.10574 22C1.25087 22 1.39456 21.9713 1.52853 21.9155C1.66249 21.8596 1.78408 21.7779 1.88628 21.6748L21.6748 1.88628C21.7779 1.78408 21.8596 1.66249 21.9155 1.52853C21.9713 1.39456 22 1.25087 22 1.10574C22 0.960608 21.9713 0.816916 21.9155 0.682948C21.8596 0.54898 21.7779 0.42739 21.6748 0.32519Z'
        fill='#FCA600'
      />
      <path
        d='M11 7.4C9.1938 7.4 7.7 8.8949 7.7 10.7C7.7 12.5062 9.1938 14 11 14C12.8051 14 14.3 12.5062 14.3 10.7C14.3 8.8949 12.8051 7.4 11 7.4Z'
        fill='#FCA600'
      />
      <path
        d='M11 3C2.6488 3 0.13895 10.2787 0.115974 10.3524L0 10.7L0.11488 11.0476C0.13895 11.1213 2.6488 18.4 11 18.4C19.3512 18.4 21.861 11.1213 21.884 11.0476L22 10.7L21.8851 10.3524C21.861 10.2787 19.3512 3 11 3ZM11 16.2C5.14551 16.2 2.87746 11.9694 2.32823 10.7C2.87965 9.4262 5.1488 5.2 11 5.2C16.8545 5.2 19.1225 9.4306 19.6718 10.7C19.1203 11.9738 16.8512 16.2 11 16.2Z'
        fill='#FCA600'
      />
    </svg>
  );
};

export default IconHidden;
