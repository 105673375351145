import React, {useEffect} from 'react';
import {Route, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Box} from '@material-ui/core';
import {toast} from 'react-toastify';
import {
  GET_SETUP_USER_ACCOUNT,
  GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING,
  GET_UBER_EATS_STORES_ONBOARDING,
  SAVE_STEP_DATA
} from '../../../../../store/setupRestaurant/action';
import {
  PendingData,
  stepData
} from '../../../../../store/setupRestaurant/selectors';
import {findLastFilledStep} from '../../helplers/findLastFilledStep';
import {
  SetupHolder,
  SetupSection
} from '../../../../Auth/registration/signUpNew/style';
import FreeTrial from '../SeflSetup/freeTrialSteps/FreeTrial';
import SidebarStepper from '../Components/SidebarStepper';
import Loading from '../../../../../components/shared/Utils/Loading';
import AddMenu from './setup/AddMenu';
import GeneralInfo from './setup/GeneralInfo';
import {SidebarProfileLogoutButton} from '../../../../../components/layout/sidebar/styles';
import {LOGOUT_USER} from '../../../../../store/global/auth/action';
import {Body1} from '../../../../../components/typography';
import {SetupLogout} from '../../styles';
import OldAddMenu from './setup/AddMenu/index_OLD';

const CreateAccount = () => {
  const history = useHistory();
  const {path} = useRouteMatch();
  const dispatch = useDispatch();
  const {
    UserId,
    loading,
    dataReceived,
    pendingDataSetuped,
    isUberEatsConnected
  } = stepData();
  const pendingData = PendingData();
  const location = useLocation();

  // useEffect(() => {
  //   dispatch(GET_UBER_EATS_CONNECTION_STATUS_ONBOARDING());
  // }, []);

  useEffect(() => {
    if (UserId) {
      dispatch(GET_SETUP_USER_ACCOUNT());
    }
  }, [UserId]);

  useEffect(() => {
    const lastFilledPagePath = findLastFilledStep(
      false,
      pendingData,
      isUberEatsConnected
    );
    if (dataReceived) {
      if (!pendingDataSetuped) {
        if (
          location.pathname.includes(
            '/my-account/sales-setup-new/add-menu/uber-eats-connect'
          )
        ) {
          const query = location.search;
          const params = new URLSearchParams(query);
          const error = params.get('error');
          const success = params.get('success');
          if (error) {
            toast.error(
              error || 'Something went wrong! Please try again later!'
            );
          } else if (success && success === 'true') {
            // dispatch(GET_UBER_EATS_STORES_ONBOARDING());
          }
          history.push('/my-account/sales-setup-new/add-menu');

          const localStorageValues = localStorage.getItem(
            'newMenuOnboardingFormValues'
          );

          if (localStorageValues) {
            dispatch(SAVE_STEP_DATA(JSON.parse(localStorageValues)));
            localStorage.removeItem('newMenuOnboardingFormValues');
          }
        } else {
          history.push(`${path}/${lastFilledPagePath}`);
        }
      } else {
        history.push('/my-account');
      }
    }
  }, [
    dataReceived,
    history.location.pathname === '/my-account/sales-setup' ||
      history.location.pathname === '/my-account/sales-setup-new'
  ]);

  if (!dataReceived) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >
        <Loading />
      </Box>
    );
  }

  return (
    <SetupSection>
      <SetupHolder>
        <Route exact path={`${path}/general-info`} component={GeneralInfo} />
        <Route
          exact
          path={`${path}/add-menu`}
          component={
            location.pathname.includes('/my-account/sales-setup-new')
              ? AddMenu
              : OldAddMenu
          }
        />
        <SetupLogout>
          <Body1>Logout</Body1>
          <SidebarProfileLogoutButton
            onClick={() => {
              dispatch(LOGOUT_USER());
            }}
            href={`${process.env.REACT_APP_OLD_DASHBOARD_URL}/logout`}
          />
        </SetupLogout>
      </SetupHolder>
      {!path.includes('/calculator') && (
        <SidebarStepper isMobile={false} isSignUp2={false} />
      )}
    </SetupSection>
  );
};

export default CreateAccount;
